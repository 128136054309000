import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { EWithdrawProviderSchema, useGetWithdrawalPinCodeHook, WithdrawalSchema } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, IconButton } from '@mui/material';
import {
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon, Link } from '@greenisland-common/components/atoms';

import { formatCurrency, formatDateTime } from '@greenisland-common/helpers';

import { StatusSpan } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';

const useGetWithdrawalsHeaders = (hasDetails: boolean) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [pinCode, setPinCode] = useState<string | null>(null);
  const canLookupUser = usePermission(OnlineCasinoPermissions.advancedUserSearchV2);
  const canReadWithdrawalPinCode = usePermission(OnlineCasinoPermissions.getWithdrawalPinCode);

  const getWithdrawalPinCode = useGetWithdrawalPinCodeHook();

  const columns = useMemo<GridColumns<WithdrawalSchema>>(() => {
    const headers: GridColumns<WithdrawalSchema> = [
      {
        headerName: t('id'),
        field: 'id',
        type: 'string',
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('timestamp'),
        field: 'timestamp',
        type: 'date',
        valueGetter: ({ value }: GridValueGetterParams<number>) => value && new Date(value * 1000),
        valueFormatter: ({ value }: GridValueFormatterParams<Date>) => formatDateTime(value, false),
        flex: 2,
        minWidth: 150,
        description: t('theRequestTime'),
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('amount'),
        field: 'amount',
        type: 'number',
        valueFormatter: ({ value }: GridValueFormatterParams<number>) => formatCurrency(value, i18n.language),
        flex: 1,
        minWidth: 125,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('accountId'),
        field: 'accountId',
        type: 'string',
        flex: 1,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<string, WithdrawalSchema>) => {
          return canLookupUser ? (
            <Link underline="hover" to={`../../users/${params.row.userId}/details`}>
              {params.row.accountId}
            </Link>
          ) : (
            <span>{params.row.accountId}</span>
          );
        },
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('flag'),
        field: 'flag',
        type: 'string',
        flex: 1,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<string, WithdrawalSchema>) => (
          <StatusSpan value={params.row.flag?.toString() ?? ''} />
        ),
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('status'),
        field: 'status',
        type: 'string',
        flex: 2,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<string, WithdrawalSchema>) => (
          <StatusSpan value={params.row.status.toString() ?? ''} />
        ),
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('destination'),
        field: 'destination',
        type: 'string',
        flex: 2,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },

      {
        headerName: t('reference'),
        field: 'reference',
        type: 'string',
        flex: 4,
        minWidth: 250,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'actions',
        headerName: t('details'),
        flex: 2,
        minWidth: 220,
        disableColumnMenu: true,
        disableExport: true,
        disableReorder: true,
        filterable: false,
        hideable: false,
        sortable: false,
        resizable: false,
        renderCell: (params: GridRenderCellParams<string, WithdrawalSchema>) => {
          return (
            <>
              {params.row.destination === EWithdrawProviderSchema.casino && canReadWithdrawalPinCode && (
                <Button
                  sx={{ mr: 1 }}
                  size="small"
                  variant="outlined"
                  color="secondary"
                  onClick={async () => {
                    try {
                      const pinCode = await getWithdrawalPinCode(params.row.id);
                      setPinCode(pinCode?.pinCode || t('noPinCode'));
                    } catch {
                      enqueueSnackbar(t('error'), { variant: 'error' });
                    }
                  }}
                >
                  {t('revealPinCode')}
                </Button>
              )}
              <Link to={`../../users/${params.row.userId}/withdrawals/${params.row.id}`} underline="none">
                <IconButton size="small" color="primary">
                  <FontAwesomeIcon icon={faEye} />
                </IconButton>
              </Link>
            </>
          );
        },
        headerAlign: 'center',
        align: 'right',
      },
    ];
    const extraHeaders: GridColumns<WithdrawalSchema> = [
      {
        headerName: t('balanceAfter'),
        field: 'balanceAfter',
        type: 'string',
        valueFormatter: ({ value }: GridValueFormatterParams<number>) => formatCurrency(value, i18n.language),
        flex: 2,
        minWidth: 125,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('approvedBy'),
        field: 'approvedBy',
        type: 'string',
        flex: 3,
        minWidth: 250,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('iban'),
        field: 'iban',
        type: 'string',
        flex: 2,
        minWidth: 200,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('balanceSource'),
        field: 'balanceSource',
        type: 'string',
        flex: 2,
        minWidth: 250,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('fee'),
        field: 'fee',
        type: 'number',
        valueFormatter: ({ value }: GridValueFormatterParams<number>) => formatCurrency(value, i18n.language),
        flex: 1,
        minWidth: 75,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('isabelExportId'),
        field: 'isabelExportId',
        type: 'string',
        flex: 2,
        minWidth: 150,
        align: 'center',
        headerAlign: 'center',
      },
      {
        headerName: t('casinoId'),
        field: 'casinoFk',
        type: 'string',
        flex: 2,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('walletId'),
        field: 'wallet',
        type: 'string',
        flex: 2,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },
    ];
    return hasDetails ? headers.concat(extraHeaders) : headers;
  }, [t, hasDetails, canLookupUser, getWithdrawalPinCode, canReadWithdrawalPinCode, i18n.language, enqueueSnackbar]);
  return { columns, pinCode, setPinCode };
};

export default useGetWithdrawalsHeaders;
