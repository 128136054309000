/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * Open: Indicates the match requires review. TruePositive: Indicates that the match is closed and that the match was to the correct profile. FalsePositive: Indicates that the match is closed and that the match was to the incorrect profile.   Discarded: Indicates that the given profile no longer matches the monitor record due to the changes in the profile, monitor record, or the worklist.

 */
export type EMatchStatusSchema = (typeof EMatchStatusSchema)[keyof typeof EMatchStatusSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EMatchStatusSchema = {
  Open: 'Open',
  TruePositive: 'TruePositive',
  FalsePositive: 'FalsePositive',
  Discarded: 'Discarded',
} as const;
