import { ETransactionsBadRequestTypeSchema } from '@greenisland-api';

export enum DepositsParamsType {
  START_DATE = 'start',
  END_DATE = 'end',
  USER_ID = 'userId',
  ACCOUNT_ID = 'accountId',
  DEPOSIT_ID = 'depositId',
  MIN_AMOUNT = 'minAmount',
  MAX_AMOUNT = 'maxAmount',
  REFERENCE = 'reference',
  IBAN = 'iban',
  WALLET = 'wallet',
  IP_ADDRESS = 'ipaddress',
  STATUS = 'status',
  PROVIDER = 'provider',
  METHOD = 'method',
  SORT_BY = 'sortby',
  PAGE = 'page',
  PAGE_SIZE = 'pageSize',
  HAS_DETAILS = 'hasDetails',
  ASCENDING = 'ascending',
}

type DepositErrorReasons = {
  [key in ETransactionsBadRequestTypeSchema]: string;
};

export const DEPOSIT_ERROR_REASONS: DepositErrorReasons = {
  [ETransactionsBadRequestTypeSchema.insufficientFiltering]: 'deposits.errors.insufficientFiltering',
};

export const isCheckedValidationHandler = (value: string | null) => {
  return /true/.test(value || '');
};
