/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  QueryTournamentConfigurationsParams,
  TournamentConfiguration,
  TournamentConfigurationsPaginated,
  UpdateInstantiatedTournamentConfiguration,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Get list of tournament configurations
 */
export const useQueryTournamentConfigurationsHook = () => {
  const queryTournamentConfigurations = useCustomInstance<TournamentConfigurationsPaginated>();

  return (params?: QueryTournamentConfigurationsParams, signal?: AbortSignal) => {
    return queryTournamentConfigurations({ url: `/tournament-configurations`, method: 'get', params, signal });
  };
};

export const getQueryTournamentConfigurationsQueryKey = (params?: QueryTournamentConfigurationsParams) =>
  [`/tournament-configurations`, ...(params ? [params] : [])] as const;

export const useQueryTournamentConfigurationsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useQueryTournamentConfigurationsHook>>>,
  TError = unknown
>(
  params?: QueryTournamentConfigurationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useQueryTournamentConfigurationsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryTournamentConfigurationsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQueryTournamentConfigurationsQueryKey(params);

  const queryTournamentConfigurations = useQueryTournamentConfigurationsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useQueryTournamentConfigurationsHook>>>> = ({
    signal,
  }) => queryTournamentConfigurations(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type QueryTournamentConfigurationsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useQueryTournamentConfigurationsHook>>>
>;
export type QueryTournamentConfigurationsQueryError = unknown;

export const useQueryTournamentConfigurations = <
  TData = Awaited<ReturnType<ReturnType<typeof useQueryTournamentConfigurationsHook>>>,
  TError = unknown
>(
  params?: QueryTournamentConfigurationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useQueryTournamentConfigurationsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useQueryTournamentConfigurationsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Add a new tournament configuration
 */
export const useAddTournamentConfigurationHook = () => {
  const addTournamentConfiguration = useCustomInstance<TournamentConfiguration>();

  return (tournamentConfiguration: TournamentConfiguration) => {
    return addTournamentConfiguration({
      url: `/tournament-configurations`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: tournamentConfiguration,
    });
  };
};

export const useAddTournamentConfigurationMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddTournamentConfigurationHook>>>,
    TError,
    { data: TournamentConfiguration },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddTournamentConfigurationHook>>>,
  TError,
  { data: TournamentConfiguration },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addTournamentConfiguration = useAddTournamentConfigurationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddTournamentConfigurationHook>>>,
    { data: TournamentConfiguration }
  > = props => {
    const { data } = props ?? {};

    return addTournamentConfiguration(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddTournamentConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddTournamentConfigurationHook>>>
>;
export type AddTournamentConfigurationMutationBody = TournamentConfiguration;
export type AddTournamentConfigurationMutationError = unknown;

export const useAddTournamentConfiguration = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddTournamentConfigurationHook>>>,
    TError,
    { data: TournamentConfiguration },
    TContext
  >;
}) => {
  const mutationOptions = useAddTournamentConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete Scheduled Instances for given Tournament Configuration Id
 */
export const useDeleteScheduledTournamentInstancesForTournamentConfigurationHook = () => {
  const deleteScheduledTournamentInstancesForTournamentConfiguration = useCustomInstance<void>();

  return (tournamentConfigurationId: number) => {
    return deleteScheduledTournamentInstancesForTournamentConfiguration({
      url: `/tournament-configurations/${tournamentConfigurationId}/scheduled-instances`,
      method: 'delete',
    });
  };
};

export const useDeleteScheduledTournamentInstancesForTournamentConfigurationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteScheduledTournamentInstancesForTournamentConfigurationHook>>>,
    TError,
    { tournamentConfigurationId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteScheduledTournamentInstancesForTournamentConfigurationHook>>>,
  TError,
  { tournamentConfigurationId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteScheduledTournamentInstancesForTournamentConfiguration =
    useDeleteScheduledTournamentInstancesForTournamentConfigurationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteScheduledTournamentInstancesForTournamentConfigurationHook>>>,
    { tournamentConfigurationId: number }
  > = props => {
    const { tournamentConfigurationId } = props ?? {};

    return deleteScheduledTournamentInstancesForTournamentConfiguration(tournamentConfigurationId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteScheduledTournamentInstancesForTournamentConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteScheduledTournamentInstancesForTournamentConfigurationHook>>>
>;

export type DeleteScheduledTournamentInstancesForTournamentConfigurationMutationError = unknown;

export const useDeleteScheduledTournamentInstancesForTournamentConfiguration = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteScheduledTournamentInstancesForTournamentConfigurationHook>>>,
    TError,
    { tournamentConfigurationId: number },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteScheduledTournamentInstancesForTournamentConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get Tournament configuration
 */
export const useGetTournamentConfigurationHook = () => {
  const getTournamentConfiguration = useCustomInstance<TournamentConfiguration>();

  return (tournamentConfigurationId: number, signal?: AbortSignal) => {
    return getTournamentConfiguration({
      url: `/tournament-configurations/${tournamentConfigurationId}`,
      method: 'get',
      signal,
    });
  };
};

export const getGetTournamentConfigurationQueryKey = (tournamentConfigurationId: number) =>
  [`/tournament-configurations/${tournamentConfigurationId}`] as const;

export const useGetTournamentConfigurationQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTournamentConfigurationHook>>>,
  TError = unknown
>(
  tournamentConfigurationId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTournamentConfigurationHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTournamentConfigurationHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTournamentConfigurationQueryKey(tournamentConfigurationId);

  const getTournamentConfiguration = useGetTournamentConfigurationHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTournamentConfigurationHook>>>> = ({
    signal,
  }) => getTournamentConfiguration(tournamentConfigurationId, signal);

  return { queryKey, queryFn, enabled: !!tournamentConfigurationId, ...queryOptions };
};

export type GetTournamentConfigurationQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetTournamentConfigurationHook>>>
>;
export type GetTournamentConfigurationQueryError = unknown;

export const useGetTournamentConfiguration = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTournamentConfigurationHook>>>,
  TError = unknown
>(
  tournamentConfigurationId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTournamentConfigurationHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTournamentConfigurationQueryOptions(tournamentConfigurationId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update a tournament configuration that has no instances yet
 */
export const usePutTournamentConfigurationHook = () => {
  const putTournamentConfiguration = useCustomInstance<TournamentConfiguration>();

  return (tournamentConfigurationId: number, tournamentConfiguration: TournamentConfiguration) => {
    return putTournamentConfiguration({
      url: `/tournament-configurations/${tournamentConfigurationId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: tournamentConfiguration,
    });
  };
};

export const usePutTournamentConfigurationMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutTournamentConfigurationHook>>>,
    TError,
    { tournamentConfigurationId: number; data: TournamentConfiguration },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutTournamentConfigurationHook>>>,
  TError,
  { tournamentConfigurationId: number; data: TournamentConfiguration },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putTournamentConfiguration = usePutTournamentConfigurationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutTournamentConfigurationHook>>>,
    { tournamentConfigurationId: number; data: TournamentConfiguration }
  > = props => {
    const { tournamentConfigurationId, data } = props ?? {};

    return putTournamentConfiguration(tournamentConfigurationId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutTournamentConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutTournamentConfigurationHook>>>
>;
export type PutTournamentConfigurationMutationBody = TournamentConfiguration;
export type PutTournamentConfigurationMutationError = unknown;

export const usePutTournamentConfiguration = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutTournamentConfigurationHook>>>,
    TError,
    { tournamentConfigurationId: number; data: TournamentConfiguration },
    TContext
  >;
}) => {
  const mutationOptions = usePutTournamentConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update an instantiated tournament configuration
 */
export const usePutInstantiatedTournamentConfigurationHook = () => {
  const putInstantiatedTournamentConfiguration = useCustomInstance<TournamentConfiguration>();

  return (
    tournamentConfigurationId: number,
    updateInstantiatedTournamentConfiguration: UpdateInstantiatedTournamentConfiguration
  ) => {
    return putInstantiatedTournamentConfiguration({
      url: `/tournament-configurations/update-instantiated/${tournamentConfigurationId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateInstantiatedTournamentConfiguration,
    });
  };
};

export const usePutInstantiatedTournamentConfigurationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutInstantiatedTournamentConfigurationHook>>>,
    TError,
    { tournamentConfigurationId: number; data: UpdateInstantiatedTournamentConfiguration },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutInstantiatedTournamentConfigurationHook>>>,
  TError,
  { tournamentConfigurationId: number; data: UpdateInstantiatedTournamentConfiguration },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putInstantiatedTournamentConfiguration = usePutInstantiatedTournamentConfigurationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutInstantiatedTournamentConfigurationHook>>>,
    { tournamentConfigurationId: number; data: UpdateInstantiatedTournamentConfiguration }
  > = props => {
    const { tournamentConfigurationId, data } = props ?? {};

    return putInstantiatedTournamentConfiguration(tournamentConfigurationId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutInstantiatedTournamentConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePutInstantiatedTournamentConfigurationHook>>>
>;
export type PutInstantiatedTournamentConfigurationMutationBody = UpdateInstantiatedTournamentConfiguration;
export type PutInstantiatedTournamentConfigurationMutationError = unknown;

export const usePutInstantiatedTournamentConfiguration = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutInstantiatedTournamentConfigurationHook>>>,
    TError,
    { tournamentConfigurationId: number; data: UpdateInstantiatedTournamentConfiguration },
    TContext
  >;
}) => {
  const mutationOptions = usePutInstantiatedTournamentConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
