/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  CampaignActionsSchema,
  CampaignDetailsSchema,
  CampaignSchema,
  CampaignsOverviewSchema,
  CampaignTemplateDetailsSchema,
  CampaignTemplatesSchema,
  CampaignTemplateUpdateSchema,
  CreateIndividualActionBadRequestResponse,
  DefaultResponse,
  ErrorSchema,
  FreeSpinArgumentsSchema,
  FreeSpinsCampaignsSchema,
  FreeSpinsGamesConfigurationsSchema,
  GetCampaignsOverviewParams,
  IndividualActionSchema,
  NotFoundResponse,
  TemplateTypesSchema,
  TriggerIndividualActionBadRequestResponse,
  TriggerIndividualActionSchema,
  UnauthorizedResponse,
  UpdateCampaignSchema,
  UpdateIndividualActionSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Returns the available freespins campaigns
 */
export const useGetFreeSpinsCampaignsHook = () => {
  const getFreeSpinsCampaigns = useCustomInstance<FreeSpinsCampaignsSchema>();

  return (signal?: AbortSignal) => {
    return getFreeSpinsCampaigns({ url: `/marketing/campaigns/freespincampaigns`, method: 'get', signal });
  };
};

export const getGetFreeSpinsCampaignsQueryKey = () => [`/marketing/campaigns/freespincampaigns`] as const;

export const useGetFreeSpinsCampaignsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsCampaignsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsCampaignsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsCampaignsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFreeSpinsCampaignsQueryKey();

  const getFreeSpinsCampaigns = useGetFreeSpinsCampaignsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsCampaignsHook>>>> = ({ signal }) =>
    getFreeSpinsCampaigns(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetFreeSpinsCampaignsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsCampaignsHook>>>
>;
export type GetFreeSpinsCampaignsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Returns the available freespins campaigns
 */
export const useGetFreeSpinsCampaigns = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsCampaignsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsCampaignsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetFreeSpinsCampaignsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Adds a new freespins campaign
 */
export const useAddFreeSpinsCampaignHook = () => {
  const addFreeSpinsCampaign = useCustomInstance<FreeSpinsCampaignsSchema>();

  return (freeSpinArgumentsSchema: FreeSpinArgumentsSchema) => {
    return addFreeSpinsCampaign({
      url: `/marketing/campaigns/freespincampaigns`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: freeSpinArgumentsSchema,
    });
  };
};

export const useAddFreeSpinsCampaignMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddFreeSpinsCampaignHook>>>,
    TError,
    { data: FreeSpinArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddFreeSpinsCampaignHook>>>,
  TError,
  { data: FreeSpinArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addFreeSpinsCampaign = useAddFreeSpinsCampaignHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddFreeSpinsCampaignHook>>>,
    { data: FreeSpinArgumentsSchema }
  > = props => {
    const { data } = props ?? {};

    return addFreeSpinsCampaign(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddFreeSpinsCampaignMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddFreeSpinsCampaignHook>>>
>;
export type AddFreeSpinsCampaignMutationBody = FreeSpinArgumentsSchema;
export type AddFreeSpinsCampaignMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Adds a new freespins campaign
 */
export const useAddFreeSpinsCampaign = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddFreeSpinsCampaignHook>>>,
    TError,
    { data: FreeSpinArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddFreeSpinsCampaignMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns the available freespins campaigns
 */
export const useGetFreeSpinsCampaignsV2Hook = () => {
  const getFreeSpinsCampaignsV2 = useCustomInstance<FreeSpinsCampaignsSchema>();

  return (providerId: string, signal?: AbortSignal) => {
    return getFreeSpinsCampaignsV2({
      url: `/marketing/campaigns/v2/${providerId}/freespincampaigns`,
      method: 'get',
      signal,
    });
  };
};

export const getGetFreeSpinsCampaignsV2QueryKey = (providerId: string) =>
  [`/marketing/campaigns/v2/${providerId}/freespincampaigns`] as const;

export const useGetFreeSpinsCampaignsV2QueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsCampaignsV2Hook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  providerId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsCampaignsV2Hook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsCampaignsV2Hook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFreeSpinsCampaignsV2QueryKey(providerId);

  const getFreeSpinsCampaignsV2 = useGetFreeSpinsCampaignsV2Hook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsCampaignsV2Hook>>>> = ({ signal }) =>
    getFreeSpinsCampaignsV2(providerId, signal);

  return { queryKey, queryFn, enabled: !!providerId, ...queryOptions };
};

export type GetFreeSpinsCampaignsV2QueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsCampaignsV2Hook>>>
>;
export type GetFreeSpinsCampaignsV2QueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Returns the available freespins campaigns
 */
export const useGetFreeSpinsCampaignsV2 = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsCampaignsV2Hook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  providerId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsCampaignsV2Hook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetFreeSpinsCampaignsV2QueryOptions(providerId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns the available freespin game configurations
 */
export const useGetFreeSpinsGameConfigurationsV2Hook = () => {
  const getFreeSpinsGameConfigurationsV2 = useCustomInstance<FreeSpinsGamesConfigurationsSchema>();

  return (providerId: string, signal?: AbortSignal) => {
    return getFreeSpinsGameConfigurationsV2({
      url: `/marketing/campaigns/v2/freespinconfigurations/${providerId}`,
      method: 'get',
      signal,
    });
  };
};

export const getGetFreeSpinsGameConfigurationsV2QueryKey = (providerId: string) =>
  [`/marketing/campaigns/v2/freespinconfigurations/${providerId}`] as const;

export const useGetFreeSpinsGameConfigurationsV2QueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsGameConfigurationsV2Hook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  providerId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsGameConfigurationsV2Hook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsGameConfigurationsV2Hook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFreeSpinsGameConfigurationsV2QueryKey(providerId);

  const getFreeSpinsGameConfigurationsV2 = useGetFreeSpinsGameConfigurationsV2Hook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsGameConfigurationsV2Hook>>>> = ({
    signal,
  }) => getFreeSpinsGameConfigurationsV2(providerId, signal);

  return { queryKey, queryFn, enabled: !!providerId, ...queryOptions };
};

export type GetFreeSpinsGameConfigurationsV2QueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsGameConfigurationsV2Hook>>>
>;
export type GetFreeSpinsGameConfigurationsV2QueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Returns the available freespin game configurations
 */
export const useGetFreeSpinsGameConfigurationsV2 = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsGameConfigurationsV2Hook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  providerId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetFreeSpinsGameConfigurationsV2Hook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetFreeSpinsGameConfigurationsV2QueryOptions(providerId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get campaigns overview
 */
export const useGetCampaignsOverviewHook = () => {
  const getCampaignsOverview = useCustomInstance<CampaignsOverviewSchema>();

  return (params?: GetCampaignsOverviewParams, signal?: AbortSignal) => {
    return getCampaignsOverview({ url: `/marketing/campaigns`, method: 'get', params, signal });
  };
};

export const getGetCampaignsOverviewQueryKey = (params?: GetCampaignsOverviewParams) =>
  [`/marketing/campaigns`, ...(params ? [params] : [])] as const;

export const useGetCampaignsOverviewQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCampaignsOverviewHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetCampaignsOverviewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCampaignsOverviewHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCampaignsOverviewHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCampaignsOverviewQueryKey(params);

  const getCampaignsOverview = useGetCampaignsOverviewHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCampaignsOverviewHook>>>> = ({ signal }) =>
    getCampaignsOverview(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetCampaignsOverviewQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCampaignsOverviewHook>>>
>;
export type GetCampaignsOverviewQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get campaigns overview
 */
export const useGetCampaignsOverview = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCampaignsOverviewHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetCampaignsOverviewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCampaignsOverviewHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCampaignsOverviewQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get campaigns overview
 */
export const useAddCampaignHook = () => {
  const addCampaign = useCustomInstance<CampaignsOverviewSchema>();

  return (campaignSchema: CampaignSchema) => {
    return addCampaign({
      url: `/marketing/campaigns`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: campaignSchema,
    });
  };
};

export const useAddCampaignMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddCampaignHook>>>,
    TError,
    { data: CampaignSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddCampaignHook>>>,
  TError,
  { data: CampaignSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addCampaign = useAddCampaignHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddCampaignHook>>>,
    { data: CampaignSchema }
  > = props => {
    const { data } = props ?? {};

    return addCampaign(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddCampaignMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddCampaignHook>>>>;
export type AddCampaignMutationBody = CampaignSchema;
export type AddCampaignMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get campaigns overview
 */
export const useAddCampaign = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddCampaignHook>>>,
    TError,
    { data: CampaignSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddCampaignMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get campaign
 */
export const useGetCampaignHook = () => {
  const getCampaign = useCustomInstance<CampaignDetailsSchema>();

  return (campaignId: string, signal?: AbortSignal) => {
    return getCampaign({ url: `/marketing/campaigns/${campaignId}`, method: 'get', signal });
  };
};

export const getGetCampaignQueryKey = (campaignId: string) => [`/marketing/campaigns/${campaignId}`] as const;

export const useGetCampaignQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCampaignHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  campaignId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCampaignHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCampaignHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCampaignQueryKey(campaignId);

  const getCampaign = useGetCampaignHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCampaignHook>>>> = ({ signal }) =>
    getCampaign(campaignId, signal);

  return { queryKey, queryFn, enabled: !!campaignId, ...queryOptions };
};

export type GetCampaignQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCampaignHook>>>>;
export type GetCampaignQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get campaign
 */
export const useGetCampaign = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCampaignHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  campaignId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCampaignHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCampaignQueryOptions(campaignId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update campaign
 */
export const useUpdateCampaignHook = () => {
  const updateCampaign = useCustomInstance<CampaignDetailsSchema>();

  return (campaignId: string, updateCampaignSchema: UpdateCampaignSchema) => {
    return updateCampaign({
      url: `/marketing/campaigns/${campaignId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateCampaignSchema,
    });
  };
};

export const useUpdateCampaignMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateCampaignHook>>>,
    TError,
    { campaignId: string; data: UpdateCampaignSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateCampaignHook>>>,
  TError,
  { campaignId: string; data: UpdateCampaignSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateCampaign = useUpdateCampaignHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateCampaignHook>>>,
    { campaignId: string; data: UpdateCampaignSchema }
  > = props => {
    const { campaignId, data } = props ?? {};

    return updateCampaign(campaignId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCampaignMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateCampaignHook>>>>;
export type UpdateCampaignMutationBody = UpdateCampaignSchema;
export type UpdateCampaignMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Update campaign
 */
export const useUpdateCampaign = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateCampaignHook>>>,
    TError,
    { campaignId: string; data: UpdateCampaignSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateCampaignMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get individual action
 */
export const useGetIndividualActionHook = () => {
  const getIndividualAction = useCustomInstance<IndividualActionSchema>();

  return (campaignId: string, actionId: string, signal?: AbortSignal) => {
    return getIndividualAction({
      url: `/marketing/campaigns/${campaignId}/actions/individual/${actionId}`,
      method: 'get',
      signal,
    });
  };
};

export const getGetIndividualActionQueryKey = (campaignId: string, actionId: string) =>
  [`/marketing/campaigns/${campaignId}/actions/individual/${actionId}`] as const;

export const useGetIndividualActionQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetIndividualActionHook>>>,
  TError = CreateIndividualActionBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  campaignId: string,
  actionId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIndividualActionHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIndividualActionHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIndividualActionQueryKey(campaignId, actionId);

  const getIndividualAction = useGetIndividualActionHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetIndividualActionHook>>>> = ({ signal }) =>
    getIndividualAction(campaignId, actionId, signal);

  return { queryKey, queryFn, enabled: !!(campaignId && actionId), ...queryOptions };
};

export type GetIndividualActionQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetIndividualActionHook>>>
>;
export type GetIndividualActionQueryError =
  | CreateIndividualActionBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get individual action
 */
export const useGetIndividualAction = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetIndividualActionHook>>>,
  TError = CreateIndividualActionBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  campaignId: string,
  actionId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIndividualActionHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetIndividualActionQueryOptions(campaignId, actionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Set individual action
 */
export const useUpdateIndividualActionHook = () => {
  const updateIndividualAction = useCustomInstance<IndividualActionSchema>();

  return (campaignId: string, actionId: string, updateIndividualActionSchema: UpdateIndividualActionSchema) => {
    return updateIndividualAction({
      url: `/marketing/campaigns/${campaignId}/actions/individual/${actionId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateIndividualActionSchema,
    });
  };
};

export const useUpdateIndividualActionMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateIndividualActionHook>>>,
    TError,
    { campaignId: string; actionId: string; data: UpdateIndividualActionSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateIndividualActionHook>>>,
  TError,
  { campaignId: string; actionId: string; data: UpdateIndividualActionSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateIndividualAction = useUpdateIndividualActionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateIndividualActionHook>>>,
    { campaignId: string; actionId: string; data: UpdateIndividualActionSchema }
  > = props => {
    const { campaignId, actionId, data } = props ?? {};

    return updateIndividualAction(campaignId, actionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateIndividualActionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateIndividualActionHook>>>
>;
export type UpdateIndividualActionMutationBody = UpdateIndividualActionSchema;
export type UpdateIndividualActionMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Set individual action
 */
export const useUpdateIndividualAction = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateIndividualActionHook>>>,
    TError,
    { campaignId: string; actionId: string; data: UpdateIndividualActionSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateIndividualActionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Trigger individual action
 */
export const useTriggerIndividualActionHook = () => {
  const triggerIndividualAction = useCustomInstance<IndividualActionSchema>();

  return (campaignId: string, actionId: string, triggerIndividualActionSchema: TriggerIndividualActionSchema) => {
    return triggerIndividualAction({
      url: `/marketing/campaigns/${campaignId}/actions/individual/${actionId}/process`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: triggerIndividualActionSchema,
    });
  };
};

export const useTriggerIndividualActionMutationOptions = <
  TError = TriggerIndividualActionBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTriggerIndividualActionHook>>>,
    TError,
    { campaignId: string; actionId: string; data: TriggerIndividualActionSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTriggerIndividualActionHook>>>,
  TError,
  { campaignId: string; actionId: string; data: TriggerIndividualActionSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const triggerIndividualAction = useTriggerIndividualActionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTriggerIndividualActionHook>>>,
    { campaignId: string; actionId: string; data: TriggerIndividualActionSchema }
  > = props => {
    const { campaignId, actionId, data } = props ?? {};

    return triggerIndividualAction(campaignId, actionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type TriggerIndividualActionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTriggerIndividualActionHook>>>
>;
export type TriggerIndividualActionMutationBody = TriggerIndividualActionSchema;
export type TriggerIndividualActionMutationError =
  | TriggerIndividualActionBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Trigger individual action
 */
export const useTriggerIndividualAction = <
  TError = TriggerIndividualActionBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTriggerIndividualActionHook>>>,
    TError,
    { campaignId: string; actionId: string; data: TriggerIndividualActionSchema },
    TContext
  >;
}) => {
  const mutationOptions = useTriggerIndividualActionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Trigger an individual action for all active users
 */
export const useTriggerIndividualActionAllAccountsHook = () => {
  const triggerIndividualActionAllAccounts = useCustomInstance<IndividualActionSchema>();

  return (campaignId: string, actionId: string) => {
    return triggerIndividualActionAllAccounts({
      url: `/marketing/campaigns/${campaignId}/actions/individual/${actionId}/processall`,
      method: 'post',
    });
  };
};

export const useTriggerIndividualActionAllAccountsMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTriggerIndividualActionAllAccountsHook>>>,
    TError,
    { campaignId: string; actionId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTriggerIndividualActionAllAccountsHook>>>,
  TError,
  { campaignId: string; actionId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const triggerIndividualActionAllAccounts = useTriggerIndividualActionAllAccountsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTriggerIndividualActionAllAccountsHook>>>,
    { campaignId: string; actionId: string }
  > = props => {
    const { campaignId, actionId } = props ?? {};

    return triggerIndividualActionAllAccounts(campaignId, actionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type TriggerIndividualActionAllAccountsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTriggerIndividualActionAllAccountsHook>>>
>;

export type TriggerIndividualActionAllAccountsMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Trigger an individual action for all active users
 */
export const useTriggerIndividualActionAllAccounts = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTriggerIndividualActionAllAccountsHook>>>,
    TError,
    { campaignId: string; actionId: string },
    TContext
  >;
}) => {
  const mutationOptions = useTriggerIndividualActionAllAccountsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Trigger an individual action for all closed users
 */
export const useTriggerIndividualActionAllClosedAccountsHook = () => {
  const triggerIndividualActionAllClosedAccounts = useCustomInstance<IndividualActionSchema>();

  return (campaignId: string, actionId: string) => {
    return triggerIndividualActionAllClosedAccounts({
      url: `/marketing/campaigns/${campaignId}/actions/individual/${actionId}/processallclosed`,
      method: 'post',
    });
  };
};

export const useTriggerIndividualActionAllClosedAccountsMutationOptions = <
  TError = ErrorSchema | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTriggerIndividualActionAllClosedAccountsHook>>>,
    TError,
    { campaignId: string; actionId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTriggerIndividualActionAllClosedAccountsHook>>>,
  TError,
  { campaignId: string; actionId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const triggerIndividualActionAllClosedAccounts = useTriggerIndividualActionAllClosedAccountsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTriggerIndividualActionAllClosedAccountsHook>>>,
    { campaignId: string; actionId: string }
  > = props => {
    const { campaignId, actionId } = props ?? {};

    return triggerIndividualActionAllClosedAccounts(campaignId, actionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type TriggerIndividualActionAllClosedAccountsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTriggerIndividualActionAllClosedAccountsHook>>>
>;

export type TriggerIndividualActionAllClosedAccountsMutationError =
  | ErrorSchema
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Trigger an individual action for all closed users
 */
export const useTriggerIndividualActionAllClosedAccounts = <
  TError = ErrorSchema | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTriggerIndividualActionAllClosedAccountsHook>>>,
    TError,
    { campaignId: string; actionId: string },
    TContext
  >;
}) => {
  const mutationOptions = useTriggerIndividualActionAllClosedAccountsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Set individual action
 */
export const useAddIndividualActionHook = () => {
  const addIndividualAction = useCustomInstance<IndividualActionSchema>();

  return (campaignId: string, updateIndividualActionSchema: UpdateIndividualActionSchema) => {
    return addIndividualAction({
      url: `/marketing/campaigns/${campaignId}/actions/individual`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateIndividualActionSchema,
    });
  };
};

export const useAddIndividualActionMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddIndividualActionHook>>>,
    TError,
    { campaignId: string; data: UpdateIndividualActionSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddIndividualActionHook>>>,
  TError,
  { campaignId: string; data: UpdateIndividualActionSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addIndividualAction = useAddIndividualActionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddIndividualActionHook>>>,
    { campaignId: string; data: UpdateIndividualActionSchema }
  > = props => {
    const { campaignId, data } = props ?? {};

    return addIndividualAction(campaignId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddIndividualActionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddIndividualActionHook>>>
>;
export type AddIndividualActionMutationBody = UpdateIndividualActionSchema;
export type AddIndividualActionMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Set individual action
 */
export const useAddIndividualAction = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddIndividualActionHook>>>,
    TError,
    { campaignId: string; data: UpdateIndividualActionSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddIndividualActionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the campaign actions
 */
export const useGetCampaignActionsHook = () => {
  const getCampaignActions = useCustomInstance<CampaignActionsSchema>();

  return (signal?: AbortSignal) => {
    return getCampaignActions({ url: `/marketing/campaigns/actions`, method: 'get', signal });
  };
};

export const getGetCampaignActionsQueryKey = () => [`/marketing/campaigns/actions`] as const;

export const useGetCampaignActionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCampaignActionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCampaignActionsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCampaignActionsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCampaignActionsQueryKey();

  const getCampaignActions = useGetCampaignActionsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCampaignActionsHook>>>> = ({ signal }) =>
    getCampaignActions(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetCampaignActionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCampaignActionsHook>>>
>;
export type GetCampaignActionsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the campaign actions
 */
export const useGetCampaignActions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCampaignActionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCampaignActionsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCampaignActionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the list of template types
 */
export const useGetTemplateTypesHook = () => {
  const getTemplateTypes = useCustomInstance<TemplateTypesSchema>();

  return (signal?: AbortSignal) => {
    return getTemplateTypes({ url: `/marketing/campaigns/templatetypes`, method: 'get', signal });
  };
};

export const getGetTemplateTypesQueryKey = () => [`/marketing/campaigns/templatetypes`] as const;

export const useGetTemplateTypesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTemplateTypesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTemplateTypesHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTemplateTypesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTemplateTypesQueryKey();

  const getTemplateTypes = useGetTemplateTypesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTemplateTypesHook>>>> = ({ signal }) =>
    getTemplateTypes(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetTemplateTypesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTemplateTypesHook>>>>;
export type GetTemplateTypesQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get the list of template types
 */
export const useGetTemplateTypes = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTemplateTypesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTemplateTypesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTemplateTypesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get default campaign templates
 */
export const useGetDefaultTemplatesHook = () => {
  const getDefaultTemplates = useCustomInstance<CampaignTemplatesSchema>();

  return (signal?: AbortSignal) => {
    return getDefaultTemplates({ url: `/marketing/campaigns/templates`, method: 'get', signal });
  };
};

export const getGetDefaultTemplatesQueryKey = () => [`/marketing/campaigns/templates`] as const;

export const useGetDefaultTemplatesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDefaultTemplatesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDefaultTemplatesHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDefaultTemplatesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDefaultTemplatesQueryKey();

  const getDefaultTemplates = useGetDefaultTemplatesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDefaultTemplatesHook>>>> = ({ signal }) =>
    getDefaultTemplates(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDefaultTemplatesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetDefaultTemplatesHook>>>
>;
export type GetDefaultTemplatesQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get default campaign templates
 */
export const useGetDefaultTemplates = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDefaultTemplatesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDefaultTemplatesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDefaultTemplatesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Set default template details
 */
export const useAddDefaultTemplateHook = () => {
  const addDefaultTemplate = useCustomInstance<CampaignTemplateDetailsSchema>();

  return (campaignTemplateUpdateSchema: CampaignTemplateUpdateSchema) => {
    return addDefaultTemplate({
      url: `/marketing/campaigns/templates`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: campaignTemplateUpdateSchema,
    });
  };
};

export const useAddDefaultTemplateMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddDefaultTemplateHook>>>,
    TError,
    { data: CampaignTemplateUpdateSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddDefaultTemplateHook>>>,
  TError,
  { data: CampaignTemplateUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addDefaultTemplate = useAddDefaultTemplateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddDefaultTemplateHook>>>,
    { data: CampaignTemplateUpdateSchema }
  > = props => {
    const { data } = props ?? {};

    return addDefaultTemplate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddDefaultTemplateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddDefaultTemplateHook>>>
>;
export type AddDefaultTemplateMutationBody = CampaignTemplateUpdateSchema;
export type AddDefaultTemplateMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Set default template details
 */
export const useAddDefaultTemplate = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddDefaultTemplateHook>>>,
    TError,
    { data: CampaignTemplateUpdateSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddDefaultTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get default template details
 */
export const useGetDefaultCampaignTemplateHook = () => {
  const getDefaultCampaignTemplate = useCustomInstance<CampaignTemplateDetailsSchema>();

  return (templateType: string, signal?: AbortSignal) => {
    return getDefaultCampaignTemplate({ url: `/marketing/campaigns/templates/${templateType}`, method: 'get', signal });
  };
};

export const getGetDefaultCampaignTemplateQueryKey = (templateType: string) =>
  [`/marketing/campaigns/templates/${templateType}`] as const;

export const useGetDefaultCampaignTemplateQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDefaultCampaignTemplateHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  templateType: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDefaultCampaignTemplateHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDefaultCampaignTemplateHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDefaultCampaignTemplateQueryKey(templateType);

  const getDefaultCampaignTemplate = useGetDefaultCampaignTemplateHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDefaultCampaignTemplateHook>>>> = ({
    signal,
  }) => getDefaultCampaignTemplate(templateType, signal);

  return { queryKey, queryFn, enabled: !!templateType, ...queryOptions };
};

export type GetDefaultCampaignTemplateQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetDefaultCampaignTemplateHook>>>
>;
export type GetDefaultCampaignTemplateQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get default template details
 */
export const useGetDefaultCampaignTemplate = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDefaultCampaignTemplateHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  templateType: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDefaultCampaignTemplateHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDefaultCampaignTemplateQueryOptions(templateType, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Set default template details
 */
export const useUpdateDefaultCampaignTemplateHook = () => {
  const updateDefaultCampaignTemplate = useCustomInstance<CampaignTemplateDetailsSchema>();

  return (templateType: string, campaignTemplateUpdateSchema: CampaignTemplateUpdateSchema) => {
    return updateDefaultCampaignTemplate({
      url: `/marketing/campaigns/templates/${templateType}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: campaignTemplateUpdateSchema,
    });
  };
};

export const useUpdateDefaultCampaignTemplateMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateDefaultCampaignTemplateHook>>>,
    TError,
    { templateType: string; data: CampaignTemplateUpdateSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateDefaultCampaignTemplateHook>>>,
  TError,
  { templateType: string; data: CampaignTemplateUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateDefaultCampaignTemplate = useUpdateDefaultCampaignTemplateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateDefaultCampaignTemplateHook>>>,
    { templateType: string; data: CampaignTemplateUpdateSchema }
  > = props => {
    const { templateType, data } = props ?? {};

    return updateDefaultCampaignTemplate(templateType, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateDefaultCampaignTemplateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateDefaultCampaignTemplateHook>>>
>;
export type UpdateDefaultCampaignTemplateMutationBody = CampaignTemplateUpdateSchema;
export type UpdateDefaultCampaignTemplateMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Set default template details
 */
export const useUpdateDefaultCampaignTemplate = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateDefaultCampaignTemplateHook>>>,
    TError,
    { templateType: string; data: CampaignTemplateUpdateSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateDefaultCampaignTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deletes the default template
 */
export const useDeleteDefaultTemplateHook = () => {
  const deleteDefaultTemplate = useCustomInstance<void>();

  return (templateType: string) => {
    return deleteDefaultTemplate({ url: `/marketing/campaigns/templates/${templateType}`, method: 'delete' });
  };
};

export const useDeleteDefaultTemplateMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteDefaultTemplateHook>>>,
    TError,
    { templateType: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteDefaultTemplateHook>>>,
  TError,
  { templateType: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteDefaultTemplate = useDeleteDefaultTemplateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteDefaultTemplateHook>>>,
    { templateType: string }
  > = props => {
    const { templateType } = props ?? {};

    return deleteDefaultTemplate(templateType);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteDefaultTemplateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteDefaultTemplateHook>>>
>;

export type DeleteDefaultTemplateMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Deletes the default template
 */
export const useDeleteDefaultTemplate = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteDefaultTemplateHook>>>,
    TError,
    { templateType: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteDefaultTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Set campaign template details
 */
export const useAddCampaignTemplateHook = () => {
  const addCampaignTemplate = useCustomInstance<CampaignTemplateDetailsSchema>();

  return (campaignId: string, campaignTemplateUpdateSchema: CampaignTemplateUpdateSchema) => {
    return addCampaignTemplate({
      url: `/marketing/campaigns/${campaignId}/templates`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: campaignTemplateUpdateSchema,
    });
  };
};

export const useAddCampaignTemplateMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddCampaignTemplateHook>>>,
    TError,
    { campaignId: string; data: CampaignTemplateUpdateSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddCampaignTemplateHook>>>,
  TError,
  { campaignId: string; data: CampaignTemplateUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addCampaignTemplate = useAddCampaignTemplateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddCampaignTemplateHook>>>,
    { campaignId: string; data: CampaignTemplateUpdateSchema }
  > = props => {
    const { campaignId, data } = props ?? {};

    return addCampaignTemplate(campaignId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddCampaignTemplateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddCampaignTemplateHook>>>
>;
export type AddCampaignTemplateMutationBody = CampaignTemplateUpdateSchema;
export type AddCampaignTemplateMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Set campaign template details
 */
export const useAddCampaignTemplate = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddCampaignTemplateHook>>>,
    TError,
    { campaignId: string; data: CampaignTemplateUpdateSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddCampaignTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get campaign template details
 */
export const useGetCampaignTemplateHook = () => {
  const getCampaignTemplate = useCustomInstance<CampaignTemplateDetailsSchema>();

  return (campaignId: string, templateType: string, signal?: AbortSignal) => {
    return getCampaignTemplate({
      url: `/marketing/campaigns/${campaignId}/templates/${templateType}`,
      method: 'get',
      signal,
    });
  };
};

export const getGetCampaignTemplateQueryKey = (campaignId: string, templateType: string) =>
  [`/marketing/campaigns/${campaignId}/templates/${templateType}`] as const;

export const useGetCampaignTemplateQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCampaignTemplateHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  campaignId: string,
  templateType: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCampaignTemplateHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCampaignTemplateHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCampaignTemplateQueryKey(campaignId, templateType);

  const getCampaignTemplate = useGetCampaignTemplateHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCampaignTemplateHook>>>> = ({ signal }) =>
    getCampaignTemplate(campaignId, templateType, signal);

  return { queryKey, queryFn, enabled: !!(campaignId && templateType), ...queryOptions };
};

export type GetCampaignTemplateQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCampaignTemplateHook>>>
>;
export type GetCampaignTemplateQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get campaign template details
 */
export const useGetCampaignTemplate = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCampaignTemplateHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  campaignId: string,
  templateType: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCampaignTemplateHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCampaignTemplateQueryOptions(campaignId, templateType, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Set campaign template details
 */
export const useUpdateCampaignTemplateHook = () => {
  const updateCampaignTemplate = useCustomInstance<CampaignTemplateDetailsSchema>();

  return (campaignId: string, templateType: string, campaignTemplateUpdateSchema: CampaignTemplateUpdateSchema) => {
    return updateCampaignTemplate({
      url: `/marketing/campaigns/${campaignId}/templates/${templateType}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: campaignTemplateUpdateSchema,
    });
  };
};

export const useUpdateCampaignTemplateMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateCampaignTemplateHook>>>,
    TError,
    { campaignId: string; templateType: string; data: CampaignTemplateUpdateSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateCampaignTemplateHook>>>,
  TError,
  { campaignId: string; templateType: string; data: CampaignTemplateUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateCampaignTemplate = useUpdateCampaignTemplateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateCampaignTemplateHook>>>,
    { campaignId: string; templateType: string; data: CampaignTemplateUpdateSchema }
  > = props => {
    const { campaignId, templateType, data } = props ?? {};

    return updateCampaignTemplate(campaignId, templateType, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCampaignTemplateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateCampaignTemplateHook>>>
>;
export type UpdateCampaignTemplateMutationBody = CampaignTemplateUpdateSchema;
export type UpdateCampaignTemplateMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Set campaign template details
 */
export const useUpdateCampaignTemplate = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateCampaignTemplateHook>>>,
    TError,
    { campaignId: string; templateType: string; data: CampaignTemplateUpdateSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateCampaignTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deletes the action template
 */
export const useDeleteActionTemplateHook = () => {
  const deleteActionTemplate = useCustomInstance<void>();

  return (campaignId: string, templateType: string) => {
    return deleteActionTemplate({
      url: `/marketing/campaigns/${campaignId}/templates/${templateType}`,
      method: 'delete',
    });
  };
};

export const useDeleteActionTemplateMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionTemplateHook>>>,
    TError,
    { campaignId: string; templateType: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionTemplateHook>>>,
  TError,
  { campaignId: string; templateType: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteActionTemplate = useDeleteActionTemplateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionTemplateHook>>>,
    { campaignId: string; templateType: string }
  > = props => {
    const { campaignId, templateType } = props ?? {};

    return deleteActionTemplate(campaignId, templateType);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteActionTemplateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteActionTemplateHook>>>
>;

export type DeleteActionTemplateMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Deletes the action template
 */
export const useDeleteActionTemplate = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteActionTemplateHook>>>,
    TError,
    { campaignId: string; templateType: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteActionTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};
