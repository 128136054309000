import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ELanguagesSchema, TranslationsSchemaItem } from '@greenisland-api';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, capitalize, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { SelectInput } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

const sortLanguages = (a: ELanguagesSchema, b: ELanguagesSchema) => {
  if (a === ELanguagesSchema.EN) return -1;
  if (b === ELanguagesSchema.EN) return 1;
  if (a === ELanguagesSchema.NL) return -1;
  if (b === ELanguagesSchema.NL) return 1;
  if (a === ELanguagesSchema.FR) return -1;
  if (b === ELanguagesSchema.FR) return 1;
  if (a === ELanguagesSchema.DE) return -1;
  if (b === ELanguagesSchema.DE) return 1;
  return a.localeCompare(b);
};

interface Props {
  name: string;
  mainTitle: string;
  contentFieldTitle?: string;
}

const LanguageTranslationSelect = ({ name, mainTitle, contentFieldTitle = 'description' }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { control, errors, setError, clearErrors } = useFormContext();
  const { fields, append, remove } = useFieldArray<TranslationsSchemaItem>({ control, name });

  const appendDataHandler = useCallback(() => {
    const nextLanguage = Object.values(ELanguagesSchema)
      .sort(sortLanguages)
      .find(language => !fields.find(field => field.language === language));
    if (!nextLanguage) return;

    return append({
      language: nextLanguage,
      content: '',
    });
  }, [append, fields]);

  const isEmptyDescriptionFieldHandler = useCallback(
    (id: string, value: string) => {
      if (!value.trim()) {
        setError(id, {
          type: 'custom',
          message: t('required'),
        });

        return false;
      }

      clearErrors(id);
      return true;
    },
    [clearErrors, setError, t]
  );

  return (
    <>
      <Typography fontWeight={500} variant="body1" fontSize="large" mb={1}>
        {capitalize(t(mainTitle))}
      </Typography>
      {fields.map((_field, index) => (
        <Box
          key={_field?.id}
          display="flex"
          justifyContent="space-between"
          gap={2}
          sx={{
            mb: 2,
            [theme.breakpoints.up('xs')]: {
              flexDirection: 'column',
            },
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
            },
          }}
        >
          <Box
            display="flex"
            sx={{
              [theme.breakpoints.up('xs')]: {
                width: '100%',
                justifyContent: 'space-between',
              },
              [theme.breakpoints.up('md')]: {
                width: '20%',
              },
            }}
          >
            {index !== 0 && (
              <IconButton
                size="small"
                onClick={() => remove(index)}
                color="error"
                sx={{ mr: 1, height: 'fit-content' }}
              >
                <DeleteIcon />
              </IconButton>
            )}
            <SelectInput
              name={`${name}.${index}.language`}
              label={t('language')}
              rules={{ required: t('fieldIsRequired') }}
              options={Object.keys(ELanguagesSchema).map(key => ({ value: key, label: t(key) }))}
              defaultValue={_field.language}
              required={true}
              size="small"
            />
          </Box>
          <Input
            size="small"
            label={t(contentFieldTitle)}
            required
            name={`${name}.${index}.content`}
            rules={{
              required: t('fieldIsRequired'),
              setValueAs: value => value?.trim(),
              validate: (value: string) => _field?.id && isEmptyDescriptionFieldHandler(_field.id, value),
            }}
            defaultValue={_field.content}
            sx={{
              [theme.breakpoints.up('xs')]: {
                width: '100%',
              },
              [theme.breakpoints.up('md')]: {
                width: '80%',
              },
            }}
            error={_field?.id && errors[_field?.id] && errors[_field.id]}
            helperText={_field?.id && errors[_field?.id] ? errors[_field.id]?.message : ''}
          />
        </Box>
      ))}
      {fields.length < Object.keys(ELanguagesSchema).length ? (
        <Box>
          <Button size="small" variant="outlined" onClick={appendDataHandler} sx={{ mb: 2 }}>
            {t('common.addTranslations')}
          </Button>
        </Box>
      ) : null}
    </>
  );
};

export default LanguageTranslationSelect;
