/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * The channel by which the intervention communication will be done.
 */
export type EInterventionChannelTypeSchema =
  (typeof EInterventionChannelTypeSchema)[keyof typeof EInterventionChannelTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EInterventionChannelTypeSchema = {
  Phone: 'Phone',
  Email: 'Email',
  PopUp: 'PopUp',
  Other: 'Other',
  None: 'None',
} as const;
