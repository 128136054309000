import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CountryListSchema } from '@greenisland-api';
import { Autocomplete, TextField } from '@mui/material';

interface Props {
  countries: CountryListSchema;
}

const NationalityField = ({ countries }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      name="nationality"
      control={control}
      render={({ value, onChange }) => {
        return (
          <Autocomplete
            fullWidth
            renderInput={({ size, ...params }) => <TextField size="small" {...params} />}
            options={countries}
            getOptionLabel={(option: any) => (option.countryName ? option.countryName : '')}
            value={value}
            onChange={(e, value) => onChange(value)}
          />
        );
      }}
    />
  );
};

export default NationalityField;
