import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  getGetLoyaltyBoostersQueryKey,
  useAddLoyaltyBooster,
  useGetGameProviders,
  useGetGamesByQueryFilters,
} from '@greenisland-api';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { addDays, addHours, getTime } from 'date-fns';
import { useSnackbar } from 'notistack';

import AutocompleteField from '@greenisland-common/components/molecules/AutocompleteField';
import Input from '@greenisland-common/components/molecules/Input';

interface Props {
  open: boolean;
  onClose: () => void;
}

interface Form {
  startDate: number;
  endDate: number;
  factor: number;
  gameProvider?: {
    id: string;
    label: string;
  } | null;
  game?: {
    id: string;
    label: string;
  } | null;
}

const LoyaltyBoostersDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  // We want this to update on render to get current time
  const DEFAULT_VALUES = {
    startDate: getTime(new Date()),
    endDate: getTime(addDays(new Date(), 1)),
    factor: 100,
  };

  const { handleSubmit, errors, control, watch } = useForm<Form>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });
  const mutation = useAddLoyaltyBooster({
    mutation: {
      onSuccess: async () => {
        enqueueSnackbar(t('marketing.loyaltyBooster.addSuccess'), {
          variant: 'success',
        });
        queryClient.invalidateQueries(getGetLoyaltyBoostersQueryKey());
        onClose();
      },
    },
  });
  const gameProviders = useGetGameProviders();
  const gameProvidersOptions = useMemo(
    () => gameProviders.data?.map(provider => ({ id: provider.providerId, label: provider.providerName })) ?? [],
    [gameProviders.data]
  );

  const watchGameProvider = watch('gameProvider');

  const games = useGetGamesByQueryFilters(
    watchGameProvider
      ? {
          providerId: parseInt(watchGameProvider.id, 10),
          resultsPerPage: 1000,
        }
      : undefined,
    {
      query: { enabled: !!watchGameProvider?.id },
    }
  );
  const gamesOptions = useMemo(
    () => games.data?.results?.map(game => ({ id: game.gameId ?? '', label: game.gameName })) ?? [],
    [games.data]
  );

  const onSubmit = handleSubmit(data => {
    if (data.gameProvider && data.game) {
      mutation.mutate({
        providerId: data.gameProvider.id,
        gameId: data.game.id,
        data: {
          startDate: data.startDate / 1000,
          endDate: data.endDate / 1000,
          factor: data.factor,
        },
      });
    }
  });

  const watchStartDate = watch('startDate');

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={onSubmit}>
        <DialogTitle>{t('marketing.loyaltyBooster.add')}</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <Controller
              render={({ onChange, ref, value }) => (
                <DateTimePicker
                  label={t('startDate')}
                  value={value}
                  onChange={newDate => onChange(newDate?.getTime())}
                  renderInput={params => <TextField {...params} variant="standard" />}
                  inputRef={ref}
                  disablePast
                  minDate={new Date()}
                  inputFormat="dd/MM/yyyy HH:mm"
                  mask="__/__/____ __:__"
                />
              )}
              control={control}
              name={'startDate'}
              rules={{ required: true }}
            />
            <Controller
              render={({ onChange, ref, value }) => (
                <DateTimePicker
                  label={t('endDate')}
                  value={value}
                  onChange={newDate => onChange(newDate)}
                  renderInput={params => <TextField {...params} variant="standard" />}
                  inputRef={ref}
                  minDateTime={getTime(addHours(watchStartDate, 1))}
                  inputFormat="dd/MM/yyyy HH:mm"
                  mask="__/__/____ __:__"
                />
              )}
              control={control}
              name={'endDate'}
              rules={{ required: true }}
            />
            <Input
              control={control}
              name="factor"
              label={`${t('factor')} (%)`}
              rules={{
                required: {
                  value: true,
                  message: t('marketing.loyaltyBooster.form.factor.required'),
                },
                min: 1,
              }}
              error={!!errors.factor}
              helpText={errors.factor?.message}
              type={'number'}
            />
            <AutocompleteField
              label={t('marketing.loyaltyBooster.gameProvider')}
              loading={gameProviders.isLoading}
              options={gameProvidersOptions}
              control={control}
              name={'gameProvider'}
              rules={{ required: true }}
            />
            {watchGameProvider ? (
              <AutocompleteField
                label={t('marketing.loyaltyBooster.game')}
                loading={games.isLoading}
                options={gamesOptions}
                control={control}
                name={'game'}
                rules={{ required: true }}
              />
            ) : null}

            {mutation.isError ? <Alert severity="error">{t('marketing.loyaltyBooster.addError')}</Alert> : null}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button variant="text" onClick={onClose} color="secondary">
            {t('cancel')}
          </Button>
          <Button variant="contained" type="submit" disabled={mutation.isLoading}>
            {t('save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LoyaltyBoostersDialog;
