/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type { MutationFunction, UseMutationOptions } from 'react-query';
import { useMutation } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  AddBonusBlacklistRequestSchema,
  AddMarketingBlacklistRequestSchema,
  BadRequestResponse,
  BonusBlacklistEntrySchema,
  DefaultResponse,
  MarketingBlacklistEntrySchema,
  NotFoundResponse,
  PreconditionFailedResponse,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Add user to bonus blacklist
 * @summary Add user to bonus blacklist
 */
export const useAddToBonusBlacklistHook = () => {
  const addToBonusBlacklist = useCustomInstance<BonusBlacklistEntrySchema>();

  return (userId: number, addBonusBlacklistRequestSchema: AddBonusBlacklistRequestSchema) => {
    return addToBonusBlacklist({
      url: `/blacklist/bonus/${userId}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: addBonusBlacklistRequestSchema,
    });
  };
};

export const useAddToBonusBlacklistMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddToBonusBlacklistHook>>>,
    TError,
    { userId: number; data: AddBonusBlacklistRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddToBonusBlacklistHook>>>,
  TError,
  { userId: number; data: AddBonusBlacklistRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addToBonusBlacklist = useAddToBonusBlacklistHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddToBonusBlacklistHook>>>,
    { userId: number; data: AddBonusBlacklistRequestSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return addToBonusBlacklist(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddToBonusBlacklistMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddToBonusBlacklistHook>>>
>;
export type AddToBonusBlacklistMutationBody = AddBonusBlacklistRequestSchema;
export type AddToBonusBlacklistMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @summary Add user to bonus blacklist
 */
export const useAddToBonusBlacklist = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddToBonusBlacklistHook>>>,
    TError,
    { userId: number; data: AddBonusBlacklistRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddToBonusBlacklistMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Remove user to bonus blacklist
 * @summary Remove user from bonus blacklist
 */
export const useRemoveFromBonusBlacklistHook = () => {
  const removeFromBonusBlacklist = useCustomInstance<void>();

  return (userId: number) => {
    return removeFromBonusBlacklist({ url: `/blacklist/bonus/${userId}`, method: 'delete' });
  };
};

export const useRemoveFromBonusBlacklistMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveFromBonusBlacklistHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRemoveFromBonusBlacklistHook>>>,
  TError,
  { userId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const removeFromBonusBlacklist = useRemoveFromBonusBlacklistHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRemoveFromBonusBlacklistHook>>>,
    { userId: number }
  > = props => {
    const { userId } = props ?? {};

    return removeFromBonusBlacklist(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveFromBonusBlacklistMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRemoveFromBonusBlacklistHook>>>
>;

export type RemoveFromBonusBlacklistMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @summary Remove user from bonus blacklist
 */
export const useRemoveFromBonusBlacklist = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveFromBonusBlacklistHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}) => {
  const mutationOptions = useRemoveFromBonusBlacklistMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Add user to marketing blacklist
 * @summary Add user to marketing blacklist
 */
export const useAddToMarketingBlacklistHook = () => {
  const addToMarketingBlacklist = useCustomInstance<MarketingBlacklistEntrySchema>();

  return (userId: number, addMarketingBlacklistRequestSchema: AddMarketingBlacklistRequestSchema) => {
    return addToMarketingBlacklist({
      url: `/blacklist/marketing/${userId}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: addMarketingBlacklistRequestSchema,
    });
  };
};

export const useAddToMarketingBlacklistMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddToMarketingBlacklistHook>>>,
    TError,
    { userId: number; data: AddMarketingBlacklistRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddToMarketingBlacklistHook>>>,
  TError,
  { userId: number; data: AddMarketingBlacklistRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addToMarketingBlacklist = useAddToMarketingBlacklistHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddToMarketingBlacklistHook>>>,
    { userId: number; data: AddMarketingBlacklistRequestSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return addToMarketingBlacklist(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddToMarketingBlacklistMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddToMarketingBlacklistHook>>>
>;
export type AddToMarketingBlacklistMutationBody = AddMarketingBlacklistRequestSchema;
export type AddToMarketingBlacklistMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Add user to marketing blacklist
 */
export const useAddToMarketingBlacklist = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddToMarketingBlacklistHook>>>,
    TError,
    { userId: number; data: AddMarketingBlacklistRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddToMarketingBlacklistMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Remove user to marketing blacklist
 * @summary Remove user from marketing blacklist
 */
export const useRemoveFromMarketingBlacklistHook = () => {
  const removeFromMarketingBlacklist = useCustomInstance<void>();

  return (userId: number) => {
    return removeFromMarketingBlacklist({ url: `/blacklist/marketing/${userId}`, method: 'delete' });
  };
};

export const useRemoveFromMarketingBlacklistMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveFromMarketingBlacklistHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRemoveFromMarketingBlacklistHook>>>,
  TError,
  { userId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const removeFromMarketingBlacklist = useRemoveFromMarketingBlacklistHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRemoveFromMarketingBlacklistHook>>>,
    { userId: number }
  > = props => {
    const { userId } = props ?? {};

    return removeFromMarketingBlacklist(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveFromMarketingBlacklistMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRemoveFromMarketingBlacklistHook>>>
>;

export type RemoveFromMarketingBlacklistMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Remove user from marketing blacklist
 */
export const useRemoveFromMarketingBlacklist = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveFromMarketingBlacklistHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}) => {
  const mutationOptions = useRemoveFromMarketingBlacklistMutationOptions(options);

  return useMutation(mutationOptions);
};
