import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  type TournamentConfiguration,
  UpdateInstantiatedTournamentConfiguration,
  useAddTournamentConfiguration,
  useGetTournamentConfiguration,
  usePutInstantiatedTournamentConfiguration,
} from '@greenisland-store/gameServer';
import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {
  generateTournamentConfigurationCreateFormPayload,
  generateTournamentConfigurationFormDefaultValues,
} from './helpers/tournamentConfigFormHelpers';
import { useTournamentConfigurationFormHandlers } from './helpers/tournamentConfigFormHooks';
import {
  CreateTournamentConfigurationFormData,
  TournamentConfigurationMode,
} from './helpers/tournamentConfigFormInterfaces';
import TournamentConfigBuyInSettings from './TournamentConfigFormSections/TournamentConfigBuyInSettings';
import TournamentConfigEditPopoverContent from './TournamentConfigFormSections/TournamentConfigEditPopoverContent';
import TournamentConfigExtraSettings from './TournamentConfigFormSections/TournamentConfigExtraSettings';
import TournamentConfigGamesSettings from './TournamentConfigFormSections/TournamentConfigGamesSettings';
import TournamentConfigGeneralSettings from './TournamentConfigFormSections/TournamentConfigGeneralSettings';
import TournamentConfigMonetaryPrizePoolSettings from './TournamentConfigFormSections/TournamentConfigMonetaryPrizePoolSettings';
import TournamentConfigNonMonetaryPrizePoolSettings from './TournamentConfigFormSections/TournamentConfigNonMonetaryPrizePoolSettings';
import TournamentConfigScheduleSettings from './TournamentConfigFormSections/TournamentConfigScheduleSettings';

const TournamentConfigForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { mode = '', configurationId = '' } = useParams();

  const chosenMode = (mode && configurationId ? mode : TournamentConfigurationMode.ADD) as TournamentConfigurationMode;

  const [isValidForm, setIsValidForm] = useState(true);

  const methods = useForm({
    shouldFocusError: true,
    mode: 'onChange',
  });

  const { handleSubmit, reset } = methods;

  const { onSuccess, onError } = useTournamentConfigurationFormHandlers(reset);

  const { data: tournamentConfiguration, isLoading: isTournamentConfigurationLoading } = useGetTournamentConfiguration(
    Number(configurationId),
    {
      query: {
        enabled: !!configurationId,
        onSuccess: data => {
          const defaultValues = generateTournamentConfigurationFormDefaultValues(chosenMode, data);
          if (defaultValues) {
            reset(defaultValues);
          }
        },
      },
    }
  );

  const { mutate: createTournamentConfiguration, isLoading: isCreateTournamentConfigurationLoading } =
    useAddTournamentConfiguration({
      mutation: {
        onSuccess,
        onError,
      },
    });

  const { mutate: editTournamentConfiguration, isLoading: isEditTournamentConfigurationLoading } =
    usePutInstantiatedTournamentConfiguration({
      mutation: {
        onSuccess,
        onError,
      },
    });

  const onSubmit = (formData: CreateTournamentConfigurationFormData) => {
    if (
      !formData?.prizePoolDistribution?.monetaryPrizeEntries &&
      !formData?.prizePoolDistribution?.nonMonetaryPrizeEntries
    ) {
      setIsValidForm(false);
      return;
    } else {
      setIsValidForm(true);
    }

    if (mode !== TournamentConfigurationMode.EDIT) {
      const addConfigurationPayload = generateTournamentConfigurationCreateFormPayload(formData);
      return createTournamentConfiguration({ data: addConfigurationPayload as TournamentConfiguration });
    } else {
      const editConfigurationPayload: UpdateInstantiatedTournamentConfiguration = {
        active: formData.active,
        tournamentDescription: formData.tournamentDescription || '',
        thumbnail: formData.thumbnail || '',
        banner: formData.banner || '',
        validTo: formData?.validity?.to || undefined,
      };
      if (tournamentConfiguration?.id) {
        return editTournamentConfiguration({
          tournamentConfigurationId: tournamentConfiguration.id,
          data: editConfigurationPayload,
        });
      }
    }
  };

  if (isTournamentConfigurationLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <>
      <TournamentConfigEditPopoverContent mode={chosenMode} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <TournamentConfigGeneralSettings mode={chosenMode} />
            <TournamentConfigScheduleSettings mode={chosenMode} />
            <TournamentConfigBuyInSettings mode={chosenMode} />
            {!isValidForm && (
              <Typography ml={2} variant="body2" fontSize="small" color={theme.palette.error.main}>
                {t('content.gameserver.tournamentconfiguration.form.prizepoolsettings.error')}
              </Typography>
            )}
            <TournamentConfigMonetaryPrizePoolSettings mode={chosenMode} />
            <TournamentConfigNonMonetaryPrizePoolSettings mode={chosenMode} />
            <TournamentConfigExtraSettings mode={chosenMode} />
            <TournamentConfigGamesSettings mode={chosenMode} />
          </Stack>
          <LoadingButton
            loading={isCreateTournamentConfigurationLoading || isEditTournamentConfigurationLoading}
            sx={{ mt: 4, px: 6, py: 2 }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={
              isTournamentConfigurationLoading ||
              isCreateTournamentConfigurationLoading ||
              isEditTournamentConfigurationLoading
            }
          >
            {t('save')}
          </LoadingButton>
        </form>
      </FormProvider>
    </>
  );
};

export default TournamentConfigForm;
