import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetBonusesActive } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Link, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { usePermission } from 'src/app/hooks';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

import { transformCurrencyV2, transformPercentageV2 } from '../../../../../app/helpers/transformFunctions';

const ActiveBonuses = () => {
  const { t } = useTranslation();
  const canReadBonusesActive = usePermission(OnlineCasinoPermissions.getBonusesActive);

  const { data: activeBonuses, isLoading } = useGetBonusesActive({
    query: { enabled: canReadBonusesActive },
  });

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'username',
        headerName: t('username'),
        flex: 1,
        renderCell: params => <Link href={`../../../users/${params.row.userid}/details`}>{params.value}</Link>,
      },
      {
        ...transformCurrencyV2('balance', t),
        flex: 1,
      },
      {
        ...transformCurrencyV2('amount', t),
        flex: 1,
      },
      {
        ...transformPercentageV2('progress', t),
        flex: 1,
      },
      {
        field: 'description',
        headerName: t('description'),
        flex: 1,
      },
    ],
    [t]
  );

  return (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom>
        {t('activeBonuses')}
      </Typography>
      <StyledDataGrid
        columns={columns}
        rows={activeBonuses ?? []}
        loading={isLoading}
        autoHeight
        disableSelectionOnClick
        getRowId={row => row.userid}
      />
    </Stack>
  );
};

export default ActiveBonuses;
