import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { SxProps, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

import { DATETIME_INPUT_FORMAT } from '@greenisland-common/components/atoms/Constants';

import { dateTimeMask } from '../../../../app/constants/defaultvalues';

interface Props {
  name: string;
  label?: string;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  disabled?: boolean;
  disablePast?: boolean;
  defaultValue?: Date | null;
  inputFormat?: string;
  mask?: string;
  ampm?: false;
  minDateTime?: Date;
  maxDateTime?: Date;
  required?: boolean;
  sx?: SxProps;
}

const DateTimeField = ({
  name,
  disabled = false,
  disablePast = false,
  defaultValue = null,
  label,
  rules,
  inputFormat = DATETIME_INPUT_FORMAT,
  mask = dateTimeMask,
  ampm = false,
  required = false,
  minDateTime = undefined,
  maxDateTime = undefined,
  sx,
}: Props) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      rules={rules}
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ value, ...renderProps }) => (
        <DateTimePicker
          value={value || defaultValue}
          label={label}
          disablePast={disablePast}
          {...renderProps}
          renderInput={params => (
            <TextField
              size="small"
              fullWidth
              {...params}
              sx={sx}
              error={Boolean(errors?.[name])}
              helperText={errors[name]?.message}
              required={required}
            />
          )}
          disabled={disabled}
          inputFormat={inputFormat}
          mask={mask}
          ampm={ampm}
          minDateTime={minDateTime}
          maxDateTime={maxDateTime}
        />
      )}
    />
  );
};

export default DateTimeField;
