import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, capitalize, FormControlLabel, InputAdornment, Switch, TextField, useTheme } from '@mui/material';

import LoyaltyDependentValue, {
  LoyaltyDependentType,
} from '../../Components/LoyaltyDependentValue/LoyaltyDependentValue';

interface Props {
  isLoyaltyDependentActive?: boolean;
  isLoyaltyDependentRequired?: boolean;
  isLoyaltyDependentDisabled?: boolean;
  loyaltyDependentName: string;
  changeLoyaltyDependentHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  loyaltyDependentOptions: { label: string; name: string }[];
  maxPayoutName: string;
  isMaxPayoutDisabled?: boolean;
  isMaxPayoutRequired?: boolean;
}

const LoyaltyDependantMaxPayoutSection = ({
  isLoyaltyDependentActive = false,
  isLoyaltyDependentRequired = false,
  isLoyaltyDependentDisabled = false,
  loyaltyDependentName,
  changeLoyaltyDependentHandler,
  loyaltyDependentOptions,
  maxPayoutName,
  isMaxPayoutDisabled = false,
  isMaxPayoutRequired = false,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { register, errors } = useFormContext();

  return (
    <Box p={1} borderRadius="5px" border={`1px solid ${theme.palette.text.disabled}`}>
      <FormControlLabel
        control={<Switch checked={isLoyaltyDependentActive} onChange={changeLoyaltyDependentHandler} />}
        label={capitalize(t('boost.loyaltyDependantMaxPayout'))}
        name={loyaltyDependentName}
        disabled={isLoyaltyDependentDisabled}
        inputRef={register({ required: isLoyaltyDependentRequired })}
      />
      {isLoyaltyDependentActive ? (
        <LoyaltyDependentValue
          title={t('marketing.loyaltyDependentMaxPayout.title')}
          type={LoyaltyDependentType.DECIMAL}
          disabled={true}
          options={loyaltyDependentOptions}
          sx={{ mt: 1 }}
        />
      ) : (
        <TextField
          fullWidth={true}
          size="small"
          name={maxPayoutName}
          helperText={
            errors[maxPayoutName]
              ? capitalize(t('boost.helpText.maxPayout.error'))
              : capitalize(t('boost.helpText.maxPayout.default'))
          }
          error={Boolean(errors[maxPayoutName])}
          disabled={isMaxPayoutDisabled}
          type="text"
          InputProps={{ startAdornment: <InputAdornment position={'start'}>€</InputAdornment> }}
          inputRef={register({ required: isMaxPayoutRequired })}
          label={capitalize(t('boost.maxPayout'))}
          sx={{ mt: 2 }}
        />
      )}
    </Box>
  );
};

export default LoyaltyDependantMaxPayoutSection;
