import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EVerificationStatusSchema, WithdrawalsSchemaItem } from '@greenisland-api';
import { Card, CardContent, Checkbox, FormControlLabel, FormGroup } from '@mui/material';

type GlobalFilters = {
  identityVerification: boolean;
  domicileVerification: boolean;
  bankAccountVerification: boolean;
  walletVerifications: boolean;
};

export function useGlobalFilters() {
  const [filters, setFilters] = useState({
    identityVerification: false,
    domicileVerification: false,
    bankAccountVerification: false,
    walletVerifications: false,
  });

  const validate = (row: WithdrawalsSchemaItem) => {
    if (
      filters.identityVerification &&
      row.identityVerification !== EVerificationStatusSchema.Verified &&
      row.identityVerification !== EVerificationStatusSchema.VerifiedByItsMe
    )
      return false;
    if (
      filters.domicileVerification &&
      row.identityVerification !== EVerificationStatusSchema.Verified &&
      row.identityVerification !== EVerificationStatusSchema.VerifiedByItsMe
    )
      return false;
    if (
      filters.bankAccountVerification &&
      row.bankAccountVerification !== EVerificationStatusSchema.Verified &&
      row.bankAccountVerification !== EVerificationStatusSchema.VerifiedByDeposit
    )
      return false;
    if (filters.walletVerifications && row.walletVerification !== EVerificationStatusSchema.Verified) return false;

    return true;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.checked,
    });
  };

  return {
    filters,
    validate,
    onChange,
  };
}

interface Props {
  filters: GlobalFilters;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const GlobalFilter = ({ filters, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        backgroundColor: 'background.default',
      }}
    >
      <CardContent sx={{ paddingX: 2, paddingY: 1, ':last-child': { paddingBottom: 1 } }}>
        <FormGroup aria-label="position" row>
          {Object.entries(filters).map((entry: any, key: number) => {
            const [title, value] = entry;
            const label = t(`${title}Verified`);
            return (
              <FormControlLabel
                key={key}
                control={<Checkbox checked={value} onChange={onChange} name={title} />}
                label={label}
              />
            );
          })}
        </FormGroup>
      </CardContent>
    </Card>
  );
};

export default GlobalFilter;
