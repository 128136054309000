import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AcurisIndividualProfileEvidenceSchema } from '@greenisland-api';
import { Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { getDateFromUnix } from 'src/app/helpers/transformFunctions';

interface Props {
  evidences: AcurisIndividualProfileEvidenceSchema[] | undefined;
}

const KYCEvidencesTable = ({ evidences }: Props) => {
  const { t } = useTranslation();

  if (!evidences || evidences.length === 0) {
    return null;
  }

  return (
    <TableContainer style={{ marginBottom: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('kyc.headers.title')}</TableCell>
            <TableCell>{t('kyc.headers.summary')}</TableCell>
            <TableCell>{t('kyc.headers.credibility')}</TableCell>
            <TableCell>{t('date')}</TableCell>
            <TableCell>{t('kyc.headers.language')}</TableCell>
            <TableCell>{t('kyc.headers.source')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {evidences.map((evidence, index) => (
            <TableRow key={index}>
              <TableCell>{evidence.title || '/'}</TableCell>
              <TableCell>{evidence.summary || '/'}</TableCell>
              <TableCell>{evidence.credibility}</TableCell>
              <TableCell>
                {evidence.publicationDate && getDateFromUnix(evidence.publicationDate)}
                {evidence.captureDate && getDateFromUnix(evidence.captureDate)}
              </TableCell>
              <TableCell>{evidence.language}</TableCell>
              <TableCell>
                <Link href={evidence.originalUrl} target="_blank">
                  {evidence.originalUrl}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(KYCEvidencesTable);
