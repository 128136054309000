/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EVerificationStatusSchema = (typeof EVerificationStatusSchema)[keyof typeof EVerificationStatusSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EVerificationStatusSchema = {
  NeedsVerification: 'NeedsVerification',
  Verified: 'Verified',
  Unverified: 'Unverified',
  WaitingForApproval: 'WaitingForApproval',
  Declined: 'Declined',
  VerifiedByDeposit: 'VerifiedByDeposit',
  VerifiedByItsMe: 'VerifiedByItsMe',
} as const;
