import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetOnlineDeposits } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Card, CardContent, CardHeader } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, Link, PermissionWrapper, StyledDataGrid } from '@greenisland-common/components/atoms';

import { formatCurrency, getDateTimeFromUnix, StatusSpan } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';

const PAGE_SIZE = 5;

const Deposits = () => {
  const { t } = useTranslation();
  const canReadDepositsInProcess = usePermission(OnlineCasinoPermissions.getOnlineDeposits);
  const {
    data: depositsInProcess,
    isLoading,
    isError,
  } = useGetOnlineDeposits({ top: 250 }, { query: { enabled: canReadDepositsInProcess } });

  const headers: GridColumns = [
    {
      headerName: t('timestamp'),
      field: 'timestamp',
      width: 180,
      valueFormatter: params => getDateTimeFromUnix(params.value),
    },
    {
      headerName: t('username'),
      field: 'username',
      width: 150,
      renderCell: params => params.row.username,
    },
    {
      headerName: t('amount'),
      field: 'amount',
      valueFormatter: params => formatCurrency(params.value),
    },
    {
      headerName: t('provider'),
      field: 'provider',
      width: 150,
    },
    {
      headerName: t('paymentMethod'),
      field: 'paymentMethod',
      width: 150,
    },
    {
      headerName: t('status'),
      field: 'status',
      renderCell: params => <StatusSpan value={params.value} />,
    },
  ];

  const rows: GridRowsProp =
    depositsInProcess?.map((item, i) => {
      return {
        ...item,
        username: (
          <Link fontWeight="fontWeightMedium" color="secondary" to={`../users/${item.userId}/details`}>
            {item?.username}
          </Link>
        ),
        id: i,
      };
    }) || [];

  return (
    <Card>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <CardHeader title={t('depositsInProcess')} />
        {canReadDepositsInProcess && (
          <Button variant="outlined" color="primary" component={Link} to={`../reporting/deposits/overview`}>
            {capitalize(t('seeAll'))}
          </Button>
        )}
      </Box>
      <CardContent>
        <PermissionWrapper
          errorMessage={t('depositsInProcessCard.fetchError')}
          isError={isError}
          isLoading={false}
          permission={OnlineCasinoPermissions.getOnlineDeposits}
        >
          <DataGridContainer>
            <StyledDataGrid
              autoHeight
              density="compact"
              columns={headers}
              rows={rows}
              pageSize={PAGE_SIZE}
              pagination
              loading={isLoading}
              hideFooterRowCount={true}
            />
          </DataGridContainer>
        </PermissionWrapper>
      </CardContent>
    </Card>
  );
};

export default memo(Deposits);
