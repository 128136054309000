import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetActiveLoginSessionLimitsForUser } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { getDurationFromSeconds } from 'src/app/helpers/transformFunctions';
import { usePermission } from 'src/app/hooks';

import { DataGridContainer, DateTime, PermissionWrapper, StyledDataGrid } from '@greenisland-common/components/atoms';

type DepositLimitsProps = {
  userId: string;
};

const ActiveLimitsTable = ({ userId }: DepositLimitsProps) => {
  const { t } = useTranslation();
  const canReadSessionLimits = usePermission(OnlineCasinoPermissions.getActiveLoginSessionLimitsForUser);
  const {
    data: activeSessionLimits,
    isLoading,
    isError,
    error,
  } = useGetActiveLoginSessionLimitsForUser(parseInt(userId, 10), { query: { enabled: canReadSessionLimits } });

  const headers: GridColumns = [
    {
      headerName: t('startDate'),
      field: 'startDate',
      renderCell: ({ row }) => (row.startDate ? <DateTime dateTime={row.startDate} /> : <></>),
      flex: 1,
      minWidth: 160,
    },
    { headerName: t('period'), field: 'period', flex: 1, minWidth: 80 },
    {
      headerName: t('value'),
      field: 'value',
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row }) => (row.value ? getDurationFromSeconds(row.value) : ''),
    },
  ];

  const rows: GridRowsProp = useMemo(() => {
    return [
      ...(activeSessionLimits?.dailyLimit ? [{ ...activeSessionLimits?.dailyLimit, id: 0 }] : []),
      ...(activeSessionLimits?.weeklyLimit ? [{ ...activeSessionLimits?.weeklyLimit, id: 1 }] : []),
      ...(activeSessionLimits?.monthlyLimit ? [{ ...activeSessionLimits?.monthlyLimit, id: 2 }] : []),
    ];
  }, [activeSessionLimits?.dailyLimit, activeSessionLimits?.monthlyLimit, activeSessionLimits?.weeklyLimit]);

  return (
    <>
      <PermissionWrapper
        errorMessage={error?.message}
        isError={isError}
        isLoading={isLoading}
        permission={OnlineCasinoPermissions.getActiveLoginSessionLimitsForUser}
      >
        <DataGridContainer>
          <StyledDataGrid columns={headers} rows={rows} hideFooter rowCount={3} />
        </DataGridContainer>
      </PermissionWrapper>
    </>
  );
};

export default memo(ActiveLimitsTable);
