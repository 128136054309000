import React, { PropsWithChildren } from 'react';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { Theme } from '@mui/system';

interface Props {
  index: number;
  value: number;
  sx?: SxProps<Theme>;
}

/**
 * This component should be used with Mui Tabs component
 *
 * This component displays content of the relative Tab if it is selected
 *
 * @param {React.ReactNode} children - The elements to be displayed if the right Tab is active
 * @param {number} value - Index of the currently selected Tab
 * @param {number} index - index of the relative Tab
 * @param {SxProps} sx - additional styles
 */
const TabPanel = ({ children, value, index, sx }: PropsWithChildren<Props>) => {
  return (
    <Box sx={sx} role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
      {value === index ? children : null}
    </Box>
  );
};

export default TabPanel;
