/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  DefaultResponse,
  GetWithdrawalDetailsV2Params,
  NotFoundResponse,
  PinCodeSchema,
  UnauthorizedResponse,
  WithdrawalDetailsSchema,
  WithdrawalsSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Get all details from a withdrawal
 * @summary Get withdrawal details
 */
export const useGetWithdrawalDetailsV2Hook = () => {
  const getWithdrawalDetailsV2 = useCustomInstance<WithdrawalDetailsSchema>();

  return (withdrawalId: number, params?: GetWithdrawalDetailsV2Params, signal?: AbortSignal) => {
    return getWithdrawalDetailsV2({ url: `/v2/withdrawals/${withdrawalId}`, method: 'get', params, signal });
  };
};

export const getGetWithdrawalDetailsV2QueryKey = (withdrawalId: number, params?: GetWithdrawalDetailsV2Params) =>
  [`/v2/withdrawals/${withdrawalId}`, ...(params ? [params] : [])] as const;

export const useGetWithdrawalDetailsV2QueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalDetailsV2Hook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  withdrawalId: number,
  params?: GetWithdrawalDetailsV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalDetailsV2Hook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalDetailsV2Hook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalDetailsV2QueryKey(withdrawalId, params);

  const getWithdrawalDetailsV2 = useGetWithdrawalDetailsV2Hook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalDetailsV2Hook>>>> = ({ signal }) =>
    getWithdrawalDetailsV2(withdrawalId, params, signal);

  return { queryKey, queryFn, enabled: !!withdrawalId, ...queryOptions };
};

export type GetWithdrawalDetailsV2QueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWithdrawalDetailsV2Hook>>>
>;
export type GetWithdrawalDetailsV2QueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get withdrawal details
 */
export const useGetWithdrawalDetailsV2 = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalDetailsV2Hook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  withdrawalId: number,
  params?: GetWithdrawalDetailsV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalDetailsV2Hook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWithdrawalDetailsV2QueryOptions(withdrawalId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Reveals the pincode for this withdrawal (can be null)
 * @summary Reveal pincode for this withdrawal
 */
export const useGetWithdrawalPinCodeHook = () => {
  const getWithdrawalPinCode = useCustomInstance<PinCodeSchema>();

  return (withdrawalId: string, signal?: AbortSignal) => {
    return getWithdrawalPinCode({ url: `/v2/withdrawals/${withdrawalId}/pincode`, method: 'get', signal });
  };
};

export const getGetWithdrawalPinCodeQueryKey = (withdrawalId: string) =>
  [`/v2/withdrawals/${withdrawalId}/pincode`] as const;

export const useGetWithdrawalPinCodeQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalPinCodeHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  withdrawalId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalPinCodeHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalPinCodeHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalPinCodeQueryKey(withdrawalId);

  const getWithdrawalPinCode = useGetWithdrawalPinCodeHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalPinCodeHook>>>> = ({ signal }) =>
    getWithdrawalPinCode(withdrawalId, signal);

  return { queryKey, queryFn, enabled: !!withdrawalId, ...queryOptions };
};

export type GetWithdrawalPinCodeQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWithdrawalPinCodeHook>>>
>;
export type GetWithdrawalPinCodeQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Reveal pincode for this withdrawal
 */
export const useGetWithdrawalPinCode = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalPinCodeHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  withdrawalId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalPinCodeHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWithdrawalPinCodeQueryOptions(withdrawalId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Decline an individual withdrawal
 * @summary Decline withdrawal
 */
export const useDeclineWithdrawalV2Hook = () => {
  const declineWithdrawalV2 = useCustomInstance<void>();

  return (withdrawalId: number, declineWithdrawalV2Body: string) => {
    return declineWithdrawalV2({
      url: `/v2/withdrawals/${withdrawalId}/decline`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: declineWithdrawalV2Body,
    });
  };
};

export const useDeclineWithdrawalV2MutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeclineWithdrawalV2Hook>>>,
    TError,
    { withdrawalId: number; data: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeclineWithdrawalV2Hook>>>,
  TError,
  { withdrawalId: number; data: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const declineWithdrawalV2 = useDeclineWithdrawalV2Hook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeclineWithdrawalV2Hook>>>,
    { withdrawalId: number; data: string }
  > = props => {
    const { withdrawalId, data } = props ?? {};

    return declineWithdrawalV2(withdrawalId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeclineWithdrawalV2MutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeclineWithdrawalV2Hook>>>
>;
export type DeclineWithdrawalV2MutationBody = string;
export type DeclineWithdrawalV2MutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Decline withdrawal
 */
export const useDeclineWithdrawalV2 = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeclineWithdrawalV2Hook>>>,
    TError,
    { withdrawalId: number; data: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeclineWithdrawalV2MutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Complete withdrawal manually
 * @summary Complete withdrawal
 */
export const useCompleteWithdrawalManuallyV2Hook = () => {
  const completeWithdrawalManuallyV2 = useCustomInstance<void>();

  return (withdrawalId: number) => {
    return completeWithdrawalManuallyV2({ url: `/v2/withdrawals/${withdrawalId}/completemanually`, method: 'put' });
  };
};

export const useCompleteWithdrawalManuallyV2MutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompleteWithdrawalManuallyV2Hook>>>,
    TError,
    { withdrawalId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCompleteWithdrawalManuallyV2Hook>>>,
  TError,
  { withdrawalId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const completeWithdrawalManuallyV2 = useCompleteWithdrawalManuallyV2Hook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCompleteWithdrawalManuallyV2Hook>>>,
    { withdrawalId: number }
  > = props => {
    const { withdrawalId } = props ?? {};

    return completeWithdrawalManuallyV2(withdrawalId);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompleteWithdrawalManuallyV2MutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCompleteWithdrawalManuallyV2Hook>>>
>;

export type CompleteWithdrawalManuallyV2MutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Complete withdrawal
 */
export const useCompleteWithdrawalManuallyV2 = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompleteWithdrawalManuallyV2Hook>>>,
    TError,
    { withdrawalId: number },
    TContext
  >;
}) => {
  const mutationOptions = useCompleteWithdrawalManuallyV2MutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retry withdrawal
 * @summary Retry withdrawal
 */
export const useRetryWithdrawalV2Hook = () => {
  const retryWithdrawalV2 = useCustomInstance<void>();

  return (withdrawalId: number) => {
    return retryWithdrawalV2({ url: `/v2/withdrawals/${withdrawalId}/retry`, method: 'put' });
  };
};

export const useRetryWithdrawalV2MutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRetryWithdrawalV2Hook>>>,
    TError,
    { withdrawalId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRetryWithdrawalV2Hook>>>,
  TError,
  { withdrawalId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const retryWithdrawalV2 = useRetryWithdrawalV2Hook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRetryWithdrawalV2Hook>>>,
    { withdrawalId: number }
  > = props => {
    const { withdrawalId } = props ?? {};

    return retryWithdrawalV2(withdrawalId);
  };

  return { mutationFn, ...mutationOptions };
};

export type RetryWithdrawalV2MutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRetryWithdrawalV2Hook>>>
>;

export type RetryWithdrawalV2MutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Retry withdrawal
 */
export const useRetryWithdrawalV2 = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRetryWithdrawalV2Hook>>>,
    TError,
    { withdrawalId: number },
    TContext
  >;
}) => {
  const mutationOptions = useRetryWithdrawalV2MutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Approve/process/export list of withdrawals
 * @summary Process withdrawals
 */
export const useProcessWithdrawalsV2Hook = () => {
  const processWithdrawalsV2 = useCustomInstance<void>();

  return (processWithdrawalsV2Body: number[]) => {
    return processWithdrawalsV2({
      url: `/v2/withdrawals/process`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: processWithdrawalsV2Body,
    });
  };
};

export const useProcessWithdrawalsV2MutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProcessWithdrawalsV2Hook>>>,
    TError,
    { data: number[] },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useProcessWithdrawalsV2Hook>>>,
  TError,
  { data: number[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const processWithdrawalsV2 = useProcessWithdrawalsV2Hook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useProcessWithdrawalsV2Hook>>>,
    { data: number[] }
  > = props => {
    const { data } = props ?? {};

    return processWithdrawalsV2(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProcessWithdrawalsV2MutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProcessWithdrawalsV2Hook>>>
>;
export type ProcessWithdrawalsV2MutationBody = number[];
export type ProcessWithdrawalsV2MutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Process withdrawals
 */
export const useProcessWithdrawalsV2 = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProcessWithdrawalsV2Hook>>>,
    TError,
    { data: number[] },
    TContext
  >;
}) => {
  const mutationOptions = useProcessWithdrawalsV2MutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get all requested withdrawals
 * @summary Get all requested withdrawals
 */
export const useGetRequestedWithdrawalsV2Hook = () => {
  const getRequestedWithdrawalsV2 = useCustomInstance<WithdrawalsSchema>();

  return (
    type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer',
    signal?: AbortSignal
  ) => {
    return getRequestedWithdrawalsV2({ url: `/v2/withdrawals/requested/${type}`, method: 'get', signal });
  };
};

export const getGetRequestedWithdrawalsV2QueryKey = (
  type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer'
) => [`/v2/withdrawals/requested/${type}`] as const;

export const useGetRequestedWithdrawalsV2QueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetRequestedWithdrawalsV2Hook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer',
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestedWithdrawalsV2Hook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestedWithdrawalsV2Hook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRequestedWithdrawalsV2QueryKey(type);

  const getRequestedWithdrawalsV2 = useGetRequestedWithdrawalsV2Hook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRequestedWithdrawalsV2Hook>>>> = ({
    signal,
  }) => getRequestedWithdrawalsV2(type, signal);

  return { queryKey, queryFn, enabled: !!type, ...queryOptions };
};

export type GetRequestedWithdrawalsV2QueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetRequestedWithdrawalsV2Hook>>>
>;
export type GetRequestedWithdrawalsV2QueryError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Get all requested withdrawals
 */
export const useGetRequestedWithdrawalsV2 = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetRequestedWithdrawalsV2Hook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer',
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRequestedWithdrawalsV2Hook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetRequestedWithdrawalsV2QueryOptions(type, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Cancel an failed individual withdrawal
 * @summary Cancel failed withdrawal
 */
export const useCancelFailedWithdrawalV2Hook = () => {
  const cancelFailedWithdrawalV2 = useCustomInstance<void>();

  return (withdrawalId: number) => {
    return cancelFailedWithdrawalV2({ url: `/v2/withdrawals/${withdrawalId}/cancel`, method: 'post' });
  };
};

export const useCancelFailedWithdrawalV2MutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelFailedWithdrawalV2Hook>>>,
    TError,
    { withdrawalId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCancelFailedWithdrawalV2Hook>>>,
  TError,
  { withdrawalId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const cancelFailedWithdrawalV2 = useCancelFailedWithdrawalV2Hook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCancelFailedWithdrawalV2Hook>>>,
    { withdrawalId: number }
  > = props => {
    const { withdrawalId } = props ?? {};

    return cancelFailedWithdrawalV2(withdrawalId);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelFailedWithdrawalV2MutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCancelFailedWithdrawalV2Hook>>>
>;

export type CancelFailedWithdrawalV2MutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Cancel failed withdrawal
 */
export const useCancelFailedWithdrawalV2 = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelFailedWithdrawalV2Hook>>>,
    TError,
    { withdrawalId: number },
    TContext
  >;
}) => {
  const mutationOptions = useCancelFailedWithdrawalV2MutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mark a withdrawal failed
 * @summary Mark a withdrawal failed
 */
export const useFailWithdrawalV2Hook = () => {
  const failWithdrawalV2 = useCustomInstance<void>();

  return (withdrawalId: number) => {
    return failWithdrawalV2({ url: `/v2/withdrawals/${withdrawalId}/fail`, method: 'post' });
  };
};

export const useFailWithdrawalV2MutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFailWithdrawalV2Hook>>>,
    TError,
    { withdrawalId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useFailWithdrawalV2Hook>>>,
  TError,
  { withdrawalId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const failWithdrawalV2 = useFailWithdrawalV2Hook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useFailWithdrawalV2Hook>>>,
    { withdrawalId: number }
  > = props => {
    const { withdrawalId } = props ?? {};

    return failWithdrawalV2(withdrawalId);
  };

  return { mutationFn, ...mutationOptions };
};

export type FailWithdrawalV2MutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useFailWithdrawalV2Hook>>>
>;

export type FailWithdrawalV2MutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Mark a withdrawal failed
 */
export const useFailWithdrawalV2 = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFailWithdrawalV2Hook>>>,
    TError,
    { withdrawalId: number },
    TContext
  >;
}) => {
  const mutationOptions = useFailWithdrawalV2MutationOptions(options);

  return useMutation(mutationOptions);
};
