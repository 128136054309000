/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EPaymentProviderSchema = (typeof EPaymentProviderSchema)[keyof typeof EPaymentProviderSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EPaymentProviderSchema = {
  Unknown: 'Unknown',
  StarCasino: 'StarCasino',
  OnlineCasino: 'OnlineCasino',
  HiPay: 'HiPay',
  HiPayWallet: 'HiPayWallet',
  PaySafeCard: 'PaySafeCard',
  Skrill: 'Skrill',
  Neteller: 'Neteller',
  Trustly: 'Trustly',
  Paypal: 'Paypal',
  Apcopay: 'Apcopay',
  Casino: 'Casino',
  Green_Island: 'Green Island',
  NeoSurf: 'NeoSurf',
  TrueLayer: 'TrueLayer',
  Payconiq: 'Payconiq',
  Paysafe: 'Paysafe',
  Bizum: 'Bizum',
} as const;
