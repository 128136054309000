import { Outlet } from 'react-router';
import { GameServerPermissions } from '@greenisland-core/permissions/GameServerPermissions';

import TournamentConfigForm from '../../pages/GameServer/TournamentConfigs/components/forms/TournamentConfigForm';
import TournamentsConfigs from '../../pages/GameServer/TournamentConfigs/TournamentsConfigs';
import TournamentDetails from '../../pages/GameServer/Tournaments/TournamentDetails';
import Tournaments from '../../pages/GameServer/Tournaments/Tournaments';
import { BaseRoute } from '../RouteInterfaces';

const tournamentRoutes: Array<BaseRoute> = [
  {
    name: 'Tournaments',
    path: 'tournaments',
    collapse: true,
    element: Outlet,
    children: [
      {
        path: 'tournaments-overview',
        name: 'Tournaments Overview',
        element: Tournaments,
      },
      {
        path: 'configurations-overview',
        name: 'Configurations Overview',
        element: Outlet,
        children: [
          {
            path: '',
            name: '',
            element: TournamentsConfigs,
            permissions: [GameServerPermissions.queryTournamentConfigurations],
          },
          {
            path: ':mode',
            name: '',
            element: TournamentConfigForm,
            hiddenLink: true,
            permissions: [GameServerPermissions.addTournamentConfiguration],
          },
          {
            path: ':mode/:configurationId',
            name: '',
            element: TournamentConfigForm,
            hiddenLink: true,
            permissions: [GameServerPermissions.addTournamentConfiguration],
          },
        ],
      },
      {
        path: ':tournamentInstanceId',
        name: 'Tournament Details',
        element: TournamentDetails,
        hiddenLink: true,
      },
    ],
  },
];

export default tournamentRoutes;
