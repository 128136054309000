import React from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingCashflowSchema } from '@greenisland-api';
import { Card, CardContent, CardHeader } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, DataGridPlain, DataGridPlainHeader } from '@greenisland-common/components/atoms';

import { formatCurrency, formatPercentage, PercentageSpan } from '../../../../../../app/helpers/transformFunctions';

interface Props {
  cashflow: AccountingCashflowSchema;
}

const headers: GridColumns = [
  {
    headerName: '',
    field: 'title',
    renderCell: ({ value }) => <DataGridPlainHeader value={value} />,
    minWidth: 200,
    flex: 2,
  },
  { headerName: '', field: 'value', renderCell: ({ value }) => value, minWidth: 110, flex: 1 },
];

const Cashflow = ({ cashflow }: Props) => {
  const { t } = useTranslation();

  const rows: GridRowsProp = [
    { id: 1, title: t('deposits.title'), value: formatCurrency(cashflow.deposits) },
    { id: 2, title: t('withdrawals.title'), value: formatCurrency(cashflow.withdrawals) },
    { id: 3, title: t('automaticWithdrawals'), value: formatCurrency(cashflow.automaticWithdrawals) },
    { id: 4, title: t('cancelledWithdrawals'), value: formatCurrency(cashflow.cancelledWithdrawals) },
    { id: 5, title: t('revenue'), value: formatCurrency(cashflow.revenue) },
    { id: 6, title: t('forecast'), value: formatCurrency(cashflow.forecast) },
    { id: 7, title: t('casinoHold'), value: <PercentageSpan value={formatPercentage(cashflow.casinoHold)} /> },
    { id: 8, title: t('transferToVoucher'), value: formatCurrency(cashflow.transferToVoucher) },
  ];

  return (
    <Card sx={{ pl: 0, pr: 0, mb: 2, maxWidth: 550 }}>
      <CardHeader title={t('cashflow')} />
      <CardContent sx={{ pl: 0, pr: 0 }}>
        <DataGridContainer>
          <DataGridPlain columns={headers} rows={rows} />
        </DataGridContainer>
      </CardContent>
    </Card>
  );
};

export default Cashflow;
