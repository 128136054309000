import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSelfTests } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, capitalize } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { GridColumns, GridRowParams, GridRowsProp } from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import { getDateFromUnix } from 'src/app/helpers/transformFunctions';

import {
  DataGridContainer,
  DataGridPagination,
  DataGridPlain,
  ErrorCard,
  NoData,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../../app/hooks';
import PgsiResultField from '../PgsiResultField';
import { ANSWERS } from './PgsiDialog';

const getDetailsHeaders: GridColumns = [
  {
    headerName: '',
    field: 'id',
    flex: 0.05,
  },
  {
    headerName: '',
    field: 'title',
    flex: 2,
    minWidth: 300,
  },
  { headerName: '', field: 'value', flex: 0.2, minWidth: 130 },
];

const PgsiPage = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const { userId = '' } = useParams();
  const canReadPgsi = usePermission(OnlineCasinoPermissions.getSelfTests);
  const {
    data: pgsiData,
    isLoading,
    isError,
    error,
  } = useGetSelfTests(
    { userId: userId, resultsPerPage: pageSize, currentPageNumber: page },
    { query: { enabled: !!userId } }
  );

  const [rowCountState, setRowCountState] = useState(pgsiData?.pagingDetails?.totalItems || 0);
  useEffect(() => {
    setRowCountState(prevRowCountState =>
      pgsiData?.pagingDetails?.totalItems !== undefined ? pgsiData?.pagingDetails.totalItems : prevRowCountState
    );
  }, [pgsiData?.pagingDetails?.totalItems, setRowCountState]);

  const rows = useMemo(() => {
    if (pgsiData?.selfTests) {
      return pgsiData.selfTests.map((test, i) => ({ id: i, ...test }));
    }
    return [];
  }, [pgsiData?.selfTests]);

  const getDetailPanelContent = useCallback(({ row }: GridRowParams<any>) => {
    const rows: GridRowsProp = [
      { id: 1, title: t('pgsi.question1'), value: t(ANSWERS[row.Q1Score as number]) },
      { id: 2, title: t('pgsi.question2'), value: t(ANSWERS[row.Q2Score as number]) },
      { id: 3, title: t('pgsi.question3'), value: t(ANSWERS[row.Q3Score as number]) },
      { id: 4, title: t('pgsi.question4'), value: t(ANSWERS[row.Q4Score as number]) },
      { id: 5, title: t('pgsi.question5'), value: t(ANSWERS[row.Q5Score as number]) },
      { id: 6, title: t('pgsi.question6'), value: t(ANSWERS[row.Q6Score as number]) },
      { id: 7, title: t('pgsi.question7'), value: t(ANSWERS[row.Q7Score as number]) },
      { id: 8, title: t('pgsi.question8'), value: t(ANSWERS[row.Q8Score as number]) },
      { id: 9, title: t('pgsi.question9'), value: t(ANSWERS[row.Q9Score as number]) },
    ];

    return (
      <Box padding={2}>
        <DataGridContainer>
          <DataGridPlain
            sx={{
              '& .MuiDataGrid-cellContent': { whiteSpace: 'normal' },
            }}
            getRowHeight={() => 'auto'}
            columns={getDetailsHeaders}
            rows={rows}
          />
        </DataGridContainer>
      </Box>
    );
  }, []);

  const getDetailPanelHeight = useCallback(() => 'auto' as const, []);

  if (!canReadPgsi) return null;
  if (isError || (!pgsiData && !isLoading)) return <ErrorCard sx={{ height: 95 }}>{error?.message}</ErrorCard>;
  if (!isLoading && rows.length === 0) {
    return <NoData />;
  }

  const headers: GridColumns = [
    {
      headerName: capitalize(t('pgsi.passDate')),
      field: 'created',
      valueFormatter: ({ value }) => (value && value !== 0 ? getDateFromUnix(value) : '/'),
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: capitalize(t('pgsi.deviceId')),
      field: 'deviceId',
      flex: 1,
      renderCell: ({ value }) => (value && value !== '' ? value : '-'),
      minWidth: 120,
    },
    { headerName: capitalize(t('pgsi.totalScore')), field: 'totalScore', flex: 0.5, minWidth: 100 },
    { headerName: capitalize(t('pgsi.reason')), field: 'reason', flex: 1, minWidth: 120 },
    {
      headerName: capitalize(t('pgsi.classification')),
      field: 'PGSIClassification',
      renderCell: ({ value }) => <PgsiResultField value={value} />,
      flex: 1,
      minWidth: 180,
    },
  ];

  return (
    <DataGridContainer>
      <StyledDataGrid
        autoHeight
        density="compact"
        loading={isLoading}
        columns={headers}
        rows={rows}
        rowCount={rowCountState}
        rowsPerPageOptions={[10, 25, 50, 100, 200]}
        pagination
        page={page}
        pageSize={pageSize}
        paginationMode="server"
        onPageChange={newPage => setPage(newPage)}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        disableDensitySelector
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        disableChildrenSorting
        disableChildrenFiltering
        disableMultipleColumnsSorting
        disableColumnResize
        disableColumnReorder
        isRowSelectable={() => false}
        components={{
          Pagination: DataGridPagination,
          LoadingOverlay: LinearProgress,
        }}
        componentsProps={{
          pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] },
        }}
      />
    </DataGridContainer>
  );
};

export default memo(PgsiPage);
