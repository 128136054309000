import {
  CompoundRequirement,
  Currency,
  ExtraRequirements,
  MonetaryTournamentPrizePoolDistributionEntry,
  NonMonetaryTournamentPrizePoolDistributionEntry,
  RequirementValueType,
  TimeZoneId,
  TournamentConfiguration,
} from '@greenisland-store/gameServer';

import {
  CreateTournamentConfigurationFormData,
  TournamentConfigurationMode,
  TournamentDurationsEnum,
} from './tournamentConfigFormInterfaces';

const parseFromISO8601DurationHandler = (duration?: string): { value: string; unit: TournamentDurationsEnum } => {
  if (!duration) {
    return { value: '', unit: TournamentDurationsEnum.HOURS };
  }

  const regex = /^P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;
  const match = duration.match(regex);

  if (match) {
    const years = match[1];
    const months = match[2];
    const weeks = match[3];
    const days = match[4];
    const hours = match[5];

    if (years) {
      return { value: years, unit: TournamentDurationsEnum.YEARS };
    } else if (months) {
      return { value: months, unit: TournamentDurationsEnum.MONTHS };
    } else if (weeks) {
      return { value: weeks, unit: TournamentDurationsEnum.WEEKS };
    } else if (days) {
      return { value: days, unit: TournamentDurationsEnum.DAYS };
    } else if (hours) {
      return { value: hours, unit: TournamentDurationsEnum.HOURS };
    }
  }

  return { value: '', unit: TournamentDurationsEnum.HOURS };
};

const parseToISO8601DurationHandler = (value: string, unit: TournamentDurationsEnum) => {
  switch (unit.toLowerCase()) {
    case TournamentDurationsEnum.HOURS:
      return 'PT' + value + 'H';
    case TournamentDurationsEnum.DAYS:
      return 'P' + value + 'D';
    case TournamentDurationsEnum.WEEKS:
      return 'P' + parseInt(value) * 7 + 'D';
    case TournamentDurationsEnum.MONTHS:
      return 'P' + value + 'M';
    case TournamentDurationsEnum.YEARS:
      return 'P' + value + 'Y';
    default:
      return '';
  }
};

const parseFromCurrencyToNumberHandler = (value: string) => {
  const numericString = value?.replace(/[^\d,.-]/g, '')?.replace(',', '.');
  return parseFloat(numericString);
};

const generateExtraRequirementsValuesHandler = (extraRequirements?: ExtraRequirements) => {
  if (!extraRequirements?.description || !extraRequirements?.condition) {
    return undefined;
  }
  const updatedCompoundRequirements: CompoundRequirement[] | undefined =
    extraRequirements?.compoundRequirements?.map(compoundRequirement => ({
      ...compoundRequirement,
      requirements: compoundRequirement?.requirements?.map(requirement => {
        let valueType;
        const value = requirement?.value?.trim();
        if (value && !isNaN(+value)) {
          valueType = RequirementValueType.INTEGER;
        } else if (value?.includes(',')) {
          valueType = RequirementValueType.ARRAY_OF_STRINGS;
        } else {
          valueType = RequirementValueType.STRING;
        }
        return { ...requirement, valueType };
      }),
    })) || undefined;

  return {
    description: extraRequirements?.description,
    condition: extraRequirements?.condition,
    compoundRequirements: updatedCompoundRequirements,
  };
};

const generateNonMonetaryPrizePoolValuesHandler = (
  nonMonetaryPrizeEntries: NonMonetaryTournamentPrizePoolDistributionEntry[] | undefined
) => {
  if (!nonMonetaryPrizeEntries) return [];
  return nonMonetaryPrizeEntries.map(entry => ({
    amount: (entry.to ?? 0) - (entry.from ?? 0) + 1,
    prize: entry.prize || '',
  }));
};

const generateTournamentConfigurationFormDefaultValues = (
  mode: TournamentConfigurationMode,
  tournamentConfiguration?: TournamentConfiguration
) => {
  const isCreateMode = mode === TournamentConfigurationMode.ADD;
  const isEditMode = mode === TournamentConfigurationMode.EDIT;
  const isCopyMode = mode === TournamentConfigurationMode.COPY;

  const getMonetaryPrizeEntriesDefaultValues = () => {
    if (isCreateMode) return [];

    const entries = tournamentConfiguration?.prizePoolDistribution?.monetaryPrizeEntries ?? [];

    if (isEditMode || (isCopyMode && entries[0]?.amount !== undefined)) {
      return entries.map(entry => ({
        ...entry,
        amount: entry?.amount ? (entry.amount / 100).toFixed(2) : undefined,
        share: entry?.share ?? undefined,
      }));
    }

    return [];
  };

  return {
    active: isEditMode ? tournamentConfiguration?.active : false,
    tournamentName: !isCreateMode ? tournamentConfiguration?.tournamentName : '',
    tournamentDescription: !isCreateMode ? tournamentConfiguration?.tournamentDescription : '',
    validity: {
      from:
        isEditMode && tournamentConfiguration?.validity?.from
          ? new Date(tournamentConfiguration?.validity?.from * 1000)
          : null,
      to:
        isEditMode && tournamentConfiguration?.validity?.to
          ? new Date(tournamentConfiguration?.validity?.to * 1000)
          : null,
    },
    thumbnail: !isCreateMode ? tournamentConfiguration?.thumbnail : '',
    banner: !isCreateMode ? tournamentConfiguration?.banner : '',
    tournamentSchedule: {
      cronExpression: !isCreateMode ? tournamentConfiguration?.tournamentSchedule?.cronExpression : '',
      scheduleTimeZoneId: !isCreateMode
        ? tournamentConfiguration?.tournamentSchedule?.scheduleTimeZoneId
        : TimeZoneId['Europe/Brussels'],
    },
    tournamentDuration: !isCreateMode
      ? parseFromISO8601DurationHandler(tournamentConfiguration?.tournamentDuration)?.value
      : '',
    tournamentDurationUnit: !isCreateMode
      ? parseFromISO8601DurationHandler(tournamentConfiguration?.tournamentDuration)?.unit
      : '',
    buyInPrice: {
      amount:
        !isCreateMode && tournamentConfiguration?.buyInPrice?.amount
          ? tournamentConfiguration?.buyInPrice?.amount / 100
          : null,
      currency: Currency.EUR,
    },
    rebuyPrice: {
      amount:
        !isCreateMode && tournamentConfiguration?.rebuyPrice?.amount
          ? tournamentConfiguration?.rebuyPrice?.amount / 100
          : null,
      currency: Currency.EUR,
    },
    maxNumberOfRebuys: !isCreateMode ? tournamentConfiguration?.maxNumberOfRebuys : 0,
    initialPlayCreditsBalance: {
      amount:
        !isCreateMode && tournamentConfiguration?.initialPlayCreditsBalance?.amount
          ? tournamentConfiguration?.initialPlayCreditsBalance?.amount / 100
          : null,
      currency: Currency.EUR,
    },
    currency: Currency.EUR,
    prizePool: {
      monetaryPrizePool: !isCreateMode ? tournamentConfiguration?.prizePool?.monetaryPrizePool : [],
      nonMonetaryPrizeEntries: !isCreateMode ? tournamentConfiguration?.prizePool?.nonMonetaryPrizePool : [],
    },
    prizePoolDistribution: {
      monetaryPrizeEntries: getMonetaryPrizeEntriesDefaultValues(),
      nonMonetaryPrizeEntries: !isCreateMode
        ? tournamentConfiguration?.prizePoolDistribution?.nonMonetaryPrizeEntries
        : [],
    },
    extraRequirements: !isCreateMode ? tournamentConfiguration?.extraRequirements : [],
    gameReferences: !isCreateMode ? tournamentConfiguration?.gameReferences : [],
    games: [],
  };
};

const generateTournamentConfigurationCreateFormPayload = (formData: CreateTournamentConfigurationFormData) => {
  return {
    active: formData?.active,
    tournamentName: formData?.tournamentName,
    tournamentDescription: formData?.tournamentDescription,
    validity: {
      from: formData?.validity?.from,
      to: formData?.validity?.to ? formData?.validity?.to : undefined,
    },
    thumbnail: formData?.thumbnail,
    banner: formData?.banner,
    tournamentSchedule: {
      cronExpression: formData?.tournamentSchedule?.cronExpression || '',
      scheduleTimeZoneId: TimeZoneId['Europe/Brussels'],
    },
    tournamentDuration: formData?.tournamentDuration || '',
    buyInPrice: {
      amount: formData?.buyInPrice?.amount ? parseInt((formData.buyInPrice.amount * 100).toString()) : 0,
      currency: formData?.currency || Currency.EUR,
    },
    currency: formData?.currency || Currency.EUR,
    rebuyPrice: {
      amount: formData?.rebuyPrice?.amount ? parseInt((formData.rebuyPrice.amount * 100).toString()) : 0,
      currency: formData?.currency || Currency.EUR,
    },
    maxNumberOfRebuys: formData?.maxNumberOfRebuys,
    initialPlayCreditsBalance: {
      amount: formData?.initialPlayCreditsBalance?.amount
        ? parseInt((formData.initialPlayCreditsBalance.amount * 100).toString())
        : 0,
      currency: formData?.currency || Currency.EUR,
    },
    prizePool: {
      monetaryPrizePool: {
        amount: formData?.prizePool?.monetaryPrizePool?.amount || 0,
        currency: formData?.currency || Currency.EUR,
      },
      nonMonetaryPrizePool: generateNonMonetaryPrizePoolValuesHandler(
        formData?.prizePoolDistribution?.nonMonetaryPrizeEntries
      ),
    },
    prizePoolDistribution: {
      monetaryPrizeEntries: formData?.prizePoolDistribution?.monetaryPrizeEntries?.map(entry => ({
        ...entry,
        amount: entry?.amount ? parseInt((entry.amount * 100).toString()) : 0,
      })),
      nonMonetaryPrizeEntries: formData?.prizePoolDistribution?.nonMonetaryPrizeEntries,
    },
    extraRequirements: generateExtraRequirementsValuesHandler(formData?.extraRequirements),
    gameReferences: formData?.games?.map(game => game?.gameReference),
  };
};

const validatePrizeEntriesHandler = (
  entry?: MonetaryTournamentPrizePoolDistributionEntry[] | NonMonetaryTournamentPrizePoolDistributionEntry[]
) => {
  if (!entry || entry?.length <= 1) return true;

  for (let i = 0; i < entry.length - 1; i++) {
    const currentEntry = entry[i];
    const nextEntry = entry[i + 1];

    if (
      currentEntry?.from &&
      currentEntry?.to &&
      nextEntry?.from &&
      nextEntry?.to &&
      (currentEntry.from > currentEntry.to || nextEntry.from > nextEntry.to || currentEntry.to >= nextEntry.from)
    ) {
      return false;
    }
  }
  return true;
};

export {
  parseFromISO8601DurationHandler,
  parseToISO8601DurationHandler,
  generateTournamentConfigurationFormDefaultValues,
  generateTournamentConfigurationCreateFormPayload,
  validatePrizeEntriesHandler,
  parseFromCurrencyToNumberHandler,
};
