import { Outlet } from 'react-router';
import { faGift } from '@fortawesome/free-solid-svg-icons';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import LoyaltyCredits from 'src/pages/OnlineCasino/Loyalty/LoyaltyCredits/LoyaltyCredits';
import LoyaltyShop from 'src/pages/OnlineCasino/Loyalty/LoyaltyShop/LoyaltyShop';
import RankUsers from 'src/pages/OnlineCasino/Loyalty/Ranks/Components/RankUsers';
import Ranks from 'src/pages/OnlineCasino/Loyalty/Ranks/Ranks';

import LoyaltyBoosters from '../../pages/OnlineCasino/Loyalty/LoyaltyBoosters/LoyaltyBoosters';
import { BaseRoute } from '../RouteInterfaces';

export const loyaltyRoutes: Array<BaseRoute> = [
  {
    path: 'loyalty',
    name: 'Loyalty',
    icon: faGift,
    collapse: true,
    element: Outlet,
    permissions: [
      OnlineCasinoPermissions.getAllLoyaltyShopItems,
      OnlineCasinoPermissions.getLoyaltyRankDistributions,
      OnlineCasinoPermissions.getLoyaltyTotalBalances,
      OnlineCasinoPermissions.getLoyaltyBoosters,
    ],
    featureFlag: 'isLoyaltyEnabled',
    children: [
      {
        path: 'ranks',
        name: 'Ranks',
        element: Ranks,
        mini: 'R',
        permissions: OnlineCasinoPermissions.getLoyaltyRankDistributions,
        featureFlag: 'isLoyaltyEnabled',
      },
      {
        path: 'ranks/:rank',
        name: 'User Ranks',
        element: RankUsers,
        hiddenLink: true,
        permissions: OnlineCasinoPermissions.getLoyaltyRankDistributions,
        featureFlag: 'isLoyaltyEnabled',
      },
      {
        path: 'loyalty-credits',
        name: 'Loyalty Credits',
        element: LoyaltyCredits,
        mini: 'LC',
        permissions: OnlineCasinoPermissions.getLoyaltyTotalBalances,
        featureFlag: 'isLoyaltyEnabled',
      },
      {
        name: 'Loyalty Shop',
        path: 'loyalty-shop',
        element: LoyaltyShop,
        permissions: OnlineCasinoPermissions.getAllLoyaltyShopItems,
        mini: 'LS',
        featureFlag: 'isLoyaltyEnabled',
      },
      {
        name: 'Boosters',
        path: 'boosters',
        element: LoyaltyBoosters,
        mini: 'LB',
        permissions: OnlineCasinoPermissions.getLoyaltyBoosters,
        featureFlag: 'isLoyaltyEnabled',
      },
    ],
  },
];

export default loyaltyRoutes;
