import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

type Props = {
  title: string;
  content: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onConfirm: () => void;
};

const ConfirmDialog = ({ title, isOpen, setIsOpen, onConfirm, content }: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog maxWidth="sm" open={isOpen} onClose={() => setIsOpen(false)} aria-labelledby="confirm-dialog">
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions sx={{ '.MuiDialogActions-root': { padding: '16px 24px' } }}>
        <Button color="secondary" onClick={() => setIsOpen(false)}>
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setIsOpen(false);
            onConfirm();
          }}
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
