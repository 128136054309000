/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EWithdrawFlagSchema = (typeof EWithdrawFlagSchema)[keyof typeof EWithdrawFlagSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EWithdrawFlagSchema = {
  Unknown: 'Unknown',
  Allowed: 'Allowed',
  Blocked: 'Blocked',
  Suspicious: 'Suspicious',
  ToBeChecked: 'ToBeChecked',
  Safe: 'Safe',
} as const;
