import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMediaGalleryEntryTypeSchema } from '@greenisland-api';
import { Box, Typography } from '@mui/material';

import MediaField from '@greenisland-common/components/molecules/MediaField/MediaField';

const images = {
  background: EMediaGalleryEntryTypeSchema.GameBackground,
  thumbnailSquare: EMediaGalleryEntryTypeSchema.GameThumbnailSquare,
  thumbnailLandscape: EMediaGalleryEntryTypeSchema.GameThumbnailLandscape,
  thumbnailPortrait: EMediaGalleryEntryTypeSchema.GameThumbnailPortrait,
} as const;

const EditGameImages = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
      {Object.entries(images).map(([name, type]) => (
        <Box key={name} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="caption">{t(`content.games.games.${name}`)}</Typography>
          <MediaField control={control} name={`${name}Url`} mediaGalleryEntryType={type} />
        </Box>
      ))}
    </Box>
  );
};

export default EditGameImages;
