/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  DefaultResponse,
  DepositDetailsSchema,
  DepositManuallyDataSchema,
  DepositManuallySchema,
  DepositsSchema,
  DepositStatusesSchema,
  DepositSummaryErrorSchema,
  GetDepositDetailsParams,
  GetDepositsGeneralParams,
  GetDepositsGeneralResultSchema,
  GetDepositsSummaryParams,
  GetOnlineDepositsForUserParams,
  GetOnlineDepositsParams,
  GetOnlineDepositStatusesParams,
  NotFoundResponse,
  PagedTransactionSummarySchema,
  RefundDepositManuallyDataSchema,
  RefundDepositManuallySchema,
  TransactionsBadRequestResponse,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Gets all deposits for the given filters
 */
export const useGetDepositsGeneralHook = () => {
  const getDepositsGeneral = useCustomInstance<GetDepositsGeneralResultSchema>();

  return (params?: GetDepositsGeneralParams, signal?: AbortSignal) => {
    return getDepositsGeneral({ url: `/deposits`, method: 'get', params, signal });
  };
};

export const getGetDepositsGeneralQueryKey = (params?: GetDepositsGeneralParams) =>
  [`/deposits`, ...(params ? [params] : [])] as const;

export const useGetDepositsGeneralQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDepositsGeneralHook>>>,
  TError = TransactionsBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetDepositsGeneralParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositsGeneralHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositsGeneralHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDepositsGeneralQueryKey(params);

  const getDepositsGeneral = useGetDepositsGeneralHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDepositsGeneralHook>>>> = ({ signal }) =>
    getDepositsGeneral(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDepositsGeneralQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetDepositsGeneralHook>>>
>;
export type GetDepositsGeneralQueryError =
  | TransactionsBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Gets all deposits for the given filters
 */
export const useGetDepositsGeneral = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDepositsGeneralHook>>>,
  TError = TransactionsBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetDepositsGeneralParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositsGeneralHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDepositsGeneralQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get overview of deposits
 */
export const useGetOnlineDepositsHook = () => {
  const getOnlineDeposits = useCustomInstance<DepositsSchema>();

  return (params?: GetOnlineDepositsParams, signal?: AbortSignal) => {
    return getOnlineDeposits({ url: `/deposits/onlinedeposits`, method: 'get', params, signal });
  };
};

export const getGetOnlineDepositsQueryKey = (params?: GetOnlineDepositsParams) =>
  [`/deposits/onlinedeposits`, ...(params ? [params] : [])] as const;

export const useGetOnlineDepositsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetOnlineDepositsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOnlineDepositsQueryKey(params);

  const getOnlineDeposits = useGetOnlineDepositsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositsHook>>>> = ({ signal }) =>
    getOnlineDeposits(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetOnlineDepositsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositsHook>>>
>;
export type GetOnlineDepositsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get overview of deposits
 */
export const useGetOnlineDeposits = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetOnlineDepositsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetOnlineDepositsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get overview of deposits for a specific user
 */
export const useGetOnlineDepositsForUserHook = () => {
  const getOnlineDepositsForUser = useCustomInstance<DepositsSchema>();

  return (userId: number, params?: GetOnlineDepositsForUserParams, signal?: AbortSignal) => {
    return getOnlineDepositsForUser({ url: `/deposits/onlinedeposits/${userId}`, method: 'get', params, signal });
  };
};

export const getGetOnlineDepositsForUserQueryKey = (userId: number, params?: GetOnlineDepositsForUserParams) =>
  [`/deposits/onlinedeposits/${userId}`, ...(params ? [params] : [])] as const;

export const useGetOnlineDepositsForUserQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositsForUserHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params?: GetOnlineDepositsForUserParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositsForUserHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositsForUserHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOnlineDepositsForUserQueryKey(userId, params);

  const getOnlineDepositsForUser = useGetOnlineDepositsForUserHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositsForUserHook>>>> = ({
    signal,
  }) => getOnlineDepositsForUser(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetOnlineDepositsForUserQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositsForUserHook>>>
>;
export type GetOnlineDepositsForUserQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get overview of deposits for a specific user
 */
export const useGetOnlineDepositsForUser = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositsForUserHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params?: GetOnlineDepositsForUserParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositsForUserHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetOnlineDepositsForUserQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Deposit manually for given user
 */
export const useDepositManuallyHook = () => {
  const depositManually = useCustomInstance<DepositManuallySchema>();

  return (depositManuallyDataSchema: DepositManuallyDataSchema) => {
    return depositManually({
      url: `/deposits/manually`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: depositManuallyDataSchema,
    });
  };
};

export const useDepositManuallyMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDepositManuallyHook>>>,
    TError,
    { data: DepositManuallyDataSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDepositManuallyHook>>>,
  TError,
  { data: DepositManuallyDataSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const depositManually = useDepositManuallyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDepositManuallyHook>>>,
    { data: DepositManuallyDataSchema }
  > = props => {
    const { data } = props ?? {};

    return depositManually(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DepositManuallyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDepositManuallyHook>>>>;
export type DepositManuallyMutationBody = DepositManuallyDataSchema;
export type DepositManuallyMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Deposit manually for given user
 */
export const useDepositManually = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDepositManuallyHook>>>,
    TError,
    { data: DepositManuallyDataSchema },
    TContext
  >;
}) => {
  const mutationOptions = useDepositManuallyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Refund a deposit manually for given user
 */
export const useRefundDepositManuallyHook = () => {
  const refundDepositManually = useCustomInstance<RefundDepositManuallySchema>();

  return (refundDepositManuallyDataSchema: RefundDepositManuallyDataSchema) => {
    return refundDepositManually({
      url: `/deposits/refundmanually`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: refundDepositManuallyDataSchema,
    });
  };
};

export const useRefundDepositManuallyMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRefundDepositManuallyHook>>>,
    TError,
    { data: RefundDepositManuallyDataSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRefundDepositManuallyHook>>>,
  TError,
  { data: RefundDepositManuallyDataSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const refundDepositManually = useRefundDepositManuallyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRefundDepositManuallyHook>>>,
    { data: RefundDepositManuallyDataSchema }
  > = props => {
    const { data } = props ?? {};

    return refundDepositManually(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RefundDepositManuallyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRefundDepositManuallyHook>>>
>;
export type RefundDepositManuallyMutationBody = RefundDepositManuallyDataSchema;
export type RefundDepositManuallyMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Refund a deposit manually for given user
 */
export const useRefundDepositManually = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRefundDepositManuallyHook>>>,
    TError,
    { data: RefundDepositManuallyDataSchema },
    TContext
  >;
}) => {
  const mutationOptions = useRefundDepositManuallyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Set the state of a deposit to chargedback manually
 */
export const useChargeBackDepositManuallyHook = () => {
  const chargeBackDepositManually = useCustomInstance<void>();

  return (depositId: string) => {
    return chargeBackDepositManually({ url: `/deposits/${depositId}/chargebackmanually`, method: 'put' });
  };
};

export const useChargeBackDepositManuallyMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useChargeBackDepositManuallyHook>>>,
    TError,
    { depositId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useChargeBackDepositManuallyHook>>>,
  TError,
  { depositId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const chargeBackDepositManually = useChargeBackDepositManuallyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useChargeBackDepositManuallyHook>>>,
    { depositId: string }
  > = props => {
    const { depositId } = props ?? {};

    return chargeBackDepositManually(depositId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChargeBackDepositManuallyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useChargeBackDepositManuallyHook>>>
>;

export type ChargeBackDepositManuallyMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Set the state of a deposit to chargedback manually
 */
export const useChargeBackDepositManually = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useChargeBackDepositManuallyHook>>>,
    TError,
    { depositId: string },
    TContext
  >;
}) => {
  const mutationOptions = useChargeBackDepositManuallyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Confirm that a charged back deposit dispute has been lost
 */
export const useConfirmDepositDisputeLostHook = () => {
  const confirmDepositDisputeLost = useCustomInstance<void>();

  return (depositId: string) => {
    return confirmDepositDisputeLost({ url: `/deposits/${depositId}/confirmdisputelost`, method: 'put' });
  };
};

export const useConfirmDepositDisputeLostMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useConfirmDepositDisputeLostHook>>>,
    TError,
    { depositId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useConfirmDepositDisputeLostHook>>>,
  TError,
  { depositId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const confirmDepositDisputeLost = useConfirmDepositDisputeLostHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useConfirmDepositDisputeLostHook>>>,
    { depositId: string }
  > = props => {
    const { depositId } = props ?? {};

    return confirmDepositDisputeLost(depositId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfirmDepositDisputeLostMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useConfirmDepositDisputeLostHook>>>
>;

export type ConfirmDepositDisputeLostMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Confirm that a charged back deposit dispute has been lost
 */
export const useConfirmDepositDisputeLost = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useConfirmDepositDisputeLostHook>>>,
    TError,
    { depositId: string },
    TContext
  >;
}) => {
  const mutationOptions = useConfirmDepositDisputeLostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Confirm that a charged back deposit dispute has been won
 */
export const useConfirmDepositDisputeWonHook = () => {
  const confirmDepositDisputeWon = useCustomInstance<void>();

  return (depositId: string) => {
    return confirmDepositDisputeWon({ url: `/deposits/${depositId}/confirmdisputewon`, method: 'put' });
  };
};

export const useConfirmDepositDisputeWonMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useConfirmDepositDisputeWonHook>>>,
    TError,
    { depositId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useConfirmDepositDisputeWonHook>>>,
  TError,
  { depositId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const confirmDepositDisputeWon = useConfirmDepositDisputeWonHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useConfirmDepositDisputeWonHook>>>,
    { depositId: string }
  > = props => {
    const { depositId } = props ?? {};

    return confirmDepositDisputeWon(depositId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConfirmDepositDisputeWonMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useConfirmDepositDisputeWonHook>>>
>;

export type ConfirmDepositDisputeWonMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Confirm that a charged back deposit dispute has been won
 */
export const useConfirmDepositDisputeWon = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useConfirmDepositDisputeWonHook>>>,
    TError,
    { depositId: string },
    TContext
  >;
}) => {
  const mutationOptions = useConfirmDepositDisputeWonMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mark a deposit failed
 * @summary Mark a deposit failed
 */
export const useFailDepositHook = () => {
  const failDeposit = useCustomInstance<void>();

  return (depositId: string) => {
    return failDeposit({ url: `/deposits/${depositId}/fail`, method: 'put' });
  };
};

export const useFailDepositMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFailDepositHook>>>,
    TError,
    { depositId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useFailDepositHook>>>,
  TError,
  { depositId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const failDeposit = useFailDepositHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useFailDepositHook>>>,
    { depositId: string }
  > = props => {
    const { depositId } = props ?? {};

    return failDeposit(depositId);
  };

  return { mutationFn, ...mutationOptions };
};

export type FailDepositMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useFailDepositHook>>>>;

export type FailDepositMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Mark a deposit failed
 */
export const useFailDeposit = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useFailDepositHook>>>,
    TError,
    { depositId: string },
    TContext
  >;
}) => {
  const mutationOptions = useFailDepositMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets the overview of the deposits in process
 */
export const useGetOnlineDepositStatusesHook = () => {
  const getOnlineDepositStatuses = useCustomInstance<DepositStatusesSchema>();

  return (params?: GetOnlineDepositStatusesParams, signal?: AbortSignal) => {
    return getOnlineDepositStatuses({ url: `/deposits/onlinedeposits/statuses`, method: 'get', params, signal });
  };
};

export const getGetOnlineDepositStatusesQueryKey = (params?: GetOnlineDepositStatusesParams) =>
  [`/deposits/onlinedeposits/statuses`, ...(params ? [params] : [])] as const;

export const useGetOnlineDepositStatusesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositStatusesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetOnlineDepositStatusesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositStatusesHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositStatusesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOnlineDepositStatusesQueryKey(params);

  const getOnlineDepositStatuses = useGetOnlineDepositStatusesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositStatusesHook>>>> = ({
    signal,
  }) => getOnlineDepositStatuses(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetOnlineDepositStatusesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositStatusesHook>>>
>;
export type GetOnlineDepositStatusesQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Gets the overview of the deposits in process
 */
export const useGetOnlineDepositStatuses = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositStatusesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetOnlineDepositStatusesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOnlineDepositStatusesHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetOnlineDepositStatusesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get sum of deposits with filters
 */
export const useGetDepositsSummaryHook = () => {
  const getDepositsSummary = useCustomInstance<PagedTransactionSummarySchema>();

  return (params?: GetDepositsSummaryParams, signal?: AbortSignal) => {
    return getDepositsSummary({ url: `/deposits/summary`, method: 'get', params, signal });
  };
};

export const getGetDepositsSummaryQueryKey = (params?: GetDepositsSummaryParams) =>
  [`/deposits/summary`, ...(params ? [params] : [])] as const;

export const useGetDepositsSummaryQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDepositsSummaryHook>>>,
  TError = DepositSummaryErrorSchema | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetDepositsSummaryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositsSummaryHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositsSummaryHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDepositsSummaryQueryKey(params);

  const getDepositsSummary = useGetDepositsSummaryHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDepositsSummaryHook>>>> = ({ signal }) =>
    getDepositsSummary(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDepositsSummaryQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetDepositsSummaryHook>>>
>;
export type GetDepositsSummaryQueryError =
  | DepositSummaryErrorSchema
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get sum of deposits with filters
 */
export const useGetDepositsSummary = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDepositsSummaryHook>>>,
  TError = DepositSummaryErrorSchema | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetDepositsSummaryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositsSummaryHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDepositsSummaryQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Manually complete a user deposit
 */
export const useCompleteDepositManuallyHook = () => {
  const completeDepositManually = useCustomInstance<DepositDetailsSchema>();

  return (userId: string, depositId: string) => {
    return completeDepositManually({ url: `/deposits/${userId}/complete/${depositId}`, method: 'post' });
  };
};

export const useCompleteDepositManuallyMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | void | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompleteDepositManuallyHook>>>,
    TError,
    { userId: string; depositId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCompleteDepositManuallyHook>>>,
  TError,
  { userId: string; depositId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const completeDepositManually = useCompleteDepositManuallyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCompleteDepositManuallyHook>>>,
    { userId: string; depositId: string }
  > = props => {
    const { userId, depositId } = props ?? {};

    return completeDepositManually(userId, depositId);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompleteDepositManuallyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCompleteDepositManuallyHook>>>
>;

export type CompleteDepositManuallyMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | void
  | DefaultResponse;

/**
 * @summary Manually complete a user deposit
 */
export const useCompleteDepositManually = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | void | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompleteDepositManuallyHook>>>,
    TError,
    { userId: string; depositId: string },
    TContext
  >;
}) => {
  const mutationOptions = useCompleteDepositManuallyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary get details on user deposits
 */
export const useGetDepositDetailsHook = () => {
  const getDepositDetails = useCustomInstance<DepositDetailsSchema>();

  return (userId: string, depositId: string, params?: GetDepositDetailsParams, signal?: AbortSignal) => {
    return getDepositDetails({ url: `/deposits/${userId}/details/${depositId}`, method: 'get', params, signal });
  };
};

export const getGetDepositDetailsQueryKey = (userId: string, depositId: string, params?: GetDepositDetailsParams) =>
  [`/deposits/${userId}/details/${depositId}`, ...(params ? [params] : [])] as const;

export const useGetDepositDetailsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDepositDetailsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  depositId: string,
  params?: GetDepositDetailsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositDetailsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositDetailsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDepositDetailsQueryKey(userId, depositId, params);

  const getDepositDetails = useGetDepositDetailsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDepositDetailsHook>>>> = ({ signal }) =>
    getDepositDetails(userId, depositId, params, signal);

  return { queryKey, queryFn, enabled: !!(userId && depositId), ...queryOptions };
};

export type GetDepositDetailsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetDepositDetailsHook>>>
>;
export type GetDepositDetailsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary get details on user deposits
 */
export const useGetDepositDetails = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDepositDetailsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  depositId: string,
  params?: GetDepositDetailsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositDetailsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDepositDetailsQueryOptions(userId, depositId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
