/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type GameSessionsSummarySortKeyParamParameter =
  (typeof GameSessionsSummarySortKeyParamParameter)[keyof typeof GameSessionsSummarySortKeyParamParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GameSessionsSummarySortKeyParamParameter = {
  TotalBetAmount: 'TotalBetAmount',
  TotalWinAmount: 'TotalWinAmount',
  GamingRevenue: 'GamingRevenue',
  Rtp: 'Rtp',
} as const;
