/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  DefaultResponse,
  GetAllLoyaltyShopItemsParams,
  LoyaltyShopArgumentsSchema,
  LoyaltyShopItemArgumentsSchema,
  LoyaltyShopManagementBadRequestResponse,
  LoyaltyShopsSchema,
  NotFoundResponse,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get the list of loyalty shop items
 */
export const useGetAllLoyaltyShopItemsHook = () => {
  const getAllLoyaltyShopItems = useCustomInstance<LoyaltyShopsSchema>();

  return (params?: GetAllLoyaltyShopItemsParams, signal?: AbortSignal) => {
    return getAllLoyaltyShopItems({ url: `/loyalty/v2/shop`, method: 'get', params, signal });
  };
};

export const getGetAllLoyaltyShopItemsQueryKey = (params?: GetAllLoyaltyShopItemsParams) =>
  [`/loyalty/v2/shop`, ...(params ? [params] : [])] as const;

export const useGetAllLoyaltyShopItemsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAllLoyaltyShopItemsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetAllLoyaltyShopItemsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllLoyaltyShopItemsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllLoyaltyShopItemsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllLoyaltyShopItemsQueryKey(params);

  const getAllLoyaltyShopItems = useGetAllLoyaltyShopItemsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllLoyaltyShopItemsHook>>>> = ({ signal }) =>
    getAllLoyaltyShopItems(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAllLoyaltyShopItemsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAllLoyaltyShopItemsHook>>>
>;
export type GetAllLoyaltyShopItemsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the list of loyalty shop items
 */
export const useGetAllLoyaltyShopItems = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAllLoyaltyShopItemsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetAllLoyaltyShopItemsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllLoyaltyShopItemsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAllLoyaltyShopItemsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Add a new item to the loyalty shop
 */
export const useAddLoyaltyShopItemHook = () => {
  const addLoyaltyShopItem = useCustomInstance<void>();

  return (loyaltyShopArgumentsSchema: LoyaltyShopArgumentsSchema) => {
    return addLoyaltyShopItem({
      url: `/loyalty/v2/shop`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: loyaltyShopArgumentsSchema,
    });
  };
};

export const useAddLoyaltyShopItemMutationOptions = <
  TError = LoyaltyShopManagementBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddLoyaltyShopItemHook>>>,
    TError,
    { data: LoyaltyShopArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddLoyaltyShopItemHook>>>,
  TError,
  { data: LoyaltyShopArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addLoyaltyShopItem = useAddLoyaltyShopItemHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddLoyaltyShopItemHook>>>,
    { data: LoyaltyShopArgumentsSchema }
  > = props => {
    const { data } = props ?? {};

    return addLoyaltyShopItem(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddLoyaltyShopItemMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddLoyaltyShopItemHook>>>
>;
export type AddLoyaltyShopItemMutationBody = LoyaltyShopArgumentsSchema;
export type AddLoyaltyShopItemMutationError =
  | LoyaltyShopManagementBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Add a new item to the loyalty shop
 */
export const useAddLoyaltyShopItem = <
  TError = LoyaltyShopManagementBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddLoyaltyShopItemHook>>>,
    TError,
    { data: LoyaltyShopArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddLoyaltyShopItemMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update the loyalty shop item
 */
export const useUpdateLoyaltyShopItemV2Hook = () => {
  const updateLoyaltyShopItemV2 = useCustomInstance<void>();

  return (loyaltyShopItemId: string, loyaltyShopItemArgumentsSchema: LoyaltyShopItemArgumentsSchema) => {
    return updateLoyaltyShopItemV2({
      url: `/loyalty/v2/shop/${loyaltyShopItemId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: loyaltyShopItemArgumentsSchema,
    });
  };
};

export const useUpdateLoyaltyShopItemV2MutationOptions = <
  TError = LoyaltyShopManagementBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateLoyaltyShopItemV2Hook>>>,
    TError,
    { loyaltyShopItemId: string; data: LoyaltyShopItemArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateLoyaltyShopItemV2Hook>>>,
  TError,
  { loyaltyShopItemId: string; data: LoyaltyShopItemArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateLoyaltyShopItemV2 = useUpdateLoyaltyShopItemV2Hook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateLoyaltyShopItemV2Hook>>>,
    { loyaltyShopItemId: string; data: LoyaltyShopItemArgumentsSchema }
  > = props => {
    const { loyaltyShopItemId, data } = props ?? {};

    return updateLoyaltyShopItemV2(loyaltyShopItemId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateLoyaltyShopItemV2MutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateLoyaltyShopItemV2Hook>>>
>;
export type UpdateLoyaltyShopItemV2MutationBody = LoyaltyShopItemArgumentsSchema;
export type UpdateLoyaltyShopItemV2MutationError =
  | LoyaltyShopManagementBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Update the loyalty shop item
 */
export const useUpdateLoyaltyShopItemV2 = <
  TError = LoyaltyShopManagementBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateLoyaltyShopItemV2Hook>>>,
    TError,
    { loyaltyShopItemId: string; data: LoyaltyShopItemArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateLoyaltyShopItemV2MutationOptions(options);

  return useMutation(mutationOptions);
};
