/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type { GetJackpotConfigurationsParams, JackpotConfigurationPaginated } from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Get the jackpot configurations
 */
export const useGetJackpotConfigurationsHook = () => {
  const getJackpotConfigurations = useCustomInstance<JackpotConfigurationPaginated>();

  return (params?: GetJackpotConfigurationsParams, signal?: AbortSignal) => {
    return getJackpotConfigurations({ url: `/jackpot-configurations`, method: 'get', params, signal });
  };
};

export const getGetJackpotConfigurationsQueryKey = (params?: GetJackpotConfigurationsParams) =>
  [`/jackpot-configurations`, ...(params ? [params] : [])] as const;

export const useGetJackpotConfigurationsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetJackpotConfigurationsHook>>>,
  TError = unknown
>(
  params?: GetJackpotConfigurationsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJackpotConfigurationsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJackpotConfigurationsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetJackpotConfigurationsQueryKey(params);

  const getJackpotConfigurations = useGetJackpotConfigurationsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetJackpotConfigurationsHook>>>> = ({
    signal,
  }) => getJackpotConfigurations(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetJackpotConfigurationsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetJackpotConfigurationsHook>>>
>;
export type GetJackpotConfigurationsQueryError = unknown;

export const useGetJackpotConfigurations = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetJackpotConfigurationsHook>>>,
  TError = unknown
>(
  params?: GetJackpotConfigurationsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJackpotConfigurationsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetJackpotConfigurationsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
