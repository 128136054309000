import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import {
  EActionKeySchema,
  getGetEventstreamStreamIdQueryKey,
  getGetUserRegistrationMessagesQueryKey,
  getGetUserRegistrationQueryKey,
  useAddUserRegistrationActionKey,
  UserCruksRegistrationSchema,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, List, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';

import { formatDateTimeFromUnix } from '@greenisland-common/helpers';

import { usePermission } from '../../../../../../../app/hooks';
import RegistrationCriticalFieldsMismatch from './RegistrationCriticalFieldsMismatch';
import RegistrationSummaryListItem from './RegistrationSummaryListItem';

interface Props {
  registration: UserCruksRegistrationSchema;
}

const RegistrationSummaryListKsa = ({ registration }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { userId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const canHandleActionKey = usePermission(OnlineCasinoPermissions.addUserRegistrationActionKey);
  const mutation = useAddUserRegistrationActionKey({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetUserRegistrationQueryKey(userId));
        queryClient.invalidateQueries(getGetUserRegistrationMessagesQueryKey(userId));
        queryClient.invalidateQueries(
          getGetEventstreamStreamIdQueryKey({ streamId: `ksaplayerregistration/${userId}` })
        );
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  return (
    <List dense>
      <RegistrationSummaryListItem title={t('crukscode')} value={registration.cruksCode} />
      <RegistrationSummaryListItem
        title={t('registeredInCruks')}
        value={
          <Box component="span" sx={{ color: registration.isRegisteredInCruks ? 'primary.main' : 'error.main' }}>
            {registration.isRegisteredInCruks ? t(registration.isRegisteredInCruks.toString()) : '-'}
          </Box>
        }
      />
      <RegistrationSummaryListItem
        title={t('allowedGames')}
        value={
          <Box component="span" sx={{ color: registration.registration.allowedGames ? 'primary.main' : 'error.main' }}>
            {t(registration.registration.allowedGames.toString())}
          </Box>
        }
      />
      <RegistrationSummaryListItem
        title={t('allowedSports')}
        value={
          <Box component="span" sx={{ color: registration.registration.allowedSports ? 'primary.main' : 'error.main' }}>
            {t(registration.registration.allowedSports.toString())}
          </Box>
        }
      />
      <RegistrationSummaryListItem title={t('lastLoginIp')} value={registration.registration.lastLoginIp} />
      <RegistrationSummaryListItem
        title={t('lastValidation')}
        value={
          registration.registration.lastValidation > 0
            ? formatDateTimeFromUnix(registration.registration.lastValidation)
            : t('noValue')
        }
      />
      <RegistrationSummaryListItem
        title={t('actions')}
        value={
          canHandleActionKey ? (
            <Stack direction="row" spacing={2}>
              {registration.registration.actionKeys.map((key: EActionKeySchema) => (
                <Button
                  key={key}
                  size="small"
                  color={key === EActionKeySchema.remove ? 'error' : 'primary'}
                  onClick={() => mutation.mutate({ userId, actionKey: key })}
                  variant="contained"
                >
                  {t(key)}
                </Button>
              ))}
            </Stack>
          ) : null
        }
      />
      <RegistrationCriticalFieldsMismatch />
    </List>
  );
};

export default RegistrationSummaryListKsa;
