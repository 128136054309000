import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Permission } from '@greenisland-types/authorization';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from '@mui/material';

import { usePermission } from '../../../../../../../app/hooks';

interface Props {
  value?: boolean | string;
  tooltip?: string;
  description?: string;
  error?: boolean;
  disabled?: boolean;
  withReason?: boolean;
  reasonValue?: string;
  reasonLabel?: string;
  togglePermissions?: Permission;
  onToggle?: (reason?: string) => void;
}

const GenericField = ({
  value,
  tooltip,
  description,
  error,
  disabled,
  withReason,
  reasonValue,
  reasonLabel,
  togglePermissions,
  onToggle,
}: Props) => {
  const { t } = useTranslation();
  const canToggle = usePermission(togglePermissions ?? []);
  const [isOpen, setOpen] = useState(false);
  const [isBlurred, setBlurred] = useState(false);
  const [reason, setReason] = useState('');
  const showError = isBlurred && !reason;

  const confirm = useCallback(() => {
    if (withReason && !reason) {
      setBlurred(true);
    }
    if (reasonValue && reason !== reasonValue) {
      setBlurred(true);
    } else {
      withReason ? onToggle?.(reason) : onToggle?.();
      setOpen(false);
    }
  }, [onToggle, setOpen, withReason, reason, reasonValue]);

  useEffect(() => {
    setReason('');
    setBlurred(false);
  }, [setReason, setBlurred, isOpen]);

  return value !== undefined ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        ...(error && { color: 'error.main' }),
        ...(!error && { color: 'success.main' }),
      }}
    >
      {typeof value === 'string' ? value : t(String(value))}
      {canToggle && onToggle ? (
        <>
          <Tooltip arrow title={tooltip || ''}>
            <Box ml="auto">
              <Button
                sx={{ minWidth: 'fit-content' }}
                disabled={disabled}
                color="secondary"
                variant="text"
                size="small"
                onClick={() => setOpen(true)}
              >
                <FontAwesomeIcon icon={faPen} />
              </Button>
            </Box>
          </Tooltip>
          <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={() => setOpen(false)}>
            <DialogTitle>{tooltip}</DialogTitle>
            <DialogContent>
              <Box>{description}</Box>
              {withReason || reasonValue ? (
                <TextField
                  sx={{ mt: 4, width: '100%' }}
                  value={reason}
                  error={showError}
                  helperText={showError ? t('lookup.verification.reasonRequired') : undefined}
                  required
                  label={reasonLabel || t('lookup.verification.reason')}
                  onChange={event => setReason(event.target.value)}
                  onBlur={() => setBlurred(true)}
                />
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="error" variant="outlined">
                {t('cancel')}
              </Button>
              <Button onClick={confirm} variant="contained">
                {t('confirm')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}
    </Box>
  ) : null;
};

export default GenericField;
