import { useEffect, useState } from 'react';
import { Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { endOfDay, getUnixTime, startOfDay } from 'date-fns';
import { t } from 'i18next';

import { DATE_INPUT_FORMAT, DATE_INPUT_MASK } from '@greenisland-common/components/atoms/Constants';

interface Props {
  unixStartDate?: number;
  unixEndDate?: number;
  onChange: (start: number, end: number) => void;
}

export const DateRangePicker = ({ onChange, unixStartDate, unixEndDate }: Props) => {
  const [startDate, setStartDate] = useState<Date | undefined>(
    unixStartDate ? new Date(unixStartDate) : startOfDay(new Date())
  );
  const [endDate, setEndDate] = useState<Date | undefined>(unixEndDate ? new Date(unixEndDate) : endOfDay(new Date()));

  useEffect(() => {
    if (startDate && endDate) {
      const startDateUnix = getUnixTime(startOfDay(startDate));
      const endDateUnix = getUnixTime(endOfDay(endDate));
      onChange(startDateUnix, endDateUnix);
    }
  }, [startDate, endDate]);

  return (
    <Stack direction="row" spacing={1}>
      <DatePicker
        label={t('startDate')}
        onChange={(date: Date | null) => {
          setStartDate(date ?? undefined);
        }}
        value={startDate ?? null}
        renderInput={params => <TextField size="small" {...params} />}
        inputFormat={DATE_INPUT_FORMAT}
        mask={DATE_INPUT_MASK}
      />
      <DatePicker
        label={t('endDate')}
        onChange={(date: Date | null) => {
          setEndDate(date ?? undefined);
        }}
        value={endDate ?? null}
        renderInput={params => <TextField size="small" {...params} />}
        inputFormat={DATE_INPUT_FORMAT}
        mask={DATE_INPUT_MASK}
      />
    </Stack>
  );
};
