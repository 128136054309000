const config: any = {};
export default config;

async function load() {
  const myHeaders = new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });

  if (process.env.NODE_ENV === 'production') {
    return fetch('/config.json', {
      headers: myHeaders,
    })
      .then(result => {
        return result.json();
      })
      .then((newconfig: any) => {
        for (const prop in config) {
          delete config[prop];
        }
        for (const prop in newconfig) {
          config[prop] = newconfig[prop];
        }
        return config;
      })
      .catch(e => console.error(e));
  } else {
    return import('./config.local.json').then((newconfig: any) => {
      for (const prop in config) {
        delete config[prop];
      }
      for (const prop in newconfig) {
        config[prop] = newconfig[prop];
      }
      return config;
    });
  }
}
export { load };
