/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * State of the responsible gaming test response process:
* `unprocessed` - Responsible gaming test response has not been processed yet. * `processed` - Responsible gaming test response has been processed. * `waitingForManualReview` - Responsible gaming test response awaits manual review

 */
export type EResponsibleGamingTestResponseStateSchema =
  (typeof EResponsibleGamingTestResponseStateSchema)[keyof typeof EResponsibleGamingTestResponseStateSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EResponsibleGamingTestResponseStateSchema = {
  unprocessed: 'unprocessed',
  processed: 'processed',
  waitingForManualReview: 'waitingForManualReview',
} as const;
