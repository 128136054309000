import { useTranslation } from 'react-i18next';
import { SortOrderParamParameter } from '@greenisland-api';
import { Box, capitalize, TextField, useTheme } from '@mui/material';

import Select from '@greenisland-common/components/atoms/Select';

import { useUpdateSearchParams } from '@greenisland-common/hooks/useUpdateSearchParams';

import {
  TestResponsesSearchFilters,
  useGetTestResponsesFiltersQuery,
} from './helpers/responsibleGamingTestResponsesHooks';
import { GameSummarySearchFilters } from '../../GameSessionsPage/Constants';

const ResponsibleGamingTestResponsesFilters = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const updateTestResponsesQueryParamsHandler = useUpdateSearchParams();

  const { idQuery, userIdQuery, sortByOrderQuery } = useGetTestResponsesFiltersQuery();

  return (
    <Box
      display="flex"
      gap={2}
      sx={{
        [theme.breakpoints.up('xs')]: {
          justifyContent: 'flex-start',
        },
        [theme.breakpoints.up('md')]: {
          justifyContent: 'flex-end',
        },
      }}
    >
      <Box
        gap={2}
        display="flex"
        sx={{
          [theme.breakpoints.up('xs')]: {
            width: '100%',
            flexDirection: 'column',
          },
          [theme.breakpoints.up('md')]: {
            width: '50%',
            flexDirection: 'row',
          },
        }}
      >
        <TextField
          fullWidth
          size="small"
          label={t('id')}
          type="text"
          value={idQuery || ''}
          onChange={event => updateTestResponsesQueryParamsHandler(TestResponsesSearchFilters.ID, event.target.value)}
        />
        <TextField
          fullWidth
          size="small"
          label={t('userId')}
          type="text"
          value={userIdQuery || ''}
          onChange={event =>
            updateTestResponsesQueryParamsHandler(TestResponsesSearchFilters.USER_ID, event.target.value)
          }
        />
        <Select
          id={GameSummarySearchFilters.SORT_BY_ORDER}
          label={capitalize(t('sortByOrder'))}
          value={sortByOrderQuery || SortOrderParamParameter.descending}
          onChange={event =>
            updateTestResponsesQueryParamsHandler(TestResponsesSearchFilters.SORT_BY_ORDER, event.target.value)
          }
          options={Object.values(SortOrderParamParameter)}
        />
      </Box>
    </Box>
  );
};

export default ResponsibleGamingTestResponsesFilters;
