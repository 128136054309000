/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  AdvancedSearchSchema,
  AdvancedUserSearchParams,
  AdvancedUserSearchV2Params,
  AllAccountDataSchema,
  AnnualUserReportAcceptedResponseSchema,
  AnnualUserReportResponseSchema,
  AutomaticWithdrawalPermissionSchema,
  AvailableBonusArgumentsSchema,
  AvailableBonusesSchema,
  AwardBonusBadRequestResponse,
  BadRequestAccountResponse,
  BadRequestResponse,
  BadRequestUserResponse,
  Base64FileSchema,
  BasicAccountDataSchema,
  BlacklistedSchema,
  CountryListSchema,
  CreateTestUserBadRequestResponse,
  CreateTestUserRequestSchema,
  CreateTestUserResponseSchema,
  DefaultResponse,
  DepositBonusArgumentsSchema,
  DepositBonusesSchema,
  ExportWithdrawalsAndDepositsHistoryParams,
  FinancialVerificationArgumentsSchema,
  ForbiddenResponse,
  FreeSpinsBonusArgumentsSchema,
  FreeSpinsSchema,
  GamesActivitySchema,
  GetAccountTransactionsParams,
  GetOrCreateAnnualUserReportRequestSchema,
  GetUserAvailableBonusesParams,
  GetUserCruksMessagesParams,
  GetUserDepositBonusesParams,
  GetUserFreeSpinsParams,
  GetUserVaultTransactionsParams,
  GetUserVouchersParams,
  GetUserWithdrawalRequestsParams,
  IdentitySchema,
  NotFoundResponse,
  PagedUserSearchResultsSchema,
  PhoneNumberBadRequestResponse,
  PreconditionFailedResponse,
  PromotionSchema,
  PromoToCashEventsSchema,
  QuickUserSearchParams,
  ReasonArgumentSchema,
  SetRefundSchema,
  TransactionsSchema,
  UnauthorizedResponse,
  UpdatePhoneNumberRequestSchema,
  UpdatePhoneNumberResponseSchema,
  UpdateUserVerificationsIdentityResultSchema,
  UserAccountStatisticsSchema,
  UserCruksMessagesSchema,
  UserCruksRegistrationSchema,
  UserEmailSchema,
  UserGeneralSchema,
  UserLinkedAccountSchema,
  UserLoginSessionSchema,
  UserNotesSchema,
  UserPreferencesSchema,
  UserSearchResultsSchema,
  UserServiceContractApprovalsSchema,
  UserStatusSchema,
  UserVoucherCodeSchema,
  UserVoucherSensitiveInfoSchema,
  UserVouchersSchema,
  VaultTokenBalanceSchema,
  VaultTransactionsSchema,
  VerificationSchema,
  VerificationsDomicileArgumentsSchema,
  VerificationsFinancialArgumentsSchema,
  VerificationsIdentityArgumentsSchema,
  VerificationsSchema,
  VerificationsSolvencyArgumentsSchema,
  VerificationsStatusSchema,
  VerificationStatusArgumentsSchema,
  WithdrawalRequestsSchema,
  WithdrawLimitArgumentsSchema,
  WithdrawLimitsSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Returns you the general data of the requested user
 * @summary Get general user data
 */
export const useGetUserGeneralHook = () => {
  const getUserGeneral = useCustomInstance<UserGeneralSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getUserGeneral({ url: `/users/${userId}/general`, method: 'get', signal });
  };
};

export const getGetUserGeneralQueryKey = (userId: string) => [`/users/${userId}/general`] as const;

export const useGetUserGeneralQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserGeneralHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserGeneralHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserGeneralHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserGeneralQueryKey(userId);

  const getUserGeneral = useGetUserGeneralHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserGeneralHook>>>> = ({ signal }) =>
    getUserGeneral(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserGeneralQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUserGeneralHook>>>>;
export type GetUserGeneralQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get general user data
 */
export const useGetUserGeneral = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserGeneralHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserGeneralHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserGeneralQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * This is used to check the pep/sanction-list
 * @summary Check pep/sanction-list
 */
export const useCheckPepSanctionListHook = () => {
  const checkPepSanctionList = useCustomInstance<UserGeneralSchema>();

  return (userId: string) => {
    return checkPepSanctionList({ url: `/users/${userId}/general`, method: 'post' });
  };
};

export const useCheckPepSanctionListMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCheckPepSanctionListHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCheckPepSanctionListHook>>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const checkPepSanctionList = useCheckPepSanctionListHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCheckPepSanctionListHook>>>,
    { userId: string }
  > = props => {
    const { userId } = props ?? {};

    return checkPepSanctionList(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type CheckPepSanctionListMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCheckPepSanctionListHook>>>
>;

export type CheckPepSanctionListMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Check pep/sanction-list
 */
export const useCheckPepSanctionList = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCheckPepSanctionListHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}) => {
  const mutationOptions = useCheckPepSanctionListMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Endpoint to add / update a phone number for the given user. If try updating a phone number to the same phone number, no effect. Otherwise, verified status is reset.
 * @summary Endpoint to add / update a phone number for the given user.
 */
export const useUpdatePhoneNumberHook = () => {
  const updatePhoneNumber = useCustomInstance<UpdatePhoneNumberResponseSchema>();

  return (userId: number, updatePhoneNumberRequestSchema: UpdatePhoneNumberRequestSchema) => {
    return updatePhoneNumber({
      url: `/users/${userId}/phone-number`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updatePhoneNumberRequestSchema,
    });
  };
};

export const useUpdatePhoneNumberMutationOptions = <
  TError = PhoneNumberBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdatePhoneNumberHook>>>,
    TError,
    { userId: number; data: UpdatePhoneNumberRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdatePhoneNumberHook>>>,
  TError,
  { userId: number; data: UpdatePhoneNumberRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updatePhoneNumber = useUpdatePhoneNumberHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdatePhoneNumberHook>>>,
    { userId: number; data: UpdatePhoneNumberRequestSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return updatePhoneNumber(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePhoneNumberMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdatePhoneNumberHook>>>
>;
export type UpdatePhoneNumberMutationBody = UpdatePhoneNumberRequestSchema;
export type UpdatePhoneNumberMutationError =
  | PhoneNumberBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Endpoint to add / update a phone number for the given user.
 */
export const useUpdatePhoneNumber = <
  TError = PhoneNumberBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdatePhoneNumberHook>>>,
    TError,
    { userId: number; data: UpdatePhoneNumberRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdatePhoneNumberMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Set the new blacklisted boolean value for given user Id
 * @deprecated
 * @summary Set user blacklisted boolean
 */
export const useSetBlacklistedHook = () => {
  const setBlacklisted = useCustomInstance<void>();

  return (userId: string, blacklistedSchema: BlacklistedSchema) => {
    return setBlacklisted({
      url: `/users/${userId}/general/blacklisted`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: blacklistedSchema,
    });
  };
};

export const useSetBlacklistedMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetBlacklistedHook>>>,
    TError,
    { userId: string; data: BlacklistedSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetBlacklistedHook>>>,
  TError,
  { userId: string; data: BlacklistedSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setBlacklisted = useSetBlacklistedHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetBlacklistedHook>>>,
    { userId: string; data: BlacklistedSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return setBlacklisted(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetBlacklistedMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetBlacklistedHook>>>>;
export type SetBlacklistedMutationBody = BlacklistedSchema;
export type SetBlacklistedMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary Set user blacklisted boolean
 */
export const useSetBlacklisted = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetBlacklistedHook>>>,
    TError,
    { userId: string; data: BlacklistedSchema },
    TContext
  >;
}) => {
  const mutationOptions = useSetBlacklistedMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Create a new test user
 * @summary Create a new test user
 */
export const useCreateTestUserHook = () => {
  const createTestUser = useCustomInstance<CreateTestUserResponseSchema>();

  return (createTestUserRequestSchema: CreateTestUserRequestSchema) => {
    return createTestUser({
      url: `/users/testuser`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createTestUserRequestSchema,
    });
  };
};

export const useCreateTestUserMutationOptions = <
  TError = CreateTestUserBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateTestUserHook>>>,
    TError,
    { data: CreateTestUserRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateTestUserHook>>>,
  TError,
  { data: CreateTestUserRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createTestUser = useCreateTestUserHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateTestUserHook>>>,
    { data: CreateTestUserRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return createTestUser(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateTestUserMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateTestUserHook>>>>;
export type CreateTestUserMutationBody = CreateTestUserRequestSchema;
export type CreateTestUserMutationError =
  | CreateTestUserBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Create a new test user
 */
export const useCreateTestUser = <
  TError = CreateTestUserBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateTestUserHook>>>,
    TError,
    { data: CreateTestUserRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useCreateTestUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * This is used to set a new status for the user
 * @summary Change user status
 */
export const useSetUserStatusHook = () => {
  const setUserStatus = useCustomInstance<UserGeneralSchema>();

  return (userId: string, userStatusSchema: UserStatusSchema) => {
    return setUserStatus({
      url: `/users/${userId}/status`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: userStatusSchema,
    });
  };
};

export const useSetUserStatusMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetUserStatusHook>>>,
    TError,
    { userId: string; data: UserStatusSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetUserStatusHook>>>,
  TError,
  { userId: string; data: UserStatusSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setUserStatus = useSetUserStatusHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetUserStatusHook>>>,
    { userId: string; data: UserStatusSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return setUserStatus(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetUserStatusMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetUserStatusHook>>>>;
export type SetUserStatusMutationBody = UserStatusSchema;
export type SetUserStatusMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Change user status
 */
export const useSetUserStatus = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetUserStatusHook>>>,
    TError,
    { userId: string; data: UserStatusSchema },
    TContext
  >;
}) => {
  const mutationOptions = useSetUserStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Disable user self exclusion
 * @deprecated
 * @summary Disable user self exclusion
 */
export const useDisableSelfExclusionHook = () => {
  const disableSelfExclusion = useCustomInstance<UserGeneralSchema>();

  return (userId: string) => {
    return disableSelfExclusion({ url: `/users/${userId}/disableSelfExclusion`, method: 'post' });
  };
};

export const useDisableSelfExclusionMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDisableSelfExclusionHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDisableSelfExclusionHook>>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const disableSelfExclusion = useDisableSelfExclusionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDisableSelfExclusionHook>>>,
    { userId: string }
  > = props => {
    const { userId } = props ?? {};

    return disableSelfExclusion(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DisableSelfExclusionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDisableSelfExclusionHook>>>
>;

export type DisableSelfExclusionMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @deprecated
 * @summary Disable user self exclusion
 */
export const useDisableSelfExclusion = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDisableSelfExclusionHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}) => {
  const mutationOptions = useDisableSelfExclusionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Enable user as test user
 * @summary Enable user as test user
 */
export const useEnableIsTestUserHook = () => {
  const enableIsTestUser = useCustomInstance<UserGeneralSchema>();

  return (userId: string) => {
    return enableIsTestUser({ url: `/users/${userId}/enableIsTestUser`, method: 'post' });
  };
};

export const useEnableIsTestUserMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useEnableIsTestUserHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useEnableIsTestUserHook>>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const enableIsTestUser = useEnableIsTestUserHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useEnableIsTestUserHook>>>,
    { userId: string }
  > = props => {
    const { userId } = props ?? {};

    return enableIsTestUser(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type EnableIsTestUserMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useEnableIsTestUserHook>>>
>;

export type EnableIsTestUserMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Enable user as test user
 */
export const useEnableIsTestUser = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useEnableIsTestUserHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}) => {
  const mutationOptions = useEnableIsTestUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * This is used to set a new email addres for the user
 * @summary Change user email
 */
export const useSetUserEmailHook = () => {
  const setUserEmail = useCustomInstance<UserGeneralSchema>();

  return (userId: string, userEmailSchema: UserEmailSchema) => {
    return setUserEmail({
      url: `/users/${userId}/email`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: userEmailSchema,
    });
  };
};

export const useSetUserEmailMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetUserEmailHook>>>,
    TError,
    { userId: string; data: UserEmailSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetUserEmailHook>>>,
  TError,
  { userId: string; data: UserEmailSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setUserEmail = useSetUserEmailHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetUserEmailHook>>>,
    { userId: string; data: UserEmailSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return setUserEmail(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetUserEmailMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetUserEmailHook>>>>;
export type SetUserEmailMutationBody = UserEmailSchema;
export type SetUserEmailMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Change user email
 */
export const useSetUserEmail = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetUserEmailHook>>>,
    TError,
    { userId: string; data: UserEmailSchema },
    TContext
  >;
}) => {
  const mutationOptions = useSetUserEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * end a verifiction message to a user to verify his/her email
 * @summary Send a verifiction message to a user to verify his/her email
 */
export const useSendUserEmailVerificationHook = () => {
  const sendUserEmailVerification = useCustomInstance<UserGeneralSchema>();

  return (userId: string) => {
    return sendUserEmailVerification({ url: `/users/${userId}/emailverification`, method: 'put' });
  };
};

export const useSendUserEmailVerificationMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSendUserEmailVerificationHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSendUserEmailVerificationHook>>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const sendUserEmailVerification = useSendUserEmailVerificationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSendUserEmailVerificationHook>>>,
    { userId: string }
  > = props => {
    const { userId } = props ?? {};

    return sendUserEmailVerification(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendUserEmailVerificationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSendUserEmailVerificationHook>>>
>;

export type SendUserEmailVerificationMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Send a verifiction message to a user to verify his/her email
 */
export const useSendUserEmailVerification = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSendUserEmailVerificationHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}) => {
  const mutationOptions = useSendUserEmailVerificationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * This is used to try and award a user a welcome bonus pack
 * @summary Try to award welcome bonus pack
 */
export const useSetAwardWelcomeBonusPackHook = () => {
  const setAwardWelcomeBonusPack = useCustomInstance<UserGeneralSchema>();

  return (userId: string) => {
    return setAwardWelcomeBonusPack({ url: `/users/${userId}/awardwelcomebonuspack`, method: 'post' });
  };
};

export const useSetAwardWelcomeBonusPackMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | PreconditionFailedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetAwardWelcomeBonusPackHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetAwardWelcomeBonusPackHook>>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setAwardWelcomeBonusPack = useSetAwardWelcomeBonusPackHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetAwardWelcomeBonusPackHook>>>,
    { userId: string }
  > = props => {
    const { userId } = props ?? {};

    return setAwardWelcomeBonusPack(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetAwardWelcomeBonusPackMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetAwardWelcomeBonusPackHook>>>
>;

export type SetAwardWelcomeBonusPackMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @summary Try to award welcome bonus pack
 */
export const useSetAwardWelcomeBonusPack = <
  TError = BadRequestResponse | UnauthorizedResponse | PreconditionFailedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetAwardWelcomeBonusPackHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}) => {
  const mutationOptions = useSetAwardWelcomeBonusPackMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * This is used to correct promo to cash
 * @summary Get a list of promotocash events to correct
 */
export const useGetPromoToCashEventsHook = () => {
  const getPromoToCashEvents = useCustomInstance<PromoToCashEventsSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getPromoToCashEvents({ url: `/users/${userId}/correctpromotocash`, method: 'get', signal });
  };
};

export const getGetPromoToCashEventsQueryKey = (userId: string) => [`/users/${userId}/correctpromotocash`] as const;

export const useGetPromoToCashEventsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPromoToCashEventsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPromoToCashEventsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPromoToCashEventsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPromoToCashEventsQueryKey(userId);

  const getPromoToCashEvents = useGetPromoToCashEventsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPromoToCashEventsHook>>>> = ({ signal }) =>
    getPromoToCashEvents(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetPromoToCashEventsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPromoToCashEventsHook>>>
>;
export type GetPromoToCashEventsQueryError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Get a list of promotocash events to correct
 */
export const useGetPromoToCashEvents = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPromoToCashEventsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPromoToCashEventsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPromoToCashEventsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * This is used to correct promo to cash
 * @summary Try to award welcome bonus pack
 */
export const useSetCorrectPromoToCashHook = () => {
  const setCorrectPromoToCash = useCustomInstance<void>();

  return (userId: string, promotionSchema: PromotionSchema) => {
    return setCorrectPromoToCash({
      url: `/users/${userId}/correctpromotocash`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: promotionSchema,
    });
  };
};

export const useSetCorrectPromoToCashMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetCorrectPromoToCashHook>>>,
    TError,
    { userId: string; data: PromotionSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetCorrectPromoToCashHook>>>,
  TError,
  { userId: string; data: PromotionSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setCorrectPromoToCash = useSetCorrectPromoToCashHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetCorrectPromoToCashHook>>>,
    { userId: string; data: PromotionSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return setCorrectPromoToCash(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetCorrectPromoToCashMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetCorrectPromoToCashHook>>>
>;
export type SetCorrectPromoToCashMutationBody = PromotionSchema;
export type SetCorrectPromoToCashMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Try to award welcome bonus pack
 */
export const useSetCorrectPromoToCash = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetCorrectPromoToCashHook>>>,
    TError,
    { userId: string; data: PromotionSchema },
    TContext
  >;
}) => {
  const mutationOptions = useSetCorrectPromoToCashMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns you the notes of the requested user
 * @summary Get user notes
 */
export const useGetUserNotesHook = () => {
  const getUserNotes = useCustomInstance<UserNotesSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getUserNotes({ url: `/users/${userId}/notes`, method: 'get', signal });
  };
};

export const getGetUserNotesQueryKey = (userId: string) => [`/users/${userId}/notes`] as const;

export const useGetUserNotesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserNotesHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserNotesHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserNotesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserNotesQueryKey(userId);

  const getUserNotes = useGetUserNotesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserNotesHook>>>> = ({ signal }) =>
    getUserNotes(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserNotesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUserNotesHook>>>>;
export type GetUserNotesQueryError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get user notes
 */
export const useGetUserNotes = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserNotesHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserNotesHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserNotesQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * This is used to add a user note
 * @summary Add a user note
 */
export const useAddUserNoteHook = () => {
  const addUserNote = useCustomInstance<UserNotesSchema>();

  return (userId: string, addUserNoteBody: string) => {
    return addUserNote({
      url: `/users/${userId}/notes`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: addUserNoteBody,
    });
  };
};

export const useAddUserNoteMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddUserNoteHook>>>,
    TError,
    { userId: string; data: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddUserNoteHook>>>,
  TError,
  { userId: string; data: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addUserNote = useAddUserNoteHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddUserNoteHook>>>,
    { userId: string; data: string }
  > = props => {
    const { userId, data } = props ?? {};

    return addUserNote(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddUserNoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddUserNoteHook>>>>;
export type AddUserNoteMutationBody = string;
export type AddUserNoteMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Add a user note
 */
export const useAddUserNote = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddUserNoteHook>>>,
    TError,
    { userId: string; data: string },
    TContext
  >;
}) => {
  const mutationOptions = useAddUserNoteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * This is used to delete a user note
 * @summary Delete a user note
 */
export const useDeleteUserNoteHook = () => {
  const deleteUserNote = useCustomInstance<void>();

  return (userId: string, noteId: string) => {
    return deleteUserNote({ url: `/users/${userId}/notes/${noteId}`, method: 'delete' });
  };
};

export const useDeleteUserNoteMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserNoteHook>>>,
    TError,
    { userId: string; noteId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteUserNoteHook>>>,
  TError,
  { userId: string; noteId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteUserNote = useDeleteUserNoteHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserNoteHook>>>,
    { userId: string; noteId: string }
  > = props => {
    const { userId, noteId } = props ?? {};

    return deleteUserNote(userId, noteId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserNoteMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteUserNoteHook>>>>;

export type DeleteUserNoteMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Delete a user note
 */
export const useDeleteUserNote = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserNoteHook>>>,
    TError,
    { userId: string; noteId: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteUserNoteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get linked accounts of the user
 */
export const useGetUserLinkedAccountsHook = () => {
  const getUserLinkedAccounts = useCustomInstance<UserLinkedAccountSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getUserLinkedAccounts({ url: `/users/${userId}/linkedaccounts`, method: 'get', signal });
  };
};

export const getGetUserLinkedAccountsQueryKey = (userId: string) => [`/users/${userId}/linkedaccounts`] as const;

export const useGetUserLinkedAccountsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserLinkedAccountsHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserLinkedAccountsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserLinkedAccountsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserLinkedAccountsQueryKey(userId);

  const getUserLinkedAccounts = useGetUserLinkedAccountsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserLinkedAccountsHook>>>> = ({ signal }) =>
    getUserLinkedAccounts(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserLinkedAccountsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserLinkedAccountsHook>>>
>;
export type GetUserLinkedAccountsQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get linked accounts of the user
 */
export const useGetUserLinkedAccounts = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserLinkedAccountsHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserLinkedAccountsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserLinkedAccountsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns you the login sessions of the requested user while logged in
 * @summary Get user login sessions
 */
export const useGetUserLoginSessionHook = () => {
  const getUserLoginSession = useCustomInstance<UserLoginSessionSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getUserLoginSession({ url: `/users/${userId}/loginsession`, method: 'get', signal });
  };
};

export const getGetUserLoginSessionQueryKey = (userId: string) => [`/users/${userId}/loginsession`] as const;

export const useGetUserLoginSessionQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserLoginSessionHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserLoginSessionHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserLoginSessionHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserLoginSessionQueryKey(userId);

  const getUserLoginSession = useGetUserLoginSessionHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserLoginSessionHook>>>> = ({ signal }) =>
    getUserLoginSession(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserLoginSessionQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserLoginSessionHook>>>
>;
export type GetUserLoginSessionQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get user login sessions
 */
export const useGetUserLoginSession = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserLoginSessionHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserLoginSessionHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserLoginSessionQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns you the preferences of the requested user
 * @summary Get user preferences
 */
export const useGetUserPreferencesHook = () => {
  const getUserPreferences = useCustomInstance<UserPreferencesSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getUserPreferences({ url: `/users/${userId}/preferences`, method: 'get', signal });
  };
};

export const getGetUserPreferencesQueryKey = (userId: string) => [`/users/${userId}/preferences`] as const;

export const useGetUserPreferencesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserPreferencesHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserPreferencesHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserPreferencesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserPreferencesQueryKey(userId);

  const getUserPreferences = useGetUserPreferencesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserPreferencesHook>>>> = ({ signal }) =>
    getUserPreferences(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserPreferencesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserPreferencesHook>>>
>;
export type GetUserPreferencesQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get user preferences
 */
export const useGetUserPreferences = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserPreferencesHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserPreferencesHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserPreferencesQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns you the service contract approvals of the requested user
 * @summary Get user service contract approvals
 */
export const useGetUserServiceContractApprovalsHook = () => {
  const getUserServiceContractApprovals = useCustomInstance<UserServiceContractApprovalsSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getUserServiceContractApprovals({ url: `/users/${userId}/servicecontractapprovals`, method: 'get', signal });
  };
};

export const getGetUserServiceContractApprovalsQueryKey = (userId: string) =>
  [`/users/${userId}/servicecontractapprovals`] as const;

export const useGetUserServiceContractApprovalsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserServiceContractApprovalsHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserServiceContractApprovalsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserServiceContractApprovalsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserServiceContractApprovalsQueryKey(userId);

  const getUserServiceContractApprovals = useGetUserServiceContractApprovalsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserServiceContractApprovalsHook>>>> = ({
    signal,
  }) => getUserServiceContractApprovals(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserServiceContractApprovalsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserServiceContractApprovalsHook>>>
>;
export type GetUserServiceContractApprovalsQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get user service contract approvals
 */
export const useGetUserServiceContractApprovals = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserServiceContractApprovalsHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetUserServiceContractApprovalsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserServiceContractApprovalsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get basic account data
 */
export const useGetBasicAccountDataHook = () => {
  const getBasicAccountData = useCustomInstance<BasicAccountDataSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getBasicAccountData({ url: `/users/${userId}/basicaccountdata`, method: 'get', signal });
  };
};

export const getGetBasicAccountDataQueryKey = (userId: string) => [`/users/${userId}/basicaccountdata`] as const;

export const useGetBasicAccountDataQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBasicAccountDataHook>>>,
  TError = BadRequestAccountResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBasicAccountDataHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBasicAccountDataHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBasicAccountDataQueryKey(userId);

  const getBasicAccountData = useGetBasicAccountDataHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBasicAccountDataHook>>>> = ({ signal }) =>
    getBasicAccountData(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetBasicAccountDataQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBasicAccountDataHook>>>
>;
export type GetBasicAccountDataQueryError =
  | BadRequestAccountResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get basic account data
 */
export const useGetBasicAccountData = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBasicAccountDataHook>>>,
  TError = BadRequestAccountResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBasicAccountDataHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBasicAccountDataQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get all account data
 */
export const useGetAllAccountDataHook = () => {
  const getAllAccountData = useCustomInstance<AllAccountDataSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getAllAccountData({ url: `/users/${userId}/allaccountdata`, method: 'get', signal });
  };
};

export const getGetAllAccountDataQueryKey = (userId: string) => [`/users/${userId}/allaccountdata`] as const;

export const useGetAllAccountDataQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAllAccountDataHook>>>,
  TError = BadRequestAccountResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllAccountDataHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllAccountDataHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllAccountDataQueryKey(userId);

  const getAllAccountData = useGetAllAccountDataHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllAccountDataHook>>>> = ({ signal }) =>
    getAllAccountData(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetAllAccountDataQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAllAccountDataHook>>>
>;
export type GetAllAccountDataQueryError =
  | BadRequestAccountResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get all account data
 */
export const useGetAllAccountData = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAllAccountDataHook>>>,
  TError = BadRequestAccountResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllAccountDataHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAllAccountDataQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get statistics data about the user
 */
export const useGetUserAccountStatisticsHook = () => {
  const getUserAccountStatistics = useCustomInstance<UserAccountStatisticsSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getUserAccountStatistics({ url: `/users/${userId}/account/statistics`, method: 'get', signal });
  };
};

export const getGetUserAccountStatisticsQueryKey = (userId: string) => [`/users/${userId}/account/statistics`] as const;

export const useGetUserAccountStatisticsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserAccountStatisticsHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserAccountStatisticsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserAccountStatisticsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserAccountStatisticsQueryKey(userId);

  const getUserAccountStatistics = useGetUserAccountStatisticsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserAccountStatisticsHook>>>> = ({
    signal,
  }) => getUserAccountStatistics(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserAccountStatisticsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserAccountStatisticsHook>>>
>;
export type GetUserAccountStatisticsQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get statistics data about the user
 */
export const useGetUserAccountStatistics = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserAccountStatisticsHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserAccountStatisticsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserAccountStatisticsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Set the amount and description of a user refund
 * @summary Set a user refund
 */
export const useSetRefundHook = () => {
  const setRefund = useCustomInstance<void>();

  return (userId: string, setRefundSchema: SetRefundSchema) => {
    return setRefund({
      url: `/users/${userId}/account/refund`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: setRefundSchema,
    });
  };
};

export const useSetRefundMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetRefundHook>>>,
    TError,
    { userId: string; data: SetRefundSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetRefundHook>>>,
  TError,
  { userId: string; data: SetRefundSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setRefund = useSetRefundHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetRefundHook>>>,
    { userId: string; data: SetRefundSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return setRefund(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetRefundMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetRefundHook>>>>;
export type SetRefundMutationBody = SetRefundSchema;
export type SetRefundMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Set a user refund
 */
export const useSetRefund = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetRefundHook>>>,
    TError,
    { userId: string; data: SetRefundSchema },
    TContext
  >;
}) => {
  const mutationOptions = useSetRefundMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the games activity for user
 */
export const useGetGamesActivityHook = () => {
  const getGamesActivity = useCustomInstance<GamesActivitySchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getGamesActivity({ url: `/users/${userId}/gamesactivity`, method: 'get', signal });
  };
};

export const getGetGamesActivityQueryKey = (userId: string) => [`/users/${userId}/gamesactivity`] as const;

export const useGetGamesActivityQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGamesActivityHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGamesActivityHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGamesActivityHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGamesActivityQueryKey(userId);

  const getGamesActivity = useGetGamesActivityHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGamesActivityHook>>>> = ({ signal }) =>
    getGamesActivity(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetGamesActivityQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetGamesActivityHook>>>>;
export type GetGamesActivityQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get the games activity for user
 */
export const useGetGamesActivity = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGamesActivityHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGamesActivityHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGamesActivityQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns you the identity of the requested user
 * @summary Get user identity
 */
export const useGetUserIdentityHook = () => {
  const getUserIdentity = useCustomInstance<IdentitySchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getUserIdentity({ url: `/users/${userId}/identity`, method: 'get', signal });
  };
};

export const getGetUserIdentityQueryKey = (userId: string) => [`/users/${userId}/identity`] as const;

export const useGetUserIdentityQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserIdentityHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserIdentityHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserIdentityHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserIdentityQueryKey(userId);

  const getUserIdentity = useGetUserIdentityHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserIdentityHook>>>> = ({ signal }) =>
    getUserIdentity(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserIdentityQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUserIdentityHook>>>>;
export type GetUserIdentityQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get user identity
 */
export const useGetUserIdentity = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserIdentityHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserIdentityHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserIdentityQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns you the user withdraw limits for given user Id
 * @summary Get user withdraw limits
 */
export const useGetWithdrawLimitsHook = () => {
  const getWithdrawLimits = useCustomInstance<WithdrawLimitsSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getWithdrawLimits({ url: `/users/${userId}/withdrawlimits`, method: 'get', signal });
  };
};

export const getGetWithdrawLimitsQueryKey = (userId: string) => [`/users/${userId}/withdrawlimits`] as const;

export const useGetWithdrawLimitsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawLimitsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawLimitsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawLimitsQueryKey(userId);

  const getWithdrawLimits = useGetWithdrawLimitsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWithdrawLimitsHook>>>> = ({ signal }) =>
    getWithdrawLimits(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetWithdrawLimitsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWithdrawLimitsHook>>>
>;
export type GetWithdrawLimitsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get user withdraw limits
 */
export const useGetWithdrawLimits = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawLimitsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWithdrawLimitsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Add a withdraw limit for given user
 * @summary Add user withdraw limit
 */
export const useAddWithdrawLimitHook = () => {
  const addWithdrawLimit = useCustomInstance<WithdrawLimitsSchema>();

  return (userId: string, withdrawLimitArgumentsSchema: WithdrawLimitArgumentsSchema) => {
    return addWithdrawLimit({
      url: `/users/${userId}/withdrawlimits`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: withdrawLimitArgumentsSchema,
    });
  };
};

export const useAddWithdrawLimitMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddWithdrawLimitHook>>>,
    TError,
    { userId: string; data: WithdrawLimitArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddWithdrawLimitHook>>>,
  TError,
  { userId: string; data: WithdrawLimitArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addWithdrawLimit = useAddWithdrawLimitHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddWithdrawLimitHook>>>,
    { userId: string; data: WithdrawLimitArgumentsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return addWithdrawLimit(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddWithdrawLimitMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddWithdrawLimitHook>>>
>;
export type AddWithdrawLimitMutationBody = WithdrawLimitArgumentsSchema;
export type AddWithdrawLimitMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Add user withdraw limit
 */
export const useAddWithdrawLimit = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddWithdrawLimitHook>>>,
    TError,
    { userId: string; data: WithdrawLimitArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddWithdrawLimitMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update a withdraw limit for given user
 * @summary Update user withdraw limit
 */
export const useUpdateWithdrawLimitHook = () => {
  const updateWithdrawLimit = useCustomInstance<WithdrawLimitsSchema>();

  return (userId: string, withdrawLimitId: string, withdrawLimitArgumentsSchema: WithdrawLimitArgumentsSchema) => {
    return updateWithdrawLimit({
      url: `/users/${userId}/withdrawlimits/${withdrawLimitId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: withdrawLimitArgumentsSchema,
    });
  };
};

export const useUpdateWithdrawLimitMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateWithdrawLimitHook>>>,
    TError,
    { userId: string; withdrawLimitId: string; data: WithdrawLimitArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateWithdrawLimitHook>>>,
  TError,
  { userId: string; withdrawLimitId: string; data: WithdrawLimitArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateWithdrawLimit = useUpdateWithdrawLimitHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateWithdrawLimitHook>>>,
    { userId: string; withdrawLimitId: string; data: WithdrawLimitArgumentsSchema }
  > = props => {
    const { userId, withdrawLimitId, data } = props ?? {};

    return updateWithdrawLimit(userId, withdrawLimitId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateWithdrawLimitMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateWithdrawLimitHook>>>
>;
export type UpdateWithdrawLimitMutationBody = WithdrawLimitArgumentsSchema;
export type UpdateWithdrawLimitMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Update user withdraw limit
 */
export const useUpdateWithdrawLimit = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateWithdrawLimitHook>>>,
    TError,
    { userId: string; withdrawLimitId: string; data: WithdrawLimitArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateWithdrawLimitMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete a withdraw limit for given user
 * @summary Delete user withdraw limit
 */
export const useDeleteWithdrawLimitHook = () => {
  const deleteWithdrawLimit = useCustomInstance<WithdrawLimitsSchema>();

  return (userId: string, withdrawLimitId: string) => {
    return deleteWithdrawLimit({ url: `/users/${userId}/withdrawlimits/${withdrawLimitId}`, method: 'delete' });
  };
};

export const useDeleteWithdrawLimitMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteWithdrawLimitHook>>>,
    TError,
    { userId: string; withdrawLimitId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteWithdrawLimitHook>>>,
  TError,
  { userId: string; withdrawLimitId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteWithdrawLimit = useDeleteWithdrawLimitHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteWithdrawLimitHook>>>,
    { userId: string; withdrawLimitId: string }
  > = props => {
    const { userId, withdrawLimitId } = props ?? {};

    return deleteWithdrawLimit(userId, withdrawLimitId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteWithdrawLimitMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteWithdrawLimitHook>>>
>;

export type DeleteWithdrawLimitMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Delete user withdraw limit
 */
export const useDeleteWithdrawLimit = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteWithdrawLimitHook>>>,
    TError,
    { userId: string; withdrawLimitId: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteWithdrawLimitMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Export Withdrawals and Deposits History as Excel file for a given user
 * @summary Export Withdrawals and Deposits History as Excel file
 */
export const useExportWithdrawalsAndDepositsHistoryHook = () => {
  const exportWithdrawalsAndDepositsHistory = useCustomInstance<Base64FileSchema>();

  return (userId: string, params?: ExportWithdrawalsAndDepositsHistoryParams, signal?: AbortSignal) => {
    return exportWithdrawalsAndDepositsHistory({
      url: `/users/${userId}/withdrawalsanddeposits`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getExportWithdrawalsAndDepositsHistoryQueryKey = (
  userId: string,
  params?: ExportWithdrawalsAndDepositsHistoryParams
) => [`/users/${userId}/withdrawalsanddeposits`, ...(params ? [params] : [])] as const;

export const useExportWithdrawalsAndDepositsHistoryQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useExportWithdrawalsAndDepositsHistoryHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: ExportWithdrawalsAndDepositsHistoryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useExportWithdrawalsAndDepositsHistoryHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useExportWithdrawalsAndDepositsHistoryHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getExportWithdrawalsAndDepositsHistoryQueryKey(userId, params);

  const exportWithdrawalsAndDepositsHistory = useExportWithdrawalsAndDepositsHistoryHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useExportWithdrawalsAndDepositsHistoryHook>>>> = ({
    signal,
  }) => exportWithdrawalsAndDepositsHistory(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type ExportWithdrawalsAndDepositsHistoryQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useExportWithdrawalsAndDepositsHistoryHook>>>
>;
export type ExportWithdrawalsAndDepositsHistoryQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Export Withdrawals and Deposits History as Excel file
 */
export const useExportWithdrawalsAndDepositsHistory = <
  TData = Awaited<ReturnType<ReturnType<typeof useExportWithdrawalsAndDepositsHistoryHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: ExportWithdrawalsAndDepositsHistoryParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useExportWithdrawalsAndDepositsHistoryHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useExportWithdrawalsAndDepositsHistoryQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns you the verification of the requested user
 * @summary Get user verification
 */
export const useGetUserVerificationHook = () => {
  const getUserVerification = useCustomInstance<VerificationSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getUserVerification({ url: `/users/${userId}/verification`, method: 'get', signal });
  };
};

export const getGetUserVerificationQueryKey = (userId: string) => [`/users/${userId}/verification`] as const;

export const useGetUserVerificationQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserVerificationHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVerificationHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVerificationHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserVerificationQueryKey(userId);

  const getUserVerification = useGetUserVerificationHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserVerificationHook>>>> = ({ signal }) =>
    getUserVerification(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserVerificationQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserVerificationHook>>>
>;
export type GetUserVerificationQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get user verification
 */
export const useGetUserVerification = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserVerificationHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVerificationHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserVerificationQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the detailed user verifications
 */
export const useGetUserVerificationsHook = () => {
  const getUserVerifications = useCustomInstance<VerificationsSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getUserVerifications({ url: `/users/${userId}/verifications`, method: 'get', signal });
  };
};

export const getGetUserVerificationsQueryKey = (userId: string) => [`/users/${userId}/verifications`] as const;

export const useGetUserVerificationsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserVerificationsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVerificationsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVerificationsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserVerificationsQueryKey(userId);

  const getUserVerifications = useGetUserVerificationsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserVerificationsHook>>>> = ({ signal }) =>
    getUserVerifications(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserVerificationsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserVerificationsHook>>>
>;
export type GetUserVerificationsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the detailed user verifications
 */
export const useGetUserVerifications = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserVerificationsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVerificationsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserVerificationsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Communicate that verification is needed
 */
export const usePostCommunicateVerificationNeededHook = () => {
  const postCommunicateVerificationNeeded = useCustomInstance<VerificationsSchema>();

  return (userId: string) => {
    return postCommunicateVerificationNeeded({ url: `/users/${userId}/verifications/communicate`, method: 'post' });
  };
};

export const usePostCommunicateVerificationNeededMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostCommunicateVerificationNeededHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePostCommunicateVerificationNeededHook>>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const postCommunicateVerificationNeeded = usePostCommunicateVerificationNeededHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePostCommunicateVerificationNeededHook>>>,
    { userId: string }
  > = props => {
    const { userId } = props ?? {};

    return postCommunicateVerificationNeeded(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCommunicateVerificationNeededMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePostCommunicateVerificationNeededHook>>>
>;

export type PostCommunicateVerificationNeededMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Communicate that verification is needed
 */
export const usePostCommunicateVerificationNeeded = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostCommunicateVerificationNeededHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}) => {
  const mutationOptions = usePostCommunicateVerificationNeededMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update the user verifications identity. returns error if operation not allowed.
 */
export const useUpdateUserVerificationsIdentityHook = () => {
  const updateUserVerificationsIdentity = useCustomInstance<UpdateUserVerificationsIdentityResultSchema>();

  return (userId: string, verificationsIdentityArgumentsSchema: VerificationsIdentityArgumentsSchema) => {
    return updateUserVerificationsIdentity({
      url: `/users/${userId}/verifications/identity`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: verificationsIdentityArgumentsSchema,
    });
  };
};

export const useUpdateUserVerificationsIdentityMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsIdentityHook>>>,
    TError,
    { userId: string; data: VerificationsIdentityArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsIdentityHook>>>,
  TError,
  { userId: string; data: VerificationsIdentityArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateUserVerificationsIdentity = useUpdateUserVerificationsIdentityHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsIdentityHook>>>,
    { userId: string; data: VerificationsIdentityArgumentsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return updateUserVerificationsIdentity(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserVerificationsIdentityMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsIdentityHook>>>
>;
export type UpdateUserVerificationsIdentityMutationBody = VerificationsIdentityArgumentsSchema;
export type UpdateUserVerificationsIdentityMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary update the user verifications identity. returns error if operation not allowed.
 */
export const useUpdateUserVerificationsIdentity = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsIdentityHook>>>,
    TError,
    { userId: string; data: VerificationsIdentityArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateUserVerificationsIdentityMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update the user verifications identity verification status
 */
export const useUpdateUserVerificationsIdentityVerificationStatusHook = () => {
  const updateUserVerificationsIdentityVerificationStatus = useCustomInstance<VerificationsSchema>();

  return (userId: string, verificationStatusArgumentsSchema: VerificationStatusArgumentsSchema) => {
    return updateUserVerificationsIdentityVerificationStatus({
      url: `/users/${userId}/verifications/identity/verificationstatus`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: verificationStatusArgumentsSchema,
    });
  };
};

export const useUpdateUserVerificationsIdentityVerificationStatusMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsIdentityVerificationStatusHook>>>,
    TError,
    { userId: string; data: VerificationStatusArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsIdentityVerificationStatusHook>>>,
  TError,
  { userId: string; data: VerificationStatusArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateUserVerificationsIdentityVerificationStatus = useUpdateUserVerificationsIdentityVerificationStatusHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsIdentityVerificationStatusHook>>>,
    { userId: string; data: VerificationStatusArgumentsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return updateUserVerificationsIdentityVerificationStatus(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserVerificationsIdentityVerificationStatusMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsIdentityVerificationStatusHook>>>
>;
export type UpdateUserVerificationsIdentityVerificationStatusMutationBody = VerificationStatusArgumentsSchema;
export type UpdateUserVerificationsIdentityVerificationStatusMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary update the user verifications identity verification status
 */
export const useUpdateUserVerificationsIdentityVerificationStatus = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsIdentityVerificationStatusHook>>>,
    TError,
    { userId: string; data: VerificationStatusArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateUserVerificationsIdentityVerificationStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update the user verifications identity to the VerificationNeeded status
 */
export const useResetStatusToVerificationNeededHook = () => {
  const resetStatusToVerificationNeeded = useCustomInstance<void>();

  return (userId: string, reasonArgumentSchema: ReasonArgumentSchema) => {
    return resetStatusToVerificationNeeded({
      url: `/users/${userId}/verifications/identity/reset-to-verification-needed`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: reasonArgumentSchema,
    });
  };
};

export const useResetStatusToVerificationNeededMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetStatusToVerificationNeededHook>>>,
    TError,
    { userId: string; data: ReasonArgumentSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useResetStatusToVerificationNeededHook>>>,
  TError,
  { userId: string; data: ReasonArgumentSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const resetStatusToVerificationNeeded = useResetStatusToVerificationNeededHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useResetStatusToVerificationNeededHook>>>,
    { userId: string; data: ReasonArgumentSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return resetStatusToVerificationNeeded(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetStatusToVerificationNeededMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useResetStatusToVerificationNeededHook>>>
>;
export type ResetStatusToVerificationNeededMutationBody = ReasonArgumentSchema;
export type ResetStatusToVerificationNeededMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary update the user verifications identity to the VerificationNeeded status
 */
export const useResetStatusToVerificationNeeded = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetStatusToVerificationNeededHook>>>,
    TError,
    { userId: string; data: ReasonArgumentSchema },
    TContext
  >;
}) => {
  const mutationOptions = useResetStatusToVerificationNeededMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary upload a file for the user verifications identity
 */
export const useUploadUserVerificationsIdentityFileHook = () => {
  const uploadUserVerificationsIdentityFile = useCustomInstance<VerificationsStatusSchema>();

  return (userId: string, fileType: 'passport' | 'idback' | 'idfront', base64FileSchema: Base64FileSchema) => {
    return uploadUserVerificationsIdentityFile({
      url: `/users/${userId}/verifications/identity/${fileType}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: base64FileSchema,
    });
  };
};

export const useUploadUserVerificationsIdentityFileMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsIdentityFileHook>>>,
    TError,
    { userId: string; fileType: 'passport' | 'idback' | 'idfront'; data: Base64FileSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsIdentityFileHook>>>,
  TError,
  { userId: string; fileType: 'passport' | 'idback' | 'idfront'; data: Base64FileSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const uploadUserVerificationsIdentityFile = useUploadUserVerificationsIdentityFileHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsIdentityFileHook>>>,
    { userId: string; fileType: 'passport' | 'idback' | 'idfront'; data: Base64FileSchema }
  > = props => {
    const { userId, fileType, data } = props ?? {};

    return uploadUserVerificationsIdentityFile(userId, fileType, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadUserVerificationsIdentityFileMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsIdentityFileHook>>>
>;
export type UploadUserVerificationsIdentityFileMutationBody = Base64FileSchema;
export type UploadUserVerificationsIdentityFileMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary upload a file for the user verifications identity
 */
export const useUploadUserVerificationsIdentityFile = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsIdentityFileHook>>>,
    TError,
    { userId: string; fileType: 'passport' | 'idback' | 'idfront'; data: Base64FileSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUploadUserVerificationsIdentityFileMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary delete the file for the user verifications identity
 */
export const useDeleteUserVerificationsIdentityFileHook = () => {
  const deleteUserVerificationsIdentityFile = useCustomInstance<void>();

  return (userId: string, fileType: 'passport' | 'idback' | 'idfront') => {
    return deleteUserVerificationsIdentityFile({
      url: `/users/${userId}/verifications/identity/${fileType}`,
      method: 'delete',
    });
  };
};

export const useDeleteUserVerificationsIdentityFileMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsIdentityFileHook>>>,
    TError,
    { userId: string; fileType: 'passport' | 'idback' | 'idfront' },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsIdentityFileHook>>>,
  TError,
  { userId: string; fileType: 'passport' | 'idback' | 'idfront' },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteUserVerificationsIdentityFile = useDeleteUserVerificationsIdentityFileHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsIdentityFileHook>>>,
    { userId: string; fileType: 'passport' | 'idback' | 'idfront' }
  > = props => {
    const { userId, fileType } = props ?? {};

    return deleteUserVerificationsIdentityFile(userId, fileType);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserVerificationsIdentityFileMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsIdentityFileHook>>>
>;

export type DeleteUserVerificationsIdentityFileMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary delete the file for the user verifications identity
 */
export const useDeleteUserVerificationsIdentityFile = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsIdentityFileHook>>>,
    TError,
    { userId: string; fileType: 'passport' | 'idback' | 'idfront' },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteUserVerificationsIdentityFileMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update the user verifications wallet verification status
 */
export const useUpdateUserVerificationsWalletVerificationStatusHook = () => {
  const updateUserVerificationsWalletVerificationStatus = useCustomInstance<VerificationsSchema>();

  return (userId: string, walletId: number, verificationStatusArgumentsSchema: VerificationStatusArgumentsSchema) => {
    return updateUserVerificationsWalletVerificationStatus({
      url: `/users/${userId}/verifications/wallets/${walletId}/verificationstatus`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: verificationStatusArgumentsSchema,
    });
  };
};

export const useUpdateUserVerificationsWalletVerificationStatusMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsWalletVerificationStatusHook>>>,
    TError,
    { userId: string; walletId: number; data: VerificationStatusArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsWalletVerificationStatusHook>>>,
  TError,
  { userId: string; walletId: number; data: VerificationStatusArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateUserVerificationsWalletVerificationStatus = useUpdateUserVerificationsWalletVerificationStatusHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsWalletVerificationStatusHook>>>,
    { userId: string; walletId: number; data: VerificationStatusArgumentsSchema }
  > = props => {
    const { userId, walletId, data } = props ?? {};

    return updateUserVerificationsWalletVerificationStatus(userId, walletId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserVerificationsWalletVerificationStatusMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsWalletVerificationStatusHook>>>
>;
export type UpdateUserVerificationsWalletVerificationStatusMutationBody = VerificationStatusArgumentsSchema;
export type UpdateUserVerificationsWalletVerificationStatusMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary update the user verifications wallet verification status
 */
export const useUpdateUserVerificationsWalletVerificationStatus = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsWalletVerificationStatusHook>>>,
    TError,
    { userId: string; walletId: number; data: VerificationStatusArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateUserVerificationsWalletVerificationStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update the user verifications bank account status
 */
export const useUpdateUserVerificationsBankAccountStatusHook = () => {
  const updateUserVerificationsBankAccountStatus = useCustomInstance<VerificationsSchema>();

  return (
    userId: string,
    bankAccountId: string,
    verificationStatusArgumentsSchema: VerificationStatusArgumentsSchema
  ) => {
    return updateUserVerificationsBankAccountStatus({
      url: `/users/${userId}/verifications/bankaccounts/${bankAccountId}/verificationstatus`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: verificationStatusArgumentsSchema,
    });
  };
};

export const useUpdateUserVerificationsBankAccountStatusMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsBankAccountStatusHook>>>,
    TError,
    { userId: string; bankAccountId: string; data: VerificationStatusArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsBankAccountStatusHook>>>,
  TError,
  { userId: string; bankAccountId: string; data: VerificationStatusArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateUserVerificationsBankAccountStatus = useUpdateUserVerificationsBankAccountStatusHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsBankAccountStatusHook>>>,
    { userId: string; bankAccountId: string; data: VerificationStatusArgumentsSchema }
  > = props => {
    const { userId, bankAccountId, data } = props ?? {};

    return updateUserVerificationsBankAccountStatus(userId, bankAccountId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserVerificationsBankAccountStatusMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsBankAccountStatusHook>>>
>;
export type UpdateUserVerificationsBankAccountStatusMutationBody = VerificationStatusArgumentsSchema;
export type UpdateUserVerificationsBankAccountStatusMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary update the user verifications bank account status
 */
export const useUpdateUserVerificationsBankAccountStatus = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsBankAccountStatusHook>>>,
    TError,
    { userId: string; bankAccountId: string; data: VerificationStatusArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateUserVerificationsBankAccountStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Communicate that verification is needed
 */
export const useUploadUserVerificationsBankAccountDocumentHook = () => {
  const uploadUserVerificationsBankAccountDocument = useCustomInstance<VerificationsStatusSchema>();

  return (userId: string, bankAccountId: string, base64FileSchema: Base64FileSchema) => {
    return uploadUserVerificationsBankAccountDocument({
      url: `/users/${userId}/verifications/bankaccounts/${bankAccountId}/bankaccountdocument`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: base64FileSchema,
    });
  };
};

export const useUploadUserVerificationsBankAccountDocumentMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsBankAccountDocumentHook>>>,
    TError,
    { userId: string; bankAccountId: string; data: Base64FileSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsBankAccountDocumentHook>>>,
  TError,
  { userId: string; bankAccountId: string; data: Base64FileSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const uploadUserVerificationsBankAccountDocument = useUploadUserVerificationsBankAccountDocumentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsBankAccountDocumentHook>>>,
    { userId: string; bankAccountId: string; data: Base64FileSchema }
  > = props => {
    const { userId, bankAccountId, data } = props ?? {};

    return uploadUserVerificationsBankAccountDocument(userId, bankAccountId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadUserVerificationsBankAccountDocumentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsBankAccountDocumentHook>>>
>;
export type UploadUserVerificationsBankAccountDocumentMutationBody = Base64FileSchema;
export type UploadUserVerificationsBankAccountDocumentMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Communicate that verification is needed
 */
export const useUploadUserVerificationsBankAccountDocument = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsBankAccountDocumentHook>>>,
    TError,
    { userId: string; bankAccountId: string; data: Base64FileSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUploadUserVerificationsBankAccountDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Communicate that verification is needed
 */
export const useDeleteUserVerificationsBankAccountDocumentHook = () => {
  const deleteUserVerificationsBankAccountDocument = useCustomInstance<void>();

  return (userId: string, bankAccountId: string) => {
    return deleteUserVerificationsBankAccountDocument({
      url: `/users/${userId}/verifications/bankaccounts/${bankAccountId}/bankaccountdocument`,
      method: 'delete',
    });
  };
};

export const useDeleteUserVerificationsBankAccountDocumentMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsBankAccountDocumentHook>>>,
    TError,
    { userId: string; bankAccountId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsBankAccountDocumentHook>>>,
  TError,
  { userId: string; bankAccountId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteUserVerificationsBankAccountDocument = useDeleteUserVerificationsBankAccountDocumentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsBankAccountDocumentHook>>>,
    { userId: string; bankAccountId: string }
  > = props => {
    const { userId, bankAccountId } = props ?? {};

    return deleteUserVerificationsBankAccountDocument(userId, bankAccountId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserVerificationsBankAccountDocumentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsBankAccountDocumentHook>>>
>;

export type DeleteUserVerificationsBankAccountDocumentMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Communicate that verification is needed
 */
export const useDeleteUserVerificationsBankAccountDocument = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsBankAccountDocumentHook>>>,
    TError,
    { userId: string; bankAccountId: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteUserVerificationsBankAccountDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Archives the users bank account
 */
export const useArchiveUserBankAccountVerificationHook = () => {
  const archiveUserBankAccountVerification = useCustomInstance<void>();

  return (userId: string, bankAccountNumber: string) => {
    return archiveUserBankAccountVerification({
      url: `/users/${userId}/verifications/bankaccounts/${bankAccountNumber}/archive`,
      method: 'put',
    });
  };
};

export const useArchiveUserBankAccountVerificationMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveUserBankAccountVerificationHook>>>,
    TError,
    { userId: string; bankAccountNumber: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useArchiveUserBankAccountVerificationHook>>>,
  TError,
  { userId: string; bankAccountNumber: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const archiveUserBankAccountVerification = useArchiveUserBankAccountVerificationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useArchiveUserBankAccountVerificationHook>>>,
    { userId: string; bankAccountNumber: string }
  > = props => {
    const { userId, bankAccountNumber } = props ?? {};

    return archiveUserBankAccountVerification(userId, bankAccountNumber);
  };

  return { mutationFn, ...mutationOptions };
};

export type ArchiveUserBankAccountVerificationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useArchiveUserBankAccountVerificationHook>>>
>;

export type ArchiveUserBankAccountVerificationMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Archives the users bank account
 */
export const useArchiveUserBankAccountVerification = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveUserBankAccountVerificationHook>>>,
    TError,
    { userId: string; bankAccountNumber: string },
    TContext
  >;
}) => {
  const mutationOptions = useArchiveUserBankAccountVerificationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Active the users bank account
 */
export const useUnarchiveUserBankAccountVerificationHook = () => {
  const unarchiveUserBankAccountVerification = useCustomInstance<void>();

  return (userId: string, bankAccountId: string) => {
    return unarchiveUserBankAccountVerification({
      url: `/users/${userId}/verifications/bankaccounts/${bankAccountId}/unarchive`,
      method: 'put',
    });
  };
};

export const useUnarchiveUserBankAccountVerificationMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUnarchiveUserBankAccountVerificationHook>>>,
    TError,
    { userId: string; bankAccountId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUnarchiveUserBankAccountVerificationHook>>>,
  TError,
  { userId: string; bankAccountId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const unarchiveUserBankAccountVerification = useUnarchiveUserBankAccountVerificationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUnarchiveUserBankAccountVerificationHook>>>,
    { userId: string; bankAccountId: string }
  > = props => {
    const { userId, bankAccountId } = props ?? {};

    return unarchiveUserBankAccountVerification(userId, bankAccountId);
  };

  return { mutationFn, ...mutationOptions };
};

export type UnarchiveUserBankAccountVerificationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUnarchiveUserBankAccountVerificationHook>>>
>;

export type UnarchiveUserBankAccountVerificationMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Active the users bank account
 */
export const useUnarchiveUserBankAccountVerification = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUnarchiveUserBankAccountVerificationHook>>>,
    TError,
    { userId: string; bankAccountId: string },
    TContext
  >;
}) => {
  const mutationOptions = useUnarchiveUserBankAccountVerificationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Upload the wallet document
 */
export const useUploadUserVerificationsWalletDocumentHook = () => {
  const uploadUserVerificationsWalletDocument = useCustomInstance<VerificationsStatusSchema>();

  return (userId: number, walletId: number, base64FileSchema: Base64FileSchema) => {
    return uploadUserVerificationsWalletDocument({
      url: `/users/${userId}/verifications/wallets/${walletId}/walletDocument`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: base64FileSchema,
    });
  };
};

export const useUploadUserVerificationsWalletDocumentMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsWalletDocumentHook>>>,
    TError,
    { userId: number; walletId: number; data: Base64FileSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsWalletDocumentHook>>>,
  TError,
  { userId: number; walletId: number; data: Base64FileSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const uploadUserVerificationsWalletDocument = useUploadUserVerificationsWalletDocumentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsWalletDocumentHook>>>,
    { userId: number; walletId: number; data: Base64FileSchema }
  > = props => {
    const { userId, walletId, data } = props ?? {};

    return uploadUserVerificationsWalletDocument(userId, walletId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadUserVerificationsWalletDocumentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsWalletDocumentHook>>>
>;
export type UploadUserVerificationsWalletDocumentMutationBody = Base64FileSchema;
export type UploadUserVerificationsWalletDocumentMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Upload the wallet document
 */
export const useUploadUserVerificationsWalletDocument = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsWalletDocumentHook>>>,
    TError,
    { userId: number; walletId: number; data: Base64FileSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUploadUserVerificationsWalletDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete the wallet document
 */
export const useDeleteUserVerificationsWalletDocumentHook = () => {
  const deleteUserVerificationsWalletDocument = useCustomInstance<void>();

  return (userId: number, walletId: number) => {
    return deleteUserVerificationsWalletDocument({
      url: `/users/${userId}/verifications/wallets/${walletId}/walletDocument`,
      method: 'delete',
    });
  };
};

export const useDeleteUserVerificationsWalletDocumentMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsWalletDocumentHook>>>,
    TError,
    { userId: number; walletId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsWalletDocumentHook>>>,
  TError,
  { userId: number; walletId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteUserVerificationsWalletDocument = useDeleteUserVerificationsWalletDocumentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsWalletDocumentHook>>>,
    { userId: number; walletId: number }
  > = props => {
    const { userId, walletId } = props ?? {};

    return deleteUserVerificationsWalletDocument(userId, walletId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserVerificationsWalletDocumentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsWalletDocumentHook>>>
>;

export type DeleteUserVerificationsWalletDocumentMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Delete the wallet document
 */
export const useDeleteUserVerificationsWalletDocument = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsWalletDocumentHook>>>,
    TError,
    { userId: number; walletId: number },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteUserVerificationsWalletDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Archives the users wallet verification
 */
export const useArchiveUserWalletVerificationHook = () => {
  const archiveUserWalletVerification = useCustomInstance<void>();

  return (userId: number, walletId: number) => {
    return archiveUserWalletVerification({
      url: `/users/${userId}/verifications/wallets/${walletId}/archive`,
      method: 'put',
    });
  };
};

export const useArchiveUserWalletVerificationMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveUserWalletVerificationHook>>>,
    TError,
    { userId: number; walletId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useArchiveUserWalletVerificationHook>>>,
  TError,
  { userId: number; walletId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const archiveUserWalletVerification = useArchiveUserWalletVerificationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useArchiveUserWalletVerificationHook>>>,
    { userId: number; walletId: number }
  > = props => {
    const { userId, walletId } = props ?? {};

    return archiveUserWalletVerification(userId, walletId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ArchiveUserWalletVerificationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useArchiveUserWalletVerificationHook>>>
>;

export type ArchiveUserWalletVerificationMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Archives the users wallet verification
 */
export const useArchiveUserWalletVerification = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveUserWalletVerificationHook>>>,
    TError,
    { userId: number; walletId: number },
    TContext
  >;
}) => {
  const mutationOptions = useArchiveUserWalletVerificationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Active the users wallet verification
 */
export const useUnarchiveUserWalletVerificationHook = () => {
  const unarchiveUserWalletVerification = useCustomInstance<void>();

  return (userId: number, walletId: number) => {
    return unarchiveUserWalletVerification({
      url: `/users/${userId}/verifications/wallets/${walletId}/unarchive`,
      method: 'put',
    });
  };
};

export const useUnarchiveUserWalletVerificationMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUnarchiveUserWalletVerificationHook>>>,
    TError,
    { userId: number; walletId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUnarchiveUserWalletVerificationHook>>>,
  TError,
  { userId: number; walletId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const unarchiveUserWalletVerification = useUnarchiveUserWalletVerificationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUnarchiveUserWalletVerificationHook>>>,
    { userId: number; walletId: number }
  > = props => {
    const { userId, walletId } = props ?? {};

    return unarchiveUserWalletVerification(userId, walletId);
  };

  return { mutationFn, ...mutationOptions };
};

export type UnarchiveUserWalletVerificationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUnarchiveUserWalletVerificationHook>>>
>;

export type UnarchiveUserWalletVerificationMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Active the users wallet verification
 */
export const useUnarchiveUserWalletVerification = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUnarchiveUserWalletVerificationHook>>>,
    TError,
    { userId: number; walletId: number },
    TContext
  >;
}) => {
  const mutationOptions = useUnarchiveUserWalletVerificationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update the user verifications domicile
 */
export const useUpdateUserVerificationsDomicileHook = () => {
  const updateUserVerificationsDomicile = useCustomInstance<VerificationsSchema>();

  return (userId: string, verificationsDomicileArgumentsSchema: VerificationsDomicileArgumentsSchema) => {
    return updateUserVerificationsDomicile({
      url: `/users/${userId}/verifications/domicile`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: verificationsDomicileArgumentsSchema,
    });
  };
};

export const useUpdateUserVerificationsDomicileMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsDomicileHook>>>,
    TError,
    { userId: string; data: VerificationsDomicileArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsDomicileHook>>>,
  TError,
  { userId: string; data: VerificationsDomicileArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateUserVerificationsDomicile = useUpdateUserVerificationsDomicileHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsDomicileHook>>>,
    { userId: string; data: VerificationsDomicileArgumentsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return updateUserVerificationsDomicile(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserVerificationsDomicileMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsDomicileHook>>>
>;
export type UpdateUserVerificationsDomicileMutationBody = VerificationsDomicileArgumentsSchema;
export type UpdateUserVerificationsDomicileMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary update the user verifications domicile
 */
export const useUpdateUserVerificationsDomicile = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsDomicileHook>>>,
    TError,
    { userId: string; data: VerificationsDomicileArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateUserVerificationsDomicileMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update the user verifications domicile verification status
 */
export const useUpdateUserVerificationsDomicileVerificationStatusHook = () => {
  const updateUserVerificationsDomicileVerificationStatus = useCustomInstance<VerificationsSchema>();

  return (userId: string, verificationStatusArgumentsSchema: VerificationStatusArgumentsSchema) => {
    return updateUserVerificationsDomicileVerificationStatus({
      url: `/users/${userId}/verifications/domicile/verificationstatus`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: verificationStatusArgumentsSchema,
    });
  };
};

export const useUpdateUserVerificationsDomicileVerificationStatusMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsDomicileVerificationStatusHook>>>,
    TError,
    { userId: string; data: VerificationStatusArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsDomicileVerificationStatusHook>>>,
  TError,
  { userId: string; data: VerificationStatusArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateUserVerificationsDomicileVerificationStatus = useUpdateUserVerificationsDomicileVerificationStatusHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsDomicileVerificationStatusHook>>>,
    { userId: string; data: VerificationStatusArgumentsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return updateUserVerificationsDomicileVerificationStatus(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserVerificationsDomicileVerificationStatusMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsDomicileVerificationStatusHook>>>
>;
export type UpdateUserVerificationsDomicileVerificationStatusMutationBody = VerificationStatusArgumentsSchema;
export type UpdateUserVerificationsDomicileVerificationStatusMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary update the user verifications domicile verification status
 */
export const useUpdateUserVerificationsDomicileVerificationStatus = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsDomicileVerificationStatusHook>>>,
    TError,
    { userId: string; data: VerificationStatusArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateUserVerificationsDomicileVerificationStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Upload the domicile document
 */
export const useUploadUserVerificationsDomicileDocumentHook = () => {
  const uploadUserVerificationsDomicileDocument = useCustomInstance<VerificationsStatusSchema>();

  return (userId: string, base64FileSchema: Base64FileSchema) => {
    return uploadUserVerificationsDomicileDocument({
      url: `/users/${userId}/verifications/domicile/domiciledocument`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: base64FileSchema,
    });
  };
};

export const useUploadUserVerificationsDomicileDocumentMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsDomicileDocumentHook>>>,
    TError,
    { userId: string; data: Base64FileSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsDomicileDocumentHook>>>,
  TError,
  { userId: string; data: Base64FileSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const uploadUserVerificationsDomicileDocument = useUploadUserVerificationsDomicileDocumentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsDomicileDocumentHook>>>,
    { userId: string; data: Base64FileSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return uploadUserVerificationsDomicileDocument(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadUserVerificationsDomicileDocumentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsDomicileDocumentHook>>>
>;
export type UploadUserVerificationsDomicileDocumentMutationBody = Base64FileSchema;
export type UploadUserVerificationsDomicileDocumentMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Upload the domicile document
 */
export const useUploadUserVerificationsDomicileDocument = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsDomicileDocumentHook>>>,
    TError,
    { userId: string; data: Base64FileSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUploadUserVerificationsDomicileDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary delete the domicile document
 */
export const useDeleteUserVerificationsDomicileDocumentHook = () => {
  const deleteUserVerificationsDomicileDocument = useCustomInstance<void>();

  return (userId: string) => {
    return deleteUserVerificationsDomicileDocument({
      url: `/users/${userId}/verifications/domicile/domiciledocument`,
      method: 'delete',
    });
  };
};

export const useDeleteUserVerificationsDomicileDocumentMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsDomicileDocumentHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsDomicileDocumentHook>>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteUserVerificationsDomicileDocument = useDeleteUserVerificationsDomicileDocumentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsDomicileDocumentHook>>>,
    { userId: string }
  > = props => {
    const { userId } = props ?? {};

    return deleteUserVerificationsDomicileDocument(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserVerificationsDomicileDocumentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsDomicileDocumentHook>>>
>;

export type DeleteUserVerificationsDomicileDocumentMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary delete the domicile document
 */
export const useDeleteUserVerificationsDomicileDocument = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsDomicileDocumentHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteUserVerificationsDomicileDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update the user verifications solvency
 */
export const useUpdateUserVerificationsSolvencyHook = () => {
  const updateUserVerificationsSolvency = useCustomInstance<VerificationsSchema>();

  return (userId: string, verificationsSolvencyArgumentsSchema: VerificationsSolvencyArgumentsSchema) => {
    return updateUserVerificationsSolvency({
      url: `/users/${userId}/verifications/solvency`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: verificationsSolvencyArgumentsSchema,
    });
  };
};

export const useUpdateUserVerificationsSolvencyMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsSolvencyHook>>>,
    TError,
    { userId: string; data: VerificationsSolvencyArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsSolvencyHook>>>,
  TError,
  { userId: string; data: VerificationsSolvencyArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateUserVerificationsSolvency = useUpdateUserVerificationsSolvencyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsSolvencyHook>>>,
    { userId: string; data: VerificationsSolvencyArgumentsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return updateUserVerificationsSolvency(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserVerificationsSolvencyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsSolvencyHook>>>
>;
export type UpdateUserVerificationsSolvencyMutationBody = VerificationsSolvencyArgumentsSchema;
export type UpdateUserVerificationsSolvencyMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary update the user verifications solvency
 */
export const useUpdateUserVerificationsSolvency = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsSolvencyHook>>>,
    TError,
    { userId: string; data: VerificationsSolvencyArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateUserVerificationsSolvencyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update the user verifications financial
 */
export const useUpdateUserVerificationsFinancialHook = () => {
  const updateUserVerificationsFinancial = useCustomInstance<VerificationsSchema>();

  return (userId: string, verificationsFinancialArgumentsSchema: VerificationsFinancialArgumentsSchema) => {
    return updateUserVerificationsFinancial({
      url: `/users/${userId}/verifications/financial`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: verificationsFinancialArgumentsSchema,
    });
  };
};

export const useUpdateUserVerificationsFinancialMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsFinancialHook>>>,
    TError,
    { userId: string; data: VerificationsFinancialArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsFinancialHook>>>,
  TError,
  { userId: string; data: VerificationsFinancialArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateUserVerificationsFinancial = useUpdateUserVerificationsFinancialHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsFinancialHook>>>,
    { userId: string; data: VerificationsFinancialArgumentsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return updateUserVerificationsFinancial(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserVerificationsFinancialMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsFinancialHook>>>
>;
export type UpdateUserVerificationsFinancialMutationBody = VerificationsFinancialArgumentsSchema;
export type UpdateUserVerificationsFinancialMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary update the user verifications financial
 */
export const useUpdateUserVerificationsFinancial = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsFinancialHook>>>,
    TError,
    { userId: string; data: VerificationsFinancialArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateUserVerificationsFinancialMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update the user verifications solvency verification status
 */
export const useUpdateUserVerificationsSolvencyVerificationStatusHook = () => {
  const updateUserVerificationsSolvencyVerificationStatus = useCustomInstance<VerificationsSchema>();

  return (userId: string, verificationStatusArgumentsSchema: VerificationStatusArgumentsSchema) => {
    return updateUserVerificationsSolvencyVerificationStatus({
      url: `/users/${userId}/verifications/solvency/verificationstatus`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: verificationStatusArgumentsSchema,
    });
  };
};

export const useUpdateUserVerificationsSolvencyVerificationStatusMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsSolvencyVerificationStatusHook>>>,
    TError,
    { userId: string; data: VerificationStatusArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsSolvencyVerificationStatusHook>>>,
  TError,
  { userId: string; data: VerificationStatusArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateUserVerificationsSolvencyVerificationStatus = useUpdateUserVerificationsSolvencyVerificationStatusHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsSolvencyVerificationStatusHook>>>,
    { userId: string; data: VerificationStatusArgumentsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return updateUserVerificationsSolvencyVerificationStatus(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserVerificationsSolvencyVerificationStatusMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsSolvencyVerificationStatusHook>>>
>;
export type UpdateUserVerificationsSolvencyVerificationStatusMutationBody = VerificationStatusArgumentsSchema;
export type UpdateUserVerificationsSolvencyVerificationStatusMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary update the user verifications solvency verification status
 */
export const useUpdateUserVerificationsSolvencyVerificationStatus = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsSolvencyVerificationStatusHook>>>,
    TError,
    { userId: string; data: VerificationStatusArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateUserVerificationsSolvencyVerificationStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 * @summary update the user verifications financial verification status
 */
export const useUpdateUserVerificationsFinancialVerificationStatusHook = () => {
  const updateUserVerificationsFinancialVerificationStatus = useCustomInstance<VerificationsSchema>();

  return (userId: string, financialVerificationArgumentsSchema: FinancialVerificationArgumentsSchema) => {
    return updateUserVerificationsFinancialVerificationStatus({
      url: `/users/${userId}/verifications/financial/verificationstatus`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: financialVerificationArgumentsSchema,
    });
  };
};

export const useUpdateUserVerificationsFinancialVerificationStatusMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsFinancialVerificationStatusHook>>>,
    TError,
    { userId: string; data: FinancialVerificationArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsFinancialVerificationStatusHook>>>,
  TError,
  { userId: string; data: FinancialVerificationArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateUserVerificationsFinancialVerificationStatus =
    useUpdateUserVerificationsFinancialVerificationStatusHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsFinancialVerificationStatusHook>>>,
    { userId: string; data: FinancialVerificationArgumentsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return updateUserVerificationsFinancialVerificationStatus(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserVerificationsFinancialVerificationStatusMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsFinancialVerificationStatusHook>>>
>;
export type UpdateUserVerificationsFinancialVerificationStatusMutationBody = FinancialVerificationArgumentsSchema;
export type UpdateUserVerificationsFinancialVerificationStatusMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary update the user verifications financial verification status
 */
export const useUpdateUserVerificationsFinancialVerificationStatus = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateUserVerificationsFinancialVerificationStatusHook>>>,
    TError,
    { userId: string; data: FinancialVerificationArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateUserVerificationsFinancialVerificationStatusMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Upload the solvency document
 */
export const useUploadUserVerificationsSolvencyDocumentHook = () => {
  const uploadUserVerificationsSolvencyDocument = useCustomInstance<VerificationsStatusSchema>();

  return (userId: string, base64FileSchema: Base64FileSchema) => {
    return uploadUserVerificationsSolvencyDocument({
      url: `/users/${userId}/verifications/solvency/solvencydocument`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: base64FileSchema,
    });
  };
};

export const useUploadUserVerificationsSolvencyDocumentMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsSolvencyDocumentHook>>>,
    TError,
    { userId: string; data: Base64FileSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsSolvencyDocumentHook>>>,
  TError,
  { userId: string; data: Base64FileSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const uploadUserVerificationsSolvencyDocument = useUploadUserVerificationsSolvencyDocumentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsSolvencyDocumentHook>>>,
    { userId: string; data: Base64FileSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return uploadUserVerificationsSolvencyDocument(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadUserVerificationsSolvencyDocumentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsSolvencyDocumentHook>>>
>;
export type UploadUserVerificationsSolvencyDocumentMutationBody = Base64FileSchema;
export type UploadUserVerificationsSolvencyDocumentMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Upload the solvency document
 */
export const useUploadUserVerificationsSolvencyDocument = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsSolvencyDocumentHook>>>,
    TError,
    { userId: string; data: Base64FileSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUploadUserVerificationsSolvencyDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary delete the solvency document
 */
export const useDeleteUserVerificationsSolvencyDocumentHook = () => {
  const deleteUserVerificationsSolvencyDocument = useCustomInstance<void>();

  return (userId: string) => {
    return deleteUserVerificationsSolvencyDocument({
      url: `/users/${userId}/verifications/solvency/solvencydocument`,
      method: 'delete',
    });
  };
};

export const useDeleteUserVerificationsSolvencyDocumentMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsSolvencyDocumentHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsSolvencyDocumentHook>>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteUserVerificationsSolvencyDocument = useDeleteUserVerificationsSolvencyDocumentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsSolvencyDocumentHook>>>,
    { userId: string }
  > = props => {
    const { userId } = props ?? {};

    return deleteUserVerificationsSolvencyDocument(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUserVerificationsSolvencyDocumentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsSolvencyDocumentHook>>>
>;

export type DeleteUserVerificationsSolvencyDocumentMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary delete the solvency document
 */
export const useDeleteUserVerificationsSolvencyDocument = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteUserVerificationsSolvencyDocumentHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteUserVerificationsSolvencyDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Upload the financial verification document
 */
export const useUploadUserVerificationsFinancialDocumentHook = () => {
  const uploadUserVerificationsFinancialDocument = useCustomInstance<VerificationsStatusSchema>();

  return (userId: string, base64FileSchema: Base64FileSchema) => {
    return uploadUserVerificationsFinancialDocument({
      url: `/users/${userId}/verifications/financial/financialdocument`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: base64FileSchema,
    });
  };
};

export const useUploadUserVerificationsFinancialDocumentMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsFinancialDocumentHook>>>,
    TError,
    { userId: string; data: Base64FileSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsFinancialDocumentHook>>>,
  TError,
  { userId: string; data: Base64FileSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const uploadUserVerificationsFinancialDocument = useUploadUserVerificationsFinancialDocumentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsFinancialDocumentHook>>>,
    { userId: string; data: Base64FileSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return uploadUserVerificationsFinancialDocument(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadUserVerificationsFinancialDocumentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsFinancialDocumentHook>>>
>;
export type UploadUserVerificationsFinancialDocumentMutationBody = Base64FileSchema;
export type UploadUserVerificationsFinancialDocumentMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Upload the financial verification document
 */
export const useUploadUserVerificationsFinancialDocument = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadUserVerificationsFinancialDocumentHook>>>,
    TError,
    { userId: string; data: Base64FileSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUploadUserVerificationsFinancialDocumentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Returns you the vouchers of the requested user
 * @summary Get user vouchers
 */
export const useGetUserVouchersHook = () => {
  const getUserVouchers = useCustomInstance<UserVouchersSchema>();

  return (userId: string, params?: GetUserVouchersParams, signal?: AbortSignal) => {
    return getUserVouchers({ url: `/users/${userId}/vouchers`, method: 'get', params, signal });
  };
};

export const getGetUserVouchersQueryKey = (userId: string, params?: GetUserVouchersParams) =>
  [`/users/${userId}/vouchers`, ...(params ? [params] : [])] as const;

export const useGetUserVouchersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserVouchersHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserVouchersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVouchersHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVouchersHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserVouchersQueryKey(userId, params);

  const getUserVouchers = useGetUserVouchersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserVouchersHook>>>> = ({ signal }) =>
    getUserVouchers(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserVouchersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUserVouchersHook>>>>;
export type GetUserVouchersQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get user vouchers
 */
export const useGetUserVouchers = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserVouchersHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserVouchersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVouchersHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserVouchersQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns you the code for the given voucher
 * @summary Get a voucher code
 */
export const useGetUserVoucherCodeHook = () => {
  const getUserVoucherCode = useCustomInstance<UserVoucherCodeSchema>();

  return (userId: string, voucherId: string, signal?: AbortSignal) => {
    return getUserVoucherCode({ url: `/users/${userId}/vouchers/${voucherId}/code`, method: 'get', signal });
  };
};

export const getGetUserVoucherCodeQueryKey = (userId: string, voucherId: string) =>
  [`/users/${userId}/vouchers/${voucherId}/code`] as const;

export const useGetUserVoucherCodeQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserVoucherCodeHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  voucherId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVoucherCodeHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVoucherCodeHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserVoucherCodeQueryKey(userId, voucherId);

  const getUserVoucherCode = useGetUserVoucherCodeHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserVoucherCodeHook>>>> = ({ signal }) =>
    getUserVoucherCode(userId, voucherId, signal);

  return { queryKey, queryFn, enabled: !!(userId && voucherId), ...queryOptions };
};

export type GetUserVoucherCodeQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserVoucherCodeHook>>>
>;
export type GetUserVoucherCodeQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get a voucher code
 */
export const useGetUserVoucherCode = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserVoucherCodeHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  voucherId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVoucherCodeHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserVoucherCodeQueryOptions(userId, voucherId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns you the pin and code for the given voucher
 * @summary Get a voucher pin and code
 */
export const useGetUserVoucherSensitiveInfoHook = () => {
  const getUserVoucherSensitiveInfo = useCustomInstance<UserVoucherSensitiveInfoSchema>();

  return (userId: string, voucherId: string, voucherType: 'Internal' | 'BeCharge', signal?: AbortSignal) => {
    return getUserVoucherSensitiveInfo({
      url: `/users/${userId}/vouchers/${voucherId}/${voucherType}/sensitive`,
      method: 'get',
      signal,
    });
  };
};

export const getGetUserVoucherSensitiveInfoQueryKey = (
  userId: string,
  voucherId: string,
  voucherType: 'Internal' | 'BeCharge'
) => [`/users/${userId}/vouchers/${voucherId}/${voucherType}/sensitive`] as const;

export const useGetUserVoucherSensitiveInfoQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserVoucherSensitiveInfoHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  voucherId: string,
  voucherType: 'Internal' | 'BeCharge',
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVoucherSensitiveInfoHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVoucherSensitiveInfoHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserVoucherSensitiveInfoQueryKey(userId, voucherId, voucherType);

  const getUserVoucherSensitiveInfo = useGetUserVoucherSensitiveInfoHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserVoucherSensitiveInfoHook>>>> = ({
    signal,
  }) => getUserVoucherSensitiveInfo(userId, voucherId, voucherType, signal);

  return { queryKey, queryFn, enabled: !!(userId && voucherId && voucherType), ...queryOptions };
};

export type GetUserVoucherSensitiveInfoQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserVoucherSensitiveInfoHook>>>
>;
export type GetUserVoucherSensitiveInfoQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get a voucher pin and code
 */
export const useGetUserVoucherSensitiveInfo = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserVoucherSensitiveInfoHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  voucherId: string,
  voucherType: 'Internal' | 'BeCharge',
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVoucherSensitiveInfoHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserVoucherSensitiveInfoQueryOptions(userId, voucherId, voucherType, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary Get CRUKS details for user.
 */
export const useGetUserCruksRegistrationHook = () => {
  const getUserCruksRegistration = useCustomInstance<UserCruksRegistrationSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getUserCruksRegistration({ url: `/users/${userId}/cruksregistration`, method: 'get', signal });
  };
};

export const getGetUserCruksRegistrationQueryKey = (userId: string) => [`/users/${userId}/cruksregistration`] as const;

export const useGetUserCruksRegistrationQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserCruksRegistrationHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserCruksRegistrationHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserCruksRegistrationHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserCruksRegistrationQueryKey(userId);

  const getUserCruksRegistration = useGetUserCruksRegistrationHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserCruksRegistrationHook>>>> = ({
    signal,
  }) => getUserCruksRegistration(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserCruksRegistrationQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserCruksRegistrationHook>>>
>;
export type GetUserCruksRegistrationQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary Get CRUKS details for user.
 */
export const useGetUserCruksRegistration = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserCruksRegistrationHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserCruksRegistrationHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserCruksRegistrationQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary Get CRUKS messages for user.
 */
export const useGetUserCruksMessagesHook = () => {
  const getUserCruksMessages = useCustomInstance<UserCruksMessagesSchema>();

  return (userId: string, params?: GetUserCruksMessagesParams, signal?: AbortSignal) => {
    return getUserCruksMessages({ url: `/user/${userId}/cruksmessages`, method: 'get', params, signal });
  };
};

export const getGetUserCruksMessagesQueryKey = (userId: string, params?: GetUserCruksMessagesParams) =>
  [`/user/${userId}/cruksmessages`, ...(params ? [params] : [])] as const;

export const useGetUserCruksMessagesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserCruksMessagesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserCruksMessagesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserCruksMessagesHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserCruksMessagesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserCruksMessagesQueryKey(userId, params);

  const getUserCruksMessages = useGetUserCruksMessagesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserCruksMessagesHook>>>> = ({ signal }) =>
    getUserCruksMessages(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserCruksMessagesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserCruksMessagesHook>>>
>;
export type GetUserCruksMessagesQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary Get CRUKS messages for user.
 */
export const useGetUserCruksMessages = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserCruksMessagesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserCruksMessagesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserCruksMessagesHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserCruksMessagesQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Clear the aml deposit turnover requirements
 */
export const useClearAmlDepositTurnoverRequirementsHook = () => {
  const clearAmlDepositTurnoverRequirements = useCustomInstance<void>();

  return (userId: string) => {
    return clearAmlDepositTurnoverRequirements({ url: `/users/${userId}/account/clearaml`, method: 'put' });
  };
};

export const useClearAmlDepositTurnoverRequirementsMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useClearAmlDepositTurnoverRequirementsHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useClearAmlDepositTurnoverRequirementsHook>>>,
  TError,
  { userId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const clearAmlDepositTurnoverRequirements = useClearAmlDepositTurnoverRequirementsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useClearAmlDepositTurnoverRequirementsHook>>>,
    { userId: string }
  > = props => {
    const { userId } = props ?? {};

    return clearAmlDepositTurnoverRequirements(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ClearAmlDepositTurnoverRequirementsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useClearAmlDepositTurnoverRequirementsHook>>>
>;

export type ClearAmlDepositTurnoverRequirementsMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Clear the aml deposit turnover requirements
 */
export const useClearAmlDepositTurnoverRequirements = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useClearAmlDepositTurnoverRequirementsHook>>>,
    TError,
    { userId: string },
    TContext
  >;
}) => {
  const mutationOptions = useClearAmlDepositTurnoverRequirementsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the available bonuses of the user
 */
export const useGetUserAvailableBonusesHook = () => {
  const getUserAvailableBonuses = useCustomInstance<AvailableBonusesSchema>();

  return (userId: string, params?: GetUserAvailableBonusesParams, signal?: AbortSignal) => {
    return getUserAvailableBonuses({ url: `/users/${userId}/account/availablebonuses`, method: 'get', params, signal });
  };
};

export const getGetUserAvailableBonusesQueryKey = (userId: string, params?: GetUserAvailableBonusesParams) =>
  [`/users/${userId}/account/availablebonuses`, ...(params ? [params] : [])] as const;

export const useGetUserAvailableBonusesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserAvailableBonusesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserAvailableBonusesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserAvailableBonusesHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserAvailableBonusesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserAvailableBonusesQueryKey(userId, params);

  const getUserAvailableBonuses = useGetUserAvailableBonusesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserAvailableBonusesHook>>>> = ({ signal }) =>
    getUserAvailableBonuses(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserAvailableBonusesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserAvailableBonusesHook>>>
>;
export type GetUserAvailableBonusesQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @summary Get the available bonuses of the user
 */
export const useGetUserAvailableBonuses = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserAvailableBonusesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserAvailableBonusesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserAvailableBonusesHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserAvailableBonusesQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary Award the user an available bonus
 */
export const useAwardAvailableBonusHook = () => {
  const awardAvailableBonus = useCustomInstance<AvailableBonusesSchema>();

  return (userId: string, availableBonusArgumentsSchema: AvailableBonusArgumentsSchema) => {
    return awardAvailableBonus({
      url: `/users/${userId}/account/availablebonuses`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: availableBonusArgumentsSchema,
    });
  };
};

export const useAwardAvailableBonusMutationOptions = <
  TError =
    | AwardBonusBadRequestResponse
    | UnauthorizedResponse
    | NotFoundResponse
    | PreconditionFailedResponse
    | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAwardAvailableBonusHook>>>,
    TError,
    { userId: string; data: AvailableBonusArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAwardAvailableBonusHook>>>,
  TError,
  { userId: string; data: AvailableBonusArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const awardAvailableBonus = useAwardAvailableBonusHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAwardAvailableBonusHook>>>,
    { userId: string; data: AvailableBonusArgumentsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return awardAvailableBonus(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AwardAvailableBonusMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAwardAvailableBonusHook>>>
>;
export type AwardAvailableBonusMutationBody = AvailableBonusArgumentsSchema;
export type AwardAvailableBonusMutationError =
  | AwardBonusBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary Award the user an available bonus
 */
export const useAwardAvailableBonus = <
  TError =
    | AwardBonusBadRequestResponse
    | UnauthorizedResponse
    | NotFoundResponse
    | PreconditionFailedResponse
    | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAwardAvailableBonusHook>>>,
    TError,
    { userId: string; data: AvailableBonusArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAwardAvailableBonusMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the deposit bonuses of the user
 */
export const useGetUserDepositBonusesHook = () => {
  const getUserDepositBonuses = useCustomInstance<DepositBonusesSchema>();

  return (userId: string, params?: GetUserDepositBonusesParams, signal?: AbortSignal) => {
    return getUserDepositBonuses({ url: `/users/${userId}/account/depositbonuses`, method: 'get', params, signal });
  };
};

export const getGetUserDepositBonusesQueryKey = (userId: string, params?: GetUserDepositBonusesParams) =>
  [`/users/${userId}/account/depositbonuses`, ...(params ? [params] : [])] as const;

export const useGetUserDepositBonusesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserDepositBonusesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserDepositBonusesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserDepositBonusesHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserDepositBonusesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserDepositBonusesQueryKey(userId, params);

  const getUserDepositBonuses = useGetUserDepositBonusesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserDepositBonusesHook>>>> = ({ signal }) =>
    getUserDepositBonuses(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserDepositBonusesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserDepositBonusesHook>>>
>;
export type GetUserDepositBonusesQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @summary Get the deposit bonuses of the user
 */
export const useGetUserDepositBonuses = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserDepositBonusesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserDepositBonusesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserDepositBonusesHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserDepositBonusesQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary Award the user a deposit bonus
 */
export const useAwardDepositBonusHook = () => {
  const awardDepositBonus = useCustomInstance<DepositBonusesSchema>();

  return (userId: string, depositBonusArgumentsSchema: DepositBonusArgumentsSchema) => {
    return awardDepositBonus({
      url: `/users/${userId}/account/depositbonuses`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: depositBonusArgumentsSchema,
    });
  };
};

export const useAwardDepositBonusMutationOptions = <
  TError = AwardBonusBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAwardDepositBonusHook>>>,
    TError,
    { userId: string; data: DepositBonusArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAwardDepositBonusHook>>>,
  TError,
  { userId: string; data: DepositBonusArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const awardDepositBonus = useAwardDepositBonusHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAwardDepositBonusHook>>>,
    { userId: string; data: DepositBonusArgumentsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return awardDepositBonus(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AwardDepositBonusMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAwardDepositBonusHook>>>
>;
export type AwardDepositBonusMutationBody = DepositBonusArgumentsSchema;
export type AwardDepositBonusMutationError =
  | AwardBonusBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary Award the user a deposit bonus
 */
export const useAwardDepositBonus = <
  TError = AwardBonusBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAwardDepositBonusHook>>>,
    TError,
    { userId: string; data: DepositBonusArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAwardDepositBonusMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the free spins of the user
 */
export const useGetUserFreeSpinsHook = () => {
  const getUserFreeSpins = useCustomInstance<FreeSpinsSchema>();

  return (userId: string, params?: GetUserFreeSpinsParams, signal?: AbortSignal) => {
    return getUserFreeSpins({ url: `/users/${userId}/account/freespins`, method: 'get', params, signal });
  };
};

export const getGetUserFreeSpinsQueryKey = (userId: string, params?: GetUserFreeSpinsParams) =>
  [`/users/${userId}/account/freespins`, ...(params ? [params] : [])] as const;

export const useGetUserFreeSpinsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserFreeSpinsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserFreeSpinsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserFreeSpinsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserFreeSpinsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserFreeSpinsQueryKey(userId, params);

  const getUserFreeSpins = useGetUserFreeSpinsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserFreeSpinsHook>>>> = ({ signal }) =>
    getUserFreeSpins(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserFreeSpinsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetUserFreeSpinsHook>>>>;
export type GetUserFreeSpinsQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get the free spins of the user
 */
export const useGetUserFreeSpins = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserFreeSpinsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserFreeSpinsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserFreeSpinsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserFreeSpinsQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Award the user free spins
 */
export const useAwardFreeSpinsHook = () => {
  const awardFreeSpins = useCustomInstance<void>();

  return (userId: string, freeSpinsBonusArgumentsSchema: FreeSpinsBonusArgumentsSchema) => {
    return awardFreeSpins({
      url: `/users/${userId}/account/freespins`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: freeSpinsBonusArgumentsSchema,
    });
  };
};

export const useAwardFreeSpinsMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAwardFreeSpinsHook>>>,
    TError,
    { userId: string; data: FreeSpinsBonusArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAwardFreeSpinsHook>>>,
  TError,
  { userId: string; data: FreeSpinsBonusArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const awardFreeSpins = useAwardFreeSpinsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAwardFreeSpinsHook>>>,
    { userId: string; data: FreeSpinsBonusArgumentsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return awardFreeSpins(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AwardFreeSpinsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAwardFreeSpinsHook>>>>;
export type AwardFreeSpinsMutationBody = FreeSpinsBonusArgumentsSchema;
export type AwardFreeSpinsMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Award the user free spins
 */
export const useAwardFreeSpins = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAwardFreeSpinsHook>>>,
    TError,
    { userId: string; data: FreeSpinsBonusArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAwardFreeSpinsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the account transactions
 */
export const useGetAccountTransactionsHook = () => {
  const getAccountTransactions = useCustomInstance<TransactionsSchema>();

  return (userId: string, params?: GetAccountTransactionsParams, signal?: AbortSignal) => {
    return getAccountTransactions({ url: `/users/${userId}/account/transactions`, method: 'get', params, signal });
  };
};

export const getGetAccountTransactionsQueryKey = (userId: string, params?: GetAccountTransactionsParams) =>
  [`/users/${userId}/account/transactions`, ...(params ? [params] : [])] as const;

export const useGetAccountTransactionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAccountTransactionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: GetAccountTransactionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountTransactionsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountTransactionsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccountTransactionsQueryKey(userId, params);

  const getAccountTransactions = useGetAccountTransactionsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAccountTransactionsHook>>>> = ({ signal }) =>
    getAccountTransactions(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetAccountTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAccountTransactionsHook>>>
>;
export type GetAccountTransactionsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the account transactions
 */
export const useGetAccountTransactions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAccountTransactionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: GetAccountTransactionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountTransactionsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAccountTransactionsQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Cancel game round
 */
export const useCancelGameRoundHook = () => {
  const cancelGameRound = useCustomInstance<void>();

  return (userId: string, txId: string) => {
    return cancelGameRound({ url: `/users/${userId}/cancelRound/${txId}`, method: 'post' });
  };
};

export const useCancelGameRoundMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelGameRoundHook>>>,
    TError,
    { userId: string; txId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCancelGameRoundHook>>>,
  TError,
  { userId: string; txId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const cancelGameRound = useCancelGameRoundHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCancelGameRoundHook>>>,
    { userId: string; txId: string }
  > = props => {
    const { userId, txId } = props ?? {};

    return cancelGameRound(userId, txId);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelGameRoundMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelGameRoundHook>>>>;

export type CancelGameRoundMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Cancel game round
 */
export const useCancelGameRound = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelGameRoundHook>>>,
    TError,
    { userId: string; txId: string },
    TContext
  >;
}) => {
  const mutationOptions = useCancelGameRoundMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the withdrawal requests of the user
 */
export const useGetUserWithdrawalRequestsHook = () => {
  const getUserWithdrawalRequests = useCustomInstance<WithdrawalRequestsSchema>();

  return (userId: string, params?: GetUserWithdrawalRequestsParams, signal?: AbortSignal) => {
    return getUserWithdrawalRequests({
      url: `/users/${userId}/account/withdrawalrequests`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getGetUserWithdrawalRequestsQueryKey = (userId: string, params?: GetUserWithdrawalRequestsParams) =>
  [`/users/${userId}/account/withdrawalrequests`, ...(params ? [params] : [])] as const;

export const useGetUserWithdrawalRequestsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserWithdrawalRequestsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserWithdrawalRequestsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserWithdrawalRequestsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserWithdrawalRequestsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserWithdrawalRequestsQueryKey(userId, params);

  const getUserWithdrawalRequests = useGetUserWithdrawalRequestsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserWithdrawalRequestsHook>>>> = ({
    signal,
  }) => getUserWithdrawalRequests(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserWithdrawalRequestsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserWithdrawalRequestsHook>>>
>;
export type GetUserWithdrawalRequestsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the withdrawal requests of the user
 */
export const useGetUserWithdrawalRequests = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserWithdrawalRequestsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserWithdrawalRequestsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserWithdrawalRequestsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserWithdrawalRequestsQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the vault token balance of the user
 */
export const useGetUserVaultTokenBalanceHook = () => {
  const getUserVaultTokenBalance = useCustomInstance<VaultTokenBalanceSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getUserVaultTokenBalance({ url: `/users/${userId}/vault/balance`, method: 'get', signal });
  };
};

export const getGetUserVaultTokenBalanceQueryKey = (userId: string) => [`/users/${userId}/vault/balance`] as const;

export const useGetUserVaultTokenBalanceQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserVaultTokenBalanceHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVaultTokenBalanceHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVaultTokenBalanceHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserVaultTokenBalanceQueryKey(userId);

  const getUserVaultTokenBalance = useGetUserVaultTokenBalanceHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserVaultTokenBalanceHook>>>> = ({
    signal,
  }) => getUserVaultTokenBalance(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserVaultTokenBalanceQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserVaultTokenBalanceHook>>>
>;
export type GetUserVaultTokenBalanceQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the vault token balance of the user
 */
export const useGetUserVaultTokenBalance = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserVaultTokenBalanceHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVaultTokenBalanceHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserVaultTokenBalanceQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the vault transactions of the user
 */
export const useGetUserVaultTransactionsHook = () => {
  const getUserVaultTransactions = useCustomInstance<VaultTransactionsSchema>();

  return (userId: string, params?: GetUserVaultTransactionsParams, signal?: AbortSignal) => {
    return getUserVaultTransactions({ url: `/users/${userId}/vault/transactions`, method: 'get', params, signal });
  };
};

export const getGetUserVaultTransactionsQueryKey = (userId: string, params?: GetUserVaultTransactionsParams) =>
  [`/users/${userId}/vault/transactions`, ...(params ? [params] : [])] as const;

export const useGetUserVaultTransactionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserVaultTransactionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserVaultTransactionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVaultTransactionsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVaultTransactionsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserVaultTransactionsQueryKey(userId, params);

  const getUserVaultTransactions = useGetUserVaultTransactionsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserVaultTransactionsHook>>>> = ({
    signal,
  }) => getUserVaultTransactions(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserVaultTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserVaultTransactionsHook>>>
>;
export type GetUserVaultTransactionsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the vault transactions of the user
 */
export const useGetUserVaultTransactions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserVaultTransactionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserVaultTransactionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserVaultTransactionsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserVaultTransactionsQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary Get a full list of countries in the world
 */
export const useListCountriesHook = () => {
  const listCountries = useCustomInstance<CountryListSchema>();

  return (signal?: AbortSignal) => {
    return listCountries({ url: `/users/countries`, method: 'get', signal });
  };
};

export const getListCountriesQueryKey = () => [`/users/countries`] as const;

export const useListCountriesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListCountriesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCountriesHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCountriesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCountriesQueryKey();

  const listCountries = useListCountriesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListCountriesHook>>>> = ({ signal }) =>
    listCountries(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type ListCountriesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useListCountriesHook>>>>;
export type ListCountriesQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @deprecated
 * @summary Get a full list of countries in the world
 */
export const useListCountries = <
  TData = Awaited<ReturnType<ReturnType<typeof useListCountriesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListCountriesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListCountriesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Quicksearch a user
 */
export const useQuickUserSearchHook = () => {
  const quickUserSearch = useCustomInstance<UserSearchResultsSchema>();

  return (params: QuickUserSearchParams, signal?: AbortSignal) => {
    return quickUserSearch({ url: `/users/search`, method: 'get', params, signal });
  };
};

export const getQuickUserSearchQueryKey = (params: QuickUserSearchParams) =>
  [`/users/search`, ...(params ? [params] : [])] as const;

export const useQuickUserSearchQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useQuickUserSearchHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: QuickUserSearchParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQuickUserSearchHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQuickUserSearchHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQuickUserSearchQueryKey(params);

  const quickUserSearch = useQuickUserSearchHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useQuickUserSearchHook>>>> = ({ signal }) =>
    quickUserSearch(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type QuickUserSearchQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useQuickUserSearchHook>>>>;
export type QuickUserSearchQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Quicksearch a user
 */
export const useQuickUserSearch = <
  TData = Awaited<ReturnType<ReturnType<typeof useQuickUserSearchHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: QuickUserSearchParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQuickUserSearchHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useQuickUserSearchQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary Advanced search a user
 */
export const useAdvancedUserSearchHook = () => {
  const advancedUserSearch = useCustomInstance<UserSearchResultsSchema>();

  return (advancedSearchSchema: AdvancedSearchSchema, params?: AdvancedUserSearchParams) => {
    return advancedUserSearch({
      url: `/users/search`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: advancedSearchSchema,
      params,
    });
  };
};

export const useAdvancedUserSearchMutationOptions = <
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAdvancedUserSearchHook>>>,
    TError,
    { data: AdvancedSearchSchema; params?: AdvancedUserSearchParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAdvancedUserSearchHook>>>,
  TError,
  { data: AdvancedSearchSchema; params?: AdvancedUserSearchParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const advancedUserSearch = useAdvancedUserSearchHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAdvancedUserSearchHook>>>,
    { data: AdvancedSearchSchema; params?: AdvancedUserSearchParams }
  > = props => {
    const { data, params } = props ?? {};

    return advancedUserSearch(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AdvancedUserSearchMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAdvancedUserSearchHook>>>
>;
export type AdvancedUserSearchMutationBody = AdvancedSearchSchema;
export type AdvancedUserSearchMutationError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary Advanced search a user
 */
export const useAdvancedUserSearch = <
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAdvancedUserSearchHook>>>,
    TError,
    { data: AdvancedSearchSchema; params?: AdvancedUserSearchParams },
    TContext
  >;
}) => {
  const mutationOptions = useAdvancedUserSearchMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Advanced search for a user
 */
export const useAdvancedUserSearchV2Hook = () => {
  const advancedUserSearchV2 = useCustomInstance<PagedUserSearchResultsSchema>();

  return (params?: AdvancedUserSearchV2Params, signal?: AbortSignal) => {
    return advancedUserSearchV2({ url: `/users/search/advanced`, method: 'get', params, signal });
  };
};

export const getAdvancedUserSearchV2QueryKey = (params?: AdvancedUserSearchV2Params) =>
  [`/users/search/advanced`, ...(params ? [params] : [])] as const;

export const useAdvancedUserSearchV2QueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useAdvancedUserSearchV2Hook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: AdvancedUserSearchV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAdvancedUserSearchV2Hook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAdvancedUserSearchV2Hook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAdvancedUserSearchV2QueryKey(params);

  const advancedUserSearchV2 = useAdvancedUserSearchV2Hook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAdvancedUserSearchV2Hook>>>> = ({ signal }) =>
    advancedUserSearchV2(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type AdvancedUserSearchV2QueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAdvancedUserSearchV2Hook>>>
>;
export type AdvancedUserSearchV2QueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Advanced search for a user
 */
export const useAdvancedUserSearchV2 = <
  TData = Awaited<ReturnType<ReturnType<typeof useAdvancedUserSearchV2Hook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: AdvancedUserSearchV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useAdvancedUserSearchV2Hook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAdvancedUserSearchV2QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Set manualWithdrawaFlag property of the user's account
 */
export const useAutomaticWithdrawalPermissionHook = () => {
  const automaticWithdrawalPermission = useCustomInstance<void>();

  return (userId: string, automaticWithdrawalPermissionSchema: AutomaticWithdrawalPermissionSchema) => {
    return automaticWithdrawalPermission({
      url: `/users/${userId}/manualwithdrawalflag`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: automaticWithdrawalPermissionSchema,
    });
  };
};

export const useAutomaticWithdrawalPermissionMutationOptions = <
  TError = UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAutomaticWithdrawalPermissionHook>>>,
    TError,
    { userId: string; data: AutomaticWithdrawalPermissionSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAutomaticWithdrawalPermissionHook>>>,
  TError,
  { userId: string; data: AutomaticWithdrawalPermissionSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const automaticWithdrawalPermission = useAutomaticWithdrawalPermissionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAutomaticWithdrawalPermissionHook>>>,
    { userId: string; data: AutomaticWithdrawalPermissionSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return automaticWithdrawalPermission(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AutomaticWithdrawalPermissionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAutomaticWithdrawalPermissionHook>>>
>;
export type AutomaticWithdrawalPermissionMutationBody = AutomaticWithdrawalPermissionSchema;
export type AutomaticWithdrawalPermissionMutationError = UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Set manualWithdrawaFlag property of the user's account
 */
export const useAutomaticWithdrawalPermission = <
  TError = UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAutomaticWithdrawalPermissionHook>>>,
    TError,
    { userId: string; data: AutomaticWithdrawalPermissionSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAutomaticWithdrawalPermissionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get (poll) for the annual report for this user for a given year.
 */
export const useGetAnnualUserReportHook = () => {
  const getAnnualUserReport = useCustomInstance<AnnualUserReportResponseSchema | void>();

  return (userId: number, year: number, signal?: AbortSignal) => {
    return getAnnualUserReport({ url: `/users/${userId}/annual-report/${year}`, method: 'get', signal });
  };
};

export const getGetAnnualUserReportQueryKey = (userId: number, year: number) =>
  [`/users/${userId}/annual-report/${year}`] as const;

export const useGetAnnualUserReportQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAnnualUserReportHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  userId: number,
  year: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAnnualUserReportHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAnnualUserReportHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAnnualUserReportQueryKey(userId, year);

  const getAnnualUserReport = useGetAnnualUserReportHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAnnualUserReportHook>>>> = ({ signal }) =>
    getAnnualUserReport(userId, year, signal);

  return { queryKey, queryFn, enabled: !!(userId && year), ...queryOptions };
};

export type GetAnnualUserReportQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAnnualUserReportHook>>>
>;
export type GetAnnualUserReportQueryError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Get (poll) for the annual report for this user for a given year.
 */
export const useGetAnnualUserReport = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAnnualUserReportHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  userId: number,
  year: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAnnualUserReportHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAnnualUserReportQueryOptions(userId, year, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get or create an annual report for this user and year.
 */
export const useGetOrCreateAnnualUserReportHook = () => {
  const getOrCreateAnnualUserReport = useCustomInstance<
    AnnualUserReportResponseSchema | AnnualUserReportAcceptedResponseSchema
  >();

  return (
    userId: number,
    year: number,
    getOrCreateAnnualUserReportRequestSchema: GetOrCreateAnnualUserReportRequestSchema
  ) => {
    return getOrCreateAnnualUserReport({
      url: `/users/${userId}/annual-report/${year}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: getOrCreateAnnualUserReportRequestSchema,
    });
  };
};

export const useGetOrCreateAnnualUserReportMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetOrCreateAnnualUserReportHook>>>,
    TError,
    { userId: number; year: number; data: GetOrCreateAnnualUserReportRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useGetOrCreateAnnualUserReportHook>>>,
  TError,
  { userId: number; year: number; data: GetOrCreateAnnualUserReportRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const getOrCreateAnnualUserReport = useGetOrCreateAnnualUserReportHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useGetOrCreateAnnualUserReportHook>>>,
    { userId: number; year: number; data: GetOrCreateAnnualUserReportRequestSchema }
  > = props => {
    const { userId, year, data } = props ?? {};

    return getOrCreateAnnualUserReport(userId, year, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetOrCreateAnnualUserReportMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetOrCreateAnnualUserReportHook>>>
>;
export type GetOrCreateAnnualUserReportMutationBody = GetOrCreateAnnualUserReportRequestSchema;
export type GetOrCreateAnnualUserReportMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | DefaultResponse;

/**
 * @summary Get or create an annual report for this user and year.
 */
export const useGetOrCreateAnnualUserReport = <
  TError = BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetOrCreateAnnualUserReportHook>>>,
    TError,
    { userId: number; year: number; data: GetOrCreateAnnualUserReportRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useGetOrCreateAnnualUserReportMutationOptions(options);

  return useMutation(mutationOptions);
};
