import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { JackpotInstance } from '@greenisland-store/gameServer';
import { Box, Typography } from '@mui/material';
import { getDateTimeFromUnix } from 'src/app/helpers/transformFunctions';

import { formatCurrency } from '@greenisland-common/helpers';

interface Props {
  jackpotInstance: JackpotInstance;
}

const JackpotInstanceDetail = ({ jackpotInstance }: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ paddingY: 1, paddingLeft: 2 }}>
      <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
        {t('content.gameserver.jackpotInstance.contributingGames')}
      </Typography>
      <Typography sx={{ textTransform: 'capitalize' }} variant="body2">
        {jackpotInstance.contributingGames.map(x => ' ' + x.gameReference).toString()}
      </Typography>
      <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
        {t('content.gameserver.jackpotInstance.gameContribution')}
      </Typography>
      <Typography sx={{ textTransform: 'capitalize' }} variant="body2">
        {jackpotInstance.gameContribution * 100 + '%'}
      </Typography>
      {WinDetail(jackpotInstance)}
    </Box>
  );
};

const WinDetail = (jackpotInstance: JackpotInstance) => {
  const { t } = useTranslation();
  if (jackpotInstance.jackpotWin) {
    return (
      <Box>
        <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
          {t('content.gameserver.jackpotInstanceDetails.jackpotWin')}
        </Typography>
        <Typography sx={{ textTransform: 'capitalize' }} variant="body2">
          {t('content.gameserver.jackpotInstance.jackpotWin.gameReference')}:
          {jackpotInstance.jackpotWin.gameReference?.toString() + '\t'}
          {t('content.gameserver.jackpotInstance.jackpotWin.gameRoundId')}:
          {jackpotInstance.jackpotWin.gameRoundId?.toString() + '\t'}
          {t('content.gameserver.jackpotInstance.jackpotWin.playerId')}:
          {jackpotInstance.jackpotWin.playerId?.toString() + '\t'}
          {t('content.gameserver.jackpotInstance.jackpotWin.winAmount')}:
          {formatCurrency((jackpotInstance.jackpotWin?.winAmount ?? NaN) / 100, jackpotInstance.baseCurrency) + '\t'}
          {t('content.gameserver.jackpotInstance.jackpotWin.timestamp')}:
          {getDateTimeFromUnix(jackpotInstance.jackpotWin.timestamp ?? 0) + '\t'}
        </Typography>
      </Box>
    );
  }
};

export default memo(JackpotInstanceDetail);
