/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EBGCArticle6ActionTypeSchema =
  (typeof EBGCArticle6ActionTypeSchema)[keyof typeof EBGCArticle6ActionTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EBGCArticle6ActionTypeSchema = {
  RequestExceed: 'RequestExceed',
  CanExceedRequest: 'CanExceedRequest',
  GetLimitStatusForToken: 'GetLimitStatusForToken',
  DeleteRequestExceed: 'DeleteRequestExceed',
} as const;
