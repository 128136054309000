import { useTranslation } from 'react-i18next';
import { Box, SxProps, Theme } from '@mui/material';

interface Props {
  sx: SxProps<Theme>;
  value: string;
}

const ColorCell = ({ sx, value }: Props) => {
  const { t } = useTranslation();
  return (
    <Box component="span" sx={{ ...sx, whitespace: 'nowrap' }}>
      {t(value)}
    </Box>
  );
};

export default ColorCell;
