import { memo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  CountryListSchema,
  CountryListSchemaItem,
  EVerificationStatusSchema,
  getGetUserVerificationsQueryKey,
  useDeleteUserVerificationsDomicileDocument,
  useUpdateUserVerificationsDomicile,
  useUpdateUserVerificationsDomicileVerificationStatus,
  useUploadUserVerificationsDomicileDocument,
  VerificationsDomicileArgumentsSchema,
  VerificationsSchemaDomicile,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  TextField,
} from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { DataGridPlain, DataGridPlainHeader, ErrorCard, Link } from '@greenisland-common/components/atoms';
import { RowContainer } from '@greenisland-common/components/atoms/DataGridPlain/DataGridPlain';
import FileUploadDialog from '@greenisland-common/components/molecules/FileUpload/FileUploadDialog';

import UpdateVerificationStatusField from './Identity/Fields/UpdateVerificationStatusField';
import { usePermission } from '../../../../../../app/hooks';
import DeclineVerificationDialog from './DeclineVerificationDialog';

type DomicileProps = {
  domicile: VerificationsSchemaDomicile;
  countries?: CountryListSchema;
};

interface IDomicileForm extends Omit<VerificationsDomicileArgumentsSchema, 'country'> {
  country: CountryListSchemaItem;
}

const headers: GridColumns = [
  {
    headerName: '',
    field: 'title',
    renderCell: ({ value }) => <DataGridPlainHeader value={value} />,
    width: 220,
  },
  { headerName: '', field: 'value', renderCell: ({ value }) => <RowContainer>{value}</RowContainer>, flex: 1 },
];

const Domicile = ({ domicile, countries }: DomicileProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { userId = '' } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const canSaveDomicileVerification = usePermission(OnlineCasinoPermissions.updateUserVerificationsDomicile);
  const canEditDomicileVerificationStatus = usePermission(
    OnlineCasinoPermissions.updateUserVerificationsDomicileVerificationStatus
  );

  const getNationalityDefaultValue = () => {
    const nationality = countries?.find((country: any) => country.countryCode === domicile.country);
    return nationality || { countryCode: '', countryName: '' };
  };

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { isDirty },
    reset,
  } = useForm<IDomicileForm>({
    defaultValues: {
      street: domicile.street,
      houseNumber: domicile.houseNumber,
      postBox: domicile.postBox,
      city: domicile.city,
      postalCode: domicile.postalCode,
      country: getNationalityDefaultValue(),
    },
  });

  const mutationObj = {
    mutation: {
      onSuccess: async () => {
        queryClient.invalidateQueries(getGetUserVerificationsQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  };

  const { mutate: uploadDomicileDocument } = useUploadUserVerificationsDomicileDocument(mutationObj);
  const { mutate: deleteDomicileDocument } = useDeleteUserVerificationsDomicileDocument(mutationObj);
  const { mutate: updateUserVerificationsDomicile, isLoading: userVerificationsDomicileIsLoading } =
    useUpdateUserVerificationsDomicile(mutationObj);
  const updateUserVerificationsDomicileStatusMutation =
    useUpdateUserVerificationsDomicileVerificationStatus(mutationObj);

  const onSubmit = async (data: IDomicileForm) => {
    updateUserVerificationsDomicile({
      userId,
      data: { ...data, country: data.country.countryCode },
    });

    reset(data);
  };

  if (!countries) return <ErrorCard sx={{ height: 500 }}>{t('erroFetchingCountriesReloadPage')}</ErrorCard>;

  const rows: GridRowsProp = [
    {
      id: 1,
      title: t('verificationStatus.title'),
      value: (
        <>
          <UpdateVerificationStatusField
            userId={userId}
            status={domicile}
            updateStatus={updateUserVerificationsDomicileStatusMutation}
          />
          {canEditDomicileVerificationStatus &&
            domicile.verificationStatus === EVerificationStatusSchema.WaitingForApproval && (
              <Box mt={1}>
                <Button
                  sx={{ mr: 1 }}
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={() =>
                    updateUserVerificationsDomicileStatusMutation.mutate({
                      userId,
                      data: { verificationStatus: EVerificationStatusSchema.Verified },
                    })
                  }
                >
                  {updateUserVerificationsDomicileStatusMutation.isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    t('verify')
                  )}
                </Button>
                <Button size="small" color="error" variant="outlined" onClick={() => setOpenDialog(true)}>
                  {t('decline')}
                </Button>
              </Box>
            )}
        </>
      ),
    },
    {
      id: 2,
      title: t('street'),
      value: <TextField size="small" fullWidth name="street" defaultValue={domicile.street} inputRef={register()} />,
    },
    {
      id: 3,
      title: t('houseNumber'),
      value: (
        <TextField
          size="small"
          fullWidth
          name="houseNumber"
          defaultValue={domicile.houseNumber}
          inputRef={register()}
        />
      ),
    },
    {
      id: 4,
      title: t('postBox'),
      value: <TextField size="small" fullWidth name="postBox" defaultValue={domicile.postBox} inputRef={register()} />,
    },
    {
      id: 5,
      title: t('city'),
      value: <TextField size="small" fullWidth name="city" defaultValue={domicile.city} inputRef={register()} />,
    },
    {
      id: 6,
      title: t('postalCode'),
      value: (
        <TextField size="small" fullWidth name="postalCode" defaultValue={domicile.postalCode} inputRef={register()} />
      ),
    },
    {
      id: 7,
      title: t('country'),
      value: (
        <Controller
          name="country"
          control={control}
          rules={{ required: t('fieldIsRequired') as string }}
          defaultValue={countries?.find((country: CountryListSchemaItem) => country.countryCode === domicile.country)}
          render={({ onChange, value }) => {
            return (
              <Autocomplete
                id="countries"
                options={countries}
                getOptionLabel={(option: CountryListSchemaItem) => (option.countryName ? option.countryName : '')}
                isOptionEqualToValue={(option, value) => option.countryCode === value.countryCode}
                renderInput={({ size, ...params }) => (
                  <TextField label={t('country')} size="small" error={!!errors.country} {...params} />
                )}
                value={value}
                onChange={(e, value) => {
                  onChange(value);
                }}
              />
            );
          }}
        />
      ),
    },
    {
      id: 8,
      title: t('domicileDocument'),
      value: (
        <Box display="flex" gap={1}>
          <FileUploadDialog
            requirements={{ size: 5, mimeTypes: ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'] }}
            id="verificationDomicile"
            saveFile={file => uploadDomicileDocument({ userId, data: file })}
            deleteFile={() => deleteDomicileDocument({ userId })}
            metadata={domicile?.domicileDocumentMetadata}
          />
          {domicile.domicileDocumentMetadata && (
            <Button size="small" onClick={() => deleteDomicileDocument({ userId })} color="error" variant="outlined">
              {t('remove')}
            </Button>
          )}
        </Box>
      ),
      minHeight: 100,
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ mt: 2, pl: 0, pr: 0 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CardHeader title={t('domicile')} />
          <Button component={Link} size="small" variant="outlined" to={`../domicile-history`}>
            {t('domicileHistory')}
          </Button>
        </Box>
        <CardContent sx={{ pl: 0, pr: 0 }}>
          <DataGridPlain columns={headers} rows={rows} getRowHeight={() => 'auto'} />
        </CardContent>
        <CardActions>
          {canSaveDomicileVerification && (
            <LoadingButton
              color="primary"
              size="medium"
              variant="contained"
              type="submit"
              disabled={!isDirty}
              loading={userVerificationsDomicileIsLoading}
            >
              {t('save')}
            </LoadingButton>
          )}
        </CardActions>
      </Card>
      <DeclineVerificationDialog
        open={openDialog}
        setOpen={setOpenDialog}
        action={data => updateUserVerificationsDomicileStatusMutation.mutate({ userId, data })}
        domicile={domicile}
      />
    </form>
  );
};

export default memo(Domicile);
