import React from 'react';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';

interface Props {
  sx?: SxProps;
}

const DataGridContainer: React.FC<Props> = ({ children, sx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        overflow: 'scroll',
        backgroundColor: 'background.default',
        ...sx,
      }}
    >
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
    </Box>
  );
};

export default DataGridContainer;
