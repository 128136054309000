import { useMemo } from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor } from 'slate';
import { useSlate } from 'slate-react';

import { CustomEditor } from '../types';
import { isTextFormatType } from '../typeUtils';
import { Button } from './RichTextComponents';

const isMarkActive = (editor: CustomEditor, format: string) => {
  if (!isTextFormatType(format)) {
    console.error(`Invalid mark type: ${format}`);
    return;
  }
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleMark = (editor: CustomEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const MarkButton = ({ format, icon }: { format: string; icon: IconDefinition | string }) => {
  const editor = useSlate();
  useMemo(() => {
    return isMarkActive(editor, format);
  }, [editor, format]);

  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event: { preventDefault: () => void }) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {typeof icon === 'string' || icon instanceof String ? icon : <FontAwesomeIcon icon={icon} />}
    </Button>
  );
};

export default MarkButton;
