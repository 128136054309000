/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * EOperatorJackpotType:
 * `bigDrop` - A jackpot type that in principle should not be affected by any constraints, but it is modelled like a mustDrop to limit the casino's losses.
 * `mustDrop` - A jackpot type that is forced to drop before its value reaches a predefined maximum value.
 * `timeDrop` - A type of jackpot that is forced to drop in a predefined amount of time, expressed in amount of minutes since start

 */
export type EOperatorJackpotTypeSchema = (typeof EOperatorJackpotTypeSchema)[keyof typeof EOperatorJackpotTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EOperatorJackpotTypeSchema = {
  bigDrop: 'bigDrop',
  mustDrop: 'mustDrop',
  timeDrop: 'timeDrop',
} as const;
