export interface ChipData {
  id: string;
  label: string;
  type: GameFilterType;
}

export enum GameFilterType {
  PAGE = 'page',
  PAGE_SIZE = 'page_size',
  SORT_BY = 'sortby',
  SEARCH = 'search',
  THEME = 'theme',
  STUDIO = 'studio',
  PROVIDER = 'provider',
  CATEGORY = 'category',
  ENABLED = 'enabled',
  FEATURED = 'featured',
  NEW = 'new',
  POPULAR = 'popular',
  LIVE = 'live',
  HAS_JACKPOT = 'hasJackpot',
  EXCLUSIVE = 'exclusive',
  ADDITIONAL = 'additionalFilters',
}

interface ChipItem {
  code?: string;
  name?: string;
  codeName?: string;
  gameStudioName?: string;
}

export const getGameFilterTypeLabel = (type: GameFilterType): string => {
  switch (type) {
    case GameFilterType.THEME:
      return 'content.games.filter.theme';
    case GameFilterType.PROVIDER:
      return 'content.games.filter.provider';
    case GameFilterType.STUDIO:
      return 'content.games.filter.studio';
    case GameFilterType.CATEGORY:
      return 'content.games.filter.category';
    case GameFilterType.ENABLED:
    case GameFilterType.FEATURED:
    case GameFilterType.LIVE:
    case GameFilterType.HAS_JACKPOT:
    case GameFilterType.NEW:
    case GameFilterType.POPULAR:
    case GameFilterType.EXCLUSIVE:
    case GameFilterType.ADDITIONAL:
      return 'content.games.filter.filter';
    default:
      return '';
  }
};

export const getSelectedChipsFilters = (chips: ChipData[], filterType: GameFilterType) => {
  return chips?.filter(chip => chip?.type === filterType)?.map(c => c?.id);
};

export const convertDropdownItemsToChipsData = <T extends ChipItem>(
  allItems: T[] | undefined,
  selectedFilters: string | null,
  type: GameFilterType
): ChipData[] => {
  if (!allItems || !selectedFilters) return [];

  const filteredItems = allItems?.filter(item => selectedFilters?.split(',')?.some(item2 => item?.code === item2));

  return filteredItems?.map(
    item =>
      ({
        id: item?.code || '',
        label:
          type === GameFilterType.THEME
            ? item?.name || ''
            : type === GameFilterType.STUDIO
            ? item?.gameStudioName || ''
            : type === GameFilterType.CATEGORY
            ? item?.codeName || ''
            : '',
        type,
      } || [])
  );
};

export const convertCheckboxItemsToChipsData = (additionalFilters: string | null): ChipData[] => {
  if (!additionalFilters) return [];

  return additionalFilters
    ?.split(',')
    ?.flatMap(item => (item ? [{ id: item, label: item, type: GameFilterType.ADDITIONAL }] : []));
};

export const ADD_GAME_ID = 'add';
