/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * The state of the kill switch
 */
export type EKillSwitchStateSchema = (typeof EKillSwitchStateSchema)[keyof typeof EKillSwitchStateSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EKillSwitchStateSchema = {
  Enabled: 'Enabled',
  Disabled: 'Disabled',
} as const;
