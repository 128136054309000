import { faListAlt } from '@fortawesome/free-solid-svg-icons';

import GameServer from '../../pages/GameServer/GameServer';
import { BaseRoute } from '../RouteInterfaces';

export const dashboardRoutes: Array<BaseRoute> = [
  {
    path: 'dashboard',
    name: 'Dashboard',
    element: GameServer,
    icon: faListAlt,
  },
];

export default dashboardRoutes;
