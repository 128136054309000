import React from 'react';
import { RegistrationSchema } from '@greenisland-api';

import GenericField from './GenericField';

interface Props {
  registration: RegistrationSchema;
}

const EpisBlockedSportField = ({ registration }: Props) => (
  <GenericField value={!registration.allowedSports} error={!registration.allowedSports} />
);

export default EpisBlockedSportField;
