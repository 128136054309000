import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useQueryPlayers } from '@greenisland-store/gameServer';
import { GridColDef } from '@mui/x-data-grid-pro';
import { getUnixTime } from 'date-fns';

import { Link, StyledDataGrid } from '@greenisland-common/components/atoms';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers';

import { transformDefaultV2 } from '../../app/helpers/transformFunctions';

const Lookup = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchKey = searchParams.get('searchKey') ?? undefined;
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  const pageSize = searchParams.get('pageSize') ? Number(searchParams.get('pageSize')) : 20;

  const { isLoading: fetching, data: playerSearch } = useQueryPlayers({ searchKey, page, pageSize });

  const handlePageChange = (page: number, pageSize: number) => {
    if (page + 1 !== page) {
      replaceUrlQueryParameters(
        {
          searchKey: localStorage.getItem('gameServerSearchKey') || undefined,
          page: (page + 1).toString(),
          pageSize: pageSize.toString(),
        },
        setSearchParams
      );
    }
  };

  const columns: GridColDef[] =
    playerSearch && playerSearch.entries && playerSearch.entries.length > 0
      ? Object.keys(playerSearch.entries[0])
          .map(key => {
            switch (key) {
              default:
                return {
                  ...transformDefaultV2(key, t),
                  flex: 1,
                };
            }
          })
          .concat([
            {
              field: 'gamerounds',
              headerName: t('gamerounds'),
              sortable: false,
              filterable: false,
              flex: 1,
              renderCell: params => (
                <Link
                  to={`../players/${params.row.externalId}/gamerounds?startdate=${getUnixTime(
                    new Date(new Date().getTime() - 2 * 60 * 60 * 1000)
                  )}&enddate=${getUnixTime(new Date())}`}
                >
                  {t('gamerounds')}
                </Link>
              ),
            },
          ])
      : [];

  const rows = playerSearch?.entries || [];

  return (
    <StyledDataGrid
      columns={columns}
      rows={rows}
      pagination
      paginationMode="server"
      rowCount={playerSearch?.totalNumberOfEntries || 0}
      page={playerSearch?.currentPage ? playerSearch.currentPage - 1 : 0}
      pageSize={10}
      onPageChange={page => handlePageChange(page, 10)}
      loading={fetching}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
};

export default Lookup;
