import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export interface FilterMenuOption {
  id: string;
  label: string;
}

interface Props {
  options: FilterMenuOption[];
  label: string;
  selected: string[];
  onClick: (selected: FilterMenuOption & { selected?: boolean }) => void;
  multiple?: boolean;
}

const ITEM_HEIGHT = 48;

const FilterMenu = ({ options, label, onClick, selected, multiple }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelect = (element: FilterMenuOption) => {
    const alreadySelected = selected.includes(element.id);
    if (alreadySelected) {
      onClick(element);
    } else {
      onClick({ ...element, selected: true });
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant={'text'}
        color="info"
        endIcon={<ExpandMoreIcon />}
        size="small"
      >
        {label}
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option.id}
            onClick={() => handleSelect(option)}
            sx={
              !multiple && selected.includes(option.id)
                ? {
                    backgroundColor: 'primary.main',
                    color: 'white',
                    '&:hover': { backgroundColor: 'primary.main', color: 'white' },
                  }
                : {}
            }
          >
            {multiple ? (
              <FormControlLabel
                control={<Checkbox />}
                checked={selected.includes(option.id)}
                label={option.label}
                onChange={() => handleSelect(option)}
              />
            ) : (
              <Box component="span">{option.label}</Box>
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default FilterMenu;
