import { useTranslation } from 'react-i18next';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Box, capitalize, Tooltip, Typography } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

import { theme } from '../../../../../../app/theme/theme';

interface Props {
  title: string;
  content: string;
}

const AddTestUserCopyField = ({ title, content }: Props) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center">
      <Typography fontWeight={500} variant="body2" fontSize="medium">
        {`${capitalize(title)}: `}
        <span style={{ fontWeight: 400 }}>{content}</span>
      </Typography>
      <Tooltip title={t('copy')}>
        <Box>
          <FontAwesomeIcon
            sx={{ ml: 2, cursor: 'pointer', color: theme.palette.text.secondary }}
            icon={faCopy}
            onClick={() => navigator.clipboard.writeText(content)}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default AddTestUserCopyField;
