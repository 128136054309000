import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';
import TabMenu, { Tab } from 'src/routes/components/TabMenu';

import { Link } from '@greenisland-common/components/atoms';

import ActiveLimitsTable from './tables/ActiveLimitsTable';
import ActiveMaximumLimitsTable from './tables/ActiveMaximumLimitsTable';
import LimitRequestsTable from './tables/LimitRequestsTable';
import { usePermission } from '../../../../../../app/hooks';
import { LimitSearchFilters } from '../Constants/Limits';
import DepositLimitRequestDialog from './DepositLimitRequestDialog';

type DepositLimitsProps = {
  userId: string;
};

const DepositLimitsCard = ({ userId }: DepositLimitsProps) => {
  const { t } = useTranslation();

  const canReadDepositLimits = usePermission(OnlineCasinoPermissions.getActiveDepositLimitsForUser);
  const canSetLimit = usePermission(OnlineCasinoPermissions.requestDepositLimitUpdate);

  const [openDialog, setOpenDialog] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const tabs: Tab[] = [
    {
      title: 'depositLimit.tabTitle.activeLimits',
      content: <ActiveLimitsTable userId={userId} />,
    },
    {
      title: 'depositLimit.tabTitle.queuedRequests',
      content: <LimitRequestsTable userId={userId} showOnlyQueuedRequests={true} compact />,
    },
    {
      title: 'depositLimit.tabTitle.maxLimits',
      content: <ActiveMaximumLimitsTable userId={userId} />,
    },
  ];

  return (
    <Card>
      <Box display="flex" justifyContent="space-between">
        <CardHeader title={t('depositLimit.title')} />
        <Box display="flex" gap={1} flexWrap="wrap" justifyContent="flex-end">
          {canSetLimit && (
            <Button size="small" variant="contained" onClick={() => setOpenDialog(true)}>
              {t('common.setLimit')}
            </Button>
          )}
          {canReadDepositLimits && (
            <Button
              size="small"
              variant="outlined"
              component={Link}
              to={`../depositLimits?${LimitSearchFilters.ACTIVE_TAB}=${activeTab}`}
            >
              {t('seeMore')}
            </Button>
          )}
        </Box>
      </Box>
      <CardContent>
        <TabMenu tabs={tabs} setActiveTab={setActiveTab} />
      </CardContent>
      {openDialog && (
        <DepositLimitRequestDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          userId={userId}
          activeTab={activeTab === 2 ? 1 : 0}
        />
      )}
    </Card>
  );
};

export default memo(DepositLimitsCard);
