import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortOrderParamParameter, useGetLoyaltySpendablePointsTx } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { capitalize } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { usePermission } from 'src/app/hooks';

import {
  DataGridContainer,
  DataGridPagination,
  DateTime,
  PermissionWrapper,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { useGetLoyaltyUserErrorString } from '../../Loyalty/Hooks/useGetLoyaltyUserErrorString';

type LoyaltyTransactionsProps = {
  userId: number;
  compact?: boolean;
  sortOrder?: SortOrderParamParameter;
};

const LoyaltySpendablePointsTransactionsTable = ({ userId, compact = false, sortOrder }: LoyaltyTransactionsProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(compact ? 10 : 25);
  const canReadLoyaltySpendablePointsTransactions = usePermission(OnlineCasinoPermissions.getLoyaltySpendablePointsTx);
  const {
    data: loyaltySpendablePointsBalanceTxData,
    isLoading,
    isError,
    error,
  } = useGetLoyaltySpendablePointsTx(
    userId,
    {
      page: page,
      pageSize: pageSize,
      sortOrder: sortOrder,
    },
    { query: { enabled: canReadLoyaltySpendablePointsTransactions } }
  );
  const getErrorString = useGetLoyaltyUserErrorString();

  const headers: GridColumns = [
    { headerName: capitalize(t('type')), field: 'txType', flex: 1, minWidth: 120 },
    {
      headerName: capitalize(t('date')),
      field: 'txDate',
      renderCell: ({ row }) => <DateTime dateTime={row.txDate} />,
      flex: 1,
      minWidth: 100,
    },
    { headerName: capitalize(t('amount')), field: 'txAmount', flex: 1, minWidth: 20 },
    { headerName: capitalize(t('balanceAfter')), field: 'balanceAfter', flex: 1, minWidth: 20 },
    { headerName: capitalize(t('description')), field: 'txDescription', flex: 1, minWidth: 120 },
    { headerName: capitalize(t('reference')), field: 'reference', flex: 1, minWidth: 500 },
  ];

  const rows: GridRowsProp = loyaltySpendablePointsBalanceTxData?.items
    ? loyaltySpendablePointsBalanceTxData?.items.map((item, i) => {
        return {
          ...item,
          id: i,
        };
      })
    : [];

  return (
    <>
      <PermissionWrapper
        errorMessage={getErrorString(error)}
        isError={isError}
        isLoading={isLoading}
        permission={OnlineCasinoPermissions.getLoyaltySpendablePointsTx}
      >
        <DataGridContainer>
          <StyledDataGrid
            autoHeight
            density="compact"
            columns={headers}
            rows={rows}
            pageSize={pageSize}
            paginationMode="server"
            pagination
            page={loyaltySpendablePointsBalanceTxData?.pagingDetails?.currentPage}
            rowCount={loyaltySpendablePointsBalanceTxData?.pagingDetails?.totalItems}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            components={{ Pagination: DataGridPagination }}
            componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
          />
        </DataGridContainer>
      </PermissionWrapper>
    </>
  );
};

export default memo(LoyaltySpendablePointsTransactionsTable);
