import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getGetVaultTokenBoostersQueryKey,
  useDeactivateTokenBooster,
  useGetVaultTokenBoosters,
  VaultTokenBoosterSchema,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Stack } from '@mui/material';
import {
  GridActionsCellItem,
  GridColumns,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { DataGridContainer, StyledDataGrid } from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';
import ConfirmDialog from '@greenisland-common/components/organisms/ConfirmDialog';

import { formatDateTime } from '@greenisland-common/helpers';

import { usePermission } from '../../../../../app/hooks';
import { VaultTokenBoosterDialog } from './components';

type Row = VaultTokenBoosterSchema & { id: number | undefined };

const VaultTokenBoosterPage = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const canReadVaultTokenBooster = usePermission(OnlineCasinoPermissions.getVaultTokenBoosters);
  const canWriteVaultTokenBooster = usePermission(OnlineCasinoPermissions.addVaultTokenBooster);

  const [isOpen, setIsOpen] = useState(false);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<{ isOpen: boolean; boosterId?: string }>({
    isOpen: false,
  });

  const { data: tokenBoosters, isLoading, isError, refetch } = useGetVaultTokenBoosters();
  const mutation = useDeactivateTokenBooster({
    mutation: {
      onSuccess: async () => {
        enqueueSnackbar(t('contests.vault.tokenBooster.deleteSuccess'), {
          variant: 'success',
        });
        queryClient.invalidateQueries(getGetVaultTokenBoostersQueryKey());
        setDeleteConfirmationDialog({ isOpen: false });
      },

      onError: async () => {
        enqueueSnackbar(t('contests.vault.tokenBooster.deleteError'), {
          variant: 'error',
        });
      },
    },
  });

  const rows = useMemo(
    () => tokenBoosters?.map(booster => ({ ...booster, id: booster.boosterId })) ?? [],
    [tokenBoosters]
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        headerName: t('startDate'),
        field: 'startDate',
        type: 'date',
        valueGetter: ({ value }: GridValueGetterParams<number>) => value && new Date(value * 1000),
        valueFormatter: ({ value }: GridValueFormatterParams<Date>) => formatDateTime(value, false),
        minWidth: 180,
        flex: 1,
      },
      {
        headerName: t('endDate'),
        field: 'endDate',
        type: 'date',
        valueGetter: ({ value }: GridValueGetterParams<number>) => value && new Date(value * 1000),
        valueFormatter: ({ value }: GridValueFormatterParams<Date>) => formatDateTime(value, false),
        minWidth: 180,
        flex: 1,
      },
      {
        headerName: t('factor'),
        field: 'factor',
        type: 'number',
        minWidth: 180,
        flex: 1,
      },
      ...(canWriteVaultTokenBooster
        ? [
            {
              field: 'actions',
              type: 'actions',
              getActions: (params: GridRowParams<Row>) => [
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  label="Delete"
                  onClick={() => setDeleteConfirmationDialog({ isOpen: true, boosterId: params.id as string })}
                  key="delete"
                />,
              ],
            },
          ]
        : []),
    ],
    [canWriteVaultTokenBooster, t]
  );

  const handleConfirm = () => {
    if (deleteConfirmationDialog.boosterId) {
      mutation.mutate({ boosterId: parseInt(deleteConfirmationDialog.boosterId) });
    }
  };

  return canReadVaultTokenBooster ? (
    <>
      <Stack spacing={2}>
        {canWriteVaultTokenBooster ? (
          <Box>
            <Button
              variant="contained"
              size="medium"
              startIcon={<FontAwesomeIcon icon={faAdd} />}
              onClick={() => setIsOpen(true)}
            >
              {t('addTokenBooster')}
            </Button>
          </Box>
        ) : undefined}

        {!isError ? (
          <DataGridContainer>
            <StyledDataGrid density="compact" autoHeight loading={isLoading} rows={rows} columns={columns} />
          </DataGridContainer>
        ) : (
          <ErrorState errorMessage={t('contests.vault.tokenBooster.loadingError')} retryAction={() => refetch()} />
        )}
      </Stack>
      <ConfirmDialog
        title={t('contests.vault.tokenBooster.deleteConfirmation.title')}
        description={t('contests.vault.tokenBooster.deleteConfirmation.description')}
        isOpen={deleteConfirmationDialog.isOpen}
        onCancel={() => setDeleteConfirmationDialog({ ...deleteConfirmationDialog, isOpen: false })}
        onConfirm={handleConfirm}
        confirmText={t('yes')}
        cancelText={t('cancel')}
      />
      {canWriteVaultTokenBooster ? (
        <VaultTokenBoosterDialog
          onClose={() => {
            setIsOpen(false);
          }}
          open={isOpen}
        />
      ) : null}
    </>
  ) : null;
};

export default VaultTokenBoosterPage;
