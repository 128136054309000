import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const LI = styled('li')(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  '&:not(:last-of-type)': {
    borderBottom: '1px solid #ddd',
  },
}));

const CheckList = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography sx={{ mt: 1, mb: 1, fontWeight: 500, pl: 1, fontSize: 16 }}>{t('checklist')}</Typography>
      <Box component="ul" sx={{ listStyle: 'none', paddingInlineStart: 0 }}>
        <LI>{`1. ${t('check')} ${t('firstName')}`}</LI>
        <LI>{`2. ${t('check')} ${t('lastName')}`}</LI>
        <LI>{`3. ${t('check')} ${t('birthdate')}`}</LI>
        <LI>{`4. ${t('check')} ${t('identificationExpiryDate')}`}</LI>
        <LI>{`5. ${t('check')} ${t('otherFields')}`}</LI>
        <LI>{`7. ${t('click')} ${t('save')}`}</LI>
        <LI>{`6. ${t('click')} ${t('verifyOrDecline')}`}</LI>
      </Box>
    </>
  );
};

export default CheckList;
