/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */

/**
 * https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 */
export type TimeZoneId = (typeof TimeZoneId)[keyof typeof TimeZoneId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimeZoneId = {
  UTC: 'UTC',
  'Europe/Brussels': 'Europe/Brussels',
} as const;
