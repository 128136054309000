import '@greenisland-common/helpers/prettyPrintJson/prettyPrintCss.css';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ErrorCard } from '@greenisland-common/components/atoms';

import EventsStreamByEventName from './EventsStreamByEventName';

const EventsStream = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const eventName = searchParams.get('eventname');

  if (eventName) {
    return <EventsStreamByEventName eventName={eventName} />;
  }

  return <ErrorCard severity="info">{t('common.common.missingQueryParameters')}</ErrorCard>;
};

export default EventsStream;
