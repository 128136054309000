import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, capitalize, Typography } from '@mui/material';

interface Props {
  enabled?: boolean;
  title: string;
  icon: IconDefinition;
  iconColor: string;
}

const AgentIconInfo = ({ enabled, title, icon, iconColor }: Props) => {
  if (!enabled) return null;

  return (
    <Box display="flex" mb={2}>
      <Box mr={1}>
        <FontAwesomeIcon icon={icon} color={iconColor} />
      </Box>
      <Typography fontWeight={500} fontSize="medium" variant="body2">
        {capitalize(title)}
      </Typography>
    </Box>
  );
};

export default AgentIconInfo;
