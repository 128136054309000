/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  DefaultResponse,
  GameLobbyArgumentsSchema,
  GameLobbySchema,
  GamesControllerBadRequestResponse,
  GameStudioLobbiesSchema,
  NotFoundResponse,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Lists the game lobbies with their linked studios
 */
export const useGetGameLobbiesHook = () => {
  const getGameLobbies = useCustomInstance<GameStudioLobbiesSchema>();

  return (signal?: AbortSignal) => {
    return getGameLobbies({ url: `/gamelobbies`, method: 'get', signal });
  };
};

export const getGetGameLobbiesQueryKey = () => [`/gamelobbies`] as const;

export const useGetGameLobbiesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameLobbiesHook>>>,
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameLobbiesHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameLobbiesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameLobbiesQueryKey();

  const getGameLobbies = useGetGameLobbiesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameLobbiesHook>>>> = ({ signal }) =>
    getGameLobbies(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetGameLobbiesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetGameLobbiesHook>>>>;
export type GetGameLobbiesQueryError =
  | GamesControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Lists the game lobbies with their linked studios
 */
export const useGetGameLobbies = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameLobbiesHook>>>,
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameLobbiesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameLobbiesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Adds a new Game Lobby
 */
export const useAddGameLobbyHook = () => {
  const addGameLobby = useCustomInstance<GameLobbySchema>();

  return (gameLobbyArgumentsSchema: GameLobbyArgumentsSchema) => {
    return addGameLobby({
      url: `/gamelobbies`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: gameLobbyArgumentsSchema,
    });
  };
};

export const useAddGameLobbyMutationOptions = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameLobbyHook>>>,
    TError,
    { data: GameLobbyArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddGameLobbyHook>>>,
  TError,
  { data: GameLobbyArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addGameLobby = useAddGameLobbyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddGameLobbyHook>>>,
    { data: GameLobbyArgumentsSchema }
  > = props => {
    const { data } = props ?? {};

    return addGameLobby(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddGameLobbyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddGameLobbyHook>>>>;
export type AddGameLobbyMutationBody = GameLobbyArgumentsSchema;
export type AddGameLobbyMutationError =
  | GamesControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Adds a new Game Lobby
 */
export const useAddGameLobby = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameLobbyHook>>>,
    TError,
    { data: GameLobbyArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddGameLobbyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets the details on a game lobby
 */
export const useGetGameLobbyHook = () => {
  const getGameLobby = useCustomInstance<GameLobbySchema>();

  return (gameLobbyId: number, signal?: AbortSignal) => {
    return getGameLobby({ url: `/gamelobbies/${gameLobbyId}`, method: 'get', signal });
  };
};

export const getGetGameLobbyQueryKey = (gameLobbyId: number) => [`/gamelobbies/${gameLobbyId}`] as const;

export const useGetGameLobbyQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameLobbyHook>>>,
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  gameLobbyId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameLobbyHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameLobbyHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameLobbyQueryKey(gameLobbyId);

  const getGameLobby = useGetGameLobbyHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameLobbyHook>>>> = ({ signal }) =>
    getGameLobby(gameLobbyId, signal);

  return { queryKey, queryFn, enabled: !!gameLobbyId, ...queryOptions };
};

export type GetGameLobbyQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetGameLobbyHook>>>>;
export type GetGameLobbyQueryError =
  | GamesControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Gets the details on a game lobby
 */
export const useGetGameLobby = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameLobbyHook>>>,
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  gameLobbyId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameLobbyHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameLobbyQueryOptions(gameLobbyId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Updates a Game Lobby
 */
export const useUpdateGameLobbyHook = () => {
  const updateGameLobby = useCustomInstance<GameLobbySchema>();

  return (gameLobbyId: number, gameLobbyArgumentsSchema: GameLobbyArgumentsSchema) => {
    return updateGameLobby({
      url: `/gamelobbies/${gameLobbyId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: gameLobbyArgumentsSchema,
    });
  };
};

export const useUpdateGameLobbyMutationOptions = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameLobbyHook>>>,
    TError,
    { gameLobbyId: number; data: GameLobbyArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateGameLobbyHook>>>,
  TError,
  { gameLobbyId: number; data: GameLobbyArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateGameLobby = useUpdateGameLobbyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameLobbyHook>>>,
    { gameLobbyId: number; data: GameLobbyArgumentsSchema }
  > = props => {
    const { gameLobbyId, data } = props ?? {};

    return updateGameLobby(gameLobbyId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGameLobbyMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateGameLobbyHook>>>>;
export type UpdateGameLobbyMutationBody = GameLobbyArgumentsSchema;
export type UpdateGameLobbyMutationError =
  | GamesControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Updates a Game Lobby
 */
export const useUpdateGameLobby = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameLobbyHook>>>,
    TError,
    { gameLobbyId: number; data: GameLobbyArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateGameLobbyMutationOptions(options);

  return useMutation(mutationOptions);
};
