import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUserGeneral, useGetUserVerification } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, DataGridPlain, ErrorCard, Link, NoData } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../../app/hooks';
import BankAccountList from './BankAccountList';
import VerificationStatusField from './VerificationStatusField';
import WalletList from './WalletList';

interface Props {
  userId: string;
}

const UserVerification = ({ userId }: Props) => {
  const { t } = useTranslation();
  const canReadUserVerification = usePermission(OnlineCasinoPermissions.getUserVerification);
  const canReadUserVerifications = usePermission(OnlineCasinoPermissions.getUserVerifications);
  const canReadUserGeneral = usePermission(OnlineCasinoPermissions.getUserGeneral);

  const {
    data: user,
    isLoading: userGeneralIsLoading,
    isError: userGeneralIsError,
    error: userGeneralError,
  } = useGetUserGeneral(userId, { query: { enabled: canReadUserGeneral } });

  const {
    data: userVerification,
    isLoading: userVerificationIsLoading,
    isError: userVerificationIsError,
    error: userVerificationError,
  } = useGetUserVerification(userId, { query: { enabled: canReadUserVerification } });

  const isLoading = userVerificationIsLoading || userGeneralIsLoading;
  const isError = userVerificationIsError || userGeneralIsError;
  const error = userVerificationError || userGeneralError;

  if (!canReadUserVerification) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={323} />;
  if (isError || !userVerification || !user) return <ErrorCard sx={{ height: 323 }}>{error?.message}</ErrorCard>;

  const { identityVerificationStatus, domicileVerificationStatus, paymentMethods } = userVerification;

  const headers: GridColumns = [
    {
      headerName: '',
      field: 'title',
      renderCell: ({ value }) => (
        <Box color="array.headers" component="span">
          {capitalize(t(value))}
        </Box>
      ),
      width: 140,
    },
    { headerName: '', field: 'value', renderCell: ({ value }) => value, flex: 1 },
  ];

  const rows: GridRowsProp = [
    { id: 2, title: t('identity'), value: <VerificationStatusField status={identityVerificationStatus} /> },
    { id: 3, title: t('domicile'), value: <VerificationStatusField status={domicileVerificationStatus} /> },
    { id: 5, title: t('bankaccount'), value: <BankAccountList accounts={paymentMethods.bankAccounts} /> },
    { id: 6, title: t('wallet'), value: <WalletList wallets={userVerification.paymentMethods.wallets} /> },
  ];

  return (
    <Card sx={{ pl: 0, pr: 0 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CardHeader title={t('userVerification')} />
        {canReadUserVerifications && (
          <Button sx={{ mr: 1 }} size="small" variant="outlined" component={Link} to={`../verifications`}>
            {t('seeMore')}
          </Button>
        )}
      </Box>
      <CardContent sx={{ pl: 0, pr: 0 }}>
        {rows.length > 0 ? (
          <DataGridContainer>
            <DataGridPlain columns={headers} rows={rows} />
          </DataGridContainer>
        ) : (
          <NoData />
        )}
      </CardContent>
    </Card>
  );
};

export default UserVerification;
