/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EWithdrawalStatusSchema = (typeof EWithdrawalStatusSchema)[keyof typeof EWithdrawalStatusSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EWithdrawalStatusSchema = {
  DeclineRequested: 'DeclineRequested',
  Declined: 'Declined',
  ProcessingFailed: 'ProcessingFailed',
  New: 'New',
  Pended: 'Pended',
  Approved: 'Approved',
  Processed: 'Processed',
  CompletedManually: 'CompletedManually',
  Cancelled: 'Cancelled',
  CancelRequested: 'CancelRequested',
  BalanceLimitRequested: 'BalanceLimitRequested',
  Unknown: 'Unknown',
} as const;
