import { useTranslation } from 'react-i18next';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';

const LANGUAGES = [
  { value: 'en', label: 'English' },
  { value: 'nl-BE', label: 'Nederlands' },
  { value: 'fr-BE', label: 'Francais' },
];

const LanguageSelector = () => {
  const { i18n, t } = useTranslation();

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    i18n.changeLanguage(value);
    localStorage.setItem('language', value);
  };

  return (
    <Box display="flex" gap={1} alignItems="center">
      <FontAwesomeIcon icon={faGlobe} size="lg" />
      <Select
        fullWidth
        size="small"
        value={i18n.language}
        onChange={handleChange}
        displayEmpty
        renderValue={value => value || t('chooseLanguage')}
      >
        {LANGUAGES.map(lang => (
          <MenuItem key={lang.value} value={lang.value}>
            {lang.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default LanguageSelector;
