import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { GameStudioSchema, GameStudiosSchema } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';
import TransferList from '@greenisland-common/components/organisms/TransferList';

import { usePermission } from '../../../../../app/hooks';
import GameStudioDialog from './GameStudioDialog';

interface Props {
  gameStudios: GameStudiosSchema;
  selectedGameStudios: GameStudiosSchema;
  setSelectedGameStudios: (studios: GameStudiosSchema) => void;
}

const DEFAULT_GAME_STUDIO = {
  isLive: true,
  gameStudioId: '',
  gameStudioName: '',
  logoUrl: '',
  urlName: '',
  imageUrl: '',
  enabled: false,
};

const GameStudiosComp = ({ gameStudios, selectedGameStudios, setSelectedGameStudios }: Props) => {
  const { t } = useTranslation();
  const canAddGameStudio = usePermission(OnlineCasinoPermissions.addGameStudio);

  const [gameStudioDialog, setGameStudioDialog] = useState<{ open: boolean; gameStudio: GameStudioSchema }>({
    open: false,
    gameStudio: DEFAULT_GAME_STUDIO,
  });

  const methods = useForm<{ studios: { name: string; id: string }[] }>({
    shouldFocusError: true,
    mode: 'onChange',
    defaultValues: {
      studios: selectedGameStudios.map(item => ({
        name: item.gameStudioName,
        id: item.gameStudioId,
      })),
    },
  });

  const { control, watch } = methods;

  const watchStudiosData = watch('studios');

  useEffect(() => {
    setSelectedGameStudios(
      gameStudios.filter(studio => watchStudiosData.some(item => item.id === studio.gameStudioId))
    );
  }, [gameStudios, setSelectedGameStudios, watchStudiosData]);

  const openCloseDialog = (state: boolean) => {
    setGameStudioDialog({ ...gameStudioDialog, open: state });
  };

  const handleAddGameStudio = () => {
    setGameStudioDialog({
      open: true,
      gameStudio: DEFAULT_GAME_STUDIO,
    });
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        {canAddGameStudio ? (
          <Button variant="text" onClick={handleAddGameStudio} startIcon={<FontAwesomeIcon icon={faPlus} />}>
            {t('addGameStudio')}
          </Button>
        ) : null}
      </div>
      <FormProvider {...methods}>
        <form>
          <Controller
            control={control}
            name="studios"
            render={({ name }) => {
              return (
                <TransferList
                  control={control}
                  name={name}
                  leftTitle={'available game studios'}
                  rightTitle={'selected game studios'}
                  fallbackText={'no game studios available'}
                  allItems={gameStudios.map(item => ({
                    name: item.gameStudioName,
                    id: item.gameStudioId,
                  }))}
                />
              );
            }}
          />
        </form>
      </FormProvider>

      {gameStudioDialog.open && (
        <GameStudioDialog
          open={gameStudioDialog.open}
          setOpen={openCloseDialog}
          gameStudio={gameStudioDialog.gameStudio}
        />
      )}
    </>
  );
};

export default GameStudiosComp;
