import { ComponentPropsWithoutRef, MouseEvent, useCallback, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { faAdd, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EMediaGalleryEntryTypeSchema, ImageSchema, MediaGalleryEntrySchema } from '@greenisland-api';
import { Box, Button, TextField } from '@mui/material';

import MediaGallery from '../../../../app/components/MediaGallery';

type Props = ComponentPropsWithoutRef<typeof TextField> & {
  name: string;
  label?: string;
  helpText?: string;
  control: Control;
  mediaGalleryEntryType: EMediaGalleryEntryTypeSchema;
  isMultiSelect?: boolean;
  defaultValue?: ImageSchema[] | null;
};

export const MediaFieldsWithPreview = ({
  name,
  control,
  mediaGalleryEntryType,
  isMultiSelect,
  label,
  defaultValue,
}: Props) => {
  const {
    field: { value, onChange },
  } = useController({ name, control, defaultValue });

  const [showMediaGallery, setShowMediaGallery] = useState(false);
  const [resetMedia, setResetMedia] = useState(false);

  const addMediaHandler = useCallback(
    (media: MediaGalleryEntrySchema[]) => {
      onChange(media);
      setShowMediaGallery(false);
    },
    [onChange]
  );

  const openGalleryHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowMediaGallery(true);
  };

  const deleteMediaHandler = (mediaId: number) => {
    const filteredMedia = value?.filter((media: MediaGalleryEntrySchema) => media?.id !== mediaId);
    onChange(filteredMedia);
    setResetMedia(true);
  };

  return (
    <>
      <Box>
        {!(!isMultiSelect && value?.length) && (
          <Button
            onClick={openGalleryHandler}
            variant="contained"
            size="medium"
            startIcon={<FontAwesomeIcon icon={faAdd} />}
          >
            {label}
          </Button>
        )}
        <Box display="flex" alignItems="center" flexWrap="wrap">
          {value?.length
            ? value?.map((image: MediaGalleryEntrySchema) => (
                <div key={image?.id}>
                  <Box
                    sx={{
                      width: 100,
                      height: 100,
                      position: 'relative',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                      mx: 1,
                      my: 2,
                    }}
                  >
                    <FontAwesomeIcon
                      onClick={() => deleteMediaHandler(image?.id)}
                      style={{
                        marginRight: '10px',
                        cursor: 'pointer',
                        position: 'absolute',
                        top: -10,
                        right: -20,
                        color: 'red',
                        zIndex: 1,
                      }}
                      icon={faCircleXmark}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        position: 'absolute',
                        opacity: 0.9,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: '#9ca3af1a',
                        backgroundImage:
                          'linear-gradient(135deg,#6b728080 10%,transparent 0,transparent 50%,#6b728080 0,#6b728080 60%,transparent 0,transparent)',
                        backgroundSize: '7.07px 7.07px',
                      }}
                    >
                      <Box
                        component="img"
                        src={image?.path}
                        alt={image?.fileName}
                        loading="lazy"
                        title={image?.fileName}
                        sx={{ objectFit: 'contain', position: 'relative', width: '100%' }}
                      />
                    </Box>
                  </Box>
                </div>
              ))
            : null}
        </Box>
      </Box>
      <MediaGallery
        isOpen={showMediaGallery}
        onSave={addMediaHandler}
        onClose={() => setShowMediaGallery(false)}
        defaultMedia={value}
        mediaGalleryEntryType={mediaGalleryEntryType}
        isMultiSelect={isMultiSelect}
        resetMedia={resetMedia}
        setResetMedia={setResetMedia}
      />
    </>
  );
};
