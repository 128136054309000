import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useGetUserVaultTransactions } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { endOfDay, getUnixTime, sub } from 'date-fns';

import { StyledDataGrid } from '@greenisland-common/components/atoms';
import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import {
  transformDecimalV2,
  transformDefaultV2,
  transformUnixDateV2,
} from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';

const VaultTransactions = () => {
  const canReadVaultTransactions = usePermission(OnlineCasinoPermissions.getUserVaultTransactions);
  return canReadVaultTransactions ? <VaultTransactionsChild /> : null;
};

const currentDate = new Date();

const VaultTransactionsChild = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startdate');
  const endDate = searchParams.get('enddate');
  const start = startDate ? Number(startDate) : getUnixTime(sub(currentDate, { days: 1 }));
  const end = endDate ? Number(endDate) : getUnixTime(endOfDay(currentDate));
  const canReadUserVaultTransactions = usePermission(OnlineCasinoPermissions.getUserVaultTransactions);
  const { data: vaultTransactions, isLoading } = useGetUserVaultTransactions(
    userId as string,
    {
      start: start,
      end: end,
    },
    {
      query: { enabled: canReadUserVaultTransactions && !!userId },
    }
  );

  const columns: GridColDef[] = [
    {
      ...transformUnixDateV2('timestamp', t, true),
      flex: 1,
    },
    {
      ...transformDecimalV2('amount', t, false),
      flex: 1,
    },
    ...Object.keys(vaultTransactions?.[0] || {})
      .filter(key => !['timestamp', 'amount'].includes(key))
      .map(key => ({
        ...transformDefaultV2(key, t, true),
        flex: 1,
      })),
  ];

  return (
    <Stack spacing={2}>
      <DateRangePicker
        unixStartDate={start * 1000}
        unixEndDate={end * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
        }}
      />
      <StyledDataGrid
        columns={columns}
        rows={vaultTransactions ?? []}
        loading={isLoading}
        autoHeight
        getRowId={row => row.id || Math.random().toString()}
        pagination
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Stack>
  );
};

export default VaultTransactions;
