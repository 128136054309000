import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, Outlet, useLocation } from 'react-router';
import { actionCreators, IEntityState } from '@greenisland/stores';
import { useAppDispatch } from '@greenisland-core/store';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Fab, Typography, useScrollTrigger, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';

import { ErrorBoundary } from '@greenisland-common/components/atoms';

import { BaseRoute, BaseRouting } from '../RouteInterfaces';
import SideBar from './SideBar';
import TopAppBar from './TopAppBar';

interface Props extends BaseRouting {
  entitySelected: IEntityState;
  canAccessRoutes: Array<BaseRoute>;
}

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

function ScrollTop() {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Zoom in={trigger}>
      <StyledFab onClick={handleClick} color="secondary" size="small" aria-label="scroll back to top">
        <KeyboardArrowUpIcon />
      </StyledFab>
    </Zoom>
  );
}

const BackofficeLayout = ({ routes, entitySelected, canAccessRoutes, identifier }: Props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();

  const title = useMemo(() => {
    const findRouteName = (routes: Array<BaseRoute>, path: string): string => {
      for (const route of routes) {
        const match = matchPath(`${path}/${route.path}`, location.pathname);
        if (match) return route.name;
        if (route.children) {
          const routeName = findRouteName(route?.children ?? [], `${path}/${route?.path}`);
          if (routeName) return routeName;
        }
      }
      return '';
    };

    return findRouteName(routes, `${entitySelected.selectedEntity}/${entitySelected.selectedProduct}`);
  }, [routes, entitySelected, location]);

  const handleLogout = () => {
    // We clear these items because of an annoying bug (not 100% sure but it works so yeah)
    // https://github.com/aws-amplify/amplify-flutter/issues/401#issuecomment-1224270594
    localStorage.removeItem('amplify-signin-with-hostedUI');
    localStorage.removeItem('amplify-redirected-from-hosted-ui');

    Auth.signOut()
      .then(() => {
        dispatch(actionCreators.logout());
      })
      .catch(err => {
        enqueueSnackbar(err || t('somethingWentWrong'), { variant: 'error' });
        dispatch(actionCreators.logout());
      });
  };

  return (
    <Box display="flex">
      <SideBar
        entitySelected={entitySelected}
        routes={canAccessRoutes}
        handleDrawerToggle={() => setDrawerOpen(open => !open)}
        drawerOpen={drawerOpen}
        handleLogout={handleLogout}
        identifier={identifier}
      />
      <Box flexGrow={1}>
        <TopAppBar
          handleDrawerToggle={() => setDrawerOpen(open => !open)}
          drawerOpen={drawerOpen}
          handleLogout={handleLogout}
        />
        <Box py={10} px={2}>
          {title ? (
            <Typography variant="h4" sx={{ mb: 2 }}>
              {title}
            </Typography>
          ) : null}
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Box>
      </Box>
      <ScrollTop />
    </Box>
  );
};

export default BackofficeLayout;
