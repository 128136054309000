import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faAdd, faPencil } from '@fortawesome/free-solid-svg-icons';
import { GameLobbySchema, useGetGameLobbies } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Stack } from '@mui/material';
import type { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-pro';

import { CheckboxIcon, DataGridContainer, FontAwesomeIcon, StyledDataGrid } from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { usePermission } from '../../../../../app/hooks';
import ImageCell from '../Components/ImageCell';

type Row = GameLobbySchema;

const Lobbies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const canReadLobbies = usePermission(OnlineCasinoPermissions.getGameLobbies);
  const canAddLobbies = usePermission(OnlineCasinoPermissions.addGameLobby);
  const canUpdateLobby = usePermission(OnlineCasinoPermissions.updateGameLobby);

  const { data: gameLobbyStudios, isLoading, isError, refetch } = useGetGameLobbies();

  const gameLobbies = useMemo<GameLobbySchema[]>(
    () =>
      gameLobbyStudios?.studios
        ?.map(gameLobbyStudio => gameLobbyStudio.lobbies)
        .flat()
        .filter((gameLobbySchema): gameLobbySchema is GameLobbySchema => gameLobbySchema !== undefined)
        .map<Row>(gameLobby => ({ ...gameLobby })) ?? [],
    [gameLobbyStudios]
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'name',
        headerName: t('content.games.gameLobbies.name'),
        flex: 1,
        sortable: false,
        minWidth: 150,
      },
      {
        field: 'urlName',
        headerName: t('content.games.gameLobbies.urlName'),
        flex: 1,
        sortable: false,
        minWidth: 150,
      },
      {
        field: 'gameProviderName',
        headerName: t('content.games.gameLobbies.providerName'),
        flex: 1,
        sortable: false,
        minWidth: 120,
      },
      {
        field: 'backgroundImageUrl',
        headerName: t('content.games.gameLobbies.backgroundImage'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Row>) => <ImageCell value={params.row.backgroundImageUrl} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'thumbnailSquareUrl',
        headerName: t('content.games.gameLobbies.thumbnailSquare'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Row>) => <ImageCell value={params.row.thumbnailSquareUrl} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'thumbnailLandscapeUrl',
        headerName: t('content.games.gameLobbies.thumbnailLandscape'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Row>) => <ImageCell value={params.row.thumbnailLandscapeUrl} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'thumbnailPortraitUrl',
        headerName: t('content.games.gameLobbies.thumbnailPortrait'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Row>) => <ImageCell value={params.row.thumbnailPortraitUrl} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'enabled',
        headerName: t('content.games.gameLobbies.enabled'),
        type: 'boolean',
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        sortable: false,
        minWidth: 100,
      },
      ...(canUpdateLobby
        ? [
            {
              field: 'actions',
              type: 'actions',
              getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faPencil} />}
                  label="Edit"
                  onClick={() => navigate(`./${params.row.id}`)}
                  key="edit"
                />,
              ],
              flex: 0.5,
            },
          ]
        : []),
    ],
    [canUpdateLobby, t, navigate]
  );

  return canReadLobbies ? (
    <>
      <Stack spacing={2}>
        {canAddLobbies ? (
          <div>
            <Button variant="text" startIcon={<FontAwesomeIcon icon={faAdd} />} onClick={() => navigate('./add')}>
              {t('content.games.gameLobbies.add')}
            </Button>
          </div>
        ) : null}
        {!isError ? (
          <DataGridContainer>
            <StyledDataGrid
              density="compact"
              autoHeight
              loading={isLoading}
              rows={gameLobbies}
              columns={columns}
              pagination={false}
            />
          </DataGridContainer>
        ) : (
          <ErrorState errorMessage={t('content.games.gameLobbies.loadingError')} retryAction={() => refetch()} />
        )}
      </Stack>
    </>
  ) : null;
};

export default Lobbies;
