import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {
  EContentTypeSchema,
  ErrorSchema,
  getGetContentFiltersQueryKey,
  useCreateContentFilter,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon, SelectInput } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';
import LanguageTranslationSelect from '@greenisland-common/components/organisms/LanguageTranslationSelect';

import { useTenantContext } from '../../../../../../../app/context/TenantContext';
import {
  defaultContentFilterFormValues,
  ExtendedUpdateContentFilterSchema,
  useIsLiveFilterOptions,
} from '../helpers/ContentFilterFormContext';
import ContentFilterRTPFilterField from './ContentFilterRTPFilterField';
import ContentIncludedExcludedFilterField from './ContentIncludedExcludedFilterField';

interface Props {
  open: boolean;
  onClose: () => void;
}

const CreateContentFilterForm = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const tenant = useTenantContext();
  const isLiveFilterOptions = useIsLiveFilterOptions();

  const [isRtpFilterOn, setIsRtpFilterOn] = useState(false);

  const [includedFilters, setIncludedFilters] = useState({
    gamePKeys: true,
    gameProviderPKeys: true,
    gameStudioPKeys: true,
    gameCategoryPKeys: true,
    gameThemePKeys: true,
  });

  const [excludedFilters, setExcludedFilters] = useState({
    gamePKeys: false,
    gameProviderPKeys: false,
    gameStudioPKeys: false,
    gameCategoryPKeys: false,
    gameThemePKeys: false,
  });

  const defaultContentType = useMemo(() => {
    if (tenant?.isGamesEnabled && tenant?.isSportsEnabled) return EContentTypeSchema.All;
    return tenant?.isGamesEnabled ? EContentTypeSchema.Games : EContentTypeSchema.Sports;
  }, [tenant]);

  const methods = useForm<ExtendedUpdateContentFilterSchema>({
    defaultValues: {
      ...defaultContentFilterFormValues,
      contentType: defaultContentType,
    },
    shouldFocusError: true,
    mode: 'onChange',
  });

  const { control, handleSubmit, reset, formState, watch } = methods;

  const isSportFilterTypeChosen = watch('contentType') === EContentTypeSchema.Sports;

  const { mutate: createContentFilter, isLoading } = useCreateContentFilter({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetContentFiltersQueryKey());
        onCloseHandler();
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: (error: ErrorSchema) => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  const resetFilters = () => {
    setIsRtpFilterOn(false);
    setIncludedFilters({
      gamePKeys: true,
      gameProviderPKeys: true,
      gameStudioPKeys: true,
      gameCategoryPKeys: true,
      gameThemePKeys: true,
    });
    setExcludedFilters({
      gamePKeys: false,
      gameProviderPKeys: false,
      gameStudioPKeys: false,
      gameCategoryPKeys: false,
      gameThemePKeys: false,
    });
  };

  const resetFormValues = () => {
    reset({
      ...defaultContentFilterFormValues,
    });
  };

  const onCloseHandler = () => {
    onClose();
    resetFilters();
    resetFormValues();
  };

  const onSubmit = (data: ExtendedUpdateContentFilterSchema) => {
    const isLiveFilterValue = isLiveFilterOptions.find(option => option.value === data.isLiveFilterLabel)?.status;

    const formData = {
      ...data,
      rtpFilter: !isSportFilterTypeChosen ? data.rtpFilter : undefined,
      isLiveFilter: !isSportFilterTypeChosen ? isLiveFilterValue : null,
      includedFilter: !isSportFilterTypeChosen ? data.includedFilter : defaultContentFilterFormValues.includedFilter,
      excludedFilter: !isSportFilterTypeChosen ? data.excludedFilter : defaultContentFilterFormValues.excludedFilter,
    };

    createContentFilter({ data: formData });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>{t('content.contentFilter.addContentFilter')}</DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Input
                size="small"
                label={t('content.contentFilter.tableHeaders.name')}
                control={control}
                required
                name="name"
                fullWidth
                rules={{ required: t('fieldIsRequired') }}
                sx={{ mb: 2 }}
              />
              <LanguageTranslationSelect name="description" mainTitle="description" />
              {tenant?.isGamesEnabled && tenant?.isSportsEnabled && (
                <>
                  <InputLabel sx={{ mb: 2 }}>{t('content.contentFilter.tableHeaders.contentType.title')}</InputLabel>
                  <SelectInput
                    size="small"
                    name="contentType"
                    label=""
                    rules={{
                      required: t('fieldIsRequired'),
                    }}
                    options={Object.keys(EContentTypeSchema).map(key => ({ value: key, label: t(key) }))}
                    sx={{
                      [theme.breakpoints.up('xs')]: {
                        width: '100%',
                      },
                      [theme.breakpoints.up('lg')]: {
                        width: '30%',
                      },
                    }}
                  />
                </>
              )}
              {!isSportFilterTypeChosen ? (
                <>
                  <InputLabel sx={{ mb: 2 }}>{t('content.contentFilter.tableHeaders.isLiveFilter.title')}</InputLabel>
                  <SelectInput
                    size="small"
                    name="isLiveFilterLabel"
                    label=""
                    rules={{
                      required: t('fieldIsRequired'),
                    }}
                    options={isLiveFilterOptions}
                    defaultValue={isLiveFilterOptions[0].value}
                    sx={{
                      [theme.breakpoints.up('xs')]: {
                        width: '100%',
                      },
                      [theme.breakpoints.up('lg')]: {
                        width: '30%',
                      },
                    }}
                  />
                  <ContentFilterRTPFilterField isRtpFilterOn={isRtpFilterOn} setIsRtpFilterOn={setIsRtpFilterOn} />
                  <Box
                    display="flex"
                    gap={4}
                    sx={{
                      [theme.breakpoints.up('xs')]: {
                        flexDirection: 'column',
                      },
                      [theme.breakpoints.up('lg')]: {
                        flexDirection: 'row',
                      },
                    }}
                  >
                    <>
                      <ContentIncludedExcludedFilterField
                        type="included"
                        filters={includedFilters}
                        setFilters={setIncludedFilters}
                      />
                      <ContentIncludedExcludedFilterField
                        type="excluded"
                        filters={excludedFilters}
                        setFilters={setExcludedFilters}
                      />
                    </>
                  </Box>
                </>
              ) : null}
              <DialogActions>
                <Button variant="text" onClick={onCloseHandler} color="secondary">
                  {t('cancel')}
                </Button>
                <LoadingButton
                  color="primary"
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                  disabled={!formState.isDirty}
                >
                  {t('save')}
                </LoadingButton>
              </DialogActions>
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default CreateContentFilterForm;
