import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { TournamentConfiguration } from '@greenisland-store/gameServer';
import { Box, Card, CardContent, CardHeader, Link, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { parseExpression } from 'cron-parser';
import { isValidCron } from 'cron-validator';
import cronstrue from 'cronstrue';
import { isBefore } from 'date-fns';

import { FontAwesomeIcon, FormError } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';
import SelectDropdownButton from '@greenisland-common/components/organisms/SelectDropdownButton';

import { parseToISO8601DurationHandler } from '../helpers/tournamentConfigFormHelpers';
import { useGetTournamentDurationValues } from '../helpers/tournamentConfigFormHooks';
import { TournamentConfigurationMode } from '../helpers/tournamentConfigFormInterfaces';

const CRON_GENERATOR_LINK = 'https://www.freeformatter.com/cron-expression-generator-quartz.html';
const CRON_VALIDATOR_LINK = 'https://crontab.cronhub.io/';

interface Props {
  mode: TournamentConfigurationMode;
  tournamentConfiguration?: TournamentConfiguration;
}

const TournamentConfigScheduleSettings = ({ mode = TournamentConfigurationMode.ADD }: Props) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const durationOptions = useGetTournamentDurationValues();

  const canModify = mode === TournamentConfigurationMode.ADD || mode === TournamentConfigurationMode.COPY;

  const { control, errors, watch, setValue } = useFormContext();

  const validityFromValue = watch('validity.from');
  const validityToValue = watch('validity.to');
  const tournamentSchedule = watch('tournamentSchedule');
  const tournamentDurationUnit = watch('tournamentDurationUnit');

  const isCronLengthValid = tournamentSchedule?.cronExpression?.trim()?.split(/\s+/).length === 6;

  const [chosenTournamentDurationValue, setChosenTournamentDurationValue] = useState(durationOptions[3]);
  const [showCronExplainer, setShowCronExplainer] = useState(false);

  const parsedCronValue = useMemo(() => {
    const isCronExpressionAvailable =
      tournamentSchedule?.cronExpression && tournamentSchedule?.scheduleTimeZoneId && validityFromValue;

    if (!isCronExpressionAvailable || !isCronLengthValid) {
      return '';
    }

    return cronstrue.toString(tournamentSchedule?.cronExpression, {
      use24HourTimeFormat: true,
      verbose: true,
      locale: i18n.language,
    });
  }, [
    i18n.language,
    isCronLengthValid,
    tournamentSchedule?.cronExpression,
    tournamentSchedule?.scheduleTimeZoneId,
    validityFromValue,
  ]);

  const parsedNextJobCronValue = useMemo(() => {
    try {
      const interval = parseExpression(tournamentSchedule?.cronExpression, {
        iterator: true,
        currentDate:
          mode === TournamentConfigurationMode.ADD &&
          validityFromValue &&
          isBefore(new Date(validityFromValue * 1000), new Date())
            ? new Date()
            : new Date(validityFromValue * 1000),
        startDate: validityFromValue ? new Date(validityFromValue * 1000) : undefined,
        endDate: validityToValue ? new Date(validityToValue * 1000) : undefined,
        tz: tournamentSchedule?.scheduleTimeZoneId,
      });

      const nextDateResult = interval.next();

      return (
        <Typography variant="body2" mt={1} mb={2} color="primary">
          {`${t(
            'content.gameserver.tournamentconfiguration.form.schedulesettings.nextcrontitle'
          )} ${nextDateResult.value.toDate().toString().split(' ').splice(0, 5).join(' ')}`}
        </Typography>
      );
    } catch (e) {
      return (
        <Typography variant="body2" mt={1} mb={2} color="error">
          {t('content.gameserver.tournamentconfiguration.form.schedulesettings.invalidcrontitle')}
        </Typography>
      );
    }
  }, [
    mode,
    t,
    tournamentSchedule?.cronExpression,
    tournamentSchedule?.scheduleTimeZoneId,
    validityFromValue,
    validityToValue,
  ]);

  useEffect(() => {
    if (validityFromValue && tournamentSchedule?.cronExpression) {
      setShowCronExplainer(true);
    }
  }, [setValue, tournamentSchedule?.cronExpression, validityFromValue]);

  useEffect(() => {
    if (tournamentDurationUnit) {
      const foundDurationUnitOption = durationOptions?.find(option => option.value === tournamentDurationUnit);

      if (foundDurationUnitOption?.value) {
        return setChosenTournamentDurationValue(foundDurationUnitOption);
      }
    }
  }, [durationOptions, tournamentDurationUnit]);

  return (
    <Stack spacing={2} position="relative">
      <Card>
        <CardHeader title={t('content.gameserver.tournamentconfiguration.form.schedulesettings.title')} />
        <Link href={canModify ? CRON_GENERATOR_LINK : CRON_VALIDATOR_LINK} target="_blank">
          <Typography
            variant="body2"
            fontSize="small"
            sx={{
              [theme.breakpoints.up('xs')]: {
                position: 'initial',
                ml: 2,
                my: 1,
              },
              [theme.breakpoints.up('md')]: {
                position: 'absolute',
                right: 16,
                top: 8,
              },
            }}
          >
            <FontAwesomeIcon icon={faCircleInfo} sx={{ color: 'primary.main', mr: 1 }} />
            {canModify
              ? t('content.gameserver.tournamentconfiguration.form.schedulesettings.crongeneratortitle')
              : t('content.gameserver.tournamentconfiguration.form.schedulesettings.cronvalidatortitle')}
          </Typography>
        </Link>
        <CardContent>
          <Box display="flex" flexDirection="column">
            <Input
              sx={{ display: 'none' }}
              type="hidden"
              control={control}
              name="tournamentSchedule.scheduleTimeZoneId"
              defaultValue="Europe/Brussels"
            />
            <Input
              size="small"
              label={t('content.gameserver.tournamentconfiguration.form.schedulesettings.cronexpressiontitle')}
              disabled={mode === TournamentConfigurationMode.EDIT || !validityFromValue}
              control={control}
              type="text"
              name="tournamentSchedule.cronExpression"
              required
              rules={{
                required: t('fieldIsRequired'),
                validate: value => {
                  if (value.trim().split(/\s+/).length !== 6) {
                    setShowCronExplainer(false);
                    return t(
                      'content.gameserver.tournamentconfiguration.form.schedulesettings.invalidcronexpressionlength'
                    );
                  } else if (!isValidCron(value, { seconds: true, alias: true, allowBlankDay: true })) {
                    setShowCronExplainer(false);
                    return t('content.gameserver.tournamentconfiguration.form.schedulesettings.invalidcronexpression');
                  } else {
                    setShowCronExplainer(true);
                  }
                },
              }}
              placeholder="* * * * * *"
              sx={{
                [theme.breakpoints.up('xs')]: {
                  width: '100%',
                },
                [theme.breakpoints.up('md')]: {
                  width: '45%',
                },
              }}
              defaultValue=""
            />
            {!validityFromValue && (
              <Typography sx={{ ml: 1, mt: 0.5 }} variant="body2" fontSize="x-small" color="error">
                {t('common.startDateIsRequired')}
              </Typography>
            )}
            {isCronLengthValid && showCronExplainer && tournamentSchedule?.cronExpression && (
              <>
                <Typography variant="body2" fontWeight="bold" mt={1}>
                  ({parsedCronValue})
                </Typography>
                <Box>{parsedNextJobCronValue}</Box>
              </>
            )}
            {errors?.tournamentSchedule?.cronExpression && (
              <FormError error={true} message={errors?.tournamentSchedule?.cronExpression?.message} />
            )}
          </Box>
          <Box
            display="flex"
            sx={{
              [theme.breakpoints.up('xs')]: {
                flexDirection: 'column',
                alignItems: 'flex-end',
              },
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                alignItems: 'center',
              },
            }}
          >
            <Box
              position="relative"
              sx={{
                [theme.breakpoints.up('xs')]: {
                  width: '100%',
                },
                [theme.breakpoints.up('md')]: {
                  width: '45%',
                },
              }}
            >
              <Input
                size="small"
                label={t('content.gameserver.tournamentconfiguration.duration')}
                control={control}
                type="text"
                name="tournamentDuration"
                required
                disabled={mode === TournamentConfigurationMode.EDIT}
                rules={{
                  required: t('fieldIsRequired'),
                  pattern: { value: /^[0-9]+$/, message: t('invalidPattern', { pattern: '[0-9]' }) },
                  setValueAs: value => parseToISO8601DurationHandler(value, chosenTournamentDurationValue.value),
                }}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    width: '100%',
                    mb: 2,
                    mt: 2,
                  },
                }}
                defaultValue=""
              />
              {errors?.tournamentDuration && (
                <FormError
                  sx={{ position: 'absolute', bottom: -10, left: 0 }}
                  error={true}
                  message={errors?.tournamentDuration?.message}
                />
              )}
            </Box>
            <SelectDropdownButton
              chosenValue={chosenTournamentDurationValue}
              options={durationOptions}
              onChange={setChosenTournamentDurationValue}
              disabled={mode === TournamentConfigurationMode.EDIT}
            />
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default TournamentConfigScheduleSettings;
