import { ComponentProps, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Base64FileSchema, useGetDocumentById, UserDocumentMetadataSchema } from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import { Button, capitalize, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';

import { formatBase64String } from '@greenisland-common/helpers';

import ImageDialog from '../ImageDialog';
import PdfDialog from '../PdfDialog';
import FileUpload from './FileUpload';

type Props = ComponentProps<typeof FileUpload> & {
  hidePreviewButton?: boolean;
  metadata?: UserDocumentMetadataSchema;
};

const FileUploadDialog = (props: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [file, setFile] = useState<Base64FileSchema>();

  const {
    data: _,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useGetDocumentById(props?.metadata?.documentId as number, {
    query: { enabled: false },
  });

  const previewImage = (f?: Base64FileSchema) => {
    if (!f) {
      return;
    }
    setFile({ ...f, content: formatBase64String(f.content, f.mimeType) });
    setOpenImagePreview(true);
  };

  const previewMetadataImageHandler = async () => {
    if (props?.metadata) {
      const response = await refetch();
      if (response?.data?.document) {
        const documentData = response.data.document;

        setFile({
          ...documentData,
          content: formatBase64String(documentData.content, documentData.mimeType),
        });
        setOpenImagePreview(true);
      }
    }
  };

  if (isError) {
    enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
  }

  return (
    <>
      {props?.metadata && !props?.hidePreviewButton && (
        <LoadingButton
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => (props?.metadata ? previewMetadataImageHandler() : previewImage())}
          loading={isLoading || isFetching}
        >
          {t('show')}
        </LoadingButton>
      )}
      {!props?.metadata && (
        <Button variant="outlined" color="primary" size="small" onClick={() => setDialogOpen(true)}>
          {t('uploadDocument')}
        </Button>
      )}
      {!props?.metadata ? (
        <Dialog fullWidth open={isDialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>{capitalize(t('uploadDocument'))}</DialogTitle>
          <DialogContent>
            <FileUpload {...props} />
          </DialogContent>
        </Dialog>
      ) : null}
      {openImagePreview && file && file?.mimeType !== 'application/pdf' && (
        <ImageDialog openImageDialog={openImagePreview} setOpenImageDialog={setOpenImagePreview} image={file.content} />
      )}
      {openImagePreview && file && file?.mimeType === 'application/pdf' && (
        <PdfDialog openPdfDialog={openImagePreview} setOpenPdfDialog={setOpenImagePreview} image={file.content} />
      )}
    </>
  );
};

export default FileUploadDialog;
