import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EventModelSchema, useGetEventstreamStreamId } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { DataGridProProps, GridColumns, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-pro';

import { DataGridContainer, StyledDataGrid } from '@greenisland-common/components/atoms';

import { formatDateTime } from '@greenisland-common/helpers';

import { usePermission } from '../../../../../../../app/hooks';
import RegistrationEventDetailPanel from './RegistrationEventDetailPanel';

//TODO will move it in a config file when we have a better app structure
const DEFAULT_EVENTSTREAM_PAGESIZE = 50;

const RegistrationEventsKsaStreamTable = ({ userId }: { userId?: string }) => {
  const { t } = useTranslation();
  const canReadRegistration = usePermission(OnlineCasinoPermissions.getEventstreamStreamId);
  const { data: events, isLoading } = useGetEventstreamStreamId(
    { streamId: `ksaplayerregistration/${userId}` },
    {
      query: {
        enabled: canReadRegistration && !!userId,
      },
    }
  );
  const [pageSize, setPageSize] = React.useState<number>(DEFAULT_EVENTSTREAM_PAGESIZE);

  const eventsRows = useMemo(() => {
    return events?.events?.map((message, index) => ({ ...message, id: index })) ?? [];
  }, [events?.events]);

  const eventsColumns = useMemo<GridColumns>(() => {
    const headers = [
      {
        headerName: t('timestamp'),
        field: 'timestamp',
        type: 'date',
        valueGetter: ({ value }: GridValueGetterParams<number>) => value && new Date(value * 1000),
        valueFormatter: ({ value }: GridValueFormatterParams<Date>) => formatDateTime(value, false),
        flex: 1,
        minWidth: 150,
      },
      {
        headerName: t('eventName'),
        field: 'eventName',
        filter: 'select',
        type: 'singleSelect',
        valueOptions: [...new Set(events?.events?.map(w => w.eventName))],
        flex: 1,
        minWidth: 150,
      },
    ];

    return headers;
  }, [events?.events, t]);

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback(
    ({ row }: { row: EventModelSchema }) => <RegistrationEventDetailPanel event={row} />,
    []
  );

  const getDetailPanelHeight = useCallback(() => 300, []);

  return (
    <DataGridContainer>
      <StyledDataGrid
        density="compact"
        loading={isLoading}
        rows={eventsRows}
        columns={eventsColumns}
        showColumnRightBorder={false}
        disableDensitySelector
        disableSelectionOnClick
        pageSize={pageSize}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        //TODO move it in a config file when we have a better app structure
        rowsPerPageOptions={[10, 50, 100]}
        pagination
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
      />
    </DataGridContainer>
  );
};

export default RegistrationEventsKsaStreamTable;
