import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faAdd, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  GameCategorySchema,
  getGetGameCategoriesQueryKey,
  useGetGameCategories,
  useRemoveGameCategory,
  useUpdateGameCategory,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Stack } from '@mui/material';
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowOrderChangeParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { CheckboxIcon, DataGridContainer, FontAwesomeIcon, StyledDataGrid } from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import ConfirmDialog from '../../../../../app/components/ConfirmDialog';
import { usePermission } from '../../../../../app/hooks';
import ImageCell from '../Components/ImageCell';
import useGetTranslationSchemaLocale from '../Components/utils/useGetTranslationSchemaLocale';
import { GameCategoryDialog } from './components';

type Row = GameCategorySchema;

const Categories = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const canCreateGameCategory = usePermission(OnlineCasinoPermissions.addGameCategory);
  const canEditGameCategory = usePermission(OnlineCasinoPermissions.updateGameCategory);
  const canReadGameCategories = usePermission(OnlineCasinoPermissions.getGameCategories);
  const updateMutation = useUpdateGameCategory();
  const getTranslation = useGetTranslationSchemaLocale();

  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<{ isOpen: boolean; id?: string }>({
    isOpen: false,
  });

  const { data: gameCategories, isLoading, isError, refetch } = useGetGameCategories();
  const mutation = useRemoveGameCategory({
    mutation: {
      onSuccess: async () => {
        enqueueSnackbar(t('content.games.categories.deleteSuccess'), {
          variant: 'success',
        });
        queryClient.invalidateQueries(getGetGameCategoriesQueryKey());
        setDeleteConfirmationDialog({ isOpen: false });
      },

      onError: async () => {
        enqueueSnackbar(t('content.games.categories.deleteError'), {
          variant: 'error',
        });
      },
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | undefined>(undefined);
  const selectedCategory = useMemo(
    () => gameCategories?.find(({ id }) => id === selectedCategoryId),
    [gameCategories, selectedCategoryId]
  );

  const handleConfirm = () => {
    if (deleteConfirmationDialog.id) {
      mutation.mutate({ gameCategoryId: deleteConfirmationDialog.id });
    }
  };

  const handleClickEdit = (categoryId?: string) => {
    setSelectedCategoryId(categoryId);
    setIsOpen(true);
  };

  const rows = useMemo(
    () =>
      gameCategories
        ? gameCategories.slice().sort((a, b) => {
            return a.order - b.order;
          })
        : [],
    [gameCategories]
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'codeNameTranslations',
        headerName: t('content.games.categories.codeName'),
        flex: 1,
        valueGetter: params => getTranslation(params?.row?.codeNameTranslations) ?? params?.row?.codeName,
      },
      { field: 'code', headerName: t('code'), flex: 1, minWidth: 150 },
      {
        field: 'imageUrl',
        headerName: t('content.games.themes.thumbnail'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Row>) => <ImageCell value={params.row.imageUrl} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'enabledInLobby',
        headerName: t('content.games.themes.enabledInLobby'),
        type: 'boolean',
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
      },
      {
        field: 'isLive',
        headerName: t('isLive'),
        type: 'boolean',
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
      },
      {
        field: 'order',
        headerName: t('sortOrder'),
        type: 'number',
        flex: 0.3,
      },
      ...(canEditGameCategory
        ? [
            {
              field: 'actions',
              type: 'actions',
              getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faPencil} />}
                  label="Edit"
                  onClick={() => handleClickEdit(params.row.id)}
                  key="edit"
                />,
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  label="Delete"
                  onClick={() => setDeleteConfirmationDialog({ isOpen: true, id: params.id as string })}
                  key="delete"
                />,
              ],
            },
          ]
        : []),
    ],
    [canEditGameCategory, t, getTranslation]
  );

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const oldRow = rows[params.oldIndex];
    const newRow = rows[params.targetIndex];

    const response = await Promise.allSettled([
      updateMutation.mutateAsync({
        gameCategoryId: oldRow.id,
        data: {
          ...oldRow,
          order: params.targetIndex,
        },
      }),
      updateMutation.mutateAsync({
        gameCategoryId: newRow.id,
        data: {
          ...newRow,
          order: params.oldIndex,
        },
      }),
    ]);

    const error = response.find(p => p.status === 'rejected');

    if (error) {
      enqueueSnackbar(t('content.games.categories.orderError'), {
        variant: 'error',
      });
      return;
    }
    queryClient.invalidateQueries(getGetGameCategoriesQueryKey());
    enqueueSnackbar(t('content.games.categories.orderSuccess'), {
      variant: 'success',
    });
  };

  return canReadGameCategories ? (
    <>
      <Stack spacing={2}>
        {canCreateGameCategory ? (
          <div>
            <Button
              variant="text"
              startIcon={<FontAwesomeIcon icon={faAdd} />}
              onClick={() => handleClickEdit(undefined)}
            >
              {t('content.games.categories.add')}
            </Button>
          </div>
        ) : null}
        {!isError ? (
          <DataGridContainer>
            <StyledDataGrid
              density="compact"
              autoHeight
              loading={isLoading}
              rows={rows}
              columns={columns}
              pagination={false}
              rowReordering={canEditGameCategory}
              onRowOrderChange={handleRowOrderChange}
            />
          </DataGridContainer>
        ) : (
          <ErrorState errorMessage={t('content.games.categories.loadingError')} retryAction={() => refetch()} />
        )}
      </Stack>
      <ConfirmDialog
        title={t('content.games.categories.deleteConfirmation.title')}
        content={t('content.games.categories.deleteConfirmation.description')}
        isOpen={deleteConfirmationDialog.isOpen}
        setIsOpen={(open: boolean) => setDeleteConfirmationDialog({ ...deleteConfirmationDialog, isOpen: open })}
        onConfirm={handleConfirm}
      />
      {canEditGameCategory ? (
        <GameCategoryDialog
          onClose={() => {
            setIsOpen(false);
            setSelectedCategoryId(undefined);
          }}
          open={isOpen}
          category={selectedCategory}
        />
      ) : null}
    </>
  ) : null;
};

export default Categories;
