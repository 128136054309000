import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

interface Props {
  name: string;
  defaultValue: string | null;
}

const IdentificationDateField = ({ name, defaultValue }: Props) => {
  const { control, errors } = useFormContext();
  return (
    <Controller
      rules={{ required: true }}
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ value, onChange }) => (
        <DatePicker
          disablePast
          value={value}
          onChange={onChange}
          renderInput={params => <TextField fullWidth size="small" {...params} error={errors[name]} />}
          inputFormat="dd/MM/yyyy"
          mask="__/__/____"
          views={['year', 'month', 'day']}
        />
      )}
    />
  );
};

export default IdentificationDateField;
