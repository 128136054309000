import { EProductTypeSchema } from '@greenisland-store/authorization';

import BackofficeEntry from './components/BackofficeEntry';
import EmptyLayout from './components/EmptyLayout';
import dashboardGameServerRoutes from './GameServer/DashboardRoutes';
import detailsRoutes from './GameServer/DetailsRoutes';
import gameroundRoutes from './GameServer/GameroundRoutes';
import gamesRoutes from './GameServer/GamesRoutes';
import jackpotRoutes from './GameServer/JackpotRoutes';
import lookupRoutesRedPanda from './GameServer/LookupRoutes';
import operatorRoutes from './GameServer/OperatorRoutes';
import tournamentRoutes from './GameServer/TournamentRoutes';
import contentRoutes from './OnlineCasino/ContentRoutes';
import contestsRoutes from './OnlineCasino/ContestsRoutes';
import dashboardOnlineCasinoRoutes from './OnlineCasino/DashboardRoutes';
import fraudRoutes from './OnlineCasino/FraudRoutes';
import lookupRoutes from './OnlineCasino/LookupRoutes';
import loyaltyRoutes from './OnlineCasino/LoyaltyRoutes';
import marketingRoutes from './OnlineCasino/MarketingRoutes';
import reportingRoutes from './OnlineCasino/ReportingRoutes';
import responsibleGamingRoutes from './OnlineCasino/ResponsibleGamingRoutes';
import settingsRoutes, { agentManagementRoutes } from './OnlineCasino/SettingsRoutes';
import taskRoutes from './OnlineCasino/TaskRoutes';
import userRoutes from './OnlineCasino/UserRoutes';
import providerServiceRoutes from './ProvidersService/ProviderServiceRoutes';
import visitorRoutes from './VisitorRoutes/VisitorRoutes';
import { BaseRouting } from './RouteInterfaces';

const RoutesVisitor: BaseRouting = {
  identifier: 'visitor',
  layout: EmptyLayout,
  authorize: false,
  entityPrefixed: false,
  routes: [...visitorRoutes],
};

const importBackofficeGIRoutes = (): BaseRouting => {
  return {
    identifier: EProductTypeSchema.OnlineCasino,
    layout: BackofficeEntry,
    authorize: true,
    entityPrefixed: true,
    routes: [
      ...dashboardOnlineCasinoRoutes,
      ...lookupRoutes,
      ...userRoutes,
      ...taskRoutes,
      ...reportingRoutes,
      ...marketingRoutes,
      ...contentRoutes,
      ...contestsRoutes,
      ...fraudRoutes,
      ...loyaltyRoutes,
      ...responsibleGamingRoutes,
      ...settingsRoutes,
    ],
  };
};

const importGameServerRoutes: () => BaseRouting = () => {
  return {
    identifier: EProductTypeSchema.GameServer,
    layout: BackofficeEntry,
    authorize: true,
    entityPrefixed: true,
    routes: [
      ...dashboardGameServerRoutes,
      ...operatorRoutes,
      ...lookupRoutesRedPanda,
      ...detailsRoutes,
      ...gamesRoutes,
      ...gameroundRoutes,
      ...tournamentRoutes,
      ...jackpotRoutes,
      ...agentManagementRoutes,
    ],
  };
};

const importProviderServiceRoutes: () => BaseRouting = () => {
  return {
    identifier: EProductTypeSchema.ProviderService,
    layout: BackofficeEntry,
    authorize: true,
    entityPrefixed: true,
    routes: [...providerServiceRoutes, ...agentManagementRoutes],
  };
};

export const getRoutes = (productType?: EProductTypeSchema) => {
  switch (productType) {
    case EProductTypeSchema.Authorization: // TODO: Display dedicaded Authorization backoffice?
    case EProductTypeSchema.OnlineCasino:
    case EProductTypeSchema.PaymentService: // TODO: Display dedicaded PaymentService backoffice
      return importBackofficeGIRoutes();
    case EProductTypeSchema.GameServer:
      return importGameServerRoutes();
    case EProductTypeSchema.ProviderService:
      return importProviderServiceRoutes();
    default:
      return RoutesVisitor;
  }
};

export default getRoutes;
