import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Box, capitalize, List, ListItem, Popover, Typography } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

import { POPOVER_MODIFIED_LABELS, TournamentConfigurationMode } from '../helpers/tournamentConfigFormInterfaces';

interface Props {
  mode: TournamentConfigurationMode;
}

const TournamentConfigEditPopoverContent = ({ mode }: Props) => {
  const { t } = useTranslation();
  const [popoverElement, setPopoverElement] = useState<SVGSVGElement | null>(null);
  const isPopoverOpen = Boolean(popoverElement);

  const handlePopoverOpen = (event: MouseEvent<SVGSVGElement>) => {
    setPopoverElement(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverElement(null);
  };

  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Box display="flex" alignItems="center">
        <Typography variant="h4">
          {capitalize(
            t(
              mode !== TournamentConfigurationMode.EDIT
                ? 'content.gameserver.tournamentconfiguration.addTournamentConfiguration'
                : 'content.gameserver.tournamentconfiguration.form.title'
            )
          )}
        </Typography>
        {mode === TournamentConfigurationMode.EDIT ? (
          <FontAwesomeIcon
            aria-owns={isPopoverOpen ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            icon={faCircleInfo}
            sx={{ marginLeft: 2, color: 'array.headers', cursor: 'pointer', display: 'inline' }}
          />
        ) : null}
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={isPopoverOpen}
        anchorEl={popoverElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        style={{ marginTop: 20 }}
      >
        <Box p={2}>
          <Typography sx={{ mb: 2 }} variant="body2" fontSize="large" fontWeight="bold">
            {t('content.gameserver.tournamentconfiguration.form.modeTitle')}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="body2" fontSize="medium">
            {t('content.gameserver.tournamentconfiguration.form.description')}
          </Typography>
          <Box>
            {POPOVER_MODIFIED_LABELS?.map((option, index) => (
              <List sx={{ listStyleType: 'disc', pl: 1, listStylePosition: 'inside' }} key={index}>
                <ListItem sx={{ display: 'list-item', p: 0, m: 0 }}>{capitalize(t(option.label))}</ListItem>
              </List>
            ))}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default TournamentConfigEditPopoverContent;
