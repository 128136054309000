import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Stack } from '@mui/material';

import { ErrorCard } from '@greenisland-common/components/atoms';
import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers';

import { usePermission } from '../../../../../../app/hooks';
import VouchersTable from './VouchersTable';

const VouchersPage = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const canReadVouchers = usePermission(OnlineCasinoPermissions.getUserVouchers);
  const [searchParams, setSearchParams] = useSearchParams();
  if (!canReadVouchers)
    return (
      <ErrorCard severity="info">
        {t('common.noPermissionToSeeThePage', OnlineCasinoPermissions.getUserVouchers)}
      </ErrorCard>
    );

  if (!userId) {
    return null;
  }

  return (
    <Stack direction="column" spacing={2}>
      <DateRangePicker
        unixStartDate={Number(searchParams.get('startdate')) * 1000}
        unixEndDate={Number(searchParams.get('enddate')) * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
        }}
      />
      <VouchersTable
        userId={userId}
        start={searchParams.get('startdate') ? Number(searchParams.get('startdate')) : undefined}
        end={searchParams.get('enddate') ? Number(searchParams.get('enddate')) : undefined}
      />
    </Stack>
  );
};

export default VouchersPage;
