import { useCallback } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CampaignSchema, getGetCampaignsOverviewQueryKey, useAddCampaign, useUpdateCampaign } from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';

interface Props {
  open: boolean;
  onClose: () => void;
  chosenCampaign: CampaignSchema | null;
}

const CampaignDialog = ({ open, onClose, chosenCampaign }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const methods = useForm<CampaignSchema>({
    shouldFocusError: true,
    mode: 'onChange',
    defaultValues: {
      campaignId: chosenCampaign ? chosenCampaign?.campaignId : undefined,
      campaignName: chosenCampaign ? chosenCampaign?.campaignName : undefined,
      year: chosenCampaign ? chosenCampaign?.year : new Date().getFullYear().toString(),
      theme: chosenCampaign ? chosenCampaign?.theme : undefined,
    },
  });

  const { register, control, handleSubmit, reset, errors } = methods;

  const onSuccessHandler = useCallback(() => {
    enqueueSnackbar(t('success'), { variant: 'success' });
    queryClient.invalidateQueries(getGetCampaignsOverviewQueryKey());
    onClose();
    reset();
  }, [enqueueSnackbar, onClose, queryClient, reset, t]);

  const onErrorHandler = useCallback(
    error => {
      enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
    },
    [enqueueSnackbar, t]
  );

  const { mutate: addCampaign, isLoading: isAddCampaignLoading } = useAddCampaign({
    mutation: { onSuccess: onSuccessHandler, onError: onErrorHandler },
  });

  const { mutate: updateCampaign, isLoading: isUpdateCamapignLoading } = useUpdateCampaign({
    mutation: { onSuccess: onSuccessHandler, onError: onErrorHandler },
  });

  const onSubmit = (data: CampaignSchema) => {
    const payload = {
      ...data,
      year: data?.year || undefined,
      theme: data?.theme || undefined,
    };

    if (chosenCampaign) {
      return updateCampaign({
        campaignId: chosenCampaign.campaignId,
        data: payload,
      });
    }
    return addCampaign({ data: payload });
  };

  return (
    <Dialog fullWidth open={open} maxWidth="sm">
      <DialogTitle>{capitalize(t(chosenCampaign ? 'editCampaign' : 'addCampaign'))}</DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <TextField
                label={t('campaignId')}
                name="campaignId"
                inputRef={register({ required: capitalize(t('required')) })}
                required={true}
                error={!!errors?.campaignId}
                helperText={errors?.campaignId?.message}
                disabled={!!chosenCampaign}
                size="small"
                fullWidth
              />
              <TextField
                label={t('campaignName')}
                name="campaignName"
                required={true}
                inputRef={register({ required: capitalize(t('required')) })}
                error={!!errors?.campaignName}
                helperText={errors?.campaignName?.message}
                size="small"
                fullWidth
              />
              <Controller
                rules={{ required: false, setValueAs: value => value && new Date(value).getFullYear().toString() }}
                name="year"
                control={control}
                render={props => (
                  <DatePicker
                    {...props}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        error={Boolean(errors?.year)}
                        helperText={errors?.year?.message}
                        inputRef={register({
                          required: false,
                        })}
                        label={t('year')}
                      />
                    )}
                    inputFormat="yyyy"
                    mask="____"
                    views={['year']}
                  />
                )}
              />
              <TextField
                label={t('theme')}
                name="theme"
                inputRef={register({ required: false })}
                error={!!errors?.theme}
                helperText={errors?.theme?.message}
                size="small"
                fullWidth
              />
            </Stack>
            <DialogActions>
              <Button variant="outlined" onClick={onClose}>
                {t('cancel')}
              </Button>
              <LoadingButton
                loading={isAddCampaignLoading || isUpdateCamapignLoading}
                variant="contained"
                type="submit"
                disabled={isAddCampaignLoading || isUpdateCamapignLoading}
              >
                {t('save')}
              </LoadingButton>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default CampaignDialog;
