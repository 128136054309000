import { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Game, QueryGamesParams, useQueryGames } from '@greenisland-store/gameServer';
import { Autocomplete, Box, Card, CardContent, CardHeader, Chip, Stack, TextField } from '@mui/material';

import { TournamentConfigurationMode } from '../helpers/tournamentConfigFormInterfaces';

interface Props {
  mode: TournamentConfigurationMode;
}

const TournamentConfigGamesSettings = ({ mode = TournamentConfigurationMode.ADD }: Props) => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();

  const watchGameReferences = watch('gameReferences');

  const canModify = mode === TournamentConfigurationMode.ADD || mode === TournamentConfigurationMode.COPY;

  const queryGamesParams: QueryGamesParams = {
    pageSize: 100,
    page: 1,
    availableForTournament: true,
  };

  const { data: games, isLoading } = useQueryGames(queryGamesParams);

  const defaultGames = useMemo(() => {
    return games?.entries?.filter(game => {
      if (game?.gameReference) {
        return watchGameReferences?.includes(game?.gameReference);
      }
    });
  }, [games?.entries, watchGameReferences]);

  useEffect(() => {
    if (!isLoading && games && defaultGames) {
      setValue('games', defaultGames);
    }
  }, [defaultGames, games, isLoading, setValue]);

  if (mode === TournamentConfigurationMode.EDIT && !watchGameReferences?.length) {
    return null;
  }

  return (
    <Stack spacing={2} position="relative">
      <Card>
        <CardHeader title={t('content.gameserver.tournamentconfiguration.form.includedgamessettings.title')} />
        <CardContent>
          {canModify ? (
            <Controller
              control={control}
              name="games"
              rules={{
                required: true,
              }}
              defaultValue={defaultGames}
              render={({ onChange, value, ...props }) => {
                return (
                  <Autocomplete
                    size="small"
                    multiple={true}
                    value={value}
                    options={games?.entries || []}
                    defaultValue={defaultGames}
                    getOptionLabel={option => option?.name ?? ''}
                    onChange={(_, value) => onChange(value)}
                    disableCloseOnSelect={true}
                    renderInput={params => (
                      <TextField
                        {...params}
                        {...props}
                        fullWidth
                        variant="outlined"
                        label={t('games')}
                        size="small"
                        sx={{
                          width: '40%',
                        }}
                      />
                    )}
                  />
                );
              }}
            />
          ) : (
            <Box display="flex" flexWrap="wrap" gap={2}>
              {defaultGames?.map((item: Game, index: number) => (
                <Chip key={index} label={item?.name} color="success" />
              ))}
            </Box>
          )}
        </CardContent>
      </Card>
    </Stack>
  );
};

export default TournamentConfigGamesSettings;
