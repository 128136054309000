import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { CriticalFieldsMismatchSchema, useGetCriticalFieldsMismatch } from '@greenisland-api';
import { Box, capitalize, CircularProgress, Dialog, IconButton, Stack, Typography, useTheme } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

import { CriticalMismatchField, CriticalMismatchFieldMode, useGetMismatchCriticalFields } from './Constants';
import CriticalFieldsMismatchChoiceSection from './CriticalFieldsMismatchChoiceSection';

interface Props {
  userId: number;
  criticalFieldsMismatchInfo?: CriticalFieldsMismatchSchema;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  fetchCriticalFieldsMismatchData?: boolean;
}

const CriticalFieldsMismatchDialog = ({
  userId,
  criticalFieldsMismatchInfo,
  open,
  setOpen,
  fetchCriticalFieldsMismatchData = false,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { data: criticalFieldsMismatchUpdatedInfo, isLoading } = useGetCriticalFieldsMismatch(userId, {
    query: { enabled: fetchCriticalFieldsMismatchData && !!userId },
  });
  const mismatchCriticalFields = useGetMismatchCriticalFields(
    fetchCriticalFieldsMismatchData,
    criticalFieldsMismatchInfo,
    criticalFieldsMismatchUpdatedInfo
  );

  const [chosenOption, setChosenOption] = useState<CriticalMismatchField | null>(null);

  return (
    <Dialog fullWidth open={open} maxWidth="sm" onClose={() => setOpen(false)}>
      <DialogTitle>{capitalize(t('criticalFieldsMismatch.titles.resolveCriticalFieldsMismatch'))}</DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={() => setOpen(false)}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        {isLoading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Stack spacing={2}>
            <Box
              display="flex"
              sx={{
                [theme.breakpoints.up('xs')]: {
                  flexDirection: 'column',
                  gap: 2,
                },
              }}
            >
              <Typography variant="body1">
                {`${capitalize(t('userId'))}: `}
                <Box component="span" fontWeight="500">
                  {userId}
                </Box>
              </Typography>
              <Typography variant="body1">
                {`${capitalize(t('token'))}: `}
                <Box component="span" fontWeight="500">
                  {fetchCriticalFieldsMismatchData
                    ? criticalFieldsMismatchUpdatedInfo?.BGCCriticalFieldsMismatch?.token
                    : criticalFieldsMismatchInfo?.BGCCriticalFieldsMismatch?.token}
                </Box>
              </Typography>
            </Box>
            <Box
              display="flex"
              py={4}
              sx={{
                [theme.breakpoints.up('xs')]: {
                  flexDirection: 'column',
                  gap: 4,
                },
                [theme.breakpoints.up('md')]: {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: 2,
                },
              }}
            >
              {chosenOption ? (
                <CriticalFieldsMismatchChoiceSection
                  userId={userId}
                  fieldInfo={chosenOption}
                  mode={CriticalMismatchFieldMode.CONFIRM}
                  setOpen={setOpen}
                />
              ) : (
                mismatchCriticalFields?.map((field, index) => {
                  return (
                    <CriticalFieldsMismatchChoiceSection
                      key={index}
                      fieldInfo={field}
                      setChosenOption={setChosenOption}
                    />
                  );
                })
              )}
            </Box>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CriticalFieldsMismatchDialog;
