import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { MaxDepositLimitSchema, useGetActiveMaximumBalanceLimit } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowParams, GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, DateTime, FontAwesomeIcon, PermissionWrapper } from '@greenisland-common/components/atoms';
import StyledDataGrid from '@greenisland-common/components/atoms/StyledDataGrid';

import { formatCurrency } from '../../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../../app/hooks';
import { MaxLimitType } from '../../Constants/Limits';
import MaxLimitCancelDialog from '../../MaxLimitCancelDialog';

type DepositLimitsProps = {
  userId: string;
};

const ActiveMaximumLimitsTable = ({ userId }: DepositLimitsProps) => {
  const { t } = useTranslation();

  const canReadActiveMaxBalanceLimit = usePermission(OnlineCasinoPermissions.getActiveMaximumBalanceLimit);
  const canCancelMaximumBalanceLimit = usePermission(OnlineCasinoPermissions.cancelMaximumBalanceLimit);

  const [selectedMaxLimit, setSelectedMaxLimit] = useState<MaxDepositLimitSchema | null>(null);

  const {
    data: activeBalanceLimit,
    isLoading,
    isError,
    error,
  } = useGetActiveMaximumBalanceLimit(parseInt(userId, 10), { query: { enabled: canReadActiveMaxBalanceLimit } });

  const headers: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      minWidth: 50,
      flex: 0.1,
      headerName: '',
      getActions: (params: GridRowParams) => [
        <>
          {canCancelMaximumBalanceLimit && (
            <Tooltip title={t('cancel')} key="delete">
              <GridActionsCellItem
                label={t('cancel')}
                icon={<FontAwesomeIcon icon={faTrash} sx={{ color: 'error.main' }} />}
                onClick={() => {
                  if (params?.row) {
                    setSelectedMaxLimit(params.row);
                  }
                }}
                key="cancel"
              />
            </Tooltip>
          )}
        </>,
      ],
    },
    {
      headerName: t('startDate'),
      field: 'startDate',
      renderCell: ({ row }) => <DateTime dateTime={row.startDate ?? 0} />,
      flex: 1,
      minWidth: 160,
    },
    {
      headerName: t('value'),
      field: 'value',
      flex: 1,
      minWidth: 150,
      valueGetter: ({ row }) => formatCurrency(row.value),
    },
  ];

  const rows: GridRowsProp = activeBalanceLimit ? [activeBalanceLimit].map((row, i) => ({ ...row, id: i })) : [];

  return (
    <>
      <PermissionWrapper
        errorMessage={error?.message}
        isError={isError}
        isLoading={isLoading}
        permission={OnlineCasinoPermissions.getActiveMaximumBalanceLimit}
      >
        <DataGridContainer>
          <StyledDataGrid columns={headers} rows={rows} hideFooter />
        </DataGridContainer>
      </PermissionWrapper>
      {selectedMaxLimit ? (
        <MaxLimitCancelDialog
          open={true}
          onClose={() => setSelectedMaxLimit(null)}
          type={MaxLimitType.BALANCE_LIMIT}
          selectedMaxLimit={selectedMaxLimit}
        />
      ) : null}
    </>
  );
};

export default memo(ActiveMaximumLimitsTable);
