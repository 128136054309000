import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faCircleInfo, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  GetAllMediaGalleryEntriesOfTypeParams,
  getGetAllMediaGalleryEntriesOfTypeQueryKey,
  MediaGalleryEntrySchema,
  MediaGalleryEntryTypeParamParameter,
  useDeleteMediaGalleryEntry,
  useGetAllMediaGalleryEntriesOfType,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import {
  Box,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ConfirmDialog from 'src/app/components/ConfirmDialog';
import { usePermission } from 'src/app/hooks';

import { ErrorCard, FontAwesomeIcon, PermissionWrapper } from '@greenisland-common/components/atoms';

import { Filters, MediaGalleryFilter } from './MediaGalleryFilter';
import MediaGalleryPagination from './Pagination';

type PropsWithoutSelection = {
  mediaGalleryEntryType: MediaGalleryEntryTypeParamParameter;
  disableFilter?: boolean;
  selectedMedia?: never;
  selectionEnabled?: never;
  onSelect?: never;
  onSelectMediaGalleryEntry?: Dispatch<SetStateAction<GetAllMediaGalleryEntriesOfTypeParams>>;
  isMultiSelect?: never;
};

type PropsWithSelection = {
  mediaGalleryEntryType: MediaGalleryEntryTypeParamParameter;
  disableFilter?: boolean;
  selectedMedia?: MediaGalleryEntrySchema[];
  selectionEnabled: boolean;
  onSelect: (media: MediaGalleryEntrySchema[]) => void;
  onSelectMediaGalleryEntry?: Dispatch<SetStateAction<GetAllMediaGalleryEntriesOfTypeParams>>;
  isMultiSelect?: boolean;
};

type Props = PropsWithoutSelection | PropsWithSelection;

export const MediaGalleryList = ({
  mediaGalleryEntryType,
  selectedMedia = [],
  selectionEnabled = false,
  onSelect,
  isMultiSelect = false,
  disableFilter = false,
  onSelectMediaGalleryEntry,
}: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [filters, setFilters] = useState<Filters>({
    mediaGalleryEntryType,
  });
  const [showInfo, setShowInfo] = useState<number | null>(null);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<{ isOpen: boolean; mediaId?: number }>({
    isOpen: false,
  });
  const [internalSelectedMedia, setInternalSelectedMedia] = useState<MediaGalleryEntrySchema[]>(selectedMedia);
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const canReadMediaGalleryEntries = usePermission(OnlineCasinoPermissions.getAllMediaGalleryEntriesOfType);
  const canDeleteMediaGalleryEntries = usePermission(OnlineCasinoPermissions.deleteMediaGalleryEntry);

  const cols = useMemo(() => {
    if (isLg) return 6;
    if (isMd) return 4;
    if (isSm) return 3;
    return 2;
  }, [isLg, isMd, isSm]);

  const { data: assets, isError } = useGetAllMediaGalleryEntriesOfType(
    {
      ...filters,
      numberOfItems: pageSize,
      pageNumber: page,
    },
    { query: { enabled: canReadMediaGalleryEntries } }
  );
  const deleteMutation = useDeleteMediaGalleryEntry({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetAllMediaGalleryEntriesOfTypeQueryKey({ mediaGalleryEntryType }));
      },
    },
  });

  const onSelectMedia = (media: MediaGalleryEntrySchema) => {
    setInternalSelectedMedia(currentSelectedMedia => {
      let newSelectedMedia;

      if (isMultiSelect) {
        const isAlreadySelected = currentSelectedMedia.some(entry => entry.path === media.path);
        newSelectedMedia = isAlreadySelected
          ? currentSelectedMedia.filter(entry => entry.path !== media.path)
          : [...currentSelectedMedia, media];
      } else {
        newSelectedMedia = currentSelectedMedia.some(entry => entry.path === media.path) ? [] : [media];
      }
      onSelect && onSelect(newSelectedMedia);

      return newSelectedMedia;
    });
  };

  const selectedMediaIds = useMemo(() => internalSelectedMedia.map(media => media.path), [internalSelectedMedia]);

  const images = useMemo(() => {
    return assets?.data ?? [];
  }, [assets?.data]);

  const deleteMedia = () => {
    if (deleteConfirmationDialog.mediaId) {
      deleteMutation.mutate({ mediaGalleryEntryId: deleteConfirmationDialog.mediaId });
    }
  };

  const downloadMedia = (url: string, fileName: string) => {
    const a = document.createElement('a');

    a.href = url;
    a.target = '_blank';
    a.setAttribute('download', fileName);
    a.rel = 'noopener noreferrer';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    if (Object.keys(filters).length > 0) {
      setPage(0);
    }
  }, [filters]);

  return (
    <PermissionWrapper
      errorMessage={t('content.mediaGallery.loadingError')}
      isError={isError}
      isLoading={false}
      permission={OnlineCasinoPermissions.getAllMediaGalleryEntriesOfType}
    >
      <Stack spacing={2}>
        <MediaGalleryFilter
          mediaGalleryEntryType={mediaGalleryEntryType}
          onChange={filters => {
            setFilters(filters);
            if (onSelectMediaGalleryEntry) {
              onSelectMediaGalleryEntry(filters);
            }
          }}
          disableFilter={disableFilter}
        />

        {assets?.data.length === 0 ? (
          <ErrorCard severity="info">{t('content.mediaGallery.noContent')}</ErrorCard>
        ) : (
          <Box>
            <ImageList cols={cols} sx={{ mt: 2, height: 'auto' }}>
              {images.map(entry => (
                <ImageListItem key={entry.id}>
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                      textAlign: 'center',
                      overflow: 'hidden',
                      display: 'flex',
                      alignItems: 'center',
                      minHeight: '160px',
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        opacity: 0.5,
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        backgroundColor: '#9ca3af1a',
                        backgroundImage:
                          'linear-gradient(135deg,#6b728080 10%,transparent 0,transparent 50%,#6b728080 0,#6b728080 60%,transparent 0,transparent)',
                        backgroundSize: '7.07px 7.07px',
                      }}
                    />
                    {showInfo === entry.id ? (
                      <Box
                        sx={{
                          position: 'absolute',
                          inset: 0,
                          backgroundColor: 'transparent',
                          zIndex: 2,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          height: '100%',
                          padding: 0.5,
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            zIndex: 2,
                            backgroundColor: 'white',
                            px: 1,
                            py: 3,
                          }}
                        >
                          <Typography variant="subtitle1" gutterBottom>
                            ID: {entry.id}
                          </Typography>
                          <Typography variant="h6" gutterBottom>
                            {entry.fileName}
                          </Typography>
                          <Typography variant="overline" display="block" gutterBottom>
                            {entry.width}px X {entry.height}px
                          </Typography>
                          <Divider />
                          <Typography variant="overline" display="block" gutterBottom>
                            {entry.extension}
                          </Typography>
                        </Box>
                      </Box>
                    ) : null}
                    <Box
                      component="img"
                      src={entry.path}
                      alt={entry.fileName}
                      loading="lazy"
                      onClick={selectionEnabled && onSelect ? () => onSelectMedia(entry) : undefined}
                      title={t('content.mediaGallery.selectImageTitle')}
                      sx={{
                        cursor: 'pointer',
                        objectFit: 'contain',
                        position: 'relative',
                        width: '100%',
                        ...(selectionEnabled && selectedMediaIds.includes(entry.path)
                          ? { border: 'green', borderWidth: 'thick', borderStyle: 'solid' }
                          : {}),
                      }}
                    />
                  </Box>
                  {entry.id !== showInfo ? (
                    <ImageListItemBar
                      sx={{
                        backgroundColor: 'transparent',
                        paddingLeft: 0.5,
                        paddingTop: 0.5,
                      }}
                      position="top"
                      actionIcon={
                        <IconButton
                          size="small"
                          title="download"
                          onClick={() => downloadMedia(entry.path, entry.fileName)}
                          sx={{
                            backgroundColor: 'white !important',
                            borderRadius: 0,
                            zIndex: 2,
                          }}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </IconButton>
                      }
                      actionPosition="left"
                    />
                  ) : null}
                  <ImageListItemBar
                    sx={{
                      backgroundColor: 'transparent',
                      paddingRight: 0.5,
                      paddingTop: 0.5,
                    }}
                    position="top"
                    actionIcon={
                      <IconButton
                        size="small"
                        title="info"
                        onClick={() => setShowInfo(showInfo ? null : entry.id)}
                        sx={{
                          backgroundColor: 'white !important',
                          borderRadius: 0,
                          zIndex: 3,
                        }}
                      >
                        <FontAwesomeIcon icon={faCircleInfo} />
                      </IconButton>
                    }
                    actionPosition="right"
                  />
                  {canDeleteMediaGalleryEntries && entry.id !== showInfo ? (
                    <ImageListItemBar
                      sx={{
                        backgroundColor: 'transparent',
                        paddingRight: 0.5,
                        paddingBottom: 0.5,
                      }}
                      position="bottom"
                      actionIcon={
                        <IconButton
                          size="small"
                          title="delete"
                          onClick={() => setDeleteConfirmationDialog({ isOpen: true, mediaId: entry.id })}
                          sx={{
                            backgroundColor: 'white !important',
                            borderRadius: 0,
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                      }
                    />
                  ) : null}
                </ImageListItem>
              ))}
            </ImageList>
            {assets?.paging ? (
              <MediaGalleryPagination
                pageSize={pageSize}
                page={page}
                onPage={setPage}
                onPageSize={setPageSize}
                pageCount={Math.ceil(assets?.paging.totalItems / assets?.paging.pageSize)}
              />
            ) : null}
          </Box>
        )}
        <ConfirmDialog
          title={t('content.mediaGallery.deleteConfirmation.title')}
          content={t('content.mediaGallery.deleteConfirmation.description')}
          isOpen={deleteConfirmationDialog.isOpen}
          setIsOpen={(open: boolean) => setDeleteConfirmationDialog({ isOpen: open })}
          onConfirm={deleteMedia}
        />
      </Stack>
    </PermissionWrapper>
  );
};
