import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMediaGalleryEntryTypeSchema, MultiplierTournamentSchema } from '@greenisland-api';
import { Box, capitalize, FormControlLabel, Stack, Switch, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import Input from '@greenisland-common/components/molecules/Input';
import { MediaFieldsWithPreview } from '@greenisland-common/components/molecules/MediaField';

import { convertToMediaImageSchema } from '@greenisland-common/helpers';

import { CreateMultiplierTournamentSchemaForm } from '../helpers/MultiplierTournamentFormContext';

interface Props {
  isCreateMode?: boolean;
  multiplierTournament?: MultiplierTournamentSchema;
}

const MultiplierTournamentGeneralSettings = ({ isCreateMode = true, multiplierTournament }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { control, watch, setValue, errors } = useFormContext<CreateMultiplierTournamentSchemaForm>();
  const startDateValue = watch('startDate');
  const endDateValue = watch('endDate');

  useEffect(() => {
    if (!isCreateMode) {
      if (multiplierTournament?.start) {
        setValue('startDate', new Date(multiplierTournament?.start * 1000));
      }
      if (multiplierTournament?.end) {
        setValue('endDate', new Date(multiplierTournament?.end * 1000));
      }
      if (multiplierTournament?.images?.imageItems) {
        setValue('images', convertToMediaImageSchema(multiplierTournament?.images));
      }
      if (multiplierTournament?.bannerImages?.imageItems) {
        setValue('bannerImages', convertToMediaImageSchema(multiplierTournament?.bannerImages));
      }
    }
  }, [
    isCreateMode,
    multiplierTournament?.bannerImages,
    multiplierTournament?.bannerImages?.imageItems,
    multiplierTournament?.end,
    multiplierTournament?.images,
    multiplierTournament?.start,
    setValue,
  ]);

  return (
    <Stack spacing={2}>
      <Box sx={{ ml: 2, mb: 2, mt: 1 }}>
        <FormControlLabel
          label={
            <Typography fontWeight="bold" fontSize="medium">
              {t('contests.multiplierTournaments.form.options.activate')}
            </Typography>
          }
          control={
            <Controller
              control={control}
              name="display"
              defaultValue={!isCreateMode && multiplierTournament?.display}
              render={({ value, onChange }) => (
                <Switch sx={{ mr: 1 }} edge="end" onChange={event => onChange(event.target.checked)} checked={value} />
              )}
            />
          }
        />
      </Box>
      <Typography fontWeight={500} variant="h5" sx={{ pt: 1 }}>
        {capitalize(t('content.gameserver.tournamentconfiguration.form.generalsettings.title'))}
      </Typography>
      <Box display="flex" flexDirection="column">
        <Input
          size="medium"
          label={capitalize(t('content.gameserver.tournamentconfiguration.tournamentName'))}
          control={control}
          type="text"
          name="name"
          defaultValue={!isCreateMode && multiplierTournament?.name}
          required
          rules={{ required: t('fieldIsRequired') }}
          sx={{
            [theme.breakpoints.up('xs')]: {
              width: '100%',
              mb: 2,
            },
            [theme.breakpoints.up('md')]: {
              width: '45%',
            },
          }}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          sx={{
            [theme.breakpoints.up('xs')]: {
              flexDirection: 'column',
            },
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
            },
          }}
        >
          <Controller
            name={isCreateMode ? 'start' : 'startDate'}
            control={control}
            rules={{ required: t('fieldIsRequired') }}
            defaultValue={!isCreateMode && startDateValue}
            render={({ onChange, value }) => (
              <DateTimePicker
                label={capitalize(t('startDate'))}
                disablePast={isCreateMode}
                value={value}
                onChange={value => onChange(value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    sx={{
                      [theme.breakpoints.up('xs')]: {
                        width: '100%',
                      },
                      [theme.breakpoints.up('md')]: {
                        width: '45%',
                      },
                    }}
                  />
                )}
                inputFormat="dd/MM/yyyy HH:mm:ss"
                mask="__/__/____ __:__:__"
              />
            )}
          />
          <Typography
            variant="body2"
            fontSize="large"
            fontWeight="bold"
            sx={{
              [theme.breakpoints.up('xs')]: {
                my: 1,
              },
            }}
          >
            {t('content.gameserver.tournamentconfiguration.form.other.until')}
          </Typography>
          <Controller
            name={isCreateMode ? 'end' : 'endDate'}
            control={control}
            rules={{ required: t('fieldIsRequired') }}
            defaultValue={!isCreateMode && endDateValue}
            render={({ onChange, value }) => (
              <DateTimePicker
                label={capitalize(t('endDate'))}
                value={value}
                onChange={value => onChange(value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    required
                    sx={{
                      [theme.breakpoints.up('xs')]: {
                        width: '100%',
                      },
                      [theme.breakpoints.up('md')]: {
                        width: '45%',
                      },
                    }}
                  />
                )}
                minDateTime={isCreateMode ? watch('start') : startDateValue}
                inputFormat="dd/MM/yyyy HH:mm:ss"
                mask="__/__/____ __:__:__"
              />
            )}
          />
        </Box>
        <Typography fontWeight={500} variant="body1" fontSize="large" sx={{ pt: 1, mb: 1 }}>
          {capitalize(t('imageUrl'))}
        </Typography>
        <MediaFieldsWithPreview
          control={control}
          size="small"
          key="images"
          name="images"
          error={Boolean(errors['images'])}
          type="text"
          required={false}
          label={capitalize(t('addImages'))}
          mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.MultiplierTournamentImage}
          isMultiSelect={false}
        />
        <Typography fontWeight={500} variant="body1" fontSize="large" sx={{ pt: 1, mb: 1 }}>
          {capitalize(t('bannerUrl'))}
        </Typography>
        <MediaFieldsWithPreview
          control={control}
          size="small"
          key="bannerImages"
          name="bannerImages"
          error={Boolean(errors['bannerImages'])}
          type="text"
          required={false}
          label={capitalize(t('addImages'))}
          mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.MultiplierTournamentBannerImage}
          isMultiSelect={false}
        />
      </Box>
    </Stack>
  );
};

export default MultiplierTournamentGeneralSettings;
