import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TextField, useTheme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { subHours } from 'date-fns';

import { DATETIME_INPUT_FORMAT, DATETIME_INPUT_MASK } from '@greenisland-common/components/atoms/Constants';

interface Props {
  voucherStartDate: Date;
  voucherEndDate: Date;
  setVoucherStartDate: Dispatch<SetStateAction<Date>>;
  setVoucherEndDate: Dispatch<SetStateAction<Date>>;
}

const VouchersDatesFilter = ({ voucherStartDate, voucherEndDate, setVoucherStartDate, setVoucherEndDate }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      mt={4}
      sx={{
        [theme.breakpoints.up('xs')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
        [theme.breakpoints.up('lg')]: {
          flexDirection: 'row',
          alignItems: 'center',
        },
      }}
    >
      <Box
        display="flex"
        sx={{
          [theme.breakpoints.up('xs')]: {
            flexDirection: 'column',
            width: '100%',
          },
          [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
            gap: 2,
            width: 'fit-content',
          },
        }}
      >
        <DateTimePicker
          label={t('startDate')}
          onChange={date => {
            setVoucherStartDate(date ?? subHours(new Date(), 24));
          }}
          value={voucherStartDate}
          renderInput={params => <TextField size="small" sx={{ mb: 2 }} fullWidth {...params} />}
          inputFormat={DATETIME_INPUT_FORMAT}
          mask={DATETIME_INPUT_MASK}
          maxDate={voucherEndDate}
        />
        <DateTimePicker
          label={t('endDate')}
          onChange={date => {
            setVoucherEndDate(date ?? new Date());
          }}
          value={voucherEndDate}
          renderInput={params => <TextField size="small" sx={{ mb: 2 }} fullWidth {...params} />}
          inputFormat={DATETIME_INPUT_FORMAT}
          mask={DATETIME_INPUT_MASK}
          minDate={voucherStartDate}
        />
      </Box>
    </Box>
  );
};

export default VouchersDatesFilter;
