import React, { memo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import {
  GameConfigurationSchema,
  getGetGameV2QueryKey,
  UpdateGameConfigurationSchema,
  useAddGameConfiguration,
  useUpdateGameConfiguration,
} from '@greenisland-api';
import ReactJson, { InteractionProps } from '@microlink/react-json-view';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import { Box, Card, IconButton, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { Checkbox, SelectInput } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

interface ConfigWithPK extends UpdateGameConfigurationSchema {
  pkey?: number;
}

interface DeleteParams {
  open: boolean;
  index: number;
}

interface Props {
  configuration: GameConfigurationSchema | undefined;
  isDirty?: boolean;
  setIsDirty: (isDirty: boolean) => void;
  index?: number;
  setDelete: ({ open, index }: DeleteParams) => void;
}

const GameConfigurationForm = ({ configuration, isDirty, setIsDirty, index, setDelete }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { gameId }: any = useParams();

  const canUpdate = !configuration?.gamelibManaged ?? true;

  const methods = useForm<ConfigWithPK>({
    defaultValues: configuration
      ? {
          ...configuration,
          pkey: configuration.pkey,
          aspectRatio: parseInt(configuration.aspectRatio),
          mobileCustomData: configuration.mobileCustomData,
          desktopCustomData: configuration.customParameters,
          externalDesktopGameId: configuration.gameId,
          externalMobileGameId: configuration.mobileExternalGameId,
        }
      : {},
  });

  const {
    setValue,
    handleSubmit,
    formState: { isDirty: formIsDirty },
  } = methods;

  const mutation = {
    onSuccess: (values: any, output: any) => {
      queryClient.invalidateQueries(getGetGameV2QueryKey(gameId));
      setIsDirty(false);
      methods.reset({ ...values, ...output.data }, { touched: false });
      enqueueSnackbar(t('succes'), { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar(t('error'), { variant: 'error' });
    },
  };
  const { mutate: addConfiguration, isLoading: addIsLoading } = useAddGameConfiguration({ mutation });
  const { mutate: updateConfiguration, isLoading: updateIsLoading } = useUpdateGameConfiguration({ mutation });

  const onSubmit = (data: ConfigWithPK) => {
    const { pkey, ...rest } = data;
    if (!pkey)
      addConfiguration({
        gamePKey: gameId,
        data: rest,
      });
    else
      updateConfiguration({
        gamePKey: gameId,
        gameConfigurationId: pkey,
        data: rest,
      });
  };

  useEffect(() => {
    setIsDirty(formIsDirty);
  }, [formIsDirty, setIsDirty]);

  const onEditJson = (type: keyof ConfigWithPK, data: InteractionProps) => {
    setIsDirty(true);
    setValue(type, JSON.stringify(data.updated_src));
  };

  return (
    <Card sx={{ mt: 4, px: 2 }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box display="flex" gap={2} alignItems="center" marginTop={3}>
              <Typography variant="h5">
                {configuration?.pkey ? configuration?.name : `${t('configuration')} ${(index ?? 0) + 1}`}
              </Typography>
              {!configuration?.pkey && (
                <IconButton onClick={() => setDelete({ open: true, index: index ?? 0 })}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
            <Stack spacing={1} direction="column">
              <Input sx={{ display: 'none' }} name={`pkey`} />
              <Checkbox label={t('hasDemo')} name={`hasDemo`} disabled={!canUpdate} />
              <Input
                label={t('name')}
                required
                name={`name`}
                rules={{ required: t('fieldIsRequired') }}
                disabled={!canUpdate}
              />
              <Input
                label={t('externalDesktopGameId')}
                required
                name={`externalDesktopGameId`}
                rules={{ required: t('fieldIsRequired') }}
                disabled={!canUpdate}
              />
              <Input
                label={t('externalMobileGameId')}
                required
                name={`externalMobileGameId`}
                rules={{ required: t('fieldIsRequired') }}
                disabled={!canUpdate}
              />
              <Input
                label={t('rtp')}
                required
                type="number"
                name={`rtp`}
                rules={{ required: t('fieldIsRequired') }}
                disabled={!canUpdate}
              />
              <Input
                label={t('minBet')}
                required
                type="number"
                name={`minBet`}
                rules={{ required: t('fieldIsRequired') }}
                disabled={!canUpdate}
              />
              <Input
                label={t('maxBet')}
                required
                type="number"
                name={`maxBet`}
                rules={{ required: t('fieldIsRequired') }}
                disabled={!canUpdate}
              />
              <Input
                label={t('maxMultiplier')}
                required
                type="number"
                name={`maxMultiplier`}
                rules={{ required: t('fieldIsRequired') }}
                disabled={!canUpdate}
              />
              <SelectInput
                name={`aspectRatio`}
                label={t('aspectRatio')}
                options={[
                  { label: '4:3', value: 0 },
                  { label: '16:9', value: 1 },
                  { label: '16:10', value: 2 },
                  { label: '1:1', value: 3 },
                  { label: '3:4', value: 4 },
                  { label: '3:5', value: 5 },
                ]}
                disabled={!canUpdate}
              />
              <SelectInput
                label={t('volatility') + ' *'}
                name={`volatility`}
                rules={{ required: t('fieldIsRequired') }}
                options={[
                  { value: 1, label: '1' },
                  { value: 2, label: '2' },
                  { value: 3, label: '3' },
                  { value: 4, label: '4' },
                  { value: 5, label: '5' },
                ]}
                disabled={!canUpdate}
              />
              <Input
                label={t('paylines')}
                required
                rules={{ required: t('fieldIsRequired') }}
                type="number"
                name={`paylines`}
                disabled={!canUpdate}
              />

              {t('desktopCustomData')}
              <Input name={`desktopCustomData`} sx={{ display: 'none' }} disabled={!canUpdate} />
              <ReactJson
                enableClipboard={false}
                onAdd={canUpdate ? data => onEditJson('desktopCustomData', data) : () => false}
                onEdit={canUpdate ? data => onEditJson('desktopCustomData', data) : () => false}
                onDelete={canUpdate ? data => onEditJson('desktopCustomData', data) : () => false}
                style={{ padding: '10px', borderRadius: '10px' }}
                theme="railscasts"
                src={configuration?.customParameters ? JSON.parse(configuration?.customParameters) : {}}
              />
              {t('mobileCustomData')}
              <Input name={`mobileCustomData`} sx={{ display: 'none' }} disabled={!canUpdate} />
              <ReactJson
                enableClipboard={false}
                onAdd={canUpdate ? data => onEditJson('mobileCustomData', data) : () => false}
                onEdit={canUpdate ? data => onEditJson('mobileCustomData', data) : () => false}
                onDelete={canUpdate ? data => onEditJson('mobileCustomData', data) : () => false}
                style={{ padding: '10px', borderRadius: '10px' }}
                theme="railscasts"
                src={configuration?.mobileCustomData ? JSON.parse(configuration?.mobileCustomData) : {}}
              />
            </Stack>
          </Box>
          <Box display="flex" justifyContent="flex-end" marginTop={2}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={addIsLoading || updateIsLoading}
              disabled={!isDirty || !canUpdate}
            >
              {t('save')}
            </LoadingButton>
          </Box>
        </form>
      </FormProvider>
    </Card>
  );
};

export default memo(GameConfigurationForm);
