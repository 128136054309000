import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';

import { TabPanel } from '@greenisland-common/components/atoms';

import AgentTasksCategoryView from './AgentTasksCategoryView';
import AgentTasksGlobalView from './AgentTasksGlobalView';

const UserTasks = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <>
      <Box mt={1}>
        <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)}>
          <Tab label={t('tasks.agentTasks.myView')} id="tab-1" aria-controls="tab-panel-1" />
          <Tab label={t('tasks.agentTasks.globalView')} id="tab-2" aria-controls="tab-panel-2" />
        </Tabs>
      </Box>
      <TabPanel value={activeTab} index={0}>
        <AgentTasksCategoryView />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <AgentTasksGlobalView />
      </TabPanel>
    </>
  );
};

export default memo(UserTasks);
