/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * Result of the responsible gaming test response process:
* `none` - No information to determine gambling behavior * `problemGambler` - Indicates problematic gambling behavior. * `nonProblemGambler` - Indicates no signs of problematic gambling behavior.

 */
export type EResponsibleGamingTestResponseResultSchema =
  (typeof EResponsibleGamingTestResponseResultSchema)[keyof typeof EResponsibleGamingTestResponseResultSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EResponsibleGamingTestResponseResultSchema = {
  none: 'none',
  problemGambler: 'problemGambler',
  nonProblemGambler: 'nonProblemGambler',
} as const;
