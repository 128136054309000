import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import {
  Base64FileSchema,
  EVerificationStatusSchema,
  getGetUserVerificationsQueryKey,
  useArchiveUserWalletVerification,
  useDeleteUserVerificationsWalletDocument,
  useGetDocumentById,
  UserDocumentMetadataSchema,
  useUnarchiveUserWalletVerification,
  useUpdateUserVerificationsWalletVerificationStatus,
  useUploadUserVerificationsWalletDocument,
  VerificationStatusArgumentsSchema,
  VerificationsWalletSchema,
  VerificationsWalletsSchema,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { LoadingButton } from '@mui/lab';
import { Box, Button, capitalize, Card, CardContent, CardHeader, CircularProgress, Typography } from '@mui/material';
import { GridColumns, GridRowParams, GridRowsProp } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { CheckboxIcon, FontAwesomeIcon, NoData, StyledDataGrid } from '@greenisland-common/components/atoms';
import FileUploadDialog from '@greenisland-common/components/molecules/FileUpload/FileUploadDialog';
import ImageDialog from '@greenisland-common/components/molecules/ImageDialog';
import PdfDialog from '@greenisland-common/components/molecules/PdfDialog';

import { formatBase64String } from '@greenisland-common/helpers';

import { getStatusColorClass } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import DeclineVerificationDialog from './DeclineVerificationDialog';

interface Props {
  wallets: VerificationsWalletsSchema;
}

type VerifcationDialog = {
  open: boolean;
  wallet: VerificationsWalletSchema | undefined;
};

const Wallets = ({ wallets }: Props) => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const qc = useQueryClient();
  const canUpdateVerificationStatus = usePermission(OnlineCasinoPermissions.updateUserVerificationsBankAccountStatus);
  const canArchive = usePermission(OnlineCasinoPermissions.archiveUserWalletVerification);
  const canUnarchive = usePermission(OnlineCasinoPermissions.unarchiveUserWalletVerification);

  const [documentMetadata, setDocumentMetadata] = useState<UserDocumentMetadataSchema | undefined>(undefined);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [file, setFile] = useState<Base64FileSchema | string | null>();

  const {
    data: _,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useGetDocumentById(documentMetadata?.documentId as number, {
    query: { enabled: false },
  });

  const mutationObj = {
    mutation: {
      onSuccess: async () => {
        qc.invalidateQueries(getGetUserVerificationsQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  };

  const { isLoading: isArchivingLoading, mutate: archiveMutation } = useArchiveUserWalletVerification({
    mutation: {
      onSuccess: async () => {
        await qc.invalidateQueries(getGetUserVerificationsQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const { isLoading: isUnArchivingLoading, mutate: unArchiveMutation } = useUnarchiveUserWalletVerification({
    mutation: {
      onSuccess: async () => {
        await qc.invalidateQueries(getGetUserVerificationsQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const updateWalletVerificationStatusMutation = useUpdateUserVerificationsWalletVerificationStatus(mutationObj);
  const updateVerificationStatus = async (values: VerificationStatusArgumentsSchema, walletId: number) =>
    updateWalletVerificationStatusMutation.mutate({ userId, walletId, data: values });

  const [declineVerificationDialog, setDeclineVerificationDialog] = useState<VerifcationDialog>({
    open: false,
    wallet: undefined,
  });

  const { mutate: uploadWalletDocument } = useUploadUserVerificationsWalletDocument(mutationObj);
  const { mutate: deleteWalletDocument } = useDeleteUserVerificationsWalletDocument(mutationObj);

  const previewMetadataImageHandler = useCallback(async () => {
    const response = await refetch();
    if (response?.data?.document) {
      const documentData = response.data.document;

      setFile({
        ...documentData,
        content: formatBase64String(documentData.content, documentData.mimeType),
      });
      setOpenImagePreview(true);
      setDocumentMetadata(undefined);
    }
  }, [refetch]);

  useEffect(() => {
    if (documentMetadata) {
      previewMetadataImageHandler();
    }
  }, [documentMetadata, previewMetadataImageHandler]);

  const rows: GridRowsProp = useMemo(() => {
    return wallets.map(item => ({ ...item, key: item.walletId, id: item.walletId }));
  }, [wallets]);

  const headers: GridColumns = [
    { headerName: capitalize('payment method'), field: 'paymentMethod', flex: 1, type: 'string' },
    { headerName: 'ID', field: 'walletId', flex: 1, type: 'string' },
    {
      headerName: capitalize('email'),
      field: 'walletEmail',
      flex: 2.5,
      renderCell: ({ row }) => (
        <span>
          {row.walletEmail}
          {row?.walletDocumentMetadata ? (
            <LoadingButton
              color="secondary"
              variant="text"
              onClick={() => setDocumentMetadata(row.walletDocumentMetadata)}
              loading={isLoading || isFetching}
              sx={{ ml: 1 }}
              disableRipple
            >
              <FontAwesomeIcon sx={{ width: 15 }} icon={faEye} />
            </LoadingButton>
          ) : null}
        </span>
      ),
    },
    {
      headerName: capitalize(t('verificationStatus.title')),
      field: 'verificationStatus',
      renderCell: ({ value }) => (
        <Typography variant="body2" sx={getStatusColorClass(value)}>
          {t(value)}
        </Typography>
      ),
      minWidth: 160,
      flex: 1,
    },
    {
      headerName: capitalize(t('active')),
      field: 'archived',
      renderCell: ({ value }) => <CheckboxIcon checked={!value} />,
      align: 'center',
      headerAlign: 'center',
      flex: 0.5,
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'left',
      getActions: (params: GridRowParams<VerificationsWalletSchema>) => [
        <Box key="update" sx={{ ml: 1, display: 'inline' }}>
          <Button
            disabled={
              !canUpdateVerificationStatus || params.row.verificationStatus !== EVerificationStatusSchema.Unverified
            }
            sx={{ mr: 1 }}
            size="small"
            variant="outlined"
            onClick={() =>
              updateVerificationStatus(
                { verificationStatus: EVerificationStatusSchema.NeedsVerification },
                params.row.walletPKey ?? 0
              )
            }
          >
            {updateWalletVerificationStatusMutation.isLoading ? <CircularProgress size={20} /> : t('needsVerification')}
          </Button>
          <>
            <Button
              disabled={
                !canUpdateVerificationStatus ||
                params.row.verificationStatus !== EVerificationStatusSchema.WaitingForApproval
              }
              sx={{ mr: 1 }}
              size="small"
              color="primary"
              variant="contained"
              onClick={() =>
                updateVerificationStatus(
                  { verificationStatus: EVerificationStatusSchema.Verified },
                  params.row.walletPKey ?? 0
                )
              }
            >
              {updateWalletVerificationStatusMutation.isLoading ? <CircularProgress size={20} /> : t('verify')}
            </Button>
            <Button
              disabled={
                !canUpdateVerificationStatus ||
                params.row.verificationStatus !== EVerificationStatusSchema.WaitingForApproval
              }
              sx={{ mr: 1 }}
              size="small"
              variant="outlined"
              color="error"
              onClick={() => setDeclineVerificationDialog({ wallet: params.row, open: true })}
            >
              {t('decline')}
            </Button>
          </>
          {!canArchive && !canUnarchive && <Typography>{t('wallet.permissions.noArchivation')}</Typography>}
          {canArchive && !params.row.archived && (
            <Button
              sx={{ width: 100 }}
              disabled={!canArchive}
              size="small"
              color="error"
              variant="outlined"
              onClick={() => archiveMutation({ userId: parseInt(userId), walletId: params.row.walletPKey ?? 0 })}
            >
              {isArchivingLoading ? <CircularProgress size={20} /> : t('archive')}
            </Button>
          )}
          {params.row.archived && (
            <Button
              sx={{ width: 100 }}
              disabled={!canUnarchive}
              size="small"
              variant="outlined"
              onClick={() => unArchiveMutation({ userId: parseInt(userId), walletId: params.row.walletPKey ?? 0 })}
            >
              {isUnArchivingLoading ? <CircularProgress size={20} /> : t('activate')}
            </Button>
          )}
        </Box>,
        <>
          <FileUploadDialog
            key="upload"
            hidePreviewButton={true}
            id={params.row.walletPKey?.toString() ?? ''}
            requirements={{ size: 5, mimeTypes: ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'] }}
            saveFile={file =>
              uploadWalletDocument({ userId: parseInt(userId), walletId: params.row.walletPKey ?? 0, data: file })
            }
            metadata={params?.row?.walletDocumentMetadata}
          />
          {params?.row?.walletDocumentMetadata && (
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => deleteWalletDocument({ userId: parseInt(userId), walletId: params.row.walletPKey ?? 0 })}
            >
              {t('remove')}
            </Button>
          )}
        </>,
      ],
      headerAlign: 'center',
      flex: 5,
    },
  ];

  if (isError) {
    enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
  }

  return (
    <>
      <Card sx={{ mt: 2, pl: 0, pr: 0 }}>
        <CardHeader title={t('wallets')} />
        <CardContent sx={{ pl: 0, pr: 0 }}>
          {rows.length > 0 ? (
            <StyledDataGrid hideFooter autoHeight density="compact" columns={headers} rows={rows} />
          ) : (
            <Box sx={{ pl: 2 }}>
              <NoData />
            </Box>
          )}
          <DeclineVerificationDialog
            open={declineVerificationDialog.open}
            setOpen={() => setDeclineVerificationDialog({ ...declineVerificationDialog, open: false })}
            action={values => updateVerificationStatus(values, declineVerificationDialog?.wallet?.walletPKey ?? 0)}
            wallet={declineVerificationDialog.wallet}
          />
        </CardContent>
      </Card>
      {openImagePreview && file && (
        <ImageDialog
          openImageDialog={openImagePreview}
          setOpenImageDialog={setOpenImagePreview}
          image={typeof file === 'string' ? file : file.content}
        />
      )}
      {openImagePreview && file && (file as Base64FileSchema).mimeType !== 'application/pdf' && (
        <ImageDialog
          openImageDialog={openImagePreview}
          setOpenImageDialog={setOpenImagePreview}
          image={(file as Base64FileSchema).content}
        />
      )}
      {openImagePreview && file && (file as Base64FileSchema).mimeType === 'application/pdf' && (
        <PdfDialog
          openPdfDialog={openImagePreview}
          setOpenPdfDialog={setOpenImagePreview}
          image={(file as Base64FileSchema).content}
        />
      )}
    </>
  );
};

export default memo(Wallets);
