/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  DefaultResponse,
  ErrorSchema,
  GameArgumentsV2Schema,
  GameConfigurationSchema,
  GameResultsV2Schema,
  GameSchema,
  GamesControllerBadRequestResponse,
  GetGamesByQueryFiltersV2Params,
  NotFoundResponse,
  UnauthorizedResponse,
  UpdateGameConfigurationSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Retrieves a list of games, based on the query filters.
 */
export const useGetGamesByQueryFiltersV2Hook = () => {
  const getGamesByQueryFiltersV2 = useCustomInstance<GameResultsV2Schema>();

  return (params?: GetGamesByQueryFiltersV2Params, signal?: AbortSignal) => {
    return getGamesByQueryFiltersV2({ url: `/games`, method: 'get', params, signal });
  };
};

export const getGetGamesByQueryFiltersV2QueryKey = (params?: GetGamesByQueryFiltersV2Params) =>
  [`/games`, ...(params ? [params] : [])] as const;

export const useGetGamesByQueryFiltersV2QueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGamesByQueryFiltersV2Hook>>>,
  TError = ErrorSchema
>(
  params?: GetGamesByQueryFiltersV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGamesByQueryFiltersV2Hook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGamesByQueryFiltersV2Hook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGamesByQueryFiltersV2QueryKey(params);

  const getGamesByQueryFiltersV2 = useGetGamesByQueryFiltersV2Hook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGamesByQueryFiltersV2Hook>>>> = ({
    signal,
  }) => getGamesByQueryFiltersV2(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetGamesByQueryFiltersV2QueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGamesByQueryFiltersV2Hook>>>
>;
export type GetGamesByQueryFiltersV2QueryError = ErrorSchema;

/**
 * @summary Retrieves a list of games, based on the query filters.
 */
export const useGetGamesByQueryFiltersV2 = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGamesByQueryFiltersV2Hook>>>,
  TError = ErrorSchema
>(
  params?: GetGamesByQueryFiltersV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGamesByQueryFiltersV2Hook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGamesByQueryFiltersV2QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Add game
 */
export const useAddGameV2Hook = () => {
  const addGameV2 = useCustomInstance<GameSchema>();

  return (gameArgumentsV2Schema: GameArgumentsV2Schema) => {
    return addGameV2({
      url: `/games`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: gameArgumentsV2Schema,
    });
  };
};

export const useAddGameV2MutationOptions = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameV2Hook>>>,
    TError,
    { data: GameArgumentsV2Schema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddGameV2Hook>>>,
  TError,
  { data: GameArgumentsV2Schema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addGameV2 = useAddGameV2Hook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddGameV2Hook>>>,
    { data: GameArgumentsV2Schema }
  > = props => {
    const { data } = props ?? {};

    return addGameV2(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddGameV2MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddGameV2Hook>>>>;
export type AddGameV2MutationBody = GameArgumentsV2Schema;
export type AddGameV2MutationError =
  | GamesControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Add game
 */
export const useAddGameV2 = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameV2Hook>>>,
    TError,
    { data: GameArgumentsV2Schema },
    TContext
  >;
}) => {
  const mutationOptions = useAddGameV2MutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get game
 */
export const useGetGameV2Hook = () => {
  const getGameV2 = useCustomInstance<GameSchema>();

  return (gamePKey: number, signal?: AbortSignal) => {
    return getGameV2({ url: `/games/${gamePKey}`, method: 'get', signal });
  };
};

export const getGetGameV2QueryKey = (gamePKey: number) => [`/games/${gamePKey}`] as const;

export const useGetGameV2QueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameV2Hook>>>,
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  gamePKey: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameV2Hook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameV2Hook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameV2QueryKey(gamePKey);

  const getGameV2 = useGetGameV2Hook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameV2Hook>>>> = ({ signal }) =>
    getGameV2(gamePKey, signal);

  return { queryKey, queryFn, enabled: !!gamePKey, ...queryOptions };
};

export type GetGameV2QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetGameV2Hook>>>>;
export type GetGameV2QueryError =
  | GamesControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get game
 */
export const useGetGameV2 = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameV2Hook>>>,
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  gamePKey: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameV2Hook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameV2QueryOptions(gamePKey, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update game
 */
export const useUpdateGameV2Hook = () => {
  const updateGameV2 = useCustomInstance<GameSchema>();

  return (gamePKey: number, gameArgumentsV2Schema: GameArgumentsV2Schema) => {
    return updateGameV2({
      url: `/games/${gamePKey}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: gameArgumentsV2Schema,
    });
  };
};

export const useUpdateGameV2MutationOptions = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameV2Hook>>>,
    TError,
    { gamePKey: number; data: GameArgumentsV2Schema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateGameV2Hook>>>,
  TError,
  { gamePKey: number; data: GameArgumentsV2Schema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateGameV2 = useUpdateGameV2Hook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameV2Hook>>>,
    { gamePKey: number; data: GameArgumentsV2Schema }
  > = props => {
    const { gamePKey, data } = props ?? {};

    return updateGameV2(gamePKey, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGameV2MutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateGameV2Hook>>>>;
export type UpdateGameV2MutationBody = GameArgumentsV2Schema;
export type UpdateGameV2MutationError =
  | GamesControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Update game
 */
export const useUpdateGameV2 = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameV2Hook>>>,
    TError,
    { gamePKey: number; data: GameArgumentsV2Schema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateGameV2MutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Add game configuration
 */
export const useAddGameConfigurationHook = () => {
  const addGameConfiguration = useCustomInstance<GameConfigurationSchema>();

  return (gamePKey: number, updateGameConfigurationSchema: UpdateGameConfigurationSchema) => {
    return addGameConfiguration({
      url: `/games/${gamePKey}/configurations`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateGameConfigurationSchema,
    });
  };
};

export const useAddGameConfigurationMutationOptions = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameConfigurationHook>>>,
    TError,
    { gamePKey: number; data: UpdateGameConfigurationSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddGameConfigurationHook>>>,
  TError,
  { gamePKey: number; data: UpdateGameConfigurationSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addGameConfiguration = useAddGameConfigurationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddGameConfigurationHook>>>,
    { gamePKey: number; data: UpdateGameConfigurationSchema }
  > = props => {
    const { gamePKey, data } = props ?? {};

    return addGameConfiguration(gamePKey, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddGameConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddGameConfigurationHook>>>
>;
export type AddGameConfigurationMutationBody = UpdateGameConfigurationSchema;
export type AddGameConfigurationMutationError =
  | GamesControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Add game configuration
 */
export const useAddGameConfiguration = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameConfigurationHook>>>,
    TError,
    { gamePKey: number; data: UpdateGameConfigurationSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddGameConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update game configuration
 */
export const useUpdateGameConfigurationHook = () => {
  const updateGameConfiguration = useCustomInstance<GameConfigurationSchema>();

  return (
    gamePKey: number,
    gameConfigurationId: number,
    updateGameConfigurationSchema: UpdateGameConfigurationSchema
  ) => {
    return updateGameConfiguration({
      url: `/games/${gamePKey}/configurations/${gameConfigurationId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateGameConfigurationSchema,
    });
  };
};

export const useUpdateGameConfigurationMutationOptions = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameConfigurationHook>>>,
    TError,
    { gamePKey: number; gameConfigurationId: number; data: UpdateGameConfigurationSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateGameConfigurationHook>>>,
  TError,
  { gamePKey: number; gameConfigurationId: number; data: UpdateGameConfigurationSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateGameConfiguration = useUpdateGameConfigurationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameConfigurationHook>>>,
    { gamePKey: number; gameConfigurationId: number; data: UpdateGameConfigurationSchema }
  > = props => {
    const { gamePKey, gameConfigurationId, data } = props ?? {};

    return updateGameConfiguration(gamePKey, gameConfigurationId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGameConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateGameConfigurationHook>>>
>;
export type UpdateGameConfigurationMutationBody = UpdateGameConfigurationSchema;
export type UpdateGameConfigurationMutationError =
  | GamesControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Update game configuration
 */
export const useUpdateGameConfiguration = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameConfigurationHook>>>,
    TError,
    { gamePKey: number; gameConfigurationId: number; data: UpdateGameConfigurationSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateGameConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Archive game configuration. Note - you CANNOT archive a game configuration that is GameLibManaged nor archive a game configuration that is currently active. Calling this method on an already archived game configuration will have no effect.
 */
export const useArchiveGameConfigurationHook = () => {
  const archiveGameConfiguration = useCustomInstance<GameConfigurationSchema>();

  return (gamePKey: number, gameConfigurationId: number) => {
    return archiveGameConfiguration({
      url: `/games/${gamePKey}/configurations/${gameConfigurationId}/archive`,
      method: 'put',
    });
  };
};

export const useArchiveGameConfigurationMutationOptions = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveGameConfigurationHook>>>,
    TError,
    { gamePKey: number; gameConfigurationId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useArchiveGameConfigurationHook>>>,
  TError,
  { gamePKey: number; gameConfigurationId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const archiveGameConfiguration = useArchiveGameConfigurationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useArchiveGameConfigurationHook>>>,
    { gamePKey: number; gameConfigurationId: number }
  > = props => {
    const { gamePKey, gameConfigurationId } = props ?? {};

    return archiveGameConfiguration(gamePKey, gameConfigurationId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ArchiveGameConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useArchiveGameConfigurationHook>>>
>;

export type ArchiveGameConfigurationMutationError =
  | GamesControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Archive game configuration. Note - you CANNOT archive a game configuration that is GameLibManaged nor archive a game configuration that is currently active. Calling this method on an already archived game configuration will have no effect.
 */
export const useArchiveGameConfiguration = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveGameConfigurationHook>>>,
    TError,
    { gamePKey: number; gameConfigurationId: number },
    TContext
  >;
}) => {
  const mutationOptions = useArchiveGameConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Unarchive game configuration. Calling this method on an unarchived game configuration will have no effect.
 */
export const useUnarchiveGameConfigurationHook = () => {
  const unarchiveGameConfiguration = useCustomInstance<GameConfigurationSchema>();

  return (gamePKey: number, gameConfigurationId: number) => {
    return unarchiveGameConfiguration({
      url: `/games/${gamePKey}/configurations/${gameConfigurationId}/unarchive`,
      method: 'put',
    });
  };
};

export const useUnarchiveGameConfigurationMutationOptions = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUnarchiveGameConfigurationHook>>>,
    TError,
    { gamePKey: number; gameConfigurationId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUnarchiveGameConfigurationHook>>>,
  TError,
  { gamePKey: number; gameConfigurationId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const unarchiveGameConfiguration = useUnarchiveGameConfigurationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUnarchiveGameConfigurationHook>>>,
    { gamePKey: number; gameConfigurationId: number }
  > = props => {
    const { gamePKey, gameConfigurationId } = props ?? {};

    return unarchiveGameConfiguration(gamePKey, gameConfigurationId);
  };

  return { mutationFn, ...mutationOptions };
};

export type UnarchiveGameConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUnarchiveGameConfigurationHook>>>
>;

export type UnarchiveGameConfigurationMutationError =
  | GamesControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Unarchive game configuration. Calling this method on an unarchived game configuration will have no effect.
 */
export const useUnarchiveGameConfiguration = <
  TError = GamesControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUnarchiveGameConfigurationHook>>>,
    TError,
    { gamePKey: number; gameConfigurationId: number },
    TContext
  >;
}) => {
  const mutationOptions = useUnarchiveGameConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
