import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { getGetUserNotesQueryKey, useDeleteUserNote } from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { format, fromUnixTime } from 'date-fns';
import { useSnackbar } from 'notistack';

interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
  userID: string;
  note: {
    noteId: string;
    message: string;
    author: string;
    timestamp: number;
  };
}

const DeleteUserNoteDialog = ({ userID, open, setOpen, note }: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: deleteUserNote, isLoading: isDeleteUserNoteLoading } = useDeleteUserNote({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetUserNotesQueryKey(userID));
        setOpen(false);
      },
      onError: error => {
        enqueueSnackbar(error?.message || t('somethingWentWrong'), { variant: 'error' });
      },
    },
  });

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t('deleteUserNote')}</DialogTitle>
      <DialogContent>
        <Box>
          {`${t('deleteUserNote')} ${t('postedBy')} ${note.author} ${t('on')} ${format(
            fromUnixTime(note.timestamp),
            'dd/MM/yyyy HH:mm'
          )}?`}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={() => setOpen(false)} size="small">
          {t('no')}
        </Button>
        <LoadingButton
          loading={isDeleteUserNoteLoading}
          onClick={() => deleteUserNote({ userId: userID, noteId: note.noteId })}
        >
          {t('yes')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserNoteDialog;
