import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useGetUserLoginSession } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { capitalize } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

import { transformUnixDateV2 } from '../../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../../app/hooks';

const Logins = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const canReadAccountTransactions = usePermission(OnlineCasinoPermissions.getUserLoginSession);

  const { data: logins, isLoading: fetching } = useGetUserLoginSession(userId, {
    query: { enabled: canReadAccountTransactions && !!userId },
  });

  const columns: GridColDef[] = useMemo(() => {
    if (!logins?.length) return [];
    return Object.keys(logins[0]).map(key => {
      switch (key) {
        case 'startDateTime':
        case 'endDateTime':
          return {
            ...transformUnixDateV2(key, t),
            flex: 1.2,
          };
        default:
          return {
            field: key,
            headerName: capitalize(t(key)),
            flex: 1,
          };
      }
    });
  }, [logins, t]);

  const rows = useMemo(() => {
    return logins?.sort((a, b) => b.startDateTime - a.startDateTime) || [];
  }, [logins]);

  return (
    <StyledDataGrid
      columns={columns}
      loading={fetching}
      rows={rows}
      autoHeight
      getRowId={row => row.id || Math.random().toString()}
      pagination
      pageSize={5}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
    />
  );
};

export default Logins;
