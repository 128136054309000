import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { JackpotConfiguration } from '@greenisland-store/gameServer';
import { Box, Typography } from '@mui/material';
import { getDateTimeFromUnix } from 'src/app/helpers/transformFunctions';

import { formatCurrency } from '@greenisland-common/helpers';

interface Props {
  jackpotConfiguration: JackpotConfiguration;
}

const JackpotConfigurationDetail = ({ jackpotConfiguration }: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ paddingY: 1, paddingLeft: 2 }}>
      <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
        {t('content.gameserver.jackpotInstance.contributingGames')}
      </Typography>
      <Typography sx={{ textTransform: 'capitalize' }} variant="body2">
        {jackpotConfiguration?.contributingGames?.map(x => ' ' + x.gameReference).toString()}
      </Typography>
      <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
        {t('content.gameserver.jackpotInstance.gameContribution')}
      </Typography>
      <Typography sx={{ textTransform: 'capitalize' }} variant="body2">
        {jackpotConfiguration.gameContribution * 100 + '%'}
      </Typography>
    </Box>
  );
};

export default memo(JackpotConfigurationDetail);
