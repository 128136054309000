import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { ComplaintTicketSchema, EComplaintResponseTypeSchema, useGetAllComplaintTickets } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import ComplaintStatusField from 'src/pages/OnlineCasino/Tasks/Complaints/ComplaintsStatusField';

import {
  DataGridContainer,
  ErrorCard,
  FontAwesomeIcon,
  Link,
  NoData,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../app/hooks';

interface Props {
  userId: number;
}

const Complaints = ({ userId }: Props) => {
  const { t } = useTranslation();
  const canReadComplaints = usePermission(OnlineCasinoPermissions.getAllComplaintTickets);
  const canViewComplaint = usePermission(OnlineCasinoPermissions.getComplaintTicket);
  const navigate = useNavigate();

  const {
    data: complaints,
    isLoading,
    isError,
  } = useGetAllComplaintTickets(
    { complaintUserId: userId, numberOfItems: 5, pageNumber: 0 },
    { query: { enabled: canReadComplaints && !!userId } }
  );

  const headers = useMemo<GridColumns<ComplaintTicketSchema>>(
    () => [
      {
        headerName: t('tasks.complaints.title'),
        field: 'title',
        sortable: false,
        flex: 1,
        minWidth: 120,
      },
      {
        field: 'complaintEntries',
        headerName: t('tasks.complaints.replies'),
        flex: 0.5,
        renderCell: params =>
          params.row.complaintEntries.filter(c => c.responseType === EComplaintResponseTypeSchema.FollowUp).length,
        sortable: false,
        minWidth: 60,
      },
      {
        field: 'status',
        headerName: t('tasks.complaints.status'),
        renderCell: params => <ComplaintStatusField value={params.row.status} />,
        flex: 0.5,
        sortable: false,
        minWidth: 80,
      },
      ...(canViewComplaint
        ? [
            {
              field: 'actions',
              type: 'actions',
              getActions: (params: GridRowParams<ComplaintTicketSchema>) => [
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faEye} />}
                  label="View"
                  onClick={() => navigate(`../../tasks/complaints/${params.row.id}`)}
                  key="view"
                />,
              ],
              flex: 0.5,
            },
          ]
        : []),
    ],
    [canViewComplaint, navigate, t]
  );

  if (!canReadComplaints) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={95} />;
  if (isError) return <ErrorCard sx={{ height: 95 }}>{t('tasks.complaints.loadingError')}</ErrorCard>;

  return (
    <>
      <Card>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CardHeader title={t('tasks.complaints.complaints')} />
          <Button
            sx={{ mr: 1 }}
            variant="outlined"
            color="primary"
            size="small"
            component={Link}
            to={`../../tasks/complaints?user=${userId}`}
          >
            {t('seeMore')}
          </Button>
        </Box>
        <CardContent>
          {complaints?.data && complaints?.data.length > 0 ? (
            <DataGridContainer>
              <StyledDataGrid
                autoHeight
                density="compact"
                columns={headers}
                rows={complaints?.data}
                hideFooter
                disableDensitySelector
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                disableChildrenSorting
                disableChildrenFiltering
                disableMultipleColumnsSorting
                disableColumnResize
                disableColumnReorder
                isRowSelectable={() => false}
              />
            </DataGridContainer>
          ) : (
            <NoData />
          )}
        </CardContent>
      </Card>
    </>
  );
};
export default memo(Complaints);
