import React from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

import { dateTimeSecondsMask } from '../../../../app/constants/defaultvalues';

interface Props {
  name: string;
  label?: string;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  disabled?: boolean;
  defaultValue?: Date | null;
}

const DateTimeSecondsField = ({ name, defaultValue, disabled = false, label, rules }: Props) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      rules={rules}
      name={name}
      defaultValue={defaultValue || null}
      control={control}
      render={({ value, ...renderProps }) => (
        <DateTimePicker
          value={value ?? ''}
          label={label}
          {...renderProps}
          renderInput={params => (
            <TextField fullWidth {...params} error={Boolean(errors?.[name])} helperText={errors[name]?.message} />
          )}
          disabled={disabled}
          inputFormat="dd/MM/yyyy HH:mm:ss"
          mask={dateTimeSecondsMask}
          ampm={false}
        />
      )}
    />
  );
};

export default DateTimeSecondsField;
