import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Base64FileSchema, EMIMETypeSchema } from '@greenisland-api';
import { Box, Button, styled, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { extractBase64String, formatBase64String } from '@greenisland-common/helpers/file';

import ImageDialog from '../ImageDialog/ImageDialog';

const FilePreview = styled('img')({
  width: '300px',
  paddingBottom: '5px',
  '&:hover': {
    cursor: 'pointer',
  },
});

const Link = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: 10,
  width: 48,
  height: 48,
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '50%',
}));

export type FileUploadProps = {
  id: string;
  /**
   * Required when defaultFile is a string
   */
  type?: 'video' | 'image' | 'pdf';
  defaultFile?: Base64FileSchema | string;
  saveFile?: (file: Base64FileSchema) => any;
  deleteFile?: () => any;
  /**
   * Either set onChange or saveFile
   */
  onChange?: (file: Base64FileSchema) => void;
  buttonTitle?: string;
  error?: boolean;
  requirements?: {
    dimensions?: {
      width?: number;
      height?: number;
      maxHeight?: number;
      maxWidth?: number;
    };
    /**
     * FileSize in mb;
     */
    size?: number;
    mimeTypes?: EMIMETypeSchema[];
  };
  /**
   * Defines if the requirements info should render
   * @default true
   */
  requirementsInfo?: boolean;
  shouldBeAbleToSave?: boolean;
};

const defaultMaxSize = 15;

export const FileUpload = ({
  type,
  defaultFile,
  saveFile,
  deleteFile,
  onChange,
  buttonTitle = 'uploadDocument',
  error,
  requirements,
  requirementsInfo = true,
  shouldBeAbleToSave = true,
  id,
}: FileUploadProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [shouldSave, setShouldSave] = useState(false);
  const [errorLoadingFile, setErrorLoadingFile] = useState(false);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [file, setFile] = useState<Base64FileSchema | string | null>();
  const fileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let tempFile = file;
    let tempShouldSave = shouldSave;
    let tempLoading = isLoading;
    if (defaultFile && typeof defaultFile !== 'string')
      tempFile = { ...defaultFile, content: formatBase64String(defaultFile.content, defaultFile.mimeType) };
    if (defaultFile && typeof defaultFile === 'string') tempFile = defaultFile;
    if (defaultFile && saveFile) {
      tempShouldSave = false;
    }
    if (!defaultFile) {
      tempFile = null;
      tempShouldSave = false;
      tempLoading = false;
    }
    setFile(tempFile);
    setShouldSave(tempShouldSave);
    setIsLoading(tempLoading);
  }, [defaultFile]);

  const handleSucces = (file: Base64FileSchema) => {
    if (onChange) {
      onChange(file);
    } else {
      setFile(file);
    }

    setErrorLoadingFile(false);
    setShouldSave(true);
  };

  const validateFile = (file: File) => {
    const maxSize = requirements?.size || defaultMaxSize;
    const mimeType = file.type as EMIMETypeSchema;
    if (file.size / 1024 / 1024 > maxSize) return 'fileSizeExceeded';
    if (requirements?.mimeTypes && !requirements.mimeTypes.includes(mimeType)) return 'invalidMimeType';
  };

  const validDimension = (value: number, dimension: { required?: number; max?: number }) => {
    if (dimension.required) return value === dimension.required;
    if (dimension.max) return value <= dimension.max;
    return true;
  };

  const handleFileChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const validation = validateFile(file);
      if (typeof validation === 'string') {
        if (validation === 'invalidMimeType') {
          enqueueSnackbar(t(validation, { mimeType: file.type }), { variant: 'error' });
        } else {
          enqueueSnackbar(t(validation), { variant: 'error' });
        }
      } else {
        const fileName = file.name;
        const mimeType = file.type as EMIMETypeSchema;

        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader> | React.ChangeEvent<any>) => {
          const file = { content: extractBase64String(e.target.result), name: fileName, mimeType: mimeType };

          if (requirements?.dimensions) {
            if (
              mimeType === EMIMETypeSchema['image/jpeg'] ||
              mimeType === EMIMETypeSchema['image/jpg'] ||
              mimeType === EMIMETypeSchema['image/png']
            ) {
              const image = new Image();
              image.src = e.target.result;
              image.onload = function () {
                const heightImg = image.height;
                const widthImg = image.width;
                const { height, width, maxHeight, maxWidth } = { ...requirements.dimensions };
                if (
                  !validDimension(heightImg, { required: height, max: maxHeight }) ||
                  !validDimension(widthImg, { required: width, max: maxWidth })
                ) {
                  enqueueSnackbar(t('invalidDimensions'), { variant: 'error' });
                  return false;
                } else {
                  handleSucces(file);
                  return true;
                }
              };
            } else {
              handleSucces(file);
            }
          } else {
            handleSucces(file);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const openFileInput = () => {
    if (fileInput.current) fileInput.current.click();
  };

  const handleSave = async () => {
    if (file && saveFile) {
      setIsLoading(true);
      try {
        const result = await saveFile(file as Base64FileSchema);
        if (result) {
          enqueueSnackbar(t('success'), { variant: 'success' });
        }
      } catch (error) {
        return;
      }
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (file && deleteFile) {
      setIsLoading(true);
      try {
        await deleteFile();
      } finally {
        setIsLoading(false);
      }
    }
  };

  const buildDimensionsString = (dimensions: {
    width?: number;
    height?: number;
    maxHeight?: number;
    maxWidth?: number;
  }) => {
    let string = '';
    Object.entries(dimensions).forEach(dimension => {
      const [key, value] = dimension;
      if (!string) string = `${t(key)}: ${value}px`;
      else string = `${string}, ${t(key)}: ${value}px`;
    });
    return string;
  };

  const formatRequirements = () => {
    const { dimensions, size, mimeTypes } = requirements || {};
    const dimensionsString = dimensions ? `${t('dimensions')}: ${buildDimensionsString(dimensions)}` : '';
    const sizeString = t('maxSize', { size: size ? size : defaultMaxSize });
    const mimeTypeString = mimeTypes && mimeTypes.length > 0 ? t('mimeType', { types: mimeTypes.join(', ') }) : '';
    return (
      <Typography variant="body2" fontSize={12}>
        <b>{t('requirements')}:</b>
        <br />
        {sizeString && (
          <>
            {sizeString}
            <br />
          </>
        )}
        {dimensionsString && (
          <>
            {dimensionsString}
            <br />
          </>
        )}
        {mimeTypeString}
      </Typography>
    );
  };

  const fileTypeRenderer = (file: Base64FileSchema | string) => {
    if (typeof file === 'string') {
      switch (type) {
        case 'pdf':
          return (
            <Box display="flex" alignItems="center" mb={0.5}>
              <Typography>{id}</Typography>
              <Tooltip title={t('download')}>
                <Link href={file} download={id}>
                  <FontAwesomeIcon icon={faDownload} />
                </Link>
              </Tooltip>
            </Box>
          );
        case 'video':
          return (
            <Box
              component="video"
              width={300}
              pb={0.5}
              src={file}
              autoPlay
              loop
              onError={() => setErrorLoadingFile(true)}
            />
          );
        case 'image':
          return (
            <FilePreview
              src={file}
              onClick={() => setOpenImagePreview(true)}
              onError={() => {
                setErrorLoadingFile(true);
              }}
              alt={`uploaded`}
            />
          );
        default:
          return <Typography>{t('noData')}</Typography>;
      }
    } else {
      switch (file.mimeType) {
        case EMIMETypeSchema['application/pdf']:
          return (
            <Box display="flex" alignItems="center" mb={0.5}>
              <Typography>{file.name}</Typography>
              <Tooltip title={t('download')}>
                <Link href={formatBase64String(file.content, file.mimeType)} download={file.name}>
                  <FontAwesomeIcon icon={faDownload} />
                </Link>
              </Tooltip>
            </Box>
          );
        case EMIMETypeSchema['image/jpeg']:
        case EMIMETypeSchema['image/jpg']:
        case EMIMETypeSchema['image/png']:
          return (
            <FilePreview
              src={formatBase64String(file.content, file.mimeType)}
              alt={`uploaded - ${file.name} `}
              onClick={() => setOpenImagePreview(true)}
              onError={() => setErrorLoadingFile(true)}
            />
          );
        case EMIMETypeSchema['video/mp4']:
          return (
            <Box
              component="video"
              width={300}
              pb={0.5}
              src={formatBase64String(file.content, file.mimeType)}
              autoPlay
              loop
              onError={() => setErrorLoadingFile(true)}
            />
          );
        default:
          return <Typography>{t('noData')}</Typography>;
      }
    }
  };

  const getAcceptType = () => {
    let acceptString: string | undefined = undefined;

    requirements?.mimeTypes?.map(type => {
      if (acceptString) {
        acceptString = acceptString + ',' + type;
      } else {
        acceptString = type;
      }
    });

    return acceptString;
  };

  return (
    <Box>
      <Box>
        {file ? (
          errorLoadingFile ? (
            <Typography>{t('errorLoadingFile')}</Typography>
          ) : (
            fileTypeRenderer(file)
          )
        ) : defaultFile ? (
          <Typography>{t('noData')}</Typography>
        ) : null}
      </Box>
      {error && <Typography color="error">{t('required')}</Typography>}
      <Box display="flex" flexWrap="wrap" gap={0.5}>
        <input
          accept={getAcceptType()}
          ref={fileInput}
          style={{ display: 'none' }}
          id={`button-file-${id}`}
          type="file"
          onChange={handleFileChange()}
        />
        <label htmlFor={`button-file-${id}`}>
          <Button color="primary" variant="outlined" size="small" onClick={() => openFileInput()}>
            {t(buttonTitle)}
          </Button>
        </label>
        {file && (
          <Box display="flex" gap={1}>
            {shouldSave && shouldBeAbleToSave && (
              <Button color="primary" variant="contained" size="small" disabled={isLoading} onClick={handleSave}>
                {t('save')}
              </Button>
            )}
            {deleteFile && (
              <Button color="error" variant="text" size="small" disabled={isLoading} onClick={handleDelete}>
                {t('remove')}
              </Button>
            )}
          </Box>
        )}
      </Box>
      {openImagePreview && file && (
        <ImageDialog
          openImageDialog={openImagePreview}
          setOpenImageDialog={setOpenImagePreview}
          image={typeof file === 'string' ? file : file.content}
        />
      )}
      {requirementsInfo && formatRequirements()}
    </Box>
  );
};

export default FileUpload;
