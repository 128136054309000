import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GameSessionsSummaryGamingRevenueComparisonParameter,
  GameSessionsSummaryGroupByKeyParamParameter,
  SortOrderParamParameter,
  useGetBasicInfo,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { usePermission } from 'src/app/hooks';

import GameSessionCard from '../../GameSessionsPage/Components/GameSessionCard';
import GameSummariesCard from '../../GameSessionsPage/Components/GameSummariesCard';
import { GameSummaryCardVariant, SessionGameCardType } from '../../GameSessionsPage/Constants';
import StatisticsCardWrapper, { StatisticsCardSections } from '../Components/BasicInfo/StatisticsCardWrapper';
import Cashflow from '../Components/Cashflow';
import PromotionOverview from '../Components/DepositsAndWithdrawals/PromotionOverview';
import TotalBalanceOverview from '../Components/DepositsAndWithdrawals/TotalBalanceOverview';
import DepositsInProcess from '../Components/DepositsInProcess';
import PopularGamesCard from '../Components/PopularGamesCard';
import RevenueGames from '../Components/Revenue/RevenueGames';
import RevenueSports from '../Components/Revenue/RevenueSports';

export interface DashboardCardProps {
  component: ReactElement;
  key: string;
  width?: DashboardCardWidth;
  height?: DashboardCardHeight;
}

export enum DashboardCardHeight {
  TINY = '110px',
  XSMALL = '130px',
  SMALL = '350px',
  MEDIUM = '450px',
  LARGE = '600px',
}

export enum DashboardCardWidth {
  FULL = '100%',
  MEDIUM = '50%',
}

const GameSessionCardData = [
  {
    key: 'biggestWinners',
    cardVariant: GameSummaryCardVariant.BIGGEST_WINNERS,
    groupByKey: GameSessionsSummaryGroupByKeyParamParameter.User,
    sortByOrder: SortOrderParamParameter.ascending,
    gamingRevenueComparison: GameSessionsSummaryGamingRevenueComparisonParameter.LessThan,
    gamingRevenueComparisonValue: 0,
  },
  {
    key: 'mostUnprofitableGames',
    cardVariant: GameSummaryCardVariant.MOST_UNPROFITABLE_GAMES,
    groupByKey: GameSessionsSummaryGroupByKeyParamParameter.Game,
    sortByOrder: SortOrderParamParameter.ascending,
    gamingRevenueComparison: GameSessionsSummaryGamingRevenueComparisonParameter.LessThan,
    gamingRevenueComparisonValue: 0,
  },
  {
    key: 'biggestLosers',
    cardVariant: GameSummaryCardVariant.BIGGEST_LOSERS,
    groupByKey: GameSessionsSummaryGroupByKeyParamParameter.User,
    sortByOrder: SortOrderParamParameter.descending,
    gamingRevenueComparison: GameSessionsSummaryGamingRevenueComparisonParameter.GreaterThan,
    gamingRevenueComparisonValue: 0,
  },
  {
    key: 'mostProfitableGames',
    cardVariant: GameSummaryCardVariant.MOST_PROFITABLE_GAMES,
    groupByKey: GameSessionsSummaryGroupByKeyParamParameter.Game,
    sortByOrder: SortOrderParamParameter.descending,
    gamingRevenueComparison: GameSessionsSummaryGamingRevenueComparisonParameter.GreaterThan,
    gamingRevenueComparisonValue: 0,
  },
];

const GameSessionCardWrapper = ({ data }: { data: typeof GameSessionCardData }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      width={DashboardCardWidth.FULL}
      gap={1}
      sx={{
        [theme.breakpoints.up('xs')]: {
          flexDirection: 'column',
          height: '100%',
        },
        [theme.breakpoints.up('lg')]: {
          flexDirection: 'row',
          minHeight: DashboardCardHeight.LARGE,
          height: '100%',
        },
        '.MuiPaper-root': {
          [theme.breakpoints.up('xs')]: {
            width: DashboardCardWidth.FULL,
          },
          [theme.breakpoints.up('md')]: {
            '.MuiDataGrid-footerContainer .MuiBox-root': {
              flexDirection: 'row',
            },
          },
          [theme.breakpoints.up('lg')]: {
            width: DashboardCardWidth.MEDIUM,
            overflowY: 'auto',
            '.MuiDataGrid-footerContainer .MuiBox-root': {
              flexDirection: 'column',
            },
          },
          [theme.breakpoints.up('xl')]: {
            overflowY: 'hidden',
          },
        },
      }}
    >
      {data.map(
        ({ key, cardVariant, groupByKey, sortByOrder, gamingRevenueComparison, gamingRevenueComparisonValue }) => (
          <GameSummariesCard
            key={key}
            cardVariant={cardVariant}
            groupByKey={groupByKey}
            sortByOrder={sortByOrder}
            gamingRevenueComparison={gamingRevenueComparison}
            gamingRevenueComparisonValue={gamingRevenueComparisonValue}
          />
        )
      )}
    </Box>
  );
};

export const useDashboardComponents = (isGamesEnabled: boolean, isSportsEnabled: boolean, isPromoEnabled: boolean) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const canReadBasicInfo = usePermission(OnlineCasinoPermissions.getBasicInfo);

  const { data: basicInfoStatistics, isLoading, isError } = useGetBasicInfo({ query: { enabled: canReadBasicInfo } });

  return useMemo(() => {
    const components = [
      {
        component: (
          <StatisticsCardWrapper
            basicInfoStatistics={basicInfoStatistics}
            isLoading={isLoading}
            isError={isError}
            section={StatisticsCardSections.slice(0, 2)}
          />
        ),
        height: DashboardCardHeight.XSMALL,
      },
      {
        component: (
          <StatisticsCardWrapper
            basicInfoStatistics={basicInfoStatistics}
            isLoading={isLoading}
            isError={isError}
            section={StatisticsCardSections.slice(2)}
          />
        ),
        height: DashboardCardHeight.XSMALL,
      },
      { component: isGamesEnabled ? <RevenueGames /> : null, height: DashboardCardHeight.SMALL, key: 'revenueGames' },
      {
        component: isSportsEnabled ? <RevenueSports /> : null,
        height: DashboardCardHeight.SMALL,
        key: 'revenueSports',
      },
      { component: <Cashflow />, height: DashboardCardHeight.SMALL, key: 'cashflow' },
      {
        component: isGamesEnabled && isSportsEnabled ? <Box /> : null,
        height: DashboardCardHeight.SMALL,
        key: 'empty',
      },
      {
        component: isGamesEnabled ? <GameSessionCardWrapper data={GameSessionCardData.slice(0, 2)} /> : null,
        height: !isDesktop ? `${parseInt(DashboardCardHeight.LARGE) * 2}px` : DashboardCardHeight.LARGE,
      },
      {
        component: isGamesEnabled ? <GameSessionCardWrapper data={GameSessionCardData.slice(2)} /> : null,
        height: !isDesktop ? `${parseInt(DashboardCardHeight.LARGE) * 2}px` : DashboardCardHeight.LARGE,
      },
      {
        component: isGamesEnabled ? (
          <GameSessionCard cardType={SessionGameCardType.DASHBOARD} title={t('gameSessions.titles.lastDayTitle')} />
        ) : null,
        key: 'gameSessionsCard',
        height: DashboardCardHeight.MEDIUM,
      },
      {
        component: <DepositsInProcess />,
        key: 'depositsInProcess',
        height: DashboardCardHeight.MEDIUM,
      },
      {
        component: <PopularGamesCard />,
        key: 'popularGamesCard',
        height: isSportsEnabled && !isGamesEnabled ? DashboardCardHeight.MEDIUM : DashboardCardHeight.SMALL,
      },
      { component: <TotalBalanceOverview />, key: 'totalBalanceOverview', height: DashboardCardHeight.SMALL },
      {
        component: isPromoEnabled ? <PromotionOverview /> : null,
        key: 'promotionOverview',
        height: DashboardCardHeight.SMALL,
      },
    ];

    return components.filter(({ component }) => component !== null) as DashboardCardProps[];
  }, [isDesktop, isGamesEnabled, isPromoEnabled, isSportsEnabled, isError, isLoading, basicInfoStatistics, t]);
};
