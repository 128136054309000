import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface Props {
  pepEntries: string[];
}

const KYCPEPEntries = ({ pepEntries }: Props) => {
  const { t } = useTranslation();

  const renderPepEntry = (entry: string) => {
    const pepData = JSON.parse(entry);

    return (
      <TableRow key={pepData.evidenceIds[0]}>
        <TableCell>{pepData.position}</TableCell>
        <TableCell>{pepData.segment}</TableCell>
        <TableCell>
          {pepData.dateFromIso && pepData.dateToIso
            ? `${pepData.dateFromIso} to ${pepData.dateToIso}`
            : pepData.dateFromIso
            ? `from ${pepData.dateFromIso}`
            : pepData.dateToIso || '/'}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer style={{ marginBottom: '20px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('kyc.headers.position')}</TableCell>
            <TableCell>{t('kyc.headers.segment')}</TableCell>
            <TableCell>{t('date')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody> {pepEntries.map((entry: string) => renderPepEntry(entry))} </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(KYCPEPEntries);
