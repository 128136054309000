import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select, TextField } from '@mui/material';

import type { IndividualActionForm } from '../context';
import IndividualActionFormListItem from './IndividualActionFormListItem';

const FreeCashForm = () => {
  const { t } = useTranslation();
  const { register, watch, setValue, errors } = useFormContext<IndividualActionForm>();
  const enabledFreeCash = watch('enabledFreeCash');

  useEffect(() => {
    if (!enabledFreeCash) {
      setValue('amountFreeCash', '');
    }
  }, [setValue, enabledFreeCash]);

  return (
    <IndividualActionFormListItem enabled={enabledFreeCash} name="enabledFreeCash" listItemText={t('freeCash')}>
      <TextField
        size="small"
        inputRef={register({ required: enabledFreeCash, min: 1 })}
        name="amountFreeCash"
        disabled={!enabledFreeCash}
        error={Boolean(errors.amountFreeCash)}
        required={enabledFreeCash}
        label={t('amount')}
        type="number"
      />
      <Controller
        rules={{ required: enabledFreeCash }}
        name="currencyFreeCash"
        render={props => (
          <Select
            size="small"
            disabled={!enabledFreeCash}
            error={Boolean(errors.currencyFreeCash)}
            required={enabledFreeCash}
            label={t('currency')}
            onChange={props.onChange}
            value={props.value}
          >
            <MenuItem value="EUR">EUR €</MenuItem>
            <MenuItem value="USD">USD $</MenuItem>
            <MenuItem value="GBP">GBP £</MenuItem>
          </Select>
        )}
      />
    </IndividualActionFormListItem>
  );
};

export default FreeCashForm;
