import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { actionCreators } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Alert, Snackbar } from '@mui/material';

const AlertContainer = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isVisible, alert } = useAppSelector(state => state.alert);

  const clearAlert = () => dispatch(actionCreators.clearAlert());

  return (
    <Snackbar
      onClose={clearAlert}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={
        !!(
          isVisible &&
          alert.message &&
          typeof alert.message === 'string' &&
          alert.message.toLowerCase() !== 'tokenexpired'
        )
      }
    >
      <Alert onClose={clearAlert} variant="filled" severity={alert.severity}>
        {t(alert.message)}
      </Alert>
    </Snackbar>
  );
};

export default memo(AlertContainer);
