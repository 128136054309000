import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { useSlate } from 'slate-react';

import { CustomEditor } from '../types';
import { CustomElementKeys, isCustomElementType, isListType, isTextAlignType } from '../typeUtils';
import { Button } from './RichTextComponents';

const isBlockActive = (
  editor: CustomEditor,
  format: string | undefined,
  blockType: CustomElementKeys = 'type',
  level?: number
) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        n[blockType] === format &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (format === 'heading' ? n.level === level : true),
    })
  );

  return !!match;
};

const toggleBlock = (editor: CustomEditor, format: string, level?: number) => {
  if (!isCustomElementType(format) && !isTextAlignType(format)) {
    console.error(`Invalid block type: ${format}`);
    return;
  }
  const isActive = isBlockActive(editor, format, isTextAlignType(format) ? 'align' : 'type', level);
  const isList = isListType(format);

  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && isListType(n.type) && !isTextAlignType(format),
    split: true,
  });
  let newProperties: Partial<SlateElement>;
  if (isTextAlignType(format)) {
    newProperties = {
      align: isActive ? 'left' : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
    if (level && newProperties.type === 'heading') {
      newProperties.level = level;
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const BlockButton = ({ format, icon, level }: { format: string; icon: IconDefinition | string; level?: number }) => {
  const editor = useSlate();

  return (
    <Button
      active={isBlockActive(editor, format, isTextAlignType(format) ? 'align' : 'type', level)}
      onMouseDown={(event: { preventDefault: () => void }) => {
        event.preventDefault();
        toggleBlock(editor, format, level);
      }}
    >
      {typeof icon === 'string' || icon instanceof String ? icon : <FontAwesomeIcon icon={icon} />}
    </Button>
  );
};

export default BlockButton;
