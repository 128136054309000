import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import {
  getGetUserNotesQueryKey,
  useAddUserNote,
  useGetUserNotes,
  UserNoteSchema,
  UserNotesSchema,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { format, fromUnixTime } from 'date-fns';
import { useSnackbar } from 'notistack';

import { PermissionWrapper } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../../../../app/hooks';
import DeleteUserNoteDialog from './DeleteUserNoteDialog';

const StyledParagraph = styled(Typography)(({ theme }) => ({
  maxWidth: '90%',
  marginBottom: theme.spacing(1),
}));

const StyledCredits = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: theme.typography.caption.fontSize,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const UserNotes = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();

  const canReadUserNotes = usePermission(OnlineCasinoPermissions.getUserNotes);

  const [deleteUserNoteDialog, setDeleteUserNoteDialog] = useState({
    open: false,
    note: { noteId: '0', message: '', author: '', timestamp: 0 },
  });

  const {
    data: userNotes,
    isLoading,
    isError,
  } = useGetUserNotes(userId, {
    query: { enabled: canReadUserNotes && !!userId },
  });

  const NotesList = ({ userNotes }: { userNotes: UserNotesSchema }) => (
    <List>
      {userNotes.notes
        .sort((a: UserNoteSchema, b: UserNoteSchema) => a.timestamp - b.timestamp)
        .map((note, key) => (
          <ListItem key={key}>
            <ListItemText
              primary={<StyledParagraph>{note.message}</StyledParagraph>}
              secondary={
                <StyledCredits>
                  <Box>
                    <Typography variant="body2">
                      {`${t('postedBy')}`}: {note.author}
                    </Typography>
                    <Typography variant="body2">
                      {`${t('createdOn')}`}: {format(fromUnixTime(note.timestamp), 'dd/MM/yyyy HH:mm')}
                    </Typography>
                  </Box>
                  <Tooltip title={t('delete')}>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => setDeleteUserNoteDialog({ open: true, note: note })}
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                </StyledCredits>
              }
            />
          </ListItem>
        ))}
    </List>
  );

  const openCloseDeleteUserNote = (state: boolean) => {
    setDeleteUserNoteDialog({ ...deleteUserNoteDialog, open: state });
  };

  return (
    <PermissionWrapper permission={OnlineCasinoPermissions.getUserNotes} isLoading={isLoading} isError={isError}>
      <Stack spacing={2}>
        <AddUserNoteDialog />
        <Card>
          <CardContent>
            {userNotes && userNotes.notes && userNotes.notes.length > 0 ? (
              <>
                <Typography variant="h5" gutterBottom>
                  {userNotes.username}
                </Typography>
                <NotesList userNotes={userNotes} />
              </>
            ) : (
              <Typography>{t('noData')}</Typography>
            )}
          </CardContent>
        </Card>
        <DeleteUserNoteDialog
          open={deleteUserNoteDialog.open}
          setOpen={openCloseDeleteUserNote}
          userID={userId}
          note={deleteUserNoteDialog.note}
        />
      </Stack>
    </PermissionWrapper>
  );
};

const AddUserNoteDialog = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { userId = '' } = useParams();
  const [open, setOpen] = useState(false);
  const methods = useForm<{ message: string }>();
  const canAddUserNotes = usePermission(OnlineCasinoPermissions.addUserNote);
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: addUserNote, isLoading: isAddUserNoteLoading } = useAddUserNote({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetUserNotesQueryKey(userId));
      },
      onError: error => {
        enqueueSnackbar(error?.message || t('somethingWentWrong'), { variant: 'error' });
      },
    },
  });

  const handleAddMessage = (data: any) => {
    const { message } = data;
    setOpen(false);
    if (message.length) addUserNote({ userId, data: message });
  };

  return canAddUserNotes ? (
    <FormProvider {...methods}>
      <Box>
        <Button startIcon={<AddIcon />} variant="outlined" onClick={() => setOpen(true)}>
          {t('addUserNote')}
        </Button>
      </Box>
      <Dialog fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)}>
        <form onSubmit={methods.handleSubmit(handleAddMessage)}>
          <DialogTitle>{t('addUserNote')}</DialogTitle>
          <DialogContent>
            <TextField
              multiline
              name="message"
              label={t('message')}
              fullWidth
              error={!!methods.formState.errors.message}
              inputRef={methods.register({ required: true })}
              rows={4}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="inherit">
              {t('cancel')}
            </Button>
            <LoadingButton
              disabled={!!methods.formState.errors.message}
              type="submit"
              variant="contained"
              loading={isAddUserNoteLoading}
            >
              {t('add')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  ) : null;
};

export default UserNotes;
