import { useLocation } from 'react-router';
import { EWithdrawalTypeSchema } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';

import { usePermission } from '../../../../app/hooks';
import GeneralWithdrawal from './GeneralWithdrawal';

interface WithdrawalProviderState {
  name: string;
  tab: EWithdrawalTypeSchema;
}

const WithdrawalProvider = () => {
  const { state } = useLocation();
  const tab = (state as WithdrawalProviderState | undefined)?.tab ?? EWithdrawalTypeSchema.bankaccount;
  const providerPermission = usePermission(OnlineCasinoPermissions.getWithdrawalsByType);

  return providerPermission ? <GeneralWithdrawal destinationName={tab} /> : null;
};

export default WithdrawalProvider;
