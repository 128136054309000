import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { MultiplierTournamentSchema } from '@greenisland-api';
import { capitalize, InputAdornment, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

import { CreateMultiplierTournamentSchemaForm } from '../helpers/MultiplierTournamentFormContext';

interface Props {
  isCreateMode?: boolean;
  multiplierTournament?: MultiplierTournamentSchema;
}

const MultiplierTournamentBetSettings = ({ isCreateMode = true, multiplierTournament }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { control } = useFormContext<CreateMultiplierTournamentSchemaForm>();

  return (
    <Stack spacing={2}>
      <Typography fontWeight={500} variant="h5" sx={{ py: 1 }}>
        {capitalize(t('betSettings'))}
      </Typography>
      <Input
        control={control}
        size="medium"
        label={capitalize(t('minMultiplier'))}
        type="number"
        name="settings.minimumMultiplier"
        defaultValue={!isCreateMode && multiplierTournament?.settings?.minimumMultiplier}
        InputProps={{
          inputProps: { min: 0 },
          startAdornment: <InputAdornment position={'start'}>x</InputAdornment>,
        }}
        required
        rules={{ required: t('fieldIsRequired'), valueAsNumber: true }}
        sx={{
          [theme.breakpoints.up('xs')]: {
            width: '100%',
            mb: 2,
          },
          [theme.breakpoints.up('md')]: {
            width: '45%',
          },
        }}
      />
      <Input
        control={control}
        size="medium"
        label={capitalize(t('numOfMultiplier'))}
        type="number"
        name="settings.numberOfMultipliers"
        defaultValue={!isCreateMode && multiplierTournament?.settings?.numberOfMultipliers}
        InputProps={{ inputProps: { min: 0 } }}
        required
        rules={{ required: t('fieldIsRequired'), valueAsNumber: true }}
        sx={{
          [theme.breakpoints.up('xs')]: {
            width: '100%',
            mb: 2,
          },
          [theme.breakpoints.up('md')]: {
            width: '45%',
          },
        }}
      />
      <Input
        control={control}
        size="medium"
        label={capitalize(t('minimumBet'))}
        type="number"
        name="minimumBet"
        defaultValue={!isCreateMode && multiplierTournament?.minimumBet}
        InputProps={{
          startAdornment: <FontAwesomeIcon sx={{ pr: 2 }} icon={faEuroSign} />,
          inputProps: { min: 0, step: 'any' },
        }}
        required
        rules={{ required: t('fieldIsRequired'), valueAsNumber: true }}
        sx={{
          [theme.breakpoints.up('xs')]: {
            width: '100%',
            mb: 2,
          },
          [theme.breakpoints.up('md')]: {
            width: '45%',
          },
        }}
      />
    </Stack>
  );
};

export default MultiplierTournamentBetSettings;
