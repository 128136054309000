import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Box, Button, capitalize, Card, CardContent, CardHeader } from '@mui/material';

import ResponsibleGamingTestResponses from './ResponsibleGamingTestResponses';

interface Props {
  showSeeMoreButton?: boolean;
  showCustomFields?: boolean;
  customPrimaryKey?: string;
  customUserId?: string;
}

const ResponsibleGamingTestResponsesCard = ({ showSeeMoreButton = true, customPrimaryKey, customUserId }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const navigateToHandler = useCallback(() => {
    return navigate('../../responsible-gaming/test-responses');
  }, [navigate]);

  return (
    <Card elevation={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <CardHeader title={capitalize(t('responsibleGaming.reportToCruks.titles.testResponses'))} />
        {showSeeMoreButton && (
          <Button sx={{ mr: 1 }} variant="outlined" color="primary" size="small" onClick={navigateToHandler}>
            {t('seeMore')}
          </Button>
        )}
      </Box>
      <CardContent sx={{ pt: 0 }}>
        <ResponsibleGamingTestResponses isCard={true} customPrimaryKey={customPrimaryKey} customUserId={customUserId} />
      </CardContent>
    </Card>
  );
};

export default ResponsibleGamingTestResponsesCard;
