import React, { ComponentProps, memo } from 'react';
import { alpha, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Editor } from '@tinymce/tinymce-react';
import classnames from 'classnames';

interface Props extends Omit<ComponentProps<typeof Editor>, 'plugins' | 'toolbar'> {
  label?: string;
  required?: boolean;
  className?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flexDirection: 'column',
    boxSizing: 'border-box',
    display: 'flex',
    '&:hover': {
      '& $label': { color: theme.palette.text.primary },
    },
  },
  label: {
    ...theme.typography.body1,
    color: alpha(theme.palette.text.primary, 0.6),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
    margin: '0 8px 5px',
    boxSizing: 'border-box',
    transition: 'all linear',
  },
}));

// Deprecated in favor of app/components/TextEditor.tsx
const TextEditor = ({ label, required, className, ...props }: Props) => {
  const classes = useStyles();
  return (
    <div className={classnames(className, classes.container)}>
      {label ? <label className={classes.label}>{required ? `${label} *` : label}</label> : null}
      <Editor
        {...props}
        apiKey="wu7foj1gwc13g7pl3bjucchjzl2t4q0j8dbeb5vgmx51p3tm"
        init={{
          ...props.init,
          height: 500,
          menubar: true,
          plugins: [
            'advlist autolink lists link image charmap print preview searchreplace visualblocks code fullscreen insertdatetime paste code help wordcount tabfocus emoticons',
          ],
          toolbar: [
            'undo redo',
            'image',
            'formatselect',
            'bold italic backcolor',
            'alignleft aligncenter',
            'alignright alignjustify',
            'bullist numlist outdent indent',
            'removeformat',
            'emoticons',
            'help',
          ].join(' | '),
          ...props,
        }}
      />
    </div>
  );
};

export default memo(TextEditor);
