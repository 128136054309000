import React, { memo, useState } from 'react';
import {
  Authenticator,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  Text,
  useAuthenticator,
  useTheme,
  View,
} from '@aws-amplify/ui-react';

const AmplifyFooter = () => {
  const { toResetPassword } = useAuthenticator();
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" padding={tokens.space.medium}>
      <Text>&copy; Green Island</Text>
    </Flex>
  );
};

export default AmplifyFooter;
