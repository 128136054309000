import { PropsWithChildren } from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useTheme,
} from '@mui/material';

type Props = {
  title: string;
  description?: string;
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
  loading?: boolean;
  confirmText: string;
  cancelText: string;
};

const ConfirmDialog = ({
  title,
  description,
  isOpen,
  loading,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  children,
}: PropsWithChildren<Props>) => {
  const { spacing } = useTheme();

  return (
    <Dialog open={isOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{capitalize(title)}</DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onCancel}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: spacing(2),
          top: spacing(1),
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        {description ? (
          <DialogContentText id="alert-dialog-description">{capitalize(description)}</DialogContentText>
        ) : null}
        {children ? <DialogContentText id="alert-dialog-description">{children}</DialogContentText> : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary" variant="text">
          {cancelText}
        </Button>
        <LoadingButton loading={loading} onClick={onConfirm} autoFocus variant="contained">
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
