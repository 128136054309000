import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { ERegulatorSchema, UserLimitUpdateRequestWithReferenceSchema } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Stack, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';

import { DataGridContainer, FontAwesomeIcon, Link, PermissionWrapper } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { formatDateTime } from '@greenisland-common/helpers';

import { useTenantContext } from '../../../../app/context/TenantContext';
import { formatCurrency } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';
import UserLimitsApprovalDecisionFormDialog from './UserLimitsApprovalDecisionFormDialog';
import UserLimitsRequestDetailsDialog from './UserLimitsRequestDetailsDialog';

type Row = UserLimitUpdateRequestWithReferenceSchema;

interface Props {
  depositLimitProps: {
    data?: UserLimitUpdateRequestWithReferenceSchema[];
    isLoading: boolean;
    isError: boolean;
    page: number;
    setPage: Dispatch<SetStateAction<number>>;
    pageSize: number;
    setPageSize: Dispatch<SetStateAction<number>>;
    totalItems?: number;
  };
}

const UserLimitsTabView = ({ depositLimitProps }: Props) => {
  const { t } = useTranslation();
  const tenant = useTenantContext();

  const canSetDepositLimitsThatRequireManualApproval = usePermission(
    OnlineCasinoPermissions.setDepositLimitUpdateRequestToRequireManualApproval
  );
  const canApproveDepositLimitsThatRequireManualApproval = usePermission(
    OnlineCasinoPermissions.approveDepositLimitUpdateRequestManually
  );
  const canDeclineDepositLimitsThatRequireManualApproval = usePermission(
    OnlineCasinoPermissions.declineDepositLimitUpdateRequestManually
  );

  const isDGOJRegulator = tenant?.regulatorV2 === ERegulatorSchema.DGOJ;

  const { data, isLoading, isError, page, setPage, pageSize, setPageSize, totalItems } = depositLimitProps;

  const [chosenRequestContext, setChosenRequestContext] = useState<UserLimitUpdateRequestWithReferenceSchema | null>(
    null
  );
  const [chosenApprovalDecisionRequest, setChosenApprovalDecisionRequest] =
    useState<UserLimitUpdateRequestWithReferenceSchema | null>(null);

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'id',
        headerName: capitalize(t('requestId')),
        sortable: false,
        minWidth: 80,
        flex: 0.03,
        renderCell: ({ row }) => {
          return <Typography variant="body2">{row?.depositLimitUpdateRequest?.id || '-'}</Typography>;
        },
      },
      {
        field: 'created',
        headerName: capitalize(t('requestedOn')),
        sortable: false,
        minWidth: 100,
        flex: 0.04,
        renderCell: ({ row }) => {
          if (row?.depositLimitUpdateRequest?.created) {
            const formattedDate = formatDateTime(new Date(row?.depositLimitUpdateRequest?.created * 1000), true);
            return (
              <Box>
                <Typography variant="body2">{formattedDate.split(' ')[0]}</Typography>
                <Typography variant="body2" color="GrayText">
                  {formattedDate.split(' ')[1]}
                </Typography>
              </Box>
            );
          }
          return <Typography variant="body2">-</Typography>;
        },
      },
      {
        field: 'userId',
        headerName: capitalize(t('userId')),
        flex: 0.03,
        renderCell: ({ row }) => (
          <Link target="_blank" to={`../../users/${row?.depositLimitUpdateRequest?.userId}/details`}>
            {row?.depositLimitUpdateRequest?.userId}
          </Link>
        ),
        sortable: false,
        minWidth: 80,
      },
      {
        field: 'period',
        headerName: capitalize(t('period')),
        sortable: false,
        minWidth: 80,
        flex: 0.05,
        renderCell: ({ row }) => {
          return <Typography variant="body2">{row?.depositLimitUpdateRequest?.period || '-'}</Typography>;
        },
      },
      {
        field: 'requestType',
        headerName: capitalize(t('common.requestType')),
        sortable: false,
        minWidth: 100,
        flex: 0.05,
        renderCell: ({ row }) => {
          return <Typography variant="body2">{row?.depositLimitUpdateRequest?.requestType || '-'}</Typography>;
        },
      },
      {
        field: 'value',
        headerName: capitalize(t('value')),
        sortable: false,
        minWidth: 100,
        flex: 0.05,
        renderCell: ({ row }) => {
          return (
            <Typography variant="body2">
              {row?.depositLimitUpdateRequest?.value ? formatCurrency(row?.depositLimitUpdateRequest?.value) : '-'}
            </Typography>
          );
        },
      },
      ...(isDGOJRegulator
        ? [
            {
              field: 'actions',
              type: 'actions',
              minWidth: 80,
              flex: 0.02,
              headerName: capitalize(t('details')),
              getActions: (params: GridRowParams<Row>) => [
                <Tooltip title={t('details')} key="details">
                  <Box>
                    <GridActionsCellItem
                      label={t('details')}
                      icon={<FontAwesomeIcon icon={faEye} />}
                      onClick={() => setChosenRequestContext(params.row)}
                      key="details"
                    />
                  </Box>
                </Tooltip>,
              ],
            },
          ]
        : []),
      ...(canSetDepositLimitsThatRequireManualApproval &&
      canApproveDepositLimitsThatRequireManualApproval &&
      canDeclineDepositLimitsThatRequireManualApproval
        ? [
            {
              field: '',
              headerName: '',
              sortable: false,
              minWidth: 130,
              flex: 0.03,
              renderCell: ({ row }: GridRenderCellParams) => {
                return (
                  <Tooltip title={t('common.decision')} key="details">
                    <Box>
                      <Button
                        variant="contained"
                        onClick={() => setChosenApprovalDecisionRequest(row)}
                        disabled={!row?.reference}
                      >
                        {t('common.decision')}
                      </Button>
                    </Box>
                  </Tooltip>
                );
              },
            },
          ]
        : []),
    ],
    [
      canApproveDepositLimitsThatRequireManualApproval,
      canDeclineDepositLimitsThatRequireManualApproval,
      canSetDepositLimitsThatRequireManualApproval,
      isDGOJRegulator,
      t,
    ]
  );

  const rows = useMemo(
    () =>
      data?.map(request => ({
        id: request?.depositLimitUpdateRequest?.id,
        ...request,
      })) ?? [],
    [data]
  );

  return (
    <Stack spacing={2}>
      <PermissionWrapper
        errorMessage={t('tasks.userLimits.errors.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getDepositLimitsThatRequireManualApproval}
      >
        <DataGridContainer>
          <LimitedDataGrid
            density="compact"
            autoHeight
            rowHeight={70}
            loading={isLoading}
            rows={rows}
            columns={columns}
            pagination
            page={page}
            rowCount={totalItems}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(page: number) => {
              setPage(page);
            }}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
          />
        </DataGridContainer>
      </PermissionWrapper>
      {chosenRequestContext && (
        <UserLimitsRequestDetailsDialog
          chosenRequestDetails={chosenRequestContext}
          onClose={() => setChosenRequestContext(null)}
        />
      )}
      {chosenApprovalDecisionRequest ? (
        <UserLimitsApprovalDecisionFormDialog
          chosenApprovalDecisionRequest={chosenApprovalDecisionRequest}
          onClose={() => {
            setChosenApprovalDecisionRequest(null);
          }}
        />
      ) : null}
    </Stack>
  );
};

export default UserLimitsTabView;
