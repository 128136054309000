import { Controller, UseControllerOptions } from 'react-hook-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { StandardTextFieldProps, TextField } from '@mui/material';

type Props = UseControllerOptions &
  StandardTextFieldProps &
  NumericFormatProps & {
    adornmentIcon?: string;
    helpText?: string;
  };

const CurrencyInput = ({ name, control, defaultValue, adornmentIcon = '€ ', ...restProps }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={restProps.rules}
      render={({ onChange, onBlur, value, name, ref }) => (
        <NumericFormat
          size="small"
          name={name}
          onBlur={onBlur}
          onChange={e => onChange(e.target.value)}
          value={value}
          inputRef={ref}
          {...restProps}
          customInput={TextField}
          decimalScale={2}
          fixedDecimalScale
          decimalSeparator={','}
          prefix={adornmentIcon}
          allowNegative={false}
        />
      )}
    />
  );
};

export default CurrencyInput;
