import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetOnlineDepositsForUser } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { getUnixTime, startOfDay } from 'date-fns';

import { Link, StyledDataGrid } from '@greenisland-common/components/atoms';
import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import {
  transformBooleanV2,
  transformCurrencyV2,
  transformUnixDateV2,
} from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import { transformToCSVDeposits } from '../AccountData/helpers/TransformToCSV';

const Deposits = () => {
  const canReadUserDeposits = usePermission(OnlineCasinoPermissions.getOnlineDepositsForUser);
  return canReadUserDeposits ? <DepositsInnerChild /> : null;
};

const DepositsInnerChild = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const canReadDepositDetails = usePermission(OnlineCasinoPermissions.getDepositDetails);
  const canReadUserEventstream = usePermission(OnlineCasinoPermissions.getEventstreamStreamId);
  const canReadUserDeposits = usePermission(OnlineCasinoPermissions.getOnlineDepositsForUser);

  const startDate = searchParams.get('startdate')
    ? Number(searchParams.get('startdate'))
    : getUnixTime(startOfDay(new Date()));
  const endDate = searchParams.get('enddate') ? Number(searchParams.get('enddate')) : getUnixTime(new Date());

  const { data: deposits, isLoading } = useGetOnlineDepositsForUser(
    parseInt(userId),
    {
      start: startDate,
      end: endDate,
      top: 1000,
    },
    { query: { enabled: canReadUserDeposits } }
  );

  const columns: GridColDef[] = useMemo(() => {
    if (!deposits || deposits.length === 0) return [];
    return Object.keys(deposits[0])
      .map(key => {
        switch (key) {
          case 'timestamp':
            return {
              ...transformUnixDateV2(key, t),
              flex: 1,
            };
          case 'amount':
            return {
              ...transformCurrencyV2(key, t),
              flex: 1,
            };
          case 'status':
            return {
              field: key,
              headerName: t(key),
              renderCell: params => (
                <Typography
                  color={
                    params.value.toLowerCase() === 'declined'
                      ? 'error'
                      : params.value.toLowerCase() === 'started'
                      ? 'warning'
                      : params.value.toLowerCase() === 'completedmanually'
                      ? 'success'
                      : 'inherit'
                  }
                >
                  {params.value}
                </Typography>
              ),
              flex: 1,
            } as GridColDef;
          case 'depositBonus':
            return {
              field: key,
              headerName: t(key),
              sortable: false,
              renderCell: params =>
                canReadUserEventstream && params.value ? (
                  <Link to={`../../../reporting/events/stream?streamid=user/${params.row.userId}`}>{t('stream')}</Link>
                ) : (
                  <span>{params.value ? t('yes') : t('no')}</span>
                ),
              flex: 1,
            } as GridColDef;
          case 'onAccount':
            return transformBooleanV2(key, t);
          default:
            return { field: key, headerName: t(key), flex: 1 };
        }
      })
      .concat({
        field: 'details',
        headerName: t('details'),
        sortable: false,
        filterable: false,
        renderCell: params =>
          canReadDepositDetails ? <Link to={`${params.row.depositId}`}>{t('details')}</Link> : null,
      });
  }, [deposits, t, canReadUserEventstream, canReadDepositDetails]);

  const rows = useMemo(() => {
    return deposits || [];
  }, [deposits]);

  const handleDownload = () => {
    if (deposits) {
      const csv = transformToCSVDeposits(deposits);
      if (csv) {
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', `deposits_${userId}_${startDate}_${endDate}.csv`);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }
  };

  return (
    <Stack spacing={2}>
      <DateRangePicker
        unixStartDate={Number(searchParams.get('startdate')) * 1000}
        unixEndDate={Number(searchParams.get('enddate')) * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
        }}
      />
      <Button
        sx={{ width: 'fit-content' }}
        disabled={!deposits || !deposits?.length}
        variant="outlined"
        size="small"
        onClick={handleDownload}
      >
        {t('exportToCSV')}
      </Button>

      <StyledDataGrid
        columns={columns}
        rows={rows}
        loading={isLoading}
        autoHeight
        pagination
        pageSize={20}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        getRowId={row => row.depositId}
        initialState={{
          columns: {
            columnVisibilityModel: {
              userId: false,
              depositId: false,
              username: false,
            },
          },
        }}
      />
    </Stack>
  );
};

export default Deposits;
