import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { faCircleInfo, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LinkedDgojResponsibleGamingTestResponseSchema,
  RetrieveDgojResponsibleGamingTestResponsesParams,
  SortOrderParamParameter,
  useRetrieveDgojResponsibleGamingTestResponses,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useDebouncedValue } from '@lilib/hooks';
import { Box, capitalize, LinearProgress, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';

import { DataGridContainer, DataGridPagination, Link, PermissionWrapper } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { formatDateTime } from '@greenisland-common/helpers';

import { GAMING_QUESTIONS } from './helpers/responsibleGamingTestResponsesHelpers';
import {
  TestResponsesSearchFilters,
  useGetTestResponsesFiltersQuery,
} from './helpers/responsibleGamingTestResponsesHooks';
import { usePermission } from '../../../../app/hooks';
import ResponsibleGamingTestResponsesFilters from './ResponsibleGamingTestResponsesFilters';
import ResponsibleGamingTestResponsesResult from './ResponsibleGamingTestResponsesResult';
import ResponsibleGamingTestResponsesState from './ResponsibleGamingTestResponsesState';

const CARD_SIZE = 10;
const PAGE_SIZE = 25;

type Row = LinkedDgojResponsibleGamingTestResponseSchema;

interface Props {
  isCard?: boolean;
  customPrimaryKey?: string;
  customUserId?: string;
}

const ResponsibleGamingTestResponses = ({ customPrimaryKey, customUserId, isCard = false }: Props) => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const theme = useTheme();
  const { idQuery, userIdQuery, sortByOrderQuery } = useGetTestResponsesFiltersQuery();

  const canReadTestResponses = usePermission(OnlineCasinoPermissions.retrieveDgojResponsibleGamingTestResponses);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(isCard ? CARD_SIZE : PAGE_SIZE);
  const [debouncedId] = useDebouncedValue(idQuery, { wait: 500 });
  const [debouncedUserId] = useDebouncedValue(userIdQuery, { wait: 500 });

  const params = useMemo((): RetrieveDgojResponsibleGamingTestResponsesParams => {
    return {
      page,
      pageSize,
      sortOrder: sortByOrderQuery as SortOrderParamParameter,
      ...(isCard && customPrimaryKey
        ? { responsibleGamingTestResponsePkey: Number(customPrimaryKey) }
        : debouncedId
        ? { responsibleGamingTestResponsePkey: Number(debouncedId) }
        : {}),
      ...(isCard && (userId || customUserId)
        ? { userId: Number(userId || customUserId) }
        : debouncedUserId
        ? { userId: Number(debouncedUserId) }
        : {}),
    };
  }, [customPrimaryKey, customUserId, debouncedId, debouncedUserId, isCard, page, pageSize, sortByOrderQuery, userId]);

  const {
    data: testResponses,
    isLoading,
    isError,
  } = useRetrieveDgojResponsibleGamingTestResponses(params, {
    query: { enabled: canReadTestResponses },
  });

  const columns = useMemo<GridColumns<Row>>(() => {
    const cardFields: GridColumns<Row> = [
      {
        field: 'created',
        headerName: capitalize(t('created')),
        flex: 0.2,
        renderCell: ({ value }) => {
          const formattedDate = formatDateTime(new Date(value * 1000), true);
          return (
            <Box>
              <Typography variant="body2">{formattedDate.split(' ')[0]}</Typography>
              <Typography variant="body2" color="GrayText">
                {formattedDate.split(' ')[1]}
              </Typography>
            </Box>
          );
        },
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'state',
        headerName: capitalize(t('state')),
        flex: 0.1,
        renderCell: ({ value }) => <ResponsibleGamingTestResponsesState state={value} />,
        sortable: false,
        minWidth: 80,
      },
      {
        field: 'result',
        headerName: capitalize(t('result')),
        flex: 0.1,
        renderCell: ({ value }) => <ResponsibleGamingTestResponsesResult result={value} />,
        sortable: false,
        minWidth: 120,
      },
      {
        field: 'actions',
        type: 'actions',
        flex: 0.1,
        minWidth: 100,
        getActions: (params: GridRowParams<Row>) => [
          <Tooltip title={t('details')} key="details">
            <Box>
              <Link
                to={`../../responsible-gaming/test-responses?${TestResponsesSearchFilters.ID}=${params.row.id}&${TestResponsesSearchFilters.USER_ID}=${params.row.userId}`}
                target="_blank"
              >
                <FontAwesomeIcon icon={faEye} color={theme.palette.text.secondary} />
              </Link>
            </Box>
          </Tooltip>,
        ],
      },
    ];
    const pageFields: GridColumns<Row> = [
      {
        field: 'id',
        headerName: capitalize(t('id')),
        flex: 0.02,
        renderCell: ({ value }) => value,
        sortable: false,
        minWidth: 80,
      },
      {
        field: 'created',
        headerName: capitalize(t('created')),
        flex: 0.05,
        renderCell: ({ value }) => {
          const formattedDate = formatDateTime(new Date(value * 1000), true);
          return (
            <Box>
              <Typography variant="body2">{formattedDate.split(' ')[0]}</Typography>
              <Typography variant="body2" color="GrayText">
                {formattedDate.split(' ')[1]}
              </Typography>
            </Box>
          );
        },
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'userId',
        headerName: capitalize(t('userId')),
        flex: 0.03,
        renderCell: ({ value }) => (
          <Link target="_blank" to={`../../users/${value}/details`}>
            {value}
          </Link>
        ),
        sortable: false,
        minWidth: 80,
      },
      {
        field: 'state',
        headerName: capitalize(t('state')),
        flex: 0.02,
        renderCell: ({ value }) => <ResponsibleGamingTestResponsesState state={value} />,
        sortable: false,
        minWidth: 80,
      },
      {
        field: 'result',
        headerName: capitalize(t('result')),
        flex: 0.05,
        renderCell: ({ value }) => <ResponsibleGamingTestResponsesResult result={value} />,
        sortable: false,
        minWidth: 120,
      },
      {
        field: 'depositLimitUpdateRequestId',
        headerName: capitalize(t('responsibleGaming.testResponses.titles.depositLimitUpdateRequestId')),
        flex: 0.05,
        renderCell: ({ value }) => value || '-',
        sortable: false,
        minWidth: 80,
      },
      ...GAMING_QUESTIONS.map(item => ({
        field: item.field,
        flex: 0.02,
        renderHeader: () => {
          return (
            <Tooltip title={capitalize(t(item.question))} placement="top" arrow sx={{ cursor: 'pointer' }}>
              <Box display="flex" alignItems="center">
                <Typography variant="body2" sx={{ pr: 1 }}>
                  {capitalize(item.header)}
                </Typography>
                <FontAwesomeIcon icon={faCircleInfo} />
              </Box>
            </Tooltip>
          );
        },
        renderCell: ({ value }: GridRenderCellParams) => {
          const color = value ? theme.palette.error.main : theme.palette.success.main;
          return (
            <Typography variant="body2" color={color}>
              {value?.toString()}
            </Typography>
          );
        },
        sortable: false,
        minWidth: 80,
      })),
    ];

    return isCard ? cardFields : pageFields;
  }, [isCard, t, theme.palette.error.main, theme.palette.success.main, theme.palette.text.secondary]);

  const rows = useMemo(
    () =>
      testResponses?.responses?.map<Row>(testResponse => ({
        ...testResponse,
      })) ?? [],
    [testResponses?.responses]
  );

  return (
    <Stack spacing={2}>
      <PermissionWrapper
        errorMessage={t('responsibleGaming.testResponses.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.retrieveDgojResponsibleGamingTestResponses}
      >
        <>
          {!isCard && <ResponsibleGamingTestResponsesFilters />}
          <DataGridContainer>
            <LimitedDataGrid
              density="compact"
              autoHeight
              rowHeight={70}
              loading={isLoading}
              rows={rows}
              columns={columns}
              pagination
              page={page}
              rowCount={testResponses?.paging?.totalItems}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={(page: number) => {
                if (!isLoading) {
                  setPage(page);
                }
              }}
              onPageSizeChange={setPageSize}
              components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
              rowsPerPageOptions={isCard ? [] : [10, 25, 50, 100, 200]}
              componentsProps={isCard ? {} : { pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
        </>
      </PermissionWrapper>
    </Stack>
  );
};

export default ResponsibleGamingTestResponses;
