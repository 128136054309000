import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { EUserStatusSchema, getGetUserGeneralQueryKey, UserGeneralSchema, useSetUserStatus } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useSnackbar } from 'notistack';

import GenericField from './GenericField';

interface Props {
  user: UserGeneralSchema;
}

const ActiveField = ({ user }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const updateMutation = useSetUserStatus({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetUserGeneralQueryKey(user.userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: async () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const { isActive } = user;

  const handleUpdateUserStatus = (reason?: string) => {
    updateMutation.mutate({ userId: user.userId, data: { status: EUserStatusSchema.Active, reason } });
  };

  return (
    <GenericField
      withReason
      value={isActive}
      error={!isActive}
      tooltip={isActive ? t('lookup.verification.deactivateTitle') : t('lookup.verification.activateTitle')}
      description={
        isActive ? t('lookup.verification.deactivateDescription') : t('lookup.verification.activateDescription')
      }
      togglePermissions={OnlineCasinoPermissions.setUserStatus}
      onToggle={handleUpdateUserStatus}
    />
  );
};

export default ActiveField;
