import { faListAlt } from '@fortawesome/free-solid-svg-icons';

import Dashboard from '../../pages/OnlineCasino/Dashboard/Dashboard';
import GamesActivity from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/GamesActivity/GamesActivity';
import { BaseRoute } from '../RouteInterfaces';

export const dashboardRoutes: Array<BaseRoute> = [
  {
    path: 'dashboard',
    name: 'Dashboard',
    element: Dashboard,
    icon: faListAlt,
  },
  {
    path: 'gameactivities',
    name: 'Game Activities',
    element: GamesActivity,
    hiddenLink: true,
  },
];

export default dashboardRoutes;
