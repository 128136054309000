import { memo, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  Base64FileSchema,
  CampaignTemplateDetailsSchema,
  CampaignTemplateUpdateSchema,
  EMIMETypeSchema,
  TemplateTypesSchema,
} from '@greenisland-api';
import { Box, Button, Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { SelectInput } from '@greenisland-common/components/atoms';
import FileUpload from '@greenisland-common/components/molecules/FileUpload';
import NavPills from '@greenisland-common/components/molecules/NavPills/NavPills';
import TextEditor from '@greenisland-common/components/molecules/TextEditor';

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'grid',
  gridGap: theme.spacing(2),
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  gridGap: theme.spacing(2),
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const SaveButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

type TemplateFormProps = {
  onSubmit: (data: CampaignTemplateUpdateSchema) => void;
  templateTypes: TemplateTypesSchema;
  templateData?: CampaignTemplateDetailsSchema;
  returnLink: string;
};

type FormData = {
  name: string;
  type: any;
  ENTitle: string;
  ENBody: string;
  ENImage: Base64FileSchema | string;
  NLTitle: string;
  NLBody: string;
  NLImage: Base64FileSchema | string;
  FRTitle: string;
  FRBody: string;
  FRImage: Base64FileSchema | string;
};

const TemplateForm = ({ onSubmit, templateTypes, templateData }: TemplateFormProps) => {
  const { t } = useTranslation();
  const { templateType }: any = useParams();

  const [activeTab, setActiveTab] = useState(0);
  const methods = useForm<FormData>({
    defaultValues: {
      name: templateData?.name,
      type: templateData?.type,
      ENTitle: templateData?.content.find(language => language.language === 'EN')?.title,
      ENBody: templateData?.content.find(language => language.language === 'EN')?.body,
      ENImage: templateData?.content.find(language => language.language === 'EN')?.imageUrl,
      NLTitle: templateData?.content.find(language => language.language === 'NL')?.title,
      NLBody: templateData?.content.find(language => language.language === 'NL')?.body,
      NLImage: templateData?.content.find(language => language.language === 'NL')?.imageUrl,
      FRTitle: templateData?.content.find(language => language.language === 'FR')?.title,
      FRBody: templateData?.content.find(language => language.language === 'FR')?.body,
      FRImage: templateData?.content.find(language => language.language === 'FR')?.imageUrl,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { register, handleSubmit, control, errors, watch, getValues } = methods;

  const values = getValues();
  const typeWatch = watch('type');

  const getOptions = (key: string) => {
    if (templateTypes?.length > 0) {
      const selectedEntry = templateTypes?.find((entry: any) => entry.name === key);
      if (selectedEntry && selectedEntry.availableFields) return selectedEntry.availableFields;
      return [];
    }
    return [];
  };

  useEffect(() => {
    const keys = Object.keys(errors)
      .map(key => key.substring(0, 2))
      .sort((_, b) => {
        if (b === 'EN') return 1;
        if (b === 'FR') return -1;
        return 0;
      });
    for (let i = 0; i < keys.length; i++) {
      const language = keys[i];
      if (language === 'EN') {
        setActiveTab(0);
        break;
      }
      if (language === 'NL') {
        setActiveTab(1);
        break;
      }
      if (language === 'FR') {
        setActiveTab(2);
        break;
      }
    }
  }, [errors]);

  const onSubmitForm = (data: FormData) => {
    onSubmit({
      name: data.name,
      type: data.type,
      content: [
        {
          body: data.ENBody,
          image: typeof data.ENImage === 'string' ? null : (data.ENImage as any),
          language: 'EN',
          title: data.ENTitle,
        },
        {
          body: data.NLBody,
          image: typeof data.NLImage === 'string' ? null : (data.NLImage as any),
          language: 'NL',
          title: data.NLTitle,
        },
        {
          body: data.FRBody,
          image: typeof data.FRImage === 'string' ? null : (data.FRImage as any),
          language: 'FR',
          title: data.FRTitle,
        },
      ],
    });
  };

  const hasOptions = () => {
    const availableFields = templateTypes?.find((entry: any) => entry.name === typeWatch)?.availableFields;
    return typeWatch && templateTypes?.length > 0 && availableFields && availableFields.length > 0;
  };

  const constructTabs = () => {
    const languages = ['EN', 'NL', 'FR'];
    return languages.map((language, index) => ({
      tabButton: t(language),
      tabContent: (
        <Stack spacing={2}>
          <Typography variant="h6">{t(language)}</Typography>
          <InputWrapper>
            <TextField
              error={!!errors[`${language}Title` as keyof FormData]}
              type="text"
              label={t('title')}
              size="small"
              fullWidth
              name={`${language}Title`}
              inputRef={register({ required: true, validate: value => value.length > 0 })}
            />
            <Controller
              render={({ value, onChange }) => (
                <FileUpload
                  error={!!errors[`${language}Image` as keyof FormData]}
                  id="loyaltyShop"
                  type="image"
                  onChange={(file: Base64FileSchema) => onChange(file)}
                  defaultFile={value}
                  buttonTitle="uploadImage"
                  shouldBeAbleToSave={false}
                  requirements={{
                    dimensions: { width: 600 },
                    mimeTypes: [
                      EMIMETypeSchema['image/jpg'],
                      EMIMETypeSchema['image/jpeg'],
                      EMIMETypeSchema['image/png'],
                    ],
                  }}
                />
              )}
              control={control}
              rules={{ required: true }}
              defaultValue={getValues(`${language}Image`)}
              name={`${language}Image`}
            />
          </InputWrapper>
          {!!errors[`${language.toUpperCase()}Body` as keyof FormData] && <ErrorText>{t('bodyRequired')}</ErrorText>}
          <Controller
            name={`${language.toUpperCase()}Body`}
            control={control}
            defaultValue={values[`${language.toUpperCase()}Body` as keyof FormData]}
            rules={{ required: true, validate: value => value.length > 0 }}
            render={({ onChange, value }) => <TextEditor onEditorChange={onChange} value={value} />}
          />
        </Stack>
      ),
    }));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Stack spacing={2}>
          <Card>
            <CardContent>
              <Typography variant="h6">{`${templateType === 'add' ? t('addTemplate') : t('editTemplate')}`}</Typography>
              <StyledCardContent>
                <InputWrapper>
                  <TextField
                    error={!!errors.name}
                    type="text"
                    label={t('name')}
                    fullWidth
                    name="name"
                    size="small"
                    inputRef={register({ required: true, validate: value => value.length > 0 })}
                  />
                  {templateTypes?.length > 0 && (
                    <SelectInput
                      name="type"
                      label={t('templateType')}
                      disabled={templateType !== 'add'}
                      size="small"
                      rules={{ required: true, validate: value => value.length > 0 }}
                      options={
                        templateTypes?.map(entry => ({
                          label: entry.name,
                          value: entry.name,
                        })) ?? []
                      }
                    />
                  )}
                </InputWrapper>
                <Box>
                  <Typography>{t('templateFormatExplanation')}</Typography>
                  <Typography>{t('availableFields')}:</Typography>
                  {hasOptions() ? (
                    <ul>
                      {getOptions(typeWatch).map((type: string) => (
                        <li key={type}>{type}</li>
                      ))}
                    </ul>
                  ) : (
                    <Typography>{t('noAvailableFields')}</Typography>
                  )}
                </Box>
              </StyledCardContent>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              {templateTypes?.length > 0 && (
                <NavPills alignCenter tabs={constructTabs()} active={activeTab} onChangeTab={setActiveTab} />
              )}
            </CardContent>
          </Card>
          <SaveButton fullWidth color="primary" variant="contained" type="submit">
            {t('save')}
          </SaveButton>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default memo(TemplateForm);
