/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  CloudwatchAlarmActionsEnabledSchema,
  DefaultResponse,
  ForbiddenResponse,
  KillSwitchInternalServerErrorResponse,
  KillSwitchStateResponseSchema,
  SetCloudwatchAlarmActionsEnabledParams,
  SetKillSwitchStateParams,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary fetch alarm actions enabled flag
 */
export const useGetCloudwatchAlarmActionsEnabledHook = () => {
  const getCloudwatchAlarmActionsEnabled = useCustomInstance<CloudwatchAlarmActionsEnabledSchema>();

  return (signal?: AbortSignal) => {
    return getCloudwatchAlarmActionsEnabled({
      url: `/killswitch/cloudwatchalarm/actions-enabled`,
      method: 'get',
      signal,
    });
  };
};

export const getGetCloudwatchAlarmActionsEnabledQueryKey = () =>
  [`/killswitch/cloudwatchalarm/actions-enabled`] as const;

export const useGetCloudwatchAlarmActionsEnabledQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCloudwatchAlarmActionsEnabledHook>>>,
  TError = BadRequestResponse | KillSwitchInternalServerErrorResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetCloudwatchAlarmActionsEnabledHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCloudwatchAlarmActionsEnabledHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCloudwatchAlarmActionsEnabledQueryKey();

  const getCloudwatchAlarmActionsEnabled = useGetCloudwatchAlarmActionsEnabledHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCloudwatchAlarmActionsEnabledHook>>>> = ({
    signal,
  }) => getCloudwatchAlarmActionsEnabled(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetCloudwatchAlarmActionsEnabledQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCloudwatchAlarmActionsEnabledHook>>>
>;
export type GetCloudwatchAlarmActionsEnabledQueryError =
  | BadRequestResponse
  | KillSwitchInternalServerErrorResponse
  | DefaultResponse;

/**
 * @summary fetch alarm actions enabled flag
 */
export const useGetCloudwatchAlarmActionsEnabled = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCloudwatchAlarmActionsEnabledHook>>>,
  TError = BadRequestResponse | KillSwitchInternalServerErrorResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetCloudwatchAlarmActionsEnabledHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCloudwatchAlarmActionsEnabledQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Set the alarm missing data state of the cloudwatch alarm
 */
export const useSetCloudwatchAlarmActionsEnabledHook = () => {
  const setCloudwatchAlarmActionsEnabled = useCustomInstance<void>();

  return (params: SetCloudwatchAlarmActionsEnabledParams) => {
    return setCloudwatchAlarmActionsEnabled({
      url: `/killswitch/cloudwatchalarm/actions-enabled`,
      method: 'put',
      params,
    });
  };
};

export const useSetCloudwatchAlarmActionsEnabledMutationOptions = <
  TError = BadRequestResponse | KillSwitchInternalServerErrorResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetCloudwatchAlarmActionsEnabledHook>>>,
    TError,
    { params: SetCloudwatchAlarmActionsEnabledParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetCloudwatchAlarmActionsEnabledHook>>>,
  TError,
  { params: SetCloudwatchAlarmActionsEnabledParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setCloudwatchAlarmActionsEnabled = useSetCloudwatchAlarmActionsEnabledHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetCloudwatchAlarmActionsEnabledHook>>>,
    { params: SetCloudwatchAlarmActionsEnabledParams }
  > = props => {
    const { params } = props ?? {};

    return setCloudwatchAlarmActionsEnabled(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetCloudwatchAlarmActionsEnabledMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetCloudwatchAlarmActionsEnabledHook>>>
>;

export type SetCloudwatchAlarmActionsEnabledMutationError =
  | BadRequestResponse
  | KillSwitchInternalServerErrorResponse
  | DefaultResponse;

/**
 * @summary Set the alarm missing data state of the cloudwatch alarm
 */
export const useSetCloudwatchAlarmActionsEnabled = <
  TError = BadRequestResponse | KillSwitchInternalServerErrorResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetCloudwatchAlarmActionsEnabledHook>>>,
    TError,
    { params: SetCloudwatchAlarmActionsEnabledParams },
    TContext
  >;
}) => {
  const mutationOptions = useSetCloudwatchAlarmActionsEnabledMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the state of the kill switch
 */
export const useGetKillSwitchStateHook = () => {
  const getKillSwitchState = useCustomInstance<KillSwitchStateResponseSchema>();

  return (signal?: AbortSignal) => {
    return getKillSwitchState({ url: `/killswitch`, method: 'get', signal });
  };
};

export const getGetKillSwitchStateQueryKey = () => [`/killswitch`] as const;

export const useGetKillSwitchStateQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetKillSwitchStateHook>>>,
  TError = BadRequestResponse | KillSwitchInternalServerErrorResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetKillSwitchStateHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetKillSwitchStateHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetKillSwitchStateQueryKey();

  const getKillSwitchState = useGetKillSwitchStateHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetKillSwitchStateHook>>>> = ({ signal }) =>
    getKillSwitchState(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetKillSwitchStateQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetKillSwitchStateHook>>>
>;
export type GetKillSwitchStateQueryError = BadRequestResponse | KillSwitchInternalServerErrorResponse | DefaultResponse;

/**
 * @summary Get the state of the kill switch
 */
export const useGetKillSwitchState = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetKillSwitchStateHook>>>,
  TError = BadRequestResponse | KillSwitchInternalServerErrorResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetKillSwitchStateHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetKillSwitchStateQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Set the state of the kill switch by triggering a lambda
 */
export const useSetKillSwitchStateHook = () => {
  const setKillSwitchState = useCustomInstance<void>();

  return (params: SetKillSwitchStateParams) => {
    return setKillSwitchState({ url: `/killswitch`, method: 'put', params });
  };
};

export const useSetKillSwitchStateMutationOptions = <
  TError = BadRequestResponse | ForbiddenResponse | KillSwitchInternalServerErrorResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetKillSwitchStateHook>>>,
    TError,
    { params: SetKillSwitchStateParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetKillSwitchStateHook>>>,
  TError,
  { params: SetKillSwitchStateParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setKillSwitchState = useSetKillSwitchStateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetKillSwitchStateHook>>>,
    { params: SetKillSwitchStateParams }
  > = props => {
    const { params } = props ?? {};

    return setKillSwitchState(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetKillSwitchStateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetKillSwitchStateHook>>>
>;

export type SetKillSwitchStateMutationError =
  | BadRequestResponse
  | ForbiddenResponse
  | KillSwitchInternalServerErrorResponse
  | DefaultResponse;

/**
 * @summary Set the state of the kill switch by triggering a lambda
 */
export const useSetKillSwitchState = <
  TError = BadRequestResponse | ForbiddenResponse | KillSwitchInternalServerErrorResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetKillSwitchStateHook>>>,
    TError,
    { params: SetKillSwitchStateParams },
    TContext
  >;
}) => {
  const mutationOptions = useSetKillSwitchStateMutationOptions(options);

  return useMutation(mutationOptions);
};
