import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { Box, useTheme } from '@mui/material';

import { DashboardCardHeight, DashboardCardProps, DashboardCardWidth } from '../hooks';

interface Props {
  cards: DashboardCardProps[];
}

const DashboardGameCardsSection = ({ cards }: Props) => {
  const theme = useTheme();

  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 1200: 2 }}>
      <Masonry gutter="10px">
        {cards?.map((card, index) => (
          <Box
            key={`${card.key}-${index}`}
            sx={{
              [theme.breakpoints.up('xs')]: {
                width: card.width || DashboardCardWidth.FULL,
                '.MuiCardContent-root': {
                  padding: 0.5,
                  marginTop: 0.1,
                },
                '.MuiDataGrid-footerContainer': {
                  marginTop: 0.5,
                },
              },
              [theme.breakpoints.up('lg')]: {
                height: card.height || DashboardCardHeight.SMALL,
                '.MuiPaper-root': { height: DashboardCardWidth.FULL },
                '.MuiCardContent-root': {
                  height: DashboardCardWidth.FULL,
                  padding: 1,
                  marginTop: 1,
                  '.MuiBox-root': { height: card.key !== 'gameSessionsCard' ? DashboardCardWidth.FULL : 'initial' },
                  '.MuiDataGrid-footerContainer': {
                    marginTop: 2,
                  },
                },
              },
            }}
          >
            {card.component}
          </Box>
        ))}
      </Masonry>
    </ResponsiveMasonry>
  );
};

export default DashboardGameCardsSection;
