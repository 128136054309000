import { useParams } from 'react-router-dom';
import { AuthorizationPermissions } from '@greenisland-core/permissions';
import { useGetAgentDetails, useGetListAgentRoles, useGetPermissions } from '@greenisland-store/authorization';
import { Box, CircularProgress } from '@mui/material';

import { usePermission } from '../../../../../../../app/hooks';
import SetAgentForm from './SetAgentForm';

const AgentForm = () => {
  const { agentId = '' } = useParams();
  const canReadAgentDetails = usePermission(AuthorizationPermissions.getAgentDetails);

  const { data: agent, isLoading: isAgentDataLoading } = useGetAgentDetails(agentId, {
    query: { enabled: !!agentId && canReadAgentDetails },
  });
  const { data: agentRoles, isLoading: isAgentRolesLoading } = useGetListAgentRoles();
  const { data: agentPermissions, isLoading: isAgentPermissionsLoading } = useGetPermissions();

  if (isAgentDataLoading || isAgentRolesLoading || isAgentPermissionsLoading)
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress color="success" />
      </Box>
    );

  return <SetAgentForm agentId={agentId} agent={agent} agentRoles={agentRoles} agentPermissions={agentPermissions} />;
};

export default AgentForm;
