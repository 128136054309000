import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import {
  MultiplierTournamentSchema,
  TournamentStatusFilterParamParameter,
  useGetMultiplierTournamentWithFilters,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useDebouncedValue } from '@lilib/hooks';
import { Box, capitalize, LinearProgress, Stack, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';

import {
  CheckboxIcon,
  DataGridContainer,
  DataGridPagination,
  FontAwesomeIcon,
  PermissionWrapper,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import EditMultiplierTournament from './components/Forms/EditMultiplierTournament';
import MultiplierTournamentFilterBar from './components/MultiplierTournamentFilterBar';
import { getDateTimeFromUnix } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';

const PAGE_SIZE = 25;

const MultiplierTournaments = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [selectedMultiplierTournament, setSelectedMultiplierTournament] = useState<MultiplierTournamentSchema | null>(
    null
  );
  const [isEdit, setIsEdit] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [tournamentStatusFilter, setTournamentStatusFilter] = useState<TournamentStatusFilterParamParameter>(
    TournamentStatusFilterParamParameter.All
  );
  const canReadMultiplierTournaments = usePermission(OnlineCasinoPermissions.getMultiplierTournamentWithFilters);
  const canEditMultiplierTournaments = usePermission(OnlineCasinoPermissions.updateMultiplierTournament);
  const [debouncedSearch] = useDebouncedValue(search, { wait: 500 });

  const params = {
    pageSize: pageSize,
    page: page,
    tournamentStatusFilter,
    ...(debouncedSearch.length > 0 ? { nameLikeFilter: debouncedSearch } : {}),
  };

  const {
    data: multiplierTournaments,
    isLoading,
    isError,
  } = useGetMultiplierTournamentWithFilters(params, {
    query: { enabled: canReadMultiplierTournaments, keepPreviousData: true },
  });

  const columns = useMemo<GridColumns<MultiplierTournamentSchema>>(
    () => [
      {
        field: 'display',
        headerName: t('content.gameserver.tournamentconfiguration.active'),
        type: 'boolean',
        flex: 0.1,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'name',
        headerName: capitalize(t('content.gameserver.tournamentconfiguration.tournamentName')),
        sortable: false,
        minWidth: 250,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'multiplierTournamentStatus',
        headerName: capitalize(t('status')),
        sortable: false,
        minWidth: 150,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        headerName: capitalize(t('content.gameserver.tournamentconfiguration.validFrom')),
        field: 'start',
        renderCell: params => {
          const startDate = params.value;
          if (startDate) {
            const formattedDaysDate = getDateTimeFromUnix(startDate).split(' ')[0];
            const formattedHoursDate = getDateTimeFromUnix(startDate).split(' ')[1];
            return (
              <Box>
                <Typography variant="body2">{formattedDaysDate}</Typography>
                <Typography variant="body2" color="GrayText">
                  {formattedHoursDate}
                </Typography>
              </Box>
            );
          }
          return <Typography variant="body2">-</Typography>;
        },
        minWidth: 150,
        flex: 0.2,
      },
      {
        headerName: capitalize(t('content.gameserver.tournamentconfiguration.validTo')),
        field: 'end',
        renderCell: params => {
          const endDate = params.value;
          if (endDate) {
            const formattedDaysDate = getDateTimeFromUnix(endDate).split(' ')[0];
            const formattedHoursDate = getDateTimeFromUnix(endDate).split(' ')[1];
            return (
              <Box>
                <Typography variant="body2">{formattedDaysDate}</Typography>
                <Typography variant="body2" color="GrayText">
                  {formattedHoursDate}
                </Typography>
              </Box>
            );
          }
          return <Typography variant="body2">-</Typography>;
        },
        minWidth: 150,
        flex: 0.2,
      },
      {
        field: 'totalPrizePool',
        headerName: capitalize(t('prizePool')),
        sortable: true,
        minWidth: 150,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.value} &euro;</Typography>;
        },
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 150,
        flex: 0.1,
        headerName: t('content.gameserver.tournamentconfiguration.actions'),
        getActions: (params: GridRowParams<MultiplierTournamentSchema>) => [
          <>
            {canEditMultiplierTournaments && (
              <Tooltip title={t('content.gameserver.tournamentconfiguration.edit')} key="details">
                <>
                  <GridActionsCellItem
                    title={t('content.gameserver.tournamentconfiguration.edit')}
                    label={t('content.gameserver.tournamentconfiguration.edit')}
                    icon={<FontAwesomeIcon icon={faPencil} />}
                    onClick={() => {
                      if (params?.id) {
                        setIsEdit(true);
                        setSelectedMultiplierTournament(params.row);
                      }
                    }}
                    key="edit"
                  />
                </>
              </Tooltip>
            )}
          </>,
        ],
      },
    ],
    [canEditMultiplierTournaments, t]
  );

  const rows = useMemo(
    () =>
      multiplierTournaments?.tournaments?.map<MultiplierTournamentSchema>(tournament => ({
        ...tournament,
      })) ?? [],
    [multiplierTournaments?.tournaments]
  );

  return (
    <Stack spacing={2}>
      <PermissionWrapper
        errorMessage={t('contests.multiplierTournaments.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getMultiplierTournamentWithFilters}
      >
        <>
          <MultiplierTournamentFilterBar
            setSearch={setSearch}
            tournamentStatusFilter={tournamentStatusFilter}
            setTournamentStatusFilter={setTournamentStatusFilter}
          />
          <DataGridContainer>
            <StyledDataGrid
              density="compact"
              autoHeight
              rowHeight={80}
              loading={isLoading}
              rows={rows}
              columns={columns}
              pagination
              page={page}
              rowCount={multiplierTournaments?.pagingDetails?.totalItems}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
              disableDensitySelector
              disableSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
              disableChildrenSorting
              disableChildrenFiltering
              disableMultipleColumnsSorting
              disableColumnResize
              disableColumnReorder
              isRowSelectable={() => false}
              components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
          {canEditMultiplierTournaments && selectedMultiplierTournament ? (
            <EditMultiplierTournament
              open={isEdit}
              setOpen={setIsEdit}
              selectedMultiplierTournament={selectedMultiplierTournament}
              setSelectedMultiplierTournament={setSelectedMultiplierTournament}
              filterParams={params}
            />
          ) : null}
        </>
      </PermissionWrapper>
    </Stack>
  );
};

export default MultiplierTournaments;
