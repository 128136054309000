import { PlayingBreakSchema } from '@greenisland-api';
import { capitalize, Card, CardContent, CardHeader, Tooltip } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { t } from 'i18next';
import { getDateFromUnix, StatusSpan } from 'src/app/helpers/transformFunctions';

import { DataGridContainer, StyledDataGrid } from '@greenisland-common/components/atoms';

interface Props {
  playingBreak: PlayingBreakSchema;
}

const PlayingBreakDetail = ({ playingBreak }: Props) => {
  const detailsHeaders: GridColumns = [
    {
      headerName: capitalize(t('cancelType')),
      flex: 0.1,
      field: 'requestType',
      renderCell: ({ value }) => (
        <Tooltip title={capitalize(t(value))}>
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{capitalize(t(value))}</span>
        </Tooltip>
      ),
    },
    {
      headerName: capitalize(t('cancelReason')),
      flex: 0.2,
      field: 'reason',
      renderCell: ({ value }) => {
        const formattedValue = value ? capitalize(value) : '-';
        return (
          <Tooltip title={formattedValue}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{formattedValue}</span>
          </Tooltip>
        );
      },
    },
    {
      headerName: capitalize(t('status')),
      flex: 0.1,
      field: 'status',
      renderCell: ({ value }) => <StatusSpan value={value} />,
    },
    {
      headerName: capitalize(t('decisionDate')),
      flex: 0.1,
      field: 'decisionDate',
      renderCell: ({ value }) => (value ? getDateFromUnix(value) : '-'),
    },
    {
      headerName: capitalize(t('decisionBy')),
      flex: 0.2,
      field: 'decisionBy',
      renderCell: ({ value }) => (
        <Tooltip title={capitalize(t(value))}>
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{capitalize(t(value))}</span>
        </Tooltip>
      ),
    },
    {
      headerName: capitalize(t('decisionReason')),
      flex: 0.3,
      field: 'additionalData',
      renderCell: ({ value }) => {
        const formattedValue = value ? capitalize(value) : '-';
        return (
          <Tooltip title={formattedValue}>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{formattedValue}</span>
          </Tooltip>
        );
      },
    },
  ];

  const detailRows: GridRowsProp = playingBreak.cancellationRequests.map((cancelRequest, index) => {
    return {
      ...cancelRequest,
      ...cancelRequest.approvalDecision,
      id: index,
    };
  });

  return (
    <Card sx={{ m: 2 }}>
      <CardHeader sx={{ mb: 1 }} title={t('settings.playingBreak.titles.cancellationRequests')} />
      <CardContent>
        <DataGridContainer>
          <StyledDataGrid
            columns={detailsHeaders}
            rows={detailRows}
            hideFooter
            autoHeight
            density="compact"
            disableDensitySelector
            disableSelectionOnClick
          />
        </DataGridContainer>
      </CardContent>
    </Card>
  );
};

export default PlayingBreakDetail;
