import { ERankSchema, ImageSchema, PromotionalCalendarUpdateBaseSchema } from '@greenisland-api';

enum PromotionalCalendarActionMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

interface PromotionalCalendarLoyaltyRankOption {
  value: string;
  label: string;
}

interface PromotionalCalendarPrizeRank {
  loyaltyRank: ERankSchema;
  name: string;
  description: string;
  redirectUrl: string;
  images: ImageSchema[] | null;
  actionId: string;
  id?: number;
}

interface PromotionalCalendarPrize {
  dayOrdinal: number;
  activeTimestamp: number;
  endTimestamp: number;
  activeDate: Date | null;
  endDate: Date | null;
  ranks: PromotionalCalendarPrizeRank[];
}

interface CalendarPromotionDialogSchema extends PromotionalCalendarUpdateBaseSchema {
  prizes: PromotionalCalendarPrize[];
}

export type {
  CalendarPromotionDialogSchema,
  PromotionalCalendarLoyaltyRankOption,
  PromotionalCalendarPrizeRank,
  PromotionalCalendarPrize,
};

export { PromotionalCalendarActionMode };
