import {
  EBoostTypeSchema,
  EOperatorJackpotTypeSchema,
  EPromotionNameConfigurationBadRequestTypeSchema,
  EPromotionNameConfigurationTypeSchema,
} from '@greenisland-api';

enum PromotionNameConfigurationActionMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

const PROMOTION_NAME_CONFIGURATION_TITLES = {
  [EPromotionNameConfigurationTypeSchema.Boost]: 'boosts',
  [EPromotionNameConfigurationTypeSchema.OperatorJackpot]: 'jackpots',
};

const PROMOTION_NAME_CONFIGURATION_TYPE_OPTIONS = {
  [EPromotionNameConfigurationTypeSchema.Boost]: Object.keys(EBoostTypeSchema),
  [EPromotionNameConfigurationTypeSchema.OperatorJackpot]: Object.keys(EOperatorJackpotTypeSchema),
};

const PROMOTION_NAME_CONFIGURATION_COLUMN_FIELDS = {
  [EPromotionNameConfigurationTypeSchema.Boost]: 'boostType',
  [EPromotionNameConfigurationTypeSchema.OperatorJackpot]: 'operatorJackpotType',
};

type PromotionalNameConfigurationErrorReasons = {
  [key in EPromotionNameConfigurationBadRequestTypeSchema]: string;
};

const PROMOTIONAL_NAME_CONFIGURATION_ERROR_REASONS: PromotionalNameConfigurationErrorReasons = {
  [EPromotionNameConfigurationBadRequestTypeSchema.InvalidArgument]:
    'settings.configurations.promotionalNameConfigurations.errors.invalidArgument',
};

export {
  PROMOTION_NAME_CONFIGURATION_TITLES,
  PROMOTION_NAME_CONFIGURATION_TYPE_OPTIONS,
  PROMOTION_NAME_CONFIGURATION_COLUMN_FIELDS,
  PromotionNameConfigurationActionMode,
  PROMOTIONAL_NAME_CONFIGURATION_ERROR_REASONS,
};
