/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  BalanceLimitUpdateRequestResultSchema,
  CancelMaxLimitRequestBodySchema,
  CurrentBalanceLimitSchema,
  DefaultResponse,
  ErrorSchema,
  GetBalanceLimitsParams,
  GetBalanceLimitUpdateRequestsParams,
  MaxBalanceLimitSchema,
  MaxBalanceLimitsSchema,
  MaxLimitBadRequestResponse,
  NotFoundResponse,
  PagedBalanceLimitsSchema,
  PagedBalanceLimitUpdateRequestsSchema,
  UnauthorizedResponse,
  UpdateBalanceLimitRequestSchema,
  UpdateMaxBalanceLimitSchema,
  UserLimitUpdateRequestManualDecisionArgumentsSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Request to update balance limit. A request does not mean that this balance limit will be set in the future, as it may be declined. An update to the balance limit can take place immediately (if a lower balance limit value is requested) or in the future (after the approval process).
 * @summary Request an update for balance limit.
 */
export const useRequestBalanceLimitUpdateHook = () => {
  const requestBalanceLimitUpdate = useCustomInstance<BalanceLimitUpdateRequestResultSchema>();

  return (updateBalanceLimitRequestSchema: UpdateBalanceLimitRequestSchema) => {
    return requestBalanceLimitUpdate({
      url: `/balanceLimits/requests`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateBalanceLimitRequestSchema,
    });
  };
};

export const useRequestBalanceLimitUpdateMutationOptions = <TError = ErrorSchema, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRequestBalanceLimitUpdateHook>>>,
    TError,
    { data: UpdateBalanceLimitRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRequestBalanceLimitUpdateHook>>>,
  TError,
  { data: UpdateBalanceLimitRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const requestBalanceLimitUpdate = useRequestBalanceLimitUpdateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRequestBalanceLimitUpdateHook>>>,
    { data: UpdateBalanceLimitRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return requestBalanceLimitUpdate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RequestBalanceLimitUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRequestBalanceLimitUpdateHook>>>
>;
export type RequestBalanceLimitUpdateMutationBody = UpdateBalanceLimitRequestSchema;
export type RequestBalanceLimitUpdateMutationError = ErrorSchema;

/**
 * @summary Request an update for balance limit.
 */
export const useRequestBalanceLimitUpdate = <TError = ErrorSchema, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRequestBalanceLimitUpdateHook>>>,
    TError,
    { data: UpdateBalanceLimitRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useRequestBalanceLimitUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves balance limit update requests.
 * @summary Retrieve balance limit update requests.
 */
export const useGetBalanceLimitUpdateRequestsHook = () => {
  const getBalanceLimitUpdateRequests = useCustomInstance<PagedBalanceLimitUpdateRequestsSchema>();

  return (params?: GetBalanceLimitUpdateRequestsParams, signal?: AbortSignal) => {
    return getBalanceLimitUpdateRequests({ url: `/balanceLimits/requests`, method: 'get', params, signal });
  };
};

export const getGetBalanceLimitUpdateRequestsQueryKey = (params?: GetBalanceLimitUpdateRequestsParams) =>
  [`/balanceLimits/requests`, ...(params ? [params] : [])] as const;

export const useGetBalanceLimitUpdateRequestsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBalanceLimitUpdateRequestsHook>>>,
  TError = ErrorSchema
>(
  params?: GetBalanceLimitUpdateRequestsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetBalanceLimitUpdateRequestsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBalanceLimitUpdateRequestsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBalanceLimitUpdateRequestsQueryKey(params);

  const getBalanceLimitUpdateRequests = useGetBalanceLimitUpdateRequestsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBalanceLimitUpdateRequestsHook>>>> = ({
    signal,
  }) => getBalanceLimitUpdateRequests(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetBalanceLimitUpdateRequestsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBalanceLimitUpdateRequestsHook>>>
>;
export type GetBalanceLimitUpdateRequestsQueryError = ErrorSchema;

/**
 * @summary Retrieve balance limit update requests.
 */
export const useGetBalanceLimitUpdateRequests = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBalanceLimitUpdateRequestsHook>>>,
  TError = ErrorSchema
>(
  params?: GetBalanceLimitUpdateRequestsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetBalanceLimitUpdateRequestsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBalanceLimitUpdateRequestsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves balance limits.
 * @summary Retrieve balance limits.
 */
export const useGetBalanceLimitsHook = () => {
  const getBalanceLimits = useCustomInstance<PagedBalanceLimitsSchema>();

  return (params?: GetBalanceLimitsParams, signal?: AbortSignal) => {
    return getBalanceLimits({ url: `/balanceLimits`, method: 'get', params, signal });
  };
};

export const getGetBalanceLimitsQueryKey = (params?: GetBalanceLimitsParams) =>
  [`/balanceLimits`, ...(params ? [params] : [])] as const;

export const useGetBalanceLimitsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBalanceLimitsHook>>>,
  TError = ErrorSchema
>(
  params?: GetBalanceLimitsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBalanceLimitsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBalanceLimitsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBalanceLimitsQueryKey(params);

  const getBalanceLimits = useGetBalanceLimitsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBalanceLimitsHook>>>> = ({ signal }) =>
    getBalanceLimits(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetBalanceLimitsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetBalanceLimitsHook>>>>;
export type GetBalanceLimitsQueryError = ErrorSchema;

/**
 * @summary Retrieve balance limits.
 */
export const useGetBalanceLimits = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBalanceLimitsHook>>>,
  TError = ErrorSchema
>(
  params?: GetBalanceLimitsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBalanceLimitsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBalanceLimitsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves balance limit.
 * @summary Retrieve the active balance limit for the user.
 */
export const useGetActiveBalanceLimitForUserHook = () => {
  const getActiveBalanceLimitForUser = useCustomInstance<CurrentBalanceLimitSchema>();

  return (userId: number, signal?: AbortSignal) => {
    return getActiveBalanceLimitForUser({ url: `/balanceLimits/active/${userId}`, method: 'get', signal });
  };
};

export const getGetActiveBalanceLimitForUserQueryKey = (userId: number) => [`/balanceLimits/active/${userId}`] as const;

export const useGetActiveBalanceLimitForUserQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActiveBalanceLimitForUserHook>>>,
  TError = ErrorSchema
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveBalanceLimitForUserHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveBalanceLimitForUserHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveBalanceLimitForUserQueryKey(userId);

  const getActiveBalanceLimitForUser = useGetActiveBalanceLimitForUserHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetActiveBalanceLimitForUserHook>>>> = ({
    signal,
  }) => getActiveBalanceLimitForUser(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetActiveBalanceLimitForUserQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetActiveBalanceLimitForUserHook>>>
>;
export type GetActiveBalanceLimitForUserQueryError = ErrorSchema;

/**
 * @summary Retrieve the active balance limit for the user.
 */
export const useGetActiveBalanceLimitForUser = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActiveBalanceLimitForUserHook>>>,
  TError = ErrorSchema
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveBalanceLimitForUserHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetActiveBalanceLimitForUserQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Manually approve a balance limit update request
 * @summary Manually approve a balance limit update request
 */
export const useApproveBalanceLimitUpdateRequestManuallyHook = () => {
  const approveBalanceLimitUpdateRequestManually = useCustomInstance<void>();

  return (
    requestId: number,
    userLimitUpdateRequestManualDecisionArgumentsSchema: UserLimitUpdateRequestManualDecisionArgumentsSchema
  ) => {
    return approveBalanceLimitUpdateRequestManually({
      url: `/balanceLimits/requests/${requestId}/approve`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: userLimitUpdateRequestManualDecisionArgumentsSchema,
    });
  };
};

export const useApproveBalanceLimitUpdateRequestManuallyMutationOptions = <
  TError = ErrorSchema,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useApproveBalanceLimitUpdateRequestManuallyHook>>>,
    TError,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useApproveBalanceLimitUpdateRequestManuallyHook>>>,
  TError,
  { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const approveBalanceLimitUpdateRequestManually = useApproveBalanceLimitUpdateRequestManuallyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useApproveBalanceLimitUpdateRequestManuallyHook>>>,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema }
  > = props => {
    const { requestId, data } = props ?? {};

    return approveBalanceLimitUpdateRequestManually(requestId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApproveBalanceLimitUpdateRequestManuallyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useApproveBalanceLimitUpdateRequestManuallyHook>>>
>;
export type ApproveBalanceLimitUpdateRequestManuallyMutationBody = UserLimitUpdateRequestManualDecisionArgumentsSchema;
export type ApproveBalanceLimitUpdateRequestManuallyMutationError = ErrorSchema;

/**
 * @summary Manually approve a balance limit update request
 */
export const useApproveBalanceLimitUpdateRequestManually = <TError = ErrorSchema, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useApproveBalanceLimitUpdateRequestManuallyHook>>>,
    TError,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useApproveBalanceLimitUpdateRequestManuallyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Manually decline a balance limit update request
 * @summary Manually decline a balance limit update request
 */
export const useDeclineBalanceLimitUpdateRequestManuallyHook = () => {
  const declineBalanceLimitUpdateRequestManually = useCustomInstance<void>();

  return (
    requestId: number,
    userLimitUpdateRequestManualDecisionArgumentsSchema: UserLimitUpdateRequestManualDecisionArgumentsSchema
  ) => {
    return declineBalanceLimitUpdateRequestManually({
      url: `/balanceLimits/requests/${requestId}/decline`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: userLimitUpdateRequestManualDecisionArgumentsSchema,
    });
  };
};

export const useDeclineBalanceLimitUpdateRequestManuallyMutationOptions = <
  TError = ErrorSchema,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeclineBalanceLimitUpdateRequestManuallyHook>>>,
    TError,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeclineBalanceLimitUpdateRequestManuallyHook>>>,
  TError,
  { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const declineBalanceLimitUpdateRequestManually = useDeclineBalanceLimitUpdateRequestManuallyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeclineBalanceLimitUpdateRequestManuallyHook>>>,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema }
  > = props => {
    const { requestId, data } = props ?? {};

    return declineBalanceLimitUpdateRequestManually(requestId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeclineBalanceLimitUpdateRequestManuallyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeclineBalanceLimitUpdateRequestManuallyHook>>>
>;
export type DeclineBalanceLimitUpdateRequestManuallyMutationBody = UserLimitUpdateRequestManualDecisionArgumentsSchema;
export type DeclineBalanceLimitUpdateRequestManuallyMutationError = ErrorSchema;

/**
 * @summary Manually decline a balance limit update request
 */
export const useDeclineBalanceLimitUpdateRequestManually = <TError = ErrorSchema, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeclineBalanceLimitUpdateRequestManuallyHook>>>,
    TError,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useDeclineBalanceLimitUpdateRequestManuallyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Sets requiresManualApproval flag to true for balance limit update request
 * @summary Sets requiresManualApproval flag to true for balance limit update request
 */
export const useSetBalanceLimitUpdateRequestToRequireManualApprovalHook = () => {
  const setBalanceLimitUpdateRequestToRequireManualApproval = useCustomInstance<void>();

  return (requestId: number) => {
    return setBalanceLimitUpdateRequestToRequireManualApproval({
      url: `/balanceLimits/requests/${requestId}/requiresmanualapproval`,
      method: 'patch',
    });
  };
};

export const useSetBalanceLimitUpdateRequestToRequireManualApprovalMutationOptions = <
  TError = ErrorSchema,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetBalanceLimitUpdateRequestToRequireManualApprovalHook>>>,
    TError,
    { requestId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetBalanceLimitUpdateRequestToRequireManualApprovalHook>>>,
  TError,
  { requestId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setBalanceLimitUpdateRequestToRequireManualApproval =
    useSetBalanceLimitUpdateRequestToRequireManualApprovalHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetBalanceLimitUpdateRequestToRequireManualApprovalHook>>>,
    { requestId: number }
  > = props => {
    const { requestId } = props ?? {};

    return setBalanceLimitUpdateRequestToRequireManualApproval(requestId);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetBalanceLimitUpdateRequestToRequireManualApprovalMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetBalanceLimitUpdateRequestToRequireManualApprovalHook>>>
>;

export type SetBalanceLimitUpdateRequestToRequireManualApprovalMutationError = ErrorSchema;

/**
 * @summary Sets requiresManualApproval flag to true for balance limit update request
 */
export const useSetBalanceLimitUpdateRequestToRequireManualApproval = <
  TError = ErrorSchema,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetBalanceLimitUpdateRequestToRequireManualApprovalHook>>>,
    TError,
    { requestId: number },
    TContext
  >;
}) => {
  const mutationOptions = useSetBalanceLimitUpdateRequestToRequireManualApprovalMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Sets the maximum balance limit for the user. If the user's balance limit is higher than the given value, automatically sets the user's limit to this max value.
 * @summary Sets the maximum balance limit for the user. If the user's balance limit is higher than the given value, automatically sets the user's limit to this max value.
 */
export const useSetMaximumBalanceLimitHook = () => {
  const setMaximumBalanceLimit = useCustomInstance<MaxBalanceLimitSchema>();

  return (userId: number, updateMaxBalanceLimitSchema: UpdateMaxBalanceLimitSchema) => {
    return setMaximumBalanceLimit({
      url: `/balanceLimits/${userId}/max`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateMaxBalanceLimitSchema,
    });
  };
};

export const useSetMaximumBalanceLimitMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetMaximumBalanceLimitHook>>>,
    TError,
    { userId: number; data: UpdateMaxBalanceLimitSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetMaximumBalanceLimitHook>>>,
  TError,
  { userId: number; data: UpdateMaxBalanceLimitSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setMaximumBalanceLimit = useSetMaximumBalanceLimitHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetMaximumBalanceLimitHook>>>,
    { userId: number; data: UpdateMaxBalanceLimitSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return setMaximumBalanceLimit(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetMaximumBalanceLimitMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetMaximumBalanceLimitHook>>>
>;
export type SetMaximumBalanceLimitMutationBody = UpdateMaxBalanceLimitSchema;
export type SetMaximumBalanceLimitMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Sets the maximum balance limit for the user. If the user's balance limit is higher than the given value, automatically sets the user's limit to this max value.
 */
export const useSetMaximumBalanceLimit = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetMaximumBalanceLimitHook>>>,
    TError,
    { userId: number; data: UpdateMaxBalanceLimitSchema },
    TContext
  >;
}) => {
  const mutationOptions = useSetMaximumBalanceLimitMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Gets the maximum balance limit for the user.
 * @summary Gets the maximum balance limit for the user.
 */
export const useGetMaximumBalanceLimitsHook = () => {
  const getMaximumBalanceLimits = useCustomInstance<MaxBalanceLimitsSchema>();

  return (userId: number, signal?: AbortSignal) => {
    return getMaximumBalanceLimits({ url: `/balanceLimits/${userId}/max`, method: 'get', signal });
  };
};

export const getGetMaximumBalanceLimitsQueryKey = (userId: number) => [`/balanceLimits/${userId}/max`] as const;

export const useGetMaximumBalanceLimitsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMaximumBalanceLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMaximumBalanceLimitsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMaximumBalanceLimitsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMaximumBalanceLimitsQueryKey(userId);

  const getMaximumBalanceLimits = useGetMaximumBalanceLimitsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMaximumBalanceLimitsHook>>>> = ({ signal }) =>
    getMaximumBalanceLimits(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetMaximumBalanceLimitsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMaximumBalanceLimitsHook>>>
>;
export type GetMaximumBalanceLimitsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Gets the maximum balance limit for the user.
 */
export const useGetMaximumBalanceLimits = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMaximumBalanceLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMaximumBalanceLimitsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMaximumBalanceLimitsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Cancels the maximum balance limit for the user.
 * @summary Cancels the maximum balance limit for the user.
 */
export const useCancelMaximumBalanceLimitHook = () => {
  const cancelMaximumBalanceLimit = useCustomInstance<void>();

  return (userId: number, cancelMaxLimitRequestBodySchema: CancelMaxLimitRequestBodySchema) => {
    return cancelMaximumBalanceLimit({
      url: `/balanceLimits/${userId}/max/cancel`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: cancelMaxLimitRequestBodySchema,
    });
  };
};

export const useCancelMaximumBalanceLimitMutationOptions = <
  TError = MaxLimitBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelMaximumBalanceLimitHook>>>,
    TError,
    { userId: number; data: CancelMaxLimitRequestBodySchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCancelMaximumBalanceLimitHook>>>,
  TError,
  { userId: number; data: CancelMaxLimitRequestBodySchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const cancelMaximumBalanceLimit = useCancelMaximumBalanceLimitHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCancelMaximumBalanceLimitHook>>>,
    { userId: number; data: CancelMaxLimitRequestBodySchema }
  > = props => {
    const { userId, data } = props ?? {};

    return cancelMaximumBalanceLimit(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelMaximumBalanceLimitMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCancelMaximumBalanceLimitHook>>>
>;
export type CancelMaximumBalanceLimitMutationBody = CancelMaxLimitRequestBodySchema;
export type CancelMaximumBalanceLimitMutationError =
  | MaxLimitBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Cancels the maximum balance limit for the user.
 */
export const useCancelMaximumBalanceLimit = <
  TError = MaxLimitBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelMaximumBalanceLimitHook>>>,
    TError,
    { userId: number; data: CancelMaxLimitRequestBodySchema },
    TContext
  >;
}) => {
  const mutationOptions = useCancelMaximumBalanceLimitMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Gets the active maximum balance limit for the user.
 * @summary Gets the active maximum balance limit for the user.
 */
export const useGetActiveMaximumBalanceLimitHook = () => {
  const getActiveMaximumBalanceLimit = useCustomInstance<MaxBalanceLimitSchema>();

  return (userId: number, signal?: AbortSignal) => {
    return getActiveMaximumBalanceLimit({ url: `/balancelimits/${userId}/max/active`, method: 'get', signal });
  };
};

export const getGetActiveMaximumBalanceLimitQueryKey = (userId: number) =>
  [`/balancelimits/${userId}/max/active`] as const;

export const useGetActiveMaximumBalanceLimitQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumBalanceLimitHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumBalanceLimitHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumBalanceLimitHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveMaximumBalanceLimitQueryKey(userId);

  const getActiveMaximumBalanceLimit = useGetActiveMaximumBalanceLimitHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumBalanceLimitHook>>>> = ({
    signal,
  }) => getActiveMaximumBalanceLimit(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetActiveMaximumBalanceLimitQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumBalanceLimitHook>>>
>;
export type GetActiveMaximumBalanceLimitQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Gets the active maximum balance limit for the user.
 */
export const useGetActiveMaximumBalanceLimit = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumBalanceLimitHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumBalanceLimitHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetActiveMaximumBalanceLimitQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
