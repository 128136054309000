import { ReadPermission, WritePermission } from '@greenisland-core/permissions';
import { EProductTypeSchema } from '@greenisland-store/authorization';

export const AuthorizationPermissions = {
  ping: new ReadPermission('/ping', EProductTypeSchema.Authorization),
  login: new WritePermission('/authorization/login', EProductTypeSchema.Authorization),
  loginTokens: new ReadPermission('/authorization/login/$logincode', EProductTypeSchema.Authorization),
  logoff: new WritePermission('/authorization/logoff', EProductTypeSchema.Authorization),
  loginWithChallenge: new WritePermission('/authorization/loginchallenge', EProductTypeSchema.Authorization),
  resetPassword: new WritePermission('/authorization/resetpassword', EProductTypeSchema.Authorization),
  changePassword: new WritePermission('/authorization/changepassword', EProductTypeSchema.Authorization),
  getStartup: new ReadPermission('/authorization/startup', EProductTypeSchema.Authorization),
  getListEntities: new ReadPermission('/authorization/entities', EProductTypeSchema.Authorization),
  addEntity: new WritePermission('/authorization/entities', EProductTypeSchema.Authorization),
  getEntityDetails: new ReadPermission('/authorization/entities/$entityid', EProductTypeSchema.Authorization),
  updateEntity: new WritePermission('/authorization/entities/$entityid', EProductTypeSchema.Authorization),
  deleteEntity: new WritePermission('/authorization/entities/$entityid', EProductTypeSchema.Authorization),
  getListProducts: new ReadPermission('/authorization/products', EProductTypeSchema.Authorization),
  addProduct: new WritePermission('/authorization/products', EProductTypeSchema.Authorization),
  getProductDetails: new ReadPermission('/authorization/products/$productid', EProductTypeSchema.Authorization),
  updateProduct: new WritePermission('/authorization/products/$productid', EProductTypeSchema.Authorization),
  deleteProduct: new WritePermission('/authorization/products/$productid', EProductTypeSchema.Authorization),
  getCurrentPermissions: new ReadPermission('/authorization/permissions/current', EProductTypeSchema.Authorization),
  getPermissions: new ReadPermission('/authorization/permissions', EProductTypeSchema.Authorization),
  listPermissionPropertyNames: new ReadPermission(
    '/authorization/permissions/name/$permissionid',
    EProductTypeSchema.Authorization
  ),
  addNamePermissions: new WritePermission(
    '/authorization/permissions/name/$permissionid',
    EProductTypeSchema.Authorization
  ),
  deleteNamePermissions: new WritePermission(
    '/authorization/permissions/name/$permissionid',
    EProductTypeSchema.Authorization
  ),
  getListAgents: new ReadPermission('/authorization/agents', EProductTypeSchema.Authorization),
  addAgent: new WritePermission('/authorization/agents', EProductTypeSchema.Authorization),
  getCurrentAgent: new ReadPermission('/authorization/agents/current', EProductTypeSchema.Authorization),
  getAgentDetails: new ReadPermission('/authorization/agents/$agentid', EProductTypeSchema.Authorization),
  updateAgent: new WritePermission('/authorization/agents/$agentid', EProductTypeSchema.Authorization),
  deleteAgent: new WritePermission('/authorization/agents/$agentid', EProductTypeSchema.Authorization),
  deleteAgentEntityReference: new WritePermission(
    '/authorization/agents/$agentid/$entityid',
    EProductTypeSchema.Authorization
  ),
  resetAgentPassword: new WritePermission(
    '/authorization/agents/$agentid/resetpassword',
    EProductTypeSchema.Authorization
  ),
  lockAgent: new WritePermission('/authorization/agents/$agentid/lock', EProductTypeSchema.Authorization),
  unlockAgent: new WritePermission('/authorization/agents/$agentid/lock', EProductTypeSchema.Authorization),
  getListAgentRoles: new ReadPermission('/authorization/agentroles', EProductTypeSchema.Authorization),
  addAgentRole: new WritePermission('/authorization/agentroles', EProductTypeSchema.Authorization),
  getAgentRoleDetails: new ReadPermission('/authorization/agentroles/$agentroleid', EProductTypeSchema.Authorization),
  updateAgentRole: new WritePermission('/authorization/agentroles/$agentroleid', EProductTypeSchema.Authorization),
  deleteAgentRole: new WritePermission('/authorization/agentroles/$agentroleid', EProductTypeSchema.Authorization),
  getAuditLogs: new WritePermission('/authorization/audit', EProductTypeSchema.Authorization),
};

export type AuthorizationPermissionTypes = keyof typeof AuthorizationPermissions;
