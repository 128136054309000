import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  ComplaintEntrySchema,
  EAuthorTypeSchema,
  getGetComplaintTicketQueryKey,
  useArchiveComplaintEntry,
} from '@greenisland-api';
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { getDateTimeFromUnix } from 'src/app/helpers/transformFunctions';

import ComplaintEntryMenu from './ComplaintEntryMenu';
import EditComplaintEntryDialog from './EditComplaintEntryDialog';

export default function ComplaintEntryItem({
  complaintId,
  complaintEntry,
  hasPermission,
  editable,
}: {
  complaintId: number;
  complaintEntry: ComplaintEntrySchema;
  hasPermission: boolean;
  editable: boolean;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const archiveComplaintEntryMutation = useArchiveComplaintEntry({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetComplaintTicketQueryKey(complaintId));
      },
    },
  });

  const handleDeleteClick = () => {
    confirm({
      title: t('tasks.complaints.deleteEntry'),
      description: t('tasks.complaints.deleteEntryDescription', { title: complaintEntry.content }),
      confirmationButtonProps: { color: 'error', variant: 'contained' },
      confirmationText: t('tasks.complaints.deleteEntryButton'),
    }).then(() =>
      archiveComplaintEntryMutation.mutate({
        complaintEntryId: complaintEntry.id,
        complaintTicketId: complaintId,
      })
    );
  };

  const handleEdit = () => {
    setIsEditOpen(true);
  };

  return (
    <>
      <ListItem
        sx={{
          backgroundColor: complaintEntry.authorType === EAuthorTypeSchema.Agent ? '#F5F6F9' : '#E6F2EC',
        }}
        key={complaintEntry.id}
        secondaryAction={
          hasPermission && editable ? (
            <Box display="flex" alignItems="center">
              {complaintEntry.authorType === EAuthorTypeSchema.Agent ? (
                <Box
                  mr={2.25}
                  fontSize={10}
                  color="#4579B5"
                  textTransform="uppercase"
                  fontWeight={700}
                  display={['none', 'block']}
                >
                  {complaintEntry.agent.slice(0, complaintEntry.agent.indexOf('('))}
                </Box>
              ) : null}
              <ComplaintEntryMenu onDelete={handleDeleteClick} onEdit={handleEdit} />
            </Box>
          ) : complaintEntry.authorType === EAuthorTypeSchema.Agent ? (
            <Box
              mr={2.25}
              fontSize={10}
              color="#4579B5"
              textTransform="uppercase"
              fontWeight={700}
              display={['none', 'block']}
            >
              {complaintEntry.agent.slice(0, complaintEntry.agent.indexOf('('))}
            </Box>
          ) : null
        }
      >
        {!isMobile ? (
          <ListItemAvatar>
            <Avatar
              sx={{
                backgroundColor: complaintEntry.authorType === EAuthorTypeSchema.Agent ? '#4579B5' : '#549041',
                fontSize: '10px',
                lineHeight: '12px',
              }}
            >
              {complaintEntry.authorType}
            </Avatar>
          </ListItemAvatar>
        ) : null}
        <ListItemText
          sx={{ ml: isMobile ? 0 : 2 }}
          primary={complaintEntry.content}
          primaryTypographyProps={{ width: isMobile ? '100%' : '75%' }}
          secondary={
            isMobile ? (
              <Box display="flex" flexDirection="column">
                <Box fontSize={10} my={0.5} color="#4579B5" textTransform="uppercase" fontWeight={700}>
                  {complaintEntry.agent.slice(0, complaintEntry.agent.indexOf('('))}
                </Box>
                {getDateTimeFromUnix(complaintEntry.date)}
              </Box>
            ) : (
              getDateTimeFromUnix(complaintEntry.date)
            )
          }
        />
      </ListItem>
      {isEditOpen ? (
        <EditComplaintEntryDialog
          complaintId={complaintId}
          entryAuthor={complaintEntry.authorType}
          entryContent={complaintEntry.content as string}
          entryId={complaintEntry.id}
          setOpen={setIsEditOpen}
          open={isEditOpen}
        />
      ) : null}
    </>
  );
}
