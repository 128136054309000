import '../../../../../../common/helpers/prettyPrintJson/prettyPrintCss.css';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import {
  EDepositStatusSchema,
  EPaymentMethodSchema,
  EPaymentProviderSchema,
  getGetDepositDetailsQueryKey,
  useChargeBackDepositManually,
  useCompleteDepositManually,
  useConfirmDepositDisputeLost,
  useConfirmDepositDisputeWon,
  useFailDeposit,
  useGetDepositDetails,
  useMarkDepositSafe,
  useRefundDepositManually,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardContent, CardHeader, Stack, styled, Tab, Tabs, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

import { Link, StyledDataGrid, TabPanel } from '@greenisland-common/components/atoms';

import { prettyPrintJson } from '@greenisland-common/helpers';

import { formatCurrency, transformUnixDateV2 } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../../LookupPermissions';
import MarkAsSafeWithCrendetialsDialog from './MarkAsSafeWithCredentialsDialog';

const NicknameTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h5.fontSize,
  letterSpacing: theme.typography.h5.letterSpacing,
  fontWeight: theme.typography.h5.fontWeight,
}));

const DetailsGrid = styled(Box)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  letterSpacing: theme.typography.body1.letterSpacing,
  fontWeight: theme.typography.body1.fontWeight,
  display: 'grid',
  gridGap: 32,
  textTransform: 'capitalize',
  gridTemplateColumns: 'auto auto auto auto',
  alignItems: 'start',
  whiteSpace: 'nowrap',
  overflowX: 'scroll',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const UserDepositDetails = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const [isAddOpen, setIsAddOpen] = useState(false);

  const { userId = '', depositId = '' } = useParams();
  const [stream, setStream] = useState<number>(0);
  const canLookupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });
  const canCompleteDeposit = usePermission(OnlineCasinoPermissions.completeDepositManually);
  const canRefundDeposit = usePermission(OnlineCasinoPermissions.refundDepositManually);
  const canReadUserDepositDetails = usePermission(OnlineCasinoPermissions.getDepositDetails);
  const canConfirmDisputeWon = usePermission(OnlineCasinoPermissions.confirmDepositDisputeWon);
  const canConfirmDisputeLost = usePermission(OnlineCasinoPermissions.confirmDepositDisputeLost);
  const canChargeBackManually = usePermission(OnlineCasinoPermissions.chargeBackDepositManually);
  const canFailManually = usePermission(OnlineCasinoPermissions.failDeposit);
  const canMarkSafe = usePermission(OnlineCasinoPermissions.markDepositSafe);
  const { data: depositDetails, isLoading: isLoadingDepositDetails } = useGetDepositDetails(
    userId,
    depositId,
    undefined,
    {
      query: {
        enabled: canReadUserDepositDetails,
        onError: () => {
          enqueueSnackbar(t('deposits.errors.loadingDetails'), { variant: 'error' });
        },
      },
    }
  );
  const { mutate: completeManually, isLoading: isCompletingManually } = useCompleteDepositManually({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetDepositDetailsQueryKey(userId, depositId));
        enqueueSnackbar(t('deposits.completedManually'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('deposits.errors.completingManually'), { variant: 'error' });
      },
    },
  });
  const { mutate: refundManually, isLoading: isRefundingManually } = useRefundDepositManually({
    mutation: {
      onSuccess: data => {
        if (data.success) {
          queryClient.invalidateQueries(getGetDepositDetailsQueryKey(userId, depositId));
          enqueueSnackbar(t('deposits.refundedManually'), { variant: 'success' });
        } else {
          enqueueSnackbar(`${t('deposits.errors.refundingManually')}: ${data.errorReason}`, { variant: 'error' });
        }
      },
      onError: () => {
        enqueueSnackbar(t('deposits.errors.refundingManually'), { variant: 'error' });
      },
    },
  });
  const { mutate: confirmDisputeWon, isLoading: isConfirmingDisputeWon } = useConfirmDepositDisputeWon({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetDepositDetailsQueryKey(userId, depositId));
        enqueueSnackbar(t('deposits.confirmedDisputeWon'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('deposits.errors.confirmingDisputeWon'), { variant: 'error' });
      },
    },
  });
  const { mutate: confirmDisputeLost, isLoading: isConfirmingDisputeLost } = useConfirmDepositDisputeLost({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetDepositDetailsQueryKey(userId, depositId));
        enqueueSnackbar(t('deposits.confirmedDisputeLost'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('deposits.errors.confirmingDisputeLost'), { variant: 'error' });
      },
    },
  });
  const { mutate: chargeBackManually, isLoading: isChargingBackManually } = useChargeBackDepositManually({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetDepositDetailsQueryKey(userId, depositId));
        enqueueSnackbar(t('deposits.chargeBackManually'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('deposits.errors.chargingBackManually'), { variant: 'error' });
      },
    },
  });
  const { isLoading: isMarkingSafe } = useMarkDepositSafe({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetDepositDetailsQueryKey(userId, depositId));
        enqueueSnackbar(t('deposits.markAsSafe'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('deposits.errors.markAsSafe'), { variant: 'error' });
      },
    },
  });
  const { mutate: failManually, isLoading: isFailingManually } = useFailDeposit({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetDepositDetailsQueryKey(userId, depositId));
        enqueueSnackbar(t('deposits.failManually'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('deposits.errors.failManually'), { variant: 'error' });
      },
    },
  });

  const isLoading =
    isLoadingDepositDetails ||
    isCompletingManually ||
    isRefundingManually ||
    isConfirmingDisputeWon ||
    isConfirmingDisputeLost ||
    isChargingBackManually ||
    isFailingManually ||
    isMarkingSafe;

  const getPrettyPrintValue = (value: string) => {
    try {
      return JSON.parse(value);
    } catch (e: any) {
      return value;
    }
  };
  const mutatedMessageData = useMemo(() => {
    if (!depositDetails?.messages || depositDetails.messages.length === 0) return { columns: [], rows: [] };
    const columns = Object.keys(depositDetails.messages[0]).map(key => {
      switch (key) {
        case 'payload':
          return {
            field: key,
            headerName: t(key),
            flex: 1,
            renderCell: (params: any) => (
              <pre
                dangerouslySetInnerHTML={{
                  __html: prettyPrintJson(getPrettyPrintValue(params.value), { linkUrls: true }),
                }}
              />
            ),
            sortable: false,
          };
        case 'timestamp':
          return transformUnixDateV2(key, t, true);
        default:
          return { field: key, headerName: t(key), flex: 1 };
      }
    });

    const rows = depositDetails.messages.map((item, index) => ({ ...item, id: index }));
    return { columns, rows };
  }, [depositDetails, t]);

  const mutatedEventStreamData = useMemo(() => {
    if (!depositDetails?.events || depositDetails.events.length === 0) return { columns: [], rows: [] };
    const columns = Object.keys(depositDetails.events[0]).map(key => {
      switch (key) {
        case 'payload':
          return {
            field: key,
            headerName: t(key),
            flex: 1,
            renderCell: (params: any) => (
              <pre
                dangerouslySetInnerHTML={{
                  __html: prettyPrintJson(getPrettyPrintValue(params.value), { linkUrls: true }),
                }}
              />
            ),
            sortable: false,
          };
        case 'timestamp':
          return transformUnixDateV2(key, t, true);
        default:
          return { field: key, headerName: t(key), flex: 1 };
      }
    });

    const rows = depositDetails.events.map((item, index) => ({ ...item, id: index }));
    return { columns, rows };
  }, [depositDetails, t]);

  const handleCompleteManually = () => {
    confirm({
      title: t('deposits.confirmations.complete.title'),
      description: t('deposits.confirmations.complete.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('deposits.confirmations.proceed'),
    }).then(() => completeManually({ userId, depositId }));
  };

  const handleRefundManually = (
    amount: number,
    paymentMethod: EPaymentMethodSchema,
    provider: EPaymentProviderSchema
  ) => {
    confirm({
      title: t('deposits.confirmations.refund.title'),
      description: t('deposits.confirmations.refund.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('deposits.confirmations.proceed'),
    }).then(() =>
      refundManually({
        data: {
          amount: amount,
          currency: 'EUR',
          paymentMethod: paymentMethod,
          provider: provider,
          depositId,
        },
      })
    );
  };

  const handleChargeBackManually = () => {
    confirm({
      title: t('deposits.confirmations.chargeback.title'),
      description: t('deposits.confirmations.chargeback.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('deposits.confirmations.proceed'),
    }).then(() => chargeBackManually({ depositId }));
  };

  const handleDisputeWon = () => {
    confirm({
      title: t('deposits.confirmations.dispute.title'),
      description: t('deposits.confirmations.dispute.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('deposits.confirmations.proceed'),
    }).then(() => confirmDisputeWon({ depositId }));
  };

  const handleDisputeLost = () => {
    confirm({
      title: t('deposits.confirmations.dispute.title'),
      description: t('deposits.confirmations.dispute.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('deposits.confirmations.proceed'),
    }).then(() => confirmDisputeLost({ depositId }));
  };

  const handleFailManually = () => {
    confirm({
      title: t('deposits.confirmations.fail.title'),
      description: t('deposits.confirmations.fail.description'),
      confirmationButtonProps: { color: 'primary', variant: 'contained' },
      confirmationText: t('deposits.confirmations.proceed'),
    }).then(() => failManually({ depositId }));
  };

  const completeManuallyStatuses: EDepositStatusSchema[] = [
    EDepositStatusSchema.Started,
    EDepositStatusSchema.Pending,
    EDepositStatusSchema.Failed,
    EDepositStatusSchema.Declined,
    EDepositStatusSchema.Refused,
  ];
  const showCompleteManuallyButton =
    canCompleteDeposit && depositDetails?.status && completeManuallyStatuses.includes(depositDetails.status);

  const refundManuallyStatuses: EDepositStatusSchema[] = [EDepositStatusSchema.Refused];
  const showRefundManuallyButton =
    canRefundDeposit && depositDetails?.status && refundManuallyStatuses.includes(depositDetails.status);

  const disputeStatuses: EDepositStatusSchema[] = [
    EDepositStatusSchema.ChargedBack,
    EDepositStatusSchema.ChargedBackManually,
  ];
  const showConfirmDisputeWonButton =
    canConfirmDisputeWon && depositDetails?.status && disputeStatuses.includes(depositDetails.status);
  const showConfirmDisputeLostButton =
    canConfirmDisputeLost && depositDetails?.status && disputeStatuses.includes(depositDetails.status);

  const chargeBackManuallyStatuses: EDepositStatusSchema[] = [
    EDepositStatusSchema.Completed,
    EDepositStatusSchema.ManuallyCompleted,
  ];
  const showChargeBackManuallyButton =
    canChargeBackManually && depositDetails?.status && chargeBackManuallyStatuses.includes(depositDetails.status);
  const showMarkAsSafeButton = canMarkSafe && depositDetails?.suspiciousDepositFlags;
  const failManuallyStatuses: EDepositStatusSchema[] = [EDepositStatusSchema.Pending];
  const showFailManuallyButton =
    canFailManually && depositDetails?.status && failManuallyStatuses.includes(depositDetails.status);

  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader>{t('details')}</CardHeader>
        <CardContent>
          {isLoading ? (
            <Typography>{t('loading')}</Typography>
          ) : !depositDetails ? (
            <Typography>{t('noData')}</Typography>
          ) : (
            <>
              <DetailsGrid>
                <div>
                  <NicknameTypography>
                    {canLookupUser ? (
                      <Link to={`../../users/${userId}/details`}>{depositDetails.nickname}</Link>
                    ) : (
                      depositDetails.nickname
                    )}
                  </NicknameTypography>
                  <Typography variant="body2">
                    {t('depositId')} - {depositId}
                  </Typography>
                </div>
                <div>
                  <Typography>{formatCurrency(depositDetails.amount)}</Typography>
                  <Typography>
                    {depositDetails.provider} - {depositDetails.paymentMethod}
                  </Typography>
                </div>
                <div>
                  <Typography>{t('status')}:</Typography>
                  <Typography variant="subtitle1">{depositDetails.status}</Typography>
                  {depositDetails.suspiciousDepositFlags && (
                    <div>
                      <Typography>{t('suspisicousFlags')}:</Typography>
                      {depositDetails.suspiciousDepositFlags.map((flag, index) => (
                        <Typography key={index} variant="subtitle2">
                          {flag}
                        </Typography>
                      ))}
                    </div>
                  )}
                </div>
                <div>
                  <Typography align="center">{t('deposits.actions.title')}:</Typography>
                  <Stack direction="column" spacing={1} p={1}>
                    {showCompleteManuallyButton && (
                      <Button onClick={handleCompleteManually} variant="contained">
                        {t('deposits.actions.complete')}
                      </Button>
                    )}
                    {showRefundManuallyButton && (
                      <Button
                        onClick={() =>
                          handleRefundManually(
                            depositDetails.amount,
                            depositDetails.paymentMethod as EPaymentMethodSchema,
                            depositDetails.provider as EPaymentProviderSchema
                          )
                        }
                        variant="contained"
                      >
                        {t('deposits.actions.refund')}
                      </Button>
                    )}
                    {showConfirmDisputeWonButton && (
                      <Button onClick={handleDisputeWon} variant="contained">
                        {t('deposits.actions.confirmDisputeWon')}
                      </Button>
                    )}
                    {showConfirmDisputeLostButton && (
                      <Button onClick={handleDisputeLost} variant="contained" color="error">
                        {t('deposits.actions.confirmDisputeLost')}
                      </Button>
                    )}
                    {showChargeBackManuallyButton && (
                      <Button onClick={handleChargeBackManually} variant="contained">
                        {t('deposits.actions.chargeBackManually')}
                      </Button>
                    )}
                    {showMarkAsSafeButton && (
                      <Button onClick={() => setIsAddOpen(true)} variant="contained">
                        {t('deposits.actions.markAsSafe')}
                      </Button>
                    )}
                    {showFailManuallyButton && (
                      <Button onClick={handleFailManually} variant="contained">
                        {t('deposits.actions.failManually')}
                      </Button>
                    )}
                    <MarkAsSafeWithCrendetialsDialog
                      userId={userId}
                      depositId={depositId}
                      isOpen={isAddOpen}
                      onClose={() => setIsAddOpen(false)}
                    />
                    {!showCompleteManuallyButton &&
                      !showRefundManuallyButton &&
                      !showConfirmDisputeWonButton &&
                      !showConfirmDisputeLostButton &&
                      !showChargeBackManuallyButton &&
                      !showMarkAsSafeButton && (
                        <Typography align="center" variant="body2">
                          {t('deposits.actions.noActionsRequired')}
                        </Typography>
                      )}
                  </Stack>
                </div>
              </DetailsGrid>
            </>
          )}
        </CardContent>
      </Card>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={stream} onChange={(event, newValue) => setStream(newValue)}>
          <Tab label="Messages" value={0} />
          <Tab label="Events" value={1} />
        </Tabs>
      </Box>
      <TabPanel value={stream} index={0}>
        <StyledDataGrid
          columns={mutatedMessageData.columns}
          rows={mutatedMessageData.rows}
          autoHeight
          getRowHeight={() => 'auto'}
        />
      </TabPanel>
      <TabPanel value={stream} index={1}>
        <StyledDataGrid
          columns={mutatedEventStreamData.columns}
          rows={mutatedEventStreamData.rows}
          autoHeight
          getRowHeight={() => 'auto'}
        />
      </TabPanel>
    </Stack>
  );
};

export default UserDepositDetails;
