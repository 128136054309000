import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';

import type { IndividualActionForm } from '../context';
import IndividualActionFormListItem from './IndividualActionFormListItem';

const ContestForm = () => {
  const { t } = useTranslation();
  const { register, watch, setValue, errors } = useFormContext<IndividualActionForm>();
  const enabledContest = watch('enabledContest');

  useEffect(() => {
    if (!enabledContest) {
      setValue('questionContest', '');
    }
  }, [setValue, enabledContest]);

  return (
    <IndividualActionFormListItem enabled={enabledContest} name="enabledContest" listItemText={t('contestQuestion')}>
      <TextField
        fullWidth
        size="small"
        inputRef={register({ required: enabledContest, minLength: 1 })}
        name="questionContest"
        disabled={!enabledContest}
        error={Boolean(errors.questionContest)}
        required={enabledContest}
        label={t('questionContest')}
      />
    </IndividualActionFormListItem>
  );
};

export default ContestForm;
