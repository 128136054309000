/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EPaymentServiceVersionSchema =
  (typeof EPaymentServiceVersionSchema)[keyof typeof EPaymentServiceVersionSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EPaymentServiceVersionSchema = {
  Portal: 'Portal',
  PaymentsV1: 'PaymentsV1',
  PaymentsV2: 'PaymentsV2',
} as const;
