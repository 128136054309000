/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  GameRoundReplay,
  GetTournamentParticipationGameRoundsParams,
  QueryParticipationParams,
  QueryTournamentsParams,
  TournamentParticipationGameRoundsPaginated,
  TournamentParticipationGameRoundTransaction,
  TournamentParticipationPaginated,
  TournamentParticipationTransaction,
  TournamentsPaginated,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Get list of tournaments
 */
export const useQueryTournamentsHook = () => {
  const queryTournaments = useCustomInstance<TournamentsPaginated>();

  return (params?: QueryTournamentsParams, signal?: AbortSignal) => {
    return queryTournaments({ url: `/tournaments`, method: 'get', params, signal });
  };
};

export const getQueryTournamentsQueryKey = (params?: QueryTournamentsParams) =>
  [`/tournaments`, ...(params ? [params] : [])] as const;

export const useQueryTournamentsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useQueryTournamentsHook>>>,
  TError = unknown
>(
  params?: QueryTournamentsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryTournamentsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryTournamentsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQueryTournamentsQueryKey(params);

  const queryTournaments = useQueryTournamentsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useQueryTournamentsHook>>>> = ({ signal }) =>
    queryTournaments(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type QueryTournamentsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useQueryTournamentsHook>>>>;
export type QueryTournamentsQueryError = unknown;

export const useQueryTournaments = <
  TData = Awaited<ReturnType<ReturnType<typeof useQueryTournamentsHook>>>,
  TError = unknown
>(
  params?: QueryTournamentsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryTournamentsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useQueryTournamentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get list Tournament Participation
 */
export const useQueryParticipationHook = () => {
  const queryParticipation = useCustomInstance<TournamentParticipationPaginated>();

  return (params?: QueryParticipationParams, signal?: AbortSignal) => {
    return queryParticipation({ url: `/tournaments/participation`, method: 'get', params, signal });
  };
};

export const getQueryParticipationQueryKey = (params?: QueryParticipationParams) =>
  [`/tournaments/participation`, ...(params ? [params] : [])] as const;

export const useQueryParticipationQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useQueryParticipationHook>>>,
  TError = unknown
>(
  params?: QueryParticipationParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryParticipationHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryParticipationHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQueryParticipationQueryKey(params);

  const queryParticipation = useQueryParticipationHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useQueryParticipationHook>>>> = ({ signal }) =>
    queryParticipation(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type QueryParticipationQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useQueryParticipationHook>>>
>;
export type QueryParticipationQueryError = unknown;

export const useQueryParticipation = <
  TData = Awaited<ReturnType<ReturnType<typeof useQueryParticipationHook>>>,
  TError = unknown
>(
  params?: QueryParticipationParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryParticipationHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useQueryParticipationQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get list of tournament participation transactions
 */
export const useGetTournamentParticipationTransactionsHook = () => {
  const getTournamentParticipationTransactions = useCustomInstance<TournamentParticipationTransaction[]>();

  return (tournamentParticipationId: number, signal?: AbortSignal) => {
    return getTournamentParticipationTransactions({
      url: `/tournaments/participation/${tournamentParticipationId}/transactions`,
      method: 'get',
      signal,
    });
  };
};

export const getGetTournamentParticipationTransactionsQueryKey = (tournamentParticipationId: number) =>
  [`/tournaments/participation/${tournamentParticipationId}/transactions`] as const;

export const useGetTournamentParticipationTransactionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationTransactionsHook>>>,
  TError = unknown
>(
  tournamentParticipationId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationTransactionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationTransactionsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTournamentParticipationTransactionsQueryKey(tournamentParticipationId);

  const getTournamentParticipationTransactions = useGetTournamentParticipationTransactionsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationTransactionsHook>>>
  > = ({ signal }) => getTournamentParticipationTransactions(tournamentParticipationId, signal);

  return { queryKey, queryFn, enabled: !!tournamentParticipationId, ...queryOptions };
};

export type GetTournamentParticipationTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationTransactionsHook>>>
>;
export type GetTournamentParticipationTransactionsQueryError = unknown;

export const useGetTournamentParticipationTransactions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationTransactionsHook>>>,
  TError = unknown
>(
  tournamentParticipationId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationTransactionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTournamentParticipationTransactionsQueryOptions(tournamentParticipationId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get list tournament participation game rounds
 */
export const useGetTournamentParticipationGameRoundsHook = () => {
  const getTournamentParticipationGameRounds = useCustomInstance<TournamentParticipationGameRoundsPaginated>();

  return (
    tournamentParticipationId: number,
    params?: GetTournamentParticipationGameRoundsParams,
    signal?: AbortSignal
  ) => {
    return getTournamentParticipationGameRounds({
      url: `/tournaments/participation/${tournamentParticipationId}/game-rounds`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getGetTournamentParticipationGameRoundsQueryKey = (
  tournamentParticipationId: number,
  params?: GetTournamentParticipationGameRoundsParams
) => [`/tournaments/participation/${tournamentParticipationId}/game-rounds`, ...(params ? [params] : [])] as const;

export const useGetTournamentParticipationGameRoundsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundsHook>>>,
  TError = unknown
>(
  tournamentParticipationId: number,
  params?: GetTournamentParticipationGameRoundsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetTournamentParticipationGameRoundsQueryKey(tournamentParticipationId, params);

  const getTournamentParticipationGameRounds = useGetTournamentParticipationGameRoundsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundsHook>>>> = ({
    signal,
  }) => getTournamentParticipationGameRounds(tournamentParticipationId, params, signal);

  return { queryKey, queryFn, enabled: !!tournamentParticipationId, ...queryOptions };
};

export type GetTournamentParticipationGameRoundsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundsHook>>>
>;
export type GetTournamentParticipationGameRoundsQueryError = unknown;

export const useGetTournamentParticipationGameRounds = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundsHook>>>,
  TError = unknown
>(
  tournamentParticipationId: number,
  params?: GetTournamentParticipationGameRoundsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTournamentParticipationGameRoundsQueryOptions(tournamentParticipationId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get list of tournament participation game round transactions
 */
export const useGetTournamentParticipationGameRoundTransactionsHook = () => {
  const getTournamentParticipationGameRoundTransactions =
    useCustomInstance<TournamentParticipationGameRoundTransaction[]>();

  return (tournamentParticipationId: number, tournamentParticipationGameRoundId: number, signal?: AbortSignal) => {
    return getTournamentParticipationGameRoundTransactions({
      url: `/tournaments/participation/${tournamentParticipationId}/game-rounds/${tournamentParticipationGameRoundId}/transactions`,
      method: 'get',
      signal,
    });
  };
};

export const getGetTournamentParticipationGameRoundTransactionsQueryKey = (
  tournamentParticipationId: number,
  tournamentParticipationGameRoundId: number
) =>
  [
    `/tournaments/participation/${tournamentParticipationId}/game-rounds/${tournamentParticipationGameRoundId}/transactions`,
  ] as const;

export const useGetTournamentParticipationGameRoundTransactionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundTransactionsHook>>>,
  TError = unknown
>(
  tournamentParticipationId: number,
  tournamentParticipationGameRoundId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundTransactionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundTransactionsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetTournamentParticipationGameRoundTransactionsQueryKey(
      tournamentParticipationId,
      tournamentParticipationGameRoundId
    );

  const getTournamentParticipationGameRoundTransactions = useGetTournamentParticipationGameRoundTransactionsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundTransactionsHook>>>
  > = ({ signal }) =>
    getTournamentParticipationGameRoundTransactions(
      tournamentParticipationId,
      tournamentParticipationGameRoundId,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(tournamentParticipationId && tournamentParticipationGameRoundId),
    ...queryOptions,
  };
};

export type GetTournamentParticipationGameRoundTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundTransactionsHook>>>
>;
export type GetTournamentParticipationGameRoundTransactionsQueryError = unknown;

export const useGetTournamentParticipationGameRoundTransactions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundTransactionsHook>>>,
  TError = unknown
>(
  tournamentParticipationId: number,
  tournamentParticipationGameRoundId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundTransactionsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTournamentParticipationGameRoundTransactionsQueryOptions(
    tournamentParticipationId,
    tournamentParticipationGameRoundId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get tournament game round replay launch url
 */
export const useGetTournamentParticipationGameRoundReplayHook = () => {
  const getTournamentParticipationGameRoundReplay = useCustomInstance<GameRoundReplay>();

  return (tournamentParticipationId: number, tournamentParticipationGameRoundId: number, signal?: AbortSignal) => {
    return getTournamentParticipationGameRoundReplay({
      url: `/tournaments/participation/${tournamentParticipationId}/game-rounds/${tournamentParticipationGameRoundId}/replay`,
      method: 'get',
      signal,
    });
  };
};

export const getGetTournamentParticipationGameRoundReplayQueryKey = (
  tournamentParticipationId: number,
  tournamentParticipationGameRoundId: number
) =>
  [
    `/tournaments/participation/${tournamentParticipationId}/game-rounds/${tournamentParticipationGameRoundId}/replay`,
  ] as const;

export const useGetTournamentParticipationGameRoundReplayQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundReplayHook>>>,
  TError = unknown
>(
  tournamentParticipationId: number,
  tournamentParticipationGameRoundId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundReplayHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundReplayHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetTournamentParticipationGameRoundReplayQueryKey(tournamentParticipationId, tournamentParticipationGameRoundId);

  const getTournamentParticipationGameRoundReplay = useGetTournamentParticipationGameRoundReplayHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundReplayHook>>>
  > = ({ signal }) =>
    getTournamentParticipationGameRoundReplay(tournamentParticipationId, tournamentParticipationGameRoundId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(tournamentParticipationId && tournamentParticipationGameRoundId),
    ...queryOptions,
  };
};

export type GetTournamentParticipationGameRoundReplayQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundReplayHook>>>
>;
export type GetTournamentParticipationGameRoundReplayQueryError = unknown;

export const useGetTournamentParticipationGameRoundReplay = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundReplayHook>>>,
  TError = unknown
>(
  tournamentParticipationId: number,
  tournamentParticipationGameRoundId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetTournamentParticipationGameRoundReplayHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTournamentParticipationGameRoundReplayQueryOptions(
    tournamentParticipationId,
    tournamentParticipationGameRoundId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
