import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useGetDepositsGeneral } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Card } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';
import { formatCurrency, getDateTimeFromUnix, StatusSpan } from 'src/app/helpers/transformFunctions';
import { usePermission } from 'src/app/hooks';
import useSearch from 'src/pages/OnlineCasino/Lookup/hooks/useSearch';

import { Link, StyledDataGrid } from '@greenisland-common/components/atoms';

interface Props {
  iban: string;
  startDate?: Date;
  endDate?: Date;
  userId?: string;
}

const DepositsTable = ({ iban, startDate, endDate, userId }: Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>('');
  const canSearchDeposists = usePermission(OnlineCasinoPermissions.getDepositsGeneral);
  const { data: deposists } = useGetDepositsGeneral(
    {
      iban,
      userId,
      ...(startDate ? { start: (startDate.getTime() ?? 0) / 1000 } : {}),
      ...(endDate ? { end: (endDate.getTime() ?? 0) / 1000 } : {}),
      pageNumber: page,
      numberOfItems: 20,
    },
    { query: { enabled: canSearchDeposists && !!userId } }
  );

  useSearch(data => {
    if (data && data.length > 0) {
      if (data.length === 1) {
        navigate(`../../users/${data[0].userId}/details`, {
          state: { name: 'User Details' },
        });
      }
      if (data.length > 1 && location.pathname) {
        navigate(`../../lookup?search=${searchValue}`, {
          state: { name: 'Lookup' },
        });
      }
    }
  });

  const headers: GridColumns = [
    {
      headerName: t('date'),
      field: 'timestamp',
      valueFormatter: ({ value }) => getDateTimeFromUnix(value),
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: t('value'),
      field: 'amount',
      valueFormatter: ({ value }) => formatCurrency(value),
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: t('destination'),
      field: 'destination',
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: t('status'),
      field: 'status',
      flex: 1,
      renderCell: ({ row }) => <StatusSpan value={row.status} />,
      minWidth: 100,
    },
    {
      headerName: t('accountId'),
      field: 'accountId',
      flex: 1,
      renderCell: ({ row }) => (
        <Button variant="text" onClick={() => setSearchValue(`accountId:${row.accountId}`)}>
          {row.accountId}
        </Button>
      ),
      minWidth: 100,
    },
    {
      headerName: '',
      field: 'depositId',
      renderCell: ({ value }) => <Link to={`../deposits/${value}`}>{t('details')}</Link>,
      flex: 1,
      minWidth: 100,
    },
  ];

  const rows = deposists?.items?.map((item, index) => ({ ...item, id: index })) ?? [];

  return (
    <Card sx={{ padding: 0 }}>
      <StyledDataGrid
        autoHeight
        columns={headers}
        rows={rows}
        pagination
        paginationMode="server"
        pageSize={20}
        page={deposists?.pagingDetails?.currentPage}
        rowCount={deposists?.pagingDetails?.totalItems}
        onPageChange={setPage}
      />
    </Card>
  );
};

export default DepositsTable;
