import { format, fromUnixTime } from 'date-fns';

export const formatCurrency = (amount: number, locale: string, currency?: string) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency ? currency : 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

export const formatDateTime = (date: Date, seconds = true) => {
  return format(date, `dd/MM/yyyy HH:mm${seconds ? ':ss' : ''}`);
};

export const formatDateTimeFromUnix = (date: number, seconds = true) => {
  return formatDateTime(fromUnixTime(date), seconds);
};

export const capitalize = <T extends string>(string: T) =>
  string && ((string.charAt(0).toUpperCase() + string.slice(1)) as Capitalize<T>);

export const uncapitalize = <T extends string>(string: T) =>
  string && ((string.charAt(0).toLowerCase() + string.slice(1)) as Uncapitalize<T>);

/**
 * Formats a number into a more readable string with appropriate suffixes (T, B, M, K).
 * The formatted string will be at most 4 characters long, including the suffix.
 *
 * @param num The number to format.
 * @returns A string representing the formatted number with a suffix.
 *
 * @example
 * // Returns "7.89M"
 * formatNumber(7890000);
 *
 * @example
 * // Returns "123"
 * formatNumber(123);
 *
 * @example
 * // Returns "12.3K"
 * formatNumber(12345); // Ensures it fits within 4 characters including the suffix
 */
export const formatCompactNumber = (num: number): string => {
  const TRILLION = 1e12;
  const BILLION = 1e9;
  const MILLION = 1e6;
  const THOUSAND = 1e3;

  let formattedNum: string;
  let suffix = '';

  if (num >= TRILLION) {
    formattedNum = (num / TRILLION).toPrecision(3);
    suffix = 'T';
  } else if (num >= BILLION) {
    formattedNum = (num / BILLION).toPrecision(3);
    suffix = 'B';
  } else if (num >= MILLION) {
    formattedNum = (num / MILLION).toPrecision(3);
    suffix = 'M';
  } else if (num >= THOUSAND) {
    formattedNum = (num / THOUSAND).toPrecision(3);
    suffix = 'K';
  } else {
    formattedNum = num.toString();
  }

  // Remove trailing zeros
  formattedNum = parseFloat(formattedNum).toString();

  // Ensure it fits within 4 characters including the suffix
  if (formattedNum.length + suffix.length > 4) {
    formattedNum = parseFloat(formattedNum)
      .toFixed(4 - suffix.length)
      .replace(/\.?0+$/, '');
  }

  return formattedNum + suffix;
};
