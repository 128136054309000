import { IndividualActionSchema } from '@greenisland-api';

import { convertToMediaImageSchema } from '@greenisland-common/helpers';
import { calculateTime } from '@greenisland-common/helpers/timeConverters';

import { BonusDurationsEnum } from '../Forms/helpers/DepositBonusFormContext';

export default function defaultIndividualActionFormValues(individualAction?: IndividualActionSchema) {
  return {
    name: individualAction?.name ?? '',
    enabledPlaythrough: individualAction?.playthroughBonus?.enabled || false,
    playthroughBonusName: individualAction?.playthroughBonus?.name || '',
    playthroughBonusExpirationDuration: individualAction?.playthroughBonus?.expiryDuration || undefined,
    playthroughBonusExpirationDurationSelectOption: BonusDurationsEnum.SECONDS,
    playthroughBonusContentFilterId: individualAction?.playthroughBonus?.contentFilterId || undefined,
    playthroughBonusImages: convertToMediaImageSchema(individualAction?.playthroughBonus.images),
    playthroughBonusAmountIsLoyaltyDependent: true,
    playthroughBonusPlaythroughIsLoyaltyDependent: true,
    playthroughBonusLoyaltyDependentAmountField: individualAction?.playthroughBonus?.loyaltyDependentAmount,
    playthroughBonusLoyaltyDependentPlaythroughField: individualAction?.playthroughBonus?.loyaltyDependentPlaythrough,
    enabledDeposit: individualAction?.depositBonus.enabled || false,
    percentageIsLoyaltyDependent: true,
    limitIsLoyaltyDependent: true,
    playthroughIsLoyaltyDependent: true,
    depositBonusName: individualAction?.depositBonus?.name || '',
    depositBonusExpirationDuration: individualAction?.depositBonus?.expiryDuration || undefined,
    depositBonusPlaythroughExpirationDuration: individualAction?.depositBonus?.expiryDurationDeposit || undefined,
    depositBonusContentFilterId: individualAction?.depositBonus?.contentFilterId || undefined,
    depositBonusImages: convertToMediaImageSchema(individualAction?.depositBonus.images),
    depositBonusExpirationDurationSelectOption: BonusDurationsEnum.SECONDS,
    depositBonusPlaythroughExpirationDurationSelectOption: BonusDurationsEnum.SECONDS,
    depositBonusAbsoluteExpiryDate: individualAction?.depositBonus?.absoluteExpiryDate
      ? new Date(individualAction?.depositBonus?.absoluteExpiryDate * 1000)
      : null,
    loyaltyDependentPercentageField: individualAction?.depositBonus?.loyaltyDependentPercentage,
    loyaltyDependentLimitField: individualAction?.depositBonus?.loyaltyDependentLimit,
    loyaltyDependentPlaythroughField: individualAction?.depositBonus?.loyaltyDependentPlaythrough,
    depositPercentage: individualAction?.depositBonus.depositPercentage || null,
    maximumDepositAmount: individualAction?.depositBonus.limit || null,
    expiresInDaysDeposit: individualAction?.depositBonus.expiresInDays || null,
    enabledFreeSpins: individualAction?.freeSpins.enabled || false,
    freeSpinsCampaign: individualAction?.freeSpins.freeSpinsCampaign || null,
    expiresInDaysFreeSpins: individualAction?.freeSpins.expiresInDays || null,
    enabledFreeBet: individualAction?.freeBet.enabled || false,
    amountFreeBet: individualAction?.freeBet.amount || null,
    currencyFreeBet: individualAction?.freeBet.currency || '',
    providerFreeBet: individualAction?.freeBet.provider || '',
    bonusFreeBet: individualAction?.freeBet.bonus || '',
    enabledFreeCash: individualAction?.freeCash.enabled || false,
    amountFreeCash: individualAction?.freeCash.amount || null,
    currencyFreeCash: individualAction?.freeCash.currency ?? '',
    freeSpinsImages: convertToMediaImageSchema(individualAction?.freeSpins?.images),
    enabledContest: individualAction?.contestQuestion.enabled || false,
    questionContest: individualAction?.contestQuestion.question || '',
    enabledPlain: individualAction?.plainCampaignMail.enabled || false,
    enabledTrustpilotEmail: individualAction?.trustpilotReviewEmail?.enabled || false,
    enabledLoyaltyPoints: individualAction?.loyaltyPoints.enabled || false,
    spendablePointsLoyaltyPoints: individualAction?.loyaltyPoints.spendablePoints || 0,
    statusPointsLoyaltyPoints: individualAction?.loyaltyPoints.statusPoints || 0,
    enabledVoucher: individualAction?.voucher.enabled || false,
    internalVoucher: individualAction?.voucher.internalVoucher || false,
    productGroupIdVoucher: individualAction?.voucher.productGroupId || null,
    productIdVoucher: individualAction?.voucher.productId || null,
    productValueVoucher: individualAction?.voucher.productValue || null,
    enabledTokens: individualAction?.vault?.enabled || false,
    amountOfTokens: individualAction?.vault?.amountOfTokens || 0,
    durationVoucher: individualAction?.voucher?.durationTicks
      ? calculateTime(individualAction.voucher.durationTicks, 'days')
      : null,
    boostName: individualAction?.boost?.name || '',
    boostEnabled: individualAction?.boost?.enabled || false,
    boostType: individualAction?.boost?.boostType || 'FixedTime',
    boostDuration: individualAction?.boost?.duration || undefined,
    boostExpiryDate: individualAction?.boost?.expiryDate || null,
    boostAggregationFunction: individualAction?.boost?.aggregationFunction || 'Max',
    boostFactor: individualAction?.boost?.factor ? individualAction?.boost?.factor * 100.0 : 100,
    boostPayoutType: individualAction?.boost?.payoutType || 'Cash',
    boostIsLoyaltyDependent: individualAction?.boost?.isLoyaltyDependent || false,
    boostMaxPayout: individualAction?.boost?.maxPayout || null,
    boostContentFilterId: individualAction?.boost?.contentFilterId || null,
    boostStartDate: individualAction?.boost?.startDate ? individualAction?.boost?.startDate * 1000 : null,
    boostExpirationDuration: individualAction?.boost?.expiryDuration || undefined,
    boostImages: convertToMediaImageSchema(individualAction?.boost.images),
    boostsEarlyMaxPayoutEnabled: individualAction?.boost?.earlyMaxPayoutEnabled,
    loyaltyDependentMaxPayout: individualAction?.boost?.loyaltyDependentMaxPayout,
    enabledBoosterPack: individualAction?.boosterPack?.enabled,
    boosterPackId: individualAction?.boosterPack?.boosterPackId,
  };
}
