/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type { GameRoundReplay, GameRoundTransaction } from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Get list of game round transactions
 * @summary GameRound Transactions
 */
export const useGetGameRoundTransactionsHook = () => {
  const getGameRoundTransactions = useCustomInstance<GameRoundTransaction[]>();

  return (gameRoundId: number, signal?: AbortSignal) => {
    return getGameRoundTransactions({
      url: `/cash-games/game-rounds/${gameRoundId}/transactions`,
      method: 'get',
      signal,
    });
  };
};

export const getGetGameRoundTransactionsQueryKey = (gameRoundId: number) =>
  [`/cash-games/game-rounds/${gameRoundId}/transactions`] as const;

export const useGetGameRoundTransactionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameRoundTransactionsHook>>>,
  TError = unknown
>(
  gameRoundId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameRoundTransactionsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameRoundTransactionsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameRoundTransactionsQueryKey(gameRoundId);

  const getGameRoundTransactions = useGetGameRoundTransactionsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameRoundTransactionsHook>>>> = ({
    signal,
  }) => getGameRoundTransactions(gameRoundId, signal);

  return { queryKey, queryFn, enabled: !!gameRoundId, ...queryOptions };
};

export type GetGameRoundTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGameRoundTransactionsHook>>>
>;
export type GetGameRoundTransactionsQueryError = unknown;

/**
 * @summary GameRound Transactions
 */
export const useGetGameRoundTransactions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameRoundTransactionsHook>>>,
  TError = unknown
>(
  gameRoundId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameRoundTransactionsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameRoundTransactionsQueryOptions(gameRoundId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get game round replay launch url
 * @summary GameRound replay
 */
export const useGetGameRoundReplayHook = () => {
  const getGameRoundReplay = useCustomInstance<GameRoundReplay>();

  return (gameRoundId: number, signal?: AbortSignal) => {
    return getGameRoundReplay({ url: `/cash-games/game-rounds/${gameRoundId}/replay`, method: 'get', signal });
  };
};

export const getGetGameRoundReplayQueryKey = (gameRoundId: number) =>
  [`/cash-games/game-rounds/${gameRoundId}/replay`] as const;

export const useGetGameRoundReplayQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameRoundReplayHook>>>,
  TError = unknown
>(
  gameRoundId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameRoundReplayHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameRoundReplayHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameRoundReplayQueryKey(gameRoundId);

  const getGameRoundReplay = useGetGameRoundReplayHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameRoundReplayHook>>>> = ({ signal }) =>
    getGameRoundReplay(gameRoundId, signal);

  return { queryKey, queryFn, enabled: !!gameRoundId, ...queryOptions };
};

export type GetGameRoundReplayQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGameRoundReplayHook>>>
>;
export type GetGameRoundReplayQueryError = unknown;

/**
 * @summary GameRound replay
 */
export const useGetGameRoundReplay = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameRoundReplayHook>>>,
  TError = unknown
>(
  gameRoundId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameRoundReplayHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameRoundReplayQueryOptions(gameRoundId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
