import { Box } from '@mui/material';

import VouchersBalance from './VouchersBalance';
import VouchersTransactions from './VouchersTransactions';

const VouchersBalanceTransactionsHistory = () => {
  return (
    <Box mt={3}>
      <VouchersBalance />
      <VouchersTransactions />
    </Box>
  );
};

export default VouchersBalanceTransactionsHistory;
