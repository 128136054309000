/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  CreateServiceContractSchema,
  DefaultResponse,
  ServiceContractBadRequestResponse,
  ServiceContractExtendedSchema,
  ServiceContractSchema,
  ServiceContractsOverviewSchema,
  UnauthorizedResponse,
  UpdateServiceContractSchema,
  UserServiceContractExtendedSchema,
  UserServiceContractsOverviewSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Create a new service contract.
 */
export const useCreateServiceContractHook = () => {
  const createServiceContract = useCustomInstance<ServiceContractSchema>();

  return (createServiceContractSchema: CreateServiceContractSchema) => {
    return createServiceContract({
      url: `/service-contracts`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createServiceContractSchema,
    });
  };
};

export const useCreateServiceContractMutationOptions = <
  TError = ServiceContractBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateServiceContractHook>>>,
    TError,
    { data: CreateServiceContractSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateServiceContractHook>>>,
  TError,
  { data: CreateServiceContractSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createServiceContract = useCreateServiceContractHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateServiceContractHook>>>,
    { data: CreateServiceContractSchema }
  > = props => {
    const { data } = props ?? {};

    return createServiceContract(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateServiceContractMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateServiceContractHook>>>
>;
export type CreateServiceContractMutationBody = CreateServiceContractSchema;
export type CreateServiceContractMutationError =
  | ServiceContractBadRequestResponse
  | UnauthorizedResponse
  | DefaultResponse;

/**
 * @summary Create a new service contract.
 */
export const useCreateServiceContract = <
  TError = ServiceContractBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateServiceContractHook>>>,
    TError,
    { data: CreateServiceContractSchema },
    TContext
  >;
}) => {
  const mutationOptions = useCreateServiceContractMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary get overview of the service contracts
 */
export const useGetServiceContractsHook = () => {
  const getServiceContracts = useCustomInstance<ServiceContractsOverviewSchema>();

  return (signal?: AbortSignal) => {
    return getServiceContracts({ url: `/service-contracts`, method: 'get', signal });
  };
};

export const getGetServiceContractsQueryKey = () => [`/service-contracts`] as const;

export const useGetServiceContractsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetServiceContractsHook>>>,
  TError = ServiceContractBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetServiceContractsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetServiceContractsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetServiceContractsQueryKey();

  const getServiceContracts = useGetServiceContractsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetServiceContractsHook>>>> = ({ signal }) =>
    getServiceContracts(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetServiceContractsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetServiceContractsHook>>>
>;
export type GetServiceContractsQueryError = ServiceContractBadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary get overview of the service contracts
 */
export const useGetServiceContracts = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetServiceContractsHook>>>,
  TError = ServiceContractBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetServiceContractsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetServiceContractsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get an extended overview of the service contract
 */
export const useGetServiceContractHook = () => {
  const getServiceContract = useCustomInstance<ServiceContractExtendedSchema>();

  return (contractId: number, signal?: AbortSignal) => {
    return getServiceContract({ url: `/service-contracts/${contractId}`, method: 'get', signal });
  };
};

export const getGetServiceContractQueryKey = (contractId: number) => [`/service-contracts/${contractId}`] as const;

export const useGetServiceContractQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetServiceContractHook>>>,
  TError = ServiceContractBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  contractId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetServiceContractHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetServiceContractHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetServiceContractQueryKey(contractId);

  const getServiceContract = useGetServiceContractHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetServiceContractHook>>>> = ({ signal }) =>
    getServiceContract(contractId, signal);

  return { queryKey, queryFn, enabled: !!contractId, ...queryOptions };
};

export type GetServiceContractQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetServiceContractHook>>>
>;
export type GetServiceContractQueryError = ServiceContractBadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Get an extended overview of the service contract
 */
export const useGetServiceContract = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetServiceContractHook>>>,
  TError = ServiceContractBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  contractId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetServiceContractHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetServiceContractQueryOptions(contractId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Add a new version to the contract
 */
export const useAddVersionToServiceContractHook = () => {
  const addVersionToServiceContract = useCustomInstance<ServiceContractExtendedSchema>();

  return (contractId: number, updateServiceContractSchema: UpdateServiceContractSchema) => {
    return addVersionToServiceContract({
      url: `/service-contracts/${contractId}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateServiceContractSchema,
    });
  };
};

export const useAddVersionToServiceContractMutationOptions = <
  TError = ServiceContractBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddVersionToServiceContractHook>>>,
    TError,
    { contractId: number; data: UpdateServiceContractSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddVersionToServiceContractHook>>>,
  TError,
  { contractId: number; data: UpdateServiceContractSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addVersionToServiceContract = useAddVersionToServiceContractHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddVersionToServiceContractHook>>>,
    { contractId: number; data: UpdateServiceContractSchema }
  > = props => {
    const { contractId, data } = props ?? {};

    return addVersionToServiceContract(contractId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddVersionToServiceContractMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddVersionToServiceContractHook>>>
>;
export type AddVersionToServiceContractMutationBody = UpdateServiceContractSchema;
export type AddVersionToServiceContractMutationError =
  | ServiceContractBadRequestResponse
  | UnauthorizedResponse
  | DefaultResponse;

/**
 * @summary Add a new version to the contract
 */
export const useAddVersionToServiceContract = <
  TError = ServiceContractBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddVersionToServiceContractHook>>>,
    TError,
    { contractId: number; data: UpdateServiceContractSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddVersionToServiceContractMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get overview of contracts signed by the user
 */
export const useGetServiceContractsForUserHook = () => {
  const getServiceContractsForUser = useCustomInstance<UserServiceContractsOverviewSchema>();

  return (userId: number, signal?: AbortSignal) => {
    return getServiceContractsForUser({ url: `/service-contracts/user/${userId}`, method: 'get', signal });
  };
};

export const getGetServiceContractsForUserQueryKey = (userId: number) => [`/service-contracts/user/${userId}`] as const;

export const useGetServiceContractsForUserQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetServiceContractsForUserHook>>>,
  TError = ServiceContractBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetServiceContractsForUserHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetServiceContractsForUserHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetServiceContractsForUserQueryKey(userId);

  const getServiceContractsForUser = useGetServiceContractsForUserHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetServiceContractsForUserHook>>>> = ({
    signal,
  }) => getServiceContractsForUser(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetServiceContractsForUserQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetServiceContractsForUserHook>>>
>;
export type GetServiceContractsForUserQueryError =
  | ServiceContractBadRequestResponse
  | UnauthorizedResponse
  | DefaultResponse;

/**
 * @summary Get overview of contracts signed by the user
 */
export const useGetServiceContractsForUser = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetServiceContractsForUserHook>>>,
  TError = ServiceContractBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetServiceContractsForUserHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetServiceContractsForUserQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get a detailed overview of a contract signed by the user
 */
export const useGetServiceContractForUserHook = () => {
  const getServiceContractForUser = useCustomInstance<UserServiceContractExtendedSchema>();

  return (contractId: number, userId: number, signal?: AbortSignal) => {
    return getServiceContractForUser({ url: `/service-contracts/${contractId}/user/${userId}`, method: 'get', signal });
  };
};

export const getGetServiceContractForUserQueryKey = (contractId: number, userId: number) =>
  [`/service-contracts/${contractId}/user/${userId}`] as const;

export const useGetServiceContractForUserQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetServiceContractForUserHook>>>,
  TError = ServiceContractBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  contractId: number,
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetServiceContractForUserHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetServiceContractForUserHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetServiceContractForUserQueryKey(contractId, userId);

  const getServiceContractForUser = useGetServiceContractForUserHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetServiceContractForUserHook>>>> = ({
    signal,
  }) => getServiceContractForUser(contractId, userId, signal);

  return { queryKey, queryFn, enabled: !!(contractId && userId), ...queryOptions };
};

export type GetServiceContractForUserQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetServiceContractForUserHook>>>
>;
export type GetServiceContractForUserQueryError =
  | ServiceContractBadRequestResponse
  | UnauthorizedResponse
  | DefaultResponse;

/**
 * @summary Get a detailed overview of a contract signed by the user
 */
export const useGetServiceContractForUser = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetServiceContractForUserHook>>>,
  TError = ServiceContractBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  contractId: number,
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetServiceContractForUserHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetServiceContractForUserQueryOptions(contractId, userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
