import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {
  BoosterPackCardUpdateSchema,
  EMediaGalleryEntryTypeSchema,
  useAddBoosterPackCard,
  useUpdateBoosterPackCard,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';
import { MediaFieldsWithPreview } from '@greenisland-common/components/molecules/MediaField';

import { useGetSortedAutocompleteCampaignActions } from '@greenisland-common/hooks/useGetSortedAutocompleteCampaignActions';

import {
  BoosterFormType,
  BoosterPackCardDialogSchema,
  useBoosterPackFormHandlers,
  useGetBoosterPackCardDefaultValues,
} from './helpers';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedCard?: BoosterPackCardDialogSchema;
}

const CreateBoosterPackCardForm = ({ open, onClose, selectedCard }: Props) => {
  const { t } = useTranslation();

  const { actions: boosterCardActions, isLoading: isLoadingActions } = useGetSortedAutocompleteCampaignActions();

  const methods = useForm<BoosterPackCardDialogSchema>({
    shouldFocusError: true,
    mode: 'onChange',
    defaultValues: useGetBoosterPackCardDefaultValues(selectedCard, boosterCardActions),
  });

  const { handleSubmit, reset, control, errors } = methods;

  const { onSuccessHandler, onErrorHandler } = useBoosterPackFormHandlers(
    BoosterFormType.BOOSTER_PACK_CARDS,
    onClose,
    reset
  );

  const { mutate: addBoosterPackCard, isLoading: isAddBoosterPackCardLoading } = useAddBoosterPackCard({
    mutation: {
      onSuccess: onSuccessHandler,
      onError: error => onErrorHandler(error),
    },
  });

  const { mutate: updateBoosterPackCard, isLoading: isUpdateBoosterPackCardLoading } = useUpdateBoosterPackCard({
    mutation: {
      onSuccess: onSuccessHandler,
      onError: error => onErrorHandler(error),
    },
  });

  const onSubmit = (data: BoosterPackCardDialogSchema) => {
    const payload = {
      ...data,
      imageIds: data?.boosterPackCardImages?.map(image => image?.id),
    } as BoosterPackCardUpdateSchema;

    if (selectedCard) {
      updateBoosterPackCard({ boosterPackCardId: selectedCard.id, data: payload });
    } else {
      addBoosterPackCard({ data: payload });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t(
          selectedCard
            ? 'marketing.boosterCards.titles.updateBoosterPackCard'
            : 'marketing.boosterCards.titles.addBoosterPackCard'
        )}
      </DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Typography fontWeight={500} variant="body1" fontSize="large" sx={{ pt: 1, mb: 1 }}>
                {capitalize(t('common.generalSettings'))}
              </Typography>
              <Box display="flex" flexDirection="column">
                <Input
                  size="small"
                  label={capitalize(t('name'))}
                  control={control}
                  type="text"
                  name="name"
                  defaultValue={selectedCard?.name}
                  required
                  rules={{ required: t('fieldIsRequired') }}
                  sx={{ mb: 2 }}
                />
                <Input
                  multiline
                  size="small"
                  label={capitalize(t('description'))}
                  control={control}
                  type="text"
                  name="description"
                  defaultValue={selectedCard?.description}
                  sx={{ mb: 2 }}
                  rules={{
                    maxLength: {
                      value: 255,
                      message: t('common.descriptionMaxLengthError'),
                    },
                  }}
                  error={!!errors?.description}
                  helperText={errors?.description ? errors.description.message : ''}
                />
                <Input
                  size="small"
                  label={capitalize(t('redirectUrl'))}
                  control={control}
                  type="url"
                  name="redirectUrl"
                  defaultValue={selectedCard?.redirectUrl}
                  sx={{ mb: 2 }}
                />
                <Controller
                  control={control}
                  name="actionId"
                  render={({ value, onChange }) => (
                    <Autocomplete
                      loading={isLoadingActions}
                      value={boosterCardActions?.find(option => option.value === value) ?? null}
                      onChange={(_event, value) => onChange(value?.value)}
                      options={boosterCardActions}
                      getOptionLabel={option => option.label ?? ''}
                      renderOption={(props, option) => (
                        <li {...props} key={option.value}>
                          {option.label}
                        </li>
                      )}
                      sx={{ mb: 2 }}
                      renderInput={params => (
                        <TextField required {...params} size="small" label={capitalize(t('actions'))} />
                      )}
                    />
                  )}
                />
              </Box>
              <Typography fontWeight={500} variant="body1" fontSize="large">
                {capitalize(t('imageUrl'))}
              </Typography>
              <MediaFieldsWithPreview
                control={control}
                size="small"
                key="boosterPackCardImages"
                name="boosterPackCardImages"
                error={Boolean(errors['boosterPackCardImages'])}
                type="text"
                required={false}
                label={capitalize(t('addImages'))}
                mediaGalleryEntryType={EMediaGalleryEntryTypeSchema.Template}
                isMultiSelect={true}
              />
            </Stack>
            <DialogActions>
              <Button variant="text" onClick={onClose} color="secondary">
                {t('cancel')}
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                variant="contained"
                loading={isAddBoosterPackCardLoading || isUpdateBoosterPackCardLoading}
              >
                {t('save')}
              </LoadingButton>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default CreateBoosterPackCardForm;
