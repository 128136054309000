import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  PlayerOverviewSchemaLanguagesItem,
  PlayerOverviewSchemaUniqueLoginsByCountryItem,
  useGetPlayerOverview,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Stack, styled, Typography } from '@mui/material';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

import colors from '../../../../../app/helpers/colors';
import { formatDecimal, transformCurrencyV2 } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const TooltipContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[500]}`,
}));

interface ChartData {
  name: string;
  value: number;
}
function isPlayerOverviewSchemaLanguagesItem(
  item: PlayerOverviewSchemaLanguagesItem | PlayerOverviewSchemaUniqueLoginsByCountryItem
): item is PlayerOverviewSchemaLanguagesItem {
  return (item as PlayerOverviewSchemaLanguagesItem).language !== undefined;
}

const PlayerOverview = () => {
  const { t } = useTranslation();
  const canReadPlayerOverview = usePermission(OnlineCasinoPermissions.getPlayerOverview);
  const { data: playerOverview, isLoading: fetching } = useGetPlayerOverview({
    query: { enabled: canReadPlayerOverview },
  });

  const mutatedActiveUsers = () => {
    if (!playerOverview || playerOverview.activeUsers.length === 0) return undefined;
    const entries = Object.entries(playerOverview.activeUsers[0]);
    const columns = entries.map(([key]) => {
      switch (key) {
        case 'promoBalance':
        case 'cashBalance':
          return transformCurrencyV2(key, t);
        default:
          return { field: key, headerName: t(key), flex: 1 };
      }
    });

    const rows = playerOverview.activeUsers.map((item, index) => ({
      ...item,
      id: index,
      username: <Link to={`../../../users/${item.userId}/details`}>{item.username}</Link>,
    }));

    return { columns, rows };
  };

  const constructLanguagesUniqueLoginsData = (data: {
    languages: PlayerOverviewSchemaLanguagesItem[];
    uniqueLoginsByCountry: PlayerOverviewSchemaUniqueLoginsByCountryItem[];
  }): JSX.Element[] => {
    return Object.entries(data).map(([key, entry]) => {
      let totals = 0;
      const dataToRender: ChartData[] = entry.map(
        (item: PlayerOverviewSchemaLanguagesItem | PlayerOverviewSchemaUniqueLoginsByCountryItem) => {
          if (isPlayerOverviewSchemaLanguagesItem(item)) {
            const { language, numberOfUsers } = item;
            totals += numberOfUsers;
            return { name: language, value: numberOfUsers };
          } else {
            const { country, numberOfLogins } = item;
            totals += numberOfLogins;
            return { name: country, value: numberOfLogins };
          }
        }
      );

      const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
          return (
            <TooltipContent>
              <Typography variant="body2">{label}</Typography>
              <Typography variant="body2">{`${t('amount')}: ${formatDecimal(payload[0].value)}`}</Typography>
              <Typography variant="body2">{`${t('percentage')}: ${formatDecimal(
                (payload[0].value / totals) * 100,
                true
              )}%`}</Typography>
            </TooltipContent>
          );
        }
        return null;
      };

      return (
        <Fragment key={`${key}_key`}>
          <Typography variant="h6" gutterBottom>
            {t(key)}
          </Typography>
          <Box height={300}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={dataToRender} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="value">
                  {dataToRender.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Fragment>
      );
    });
  };

  const numberOfUsers = playerOverview?.activeUsers.length;

  if (!canReadPlayerOverview) return null;

  const activeUsersData = mutatedActiveUsers();

  return (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom>{`${t('activeUsers')}: ${numberOfUsers} users last 30 minutes`}</Typography>
      {activeUsersData && (
        <Box height={400} width="100%">
          <StyledDataGrid
            rows={activeUsersData.rows}
            columns={activeUsersData.columns}
            loading={fetching}
            disableSelectionOnClick
            disableColumnMenu
            disableDensitySelector
            disableColumnSelector
            pagination
            initialState={{
              columns: {
                columnVisibilityModel: {
                  userId: false,
                },
              },
            }}
          />
        </Box>
      )}

      {constructLanguagesUniqueLoginsData({
        languages: playerOverview?.languages || [],
        uniqueLoginsByCountry: playerOverview?.uniqueLoginsByCountry || [],
      })}
    </Stack>
  );
};

export default PlayerOverview;
