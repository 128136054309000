import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  ConfigurationArgumentsSchema,
  ConfigurationsSchemaItem,
  getGetConfigurationsQueryKey,
  useUpdateConfiguration,
} from '@greenisland-api';
import {
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';

interface Props {
  isOpen: boolean;
  closeDialog: () => void;
  configuration: ConfigurationsSchemaItem;
}

const STRING = 'String';
const BOOLEAN = 'Boolean';

const EditConfigurationDialog = ({ isOpen, closeDialog: closeDialog, configuration }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, errors, control } = useForm<ConfigurationArgumentsSchema>();

  const { mutate: setConfiguration } = useUpdateConfiguration({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('editConfigurationSuccess'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('editConfigurationError'), { variant: 'error' });
      },
      onSettled: () => queryClient.invalidateQueries(getGetConfigurationsQueryKey()),
    },
  });

  const onSubmit = (data: ConfigurationArgumentsSchema) => {
    if (configuration) setConfiguration({ configurationId: configuration.configurationId, data });
    closeDialog();
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={closeDialog}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{t('editConfiguration')}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Grid container>
              <Grid item md={3} xs={12}>
                {capitalize(t('key'))}:
              </Grid>
              <Grid item md={9} xs={12} sx={{ overflowWrap: 'break-word' }}>
                {configuration.key}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={3} xs={12}>
                {capitalize(t('description'))}:
              </Grid>
              <Grid item md={9} xs={12}>
                {configuration.description}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={3} xs={12}>
                {capitalize(t('type'))}:
              </Grid>
              <Grid item md={9} xs={12}>
                {configuration.type}
              </Grid>
            </Grid>
            <Grid container alignItems="center">
              <Grid item md={3} xs={12}>
                {capitalize(t('value'))}:
              </Grid>
              <Grid item>
                {configuration.type === BOOLEAN ? (
                  <Controller
                    control={control}
                    name="value"
                    label={t('value')}
                    render={({ value: valueProp, onChange }) => {
                      return (
                        <Switch
                          // @ts-expect-error there is a bug in the swagger
                          defaultChecked={configuration.value === 'True' ? true : false} // TODO: OCPC-5358, swagger should probably be updated for this?
                          value={valueProp}
                          onChange={(event, val) => {
                            return onChange(val ? 'True' : 'False');
                          }}
                        />
                      );
                    }}
                  />
                ) : (
                  <TextField
                    size="small"
                    variant="outlined"
                    name="value"
                    inputRef={register({
                      required: configuration.type !== STRING,
                    })}
                    error={!!errors.value}
                    defaultValue={configuration.value}
                  />
                )}
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="outlined" onClick={closeDialog}>
            {t('cancel')}
          </Button>
          <Button type="submit" variant="contained">
            {t('save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditConfigurationDialog;
