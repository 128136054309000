/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestUserResponse,
  DefaultResponse,
  EventStreamOverviewSchema,
  EventStreamSchema,
  GetEventsParams,
  GetEventstreamEventnameParams,
  GetEventstreamStreamIdParams,
  GetPagedEventstreamByEventnameParams,
  GetPagedEventstreamByStreamIdParams,
  NotFoundResponse,
  PagedEventStreamSchema,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Returns the eventstream
 * @deprecated
 * @summary Get eventstream
 */
export const useGetEventsHook = () => {
  const getEvents = useCustomInstance<EventStreamOverviewSchema>();

  return (params?: GetEventsParams, signal?: AbortSignal) => {
    return getEvents({ url: `/events`, method: 'get', params, signal });
  };
};

export const getGetEventsQueryKey = (params?: GetEventsParams) => [`/events`, ...(params ? [params] : [])] as const;

export const useGetEventsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetEventsHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetEventsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEventsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEventsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEventsQueryKey(params);

  const getEvents = useGetEventsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetEventsHook>>>> = ({ signal }) =>
    getEvents(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetEventsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetEventsHook>>>>;
export type GetEventsQueryError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @deprecated
 * @summary Get eventstream
 */
export const useGetEvents = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetEventsHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetEventsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEventsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetEventsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns the eventstream based on a streamId. Deprecated - please use paged version.
 * @deprecated
 * @summary Get eventstream
 */
export const useGetEventstreamStreamIdHook = () => {
  const getEventstreamStreamId = useCustomInstance<EventStreamSchema>();

  return (params: GetEventstreamStreamIdParams, signal?: AbortSignal) => {
    return getEventstreamStreamId({ url: `/events/streamid`, method: 'get', params, signal });
  };
};

export const getGetEventstreamStreamIdQueryKey = (params: GetEventstreamStreamIdParams) =>
  [`/events/streamid`, ...(params ? [params] : [])] as const;

export const useGetEventstreamStreamIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetEventstreamStreamIdHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetEventstreamStreamIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEventstreamStreamIdHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEventstreamStreamIdHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEventstreamStreamIdQueryKey(params);

  const getEventstreamStreamId = useGetEventstreamStreamIdHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetEventstreamStreamIdHook>>>> = ({ signal }) =>
    getEventstreamStreamId(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetEventstreamStreamIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetEventstreamStreamIdHook>>>
>;
export type GetEventstreamStreamIdQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary Get eventstream
 */
export const useGetEventstreamStreamId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetEventstreamStreamIdHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetEventstreamStreamIdParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEventstreamStreamIdHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetEventstreamStreamIdQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns the eventstream based on a streamId
 * @summary Get eventstream
 */
export const useGetPagedEventstreamByStreamIdHook = () => {
  const getPagedEventstreamByStreamId = useCustomInstance<PagedEventStreamSchema>();

  return (params: GetPagedEventstreamByStreamIdParams, signal?: AbortSignal) => {
    return getPagedEventstreamByStreamId({ url: `/events/v2/streamid`, method: 'get', params, signal });
  };
};

export const getGetPagedEventstreamByStreamIdQueryKey = (params: GetPagedEventstreamByStreamIdParams) =>
  [`/events/v2/streamid`, ...(params ? [params] : [])] as const;

export const useGetPagedEventstreamByStreamIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPagedEventstreamByStreamIdHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetPagedEventstreamByStreamIdParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetPagedEventstreamByStreamIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPagedEventstreamByStreamIdHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPagedEventstreamByStreamIdQueryKey(params);

  const getPagedEventstreamByStreamId = useGetPagedEventstreamByStreamIdHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPagedEventstreamByStreamIdHook>>>> = ({
    signal,
  }) => getPagedEventstreamByStreamId(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPagedEventstreamByStreamIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPagedEventstreamByStreamIdHook>>>
>;
export type GetPagedEventstreamByStreamIdQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get eventstream
 */
export const useGetPagedEventstreamByStreamId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPagedEventstreamByStreamIdHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetPagedEventstreamByStreamIdParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetPagedEventstreamByStreamIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPagedEventstreamByStreamIdQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns the eventstream based on a eventname. Deprecated - please use paged version.
 * @deprecated
 * @summary Get eventstream
 */
export const useGetEventstreamEventnameHook = () => {
  const getEventstreamEventname = useCustomInstance<EventStreamSchema>();

  return (eventName: string, params?: GetEventstreamEventnameParams, signal?: AbortSignal) => {
    return getEventstreamEventname({ url: `/events/eventname/${eventName}`, method: 'get', params, signal });
  };
};

export const getGetEventstreamEventnameQueryKey = (eventName: string, params?: GetEventstreamEventnameParams) =>
  [`/events/eventname/${eventName}`, ...(params ? [params] : [])] as const;

export const useGetEventstreamEventnameQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetEventstreamEventnameHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  eventName: string,
  params?: GetEventstreamEventnameParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEventstreamEventnameHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEventstreamEventnameHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEventstreamEventnameQueryKey(eventName, params);

  const getEventstreamEventname = useGetEventstreamEventnameHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetEventstreamEventnameHook>>>> = ({ signal }) =>
    getEventstreamEventname(eventName, params, signal);

  return { queryKey, queryFn, enabled: !!eventName, ...queryOptions };
};

export type GetEventstreamEventnameQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetEventstreamEventnameHook>>>
>;
export type GetEventstreamEventnameQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary Get eventstream
 */
export const useGetEventstreamEventname = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetEventstreamEventnameHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  eventName: string,
  params?: GetEventstreamEventnameParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEventstreamEventnameHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetEventstreamEventnameQueryOptions(eventName, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns the eventstream based on a eventname
 * @deprecated
 * @summary Get eventstream
 */
export const useGetPagedEventstreamByEventnameHook = () => {
  const getPagedEventstreamByEventname = useCustomInstance<PagedEventStreamSchema>();

  return (params: GetPagedEventstreamByEventnameParams, signal?: AbortSignal) => {
    return getPagedEventstreamByEventname({ url: `/events/v2/eventname`, method: 'get', params, signal });
  };
};

export const getGetPagedEventstreamByEventnameQueryKey = (params: GetPagedEventstreamByEventnameParams) =>
  [`/events/v2/eventname`, ...(params ? [params] : [])] as const;

export const useGetPagedEventstreamByEventnameQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPagedEventstreamByEventnameHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetPagedEventstreamByEventnameParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetPagedEventstreamByEventnameHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPagedEventstreamByEventnameHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPagedEventstreamByEventnameQueryKey(params);

  const getPagedEventstreamByEventname = useGetPagedEventstreamByEventnameHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPagedEventstreamByEventnameHook>>>> = ({
    signal,
  }) => getPagedEventstreamByEventname(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPagedEventstreamByEventnameQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPagedEventstreamByEventnameHook>>>
>;
export type GetPagedEventstreamByEventnameQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary Get eventstream
 */
export const useGetPagedEventstreamByEventname = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPagedEventstreamByEventnameHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetPagedEventstreamByEventnameParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetPagedEventstreamByEventnameHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPagedEventstreamByEventnameQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
