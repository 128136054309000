import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GamesPopularitySchemaItem, useGetGamesPopularity } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { capitalize, LinearProgress, Stack, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';

import { DataGridContainer, PermissionWrapper } from '@greenisland-common/components/atoms';
import DataGridCustomPagination from '@greenisland-common/components/atoms/DataGridPagination/DataGridCustomPagination';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { usePermission } from '../../../../../app/hooks';

const CARD_PAGE_SIZE = 5;
const FULL_PAGE_SIZE = 25;

type Row = GamesPopularitySchemaItem;

interface Props {
  isCard: boolean;
}

const PopularGames = ({ isCard }: Props) => {
  const { t } = useTranslation();
  const canReadPopularGames = usePermission(OnlineCasinoPermissions.getGamesPopularity);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(isCard ? CARD_PAGE_SIZE : FULL_PAGE_SIZE);

  const {
    data: popularGames,
    isLoading,
    isError,
  } = useGetGamesPopularity(
    {},
    {
      query: {
        enabled: canReadPopularGames,
        keepPreviousData: true,
      },
    }
  );

  const columns = useMemo<GridColumns<Row>>(() => {
    const cardColumns = [
      {
        field: 'ranking',
        headerName: capitalize(t('ranking')),
        sortable: true,
        minWidth: 100,
        flex: 0.1,
        renderCell: ({ row }: { row: Row }) => (row?.ranking !== undefined ? row.ranking + 1 : '-'),
      },
      {
        field: 'nameOfGame',
        headerName: capitalize(t('content.gameserver.game.gameName')),
        sortable: false,
        minWidth: 200,
        flex: 0.9,
        renderCell: ({ row }: { row: Row }) => (
          <Stack display="flex" flexDirection="column">
            {row?.nameOfGame}
            <Typography variant="caption">{row?.providerName}</Typography>
          </Stack>
        ),
      },
    ];
    const allColumns = [
      {
        field: 'gameId',
        headerName: capitalize(t('content.games.games.id')),
        sortable: false,
        minWidth: 120,
        flex: 0.1,
        renderCell: ({ row }: { row: Row }) => row?.gameId,
      },
      {
        field: 'nameOfGame',
        headerName: capitalize(t('content.gameserver.game.gameName')),
        sortable: false,
        minWidth: 200,
        flex: 0.2,
        renderCell: ({ row }: { row: Row }) => row?.nameOfGame,
      },
      {
        field: 'providerName',
        headerName: capitalize(t('providerName')),
        sortable: false,
        minWidth: 120,
        flex: 0.1,
        renderCell: ({ row }: { row: Row }) => row?.providerName,
      },
      {
        field: 'ranking',
        headerName: capitalize(t('ranking')),
        sortable: true,
        minWidth: 100,
        flex: 0.1,
        renderCell: ({ row }: { row: Row }) => (row?.ranking !== undefined ? row.ranking + 1 : '-'),
      },
      {
        field: 'numberOfUniquePlayers',
        headerName: capitalize(t('numberOfUniquePlayers')),
        sortable: false,
        minWidth: 100,
        flex: 0.1,
        renderCell: ({ row }: { row: Row }) => row?.numberOfUniquePlayers,
      },
      {
        field: 'numberOfBets',
        headerName: capitalize(t('numberOfBets')),
        sortable: false,
        minWidth: 120,
        flex: 0.1,
        renderCell: ({ row }: { row: Row }) => row?.numberOfBets,
      },
    ];

    if (isCard) {
      return cardColumns;
    }

    return allColumns;
  }, [isCard, t]);

  const rows = useMemo(
    () =>
      popularGames?.map<Row>(popularGame => ({
        id: popularGame?.gameId,
        ...popularGame,
      })) ?? [],
    [popularGames]
  );

  const handleChangeRowsPerPage = (value: number) => {
    setPageSize(value);
    setPage(0);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  return (
    <Stack spacing={2}>
      <PermissionWrapper
        errorMessage={t('reporting.games.popular.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getGamesPopularity}
      >
        <DataGridContainer>
          <LimitedDataGrid
            density="compact"
            autoHeight
            rowHeight={isCard ? 50 : 80}
            loading={isLoading}
            columns={columns}
            rows={rows}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="client"
            components={{
              LoadingOverlay: LinearProgress,
              Footer: () => (
                <>
                  <DataGridCustomPagination
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={isCard ? [] : [10, 25, 50, 100, 200]}
                    count={popularGames?.length ? Math.ceil(popularGames.length / pageSize) : 0}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    paginationMode="client"
                  />
                </>
              ),
            }}
            hideFooterRowCount={true}
          />
        </DataGridContainer>
      </PermissionWrapper>
    </Stack>
  );
};

export default PopularGames;
