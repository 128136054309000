import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { faAdd, faExclamationCircle, faEye } from '@fortawesome/free-solid-svg-icons';
import { InterventionSchema, SortOrderParamParameter, useGetInterventions } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import { getDateFromUnix } from 'src/app/helpers/transformFunctions';
import { usePermission } from 'src/app/hooks';

import {
  DataGridContainer,
  DataGridPagination,
  FontAwesomeIcon,
  Link,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import AddInterventionDialog from './AddInterventionDialog';
import InterventionsFilter, { Filters } from './InterventionsFilter';
import InterventionStatusField from './InterventionStatusField';
import { WRITE_INTERVENTION_PERMISSION } from './permissions';

const Interventions = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState<Filters>({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [isOpen, setIsOpen] = useState(false);

  const canReadInterventions = usePermission(OnlineCasinoPermissions.getInterventions);
  const canAddIntervention = usePermission(WRITE_INTERVENTION_PERMISSION);
  const canViewIntervention = usePermission(OnlineCasinoPermissions.getIntervention);
  const navigate = useNavigate();

  useEffect(() => {
    const user = searchParams.get('user');
    if (user) setParams(oldParams => ({ ...oldParams, userId: Number.parseInt(user) }));
  }, [searchParams]);

  const {
    data: interventions,
    isLoading,
    isError,
  } = useGetInterventions(
    { ...params, numberOfItems: pageSize, pageNumber: page, sortOrder: SortOrderParamParameter.descending },
    { query: { enabled: canReadInterventions } }
  );

  const [rowCountState, setRowCountState] = useState(interventions?.pagingDetails?.totalItems || 0);

  useEffect(() => {
    setRowCountState(prevRowCountState =>
      interventions?.pagingDetails?.totalItems !== undefined
        ? interventions?.pagingDetails?.totalItems
        : prevRowCountState
    );
  }, [interventions?.pagingDetails?.totalItems, setRowCountState]);

  const rows = useMemo(
    () => interventions?.interventions?.map((intervention, index) => ({ ...intervention, pk: index })) ?? [],
    [interventions]
  );

  const columns = useMemo<GridColumns<InterventionSchema>>(
    () => [
      {
        field: 'manualActionRequired',
        headerName: '',
        flex: 0.1,
        minWidth: 30,
        renderCell: params =>
          params.row.manualActionRequired ? (
            <Tooltip title={t('tasks.interventions.manualActionRequired')}>
              <FontAwesomeIcon sx={{ color: 'error.main' }} icon={faExclamationCircle} />
            </Tooltip>
          ) : (
            ''
          ),
      },
      {
        field: 'userId',
        headerName: t('tasks.interventions.userId'),
        flex: 1,
        renderCell: params => <Link to={`../../users/${params.row.userId}/details`}>{params.row.userId}</Link>,
        sortable: false,
        minWidth: 120,
      },
      {
        field: 'status',
        headerName: t('tasks.interventions.status'),
        renderCell: params => <InterventionStatusField value={params.row.status} />,
        flex: 0.5,
        minWidth: 90,
      },
      {
        field: 'interventionType',
        headerName: t('tasks.interventions.interventionType'),
        flex: 2,
        sortable: false,
        minWidth: 130,
      },
      {
        field: 'interventionCause',
        headerName: t('tasks.interventions.interventionCause'),
        flex: 2,
        sortable: false,
        minWidth: 140,
      },
      {
        field: 'interventionActionType',
        headerName: t('tasks.interventions.interventionActionType'),
        flex: 2,
        sortable: false,
        minWidth: 110,
      },
      {
        field: 'interventionChannelType',
        headerName: t('tasks.interventions.interventionChannelType'),
        flex: 2,
        sortable: false,
        minWidth: 80,
      },
      {
        field: 'sourceType',
        headerName: t('tasks.interventions.sourceType'),
        flex: 2,
        sortable: false,
        minWidth: 80,
      },
      {
        field: 'sourceValue',
        headerName: t('tasks.interventions.sourceValue'),
        flex: 3,
        sortable: false,
        minWidth: 120,
      },
      {
        field: 'startDate',
        headerName: t('tasks.interventions.startDate'),
        renderCell: params =>
          params.row.startDate && params.row.startDate > 0 ? getDateFromUnix(params.row.startDate) : '-',
        flex: 0.5,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'endDate',
        headerName: t('tasks.interventions.endDate'),
        renderCell: params =>
          params.row.endDate && params.row.endDate > 0 ? getDateFromUnix(params.row.endDate) : '-',
        flex: 0.5,
        sortable: false,
        minWidth: 100,
      },
      ...(canViewIntervention
        ? [
            {
              field: 'actions',
              type: 'actions',
              getActions: (params: GridRowParams<InterventionSchema>) => [
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faEye} />}
                  label="View"
                  onClick={() => navigate(`../interventions/${params.row.id}`)}
                  key="view"
                />,
              ],
              flex: 0.5,
            },
          ]
        : []),
    ],
    [canViewIntervention, navigate, t]
  );

  return (
    <>
      <Stack spacing={2}>
        {canAddIntervention ? (
          <Box width="100%" display="flex" justifyContent="flex-end">
            <Button variant="contained" startIcon={<FontAwesomeIcon icon={faAdd} />} onClick={() => setIsOpen(true)}>
              {t('tasks.interventions.add')}
            </Button>
          </Box>
        ) : null}
        <InterventionsFilter onChange={filters => setParams(filters)} />
        <PermissionWrapper
          errorMessage={t('tasks.interventions.permissions.fetchError')}
          isError={isError}
          isLoading={false}
          permission={OnlineCasinoPermissions.getInterventions}
        >
          <DataGridContainer>
            <LimitedDataGrid
              density="compact"
              autoHeight
              loading={isLoading}
              rows={rows}
              rowCount={rowCountState}
              columns={columns}
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={newPage => setPage(newPage)}
              onPageSizeChange={newPageSize => setPageSize(newPageSize)}
              components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
        </PermissionWrapper>
      </Stack>
      {canAddIntervention ? <AddInterventionDialog setOpen={setIsOpen} open={isOpen} /> : null}
    </>
  );
};

export default Interventions;
