import { CreateMultiplierTournamentSchema, ImageSchema, TournamentPrizeConfigurationSchema } from '@greenisland-api';
import { getUnixTime } from 'date-fns';

export enum PrizePoolType {
  BONUS_PRIZE = 'bonusPrize',
  WITHDRAWABLE_PRIZE = 'withdrawablePrize',
}

export type CreateMultiplierTournamentSchemaForm = Omit<CreateMultiplierTournamentSchema, 'start' | 'end'> & {
  start: number | null;
  end: number | null;
  images: ImageSchema[] | null;
  bannerImages: ImageSchema[] | null;
};

export const createMultiplierTournamentFormDefaultValues: CreateMultiplierTournamentSchemaForm = {
  display: false,
  name: '',
  descriptionKey: undefined,
  start: null,
  end: null,
  images: null,
  bannerImages: null,
  prizePool: [],
  minimumBet: 0,
  settings: {
    minimumMultiplier: 0,
    numberOfMultipliers: 0,
  },
  contentFilterId: undefined,
};

export function generateCreateMultiplierTournamentData(
  formData: CreateMultiplierTournamentSchemaForm
): CreateMultiplierTournamentSchema {
  return {
    display: formData.display,
    name: formData.name,
    descriptionKey: formData.descriptionKey,
    start: getUnixTime(formData?.start ?? new Date()),
    end: getUnixTime(formData?.end ?? new Date()),
    imageIds: formData?.images?.map(image => image?.id),
    bannerImageIds: formData?.bannerImages?.map(image => image?.id),
    prizePool: formData.prizePool,
    settings: formData.settings,
    minimumBet: formData.minimumBet,
    contentFilterId: formData.contentFilterId,
  };
}

export function isPrizePoolEntryValid(entry: TournamentPrizeConfigurationSchema[] | undefined) {
  if (!entry || entry?.length <= 1) return true;

  for (let i = 0; i < entry.length - 1; i++) {
    const currentEntry = entry[i];
    const nextEntry = entry[i + 1];

    if (
      currentEntry?.firstPlace &&
      currentEntry?.lastPlace &&
      nextEntry?.firstPlace &&
      nextEntry?.lastPlace &&
      (currentEntry.firstPlace > currentEntry.lastPlace ||
        nextEntry.firstPlace > nextEntry.lastPlace ||
        currentEntry.lastPlace >= nextEntry.firstPlace)
    ) {
      return false;
    }
  }
  return true;
}

export const calculatePrizePoolTotalAmount = (
  entries: TournamentPrizeConfigurationSchema[],
  property: PrizePoolType
) => {
  return entries.reduce((acc, current) => {
    const difference = (current.lastPlace ?? 0) - (current.firstPlace ?? 0) + 1;
    return acc + difference * (current[property] ?? 0);
  }, 0);
};
