/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * Loyalty rank users bad request type:
  * `modelStateInvalid` - Invalid model has been provided.

 */
export type ELoyaltyRankUsersBadRequestTypeSchema =
  (typeof ELoyaltyRankUsersBadRequestTypeSchema)[keyof typeof ELoyaltyRankUsersBadRequestTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ELoyaltyRankUsersBadRequestTypeSchema = {
  modelStateInvalid: 'modelStateInvalid',
} as const;
