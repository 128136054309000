import { forwardRef, ReactElement, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { AppBar, Box, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { FontAwesomeIcon, PermissionWrapper } from '@greenisland-common/components/atoms';

import ContentFilter from '../../../ContentFilter';
import { ExtendedContentFilterSchema } from '../helpers/ContentFilterFormContext';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>
) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {props.children}
    </Slide>
  );
});

interface Props {
  isOpen: boolean;
  onSelectHandler: (contentFilter: ExtendedContentFilterSchema) => void;
  onClose: () => void;
}

const ContentFilterDialog = ({ isOpen, onSelectHandler, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog fullScreen open={isOpen} onClose={onClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {t('content.contentFilter.selectContentFilter')}
          </Typography>
        </Toolbar>
      </AppBar>
      <PermissionWrapper
        errorMessage={t('content.contentFilter.readError')}
        isLoading={false}
        isError={false}
        permission={OnlineCasinoPermissions.getContentFilters}
      >
        <Box sx={{ padding: 3 }}>
          <ContentFilter
            customPageSize={25}
            showCreateButton={true}
            isSelectFilterEnabled={true}
            onSelectedHandler={onSelectHandler}
          />
        </Box>
      </PermissionWrapper>
    </Dialog>
  );
};

export default ContentFilterDialog;
