/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  ApproveCancelPlayingBreakRequestSchema,
  ConcludeInvestigationBadRequestResponse,
  ConcludeInvestigationRequestSchema,
  DeclineCancelPlayingBreakRequestSchema,
  DefaultResponse,
  GetAllPlayingBreaksParams,
  PagedPlayingBreaksSchema,
  PlayingBreakBadRequestResponse,
  PlayingBreakSchema,
  SetPlayingBreakRequestSchema,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Get a list of playing breaks
 * @summary Get a list of playing breaks
 */
export const useGetAllPlayingBreaksHook = () => {
  const getAllPlayingBreaks = useCustomInstance<PagedPlayingBreaksSchema>();

  return (params?: GetAllPlayingBreaksParams, signal?: AbortSignal) => {
    return getAllPlayingBreaks({ url: `/playingbreaks`, method: 'get', params, signal });
  };
};

export const getGetAllPlayingBreaksQueryKey = (params?: GetAllPlayingBreaksParams) =>
  [`/playingbreaks`, ...(params ? [params] : [])] as const;

export const useGetAllPlayingBreaksQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAllPlayingBreaksHook>>>,
  TError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetAllPlayingBreaksParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPlayingBreaksHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPlayingBreaksHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllPlayingBreaksQueryKey(params);

  const getAllPlayingBreaks = useGetAllPlayingBreaksHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllPlayingBreaksHook>>>> = ({ signal }) =>
    getAllPlayingBreaks(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAllPlayingBreaksQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAllPlayingBreaksHook>>>
>;
export type GetAllPlayingBreaksQueryError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Get a list of playing breaks
 */
export const useGetAllPlayingBreaks = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAllPlayingBreaksHook>>>,
  TError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetAllPlayingBreaksParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllPlayingBreaksHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAllPlayingBreaksQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get a user's ACTIVE playing break
 * @summary Get a user's ACTIVE playing break
 */
export const useGetPlayingBreakHook = () => {
  const getPlayingBreak = useCustomInstance<PlayingBreakSchema>();

  return (userId: number, signal?: AbortSignal) => {
    return getPlayingBreak({ url: `/playingbreaks/${userId}`, method: 'get', signal });
  };
};

export const getGetPlayingBreakQueryKey = (userId: number) => [`/playingbreaks/${userId}`] as const;

export const useGetPlayingBreakQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayingBreakHook>>>,
  TError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  userId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayingBreakHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayingBreakHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayingBreakQueryKey(userId);

  const getPlayingBreak = useGetPlayingBreakHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPlayingBreakHook>>>> = ({ signal }) =>
    getPlayingBreak(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetPlayingBreakQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPlayingBreakHook>>>>;
export type GetPlayingBreakQueryError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Get a user's ACTIVE playing break
 */
export const useGetPlayingBreak = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayingBreakHook>>>,
  TError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  userId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayingBreakHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPlayingBreakQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Set a user's playing break. If the duration of the playingbreak is set to a value higher than the maximum allowed duration, it will automatically set a playing break of the maximum duration.
 * @summary Set a user's playing break
 */
export const useSetPlayingBreakHook = () => {
  const setPlayingBreak = useCustomInstance<PlayingBreakSchema>();

  return (userId: number, setPlayingBreakRequestSchema: SetPlayingBreakRequestSchema) => {
    return setPlayingBreak({
      url: `/playingbreaks/${userId}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: setPlayingBreakRequestSchema,
    });
  };
};

export const useSetPlayingBreakMutationOptions = <
  TError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetPlayingBreakHook>>>,
    TError,
    { userId: number; data: SetPlayingBreakRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetPlayingBreakHook>>>,
  TError,
  { userId: number; data: SetPlayingBreakRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setPlayingBreak = useSetPlayingBreakHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetPlayingBreakHook>>>,
    { userId: number; data: SetPlayingBreakRequestSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return setPlayingBreak(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetPlayingBreakMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetPlayingBreakHook>>>>;
export type SetPlayingBreakMutationBody = SetPlayingBreakRequestSchema;
export type SetPlayingBreakMutationError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Set a user's playing break
 */
export const useSetPlayingBreak = <
  TError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetPlayingBreakHook>>>,
    TError,
    { userId: number; data: SetPlayingBreakRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useSetPlayingBreakMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Cancel a user's playing break
 * @deprecated
 * @summary Cancel a user's playing break
 */
export const useCancelPlayingBreakHook = () => {
  const cancelPlayingBreak = useCustomInstance<PlayingBreakSchema>();

  return (userId: number) => {
    return cancelPlayingBreak({ url: `/playingbreaks/${userId}`, method: 'delete' });
  };
};

export const useCancelPlayingBreakMutationOptions = <
  TError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelPlayingBreakHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCancelPlayingBreakHook>>>,
  TError,
  { userId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const cancelPlayingBreak = useCancelPlayingBreakHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCancelPlayingBreakHook>>>,
    { userId: number }
  > = props => {
    const { userId } = props ?? {};

    return cancelPlayingBreak(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelPlayingBreakMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCancelPlayingBreakHook>>>
>;

export type CancelPlayingBreakMutationError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @deprecated
 * @summary Cancel a user's playing break
 */
export const useCancelPlayingBreak = <
  TError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelPlayingBreakHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}) => {
  const mutationOptions = useCancelPlayingBreakMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Cancel a user's playing break
 * @summary Approve the cancellation of a user's playing break, Is idempotent - if playing break doesn't exist, will return status code 200.
 */
export const useCancelPlayingBreakV2Hook = () => {
  const cancelPlayingBreakV2 = useCustomInstance<PlayingBreakSchema>();

  return (userId: number, approveCancelPlayingBreakRequestSchema: ApproveCancelPlayingBreakRequestSchema) => {
    return cancelPlayingBreakV2({
      url: `/playingbreaks/${userId}/cancel`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: approveCancelPlayingBreakRequestSchema,
    });
  };
};

export const useCancelPlayingBreakV2MutationOptions = <
  TError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelPlayingBreakV2Hook>>>,
    TError,
    { userId: number; data: ApproveCancelPlayingBreakRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCancelPlayingBreakV2Hook>>>,
  TError,
  { userId: number; data: ApproveCancelPlayingBreakRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const cancelPlayingBreakV2 = useCancelPlayingBreakV2Hook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCancelPlayingBreakV2Hook>>>,
    { userId: number; data: ApproveCancelPlayingBreakRequestSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return cancelPlayingBreakV2(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelPlayingBreakV2MutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCancelPlayingBreakV2Hook>>>
>;
export type CancelPlayingBreakV2MutationBody = ApproveCancelPlayingBreakRequestSchema;
export type CancelPlayingBreakV2MutationError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Approve the cancellation of a user's playing break, Is idempotent - if playing break doesn't exist, will return status code 200.
 */
export const useCancelPlayingBreakV2 = <
  TError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelPlayingBreakV2Hook>>>,
    TError,
    { userId: number; data: ApproveCancelPlayingBreakRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useCancelPlayingBreakV2MutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Decline a user's cancellation request
 * @summary Decline a user's cancellation request
 */
export const useDeclinePlayingBreakCancellationRequestHook = () => {
  const declinePlayingBreakCancellationRequest = useCustomInstance<void>();

  return (userId: number, declineCancelPlayingBreakRequestSchema: DeclineCancelPlayingBreakRequestSchema) => {
    return declinePlayingBreakCancellationRequest({
      url: `/playingbreaks/${userId}/cancel/decline`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: declineCancelPlayingBreakRequestSchema,
    });
  };
};

export const useDeclinePlayingBreakCancellationRequestMutationOptions = <
  TError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeclinePlayingBreakCancellationRequestHook>>>,
    TError,
    { userId: number; data: DeclineCancelPlayingBreakRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeclinePlayingBreakCancellationRequestHook>>>,
  TError,
  { userId: number; data: DeclineCancelPlayingBreakRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const declinePlayingBreakCancellationRequest = useDeclinePlayingBreakCancellationRequestHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeclinePlayingBreakCancellationRequestHook>>>,
    { userId: number; data: DeclineCancelPlayingBreakRequestSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return declinePlayingBreakCancellationRequest(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeclinePlayingBreakCancellationRequestMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeclinePlayingBreakCancellationRequestHook>>>
>;
export type DeclinePlayingBreakCancellationRequestMutationBody = DeclineCancelPlayingBreakRequestSchema;
export type DeclinePlayingBreakCancellationRequestMutationError =
  | PlayingBreakBadRequestResponse
  | UnauthorizedResponse
  | DefaultResponse;

/**
 * @summary Decline a user's cancellation request
 */
export const useDeclinePlayingBreakCancellationRequest = <
  TError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeclinePlayingBreakCancellationRequestHook>>>,
    TError,
    { userId: number; data: DeclineCancelPlayingBreakRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useDeclinePlayingBreakCancellationRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Force cancel a user's playing break. (deprecated 05/03 - please use the /playingbreaks/{userId}/cancel endpoint.)
 * @deprecated
 * @summary Force cancel a user's playing break
 */
export const useForceCancelPlayingBreakHook = () => {
  const forceCancelPlayingBreak = useCustomInstance<void>();

  return (userId: number) => {
    return forceCancelPlayingBreak({ url: `/playingbreaks/${userId}/force`, method: 'delete' });
  };
};

export const useForceCancelPlayingBreakMutationOptions = <
  TError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useForceCancelPlayingBreakHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useForceCancelPlayingBreakHook>>>,
  TError,
  { userId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const forceCancelPlayingBreak = useForceCancelPlayingBreakHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useForceCancelPlayingBreakHook>>>,
    { userId: number }
  > = props => {
    const { userId } = props ?? {};

    return forceCancelPlayingBreak(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ForceCancelPlayingBreakMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useForceCancelPlayingBreakHook>>>
>;

export type ForceCancelPlayingBreakMutationError =
  | PlayingBreakBadRequestResponse
  | UnauthorizedResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary Force cancel a user's playing break
 */
export const useForceCancelPlayingBreak = <
  TError = PlayingBreakBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useForceCancelPlayingBreakHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}) => {
  const mutationOptions = useForceCancelPlayingBreakMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Conclude an investigation with or without suspension.
 * @summary Conclude an investigation.
 */
export const useConcludeInvestigationHook = () => {
  const concludeInvestigation = useCustomInstance<PlayingBreakSchema>();

  return (userId: number, concludeInvestigationRequestSchema: ConcludeInvestigationRequestSchema) => {
    return concludeInvestigation({
      url: `/playingbreaks/investigations/${userId}/conclude`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: concludeInvestigationRequestSchema,
    });
  };
};

export const useConcludeInvestigationMutationOptions = <
  TError = ConcludeInvestigationBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useConcludeInvestigationHook>>>,
    TError,
    { userId: number; data: ConcludeInvestigationRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useConcludeInvestigationHook>>>,
  TError,
  { userId: number; data: ConcludeInvestigationRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const concludeInvestigation = useConcludeInvestigationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useConcludeInvestigationHook>>>,
    { userId: number; data: ConcludeInvestigationRequestSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return concludeInvestigation(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ConcludeInvestigationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useConcludeInvestigationHook>>>
>;
export type ConcludeInvestigationMutationBody = ConcludeInvestigationRequestSchema;
export type ConcludeInvestigationMutationError =
  | ConcludeInvestigationBadRequestResponse
  | UnauthorizedResponse
  | DefaultResponse;

/**
 * @summary Conclude an investigation.
 */
export const useConcludeInvestigation = <
  TError = ConcludeInvestigationBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useConcludeInvestigationHook>>>,
    TError,
    { userId: number; data: ConcludeInvestigationRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useConcludeInvestigationMutationOptions(options);

  return useMutation(mutationOptions);
};
