import { useSearchParams } from 'react-router-dom';

import { DepositsParamsType } from '../Components/DetailsComponents/Constants/Deposits';

export default function useGetDepositFiltersQuery() {
  const [searchParams] = useSearchParams();

  const startDateQuery = searchParams.get(DepositsParamsType.START_DATE);
  const endDateQuery = searchParams.get(DepositsParamsType.END_DATE);
  const pageQuery = searchParams.get(DepositsParamsType.PAGE);
  const pageSizeQuery = searchParams.get(DepositsParamsType.PAGE_SIZE);
  const hasDetailsQuery = searchParams.get(DepositsParamsType.HAS_DETAILS);
  const isAscendingQuery = searchParams.get(DepositsParamsType.ASCENDING);
  const statusQuery = searchParams.get(DepositsParamsType.STATUS);
  const sortByQuery = searchParams.get(DepositsParamsType.SORT_BY);
  const methodQuery = searchParams.get(DepositsParamsType.METHOD);
  const providerQuery = searchParams.get(DepositsParamsType.PROVIDER);
  const ibanQuery = searchParams.get(DepositsParamsType.IBAN);
  const walletQuery = searchParams.get(DepositsParamsType.WALLET);
  const ipAddressQuery = searchParams.get(DepositsParamsType.IP_ADDRESS);
  const minAmountQuery = searchParams.get(DepositsParamsType.MIN_AMOUNT);
  const maxAmountQuery = searchParams.get(DepositsParamsType.MAX_AMOUNT);
  const referenceQuery = searchParams.get(DepositsParamsType.REFERENCE);
  const depositIdQuery = searchParams.get(DepositsParamsType.DEPOSIT_ID);
  const accountIdQuery = searchParams.get(DepositsParamsType.ACCOUNT_ID);
  const userIdQuery = searchParams.get(DepositsParamsType.USER_ID);

  return {
    startDateQuery,
    endDateQuery,
    pageQuery,
    pageSizeQuery,
    hasDetailsQuery,
    isAscendingQuery,
    sortByQuery,
    statusQuery,
    methodQuery,
    providerQuery,
    ibanQuery,
    walletQuery,
    ipAddressQuery,
    minAmountQuery,
    maxAmountQuery,
    referenceQuery,
    depositIdQuery,
    accountIdQuery,
    userIdQuery,
  };
}
