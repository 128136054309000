import { EBoosterPackBadRequestTypeSchema } from '@greenisland-api';

type BoosterPackErrorReasons = {
  [key in EBoosterPackBadRequestTypeSchema]: string;
};

export const BOOSTER_PACK_ERROR_REASONS: BoosterPackErrorReasons = {
  [EBoosterPackBadRequestTypeSchema.boosterPackNotFound]: 'marketing.boosterCards.errors.boosterPackNotFound',
  [EBoosterPackBadRequestTypeSchema.boosterPackCardNotFound]: 'marketing.boosterCards.errors.boosterPackCardNotFound',
  [EBoosterPackBadRequestTypeSchema.cardPoolIsEmpty]: 'marketing.boosterCards.errors.cardPoolIsEmpty',
  [EBoosterPackBadRequestTypeSchema.invalidExpirationDuration]:
    'marketing.boosterCards.errors.invalidExpirationDuration',
};
