import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Authenticator,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  useAuthenticator,
  useTheme,
  View,
} from '@aws-amplify/ui-react';

const AmplifySignInFooter = () => {
  const { toResetPassword } = useAuthenticator();
  const { t } = useTranslation();
  const { tokens } = useTheme();

  let text = t('resetPassword');
  text = text.charAt(0).toUpperCase() + text.slice(1);

  return (
    <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
      <Link onClick={toResetPassword}>{text}</Link>
    </Flex>
  );
};

export default AmplifySignInFooter;
