import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { VerificationsSchemaIdentity } from '@greenisland-api';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

interface Props {
  identity: VerificationsSchemaIdentity;
}

const BirthdateField = ({ identity }: Props) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      rules={{ required: true }}
      name="birthdate"
      control={control}
      defaultValue={identity.birthdate}
      render={props => (
        <DatePicker
          {...props}
          renderInput={params => <TextField fullWidth size="small" {...params} error={Boolean(errors.birthdate)} />}
          inputFormat="dd/MM/yyyy"
          mask="__/__/____"
          views={['year', 'month', 'day']}
        />
      )}
    />
  );
};

export default BirthdateField;
