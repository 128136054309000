import { useMemo } from 'react';
import { hasFeatureFlags } from '@greenisland-core/featureFlags';
import { useTenantContext } from 'src/app/context/TenantContext';
import { FeatureFlag } from 'src/routes/RouteInterfaces';

const useFeatureFlags = (flags: FeatureFlag | FeatureFlag[]): boolean => {
  const tenant = useTenantContext();

  const hasFlags = useMemo(() => hasFeatureFlags(tenant, flags), [flags, tenant]);
  return hasFlags;
};

export default useFeatureFlags;
