import { Component } from 'react';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';

interface Props {
  children: any;
  sx?: SxProps<Theme>;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', ...this.props.sx }}>
          <h3>Something went wrong.</h3>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
