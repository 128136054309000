/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  ActiveLoginSessionLimitsSchema,
  BadRequestResponse,
  CancelMaxLimitRequestBodySchema,
  DefaultResponse,
  GetLoginSessionLimitsParams,
  GetLoginSessionLimitUpdateRequestsParams,
  LoginSessionLimitUpdateRequestResultSchema,
  MaxLimitBadRequestResponse,
  MaxLoginSessionLimitPerPeriodSchema,
  MaxLoginSessionLimitSchema,
  MaxLoginSessionLimitsSchema,
  NotFoundResponse,
  PaginatedLoginSessionLimitsSchema,
  PaginatedLoginSessionLimitUpdateRequestsSchema,
  UnauthorizedResponse,
  UpdateLoginSessionLimitRequestSchema,
  UpdateMaxLoginSessionLimitSchema,
  UserLimitUpdateRequestManualDecisionArgumentsSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Retrieves login session limits.
 * @summary Retrieve login session limits.
 */
export const useGetLoginSessionLimitsHook = () => {
  const getLoginSessionLimits = useCustomInstance<PaginatedLoginSessionLimitsSchema>();

  return (params?: GetLoginSessionLimitsParams, signal?: AbortSignal) => {
    return getLoginSessionLimits({ url: `/loginsessionlimits`, method: 'get', params, signal });
  };
};

export const getGetLoginSessionLimitsQueryKey = (params?: GetLoginSessionLimitsParams) =>
  [`/loginsessionlimits`, ...(params ? [params] : [])] as const;

export const useGetLoginSessionLimitsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoginSessionLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetLoginSessionLimitsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoginSessionLimitsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoginSessionLimitsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLoginSessionLimitsQueryKey(params);

  const getLoginSessionLimits = useGetLoginSessionLimitsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLoginSessionLimitsHook>>>> = ({ signal }) =>
    getLoginSessionLimits(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLoginSessionLimitsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLoginSessionLimitsHook>>>
>;
export type GetLoginSessionLimitsQueryError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Retrieve login session limits.
 */
export const useGetLoginSessionLimits = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoginSessionLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetLoginSessionLimitsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoginSessionLimitsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetLoginSessionLimitsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves login session limit update requests.
 * @summary Retrieve login session limit update requests.
 */
export const useGetLoginSessionLimitUpdateRequestsHook = () => {
  const getLoginSessionLimitUpdateRequests = useCustomInstance<PaginatedLoginSessionLimitUpdateRequestsSchema>();

  return (params?: GetLoginSessionLimitUpdateRequestsParams, signal?: AbortSignal) => {
    return getLoginSessionLimitUpdateRequests({ url: `/loginSessionlimits/requests`, method: 'get', params, signal });
  };
};

export const getGetLoginSessionLimitUpdateRequestsQueryKey = (params?: GetLoginSessionLimitUpdateRequestsParams) =>
  [`/loginSessionlimits/requests`, ...(params ? [params] : [])] as const;

export const useGetLoginSessionLimitUpdateRequestsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoginSessionLimitUpdateRequestsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetLoginSessionLimitUpdateRequestsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetLoginSessionLimitUpdateRequestsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoginSessionLimitUpdateRequestsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLoginSessionLimitUpdateRequestsQueryKey(params);

  const getLoginSessionLimitUpdateRequests = useGetLoginSessionLimitUpdateRequestsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLoginSessionLimitUpdateRequestsHook>>>> = ({
    signal,
  }) => getLoginSessionLimitUpdateRequests(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLoginSessionLimitUpdateRequestsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLoginSessionLimitUpdateRequestsHook>>>
>;
export type GetLoginSessionLimitUpdateRequestsQueryError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Retrieve login session limit update requests.
 */
export const useGetLoginSessionLimitUpdateRequests = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoginSessionLimitUpdateRequestsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetLoginSessionLimitUpdateRequestsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetLoginSessionLimitUpdateRequestsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetLoginSessionLimitUpdateRequestsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Request to update a login session limit. A request does not mean that this login session limit will be set in the future, as it may be declined. An update to the login session limit can take place immediately (if a lower login session limit value is requested) or in the future (after the approval process).
 * @summary Request an update for a login session limit.
 */
export const useRequestLoginSessionLimitUpdateHook = () => {
  const requestLoginSessionLimitUpdate = useCustomInstance<LoginSessionLimitUpdateRequestResultSchema>();

  return (updateLoginSessionLimitRequestSchema: UpdateLoginSessionLimitRequestSchema) => {
    return requestLoginSessionLimitUpdate({
      url: `/loginSessionlimits/requests`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateLoginSessionLimitRequestSchema,
    });
  };
};

export const useRequestLoginSessionLimitUpdateMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRequestLoginSessionLimitUpdateHook>>>,
    TError,
    { data: UpdateLoginSessionLimitRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRequestLoginSessionLimitUpdateHook>>>,
  TError,
  { data: UpdateLoginSessionLimitRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const requestLoginSessionLimitUpdate = useRequestLoginSessionLimitUpdateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRequestLoginSessionLimitUpdateHook>>>,
    { data: UpdateLoginSessionLimitRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return requestLoginSessionLimitUpdate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RequestLoginSessionLimitUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRequestLoginSessionLimitUpdateHook>>>
>;
export type RequestLoginSessionLimitUpdateMutationBody = UpdateLoginSessionLimitRequestSchema;
export type RequestLoginSessionLimitUpdateMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Request an update for a login session limit.
 */
export const useRequestLoginSessionLimitUpdate = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRequestLoginSessionLimitUpdateHook>>>,
    TError,
    { data: UpdateLoginSessionLimitRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useRequestLoginSessionLimitUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves login session limits
 * @summary Retrieve the active login session limits for the user.
 */
export const useGetActiveLoginSessionLimitsForUserHook = () => {
  const getActiveLoginSessionLimitsForUser = useCustomInstance<ActiveLoginSessionLimitsSchema>();

  return (userId: number, signal?: AbortSignal) => {
    return getActiveLoginSessionLimitsForUser({ url: `/loginsessionlimits/active/${userId}`, method: 'get', signal });
  };
};

export const getGetActiveLoginSessionLimitsForUserQueryKey = (userId: number) =>
  [`/loginsessionlimits/active/${userId}`] as const;

export const useGetActiveLoginSessionLimitsForUserQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActiveLoginSessionLimitsForUserHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActiveLoginSessionLimitsForUserHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveLoginSessionLimitsForUserHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveLoginSessionLimitsForUserQueryKey(userId);

  const getActiveLoginSessionLimitsForUser = useGetActiveLoginSessionLimitsForUserHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetActiveLoginSessionLimitsForUserHook>>>> = ({
    signal,
  }) => getActiveLoginSessionLimitsForUser(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetActiveLoginSessionLimitsForUserQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetActiveLoginSessionLimitsForUserHook>>>
>;
export type GetActiveLoginSessionLimitsForUserQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Retrieve the active login session limits for the user.
 */
export const useGetActiveLoginSessionLimitsForUser = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActiveLoginSessionLimitsForUserHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActiveLoginSessionLimitsForUserHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetActiveLoginSessionLimitsForUserQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Approve a login session limit update request.
 * @summary Approve a login session limit update request.
 */
export const useApproveLoginSessionLimitUpdateRequestHook = () => {
  const approveLoginSessionLimitUpdateRequest = useCustomInstance<void>();

  return (
    requestId: number,
    userLimitUpdateRequestManualDecisionArgumentsSchema: UserLimitUpdateRequestManualDecisionArgumentsSchema
  ) => {
    return approveLoginSessionLimitUpdateRequest({
      url: `/loginsessionlimits/requests/${requestId}/approve`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: userLimitUpdateRequestManualDecisionArgumentsSchema,
    });
  };
};

export const useApproveLoginSessionLimitUpdateRequestMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useApproveLoginSessionLimitUpdateRequestHook>>>,
    TError,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useApproveLoginSessionLimitUpdateRequestHook>>>,
  TError,
  { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const approveLoginSessionLimitUpdateRequest = useApproveLoginSessionLimitUpdateRequestHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useApproveLoginSessionLimitUpdateRequestHook>>>,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema }
  > = props => {
    const { requestId, data } = props ?? {};

    return approveLoginSessionLimitUpdateRequest(requestId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApproveLoginSessionLimitUpdateRequestMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useApproveLoginSessionLimitUpdateRequestHook>>>
>;
export type ApproveLoginSessionLimitUpdateRequestMutationBody = UserLimitUpdateRequestManualDecisionArgumentsSchema;
export type ApproveLoginSessionLimitUpdateRequestMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Approve a login session limit update request.
 */
export const useApproveLoginSessionLimitUpdateRequest = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useApproveLoginSessionLimitUpdateRequestHook>>>,
    TError,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useApproveLoginSessionLimitUpdateRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Decline a login session limit update request.
 * @summary Decline a login session limit update request.
 */
export const useDeclineLoginSessionLimitUpdateRequestHook = () => {
  const declineLoginSessionLimitUpdateRequest = useCustomInstance<void>();

  return (
    requestId: number,
    userLimitUpdateRequestManualDecisionArgumentsSchema: UserLimitUpdateRequestManualDecisionArgumentsSchema
  ) => {
    return declineLoginSessionLimitUpdateRequest({
      url: `/loginsessionlimits/requests/${requestId}/decline`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: userLimitUpdateRequestManualDecisionArgumentsSchema,
    });
  };
};

export const useDeclineLoginSessionLimitUpdateRequestMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeclineLoginSessionLimitUpdateRequestHook>>>,
    TError,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeclineLoginSessionLimitUpdateRequestHook>>>,
  TError,
  { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const declineLoginSessionLimitUpdateRequest = useDeclineLoginSessionLimitUpdateRequestHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeclineLoginSessionLimitUpdateRequestHook>>>,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema }
  > = props => {
    const { requestId, data } = props ?? {};

    return declineLoginSessionLimitUpdateRequest(requestId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeclineLoginSessionLimitUpdateRequestMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeclineLoginSessionLimitUpdateRequestHook>>>
>;
export type DeclineLoginSessionLimitUpdateRequestMutationBody = UserLimitUpdateRequestManualDecisionArgumentsSchema;
export type DeclineLoginSessionLimitUpdateRequestMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Decline a login session limit update request.
 */
export const useDeclineLoginSessionLimitUpdateRequest = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeclineLoginSessionLimitUpdateRequestHook>>>,
    TError,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useDeclineLoginSessionLimitUpdateRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Sets requiresManualApproval flag to true for login session limit update request.
 * @summary Sets requiresManualApproval flag to true for login session limit update request.
 */
export const useSetLoginSessionLimitUpdateRequestToRequireManualApprovalHook = () => {
  const setLoginSessionLimitUpdateRequestToRequireManualApproval = useCustomInstance<void>();

  return (requestId: number) => {
    return setLoginSessionLimitUpdateRequestToRequireManualApproval({
      url: `/loginsessionlimits/requests/${requestId}/requiresmanualapproval`,
      method: 'patch',
    });
  };
};

export const useSetLoginSessionLimitUpdateRequestToRequireManualApprovalMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetLoginSessionLimitUpdateRequestToRequireManualApprovalHook>>>,
    TError,
    { requestId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetLoginSessionLimitUpdateRequestToRequireManualApprovalHook>>>,
  TError,
  { requestId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setLoginSessionLimitUpdateRequestToRequireManualApproval =
    useSetLoginSessionLimitUpdateRequestToRequireManualApprovalHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetLoginSessionLimitUpdateRequestToRequireManualApprovalHook>>>,
    { requestId: number }
  > = props => {
    const { requestId } = props ?? {};

    return setLoginSessionLimitUpdateRequestToRequireManualApproval(requestId);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetLoginSessionLimitUpdateRequestToRequireManualApprovalMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetLoginSessionLimitUpdateRequestToRequireManualApprovalHook>>>
>;

export type SetLoginSessionLimitUpdateRequestToRequireManualApprovalMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Sets requiresManualApproval flag to true for login session limit update request.
 */
export const useSetLoginSessionLimitUpdateRequestToRequireManualApproval = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetLoginSessionLimitUpdateRequestToRequireManualApprovalHook>>>,
    TError,
    { requestId: number },
    TContext
  >;
}) => {
  const mutationOptions = useSetLoginSessionLimitUpdateRequestToRequireManualApprovalMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Sets the maximum login session limit for the user. If the user's login session limit is higher than the given value, automatically sets the user's limit to this max value.
 * @summary Sets the maximum login session limit for the user. If the user's login session limit is higher than the given value, automatically sets the user's limit to this max value.
 */
export const useSetMaximumLoginSessionLimitHook = () => {
  const setMaximumLoginSessionLimit = useCustomInstance<MaxLoginSessionLimitSchema>();

  return (userId: number, updateMaxLoginSessionLimitSchema: UpdateMaxLoginSessionLimitSchema) => {
    return setMaximumLoginSessionLimit({
      url: `/loginsessionlimits/${userId}/max`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateMaxLoginSessionLimitSchema,
    });
  };
};

export const useSetMaximumLoginSessionLimitMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetMaximumLoginSessionLimitHook>>>,
    TError,
    { userId: number; data: UpdateMaxLoginSessionLimitSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetMaximumLoginSessionLimitHook>>>,
  TError,
  { userId: number; data: UpdateMaxLoginSessionLimitSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setMaximumLoginSessionLimit = useSetMaximumLoginSessionLimitHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetMaximumLoginSessionLimitHook>>>,
    { userId: number; data: UpdateMaxLoginSessionLimitSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return setMaximumLoginSessionLimit(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetMaximumLoginSessionLimitMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetMaximumLoginSessionLimitHook>>>
>;
export type SetMaximumLoginSessionLimitMutationBody = UpdateMaxLoginSessionLimitSchema;
export type SetMaximumLoginSessionLimitMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Sets the maximum login session limit for the user. If the user's login session limit is higher than the given value, automatically sets the user's limit to this max value.
 */
export const useSetMaximumLoginSessionLimit = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetMaximumLoginSessionLimitHook>>>,
    TError,
    { userId: number; data: UpdateMaxLoginSessionLimitSchema },
    TContext
  >;
}) => {
  const mutationOptions = useSetMaximumLoginSessionLimitMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Gets the maximum login session limits for the user.
 * @summary Gets the maximum login session limits for the user.
 */
export const useGetMaximumLoginSessionLimitsHook = () => {
  const getMaximumLoginSessionLimits = useCustomInstance<MaxLoginSessionLimitsSchema>();

  return (userId: number, signal?: AbortSignal) => {
    return getMaximumLoginSessionLimits({ url: `/loginsessionlimits/${userId}/max`, method: 'get', signal });
  };
};

export const getGetMaximumLoginSessionLimitsQueryKey = (userId: number) =>
  [`/loginsessionlimits/${userId}/max`] as const;

export const useGetMaximumLoginSessionLimitsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMaximumLoginSessionLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMaximumLoginSessionLimitsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMaximumLoginSessionLimitsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMaximumLoginSessionLimitsQueryKey(userId);

  const getMaximumLoginSessionLimits = useGetMaximumLoginSessionLimitsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMaximumLoginSessionLimitsHook>>>> = ({
    signal,
  }) => getMaximumLoginSessionLimits(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetMaximumLoginSessionLimitsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMaximumLoginSessionLimitsHook>>>
>;
export type GetMaximumLoginSessionLimitsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Gets the maximum login session limits for the user.
 */
export const useGetMaximumLoginSessionLimits = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMaximumLoginSessionLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMaximumLoginSessionLimitsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMaximumLoginSessionLimitsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Cancels the maximum login session limit for the user.
 * @summary Cancels the maximum login session limit for the user.
 */
export const useCancelMaximumLoginSessionLimitHook = () => {
  const cancelMaximumLoginSessionLimit = useCustomInstance<void>();

  return (userId: number, cancelMaxLimitRequestBodySchema: CancelMaxLimitRequestBodySchema) => {
    return cancelMaximumLoginSessionLimit({
      url: `/loginsessionlimits/${userId}/max/cancel`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: cancelMaxLimitRequestBodySchema,
    });
  };
};

export const useCancelMaximumLoginSessionLimitMutationOptions = <
  TError = MaxLimitBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelMaximumLoginSessionLimitHook>>>,
    TError,
    { userId: number; data: CancelMaxLimitRequestBodySchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCancelMaximumLoginSessionLimitHook>>>,
  TError,
  { userId: number; data: CancelMaxLimitRequestBodySchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const cancelMaximumLoginSessionLimit = useCancelMaximumLoginSessionLimitHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCancelMaximumLoginSessionLimitHook>>>,
    { userId: number; data: CancelMaxLimitRequestBodySchema }
  > = props => {
    const { userId, data } = props ?? {};

    return cancelMaximumLoginSessionLimit(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelMaximumLoginSessionLimitMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCancelMaximumLoginSessionLimitHook>>>
>;
export type CancelMaximumLoginSessionLimitMutationBody = CancelMaxLimitRequestBodySchema;
export type CancelMaximumLoginSessionLimitMutationError =
  | MaxLimitBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Cancels the maximum login session limit for the user.
 */
export const useCancelMaximumLoginSessionLimit = <
  TError = MaxLimitBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelMaximumLoginSessionLimitHook>>>,
    TError,
    { userId: number; data: CancelMaxLimitRequestBodySchema },
    TContext
  >;
}) => {
  const mutationOptions = useCancelMaximumLoginSessionLimitMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Gets the active maximum login session limits for the user.
 * @summary Gets the active maximum login session limits for the user.
 */
export const useGetActiveMaximumLoginSessionLimitsHook = () => {
  const getActiveMaximumLoginSessionLimits = useCustomInstance<MaxLoginSessionLimitPerPeriodSchema>();

  return (userId: number, signal?: AbortSignal) => {
    return getActiveMaximumLoginSessionLimits({
      url: `/loginsessionlimits/${userId}/max/active`,
      method: 'get',
      signal,
    });
  };
};

export const getGetActiveMaximumLoginSessionLimitsQueryKey = (userId: number) =>
  [`/loginsessionlimits/${userId}/max/active`] as const;

export const useGetActiveMaximumLoginSessionLimitsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumLoginSessionLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumLoginSessionLimitsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumLoginSessionLimitsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveMaximumLoginSessionLimitsQueryKey(userId);

  const getActiveMaximumLoginSessionLimits = useGetActiveMaximumLoginSessionLimitsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumLoginSessionLimitsHook>>>> = ({
    signal,
  }) => getActiveMaximumLoginSessionLimits(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetActiveMaximumLoginSessionLimitsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumLoginSessionLimitsHook>>>
>;
export type GetActiveMaximumLoginSessionLimitsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Gets the active maximum login session limits for the user.
 */
export const useGetActiveMaximumLoginSessionLimits = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumLoginSessionLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumLoginSessionLimitsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetActiveMaximumLoginSessionLimitsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
