/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EPaymentMethodSchema = (typeof EPaymentMethodSchema)[keyof typeof EPaymentMethodSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EPaymentMethodSchema = {
  Voucher: 'Voucher',
  Skrill: 'Skrill',
  Neteller: 'Neteller',
  Paypal: 'Paypal',
  BCMC: 'BCMC',
  'BCMC-Mobile': 'BCMC-Mobile',
  Trustly: 'Trustly',
  ING: 'ING',
  Belfius: 'Belfius',
  Visa: 'Visa',
  Mastercard: 'Mastercard',
  Maestro: 'Maestro',
  CB: 'CB',
  Sofort: 'Sofort',
  PaysafeCard: 'PaysafeCard',
  NeoSurf: 'NeoSurf',
  IDeal: 'IDeal',
  RapidTransfer: 'RapidTransfer',
  QRCode: 'QRCode',
  Cash: 'Cash',
  BankTransfer: 'BankTransfer',
  InstantWithdrawal: 'InstantWithdrawal',
  'BCMC-WIP': 'BCMC-WIP',
  Mock: 'Mock',
  BizumRtp: 'BizumRtp',
  BizumRedirect: 'BizumRedirect',
} as const;
