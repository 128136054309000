/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  DefaultResponse,
  NotFoundResponse,
  PagedLinkedDgojResponsibleGamingTestResponsesSchema,
  RetrieveDgojResponsibleGamingTestResponsesParams,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Retrieve responsible gaming test responses
 */
export const useRetrieveDgojResponsibleGamingTestResponsesHook = () => {
  const retrieveDgojResponsibleGamingTestResponses =
    useCustomInstance<PagedLinkedDgojResponsibleGamingTestResponsesSchema>();

  return (params?: RetrieveDgojResponsibleGamingTestResponsesParams, signal?: AbortSignal) => {
    return retrieveDgojResponsibleGamingTestResponses({
      url: `/responsible-gaming-test/dgoj`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getRetrieveDgojResponsibleGamingTestResponsesQueryKey = (
  params?: RetrieveDgojResponsibleGamingTestResponsesParams
) => [`/responsible-gaming-test/dgoj`, ...(params ? [params] : [])] as const;

export const useRetrieveDgojResponsibleGamingTestResponsesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useRetrieveDgojResponsibleGamingTestResponsesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: RetrieveDgojResponsibleGamingTestResponsesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useRetrieveDgojResponsibleGamingTestResponsesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useRetrieveDgojResponsibleGamingTestResponsesHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveDgojResponsibleGamingTestResponsesQueryKey(params);

  const retrieveDgojResponsibleGamingTestResponses = useRetrieveDgojResponsibleGamingTestResponsesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useRetrieveDgojResponsibleGamingTestResponsesHook>>>
  > = ({ signal }) => retrieveDgojResponsibleGamingTestResponses(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type RetrieveDgojResponsibleGamingTestResponsesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRetrieveDgojResponsibleGamingTestResponsesHook>>>
>;
export type RetrieveDgojResponsibleGamingTestResponsesQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Retrieve responsible gaming test responses
 */
export const useRetrieveDgojResponsibleGamingTestResponses = <
  TData = Awaited<ReturnType<ReturnType<typeof useRetrieveDgojResponsibleGamingTestResponsesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: RetrieveDgojResponsibleGamingTestResponsesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useRetrieveDgojResponsibleGamingTestResponsesHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useRetrieveDgojResponsibleGamingTestResponsesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
