import { ChangeEvent, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {
  EUpdateBoostBadRequestTypeSchema,
  getGetBoostsQueryKey,
  ImageSchema,
  UpdateGlobalBoostSchema,
  useCreateGlobalBoost,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Card, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon, PermissionWrapper } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../../../app/hooks';
import { BoostDurationsEnum } from '../../../../Campaigns/Actions/Individual/components/helpers/BoostFormContext';
import { calculatePromotionDuration } from '../../../../Campaigns/Actions/Individual/Forms/helpers/DepositBonusFormContext';
import { getLoyaltyDependentMaxPayoutValues } from '../../../../Components/LoyaltyDependentValue/LoyaltyDependentValueDecimal';
import { GLOBAL_BOOST_ERROR_REASONS } from '../../../constants/GlobalBoostsErrorReasons';
import GlobalBoostCreateFormFields from './GlobalBoostCreateFormFields';

export interface GlobalBoostForm extends UpdateGlobalBoostSchema {
  boostDurationSelectOption: BoostDurationsEnum;
  images: ImageSchema[] | null;
}

interface Props {
  open: boolean;
  onClose: () => void;
}

const GlobalBoostsCreateForm = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const canCreateGlobalBoosts = usePermission(OnlineCasinoPermissions.createGlobalBoost);
  const methods = useForm<GlobalBoostForm>({
    shouldFocusError: true,
    mode: 'onChange',
    defaultValues: {
      images: null,
    },
  });
  const { handleSubmit, watch, setValue } = methods;

  const [isLoyaltyDependent, setIsLoyaltyDependent] = useState<boolean>(watch('isLoyaltyDependent') || false);

  const handleLoyaltyDependentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsLoyaltyDependent(event.target.checked);
    setValue('isLoyaltyDependent', event.target.checked);
  };

  const {
    mutate: createGlobalBoost,
    isLoading,
    isError,
  } = useCreateGlobalBoost({
    mutation: {
      onSuccess: async () => {
        queryClient.invalidateQueries(getGetBoostsQueryKey());
        onClose();
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: error => {
        if (error) {
          if ('type' in error && error.type) {
            const errorReason: EUpdateBoostBadRequestTypeSchema | undefined = error.type;
            const translationKey = errorReason ? GLOBAL_BOOST_ERROR_REASONS[errorReason] : 'somethingWentWrong';
            enqueueSnackbar(t(translationKey), { variant: 'error' });
          } else {
            enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
          }
        }
      },
    },
  });

  const onSubmit = (data: GlobalBoostForm) => {
    if (canCreateGlobalBoosts) {
      createGlobalBoost({
        data: {
          ...data,
          factor: data.factor / 100, // convert percentage to number
          startDate: Math.floor(new Date(data.startDate).getTime() / 1000), // convert DateTime to unix timestamp
          imageIds: data?.images?.map(image => image?.id),
          maxPayout: !data.isLoyaltyDependent ? data.maxPayout : null,
          loyaltyDependentMaxPayout: getLoyaltyDependentMaxPayoutValues(
            data.isLoyaltyDependent,
            data.loyaltyDependentMaxPayout
          ),
          duration: calculatePromotionDuration(data?.duration, data?.boostDurationSelectOption),
          earlyMaxPayoutEnabled: data?.earlyMaxPayoutEnabled,
        },
      });
    } else {
      enqueueSnackbar(t('boost.global.permission.createError'), { variant: 'error' });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <PermissionWrapper
        errorMessage={'boost.global.permission.createError'}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.createGlobalBoost}
      >
        <Box>
          <Card>
            <FormProvider {...methods}>
              <DialogTitle>{t('boost.global.create.title')}</DialogTitle>
              <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                aria-label="close"
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
              <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <GlobalBoostCreateFormFields
                    isLoyaltyDependent={isLoyaltyDependent}
                    onClose={onClose}
                    handleLoyaltyDependentChange={handleLoyaltyDependentChange}
                    isLoading={isLoading}
                  />
                </form>
              </DialogContent>
            </FormProvider>
          </Card>
        </Box>
      </PermissionWrapper>
    </Dialog>
  );
};

export default GlobalBoostsCreateForm;
