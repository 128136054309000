import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Permission } from '@greenisland-types/authorization';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { DATETIME_INPUT_FORMAT, DATETIME_INPUT_MASK } from '@greenisland-common/components/atoms/Constants';

import { usePermission } from '../../../../../../../app/hooks';

interface Props {
  value?: boolean | string;
  tooltip?: string;
  description?: string;
  error?: boolean;
  disabled?: boolean;
  withReason?: boolean;
  reasonValue?: string;
  reasonLabel?: string;
  withDate?: boolean;
  dateLabel?: string;
  dateValue?: Date | null;
  setDateValue?: Dispatch<SetStateAction<Date | null>>;
  togglePermissions?: Permission;
  onToggle?: (reason?: string) => void;
}

const GenericField = ({
  value,
  tooltip,
  description,
  error,
  disabled,
  withReason,
  reasonValue,
  reasonLabel,
  togglePermissions,
  onToggle,
  withDate,
  dateLabel,
  dateValue,
  setDateValue,
}: Props) => {
  const { t } = useTranslation();
  const canToggle = usePermission(togglePermissions ?? []);
  const [isOpen, setOpen] = useState(false);
  const [isBlurred, setBlurred] = useState(false);
  const [reason, setReason] = useState('');
  const showError = isBlurred && !reason;

  const onConfirm = useCallback(() => {
    if (withReason && !reason) {
      setBlurred(true);
    }
    if (withDate && setDateValue) {
      setDateValue(null);
    }
    if (reasonValue && reason !== reasonValue) {
      setBlurred(true);
    } else {
      withReason ? onToggle?.(reason) : onToggle?.();
      setOpen(false);
    }
  }, [withReason, reason, withDate, reasonValue, setDateValue, onToggle]);

  const onCancel = useCallback(() => {
    if (withDate && setDateValue) {
      setDateValue(null);
    }
    setOpen(false);
  }, [setDateValue, withDate]);

  useEffect(() => {
    setReason('');
    setBlurred(false);
  }, [setReason, setBlurred, isOpen]);

  return value !== undefined ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        ...(error && { color: 'error.main' }),
        ...(!error && { color: 'success.main' }),
      }}
    >
      <Box component="span" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {typeof value === 'string' ? value : t(String(value))}
      </Box>
      {canToggle && onToggle ? (
        <>
          <Tooltip arrow title={tooltip || ''}>
            <Box ml="auto">
              <Button
                sx={{ minWidth: 'fit-content' }}
                disabled={disabled}
                color="secondary"
                variant="text"
                size="small"
                onClick={() => setOpen(true)}
              >
                <FontAwesomeIcon icon={faPen} />
              </Button>
            </Box>
          </Tooltip>
          <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={() => setOpen(false)}>
            <DialogTitle>{tooltip}</DialogTitle>
            <DialogContent>
              <Box>{description}</Box>
              {withReason || reasonValue ? (
                <TextField
                  sx={{ mt: 4, width: '100%' }}
                  value={reason}
                  error={showError}
                  helperText={showError ? t('lookup.verification.reasonRequired') : undefined}
                  required
                  label={reasonLabel || t('lookup.verification.reason')}
                  onChange={event => setReason(event.target.value)}
                  onBlur={() => setBlurred(true)}
                />
              ) : null}
              {withDate ? (
                <Box sx={{ mt: 4, width: '100%' }}>
                  <DateTimePicker
                    label={dateLabel}
                    onChange={date => {
                      setDateValue && setDateValue(date ?? new Date());
                    }}
                    value={dateValue}
                    renderInput={params => <TextField size="small" sx={{ mb: 2 }} fullWidth {...params} />}
                    inputFormat={DATETIME_INPUT_FORMAT}
                    mask={DATETIME_INPUT_MASK}
                    minDate={new Date()}
                  />
                </Box>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} color="error" variant="outlined">
                {t('cancel')}
              </Button>
              <Button onClick={onConfirm} variant="contained">
                {t('confirm')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}
    </Box>
  ) : null;
};

export default GenericField;
