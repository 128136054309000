import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCaretDown, faCaretUp, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AcurisIndividualProfileSchema } from '@greenisland-api';
import {
  Avatar,
  Box,
  capitalize,
  Card,
  CardHeader,
  Collapse,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

interface Props {
  data: AcurisIndividualProfileSchema | undefined;
}

const KYCPersonalDetailsCard = ({ data }: Props) => {
  const [personalDetailsExpanded, setPersonalDetailsExpanded] = useState(true);
  const [isImageDialogOpen, setIsImageDialogOpen] = useState<boolean>(false);
  const [selectedProfileImage, setSelectedProfileImage] = useState<string | undefined>(undefined);
  const { t } = useTranslation();

  const onCloseDialog = () => {
    setIsImageDialogOpen(false);
  };

  return (
    <>
      <Card
        sx={{ backgroundColor: 'white' }}
        elevation={3}
        style={{ padding: '20px', marginBottom: '20px', marginTop: '20px' }}
      >
        <CardHeader
          title={t('kyc.resource.personalDetails')}
          action={
            <IconButton
              aria-expanded={personalDetailsExpanded}
              onClick={() => setPersonalDetailsExpanded(!personalDetailsExpanded)}
            >
              <FontAwesomeIcon icon={personalDetailsExpanded ? faCaretUp : faCaretDown} />
            </IconButton>
          }
        />
        <Collapse in={personalDetailsExpanded} timeout="auto" unmountOnExit>
          {data?.profileImages.map((image: string, index: number) => (
            <IconButton
              key={index}
              onClick={() => {
                setSelectedProfileImage(image);
                setIsImageDialogOpen(true);
              }}
            >
              <Avatar key={index} src={image} sx={{ width: 84, height: 84 }} />
            </IconButton>
          ))}
          <Typography>
            {capitalize(t('name'))}:{data?.firstName ? ` ${data?.firstName}` : ''}
            {data?.middleName ? ` ${data?.middleName}` : ''}
            {data?.lastName ? ` ${data?.lastName}` : ''}
          </Typography>
          {data && data.aliases.length > 0 && (
            <Typography>
              <span>{t('kyc.resource.aliases')}: </span>
              {data?.aliases
                .map((alias: any) => `${alias.firstName || ''} ${alias.middleName || ''} ${alias.lastName || ''}`)
                .join(', ')}
            </Typography>
          )}
          {data && data.datesOfBirth.length > 0 && (
            <Typography>
              <span>{t('kyc.headers.datesOfBirth')}: </span>
              {data?.datesOfBirth.join(', ')}
            </Typography>
          )}
          {data?.datesOfDeath && data?.datesOfDeath.length > 0 && (
            <Typography>
              <span>{t('kyc.resource.datesOfDeath')}: </span>
              {data?.datesOfDeath.join(', ')}
            </Typography>
          )}
          {data && data.nationalities.length > 0 && (
            <Typography>
              <span>{t('kyc.resource.nationalities')}: </span>
              {data?.nationalities.join(', ')}
            </Typography>
          )}
        </Collapse>
      </Card>
      <Dialog open={isImageDialogOpen} onClose={onCloseDialog}>
        <DialogTitle id="id">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              {data?.firstName ? `${data?.firstName} ` : ''}
              {data?.middleName ? `${data?.middleName} ` : ''}
              {data?.lastName ? `${data?.lastName}` : ''}
            </Box>
            <Box>
              <IconButton onClick={onCloseDialog}>
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <img src={selectedProfileImage} />
      </Dialog>
    </>
  );
};

export default memo(KYCPersonalDetailsCard);
