import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faAdd, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GameThemeSchema,
  getGetGameThemesQueryKey,
  useDeleteGameTheme,
  useGetGameThemes,
  useUpdateGameTheme,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Stack } from '@mui/material';
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowOrderChangeParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { CheckboxIcon, DataGridContainer, StyledDataGrid } from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import ConfirmDialog from '../../../../../app/components/ConfirmDialog';
import { usePermission } from '../../../../../app/hooks';
import ImageCell from '../Components/ImageCell';
import useGetTranslationSchemaLocale from '../Components/utils/useGetTranslationSchemaLocale';
import { GameThemeDialog } from './components';

type Row = GameThemeSchema & { id: string | undefined };

const Themes = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const canCreateGameTheme = usePermission(OnlineCasinoPermissions.addGameTheme);
  const canEditGameTheme = usePermission(OnlineCasinoPermissions.deleteGameTheme);
  const canReadGameThemes = usePermission(OnlineCasinoPermissions.getGameThemes);
  const updateMutation = useUpdateGameTheme();
  const getTranslation = useGetTranslationSchemaLocale();

  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<{ isOpen: boolean; gameThemeId?: string }>({
    isOpen: false,
  });

  const { data: gameThemes, isLoading, isError, refetch } = useGetGameThemes();
  const mutation = useDeleteGameTheme({
    mutation: {
      onSuccess: () => {
        setDeleteConfirmationDialog({ isOpen: false });
        queryClient.invalidateQueries(getGetGameThemesQueryKey());
        enqueueSnackbar(t('content.games.themes.deleteSuccess'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('content.games.themes.deleteError'), { variant: 'error' });
      },
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const [selectedThemeId, setSelectedThemeId] = useState<string | undefined>(undefined);
  const selectedTheme = useMemo(
    () => gameThemes?.find(({ gameThemeId }) => gameThemeId === selectedThemeId),
    [gameThemes, selectedThemeId]
  );

  const handleConfirm = () => {
    if (deleteConfirmationDialog.gameThemeId) {
      mutation.mutate({ gameThemeId: deleteConfirmationDialog.gameThemeId });
    }
  };

  const handleClickEdit = (themeId?: string) => {
    setSelectedThemeId(themeId);
    setIsOpen(true);
  };

  const rows = useMemo(
    () =>
      gameThemes
        ? gameThemes
            .map<Row>(theme => ({ ...theme, id: theme.gameThemeId }))
            .sort((a, b) => {
              return a.order - b.order;
            })
        : [],
    [gameThemes]
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'codeNameTranslations',
        headerName: t('name'),
        flex: 1,
        valueGetter: params => getTranslation(params?.row?.nameTranslations) ?? params?.row?.name,
      },
      { field: 'code', headerName: t('code'), flex: 1 },
      {
        field: 'gameThemeImageUrl',
        headerName: t('content.games.themes.thumbnail'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Row>) => <ImageCell value={params.row.gameThemeImageUrl} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'enabledInLobby',
        headerName: t('content.games.themes.enabledInLobby'),
        type: 'boolean',
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
      },
      {
        field: 'order',
        headerName: t('sortOrder'),
        type: 'number',
        flex: 0.3,
      },
      ...(canEditGameTheme
        ? [
            {
              field: 'actions',
              type: 'actions',
              getActions: (params: GridRowParams) => [
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faPencil} />}
                  label="Edit"
                  onClick={() => handleClickEdit(params.row.gameThemeId)}
                  key="edit"
                />,
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  label="Delete"
                  onClick={() => setDeleteConfirmationDialog({ isOpen: true, gameThemeId: params.id as string })}
                  key="delete"
                />,
              ],
            },
          ]
        : []),
    ],
    [canEditGameTheme, t, getTranslation]
  );

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const oldRow = rows[params.oldIndex];
    const newRow = rows[params.targetIndex];

    const response = await Promise.allSettled([
      updateMutation.mutateAsync({
        gameThemeId: oldRow.id as string,
        data: { ...oldRow, order: params.targetIndex },
      }),
      updateMutation.mutateAsync({
        gameThemeId: newRow.id as string,
        data: { ...newRow, order: params.oldIndex },
      }),
    ]);

    const error = response.find(p => p.status === 'rejected');
    queryClient.invalidateQueries(getGetGameThemesQueryKey());

    if (error) {
      enqueueSnackbar(t('content.games.categories.orderError'), { variant: 'error' });
    } else {
      enqueueSnackbar(t('content.games.categories.orderSuccess'), { variant: 'success' });
    }
  };

  return canReadGameThemes ? (
    <>
      <Stack spacing={2}>
        {canCreateGameTheme ? (
          <div>
            <Button
              variant="text"
              startIcon={<FontAwesomeIcon icon={faAdd} />}
              onClick={() => handleClickEdit(undefined)}
            >
              {t('content.games.themes.add')}
            </Button>
          </div>
        ) : null}
        {!isError ? (
          <DataGridContainer>
            <StyledDataGrid
              density="compact"
              autoHeight
              loading={isLoading}
              rows={rows}
              columns={columns}
              pagination={false}
              rowReordering={canEditGameTheme}
              onRowOrderChange={handleRowOrderChange}
            />
          </DataGridContainer>
        ) : (
          <ErrorState errorMessage={t('content.games.themes.loadingError')} retryAction={() => refetch()} />
        )}
      </Stack>
      <ConfirmDialog
        title={t('content.games.themes.deleteConfirmation.title')}
        content={t('content.games.themes.deleteConfirmation.description')}
        isOpen={deleteConfirmationDialog.isOpen}
        setIsOpen={(open: boolean) => setDeleteConfirmationDialog({ ...deleteConfirmationDialog, isOpen: open })}
        onConfirm={handleConfirm}
      />
      {canEditGameTheme ? (
        <GameThemeDialog
          onClose={() => {
            setIsOpen(false);
            setSelectedThemeId(undefined);
          }}
          open={isOpen}
          theme={selectedTheme}
        />
      ) : null}
    </>
  ) : null;
};

export default Themes;
