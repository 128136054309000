import React, { memo, useState } from 'react';
import { Authenticator, Flex, Grid, Heading, Image, Text, useTheme, View } from '@aws-amplify/ui-react';

const AmplifyHeader = () => {
  const { tokens } = useTheme();

  return <Image alt="logo" src="img/GI-logo-flat-darkAsset.png" padding={tokens.space.medium} />;
};

export default AmplifyHeader;
