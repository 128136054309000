import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  ApproveCancelPlayingBreakRequestSchema,
  getGetOpenAgentTasksQueryKey,
  PlayingBreakBadRequestContentSchema,
  PlayingBreakBadRequestResponse,
  useCancelPlayingBreakV2,
  useDeclinePlayingBreakCancellationRequest,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { usePermission } from 'src/app/hooks';

import { PlayingBreakRequestType } from './PlayinBreakFormContext';
import PlayingBreakDialog from './PlayingBreakDialog';

interface Props {
  userId?: number;
}

const PlayingBreakRequestAction = ({ userId }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const canDeletePlayingBreak = usePermission(OnlineCasinoPermissions.cancelPlayingBreak);
  const canCancelPlayingBreakRequest = usePermission(OnlineCasinoPermissions.declinePlayingBreakCancellationRequest);
  const [requestDialogType, setRequestDialogType] = useState<PlayingBreakRequestType | null>(null);

  const isCancelRequestType = requestDialogType === PlayingBreakRequestType.CANCEL;

  const { mutate: cancelPlayingBreakMutation, isLoading: isCancelPlayingBreakMutationLoading } =
    useCancelPlayingBreakV2({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries(getGetOpenAgentTasksQueryKey());
          setRequestDialogType(null);
          enqueueSnackbar(t('success'), { variant: 'success' });
        },
        onError: ({ error }: PlayingBreakBadRequestResponse) => {
          enqueueSnackbar(t(error.message), { variant: 'error' });
        },
      },
    });

  const { mutate: declinePlayingBreakMutation, isLoading: isDeclinePlayingBreakMutationLoading } =
    useDeclinePlayingBreakCancellationRequest({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries(getGetOpenAgentTasksQueryKey());
          setRequestDialogType(null);
          enqueueSnackbar(t('success'), { variant: 'success' });
        },
        onError: ({ error }: PlayingBreakBadRequestContentSchema) => {
          enqueueSnackbar(t(error.message), { variant: 'error' });
        },
      },
    });

  const onSubmit = (data: ApproveCancelPlayingBreakRequestSchema) => {
    if (!userId) return;

    if (isCancelRequestType) {
      cancelPlayingBreakMutation({ userId, data });
    } else {
      declinePlayingBreakMutation({ userId, data });
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: 1 }}>
        {userId ? (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={() => setRequestDialogType(PlayingBreakRequestType.CANCEL)}
              disabled={!canDeletePlayingBreak || !userId || isCancelPlayingBreakMutationLoading}
            >
              {t('playingbreak.cancel')}
            </Button>
            <Button
              color="error"
              variant="contained"
              size="small"
              onClick={() => setRequestDialogType(PlayingBreakRequestType.REMOVE)}
              disabled={!canCancelPlayingBreakRequest || !userId || isDeclinePlayingBreakMutationLoading}
            >
              {t('playingbreak.removeCancelRequest')}
            </Button>
          </>
        ) : (
          <Typography color={'error'} variant="caption">
            {t('regulator.InvalidUserId')}
          </Typography>
        )}
      </Box>
      {userId && (
        <PlayingBreakDialog
          isLoading={isCancelPlayingBreakMutationLoading || isDeclinePlayingBreakMutationLoading}
          title={isCancelRequestType ? t('playingbreak.cancel') : t('playingbreak.removeCancelRequest')}
          requestDialogType={requestDialogType}
          setRequestDialogType={setRequestDialogType}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};

export default memo(PlayingBreakRequestAction);
