import { MouseEvent, useState } from 'react';
import { Trans } from 'react-i18next';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Box, CardHeader, Popover, Typography } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

interface Props {
  title: string;
  message: string;
}

const KillSwitchHeaderPopoverContent = ({ title, message }: Props) => {
  const [popoverElement, setPopoverElement] = useState<SVGSVGElement | null>(null);
  const isPopoverOpen = Boolean(popoverElement);

  const handlePopoverOpen = (event: MouseEvent<SVGSVGElement>) => {
    setPopoverElement(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverElement(null);
  };

  return (
    <Box display="flex" alignItems="center">
      <CardHeader sx={{ pr: 1 }} title={title} />
      <FontAwesomeIcon
        aria-owns={isPopoverOpen ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        icon={faCircleInfo}
        sx={{ color: 'array.headers', cursor: 'pointer', display: 'inline' }}
      />

      <Box display="flex" alignItems="center">
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={isPopoverOpen}
          anchorEl={popoverElement}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
          style={{ marginTop: 20 }}
        >
          <Box p={2}>
            <Trans
              i18nKey={message}
              components={{
                p: <Typography sx={{ mb: 1 }} variant="h5" fontSize="medium" />,
                strong: <strong style={{ fontWeight: '600' }} />,
              }}
            />
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

export default KillSwitchHeaderPopoverContent;
