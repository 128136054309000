import React from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/material';

import { ErrorWrapper } from '@greenisland-common/components/atoms';
import { ErrorProps } from '@greenisland-common/components/atoms/ErrorWrapper/ErrorWrapper';

interface Props extends ErrorProps {
  error: boolean;
  message?: string;
  sx?: SxProps;
  placeholderSx?: SxProps;
}

const FormError = ({ error, sx, message, placeholderSx, ...rest }: Props): JSX.Element => {
  const { t } = useTranslation();

  return error ? (
    <ErrorWrapper sx={{ mt: 0.5, minHeight: '22px', ...sx }} {...rest}>
      {message || t('error')}
    </ErrorWrapper>
  ) : (
    <ErrorWrapper sx={{ mt: 0.5, minHeight: '22px', ...placeholderSx }} {...rest} />
  );
};

export default FormError;
