import { Base64FileSchema, EMIMETypeSchema } from '@greenisland-api';

export const downloadBase64File = (file: Base64FileSchema) => {
  const linkSource = `data:${file.mimeType};base64,${file.content}`;
  const downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = file.name;
  downloadLink.click();
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString().split(';base64,')[1] ?? '');
    reader.onerror = error => reject(error);
  });
};

export const formatBase64String = (base64: string, mimeType: EMIMETypeSchema) => {
  if (base64.startsWith('data:')) {
    return base64;
  }
  return `data:${mimeType};base64,${base64}`;
};

export const extractBase64String = (base64: string) => {
  return base64.split(';base64,')[1];
};
