/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * If `Custom` is used, other parameters `startDate` and `endDate` must be provided.
 */
export type GameSessionsActivityPeriodParamParameter =
  (typeof GameSessionsActivityPeriodParamParameter)[keyof typeof GameSessionsActivityPeriodParamParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GameSessionsActivityPeriodParamParameter = {
  SinceLastWeek: 'SinceLastWeek',
  SinceLastDeposit: 'SinceLastDeposit',
  Custom: 'Custom',
} as const;
