import React, { ComponentPropsWithoutRef } from 'react';
import { Control, useController } from 'react-hook-form';
import { Checkbox as MuiCheckbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

type Props = ComponentPropsWithoutRef<typeof MuiCheckbox> & {
  name: string;
  label: string;
  helpText?: string;
  control: Control;
};

const CheckboxField = (props: Props) => {
  const { name, control, label, helpText, ...restProps } = props;
  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({
    name,
    control,
  });
  return (
    <FormControl variant="standard">
      <FormControlLabel
        control={
          <MuiCheckbox
            onChange={e => onChange(e.target.checked)}
            checked={value}
            inputRef={ref}
            {...inputProps}
            {...restProps}
          />
        }
        label={label}
      />
      {helpText ? <FormHelperText>{helpText}</FormHelperText> : null}
    </FormControl>
  );
};

export default CheckboxField;
