import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CasinoSchema, EWithdrawProviderSchema, useGetCasinosV2, useWithdrawManually } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  styled,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { SelectInput } from '@greenisland-common/components/atoms';

import { formatCurrency } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const CardContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% / 3)',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

type FormData = {
  userId: string;
  amountofWithdrawal: number;
  destination: EWithdrawProviderSchema;
  walletData: string;
};

const WithdrawManually = () => {
  const { t } = useTranslation();
  const { userId } = useParams();

  const canReadCasinos = usePermission(OnlineCasinoPermissions.getCasinos);
  const canWithdrawManually = usePermission(OnlineCasinoPermissions.withdrawManually);

  const methods = useForm<FormData>({
    defaultValues: {
      destination: EWithdrawProviderSchema.bankAccount,
      userId: userId,
      amountofWithdrawal: 0,
      walletData: '',
    },
  });

  const { register, handleSubmit, errors, getValues, watch, setValue } = methods;

  const isCasinoDestination = watch('destination') === EWithdrawProviderSchema.casino;

  const { data: casinoList, isLoading } = useGetCasinosV2(
    { numberOfItems: 1000 },
    { query: { enabled: canReadCasinos } }
  );

  const [openDialog, setOpenDialog] = useState<FormData | undefined>(undefined);

  useEffect(() => {
    setValue('destination', EWithdrawProviderSchema.bankAccount);
  }, [setValue]);

  const values = getValues();

  const onSubmit = (formData: FormData) => {
    setOpenDialog(formData);
  };

  return canWithdrawManually ? (
    <>
      <CardContainer>
        <Card>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardContent>
                <Stack spacing={2}>
                  <TextField
                    size="small"
                    label={t('userId')}
                    name={'userId'}
                    defaultValue={userId}
                    fullWidth
                    inputRef={register({ required: true })}
                    error={!!errors.userId}
                    helperText={errors.userId ? t('userIdRequired') : ''}
                  />
                  <TextField
                    size="small"
                    label={t('amountOfWithdrawal')}
                    name={'amountofWithdrawal'}
                    fullWidth
                    type="number"
                    inputRef={register({ validate: value => value > 0, required: true })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={faEuroSign} />
                        </InputAdornment>
                      ),
                    }}
                    error={!!errors.amountofWithdrawal}
                    helperText={errors.amountofWithdrawal ? t('amountValidationMessageZero') : ''}
                  />
                  <SelectInput
                    size="small"
                    name="destination"
                    label={t('destination')}
                    rules={{ required: true }}
                    options={Object.values(EWithdrawProviderSchema).map(provider => ({
                      label: t(provider),
                      value: provider,
                    }))}
                  />
                  {isCasinoDestination ? (
                    <SelectInput
                      size="small"
                      name="walletData"
                      rules={{ required: true }}
                      options={
                        casinoList?.casinos && casinoList?.casinos?.length > 0
                          ? casinoList.casinos.map(casino => ({ label: casino.name, value: casino.casinoId }))
                          : [{ label: t('placeholder'), value: 'placeholder' }]
                      }
                    />
                  ) : (
                    <TextField
                      size="small"
                      name={'walletData'}
                      label={values.destination === EWithdrawProviderSchema.bankAccount ? 'IBAN' : t('walletId')}
                      fullWidth
                      inputRef={register({ required: true })}
                      error={!!errors.walletData}
                      helperText={errors.walletData ? t('fieldIsRequired') : ''}
                    />
                  )}
                </Stack>
              </CardContent>
              <CardActions>
                <Button size="large" variant="contained" color="primary" fullWidth type="submit" disabled={isLoading}>
                  {t('withdraw')}
                </Button>
              </CardActions>
            </form>
          </FormProvider>
        </Card>
      </CardContainer>
      {openDialog && (
        <ConfirmDialog casinoList={casinoList?.casinos ?? []} data={openDialog} setOpenDialog={setOpenDialog} />
      )}
    </>
  ) : null;
};

const ConfirmDialog = (props: { data: FormData; setOpenDialog: any; casinoList: CasinoSchema[] | undefined }) => {
  const { data, setOpenDialog, casinoList } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: withdrawManually, isLoading: isWithdrawManuallyLoading } = useWithdrawManually({
    mutation: {
      onSuccess: () => {
        setOpenDialog(false);
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: error => {
        enqueueSnackbar(error?.message || t('somethingWentWrong'), { variant: 'error' });
      },
    },
  });

  const onSubmit = () => {
    if (data) {
      withdrawManually({ data: { ...data, amountofWithdrawal: Number(data.amountofWithdrawal) } });
    }
  };

  return (
    <Dialog open={!!props.data}>
      <DialogTitle>{t('confirmWithdrawal')}</DialogTitle>
      <DialogContent>
        <div>
          <div>
            {t('userId')}: {data?.userId}
          </div>
          <div>
            {t('amountOfWithdrawal')}: {data && formatCurrency(data.amountofWithdrawal)}
          </div>
          <div>
            {t('destination')}: {data?.destination}
          </div>
          <div>
            {data?.destination === EWithdrawProviderSchema.bankAccount && `IBAN: ${data.walletData}`}
            {data?.destination === EWithdrawProviderSchema.casino &&
              `${t('casino')}: ${casinoList?.find(casino => casino.casinoId === data.walletData)?.name}`}
            {data?.destination !== EWithdrawProviderSchema.bankAccount &&
              data?.destination !== EWithdrawProviderSchema.casino &&
              `${t('walletId')}: ${data.walletData}`}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => setOpenDialog(false)} color="secondary">
          {t('cancel')}
        </Button>
        <LoadingButton
          loading={isWithdrawManuallyLoading}
          variant="contained"
          type="submit"
          color="primary"
          onClick={() => onSubmit()}
        >
          {t('confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default WithdrawManually;
