/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  BoosterPackBadRequestResponse,
  BoosterPackCardsSchema,
  BoosterPackCardUpdateSchema,
  BoosterPacksSchema,
  BoosterPackUpdateSchema,
  DefaultResponse,
  GetBoosterPackCardsParams,
  GetBoosterPacksParams,
  NotFoundResponse,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Get the list of booster pack
 * @summary Get the list of booster pack
 */
export const useGetBoosterPacksHook = () => {
  const getBoosterPacks = useCustomInstance<BoosterPacksSchema>();

  return (params?: GetBoosterPacksParams, signal?: AbortSignal) => {
    return getBoosterPacks({ url: `/booster-packs`, method: 'get', params, signal });
  };
};

export const getGetBoosterPacksQueryKey = (params?: GetBoosterPacksParams) =>
  [`/booster-packs`, ...(params ? [params] : [])] as const;

export const useGetBoosterPacksQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBoosterPacksHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetBoosterPacksParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBoosterPacksHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBoosterPacksHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBoosterPacksQueryKey(params);

  const getBoosterPacks = useGetBoosterPacksHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBoosterPacksHook>>>> = ({ signal }) =>
    getBoosterPacks(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetBoosterPacksQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetBoosterPacksHook>>>>;
export type GetBoosterPacksQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get the list of booster pack
 */
export const useGetBoosterPacks = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBoosterPacksHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetBoosterPacksParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBoosterPacksHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBoosterPacksQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Add a booster pack
 * @summary Add a booster pack
 */
export const useAddBoosterPackHook = () => {
  const addBoosterPack = useCustomInstance<void>();

  return (boosterPackUpdateSchema: BoosterPackUpdateSchema) => {
    return addBoosterPack({
      url: `/booster-packs`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: boosterPackUpdateSchema,
    });
  };
};

export const useAddBoosterPackMutationOptions = <
  TError = BoosterPackBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddBoosterPackHook>>>,
    TError,
    { data: BoosterPackUpdateSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddBoosterPackHook>>>,
  TError,
  { data: BoosterPackUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addBoosterPack = useAddBoosterPackHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddBoosterPackHook>>>,
    { data: BoosterPackUpdateSchema }
  > = props => {
    const { data } = props ?? {};

    return addBoosterPack(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddBoosterPackMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddBoosterPackHook>>>>;
export type AddBoosterPackMutationBody = BoosterPackUpdateSchema;
export type AddBoosterPackMutationError =
  | BoosterPackBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Add a booster pack
 */
export const useAddBoosterPack = <
  TError = BoosterPackBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddBoosterPackHook>>>,
    TError,
    { data: BoosterPackUpdateSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddBoosterPackMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update a booster pack
 * @summary Update a booster pack
 */
export const useUpdateBoosterPackHook = () => {
  const updateBoosterPack = useCustomInstance<void>();

  return (boosterPackId: number, boosterPackUpdateSchema: BoosterPackUpdateSchema) => {
    return updateBoosterPack({
      url: `/booster-packs/${boosterPackId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: boosterPackUpdateSchema,
    });
  };
};

export const useUpdateBoosterPackMutationOptions = <
  TError = BoosterPackBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateBoosterPackHook>>>,
    TError,
    { boosterPackId: number; data: BoosterPackUpdateSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateBoosterPackHook>>>,
  TError,
  { boosterPackId: number; data: BoosterPackUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateBoosterPack = useUpdateBoosterPackHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateBoosterPackHook>>>,
    { boosterPackId: number; data: BoosterPackUpdateSchema }
  > = props => {
    const { boosterPackId, data } = props ?? {};

    return updateBoosterPack(boosterPackId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateBoosterPackMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateBoosterPackHook>>>
>;
export type UpdateBoosterPackMutationBody = BoosterPackUpdateSchema;
export type UpdateBoosterPackMutationError =
  | BoosterPackBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Update a booster pack
 */
export const useUpdateBoosterPack = <
  TError = BoosterPackBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateBoosterPackHook>>>,
    TError,
    { boosterPackId: number; data: BoosterPackUpdateSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateBoosterPackMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get the list of booster pack card
 * @summary Get the list of booster pack card
 */
export const useGetBoosterPackCardsHook = () => {
  const getBoosterPackCards = useCustomInstance<BoosterPackCardsSchema>();

  return (params?: GetBoosterPackCardsParams, signal?: AbortSignal) => {
    return getBoosterPackCards({ url: `/booster-packs/cards`, method: 'get', params, signal });
  };
};

export const getGetBoosterPackCardsQueryKey = (params?: GetBoosterPackCardsParams) =>
  [`/booster-packs/cards`, ...(params ? [params] : [])] as const;

export const useGetBoosterPackCardsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBoosterPackCardsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetBoosterPackCardsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBoosterPackCardsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBoosterPackCardsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBoosterPackCardsQueryKey(params);

  const getBoosterPackCards = useGetBoosterPackCardsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBoosterPackCardsHook>>>> = ({ signal }) =>
    getBoosterPackCards(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetBoosterPackCardsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBoosterPackCardsHook>>>
>;
export type GetBoosterPackCardsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the list of booster pack card
 */
export const useGetBoosterPackCards = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBoosterPackCardsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetBoosterPackCardsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBoosterPackCardsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBoosterPackCardsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Add a booster pack card
 * @summary Add a booster pack card
 */
export const useAddBoosterPackCardHook = () => {
  const addBoosterPackCard = useCustomInstance<void>();

  return (boosterPackCardUpdateSchema: BoosterPackCardUpdateSchema) => {
    return addBoosterPackCard({
      url: `/booster-packs/cards`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: boosterPackCardUpdateSchema,
    });
  };
};

export const useAddBoosterPackCardMutationOptions = <
  TError = BoosterPackBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddBoosterPackCardHook>>>,
    TError,
    { data: BoosterPackCardUpdateSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddBoosterPackCardHook>>>,
  TError,
  { data: BoosterPackCardUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addBoosterPackCard = useAddBoosterPackCardHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddBoosterPackCardHook>>>,
    { data: BoosterPackCardUpdateSchema }
  > = props => {
    const { data } = props ?? {};

    return addBoosterPackCard(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddBoosterPackCardMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddBoosterPackCardHook>>>
>;
export type AddBoosterPackCardMutationBody = BoosterPackCardUpdateSchema;
export type AddBoosterPackCardMutationError =
  | BoosterPackBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Add a booster pack card
 */
export const useAddBoosterPackCard = <
  TError = BoosterPackBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddBoosterPackCardHook>>>,
    TError,
    { data: BoosterPackCardUpdateSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddBoosterPackCardMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update a booster pack card
 * @summary Update a booster pack card
 */
export const useUpdateBoosterPackCardHook = () => {
  const updateBoosterPackCard = useCustomInstance<void>();

  return (boosterPackCardId: number, boosterPackCardUpdateSchema: BoosterPackCardUpdateSchema) => {
    return updateBoosterPackCard({
      url: `/booster-packs/cards/${boosterPackCardId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: boosterPackCardUpdateSchema,
    });
  };
};

export const useUpdateBoosterPackCardMutationOptions = <
  TError = BoosterPackBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateBoosterPackCardHook>>>,
    TError,
    { boosterPackCardId: number; data: BoosterPackCardUpdateSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateBoosterPackCardHook>>>,
  TError,
  { boosterPackCardId: number; data: BoosterPackCardUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateBoosterPackCard = useUpdateBoosterPackCardHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateBoosterPackCardHook>>>,
    { boosterPackCardId: number; data: BoosterPackCardUpdateSchema }
  > = props => {
    const { boosterPackCardId, data } = props ?? {};

    return updateBoosterPackCard(boosterPackCardId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateBoosterPackCardMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateBoosterPackCardHook>>>
>;
export type UpdateBoosterPackCardMutationBody = BoosterPackCardUpdateSchema;
export type UpdateBoosterPackCardMutationError =
  | BoosterPackBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Update a booster pack card
 */
export const useUpdateBoosterPackCard = <
  TError = BoosterPackBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateBoosterPackCardHook>>>,
    TError,
    { boosterPackCardId: number; data: BoosterPackCardUpdateSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateBoosterPackCardMutationOptions(options);

  return useMutation(mutationOptions);
};
