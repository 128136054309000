import { memo } from 'react';
import { useAppSelector } from '@greenisland-core/store';
import { Box, CircularProgress } from '@mui/material';

import HighLevelRouteContainer from '../HighLevelRouteContainer';

const LoginStatusCheck = () => {
  const { fetching } = useAppSelector(state => state.authentication);

  return fetching ? (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress />
    </Box>
  ) : (
    <HighLevelRouteContainer />
  );
};

export default memo(LoginStatusCheck);
