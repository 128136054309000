import { ELoyaltyShopManagementBadRequestTypeSchema } from '@greenisland-api';

type LoyaltyShopManagementBadRequestTypeTranslation = {
  [key in ELoyaltyShopManagementBadRequestTypeSchema]: string;
};

export const LOYALTY_SHOP_MANAGEMENT_ERROR_REASONS: LoyaltyShopManagementBadRequestTypeTranslation = {
  [ELoyaltyShopManagementBadRequestTypeSchema.modelStateInvalid]: 'loyaltymanagement.badRequestType.modelStateInvalid',
  [ELoyaltyShopManagementBadRequestTypeSchema.invalidShopId]: 'loyaltymanagement.badRequestType.invalidShopId',
  [ELoyaltyShopManagementBadRequestTypeSchema.shopNotFound]: 'loyaltymanagement.badRequestType.shopNotFound',
  [ELoyaltyShopManagementBadRequestTypeSchema.invalidShopTitle]: 'loyaltymanagement.badRequestType.invalidShopTitle',
  [ELoyaltyShopManagementBadRequestTypeSchema.invalidImageUrl]: 'loyaltymanagement.badRequestType.invalidImageUrl',
  [ELoyaltyShopManagementBadRequestTypeSchema.priceNegative]: 'loyaltymanagement.badRequestType.priceNegative',
  [ELoyaltyShopManagementBadRequestTypeSchema.noFulfillmentActionId]:
    'loyaltymanagement.badRequestType.noFulfillmentActionId',
  [ELoyaltyShopManagementBadRequestTypeSchema.noFulfillmentItem]: 'loyaltymanagement.badRequestType.noFulfillmentItem',
};
