/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EComparisonSchema = (typeof EComparisonSchema)[keyof typeof EComparisonSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EComparisonSchema = {
  None: 'None',
  GreaterThan: 'GreaterThan',
  GreaterThanOrEqual: 'GreaterThanOrEqual',
  EqualTo: 'EqualTo',
  LessThan: 'LessThan',
  LessThanOrEqual: 'LessThanOrEqual',
} as const;
