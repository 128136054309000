/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  DefaultResponse,
  GetPromotionalCalendarsParams,
  NotFoundResponse,
  PromotionalCalendarBadRequestResponse,
  PromotionalCalendarSchema,
  PromotionalCalendarsSchema,
  PromotionalCalendarUpdateSchema,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Get the list of promotional calendars
 * @summary Get the list of promotional calendars
 */
export const useGetPromotionalCalendarsHook = () => {
  const getPromotionalCalendars = useCustomInstance<PromotionalCalendarsSchema>();

  return (params?: GetPromotionalCalendarsParams, signal?: AbortSignal) => {
    return getPromotionalCalendars({ url: `/promotional-calendars`, method: 'get', params, signal });
  };
};

export const getGetPromotionalCalendarsQueryKey = (params?: GetPromotionalCalendarsParams) =>
  [`/promotional-calendars`, ...(params ? [params] : [])] as const;

export const useGetPromotionalCalendarsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPromotionalCalendarsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetPromotionalCalendarsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPromotionalCalendarsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPromotionalCalendarsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPromotionalCalendarsQueryKey(params);

  const getPromotionalCalendars = useGetPromotionalCalendarsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPromotionalCalendarsHook>>>> = ({ signal }) =>
    getPromotionalCalendars(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPromotionalCalendarsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPromotionalCalendarsHook>>>
>;
export type GetPromotionalCalendarsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the list of promotional calendars
 */
export const useGetPromotionalCalendars = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPromotionalCalendarsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetPromotionalCalendarsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPromotionalCalendarsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPromotionalCalendarsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Add a promotional calendar
 * @summary Add a promotional calendar
 */
export const useAddPromotionalCalendarHook = () => {
  const addPromotionalCalendar = useCustomInstance<void>();

  return (promotionalCalendarUpdateSchema: PromotionalCalendarUpdateSchema) => {
    return addPromotionalCalendar({
      url: `/promotional-calendars`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: promotionalCalendarUpdateSchema,
    });
  };
};

export const useAddPromotionalCalendarMutationOptions = <
  TError = PromotionalCalendarBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddPromotionalCalendarHook>>>,
    TError,
    { data: PromotionalCalendarUpdateSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddPromotionalCalendarHook>>>,
  TError,
  { data: PromotionalCalendarUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addPromotionalCalendar = useAddPromotionalCalendarHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddPromotionalCalendarHook>>>,
    { data: PromotionalCalendarUpdateSchema }
  > = props => {
    const { data } = props ?? {};

    return addPromotionalCalendar(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddPromotionalCalendarMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddPromotionalCalendarHook>>>
>;
export type AddPromotionalCalendarMutationBody = PromotionalCalendarUpdateSchema;
export type AddPromotionalCalendarMutationError =
  | PromotionalCalendarBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Add a promotional calendar
 */
export const useAddPromotionalCalendar = <
  TError = PromotionalCalendarBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddPromotionalCalendarHook>>>,
    TError,
    { data: PromotionalCalendarUpdateSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddPromotionalCalendarMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get a promotional calendar
 * @summary Get a promotional calendar
 */
export const useGetPromotionalCalendarByIdHook = () => {
  const getPromotionalCalendarById = useCustomInstance<PromotionalCalendarSchema>();

  return (calendarId: number, signal?: AbortSignal) => {
    return getPromotionalCalendarById({ url: `/promotional-calendars/${calendarId}`, method: 'get', signal });
  };
};

export const getGetPromotionalCalendarByIdQueryKey = (calendarId: number) =>
  [`/promotional-calendars/${calendarId}`] as const;

export const useGetPromotionalCalendarByIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPromotionalCalendarByIdHook>>>,
  TError = PromotionalCalendarBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  calendarId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPromotionalCalendarByIdHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPromotionalCalendarByIdHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPromotionalCalendarByIdQueryKey(calendarId);

  const getPromotionalCalendarById = useGetPromotionalCalendarByIdHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPromotionalCalendarByIdHook>>>> = ({
    signal,
  }) => getPromotionalCalendarById(calendarId, signal);

  return { queryKey, queryFn, enabled: !!calendarId, ...queryOptions };
};

export type GetPromotionalCalendarByIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPromotionalCalendarByIdHook>>>
>;
export type GetPromotionalCalendarByIdQueryError =
  | PromotionalCalendarBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get a promotional calendar
 */
export const useGetPromotionalCalendarById = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPromotionalCalendarByIdHook>>>,
  TError = PromotionalCalendarBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  calendarId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPromotionalCalendarByIdHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPromotionalCalendarByIdQueryOptions(calendarId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Update a promotional calendar
 * @summary Update a promotional calendar
 */
export const useUpdatePromotionalCalendarHook = () => {
  const updatePromotionalCalendar = useCustomInstance<void>();

  return (calendarId: number, promotionalCalendarUpdateSchema: PromotionalCalendarUpdateSchema) => {
    return updatePromotionalCalendar({
      url: `/promotional-calendars/${calendarId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: promotionalCalendarUpdateSchema,
    });
  };
};

export const useUpdatePromotionalCalendarMutationOptions = <
  TError = PromotionalCalendarBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdatePromotionalCalendarHook>>>,
    TError,
    { calendarId: number; data: PromotionalCalendarUpdateSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdatePromotionalCalendarHook>>>,
  TError,
  { calendarId: number; data: PromotionalCalendarUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updatePromotionalCalendar = useUpdatePromotionalCalendarHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdatePromotionalCalendarHook>>>,
    { calendarId: number; data: PromotionalCalendarUpdateSchema }
  > = props => {
    const { calendarId, data } = props ?? {};

    return updatePromotionalCalendar(calendarId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePromotionalCalendarMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdatePromotionalCalendarHook>>>
>;
export type UpdatePromotionalCalendarMutationBody = PromotionalCalendarUpdateSchema;
export type UpdatePromotionalCalendarMutationError =
  | PromotionalCalendarBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Update a promotional calendar
 */
export const useUpdatePromotionalCalendar = <
  TError = PromotionalCalendarBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdatePromotionalCalendarHook>>>,
    TError,
    { calendarId: number; data: PromotionalCalendarUpdateSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdatePromotionalCalendarMutationOptions(options);

  return useMutation(mutationOptions);
};
