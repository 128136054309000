import {
  EClosedLoopRestrictionSchema,
  EClosedLoopTypeSchema,
  EPaymentMethodSchema,
  EPaymentProviderSchema,
  EPaymentServiceVersionSchema,
  ERequiredFieldSchema,
  EWindowTypeSchema,
  PaymentMethodLimitsDataSchema,
  PaymentMethodSchema,
} from '@greenisland-api';

export interface PaymentMethodFormSchema {
  method: EPaymentMethodSchema | string;
  provider: EPaymentProviderSchema | string;
  closedLoopType: EClosedLoopTypeSchema;
  closedLoopRestriction: EClosedLoopRestrictionSchema;
  paymentServiceVersion?: EPaymentServiceVersionSchema | string;
  depositsEnabled: boolean;
  withdrawalsEnabled: boolean;
  closedLoop: boolean;
  enabled: boolean;
  repeatable?: boolean;
  amlTurnoverRequired: boolean;
  serviceVersion: number | null;
  description?: string | null;
  requiredFields?: ERequiredFieldSchema[] | null;
  logoUrl?: string | null;
  logoGreyUrl?: string | null;
  logoWhiteUrl?: string | null;
  logoBlackUrl?: string | null;
  logoLightUrl?: string | null;
  logoDarkUrl?: string | null;
}

export const defaultPaymentMethodFormValue: PaymentMethodFormSchema = {
  method: '',
  provider: '',
  closedLoopType: EClosedLoopTypeSchema.None,
  closedLoopRestriction: EClosedLoopRestrictionSchema.Allowed,
  paymentServiceVersion: '',
  depositsEnabled: false,
  withdrawalsEnabled: false,
  closedLoop: false,
  enabled: false,
  repeatable: false,
  amlTurnoverRequired: true,
  serviceVersion: null,
  description: null,
  requiredFields: [],
  logoUrl: null,
  logoGreyUrl: null,
  logoWhiteUrl: null,
  logoBlackUrl: null,
  logoLightUrl: null,
  logoDarkUrl: null,
};

export const getDefaultPaymentMethodFormValue = (paymentMethod: PaymentMethodSchema): PaymentMethodFormSchema => ({
  method: paymentMethod.method,
  provider: paymentMethod.provider,
  closedLoopType: paymentMethod.closedLoopType ?? EClosedLoopTypeSchema.None,
  closedLoopRestriction: paymentMethod.closedLoopRestriction ?? EClosedLoopRestrictionSchema.Allowed,
  paymentServiceVersion: paymentMethod.paymentServiceVersion,
  depositsEnabled: paymentMethod.depositsEnabled,
  withdrawalsEnabled: paymentMethod.withdrawalsEnabled,
  closedLoop: paymentMethod.closedLoop,
  enabled: !paymentMethod.testUserOnly,
  repeatable: paymentMethod.repeatable,
  amlTurnoverRequired: paymentMethod.amlTurnoverRequired,
  serviceVersion: paymentMethod.integrationVersion,
  description: paymentMethod.description,
  requiredFields: paymentMethod.requiredFields ?? [],
  logoUrl: paymentMethod.logoUrl,
  logoGreyUrl: paymentMethod.logoGreyUrl,
  logoWhiteUrl: paymentMethod.logoWhiteUrl,
  logoBlackUrl: paymentMethod.logoBlackUrl,
  logoLightUrl: paymentMethod.logoColorLightUrl,
  logoDarkUrl: paymentMethod.logoColorDarkUrl,
});

export interface PaymentMethodLimitsFormSchema {
  minimumDepositAmount?: number;
  maximumDailyDepositLimit?: number;
  maximumWeeklyDepositLimit?: number;
  maximumMonthlyDepositLimit?: number;
  minimumWithdrawAmount?: number;
  maximumDailyWithdrawLimit?: number;
  maximumWeeklyWithdrawLimit?: number;
  maximumMonthlyWithdrawLimit?: number;
  maximumDailyInstantWithdrawLimit?: number;
  windowType?: EWindowTypeSchema;
  isMaxDailyWithdrawalsLoyaltyDependant?: boolean;
  maxDailyDeposits?: number;
  maxDailyWithdrawals?: number;
  instantWithdrawalDelay?: number;
}

export const defaultPaymentMethodLimitFormValue: PaymentMethodLimitsFormSchema = {
  minimumDepositAmount: undefined,
  maximumDailyDepositLimit: undefined,
  maximumWeeklyDepositLimit: undefined,
  maximumMonthlyDepositLimit: undefined,
  minimumWithdrawAmount: undefined,
  maximumDailyWithdrawLimit: undefined,
  maximumWeeklyWithdrawLimit: undefined,
  maximumMonthlyWithdrawLimit: undefined,
  maximumDailyInstantWithdrawLimit: undefined,
  windowType: EWindowTypeSchema.SlidingWindow,
  isMaxDailyWithdrawalsLoyaltyDependant: false,
  maxDailyDeposits: undefined,
  maxDailyWithdrawals: undefined,
  instantWithdrawalDelay: undefined,
};

export const getDefaultPaymentMethodLimitFormValue = (
  paymentMethodLimits: PaymentMethodLimitsDataSchema
): PaymentMethodLimitsFormSchema => ({
  minimumDepositAmount: paymentMethodLimits.minimumDepositAmount,
  maximumDailyDepositLimit: paymentMethodLimits.maximumDailyDepositLimit,
  maximumWeeklyDepositLimit: paymentMethodLimits.maximumWeeklyDepositLimit,
  maximumMonthlyDepositLimit: paymentMethodLimits.maximumMonthlyDepositLimit,
  minimumWithdrawAmount: paymentMethodLimits.minimumWithdrawAmount,
  maximumDailyWithdrawLimit: paymentMethodLimits.maximumDailyWithdrawLimit,
  maximumWeeklyWithdrawLimit: paymentMethodLimits.maximumWeeklyWithdrawLimit,
  maximumMonthlyWithdrawLimit: paymentMethodLimits.maximumMonthlyWithdrawLimit,
  maximumDailyInstantWithdrawLimit: paymentMethodLimits.maximumDailyInstantWithdrawLimit,
  isMaxDailyWithdrawalsLoyaltyDependant: paymentMethodLimits.isMaxDailyWithdrawalsLoyaltyDependant,
  windowType: paymentMethodLimits.windowType,
  maxDailyDeposits: paymentMethodLimits.maxDailyDeposits,
  maxDailyWithdrawals: paymentMethodLimits.maxDailyWithdrawals,
  instantWithdrawalDelay: paymentMethodLimits.instantWithdrawalDelay,
});
