import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AcurisIndividualProfileSchema } from '@greenisland-api';
import {
  capitalize,
  Card,
  CardHeader,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

interface Props {
  data: AcurisIndividualProfileSchema | undefined;
}

const KYCLinksDetailsCard = ({ data }: Props) => {
  const [linksExpanded, setLinksExpanded] = useState(false);
  const { t } = useTranslation();

  return (
    <Card style={{ padding: '20px', marginBottom: '20px', marginTop: '20px' }}>
      <CardHeader
        title="Links"
        action={
          <IconButton aria-expanded={linksExpanded} onClick={() => setLinksExpanded(!linksExpanded)}>
            <FontAwesomeIcon icon={linksExpanded ? faCaretUp : faCaretDown} />
          </IconButton>
        }
        style={{ paddingLeft: '0px' }}
      />
      <Collapse in={linksExpanded} timeout="auto" unmountOnExit>
        {data?.individualLinks && data.individualLinks.length > 0 && (
          <>
            <Typography>{t('kyc.resource.personalRelations')}</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{capitalize(t('firstName'))}</TableCell>
                    <TableCell>{capitalize(t('middleName'))}</TableCell>
                    <TableCell>{capitalize(t('middleName'))}</TableCell>
                    <TableCell>{t('kyc.headers.relationship')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.individualLinks.map((link, index) => {
                    const { firstName, middleName, lastName, relationship } = JSON.parse(link);

                    return (
                      <TableRow key={index}>
                        <TableCell>{firstName}</TableCell>
                        <TableCell>{middleName}</TableCell>
                        <TableCell>{lastName}</TableCell>
                        <TableCell>{relationship}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {data?.businessLinks && data.businessLinks.length > 0 && (
                <>
                  <Typography>{t('kyc.resource.businessRelations')}</Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{capitalize(t('name'))}</TableCell>
                          <TableCell>{t('kyc.headers.relationship')}</TableCell>
                          <TableCell>{t('kyc.headers.datasets')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.businessLinks.map((link, index) => {
                          const { name, relationship, datasets } = JSON.parse(link);

                          return (
                            <TableRow key={index}>
                              <TableCell>{name}</TableCell>
                              <TableCell>{relationship}</TableCell>
                              <TableCell>{JSON.stringify(datasets)}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </TableContainer>
          </>
        )}
      </Collapse>
    </Card>
  );
};

export default memo(KYCLinksDetailsCard);
