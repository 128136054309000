/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BechargeProductGroupsSchema,
  BeChargeVoucherTransactionsSchema,
  DefaultResponse,
  ErrorSchema,
  GetTransactionHistoryParams,
  OrderBechargeErrorSchema,
  TotalBechargeBalanceSchema,
  UnauthorizedResponse,
  UserVoucherSchema,
  VoucherInfoSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Order a becharge voucher. This only makes sense if the status is NotOrdered.
 */
export const useOrderBechargeVoucherHook = () => {
  const orderBechargeVoucher = useCustomInstance<UserVoucherSchema>();

  return (bechargeVoucherId: string) => {
    return orderBechargeVoucher({ url: `/vouchers/becharge/${bechargeVoucherId}/order`, method: 'post' });
  };
};

export const useOrderBechargeVoucherMutationOptions = <
  TError = OrderBechargeErrorSchema | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useOrderBechargeVoucherHook>>>,
    TError,
    { bechargeVoucherId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useOrderBechargeVoucherHook>>>,
  TError,
  { bechargeVoucherId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const orderBechargeVoucher = useOrderBechargeVoucherHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useOrderBechargeVoucherHook>>>,
    { bechargeVoucherId: string }
  > = props => {
    const { bechargeVoucherId } = props ?? {};

    return orderBechargeVoucher(bechargeVoucherId);
  };

  return { mutationFn, ...mutationOptions };
};

export type OrderBechargeVoucherMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useOrderBechargeVoucherHook>>>
>;

export type OrderBechargeVoucherMutationError = OrderBechargeErrorSchema | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Order a becharge voucher. This only makes sense if the status is NotOrdered.
 */
export const useOrderBechargeVoucher = <
  TError = OrderBechargeErrorSchema | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useOrderBechargeVoucherHook>>>,
    TError,
    { bechargeVoucherId: string },
    TContext
  >;
}) => {
  const mutationOptions = useOrderBechargeVoucherMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the total Becharge balance
 */
export const useGetBechargeBalanceHook = () => {
  const getBechargeBalance = useCustomInstance<TotalBechargeBalanceSchema>();

  return (signal?: AbortSignal) => {
    return getBechargeBalance({ url: `/vouchers/becharge/balance`, method: 'get', signal });
  };
};

export const getGetBechargeBalanceQueryKey = () => [`/vouchers/becharge/balance`] as const;

export const useGetBechargeBalanceQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBechargeBalanceHook>>>,
  TError = ErrorSchema | UnauthorizedResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBechargeBalanceHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBechargeBalanceHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBechargeBalanceQueryKey();

  const getBechargeBalance = useGetBechargeBalanceHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBechargeBalanceHook>>>> = ({ signal }) =>
    getBechargeBalance(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetBechargeBalanceQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBechargeBalanceHook>>>
>;
export type GetBechargeBalanceQueryError = ErrorSchema | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Get the total Becharge balance
 */
export const useGetBechargeBalance = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBechargeBalanceHook>>>,
  TError = ErrorSchema | UnauthorizedResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBechargeBalanceHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBechargeBalanceQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the details of a becharge order.
 */
export const useGetBechargeOrderDetailsHook = () => {
  const getBechargeOrderDetails = useCustomInstance<VoucherInfoSchema>();

  return (bechargeVoucherOrderReference: string, signal?: AbortSignal) => {
    return getBechargeOrderDetails({
      url: `/vouchers/becharge/${bechargeVoucherOrderReference}/details`,
      method: 'get',
      signal,
    });
  };
};

export const getGetBechargeOrderDetailsQueryKey = (bechargeVoucherOrderReference: string) =>
  [`/vouchers/becharge/${bechargeVoucherOrderReference}/details`] as const;

export const useGetBechargeOrderDetailsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBechargeOrderDetailsHook>>>,
  TError = ErrorSchema | UnauthorizedResponse | DefaultResponse
>(
  bechargeVoucherOrderReference: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBechargeOrderDetailsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBechargeOrderDetailsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBechargeOrderDetailsQueryKey(bechargeVoucherOrderReference);

  const getBechargeOrderDetails = useGetBechargeOrderDetailsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBechargeOrderDetailsHook>>>> = ({ signal }) =>
    getBechargeOrderDetails(bechargeVoucherOrderReference, signal);

  return { queryKey, queryFn, enabled: !!bechargeVoucherOrderReference, ...queryOptions };
};

export type GetBechargeOrderDetailsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBechargeOrderDetailsHook>>>
>;
export type GetBechargeOrderDetailsQueryError = ErrorSchema | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Get the details of a becharge order.
 */
export const useGetBechargeOrderDetails = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBechargeOrderDetailsHook>>>,
  TError = ErrorSchema | UnauthorizedResponse | DefaultResponse
>(
  bechargeVoucherOrderReference: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBechargeOrderDetailsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBechargeOrderDetailsQueryOptions(bechargeVoucherOrderReference, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get becharge products
 */
export const useGetBechargeProductsHook = () => {
  const getBechargeProducts = useCustomInstance<BechargeProductGroupsSchema>();

  return (signal?: AbortSignal) => {
    return getBechargeProducts({ url: `/vouchers/becharge/products`, method: 'get', signal });
  };
};

export const getGetBechargeProductsQueryKey = () => [`/vouchers/becharge/products`] as const;

export const useGetBechargeProductsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBechargeProductsHook>>>,
  TError = ErrorSchema | UnauthorizedResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBechargeProductsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBechargeProductsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBechargeProductsQueryKey();

  const getBechargeProducts = useGetBechargeProductsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBechargeProductsHook>>>> = ({ signal }) =>
    getBechargeProducts(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetBechargeProductsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBechargeProductsHook>>>
>;
export type GetBechargeProductsQueryError = ErrorSchema | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Get becharge products
 */
export const useGetBechargeProducts = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBechargeProductsHook>>>,
  TError = ErrorSchema | UnauthorizedResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBechargeProductsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBechargeProductsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the transaction history of becharge vouchers
 */
export const useGetTransactionHistoryHook = () => {
  const getTransactionHistory = useCustomInstance<BeChargeVoucherTransactionsSchema>();

  return (params?: GetTransactionHistoryParams, signal?: AbortSignal) => {
    return getTransactionHistory({ url: `/vouchers/becharge/transactions`, method: 'get', params, signal });
  };
};

export const getGetTransactionHistoryQueryKey = (params?: GetTransactionHistoryParams) =>
  [`/vouchers/becharge/transactions`, ...(params ? [params] : [])] as const;

export const useGetTransactionHistoryQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTransactionHistoryHook>>>,
  TError = ErrorSchema | UnauthorizedResponse | DefaultResponse
>(
  params?: GetTransactionHistoryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTransactionHistoryHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTransactionHistoryHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransactionHistoryQueryKey(params);

  const getTransactionHistory = useGetTransactionHistoryHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTransactionHistoryHook>>>> = ({ signal }) =>
    getTransactionHistory(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetTransactionHistoryQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetTransactionHistoryHook>>>
>;
export type GetTransactionHistoryQueryError = ErrorSchema | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Get the transaction history of becharge vouchers
 */
export const useGetTransactionHistory = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTransactionHistoryHook>>>,
  TError = ErrorSchema | UnauthorizedResponse | DefaultResponse
>(
  params?: GetTransactionHistoryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTransactionHistoryHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTransactionHistoryQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
