const colors = [
  '#D289B6',
  '#B893C6',
  '#969DCF',
  '#70A5CF',
  '#46ACC6',
  '#1CB1B6',
  '#17B39F',
  '#3AB385',
  '#5CB269',
  '#7CAE50',
  '#9AA73D',
  '#B69F33',
  '#D19437',
  '#E68847',
];

export default colors;
