import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentFilterSchema, UpdateContentFilterSchema } from '@greenisland-api';

import { AutocompleteOption } from '@greenisland-common/components/molecules/AutocompleteField/AutocompleteField';

export enum ContentFiltersPrimaryKeysType {
  gamePKeys = 'gamePKeys',
  gameProviderPKeys = 'gameProviderPKeys',
  gameStudioPKeys = 'gameStudioPKeys',
  gameCategoryPKeys = 'gameCategoryPKeys',
  gameThemePKeys = 'gameThemePKeys',
}

export enum ContentFiltersExtendedPrimaryKeysType {
  games = 'games',
  gameProviders = 'gameProviders',
  gameStudios = 'gameStudios',
  gameCategories = 'gameCategories',
  gameThemes = 'gameThemes',
}

export interface ExtendedContentFilterItemsSchema {
  items: {
    pkey: number;
    name: string;
  }[];
}

export interface ExtendedContentFilterKeysSchema {
  gameCategories: ExtendedContentFilterItemsSchema;
  gameProviders: ExtendedContentFilterItemsSchema;
  gameStudios: ExtendedContentFilterItemsSchema;
  gameThemes: ExtendedContentFilterItemsSchema;
  games: ExtendedContentFilterItemsSchema;
}

export interface ExtendedContentFilterSchema extends ContentFilterSchema {
  includedFilterExtended: ExtendedContentFilterKeysSchema;
  excludedFilterExtended: ExtendedContentFilterKeysSchema;
}

export const useIsLiveFilterOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t('content.contentFilter.tableHeaders.isLiveFilter.status.notApplicable'), value: 'n/a', status: null },
      { label: t('content.contentFilter.tableHeaders.isLiveFilter.status.on'), value: 'true', status: true },
      { label: t('content.contentFilter.tableHeaders.isLiveFilter.status.off'), value: 'false', status: false },
    ],
    [t]
  );
};

export interface ExtendedUpdateContentFilterSchema extends UpdateContentFilterSchema {
  isLiveFilterLabel: string;
  includedFilterGameProvider: AutocompleteOption | null;
  excludedFilterGameProvider: AutocompleteOption | null;
}

export const defaultContentFilterFormValues: ExtendedUpdateContentFilterSchema = {
  name: '',
  description: [{ language: 'EN', content: '' }],
  isLiveFilter: null,
  isLiveFilterLabel: 'n/a',
  rtpFilter: undefined,
  includedFilterGameProvider: null,
  excludedFilterGameProvider: null,
  includedFilter: {
    gamePKeys: [],
    gameProviderPKeys: [],
    gameStudioPKeys: [],
    gameCategoryPKeys: [],
    gameThemePKeys: [],
  },
  excludedFilter: {
    gamePKeys: [],
    gameProviderPKeys: [],
    gameStudioPKeys: [],
    gameCategoryPKeys: [],
    gameThemePKeys: [],
  },
};
