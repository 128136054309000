import React from 'react';
import { useTranslation } from 'react-i18next';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { useGetUserVaultTokenBalance } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardContent, CardHeader, Skeleton } from '@mui/material';

import { ErrorCard, FontAwesomeIcon, Link } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../app/hooks';

interface Props {
  userId: string;
}

const VaultBalance = ({ userId }: Props) => {
  const { t } = useTranslation();
  const canReadVaultBalance = usePermission(OnlineCasinoPermissions.getUserVaultTokenBalance);
  const canReadVaultTransactions = usePermission(OnlineCasinoPermissions.getUserVaultTransactions);
  const {
    data: vaultBalance,
    isLoading,
    isError,
    error,
  } = useGetUserVaultTokenBalance(userId, { query: { enabled: canReadVaultBalance } });

  if (!canReadVaultBalance) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={127} />;
  if (isError || !vaultBalance) return <ErrorCard sx={{ height: 127 }}>{error?.message || t('error')}</ErrorCard>;

  return (
    <Card>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CardHeader title={t('vaultBalance')} />
        {canReadVaultTransactions && (
          <Button
            size="small"
            sx={{ mr: 1 }}
            variant="outlined"
            color="primary"
            component={Link}
            {...{ to: '../vault/transactions' }}
          >
            {t('seeMore')}
          </Button>
        )}
      </Box>
      <CardContent>
        {vaultBalance?.balance ? (
          <>
            {t('vaultBalanceAmount', { tokens: vaultBalance?.balance })}
            <FontAwesomeIcon sx={{ ml: 1, color: '#e5b73b' }} icon={faCoins} />
          </>
        ) : (
          t('noTokens')
        )}
      </CardContent>
    </Card>
  );
};

export default VaultBalance;
