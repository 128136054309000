import { useTranslation } from 'react-i18next';
import { useGetBonusesAwardedToday } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

import { transformCurrencyV2 } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const AwardedToday = () => {
  const { t } = useTranslation();
  const canReadBonusesAwardedToday = usePermission(OnlineCasinoPermissions.getBonusesAwardedToday);
  const { data: awardedToday, isLoading } = useGetBonusesAwardedToday({
    query: {
      enabled: canReadBonusesAwardedToday,
    },
  });

  const getColumns = (data: any[]): GridColDef[] => {
    if (!data || data.length === 0) return [];
    return Object.keys(data[0]).map(key => {
      switch (key) {
        case 'sum':
          return transformCurrencyV2(key, t);
        default:
          return { field: key, headerName: t(key), flex: 1 };
      }
    });
  };

  const renderDataGrid = (data: any[] | undefined) => (
    <StyledDataGrid
      columns={getColumns(data ?? [])}
      rows={data ?? []}
      loading={isLoading}
      autoHeight
      disableSelectionOnClick
      getRowId={row => row.id || Math.random().toString()}
    />
  );

  if (!canReadBonusesAwardedToday) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom>
        {t('availablePromotions')}
      </Typography>
      {renderDataGrid(awardedToday?.availablePromotions)}
      <Typography variant="h6" gutterBottom>
        {t('freeSpins')}
      </Typography>
      {renderDataGrid(awardedToday?.freeSpins)}

      <Typography variant="h6" gutterBottom>
        {t('claimablePromotion')}
      </Typography>
      {renderDataGrid(awardedToday?.claimablePromotions)}
    </Stack>
  );
};

export default AwardedToday;
