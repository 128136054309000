import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  EBGCArticle6ActionTypeSchema,
  ERegulatorControllerBadRequestTypeSchema,
  useResetRetryCountForUser,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import Select from '@greenisland-common/components/atoms/Select';

import UserInfoField from '../../../../../../../app/components/UserInfoField';
import { CRITICAL_FIELDS_MISMATCH_ERROR_REASONS } from '../../../../../Lookup/Components/DetailsComponents/CriticalFieldsMismatch/Constants/CriticalFieldsMismatchErrorReasons';

interface Props {
  open: boolean;
  onClose: () => void;
}

const ResetRetriesArticle6ActionTypeDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [chosenActionType, setChosenActionType] = useState<EBGCArticle6ActionTypeSchema>(
    EBGCArticle6ActionTypeSchema.RequestExceed
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { mutate: resetRetryCountForUser, isLoading: isResetRetryCountForUserLoading } = useResetRetryCountForUser({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        setShowSuccessMessage(true);
      },
      onError: error => {
        if ('type' in error && error.type) {
          const errorReason: ERegulatorControllerBadRequestTypeSchema | undefined = error.type;
          const translationKey = errorReason
            ? CRITICAL_FIELDS_MISMATCH_ERROR_REASONS[errorReason]
            : 'somethingWentWrong';
          enqueueSnackbar(t(translationKey), { variant: 'error' });
        } else {
          enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
        }
      },
    },
  });

  const onSubmit = () => {
    return resetRetryCountForUser({ userId: Number(userId), actionType: chosenActionType });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{t('common.resetRetries')}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          {!showSuccessMessage ? (
            <>
              <UserInfoField label="userId" value={userId} />
              <Select
                sx={{ width: '100%' }}
                id="article6ActionType"
                label={capitalize(t('regulator.article6ActionType'))}
                value={chosenActionType}
                onChange={event => setChosenActionType(event.target.value as EBGCArticle6ActionTypeSchema)}
                options={Object.values(EBGCArticle6ActionTypeSchema).map(value => value)}
              />
            </>
          ) : (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <FontAwesomeIcon size="3x" icon={faCircleCheck} color={theme.palette.success.main} />
              <Typography variant="body2" fontSize="medium" sx={{ mt: 4 }}>
                {capitalize(t('regulator.article6ActionTypeResetSuccess', { actionType: chosenActionType, userId }))}
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {!showSuccessMessage ? (
          <>
            <Button variant="text" onClick={onClose} color="secondary">
              {t('cancel')}
            </Button>
            <LoadingButton
              loading={isResetRetryCountForUserLoading}
              variant="contained"
              type="submit"
              disabled={isResetRetryCountForUserLoading}
              onClick={onSubmit}
            >
              {t('common.reset')}
            </LoadingButton>
          </>
        ) : (
          <Button variant="text" onClick={onClose} color="secondary">
            {t('close')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ResetRetriesArticle6ActionTypeDialog;
