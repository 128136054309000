import { Outlet } from 'react-router';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';

import CreateMultiplierTournament from '../../pages/OnlineCasino/Contests/MultiplierTournaments/components/Forms/CreateMultiplierTournament';
import MultiplierTournaments from '../../pages/OnlineCasino/Contests/MultiplierTournaments/MultiplierTournaments';
import VaultTokenBooster from '../../pages/OnlineCasino/Contests/Vault/Booster/VaultTokenBooster';
import VaultConfigurations from '../../pages/OnlineCasino/Contests/Vault/Configurations/VaultConfigurations';
import { BaseRoute } from '../RouteInterfaces';

const vaultViews: Array<BaseRoute> = [
  {
    name: 'Vault Configuration',
    path: 'configurations',
    element: VaultConfigurations,
    mini: 'VC',
    permissions: OnlineCasinoPermissions.getVaultConfigurations,
  },
  {
    name: 'Vault Token Booster',
    path: 'tokenbooster',
    element: VaultTokenBooster,
    mini: 'VTB',
    permissions: OnlineCasinoPermissions.getVaultTokenBoosters,
  },
];

export const contestsRoutes: Array<BaseRoute> = [
  {
    name: 'Contests',
    path: 'contests',
    collapse: true,
    element: Outlet,
    icon: faTrophy,
    permissions: [
      OnlineCasinoPermissions.getVaultConfigurations,
      OnlineCasinoPermissions.getVaultTokenBoosters,
      OnlineCasinoPermissions.getMultiplierTournamentWithFilters,
    ],
    children: [
      {
        path: 'multiplier-tournaments',
        name: 'Multiplier Tournaments Overview',
        element: MultiplierTournaments,
        mini: 'MT',
        permissions: [OnlineCasinoPermissions.getMultiplierTournamentWithFilters],
      },
      {
        path: 'create-multiplier-tournament',
        name: 'Create Multiplier Tournament',
        element: CreateMultiplierTournament,
        hiddenLink: true,
        permissions: [OnlineCasinoPermissions.createMultiplierTournament],
      },
      {
        path: 'vault',
        name: 'Vault',
        collapse: true,
        element: Outlet,
        children: vaultViews,
        mini: 'F',
        featureFlag: 'isVaultEnabled',
        permissions: [OnlineCasinoPermissions.getVaultConfigurations, OnlineCasinoPermissions.getVaultTokenBoosters],
      },
    ],
  },
];

export default contestsRoutes;
