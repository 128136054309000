import React from 'react';
import { useTranslation } from 'react-i18next';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { UserGeneralSchema } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Tooltip } from '@mui/material';
import { format, fromUnixTime } from 'date-fns';

import { FontAwesomeIcon, Link } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../../../app/hooks';

interface Props {
  user: UserGeneralSchema;
}

const RegisteredOn = ({ user }: Props) => {
  const { t } = useTranslation();
  const canReadEventstream = usePermission(OnlineCasinoPermissions.getEventstreamStreamId);

  return (
    <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
      <Box sx={{ mr: 1 }}>{format(fromUnixTime(user.registrationDate), 'dd/MM/yyyy')}</Box>
      {canReadEventstream && user.registrationFraudAnalysisStreamId.length > 0 && (
        <Tooltip arrow title={`${t('search')}`}>
          <div>
            <Button
              sx={{ minWidth: 0 }}
              component={Link}
              to={`../../reporting/events/stream?streamid=${user.registrationFraudAnalysisStreamId}`}
            >
              <FontAwesomeIcon sx={{ width: 15, height: 15 }} icon={faSearch} />
            </Button>
          </div>
        </Tooltip>
      )}
    </Box>
  );
};

export default RegisteredOn;
