/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  DefaultResponse,
  ErrorSchema,
  GameCategoriesSchema,
  GameCategoryArgumentsSchema,
  GameCategorySchema,
  GameListOrderSchema,
  GameProviderArgumentsSchema,
  GameProviderSchema,
  GameProvidersSchema,
  GameResultsSchema,
  GameStudioArgumentsSchema,
  GameStudioSchema,
  GameStudiosSchema,
  GameThemeArgumentsSchema,
  GameThemeSchema,
  GameThemesSchema,
  GenericResultSchema,
  GetAllMediaGalleryEntriesOfTypeParams,
  GetGamesByQueryFiltersParams,
  MediaGalleryEntriesSchema,
  MediaGalleryEntrySchema,
  NotFoundResponse,
  ServiceContractVersionArgumentsSchema,
  UnauthorizedResponse,
  UpdateMediaGalleryEntrySchema,
  UploadMediaGalleryEntryParams,
  UserDocumentSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get document
 */
export const useGetDocumentByIdHook = () => {
  const getDocumentById = useCustomInstance<UserDocumentSchema>();

  return (documentId: number, signal?: AbortSignal) => {
    return getDocumentById({ url: `/content/document/${documentId}`, method: 'get', signal });
  };
};

export const getGetDocumentByIdQueryKey = (documentId: number) => [`/content/document/${documentId}`] as const;

export const useGetDocumentByIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentByIdHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  documentId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentByIdHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentByIdHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDocumentByIdQueryKey(documentId);

  const getDocumentById = useGetDocumentByIdHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDocumentByIdHook>>>> = ({ signal }) =>
    getDocumentById(documentId, signal);

  return { queryKey, queryFn, enabled: !!documentId, ...queryOptions };
};

export type GetDocumentByIdQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDocumentByIdHook>>>>;
export type GetDocumentByIdQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get document
 */
export const useGetDocumentById = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDocumentByIdHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  documentId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDocumentByIdHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDocumentByIdQueryOptions(documentId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Retrieves a list of games, based on the query filters.
 */
export const useGetGamesByQueryFiltersHook = () => {
  const getGamesByQueryFilters = useCustomInstance<GameResultsSchema>();

  return (params?: GetGamesByQueryFiltersParams, signal?: AbortSignal) => {
    return getGamesByQueryFilters({ url: `/content/games`, method: 'get', params, signal });
  };
};

export const getGetGamesByQueryFiltersQueryKey = (params?: GetGamesByQueryFiltersParams) =>
  [`/content/games`, ...(params ? [params] : [])] as const;

export const useGetGamesByQueryFiltersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGamesByQueryFiltersHook>>>,
  TError = ErrorSchema
>(
  params?: GetGamesByQueryFiltersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGamesByQueryFiltersHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGamesByQueryFiltersHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGamesByQueryFiltersQueryKey(params);

  const getGamesByQueryFilters = useGetGamesByQueryFiltersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGamesByQueryFiltersHook>>>> = ({ signal }) =>
    getGamesByQueryFilters(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetGamesByQueryFiltersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGamesByQueryFiltersHook>>>
>;
export type GetGamesByQueryFiltersQueryError = ErrorSchema;

/**
 * @summary Retrieves a list of games, based on the query filters.
 */
export const useGetGamesByQueryFilters = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGamesByQueryFiltersHook>>>,
  TError = ErrorSchema
>(
  params?: GetGamesByQueryFiltersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGamesByQueryFiltersHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGamesByQueryFiltersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary The new listOrder for game with given PKey
 */
export const useUpdateGamesV2OrderHook = () => {
  const updateGamesV2Order = useCustomInstance<void>();

  return (gamePKey: number, gameListOrderSchema: GameListOrderSchema) => {
    return updateGamesV2Order({
      url: `/content/gamesV2/order/${gamePKey}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: gameListOrderSchema,
    });
  };
};

export const useUpdateGamesV2OrderMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGamesV2OrderHook>>>,
    TError,
    { gamePKey: number; data: GameListOrderSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateGamesV2OrderHook>>>,
  TError,
  { gamePKey: number; data: GameListOrderSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateGamesV2Order = useUpdateGamesV2OrderHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateGamesV2OrderHook>>>,
    { gamePKey: number; data: GameListOrderSchema }
  > = props => {
    const { gamePKey, data } = props ?? {};

    return updateGamesV2Order(gamePKey, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGamesV2OrderMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateGamesV2OrderHook>>>
>;
export type UpdateGamesV2OrderMutationBody = GameListOrderSchema;
export type UpdateGamesV2OrderMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary The new listOrder for game with given PKey
 */
export const useUpdateGamesV2Order = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGamesV2OrderHook>>>,
    TError,
    { gamePKey: number; data: GameListOrderSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateGamesV2OrderMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get game categories
 */
export const useGetGameCategoriesHook = () => {
  const getGameCategories = useCustomInstance<GameCategoriesSchema>();

  return (signal?: AbortSignal) => {
    return getGameCategories({ url: `/content/gamecategories`, method: 'get', signal });
  };
};

export const getGetGameCategoriesQueryKey = () => [`/content/gamecategories`] as const;

export const useGetGameCategoriesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameCategoriesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameCategoriesHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameCategoriesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameCategoriesQueryKey();

  const getGameCategories = useGetGameCategoriesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameCategoriesHook>>>> = ({ signal }) =>
    getGameCategories(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetGameCategoriesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGameCategoriesHook>>>
>;
export type GetGameCategoriesQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get game categories
 */
export const useGetGameCategories = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameCategoriesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameCategoriesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameCategoriesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Add a game category
 */
export const useAddGameCategoryHook = () => {
  const addGameCategory = useCustomInstance<GameCategorySchema>();

  return (gameCategoryArgumentsSchema: GameCategoryArgumentsSchema) => {
    return addGameCategory({
      url: `/content/gamecategories`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: gameCategoryArgumentsSchema,
    });
  };
};

export const useAddGameCategoryMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameCategoryHook>>>,
    TError,
    { data: GameCategoryArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddGameCategoryHook>>>,
  TError,
  { data: GameCategoryArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addGameCategory = useAddGameCategoryHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddGameCategoryHook>>>,
    { data: GameCategoryArgumentsSchema }
  > = props => {
    const { data } = props ?? {};

    return addGameCategory(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddGameCategoryMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddGameCategoryHook>>>>;
export type AddGameCategoryMutationBody = GameCategoryArgumentsSchema;
export type AddGameCategoryMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Add a game category
 */
export const useAddGameCategory = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameCategoryHook>>>,
    TError,
    { data: GameCategoryArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddGameCategoryMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a game category
 */
export const useGetGameCategoryHook = () => {
  const getGameCategory = useCustomInstance<GameCategorySchema>();

  return (gameCategoryId: string, signal?: AbortSignal) => {
    return getGameCategory({ url: `/content/gamecategories/${gameCategoryId}`, method: 'get', signal });
  };
};

export const getGetGameCategoryQueryKey = (gameCategoryId: string) =>
  [`/content/gamecategories/${gameCategoryId}`] as const;

export const useGetGameCategoryQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameCategoryHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  gameCategoryId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameCategoryHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameCategoryHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameCategoryQueryKey(gameCategoryId);

  const getGameCategory = useGetGameCategoryHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameCategoryHook>>>> = ({ signal }) =>
    getGameCategory(gameCategoryId, signal);

  return { queryKey, queryFn, enabled: !!gameCategoryId, ...queryOptions };
};

export type GetGameCategoryQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetGameCategoryHook>>>>;
export type GetGameCategoryQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get a game category
 */
export const useGetGameCategory = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameCategoryHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  gameCategoryId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameCategoryHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameCategoryQueryOptions(gameCategoryId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update game category
 */
export const useUpdateGameCategoryHook = () => {
  const updateGameCategory = useCustomInstance<GameCategorySchema>();

  return (gameCategoryId: string, gameCategoryArgumentsSchema: GameCategoryArgumentsSchema) => {
    return updateGameCategory({
      url: `/content/gamecategories/${gameCategoryId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: gameCategoryArgumentsSchema,
    });
  };
};

export const useUpdateGameCategoryMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameCategoryHook>>>,
    TError,
    { gameCategoryId: string; data: GameCategoryArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateGameCategoryHook>>>,
  TError,
  { gameCategoryId: string; data: GameCategoryArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateGameCategory = useUpdateGameCategoryHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameCategoryHook>>>,
    { gameCategoryId: string; data: GameCategoryArgumentsSchema }
  > = props => {
    const { gameCategoryId, data } = props ?? {};

    return updateGameCategory(gameCategoryId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGameCategoryMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateGameCategoryHook>>>
>;
export type UpdateGameCategoryMutationBody = GameCategoryArgumentsSchema;
export type UpdateGameCategoryMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Update game category
 */
export const useUpdateGameCategory = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameCategoryHook>>>,
    TError,
    { gameCategoryId: string; data: GameCategoryArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateGameCategoryMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Remove game category
 */
export const useRemoveGameCategoryHook = () => {
  const removeGameCategory = useCustomInstance<void>();

  return (gameCategoryId: string) => {
    return removeGameCategory({ url: `/content/gamecategories/${gameCategoryId}`, method: 'delete' });
  };
};

export const useRemoveGameCategoryMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveGameCategoryHook>>>,
    TError,
    { gameCategoryId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRemoveGameCategoryHook>>>,
  TError,
  { gameCategoryId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const removeGameCategory = useRemoveGameCategoryHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRemoveGameCategoryHook>>>,
    { gameCategoryId: string }
  > = props => {
    const { gameCategoryId } = props ?? {};

    return removeGameCategory(gameCategoryId);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveGameCategoryMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRemoveGameCategoryHook>>>
>;

export type RemoveGameCategoryMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Remove game category
 */
export const useRemoveGameCategory = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveGameCategoryHook>>>,
    TError,
    { gameCategoryId: string },
    TContext
  >;
}) => {
  const mutationOptions = useRemoveGameCategoryMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get game providers
 */
export const useGetGameProvidersHook = () => {
  const getGameProviders = useCustomInstance<GameProvidersSchema>();

  return (signal?: AbortSignal) => {
    return getGameProviders({ url: `/content/providers`, method: 'get', signal });
  };
};

export const getGetGameProvidersQueryKey = () => [`/content/providers`] as const;

export const useGetGameProvidersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameProvidersHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameProvidersHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameProvidersHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameProvidersQueryKey();

  const getGameProviders = useGetGameProvidersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameProvidersHook>>>> = ({ signal }) =>
    getGameProviders(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetGameProvidersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetGameProvidersHook>>>>;
export type GetGameProvidersQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get game providers
 */
export const useGetGameProviders = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameProvidersHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameProvidersHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameProvidersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary add game provider
 */
export const useAddGameProviderHook = () => {
  const addGameProvider = useCustomInstance<GameProvidersSchema>();

  return (gameProviderArgumentsSchema: GameProviderArgumentsSchema) => {
    return addGameProvider({
      url: `/content/providers`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: gameProviderArgumentsSchema,
    });
  };
};

export const useAddGameProviderMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameProviderHook>>>,
    TError,
    { data: GameProviderArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddGameProviderHook>>>,
  TError,
  { data: GameProviderArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addGameProvider = useAddGameProviderHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddGameProviderHook>>>,
    { data: GameProviderArgumentsSchema }
  > = props => {
    const { data } = props ?? {};

    return addGameProvider(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddGameProviderMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddGameProviderHook>>>>;
export type AddGameProviderMutationBody = GameProviderArgumentsSchema;
export type AddGameProviderMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary add game provider
 */
export const useAddGameProvider = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameProviderHook>>>,
    TError,
    { data: GameProviderArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddGameProviderMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get game provider
 */
export const useGetGameProviderHook = () => {
  const getGameProvider = useCustomInstance<GameProviderSchema>();

  return (providerId: string, signal?: AbortSignal) => {
    return getGameProvider({ url: `/content/providers/${providerId}`, method: 'get', signal });
  };
};

export const getGetGameProviderQueryKey = (providerId: string) => [`/content/providers/${providerId}`] as const;

export const useGetGameProviderQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameProviderHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  providerId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameProviderHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameProviderHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameProviderQueryKey(providerId);

  const getGameProvider = useGetGameProviderHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameProviderHook>>>> = ({ signal }) =>
    getGameProvider(providerId, signal);

  return { queryKey, queryFn, enabled: !!providerId, ...queryOptions };
};

export type GetGameProviderQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetGameProviderHook>>>>;
export type GetGameProviderQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get game provider
 */
export const useGetGameProvider = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameProviderHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  providerId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameProviderHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameProviderQueryOptions(providerId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary update game provider
 */
export const useUpdateGameProviderHook = () => {
  const updateGameProvider = useCustomInstance<GameProvidersSchema>();

  return (providerId: string, gameProviderArgumentsSchema: GameProviderArgumentsSchema) => {
    return updateGameProvider({
      url: `/content/providers/${providerId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: gameProviderArgumentsSchema,
    });
  };
};

export const useUpdateGameProviderMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameProviderHook>>>,
    TError,
    { providerId: string; data: GameProviderArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateGameProviderHook>>>,
  TError,
  { providerId: string; data: GameProviderArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateGameProvider = useUpdateGameProviderHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameProviderHook>>>,
    { providerId: string; data: GameProviderArgumentsSchema }
  > = props => {
    const { providerId, data } = props ?? {};

    return updateGameProvider(providerId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGameProviderMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateGameProviderHook>>>
>;
export type UpdateGameProviderMutationBody = GameProviderArgumentsSchema;
export type UpdateGameProviderMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary update game provider
 */
export const useUpdateGameProvider = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameProviderHook>>>,
    TError,
    { providerId: string; data: GameProviderArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateGameProviderMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary list game studios
 */
export const useGetGameStudiosHook = () => {
  const getGameStudios = useCustomInstance<GameStudiosSchema>();

  return (signal?: AbortSignal) => {
    return getGameStudios({ url: `/content/gamestudios`, method: 'get', signal });
  };
};

export const getGetGameStudiosQueryKey = () => [`/content/gamestudios`] as const;

export const useGetGameStudiosQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameStudiosHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameStudiosHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameStudiosHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameStudiosQueryKey();

  const getGameStudios = useGetGameStudiosHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameStudiosHook>>>> = ({ signal }) =>
    getGameStudios(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetGameStudiosQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetGameStudiosHook>>>>;
export type GetGameStudiosQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary list game studios
 */
export const useGetGameStudios = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameStudiosHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameStudiosHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameStudiosQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary add game studio
 */
export const useAddGameStudioHook = () => {
  const addGameStudio = useCustomInstance<GameStudioSchema>();

  return (gameStudioArgumentsSchema: GameStudioArgumentsSchema) => {
    return addGameStudio({
      url: `/content/gamestudios`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: gameStudioArgumentsSchema,
    });
  };
};

export const useAddGameStudioMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameStudioHook>>>,
    TError,
    { data: GameStudioArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddGameStudioHook>>>,
  TError,
  { data: GameStudioArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addGameStudio = useAddGameStudioHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddGameStudioHook>>>,
    { data: GameStudioArgumentsSchema }
  > = props => {
    const { data } = props ?? {};

    return addGameStudio(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddGameStudioMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddGameStudioHook>>>>;
export type AddGameStudioMutationBody = GameStudioArgumentsSchema;
export type AddGameStudioMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary add game studio
 */
export const useAddGameStudio = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameStudioHook>>>,
    TError,
    { data: GameStudioArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddGameStudioMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get game studio
 */
export const useGetGameStudioHook = () => {
  const getGameStudio = useCustomInstance<GameStudioSchema>();

  return (gameStudioId: string, signal?: AbortSignal) => {
    return getGameStudio({ url: `/content/gamestudios/${gameStudioId}`, method: 'get', signal });
  };
};

export const getGetGameStudioQueryKey = (gameStudioId: string) => [`/content/gamestudios/${gameStudioId}`] as const;

export const useGetGameStudioQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameStudioHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  gameStudioId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameStudioHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameStudioHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameStudioQueryKey(gameStudioId);

  const getGameStudio = useGetGameStudioHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameStudioHook>>>> = ({ signal }) =>
    getGameStudio(gameStudioId, signal);

  return { queryKey, queryFn, enabled: !!gameStudioId, ...queryOptions };
};

export type GetGameStudioQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetGameStudioHook>>>>;
export type GetGameStudioQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get game studio
 */
export const useGetGameStudio = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameStudioHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  gameStudioId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameStudioHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameStudioQueryOptions(gameStudioId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update game studio
 */
export const useUpdateGameStudioHook = () => {
  const updateGameStudio = useCustomInstance<GameStudioSchema>();

  return (gameStudioId: string, gameStudioArgumentsSchema: GameStudioArgumentsSchema) => {
    return updateGameStudio({
      url: `/content/gamestudios/${gameStudioId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: gameStudioArgumentsSchema,
    });
  };
};

export const useUpdateGameStudioMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameStudioHook>>>,
    TError,
    { gameStudioId: string; data: GameStudioArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateGameStudioHook>>>,
  TError,
  { gameStudioId: string; data: GameStudioArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateGameStudio = useUpdateGameStudioHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameStudioHook>>>,
    { gameStudioId: string; data: GameStudioArgumentsSchema }
  > = props => {
    const { gameStudioId, data } = props ?? {};

    return updateGameStudio(gameStudioId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGameStudioMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateGameStudioHook>>>
>;
export type UpdateGameStudioMutationBody = GameStudioArgumentsSchema;
export type UpdateGameStudioMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Update game studio
 */
export const useUpdateGameStudio = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameStudioHook>>>,
    TError,
    { gameStudioId: string; data: GameStudioArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateGameStudioMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary list game themes
 */
export const useGetGameThemesHook = () => {
  const getGameThemes = useCustomInstance<GameThemesSchema>();

  return (signal?: AbortSignal) => {
    return getGameThemes({ url: `/content/gamethemes`, method: 'get', signal });
  };
};

export const getGetGameThemesQueryKey = () => [`/content/gamethemes`] as const;

export const useGetGameThemesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameThemesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameThemesHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameThemesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameThemesQueryKey();

  const getGameThemes = useGetGameThemesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameThemesHook>>>> = ({ signal }) =>
    getGameThemes(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetGameThemesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetGameThemesHook>>>>;
export type GetGameThemesQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary list game themes
 */
export const useGetGameThemes = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameThemesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameThemesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameThemesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary add game theme
 */
export const useAddGameThemeHook = () => {
  const addGameTheme = useCustomInstance<GameThemeSchema>();

  return (gameThemeArgumentsSchema: GameThemeArgumentsSchema) => {
    return addGameTheme({
      url: `/content/gamethemes`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: gameThemeArgumentsSchema,
    });
  };
};

export const useAddGameThemeMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameThemeHook>>>,
    TError,
    { data: GameThemeArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddGameThemeHook>>>,
  TError,
  { data: GameThemeArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addGameTheme = useAddGameThemeHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddGameThemeHook>>>,
    { data: GameThemeArgumentsSchema }
  > = props => {
    const { data } = props ?? {};

    return addGameTheme(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddGameThemeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddGameThemeHook>>>>;
export type AddGameThemeMutationBody = GameThemeArgumentsSchema;
export type AddGameThemeMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary add game theme
 */
export const useAddGameTheme = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameThemeHook>>>,
    TError,
    { data: GameThemeArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddGameThemeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a game theme
 */
export const useGetGameThemeHook = () => {
  const getGameTheme = useCustomInstance<GameThemeSchema>();

  return (gameThemeId: string, signal?: AbortSignal) => {
    return getGameTheme({ url: `/content/gamethemes/${gameThemeId}`, method: 'get', signal });
  };
};

export const getGetGameThemeQueryKey = (gameThemeId: string) => [`/content/gamethemes/${gameThemeId}`] as const;

export const useGetGameThemeQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameThemeHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  gameThemeId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameThemeHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameThemeHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameThemeQueryKey(gameThemeId);

  const getGameTheme = useGetGameThemeHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameThemeHook>>>> = ({ signal }) =>
    getGameTheme(gameThemeId, signal);

  return { queryKey, queryFn, enabled: !!gameThemeId, ...queryOptions };
};

export type GetGameThemeQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetGameThemeHook>>>>;
export type GetGameThemeQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get a game theme
 */
export const useGetGameTheme = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameThemeHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  gameThemeId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameThemeHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameThemeQueryOptions(gameThemeId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update a game theme
 */
export const useUpdateGameThemeHook = () => {
  const updateGameTheme = useCustomInstance<GameThemeSchema>();

  return (gameThemeId: string, gameThemeArgumentsSchema: GameThemeArgumentsSchema) => {
    return updateGameTheme({
      url: `/content/gamethemes/${gameThemeId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: gameThemeArgumentsSchema,
    });
  };
};

export const useUpdateGameThemeMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameThemeHook>>>,
    TError,
    { gameThemeId: string; data: GameThemeArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateGameThemeHook>>>,
  TError,
  { gameThemeId: string; data: GameThemeArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateGameTheme = useUpdateGameThemeHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameThemeHook>>>,
    { gameThemeId: string; data: GameThemeArgumentsSchema }
  > = props => {
    const { gameThemeId, data } = props ?? {};

    return updateGameTheme(gameThemeId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateGameThemeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateGameThemeHook>>>>;
export type UpdateGameThemeMutationBody = GameThemeArgumentsSchema;
export type UpdateGameThemeMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Update a game theme
 */
export const useUpdateGameTheme = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateGameThemeHook>>>,
    TError,
    { gameThemeId: string; data: GameThemeArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateGameThemeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary delete a game theme
 */
export const useDeleteGameThemeHook = () => {
  const deleteGameTheme = useCustomInstance<void>();

  return (gameThemeId: string) => {
    return deleteGameTheme({ url: `/content/gamethemes/${gameThemeId}`, method: 'delete' });
  };
};

export const useDeleteGameThemeMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteGameThemeHook>>>,
    TError,
    { gameThemeId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteGameThemeHook>>>,
  TError,
  { gameThemeId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteGameTheme = useDeleteGameThemeHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteGameThemeHook>>>,
    { gameThemeId: string }
  > = props => {
    const { gameThemeId } = props ?? {};

    return deleteGameTheme(gameThemeId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteGameThemeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteGameThemeHook>>>>;

export type DeleteGameThemeMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary delete a game theme
 */
export const useDeleteGameTheme = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteGameThemeHook>>>,
    TError,
    { gameThemeId: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteGameThemeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Request a cache clear for a given type of content
 */
export const useDoCacheBusterHook = () => {
  const doCacheBuster = useCustomInstance<void>();

  return (cacheBusterType: 'thumbnails' | 'backgrounds' | 'banners') => {
    return doCacheBuster({ url: `/content/cachebusters/${cacheBusterType}`, method: 'post' });
  };
};

export const useDoCacheBusterMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDoCacheBusterHook>>>,
    TError,
    { cacheBusterType: 'thumbnails' | 'backgrounds' | 'banners' },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDoCacheBusterHook>>>,
  TError,
  { cacheBusterType: 'thumbnails' | 'backgrounds' | 'banners' },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const doCacheBuster = useDoCacheBusterHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDoCacheBusterHook>>>,
    { cacheBusterType: 'thumbnails' | 'backgrounds' | 'banners' }
  > = props => {
    const { cacheBusterType } = props ?? {};

    return doCacheBuster(cacheBusterType);
  };

  return { mutationFn, ...mutationOptions };
};

export type DoCacheBusterMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDoCacheBusterHook>>>>;

export type DoCacheBusterMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Request a cache clear for a given type of content
 */
export const useDoCacheBuster = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDoCacheBusterHook>>>,
    TError,
    { cacheBusterType: 'thumbnails' | 'backgrounds' | 'banners' },
    TContext
  >;
}) => {
  const mutationOptions = useDoCacheBusterMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Set the service contract version
 */
export const useSetServiceContractVersionHook = () => {
  const setServiceContractVersion = useCustomInstance<GenericResultSchema>();

  return (serviceContractVersionArgumentsSchema: ServiceContractVersionArgumentsSchema) => {
    return setServiceContractVersion({
      url: `/content/servicecontracts/version`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: serviceContractVersionArgumentsSchema,
    });
  };
};

export const useSetServiceContractVersionMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetServiceContractVersionHook>>>,
    TError,
    { data: ServiceContractVersionArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetServiceContractVersionHook>>>,
  TError,
  { data: ServiceContractVersionArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setServiceContractVersion = useSetServiceContractVersionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetServiceContractVersionHook>>>,
    { data: ServiceContractVersionArgumentsSchema }
  > = props => {
    const { data } = props ?? {};

    return setServiceContractVersion(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetServiceContractVersionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetServiceContractVersionHook>>>
>;
export type SetServiceContractVersionMutationBody = ServiceContractVersionArgumentsSchema;
export type SetServiceContractVersionMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Set the service contract version
 */
export const useSetServiceContractVersion = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetServiceContractVersionHook>>>,
    TError,
    { data: ServiceContractVersionArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useSetServiceContractVersionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 * @summary Get all media gallery entries that conform to query parameters.
 */
export const useGetAllMediaGalleryEntriesOfTypeHook = () => {
  const getAllMediaGalleryEntriesOfType = useCustomInstance<MediaGalleryEntriesSchema>();

  return (params: GetAllMediaGalleryEntriesOfTypeParams, signal?: AbortSignal) => {
    return getAllMediaGalleryEntriesOfType({ url: `/content/mediagallery`, method: 'get', params, signal });
  };
};

export const getGetAllMediaGalleryEntriesOfTypeQueryKey = (params: GetAllMediaGalleryEntriesOfTypeParams) =>
  [`/content/mediagallery`, ...(params ? [params] : [])] as const;

export const useGetAllMediaGalleryEntriesOfTypeQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAllMediaGalleryEntriesOfTypeHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetAllMediaGalleryEntriesOfTypeParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetAllMediaGalleryEntriesOfTypeHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllMediaGalleryEntriesOfTypeHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllMediaGalleryEntriesOfTypeQueryKey(params);

  const getAllMediaGalleryEntriesOfType = useGetAllMediaGalleryEntriesOfTypeHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllMediaGalleryEntriesOfTypeHook>>>> = ({
    signal,
  }) => getAllMediaGalleryEntriesOfType(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAllMediaGalleryEntriesOfTypeQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAllMediaGalleryEntriesOfTypeHook>>>
>;
export type GetAllMediaGalleryEntriesOfTypeQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary Get all media gallery entries that conform to query parameters.
 */
export const useGetAllMediaGalleryEntriesOfType = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAllMediaGalleryEntriesOfTypeHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetAllMediaGalleryEntriesOfTypeParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetAllMediaGalleryEntriesOfTypeHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAllMediaGalleryEntriesOfTypeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary Upload asset to the image gallery.
 */
export const useUploadMediaGalleryEntryHook = () => {
  const uploadMediaGalleryEntry = useCustomInstance<MediaGalleryEntrySchema>();

  return (updateMediaGalleryEntrySchema: UpdateMediaGalleryEntrySchema, params?: UploadMediaGalleryEntryParams) => {
    return uploadMediaGalleryEntry({
      url: `/content/mediagallery`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateMediaGalleryEntrySchema,
      params,
    });
  };
};

export const useUploadMediaGalleryEntryMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadMediaGalleryEntryHook>>>,
    TError,
    { data: UpdateMediaGalleryEntrySchema; params?: UploadMediaGalleryEntryParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUploadMediaGalleryEntryHook>>>,
  TError,
  { data: UpdateMediaGalleryEntrySchema; params?: UploadMediaGalleryEntryParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const uploadMediaGalleryEntry = useUploadMediaGalleryEntryHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUploadMediaGalleryEntryHook>>>,
    { data: UpdateMediaGalleryEntrySchema; params?: UploadMediaGalleryEntryParams }
  > = props => {
    const { data, params } = props ?? {};

    return uploadMediaGalleryEntry(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadMediaGalleryEntryMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUploadMediaGalleryEntryHook>>>
>;
export type UploadMediaGalleryEntryMutationBody = UpdateMediaGalleryEntrySchema;
export type UploadMediaGalleryEntryMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary Upload asset to the image gallery.
 */
export const useUploadMediaGalleryEntry = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadMediaGalleryEntryHook>>>,
    TError,
    { data: UpdateMediaGalleryEntrySchema; params?: UploadMediaGalleryEntryParams },
    TContext
  >;
}) => {
  const mutationOptions = useUploadMediaGalleryEntryMutationOptions(options);

  return useMutation(mutationOptions);
};
