import { memo, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
  Base64FileSchema,
  EVerificationStatusSchema,
  useExportWithdrawalsAndDepositsHistory,
  useGetDocumentById,
  useGetUserVerifications,
  UserDocumentMetadataSchema,
  VerificationsBankAccountSchema,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, IconButton, MenuItem, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { usePermission } from 'src/app/hooks';
import TabMenu, { Tab } from 'src/routes/components/TabMenu';

import { DATE_INPUT_FORMAT, DATE_INPUT_MASK } from '@greenisland-common/components/atoms/Constants';
import ImageDialog from '@greenisland-common/components/molecules/ImageDialog';

import { downloadBase64File, formatBase64String } from '@greenisland-common/helpers';

import DepositsTable from './Tables/DepositsTable';
import WithdrawalsTable from './Tables/WithdrawalsTable';

const BankaccountDetails = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { userId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams({});
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [selectedBankaccount, setSelectedBankaccount] = useState<string>(searchParams.get('iban') ?? '');
  const [openImagePreview, setOpenImagePreview] = useState<boolean>(false);
  const [file, setFile] = useState<Base64FileSchema | string | null>();

  const canDownloadAccountArchive = usePermission(OnlineCasinoPermissions.exportWithdrawalsAndDepositsHistory);

  const { data: withdrawalsAndDepositsHistory } = useExportWithdrawalsAndDepositsHistory(
    userId,
    {},
    {
      query: { enabled: canDownloadAccountArchive && !!userId },
    }
  );

  const getBankAccount = (iban: string): VerificationsBankAccountSchema | undefined =>
    verificationData?.bankAccounts.find(({ bankAccountNumber }) => iban === bankAccountNumber);

  const { data: verificationData } = useGetUserVerifications(userId ?? '');
  const {
    data: _,
    isError,
    error,
    refetch,
  } = useGetDocumentById(getBankAccount(selectedBankaccount)?.bankAccountDocumentMetadata?.documentId as number, {
    query: { enabled: false },
  });

  const onChange = (value: string) => {
    setSearchParams({ iban: value });
    setSelectedBankaccount(value);
  };

  const tabs: Tab[] = [
    {
      title: t('deposits.title'),
      content: <DepositsTable iban={selectedBankaccount} startDate={startDate} endDate={endDate} userId={userId} />,
    },
    {
      title: t('withdrawls'),
      content: <WithdrawalsTable iban={selectedBankaccount} startDate={startDate} endDate={endDate} />,
    },
  ];

  const previewMetadataImageHandler = async (metadata?: UserDocumentMetadataSchema) => {
    if (metadata) {
      const response = await refetch();
      if (response?.data?.document) {
        const documentData = response.data.document;

        setFile({
          ...documentData,
          content: formatBase64String(documentData.content, documentData.mimeType),
        });
        setOpenImagePreview(true);
      }
    }
  };

  const downloadAccountArchive = async () => {
    try {
      if (withdrawalsAndDepositsHistory) {
        downloadBase64File(withdrawalsAndDepositsHistory);
      }
    } catch (e) {
      enqueueSnackbar(enqueueSnackbar(t('error'), { variant: 'error' }));
    }
  };

  if (isError) {
    enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
  }

  return (
    <>
      <Stack spacing={2}>
        <Stack direction="row" spacing={1}>
          <DatePicker
            label={t('startDate')}
            onChange={date => {
              setStartDate(date ?? undefined);
            }}
            value={startDate}
            renderInput={params => <TextField size="small" {...params} />}
            inputFormat={DATE_INPUT_FORMAT}
            mask={DATE_INPUT_MASK}
          />
          <DatePicker
            label={t('endDate')}
            onChange={date => {
              setEndDate(date ?? undefined);
            }}
            value={endDate}
            renderInput={params => <TextField size="small" {...params} />}
            inputFormat={DATE_INPUT_FORMAT}
            mask={DATE_INPUT_MASK}
          />
        </Stack>
        <Box display="flex" justifyContent="space-between" gap={1} flexWrap="wrap">
          <Stack direction="row" spacing={1}>
            <TextField
              select
              value={selectedBankaccount}
              label={t('bankaccount')}
              size="small"
              onChange={event => onChange(event.target.value)}
            >
              {verificationData?.bankAccounts.map((bankaccount, index) => (
                <MenuItem key={index} value={bankaccount.bankAccountNumber}>
                  <Box display="flex" alignItems="center">
                    {bankaccount.bankAccountNumber.match(/.{1,4}/g)?.join(' ')}
                    {bankaccount.verificationStatus === EVerificationStatusSchema.Verified && <CheckIcon />}
                  </Box>
                </MenuItem>
              ))}
            </TextField>
            {getBankAccount(selectedBankaccount)?.bankAccountDocumentMetadata ? (
              <IconButton
                color="primary"
                onClick={() =>
                  previewMetadataImageHandler(getBankAccount(selectedBankaccount)?.bankAccountDocumentMetadata)
                }
                disableRipple
              >
                <VisibilityIcon />
              </IconButton>
            ) : null}
          </Stack>
          {canDownloadAccountArchive && (
            <Button size="small" onClick={downloadAccountArchive} variant="contained">
              {t('deposits.downloadCSV')}
            </Button>
          )}
        </Box>
        <TabMenu tabs={tabs} />
      </Stack>
      {openImagePreview && file && (
        <ImageDialog
          openImageDialog={openImagePreview}
          setOpenImageDialog={setOpenImagePreview}
          image={typeof file === 'string' ? file : file.content}
        />
      )}
    </>
  );
};

export default memo(BankaccountDetails);
