import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { GridActionsCellItem, GridRowModel, useGridApiRef } from '@mui/x-data-grid-pro';

import { FontAwesomeIcon, StyledDataGrid } from '@greenisland-common/components/atoms';

import { StringConfigurationModel, StringConfigurations } from '../../../store/provider';

interface Props {
  stringConfigurations?: StringConfigurations;
  setStringConfigurations: (stringConfigurations: StringConfigurations) => void;
}

interface Row extends StringConfigurationModel {
  id: string;
}

const StringConfigurationsDataGrid = ({ stringConfigurations, setStringConfigurations }: Props) => {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();

  const rows = useMemo(
    () =>
      stringConfigurations?.map(stringConfiguration => ({
        id: stringConfiguration.name ?? '',
        ...stringConfiguration,
      })) ?? [],
    [stringConfigurations]
  );

  const processRowUpdate = (newRow: GridRowModel<Row>) => {
    const { id, ...stringConfiguration } = newRow;
    setStringConfigurations(rows.map(({ id: rowId, ...row }) => (rowId === id ? stringConfiguration : row)));
    return newRow;
  };

  return (
    <StyledDataGrid
      apiRef={apiRef}
      experimentalFeatures={{ newEditingApi: true }}
      rows={rows}
      columns={[
        { field: 'name', headerName: t('providerService.providerConfiguration.name'), minWidth: 200, editable: false },
        {
          field: 'description',
          headerName: t('providerService.providerConfiguration.description'),
          minWidth: 220,
          flex: 2,
          editable: false,
        },
        {
          field: 'value',
          headerName: t('providerService.providerConfiguration.value'),
          minWidth: 220,
          flex: 5,
          editable: true,
        },
        {
          field: 'actions',
          type: 'actions',
          width: 50,
          getActions: ({ id }) => [
            <GridActionsCellItem
              key="edit"
              label={t('providerService.providerConfiguration.edit')}
              icon={
                <FontAwesomeIcon
                  icon={faPencil}
                  onClick={event => {
                    event.stopPropagation();
                    apiRef.current.startCellEditMode({ id, field: 'value' });
                  }}
                />
              }
            />,
          ],
        },
      ]}
      editMode="cell"
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={console.error}
      disableSelectionOnClick
    />
  );
};

export default memo(StringConfigurationsDataGrid);
