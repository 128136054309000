import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faAdd, faPencil } from '@fortawesome/free-solid-svg-icons';
import { CampaignSchema, useGetCampaignsOverview } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, LinearProgress, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGridProProps, GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-pro';

import { DataGridContainer, FontAwesomeIcon, PermissionWrapper } from '@greenisland-common/components/atoms';
import DataGridCustomPagination from '@greenisland-common/components/atoms/DataGridPagination/DataGridCustomPagination';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import CampaignDialog from './components/forms/CampaignDialog';
import { usePermission } from '../../../../app/hooks';
import CampaignOverviewDetails from './CampaignOverviewDetails';

const PAGE_SIZE = 25;

type Row = CampaignSchema;

const Campaigns = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const canReadCampaigns = usePermission(OnlineCasinoPermissions.getCampaignsOverview);
  const canAddCampaign = usePermission(OnlineCasinoPermissions.addCampaign);
  const canEditCampaign = usePermission(OnlineCasinoPermissions.updateCampaign);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [isCampaignDialogOpen, setIsCampaignDialogOpen] = useState(false);
  const [chosenCampaign, setChosenCampaign] = useState<Row | null>(null);

  const { data: campaigns, isLoading, isError } = useGetCampaignsOverview({}, { query: { enabled: canReadCampaigns } });

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'campaignId',
        headerName: capitalize(t('id')),
        sortable: true,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        field: 'campaignName',
        headerName: capitalize(t('name')),
        sortable: true,
        minWidth: 210,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        field: 'year',
        headerName: capitalize(t('year')),
        sortable: true,
        minWidth: 80,
        flex: 0.05,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        field: 'theme',
        headerName: capitalize(t('theme')),
        sortable: true,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      ...(canEditCampaign
        ? [
            {
              field: 'actions',
              type: 'actions',
              minWidth: 50,
              flex: 0.05,
              headerName: '',
              getActions: ({ row }: GridRowParams<Row>) => [
                <>
                  <Tooltip title={t('editCampaign')} key="details">
                    <>
                      <GridActionsCellItem
                        label={t('editCampaign')}
                        icon={<FontAwesomeIcon icon={faPencil} />}
                        onClick={() => {
                          if (row) {
                            setChosenCampaign(row);
                            setIsCampaignDialogOpen(true);
                          }
                        }}
                        key="edit"
                      />
                    </>
                  </Tooltip>
                </>,
              ],
            },
          ]
        : []),
    ],
    [canEditCampaign, t]
  );

  const rows = useMemo(
    () =>
      campaigns?.map<Row>(campaign => ({
        ...campaign,
        id: campaign?.campaignId,
      })) ?? [],
    [campaigns]
  );

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setPageSize(value);
    setPage(0);
  };

  const getDetailPanelHeight = useCallback((): 'auto' => 'auto', []);

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback(({ row }: { row: Row }) => {
    if (!row?.campaignId) {
      return null;
    }

    return <CampaignOverviewDetails campaignId={row?.campaignId} />;
  }, []);

  return (
    <Stack spacing={2}>
      <PermissionWrapper
        errorMessage={t('marketing.campaigns.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getCampaignsOverview}
      >
        <>
          {canAddCampaign && (
            <Box>
              <Button
                variant="contained"
                size="medium"
                onClick={() => setIsCampaignDialogOpen(true)}
                startIcon={<FontAwesomeIcon icon={faAdd} />}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    mr: 0,
                  },
                  [theme.breakpoints.up('md')]: {
                    mr: 2,
                  },
                }}
              >
                {capitalize(t('addCampaign'))}
              </Button>
            </Box>
          )}
          <DataGridContainer>
            <LimitedDataGrid
              density="compact"
              autoHeight
              rowHeight={80}
              loading={isLoading}
              columns={columns}
              rows={rows}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="client"
              components={{
                LoadingOverlay: LinearProgress,
                Footer: () => (
                  <>
                    <DataGridCustomPagination
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[10, 25, 50, 100, 200]}
                      count={campaigns?.length ? Math.ceil(campaigns?.length / pageSize) : 0}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      paginationMode="client"
                    />
                  </>
                ),
              }}
            />
          </DataGridContainer>
          {isCampaignDialogOpen && (
            <CampaignDialog
              open={true}
              onClose={() => {
                setIsCampaignDialogOpen(false);
                setChosenCampaign(null);
              }}
              chosenCampaign={chosenCampaign}
            />
          )}
        </>
      </PermissionWrapper>
    </Stack>
  );
};

export default Campaigns;
