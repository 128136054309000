import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {
  CreateTestUserRequestSchema,
  CreateTestUserResponseSchema,
  ECreateTestUserBadRequestTypeSchema,
  useCreateTestUser,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import { Box, Button, capitalize, Dialog, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

import { ADD_TEST_USER_ERROR_REASONS } from './constants/AddTestUserErrorReasons';
import { theme } from '../../../../../../app/theme/theme';
import AddTestUserCopyField from './AddTestUserCopyField';

enum TEST_USER_NAVIGATE {
  USER_DETAILS_PAGE = 'USER_DETAILS',
  SEARCH_PAGE = 'SEARCH_PAGE',
}

interface Props {
  open: boolean;
  onClose: () => void;
}

const AddTestUserDialog = ({ open, onClose }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [createdTestedUser, setCreatedTestedUser] = useState<CreateTestUserResponseSchema | null>(null);

  const { getValues } = useFormContext<CreateTestUserRequestSchema>();

  const { mutate: createTestUser, isLoading } = useCreateTestUser({
    mutation: {
      onSuccess: data => {
        setCreatedTestedUser(data);
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: error => {
        if (error) {
          if ('type' in error && error.type) {
            const errorReason: ECreateTestUserBadRequestTypeSchema | undefined = error.type;
            const translationKey = errorReason ? ADD_TEST_USER_ERROR_REASONS[errorReason] : 'somethingWentWrong';
            enqueueSnackbar(t(translationKey), { variant: 'error' });
          } else {
            enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
          }
        }
      },
    },
  });

  const createTestUserHandler = () => {
    const { password, email, identificationCountryOfOrigin, isMale, name, language, birthDate } = getValues();
    const payload = {
      password,
      ...(email?.email ? { email } : {}),
      ...(name?.firstName ? { name } : {}),
      ...(identificationCountryOfOrigin ? { identificationCountryOfOrigin } : {}),
      ...(birthDate ? { birthDate: Math.floor(birthDate / 1000) } : {}),
      ...(isMale !== null ? { isMale } : {}),
      ...(language ? { language } : {}),
    } as CreateTestUserRequestSchema;

    return createTestUser({ data: payload });
  };

  const navigateToHandler = (navigateTo: TEST_USER_NAVIGATE) => {
    const url =
      navigateTo === TEST_USER_NAVIGATE.SEARCH_PAGE
        ? '../'
        : `${location.pathname.split('/lookup')[0]}/users/${createdTestedUser?.userId}/details`;

    return navigate(url);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{t('lookup.users.titles.createTestUser')}</DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <Stack spacing={2}>
          {createdTestedUser ? (
            <Box>
              <Typography variant="body1" fontSize="medium" sx={{ color: theme.palette.success.main }}>
                {capitalize(t('lookup.users.titles.testUserCreatedSuccess'))}
              </Typography>
              <Box ml={2} mt={6} display="flex" flexDirection="column" gap={2}>
                <AddTestUserCopyField title={t('userId')} content={String(createdTestedUser?.userId)} />
                <AddTestUserCopyField title={t('email')} content={createdTestedUser?.email} />
                <AddTestUserCopyField title={t('password')} content={createdTestedUser?.password} />
              </Box>
            </Box>
          ) : (
            <Typography variant="body1" fontSize="medium">
              {capitalize(t('lookup.users.titles.confirmTestUserMessage'))}
            </Typography>
          )}
        </Stack>
        <DialogActions sx={{ justifyContent: 'flex-start' }}>
          {createdTestedUser ? (
            <Box display="flex" gap={2} sx={{ mt: 4 }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => navigateToHandler(TEST_USER_NAVIGATE.USER_DETAILS_PAGE)}
              >
                {t('lookup.users.titles.goToUserDetails')}
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => navigateToHandler(TEST_USER_NAVIGATE.SEARCH_PAGE)}
              >
                {t('lookup.users.titles.goToSearch')}
              </Button>
            </Box>
          ) : (
            <LoadingButton
              sx={{ mt: 4 }}
              color="primary"
              variant="contained"
              loading={isLoading}
              onClick={createTestUserHandler}
            >
              {t('confirm')}
            </LoadingButton>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AddTestUserDialog;
