/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * Various financial classification levels a user can fall into.
 * @deprecated
 */
export type EFinancialClassificationSchema =
  (typeof EFinancialClassificationSchema)[keyof typeof EFinancialClassificationSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EFinancialClassificationSchema = {
  Unknown: 'Unknown',
  L1: 'L1',
  L2: 'L2',
  L3: 'L3',
  L4: 'L4',
} as const;
