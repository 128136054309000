import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { EGameLibraryModelSchema, GameSchema, getGetGameV2QueryKey } from '@greenisland-api';
import { Settings } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';

import { ADD_GAME_ID } from '../../Games/constants';
import SyncGameLibraryButton from './SyncGameLibraryButton';

type EditGameActionsProps = {
  game: GameSchema;
};

const EditGameActions = ({ game }: EditGameActionsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formState } = useFormContext();

  const navigateToConfiguration = useCallback(() => {
    if (!formState.isDirty || window.confirm(t('unsavedChanges'))) {
      navigate('./configurations');
    }
  }, [formState.isDirty, navigate, t]);

  return (
    <Stack gap={1} mb={2} flexDirection="column">
      {game?.conflictGamelib && (
        <Typography color="error">{t('conflict.gameLibId', { gameLibId: game.gameLibId })}</Typography>
      )}
      <Stack gap={1} mb={2} flexDirection="row">
        <Button
          disabled={!game?.gameId || game.gameId === ADD_GAME_ID}
          color="primary"
          variant="outlined"
          startIcon={<Settings />}
          onClick={navigateToConfiguration}
        >
          {t('editGames.editConfigurations')}
        </Button>
        <SyncGameLibraryButton
          gameLibEntity={game}
          gameLibModel={EGameLibraryModelSchema.Game}
          disabled={!game?.gameId || game.gameId === ADD_GAME_ID}
          invalidatedQueryKey={[...getGetGameV2QueryKey(Number(game?.gamePKey))]}
        />
      </Stack>
    </Stack>
  );
};

export default EditGameActions;
