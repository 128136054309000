import { WritePermission } from '@greenisland-core/permissions';
import type { Permission } from '@greenisland-types/authorization';

export const WRITE_INTERVENTION_PERMISSION: Permission = new WritePermission('/interventions/user/$userId');

export const WRITE_INTERVENTION_COMPLETE_PERMISSION: Permission = new WritePermission(
  '/interventions/$interventionid/complete'
);
export const WRITE_INTERVENTION_OPEN_PERMISSION: Permission = new WritePermission(
  '/interventions/$interventionid/open'
);
export const WRITE_INTERVENTION_CLOSE_PERMISSION: Permission = new WritePermission(
  '/interventions/$interventionid/close'
);
