import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  getGetIpWhitelistQueryKey,
  IPWhitelistInnerSchema,
  useAddIpWhitelist,
  useGetIpWhitelist,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { SelectInput, StyledDataGrid } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../app/hooks';

enum EWhitelistTypes {
  Fraud = 'Fraud',
}

const IPWhitelist = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const canReadIPWhitelist = usePermission(OnlineCasinoPermissions.getIpWhitelist);
  const { data: IPWhitelist, isLoading: fetching } = useGetIpWhitelist({ query: { enabled: canReadIPWhitelist } });
  const { mutate: addIPWhitelist } = useAddIpWhitelist({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetIpWhitelistQueryKey());
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });
  const methods = useForm<IPWhitelistInnerSchema>();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = methods;

  const columns: GridColDef[] = [
    { field: 'name', headerName: t('name'), flex: 1 },
    { field: 'ip', headerName: t('ip'), flex: 1 },
    { field: 'type', headerName: t('type'), flex: 1 },
  ];

  const onSubmit = (data: IPWhitelistInnerSchema) => {
    addIPWhitelist({ data: data });
    setIsOpen(false);
  };

  return canReadIPWhitelist ? (
    <Stack spacing={2}>
      <Button variant="contained" onClick={() => setIsOpen(true)} startIcon={<Typography>+</Typography>}>
        {t('addIp')}
      </Button>
      <StyledDataGrid
        columns={columns}
        rows={IPWhitelist || []}
        loading={fetching}
        disableSelectionOnClick
        disableColumnMenu
        pagination
        pageSize={20}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        getRowId={row => row.ip}
      />
      <Dialog fullWidth open={isOpen} onClose={() => setIsOpen(false)}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>{t('addToIPWhitelist')}</DialogTitle>
            <DialogContent>
              <Stack spacing={2}>
                <TextField
                  name="name"
                  label={t('name')}
                  error={!!errors.name}
                  fullWidth
                  size="small"
                  inputRef={register({ required: true })}
                />
                <TextField
                  name="ip"
                  label={t('ip')}
                  error={!!errors.ip}
                  fullWidth
                  size="small"
                  inputRef={register({ required: true })}
                />
                <SelectInput
                  name={'type'}
                  label={t('type')}
                  size="small"
                  options={Object.values(EWhitelistTypes).map(action => ({ label: action, value: action })) ?? []}
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button color="inherit" onClick={() => setIsOpen(false)}>
                {t('cancel')}
              </Button>
              <Button type="submit" variant="contained">
                {t('add')}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </Stack>
  ) : null;
};

export default IPWhitelist;
