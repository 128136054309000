import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid-pro';

import { FontAwesomeIcon, StyledDataGrid } from '@greenisland-common/components/atoms';

import { useGetIntegrations } from '../../store/provider';

const Providers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isLoading } = useGetIntegrations({ query: { staleTime: 10 ** 5 } });

  const rows = useMemo(() => data?.map(providerCode => ({ id: providerCode, providerCode })) ?? [], [data]);

  return (
    <StyledDataGrid
      density="compact"
      autoHeight
      loading={isLoading}
      rows={rows}
      columns={[
        { field: 'providerCode', headerName: t('providerService.providerConfiguration.providerCode'), flex: 1 },
        {
          field: 'actions',
          type: 'actions',
          getActions: (params: GridRowParams<{ providerCode: string }>) => [
            <GridActionsCellItem
              key="edit"
              label={t('providerService.providerConfiguration.edit')}
              icon={<FontAwesomeIcon icon={faPencil} />}
              onClick={() => navigate(`../providers/${params.row.providerCode}`)}
            />,
          ],
        },
      ]}
      onRowClick={({ row }) => navigate(`../providers/${row.providerCode}`)}
      disableDensitySelector
      disableSelectionOnClick
      disableColumnFilter
      disableColumnSelector
      disableColumnMenu
      disableChildrenSorting
      disableChildrenFiltering
      disableMultipleColumnsSorting
      disableColumnResize
      disableColumnReorder
      pagination={false}
    />
  );
};

export default Providers;
