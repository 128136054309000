/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */

export type RequirementCondition = (typeof RequirementCondition)[keyof typeof RequirementCondition];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequirementCondition = {
  EQUALS: 'EQUALS',
  DOES_NOT_EQUAL: 'DOES_NOT_EQUAL',
  IS_GREATER_THAN: 'IS_GREATER_THAN',
  IS_LESS_THAN: 'IS_LESS_THAN',
  IS_GREATER_THAN_OR_EQUAL_TO: 'IS_GREATER_THAN_OR_EQUAL_TO',
  IS_LESS_THAN_OR_EQUAL_TO: 'IS_LESS_THAN_OR_EQUAL_TO',
  IS_ONE_OF: 'IS_ONE_OF',
  IS_NOT_ONE_OF: 'IS_NOT_ONE_OF',
} as const;
