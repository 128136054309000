import React, { ComponentProps } from 'react';
import { Control, useController, UseControllerOptions } from 'react-hook-form';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

export type AutocompleteOption = { id: string; label: string };
type Props = ComponentProps<typeof TextField> & {
  name: string;
  options: AutocompleteOption[];
  loading?: ComponentProps<typeof Autocomplete>['loading'];
  control: Control;
  rules?: UseControllerOptions['rules'];
};

const AutocompleteField = (props: Props) => {
  const { name, control, label, rules, options, loading, ...restProps } = props;
  const {
    field: { ref, onChange, value, ...inputProps },
  } = useController({
    name,
    control,
    rules,
  });
  return (
    <Autocomplete
      clearOnEscape
      autoComplete
      onChange={(event: any, newValue: AutocompleteOption | null) => {
        onChange(newValue);
      }}
      value={value}
      loading={loading}
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            ...inputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label={label}
          variant="standard"
          inputRef={ref}
          fullWidth
          {...restProps}
        />
      )}
    />
  );
};

export default AutocompleteField;
