import { Dispatch, SetStateAction, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { ChipData, GameFilterType } from '../constants';
import useUpdateGameFilter from './useUpdateGameFilter';

export default function useDeleteGameFilter(setChips: Dispatch<SetStateAction<ChipData[]>>) {
  const location = useLocation();
  const updateFilterHandler = useUpdateGameFilter();

  return useCallback(
    (chipToDelete: ChipData) => {
      const params = new URLSearchParams(location.search);
      const queryKeys = [
        GameFilterType.THEME,
        GameFilterType.STUDIO,
        GameFilterType.CATEGORY,
        GameFilterType.ADDITIONAL,
      ];

      setChips(chips => chips.filter(chip => chip.id !== chipToDelete.id));

      queryKeys.forEach(queryKey => {
        const currentSelections = params.get(queryKey)?.split(',') || [];
        const updatedSelections = currentSelections.filter(item => item !== chipToDelete.id);
        updateFilterHandler(queryKey, updatedSelections);
      });
    },
    [location.search, setChips, updateFilterHandler]
  );
}
