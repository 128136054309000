import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Card, CardContent, CardHeader } from '@mui/material';
import TabMenu, { Tab } from 'src/routes/components/TabMenu';

import { Link } from '@greenisland-common/components/atoms';

import ActiveLimitsTable from './Tables/ActiveLimitsTable';
import ActiveMaxLimitsTable from './Tables/ActiveMaxLimitsTable';
import LimitRequestsTable from './Tables/LimitRequestsTable';
import { usePermission } from '../../../../../../app/hooks';
import { LimitSearchFilters } from '../Constants/Limits';
import SessionLimitRequestDialog from './SessionLimitRequestDialog';

type DepositLimitsProps = {
  userId: string;
};

const SessionLimitsCard = ({ userId }: DepositLimitsProps) => {
  const { t } = useTranslation();

  const canReadSessionsLimits = usePermission(OnlineCasinoPermissions.getActiveLoginSessionLimitsForUser);
  const canSetLimit = usePermission(OnlineCasinoPermissions.requestLoginSessionLimitUpdate);

  const [openDialog, setOpenDialog] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const tabs: Tab[] = [
    {
      title: 'depositLimit.tabTitle.activeLimits',
      content: <ActiveLimitsTable userId={userId} />,
    },
    {
      title: 'depositLimit.tabTitle.queuedRequests',
      content: <LimitRequestsTable userId={userId} showOnlyQueuedRequests={true} compact />,
    },
    {
      title: 'depositLimit.tabTitle.maxLimits',
      content: <ActiveMaxLimitsTable userId={userId} />,
    },
  ];

  return (
    <Card>
      <Box display="flex" justifyContent="space-between">
        <CardHeader title={capitalize(t('sessionLimit.title'))} />
        <Box display="flex" gap={1} flexWrap="wrap" justifyContent="flex-end">
          {canSetLimit && (
            <Button size="small" variant="contained" onClick={() => setOpenDialog(true)}>
              {t('common.setLimit')}
            </Button>
          )}
          {canReadSessionsLimits && (
            <Button
              size="small"
              variant="outlined"
              component={Link}
              to={`../sessionlimits?${LimitSearchFilters.ACTIVE_TAB}=${activeTab}`}
            >
              {t('seeMore')}
            </Button>
          )}
        </Box>
      </Box>
      <CardContent>
        <TabMenu tabs={tabs} setActiveTab={setActiveTab} />
      </CardContent>
      {openDialog && (
        <SessionLimitRequestDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          userId={userId}
          activeTab={activeTab === 2 ? 1 : 0}
        />
      )}
    </Card>
  );
};

export default memo(SessionLimitsCard);
