/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EInterventionTypeSchema = (typeof EInterventionTypeSchema)[keyof typeof EInterventionTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EInterventionTypeSchema = {
  Unknown: 'Unknown',
  PGSISelfTest: 'PGSISelfTest',
  BonusExclusion: 'BonusExclusion',
  RiskCommunication: 'RiskCommunication',
  AdvertisementExclusion: 'AdvertisementExclusion',
  LimitSet: 'LimitSet',
  FinancialVerification: 'FinancialVerification',
  PlatformExclusion: 'PlatformExclusion',
  AuthorityNotified: 'AuthorityNotified',
  FlagSet: 'FlagSet',
  GamblingExclusion: 'GamblingExclusion',
} as const;
