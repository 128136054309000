import { useEffect, useState } from 'react';
import { Stack, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { differenceInMilliseconds, getUnixTime } from 'date-fns';
import { t } from 'i18next';

import { DATETIME_INPUT_FORMAT, DATETIME_INPUT_MASK } from '@greenisland-common/components/atoms/Constants';

interface Props {
  unixStartDate?: number;
  unixEndDate?: number;
  onChange: (start: number, end: number) => void;
}

export const DateTimeRangePicker = ({ onChange, unixStartDate, unixEndDate }: Props) => {
  const [startDate, setStartDate] = useState<Date | undefined>(unixStartDate ? new Date(unixStartDate) : new Date());
  const [endDate, setEndDate] = useState<Date | undefined>(unixEndDate ? new Date(unixEndDate) : new Date());

  useEffect(() => {
    if (startDate && endDate) {
      const startDateUnix = getUnixTime(startDate);
      const endDateUnix = getUnixTime(endDate);
      onChange(startDateUnix, endDateUnix);
    }
  }, [startDate, endDate]);

  return (
    <Stack direction="row" spacing={1}>
      <DateTimePicker
        label={t('startDate')}
        onChange={(date: Date | null) => {
          setStartDate(date ?? undefined);
        }}
        value={startDate ?? null}
        renderInput={params => <TextField size="small" {...params} />}
        inputFormat={DATETIME_INPUT_FORMAT}
        mask={DATETIME_INPUT_MASK}
      />
      <DateTimePicker
        label={t('endDate')}
        onChange={(date: Date | null) => {
          setEndDate(date ?? undefined);
        }}
        value={endDate ?? null}
        renderInput={params => <TextField size="small" {...params} />}
        inputFormat={DATETIME_INPUT_FORMAT}
        mask={DATETIME_INPUT_MASK}
      />
    </Stack>
  );
};
