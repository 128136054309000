import { Box, capitalize } from '@mui/material';

import { ExtendedContentFilterKeysSchema } from '../helpers/ContentFilterFormContext';
import GetContentFilterStatus from './GetContentFilterStatus';

interface Props {
  title: string;
  filterType: 'included' | 'excluded';
  filterData: ExtendedContentFilterKeysSchema | undefined;
}

const GetContentFilterBox = ({ title, filterType, filterData }: Props) => {
  return (
    <Box
      width={{ xs: '100%', lg: '50%' }}
      display="flex"
      flexDirection="column"
      rowGap={1}
      sx={{
        border: '1px solid silver',
        padding: '10px',
      }}
    >
      <h5 style={{ margin: 0 }}>{capitalize(title)}:</h5>
      {filterData
        ? Object.entries(filterData).map(([key, data]) => (
            <GetContentFilterStatus key={key} indexKey={key} data={data} filterType={filterType} />
          ))
        : null}
    </Box>
  );
};

export default GetContentFilterBox;
