/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  AccountBalancesConsistencySchema,
  BadRequestResponse,
  CashflowPropertiesSchema,
  DefaultResponse,
  DepositsAndWithdrawalsSchema,
  GetCashflowParams,
  GetTopWinsParams,
  GetTransactionsGeneralParams,
  GetTransactionsGeneralResultSchema,
  NotFoundResponse,
  RevenueSchema,
  TopInOutTodaySchema,
  TopWinLoseTodaySchema,
  TransactionBadRequestResponse,
  TransactionsBadRequestResponse,
  UnauthorizedResponse,
  WinsPagedSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get revenue today and yesterday for games and sports
 */
export const useGetRevenueHook = () => {
  const getRevenue = useCustomInstance<RevenueSchema>();

  return (signal?: AbortSignal) => {
    return getRevenue({ url: `/tx/revenue`, method: 'get', signal });
  };
};

export const getGetRevenueQueryKey = () => [`/tx/revenue`] as const;

export const useGetRevenueQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetRevenueHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRevenueHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRevenueHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRevenueQueryKey();

  const getRevenue = useGetRevenueHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetRevenueHook>>>> = ({ signal }) =>
    getRevenue(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetRevenueQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetRevenueHook>>>>;
export type GetRevenueQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get revenue today and yesterday for games and sports
 */
export const useGetRevenue = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetRevenueHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetRevenueHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetRevenueQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all transactions for the given filters
 */
export const useGetTransactionsGeneralHook = () => {
  const getTransactionsGeneral = useCustomInstance<GetTransactionsGeneralResultSchema>();

  return (params?: GetTransactionsGeneralParams, signal?: AbortSignal) => {
    return getTransactionsGeneral({ url: `/tx`, method: 'get', params, signal });
  };
};

export const getGetTransactionsGeneralQueryKey = (params?: GetTransactionsGeneralParams) =>
  [`/tx`, ...(params ? [params] : [])] as const;

export const useGetTransactionsGeneralQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTransactionsGeneralHook>>>,
  TError = TransactionsBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetTransactionsGeneralParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTransactionsGeneralHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTransactionsGeneralHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTransactionsGeneralQueryKey(params);

  const getTransactionsGeneral = useGetTransactionsGeneralHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTransactionsGeneralHook>>>> = ({ signal }) =>
    getTransactionsGeneral(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetTransactionsGeneralQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetTransactionsGeneralHook>>>
>;
export type GetTransactionsGeneralQueryError =
  | TransactionsBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Gets all transactions for the given filters
 */
export const useGetTransactionsGeneral = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTransactionsGeneralHook>>>,
  TError = TransactionsBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetTransactionsGeneralParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTransactionsGeneralHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTransactionsGeneralQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get account balances consistency
 */
export const useGetAccountBalancesConsistencyHook = () => {
  const getAccountBalancesConsistency = useCustomInstance<AccountBalancesConsistencySchema>();

  return (signal?: AbortSignal) => {
    return getAccountBalancesConsistency({ url: `/tx/accountbalancesconsistency`, method: 'get', signal });
  };
};

export const getGetAccountBalancesConsistencyQueryKey = () => [`/tx/accountbalancesconsistency`] as const;

export const useGetAccountBalancesConsistencyQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAccountBalancesConsistencyHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountBalancesConsistencyHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountBalancesConsistencyHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccountBalancesConsistencyQueryKey();

  const getAccountBalancesConsistency = useGetAccountBalancesConsistencyHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAccountBalancesConsistencyHook>>>> = ({
    signal,
  }) => getAccountBalancesConsistency(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAccountBalancesConsistencyQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAccountBalancesConsistencyHook>>>
>;
export type GetAccountBalancesConsistencyQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get account balances consistency
 */
export const useGetAccountBalancesConsistency = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAccountBalancesConsistencyHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountBalancesConsistencyHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAccountBalancesConsistencyQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get cashflow for desired start and enddate
 */
export const useGetCashflowHook = () => {
  const getCashflow = useCustomInstance<CashflowPropertiesSchema>();

  return (params?: GetCashflowParams, signal?: AbortSignal) => {
    return getCashflow({ url: `/tx/cashflow`, method: 'get', params, signal });
  };
};

export const getGetCashflowQueryKey = (params?: GetCashflowParams) =>
  [`/tx/cashflow`, ...(params ? [params] : [])] as const;

export const useGetCashflowQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCashflowHook>>>,
  TError = TransactionBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetCashflowParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCashflowHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCashflowHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCashflowQueryKey(params);

  const getCashflow = useGetCashflowHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCashflowHook>>>> = ({ signal }) =>
    getCashflow(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetCashflowQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCashflowHook>>>>;
export type GetCashflowQueryError =
  | TransactionBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get cashflow for desired start and enddate
 */
export const useGetCashflow = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCashflowHook>>>,
  TError = TransactionBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetCashflowParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCashflowHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCashflowQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get deposits and withdrawals
 */
export const useGetDepositsAndWithdrawalsHook = () => {
  const getDepositsAndWithdrawals = useCustomInstance<DepositsAndWithdrawalsSchema>();

  return (signal?: AbortSignal) => {
    return getDepositsAndWithdrawals({ url: `/tx/depositsandwithdrawals`, method: 'get', signal });
  };
};

export const getGetDepositsAndWithdrawalsQueryKey = () => [`/tx/depositsandwithdrawals`] as const;

export const useGetDepositsAndWithdrawalsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDepositsAndWithdrawalsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositsAndWithdrawalsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositsAndWithdrawalsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDepositsAndWithdrawalsQueryKey();

  const getDepositsAndWithdrawals = useGetDepositsAndWithdrawalsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDepositsAndWithdrawalsHook>>>> = ({
    signal,
  }) => getDepositsAndWithdrawals(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDepositsAndWithdrawalsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetDepositsAndWithdrawalsHook>>>
>;
export type GetDepositsAndWithdrawalsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get deposits and withdrawals
 */
export const useGetDepositsAndWithdrawals = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDepositsAndWithdrawalsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositsAndWithdrawalsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDepositsAndWithdrawalsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the top in and out of today
 */
export const useGetTopInOutTodayHook = () => {
  const getTopInOutToday = useCustomInstance<TopInOutTodaySchema>();

  return (signal?: AbortSignal) => {
    return getTopInOutToday({ url: `/tx/topinouttoday`, method: 'get', signal });
  };
};

export const getGetTopInOutTodayQueryKey = () => [`/tx/topinouttoday`] as const;

export const useGetTopInOutTodayQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTopInOutTodayHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTopInOutTodayHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTopInOutTodayHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTopInOutTodayQueryKey();

  const getTopInOutToday = useGetTopInOutTodayHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTopInOutTodayHook>>>> = ({ signal }) =>
    getTopInOutToday(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetTopInOutTodayQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTopInOutTodayHook>>>>;
export type GetTopInOutTodayQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get the top in and out of today
 */
export const useGetTopInOutToday = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTopInOutTodayHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTopInOutTodayHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTopInOutTodayQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the top win and lose of today
 */
export const useGetTopWinLoseTodayHook = () => {
  const getTopWinLoseToday = useCustomInstance<TopWinLoseTodaySchema>();

  return (signal?: AbortSignal) => {
    return getTopWinLoseToday({ url: `/tx/topwinlosetoday`, method: 'get', signal });
  };
};

export const getGetTopWinLoseTodayQueryKey = () => [`/tx/topwinlosetoday`] as const;

export const useGetTopWinLoseTodayQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTopWinLoseTodayHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTopWinLoseTodayHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTopWinLoseTodayHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTopWinLoseTodayQueryKey();

  const getTopWinLoseToday = useGetTopWinLoseTodayHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTopWinLoseTodayHook>>>> = ({ signal }) =>
    getTopWinLoseToday(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetTopWinLoseTodayQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetTopWinLoseTodayHook>>>
>;
export type GetTopWinLoseTodayQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the top win and lose of today
 */
export const useGetTopWinLoseToday = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTopWinLoseTodayHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTopWinLoseTodayHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTopWinLoseTodayQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets the top wins
 */
export const useGetTopWinsHook = () => {
  const getTopWins = useCustomInstance<WinsPagedSchema>();

  return (params: GetTopWinsParams, signal?: AbortSignal) => {
    return getTopWins({ url: `/tx/top-wins`, method: 'get', params, signal });
  };
};

export const getGetTopWinsQueryKey = (params: GetTopWinsParams) =>
  [`/tx/top-wins`, ...(params ? [params] : [])] as const;

export const useGetTopWinsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTopWinsHook>>>,
  TError = BadRequestResponse | DefaultResponse
>(
  params: GetTopWinsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTopWinsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTopWinsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTopWinsQueryKey(params);

  const getTopWins = useGetTopWinsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetTopWinsHook>>>> = ({ signal }) =>
    getTopWins(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetTopWinsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetTopWinsHook>>>>;
export type GetTopWinsQueryError = BadRequestResponse | DefaultResponse;

/**
 * @summary Gets the top wins
 */
export const useGetTopWins = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetTopWinsHook>>>,
  TError = BadRequestResponse | DefaultResponse
>(
  params: GetTopWinsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetTopWinsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetTopWinsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
