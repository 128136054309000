/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  AgentTaskBadRequestResponse,
  AgentTaskResolveArgumentsSchema,
  BadRequestResponse,
  DefaultResponse,
  GetAgentTasksParams,
  GetOpenAgentTasksParams,
  InterventionsNeededSchema,
  NotFoundResponse,
  PagedAgentTasksSchema,
  UnauthorizedResponse,
  VerificationTasksSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary gets the agent tasks based on the provided filters
 */
export const useGetAgentTasksHook = () => {
  const getAgentTasks = useCustomInstance<PagedAgentTasksSchema>();

  return (params?: GetAgentTasksParams, signal?: AbortSignal) => {
    return getAgentTasks({ url: `/tasks`, method: 'get', params, signal });
  };
};

export const getGetAgentTasksQueryKey = (params?: GetAgentTasksParams) =>
  [`/tasks`, ...(params ? [params] : [])] as const;

export const useGetAgentTasksQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAgentTasksHook>>>,
  TError = AgentTaskBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetAgentTasksParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAgentTasksHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAgentTasksHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAgentTasksQueryKey(params);

  const getAgentTasks = useGetAgentTasksHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAgentTasksHook>>>> = ({ signal }) =>
    getAgentTasks(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAgentTasksQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAgentTasksHook>>>>;
export type GetAgentTasksQueryError =
  | AgentTaskBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary gets the agent tasks based on the provided filters
 */
export const useGetAgentTasks = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAgentTasksHook>>>,
  TError = AgentTaskBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetAgentTasksParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAgentTasksHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAgentTasksQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary gets the intreventions needed
 */
export const useGetInterventionNeededHook = () => {
  const getInterventionNeeded = useCustomInstance<InterventionsNeededSchema>();

  return (signal?: AbortSignal) => {
    return getInterventionNeeded({ url: `/tasks/interventions`, method: 'get', signal });
  };
};

export const getGetInterventionNeededQueryKey = () => [`/tasks/interventions`] as const;

export const useGetInterventionNeededQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInterventionNeededHook>>>,
  TError = AgentTaskBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInterventionNeededHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInterventionNeededHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInterventionNeededQueryKey();

  const getInterventionNeeded = useGetInterventionNeededHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetInterventionNeededHook>>>> = ({ signal }) =>
    getInterventionNeeded(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetInterventionNeededQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetInterventionNeededHook>>>
>;
export type GetInterventionNeededQueryError =
  | AgentTaskBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary gets the intreventions needed
 */
export const useGetInterventionNeeded = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInterventionNeededHook>>>,
  TError = AgentTaskBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInterventionNeededHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInterventionNeededQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary gets the open agent tasks
 */
export const useGetOpenAgentTasksHook = () => {
  const getOpenAgentTasks = useCustomInstance<PagedAgentTasksSchema>();

  return (params?: GetOpenAgentTasksParams, signal?: AbortSignal) => {
    return getOpenAgentTasks({ url: `/tasks/open`, method: 'get', params, signal });
  };
};

export const getGetOpenAgentTasksQueryKey = (params?: GetOpenAgentTasksParams) =>
  [`/tasks/open`, ...(params ? [params] : [])] as const;

export const useGetOpenAgentTasksQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetOpenAgentTasksHook>>>,
  TError = AgentTaskBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetOpenAgentTasksParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOpenAgentTasksHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOpenAgentTasksHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOpenAgentTasksQueryKey(params);

  const getOpenAgentTasks = useGetOpenAgentTasksHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOpenAgentTasksHook>>>> = ({ signal }) =>
    getOpenAgentTasks(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetOpenAgentTasksQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetOpenAgentTasksHook>>>
>;
export type GetOpenAgentTasksQueryError =
  | AgentTaskBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary gets the open agent tasks
 */
export const useGetOpenAgentTasks = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetOpenAgentTasksHook>>>,
  TError = AgentTaskBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetOpenAgentTasksParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOpenAgentTasksHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetOpenAgentTasksQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary resolves an open agent task
 */
export const useResolveOpenAgentTaskHook = () => {
  const resolveOpenAgentTask = useCustomInstance<void>();

  return (agentTaskId: string) => {
    return resolveOpenAgentTask({ url: `/tasks/${agentTaskId}/resolve`, method: 'post' });
  };
};

export const useResolveOpenAgentTaskMutationOptions = <
  TError = AgentTaskBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResolveOpenAgentTaskHook>>>,
    TError,
    { agentTaskId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useResolveOpenAgentTaskHook>>>,
  TError,
  { agentTaskId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const resolveOpenAgentTask = useResolveOpenAgentTaskHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useResolveOpenAgentTaskHook>>>,
    { agentTaskId: string }
  > = props => {
    const { agentTaskId } = props ?? {};

    return resolveOpenAgentTask(agentTaskId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResolveOpenAgentTaskMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useResolveOpenAgentTaskHook>>>
>;

export type ResolveOpenAgentTaskMutationError =
  | AgentTaskBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary resolves an open agent task
 */
export const useResolveOpenAgentTask = <
  TError = AgentTaskBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResolveOpenAgentTaskHook>>>,
    TError,
    { agentTaskId: string },
    TContext
  >;
}) => {
  const mutationOptions = useResolveOpenAgentTaskMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary resolves an open agent task
 */
export const useResolveOpenAgentTaskV2Hook = () => {
  const resolveOpenAgentTaskV2 = useCustomInstance<void>();

  return (agentTaskId: string, agentTaskResolveArgumentsSchema: AgentTaskResolveArgumentsSchema) => {
    return resolveOpenAgentTaskV2({
      url: `/tasks/${agentTaskId}/resolveV2`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: agentTaskResolveArgumentsSchema,
    });
  };
};

export const useResolveOpenAgentTaskV2MutationOptions = <
  TError = AgentTaskBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResolveOpenAgentTaskV2Hook>>>,
    TError,
    { agentTaskId: string; data: AgentTaskResolveArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useResolveOpenAgentTaskV2Hook>>>,
  TError,
  { agentTaskId: string; data: AgentTaskResolveArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const resolveOpenAgentTaskV2 = useResolveOpenAgentTaskV2Hook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useResolveOpenAgentTaskV2Hook>>>,
    { agentTaskId: string; data: AgentTaskResolveArgumentsSchema }
  > = props => {
    const { agentTaskId, data } = props ?? {};

    return resolveOpenAgentTaskV2(agentTaskId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResolveOpenAgentTaskV2MutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useResolveOpenAgentTaskV2Hook>>>
>;
export type ResolveOpenAgentTaskV2MutationBody = AgentTaskResolveArgumentsSchema;
export type ResolveOpenAgentTaskV2MutationError =
  | AgentTaskBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary resolves an open agent task
 */
export const useResolveOpenAgentTaskV2 = <
  TError = AgentTaskBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResolveOpenAgentTaskV2Hook>>>,
    TError,
    { agentTaskId: string; data: AgentTaskResolveArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useResolveOpenAgentTaskV2MutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary assigns an agent task to the user that called the endpoint
 */
export const useAssignAgentTaskHook = () => {
  const assignAgentTask = useCustomInstance<void>();

  return (agentTaskId: string) => {
    return assignAgentTask({ url: `/tasks/${agentTaskId}/assign`, method: 'patch' });
  };
};

export const useAssignAgentTaskMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignAgentTaskHook>>>,
    TError,
    { agentTaskId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAssignAgentTaskHook>>>,
  TError,
  { agentTaskId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignAgentTask = useAssignAgentTaskHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAssignAgentTaskHook>>>,
    { agentTaskId: string }
  > = props => {
    const { agentTaskId } = props ?? {};

    return assignAgentTask(agentTaskId);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignAgentTaskMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAssignAgentTaskHook>>>>;

export type AssignAgentTaskMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary assigns an agent task to the user that called the endpoint
 */
export const useAssignAgentTask = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignAgentTaskHook>>>,
    TError,
    { agentTaskId: string },
    TContext
  >;
}) => {
  const mutationOptions = useAssignAgentTaskMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary unassigns an agent task from the user that called the endpoint
 */
export const useUnassignAgentTaskHook = () => {
  const unassignAgentTask = useCustomInstance<void>();

  return (agentTaskId: string) => {
    return unassignAgentTask({ url: `/tasks/${agentTaskId}/unassign`, method: 'patch' });
  };
};

export const useUnassignAgentTaskMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUnassignAgentTaskHook>>>,
    TError,
    { agentTaskId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUnassignAgentTaskHook>>>,
  TError,
  { agentTaskId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const unassignAgentTask = useUnassignAgentTaskHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUnassignAgentTaskHook>>>,
    { agentTaskId: string }
  > = props => {
    const { agentTaskId } = props ?? {};

    return unassignAgentTask(agentTaskId);
  };

  return { mutationFn, ...mutationOptions };
};

export type UnassignAgentTaskMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUnassignAgentTaskHook>>>
>;

export type UnassignAgentTaskMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary unassigns an agent task from the user that called the endpoint
 */
export const useUnassignAgentTask = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUnassignAgentTaskHook>>>,
    TError,
    { agentTaskId: string },
    TContext
  >;
}) => {
  const mutationOptions = useUnassignAgentTaskMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary gets the intreventions needed
 */
export const useSolveInterventionHook = () => {
  const solveIntervention = useCustomInstance<void>();

  return (interventionId: string) => {
    return solveIntervention({ url: `/tasks/interventions/${interventionId}/solve`, method: 'put' });
  };
};

export const useSolveInterventionMutationOptions = <
  TError = AgentTaskBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSolveInterventionHook>>>,
    TError,
    { interventionId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSolveInterventionHook>>>,
  TError,
  { interventionId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const solveIntervention = useSolveInterventionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSolveInterventionHook>>>,
    { interventionId: string }
  > = props => {
    const { interventionId } = props ?? {};

    return solveIntervention(interventionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type SolveInterventionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSolveInterventionHook>>>
>;

export type SolveInterventionMutationError =
  | AgentTaskBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary gets the intreventions needed
 */
export const useSolveIntervention = <
  TError = AgentTaskBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSolveInterventionHook>>>,
    TError,
    { interventionId: string },
    TContext
  >;
}) => {
  const mutationOptions = useSolveInterventionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary gets the verifications needed
 */
export const useGetVerificationsHook = () => {
  const getVerifications = useCustomInstance<VerificationTasksSchema>();

  return (signal?: AbortSignal) => {
    return getVerifications({ url: `/tasks/verifications`, method: 'get', signal });
  };
};

export const getGetVerificationsQueryKey = () => [`/tasks/verifications`] as const;

export const useGetVerificationsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetVerificationsHook>>>,
  TError = AgentTaskBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVerificationsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVerificationsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetVerificationsQueryKey();

  const getVerifications = useGetVerificationsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetVerificationsHook>>>> = ({ signal }) =>
    getVerifications(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetVerificationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetVerificationsHook>>>>;
export type GetVerificationsQueryError =
  | AgentTaskBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary gets the verifications needed
 */
export const useGetVerifications = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetVerificationsHook>>>,
  TError = AgentTaskBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVerificationsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetVerificationsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
