import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faCaretDown, faChartBar, faHistory, faLink, faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, ListItemIcon, Menu, MenuItem } from '@mui/material';

import { usePermission } from '../../../../../../../app/hooks';

const OtherMenu = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'lookup.userDetailsMenu' });
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();
  const isOpen = Boolean(anchorEl);
  const canReadLogins = usePermission(OnlineCasinoPermissions.getUserLoginSession);
  const canReadNotes = usePermission(OnlineCasinoPermissions.getUserNotes);
  const canReadLinkedAccounts = usePermission(OnlineCasinoPermissions.getUserLinkedAccounts);
  const canReadAccountStatistics = usePermission(OnlineCasinoPermissions.getUserAccountStatistics);

  return (
    <>
      <Button
        sx={{ display: 'flex', alignItems: 'flex-start' }}
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        endIcon={<FontAwesomeIcon icon={faCaretDown} />}
        size="small"
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        {t('other')}
      </Button>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {canReadLogins && (
          <MenuItem onClick={() => navigate('../logins')}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faHistory} />
            </ListItemIcon>
            {t('loginHistory')}
          </MenuItem>
        )}
        {canReadNotes && (
          <MenuItem onClick={() => navigate('../notes')}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faNoteSticky} />
            </ListItemIcon>
            {t('userNotes')}
          </MenuItem>
        )}
        {canReadLinkedAccounts && (
          <MenuItem onClick={() => navigate('../linkedaccounts')}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faLink} />
            </ListItemIcon>
            {t('linkedAccounts')}
          </MenuItem>
        )}
        {canReadAccountStatistics && (
          <MenuItem onClick={() => navigate('../account-statistics')}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faChartBar} />
            </ListItemIcon>
            {t('accountStatistics')}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default OtherMenu;
