/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * Playing break reason type `unknown` - The playing break source is unknown. `user` - The playing break was initiated by the user. `agent` - The playing break was imposed by an agent. `system` - The playing break was imposed automatically by the system. `closedUser` - The playing break has been copied from an active playing break of a closed user.
 */
export type EPlayingBreakSourceSchema = (typeof EPlayingBreakSourceSchema)[keyof typeof EPlayingBreakSourceSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EPlayingBreakSourceSchema = {
  unknown: 'unknown',
  user: 'user',
  agent: 'agent',
  system: 'system',
  closedUser: 'closedUser',
} as const;
