import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  ComplaintTicketSchema,
  EAuthorTypeSchema,
  EComplaintResponseTypeSchema,
  EComplaintStatusSchema,
  getGetComplaintTicketQueryKey,
  useAddComplaintTicketEntry,
} from '@greenisland-api';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  InputBase,
  List,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { getDateTimeFromUnix } from 'src/app/helpers/transformFunctions';
import { usePermission } from 'src/app/hooks';

import useLoadMore from '@greenisland-common/hooks/useLoadMore';

import ComplaintEntryItem from './ComplaintEntryItem';
import { DELETE_COMPLAINT_ENTRY_PERMISSION, WRITE_COMPLAINT_ENTRY_PERMISSION } from './permissions';

const ComplaintChat = ({ complaint }: { complaint: ComplaintTicketSchema }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [currentAuthor, setCurrentAuthor] = useState<EAuthorTypeSchema>(EAuthorTypeSchema.Agent);
  const [newContent, setNewContent] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const canEditOrRemoveComplaintEntry = usePermission(DELETE_COMPLAINT_ENTRY_PERMISSION);

  const complaintFollowUps = useMemo(
    () =>
      complaint.complaintEntries
        .filter(complaintEntry => complaintEntry.responseType === EComplaintResponseTypeSchema.FollowUp)
        .sort((entry1, entry2) => entry2.date - entry1.date),
    [complaint?.complaintEntries]
  );

  const renderItems = (start: number, end: number) =>
    complaintFollowUps
      .slice(start, end)
      .map(complaintEntry => (
        <ComplaintEntryItem
          key={complaintEntry.id}
          complaintId={complaint.id}
          complaintEntry={complaintEntry}
          hasPermission={canEditOrRemoveComplaintEntry}
          editable={complaint.status !== EComplaintStatusSchema.Closed}
        />
      ));

  // Get the list of items and the "Load More" handler from the useLoadMore hook
  const { items, action } = useLoadMore(complaintFollowUps.length, 5, renderItems);

  const handleChangeAuthor = (event: { target: { value: string } }) => {
    setCurrentAuthor(event.target.value as EAuthorTypeSchema);
  };

  const canAddComplaintEntry = usePermission(WRITE_COMPLAINT_ENTRY_PERMISSION);

  const { mutate, isLoading } = useAddComplaintTicketEntry({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetComplaintTicketQueryKey(complaint.id));
        setNewContent('');
      },
    },
  });

  const handleKeyDown = useCallback(
    (event: any) => {
      if (event.key === 'Enter' && canAddComplaintEntry && newContent !== '') {
        mutate({
          complaintTicketId: complaint.id,
          data: {
            content: newContent,
            authorType: currentAuthor,
          },
        });
      }
    },
    [canAddComplaintEntry, complaint.id, currentAuthor, mutate, newContent]
  );

  const submitNewEntry = useCallback(() => {
    if (canAddComplaintEntry && newContent !== '') {
      mutate({
        complaintTicketId: complaint.id,
        data: {
          content: newContent,
          authorType: currentAuthor,
        },
      });
    }
  }, [canAddComplaintEntry, complaint.id, currentAuthor, mutate, newContent]);

  if (complaintFollowUps.length === 0 && complaint.status === EComplaintStatusSchema.Closed) {
    return null;
  }

  return (
    <>
      <Card sx={{ paddingX: 0, paddingBottom: 0 }}>
        {canAddComplaintEntry && complaint.status !== EComplaintStatusSchema.Closed ? (
          <Box display="flex" padding={2} width="100%" flexDirection={isMobile ? 'column' : 'row'}>
            <Select
              value={currentAuthor}
              onChange={handleChangeAuthor}
              input={<InputBase />}
              fullWidth
              sx={{ maxWidth: isMobile ? undefined : 120 }}
            >
              {Object.values(EAuthorTypeSchema).map(t => (
                <MenuItem value={t} key={t}>
                  {t}
                </MenuItem>
              )) ?? []}
            </Select>
            <Divider orientation={isMobile ? 'horizontal' : 'vertical'} flexItem />
            <Box ml={isMobile ? 0 : 2} mt={isMobile ? 2 : 0} display="flex" width="100%">
              <Box flexGrow={1}>
                <InputBase
                  placeholder={t('tasks.complaints.newEntryPlaceholder')}
                  fullWidth
                  onChange={e => setNewContent(e.target.value)}
                  value={newContent}
                  onKeyDown={handleKeyDown}
                />
              </Box>
              <IconButton
                aria-label="send"
                color="primary"
                sx={{ backgroundColor: 'primary.main', color: 'white' }}
                disabled={isLoading || newContent === ''}
                size="small"
                onClick={submitNewEntry}
              >
                <SendIcon />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <CardHeader
            title={t('tasks.complaints.entries')}
            subheader={t('tasks.complaints.lastUpdate', { time: getDateTimeFromUnix(complaint.startDate) })}
          />
        )}
        <List dense disablePadding>
          {items}
        </List>
      </Card>
      {action}
    </>
  );
};

export default ComplaintChat;
