import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCaretDown, faCaretUp, faCertificate } from '@fortawesome/free-solid-svg-icons';
import { BankAccountSchema } from '@greenisland-api';
import { Box, Button, Popper, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { theme } from 'src/app/theme/theme';

import { FontAwesomeIcon, Link } from '@greenisland-common/components/atoms';

import VerificationStatusField from './VerificationStatusField';

interface Props {
  accounts?: BankAccountSchema[];
}

const BankAccountList = ({ accounts }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'bank-popper' : undefined;

  const mainBankAccount = accounts?.find(account => account.isMainBankAccount);
  const bankAccountToShow = accounts?.length === 1 ? accounts[0] : mainBankAccount;
  const formattedBankAccount = bankAccountToShow?.accountNumber.match(/.{1,4}/g)?.join(' ');

  if (!accounts?.length) return <Box component="span">{t('noBankaccounts')}</Box>;

  return (
    <>
      {bankAccountToShow && formattedBankAccount ? (
        <Stack direction="row" gap={1} overflow="hidden" alignItems="center">
          {mainBankAccount ? (
            <Tooltip title={t('mainBankAccount')}>
              <FontAwesomeIcon sx={{ ml: 1 }} icon={faCertificate} />
            </Tooltip>
          ) : null}
          <Tooltip title={formattedBankAccount}>
            <Typography variant="body2" sx={{ overflow: 'hidden', flex: 1, textOverflow: 'ellipsis' }}>
              <Link to={`../userbankaccounts?iban=${bankAccountToShow.accountNumber}`}>{formattedBankAccount}</Link>
            </Typography>
          </Tooltip>
          <VerificationStatusField status={mainBankAccount?.verificationStatus || accounts[0].verificationStatus} />
        </Stack>
      ) : (
        <Typography variant="body2">{t('bankAccountsNumber', { number: accounts?.length })}</Typography>
      )}
      {accounts?.length > 1 ? (
        <Button aria-describedby={id} variant="text" onClick={handleClick}>
          {open ? (
            <FontAwesomeIcon sx={{ color: 'array.headers' }} icon={faCaretUp} />
          ) : (
            <FontAwesomeIcon sx={{ color: 'array.headers' }} icon={faCaretDown} />
          )}
        </Button>
      ) : null}
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <Box component="ul" sx={{ listStyle: 'none', p: 2, backgroundColor: 'background.paper', boxShadow: 2 }}>
          {accounts?.map(({ accountNumber, verificationStatus }) => (
            <Box component="li" sx={{ display: 'flex', justifyContent: 'space-between' }} key={accountNumber}>
              <Box component="span" sx={{ whiteSpace: 'nowrap', marginRight: '15px' }}>
                <Link to={`../userbankaccounts?iban=${accountNumber}`}>{accountNumber}</Link>
              </Box>
              {!isMobile ? <VerificationStatusField status={verificationStatus} /> : null}
            </Box>
          ))}
        </Box>
      </Popper>
    </>
  );
};

export default BankAccountList;
