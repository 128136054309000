import { Controller, get, RegisterOptions, useFormContext } from 'react-hook-form';
import { Autocomplete, SxProps, TextField, TextFieldProps, Theme } from '@mui/material';

interface Props<T> {
  name: string;
  label?: string;
  disabled?: boolean;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  options: T[];
  getLabel: (option: T) => string;
  sx?: SxProps<Theme>;
  multiple?: boolean;
  disableCloseOnSelect?: boolean;
  defaultValue?: T | null;
  loading?: boolean;
  required?: boolean;
  size?: TextFieldProps['size'];
}

const AutoCompleteInput = <T,>({
  name,
  label,
  rules,
  disabled = false,
  multiple = false,
  disableCloseOnSelect = true,
  sx,
  getLabel,
  options,
  loading,
  required = false,
  size = 'medium',
  defaultValue,
}: Props<T>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      fullWidth
      render={({ onChange, value, ...props }) => (
        <Autocomplete
          {...props}
          loading={loading}
          fullWidth
          multiple={multiple}
          options={options}
          value={value || defaultValue}
          onChange={(_, value) => onChange(value)}
          disabled={disabled}
          autoComplete
          disableCloseOnSelect={disableCloseOnSelect}
          getOptionLabel={getLabel}
          sx={{ ...sx }}
          renderInput={params => (
            <TextField
              {...params}
              required={required}
              fullWidth
              variant="outlined"
              label={label}
              error={Boolean(get(errors, name))}
              helperText={get(errors, name)?.message}
              size={size}
            />
          )}
        />
      )}
      rules={rules}
      control={control}
      name={name}
    />
  );
};

export default AutoCompleteInput;
