import {
  GameSessionsSortKeyParamParameter,
  GameSessionsSummaryGamingRevenueComparisonParameter,
  GameSessionsSummaryGamingRevenueComparisonValueParameter,
  GameSessionsSummaryGroupByKeyParamParameter,
  GameSessionsSummarySortKeyParamParameter,
  SortOrderParamParameter,
} from '@greenisland-api';

export interface GameSessionsSortingType {
  sortByKey: GameSessionsSummarySortKeyParamParameter | GameSessionsSortKeyParamParameter;
  sortByOrder: SortOrderParamParameter;
  groupByKey?: GameSessionsSummaryGroupByKeyParamParameter;
  gamingRevenueComparison?: GameSessionsSummaryGamingRevenueComparisonParameter;
  gamingRevenueComparisonValue?: GameSessionsSummaryGamingRevenueComparisonValueParameter;
}

export enum SessionGameCardType {
  DASHBOARD = 'dashboard',
  LOOKUP = 'lookup',
  ALL = 'all',
}

export enum GameSummaryCardVariant {
  BIGGEST_WINNERS = 'gameSessions.titles.biggestWinners',
  BIGGEST_LOSERS = 'gameSessions.titles.biggestLosers',
  MOST_PROFITABLE_GAMES = 'gameSessions.titles.mostProfitableGames',
  MOST_UNPROFITABLE_GAMES = 'gameSessions.titles.mostUnprofitableGames',
}

export enum GameSummarySearchFilters {
  ACTIVE_TAB = 'activetab',
  PAGE = 'page',
  START_DATE = 'startdate',
  END_DATE = 'enddate',
  PROVIDER_ID = 'providerid',
  USER_ID = 'userid',
  GAME_ID = 'gameid',
  GROUP_BY_KEY = 'groupbykey',
  SORT_BY_ORDER = 'sortbyorder',
  SORT_BY_KEY = 'sortbykey',
  GAMING_REVENUE_COMPARISON = 'gamingrevenuecomparison',
  GAMING_REVENUE_COMPARISON_VALUE = 'gamingrevenuecomparisonvalue',
}
