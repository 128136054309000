import { memo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '@greenisland-core/store';
import { EntitySchema, EProductTypeSchema, ProductSchema } from '@greenisland-store/authorization';
import { Box, CircularProgress } from '@mui/material';

import LayoutContainer from './LayoutContainer';
import { BaseRouting } from './RouteInterfaces';
import getRoutes from './routes';

const HighLevelRouteContainer = () => {
  const [routes, setRoutes] = useState<BaseRouting | null>(null);
  const { data } = useAppSelector(state => state.authentication);
  const entitySelected = useAppSelector(state => state.entities);
  const { state, pathname } = useLocation();
  const logoutState = (state as Record<string, unknown> | undefined)?.logout;
  const navigate = useNavigate();
  useEffect(() => window.scrollTo(0, 0), [pathname]);

  useEffect(() => {
    let productType;
    if (!logoutState && data.token && data.startupAgent?.entities) {
      const { entities } = data.startupAgent;
      const currentEntity = entities.find(
        (entity: EntitySchema) => entity.entityName === entitySelected.selectedEntity
      );

      if (currentEntity) {
        const currentProduct = currentEntity.products.find(
          (product: ProductSchema) => product.productName === entitySelected.selectedProduct
        );

        if (currentProduct) {
          productType = currentProduct.productType as EProductTypeSchema;
        }
      }
    }
    const initRoutes = getRoutes(productType);
    setRoutes(initRoutes);
  }, [data.startupAgent, data.token, entitySelected.selectedEntity, entitySelected.selectedProduct, logoutState]);

  useEffect(() => {
    if (logoutState && data.token === '') {
      window.localStorage.setItem('CREDENTIALS_FLUSH', Date.now().toString());
      window.localStorage.removeItem('CREDENTIALS_FLUSH');
      navigate('/');
    }
  }, [logoutState, data.token, navigate]);

  return !routes || logoutState ? (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress />
    </Box>
  ) : (
    <LayoutContainer agentEntities={data.startupAgent?.entities} entitySelected={entitySelected} {...routes} />
  );
};

export default memo(HighLevelRouteContainer);
