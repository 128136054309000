/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EUpdateBoostBadRequestTypeSchema =
  (typeof EUpdateBoostBadRequestTypeSchema)[keyof typeof EUpdateBoostBadRequestTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EUpdateBoostBadRequestTypeSchema = {
  InvalidBoostName: 'InvalidBoostName',
  InvalidStartDate: 'InvalidStartDate',
  InvalidDuration: 'InvalidDuration',
  InvalidFactor: 'InvalidFactor',
  InvalidMaxPayout: 'InvalidMaxPayout',
  InvalidContentFilterId: 'InvalidContentFilterId',
  BoostsNotEnabled: 'BoostsNotEnabled',
  OverlappingGlobalBoost: 'OverlappingGlobalBoost',
  InvalidAggregationFunction: 'InvalidAggregationFunction',
} as const;
