import { useEffect } from 'react';

const useFormPersist = (
  name: string,
  { watch, setValue }: any,
  { storage = window.sessionStorage, ignore = [] }: any = {}
) => {
  const values = watch();

  useEffect(() => {
    const str = storage.getItem(name);
    if (str) {
      const values: any = JSON.parse(str);
      Object.keys(values).forEach((key: string) => {
        if (!ignore.includes(key)) {
          setValue(key, values[key]);
        }
      });
    }

    return () => {
      storage.removeItem(name);
    };
  }, [name]);

  useEffect(() => {
    storage.setItem(name, JSON.stringify(values));
  });
};

export default useFormPersist;
