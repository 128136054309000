import { useTranslation } from 'react-i18next';
import { useGetUserGeneral } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { useTenantContext } from 'src/app/context/TenantContext';

import {
  DataGridContainer,
  DataGridPlain,
  DataGridPlainHeader,
  ErrorCard,
  NoData,
} from '@greenisland-common/components/atoms';

import {
  ActiveField,
  ClosedField,
  LockedField,
  ManuallyBlockedFromWithdrawalsField,
  MarketingBlacklistedField,
  PlayingBreakField,
  PromotionBlacklistedField,
  RegisteredOn,
  SportsBlackListed,
  TestUserField,
  TooManyLoginsField,
} from './UserVerification/fields';
import { usePermission } from '../../../../../app/hooks';

interface Props {
  userId: string;
}

const UserStatus = ({ userId }: Props) => {
  const { t } = useTranslation();
  const canReadUserGeneral = usePermission(OnlineCasinoPermissions.getUserGeneral);
  const tenant = useTenantContext();
  const {
    data: user,
    isLoading,
    isError,
    error,
  } = useGetUserGeneral(userId, { query: { enabled: canReadUserGeneral } });

  if (!canReadUserGeneral) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={359} />;
  if (isError || !user) return <ErrorCard sx={{ height: 359 }}>{error?.message}</ErrorCard>;

  const headers: GridColumns = [
    {
      headerName: '',
      field: 'title',
      renderCell: ({ value }) => <DataGridPlainHeader value={value} />,
      width: 230,
    },
    { headerName: '', field: 'value', renderCell: params => params.value, flex: 1 },
  ];

  const rows: GridRowsProp = [
    { id: 0, title: t('active'), value: <ActiveField user={user} /> },
    { id: 1, title: t('closed'), value: <ClosedField user={user} /> },
    ...(tenant.regulator === 'KSA' ? [] : [{ id: 3, title: t('locked'), value: <LockedField user={user} /> }]),
    { id: 4, title: t('testUser'), value: <TestUserField user={user} /> },
    { id: 5, title: t('lockedTooManyLogins'), value: <TooManyLoginsField user={user} /> },
    { id: 6, title: t('promotionBlacklisted'), value: <PromotionBlacklistedField user={user} /> },
    { id: 7, title: t('marketingBlacklisted'), value: <MarketingBlacklistedField user={user} /> },
    { id: 8, title: t('sportsBlacklisted'), value: <SportsBlackListed user={user} /> },
    { id: 9, title: t('playingBreak'), value: <PlayingBreakField user={user} /> },
    { id: 10, title: t('registeredOn'), value: <RegisteredOn user={user} /> },
    { id: 11, title: t('manualBlockedFromWithdrawals'), value: <ManuallyBlockedFromWithdrawalsField user={user} /> },
  ];

  return (
    <Card sx={{ pl: 0, pr: 0 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CardHeader title={t('userStatus')} />
      </Box>
      <CardContent sx={{ pl: 0, pr: 0 }}>
        {rows.length > 0 ? (
          <DataGridContainer>
            <DataGridPlain columns={headers} rows={rows} />
          </DataGridContainer>
        ) : (
          <NoData />
        )}
      </CardContent>
    </Card>
  );
};

export default UserStatus;
