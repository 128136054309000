import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ELanguagesSchema, ServiceContractSchema } from '@greenisland-api';
import { Box, Card, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';

import Select from '@greenisland-common/components/atoms/Select';

interface Props {
  serviceContract: ServiceContractSchema;
  open: boolean;
  onClose: () => void;
}

const ServiceContractsContentDialog = ({ serviceContract, open, onClose }: Props) => {
  const { t } = useTranslation();

  const nameLanguages: ELanguagesSchema[] = serviceContract.name
    .map(name => name.language)
    .filter((language): language is ELanguagesSchema => language !== undefined);
  const [selectedNameLanguage, setSelectedNameLanguage] = useState(nameLanguages[0]);

  const contentLanguages: ELanguagesSchema[] = serviceContract.latestVersion.content
    .map(content => content.language)
    .filter((language): language is ELanguagesSchema => language !== undefined);
  const [selectedContentLanguage, setSelectedContentLanguage] = useState(contentLanguages[0]);

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose} fullWidth>
      <Box>
        <Card>
          <DialogTitle>{t('serviceContracts.content.title')}</DialogTitle>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
          <DialogContent>
            {nameLanguages.length > 0 ? (
              <>
                <Typography fontWeight={500} variant="body1" fontSize="large" mb={1}>
                  {t('serviceContracts.content.contractName')}
                </Typography>
                <Box display="flex" gap={1} flexDirection="row" mb={2}>
                  <Select
                    id="nameLanguage"
                    label={t('serviceContracts.content.nameLanguage')}
                    value={selectedNameLanguage}
                    onChange={event => setSelectedNameLanguage(event.target.value as ELanguagesSchema)}
                    options={nameLanguages.map(language => language.toString())}
                    sx={{ maxWidth: '180px' }}
                  />
                  <Box alignSelf="center">
                    {serviceContract.name.find(name => name?.language === selectedNameLanguage)?.content}
                  </Box>
                </Box>
              </>
            ) : null}
            {contentLanguages.length > 0 ? (
              <>
                <Typography fontWeight={500} variant="body1" fontSize="large" mb={1}>
                  {t('serviceContracts.content.contractContent')}
                </Typography>
                <Box display="flex" gap={1} flexDirection="column">
                  <Select
                    id="contentLanguage"
                    label={t('serviceContracts.content.contentLanguage')}
                    value={selectedContentLanguage}
                    onChange={event => setSelectedContentLanguage(event.target.value as ELanguagesSchema)}
                    options={contentLanguages.map(language => language.toString())}
                  />
                  <iframe
                    src={
                      serviceContract.latestVersion.content.find(
                        content => content?.language === selectedContentLanguage
                      )?.content
                    }
                    title={t('serviceContracts.content.latestContract')}
                    height="500px"
                  ></iframe>
                </Box>
              </>
            ) : null}
          </DialogContent>
        </Card>
      </Box>
    </Dialog>
  );
};

export default ServiceContractsContentDialog;
