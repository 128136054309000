import { useCallback, useEffect, useMemo } from 'react';
import { ArrayField, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faAdd, faEuro, faTrash } from '@fortawesome/free-solid-svg-icons';
import { MonetaryTournamentPrizePoolDistributionEntry } from '@greenisland-store/gameServer';
import { Box, Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';
import CurrencyInput from '@greenisland-common/components/molecules/CurrentcyInput';
import Input from '@greenisland-common/components/molecules/Input';

import { parseFromCurrencyToNumberHandler, validatePrizeEntriesHandler } from '../helpers/tournamentConfigFormHelpers';
import { useGetTournamentPrizePoolValues } from '../helpers/tournamentConfigFormHooks';
import { TournamentConfigurationMode } from '../helpers/tournamentConfigFormInterfaces';

interface MonetaryPrizePoolTotalFieldProps {
  mode: TournamentConfigurationMode;
  fields: Partial<ArrayField<Record<string, any>, 'id'>>[];
}

const MonetaryPrizePoolTotalField = ({ mode, fields }: MonetaryPrizePoolTotalFieldProps) => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();

  const canModify = mode === TournamentConfigurationMode.ADD || mode === TournamentConfigurationMode.COPY;

  const watchMonetaryPrizePool = watch('prizePool.monetaryPrizePool');
  const watchMonetaryPrizePoolEntries: MonetaryTournamentPrizePoolDistributionEntry[] = watch(
    'prizePoolDistribution.monetaryPrizeEntries'
  );

  const calculateAmountHandler = useMemo(() => {
    if (canModify) {
      return watchMonetaryPrizePoolEntries?.reduce((acc, current) => {
        const amount = current?.amount || 0;

        const difference = (current.to ?? 0) - (current.from ?? 0) + 1;
        return acc + difference * amount;
      }, 0);
    }
  }, [canModify, watchMonetaryPrizePoolEntries]);

  useEffect(() => {
    if (calculateAmountHandler) {
      setValue('prizePool.monetaryPrizePool.amount', calculateAmountHandler * 100);
    }
  }, [calculateAmountHandler, setValue]);

  if (canModify && fields?.length && calculateAmountHandler) {
    return (
      <Box my={2}>
        <Typography fontSize="medium" variant="body2" fontWeight="bold">
          {`${t(
            'content.gameserver.tournamentconfiguration.form.prizepoolsettings.totalamount'
          )} ${calculateAmountHandler?.toFixed(2)} `}
          <FontAwesomeIcon sx={{ pr: 2 }} icon={faEuro} />
        </Typography>
        <Input type="hidden" control={control} name="prizePool.monetaryPrizePool.amount" sx={{ display: 'none' }} />
      </Box>
    );
  }

  if (mode === TournamentConfigurationMode.EDIT && watchMonetaryPrizePool?.amount && watchMonetaryPrizePool?.currency) {
    return (
      <Box my={2}>
        <Typography fontSize="medium" variant="body2" fontWeight="bold">
          {t('content.gameserver.tournamentconfiguration.form.prizepoolsettings.totalamount')}
          {` ${(watchMonetaryPrizePool?.amount / 100).toFixed(2)} `}
          <FontAwesomeIcon sx={{ pr: 2 }} icon={faEuro} />
        </Typography>
      </Box>
    );
  }

  return null;
};

interface Props {
  mode: TournamentConfigurationMode;
}

const TournamentConfigMonetaryPrizePoolSettings = ({ mode = TournamentConfigurationMode.ADD }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const prizePoolValues = useGetTournamentPrizePoolValues();

  const isCreateMode = mode === TournamentConfigurationMode.ADD;
  const canModify = mode === TournamentConfigurationMode.ADD || mode === TournamentConfigurationMode.COPY;

  const { control, watch, errors, setError, clearErrors } = useFormContext();

  const watchMonetaryPrizeEntries: MonetaryTournamentPrizePoolDistributionEntry[] = watch(
    'prizePoolDistribution.monetaryPrizeEntries'
  );
  const validateMonetaryPrizeEntries = validatePrizeEntriesHandler(watchMonetaryPrizeEntries);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prizePoolDistribution.monetaryPrizeEntries',
  });

  useEffect(() => {
    if (!validateMonetaryPrizeEntries) {
      setError('prizePoolDistribution.monetaryPrizeEntries', {
        type: 'custom',
        message: 'content.gameserver.tournamentconfiguration.form.prizepoolsettings.ascendingordererror',
      });
    } else {
      clearErrors('prizePoolDistribution.monetaryPrizeEntries');
    }
  }, [clearErrors, setError, validateMonetaryPrizeEntries]);

  const addPrizeHandler = useCallback(() => {
    return append({ from: 1, to: 1, amount: 1 });
  }, [append]);

  if (mode === TournamentConfigurationMode.EDIT && !watchMonetaryPrizeEntries?.length) {
    return null;
  }

  return (
    <Stack spacing={2} position="relative">
      <Card>
        <CardHeader
          title={`${prizePoolValues[0]?.label} ${t(
            'content.gameserver.tournamentconfiguration.form.prizepoolsettings.title'
          )}`}
        />
        {errors?.prizePoolDistribution?.monetaryPrizeEntries && (
          <Typography ml={2} variant="body2" fontSize="small" color={theme.palette.error.main}>
            {t(errors?.prizePoolDistribution?.monetaryPrizeEntries?.message)}
          </Typography>
        )}
        <CardContent>
          <>
            {fields?.map((entry, index) => {
              return (
                <Box
                  key={entry.id}
                  display="flex"
                  alignItems="center"
                  gap={2}
                  mb={2}
                  sx={{
                    [theme.breakpoints.up('xs')]: {
                      flexDirection: 'column',
                    },
                    [theme.breakpoints.up('md')]: {
                      flexDirection: 'row',
                    },
                  }}
                >
                  <Input
                    control={control}
                    size="small"
                    label={t('content.gameserver.tournamentconfiguration.form.prizepoolsettings.table.firstinrow')}
                    type="number"
                    name={`prizePoolDistribution.monetaryPrizeEntries[${index}].from`}
                    InputProps={{ inputProps: { min: 1 } }}
                    required
                    defaultValue={entry.from}
                    rules={{ required: t('fieldIsRequired'), valueAsNumber: true }}
                    sx={{
                      [theme.breakpoints.up('xs')]: {
                        width: '100%',
                      },
                      [theme.breakpoints.up('md')]: {
                        width: '20%',
                      },
                    }}
                    disabled={mode === TournamentConfigurationMode.EDIT}
                  />
                  <Input
                    control={control}
                    size="small"
                    label={t('content.gameserver.tournamentconfiguration.form.prizepoolsettings.table.lastinrow')}
                    type="number"
                    name={`prizePoolDistribution.monetaryPrizeEntries[${index}].to`}
                    required
                    defaultValue={entry.to}
                    InputProps={{ inputProps: { min: 1 } }}
                    rules={{ required: t('fieldIsRequired'), valueAsNumber: true }}
                    sx={{
                      [theme.breakpoints.up('xs')]: {
                        width: '100%',
                      },
                      [theme.breakpoints.up('md')]: {
                        width: '20%',
                      },
                    }}
                    disabled={mode === TournamentConfigurationMode.EDIT}
                  />
                  <CurrencyInput
                    control={control}
                    label={
                      isCreateMode || entry?.amount
                        ? t('content.gameserver.tournamentconfiguration.prizepooldistribution.cash')
                        : t('content.gameserver.tournamentconfiguration.prizepooldistribution.share')
                    }
                    name={`prizePoolDistribution.monetaryPrizeEntries[${index}].${entry?.amount ? 'amount' : 'share'}`}
                    defaultValue={entry?.amount ? entry?.amount : entry?.share}
                    adornmentIcon={isCreateMode || entry?.amount ? '€ ' : '% '}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    rules={{
                      required: t('fieldIsRequired'),
                      setValueAs: (value: any) => parseFromCurrencyToNumberHandler(value.toString()),
                    }}
                    required
                    disabled={mode === TournamentConfigurationMode.EDIT}
                    sx={{
                      [theme.breakpoints.up('xs')]: {
                        width: '100%',
                      },
                      [theme.breakpoints.up('md')]: {
                        width: '20%',
                      },
                    }}
                  />
                  {canModify && (
                    <FontAwesomeIcon
                      icon={faTrash}
                      sx={{ marginLeft: 1, color: 'error.main', cursor: 'pointer' }}
                      onClick={() => remove(index)}
                    />
                  )}
                </Box>
              );
            })}
            <MonetaryPrizePoolTotalField mode={mode} fields={fields} />
            {mode !== TournamentConfigurationMode.EDIT && (
              <Button
                onClick={addPrizeHandler}
                variant="contained"
                size="small"
                startIcon={<FontAwesomeIcon icon={faAdd} />}
              >
                {t('content.gameserver.tournamentconfiguration.form.prizepoolsettings.table.addrow')}
              </Button>
            )}
          </>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default TournamentConfigMonetaryPrizePoolSettings;
