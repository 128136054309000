import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faAdd, faPencil } from '@fortawesome/free-solid-svg-icons';
import { PromotionalCalendarSchema, SortOrderParamParameter, useGetPromotionalCalendars } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, LinearProgress, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColumns } from '@mui/x-data-grid-pro';

import {
  CheckboxIcon,
  DataGridContainer,
  DataGridPagination,
  FontAwesomeIcon,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';
import Select from '@greenisland-common/components/atoms/Select';

import { groupPromotionalCalendarByOrdinal } from './components/forms/helpers';
import { usePermission } from '../../../../app/hooks';

const PAGE_SIZE = 25;

type Row = PromotionalCalendarSchema;

const PromotionalCalendar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const canReadPromotionalCalendars = usePermission(OnlineCasinoPermissions.getPromotionalCalendars);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [sortOrder, setSortOrder] = useState<SortOrderParamParameter>(SortOrderParamParameter.descending);

  const {
    data: promotionalCalendars,
    isLoading,
    isError,
  } = useGetPromotionalCalendars(
    {
      page,
      pageSize,
      sortOrder,
    },
    {
      query: { enabled: canReadPromotionalCalendars },
    }
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'id',
        headerName: capitalize(t('id')),
        sortable: false,
        minWidth: 70,
        flex: 0.05,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value}</Typography>;
        },
      },
      {
        field: 'name',
        headerName: capitalize(t('marketing.promotionalCalendar.titles.calendarName')),
        sortable: false,
        minWidth: 120,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        field: 'calendarType',
        headerName: capitalize(t('marketing.promotionalCalendar.titles.calendarType')),
        sortable: false,
        minWidth: 120,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        field: 'minimalLoyaltyRankRequirement',
        headerName: capitalize(t('marketing.promotionalCalendar.titles.minimalLoyaltyRank')),
        sortable: false,
        minWidth: 120,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        field: '',
        headerName: capitalize(t('marketing.promotionalCalendar.titles.ordinals')),
        sortable: false,
        minWidth: 100,
        flex: 0.05,
        renderCell: ({ row }) => {
          const groupedByOrdinal = groupPromotionalCalendarByOrdinal(row);
          return <Typography variant="body2">{Object.keys(groupedByOrdinal)?.length || '-'}</Typography>;
        },
      },
      {
        field: 'active',
        headerName: capitalize(t('active')),
        renderCell: ({ value }) => <CheckboxIcon checked={value} />,
        sortable: false,
        minWidth: 100,
        flex: 0.05,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 120,
        flex: 0.05,
        headerName: capitalize(t('actions')),
        getActions: params => [
          <>
            <Tooltip title={t('edit')} key="details">
              <>
                <GridActionsCellItem
                  title={t('edit')}
                  label={t('edit')}
                  icon={<FontAwesomeIcon icon={faPencil} />}
                  onClick={() => params?.row?.id && navigate(`./edit/${params.row.id}`)}
                  key="edit"
                />
              </>
            </Tooltip>
          </>,
        ],
      },
    ],
    [navigate, t]
  );

  const rows = useMemo(
    () =>
      promotionalCalendars?.items?.map<Row>(promotionalCalendar => ({
        ...promotionalCalendar,
      })) ?? [],
    [promotionalCalendars?.items]
  );

  return (
    <Stack spacing={2}>
      <PermissionWrapper
        errorMessage={t('marketing.promotionalCalendar.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getPromotionalCalendars}
      >
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              [theme.breakpoints.up('xs')]: {
                flexDirection: 'column',
                alignItems: 'initial',
                gap: 2,
              },
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                alignItems: 'center',
                gap: 0,
              },
            }}
          >
            <Button
              onClick={() => navigate('./add')}
              variant="contained"
              size="medium"
              startIcon={<FontAwesomeIcon icon={faAdd} />}
              sx={{
                [theme.breakpoints.up('xs')]: {
                  mr: 0,
                },
                [theme.breakpoints.up('md')]: {
                  mr: 2,
                },
              }}
            >
              {t('marketing.promotionalCalendar.titles.addPromotionalCalendar')}
            </Button>
            <Select
              id={t('sortById')}
              label={capitalize(t('sortById'))}
              value={sortOrder}
              onChange={event => setSortOrder(event.target.value as SortOrderParamParameter)}
              options={Object.values(SortOrderParamParameter)}
            />
          </Box>
          <DataGridContainer>
            <LimitedDataGrid
              density="compact"
              autoHeight
              rowHeight={70}
              loading={isLoading}
              rows={rows}
              columns={columns}
              pagination
              page={page}
              rowCount={promotionalCalendars?.pagingDetails?.totalItems}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={(page: number) => {
                if (!isLoading) {
                  setPage(page);
                }
              }}
              onPageSizeChange={setPageSize}
              components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
        </>
      </PermissionWrapper>
    </Stack>
  );
};

export default PromotionalCalendar;
