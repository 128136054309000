/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  AccountingInvoiceSchema,
  AccountingSchema,
  BadRequestResponse,
  BadRequestUserResponse,
  Base64FileSchema,
  BasicInfoStatisticsSchema,
  BonusesActiveSchema,
  BonusesAwardedTodaySchema,
  BonusesBlacklistSchema,
  BonusesCashedPromosSchema,
  BonusRequestArgumentsSchema,
  BonusRequestsSchema,
  CasinoActivitySchema,
  CasinoPlayerActivitySchema,
  CasinoTransactionsSchema,
  DefaultResponse,
  EventStreamOverviewSchema,
  GameProviderGamesSchema,
  GameProvidersReportSchema,
  GamesPopularitySchema,
  GamesTodaySchema,
  GetAccountingInvoiceParams,
  GetAccountingParams,
  GetBonusesCashedPromosParams,
  GetCasinoTransactionsParams,
  GetDailyResultsParams,
  GetEventCountsParams,
  GetGameProviderGamesParams,
  GetGameProvidersReportParams,
  GetGamesPopularityParams,
  GetPagedHistoricEventstreamByEventnameParams,
  GetPagedHistoricEventstreamByStreamIdParams,
  GetPlayersActivityParams,
  GetPlayersRevenueContributorsParams,
  GetPlayerTopTransactionsParams,
  GetWeeklyResultsParams,
  LastActivitySchema,
  NotFoundResponse,
  PagedEventStreamSchema,
  PlayerOverviewSchema,
  PlayersActivitySchema,
  PlayersBlockedSchema,
  PreconditionFailedResponse,
  RevenueContributorsSchema,
  TopTransactionsSchema,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Returns the elapsed time since last update of the data
 */
export const useGetLastActivitySecondsHook = () => {
  const getLastActivitySeconds = useCustomInstance<LastActivitySchema>();

  return (signal?: AbortSignal) => {
    return getLastActivitySeconds({ url: `/reporting/lastactivityseconds`, method: 'get', signal });
  };
};

export const getGetLastActivitySecondsQueryKey = () => [`/reporting/lastactivityseconds`] as const;

export const useGetLastActivitySecondsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLastActivitySecondsHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLastActivitySecondsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLastActivitySecondsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLastActivitySecondsQueryKey();

  const getLastActivitySeconds = useGetLastActivitySecondsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLastActivitySecondsHook>>>> = ({ signal }) =>
    getLastActivitySeconds(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLastActivitySecondsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLastActivitySecondsHook>>>
>;
export type GetLastActivitySecondsQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Returns the elapsed time since last update of the data
 */
export const useGetLastActivitySeconds = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLastActivitySecondsHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLastActivitySecondsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetLastActivitySecondsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get basic info statistics; registrations, unique logins, active accounts, total bets
 */
export const useGetBasicInfoHook = () => {
  const getBasicInfo = useCustomInstance<BasicInfoStatisticsSchema>();

  return (signal?: AbortSignal) => {
    return getBasicInfo({ url: `/reporting/basicinfo`, method: 'get', signal });
  };
};

export const getGetBasicInfoQueryKey = () => [`/reporting/basicinfo`] as const;

export const useGetBasicInfoQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBasicInfoHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBasicInfoHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBasicInfoHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBasicInfoQueryKey();

  const getBasicInfo = useGetBasicInfoHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBasicInfoHook>>>> = ({ signal }) =>
    getBasicInfo(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetBasicInfoQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetBasicInfoHook>>>>;
export type GetBasicInfoQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get basic info statistics; registrations, unique logins, active accounts, total bets
 */
export const useGetBasicInfo = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBasicInfoHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBasicInfoHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBasicInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Active bonuses
 */
export const useGetBonusesActiveHook = () => {
  const getBonusesActive = useCustomInstance<BonusesActiveSchema>();

  return (signal?: AbortSignal) => {
    return getBonusesActive({ url: `/reporting/bonuses/active`, method: 'get', signal });
  };
};

export const getGetBonusesActiveQueryKey = () => [`/reporting/bonuses/active`] as const;

export const useGetBonusesActiveQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBonusesActiveHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBonusesActiveHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBonusesActiveHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBonusesActiveQueryKey();

  const getBonusesActive = useGetBonusesActiveHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBonusesActiveHook>>>> = ({ signal }) =>
    getBonusesActive(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetBonusesActiveQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetBonusesActiveHook>>>>;
export type GetBonusesActiveQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @summary Get Active bonuses
 */
export const useGetBonusesActive = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBonusesActiveHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBonusesActiveHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBonusesActiveQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get bonuses awarded today
 */
export const useGetBonusesAwardedTodayHook = () => {
  const getBonusesAwardedToday = useCustomInstance<BonusesAwardedTodaySchema>();

  return (signal?: AbortSignal) => {
    return getBonusesAwardedToday({ url: `/reporting/bonuses/awardedtoday`, method: 'get', signal });
  };
};

export const getGetBonusesAwardedTodayQueryKey = () => [`/reporting/bonuses/awardedtoday`] as const;

export const useGetBonusesAwardedTodayQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBonusesAwardedTodayHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBonusesAwardedTodayHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBonusesAwardedTodayHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBonusesAwardedTodayQueryKey();

  const getBonusesAwardedToday = useGetBonusesAwardedTodayHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBonusesAwardedTodayHook>>>> = ({ signal }) =>
    getBonusesAwardedToday(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetBonusesAwardedTodayQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBonusesAwardedTodayHook>>>
>;
export type GetBonusesAwardedTodayQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @summary Get bonuses awarded today
 */
export const useGetBonusesAwardedToday = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBonusesAwardedTodayHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBonusesAwardedTodayHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBonusesAwardedTodayQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get bonuses blacklist
 */
export const useGetBonusesBlacklistHook = () => {
  const getBonusesBlacklist = useCustomInstance<BonusesBlacklistSchema>();

  return (signal?: AbortSignal) => {
    return getBonusesBlacklist({ url: `/reporting/bonuses/blacklist`, method: 'get', signal });
  };
};

export const getGetBonusesBlacklistQueryKey = () => [`/reporting/bonuses/blacklist`] as const;

export const useGetBonusesBlacklistQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBonusesBlacklistHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBonusesBlacklistHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBonusesBlacklistHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBonusesBlacklistQueryKey();

  const getBonusesBlacklist = useGetBonusesBlacklistHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBonusesBlacklistHook>>>> = ({ signal }) =>
    getBonusesBlacklist(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetBonusesBlacklistQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBonusesBlacklistHook>>>
>;
export type GetBonusesBlacklistQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @summary Get bonuses blacklist
 */
export const useGetBonusesBlacklist = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBonusesBlacklistHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBonusesBlacklistHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBonusesBlacklistQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get bonuses cashed promos
 */
export const useGetBonusesCashedPromosHook = () => {
  const getBonusesCashedPromos = useCustomInstance<BonusesCashedPromosSchema>();

  return (params: GetBonusesCashedPromosParams, signal?: AbortSignal) => {
    return getBonusesCashedPromos({ url: `/reporting/bonuses/cashedpromos`, method: 'get', params, signal });
  };
};

export const getGetBonusesCashedPromosQueryKey = (params: GetBonusesCashedPromosParams) =>
  [`/reporting/bonuses/cashedpromos`, ...(params ? [params] : [])] as const;

export const useGetBonusesCashedPromosQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBonusesCashedPromosHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(
  params: GetBonusesCashedPromosParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBonusesCashedPromosHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBonusesCashedPromosHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBonusesCashedPromosQueryKey(params);

  const getBonusesCashedPromos = useGetBonusesCashedPromosHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBonusesCashedPromosHook>>>> = ({ signal }) =>
    getBonusesCashedPromos(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetBonusesCashedPromosQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBonusesCashedPromosHook>>>
>;
export type GetBonusesCashedPromosQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @summary Get bonuses cashed promos
 */
export const useGetBonusesCashedPromos = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBonusesCashedPromosHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(
  params: GetBonusesCashedPromosParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBonusesCashedPromosHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBonusesCashedPromosQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get all casino activity, to show the historical revenue
 */
export const useGetActivityHook = () => {
  const getActivity = useCustomInstance<CasinoActivitySchema>();

  return (signal?: AbortSignal) => {
    return getActivity({ url: `/reporting/casinoactivity`, method: 'get', signal });
  };
};

export const getGetActivityQueryKey = () => [`/reporting/casinoactivity`] as const;

export const useGetActivityQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActivityHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActivityHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActivityHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActivityQueryKey();

  const getActivity = useGetActivityHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetActivityHook>>>> = ({ signal }) =>
    getActivity(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetActivityQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetActivityHook>>>>;
export type GetActivityQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get all casino activity, to show the historical revenue
 */
export const useGetActivity = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActivityHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActivityHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetActivityQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get all casino player activity, to show the historical revenue
 */
export const useGetPlayerActivityHook = () => {
  const getPlayerActivity = useCustomInstance<CasinoPlayerActivitySchema>();

  return (signal?: AbortSignal) => {
    return getPlayerActivity({ url: `/reporting/casinoplayeractivity`, method: 'get', signal });
  };
};

export const getGetPlayerActivityQueryKey = () => [`/reporting/casinoplayeractivity`] as const;

export const useGetPlayerActivityQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayerActivityHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayerActivityHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayerActivityHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayerActivityQueryKey();

  const getPlayerActivity = useGetPlayerActivityHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPlayerActivityHook>>>> = ({ signal }) =>
    getPlayerActivity(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPlayerActivityQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPlayerActivityHook>>>
>;
export type GetPlayerActivityQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get all casino player activity, to show the historical revenue
 */
export const useGetPlayerActivity = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayerActivityHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayerActivityHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPlayerActivityQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary Get cashflow, refunds (also test users) and games (also test users) activity
 */
export const useGetAccountingHook = () => {
  const getAccounting = useCustomInstance<AccountingSchema>();

  return (params: GetAccountingParams, signal?: AbortSignal) => {
    return getAccounting({ url: `/reporting/financial/accounting`, method: 'get', params, signal });
  };
};

export const getGetAccountingQueryKey = (params: GetAccountingParams) =>
  [`/reporting/financial/accounting`, ...(params ? [params] : [])] as const;

export const useGetAccountingQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAccountingHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetAccountingParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountingHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountingHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccountingQueryKey(params);

  const getAccounting = useGetAccountingHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAccountingHook>>>> = ({ signal }) =>
    getAccounting(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAccountingQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAccountingHook>>>>;
export type GetAccountingQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @deprecated
 * @summary Get cashflow, refunds (also test users) and games (also test users) activity
 */
export const useGetAccounting = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAccountingHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetAccountingParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountingHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAccountingQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary Get financial accounting invoice
 */
export const useGetAccountingInvoiceHook = () => {
  const getAccountingInvoice = useCustomInstance<AccountingInvoiceSchema>();

  return (params: GetAccountingInvoiceParams, signal?: AbortSignal) => {
    return getAccountingInvoice({ url: `/reporting/financial/accounting/invoices`, method: 'get', params, signal });
  };
};

export const getGetAccountingInvoiceQueryKey = (params: GetAccountingInvoiceParams) =>
  [`/reporting/financial/accounting/invoices`, ...(params ? [params] : [])] as const;

export const useGetAccountingInvoiceQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAccountingInvoiceHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetAccountingInvoiceParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountingInvoiceHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountingInvoiceHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccountingInvoiceQueryKey(params);

  const getAccountingInvoice = useGetAccountingInvoiceHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAccountingInvoiceHook>>>> = ({ signal }) =>
    getAccountingInvoice(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAccountingInvoiceQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAccountingInvoiceHook>>>
>;
export type GetAccountingInvoiceQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary Get financial accounting invoice
 */
export const useGetAccountingInvoice = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAccountingInvoiceHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetAccountingInvoiceParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountingInvoiceHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAccountingInvoiceQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get reporting on casino transactions
 */
export const useGetCasinoTransactionsHook = () => {
  const getCasinoTransactions = useCustomInstance<CasinoTransactionsSchema>();

  return (params?: GetCasinoTransactionsParams, signal?: AbortSignal) => {
    return getCasinoTransactions({ url: `/reporting/financial/casinotransactions`, method: 'get', params, signal });
  };
};

export const getGetCasinoTransactionsQueryKey = (params?: GetCasinoTransactionsParams) =>
  [`/reporting/financial/casinotransactions`, ...(params ? [params] : [])] as const;

export const useGetCasinoTransactionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCasinoTransactionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetCasinoTransactionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCasinoTransactionsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCasinoTransactionsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCasinoTransactionsQueryKey(params);

  const getCasinoTransactions = useGetCasinoTransactionsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCasinoTransactionsHook>>>> = ({ signal }) =>
    getCasinoTransactions(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetCasinoTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCasinoTransactionsHook>>>
>;
export type GetCasinoTransactionsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get reporting on casino transactions
 */
export const useGetCasinoTransactions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCasinoTransactionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetCasinoTransactionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCasinoTransactionsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCasinoTransactionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get reporting on game providers
 */
export const useGetGameProvidersReportHook = () => {
  const getGameProvidersReport = useCustomInstance<GameProvidersReportSchema>();

  return (params?: GetGameProvidersReportParams, signal?: AbortSignal) => {
    return getGameProvidersReport({ url: `/reporting/financial/gameproviders`, method: 'get', params, signal });
  };
};

export const getGetGameProvidersReportQueryKey = (params?: GetGameProvidersReportParams) =>
  [`/reporting/financial/gameproviders`, ...(params ? [params] : [])] as const;

export const useGetGameProvidersReportQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameProvidersReportHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetGameProvidersReportParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameProvidersReportHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameProvidersReportHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameProvidersReportQueryKey(params);

  const getGameProvidersReport = useGetGameProvidersReportHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameProvidersReportHook>>>> = ({ signal }) =>
    getGameProvidersReport(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetGameProvidersReportQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGameProvidersReportHook>>>
>;
export type GetGameProvidersReportQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get reporting on game providers
 */
export const useGetGameProvidersReport = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameProvidersReportHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetGameProvidersReportParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameProvidersReportHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameProvidersReportQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get reporting details on the games for a single game provider
 */
export const useGetGameProviderGamesHook = () => {
  const getGameProviderGames = useCustomInstance<GameProviderGamesSchema>();

  return (gameProvider: string, params?: GetGameProviderGamesParams, signal?: AbortSignal) => {
    return getGameProviderGames({
      url: `/reporting/financial/gameproviders/${gameProvider}`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getGetGameProviderGamesQueryKey = (gameProvider: string, params?: GetGameProviderGamesParams) =>
  [`/reporting/financial/gameproviders/${gameProvider}`, ...(params ? [params] : [])] as const;

export const useGetGameProviderGamesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameProviderGamesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  gameProvider: string,
  params?: GetGameProviderGamesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameProviderGamesHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameProviderGamesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameProviderGamesQueryKey(gameProvider, params);

  const getGameProviderGames = useGetGameProviderGamesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameProviderGamesHook>>>> = ({ signal }) =>
    getGameProviderGames(gameProvider, params, signal);

  return { queryKey, queryFn, enabled: !!gameProvider, ...queryOptions };
};

export type GetGameProviderGamesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGameProviderGamesHook>>>
>;
export type GetGameProviderGamesQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get reporting details on the games for a single game provider
 */
export const useGetGameProviderGames = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameProviderGamesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  gameProvider: string,
  params?: GetGameProviderGamesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameProviderGamesHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameProviderGamesQueryOptions(gameProvider, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get reporting details on deposits, withdrawals, bets, wins, and other data by day.
 */
export const useGetDailyResultsHook = () => {
  const getDailyResults = useCustomInstance<Base64FileSchema>();

  return (params?: GetDailyResultsParams, signal?: AbortSignal) => {
    return getDailyResults({ url: `/reporting/financial/dailyresults`, method: 'get', params, signal });
  };
};

export const getGetDailyResultsQueryKey = (params?: GetDailyResultsParams) =>
  [`/reporting/financial/dailyresults`, ...(params ? [params] : [])] as const;

export const useGetDailyResultsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDailyResultsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetDailyResultsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDailyResultsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDailyResultsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDailyResultsQueryKey(params);

  const getDailyResults = useGetDailyResultsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDailyResultsHook>>>> = ({ signal }) =>
    getDailyResults(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDailyResultsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDailyResultsHook>>>>;
export type GetDailyResultsQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get reporting details on deposits, withdrawals, bets, wins, and other data by day.
 */
export const useGetDailyResults = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDailyResultsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetDailyResultsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDailyResultsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDailyResultsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get reporting details on deposits, withdrawals, bets, wins, and other data by week.
 */
export const useGetWeeklyResultsHook = () => {
  const getWeeklyResults = useCustomInstance<Base64FileSchema>();

  return (params?: GetWeeklyResultsParams, signal?: AbortSignal) => {
    return getWeeklyResults({ url: `/reporting/financial/weeklyresults`, method: 'get', params, signal });
  };
};

export const getGetWeeklyResultsQueryKey = (params?: GetWeeklyResultsParams) =>
  [`/reporting/financial/weeklyresults`, ...(params ? [params] : [])] as const;

export const useGetWeeklyResultsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWeeklyResultsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetWeeklyResultsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWeeklyResultsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWeeklyResultsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWeeklyResultsQueryKey(params);

  const getWeeklyResults = useGetWeeklyResultsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWeeklyResultsHook>>>> = ({ signal }) =>
    getWeeklyResults(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetWeeklyResultsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetWeeklyResultsHook>>>>;
export type GetWeeklyResultsQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get reporting details on deposits, withdrawals, bets, wins, and other data by week.
 */
export const useGetWeeklyResults = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWeeklyResultsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetWeeklyResultsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWeeklyResultsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWeeklyResultsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get reporting on the game popularity
 */
export const useGetGamesPopularityHook = () => {
  const getGamesPopularity = useCustomInstance<GamesPopularitySchema>();

  return (params?: GetGamesPopularityParams, signal?: AbortSignal) => {
    return getGamesPopularity({ url: `/reporting/games/popular`, method: 'get', params, signal });
  };
};

export const getGetGamesPopularityQueryKey = (params?: GetGamesPopularityParams) =>
  [`/reporting/games/popular`, ...(params ? [params] : [])] as const;

export const useGetGamesPopularityQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGamesPopularityHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetGamesPopularityParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGamesPopularityHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGamesPopularityHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGamesPopularityQueryKey(params);

  const getGamesPopularity = useGetGamesPopularityHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGamesPopularityHook>>>> = ({ signal }) =>
    getGamesPopularity(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetGamesPopularityQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGamesPopularityHook>>>
>;
export type GetGamesPopularityQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get reporting on the game popularity
 */
export const useGetGamesPopularity = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGamesPopularityHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetGamesPopularityParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGamesPopularityHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGamesPopularityQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get reporting on the games played today
 */
export const useGetGamesTodayHook = () => {
  const getGamesToday = useCustomInstance<GamesTodaySchema>();

  return (signal?: AbortSignal) => {
    return getGamesToday({ url: `/reporting/games/today`, method: 'get', signal });
  };
};

export const getGetGamesTodayQueryKey = () => [`/reporting/games/today`] as const;

export const useGetGamesTodayQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGamesTodayHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGamesTodayHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGamesTodayHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGamesTodayQueryKey();

  const getGamesToday = useGetGamesTodayHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGamesTodayHook>>>> = ({ signal }) =>
    getGamesToday(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetGamesTodayQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetGamesTodayHook>>>>;
export type GetGamesTodayQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get reporting on the games played today
 */
export const useGetGamesToday = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGamesTodayHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGamesTodayHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGamesTodayQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the top transactions per player
 */
export const useGetPlayerTopTransactionsHook = () => {
  const getPlayerTopTransactions = useCustomInstance<TopTransactionsSchema>();

  return (params?: GetPlayerTopTransactionsParams, signal?: AbortSignal) => {
    return getPlayerTopTransactions({ url: `/reporting/player/toptransactions`, method: 'get', params, signal });
  };
};

export const getGetPlayerTopTransactionsQueryKey = (params?: GetPlayerTopTransactionsParams) =>
  [`/reporting/player/toptransactions`, ...(params ? [params] : [])] as const;

export const useGetPlayerTopTransactionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayerTopTransactionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetPlayerTopTransactionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayerTopTransactionsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayerTopTransactionsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayerTopTransactionsQueryKey(params);

  const getPlayerTopTransactions = useGetPlayerTopTransactionsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPlayerTopTransactionsHook>>>> = ({
    signal,
  }) => getPlayerTopTransactions(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPlayerTopTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPlayerTopTransactionsHook>>>
>;
export type GetPlayerTopTransactionsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the top transactions per player
 */
export const useGetPlayerTopTransactions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayerTopTransactionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetPlayerTopTransactionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayerTopTransactionsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPlayerTopTransactionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get reporting on blocked player
 */
export const useGetPlayersBlockedHook = () => {
  const getPlayersBlocked = useCustomInstance<PlayersBlockedSchema>();

  return (signal?: AbortSignal) => {
    return getPlayersBlocked({ url: `/reporting/player/blocked`, method: 'get', signal });
  };
};

export const getGetPlayersBlockedQueryKey = () => [`/reporting/player/blocked`] as const;

export const useGetPlayersBlockedQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayersBlockedHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayersBlockedHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayersBlockedHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayersBlockedQueryKey();

  const getPlayersBlocked = useGetPlayersBlockedHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPlayersBlockedHook>>>> = ({ signal }) =>
    getPlayersBlocked(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPlayersBlockedQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPlayersBlockedHook>>>
>;
export type GetPlayersBlockedQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get reporting on blocked player
 */
export const useGetPlayersBlocked = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayersBlockedHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayersBlockedHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPlayersBlockedQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get reporting on the player activity
 */
export const useGetPlayersActivityHook = () => {
  const getPlayersActivity = useCustomInstance<PlayersActivitySchema>();

  return (params?: GetPlayersActivityParams, signal?: AbortSignal) => {
    return getPlayersActivity({ url: `/reporting/player/playeractivity`, method: 'get', params, signal });
  };
};

export const getGetPlayersActivityQueryKey = (params?: GetPlayersActivityParams) =>
  [`/reporting/player/playeractivity`, ...(params ? [params] : [])] as const;

export const useGetPlayersActivityQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayersActivityHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetPlayersActivityParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayersActivityHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayersActivityHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayersActivityQueryKey(params);

  const getPlayersActivity = useGetPlayersActivityHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPlayersActivityHook>>>> = ({ signal }) =>
    getPlayersActivity(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPlayersActivityQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPlayersActivityHook>>>
>;
export type GetPlayersActivityQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get reporting on the player activity
 */
export const useGetPlayersActivity = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayersActivityHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetPlayersActivityParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayersActivityHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPlayersActivityQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get reporting on the player activity
 */
export const useGetPlayersRevenueContributorsHook = () => {
  const getPlayersRevenueContributors = useCustomInstance<RevenueContributorsSchema>();

  return (params?: GetPlayersRevenueContributorsParams, signal?: AbortSignal) => {
    return getPlayersRevenueContributors({
      url: `/reporting/player/revenuecontributors`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getGetPlayersRevenueContributorsQueryKey = (params?: GetPlayersRevenueContributorsParams) =>
  [`/reporting/player/revenuecontributors`, ...(params ? [params] : [])] as const;

export const useGetPlayersRevenueContributorsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayersRevenueContributorsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetPlayersRevenueContributorsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetPlayersRevenueContributorsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayersRevenueContributorsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayersRevenueContributorsQueryKey(params);

  const getPlayersRevenueContributors = useGetPlayersRevenueContributorsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPlayersRevenueContributorsHook>>>> = ({
    signal,
  }) => getPlayersRevenueContributors(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPlayersRevenueContributorsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPlayersRevenueContributorsHook>>>
>;
export type GetPlayersRevenueContributorsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get reporting on the player activity
 */
export const useGetPlayersRevenueContributors = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayersRevenueContributorsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetPlayersRevenueContributorsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetPlayersRevenueContributorsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPlayersRevenueContributorsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary Get reporting on the player activity
 */
export const useGetPlayersRevenueContributorsBonusRequestsHook = () => {
  const getPlayersRevenueContributorsBonusRequests = useCustomInstance<BonusRequestsSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getPlayersRevenueContributorsBonusRequests({
      url: `/reporting/player/revenuecontributors/${userId}/bonusrequests`,
      method: 'get',
      signal,
    });
  };
};

export const getGetPlayersRevenueContributorsBonusRequestsQueryKey = (userId: string) =>
  [`/reporting/player/revenuecontributors/${userId}/bonusrequests`] as const;

export const useGetPlayersRevenueContributorsBonusRequestsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayersRevenueContributorsBonusRequestsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetPlayersRevenueContributorsBonusRequestsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetPlayersRevenueContributorsBonusRequestsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayersRevenueContributorsBonusRequestsQueryKey(userId);

  const getPlayersRevenueContributorsBonusRequests = useGetPlayersRevenueContributorsBonusRequestsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetPlayersRevenueContributorsBonusRequestsHook>>>
  > = ({ signal }) => getPlayersRevenueContributorsBonusRequests(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetPlayersRevenueContributorsBonusRequestsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPlayersRevenueContributorsBonusRequestsHook>>>
>;
export type GetPlayersRevenueContributorsBonusRequestsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary Get reporting on the player activity
 */
export const useGetPlayersRevenueContributorsBonusRequests = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayersRevenueContributorsBonusRequestsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetPlayersRevenueContributorsBonusRequestsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPlayersRevenueContributorsBonusRequestsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 * @summary award a bonus
 */
export const useAwardPlayersRevenueContributorsBonusRequestHook = () => {
  const awardPlayersRevenueContributorsBonusRequest = useCustomInstance<BonusRequestsSchema>();

  return (userId: string, answeredQuestionId: string, bonusRequestArgumentsSchema: BonusRequestArgumentsSchema) => {
    return awardPlayersRevenueContributorsBonusRequest({
      url: `/reporting/player/revenuecontributors/${userId}/bonusrequests/${answeredQuestionId}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: bonusRequestArgumentsSchema,
    });
  };
};

export const useAwardPlayersRevenueContributorsBonusRequestMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAwardPlayersRevenueContributorsBonusRequestHook>>>,
    TError,
    { userId: string; answeredQuestionId: string; data: BonusRequestArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAwardPlayersRevenueContributorsBonusRequestHook>>>,
  TError,
  { userId: string; answeredQuestionId: string; data: BonusRequestArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const awardPlayersRevenueContributorsBonusRequest = useAwardPlayersRevenueContributorsBonusRequestHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAwardPlayersRevenueContributorsBonusRequestHook>>>,
    { userId: string; answeredQuestionId: string; data: BonusRequestArgumentsSchema }
  > = props => {
    const { userId, answeredQuestionId, data } = props ?? {};

    return awardPlayersRevenueContributorsBonusRequest(userId, answeredQuestionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AwardPlayersRevenueContributorsBonusRequestMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAwardPlayersRevenueContributorsBonusRequestHook>>>
>;
export type AwardPlayersRevenueContributorsBonusRequestMutationBody = BonusRequestArgumentsSchema;
export type AwardPlayersRevenueContributorsBonusRequestMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary award a bonus
 */
export const useAwardPlayersRevenueContributorsBonusRequest = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAwardPlayersRevenueContributorsBonusRequestHook>>>,
    TError,
    { userId: string; answeredQuestionId: string; data: BonusRequestArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAwardPlayersRevenueContributorsBonusRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get reporting on the player overview
 */
export const useGetPlayerOverviewHook = () => {
  const getPlayerOverview = useCustomInstance<PlayerOverviewSchema>();

  return (signal?: AbortSignal) => {
    return getPlayerOverview({ url: `/reporting/player/overview`, method: 'get', signal });
  };
};

export const getGetPlayerOverviewQueryKey = () => [`/reporting/player/overview`] as const;

export const useGetPlayerOverviewQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayerOverviewHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayerOverviewHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayerOverviewHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayerOverviewQueryKey();

  const getPlayerOverview = useGetPlayerOverviewHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPlayerOverviewHook>>>> = ({ signal }) =>
    getPlayerOverview(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPlayerOverviewQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPlayerOverviewHook>>>
>;
export type GetPlayerOverviewQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get reporting on the player overview
 */
export const useGetPlayerOverview = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPlayerOverviewHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPlayerOverviewHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPlayerOverviewQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns the historic eventstream
 * @summary Get historic eventstream
 */
export const useGetEventCountsHook = () => {
  const getEventCounts = useCustomInstance<EventStreamOverviewSchema>();

  return (params?: GetEventCountsParams, signal?: AbortSignal) => {
    return getEventCounts({ url: `/reporting/events`, method: 'get', params, signal });
  };
};

export const getGetEventCountsQueryKey = (params?: GetEventCountsParams) =>
  [`/reporting/events`, ...(params ? [params] : [])] as const;

export const useGetEventCountsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetEventCountsHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetEventCountsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEventCountsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEventCountsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEventCountsQueryKey(params);

  const getEventCounts = useGetEventCountsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetEventCountsHook>>>> = ({ signal }) =>
    getEventCounts(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetEventCountsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetEventCountsHook>>>>;
export type GetEventCountsQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get historic eventstream
 */
export const useGetEventCounts = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetEventCountsHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetEventCountsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEventCountsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetEventCountsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns the historic eventstream based on a eventname
 * @summary Get historic eventstream
 */
export const useGetPagedHistoricEventstreamByEventnameHook = () => {
  const getPagedHistoricEventstreamByEventname = useCustomInstance<PagedEventStreamSchema>();

  return (params: GetPagedHistoricEventstreamByEventnameParams, signal?: AbortSignal) => {
    return getPagedHistoricEventstreamByEventname({
      url: `/reporting/events/eventname`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getGetPagedHistoricEventstreamByEventnameQueryKey = (
  params: GetPagedHistoricEventstreamByEventnameParams
) => [`/reporting/events/eventname`, ...(params ? [params] : [])] as const;

export const useGetPagedHistoricEventstreamByEventnameQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPagedHistoricEventstreamByEventnameHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetPagedHistoricEventstreamByEventnameParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetPagedHistoricEventstreamByEventnameHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetPagedHistoricEventstreamByEventnameHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPagedHistoricEventstreamByEventnameQueryKey(params);

  const getPagedHistoricEventstreamByEventname = useGetPagedHistoricEventstreamByEventnameHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetPagedHistoricEventstreamByEventnameHook>>>
  > = ({ signal }) => getPagedHistoricEventstreamByEventname(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPagedHistoricEventstreamByEventnameQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPagedHistoricEventstreamByEventnameHook>>>
>;
export type GetPagedHistoricEventstreamByEventnameQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get historic eventstream
 */
export const useGetPagedHistoricEventstreamByEventname = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPagedHistoricEventstreamByEventnameHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetPagedHistoricEventstreamByEventnameParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetPagedHistoricEventstreamByEventnameHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPagedHistoricEventstreamByEventnameQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Returns the historic eventstream based on a streamId
 * @summary Get historic eventstream
 */
export const useGetPagedHistoricEventstreamByStreamIdHook = () => {
  const getPagedHistoricEventstreamByStreamId = useCustomInstance<PagedEventStreamSchema>();

  return (params: GetPagedHistoricEventstreamByStreamIdParams, signal?: AbortSignal) => {
    return getPagedHistoricEventstreamByStreamId({ url: `/reporting/events/streamid`, method: 'get', params, signal });
  };
};

export const getGetPagedHistoricEventstreamByStreamIdQueryKey = (params: GetPagedHistoricEventstreamByStreamIdParams) =>
  [`/reporting/events/streamid`, ...(params ? [params] : [])] as const;

export const useGetPagedHistoricEventstreamByStreamIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPagedHistoricEventstreamByStreamIdHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetPagedHistoricEventstreamByStreamIdParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetPagedHistoricEventstreamByStreamIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetPagedHistoricEventstreamByStreamIdHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPagedHistoricEventstreamByStreamIdQueryKey(params);

  const getPagedHistoricEventstreamByStreamId = useGetPagedHistoricEventstreamByStreamIdHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetPagedHistoricEventstreamByStreamIdHook>>>
  > = ({ signal }) => getPagedHistoricEventstreamByStreamId(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPagedHistoricEventstreamByStreamIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPagedHistoricEventstreamByStreamIdHook>>>
>;
export type GetPagedHistoricEventstreamByStreamIdQueryError =
  | BadRequestUserResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get historic eventstream
 */
export const useGetPagedHistoricEventstreamByStreamId = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPagedHistoricEventstreamByStreamIdHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetPagedHistoricEventstreamByStreamIdParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetPagedHistoricEventstreamByStreamIdHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPagedHistoricEventstreamByStreamIdQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
