import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faSearchDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  EWithdrawalTypeSchema,
  getGetRequestedWithdrawalsV2QueryKey,
  useGetRequestedWithdrawalsV2,
  useProcessWithdrawalsV2,
  WithdrawalsSchemaItem,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Stack } from '@mui/material';
import { DataGridProProps, GridRowHeightParams, GridRowParams, GridSelectionModel } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { DataGridContainer, Link, StyledDataGrid } from '@greenisland-common/components/atoms';

import useColumnOrderPreference from './hooks/useColumnOrderPreference';
import useGetGeneralWithdrawalHeaders from './hooks/useGetGeneralWithdrawalHeaders';
import GlobalFilter, { useGlobalFilters } from './WithdrawTable/GlobalFilter';
import WithdrawTableToolbar from './WithdrawTable/WithdrawTableToolbar';
import { usePermission } from '../../../../app/hooks';
import FlagDataDetails from '../../Lookup/Withdrawals/FlagDataDetails';
import { WithdrawalsContext } from './Withdrawals';

export type Row = WithdrawalsSchemaItem & { id: string | undefined };

const GeneralWithdrawal = ({ destinationName }: { destinationName: EWithdrawalTypeSchema }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);
  const canReadWithdrawalsExports = usePermission(OnlineCasinoPermissions.getWithdrawalsExports);
  const canApproveWithdrawals = usePermission(OnlineCasinoPermissions.exportWithdrawals);
  const canReadRequestedWithdrawals = usePermission(OnlineCasinoPermissions.getRequestedWithdrawalsV2);

  const { setIsBlocking } = useContext(WithdrawalsContext);
  const { enqueueSnackbar } = useSnackbar();
  const { columnPreferences, setColumnPreferences, columnOrderPreferences, orderDefault, orderCasino } =
    useColumnOrderPreference(destinationName);

  const { mutate: processWithdrawals } = useProcessWithdrawalsV2({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetRequestedWithdrawalsV2QueryKey(destinationName));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const [selectedRows, setSelectedRows] = useState<GridSelectionModel>([]);
  const { filters, validate, onChange } = useGlobalFilters();
  const { data: withdrawals, isLoading: isFetching } = useGetRequestedWithdrawalsV2(destinationName, {
    query: {
      enabled: canReadRequestedWithdrawals,
      onError: () => {
        enqueueSnackbar(t('withdrawals.error.requested'), { variant: 'error' });
      },
    },
  });

  const rows = useMemo(() => {
    return (
      withdrawals
        ?.map<Row>(withdrawal => ({ ...withdrawal, id: withdrawal.withdrawalId }))
        .filter((row: Row) => validate(row)) ?? []
    );
  }, [withdrawals, validate]);

  const { columns } = useGetGeneralWithdrawalHeaders(
    withdrawals ?? [],
    destinationName,
    columnOrderPreferences,
    orderDefault,
    orderCasino
  );

  const handleSelectionChange = useCallback(
    (selected: GridSelectionModel) => {
      if (destinationName === EWithdrawalTypeSchema.bankaccount && selected.length >= 500) {
        alert(t('moreThan500Bankaccount'));
      } else {
        if (selectedRows.length === 0 && selected.length > 0) setIsBlocking(true);
        if (selected.length === 0) setIsBlocking(false);
        setSelectedRows(selected);
      }
    },
    [destinationName, selectedRows.length, setIsBlocking, t]
  );

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback<
    (params: GridRowParams<Row>) => React.ReactNode
  >(({ row }) => <FlagDataDetails flagData={row.flagData} />, []);

  return (
    <>
      <Stack spacing={2}>
        <GlobalFilter filters={filters} onChange={onChange} />
        {canReadWithdrawalsExports ? (
          <Box>
            <Link to="../exports" underline="none">
              <Button startIcon={<FontAwesomeIcon icon={faSearchDollar} />}>{t('previousExports')}</Button>
            </Link>
          </Box>
        ) : null}
        <DataGridContainer sx={{ height: 'calc(100vh - 400px)', minHeight: 1000 }}>
          <StyledDataGrid
            getRowHeight={(rowHeight: GridRowHeightParams) => {
              if (rowHeight.model.flagData?.data?.length >= 2)
                return 52 + 20 * (rowHeight.model.flagData?.data?.length - 2);
              return 52; // default value
            }}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={useCallback((): 'auto' => 'auto', [])}
            initialState={{ columns: { columnVisibilityModel: { __detail_panel_toggle__: false } } }}
            density="comfortable"
            loading={isFetching}
            rows={rows}
            columns={columns}
            checkboxSelection
            onSelectionModelChange={handleSelectionChange}
            showColumnRightBorder={false}
            selectionModel={selectedRows}
            disableDensitySelector
            disableSelectionOnClick
            editMode="row"
            columnVisibilityModel={columnPreferences}
            onColumnVisibilityModelChange={newModel => setColumnPreferences(newModel)}
            disableColumnReorder
            disableColumnPinning
            components={{
              Toolbar: WithdrawTableToolbar,
            }}
            componentsProps={{
              panel: {
                anchorEl: filterButtonEl,
              },
              toolbar: {
                setFilterButtonEl,
                destination: destinationName,
              },
            }}
            sx={{
              '& [data-field="actions"]': {
                minWidth: 'auto !important',
                maxWidth: 'inherit !important',
              },
            }}
          />
        </DataGridContainer>
      </Stack>
      {canApproveWithdrawals && (
        <Button
          disabled={selectedRows.length === 0}
          sx={{ position: 'sticky', bottom: 2, marginTop: 2, width: '100%' }}
          onClick={() => processWithdrawals({ data: selectedRows as number[] })}
          variant={'contained'}
        >
          {destinationName === EWithdrawalTypeSchema.bankaccount
            ? t('saveAndExportWithdrawals', { amount: selectedRows.length })
            : t('approveWithdrawals', { amount: selectedRows.length })}
        </Button>
      )}
    </>
  );
};

export default GeneralWithdrawal;
