// eslint-disable-next-line import/no-unresolved
import './amplifyStyles.css';
import { useTranslation } from 'react-i18next';
import { Auth } from '@aws-amplify/auth';
import { withAuthenticator, WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { actionCreators, EIdentityProvider } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Button } from '@mui/material';
import { Amplify } from 'aws-amplify';
import { useSnackbar } from 'notistack';

import AmplifyFooter from './AmplifyFooter';
import AmplifyHeader from './AmplifyHeader';
import AmplifySignInFooter from './AmplifySignInFooter';
import AmplifySignInHeader from './AmplifySignInHeader';

Amplify.configure(__AMPLIFY_CONFIG__);

const AmplifyApp = (props: WithAuthenticatorProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { message: error } = useAppSelector(state => state.alert.alert);
  const { data, fetching } = useAppSelector(state => state.authentication);

  Auth.currentSession()
    .then(sessionData => {
      if (!fetching && data && error) {
        // amplify is signed in but something is broken. Abort!

        // We clear these items because of an annoying bug (not 100% sure but it works so yeah)
        // https://github.com/aws-amplify/amplify-flutter/issues/401#issuecomment-1224270594
        localStorage.removeItem('amplify-signin-with-hostedUI');
        localStorage.removeItem('amplify-redirected-from-hosted-ui');
        Auth.signOut()
          .then(() => {
            dispatch(actionCreators.logout());
          })
          .catch(err => {
            enqueueSnackbar(err || t('somethingWentWrong'), { variant: 'error' });
            dispatch(actionCreators.logout());
          });
      }
      if (sessionData && sessionData.isValid())
        dispatch(actionCreators.handleLoginSuccess(sessionData.getIdToken().getJwtToken(), EIdentityProvider.Cognito));
    })
    .catch(err => {
      enqueueSnackbar(err || t('somethingWentWrong'), { variant: 'error' });
    });

  return (
    <div>
      <div>Redirecting... Please wait.</div>
      <Button onClick={props.signOut}>Cancel</Button>
    </div>
  );
};

export default withAuthenticator(AmplifyApp, {
  components: {
    Header: AmplifyHeader,
    SignIn: {
      Header: AmplifySignInHeader,
      Footer: AmplifySignInFooter,
    },
    Footer: AmplifyFooter,
  },
  hideSignUp: true,
});
