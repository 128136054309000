import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {
  EBoostTypeSchema,
  ELanguagesSchema,
  EOperatorJackpotTypeSchema,
  EPromotionNameConfigurationBadRequestTypeSchema,
  EPromotionNameConfigurationTypeSchema,
  getGetPromotionNameConfigurationsQueryKey,
  PromotionNameConfigurationSchema,
  UpdatePromotionNameConfigurationSchema,
  useUpdatePromotionNameConfigurations,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import { Button, capitalize, Dialog, DialogActions, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon, SelectInput } from '@greenisland-common/components/atoms';
import LanguageTranslationSelect from '@greenisland-common/components/organisms/LanguageTranslationSelect';

import { useGetPromotionNameConfigurationMissingTypes } from './helpers/promotionNameConfigurationHooks';
import {
  PROMOTION_NAME_CONFIGURATION_COLUMN_FIELDS,
  PROMOTION_NAME_CONFIGURATION_TITLES,
  PROMOTION_NAME_CONFIGURATION_TYPE_OPTIONS,
  PROMOTIONAL_NAME_CONFIGURATION_ERROR_REASONS,
} from './helpers/promotionNameConfigurationInterfaces';

interface Props {
  open: boolean;
  onClose: () => void;
  type: EPromotionNameConfigurationTypeSchema;
  configurations: PromotionNameConfigurationSchema[];
  selectedConfiguration?: PromotionNameConfigurationSchema;
}
const PromotionNameConfigurationsDialog = ({ open, onClose, type, selectedConfiguration, configurations }: Props) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const promotionalNameConfigurationMissingTypes = useGetPromotionNameConfigurationMissingTypes(configurations, type);

  const options = useMemo(() => {
    const availableTypes = selectedConfiguration
      ? PROMOTION_NAME_CONFIGURATION_TYPE_OPTIONS[type]
      : promotionalNameConfigurationMissingTypes;

    return availableTypes?.map(key => ({ value: key, label: t(key) }));
  }, [promotionalNameConfigurationMissingTypes, selectedConfiguration, t, type]);

  const methods = useForm<UpdatePromotionNameConfigurationSchema>({
    shouldFocusError: true,
    mode: 'onChange',
    defaultValues: {
      boostType: !selectedConfiguration ? (options[0].value as EBoostTypeSchema) : selectedConfiguration?.boostType,
      operatorJackpotType: !selectedConfiguration
        ? (options[0].value as EOperatorJackpotTypeSchema)
        : selectedConfiguration?.operatorJackpotType,
      translations: !selectedConfiguration
        ? [{ language: ELanguagesSchema.EN, content: '' }]
        : selectedConfiguration?.translations,
    },
  });

  const { handleSubmit, reset } = methods;

  const { mutate: updatePromotionNameConfiguration, isLoading } = useUpdatePromotionNameConfigurations({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetPromotionNameConfigurationsQueryKey());
        enqueueSnackbar(t('success'), { variant: 'success' });
        onClose();
        reset();
      },
      onError: error => {
        if (error) {
          if ('type' in error && error.type) {
            const errorReason: EPromotionNameConfigurationBadRequestTypeSchema | undefined = error.type;
            const translationKey = errorReason
              ? PROMOTIONAL_NAME_CONFIGURATION_ERROR_REASONS[errorReason]
              : 'somethingWentWrong';
            enqueueSnackbar(t(translationKey), { variant: 'error' });
          } else {
            enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
          }
        }
      },
    },
  });

  const onSubmit = (data: UpdatePromotionNameConfigurationSchema) => {
    return updatePromotionNameConfiguration({ data: { ...data, type } });
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        {t(
          selectedConfiguration
            ? 'settings.configurations.promotionalNameConfigurations.titles.editPromotionNameConfiguration'
            : 'settings.configurations.promotionalNameConfigurations.titles.addPromotionNameConfiguration'
        )}
      </DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Typography fontWeight={500} variant="body1" fontSize="large">
                {`${capitalize(t(PROMOTION_NAME_CONFIGURATION_TITLES[type]))} ${capitalize(t('type'))}`}
              </Typography>
              <SelectInput
                name={PROMOTION_NAME_CONFIGURATION_COLUMN_FIELDS[type]}
                label={capitalize(t('type'))}
                rules={{ required: t('fieldIsRequired') }}
                options={options}
                required={true}
                disabled={!!selectedConfiguration}
                size="small"
              />
              <LanguageTranslationSelect name="translations" mainTitle="common.translations" contentFieldTitle="name" />
            </Stack>
            <DialogActions>
              <Button variant="text" onClick={onClose} color="secondary">
                {t('cancel')}
              </Button>
              <LoadingButton color="primary" type="submit" variant="contained" loading={isLoading}>
                {t('save')}
              </LoadingButton>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default PromotionNameConfigurationsDialog;
