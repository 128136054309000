/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  CreateMultiplierTournamentSchema,
  DefaultResponse,
  ECheckTournamentResultSchema,
  GetMultiplierTournamentWithFiltersParams,
  MultiplierTournamentSchema,
  NotFoundResponse,
  PagedMultiplierTournamentsSchema,
  PreconditionFailedResponse,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get the multiplier tournaments with specific filters.
 */
export const useGetMultiplierTournamentWithFiltersHook = () => {
  const getMultiplierTournamentWithFilters = useCustomInstance<PagedMultiplierTournamentsSchema>();

  return (params: GetMultiplierTournamentWithFiltersParams, signal?: AbortSignal) => {
    return getMultiplierTournamentWithFilters({ url: `/multipliertournaments`, method: 'get', params, signal });
  };
};

export const getGetMultiplierTournamentWithFiltersQueryKey = (params: GetMultiplierTournamentWithFiltersParams) =>
  [`/multipliertournaments`, ...(params ? [params] : [])] as const;

export const useGetMultiplierTournamentWithFiltersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMultiplierTournamentWithFiltersHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(
  params: GetMultiplierTournamentWithFiltersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMultiplierTournamentWithFiltersHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMultiplierTournamentWithFiltersHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMultiplierTournamentWithFiltersQueryKey(params);

  const getMultiplierTournamentWithFilters = useGetMultiplierTournamentWithFiltersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMultiplierTournamentWithFiltersHook>>>> = ({
    signal,
  }) => getMultiplierTournamentWithFilters(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetMultiplierTournamentWithFiltersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMultiplierTournamentWithFiltersHook>>>
>;
export type GetMultiplierTournamentWithFiltersQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @summary Get the multiplier tournaments with specific filters.
 */
export const useGetMultiplierTournamentWithFilters = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMultiplierTournamentWithFiltersHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(
  params: GetMultiplierTournamentWithFiltersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetMultiplierTournamentWithFiltersHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMultiplierTournamentWithFiltersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create a multiplier tournament
 */
export const useCreateMultiplierTournamentHook = () => {
  const createMultiplierTournament = useCustomInstance<MultiplierTournamentSchema>();

  return (createMultiplierTournamentSchema: CreateMultiplierTournamentSchema) => {
    return createMultiplierTournament({
      url: `/multipliertournaments`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createMultiplierTournamentSchema,
    });
  };
};

export const useCreateMultiplierTournamentMutationOptions = <
  TError =
    | ECheckTournamentResultSchema
    | UnauthorizedResponse
    | NotFoundResponse
    | PreconditionFailedResponse
    | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMultiplierTournamentHook>>>,
    TError,
    { data: CreateMultiplierTournamentSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateMultiplierTournamentHook>>>,
  TError,
  { data: CreateMultiplierTournamentSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createMultiplierTournament = useCreateMultiplierTournamentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateMultiplierTournamentHook>>>,
    { data: CreateMultiplierTournamentSchema }
  > = props => {
    const { data } = props ?? {};

    return createMultiplierTournament(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateMultiplierTournamentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateMultiplierTournamentHook>>>
>;
export type CreateMultiplierTournamentMutationBody = CreateMultiplierTournamentSchema;
export type CreateMultiplierTournamentMutationError =
  | ECheckTournamentResultSchema
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @summary Create a multiplier tournament
 */
export const useCreateMultiplierTournament = <
  TError =
    | ECheckTournamentResultSchema
    | UnauthorizedResponse
    | NotFoundResponse
    | PreconditionFailedResponse
    | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateMultiplierTournamentHook>>>,
    TError,
    { data: CreateMultiplierTournamentSchema },
    TContext
  >;
}) => {
  const mutationOptions = useCreateMultiplierTournamentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the multiplier tournament with the specific ID.
 */
export const useGetMultiplierTournamentHook = () => {
  const getMultiplierTournament = useCustomInstance<MultiplierTournamentSchema>();

  return (tournamentId: string, signal?: AbortSignal) => {
    return getMultiplierTournament({ url: `/multipliertournaments/${tournamentId}`, method: 'get', signal });
  };
};

export const getGetMultiplierTournamentQueryKey = (tournamentId: string) =>
  [`/multipliertournaments/${tournamentId}`] as const;

export const useGetMultiplierTournamentQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMultiplierTournamentHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(
  tournamentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMultiplierTournamentHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMultiplierTournamentHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMultiplierTournamentQueryKey(tournamentId);

  const getMultiplierTournament = useGetMultiplierTournamentHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMultiplierTournamentHook>>>> = ({ signal }) =>
    getMultiplierTournament(tournamentId, signal);

  return { queryKey, queryFn, enabled: !!tournamentId, ...queryOptions };
};

export type GetMultiplierTournamentQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMultiplierTournamentHook>>>
>;
export type GetMultiplierTournamentQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @summary Get the multiplier tournament with the specific ID.
 */
export const useGetMultiplierTournament = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMultiplierTournamentHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(
  tournamentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMultiplierTournamentHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMultiplierTournamentQueryOptions(tournamentId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update multiplier tournament with the specific ID.
 */
export const useUpdateMultiplierTournamentHook = () => {
  const updateMultiplierTournament = useCustomInstance<void>();

  return (tournamentId: string, createMultiplierTournamentSchema: CreateMultiplierTournamentSchema) => {
    return updateMultiplierTournament({
      url: `/multipliertournaments/${tournamentId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: createMultiplierTournamentSchema,
    });
  };
};

export const useUpdateMultiplierTournamentMutationOptions = <
  TError =
    | ECheckTournamentResultSchema
    | UnauthorizedResponse
    | NotFoundResponse
    | PreconditionFailedResponse
    | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateMultiplierTournamentHook>>>,
    TError,
    { tournamentId: string; data: CreateMultiplierTournamentSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateMultiplierTournamentHook>>>,
  TError,
  { tournamentId: string; data: CreateMultiplierTournamentSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateMultiplierTournament = useUpdateMultiplierTournamentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateMultiplierTournamentHook>>>,
    { tournamentId: string; data: CreateMultiplierTournamentSchema }
  > = props => {
    const { tournamentId, data } = props ?? {};

    return updateMultiplierTournament(tournamentId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateMultiplierTournamentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateMultiplierTournamentHook>>>
>;
export type UpdateMultiplierTournamentMutationBody = CreateMultiplierTournamentSchema;
export type UpdateMultiplierTournamentMutationError =
  | ECheckTournamentResultSchema
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @summary Update multiplier tournament with the specific ID.
 */
export const useUpdateMultiplierTournament = <
  TError =
    | ECheckTournamentResultSchema
    | UnauthorizedResponse
    | NotFoundResponse
    | PreconditionFailedResponse
    | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateMultiplierTournamentHook>>>,
    TError,
    { tournamentId: string; data: CreateMultiplierTournamentSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateMultiplierTournamentMutationOptions(options);

  return useMutation(mutationOptions);
};
