import { Dispatch, SetStateAction, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { ChipData, GameFilterType } from '../constants';
import useUpdateGameFilter from './useUpdateGameFilter';

export default function useSelectGameFilter(setChips: Dispatch<SetStateAction<ChipData[]>>) {
  const location = useLocation();
  const updateFilterHandler = useUpdateGameFilter();

  return useCallback(
    (type: GameFilterType, selection: { id: string; label: string; selected?: boolean | undefined }) => {
      const params = new URLSearchParams(location.search);

      const currentSelections = params.get(type)?.split(',') || [];

      if (selection?.selected) {
        setChips(chips => [...chips, { type, ...selection }]);

        const newSelections = [...currentSelections, selection.id].filter(Boolean);
        updateFilterHandler(type, newSelections);
        return;
      }
      setChips(chips => chips.filter(chip => chip.id !== selection.id));

      const updatedSelections = currentSelections.filter(item => item !== selection.id);
      updateFilterHandler(type, updatedSelections);
    },
    [location.search, setChips, updateFilterHandler]
  );
}
