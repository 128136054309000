import { Outlet } from 'react-router';
import CreateGameForm from 'src/pages/GameServer/Games/CreateGameForm';

import Games from '../../pages/GameServer/Games/Games';
import { BaseRoute } from '../RouteInterfaces';

const gamesRoutes: Array<BaseRoute> = [
  {
    name: 'Games',
    path: 'games',
    collapse: true,
    element: Outlet,
    children: [
      {
        path: 'games-overview',
        name: 'Games Overview',
        element: Games,
      },
      {
        path: 'addgame',
        name: 'Create Game',
        element: CreateGameForm,
        hiddenLink: true,
      },
    ],
  },
];

export default gamesRoutes;
