import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCancelWithdrawalInCasino, WithdrawalsSchemaItem } from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

interface Props {
  withdrawal: WithdrawalsSchemaItem;
}

interface FormData {
  reason: string;
}

export const InCasinoCancelWithdrawalDialog = ({ withdrawal }: Props) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>();
  const [isOpen, setIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: cancelWithdrawal, isLoading: isCancelWithdrawLoading } = useCancelWithdrawalInCasino({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        setIsOpen(false);
      },
      onError: error => {
        enqueueSnackbar(error?.message || t('somethingWentWrong'), { variant: 'error' });
      },
    },
  });

  const onSubmit = () => {
    return cancelWithdrawal({ withdrawalId: withdrawal.withdrawalId, data: { username: withdrawal.username } });
  };

  return (
    <>
      <Button onClick={() => setIsOpen(isOpen => !isOpen)} variant="outlined" size="small" color="error">
        {t('cancel')}
      </Button>
      <Dialog
        fullWidth
        open={isOpen}
        keepMounted
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">{t('confirm')}</DialogTitle>
          <DialogContent>
            <TextField
              error={!!errors.reason}
              fullWidth
              name="reason"
              label={t('reason')}
              inputRef={register({ required: 'required' })}
            />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" variant="text" onClick={() => setIsOpen(false)}>
              {t('close')}
            </Button>
            <LoadingButton loading={isCancelWithdrawLoading} color="error" variant="contained" type="submit">
              {t('decline')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default InCasinoCancelWithdrawalDialog;
