import { Dispatch, SetStateAction, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {
  EResolveReportToCRUKSTypeSchema,
  ErrorSchema,
  getGetReportToCruksUsersQueryKey,
  GetReportToCruksUsersParams,
  ReportToCruksUserSchema,
  useResolveReportToCRUKS,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { differenceInHours } from 'date-fns';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

interface Props {
  chosenReportItem: ReportToCruksUserSchema;
  setChosenReportItem: Dispatch<SetStateAction<ReportToCruksUserSchema | null>>;
  params: GetReportToCruksUsersParams;
}

const REPORT_TO_CRUKS_EXTERNAL_LINK = 'https://formulieren.kansspelautoriteit.nl/Form.aspx?src=7167';

const ReportToCruksDialog = ({ chosenReportItem, setChosenReportItem, params }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const isReportOverdue = chosenReportItem.overdue;

  const { mutate: resolveReportToCRUKS, isLoading } = useResolveReportToCRUKS({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetReportToCruksUsersQueryKey(params));
        setChosenReportItem(null);
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: (error: ErrorSchema) => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  const onSubmitHandler = () => {
    const resolveType = chosenReportItem?.overdue
      ? EResolveReportToCRUKSTypeSchema.ReportedToCRUKS
      : EResolveReportToCRUKSTypeSchema.UserReplied;

    return resolveReportToCRUKS({ userId: chosenReportItem.userId, data: { resolveType } });
  };

  const [checked, setChecked] = useState(false);

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <DialogTitle>
        {t(
          isReportOverdue
            ? 'responsibleGaming.reportToCruks.titles.reportToCruks'
            : 'responsibleGaming.reportToCruks.titles.submitUserReply'
        )}
      </DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={() => setChosenReportItem(null)}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <Stack spacing={2}>
          {isReportOverdue ? (
            <>
              <Trans
                i18nKey={t('responsibleGaming.reportToCruks.titles.cruksLinkDescription', {
                  userId: chosenReportItem.userId,
                })}
                components={{ br: <br />, strong: <strong style={{ fontWeight: 600 }} /> }}
              />
              <Typography fontWeight={400} variant="body2">
                <Link href={REPORT_TO_CRUKS_EXTERNAL_LINK} target="_blank">
                  {REPORT_TO_CRUKS_EXTERNAL_LINK}
                </Link>
              </Typography>
            </>
          ) : (
            <Trans
              i18nKey={t('responsibleGaming.reportToCruks.titles.userRepliedDescription', {
                userId: chosenReportItem.userId,
                hours: differenceInHours(new Date(chosenReportItem.replyDueDate * 1000), new Date()),
              })}
              components={{ br: <br />, strong: <strong style={{ fontWeight: 600 }} /> }}
            />
          )}
          <Box>
            <FormControlLabel
              control={<Checkbox onChange={() => setChecked(!checked)} color="success" />}
              label={t(
                isReportOverdue
                  ? 'responsibleGaming.reportToCruks.titles.submitFormCheckboxInfo'
                  : 'responsibleGaming.reportToCruks.titles.userRepliedCheckboxDescription'
              )}
            />
          </Box>
          <DialogActions>
            <LoadingButton
              color="primary"
              type="submit"
              variant="contained"
              onClick={onSubmitHandler}
              loading={isLoading}
              disabled={!checked}
            >
              {t('confirm')}
            </LoadingButton>
          </DialogActions>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ReportToCruksDialog;
