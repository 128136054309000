import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Theme } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

const navPillsStyle = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: '20px',
      paddingLeft: '0',
      marginBottom: '0',
      overflow: 'visible !important',
    },
    flexContainer: {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
    displayNone: {
      display: 'none !important',
    },
    fixed: {
      overflowX: 'visible',
    },
    pills: {
      float: 'left',
      position: 'relative',
      borderRadius: '30px',
      minWidth: '100px',
      textAlign: 'center',
      transition: 'all .3s',
      padding: '10px 15px',
      color: '#555555',
      height: 'auto',
      opacity: 1,
      maxWidth: '100%',
      margin: '0 5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    pillsWithIcons: {
      borderRadius: '4px',
    },
    tabIcon: {
      width: '30px',
      height: '30px',
      display: 'block',
      margin: '15px 0',
    },
    contentWrapper: {
      marginTop: '20px',
    },
    primary: {
      color: '#FFFFFF !important',
      backgroundColor: theme.palette.primary.main,
    },
    alignCenter: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

interface IconObject {
  [key: string]: React.ReactNode;
}

interface NavPillTab {
  tabButton: string;
  tabIcon?: React.ComponentType<SvgIconProps>;
  tabContent: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

interface NavPillsProps extends WithStyles<typeof navPillsStyle> {
  active: number;
  onChangeTab: (index: number) => void;
  tabs: NavPillTab[];
  alignCenter: boolean;
}

interface NavPillsState {
  active: number;
}

class NavPills extends React.Component<NavPillsProps, NavPillsState> {
  static defaultProps = { active: 0, alignCenter: false };

  render() {
    const { classes, tabs, alignCenter, onChangeTab, active } = this.props;

    return (
      <div>
        <Tabs
          classes={{
            root: classes.root,
            fixed: classes.fixed,
            flexContainer: classes.flexContainer,
            indicator: classes.displayNone,
          }}
          value={active}
          onChange={(_event, index) => onChangeTab(index)}
          centered={alignCenter}
        >
          {tabs.map((prop, key) => {
            const icon: IconObject = {};
            if (prop.tabIcon !== undefined) {
              icon['icon'] = <prop.tabIcon className={classes.tabIcon} />;
            }
            const pillsClasses = classNames({
              [classes.pills]: true,
              [classes.pillsWithIcons]: prop.tabIcon !== undefined,
            });
            return (
              <Tab
                label={prop.tabButton}
                onClick={prop.onClick}
                key={key}
                {...icon}
                classes={{
                  root: pillsClasses,
                  selected: classes.primary,
                }}
              />
            );
          })}
        </Tabs>
        <div className={classes.contentWrapper}>
          <SwipeableViews index={active}>
            {tabs.map((prop, key) => {
              return <div key={key}>{prop.tabContent}</div>;
            })}
          </SwipeableViews>
        </div>
      </div>
    );
  }
}

export default withStyles(navPillsStyle)(NavPills);
