import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MultiplierTournamentSchema } from '@greenisland-api';
import { Box, capitalize, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ContentFilterButton } from '../../../../../Content/ContentFilter/components';
import { CreateMultiplierTournamentSchemaForm } from '../helpers/MultiplierTournamentFormContext';

interface Props {
  isCreateMode?: boolean;
  multiplierTournament?: MultiplierTournamentSchema;
}

const MultiplierTournamentContentFilterSettings = ({ isCreateMode = true, multiplierTournament }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<CreateMultiplierTournamentSchemaForm>();

  useEffect(() => {
    if (!isCreateMode && multiplierTournament?.contentFilterId) {
      setValue('contentFilterId', multiplierTournament?.contentFilterId);
    }
  }, [isCreateMode, multiplierTournament?.contentFilterId, setValue]);

  return (
    <Stack spacing={2}>
      <Typography fontWeight={500} variant="h5" sx={{ pt: 1 }}>
        {capitalize(t('multipliertournaments.subtitle.contentSettings'))}
      </Typography>
      <Box
        sx={{
          [theme.breakpoints.up('xs')]: {
            width: '100%',
            mb: 2,
          },
          [theme.breakpoints.up('md')]: {
            width: '45%',
          },
        }}
      >
        <ContentFilterButton control={control} name="contentFilterId" />
      </Box>
    </Stack>
  );
};

export default MultiplierTournamentContentFilterSettings;
