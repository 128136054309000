/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  DefaultResponse,
  LoyaltyBoosterArgumentsSchema,
  LoyaltyBoosterSchema,
  LoyaltyBoostersSchema,
  NotFoundResponse,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get the list of loyalty boosters
 */
export const useGetLoyaltyBoostersHook = () => {
  const getLoyaltyBoosters = useCustomInstance<LoyaltyBoostersSchema>();

  return (signal?: AbortSignal) => {
    return getLoyaltyBoosters({ url: `/loyalty/boosters`, method: 'get', signal });
  };
};

export const getGetLoyaltyBoostersQueryKey = () => [`/loyalty/boosters`] as const;

export const useGetLoyaltyBoostersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoyaltyBoostersHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyBoostersHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyBoostersHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLoyaltyBoostersQueryKey();

  const getLoyaltyBoosters = useGetLoyaltyBoostersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyBoostersHook>>>> = ({ signal }) =>
    getLoyaltyBoosters(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLoyaltyBoostersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLoyaltyBoostersHook>>>
>;
export type GetLoyaltyBoostersQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the list of loyalty boosters
 */
export const useGetLoyaltyBoosters = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoyaltyBoostersHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyBoostersHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetLoyaltyBoostersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete loyalty booster
 */
export const useDeleteLoyaltyBoosterHook = () => {
  const deleteLoyaltyBooster = useCustomInstance<void>();

  return (boosterId: string) => {
    return deleteLoyaltyBooster({ url: `/loyalty/boosters/${boosterId}`, method: 'delete' });
  };
};

export const useDeleteLoyaltyBoosterMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteLoyaltyBoosterHook>>>,
    TError,
    { boosterId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteLoyaltyBoosterHook>>>,
  TError,
  { boosterId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteLoyaltyBooster = useDeleteLoyaltyBoosterHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteLoyaltyBoosterHook>>>,
    { boosterId: string }
  > = props => {
    const { boosterId } = props ?? {};

    return deleteLoyaltyBooster(boosterId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteLoyaltyBoosterMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteLoyaltyBoosterHook>>>
>;

export type DeleteLoyaltyBoosterMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Delete loyalty booster
 */
export const useDeleteLoyaltyBooster = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteLoyaltyBoosterHook>>>,
    TError,
    { boosterId: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteLoyaltyBoosterMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Add loyalty booster
 */
export const useAddLoyaltyBoosterHook = () => {
  const addLoyaltyBooster = useCustomInstance<LoyaltyBoosterSchema>();

  return (providerId: string, gameId: string, loyaltyBoosterArgumentsSchema: LoyaltyBoosterArgumentsSchema) => {
    return addLoyaltyBooster({
      url: `/loyalty/boosters/providers/${providerId}/games/${gameId}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: loyaltyBoosterArgumentsSchema,
    });
  };
};

export const useAddLoyaltyBoosterMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddLoyaltyBoosterHook>>>,
    TError,
    { providerId: string; gameId: string; data: LoyaltyBoosterArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddLoyaltyBoosterHook>>>,
  TError,
  { providerId: string; gameId: string; data: LoyaltyBoosterArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addLoyaltyBooster = useAddLoyaltyBoosterHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddLoyaltyBoosterHook>>>,
    { providerId: string; gameId: string; data: LoyaltyBoosterArgumentsSchema }
  > = props => {
    const { providerId, gameId, data } = props ?? {};

    return addLoyaltyBooster(providerId, gameId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddLoyaltyBoosterMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddLoyaltyBoosterHook>>>
>;
export type AddLoyaltyBoosterMutationBody = LoyaltyBoosterArgumentsSchema;
export type AddLoyaltyBoosterMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Add loyalty booster
 */
export const useAddLoyaltyBooster = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddLoyaltyBoosterHook>>>,
    TError,
    { providerId: string; gameId: string; data: LoyaltyBoosterArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddLoyaltyBoosterMutationOptions(options);

  return useMutation(mutationOptions);
};
