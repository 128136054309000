import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import {
  faCheckCircle,
  faExclamationCircle,
  faGifts,
  faLanguage,
  faNoteSticky,
  faRectangleAd,
  faVial,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EUserStatusSchema, useGetUserGeneral, useGetUserNotes } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Badge, Box, Chip, ChipProps, Tooltip, useTheme } from '@mui/material';

import loyaltyBadges from '../../../../../../../app/constants/ranks';
import { usePermission } from '../../../../../../../app/hooks';
import useFeatureFlags from '../../../../../../../common/hooks/useFeatureFlags';

const StyledChip = ({ size, label, color, variant, sx, icon, ...rest }: ChipProps) => {
  const theme = useTheme();

  return (
    <Chip
      size={size}
      label={label}
      color={color}
      variant={variant}
      icon={icon}
      sx={{
        ...sx,
        marginRight: 0,
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
          marginRight: theme.spacing(1),
          marginTop: 0,
        },
      }}
      {...rest}
    />
  );
};

const UserDataChips = () => {
  const { t, i18n } = useTranslation();
  const { userId = '' } = useParams();
  const canGetLoyaltyRankForUser = usePermission(OnlineCasinoPermissions.getUserLoyaltyDetails);
  const isLoyaltyEnabled = useFeatureFlags('isLoyaltyEnabled');
  const navigate = useNavigate();
  const canReadNotes = usePermission(OnlineCasinoPermissions.getUserNotes);

  const { data: user } = useGetUserGeneral(userId);
  const { color, imagePath } = loyaltyBadges[user?.loyaltyRank || 'Standard'] || loyaltyBadges.Standard;
  const languageNames = new Intl.DisplayNames([i18n.language], { type: 'language' });

  const { data: userNotes } = useGetUserNotes(userId, { query: { enabled: canReadNotes } });

  const getColorFromStatusHandler = (status?: EUserStatusSchema) => {
    switch (status) {
      case EUserStatusSchema.Active:
        return 'success';
      case EUserStatusSchema.SelfExclusion:
        return 'warning';
      default:
        return 'error';
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row', lg: 'row' }}
      alignItems={{ sm: 'center' }}
      flexWrap="wrap"
    >
      <StyledChip
        size="small"
        label={user?.status}
        color={getColorFromStatusHandler(user?.status)}
        variant="outlined"
        icon={
          <FontAwesomeIcon icon={user?.status === EUserStatusSchema.Active ? faCheckCircle : faExclamationCircle} />
        }
      />
      {isLoyaltyEnabled && canGetLoyaltyRankForUser && user?.loyaltyRank && (
        <StyledChip
          size="small"
          label={user?.loyaltyRank}
          variant="outlined"
          sx={{
            color,
            borderColor: color,
          }}
          icon={<img style={{ height: 18 }} src={imagePath} alt={user?.loyaltyRank} />}
        />
      )}
      {user?.language ? (
        <StyledChip
          size="small"
          label={languageNames.of(user?.language)}
          variant="outlined"
          icon={<FontAwesomeIcon icon={faLanguage} />}
        />
      ) : null}
      {canReadNotes && userNotes && userNotes.notes.length > 0 ? (
        <StyledChip
          size="small"
          label={
            <Badge
              badgeContent={userNotes.notes.length}
              color="error"
              max={999}
              sx={{
                '& .MuiBadge-badge': {
                  position: 'relative',
                  left: '6px',
                  transform: 'none !important',
                },
              }}
            >
              {t('notes')}
            </Badge>
          }
          variant="outlined"
          icon={<FontAwesomeIcon icon={faNoteSticky} />}
          onClick={() => navigate('../notes')}
          style={{ cursor: 'pointer' }}
        />
      ) : null}
      {user?.testUser ? (
        <StyledChip size="small" label={t('testUser')} variant="outlined" icon={<FontAwesomeIcon icon={faVial} />} />
      ) : null}
      {user?.isNotAllowedToReceivePromotion ? (
        <Tooltip arrow title={t('notallowed.promotion')}>
          <div>
            <StyledChip
              size="small"
              label={t('notallowed.label')}
              color="error"
              variant="outlined"
              icon={<FontAwesomeIcon icon={faGifts} />}
            />
          </div>
        </Tooltip>
      ) : null}
      {user?.isNotAllowedToReceiveMarketing ? (
        <Tooltip arrow title={t('notallowed.marketing')}>
          <div>
            <StyledChip
              size="small"
              label={t('notallowed.label')}
              color="error"
              variant="outlined"
              icon={<FontAwesomeIcon icon={faRectangleAd} />}
            />
          </div>
        </Tooltip>
      ) : null}
    </Box>
  );
};

export default UserDataChips;
