import React, { Attributes, useCallback, useMemo } from 'react';
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBold,
  faCode,
  faItalic,
  faList,
  faListOl,
  faQuoteLeft,
  faUnderline,
} from '@fortawesome/free-solid-svg-icons';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';

import BlockButton from './components/BlockButton';
import { AddLinkButton, RemoveLinkButton, wrapLink } from './components/LinkButtons';
import MarkButton from './components/MarkButton';
import { LinkComponent, Toolbar } from './components/RichTextComponents';
import { CustomEditor } from './types';
import { deserialize, serialize } from './utils';

const withInlines = (editor: CustomEditor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = element => ['link'].includes(element.type) || isInline(element);

  editor.insertText = text => {
    if (text && text.startsWith('http')) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = data => {
    const text = data.getData('text/plain');

    if (text && text.startsWith('http')) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const RichTextEditor = ({
  initialValue,
  onEditorChange,
}: {
  initialValue: string;
  value?: string;
  onEditorChange: (value: string) => void;
}) => {
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withInlines(withHistory(withReact(createEditor()))), []);

  return (
    <>
      <Slate
        editor={editor}
        initialValue={deserialize(initialValue)}
        onChange={value => {
          const content = JSON.stringify(value);
          onEditorChange(serialize({ children: JSON.parse(content) } as any));
        }}
      >
        {
          <Toolbar>
            <MarkButton format="bold" icon={faBold} />
            <MarkButton format="italic" icon={faItalic} />
            <MarkButton format="underline" icon={faUnderline} />
            <MarkButton format="code" icon={faCode} />
            <AddLinkButton />
            <RemoveLinkButton />
            <BlockButton format="heading" level={1} icon="H1" />
            <BlockButton format="heading" level={2} icon="H2" />
            <BlockButton format="heading" level={3} icon="H3" />
            <BlockButton format="heading" level={4} icon="H4" />
            <BlockButton format="heading" level={5} icon="H5" />
            <BlockButton format="heading" level={6} icon="H6" />
            <BlockButton format="block-quote" icon={faQuoteLeft} />
            <BlockButton format="numbered-list" icon={faListOl} />
            <BlockButton format="bulleted-list" icon={faList} />
            <BlockButton format="left" icon={faAlignLeft} />
            <BlockButton format="center" icon={faAlignCenter} />
            <BlockButton format="right" icon={faAlignRight} />
            <BlockButton format="justify" icon={faAlignJustify} />
          </Toolbar>
        }
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some rich text…"
          spellCheck
          autoFocus
          style={{ minHeight: '200px' }}
        />
      </Slate>
    </>
  );
};

const Element = ({
  attributes,
  children,
  element,
}: {
  attributes: Attributes;
  children: React.ReactNode;
  element: any;
}) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading':
      switch (element.level) {
        case 1:
          return (
            <h1 style={style} {...attributes}>
              {children}
            </h1>
          );
        case 2:
          return (
            <h2 style={style} {...attributes}>
              {children}
            </h2>
          );
        case 3:
          return (
            <h3 style={style} {...attributes}>
              {children}
            </h3>
          );
        case 4:
          return (
            <h4 style={style} {...attributes}>
              {children}
            </h4>
          );
        case 5:
          return (
            <h5 style={style} {...attributes}>
              {children}
            </h5>
          );
        case 6:
          return (
            <h6 style={style} {...attributes}>
              {children}
            </h6>
          );
        default:
          return (
            <p style={style} {...attributes}>
              {children}
            </p>
          );
      }
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case 'link':
      return (
        <LinkComponent attributes={attributes} element={element}>
          {children}
        </LinkComponent>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }: { attributes: Attributes; children: React.ReactNode; leaf: any }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export default RichTextEditor;
