/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice Authorization
 * Description of API calls used for the actual authorization and agents management Changelog: - 20210315 MAE: Added linkedProductIds to agentrole - 20201124 MAE: Added crossEntityRole bij GetRoles - 20201119 MAE: Removed refreshtoken from loginresult, as it should never leave the server + loginTokens call - 20201027 MAE: invoice details for Product - 20201026 MAE: Permission level as enum - 20201019 MAE: propertyName permisions - 20200824 MAE: new LoginResultType - 20200729 MAE: discarded 6 permissions/roles calls on agent and agentrole, adjusted update models and list model for agent, separate update models for entity and product
 * OpenAPI spec version: 1.3.0
 */

export type EPermissionLevelFilterSchema =
  (typeof EPermissionLevelFilterSchema)[keyof typeof EPermissionLevelFilterSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EPermissionLevelFilterSchema = {
  Read: 'Read',
  Write: 'Write',
  null: null,
} as const;
