import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getGetPaymentMethodsQueryKey,
  PaymentMethodSchema,
  useGetPaymentMethods,
  useUpdatePaymentMethodSortOrder,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { LinearProgress } from '@mui/material';
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowOrderChangeParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { usePermission } from 'src/app/hooks';

import { CheckboxIcon, DataGridContainer, StyledDataGrid } from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

type Row = PaymentMethodSchema & { id: string | undefined };

const PaymentMethodList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: paymentMethods, isLoading, isError, refetch } = useGetPaymentMethods();
  const { mutate: orderPaymentMethod } = useUpdatePaymentMethodSortOrder({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetPaymentMethodsQueryKey());
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const canReadPaymentMethods = usePermission(OnlineCasinoPermissions.getPaymentMethods);
  const canEditPaymentMethod = usePermission(OnlineCasinoPermissions.updatePaymentMethod);

  const rows = useMemo(
    () => paymentMethods?.map<Row>(method => ({ ...method, id: method.id })) ?? [],
    [paymentMethods]
  );

  const handleRowOrderChange = useCallback(
    (params: GridRowOrderChangeParams) => {
      const oldRow = rows[params.oldIndex];
      orderPaymentMethod({ paymentMethodId: oldRow.id, data: { order: params.targetIndex } });
    },
    [orderPaymentMethod, rows]
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      { field: 'method', headerName: t('paymentMethods.single'), flex: 1, minWidth: 160 },
      { field: 'provider', headerName: t('provider'), flex: 1, minWidth: 80 },
      {
        field: 'integrationVersion',
        headerName: t('paymentMethods.integrationVersion'),
        flex: 1,
        minWidth: 70,
      },
      {
        field: 'testUserOnly',
        headerName: t('paymentMethods.enabled'),
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={!params.value} />,
        flex: 1,
        minWidth: 116,
      },
      {
        field: 'depositsEnabled',
        headerName: t('paymentMethods.depositsEnabled'),
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        flex: 1,
        minWidth: 80,
      },
      {
        field: 'withdrawalsEnabled',
        headerName: t('paymentMethods.withdrawalsEnabled'),
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        flex: 1,
        minWidth: 105,
      },
      {
        field: 'closedLoop',
        headerName: t('paymentMethods.closedLoop'),
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        flex: 1,
        minWidth: 100,
      },
      ...(canEditPaymentMethod
        ? [
            {
              field: 'actions',
              type: 'actions',
              getActions: (params: GridRowParams<Row>) => [
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faPencil} />}
                  label={t('edit')}
                  onClick={() => navigate(params.row.id)}
                  key="edit"
                />,
              ],
              flex: 0.5,
            },
          ]
        : []),
    ],
    [canEditPaymentMethod, navigate, t]
  );

  if (isError) {
    return <ErrorState errorMessage={t('paymentMethods.errors.loadingMethods')} retryAction={() => refetch()} />;
  }

  if (!canReadPaymentMethods) {
    return null;
  }

  return (
    <DataGridContainer>
      <StyledDataGrid
        density="compact"
        autoHeight
        loading={isLoading}
        rows={rows}
        columns={columns}
        disableDensitySelector
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        disableChildrenSorting
        disableChildrenFiltering
        disableMultipleColumnsSorting
        disableColumnResize
        disableColumnReorder
        rowReordering={canEditPaymentMethod}
        onRowOrderChange={handleRowOrderChange}
        isRowSelectable={() => false}
        components={{
          LoadingOverlay: LinearProgress,
        }}
      />
    </DataGridContainer>
  );
};

export default memo(PaymentMethodList);
