import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ERegulatorControllerBadRequestTypeSchema,
  RegulatorControllerBadRequestContentSchema,
  useGetScheduledCanExceedRequests,
} from '@greenisland-api';
import { Box, capitalize, Checkbox, FormControlLabel, Skeleton } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';
import { getDateTimeFromUnix } from 'src/app/helpers/transformFunctions';

import {
  DataGridContainer,
  DataGridPagination,
  ErrorCard,
  NoData,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

interface Props {
  userId: number;
}

const ScheduleRequestExceedTable = ({ userId }: Props) => {
  const { t } = useTranslation();
  const [isNotProcessed, setIsNotProcessed] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [error, setError] = useState<ERegulatorControllerBadRequestTypeSchema | undefined>(undefined);

  const queryOptions = useMemo(
    () => ({
      page,
      pageSize,
    }),
    [page, pageSize]
  );

  const canRequestExceedQuery = useGetScheduledCanExceedRequests(
    userId,
    {
      ...(isNotProcessed ? { isProcessed: !isNotProcessed } : {}),
      ...queryOptions,
    },
    {
      query: {
        onError: async (error: Response) => {
          const errorResp: RegulatorControllerBadRequestContentSchema = await error.json();
          setError(errorResp.type);
        },
      },
    }
  );

  const [rowCountState, setRowCountState] = useState(canRequestExceedQuery?.data?.pagingDetails.totalItems || 0);

  useEffect(() => {
    setRowCountState(prevRowCountState =>
      canRequestExceedQuery?.data?.pagingDetails.totalItems !== undefined
        ? canRequestExceedQuery?.data?.pagingDetails.totalItems
        : prevRowCountState
    );
  }, [canRequestExceedQuery?.data?.pagingDetails.totalItems, setRowCountState]);

  const canRequestExceedData = useMemo(
    () => canRequestExceedQuery.data?.items.map((v, index) => ({ ...v, id: index })) ?? [],
    [canRequestExceedQuery.data?.items]
  );

  if (canRequestExceedQuery.isLoading) return <Skeleton variant="rectangular" height={179} />;
  if (canRequestExceedQuery.isError || !canRequestExceedQuery.data)
    return <ErrorCard sx={{ height: 179 }}>{t(`regulator.${error}`)}</ErrorCard>;

  if (error === ERegulatorControllerBadRequestTypeSchema.BGCArticle6NotEnabled) {
    return null;
  }

  const canRequestExceedHeaders: GridColumns = [
    {
      headerName: capitalize(t('requestId')),
      field: 'requestId',
      flex: 1,
      minWidth: 180,
    },
    {
      headerName: capitalize(t('earliestProcessDate')),
      field: 'earliestProcessDate',
      flex: 1,
      minWidth: 180,
      valueFormatter: ({ value }) => (value && value !== 0 ? getDateTimeFromUnix(value) : '/'),
    },
    {
      headerName: capitalize(t('processedAt')),
      field: 'processedAt',
      flex: 1,
      minWidth: 180,
      valueFormatter: ({ value }) => (value && value !== 0 ? getDateTimeFromUnix(value) : '/'),
    },
  ];

  return (
    <>
      <Box>
        {t('depositLimitsStatus.filter')}
        <FormControlLabel
          control={<Checkbox checked={isNotProcessed} onChange={e => setIsNotProcessed(e.target.checked)} />}
          label={capitalize(t('isNotProcessed'))}
          sx={{ ml: 0.5 }}
        />
      </Box>
      {canRequestExceedData.length > 0 ? (
        <DataGridContainer>
          <StyledDataGrid
            autoHeight
            density="compact"
            columns={canRequestExceedHeaders}
            rows={canRequestExceedData}
            rowCount={rowCountState}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={newPage => setPage(newPage)}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            components={{
              Pagination: DataGridPagination,
            }}
          />
        </DataGridContainer>
      ) : (
        <NoData />
      )}
    </>
  );
};

export default memo(ScheduleRequestExceedTable);
