import { useTranslation } from 'react-i18next';
import { useGetRevenue } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Card, CardContent, CardHeader, SxProps } from '@mui/material';
import { GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, PermissionWrapper, StyledDataGrid } from '@greenisland-common/components/atoms';

import { useGetRows, usePermission } from '../../../../../app/hooks';
import { headers } from './RevenueGridColumns';

interface Props {
  sx?: SxProps;
}

const RevenueGames = ({ sx }: Props) => {
  const { t } = useTranslation();
  const canReadRevenue = usePermission(OnlineCasinoPermissions.getRevenue);
  const { data: revenue, isLoading, isError } = useGetRevenue({ query: { enabled: canReadRevenue } });
  const gamesRows: GridRowsProp = useGetRows(revenue?.games?.today, (a, b) => (b.key === 'revenue' ? -1 : 0));

  return (
    <Card sx={sx}>
      <CardHeader title={t('revenueGames')} />
      <CardContent>
        <PermissionWrapper
          errorMessage={t('revenueGamesCard.fetchError')}
          isError={isError}
          isLoading={false}
          permission={OnlineCasinoPermissions.getRevenue}
        >
          <DataGridContainer>
            <StyledDataGrid
              hideFooter
              loading={isLoading}
              density="compact"
              autoHeight
              columns={headers}
              rows={gamesRows}
            />
          </DataGridContainer>
        </PermissionWrapper>
      </CardContent>
    </Card>
  );
};

export default RevenueGames;
