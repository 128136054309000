import { useTranslation } from 'react-i18next';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { usePermission } from 'src/app/hooks';
import TabMenu, { Tab } from 'src/routes/components/TabMenu';

import RequestMaxLimitForm from './forms/RequestMaxLimitForm';
import RequestNewLimitForm from './forms/RequestNewLimitForm';

type UserLimitsDialogProps = {
  openDialog: boolean;
  setOpenDialog: (state: boolean) => void;
  userId: string;
  activeTab?: number;
};

const DepositLimitRequestDialog = ({ openDialog, setOpenDialog, userId, activeTab }: UserLimitsDialogProps) => {
  const { t } = useTranslation();

  const tabs: Tab[] = [
    {
      title: 'depositLimit.tabTitle.limit',
      content: <RequestNewLimitForm userId={parseInt(userId)} onClose={() => setOpenDialog(false)} />,
      visible: usePermission(OnlineCasinoPermissions.requestDepositLimitUpdate),
    },
    {
      title: 'depositLimit.tabTitle.maxLimit',
      content: <RequestMaxLimitForm userId={parseInt(userId)} onClose={() => setOpenDialog(false)} />,
      visible: usePermission(OnlineCasinoPermissions.setMaximumDepositLimit),
    },
  ];

  return (
    <Dialog fullWidth open={openDialog}>
      <DialogTitle>{t('setDepositLimitRequest')}</DialogTitle>
      <DialogContent sx={{ py: 1, px: 2 }}>
        <TabMenu tabs={tabs} activeTab={activeTab} />
      </DialogContent>
    </Dialog>
  );
};

export default DepositLimitRequestDialog;
