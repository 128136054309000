import { Stack } from '@mui/material';

import KillSwitchStateField from './Components/KillSwitchStateField';
import KillSwitchTriggerField from './Components/KillSwitchTriggerField';

const KillSwitchSettings = () => {
  return (
    <Stack spacing={2}>
      <KillSwitchStateField />
      <KillSwitchTriggerField />
    </Stack>
  );
};

export default KillSwitchSettings;
