import React from 'react';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { SxProps } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

interface Props {
  /**
   * change icon depending on value
   * @default false
   */
  checked?: boolean;
  /**
   * add additional styles
   * @default false
   */
  sx?: SxProps;
}

/**
 * This component either displays FontAwesome faCheckCircle if checked is true or faTimesCircle if false
 */
const CheckboxIcon = ({ checked, sx }: Props) => {
  return checked ? (
    <FontAwesomeIcon icon={faCheckCircle} sx={{ color: 'primary.main', fill: 'currentColor', ...sx }} />
  ) : (
    <FontAwesomeIcon icon={faTimesCircle} sx={{ color: 'error.main', fill: 'currentcolor', ...sx }} />
  );
};

export default CheckboxIcon;
