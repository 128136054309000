import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BechargeProductGroupSchema, useGetBechargeProducts } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { capitalize, LinearProgress, Stack, Tooltip, Typography } from '@mui/material';
import { DataGridProProps, GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { CheckboxIcon, DataGridContainer, PermissionWrapper } from '@greenisland-common/components/atoms';
import DataGridCustomPagination from '@greenisland-common/components/atoms/DataGridPagination/DataGridCustomPagination';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { usePermission } from '../../../../../app/hooks';
import VouchersProductsDetails from './VouchersProductsDetails';

const PAGE_SIZE = 25;

type Row = BechargeProductGroupSchema & { id: string };

const VouchersProducts = () => {
  const { t } = useTranslation();

  const canReadBechargeProducts = usePermission(OnlineCasinoPermissions.getBechargeProducts);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const {
    data: bechargeProducts,
    isLoading,
    isError,
  } = useGetBechargeProducts({
    query: { enabled: canReadBechargeProducts, keepPreviousData: true },
  });

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'productGroupId',
        headerName: capitalize(t('content.becharge.vouchersProducts.table.productGroupId')),
        sortable: false,
        minWidth: 130,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'name',
        headerName: capitalize(t('content.becharge.vouchersProducts.table.productName')),
        sortable: false,
        minWidth: 100,
        flex: 0.2,
        renderCell: params => {
          return (
            <Tooltip title={params.value}>
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                variant="body2"
              >
                {params.value}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: 'category',
        headerName: capitalize(t('content.becharge.vouchersProducts.table.productCategory')),
        sortable: false,
        minWidth: 100,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'currency',
        headerName: capitalize(t('content.becharge.vouchersProducts.table.productCurrency')),
        sortable: false,
        minWidth: 100,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'country',
        headerName: capitalize(t('content.becharge.vouchersProducts.table.productCountry')),
        sortable: false,
        minWidth: 80,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'directGroup',
        headerName: capitalize(t('content.becharge.vouchersProducts.table.productDirectGroup')),
        sortable: false,
        minWidth: 100,
        flex: 0.1,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
      },
      {
        field: 'ContactInfo',
        headerName: capitalize(t('content.becharge.vouchersProducts.table.productContactInfo')),
        sortable: false,
        minWidth: 100,
        flex: 0.2,
        renderCell: params => {
          return (
            <Tooltip title={params.value}>
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                variant="body2"
              >
                {params.value}
              </Typography>
            </Tooltip>
          );
        },
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      bechargeProducts?.productGroups?.map<BechargeProductGroupSchema>((product, index) => ({
        id: index,
        ...product,
      })) ?? [],
    [bechargeProducts?.productGroups]
  );

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setPageSize(value);
    setPage(0);
  };

  const getDetailPanelHeight = useCallback((): 'auto' => 'auto', []);

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback(({ row }: { row: Row }) => {
    if (!row?.products?.length) {
      return null;
    }
    return <VouchersProductsDetails usageInfo={row.usageInfo} products={row.products} />;
  }, []);

  return (
    <Stack spacing={2}>
      <PermissionWrapper
        errorMessage={t('content.becharge.vouchersProducts.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getBechargeProducts}
      >
        <DataGridContainer>
          <LimitedDataGrid
            density="compact"
            autoHeight
            rowHeight={80}
            loading={isLoading}
            columns={columns}
            rows={rows}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="client"
            components={{
              LoadingOverlay: LinearProgress,
              Footer: () => (
                <>
                  <DataGridCustomPagination
                    page={page}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100, 200]}
                    count={
                      bechargeProducts?.productGroups?.length
                        ? Math.ceil(bechargeProducts?.productGroups?.length / pageSize)
                        : 0
                    }
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    paginationMode="client"
                  />
                </>
              ),
            }}
          />
        </DataGridContainer>
      </PermissionWrapper>
    </Stack>
  );
};

export default VouchersProducts;
