import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { getGetVaultConfigurationsQueryKey, useRevealVaultDigit } from '@greenisland-api';
import { useSnackbar } from 'notistack';

import ConfirmDialog from '@greenisland-common/components/organisms/ConfirmDialog';

interface Props {
  open: boolean;
  onClose: () => void;
  revealedVaultDigitId: number;
}

const VaultConfigurationRevealDigitDialog = ({ open, onClose, revealedVaultDigitId }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const { mutate: revealVaultDigit, isLoading: isRevealVaultDigitLoading } = useRevealVaultDigit({
    mutation: {
      onSuccess: () => {
        onClose();
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetVaultConfigurationsQueryKey());
      },
      onError: error => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  return (
    <>
      <ConfirmDialog
        title={t('contests.vault.titles.revealDigit')}
        description={t('contests.vault.titles.revealDigitConfirmation', { vaultConfigId: revealedVaultDigitId })}
        isOpen={open}
        loading={isRevealVaultDigitLoading}
        onCancel={onClose}
        onConfirm={() => revealVaultDigit({ vaultConfigId: revealedVaultDigitId })}
        confirmText={t('confirm')}
        cancelText={t('cancel')}
      />
    </>
  );
};

export default VaultConfigurationRevealDigitDialog;
