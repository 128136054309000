import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import {
  capitalize,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useTheme,
} from '@mui/material';
import { gridPageCountSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';

type PaginationMode = 'client' | 'server';

interface Props {
  paginationMode?: PaginationMode;
  onChange?: (page: number) => void;
}

const DataGridPaginationSearchPageField = ({ paginationMode = 'server', onChange }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const [page, setPage] = useState<number | null>(null);

  const updatePageHandler = () => {
    if (page) {
      if (paginationMode === 'client' && onChange) {
        onChange(page - 1);
      } else {
        return apiRef.current.setPage(page - 1);
      }
    }
  };

  const isDisabled = Boolean(page && page > pageCount);

  return (
    <FormControl
      sx={{
        [theme.breakpoints.up('xs')]: {
          width: '100%',
          my: 1,
        },
        [theme.breakpoints.up('md')]: {
          width: '20ch',
          m: 1,
        },
      }}
      variant="outlined"
    >
      <InputLabel htmlFor="custom-page-input" size="small">
        {capitalize(t('page'))}
      </InputLabel>
      <OutlinedInput
        value={page}
        onChange={e => setPage(Number(e.target.value))}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            updatePageHandler();
          }
        }}
        size="small"
        id="custom-page-input"
        type="number"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              size="small"
              aria-label="search-page-btn"
              onClick={updatePageHandler}
              edge="end"
              disabled={isDisabled}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        inputProps={{ max: pageCount }}
        label="search-page-btn"
      />
    </FormControl>
  );
};

export default DataGridPaginationSearchPageField;
