import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { AdvancedUserSearchV2Params, useAdvancedUserSearchV2, useQuickUserSearch } from '@greenisland-api';
import { Box, Button } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { removeUndefinedProperties } from 'src/app/helpers/functions';

import { FontAwesomeIcon, Link, StyledDataGrid } from '@greenisland-common/components/atoms';

import { BooleanSpan } from '../../../../app/helpers/transformFunctions';
import SearchUser from '../Components/SearchUser';

const PAGE_SIZE = 20;

const Lookup = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  const [searchQuery, setSearchQuery] = useState<AdvancedUserSearchV2Params | undefined>(undefined);
  const [page, setPage] = useState(0);
  const search = useMemo(() => searchParams.get('search'), [searchParams]);

  const { data: quickSearchData, isLoading: isLoadingQuick } = useQuickUserSearch(
    {
      searchValue: search ? search : '',
    },
    {
      query: {
        enabled: !!searchParams.get('search'),
      },
    }
  );

  const { data: advancedSearchData, isLoading: isLoadingAdvanced } = useAdvancedUserSearchV2(
    {
      ...searchQuery,
      pageSize: PAGE_SIZE,
      page,
    },
    {
      query: {
        enabled: Boolean(searchQuery),
        onSuccess: () => {
          enqueueSnackbar(t('success'), {
            variant: 'success',
          });
        },
      },
    }
  );

  const isLoading = isLoadingQuick || isLoadingAdvanced;
  const onSubmit = (data: AdvancedUserSearchV2Params) => {
    setSearchQuery(removeUndefinedProperties(data));
  };

  const data = useMemo(() => {
    if (advancedSearchData?.results) {
      return advancedSearchData.results.map(item => ({ id: item.userId, ...item }));
    }
    if (search && quickSearchData) {
      return quickSearchData.map(item => ({ id: item.userId, ...item }));
    }
    return [];
  }, [advancedSearchData, quickSearchData, search]);

  const headers: GridColumns = data[0]
    ? Object.keys(data[0]).map(key => {
        switch (key) {
          case 'userId':
            return {
              headerName: t(key),
              field: key,
              minWidth: 150,
              flex: 1,
              renderCell: (params: GridRenderCellParams<string>) => {
                return (
                  <Link underline="hover" to={`../../../users/${params.row.userId}/details`}>
                    {params.row.userId}
                  </Link>
                );
              },
              headerAlign: 'center',
              align: 'center',
            };
          case 'isClosed':
            return {
              headerName: t(key),
              field: key,
              minWidth: 70,
              flex: 1,
              renderCell: (params: GridRenderCellParams<boolean>) => (
                <BooleanSpan t={t} invert={true} boolean={params.row.isClosed} />
              ),
            };
          default:
            return {
              headerName: t(key),
              field: key,
              minWidth: 150,
              flex: 1,
            };
        }
      })
    : [];

  return (
    <div>
      <Box display="flex" mb={4}>
        <Button
          onClick={() => navigate(`../add`)}
          variant="contained"
          size="medium"
          startIcon={<FontAwesomeIcon icon={faAdd} />}
        >
          {t('lookup.users.titles.addTestUser')}
        </Button>
      </Box>
      <SearchUser onSubmit={onSubmit} isLoading={isLoading} />
      <StyledDataGrid
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        loading={isLoading}
        pagination={!quickSearchData}
        paginationMode="server"
        pageSize={quickSearchData ? undefined : PAGE_SIZE}
        page={advancedSearchData?.pagingDetails?.currentPage}
        rowCount={advancedSearchData?.pagingDetails?.totalItems}
        onPageChange={newPage => setPage(newPage)}
        columns={headers}
        rows={data}
      />
    </div>
  );
};

export default Lookup;
