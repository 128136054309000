import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ELanguagesSchema,
  EServiceContractUpdateTypeSchema,
  getGetServiceContractsQueryKey,
  TranslationsSchema,
  useAddVersionToServiceContract,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Skeleton,
  Switch,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import RichTextEditor from 'src/app/components/RichTextEditor';

import { ErrorCard } from '@greenisland-common/components/atoms';
import Select from '@greenisland-common/components/atoms/Select';

import { SERVICE_CONTRACTS_ERROR_REASONS } from './serviceContractConstants';

interface Props {
  required: boolean;
  contractContent: TranslationsSchema;
  contractId: number;
  open: boolean;
  onClose: () => void;
}

const ServiceContractsUpdateDialog = ({ required, contractContent, contractId, open, onClose }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [selectedUpdateType, setSelectedUpdateType] = useState<EServiceContractUpdateTypeSchema>(
    EServiceContractUpdateTypeSchema.Patch
  );
  const [requiredToSign, setRequiredToSign] = useState<boolean>(required);

  const serviceContractLanguages: ELanguagesSchema[] = contractContent
    .map(content => content.language)
    .filter((language): language is ELanguagesSchema => language !== undefined);

  const otherLanguages: ELanguagesSchema[] = Object.values(ELanguagesSchema).filter(
    lang => !serviceContractLanguages.includes(lang)
  );

  const [contentLanguages, setContentLanguages] = useState<ELanguagesSchema[]>(serviceContractLanguages);
  const [remainingLanguages, setRemainingLanguages] = useState<ELanguagesSchema[]>(otherLanguages);
  const [selectedContentLanguage, setSelectedContentLanguage] = useState(contentLanguages[0]);
  const [selectedNewLanguage, setSelectedNewLanguage] = useState(remainingLanguages[0]);
  const [updatedContent, setUpdatedContent] = useState<TranslationsSchema>([]);
  const [isFetching, setIsFetching] = useState(true);
  const [errorLanguages, setErrorLanguages] = useState<string[]>([]);

  const fetchContent = useCallback(
    async (url: string, language: string): Promise<string> => {
      try {
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error('Failed to fetch content.');
        }

        const data = await response.text();

        return data;
      } catch (error) {
        console.error('Error fetching content: ', error);
        setErrorLanguages(prevErrorLanguages => [...prevErrorLanguages, language]);
        return `<p>${t('serviceContracts.update.placeholders.fetchError')}</p>`;
      }
    },
    [setErrorLanguages, t]
  );

  useEffect(() => {
    const fetchAllContent = async () => {
      const results = await Promise.all(
        contractContent
          .filter(content => content.content && content.language)
          .map(async content => {
            const fetchedContent = await fetchContent(content.content as string, content.language as string);
            return { language: content.language, content: fetchedContent };
          })
      );
      setUpdatedContent(results);
      setIsFetching(false);
    };

    fetchAllContent();
  }, [contractContent, fetchContent]);

  const handleRichTextEditorChange = (value: string) => {
    const existingIndex = updatedContent.findIndex(item => item.language === selectedContentLanguage);

    if (existingIndex !== -1) {
      // Replace the existing content for the language
      const newContent = [...updatedContent];
      newContent[existingIndex] = { language: selectedContentLanguage, content: value };
      setUpdatedContent(newContent);
    } else {
      // Append new content if language wasn't added yet
      setUpdatedContent([...updatedContent, { language: selectedContentLanguage, content: value }]);
    }
  };

  const { mutate: updateServiceContract, isLoading } = useAddVersionToServiceContract({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetServiceContractsQueryKey());
        onClose();
      },
      onError: error => {
        const translationKey =
          error && 'type' in error && error.type && SERVICE_CONTRACTS_ERROR_REASONS[error.type]
            ? SERVICE_CONTRACTS_ERROR_REASONS[error.type]
            : 'somethingWentWrong';

        enqueueSnackbar(t(translationKey), { variant: 'error' });
      },
    },
  });

  const handleUpdateContract = () => {
    updateServiceContract({
      contractId: contractId,
      data: {
        updateType: selectedUpdateType,
        requiredToSign: requiredToSign,
        content: updatedContent,
      },
    });
  };

  const getInitialValue = () => {
    const currentValue = updatedContent.find(translation => translation.language === selectedContentLanguage)?.content;
    if (currentValue) {
      return currentValue;
    }
    return `<p>${t('serviceContracts.update.placeholders.newLanguage')}</p>`;
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose} fullWidth>
      <Box>
        <Card>
          <DialogTitle>{t('serviceContracts.update.title')}</DialogTitle>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
          <DialogContent>
            <Box display="flex" flexDirection="row" mb={4}>
              <Select
                id="updateType"
                label={t('serviceContracts.update.updateType')}
                value={selectedUpdateType}
                onChange={event => setSelectedUpdateType(event.target.value as EServiceContractUpdateTypeSchema)}
                options={Object.values(EServiceContractUpdateTypeSchema)}
                sx={{ maxWidth: '180px' }}
              />
              <FormControlLabel
                control={
                  <Switch onChange={event => setRequiredToSign(event.target.checked)} checked={requiredToSign} />
                }
                label={t('serviceContracts.update.requiredToSign')}
                sx={{ marginLeft: 'auto' }}
              />
            </Box>
            <Box mb={1}>{t('serviceContracts.update.content')}</Box>
            {errorLanguages.length > 0 && (
              <ErrorCard sx={{ height: '50px', mb: 2 }}>
                {t('serviceContracts.errors.errorLanguages', { languages: errorLanguages.join(', ') })}{' '}
              </ErrorCard>
            )}
            <Box display="flex" gap={1} flexDirection="row" mb={4}>
              <Select
                id="remainingLanguages"
                label={t('serviceContracts.content.contentLanguage')}
                value={selectedNewLanguage}
                onChange={event => setSelectedNewLanguage(event.target.value as ELanguagesSchema)}
                options={remainingLanguages.map(language => language.toString())}
                sx={{ maxWidth: '180px' }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  setContentLanguages([...contentLanguages, selectedNewLanguage]);
                  setSelectedContentLanguage(selectedNewLanguage);
                  const newRemainingLanguages = remainingLanguages.filter(language => language !== selectedNewLanguage);
                  setRemainingLanguages(newRemainingLanguages);
                  setSelectedNewLanguage(newRemainingLanguages[0]);
                }}
              >
                {t('serviceContracts.update.addLanguage')}
              </Button>
            </Box>
            <Box display="flex" gap={1} flexDirection="column">
              <Select
                id="contentLanguage"
                label={t('serviceContracts.update.newLanguage')}
                value={selectedContentLanguage}
                onChange={event => setSelectedContentLanguage(event.target.value as ELanguagesSchema)}
                options={contentLanguages.map(language => language.toString())}
              />
              {isFetching ? (
                <Skeleton variant="rectangular" height={200} />
              ) : (
                <RichTextEditor
                  key={`Editor-${selectedContentLanguage}`}
                  initialValue={getInitialValue()}
                  onEditorChange={handleRichTextEditorChange}
                />
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button fullWidth={true} variant="text" onClick={onClose} color="secondary">
              {t('cancel')}
            </Button>
            <LoadingButton
              fullWidth={true}
              variant="contained"
              onClick={() => handleUpdateContract()}
              loading={isLoading || isFetching}
              disabled={isLoading || isFetching}
            >
              {t('serviceContracts.update.update')}
            </LoadingButton>
          </DialogActions>
        </Card>
      </Box>
    </Dialog>
  );
};

export default ServiceContractsUpdateDialog;
