import { BoosterPackCardSchema, BoosterPackSchema, ImageSchema } from '@greenisland-api';

interface OrdinalCard {
  card: number;
  weight: number;
}

interface Ordinal {
  name: string;
  cards: OrdinalCard[];
}

interface BoosterPackCardActions {
  label: string;
  value: string;
}

interface BoosterPackDialogSchema extends BoosterPackSchema {
  boosterPackImages: ImageSchema[] | null;
  ordinals: Ordinal[];
}

interface BoosterPackCardDialogSchema extends BoosterPackCardSchema {
  boosterPackCardImages: ImageSchema[] | null;
}

enum BoosterActionMode {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

enum BoosterFormType {
  BOOSTER_PACKS = 'BOOSTER_PACKS',
  BOOSTER_PACK_CARDS = 'BOOSTER_PACK_CARDS',
}

export type { OrdinalCard, Ordinal, BoosterPackCardActions, BoosterPackDialogSchema, BoosterPackCardDialogSchema };

export { BoosterActionMode, BoosterFormType };
