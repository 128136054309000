import React from 'react';
import { Box, ListItem, ListItemText, Typography } from '@mui/material';

interface Props {
  title: string;
  value: JSX.Element | string | null | undefined;
}

const RegistrationSummaryListItem = ({ title, value }: Props) => {
  return (
    <ListItem disableGutters alignItems="flex-start">
      <ListItemText
        disableTypography
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          whiteSpace: 'normal',
          overflowWrap: 'break-word',
        }}
      >
        <Typography variant="subtitle2" sx={{ minWidth: '250px' }}>
          {title}
        </Typography>
        <Box sx={{ flex: '1 1 auto', width: '100%', wordBreak: 'break-all' }}>
          {typeof value === 'string' ? (
            <Typography variant="body2" sx={{ minWidth: '180px', width: '100%' }}>
              {value}
            </Typography>
          ) : value ? (
            value
          ) : (
            '-'
          )}
        </Box>
      </ListItemText>
    </ListItem>
  );
};

export default RegistrationSummaryListItem;
