/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  ContentFilterBadRequestResponse,
  ContentFilterSchema,
  ContentFiltersSchema,
  DefaultResponse,
  GetContentFiltersParams,
  NotFoundResponse,
  UnauthorizedResponse,
  UpdateContentFilterRequestSchema,
  UpdateContentFilterSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get all unarchived content filters
 */
export const useGetContentFiltersHook = () => {
  const getContentFilters = useCustomInstance<ContentFiltersSchema>();

  return (params?: GetContentFiltersParams, signal?: AbortSignal) => {
    return getContentFilters({ url: `/content/filters`, method: 'get', params, signal });
  };
};

export const getGetContentFiltersQueryKey = (params?: GetContentFiltersParams) =>
  [`/content/filters`, ...(params ? [params] : [])] as const;

export const useGetContentFiltersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetContentFiltersHook>>>,
  TError = UnauthorizedResponse | DefaultResponse
>(
  params?: GetContentFiltersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContentFiltersHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContentFiltersHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContentFiltersQueryKey(params);

  const getContentFilters = useGetContentFiltersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetContentFiltersHook>>>> = ({ signal }) =>
    getContentFilters(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetContentFiltersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetContentFiltersHook>>>
>;
export type GetContentFiltersQueryError = UnauthorizedResponse | DefaultResponse;

/**
 * @summary Get all unarchived content filters
 */
export const useGetContentFilters = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetContentFiltersHook>>>,
  TError = UnauthorizedResponse | DefaultResponse
>(
  params?: GetContentFiltersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContentFiltersHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetContentFiltersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create a new content filter
 */
export const useCreateContentFilterHook = () => {
  const createContentFilter = useCustomInstance<ContentFilterSchema>();

  return (updateContentFilterSchema: UpdateContentFilterSchema) => {
    return createContentFilter({
      url: `/content/filters`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateContentFilterSchema,
    });
  };
};

export const useCreateContentFilterMutationOptions = <
  TError = ContentFilterBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateContentFilterHook>>>,
    TError,
    { data: UpdateContentFilterSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateContentFilterHook>>>,
  TError,
  { data: UpdateContentFilterSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createContentFilter = useCreateContentFilterHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateContentFilterHook>>>,
    { data: UpdateContentFilterSchema }
  > = props => {
    const { data } = props ?? {};

    return createContentFilter(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateContentFilterMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateContentFilterHook>>>
>;
export type CreateContentFilterMutationBody = UpdateContentFilterSchema;
export type CreateContentFilterMutationError = ContentFilterBadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Create a new content filter
 */
export const useCreateContentFilter = <
  TError = ContentFilterBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateContentFilterHook>>>,
    TError,
    { data: UpdateContentFilterSchema },
    TContext
  >;
}) => {
  const mutationOptions = useCreateContentFilterMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a specific content filter by id
 */
export const useGetContentFilterByIdHook = () => {
  const getContentFilterById = useCustomInstance<ContentFilterSchema>();

  return (contentFilterId: number, signal?: AbortSignal) => {
    return getContentFilterById({ url: `/content/filters/${contentFilterId}`, method: 'get', signal });
  };
};

export const getGetContentFilterByIdQueryKey = (contentFilterId: number) =>
  [`/content/filters/${contentFilterId}`] as const;

export const useGetContentFilterByIdQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetContentFilterByIdHook>>>,
  TError = UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  contentFilterId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContentFilterByIdHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContentFilterByIdHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContentFilterByIdQueryKey(contentFilterId);

  const getContentFilterById = useGetContentFilterByIdHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetContentFilterByIdHook>>>> = ({ signal }) =>
    getContentFilterById(contentFilterId, signal);

  return { queryKey, queryFn, enabled: !!contentFilterId, ...queryOptions };
};

export type GetContentFilterByIdQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetContentFilterByIdHook>>>
>;
export type GetContentFilterByIdQueryError = UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get a specific content filter by id
 */
export const useGetContentFilterById = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetContentFilterByIdHook>>>,
  TError = UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  contentFilterId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetContentFilterByIdHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetContentFilterByIdQueryOptions(contentFilterId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update an existing content filter
 */
export const useUpdateContentFilterHook = () => {
  const updateContentFilter = useCustomInstance<void>();

  return (contentFilterId: number, updateContentFilterRequestSchema: UpdateContentFilterRequestSchema) => {
    return updateContentFilter({
      url: `/content/filters/${contentFilterId}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: updateContentFilterRequestSchema,
    });
  };
};

export const useUpdateContentFilterMutationOptions = <
  TError = ContentFilterBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateContentFilterHook>>>,
    TError,
    { contentFilterId: number; data: UpdateContentFilterRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateContentFilterHook>>>,
  TError,
  { contentFilterId: number; data: UpdateContentFilterRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateContentFilter = useUpdateContentFilterHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateContentFilterHook>>>,
    { contentFilterId: number; data: UpdateContentFilterRequestSchema }
  > = props => {
    const { contentFilterId, data } = props ?? {};

    return updateContentFilter(contentFilterId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateContentFilterMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateContentFilterHook>>>
>;
export type UpdateContentFilterMutationBody = UpdateContentFilterRequestSchema;
export type UpdateContentFilterMutationError =
  | ContentFilterBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Update an existing content filter
 */
export const useUpdateContentFilter = <
  TError = ContentFilterBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateContentFilterHook>>>,
    TError,
    { contentFilterId: number; data: UpdateContentFilterRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateContentFilterMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Archive an existing content filter
 */
export const useArchiveContentFilterHook = () => {
  const archiveContentFilter = useCustomInstance<void>();

  return (contentFilterId: number) => {
    return archiveContentFilter({ url: `/content/filters/${contentFilterId}/archive`, method: 'patch' });
  };
};

export const useArchiveContentFilterMutationOptions = <
  TError = ContentFilterBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveContentFilterHook>>>,
    TError,
    { contentFilterId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useArchiveContentFilterHook>>>,
  TError,
  { contentFilterId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const archiveContentFilter = useArchiveContentFilterHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useArchiveContentFilterHook>>>,
    { contentFilterId: number }
  > = props => {
    const { contentFilterId } = props ?? {};

    return archiveContentFilter(contentFilterId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ArchiveContentFilterMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useArchiveContentFilterHook>>>
>;

export type ArchiveContentFilterMutationError =
  | ContentFilterBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Archive an existing content filter
 */
export const useArchiveContentFilter = <
  TError = ContentFilterBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveContentFilterHook>>>,
    TError,
    { contentFilterId: number },
    TContext
  >;
}) => {
  const mutationOptions = useArchiveContentFilterMutationOptions(options);

  return useMutation(mutationOptions);
};
