import React, { Attributes, PropsWithChildren, Ref, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useSelected } from 'slate-react';

interface BaseProps {
  className: string;
  [key: string]: unknown;
}

const useStyles = makeStyles({
  buttonStyles: {
    cursor: 'pointer',
  },
  menu: {
    '& > *': {
      display: 'inline-block',
    },
    '& > * + *': {
      marginLeft: '15px',
    },
  },
});

export const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean;
        reversed: boolean;
      } & BaseProps
    >,
    ref: Ref<HTMLDivElement> | undefined
  ) => {
    const classes = useStyles();
    return (
      <span
        {...props}
        ref={ref}
        className={classNames(className, classes.buttonStyles)}
        style={{ color: reversed ? (active ? 'white' : '#aaa') : active ? 'black' : '#ccc' }}
      />
    );
  }
);

const Menu = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLDivElement> | undefined) => {
    const classes = useStyles();
    return <div {...props} data-test-id="menu" ref={ref} className={classNames(className, classes.menu)} />;
  }
);

export const Toolbar = React.forwardRef(
  ({ className, ...props }: PropsWithChildren<BaseProps>, ref: Ref<HTMLDivElement> | undefined) => (
    <Menu
      {...props}
      ref={ref}
      className={className}
      styles={{
        position: 'relative',
        padding: '1px 18px 17px',
        margin: '0 -20px',
        borderBottom: '2px solid #eee',
        marginBottom: '20px',
      }}
    />
  )
);

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{ fontSize: 0 }}>
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);

const allowedSchemes = ['http:', 'https:', 'mailto:', 'tel:'];
export const LinkComponent = ({
  attributes,
  children,
  element,
}: {
  attributes: Attributes;
  children: React.ReactNode;
  element: any;
}) => {
  const selected = useSelected();

  const safeUrl = useMemo(() => {
    let parsedUrl: URL | null = null;
    try {
      parsedUrl = new URL(element.url);
    } catch {
      // Ignored: If the URL is invalid, we default to 'about:blank'
    }
    if (parsedUrl && allowedSchemes.includes(parsedUrl.protocol)) {
      return parsedUrl.href;
    }
    return 'about:blank';
  }, [element.url]);

  return (
    <a
      {...attributes}
      href={safeUrl}
      style={{
        ...(selected && { boxShadow: '0 0 0 3px #ddd' }),
      }}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  );
};
