import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

const DeclineWithdrawalDialog = memo((props: { close: () => void; submit: (reason: string) => void }) => {
  const { close, submit } = props;
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState<string>('');

  const handleSubmit = () => {
    if (inputValue.length > 0) {
      submit(inputValue);
      close();
    }
  };

  return (
    <Dialog open={true} fullWidth onClose={close}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t('declineDialog')}</DialogTitle>
        <DialogContent>
          <TextField
            label={t('reason')}
            error={inputValue.length === 0}
            fullWidth
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ minWidth: 'auto !important', maxWidth: 'inherit !important' }}>
          <Button onClick={() => close()} color="secondary" variant="text">
            {t('cancel')}
          </Button>
          <Button type="submit" color="error" disabled={inputValue.length === 0}>
            {t('decline')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});

export default DeclineWithdrawalDialog;
