import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Box,
  capitalize,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getUnixTime } from 'date-fns';

import { DATE_INPUT_MASK, DATETIME_INPUT_FORMAT } from '@greenisland-common/components/atoms/Constants';
import DateTimeField from '@greenisland-common/components/molecules/DateTimeInput';
import Input from '@greenisland-common/components/molecules/Input';

import { TournamentConfigurationMode } from '../helpers/tournamentConfigFormInterfaces';

interface Props {
  mode: TournamentConfigurationMode;
}

const TournamentConfigGeneralSettings = ({ mode = TournamentConfigurationMode.ADD }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { control, watch } = useFormContext();

  const startDate = watch('validity.from');
  const endDate = watch('validity.to');

  const isCreateMode = mode === TournamentConfigurationMode.ADD;

  return (
    <Stack spacing={2}>
      <Card>
        <Box sx={{ ml: 2, mb: 2, mt: 1 }}>
          <FormControlLabel
            label={
              <Typography fontWeight="bold" fontSize="medium">
                {t('content.gameserver.tournamentconfiguration.form.options.activate')}
              </Typography>
            }
            control={
              <Controller
                control={control}
                name="active"
                defaultValue={false}
                render={({ value, onChange }) => (
                  <Switch
                    sx={{ mr: 1 }}
                    edge="end"
                    onChange={event => onChange(event.target.checked)}
                    checked={value}
                  />
                )}
              />
            }
          />
        </Box>
        <CardHeader title={t('content.gameserver.tournamentconfiguration.form.generalsettings.title')} />
        <CardContent>
          <Box display="flex" flexDirection="column">
            <Input
              size="small"
              label={t('content.gameserver.tournamentconfiguration.tournamentName')}
              control={control}
              type="text"
              name="tournamentName"
              required
              rules={{ required: t('fieldIsRequired') }}
              sx={{
                [theme.breakpoints.up('xs')]: {
                  width: '100%',
                  mb: 2,
                },
                [theme.breakpoints.up('md')]: {
                  width: '45%',
                },
              }}
              disabled={mode === TournamentConfigurationMode.EDIT}
              defaultValue={''}
            />
            <Input
              size="small"
              label={t('content.gameserver.tournamentconfiguration.tournamentDescription')}
              control={control}
              type="text"
              name="tournamentDescription"
              required
              rules={{ required: t('fieldIsRequired') }}
              sx={{
                [theme.breakpoints.up('xs')]: {
                  width: '100%',
                  mb: 2,
                },
                [theme.breakpoints.up('md')]: {
                  width: '45%',
                },
              }}
              defaultValue={''}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
              sx={{
                [theme.breakpoints.up('xs')]: {
                  flexDirection: 'column',
                },
                [theme.breakpoints.up('md')]: {
                  flexDirection: 'row',
                },
              }}
            >
              <DateTimeField
                name="validity.from"
                label={capitalize(t('startDate'))}
                rules={{ required: t('fieldIsRequired'), setValueAs: value => value && getUnixTime(value) }}
                required={true}
                inputFormat={DATETIME_INPUT_FORMAT}
                mask={DATE_INPUT_MASK}
                disabled={mode === TournamentConfigurationMode.EDIT}
                disablePast={isCreateMode}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    width: '100%',
                  },
                  [theme.breakpoints.up('md')]: {
                    width: '45%',
                  },
                }}
                defaultValue={null}
                maxDateTime={endDate ? new Date(endDate * 1000) : undefined}
              />
              <Typography
                variant="body2"
                fontSize="medium"
                fontWeight="bold"
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    my: 1,
                  },
                }}
              >
                {t('content.gameserver.tournamentconfiguration.form.other.until')}
              </Typography>
              <DateTimeField
                name="validity.to"
                label={capitalize(t('endDate'))}
                rules={{ setValueAs: value => (value ? getUnixTime(value) : undefined) }}
                inputFormat={DATETIME_INPUT_FORMAT}
                mask={DATE_INPUT_MASK}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    width: '100%',
                  },
                  [theme.breakpoints.up('md')]: {
                    width: '45%',
                  },
                }}
                defaultValue={null}
                minDateTime={startDate ? new Date(startDate * 1000) : new Date()}
              />
            </Box>
            <Input
              size="small"
              label={t('content.gameserver.tournamentconfiguration.thumbnail')}
              control={control}
              type="text"
              name="thumbnail"
              sx={{
                [theme.breakpoints.up('xs')]: {
                  width: '100%',
                  mb: 2,
                },
                [theme.breakpoints.up('md')]: {
                  width: '45%',
                },
              }}
              defaultValue={''}
            />
            <Input
              size="small"
              label={t('content.gameserver.tournamentconfiguration.banner')}
              control={control}
              type="text"
              name="banner"
              sx={{
                [theme.breakpoints.up('xs')]: {
                  width: '100%',
                  mb: 2,
                },
                [theme.breakpoints.up('md')]: {
                  width: '45%',
                },
              }}
              defaultValue={''}
            />
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default TournamentConfigGeneralSettings;
