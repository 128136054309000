import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EventModelSchema, SortOrderParamParameter, useGetPagedEventstreamByStreamId } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Stack, TextField } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { DataGridContainer, PermissionWrapper } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';
import Select from '@greenisland-common/components/atoms/Select';

import { useUpdateSearchParams } from '@greenisland-common/hooks/useUpdateSearchParams';

import { prettyPrintJson } from '@greenisland-common/helpers';

import { getDateTimeFromUnix } from '../../../../app/helpers/transformFunctions';
import usePermission from '../../../../app/hooks/usePermission';
import { EventStreamParamsType } from '../Components/DetailsComponents/Constants/Eventstreams';

type Row = EventModelSchema & { id: string };

const PAGE = 0;
const PAGE_SIZE = 25;

const EventsStreamById = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const updateSearchParamsHandler = useUpdateSearchParams();
  const canReadEventStreamById = usePermission(OnlineCasinoPermissions.getPagedEventstreamByStreamId);

  const streamIdQuery = searchParams.get(EventStreamParamsType.STREAM_ID);
  const pageQuery = searchParams.get(EventStreamParamsType.PAGE);
  const pageSizeQuery = searchParams.get(EventStreamParamsType.PAGE_SIZE);
  const sortOrderQuery = searchParams.get(EventStreamParamsType.SORT_BY);

  const [streamId, setStreamId] = useState(streamIdQuery);

  const {
    data: eventStreams,
    isLoading,
    isError,
  } = useGetPagedEventstreamByStreamId(
    {
      streamId: streamIdQuery && streamIdQuery?.length > 0 ? streamIdQuery : '',
      sortOrder: sortOrderQuery ? (sortOrderQuery as SortOrderParamParameter) : SortOrderParamParameter.descending,
      pageSize: pageSizeQuery ? Number(pageSizeQuery) : PAGE_SIZE,
      page: pageQuery ? Number(pageQuery) : PAGE,
    },
    {
      query: {
        enabled: !!streamIdQuery && canReadEventStreamById,
      },
    }
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'timestamp',
        headerName: t('timestamp'),
        flex: 0.2,
        sortable: false,
        minWidth: 150,
        renderCell(params: GridRenderCellParams<Row>) {
          return params.row.timestamp > 0 ? (
            <span style={{ whiteSpace: 'nowrap' }}>{getDateTimeFromUnix(params.row.timestamp, true)}</span>
          ) : null;
        },
      },
      {
        field: 'eventName',
        headerName: t('eventName'),
        flex: 0.2,
        sortable: false,
        minWidth: 150,
      },
      {
        field: 'payload',
        headerName: t('payload'),
        flex: 0.6,
        renderCell: (params: GridRenderCellParams<Row>) => (
          <pre
            dangerouslySetInnerHTML={{ __html: prettyPrintJson(JSON.parse(params.row.payload), { linkUrls: true }) }}
          />
        ),
        sortable: false,
        minWidth: 150,
      },
    ],
    [t]
  );

  const rows = useMemo(
    () => eventStreams?.events?.map(event => ({ ...event, id: JSON.parse(event.payload)['EventId'].toString() })) ?? [],
    [eventStreams]
  );

  const applyStreamIdHandler = () => {
    if (streamId) {
      updateSearchParamsHandler(EventStreamParamsType.STREAM_ID, streamId);
    }
  };

  return (
    <Stack spacing={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
          <TextField
            size="small"
            type="text"
            required={true}
            label={capitalize(t('lookup.eventstreams.streamId'))}
            value={streamId || ''}
            onChange={event => setStreamId(event.target.value.trim())}
            InputProps={{
              endAdornment: streamId && (
                <FontAwesomeIcon
                  onClick={() => {
                    setStreamId(null);
                    updateSearchParamsHandler(EventStreamParamsType.STREAM_ID, '');
                  }}
                  style={{ visibility: streamId ? 'visible' : 'hidden', marginRight: '10px', cursor: 'pointer' }}
                  icon={faCircleXmark}
                />
              ),
            }}
          />
          <Button variant="contained" onClick={applyStreamIdHandler}>
            {t('search')}
          </Button>
        </Box>
        <Select
          id={EventStreamParamsType.SORT_BY}
          label={capitalize(t('sortByOrder'))}
          value={sortOrderQuery || SortOrderParamParameter.descending}
          onChange={event => updateSearchParamsHandler(EventStreamParamsType.SORT_BY, event.target.value)}
          options={Object.values(SortOrderParamParameter)}
        />
      </Box>
      <PermissionWrapper
        errorMessage={t('lookup.eventstreams.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getPagedEventstreamByStreamId}
      >
        <DataGridContainer>
          <LimitedDataGrid
            density="compact"
            autoHeight
            loading={isLoading}
            rows={rows}
            columns={columns}
            pagination
            page={pageQuery ? Number(pageQuery) : PAGE}
            rowCount={eventStreams?.pagingDetails?.totalItems}
            pageSize={pageSizeQuery ? Number(pageSizeQuery) : PAGE_SIZE}
            paginationMode="server"
            onPageChange={page => {
              if (!isLoading) {
                updateSearchParamsHandler(EventStreamParamsType.PAGE, page.toString());
              }
            }}
            onPageSizeChange={pageSize =>
              updateSearchParamsHandler(EventStreamParamsType.PAGE_SIZE, pageSize.toString())
            }
            componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            getRowHeight={() => 'auto'}
            sx={{
              '& .MuiDataGrid-cell': {
                overflowWrap: 'anywhere',
              },
            }}
          />
        </DataGridContainer>
      </PermissionWrapper>
    </Stack>
  );
};

export default EventsStreamById;
