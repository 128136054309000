import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  Base64FileSchema,
  EIdentityFileTypeSchema,
  getGetUserVerificationsQueryKey,
  useDeleteUserVerificationsIdentityFile,
  UserDocumentMetadataSchema,
  useUploadUserVerificationsIdentityFile,
} from '@greenisland-api';
import { useSnackbar } from 'notistack';

import { FileUploadProps } from '@greenisland-common/components/molecules/FileUpload/FileUpload';
import FileUploadDialog from '@greenisland-common/components/molecules/FileUpload/FileUploadDialog';

interface Props extends FileUploadProps {
  userId: string;
  fileType: EIdentityFileTypeSchema;
  metadata?: UserDocumentMetadataSchema;
}

const UploadIdentityFileField = ({ id, userId, fileType, metadata }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const mutationObject = {
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetUserVerificationsQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: async () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  };

  const uploadFileMutation = useUploadUserVerificationsIdentityFile(mutationObject);
  const deleteFileMutation = useDeleteUserVerificationsIdentityFile(mutationObject);

  const deleteVerificationsIdentityFile: (fileType: EIdentityFileTypeSchema) => void = async fileType =>
    deleteFileMutation.mutate({ userId, fileType });

  const uploadVerificationsIdentityFile: (fileType: EIdentityFileTypeSchema, file: Base64FileSchema) => void = async (
    fileType,
    file
  ) => uploadFileMutation.mutate({ userId, fileType, data: file });

  const uploadFile = (fileType: EIdentityFileTypeSchema, file: Base64FileSchema) =>
    uploadVerificationsIdentityFile(fileType, file);
  const deleteFile = (file: EIdentityFileTypeSchema) => deleteVerificationsIdentityFile(file);

  return (
    <FileUploadDialog
      id={id}
      requirements={{ size: 5, mimeTypes: ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'] }}
      saveFile={file => uploadFile(fileType, file)}
      deleteFile={() => deleteFile(fileType)}
      metadata={metadata}
    />
  );
};

export default UploadIdentityFileField;
