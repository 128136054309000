/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */

export type OperatorMarketId = (typeof OperatorMarketId)[keyof typeof OperatorMarketId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorMarketId = {
  DEF: 'DEF',
  ES: 'ES',
  NL: 'NL',
} as const;
