import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { actionCreators, IEntityState } from '@greenisland/stores';
import { useAppDispatch } from '@greenisland-core/store';
import { EntitiesSchema, EntitySchema, ProductSchema } from '@greenisland-store/authorization';

import { BaseRouting } from '../RouteInterfaces';
import BackofficeRoutesWrapper from './BackofficeRoutesWrapper';

interface BackofficeEntityWrapperProps extends BaseRouting {
  entitySelected: IEntityState;
  agentEntities?: EntitiesSchema;
}

const BackofficeEntityWrapper = ({ entitySelected, agentEntities, ...restProps }: BackofficeEntityWrapperProps) => {
  const { selectedEntity, selectedProduct } = entitySelected;
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const setProduct = (entity: string, product: string) =>
    dispatch(actionCreators.setProduct({ selectedEntity: entity, selectedProduct: product }));

  const redirectUrl = sessionStorage.getItem('redirectUrl');

  const checkIfExists = useCallback(
    (urlEntity: string, urlProduct: string) => {
      let exists = false;
      if (agentEntities) {
        const currentEntity = agentEntities.find((entity: EntitySchema) => {
          return entity.entityName === urlEntity;
        });

        if (currentEntity) {
          const currentProduct = currentEntity.products.find(
            (product: ProductSchema) => product.productName === urlProduct
          );
          if (currentProduct) {
            exists = true;
          }
        }
      }

      return exists;
    },
    [agentEntities]
  );

  useEffect(() => {
    if (redirectUrl) {
      const [, entity, product] = redirectUrl.split('/');

      const exists = checkIfExists(entity, product);
      if (exists) {
        sessionStorage.removeItem('redirectUrl');
        navigate(redirectUrl, { replace: true });
      }

      if (!exists) {
        navigate('/oops');
      }

      sessionStorage.removeItem('redirectUrl');
    } else {
      const [, entity, product, ...rest] = location.pathname.split('/');

      if (entity && product) {
        const exists = checkIfExists(entity, product);

        if (exists && (entity !== selectedEntity || product !== selectedProduct)) {
          navigate(`/${selectedEntity}/${selectedProduct}/${rest.join('/')}`);
        }
      } else if (location.pathname != '' && location.pathname != '/') {
        navigate('/oops');
      }
    }
  }, [redirectUrl, checkIfExists, location.pathname, navigate, selectedEntity, selectedProduct]);

  useEffect(() => {
    window.addEventListener('popstate', (e: PopStateEvent) => detectPopStateBack(e));

    return () => {
      window.removeEventListener('popstate', detectPopStateBack);
    };
  });

  // Type of e is PopStateEvent, it's set to any so that we can access the location of the event
  const detectPopStateBack = (e: any) => {
    const [, entity, product] = e.target.location.pathname.split('/');
    if (selectedEntity !== entity || selectedProduct !== product) {
      setProduct(entity, product);
    }
  };

  return <BackofficeRoutesWrapper entitySelected={entitySelected} agentEntities={agentEntities} {...restProps} />;
};

export default BackofficeEntityWrapper;
