/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * Enum descriptions:
 * `Communicate` The intent of the intervention type will be communicated to the end user. (for example, the intent to set the user's limit will be communicated.)
 * `Execute` - The intervention type will be executed. (for example, the limit will be set.)

 */
export type EInterventionActionTypeSchema =
  (typeof EInterventionActionTypeSchema)[keyof typeof EInterventionActionTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EInterventionActionTypeSchema = {
  Communicate: 'Communicate',
  Execute: 'Execute',
} as const;
