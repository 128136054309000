/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type UserLimitV2UpdateRequestStateParamParameter =
  (typeof UserLimitV2UpdateRequestStateParamParameter)[keyof typeof UserLimitV2UpdateRequestStateParamParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserLimitV2UpdateRequestStateParamParameter = {
  approved: 'approved',
  declined: 'declined',
  expired: 'expired',
  queued: 'queued',
} as const;
