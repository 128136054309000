import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useParams } from 'react-router';
import { ERegulatorSchema, EUserLimitV2TypeSchema } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box } from '@mui/material';
import { useTenantContext } from 'src/app/context/TenantContext';

import useFeatureFlags from '@greenisland-common/hooks/useFeatureFlags';

import AccountData from './DetailsComponents/AccountData/AccountData';
import AddictionMonitor from './DetailsComponents/AddictionMonitor/AddictionMonitor';
import AvailableBonuses from './DetailsComponents/AvailableBonuses';
import BalanceLimitsCard from './DetailsComponents/BalanceLimits/BalanceLimitsCard';
import Boosts from './DetailsComponents/Boosts';
import Complaints from './DetailsComponents/Complaints';
import Contact from './DetailsComponents/Contact';
import DepositBonuses from './DetailsComponents/DepositBonuses';
import DepositLimitsCard from './DetailsComponents/DepositLimits/DepositLimitsCard';
import LastDeposits from './DetailsComponents/Deposits/LastDeposits';
import DiscardedPromoCredits from './DetailsComponents/DiscardedPromoCredits';
import WhitelistedCredentialsCard from './DetailsComponents/Fraud/WhitelistedCredentialsCard';
import FreeSpins from './DetailsComponents/FreeSpins/FreeSpins';
import GamesActivity from './DetailsComponents/GamesActivity/GamesActivity';
import Identity from './DetailsComponents/Identity';
import Interventions from './DetailsComponents/Interventions';
import Loyalty from './DetailsComponents/Loyalty/Loyalty';
import LoyaltyTransactionsCard from './DetailsComponents/LoyaltyTransactions/LoyaltyTransactionsCard';
import OpenKYCMatches from './DetailsComponents/OpenKYCMatches/OpenKYCMatches';
import Pgsi from './DetailsComponents/Pgsi';
import PlayingBreaks from './DetailsComponents/PlayingBreak/PlayingBreaks';
import Regulator from './DetailsComponents/Regulator/Regulator';
import SessionLimitsCard from './DetailsComponents/SessionLimits/SessionLimitsCard';
import UserData from './DetailsComponents/UserData/UserData';
import UserStatus from './DetailsComponents/UserStatus';
import UserVerification from './DetailsComponents/UserVerification/UserVerification';
import VaultBalance from './DetailsComponents/VaultBalance';
import Vouchers from './DetailsComponents/Vouchers';
import WithdrawalRequests from './DetailsComponents/WithdrawRequests/WithdrawRequests';
import { usePermission } from '../../../../app/hooks';
import GameSessionCard from '../../GameSessionsPage/Components/GameSessionCard';
import { SessionGameCardType } from '../../GameSessionsPage/Constants';
import ResponsibleGamingTestResponsesCard from '../../ResponsibleGaming/ResponsibleGamingTestResponses/ResponsibleGamingTestResponsesCard';

const LookupDetails = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const canReadAccountData = usePermission(
    [OnlineCasinoPermissions.getBasicAccountData, OnlineCasinoPermissions.getAllAccountData],
    {
      strict: false,
    }
  );
  const tenant = useTenantContext();
  const isVaultEnabled = useFeatureFlags('isVaultEnabled');
  const isVouchersEnabled = useFeatureFlags('isVouchersEnabled');
  const isLoyaltyEnabled = useFeatureFlags('isLoyaltyEnabled');
  const isBonusesEnabled = useFeatureFlags('isBonusesEnabled');
  const isPromoEnabled = useFeatureFlags('isPromoEnabled');
  const isBoostEnabled = useFeatureFlags('isBoostEnabled');
  const isKycEnabled = useFeatureFlags('isKycEnabled');
  const { enabledLimitTypes } = tenant.limitConfigurations;

  return (
    <Fragment key={userId}>
      <UserData userId={userId} />
      <Box
        sx={{
          marginTop: 2,
          spacing: 1,
        }}
      >
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 1200: 2, 1536: 3 }}>
          <Masonry gutter="10px">
            <Identity userId={userId} />
            <Contact userId={userId} />
            <UserVerification userId={userId} />
            <Regulator userId={userId} />
            <UserStatus userId={userId} />
            <AccountData userId={userId} />
            <PlayingBreaks userId={userId} />
            {isLoyaltyEnabled && <Loyalty userId={parseInt(userId)} />}
            <GamesActivity id={userId} />
            <GameSessionCard cardType={SessionGameCardType.LOOKUP} title={t('gameSessions.titles.lastWeekTitle')} />
            {isKycEnabled && <OpenKYCMatches id={userId} />}
            {isVaultEnabled && <VaultBalance userId={userId} />}
            {isVouchersEnabled && <Vouchers userId={userId} />}
            {tenant.regulatorV2 === ERegulatorSchema.KSA && <Pgsi userId={userId} />}
            {tenant.regulatorV2 === ERegulatorSchema.KSA && <Complaints userId={parseInt(userId, 10)} />}
            {tenant.regulatorV2 === ERegulatorSchema.KSA && <Interventions userId={userId} />}
            {tenant.regulatorV2 === ERegulatorSchema.KSA ||
              (tenant.regulatorV2 === ERegulatorSchema.DGOJ && <AddictionMonitor userId={userId} />)}
            {enabledLimitTypes.includes(EUserLimitV2TypeSchema.DepositLimit) && <DepositLimitsCard userId={userId} />}
            {enabledLimitTypes.includes(EUserLimitV2TypeSchema.LoginSessionLimit) && (
              <SessionLimitsCard userId={userId} />
            )}
            {enabledLimitTypes.includes(EUserLimitV2TypeSchema.BalanceLimit) && <BalanceLimitsCard userId={userId} />}
            <WhitelistedCredentialsCard userId={userId}></WhitelistedCredentialsCard>
            {tenant.regulatorV2 === ERegulatorSchema.DGOJ && <ResponsibleGamingTestResponsesCard />}
          </Masonry>
        </ResponsiveMasonry>
      </Box>
      {canReadAccountData && (
        <>
          <Box mt={2}>
            <WithdrawalRequests userId={userId} />
          </Box>
          <Box mt={2}>
            <LastDeposits userId={userId} />
          </Box>
          {isBonusesEnabled && (
            <Box mt={2}>
              <AvailableBonuses userId={userId} />
            </Box>
          )}
          {isBonusesEnabled && (
            <Box mt={2}>
              <DepositBonuses userId={userId} />
            </Box>
          )}
          {isBoostEnabled && (
            <Box mt={2}>
              <Boosts userId={userId} />
            </Box>
          )}
          {isPromoEnabled && (
            <Box mt={2}>
              <FreeSpins userId={userId} />
            </Box>
          )}
          {isPromoEnabled && (
            <Box mt={2}>
              <DiscardedPromoCredits userId={userId} />
            </Box>
          )}
          {isLoyaltyEnabled && (
            <Box mt={2}>
              <LoyaltyTransactionsCard userId={parseInt(userId)} />
            </Box>
          )}
        </>
      )}
    </Fragment>
  );
};

export default LookupDetails;
