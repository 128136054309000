/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  BoostSchema,
  CreateGlobalBoostBadRequestResponse,
  DefaultResponse,
  GetBoostsParams,
  GetBoostUserStatesForUserParams,
  NotFoundResponse,
  PagedBoostOverviewsSchema,
  PagedBoostUserStatesSchema,
  UnauthorizedResponse,
  UpdateGlobalBoostSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get all boosts.
 */
export const useGetBoostsHook = () => {
  const getBoosts = useCustomInstance<PagedBoostOverviewsSchema>();

  return (params?: GetBoostsParams, signal?: AbortSignal) => {
    return getBoosts({ url: `/boosts`, method: 'get', params, signal });
  };
};

export const getGetBoostsQueryKey = (params?: GetBoostsParams) => [`/boosts`, ...(params ? [params] : [])] as const;

export const useGetBoostsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBoostsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetBoostsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBoostsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBoostsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBoostsQueryKey(params);

  const getBoosts = useGetBoostsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBoostsHook>>>> = ({ signal }) =>
    getBoosts(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetBoostsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetBoostsHook>>>>;
export type GetBoostsQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get all boosts.
 */
export const useGetBoosts = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBoostsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetBoostsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBoostsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBoostsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create a new global boost.
 */
export const useCreateGlobalBoostHook = () => {
  const createGlobalBoost = useCustomInstance<BoostSchema>();

  return (updateGlobalBoostSchema: UpdateGlobalBoostSchema) => {
    return createGlobalBoost({
      url: `/boosts/global`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateGlobalBoostSchema,
    });
  };
};

export const useCreateGlobalBoostMutationOptions = <
  TError = CreateGlobalBoostBadRequestResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateGlobalBoostHook>>>,
    TError,
    { data: UpdateGlobalBoostSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateGlobalBoostHook>>>,
  TError,
  { data: UpdateGlobalBoostSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createGlobalBoost = useCreateGlobalBoostHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateGlobalBoostHook>>>,
    { data: UpdateGlobalBoostSchema }
  > = props => {
    const { data } = props ?? {};

    return createGlobalBoost(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateGlobalBoostMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateGlobalBoostHook>>>
>;
export type CreateGlobalBoostMutationBody = UpdateGlobalBoostSchema;
export type CreateGlobalBoostMutationError = CreateGlobalBoostBadRequestResponse | DefaultResponse;

/**
 * @summary Create a new global boost.
 */
export const useCreateGlobalBoost = <
  TError = CreateGlobalBoostBadRequestResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateGlobalBoostHook>>>,
    TError,
    { data: UpdateGlobalBoostSchema },
    TContext
  >;
}) => {
  const mutationOptions = useCreateGlobalBoostMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get all boost user states for specific user.
 */
export const useGetBoostUserStatesForUserHook = () => {
  const getBoostUserStatesForUser = useCustomInstance<PagedBoostUserStatesSchema>();

  return (userId: number, params?: GetBoostUserStatesForUserParams, signal?: AbortSignal) => {
    return getBoostUserStatesForUser({ url: `/boosts/user-states/${userId}`, method: 'get', params, signal });
  };
};

export const getGetBoostUserStatesForUserQueryKey = (userId: number, params?: GetBoostUserStatesForUserParams) =>
  [`/boosts/user-states/${userId}`, ...(params ? [params] : [])] as const;

export const useGetBoostUserStatesForUserQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBoostUserStatesForUserHook>>>,
  TError = UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params?: GetBoostUserStatesForUserParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBoostUserStatesForUserHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBoostUserStatesForUserHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBoostUserStatesForUserQueryKey(userId, params);

  const getBoostUserStatesForUser = useGetBoostUserStatesForUserHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBoostUserStatesForUserHook>>>> = ({
    signal,
  }) => getBoostUserStatesForUser(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetBoostUserStatesForUserQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBoostUserStatesForUserHook>>>
>;
export type GetBoostUserStatesForUserQueryError = UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get all boost user states for specific user.
 */
export const useGetBoostUserStatesForUser = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBoostUserStatesForUserHook>>>,
  TError = UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params?: GetBoostUserStatesForUserParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBoostUserStatesForUserHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBoostUserStatesForUserQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
