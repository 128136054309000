import * as React from 'react';
import { EBGCCanExceedResponseSchema, EBGCLimitStatusResponseSchema } from '@greenisland-api';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: 'left',
  '&.allowed': {
    color: theme.palette.success.dark,
  },
  '&.notAllowed': {
    color: theme.palette.error.dark,
  },
  '&.canexceed': {
    color: theme.palette.success.dark,
  },
  '&.pending': {
    color: theme.palette.warning.dark,
  },
  '&.cannotexceed': {
    color: theme.palette.error.dark,
  },
}));

interface StatusProps {
  status: EBGCCanExceedResponseSchema | EBGCLimitStatusResponseSchema;
}

const Status = React.memo((props: StatusProps) => {
  const { status } = props;
  let color: any = 'default';
  if (status === 'allowed' || status === 'canexceed') {
    color = 'success';
  } else if (status === 'notAllowed' || status === 'cannotexceed') {
    color = 'error';
  } else if (status === 'pending') {
    color = 'warning';
  }

  return <StyledChip color={color} size="small" label={status} variant="filled" />;
});

export function renderStatus(
  params: GridRenderCellParams<EBGCCanExceedResponseSchema | EBGCLimitStatusResponseSchema>
) {
  if (params.value == null) {
    return '';
  }

  return <Status status={params.value} />;
}
