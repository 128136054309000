import { MouseEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { faAngleDoubleUp, faPencil } from '@fortawesome/free-solid-svg-icons';
import { GameSchema, GameSortingTypeParameter, useGetGamesByQueryFilters } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useDebouncedValue } from '@lilib/hooks';
import { DragIndicator } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';
import type { GridRenderCellParams, GridRowOrderChangeParams } from '@mui/x-data-grid-pro';
import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-pro';

import {
  CheckboxIcon,
  DataGridContainer,
  FontAwesomeIcon,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import useGetGameFiltersQuery from './hooks/useGetGameFiltersQuery';
import useUpdateGameFilter from './hooks/useUpdateGameFilter';
import { usePermission } from '../../../../../app/hooks';
import ImageCell from '../Components/ImageCell';
import { GameFilterType } from './constants';
import GamesFilter from './GamesFilter';
import { useUpdateGamesOrder } from './hooks';

const PAGE_SIZE = 25;

type Row = GameSchema & { id: string | undefined };

const { listOrder } = GameSortingTypeParameter;

const GamesTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();

  const canReadGames = usePermission(OnlineCasinoPermissions.getGamesByQueryFilters);
  const canOrderGames = usePermission(OnlineCasinoPermissions.updateGamesV2Order);
  const canUpdateGame = usePermission(OnlineCasinoPermissions.updateGameV2);

  const {
    sortByQuery,
    searchQuery,
    themesQuery,
    categoriesQuery,
    studiosQuery,
    pageQuery,
    pageSizeQuery,
    additionalFiltersQuery,
  } = useGetGameFiltersQuery();
  const updateFilterHandler = useUpdateGameFilter();
  const [debouncedSearch] = useDebouncedValue(searchQuery, { wait: 500 });
  const additionalFiltersParams = additionalFiltersQuery?.split(',');

  const selectedThemes = themesQuery?.split(',');
  const selectedStudios = studiosQuery?.split(',');
  const selectedCategories = categoriesQuery?.split(',');

  const {
    data: games,
    isLoading,
    isError,
  } = useGetGamesByQueryFilters(
    {
      ...(debouncedSearch && debouncedSearch?.length > 0 ? { searchString: debouncedSearch } : {}),
      ...{ sorting: (sortByQuery as GameSortingTypeParameter) || GameSortingTypeParameter.releaseDate },
      ...(selectedThemes && selectedThemes?.length > 0 ? { themes: selectedThemes } : {}),
      ...(selectedStudios && selectedStudios?.length > 0 ? { studios: selectedStudios } : {}),
      ...(selectedCategories && selectedCategories?.length > 0 ? { categories: selectedCategories } : {}),
      ...(additionalFiltersParams?.includes(GameFilterType.FEATURED) ? { filterFeatured: true } : {}),
      ...(additionalFiltersParams?.includes(GameFilterType.ENABLED) ? { filterEnabled: true } : {}),
      ...(additionalFiltersParams?.includes(GameFilterType.NEW) ? { filterNew: true } : {}),
      ...(additionalFiltersParams?.includes(GameFilterType.POPULAR) ? { filterPopular: true } : {}),
      ...(additionalFiltersParams?.includes(GameFilterType.LIVE) ? { filterLive: true } : {}),
      ...(additionalFiltersParams?.includes(GameFilterType.HAS_JACKPOT) ? { filterHasJackpot: true } : {}),
      ...(additionalFiltersParams?.includes(GameFilterType.EXCLUSIVE) ? { filterExclusive: true } : {}),
      resultsPerPage: pageSizeQuery ? Number(pageSizeQuery) : PAGE_SIZE,
      currentPageNumber: pageQuery ? Number(pageQuery) : 0,
    },
    { query: { enabled: canReadGames } }
  );

  const { mutate: orderGame } = useUpdateGamesOrder();

  const rows = useMemo(
    () => games?.results.map<Row>(game => ({ ...game, id: `${game.gameName} [id: ${game.gameId}]` })) ?? [],
    [games]
  );

  const handleRowOrderChange = useCallback(
    (params: GridRowOrderChangeParams) => {
      const oldRow = rows[params.oldIndex];
      const newRow = rows[params.targetIndex];
      if (typeof newRow.listOrder === 'number')
        orderGame({ gamePKey: parseInt(oldRow.gameId ?? ''), data: { order: newRow.listOrder } });
    },
    [rows, orderGame]
  );

  const navigateToEditPageHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>, row: Row) => {
      const redirectUrl = `providers/${row.providerFk}/games/${row.gameId}${search}`;

      if (event.ctrlKey || event.metaKey) {
        return window.open(`./${redirectUrl}`, '_blank');
      } else {
        return navigate(`../${redirectUrl}`);
      }
    },
    [navigate, search]
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      ...(sortByQuery !== listOrder
        ? [
            {
              field: '__reorder__',
              maxWidth: 51,
              headerName: '',
              renderCell: () => (
                <Tooltip style={{ alignSelf: 'center' }} title={t('content.games.games.enableOrderTooltip')}>
                  <DragIndicator color="disabled" />
                </Tooltip>
              ),
            },
          ]
        : []),
      { field: 'gameId', headerName: t('id'), flex: 0.1, sortable: false, minWidth: 100 },
      { field: 'gameName', headerName: t('content.games.games.gameName'), flex: 1, sortable: false, minWidth: 150 },
      {
        field: 'providerName',
        headerName: t('content.games.games.providerName'),
        flex: 0.8,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'gameImages.background',
        headerName: t('content.games.games.background'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Row>) => <ImageCell value={params.row.gameImages.background} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'gameImages.thumbnailSquare',
        headerName: t('content.games.games.thumbnailSquare'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Row>) => <ImageCell value={params.row.gameImages.thumbnailSquare} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'gameImages.thumbnailLandscape',
        headerName: t('content.games.games.thumbnailLandscape'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Row>) => (
          <ImageCell value={params.row.gameImages.thumbnailLandscape} />
        ),
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'gameImages.thumbnailPortrait',
        headerName: t('content.games.games.thumbnailPortrait'),
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<Row>) => (
          <ImageCell value={params.row.gameImages.thumbnailPortrait} />
        ),
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'featured',
        headerName: t('content.games.games.featured'),
        type: 'boolean',
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        sortable: false,
        minWidth: 100,
        editable: canUpdateGame,
      },
      {
        field: 'enabled',
        headerName: t('content.games.games.enabled'),
        type: 'boolean',
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        sortable: false,
        minWidth: 100,
      },
      ...(canUpdateGame || canOrderGames
        ? [
            {
              field: 'actions',
              type: 'actions',
              getActions: (params: GridRowParams<Row>) => [
                canOrderGames && (
                  <Tooltip title={sortByQuery !== listOrder ? t('content.games.games.enableOrderTooltip') : ''}>
                    <div>
                      <GridActionsCellItem
                        key="moveToTop"
                        title={t('content.games.games.moveToTop')}
                        disabled={sortByQuery !== listOrder}
                        icon={<FontAwesomeIcon icon={faAngleDoubleUp} />}
                        label={t('content.games.games.moveToTop')}
                        onClick={() =>
                          orderGame({ gamePKey: parseInt(params.row.gameId ?? ''), data: { moveToTop: true } })
                        }
                      />
                    </div>
                  </Tooltip>
                ),
                canUpdateGame && (
                  <GridActionsCellItem
                    title={t('content.games.games.edit')}
                    icon={<FontAwesomeIcon icon={faPencil} />}
                    label={t('content.games.games.edit')}
                    onClick={event => navigateToEditPageHandler(event, params.row)}
                    key="edit"
                  />
                ),
              ],
              flex: 0.5,
            },
          ]
        : []),
    ],
    [sortByQuery, t, canUpdateGame, canOrderGames, orderGame, navigateToEditPageHandler]
  );

  return (
    <Stack spacing={2}>
      <GamesFilter />
      <PermissionWrapper
        errorMessage={t('content.games.games.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getGamesByQueryFilters}
      >
        <DataGridContainer>
          <LimitedDataGrid
            density="compact"
            autoHeight
            loading={isLoading}
            rows={rows}
            columns={columns}
            pagination
            page={games?.pagingDetails?.currentPage}
            rowCount={games?.pagingDetails?.totalItems}
            pageSize={pageSizeQuery ? Number(pageSizeQuery) : PAGE_SIZE}
            paginationMode="server"
            onPageChange={newPage => {
              if (!isLoading) {
                updateFilterHandler(GameFilterType.PAGE, newPage.toString());
              }
            }}
            onPageSizeChange={pageSize => updateFilterHandler(GameFilterType.PAGE_SIZE, pageSize.toString())}
            rowReordering={canOrderGames}
            onRowOrderChange={handleRowOrderChange}
            componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            experimentalFeatures={{ newEditingApi: canUpdateGame }}
          />
        </DataGridContainer>
      </PermissionWrapper>
    </Stack>
  );
};

export default GamesTable;
