import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';

import type { IndividualActionForm } from '../context';
import IndividualActionFormListItem from './IndividualActionFormListItem';

const VaultTokenForm = () => {
  const { t } = useTranslation();
  const { register, watch, setValue, errors } = useFormContext<IndividualActionForm>();
  const enabledTokens = watch('enabledTokens');

  useEffect(() => {
    if (!enabledTokens) {
      setValue('amountOfTokens', '');
    }
  }, [setValue, enabledTokens]);

  return (
    <IndividualActionFormListItem enabled={enabledTokens} name="enabledTokens" listItemText={t('vaultTokens')}>
      <TextField
        size="small"
        inputRef={register({ required: enabledTokens, min: 1 })}
        name="amountOfTokens"
        disabled={!enabledTokens}
        error={Boolean(errors.amountOfTokens)}
        required={enabledTokens}
        label={t('amountOfTokens')}
        type="number"
      />
    </IndividualActionFormListItem>
  );
};

export default VaultTokenForm;
