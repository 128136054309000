import { useTranslation } from 'react-i18next';
import { StatisticsDecimalSchema, StatisticsSchema } from '@greenisland-api';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Box, Card, CardContent, CardHeader, Grid, Tooltip, Typography } from '@mui/material';

import { formatCompactNumber } from '@greenisland-common/helpers';

import { StatisticsCardVariant } from './Constants';

interface Props {
  cardVariant: StatisticsCardVariant;
  statistics: StatisticsSchema | StatisticsDecimalSchema;
}

const calculatePercentageChange = (current: number, previous: number): number => {
  if (previous === 0) return 100;
  return Math.round(((current - previous) / previous) * 100);
};

const StatisticsCard = ({ cardVariant, statistics }: Props) => {
  const { t } = useTranslation();

  const calculateColor = (current: number, previous: number): string => {
    return current >= previous ? 'green' : 'red';
  };

  const calculateGridSizes = (): number[] => {
    const labels = ['Daily', 'Weekly', 'Monthly'];
    const totalLength = labels.reduce((acc, label) => acc + label.length, 0);
    const gridSizes = labels.map(label => Math.round((label.length / totalLength) * 12));

    // Adjust the sizes to ensure the total adds up to 12
    const totalGridSize = gridSizes.reduce((acc, size) => acc + size, 0);
    const sizeDifference = 12 - totalGridSize;

    if (sizeDifference !== 0) {
      gridSizes[gridSizes.length - 1] += sizeDifference;
    }

    return gridSizes;
  };

  const gridSizes = calculateGridSizes();

  return (
    <Card elevation={1} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardHeader
        title={t(cardVariant)}
        sx={{
          textAlign: 'center',
          '.MuiCardHeader-title': {
            fontSize: '14px',
            fontWeight: '600',
          },
        }}
      />
      <CardContent>
        <Grid container textAlign="center">
          {[
            { label: 'Daily', data: statistics.dailyStatistics },
            { label: 'Weekly', data: statistics.weeklyStatistics },
            { label: 'Monthly', data: statistics.monthlyStatistics },
          ].map((item, index) => (
            <Grid
              key={index}
              item
              xs={gridSizes[index]}
              sx={{ borderRight: index < 2 ? 1 : 0, borderColor: 'divider' }}
            >
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                {item.label}
              </Typography>
              <Box sx={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Tooltip title={item.data.valueCurrentPeriod.toString()}>
                    <Typography variant="body2" sx={{ fontWeight: '700' }}>
                      {formatCompactNumber(item.data.valueCurrentPeriod)}
                    </Typography>
                  </Tooltip>
                  {(() => {
                    const percentageChange = calculatePercentageChange(
                      item.data.valueCurrentPeriod,
                      item.data.valuePreviousPeriod
                    );
                    const arrowUp = percentageChange >= 0;
                    const color = calculateColor(item.data.valueCurrentPeriod, item.data.valuePreviousPeriod);

                    return (
                      <Typography variant="caption" color={color} sx={{ mt: -0.75, fontSize: '10px', lineHeight: '1' }}>
                        {percentageChange}%
                        {arrowUp ? (
                          <TrendingUpIcon sx={{ fontSize: 'inherit' }} />
                        ) : (
                          <TrendingDownIcon sx={{ fontSize: 'inherit' }} />
                        )}
                      </Typography>
                    );
                  })()}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StatisticsCard;
