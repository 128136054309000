import { DataGridProProps } from '@mui/x-data-grid-pro';

import StyledDataGrid from '../StyledDataGrid';

const LimitedDataGrid = (props: DataGridProProps) => {
  const disabledProps = {
    disableDensitySelector: true,
    disableSelectionOnClick: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
    disableColumnMenu: true,
    disableChildrenSorting: true,
    disableChildrenFiltering: true,
    disableMultipleColumnsSorting: true,
    disableColumnResize: true,
    disableColumnReorder: true,
    isRowSelectable: () => false,
  };

  const mergedProps = { ...props, ...disabledProps };

  return <StyledDataGrid {...mergedProps} />;
};

export default LimitedDataGrid;
