import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import {
  Currency,
  ExtraRequirementsCondition,
  getQueryTournamentConfigurationsQueryKey,
  RequirementCondition,
} from '@greenisland-store/gameServer';
import { useSnackbar } from 'notistack';

import { TournamentDurationsEnum, TournamentPrizePoolEnum } from './tournamentConfigFormInterfaces';

const useGetTournamentDurationValues = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { value: TournamentDurationsEnum.YEARS, label: t('years') },
      { value: TournamentDurationsEnum.MONTHS, label: t('months') },
      { value: TournamentDurationsEnum.WEEKS, label: t('weeks') },
      { value: TournamentDurationsEnum.DAYS, label: t('days') },
      { value: TournamentDurationsEnum.HOURS, label: t('hours') },
    ],
    [t]
  );
};

const useGetTournamentCurrencyValues = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        value: Currency.EUR,
        label: t('content.gameserver.tournamentconfiguration.form.buyinsettings.currency.euro'),
      },
    ],
    [t]
  );
};

const useGetTournamentPrizePoolValues = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        value: TournamentPrizePoolEnum.Monetary,
        label: t('content.gameserver.tournamentconfiguration.form.prizepoolsettings.type.monetary'),
      },
      {
        value: TournamentPrizePoolEnum.NonMonetary,
        label: t('content.gameserver.tournamentconfiguration.form.prizepoolsettings.type.nonmonetary'),
      },
    ],
    [t]
  );
};

const useGetTournamentExtraRequirementsConditionValues = () => {
  const { t } = useTranslation();
  return [
    {
      value: ExtraRequirementsCondition.IS_ALL_OF,
      label: t('content.gameserver.tournamentconfiguration.isAllOf'),
    },
    {
      value: ExtraRequirementsCondition.IS_ANY_OF,
      label: t('content.gameserver.tournamentconfiguration.isAnyOf'),
    },
    {
      value: ExtraRequirementsCondition.IS_NOT_ONE_OF,
      label: t('content.gameserver.tournamentconfiguration.isNotOneOf'),
    },
    {
      value: ExtraRequirementsCondition.IS_ONE_OF,
      label: t('content.gameserver.tournamentconfiguration.isOneOf'),
    },
  ];
};

const useGetTournamentExtraCompoundRequirementsConditionValues = () => {
  const { t } = useTranslation();
  return [
    {
      value: RequirementCondition.EQUALS,
      label: t('content.gameserver.tournamentconfiguration.equals'),
    },
    {
      value: RequirementCondition.DOES_NOT_EQUAL,
      label: t('content.gameserver.tournamentconfiguration.doesNotEqual'),
    },
    {
      value: RequirementCondition.IS_GREATER_THAN,
      label: t('content.gameserver.tournamentconfiguration.isGreaterThan'),
    },
    {
      value: RequirementCondition.IS_LESS_THAN,
      label: t('content.gameserver.tournamentconfiguration.isLessThan'),
    },
    {
      value: RequirementCondition.IS_GREATER_THAN_OR_EQUAL_TO,
      label: t('content.gameserver.tournamentconfiguration.isGreaterThanOrEqualTo'),
    },
    {
      value: RequirementCondition.IS_LESS_THAN_OR_EQUAL_TO,
      label: t('content.gameserver.tournamentconfiguration.isLessThanOrEqualTo'),
    },
    {
      value: RequirementCondition.IS_ONE_OF,
      label: t('content.gameserver.tournamentconfiguration.isOneOf'),
    },
    {
      value: RequirementCondition.IS_NOT_ONE_OF,
      label: t('content.gameserver.tournamentconfiguration.isNotOneOf'),
    },
  ];
};

const useTournamentConfigurationFormHandlers = (reset: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries(getQueryTournamentConfigurationsQueryKey());
    enqueueSnackbar(t('success'), { variant: 'success' });
    reset();
    navigate('../');
  }, [queryClient, enqueueSnackbar, t, reset, navigate]);

  const onError = useCallback(
    error => {
      if (error) {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      }
    },
    [enqueueSnackbar, t]
  );

  return { onSuccess, onError };
};

export {
  useGetTournamentDurationValues,
  useGetTournamentCurrencyValues,
  useGetTournamentPrizePoolValues,
  useGetTournamentExtraRequirementsConditionValues,
  useGetTournamentExtraCompoundRequirementsConditionValues,
  useTournamentConfigurationFormHandlers,
};
