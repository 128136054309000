/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */

export type TournamentConfigurationOrderByParameter =
  (typeof TournamentConfigurationOrderByParameter)[keyof typeof TournamentConfigurationOrderByParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TournamentConfigurationOrderByParameter = {
  id: 'ID',
  validFrom: 'VALID_FROM',
} as const;
