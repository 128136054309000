import React from 'react';
import { useTranslation } from 'react-i18next';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { SelfTestSchema } from '@greenisland-api';
import { Box, capitalize, Dialog, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';

import { DataGridContainer, DataGridPlain, FontAwesomeIcon } from '@greenisland-common/components/atoms';

import PgsiSummaryStats from './PgsiSummaryStats';

interface PgsiDialogProps {
  openDialog: boolean;
  setOpenDialog: (state: boolean) => void;
  test: SelfTestSchema;
}

export const ANSWERS = [
  'pgsi.answers.never',
  'pgsi.answers.sometimes',
  'pgsi.answers.mostoftime',
  'pgsi.answers.always',
];

const QuestionField = ({ question, value, isMobile }: { question: string; value: string; isMobile: boolean }) => {
  if (!isMobile) {
    return <Box component="span">{question}</Box>;
  }
  return (
    <Box display="flex" flexDirection="column">
      <Box component="span" mb={1}>
        {question}
      </Box>
      <Box component="span" fontWeight={600} mb={1}>
        {value}
      </Box>
    </Box>
  );
};

interface Row {
  id: string;
  title: string;
  value: string;
}

const PgsiDialog = ({ openDialog, setOpenDialog, test }: PgsiDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const headers: GridColumns<Row> = [
    {
      headerName: '',
      field: 'id',
      flex: 0.05,
    },
    {
      headerName: '',
      field: 'title',
      renderCell: params => <QuestionField question={params.row.title} value={params.row.value} isMobile={isMobile} />,
      flex: 1,
    },
    { headerName: '', field: 'value', flex: 0.2 },
  ];

  const rows = [
    {
      id: 1,
      title: t('pgsi.question1'),
      value: t(ANSWERS[test.Q1Score as number]),
    },
    {
      id: 2,
      title: t('pgsi.question2'),
      value: t(ANSWERS[test.Q2Score as number]),
    },
    {
      id: 3,
      title: t('pgsi.question3'),
      value: t(ANSWERS[test.Q3Score as number]),
    },
    {
      id: 4,
      title: t('pgsi.question4'),
      value: t(ANSWERS[test.Q4Score as number]),
    },
    {
      id: 5,
      title: t('pgsi.question5'),
      value: t(ANSWERS[test.Q5Score as number]),
    },
    {
      id: 6,
      title: t('pgsi.question6'),
      value: t(ANSWERS[test.Q6Score as number]),
    },
    {
      id: 7,
      title: t('pgsi.question7'),
      value: t(ANSWERS[test.Q7Score as number]),
    },
    {
      id: 8,
      title: t('pgsi.question8'),
      value: t(ANSWERS[test.Q8Score as number]),
    },
    {
      id: 9,
      title: t('pgsi.question9'),
      value: t(ANSWERS[test.Q9Score as number]),
    },
  ];

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      fullWidth
      maxWidth="lg"
      sx={{ '& .MuiPaper-root': { backgroundColor: 'white' } }}
    >
      <DialogTitle sx={{ backgroundColor: 'white' }}>
        {capitalize(t('pgsi.title'))}
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialog(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <PgsiSummaryStats test={test} />
      <DataGridContainer sx={{ pb: 3 }}>
        <DataGridPlain
          columns={headers}
          rows={rows}
          getRowHeight={() => 'auto'}
          columnVisibilityModel={{
            // Hide columns status and traderName, the other columns will remain visible
            value: !isMobile,
          }}
        />
      </DataGridContainer>
    </Dialog>
  );
};

export default PgsiDialog;
