import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetDailyResults, useGetWeeklyResults } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Card, CardContent, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getUnixTime } from 'date-fns';

import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { downloadBase64File } from '@greenisland-common/helpers';

import { usePermission } from '../../../../../app/hooks';

const StyledCard = styled(Card)(({ theme }) => ({
  width: 'calc(100% / 2)',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

const Export = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const canReadDailyResults = usePermission(OnlineCasinoPermissions.getDailyResults);
  const canReadWeeklyResults = usePermission(OnlineCasinoPermissions.getWeeklyResults);

  const { data: dailyResults, isLoading: loadingDaily } = useGetDailyResults(
    {
      startdate: getUnixTime(startDate),
      enddate: getUnixTime(endDate),
    },
    { query: { enabled: canReadDailyResults } }
  );
  const { data: weeklyResults, isLoading: loadingWeekly } = useGetWeeklyResults(
    {
      startdate: getUnixTime(startDate),
    },
    { query: { enabled: canReadWeeklyResults } }
  );

  const downloadDaily = async () => {
    if (!dailyResults) return;
    downloadBase64File(dailyResults);
  };

  const downloadWeekly = async () => {
    if (!weeklyResults) return;
    downloadBase64File(weeklyResults);
  };

  return canReadDailyResults || canReadWeeklyResults ? (
    <StyledCard>
      <CardContent>
        <Stack spacing={2}>
          <DateRangePicker
            onChange={(startDate, endDate) => {
              setStartDate(new Date(startDate * 1000));
              setEndDate(new Date(endDate * 1000));
            }}
          />
          <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
            {canReadDailyResults && (
              <Button fullWidth variant="contained" color="primary" disabled={loadingDaily} onClick={downloadDaily}>
                {t('downloadDailyResults')}
              </Button>
            )}
            {canReadWeeklyResults && (
              <Button fullWidth variant="contained" color="primary" disabled={loadingWeekly} onClick={downloadWeekly}>
                {t('downloadWeeklyResults')}
              </Button>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </StyledCard>
  ) : null;
};

export default Export;
