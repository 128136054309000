import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

interface Props {
  entries: string[] | undefined;
  title: string;
}

const KYCRELOrRRETable = ({ entries, title }: Props) => {
  const { t } = useTranslation();

  if (!entries || entries.length === 0) {
    return null;
  }

  return (
    <>
      <Typography>{title}</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('kyc.headers.category')}</TableCell>
              <TableCell>{t('kyc.headers.subcategory')}</TableCell>
              <TableCell>{t('kyc.headers.events')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map((entry: string, index: number) => {
              const { category, subcategory, events } = JSON.parse(entry);
              return (
                <TableRow key={index}>
                  <TableCell>{category}</TableCell>
                  <TableCell>{subcategory}</TableCell>
                  <TableCell>
                    {events.map((event: { type?: string; dateIso?: string }, index: number) => (
                      <div key={index}>
                        {event.type && (
                          <>
                            {t('kyc.resource.type')} &apos;{event.type}&apos;{' '}
                          </>
                        )}
                        {event.dateIso && (
                          <>
                            {t('on')} {event.dateIso}
                          </>
                        )}
                      </div>
                    ))}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default memo(KYCRELOrRRETable);
