import { ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize, CircularProgress, InputAdornment, TextField, useTheme } from '@mui/material';

interface Props {
  label: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  data: any;
  isLoading: boolean;
  isError: boolean;
  disabled?: boolean;
}

const TextFieldWithLoader = ({ label, value, setValue, data, isLoading, isError, disabled }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = event.target.value;

    if (/^\d*$/.test(newValue)) {
      setValue(newValue);
    }
  };

  const borderColor = useMemo(
    () => (isError ? theme.palette.error.main : theme.palette.text.secondary),
    [isError, theme.palette.error.main, theme.palette.text.secondary]
  );

  const focusedBorderColor = useMemo(
    () => (isError ? theme.palette.error.main : theme.palette.success.main),
    [isError, theme.palette.error.main, theme.palette.success.main]
  );

  const labelColor = useMemo(
    () => (isError ? theme.palette.error.main : theme.palette.text.secondary),
    [isError, theme.palette.error.main, theme.palette.text.secondary]
  );

  const getAdornment = useMemo(() => {
    return (
      <InputAdornment position="end">
        {isLoading ? (
          <CircularProgress size={20} />
        ) : data || isError ? (
          <FontAwesomeIcon
            icon={data ? faCheckCircle : faExclamationCircle}
            color={data ? theme.palette.success.main : theme.palette.error.main}
          />
        ) : null}
      </InputAdornment>
    );
  }, [data, isError, isLoading, theme.palette.error.main, theme.palette.success.main]);

  return (
    <TextField
      size="small"
      type="text"
      label={capitalize(t(label))}
      onChange={event => onChangeHandler(event)}
      value={value}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor,
          },
          '&:hover fieldset': {
            borderColor,
          },
          '&.Mui-focused fieldset': {
            borderColor: focusedBorderColor,
          },
        },
        '& .MuiFormLabel-root': {
          color: labelColor,
        },
      }}
      InputProps={{
        endAdornment: getAdornment,
      }}
      disabled={disabled}
    />
  );
};

export default TextFieldWithLoader;
