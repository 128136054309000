/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type ETxTypeSchema = (typeof ETxTypeSchema)[keyof typeof ETxTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ETxTypeSchema = {
  Unknown: 'Unknown',
  Win: 'Win',
  Voucher: 'Voucher',
  Promo: 'Promo',
  Deposit: 'Deposit',
  Bet: 'Bet',
  Withdrawal: 'Withdrawal',
  Gift: 'Gift',
  CancelledSportsBetReserve: 'CancelledSportsBetReserve',
  RemainingSportsBetReserve: 'RemainingSportsBetReserve',
  SportsBettingCorrection: 'SportsBettingCorrection',
  RefundCash: 'RefundCash',
  RefundPromo: 'RefundPromo',
  DonationCash: 'DonationCash',
  DonationPoints: 'DonationPoints',
  DepositPromo: 'DepositPromo',
  RegistrationPromo: 'RegistrationPromo',
  CancelledWithdrawal: 'CancelledWithdrawal',
  BirthdayPromo: 'BirthdayPromo',
  StarpointsToPromo: 'StarpointsToPromo',
  TransferFromAccount: 'TransferFromAccount',
  TransferToAccount: 'TransferToAccount',
  ManualDebitPromo: 'ManualDebitPromo',
  ManualCreditCash: 'ManualCreditCash',
  RevokedPromo: 'RevokedPromo',
  CancelledRevokedPromo: 'CancelledRevokedPromo',
  CasinoReferralPayout: 'CasinoReferralPayout',
  PromoToCash: 'PromoToCash',
  RefundRiskFreeBet: 'RefundRiskFreeBet',
  UndoBet: 'UndoBet',
  UndoWin: 'UndoWin',
  PromotionActivated: 'PromotionActivated',
  PromotionDeactivated: 'PromotionDeactivated',
  TournamentRegister: 'TournamentRegister',
  TournamentPayout: 'TournamentPayout',
  TournamentPurchase: 'TournamentPurchase',
  GameCorrectionDebit: 'GameCorrectionDebit',
  GameCorrectionCredit: 'GameCorrectionCredit',
  PromoToCashCorrection: 'PromoToCashCorrection',
  GameProviderCampaignPayout: 'GameProviderCampaignPayout',
  FreeSpinsWinningsPayout: 'FreeSpinsWinningsPayout',
  CashReward: 'CashReward',
  CreditsExpired: 'CreditsExpired',
  VoucherReward: 'VoucherReward',
  TransferToVoucher: 'TransferToVoucher',
  TournamentUndoRegister: 'TournamentUndoRegister',
  DebitBalanceLimitExceeded: 'DebitBalanceLimitExceeded',
  StakeDecrease: 'StakeDecrease',
  Cashout: 'Cashout',
  RefundBet: 'RefundBet',
  VoucherRedeemed: 'VoucherRedeemed',
  OperatorJackpotCredit: 'OperatorJackpotCredit',
  BoostedWin: 'BoostedWin',
  null: null,
} as const;
