import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreateTestUserRequestSchema } from '@greenisland-api';
import { Button, Stack } from '@mui/material';

import AddTestUserDialog from './AddTestUserDialog';
import TestUserBirthdateSettings from './TestUserBirthdateSettings';
import TestUserCountryOfOriginSettings from './TestUserCountryOfOriginSettings';
import TestUserEmailSettings from './TestUserEmailSettings';
import TestUserGenderSettings from './TestUserGenderSettings';
import TestUserNameSettings from './TestUserNameSettings';
import TestUserPasswordSettings from './TestUserPasswordSettings';
import TestUserPreferredLanguageSettings from './TestUserPreferredLanguageSettings';

const AddTestUser = () => {
  const { t } = useTranslation();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const methods = useForm<CreateTestUserRequestSchema>({
    shouldFocusError: true,
    mode: 'onChange',
    defaultValues: {
      password: '',
      email: {
        email: '',
        emailVerified: false,
      },
      language: null,
      identificationCountryOfOrigin: null,
      birthDate: null,
      isMale: null,
      name: {
        firstName: '',
        lastName: '',
        lastNamePrefix: null,
        secondLastName: null,
      },
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = () => {
    setIsConfirmDialogOpen(true);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <TestUserPasswordSettings />
          <TestUserEmailSettings />
          <TestUserNameSettings />
          <TestUserCountryOfOriginSettings />
          <TestUserBirthdateSettings />
          <TestUserGenderSettings />
          <TestUserPreferredLanguageSettings />
        </Stack>
        <Button type="submit" sx={{ mt: 4 }} color="primary" variant="contained">
          {t('create')}
        </Button>
      </form>
      {isConfirmDialogOpen && <AddTestUserDialog open={true} onClose={() => setIsConfirmDialogOpen(false)} />}
    </FormProvider>
  );
};

export default AddTestUser;
