import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import type { IndividualActionForm } from '../context';
import IndividualActionFormListItem from './IndividualActionFormListItem';

const PlainMailForm = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext<IndividualActionForm>();
  const enabledPlain = watch('enabledPlain');

  return (
    <IndividualActionFormListItem enabled={enabledPlain} name="enabledPlain" listItemText={t('plainMail')}>
      <Typography variant="body1">{t('noParametersToConfigure')}</Typography>
    </IndividualActionFormListItem>
  );
};

export default PlainMailForm;
