import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAddToBonusBlacklist, useGetPlayersRevenueContributors } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Link, Stack } from '@mui/material';
import { endOfDay, getUnixTime, sub } from 'date-fns';

import { StyledDataGrid } from '@greenisland-common/components/atoms';
import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import { transformBooleanYesOrNullV2, transformCurrencyV2 } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../../Lookup/LookupPermissions';

const RevenueContributors = () => {
  const canReadRevenueContribution = usePermission(OnlineCasinoPermissions.getPlayersRevenueContributors);
  return canReadRevenueContribution ? <RevenueContributorsInnerChild /> : null;
};

const currentDate = new Date();

const RevenueContributorsInnerChild = () => {
  const { t } = useTranslation();
  const canLookupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });
  const canAddToBonusBlacklist = usePermission(OnlineCasinoPermissions.setBlacklisted);
  const canReadRevenueContribution = usePermission(OnlineCasinoPermissions.getPlayersRevenueContributors);
  const canAnswerBonusRequests = usePermission(OnlineCasinoPermissions.awardPlayersRevenueContributorsBonusRequest);
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = Number(searchParams.get('startdate'));
  const endDate = Number(searchParams.get('enddate'));
  const start = startDate ? Number(startDate) : getUnixTime(sub(currentDate, { days: 1 }));
  const end = endDate ? Number(endDate) : getUnixTime(endOfDay(currentDate));

  const addToBonusBlacklist = useAddToBonusBlacklist();

  const { data: revenueContributors, isLoading } = useGetPlayersRevenueContributors(
    {
      startdate: start,
      enddate: end,
    },
    {
      query: {
        enabled: canReadRevenueContribution,
      },
    }
  );

  const setBlacklisted = async (userId: string) => {
    addToBonusBlacklist.mutate({ userId: Number(userId), data: {} });
  };

  const columns = [
    {
      field: 'username',
      headerName: t('username'),
      flex: 1,
      renderCell: (params: any) =>
        canLookupUser ? (
          <Link href={`../../../users/${params.row.userId}/details`}>{params.value}</Link>
        ) : (
          <span>{params.value}</span>
        ),
    },
    {
      ...transformCurrencyV2('contribution', t),
      flex: 1,
    },
    {
      ...transformBooleanYesOrNullV2('isBonusBlacklisted', t),
      flex: 1,
    },
    {
      field: 'addBonusBlacklist',
      headerName: t('addBonusBlacklist'),
      flex: 1,
      renderCell: (params: any) =>
        !params.row.isBonusBlacklisted && canAddToBonusBlacklist ? (
          <Button onClick={() => setBlacklisted(params.row.userId)} size="small" variant="outlined">
            {t('addBonusBlacklist')}
          </Button>
        ) : null,
    },
    {
      field: 'answerBonusRequest',
      headerName: t('answerBonusRequest'),
      flex: 1,
      renderCell: (params: any) =>
        canAnswerBonusRequests ? (
          <Link href={`${params.row.userId}/bonus-requests`}>{t('answerBonusRequest')}</Link>
        ) : null,
    },
  ];

  return (
    <Stack spacing={2}>
      <DateRangePicker
        unixStartDate={start * 1000}
        unixEndDate={end * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
        }}
      />
      <StyledDataGrid
        columns={columns}
        rows={revenueContributors ?? []}
        loading={isLoading}
        autoHeight
        pagination
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        disableSelectionOnClick
        getRowId={row => row.userId}
      />
    </Stack>
  );
};

export default RevenueContributors;
