import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';

import { formatCurrency } from '@greenisland-common/helpers';

const Value = styled('div')(() => ({
  width: '100%',
  height: '100%',
  lineHeight: '100%',
  fontVariantNumeric: 'tabular-nums',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'normal',
  padding: 1,
  '&.good': {
    backgroundColor: '#a1f6cf',
    color: '#68b674',
  },
  '&.bad': {
    backgroundColor: '#fbe1e3',
    color: '#e73d4a',
  },
}));

interface NetDepositCellProps {
  value?: number;
  currency?: string;
}

const THRESHOLD_AMOUNT_OF_MONEY = 0;

const NetDepositCell = React.memo(function NetDeposit(props: NetDepositCellProps) {
  const { value, currency } = props;
  const { i18n } = useTranslation();

  if (!value) {
    return null;
  }

  return (
    <Value
      className={classnames({
        good: value > THRESHOLD_AMOUNT_OF_MONEY,
        bad: value < THRESHOLD_AMOUNT_OF_MONEY,
      })}
    >
      {formatCurrency(value, i18n.language, currency)}
    </Value>
  );
});

export default NetDepositCell;
