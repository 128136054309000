import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { EVerificationStatusSchema } from '@greenisland-api';
import { styled } from '@mui/material/styles';
import classnames from 'classnames';

const Value = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  lineHeight: '100%',
  fontVariantNumeric: 'tabular-nums',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'normal',
  padding: 1,
  '&.verified': {
    color: theme.palette.success.main,
  },
  '&.unverified': {
    color: theme.palette.warning.main,
  },
  '&.declined': {
    color: theme.palette.error.main,
  },
  '&.waitingForApproval': {
    color: theme.palette.info.main,
  },
}));

interface VerificationCellProps {
  value?: EVerificationStatusSchema;
}

const VerificationCell = React.memo(function Verification(props: VerificationCellProps) {
  const { value } = props;
  const { t } = useTranslation();

  if (!value) {
    return null;
  }

  return (
    <Value
      className={classnames({
        verified:
          value === EVerificationStatusSchema.Verified ||
          value === EVerificationStatusSchema.VerifiedByItsMe ||
          value === EVerificationStatusSchema.VerifiedByDeposit,
        unverified: value === EVerificationStatusSchema.Unverified,
        declined: value === EVerificationStatusSchema.Declined || value === EVerificationStatusSchema.NeedsVerification,
        waitingForApproval: value === EVerificationStatusSchema.WaitingForApproval,
      })}
    >
      {t(value)}
    </Value>
  );
});

export default VerificationCell;
