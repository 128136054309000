import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicAccountSchema, FullAccountSchema, useClearAmlDepositTurnoverRequirements } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import {
  DataGridContainer,
  DataGridPlain,
  DataGridPlainHeader,
  ErrorCard,
  NoData,
} from '@greenisland-common/components/atoms';

import RefundDialog from './Components/RefundDialog';
import { formatCurrency } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import useAccountData from '../../../hooks/useAccountData';
import PromoToCashDialog from '../UserData/Components/PromoToCashDialog';

interface Props {
  userId: string;
}

const headers: GridColumns = [
  {
    headerName: '',
    field: 'title',
    renderCell: ({ value }) => (
      <DataGridPlainHeader sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} value={value} />
    ),
    width: 200,
  },
  { headerName: '', field: 'value', renderCell: ({ value }) => value, flex: 1 },
];

const AccountData = ({ userId }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const canRefund = usePermission(OnlineCasinoPermissions.setRefund);
  const canCorrectPromoToCash = usePermission([
    OnlineCasinoPermissions.getPromoToCashEvents,
    OnlineCasinoPermissions.setCorrectPromoToCash,
  ]);
  const [promoToCashDialog, setPromoToCashDialog] = useState(false);
  const [refundDialog, setRefundDialog] = useState(false);

  const { data: accountData, isLoading, isError, error, permission } = useAccountData(userId);

  const clearAmlDepositTurnoverRequirementsMutation = useClearAmlDepositTurnoverRequirements({
    mutation: {
      onSuccess: async () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  if (!permission) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={323} />;
  if (isError || !accountData) return <ErrorCard sx={{ height: 323 }}>{error?.message}</ErrorCard>;

  const errorStyle = { color: 'error.main' };
  const successStyle = { color: 'success.main' };

  const handleClearAmlDepositTurnoverRequirements = () => {
    clearAmlDepositTurnoverRequirementsMutation.mutate({ userId });
  };

  const {
    accountId,
    balance,
    automaticWithdrawalBalance,
    openCashBets,
    amlDepositTurnoverRequirements,
    totalDeposits,
    totalWithdrawals,
    nettoDeposit,
  } = (accountData.account as Partial<FullAccountSchema> & BasicAccountSchema) || {};

  const rows: GridRowsProp = [
    { id: 1, title: t('accountId'), value: accountId },
    {
      id: 2,
      title: t('balance'),
      value: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box mr={2} component="span" sx={balance === 0 ? errorStyle : undefined}>
            {formatCurrency(balance)}
          </Box>
          {canRefund && (
            <Button variant="outlined" size="small" color="secondary" onClick={() => setRefundDialog(true)}>
              {t('refund')}
            </Button>
          )}
        </Box>
      ),
    },
    {
      id: 3,
      title: t('account.automaticWithdrawalBalance'),
      value: (
        <Box display="flex" alignItems="center">
          {formatCurrency(automaticWithdrawalBalance)}
        </Box>
      ),
    },
    {
      id: 4,
      title: t('openCashBets'),
      value: <Box sx={openCashBets === 0 ? errorStyle : successStyle}>{formatCurrency(openCashBets)}</Box>,
    },
    ...(totalDeposits
      ? [
          {
            id: 5,
            title: t('totalDeposits'),
            value: <Box sx={totalDeposits === 0 ? errorStyle : successStyle}>{formatCurrency(totalDeposits)}</Box>,
          },
        ]
      : []),
    ...(totalWithdrawals
      ? [{ id: 6, title: t('totalWithdrawals'), value: <span>{formatCurrency(totalWithdrawals)}</span> }]
      : []),
    ...(nettoDeposit
      ? [
          {
            id: 7,
            title: t('nettoDeposit'),
            value: (
              <Box component="span" sx={nettoDeposit && nettoDeposit <= 0 ? errorStyle : successStyle}>
                {formatCurrency(nettoDeposit)}
              </Box>
            ),
          },
        ]
      : []),
    {
      id: 8,
      title: t('amlDepositTurnoverRequirements'),
      value: (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ mr: 2, whiteSpace: 'nowrap' }}>
            {formatCurrency(amlDepositTurnoverRequirements || 0)} {t('leftToBet')}
          </Box>
          {Boolean(amlDepositTurnoverRequirements) && (
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              disabled={isLoading}
              onClick={handleClearAmlDepositTurnoverRequirements}
            >
              {t('clear')}
            </Button>
          )}
        </Box>
      ),
    },
  ];

  return (
    <>
      <Card sx={{ pl: 0, pr: 0 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CardHeader title={t('accountData')} />
          {canCorrectPromoToCash && (
            <Button sx={{ mr: 2 }} size="small" variant="outlined" onClick={() => setPromoToCashDialog(true)}>
              {t('promoToCash')}
            </Button>
          )}
        </Box>
        <CardContent sx={{ pl: 0, pr: 0 }}>
          {rows.length > 0 ? (
            <DataGridContainer>
              <DataGridPlain columns={headers} rows={rows} />
            </DataGridContainer>
          ) : (
            <NoData />
          )}
        </CardContent>
      </Card>
      {refundDialog && <RefundDialog open={refundDialog} setOpen={setRefundDialog} userId={userId} />}
      {promoToCashDialog && <PromoToCashDialog open={promoToCashDialog} setOpen={setPromoToCashDialog} />}
    </>
  );
};

export default AccountData;
