import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { AgentTaskMetaDataSchema } from '@greenisland-api';
import { Box, Link, Typography } from '@mui/material';

import KYCProfileDetails from '../../KYC/KYCProfileDetails/KYCProfileDetails';

interface Props {
  metaData: AgentTaskMetaDataSchema;
}

const KYCRiskDetectedDetail = ({ metaData }: Props) => {
  const { t } = useTranslation();

  const matchedProfiles = metaData.externalResourceIds.map((item: string, index: number) => {
    return (
      <Box sx={{ paddingBottom: 2 }} key={index}>
        <Typography sx={{ marginTop: 2, textTransform: 'capitalize' }} variant="body2">
          {t('kyc.agentTask.match')} {index + 1}:
        </Typography>
        <KYCProfileDetails resourceId={item} />
      </Box>
    );
  });
  return (
    <Box sx={{ paddingY: 1, paddingLeft: 2 }}>
      <Typography sx={{ textTransform: 'capitalize' }} variant="body2">
        User ID
      </Typography>
      <Link component={RouterLink} to={`../../users/${metaData.userId}/details`} target="_blank">
        {metaData.userId}
      </Link>
      <Typography sx={{ marginTop: 2, textTransform: 'capitalize' }} variant="body2">
        {t('kyc.agentTask.matches')}
      </Typography>
      {matchedProfiles}
    </Box>
  );
};

export default KYCRiskDetectedDetail;
