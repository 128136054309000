import { ReadPermission, WritePermission } from '@greenisland-core/permissions';
import { EProductTypeSchema } from '@greenisland-store/authorization';

export const OnlineCasinoPermissions = {
  getAddictionCategory: new ReadPermission('/addiction/$userid/category', EProductTypeSchema.OnlineCasino),
  setAddictionCategoryManually: new WritePermission('/addiction/$userid/category', EProductTypeSchema.OnlineCasino),
  getAddictionThresholdExceededUsers: new ReadPermission(
    '/addiction/thresholdexceededusers/$type',
    EProductTypeSchema.OnlineCasino
  ),
  getAllGameLibraryMergeConflicts: new ReadPermission('/gamelibrary/conflicts', EProductTypeSchema.OnlineCasino),
  getGameLibraryLastRuns: new ReadPermission('/gamelibrary/lastruns', EProductTypeSchema.OnlineCasino),
  gameLibrarySync: new WritePermission('/gamelibrary/sync', EProductTypeSchema.OnlineCasino),
  gameLibrarySyncAll: new WritePermission('/gamelibrary/sync/all', EProductTypeSchema.OnlineCasino),
  getMultiplierTournamentWithFilters: new ReadPermission('/multipliertournaments', EProductTypeSchema.OnlineCasino),
  createMultiplierTournament: new WritePermission('/multipliertournaments', EProductTypeSchema.OnlineCasino),
  getMultiplierTournament: new ReadPermission('/multipliertournaments/$tournamentid', EProductTypeSchema.OnlineCasino),
  updateMultiplierTournament: new WritePermission(
    '/multipliertournaments/$tournamentid',
    EProductTypeSchema.OnlineCasino
  ),
  getAllComplaintTickets: new ReadPermission('/complaints', EProductTypeSchema.OnlineCasino),
  createComplaintTicket: new WritePermission('/complaints', EProductTypeSchema.OnlineCasino),
  getComplaintTicket: new ReadPermission('/complaints/$complaintticketid', EProductTypeSchema.OnlineCasino),
  updateComplaintTicket: new WritePermission('/complaints/$complaintticketid', EProductTypeSchema.OnlineCasino),
  archiveComplaintTicket: new WritePermission('/complaints/$complaintticketid', EProductTypeSchema.OnlineCasino),
  addComplaintTicketEntry: new WritePermission('/complaints/$complaintticketid', EProductTypeSchema.OnlineCasino),
  closeComplaintTicket: new WritePermission('/complaints/$complaintticketid/close', EProductTypeSchema.OnlineCasino),
  updateComplaintEntry: new WritePermission(
    '/complaints/$complaintticketid/$complaintentryid',
    EProductTypeSchema.OnlineCasino
  ),
  archiveComplaintEntry: new WritePermission(
    '/complaints/$complaintticketid/$complaintentryid',
    EProductTypeSchema.OnlineCasino
  ),
  getOperatorJackpotConfigs: new ReadPermission('/operator-jackpots/configs', EProductTypeSchema.OnlineCasino),
  createOperatorJackpotConfig: new WritePermission('/operator-jackpots/configs', EProductTypeSchema.OnlineCasino),
  getOperatorJackpotConfig: new ReadPermission('/operator-jackpots/configs/$configid', EProductTypeSchema.OnlineCasino),
  updateOperatorJackpotConfig: new WritePermission(
    '/operator-jackpots/configs/$configid',
    EProductTypeSchema.OnlineCasino
  ),
  archiveOperatorJackpotConfig: new WritePermission(
    '/operator-jackpots/configs/$configid',
    EProductTypeSchema.OnlineCasino
  ),
  launchOperatorJackpotConfig: new WritePermission(
    '/operator-jackpots/configs/$configid/launch',
    EProductTypeSchema.OnlineCasino
  ),
  getOperatorJackpotInstances: new ReadPermission('/operator-jackpots/instances', EProductTypeSchema.OnlineCasino),
  getOperatorJackpotInstance: new ReadPermission(
    '/operator-jackpots/instances/$instanceid',
    EProductTypeSchema.OnlineCasino
  ),
  deleteOperatorJackpotInstance: new WritePermission(
    '/operator-jackpots/instances/$instanceid',
    EProductTypeSchema.OnlineCasino
  ),
  getUserRegistration: new ReadPermission('/regulator/$userid/registration', EProductTypeSchema.OnlineCasino),
  resolveCriticalFieldsMismatch: new WritePermission(
    '/regulator/$userid/critical-fields/mismatch/resolve',
    EProductTypeSchema.OnlineCasino
  ),
  getCriticalFieldsMismatch: new ReadPermission(
    '/regulator/$userid/critical-fields/mismatch',
    EProductTypeSchema.OnlineCasino
  ),
  getUserRegistrationMessages: new ReadPermission(
    '/regulator/$userid/registrationmessages',
    EProductTypeSchema.OnlineCasino
  ),
  addUserRegistrationActionKey: new WritePermission(
    '/regulator/$userid/registration/$actionkey',
    EProductTypeSchema.OnlineCasino
  ),
  scheduleCanExceedRequest: new WritePermission(
    '/regulator/$userid/article6/schedulecanexceed',
    EProductTypeSchema.OnlineCasino
  ),
  requestCanExceedForUser: new WritePermission(
    '/regulator/$userid/article6/requestcanexceed',
    EProductTypeSchema.OnlineCasino
  ),
  deleteRequestCanExceedForUser: new WritePermission(
    '/regulator/$userid/article6/requestcanexceed',
    EProductTypeSchema.OnlineCasino
  ),
  deleteRequestCanExceedForToken: new WritePermission(
    '/regulator/$userid/request-can-exceed/$token',
    EProductTypeSchema.OnlineCasino
  ),
  getExceedDefaultWeeklyDepositLimitRequests: new ReadPermission(
    '/regulator/$userid/article6/exceedrequests',
    EProductTypeSchema.OnlineCasino
  ),
  resetExceedDefaultWeeklyDepositLimitRequests: new WritePermission(
    '/regulator/$userid/article6/exceedrequests/reset',
    EProductTypeSchema.OnlineCasino
  ),
  getScheduledCanExceedRequests: new ReadPermission(
    '/regulator/$userid/article6/scheduledcanexceedrequests',
    EProductTypeSchema.OnlineCasino
  ),
  getLimitStatusForTokenForUser: new WritePermission(
    '/regulator/$userid/article6/limitstatus',
    EProductTypeSchema.OnlineCasino
  ),
  resetRetryCountForUser: new WritePermission(
    '/regulator/$userid/article6/$actiontype/resetretrycount',
    EProductTypeSchema.OnlineCasino
  ),
  getCruksDisturbanceOverview: new ReadPermission(
    '/regulator/cruks/disturbance/overview',
    EProductTypeSchema.OnlineCasino
  ),
  getCruksDisturbanceAffectedUsers: new ReadPermission(
    '/regulator/cruks/disturbance/$disturbanceid/users',
    EProductTypeSchema.OnlineCasino
  ),
  createCDBCorrectionCommand: new WritePermission('/regulator/cdb/correction', EProductTypeSchema.OnlineCasino),
  getCDBRecord: new ReadPermission('/regulator/cdb/record/$recordid', EProductTypeSchema.OnlineCasino),
  getLastActivitySeconds: new ReadPermission('/reporting/lastactivityseconds', EProductTypeSchema.OnlineCasino),
  getEvents: new ReadPermission('/events', EProductTypeSchema.OnlineCasino),
  getEventstreamStreamId: new ReadPermission('/events/streamid', EProductTypeSchema.OnlineCasino),
  getPagedEventstreamByStreamId: new ReadPermission('/events/v2/streamid', EProductTypeSchema.OnlineCasino),
  getEventstreamEventname: new ReadPermission('/events/eventname/$eventname', EProductTypeSchema.OnlineCasino),
  getPagedEventstreamByEventname: new ReadPermission('/events/v2/eventname', EProductTypeSchema.OnlineCasino),
  getUserGeneral: new ReadPermission('/users/$userid/general', EProductTypeSchema.OnlineCasino),
  checkPepSanctionList: new WritePermission('/users/$userid/general', EProductTypeSchema.OnlineCasino),
  updatePhoneNumber: new WritePermission('/users/$userid/phone-number', EProductTypeSchema.OnlineCasino),
  setBlacklisted: new WritePermission('/users/$userid/general/blacklisted', EProductTypeSchema.OnlineCasino),
  createTestUser: new WritePermission('/users/testuser', EProductTypeSchema.OnlineCasino),
  addToBonusBlacklist: new WritePermission('/blacklist/bonus/$userid', EProductTypeSchema.OnlineCasino),
  removeFromBonusBlacklist: new WritePermission('/blacklist/bonus/$userid', EProductTypeSchema.OnlineCasino),
  addToMarketingBlacklist: new WritePermission('/blacklist/marketing/$userid', EProductTypeSchema.OnlineCasino),
  removeFromMarketingBlacklist: new WritePermission('/blacklist/marketing/$userid', EProductTypeSchema.OnlineCasino),
  getWhitelistedCredentials: new ReadPermission(
    '/fraud/payments/users/$userid/whitelist/$whitelisttype',
    EProductTypeSchema.OnlineCasino
  ),
  whitelistCredentials: new WritePermission(
    '/fraud/payments/users/$userid/whitelist/$whitelisttype',
    EProductTypeSchema.OnlineCasino
  ),
  removeCredentialsFromWhitelist: new WritePermission(
    '/fraud/payments/users/$userid/whitelist/$whitelisttype',
    EProductTypeSchema.OnlineCasino
  ),
  markDepositSafe: new WritePermission('/fraud/payments/deposits/$depositid/safe', EProductTypeSchema.OnlineCasino),
  acknowledgeUnsafeDeposit: new WritePermission(
    '/fraud/payments/deposits/$depositid/acknowledge',
    EProductTypeSchema.OnlineCasino
  ),
  setUserStatus: new WritePermission('/users/$userid/status', EProductTypeSchema.OnlineCasino),
  disableSelfExclusion: new WritePermission('/users/$userid/disableselfexclusion', EProductTypeSchema.OnlineCasino),
  enableIsTestUser: new WritePermission('/users/$userid/enableistestuser', EProductTypeSchema.OnlineCasino),
  setUserEmail: new WritePermission('/users/$userid/email', EProductTypeSchema.OnlineCasino),
  sendUserEmailVerification: new WritePermission('/users/$userid/emailverification', EProductTypeSchema.OnlineCasino),
  setAwardWelcomeBonusPack: new WritePermission(
    '/users/$userid/awardwelcomebonuspack',
    EProductTypeSchema.OnlineCasino
  ),
  getPromoToCashEvents: new ReadPermission('/users/$userid/correctpromotocash', EProductTypeSchema.OnlineCasino),
  setCorrectPromoToCash: new WritePermission('/users/$userid/correctpromotocash', EProductTypeSchema.OnlineCasino),
  getUserNotes: new ReadPermission('/users/$userid/notes', EProductTypeSchema.OnlineCasino),
  addUserNote: new WritePermission('/users/$userid/notes', EProductTypeSchema.OnlineCasino),
  deleteUserNote: new WritePermission('/users/$userid/notes/$noteid', EProductTypeSchema.OnlineCasino),
  getUserLinkedAccounts: new ReadPermission('/users/$userid/linkedaccounts', EProductTypeSchema.OnlineCasino),
  getUserLoginSession: new ReadPermission('/users/$userid/loginsession', EProductTypeSchema.OnlineCasino),
  getUserPreferences: new ReadPermission('/users/$userid/preferences', EProductTypeSchema.OnlineCasino),
  getUserServiceContractApprovals: new ReadPermission(
    '/users/$userid/servicecontractapprovals',
    EProductTypeSchema.OnlineCasino
  ),
  getBasicAccountData: new ReadPermission('/users/$userid/basicaccountdata', EProductTypeSchema.OnlineCasino),
  getAllAccountData: new ReadPermission('/users/$userid/allaccountdata', EProductTypeSchema.OnlineCasino),
  getUserAccountStatistics: new ReadPermission('/users/$userid/account/statistics', EProductTypeSchema.OnlineCasino),
  setRefund: new WritePermission('/users/$userid/account/refund', EProductTypeSchema.OnlineCasino),
  getGamesActivity: new ReadPermission('/users/$userid/gamesactivity', EProductTypeSchema.OnlineCasino),
  getUserIdentity: new ReadPermission('/users/$userid/identity', EProductTypeSchema.OnlineCasino),
  getWithdrawLimits: new ReadPermission('/users/$userid/withdrawlimits', EProductTypeSchema.OnlineCasino),
  addWithdrawLimit: new WritePermission('/users/$userid/withdrawlimits', EProductTypeSchema.OnlineCasino),
  updateWithdrawLimit: new WritePermission(
    '/users/$userid/withdrawlimits/$withdrawlimitid',
    EProductTypeSchema.OnlineCasino
  ),
  deleteWithdrawLimit: new WritePermission(
    '/users/$userid/withdrawlimits/$withdrawlimitid',
    EProductTypeSchema.OnlineCasino
  ),
  exportWithdrawalsAndDepositsHistory: new ReadPermission(
    '/users/$userid/withdrawalsanddeposits',
    EProductTypeSchema.OnlineCasino
  ),
  getUserVerification: new ReadPermission('/users/$userid/verification', EProductTypeSchema.OnlineCasino),
  getUserVerifications: new ReadPermission('/users/$userid/verifications', EProductTypeSchema.OnlineCasino),
  postCommunicateVerificationNeeded: new WritePermission(
    '/users/$userid/verifications/communicate',
    EProductTypeSchema.OnlineCasino
  ),
  updateUserVerificationsIdentity: new WritePermission(
    '/users/$userid/verifications/identity',
    EProductTypeSchema.OnlineCasino
  ),
  updateUserVerificationsIdentityVerificationStatus: new WritePermission(
    '/users/$userid/verifications/identity/verificationstatus',
    EProductTypeSchema.OnlineCasino
  ),
  resetStatusToVerificationNeeded: new WritePermission(
    '/users/$userid/verifications/identity/reset-to-verification-needed',
    EProductTypeSchema.OnlineCasino
  ),
  uploadUserVerificationsIdentityFile: new WritePermission(
    '/users/$userid/verifications/identity/$filetype',
    EProductTypeSchema.OnlineCasino
  ),
  deleteUserVerificationsIdentityFile: new WritePermission(
    '/users/$userid/verifications/identity/$filetype',
    EProductTypeSchema.OnlineCasino
  ),
  updateUserVerificationsWalletVerificationStatus: new WritePermission(
    '/users/$userid/verifications/wallets/$walletid/verificationstatus',
    EProductTypeSchema.OnlineCasino
  ),
  updateUserVerificationsBankAccountStatus: new WritePermission(
    '/users/$userid/verifications/bankaccounts/$bankaccountid/verificationstatus',
    EProductTypeSchema.OnlineCasino
  ),
  uploadUserVerificationsBankAccountDocument: new WritePermission(
    '/users/$userid/verifications/bankaccounts/$bankaccountid/bankaccountdocument',
    EProductTypeSchema.OnlineCasino
  ),
  deleteUserVerificationsBankAccountDocument: new WritePermission(
    '/users/$userid/verifications/bankaccounts/$bankaccountid/bankaccountdocument',
    EProductTypeSchema.OnlineCasino
  ),
  archiveUserBankAccountVerification: new WritePermission(
    '/users/$userid/verifications/bankaccounts/$bankaccountnumber/archive',
    EProductTypeSchema.OnlineCasino
  ),
  unarchiveUserBankAccountVerification: new WritePermission(
    '/users/$userid/verifications/bankaccounts/$bankaccountid/unarchive',
    EProductTypeSchema.OnlineCasino
  ),
  uploadUserVerificationsWalletDocument: new WritePermission(
    '/users/$userid/verifications/wallets/$walletid/walletdocument',
    EProductTypeSchema.OnlineCasino
  ),
  deleteUserVerificationsWalletDocument: new WritePermission(
    '/users/$userid/verifications/wallets/$walletid/walletdocument',
    EProductTypeSchema.OnlineCasino
  ),
  archiveUserWalletVerification: new WritePermission(
    '/users/$userid/verifications/wallets/$walletid/archive',
    EProductTypeSchema.OnlineCasino
  ),
  unarchiveUserWalletVerification: new WritePermission(
    '/users/$userid/verifications/wallets/$walletid/unarchive',
    EProductTypeSchema.OnlineCasino
  ),
  updateUserVerificationsDomicile: new WritePermission(
    '/users/$userid/verifications/domicile',
    EProductTypeSchema.OnlineCasino
  ),
  updateUserVerificationsDomicileVerificationStatus: new WritePermission(
    '/users/$userid/verifications/domicile/verificationstatus',
    EProductTypeSchema.OnlineCasino
  ),
  uploadUserVerificationsDomicileDocument: new WritePermission(
    '/users/$userid/verifications/domicile/domiciledocument',
    EProductTypeSchema.OnlineCasino
  ),
  deleteUserVerificationsDomicileDocument: new WritePermission(
    '/users/$userid/verifications/domicile/domiciledocument',
    EProductTypeSchema.OnlineCasino
  ),
  updateUserVerificationsSolvency: new WritePermission(
    '/users/$userid/verifications/solvency',
    EProductTypeSchema.OnlineCasino
  ),
  approveFinancialVerification: new WritePermission(
    '/verifications/user/$userid/financial/approve',
    EProductTypeSchema.OnlineCasino
  ),
  declineFinancialVerification: new WritePermission(
    '/verifications/user/$userid/financial/decline',
    EProductTypeSchema.OnlineCasino
  ),
  setFinancialVerificationToNeedsVerification: new WritePermission(
    '/verifications/user/$userid/financial/needs-verification',
    EProductTypeSchema.OnlineCasino
  ),
  updateUserVerificationsFinancial: new WritePermission(
    '/users/$userid/verifications/financial',
    EProductTypeSchema.OnlineCasino
  ),
  updateUserVerificationsSolvencyVerificationStatus: new WritePermission(
    '/users/$userid/verifications/solvency/verificationstatus',
    EProductTypeSchema.OnlineCasino
  ),
  updateUserVerificationsFinancialVerificationStatus: new WritePermission(
    '/users/$userid/verifications/financial/verificationstatus',
    EProductTypeSchema.OnlineCasino
  ),
  uploadUserVerificationsSolvencyDocument: new WritePermission(
    '/users/$userid/verifications/solvency/solvencydocument',
    EProductTypeSchema.OnlineCasino
  ),
  deleteUserVerificationsSolvencyDocument: new WritePermission(
    '/users/$userid/verifications/solvency/solvencydocument',
    EProductTypeSchema.OnlineCasino
  ),
  uploadUserVerificationsFinancialDocument: new WritePermission(
    '/users/$userid/verifications/financial/financialdocument',
    EProductTypeSchema.OnlineCasino
  ),
  getUserVouchers: new ReadPermission('/users/$userid/vouchers', EProductTypeSchema.OnlineCasino),
  getUserVoucherCode: new ReadPermission('/users/$userid/vouchers/$voucherid/code', EProductTypeSchema.OnlineCasino),
  getUserVoucherSensitiveInfo: new ReadPermission(
    '/users/$userid/vouchers/$voucherid/$vouchertype/sensitive',
    EProductTypeSchema.OnlineCasino
  ),
  getUserCruksRegistration: new ReadPermission('/users/$userid/cruksregistration', EProductTypeSchema.OnlineCasino),
  getUserCruksMessages: new ReadPermission('/user/$userid/cruksmessages', EProductTypeSchema.OnlineCasino),
  clearAmlDepositTurnoverRequirements: new WritePermission(
    '/users/$userid/account/clearaml',
    EProductTypeSchema.OnlineCasino
  ),
  getUserAvailableBonuses: new ReadPermission(
    '/users/$userid/account/availablebonuses',
    EProductTypeSchema.OnlineCasino
  ),
  awardAvailableBonus: new WritePermission('/users/$userid/account/availablebonuses', EProductTypeSchema.OnlineCasino),
  getUserDepositBonuses: new ReadPermission('/users/$userid/account/depositbonuses', EProductTypeSchema.OnlineCasino),
  awardDepositBonus: new WritePermission('/users/$userid/account/depositbonuses', EProductTypeSchema.OnlineCasino),
  getUserFreeSpins: new ReadPermission('/users/$userid/account/freespins', EProductTypeSchema.OnlineCasino),
  awardFreeSpins: new WritePermission('/users/$userid/account/freespins', EProductTypeSchema.OnlineCasino),
  getAccountTransactions: new ReadPermission('/users/$userid/account/transactions', EProductTypeSchema.OnlineCasino),
  cancelGameRound: new WritePermission('/users/$userid/cancelround/$txid', EProductTypeSchema.OnlineCasino),
  getUserWithdrawalRequests: new ReadPermission(
    '/users/$userid/account/withdrawalrequests',
    EProductTypeSchema.OnlineCasino
  ),
  getUserVaultTokenBalance: new ReadPermission('/users/$userid/vault/balance', EProductTypeSchema.OnlineCasino),
  getUserVaultTransactions: new ReadPermission('/users/$userid/vault/transactions', EProductTypeSchema.OnlineCasino),
  listCountries: new ReadPermission('/users/countries', EProductTypeSchema.OnlineCasino),
  quickUserSearch: new ReadPermission('/users/search', EProductTypeSchema.OnlineCasino),
  advancedUserSearch: new WritePermission('/users/search', EProductTypeSchema.OnlineCasino),
  advancedUserSearchV2: new ReadPermission('/users/search/advanced', EProductTypeSchema.OnlineCasino),
  automaticWithdrawalPermission: new WritePermission(
    '/users/$userid/manualwithdrawalflag',
    EProductTypeSchema.OnlineCasino
  ),
  resetCheckinAttempts: new WritePermission('/checkin/attempts/reset', EProductTypeSchema.OnlineCasino),
  getSelfTests: new ReadPermission('/pgsi/selftest', EProductTypeSchema.OnlineCasino),
  getBasicInfo: new ReadPermission('/reporting/basicinfo', EProductTypeSchema.OnlineCasino),
  getBonusesActive: new ReadPermission('/reporting/bonuses/active', EProductTypeSchema.OnlineCasino),
  getBonusesAwardedToday: new ReadPermission('/reporting/bonuses/awardedtoday', EProductTypeSchema.OnlineCasino),
  getBonusesBlacklist: new ReadPermission('/reporting/bonuses/blacklist', EProductTypeSchema.OnlineCasino),
  getBonusesCashedPromos: new ReadPermission('/reporting/bonuses/cashedpromos', EProductTypeSchema.OnlineCasino),
  getActivity: new ReadPermission('/reporting/casinoactivity', EProductTypeSchema.OnlineCasino),
  getPlayerActivity: new ReadPermission('/reporting/casinoplayeractivity', EProductTypeSchema.OnlineCasino),
  getAccountingV2: new ReadPermission('/v2/reporting/financial/accounting', EProductTypeSchema.OnlineCasino),
  getAccountingInvoiceV2: new ReadPermission(
    '/v2/reporting/financial/accounting/invoice',
    EProductTypeSchema.OnlineCasino
  ),
  getAccounting: new ReadPermission('/reporting/financial/accounting', EProductTypeSchema.OnlineCasino),
  getAccountingInvoice: new ReadPermission('/reporting/financial/accounting/invoices', EProductTypeSchema.OnlineCasino),
  getCasinoTransactions: new ReadPermission('/reporting/financial/casinotransactions', EProductTypeSchema.OnlineCasino),
  getGameProvidersReport: new ReadPermission('/reporting/financial/gameproviders', EProductTypeSchema.OnlineCasino),
  getGameProviderGames: new ReadPermission(
    '/reporting/financial/gameproviders/$gameprovider',
    EProductTypeSchema.OnlineCasino
  ),
  getDailyResults: new ReadPermission('/reporting/financial/dailyresults', EProductTypeSchema.OnlineCasino),
  getWeeklyResults: new ReadPermission('/reporting/financial/weeklyresults', EProductTypeSchema.OnlineCasino),
  getGamesPopularity: new ReadPermission('/reporting/games/popular', EProductTypeSchema.OnlineCasino),
  getGamesToday: new ReadPermission('/reporting/games/today', EProductTypeSchema.OnlineCasino),
  getPlayerTopTransactions: new ReadPermission('/reporting/player/toptransactions', EProductTypeSchema.OnlineCasino),
  getPlayersBlocked: new ReadPermission('/reporting/player/blocked', EProductTypeSchema.OnlineCasino),
  getPlayersActivity: new ReadPermission('/reporting/player/playeractivity', EProductTypeSchema.OnlineCasino),
  getPlayersRevenueContributors: new ReadPermission(
    '/reporting/player/revenuecontributors',
    EProductTypeSchema.OnlineCasino
  ),
  getPlayersRevenueContributorsBonusRequests: new ReadPermission(
    '/reporting/player/revenuecontributors/$userid/bonusrequests',
    EProductTypeSchema.OnlineCasino
  ),
  awardPlayersRevenueContributorsBonusRequest: new WritePermission(
    '/reporting/player/revenuecontributors/$userid/bonusrequests/$answeredquestionid',
    EProductTypeSchema.OnlineCasino
  ),
  getPlayerOverview: new ReadPermission('/reporting/player/overview', EProductTypeSchema.OnlineCasino),
  getEventCounts: new ReadPermission('/reporting/events', EProductTypeSchema.OnlineCasino),
  getPagedHistoricEventstreamByEventname: new ReadPermission(
    '/reporting/events/eventname',
    EProductTypeSchema.OnlineCasino
  ),
  getPagedHistoricEventstreamByStreamId: new ReadPermission(
    '/reporting/events/streamid',
    EProductTypeSchema.OnlineCasino
  ),
  getRevenue: new ReadPermission('/tx/revenue', EProductTypeSchema.OnlineCasino),
  getTransactionsGeneral: new ReadPermission('/tx', EProductTypeSchema.OnlineCasino),
  getAccountBalancesConsistency: new ReadPermission('/tx/accountbalancesconsistency', EProductTypeSchema.OnlineCasino),
  getCashflow: new ReadPermission('/tx/cashflow', EProductTypeSchema.OnlineCasino),
  getDepositsGeneral: new ReadPermission('/deposits', EProductTypeSchema.OnlineCasino),
  getOnlineDeposits: new ReadPermission('/deposits/onlinedeposits', EProductTypeSchema.OnlineCasino),
  getOnlineDepositsForUser: new ReadPermission('/deposits/onlinedeposits/$userid', EProductTypeSchema.OnlineCasino),
  depositManually: new WritePermission('/deposits/manually', EProductTypeSchema.OnlineCasino),
  refundDepositManually: new WritePermission('/deposits/refundmanually', EProductTypeSchema.OnlineCasino),
  chargeBackDepositManually: new WritePermission(
    '/deposits/$depositid/chargebackmanually',
    EProductTypeSchema.OnlineCasino
  ),
  confirmDepositDisputeLost: new WritePermission(
    '/deposits/$depositid/confirmdisputelost',
    EProductTypeSchema.OnlineCasino
  ),
  confirmDepositDisputeWon: new WritePermission(
    '/deposits/$depositid/confirmdisputewon',
    EProductTypeSchema.OnlineCasino
  ),
  failDeposit: new WritePermission('/deposits/$depositid/fail', EProductTypeSchema.OnlineCasino),
  getOnlineDepositStatuses: new ReadPermission('/deposits/onlinedeposits/statuses', EProductTypeSchema.OnlineCasino),
  getDepositsSummary: new ReadPermission('/deposits/summary', EProductTypeSchema.OnlineCasino),
  completeDepositManually: new WritePermission(
    '/deposits/$userid/complete/$depositid',
    EProductTypeSchema.OnlineCasino
  ),
  getDepositDetails: new ReadPermission('/deposits/$userid/details/$depositid', EProductTypeSchema.OnlineCasino),
  getPaymentMethods: new ReadPermission('/paymentmethods', EProductTypeSchema.OnlineCasino),
  createPaymentMethod: new WritePermission('/paymentmethods', EProductTypeSchema.OnlineCasino),
  getPossibleConfigurations: new ReadPermission('/paymentmethods/config', EProductTypeSchema.OnlineCasino),
  getPaymentMethod: new ReadPermission('/paymentmethods/$paymentmethodid', EProductTypeSchema.OnlineCasino),
  updatePaymentMethod: new WritePermission('/paymentmethods/$paymentmethodid', EProductTypeSchema.OnlineCasino),
  updatePaymentMethodSortOrder: new WritePermission(
    '/paymentmethods/$paymentmethodid/sortorder',
    EProductTypeSchema.OnlineCasino
  ),
  createPaymentMethodLimits: new WritePermission(
    '/paymentmethods/$paymentmethodid/limits',
    EProductTypeSchema.OnlineCasino
  ),
  updatePaymentMethodLimits: new WritePermission(
    '/paymentmethods/$paymentmethodid/limits/$limitid',
    EProductTypeSchema.OnlineCasino
  ),
  getDepositsAndWithdrawals: new ReadPermission('/tx/depositsandwithdrawals', EProductTypeSchema.OnlineCasino),
  getTopInOutToday: new ReadPermission('/tx/topinouttoday', EProductTypeSchema.OnlineCasino),
  getTopWinLoseToday: new ReadPermission('/tx/topwinlosetoday', EProductTypeSchema.OnlineCasino),
  getTopWins: new ReadPermission('/tx/top-wins', EProductTypeSchema.OnlineCasino),
  awardContestQuestion: new WritePermission('/contests/awardcontestquestion', EProductTypeSchema.OnlineCasino),
  getVaultDetails: new ReadPermission('/contests/vault/$vaultid', EProductTypeSchema.OnlineCasino),
  getVaultConfigurations: new ReadPermission('/contests/vaultconfiguration', EProductTypeSchema.OnlineCasino),
  addVaultConfiguration: new WritePermission('/contests/vaultconfiguration', EProductTypeSchema.OnlineCasino),
  activateVaultConfiguration: new WritePermission(
    '/contests/vaultconfiguration/$vaultconfigid/activate',
    EProductTypeSchema.OnlineCasino
  ),
  deactivateVaultConfiguration: new WritePermission(
    '/contests/vaultconfiguration/$vaultconfigid/deactivate',
    EProductTypeSchema.OnlineCasino
  ),
  getVaultTokenBoosters: new ReadPermission('/contests/vaulttokenbooster', EProductTypeSchema.OnlineCasino),
  addVaultTokenBooster: new WritePermission('/contests/vaulttokenbooster', EProductTypeSchema.OnlineCasino),
  deactivateTokenBooster: new WritePermission(
    '/contests/vaulttokenbooster/$boosterid',
    EProductTypeSchema.OnlineCasino
  ),
  getWithdrawalsGeneral: new ReadPermission('/withdrawals', EProductTypeSchema.OnlineCasino),
  getWithdrawalsByType: new ReadPermission('/withdrawals/$type', EProductTypeSchema.OnlineCasino),
  getWithdrawalsBankAccount: new ReadPermission('/withdrawals/bankaccount', EProductTypeSchema.OnlineCasino),
  getWithdrawalsCasino: new ReadPermission('/withdrawals/casino', EProductTypeSchema.OnlineCasino),
  getWithdrawalsNetteller: new ReadPermission('/withdrawals/netteller', EProductTypeSchema.OnlineCasino),
  getWithdrawalsSkrill: new ReadPermission('/withdrawals/skrill', EProductTypeSchema.OnlineCasino),
  getWithdrawalsPaypal: new ReadPermission('/withdrawals/paypal', EProductTypeSchema.OnlineCasino),
  getWithdrawalsTrustly: new ReadPermission('/withdrawal/trustly', EProductTypeSchema.OnlineCasino),
  getProcessingFailedWithdrawals: new ReadPermission(
    '/withdrawals/$type/processingfailed',
    EProductTypeSchema.OnlineCasino
  ),
  getProcFailedBank: new ReadPermission('/withdrawals/bankaccount/processingfailed', EProductTypeSchema.OnlineCasino),
  getProcFailedCas: new ReadPermission('/withdrawals/casino/processingfailed', EProductTypeSchema.OnlineCasino),
  getProcFailedNet: new ReadPermission('/withdrawals/netteller/processingfailed', EProductTypeSchema.OnlineCasino),
  getProcFailedSkrill: new ReadPermission('/withdrawals/skrill/processingfailed', EProductTypeSchema.OnlineCasino),
  getProcFailedPaypal: new ReadPermission('/withdrawals/paypal/processingfailed', EProductTypeSchema.OnlineCasino),
  getProcFailedTrustly: new ReadPermission('/withdrawals/trustly/processingfailed', EProductTypeSchema.OnlineCasino),
  exportWithdrawals: new WritePermission('/withdrawals/$type/export', EProductTypeSchema.OnlineCasino),
  declineWithdrawal: new WritePermission('/withdrawals/$type/$withdrawalid/decline', EProductTypeSchema.OnlineCasino),
  retryWithdrawal: new WritePermission('/withdrawals/$type/$withdrawalid/retry', EProductTypeSchema.OnlineCasino),
  setManualWithdrawal: new WritePermission('/withdrawals/$type/$withdrawalid/manual', EProductTypeSchema.OnlineCasino),
  getWithdrawalsExports: new ReadPermission('/withdrawals/exports', EProductTypeSchema.OnlineCasino),
  getWithdrawalsExportFile: new ReadPermission('/withdrawals/exports/$withdrawalid', EProductTypeSchema.OnlineCasino),
  processWithdrawalsExport: new WritePermission('/withdrawals/exports/process', EProductTypeSchema.OnlineCasino),
  getWithdrawalsInCasino: new ReadPermission('/withdrawals/incasino', EProductTypeSchema.OnlineCasino),
  cancelWithdrawalInCasino: new WritePermission(
    '/withdrawals/incasino/$withdrawalid/cancel',
    EProductTypeSchema.OnlineCasino
  ),
  withdrawManually: new WritePermission('/withdrawals/manually', EProductTypeSchema.OnlineCasino),
  getWithdrawalsSummary: new ReadPermission('/withdrawals/summary', EProductTypeSchema.OnlineCasino),
  getWithdrawalDetailsV2: new ReadPermission('/v2/withdrawals/$withdrawalid', EProductTypeSchema.OnlineCasino),
  getWithdrawalPinCode: new ReadPermission('/v2/withdrawals/$withdrawalid/pincode', EProductTypeSchema.OnlineCasino),
  declineWithdrawalV2: new WritePermission('/v2/withdrawals/$withdrawalid/decline', EProductTypeSchema.OnlineCasino),
  completeWithdrawalManuallyV2: new WritePermission(
    '/v2/withdrawals/$withdrawalid/completemanually',
    EProductTypeSchema.OnlineCasino
  ),
  retryWithdrawalV2: new WritePermission('/v2/withdrawals/$withdrawalid/retry', EProductTypeSchema.OnlineCasino),
  processWithdrawalsV2: new WritePermission('/v2/withdrawals/process', EProductTypeSchema.OnlineCasino),
  getRequestedWithdrawalsV2: new ReadPermission('/v2/withdrawals/requested/$type', EProductTypeSchema.OnlineCasino),
  cancelFailedWithdrawalV2: new WritePermission(
    '/v2/withdrawals/$withdrawalid/cancel',
    EProductTypeSchema.OnlineCasino
  ),
  failWithdrawalV2: new WritePermission('/v2/withdrawals/$withdrawalid/fail', EProductTypeSchema.OnlineCasino),
  getFreeSpinsCampaigns: new ReadPermission('/marketing/campaigns/freespincampaigns', EProductTypeSchema.OnlineCasino),
  addFreeSpinsCampaign: new WritePermission('/marketing/campaigns/freespincampaigns', EProductTypeSchema.OnlineCasino),
  getFreeSpinsCampaignsV2: new ReadPermission(
    '/marketing/campaigns/v2/$providerid/freespincampaigns',
    EProductTypeSchema.OnlineCasino
  ),
  getFreeSpinsGameConfigurationsV2: new ReadPermission(
    '/marketing/campaigns/v2/freespinconfigurations/$providerid',
    EProductTypeSchema.OnlineCasino
  ),
  getCampaignsOverview: new ReadPermission('/marketing/campaigns', EProductTypeSchema.OnlineCasino),
  addCampaign: new WritePermission('/marketing/campaigns', EProductTypeSchema.OnlineCasino),
  getCampaign: new ReadPermission('/marketing/campaigns/$campaignid', EProductTypeSchema.OnlineCasino),
  updateCampaign: new WritePermission('/marketing/campaigns/$campaignid', EProductTypeSchema.OnlineCasino),
  getIndividualAction: new ReadPermission(
    '/marketing/campaigns/$campaignid/actions/individual/$actionid',
    EProductTypeSchema.OnlineCasino
  ),
  updateIndividualAction: new WritePermission(
    '/marketing/campaigns/$campaignid/actions/individual/$actionid',
    EProductTypeSchema.OnlineCasino
  ),
  triggerIndividualAction: new WritePermission(
    '/marketing/campaigns/$campaignid/actions/individual/$actionid/process',
    EProductTypeSchema.OnlineCasino
  ),
  triggerIndividualActionAllAccounts: new WritePermission(
    '/marketing/campaigns/$campaignid/actions/individual/$actionid/processall',
    EProductTypeSchema.OnlineCasino
  ),
  triggerIndividualActionAllClosedAccounts: new WritePermission(
    '/marketing/campaigns/$campaignid/actions/individual/$actionid/processallclosed',
    EProductTypeSchema.OnlineCasino
  ),
  addIndividualAction: new WritePermission(
    '/marketing/campaigns/$campaignid/actions/individual',
    EProductTypeSchema.OnlineCasino
  ),
  getCampaignActions: new ReadPermission('/marketing/campaigns/actions', EProductTypeSchema.OnlineCasino),
  getTemplateTypes: new ReadPermission('/marketing/campaigns/templatetypes', EProductTypeSchema.OnlineCasino),
  getDefaultTemplates: new ReadPermission('/marketing/campaigns/templates', EProductTypeSchema.OnlineCasino),
  addDefaultTemplate: new WritePermission('/marketing/campaigns/templates', EProductTypeSchema.OnlineCasino),
  getDefaultCampaignTemplate: new ReadPermission(
    '/marketing/campaigns/templates/$templatetype',
    EProductTypeSchema.OnlineCasino
  ),
  updateDefaultCampaignTemplate: new WritePermission(
    '/marketing/campaigns/templates/$templatetype',
    EProductTypeSchema.OnlineCasino
  ),
  deleteDefaultTemplate: new WritePermission(
    '/marketing/campaigns/templates/$templatetype',
    EProductTypeSchema.OnlineCasino
  ),
  addCampaignTemplate: new WritePermission(
    '/marketing/campaigns/$campaignid/templates',
    EProductTypeSchema.OnlineCasino
  ),
  getCampaignTemplate: new ReadPermission(
    '/marketing/campaigns/$campaignid/templates/$templatetype',
    EProductTypeSchema.OnlineCasino
  ),
  updateCampaignTemplate: new WritePermission(
    '/marketing/campaigns/$campaignid/templates/$templatetype',
    EProductTypeSchema.OnlineCasino
  ),
  deleteActionTemplate: new WritePermission(
    '/marketing/campaigns/$campaignid/templates/$templatetype',
    EProductTypeSchema.OnlineCasino
  ),
  getDocumentById: new ReadPermission('/content/document/$documentid', EProductTypeSchema.OnlineCasino),
  getGamesByQueryFilters: new ReadPermission('/content/games', EProductTypeSchema.OnlineCasino),
  updateGamesV2Order: new WritePermission('/content/gamesv2/order/$gamepkey', EProductTypeSchema.OnlineCasino),
  getGameCategories: new ReadPermission('/content/gamecategories', EProductTypeSchema.OnlineCasino),
  addGameCategory: new WritePermission('/content/gamecategories', EProductTypeSchema.OnlineCasino),
  getGameCategory: new ReadPermission('/content/gamecategories/$gamecategoryid', EProductTypeSchema.OnlineCasino),
  updateGameCategory: new WritePermission('/content/gamecategories/$gamecategoryid', EProductTypeSchema.OnlineCasino),
  removeGameCategory: new WritePermission('/content/gamecategories/$gamecategoryid', EProductTypeSchema.OnlineCasino),
  getGameProviders: new ReadPermission('/content/providers', EProductTypeSchema.OnlineCasino),
  addGameProvider: new WritePermission('/content/providers', EProductTypeSchema.OnlineCasino),
  getGameProvider: new ReadPermission('/content/providers/$providerid', EProductTypeSchema.OnlineCasino),
  updateGameProvider: new WritePermission('/content/providers/$providerid', EProductTypeSchema.OnlineCasino),
  getGameStudios: new ReadPermission('/content/gamestudios', EProductTypeSchema.OnlineCasino),
  addGameStudio: new WritePermission('/content/gamestudios', EProductTypeSchema.OnlineCasino),
  getGameStudio: new ReadPermission('/content/gamestudios/$gamestudioid', EProductTypeSchema.OnlineCasino),
  updateGameStudio: new WritePermission('/content/gamestudios/$gamestudioid', EProductTypeSchema.OnlineCasino),
  getGameThemes: new ReadPermission('/content/gamethemes', EProductTypeSchema.OnlineCasino),
  addGameTheme: new WritePermission('/content/gamethemes', EProductTypeSchema.OnlineCasino),
  getGameTheme: new ReadPermission('/content/gamethemes/$gamethemeid', EProductTypeSchema.OnlineCasino),
  updateGameTheme: new WritePermission('/content/gamethemes/$gamethemeid', EProductTypeSchema.OnlineCasino),
  deleteGameTheme: new WritePermission('/content/gamethemes/$gamethemeid', EProductTypeSchema.OnlineCasino),
  getGamesByQueryFiltersV2: new ReadPermission('/games', EProductTypeSchema.OnlineCasino),
  addGameV2: new WritePermission('/games', EProductTypeSchema.OnlineCasino),
  getGameV2: new ReadPermission('/games/$gamepkey', EProductTypeSchema.OnlineCasino),
  updateGameV2: new WritePermission('/games/$gamepkey', EProductTypeSchema.OnlineCasino),
  addGameConfiguration: new WritePermission('/games/$gamepkey/configurations', EProductTypeSchema.OnlineCasino),
  updateGameConfiguration: new WritePermission(
    '/games/$gamepkey/configurations/$gameconfigurationid',
    EProductTypeSchema.OnlineCasino
  ),
  getGameLobbies: new ReadPermission('/gamelobbies', EProductTypeSchema.OnlineCasino),
  addGameLobby: new WritePermission('/gamelobbies', EProductTypeSchema.OnlineCasino),
  getGameLobby: new ReadPermission('/gamelobbies/$gamelobbyid', EProductTypeSchema.OnlineCasino),
  updateGameLobby: new WritePermission('/gamelobbies/$gamelobbyid', EProductTypeSchema.OnlineCasino),
  archiveGameConfiguration: new WritePermission(
    '/games/$gamepkey/configurations/$gameconfigurationid/archive',
    EProductTypeSchema.OnlineCasino
  ),
  unarchiveGameConfiguration: new WritePermission(
    '/games/$gamepkey/configurations/$gameconfigurationid/unarchive',
    EProductTypeSchema.OnlineCasino
  ),
  doCacheBuster: new WritePermission('/content/cachebusters/$cachebustertype', EProductTypeSchema.OnlineCasino),
  setServiceContractVersion: new WritePermission('/content/servicecontracts/version', EProductTypeSchema.OnlineCasino),
  getAllMediaGalleryEntriesOfType: new ReadPermission('/content/mediagallery', EProductTypeSchema.OnlineCasino),
  uploadMediaGalleryEntry: new WritePermission('/content/mediagallery', EProductTypeSchema.OnlineCasino),
  deleteMediaGalleryEntry: new WritePermission(
    '/content/mediagallery/$mediagalleryentryid',
    EProductTypeSchema.OnlineCasino
  ),
  getContentFilters: new ReadPermission('/content/filters', EProductTypeSchema.OnlineCasino),
  createContentFilter: new WritePermission('/content/filters', EProductTypeSchema.OnlineCasino),
  getContentFilterById: new ReadPermission('/content/filters/$contentfilterid', EProductTypeSchema.OnlineCasino),
  updateContentFilter: new WritePermission('/content/filters/$contentfilterid', EProductTypeSchema.OnlineCasino),
  archiveContentFilter: new WritePermission(
    '/content/filters/$contentfilterid/archive',
    EProductTypeSchema.OnlineCasino
  ),
  getAgentTasks: new ReadPermission('/tasks', EProductTypeSchema.OnlineCasino),
  getInterventionNeeded: new ReadPermission('/tasks/interventions', EProductTypeSchema.OnlineCasino),
  getOpenAgentTasks: new ReadPermission('/tasks/open', EProductTypeSchema.OnlineCasino),
  resolveOpenAgentTask: new WritePermission('/tasks/$agenttaskid/resolve', EProductTypeSchema.OnlineCasino),
  resolveOpenAgentTaskV2: new WritePermission('/tasks/$agenttaskid/resolvev2', EProductTypeSchema.OnlineCasino),
  assignAgentTask: new WritePermission('/tasks/$agenttaskid/assign', EProductTypeSchema.OnlineCasino),
  unassignAgentTask: new WritePermission('/tasks/$agenttaskid/unassign', EProductTypeSchema.OnlineCasino),
  solveIntervention: new WritePermission('/tasks/interventions/$interventionid/solve', EProductTypeSchema.OnlineCasino),
  getVerifications: new ReadPermission('/tasks/verifications', EProductTypeSchema.OnlineCasino),
  getStartupSettings: new ReadPermission('/settings/startup', EProductTypeSchema.OnlineCasino),
  getIpWhitelist: new ReadPermission('/settings/fraud/ipwhitelist', EProductTypeSchema.OnlineCasino),
  addIpWhitelist: new WritePermission('/settings/fraud/ipwhitelist', EProductTypeSchema.OnlineCasino),
  getCasinos: new ReadPermission('/settings/casinos', EProductTypeSchema.OnlineCasino),
  getConfigurations: new ReadPermission('/settings/configurations', EProductTypeSchema.OnlineCasino),
  updateConfiguration: new WritePermission(
    '/settings/configurations/$configurationid',
    EProductTypeSchema.OnlineCasino
  ),
  getBonusRequestSimulation: new ReadPermission('/settings/bonusrequestsimulation', EProductTypeSchema.OnlineCasino),
  revealVaultDigit: new WritePermission('/settings/revealvaultdigit/$vaultconfigid', EProductTypeSchema.OnlineCasino),
  getAllCountries: new ReadPermission('/settings/countries', EProductTypeSchema.OnlineCasino),
  getPromotionNameConfigurations: new ReadPermission('/settings/promotions/name', EProductTypeSchema.OnlineCasino),
  updatePromotionNameConfigurations: new WritePermission('/settings/promotions/name', EProductTypeSchema.OnlineCasino),
  getDepositLimits: new ReadPermission('/depositlimits', EProductTypeSchema.OnlineCasino),
  getDepositLimitUpdateRequests: new ReadPermission('/depositlimits/requests', EProductTypeSchema.OnlineCasino),
  requestDepositLimitUpdate: new WritePermission('/depositlimits/requests', EProductTypeSchema.OnlineCasino),
  getDepositLimitsThatRequireManualApproval: new ReadPermission(
    '/depositlimits/requests/requires-manual-approval',
    EProductTypeSchema.OnlineCasino
  ),
  getActiveDepositLimitsForUser: new ReadPermission('/depositlimits/active/$userid', EProductTypeSchema.OnlineCasino),
  approveDepositLimitUpdateRequestManually: new WritePermission(
    '/depositlimits/requests/$requestid/approve',
    EProductTypeSchema.OnlineCasino
  ),
  declineDepositLimitUpdateRequestManually: new WritePermission(
    '/depositlimits/requests/$requestid/decline',
    EProductTypeSchema.OnlineCasino
  ),
  setDepositLimitUpdateRequestToRequireManualApproval: new WritePermission(
    '/depositlimits/requests/$requestid/requiresmanualapproval',
    EProductTypeSchema.OnlineCasino
  ),
  setMaximumDepositLimit: new WritePermission('/depositlimits/$userid/max', EProductTypeSchema.OnlineCasino),
  getMaximumDepositLimits: new ReadPermission('/depositlimits/$userid/max', EProductTypeSchema.OnlineCasino),
  cancelMaximumDepositLimit: new WritePermission('/depositlimits/$userid/max/cancel', EProductTypeSchema.OnlineCasino),
  getActiveMaximumDepositLimits: new ReadPermission(
    '/depositlimits/$userid/max/active',
    EProductTypeSchema.OnlineCasino
  ),
  getLoginSessionLimits: new ReadPermission('/loginsessionlimits', EProductTypeSchema.OnlineCasino),
  getLoginSessionLimitUpdateRequests: new ReadPermission(
    '/loginsessionlimits/requests',
    EProductTypeSchema.OnlineCasino
  ),
  requestLoginSessionLimitUpdate: new WritePermission('/loginsessionlimits/requests', EProductTypeSchema.OnlineCasino),
  getActiveLoginSessionLimitsForUser: new ReadPermission(
    '/loginsessionlimits/active/$userid',
    EProductTypeSchema.OnlineCasino
  ),
  approveLoginSessionLimitUpdateRequest: new WritePermission(
    '/loginsessionlimits/requests/$requestid/approve',
    EProductTypeSchema.OnlineCasino
  ),
  declineLoginSessionLimitUpdateRequest: new WritePermission(
    '/loginsessionlimits/requests/$requestid/decline',
    EProductTypeSchema.OnlineCasino
  ),
  setLoginSessionLimitUpdateRequestToRequireManualApproval: new WritePermission(
    '/loginsessionlimits/requests/$requestid/requiresmanualapproval',
    EProductTypeSchema.OnlineCasino
  ),
  setMaximumLoginSessionLimit: new WritePermission('/loginsessionlimits/$userid/max', EProductTypeSchema.OnlineCasino),
  getMaximumLoginSessionLimits: new ReadPermission('/loginsessionlimits/$userid/max', EProductTypeSchema.OnlineCasino),
  cancelMaximumLoginSessionLimit: new WritePermission(
    '/loginsessionlimits/$userid/max/cancel',
    EProductTypeSchema.OnlineCasino
  ),
  getActiveMaximumLoginSessionLimits: new ReadPermission(
    '/loginsessionlimits/$userid/max/active',
    EProductTypeSchema.OnlineCasino
  ),
  requestBalanceLimitUpdate: new WritePermission('/balancelimits/requests', EProductTypeSchema.OnlineCasino),
  getBalanceLimitUpdateRequests: new ReadPermission('/balancelimits/requests', EProductTypeSchema.OnlineCasino),
  getBalanceLimits: new ReadPermission('/balancelimits', EProductTypeSchema.OnlineCasino),
  getActiveBalanceLimitForUser: new ReadPermission('/balancelimits/active/$userid', EProductTypeSchema.OnlineCasino),
  approveBalanceLimitUpdateRequestManually: new WritePermission(
    '/balancelimits/requests/$requestid/approve',
    EProductTypeSchema.OnlineCasino
  ),
  declineBalanceLimitUpdateRequestManually: new WritePermission(
    '/balancelimits/requests/$requestid/decline',
    EProductTypeSchema.OnlineCasino
  ),
  setBalanceLimitUpdateRequestToRequireManualApproval: new WritePermission(
    '/balancelimits/requests/$requestid/requiresmanualapproval',
    EProductTypeSchema.OnlineCasino
  ),
  setMaximumBalanceLimit: new WritePermission('/balancelimits/$userid/max', EProductTypeSchema.OnlineCasino),
  getMaximumBalanceLimits: new ReadPermission('/balancelimits/$userid/max', EProductTypeSchema.OnlineCasino),
  cancelMaximumBalanceLimit: new WritePermission('/balancelimits/$userid/max/cancel', EProductTypeSchema.OnlineCasino),
  getActiveMaximumBalanceLimit: new ReadPermission(
    '/balancelimits/$userid/max/active',
    EProductTypeSchema.OnlineCasino
  ),
  orderBechargeVoucher: new WritePermission(
    '/vouchers/becharge/$bechargevoucherid/order',
    EProductTypeSchema.OnlineCasino
  ),
  getBechargeBalance: new ReadPermission('/vouchers/becharge/balance', EProductTypeSchema.OnlineCasino),
  getBechargeOrderDetails: new ReadPermission(
    '/vouchers/becharge/$bechargevoucherorderreference/details',
    EProductTypeSchema.OnlineCasino
  ),
  getBechargeProducts: new ReadPermission('/vouchers/becharge/products', EProductTypeSchema.OnlineCasino),
  getTransactionHistory: new ReadPermission('/vouchers/becharge/transactions', EProductTypeSchema.OnlineCasino),
  sendSystemEmail: new WritePermission('/systememail/send', EProductTypeSchema.OnlineCasino),
  getUserLoyaltyDetails: new ReadPermission('/loyalty/users/v2/$userid', EProductTypeSchema.OnlineCasino),
  awardLoyaltyPointsToUser: new WritePermission('/loyalty/users/v2/$userid', EProductTypeSchema.OnlineCasino),
  getLoyaltySpendablePointsTx: new ReadPermission(
    '/loyalty/users/v2/$userid/tx/spendable',
    EProductTypeSchema.OnlineCasino
  ),
  getLoyaltyStatusPointsTx: new ReadPermission('/loyalty/users/v2/$userid/tx/status', EProductTypeSchema.OnlineCasino),
  setLoyaltyRank: new WritePermission('/loyalty/users/v2/$userid/rank', EProductTypeSchema.OnlineCasino),
  getLoyaltyRankDistributions: new ReadPermission('/loyalty/reporting/v2/ranks', EProductTypeSchema.OnlineCasino),
  getLoyaltyRankUsers: new ReadPermission('/loyalty/reporting/v2/ranks/$rankname', EProductTypeSchema.OnlineCasino),
  getLoyaltyTopSavers: new ReadPermission('/loyalty/reporting/v2/top-savers', EProductTypeSchema.OnlineCasino),
  getLoyaltyTotalBalances: new ReadPermission('/loyalty/reporting/v2/total-balances', EProductTypeSchema.OnlineCasino),
  getLoyaltyBoosters: new ReadPermission('/loyalty/boosters', EProductTypeSchema.OnlineCasino),
  deleteLoyaltyBooster: new WritePermission('/loyalty/boosters/$boosterid', EProductTypeSchema.OnlineCasino),
  getAllLoyaltyShopItems: new ReadPermission('/loyalty/v2/shop', EProductTypeSchema.OnlineCasino),
  addLoyaltyShopItem: new WritePermission('/loyalty/v2/shop', EProductTypeSchema.OnlineCasino),
  updateLoyaltyShopItemV2: new WritePermission('/loyalty/v2/shop/$loyaltyshopitemid', EProductTypeSchema.OnlineCasino),
  addLoyaltyBooster: new WritePermission(
    '/loyalty/boosters/providers/$providerid/games/$gameid',
    EProductTypeSchema.OnlineCasino
  ),
  cancel: new WritePermission('/selfexclusions/$userid/cancel', EProductTypeSchema.OnlineCasino),
  getAllPlayingBreaks: new ReadPermission('/playingbreaks', EProductTypeSchema.OnlineCasino),
  getPlayingBreak: new ReadPermission('/playingbreaks/$userid', EProductTypeSchema.OnlineCasino),
  setPlayingBreak: new WritePermission('/playingbreaks/$userid', EProductTypeSchema.OnlineCasino),
  cancelPlayingBreak: new WritePermission('/playingbreaks/$userid', EProductTypeSchema.OnlineCasino),
  cancelPlayingBreakV2: new WritePermission('/playingbreaks/$userid/cancel', EProductTypeSchema.OnlineCasino),
  declinePlayingBreakCancellationRequest: new WritePermission(
    '/playingbreaks/$userid/cancel/decline',
    EProductTypeSchema.OnlineCasino
  ),
  forceCancelPlayingBreak: new WritePermission('/playingbreaks/$userid/force', EProductTypeSchema.OnlineCasino),
  concludeInvestigation: new WritePermission(
    '/playingbreaks/investigations/$userid/conclude',
    EProductTypeSchema.OnlineCasino
  ),
  getPromotionalCalendars: new ReadPermission('/promotional-calendars', EProductTypeSchema.OnlineCasino),
  addPromotionalCalendar: new WritePermission('/promotional-calendars', EProductTypeSchema.OnlineCasino),
  getPromotionalCalendarById: new ReadPermission('/promotional-calendars/$calendarid', EProductTypeSchema.OnlineCasino),
  updatePromotionalCalendar: new WritePermission('/promotional-calendars/$calendarid', EProductTypeSchema.OnlineCasino),
  getBoosterPacks: new ReadPermission('/booster-packs', EProductTypeSchema.OnlineCasino),
  addBoosterPack: new WritePermission('/booster-packs', EProductTypeSchema.OnlineCasino),
  updateBoosterPack: new WritePermission('/booster-packs/$boosterpackid', EProductTypeSchema.OnlineCasino),
  getBoosterPackCards: new ReadPermission('/booster-packs/cards', EProductTypeSchema.OnlineCasino),
  addBoosterPackCard: new WritePermission('/booster-packs/cards', EProductTypeSchema.OnlineCasino),
  updateBoosterPackCard: new WritePermission(
    '/booster-packs/cards/$boosterpackcardid',
    EProductTypeSchema.OnlineCasino
  ),
  getInterventions: new ReadPermission('/interventions', EProductTypeSchema.OnlineCasino),
  createInterventionForUser: new WritePermission('/interventions/user/$userid', EProductTypeSchema.OnlineCasino),
  getInterventionsForUser: new ReadPermission('/interventions/user/$userid', EProductTypeSchema.OnlineCasino),
  completeIntervention: new WritePermission('/interventions/$interventionid/complete', EProductTypeSchema.OnlineCasino),
  openIntervention: new WritePermission('/interventions/$interventionid/open', EProductTypeSchema.OnlineCasino),
  closeIntervention: new WritePermission('/interventions/$interventionid/close', EProductTypeSchema.OnlineCasino),
  getIntervention: new ReadPermission('/interventions/$interventionid', EProductTypeSchema.OnlineCasino),
  getGameSessions: new ReadPermission('/gamesessions', EProductTypeSchema.OnlineCasino),
  getGameSessionsActivity: new ReadPermission('/gamesessions/activity/$userid', EProductTypeSchema.OnlineCasino),
  getGameSessionSummaries: new ReadPermission('/gamesessions/summary', EProductTypeSchema.OnlineCasino),
  getMatches: new ReadPermission('/acuris/monitor/matches/$userid', EProductTypeSchema.OnlineCasino),
  addMatchReviews: new WritePermission('/acuris/monitor/matches/$userid/reviews', EProductTypeSchema.OnlineCasino),
  getMatchReviews: new ReadPermission(
    '/acuris/monitor/matches/$userid/reviews/$qrcode',
    EProductTypeSchema.OnlineCasino
  ),
  getIndividuals: new ReadPermission('/acuris/monitor/individuals', EProductTypeSchema.OnlineCasino),
  depseudonymizeString: new WritePermission('/cryptography/depseudonymize', EProductTypeSchema.OnlineCasino),
  depseudonymizeUid: new WritePermission('/cryptography/depseudonymize/uid', EProductTypeSchema.OnlineCasino),
  cancelWOKRecord: new WritePermission('/regulator/cdb/wok/cancel-record', EProductTypeSchema.OnlineCasino),
  sendWOKPausedDeliveryQueueEntries: new WritePermission(
    '/regulator/cdb/wok/send-paused-delivery',
    EProductTypeSchema.OnlineCasino
  ),
  sendTAXPausedDeliveryQueueEntries: new WritePermission(
    '/regulator/cdb/tax/send-paused-delivery',
    EProductTypeSchema.OnlineCasino
  ),
  getCasinosV2: new ReadPermission('/casinos', EProductTypeSchema.OnlineCasino),
  createCasino: new WritePermission('/casinos', EProductTypeSchema.OnlineCasino),
  archiveCasino: new WritePermission('/casinos/$casinoid/archive', EProductTypeSchema.OnlineCasino),
  updateCasino: new WritePermission('/casinos/$casinoid', EProductTypeSchema.OnlineCasino),
  uploadMediaGalleryEntryV2: new WritePermission('/mediagallery', EProductTypeSchema.OnlineCasino),
  retrieveMediaGalleryAssets: new ReadPermission('/mediagallery', EProductTypeSchema.OnlineCasino),
  retrieveValidationSettings: new ReadPermission(
    '/mediagallery/validationsettings/$mediagalleryentrytype',
    EProductTypeSchema.OnlineCasino
  ),
  retrieveMediaGalleryEntry: new ReadPermission('/mediagallery/$mediagalleryentryid', EProductTypeSchema.OnlineCasino),
  syncBlurredVersionGameImages: new WritePermission(
    '/mediagallery/blur/sync-gameimages',
    EProductTypeSchema.OnlineCasino
  ),
  profileLookup: new ReadPermission('/acuris/profile/$resourceid', EProductTypeSchema.OnlineCasino),
  resolveDetectedRisk: new WritePermission('/acuris/risk/$agenttaskid/resolve', EProductTypeSchema.OnlineCasino),
  getBoosts: new ReadPermission('/boosts', EProductTypeSchema.OnlineCasino),
  createGlobalBoost: new WritePermission('/boosts/global', EProductTypeSchema.OnlineCasino),
  getBoostUserStatesForUser: new ReadPermission('/boosts/user-states/$userid', EProductTypeSchema.OnlineCasino),
  createServiceContract: new WritePermission('/service-contracts', EProductTypeSchema.OnlineCasino),
  getServiceContracts: new ReadPermission('/service-contracts', EProductTypeSchema.OnlineCasino),
  getServiceContract: new ReadPermission('/service-contracts/$contractid', EProductTypeSchema.OnlineCasino),
  addVersionToServiceContract: new WritePermission('/service-contracts/$contractid', EProductTypeSchema.OnlineCasino),
  getServiceContractsForUser: new ReadPermission('/service-contracts/user/$userid', EProductTypeSchema.OnlineCasino),
  getServiceContractForUser: new ReadPermission(
    '/service-contracts/$contractid/user/$userid',
    EProductTypeSchema.OnlineCasino
  ),
  getCloudwatchAlarmActionsEnabled: new ReadPermission(
    '/killswitch/cloudwatchalarm/actions-enabled',
    EProductTypeSchema.OnlineCasino
  ),
  setCloudwatchAlarmActionsEnabled: new WritePermission(
    '/killswitch/cloudwatchalarm/actions-enabled',
    EProductTypeSchema.OnlineCasino
  ),
  getKillSwitchState: new ReadPermission('/killswitch', EProductTypeSchema.OnlineCasino),
  setKillSwitchState: new WritePermission('/killswitch', EProductTypeSchema.OnlineCasino),
  cancelFreeSpins: new WritePermission('/freespins/cancel/$freespinsid', EProductTypeSchema.OnlineCasino),
  updateFreeSpinsGameConfiguration: new WritePermission(
    '/freespins/configurations/$gameid/update',
    EProductTypeSchema.OnlineCasino
  ),
  syncEmailTemplates: new WritePermission('/trustpilot/sync-email-templates', EProductTypeSchema.OnlineCasino),
  getStoredEmailTemplates: new ReadPermission('/trustpilot/email-templates', EProductTypeSchema.OnlineCasino),
  retrieveDgojResponsibleGamingTestResponses: new ReadPermission(
    '/responsible-gaming-test/dgoj',
    EProductTypeSchema.OnlineCasino
  ),
  getReportToCruksUsers: new ReadPermission('/regulator/cruks/report-users', EProductTypeSchema.OnlineCasino),
  resolveReportToCRUKS: new WritePermission(
    '/regulator/cruks/report-users/$userid/resolve',
    EProductTypeSchema.OnlineCasino
  ),
  getAnnualUserReport: new ReadPermission('/users/$userid/annual-report/$year', EProductTypeSchema.OnlineCasino),
  getOrCreateAnnualUserReport: new WritePermission(
    '/users/$userid/annual-report/$year',
    EProductTypeSchema.OnlineCasino
  ),
  getCurrentTotalAccountsBalance: new ReadPermission(
    '/control-procedures/bank-balance-consistency/total-accounts-balance',
    EProductTypeSchema.OnlineCasino
  ),
  getBankBalanceConsistencyChecksHistory: new ReadPermission(
    '/control-procedures/bank-balance-consistency/history',
    EProductTypeSchema.OnlineCasino
  ),
  createBankBalanceConsistencyCheck: new WritePermission(
    '/control-procedures/bank-balance-consistency/history',
    EProductTypeSchema.OnlineCasino
  ),
};

export type OnlineCasinoPermissionTypes = keyof typeof OnlineCasinoPermissions;
