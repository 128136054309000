import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetLoginSessionLimits } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Tooltip } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { getDurationFromSeconds, StatusSpan } from 'src/app/helpers/transformFunctions';
import { usePermission } from 'src/app/hooks';

import { DataGridContainer, DateTime, PermissionWrapper, StyledDataGrid } from '@greenisland-common/components/atoms';

type DepositLimitsProps = {
  userId: string;
  activePage?: number;
  startDate?: Date;
  endDate?: Date;
};

const PAGE_SIZE = 25;

const ActiveLimitsTable = ({ userId, activePage = 0, startDate, endDate }: DepositLimitsProps) => {
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(activePage);

  const canReadSessionLimits = usePermission(OnlineCasinoPermissions.getLoginSessionLimits);
  const {
    data: sessionLimits,
    isLoading,
    isError,
    error,
  } = useGetLoginSessionLimits(
    {
      userId: parseInt(userId, 10),
      page,
      pageSize: PAGE_SIZE,
      ...(startDate ? { limitsStartedAfter: (startDate.getTime() ?? 0) / 1000 } : {}),
      ...(endDate ? { limitsStartedBefore: (endDate.getTime() ?? 0) / 1000 } : {}),
    },
    { query: { enabled: canReadSessionLimits } }
  );

  const headers: GridColumns = [
    {
      headerName: t('startDate'),
      field: 'startDate',
      renderCell: ({ row }) => (row.startDate ? <DateTime dateTime={row.startDate} /> : <></>),
      flex: 1,
      minWidth: 160,
    },
    {
      headerName: t('endDate'),
      field: 'endDate',
      renderCell: ({ row }) => (row.endDate ? <DateTime dateTime={row.endDate} /> : <></>),
      flex: 1,
      minWidth: 160,
    },
    { headerName: t('id'), field: 'id', flex: 0.5, minWidth: 100 },
    { headerName: t('period'), field: 'period', flex: 1, minWidth: 80 },
    {
      headerName: t('value'),
      field: 'value',
      flex: 1,
      minWidth: 100,
      valueGetter: ({ row }) => (row.value ? getDurationFromSeconds(row.value) : ''),
    },
    {
      headerName: t('source'),
      field: 'source',
      flex: 1,
      minWidth: 80,
    },
    {
      headerName: t('status'),
      field: 'status',
      flex: 1,
      minWidth: 100,
      renderCell: ({ value }) => <StatusSpan value={value} />,
    },
    {
      headerName: t('common.additionalInfo'),
      field: 'additionalData',
      renderCell: ({ value }) => (
        <Tooltip title={value}>
          <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</span>
        </Tooltip>
      ),
      flex: 1,
      minWidth: 250,
    },
  ];

  const rows: GridRowsProp = sessionLimits?.loginSessionLimits?.map(row => ({ ...row })) ?? [];

  return (
    <>
      <PermissionWrapper
        errorMessage={error?.message}
        isError={isError}
        isLoading={isLoading}
        permission={OnlineCasinoPermissions.getLoginSessionLimits}
      >
        <DataGridContainer>
          <StyledDataGrid
            columns={headers}
            rows={rows}
            page={sessionLimits?.pagingDetails?.currentPage}
            rowCount={sessionLimits?.pagingDetails?.totalItems}
            pageSize={PAGE_SIZE}
            onPageChange={setPage}
            paginationMode="server"
            pagination
          />
        </DataGridContainer>
      </PermissionWrapper>
    </>
  );
};

export default memo(ActiveLimitsTable);
