/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EComplaintClosureReasonSchema =
  (typeof EComplaintClosureReasonSchema)[keyof typeof EComplaintClosureReasonSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EComplaintClosureReasonSchema = {
  ProblemResolved: 'ProblemResolved',
  QuestionAnswered: 'QuestionAnswered',
  CustumerRepaid: 'CustumerRepaid',
  NonIssue: 'NonIssue',
} as const;
