import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EUserLimitTypeSchema,
  useGetDepositLimitsThatRequireManualApproval,
  UserLimitUpdateRequestWithReferenceSchema,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Badge, Box, Tab, Tabs, Typography } from '@mui/material';

import { TabPanel } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../app/hooks';
import UserLimitsTabView from './UserLimitsTabView';

const PAGE_SIZE = 25;

interface Props {
  value?: number;
}

const UserLimitTabContent = ({ value }: Props) => {
  const { t } = useTranslation();

  return (
    <Box display="flex">
      <Typography variant="body2">{t('tasks.userLimits.titles.depositLimits')}</Typography>
      <Box ml={3}>
        <Badge badgeContent={value} color="error" max={999} showZero />
      </Box>
    </Box>
  );
};

const UserLimits = () => {
  const { t } = useTranslation();

  const canReadDepositLimitsThatRequireManualApproval = usePermission(
    OnlineCasinoPermissions.getDepositLimitsThatRequireManualApproval
  );

  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const {
    data: depositLimits,
    isLoading,
    isFetching,
    isError,
  } = useGetDepositLimitsThatRequireManualApproval(
    {
      page,
      pageSize,
    },
    {
      query: { enabled: canReadDepositLimitsThatRequireManualApproval },
    }
  );

  const getUserLimitsDataHandler = useCallback(
    (type: EUserLimitTypeSchema) => {
      return depositLimits?.userLimitUpdateRequestsWithReference
        ?.filter(item => item.userLimitType === type)
        ?.map(item => {
          const newObject: UserLimitUpdateRequestWithReferenceSchema = {
            userLimitType: item.userLimitType,
            reference: item.reference,
          };
          if (type === EUserLimitTypeSchema.DepositLimit) {
            newObject.depositLimitUpdateRequest = item.depositLimitUpdateRequest;
          } else if (type === EUserLimitTypeSchema.BalanceLimit) {
            newObject.balanceLimitUpdateRequest = item.balanceLimitUpdateRequest;
          } else if (type === EUserLimitTypeSchema.LoginSessionLimit) {
            newObject.loginSessionLimitUpdateRequest = item.loginSessionLimitUpdateRequest;
          }
          return newObject;
        });
    },
    [depositLimits?.userLimitUpdateRequestsWithReference]
  );

  const getUserLimitDataProps = useCallback(
    (type: EUserLimitTypeSchema) => {
      return {
        data: getUserLimitsDataHandler(type),
        isLoading: isLoading || isFetching,
        isError: isError,
        page,
        setPage,
        pageSize,
        setPageSize,
        totalItems: depositLimits?.pagingDetails?.totalItems,
      };
    },
    [depositLimits?.pagingDetails?.totalItems, getUserLimitsDataHandler, isError, isFetching, isLoading, page, pageSize]
  );

  return (
    <Box>
      <Typography fontWeight={500} variant="body2" fontSize="large">
        {t('tasks.userLimits.titles.requiresManualApproval')}
      </Typography>
      <Box pt={2}>
        <Tabs value={activeTab} onChange={(_, newValue) => setActiveTab(newValue)}>
          <Tab
            label={<UserLimitTabContent value={depositLimits?.pagingDetails?.totalItems} />}
            id="tab-1"
            aria-controls="tab-panel-1"
          />
        </Tabs>
        <Box pt={2}>
          <TabPanel value={activeTab} index={0}>
            <UserLimitsTabView depositLimitProps={getUserLimitDataProps(EUserLimitTypeSchema.DepositLimit)} />
          </TabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default UserLimits;
