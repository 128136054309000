import { useMemo } from 'react';
import {
  EPaymentMethodSchema,
  EPaymentProviderSchema,
  EPaymentServiceVersionSchema,
  PaymentMethodConfigurationsSchema,
} from '@greenisland-api';

export default function usePaymentsAllowed(
  selectedMethod: EPaymentMethodSchema,
  selectedProvider: EPaymentProviderSchema,
  selectedPaymentsServiceVersion: EPaymentServiceVersionSchema,
  paymentMethodConfigs: PaymentMethodConfigurationsSchema | undefined
) {
  return useMemo(() => {
    if (selectedMethod && selectedProvider && selectedPaymentsServiceVersion) {
      if (selectedPaymentsServiceVersion === EPaymentServiceVersionSchema.Portal) {
        const selectedConfig = paymentMethodConfigs?.portalConfigurations
          .find(config => config.method === selectedMethod)
          ?.possibleProviders.find(config => config.provider === selectedProvider);
        if (selectedConfig) {
          return {
            withdrawalsAllowed: selectedConfig.withdrawalsPossible,
            depositsAllowed: selectedConfig.depositsPossible,
          };
        }
      }
      if (selectedPaymentsServiceVersion === EPaymentServiceVersionSchema.PaymentsV1) {
        const selectedConfig = paymentMethodConfigs?.v1Configurations
          .find(config => config.method === selectedMethod)
          ?.possibleProviders.find(config => config.provider === selectedProvider);
        if (selectedConfig) {
          return {
            withdrawalsAllowed: selectedConfig.withdrawalsPossible,
            depositsAllowed: selectedConfig.depositsPossible,
          };
        }
      }
      if (selectedPaymentsServiceVersion === EPaymentServiceVersionSchema.PaymentsV2) {
        const selectedConfig = paymentMethodConfigs?.v2Configurations
          .find(config => config.method === selectedMethod)
          ?.possibleProviders.find(config => config.provider === selectedProvider);
        if (selectedConfig) {
          return {
            withdrawalsAllowed: selectedConfig.withdrawalsPossible,
            depositsAllowed: selectedConfig.depositsPossible,
          };
        }
      }
    }
    return {
      withdrawalsAllowed: false,
      depositsAllowed: false,
    };
  }, [paymentMethodConfigs, selectedMethod, selectedProvider, selectedPaymentsServiceVersion]);
}
