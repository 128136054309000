import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  GameSessionsSortKeyParamParameter,
  GameSessionsSummaryGamingRevenueComparisonParameter,
  GameSessionsSummaryGroupByKeyParamParameter,
  GameSessionsSummarySortKeyParamParameter,
  SortOrderParamParameter,
  useGetGameProviders,
  useGetGamesByQueryFilters,
} from '@greenisland-api';
import { Autocomplete, Box, capitalize, TextField, useTheme } from '@mui/material';

import Select from '@greenisland-common/components/atoms/Select';

import { useUpdateSearchParams } from '@greenisland-common/hooks/useUpdateSearchParams';

import { GameSummarySearchFilters } from '../Constants';
import useGetGameSessionFiltersQuery from '../hooks/useGetGameSessionFiltersQuery';

interface Props {
  isSummaryTab: boolean;
}

const GameSessionAdditionalFilters = ({ isSummaryTab }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const updateGameSessionQueryParamsHandler = useUpdateSearchParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    providerIdQuery,
    userIdQuery,
    gameIdQuery,
    groupByKeyQuery,
    sortByOrderQuery,
    sortByKeyQuery,
    gamingRevenueComparisonQuery,
    gamingRevenueComparisonValueQuery,
  } = useGetGameSessionFiltersQuery();

  const { data: gameProviders } = useGetGameProviders();
  const games = useGetGamesByQueryFilters(
    providerIdQuery
      ? {
          providerId: parseInt(providerIdQuery, 10),
          resultsPerPage: 1000,
        }
      : {},
    {
      query: { enabled: !!providerIdQuery },
    }
  );

  const chosenProvider = useMemo(
    () => gameProviders?.find(provider => provider?.providerId === providerIdQuery),
    [gameProviders, providerIdQuery]
  );
  const chosenGame = useMemo(
    () => games?.data?.results?.find(game => game?.gameId === gameIdQuery),
    [gameIdQuery, games]
  );

  useEffect(() => {
    if (!providerIdQuery && gameIdQuery) {
      searchParams.delete(GameSummarySearchFilters.GAME_ID);
      setSearchParams(searchParams);
    }
  }, [gameIdQuery, providerIdQuery, searchParams, setSearchParams]);

  useEffect(() => {
    if (
      gamingRevenueComparisonQuery &&
      gamingRevenueComparisonQuery === GameSessionsSummaryGamingRevenueComparisonParameter.None
    ) {
      searchParams.delete(GameSummarySearchFilters.GAMING_REVENUE_COMPARISON);
      searchParams.delete(GameSummarySearchFilters.GAMING_REVENUE_COMPARISON_VALUE);
      setSearchParams(searchParams);
    }
  }, [gameIdQuery, gamingRevenueComparisonQuery, providerIdQuery, searchParams, setSearchParams]);

  const sortByKeyValue = useMemo(() => {
    return (isSummaryTab && sortByKeyQuery === GameSessionsSortKeyParamParameter.Timestamp) || !sortByKeyQuery
      ? GameSessionsSortKeyParamParameter.GamingRevenue
      : sortByKeyQuery;
  }, [isSummaryTab, sortByKeyQuery]);

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      gap={2}
      sx={{
        [theme.breakpoints.up('xs')]: {
          flexDirection: 'column',
          width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
          flexDirection: 'row',
          mt: 4,
          width: '80%',
        },
      }}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          fullWidth
          size="small"
          label={t('userId')}
          type="text"
          value={userIdQuery || ''}
          onChange={event => updateGameSessionQueryParamsHandler(GameSummarySearchFilters.USER_ID, event.target.value)}
        />
        {isSummaryTab && (
          <Select
            id={GameSummarySearchFilters.GROUP_BY_KEY}
            label={capitalize('groupByKey')}
            value={groupByKeyQuery || GameSessionsSummaryGroupByKeyParamParameter.Game}
            onChange={event =>
              updateGameSessionQueryParamsHandler(GameSummarySearchFilters.GROUP_BY_KEY, event.target.value)
            }
            options={Object.values(GameSessionsSummaryGroupByKeyParamParameter)}
            sx={{
              [theme.breakpoints.up('lg')]: {
                width: '100%',
              },
            }}
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          [theme.breakpoints.up('xs')]: {
            width: '100%',
          },
          [theme.breakpoints.up('lg')]: {
            width: '30%',
          },
        }}
      >
        <Autocomplete
          fullWidth
          id={GameSummarySearchFilters.PROVIDER_ID}
          multiple={false}
          options={gameProviders || []}
          getOptionLabel={option => option?.providerName}
          renderInput={params => <TextField {...params} size="small" label={capitalize(t('gameprovider'))} />}
          value={chosenProvider || null}
          onChange={(e, provider) =>
            updateGameSessionQueryParamsHandler(GameSummarySearchFilters.PROVIDER_ID, provider?.providerId || '')
          }
        />
        <Autocomplete
          fullWidth
          id={GameSummarySearchFilters.GAME_ID}
          multiple={false}
          options={games?.data?.results || []}
          getOptionLabel={option => option?.gameName ?? ''}
          renderInput={params => <TextField {...params} size="small" label={capitalize(t('games'))} />}
          value={chosenGame || null}
          onChange={(e, gameOption) =>
            updateGameSessionQueryParamsHandler(GameSummarySearchFilters.GAME_ID, gameOption?.gameId || '')
          }
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        sx={{
          [theme.breakpoints.up('xs')]: {
            width: '100%',
          },
          [theme.breakpoints.up('lg')]: {
            width: '28%',
          },
        }}
      >
        <Select
          sx={{
            width: '100%',
          }}
          id={GameSummarySearchFilters.GAMING_REVENUE_COMPARISON}
          label={capitalize(t('gameSessions.titles.gamingRevenueComparison'))}
          value={gamingRevenueComparisonQuery || GameSessionsSummaryGamingRevenueComparisonParameter.None}
          required={!!gamingRevenueComparisonValueQuery}
          onChange={event => {
            if (event.target.value) {
              updateGameSessionQueryParamsHandler(
                GameSummarySearchFilters.GAMING_REVENUE_COMPARISON,
                event.target.value
              );
            }
          }}
          options={Object.values(GameSessionsSummaryGamingRevenueComparisonParameter)}
        />
        {gamingRevenueComparisonQuery &&
          gamingRevenueComparisonQuery !== GameSessionsSummaryGamingRevenueComparisonParameter.None && (
            <TextField
              size="small"
              fullWidth
              label={t('gameSessions.titles.gamingRevenueComparisonValue')}
              type="number"
              inputProps={{
                valueAsNumber: true,
              }}
              value={gamingRevenueComparisonValueQuery || ''}
              onChange={event =>
                updateGameSessionQueryParamsHandler(
                  GameSummarySearchFilters.GAMING_REVENUE_COMPARISON_VALUE,
                  event.target.value
                )
              }
              error={!gamingRevenueComparisonValueQuery && !!gamingRevenueComparisonQuery}
              required={!!gamingRevenueComparisonQuery}
            />
          )}
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Select
          id={GameSummarySearchFilters.SORT_BY_ORDER}
          label={capitalize(t('sortByOrder'))}
          value={sortByOrderQuery || SortOrderParamParameter.descending}
          onChange={event =>
            updateGameSessionQueryParamsHandler(GameSummarySearchFilters.SORT_BY_ORDER, event.target.value)
          }
          options={Object.values(SortOrderParamParameter)}
        />
        <Select
          id={GameSummarySearchFilters.SORT_BY_KEY}
          label={capitalize(t('sortByKey'))}
          value={sortByKeyValue}
          onChange={event =>
            updateGameSessionQueryParamsHandler(
              GameSummarySearchFilters.SORT_BY_KEY,
              event.target.value as GameSessionsSortKeyParamParameter
            )
          }
          options={Object.values(
            isSummaryTab ? GameSessionsSummarySortKeyParamParameter : GameSessionsSortKeyParamParameter
          )}
        />
      </Box>
    </Box>
  );
};

export default GameSessionAdditionalFilters;
