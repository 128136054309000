import React from 'react';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';

import { formatCurrency, formatPercentage, PercentageSpan } from '../../../../../app/helpers/transformFunctions';

const renderCashAndPromoCells = (params: GridRenderCellParams) => {
  const title = params.colDef.field;
  const value = params.row.value[title];

  switch (params.row.key) {
    case 'payout':
      return <PercentageSpan value={formatPercentage(value, { fixedTo: 2 })} colored={false} />;
    case 'revenue':
      return <strong>{formatCurrency(value)}</strong>;
    default:
      return formatCurrency(value);
  }
};

export const headers: GridColumns = [
  {
    headerName: '',
    field: 'title',
    width: 200,
    renderCell: (params: GridRenderCellParams) =>
      params.row.key === 'revenue' ? <strong>{params.value}</strong> : params.value,
  },
  {
    headerName: 'Cash',
    field: 'cash',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams) => renderCashAndPromoCells(params),
  },
  {
    headerName: 'Promo',
    field: 'promo',
    width: 150,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params: GridRenderCellParams) => renderCashAndPromoCells(params),
  },
];
