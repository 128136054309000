import { ETriggerIndividualActionBadRequestTypeSchema } from '@greenisland-api';

type ProcessIndividualActionErrorReasons = {
  [key in ETriggerIndividualActionBadRequestTypeSchema]: string;
};

export const PROCESS_INDIVIDUAL_ACTION_ERROR_REASONS: ProcessIndividualActionErrorReasons = {
  [ETriggerIndividualActionBadRequestTypeSchema.BonusesNotEnabled]:
    'marketing.individualActions.errors.bonusesNotEnabled',
  [ETriggerIndividualActionBadRequestTypeSchema.BoosterPacksNotEnabled]:
    'marketing.individualActions.errors.boosterPacksNotEnabled',
  [ETriggerIndividualActionBadRequestTypeSchema.BoostsNotEnabled]:
    'marketing.individualActions.errors.boostsNotEnabled',
};
