import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { GameStudioSchema } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';

import { CheckboxIcon, FontAwesomeIcon } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../../app/hooks';
import ImageCell from '../../Components/ImageCell';

const IMAGES = ['image', 'logoColorLight', 'logoColorDark', 'logoWhite', 'logoGrey', 'logoBlack'] as const;

export default function useGameStudioColumns(handleClickEdit: (categoryId?: string) => void) {
  const { t } = useTranslation();
  const canEditGameStudio = usePermission(OnlineCasinoPermissions.updateGameStudio);

  return useMemo(() => {
    const columns: GridColumns<GameStudioSchema> = [
      { field: 'code', headerName: t('content.games.studios.code'), flex: 1, minWidth: 150 },
      { field: 'gameStudioName', headerName: t('content.games.studios.gameStudioName'), flex: 1, minWidth: 150 },
      { field: 'urlName', headerName: t('content.games.studios.urlName'), flex: 1, minWidth: 150 },
      {
        field: 'enabled',
        headerName: t('content.games.studios.enabled'),
        type: 'boolean',
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
      },
      {
        field: 'isLive',
        headerName: t('content.games.studios.isLive'),
        type: 'boolean',
        flex: 0.5,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
      },
    ];

    IMAGES.forEach(image => {
      columns.push({
        field: `${image}Url`,
        headerName: t(`content.games.studios.${image}`),
        flex: 0.5,
        minWidth: 80,
        renderCell: (params: GridRenderCellParams<GameStudioSchema>) => <ImageCell value={params.row[`${image}Url`]} />,
      });
    });

    if (canEditGameStudio) {
      columns.push({
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<FontAwesomeIcon icon={faPencil} />}
            label="Edit"
            onClick={() => handleClickEdit(params.row.id)}
            key="edit"
          />,
        ],
      });
    }
    return columns;
  }, [canEditGameStudio, handleClickEdit, t]);
}
