import { IEntityState } from '@greenisland/stores';
import { EntitiesSchema } from '@greenisland-store/authorization';

import { BaseRouting } from './RouteInterfaces';

interface Props extends BaseRouting {
  entitySelected: IEntityState;
  agentEntities?: EntitiesSchema;
}

function LayoutContainer(props: Props) {
  const Layout = props.layout;

  return <Layout {...props} />;
}

export default LayoutContainer;
