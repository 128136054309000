import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { getGetAllAccountDataQueryKey, getGetBasicAccountDataQueryKey, useSetRefund } from '@greenisland-api';
import { Button, capitalize, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

type FormInputs = {
  amount: number;
  description: string;
};

interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
  userId: string;
}

const RefundDialog = ({ userId, setOpen, open }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { register, handleSubmit, errors } = useForm<FormInputs>({ mode: 'onChange' });
  const refundMutation = useSetRefund({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetAllAccountDataQueryKey(userId));
        await queryClient.invalidateQueries(getGetBasicAccountDataQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const handleSave = (values: FormInputs) => {
    refundMutation.mutate({ userId, data: values });
    setOpen(false);
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{capitalize(t('accountRefund'))}</DialogTitle>
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogContent sx={{ display: 'grid', gridGap: 16 }}>
          <TextField
            InputProps={{ startAdornment: <FontAwesomeIcon sx={{ pr: 2 }} icon={faEuroSign} /> }}
            inputProps={{ step: 0.01 }}
            size="small"
            name="amount"
            label={t('amount')}
            type="number"
            error={!!errors.amount}
            helperText={errors.amount?.message}
            inputRef={register({
              required: { value: true, message: t('allFieldsMustBeFilled') },
              pattern: { value: /^[0-9]+((.|,)[0-9]{1,2})?$/, message: t('amountValidationMessageDecimals') },
              min: { value: 0.01, message: t('amountValidationMessageZero') },
            })}
          />
          <TextField
            name="description"
            size="small"
            label={t('description')}
            multiline
            rows={4}
            error={!!errors.description}
            helperText={errors.description?.message}
            inputRef={register({ required: { value: true, message: t('allFieldsMustBeFilled') } })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="error" variant="outlined">
            {t('close')}
          </Button>
          <Button type="submit" variant="contained" color="primary">
            {t('refund')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(RefundDialog);
