import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener, Tooltip, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import classnames from 'classnames';

const Value = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  lineHeight: '100%',
  fontVariantNumeric: 'tabular-nums',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'normal',
  padding: 1,
  '&.found': {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },
  '&.notFound': {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  },
}));

interface ImageCellProps {
  value?: string;
}

const ImageCell = React.memo(function Image(props: ImageCellProps) {
  const { value } = props;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  if (isMobile) {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title={value && value !== '' ? <img src={value} width={230} /> : ''}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <Value
            className={classnames({
              found: value && value !== '',
              notFound: !value || value === '',
            })}
            onClick={handleTooltipOpen}
          >
            {t(value ? 'content.games.games.found' : 'content.games.games.notFound')}
          </Value>
        </Tooltip>
      </ClickAwayListener>
    );
  }

  return (
    <Tooltip title={value && value !== '' ? <img src={value} width={230} /> : ''} disableInteractive>
      <Value
        className={classnames({
          found: value && value !== '',
          notFound: !value || value === '',
        })}
      >
        {t(value ? 'content.games.games.found' : 'content.games.games.notFound')}
      </Value>
    </Tooltip>
  );
});

export default ImageCell;
