import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useGetComplaintTicket } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, CircularProgress, Stack } from '@mui/material';
import { usePermission } from 'src/app/hooks';

import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import AddComplaintDialog from './AddComplaintDialog';
import ComplaintChat from './ComplaintChat';
import ComplaintSummary from './ComplaintSummary';
import { WRITE_COMPLAINT_ENTRY_PERMISSION } from './permissions';

const Complaint = () => {
  const { complaintId = '' } = useParams();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const canReadComplaint = usePermission(OnlineCasinoPermissions.getComplaintTicket);
  const canAddComplaintEntry = usePermission(WRITE_COMPLAINT_ENTRY_PERMISSION);

  const {
    data: complaint,
    isLoading,
    isError,
  } = useGetComplaintTicket(parseInt(complaintId, 10), { query: { enabled: canReadComplaint && !!complaintId } });

  if (!canReadComplaint) return null;
  if (isError) return <ErrorState errorMessage={t('tasks.complaints.loadingErrorSingle')} />;
  if (isLoading || !complaint) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Stack spacing={3}>
        <ComplaintSummary complaint={complaint} />
        <ComplaintChat complaint={complaint} />
      </Stack>
      {canAddComplaintEntry ? <AddComplaintDialog setOpen={setIsOpen} open={isOpen} /> : null}
    </>
  );
};

export default Complaint;
