import React, { ForwardedRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link as LinkMUI } from '@mui/material';

type Props = Pick<RouterLinkProps, 'to'> & React.ComponentProps<typeof LinkMUI>;

const Link = ({ children, ...rest }: Props, ref: ForwardedRef<HTMLAnchorElement>) => {
  return (
    <LinkMUI ref={ref} {...rest} component={RouterLink}>
      {children}
    </LinkMUI>
  );
};

export default React.forwardRef(Link);
