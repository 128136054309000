import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox, FormControlLabel, SxProps } from '@mui/material';

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
  sx?: SxProps;
}

const CheckBox = ({ name, label, disabled, sx }: Props) => {
  const { control } = useFormContext();
  return (
    <FormControlLabel
      disabled={disabled}
      sx={{ ...sx, width: 'fit-content' }}
      control={
        <Controller
          defaultValue={false}
          name={name}
          control={control}
          render={({ value, onChange, ...rest }) => (
            <Checkbox
              {...rest}
              checked={Boolean(value)}
              onChange={e => onChange(e.target.checked)}
              disabled={disabled}
            />
          )}
        />
      }
      label={label}
    />
  );
};

export default CheckBox;
