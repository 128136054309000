import { useTranslation } from 'react-i18next';
import { faLink, faLinkSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor, Element as SlateElement, Range, Transforms } from 'slate';
import { useSlate } from 'slate-react';

import { CustomEditor, LinkElement } from '../types';
import { Button } from './RichTextComponents';

const isLinkActive = (editor: CustomEditor) => {
  const [link] = Editor.nodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
  return !!link;
};

const unwrapLink = (editor: CustomEditor) => {
  Transforms.unwrapNodes(editor, {
    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

export const wrapLink = (editor: CustomEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const insertLink = (editor: CustomEditor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

export const AddLinkButton = () => {
  const editor = useSlate();
  const { t } = useTranslation();
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={(event: { preventDefault: () => void }) => {
        event.preventDefault();
        const url = window.prompt(t('textEditor.enterUrl'));
        if (!url) return;
        insertLink(editor, url);
      }}
    >
      <FontAwesomeIcon icon={faLink} />
    </Button>
  );
};

export const RemoveLinkButton = () => {
  const editor = useSlate();

  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={() => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      }}
    >
      <FontAwesomeIcon icon={faLinkSlash} />
    </Button>
  );
};
