/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  DefaultResponse,
  GetLoyaltySpendablePointsTxParams,
  GetLoyaltyStatusPointsTxParams,
  LoyaltyDetailsSchema,
  LoyaltyPointsArgumentsSchema,
  LoyaltyRankArgumentsSchema,
  LoyaltyUserBadRequestResponse,
  NotFoundResponse,
  PagedLoyaltySpendablePointsBalanceTxSchema,
  PagedLoyaltyStatusPointsBalanceTxSchema,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get the loyalty details for user
 */
export const useGetUserLoyaltyDetailsHook = () => {
  const getUserLoyaltyDetails = useCustomInstance<LoyaltyDetailsSchema>();

  return (userId: number, signal?: AbortSignal) => {
    return getUserLoyaltyDetails({ url: `/loyalty/users/v2/${userId}`, method: 'get', signal });
  };
};

export const getGetUserLoyaltyDetailsQueryKey = (userId: number) => [`/loyalty/users/v2/${userId}`] as const;

export const useGetUserLoyaltyDetailsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserLoyaltyDetailsHook>>>,
  TError = LoyaltyUserBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserLoyaltyDetailsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserLoyaltyDetailsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserLoyaltyDetailsQueryKey(userId);

  const getUserLoyaltyDetails = useGetUserLoyaltyDetailsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserLoyaltyDetailsHook>>>> = ({ signal }) =>
    getUserLoyaltyDetails(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserLoyaltyDetailsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserLoyaltyDetailsHook>>>
>;
export type GetUserLoyaltyDetailsQueryError =
  | LoyaltyUserBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the loyalty details for user
 */
export const useGetUserLoyaltyDetails = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserLoyaltyDetailsHook>>>,
  TError = LoyaltyUserBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserLoyaltyDetailsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserLoyaltyDetailsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Award the user new loyalty points
 */
export const useAwardLoyaltyPointsToUserHook = () => {
  const awardLoyaltyPointsToUser = useCustomInstance<void>();

  return (userId: number, loyaltyPointsArgumentsSchema: LoyaltyPointsArgumentsSchema) => {
    return awardLoyaltyPointsToUser({
      url: `/loyalty/users/v2/${userId}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: loyaltyPointsArgumentsSchema,
    });
  };
};

export const useAwardLoyaltyPointsToUserMutationOptions = <
  TError = LoyaltyUserBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAwardLoyaltyPointsToUserHook>>>,
    TError,
    { userId: number; data: LoyaltyPointsArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAwardLoyaltyPointsToUserHook>>>,
  TError,
  { userId: number; data: LoyaltyPointsArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const awardLoyaltyPointsToUser = useAwardLoyaltyPointsToUserHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAwardLoyaltyPointsToUserHook>>>,
    { userId: number; data: LoyaltyPointsArgumentsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return awardLoyaltyPointsToUser(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AwardLoyaltyPointsToUserMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAwardLoyaltyPointsToUserHook>>>
>;
export type AwardLoyaltyPointsToUserMutationBody = LoyaltyPointsArgumentsSchema;
export type AwardLoyaltyPointsToUserMutationError =
  | LoyaltyUserBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Award the user new loyalty points
 */
export const useAwardLoyaltyPointsToUser = <
  TError = LoyaltyUserBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAwardLoyaltyPointsToUserHook>>>,
    TError,
    { userId: number; data: LoyaltyPointsArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAwardLoyaltyPointsToUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Gets the loyalty spendable points for the given user
 * @summary Get loyalty spendable points
 */
export const useGetLoyaltySpendablePointsTxHook = () => {
  const getLoyaltySpendablePointsTx = useCustomInstance<PagedLoyaltySpendablePointsBalanceTxSchema>();

  return (userId: number, params?: GetLoyaltySpendablePointsTxParams, signal?: AbortSignal) => {
    return getLoyaltySpendablePointsTx({
      url: `/loyalty/users/v2/${userId}/tx/spendable`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getGetLoyaltySpendablePointsTxQueryKey = (userId: number, params?: GetLoyaltySpendablePointsTxParams) =>
  [`/loyalty/users/v2/${userId}/tx/spendable`, ...(params ? [params] : [])] as const;

export const useGetLoyaltySpendablePointsTxQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoyaltySpendablePointsTxHook>>>,
  TError = LoyaltyUserBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params?: GetLoyaltySpendablePointsTxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltySpendablePointsTxHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltySpendablePointsTxHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLoyaltySpendablePointsTxQueryKey(userId, params);

  const getLoyaltySpendablePointsTx = useGetLoyaltySpendablePointsTxHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLoyaltySpendablePointsTxHook>>>> = ({
    signal,
  }) => getLoyaltySpendablePointsTx(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetLoyaltySpendablePointsTxQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLoyaltySpendablePointsTxHook>>>
>;
export type GetLoyaltySpendablePointsTxQueryError =
  | LoyaltyUserBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get loyalty spendable points
 */
export const useGetLoyaltySpendablePointsTx = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoyaltySpendablePointsTxHook>>>,
  TError = LoyaltyUserBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params?: GetLoyaltySpendablePointsTxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltySpendablePointsTxHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetLoyaltySpendablePointsTxQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Gets the loyalty status points for the given user
 * @summary Get loyalty status points
 */
export const useGetLoyaltyStatusPointsTxHook = () => {
  const getLoyaltyStatusPointsTx = useCustomInstance<PagedLoyaltyStatusPointsBalanceTxSchema>();

  return (userId: number, params?: GetLoyaltyStatusPointsTxParams, signal?: AbortSignal) => {
    return getLoyaltyStatusPointsTx({ url: `/loyalty/users/v2/${userId}/tx/status`, method: 'get', params, signal });
  };
};

export const getGetLoyaltyStatusPointsTxQueryKey = (userId: number, params?: GetLoyaltyStatusPointsTxParams) =>
  [`/loyalty/users/v2/${userId}/tx/status`, ...(params ? [params] : [])] as const;

export const useGetLoyaltyStatusPointsTxQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoyaltyStatusPointsTxHook>>>,
  TError = LoyaltyUserBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params?: GetLoyaltyStatusPointsTxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyStatusPointsTxHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyStatusPointsTxHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLoyaltyStatusPointsTxQueryKey(userId, params);

  const getLoyaltyStatusPointsTx = useGetLoyaltyStatusPointsTxHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyStatusPointsTxHook>>>> = ({
    signal,
  }) => getLoyaltyStatusPointsTx(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetLoyaltyStatusPointsTxQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLoyaltyStatusPointsTxHook>>>
>;
export type GetLoyaltyStatusPointsTxQueryError =
  | LoyaltyUserBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get loyalty status points
 */
export const useGetLoyaltyStatusPointsTx = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoyaltyStatusPointsTxHook>>>,
  TError = LoyaltyUserBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params?: GetLoyaltyStatusPointsTxParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyStatusPointsTxHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetLoyaltyStatusPointsTxQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Set the loyalty rank for a user
 */
export const useSetLoyaltyRankHook = () => {
  const setLoyaltyRank = useCustomInstance<void>();

  return (userId: number, loyaltyRankArgumentsSchema: LoyaltyRankArgumentsSchema) => {
    return setLoyaltyRank({
      url: `/loyalty/users/v2/${userId}/rank`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: loyaltyRankArgumentsSchema,
    });
  };
};

export const useSetLoyaltyRankMutationOptions = <
  TError = LoyaltyUserBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetLoyaltyRankHook>>>,
    TError,
    { userId: number; data: LoyaltyRankArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetLoyaltyRankHook>>>,
  TError,
  { userId: number; data: LoyaltyRankArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setLoyaltyRank = useSetLoyaltyRankHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetLoyaltyRankHook>>>,
    { userId: number; data: LoyaltyRankArgumentsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return setLoyaltyRank(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetLoyaltyRankMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useSetLoyaltyRankHook>>>>;
export type SetLoyaltyRankMutationBody = LoyaltyRankArgumentsSchema;
export type SetLoyaltyRankMutationError =
  | LoyaltyUserBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Set the loyalty rank for a user
 */
export const useSetLoyaltyRank = <
  TError = LoyaltyUserBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetLoyaltyRankHook>>>,
    TError,
    { userId: number; data: LoyaltyRankArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useSetLoyaltyRankMutationOptions(options);

  return useMutation(mutationOptions);
};
