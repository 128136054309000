import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { MaxDepositLimitSchema, useGetMaximumLoginSessionLimits } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { capitalize, Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowParams, GridRowsProp } from '@mui/x-data-grid-pro';
import { formatDuration } from 'date-fns';
import { usePermission } from 'src/app/hooks';

import {
  DataGridContainer,
  DateTime,
  FontAwesomeIcon,
  PermissionWrapper,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { MaxLimitType } from '../../Constants/Limits';
import MaxLimitCancelDialog from '../../MaxLimitCancelDialog';

type DepositLimitsProps = {
  userId: string;
  compact?: boolean;
  startDate?: Date;
  endDate?: Date;
};

const MaximumLimitsTable = ({ userId, compact = false }: DepositLimitsProps) => {
  const { t } = useTranslation();

  const canReadDepositLimits = usePermission(OnlineCasinoPermissions.getActiveMaximumLoginSessionLimits);
  const canCancelMaximumSessionLimit = usePermission(OnlineCasinoPermissions.cancelMaximumLoginSessionLimit);

  const [selectedMaxLimit, setSelectedMaxLimit] = useState<MaxDepositLimitSchema | null>(null);

  const {
    data: maxDepositLimits,
    isLoading,
    isError,
    error,
  } = useGetMaximumLoginSessionLimits(parseInt(userId, 10), { query: { enabled: canReadDepositLimits } });

  const headers: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      minWidth: 50,
      flex: 0.05,
      headerName: '',
      getActions: (params: GridRowParams) => [
        <>
          {canCancelMaximumSessionLimit && (
            <Tooltip title={t('cancel')} key="delete">
              <GridActionsCellItem
                label={t('cancel')}
                icon={<FontAwesomeIcon icon={faTrash} sx={{ color: 'error.main' }} />}
                onClick={() => {
                  if (params?.row) {
                    setSelectedMaxLimit(params.row);
                  }
                }}
                key="cancel"
              />
            </Tooltip>
          )}
        </>,
      ],
    },
    {
      headerName: capitalize(t('startDate')),
      field: 'startDate',
      renderCell: ({ row }) => <DateTime dateTime={row.startDate ?? 0} />,
      flex: 0.1,
      minWidth: 100,
    },
    {
      headerName: capitalize(t('expiryDate')),
      field: 'expiryDate',
      renderCell: ({ row }) => <DateTime dateTime={row.expiryDate ?? 0} />,
      flex: 0.1,
      minWidth: 100,
    },
    { headerName: capitalize(t('id')), field: 'pkey', flex: 0.1, minWidth: 100 },
    { headerName: capitalize(t('reason')), field: 'reason', flex: 0.2, minWidth: 240 },
    { headerName: capitalize(t('period')), field: 'period', flex: 0.1, minWidth: 100 },
    {
      headerName: capitalize(t('value')),
      field: 'value',
      valueFormatter: ({ value }) => formatDuration({ seconds: value }),
      flex: 0.1,
      minWidth: 100,
    },
    {
      headerName: capitalize(t('created')),
      field: 'created',
      renderCell: ({ row }) => <DateTime dateTime={row?.created ?? 0} />,
      flex: 0.1,
      minWidth: 140,
    },
    {
      headerName: capitalize(t('createdBy')),
      field: 'createdBy',
      flex: 0.2,
      minWidth: 250,
    },
  ];

  const initialState = compact
    ? {
        columns: {
          columnVisibilityModel: {
            userId: false,
            created: false,
          },
        },
      }
    : {};

  const rows: GridRowsProp = maxDepositLimits?.map(row => ({ ...row, id: row.pkey })) ?? [];

  return (
    <>
      <PermissionWrapper
        errorMessage={error?.message}
        isError={isError}
        isLoading={isLoading}
        permission={OnlineCasinoPermissions.getActiveMaximumLoginSessionLimits}
      >
        <DataGridContainer>
          <StyledDataGrid pagination initialState={initialState} hideFooter={compact} columns={headers} rows={rows} />
        </DataGridContainer>
      </PermissionWrapper>
      {selectedMaxLimit ? (
        <MaxLimitCancelDialog
          open={true}
          onClose={() => setSelectedMaxLimit(null)}
          type={MaxLimitType.SESSION_LIMIT}
          selectedMaxLimit={selectedMaxLimit}
        />
      ) : null}
    </>
  );
};

export default memo(MaximumLimitsTable);
