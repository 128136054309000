import { useTranslation } from 'react-i18next';
import { useGetVerifications } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { capitalize, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { Link, StyledDataGrid } from '@greenisland-common/components/atoms';

import { transformUnixDateV2 } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../Lookup/LookupPermissions';

const RegistrationFraud = () => {
  const { t } = useTranslation();
  const canLookupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });
  const canReadVerifications = usePermission(OnlineCasinoPermissions.getVerifications);
  const canReadVerificationDetails = usePermission(OnlineCasinoPermissions.getUserVerifications);
  const { data: verifications, isLoading: fetching } = useGetVerifications({
    query: { enabled: canReadVerifications },
  });

  const columns = [
    {
      ...transformUnixDateV2('modifiedOn', t),
      flex: 1,
    },
    {
      field: 'username',
      headerName: capitalize(t('username')),
      flex: 1,
      renderCell: params => {
        const { userId, username } = params.row;
        return canLookupUser ? (
          <Link to={`../../users/${userId}/details`}>{username}</Link>
        ) : (
          <Typography>{username}</Typography>
        );
      },
    } as GridColDef,
    {
      field: 'countryCode',
      headerName: capitalize(t('country')),
      flex: 1,
    },
    {
      field: 'reason',
      headerName: capitalize(t('reason')),
      flex: 1,
    },
    {
      field: 'details',
      headerName: capitalize(t('details')),
      flex: 1,
      sortable: false,
      renderCell: params => {
        return canReadVerificationDetails ? (
          <Link to={`../../users/${params.row.userId}/verifications`}>{t('details')}</Link>
        ) : null;
      },
    } as GridColDef,
  ];

  const rows = verifications
    ? verifications
        .filter(
          verification => verification.reason === 'RegistrationFraud' || verification.reason === 'RegistrationBonus'
        )
        .map((item, index) => ({
          id: index,
          ...item,
        }))
    : [];

  return canReadVerifications ? (
    <StyledDataGrid
      columns={columns}
      rows={rows}
      loading={fetching}
      disableSelectionOnClick
      disableColumnMenu
      pagination
      pageSize={20}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
    />
  ) : null;
};

export default RegistrationFraud;
