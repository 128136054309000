/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type { GetJackpotInstancesParams, JackpotInstancePaginated } from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Get the jackpot instances
 */
export const useGetJackpotInstancesHook = () => {
  const getJackpotInstances = useCustomInstance<JackpotInstancePaginated>();

  return (params?: GetJackpotInstancesParams, signal?: AbortSignal) => {
    return getJackpotInstances({ url: `/jackpot-instances`, method: 'get', params, signal });
  };
};

export const getGetJackpotInstancesQueryKey = (params?: GetJackpotInstancesParams) =>
  [`/jackpot-instances`, ...(params ? [params] : [])] as const;

export const useGetJackpotInstancesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetJackpotInstancesHook>>>,
  TError = unknown
>(
  params?: GetJackpotInstancesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJackpotInstancesHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJackpotInstancesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetJackpotInstancesQueryKey(params);

  const getJackpotInstances = useGetJackpotInstancesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetJackpotInstancesHook>>>> = ({ signal }) =>
    getJackpotInstances(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetJackpotInstancesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetJackpotInstancesHook>>>
>;
export type GetJackpotInstancesQueryError = unknown;

export const useGetJackpotInstances = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetJackpotInstancesHook>>>,
  TError = unknown
>(
  params?: GetJackpotInstancesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetJackpotInstancesHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetJackpotInstancesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
