import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {
  EMaxLimitBadRequestTypeSchema,
  getGetActiveMaximumBalanceLimitQueryKey,
  getGetActiveMaximumDepositLimitsQueryKey,
  getGetActiveMaximumLoginSessionLimitsQueryKey,
  MaxDepositLimitSchema,
  useCancelMaximumBalanceLimit,
  useCancelMaximumDepositLimit,
  useCancelMaximumLoginSessionLimit,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

import { MaxLimitType } from './Constants/Limits';
import { MAX_LIMIT_ERROR_REASONS } from './Constants/MaxLimitErrorReasons';
import {
  formatCurrency,
  getDateTimeFromUnix,
  getDurationFromSeconds,
} from '../../../../../app/helpers/transformFunctions';

interface MaxLimitInfoFieldProps {
  label: string;
  value?: string;
  isDate?: boolean;
}
const MaxLimitInfoField = ({ label, value, isDate = false }: MaxLimitInfoFieldProps) => {
  const { t } = useTranslation();
  const formattedDate = isDate && value ? getDateTimeFromUnix(Number(value)).split(' ') : undefined;

  return (
    <Typography fontWeight={500} variant="body2" fontSize="small" mb={1}>
      {`${capitalize(t(label))}: `}
      <span style={{ fontWeight: 400 }}>
        {formattedDate ? `${formattedDate[0]} ${formattedDate[1]}` : value || '-'}
      </span>
    </Typography>
  );
};

interface Props {
  open: boolean;
  onClose: () => void;
  type: MaxLimitType;
  selectedMaxLimit?: MaxDepositLimitSchema;
}

const MaxLimitCancelDialog = ({ open, onClose, type, selectedMaxLimit }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { userId } = useParams();

  const [reason, setReason] = useState('');

  const onSuccessHandler = useCallback(
    (query: (userId: number) => any, userId?: string) => {
      if (userId) {
        queryClient.invalidateQueries(query(Number(userId)));
        enqueueSnackbar(t('success'), { variant: 'success' });
        onClose();
      }
    },
    [queryClient, enqueueSnackbar, t, onClose]
  );

  const onErrorHandler = useCallback(
    error => {
      if ('type' in error && error.type) {
        const errorReason: EMaxLimitBadRequestTypeSchema | undefined = error.type;
        const translationKey = errorReason ? MAX_LIMIT_ERROR_REASONS[errorReason] : 'somethingWentWrong';
        enqueueSnackbar(t(translationKey), { variant: 'error' });
      } else {
        enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
      }
    },
    [enqueueSnackbar, t]
  );

  const { mutate: cancelMaximumDepositLimit, isLoading: isCancelMaximumDepositLimitLoading } =
    useCancelMaximumDepositLimit({
      mutation: {
        onSuccess: () => onSuccessHandler(getGetActiveMaximumDepositLimitsQueryKey, userId),
        onError: error => onErrorHandler(error),
      },
    });

  const { mutate: cancelMaximumBalanceLimit, isLoading: isCancelMaximumBalanceLimitLoading } =
    useCancelMaximumBalanceLimit({
      mutation: {
        onSuccess: () => onSuccessHandler(getGetActiveMaximumBalanceLimitQueryKey, userId),
        onError: error => onErrorHandler(error),
      },
    });

  const { mutate: cancelMaximumLoginSessionLimit, isLoading: isCancelMaximumLoginSessionLimitLoading } =
    useCancelMaximumLoginSessionLimit({
      mutation: {
        onSuccess: () => onSuccessHandler(getGetActiveMaximumLoginSessionLimitsQueryKey, userId),
        onError: error => onErrorHandler(error),
      },
    });

  const cancelMaxLimitHandler = () => {
    if (userId && selectedMaxLimit) {
      const payload = {
        userId: Number(userId),
        data: {
          maxLimitId: selectedMaxLimit.pkey,
          cancellationReason: reason,
        },
      };

      if (type === MaxLimitType.DEPOSIT_LIMIT) {
        return cancelMaximumDepositLimit(payload);
      } else if (type === MaxLimitType.BALANCE_LIMIT) {
        return cancelMaximumBalanceLimit(payload);
      } else {
        return cancelMaximumLoginSessionLimit(payload);
      }
    }
  };

  const getMaxLimitValue = useMemo(() => {
    if (!selectedMaxLimit) {
      return undefined;
    }

    if (type !== MaxLimitType.SESSION_LIMIT) {
      return formatCurrency(selectedMaxLimit.value);
    }

    return getDurationFromSeconds(selectedMaxLimit.value, ['hours', 'minutes', 'seconds'], true, ' ');
  }, [selectedMaxLimit, type]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{t('common.cancelMaxLimit')}</DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <Typography fontWeight={500} variant="body1" sx={{ mb: 2, fontSize: '18px' }}>
          {capitalize(t('common.cancelMaxLimitConfirmation'))}
        </Typography>
        <Stack spacing={2}>
          <Typography fontWeight={500} variant="body1" fontSize="medium" sx={{ pt: 1, mb: 2 }}>
            {`${capitalize(t('details'))}:`}
          </Typography>
          <Box display="flex" flexDirection="column" mt={4}>
            <MaxLimitInfoField label="id" value={selectedMaxLimit?.pkey?.toString()} />
            <MaxLimitInfoField label="type" value={capitalize(type.split('_').join(' ').toLowerCase())} />
            <MaxLimitInfoField label="startDate" value={selectedMaxLimit?.startDate?.toString()} isDate={true} />
            <MaxLimitInfoField label="expiryDate" value={selectedMaxLimit?.expiryDate?.toString()} isDate={true} />
            {type !== MaxLimitType.BALANCE_LIMIT ? (
              <MaxLimitInfoField label="period" value={selectedMaxLimit?.period?.toString()} />
            ) : null}
            <MaxLimitInfoField label="value" value={getMaxLimitValue} />
            <MaxLimitInfoField label="reason" value={selectedMaxLimit?.reason?.toString()} />
          </Box>
          <TextField
            fullWidth
            multiline={true}
            size="small"
            label={t('cancelReason')}
            type="text"
            value={reason}
            required={true}
            minRows={4}
            onChange={e => setReason(e.target.value)}
          />
        </Stack>
        <DialogActions sx={{ mt: 2 }}>
          <Button variant="text" onClick={onClose} color="secondary">
            {t('close')}
          </Button>
          <LoadingButton
            color="primary"
            type="submit"
            variant="contained"
            onClick={cancelMaxLimitHandler}
            loading={
              isCancelMaximumDepositLimitLoading ||
              isCancelMaximumBalanceLimitLoading ||
              isCancelMaximumLoginSessionLimitLoading
            }
            disabled={!reason}
          >
            {t('common.cancelMaxLimit')}
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default MaxLimitCancelDialog;
