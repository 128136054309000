import { Outlet } from 'react-router';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';

import GameSessionsPage from '../../pages/OnlineCasino/GameSessionsPage/GameSessionsPage';
import LookupDeposits from '../../pages/OnlineCasino/Lookup/Deposits/LookupDeposits';
import EventsStreamById from '../../pages/OnlineCasino/Lookup/Eventstreams/EventsStreamById';
import Transactions from '../../pages/OnlineCasino/Lookup/Transactions/Transactions';
import AddTestUser from '../../pages/OnlineCasino/Lookup/Users/components/forms/AddTestUser';
import Lookup from '../../pages/OnlineCasino/Lookup/Users/Lookup';
import LookupWithdrawals from '../../pages/OnlineCasino/Lookup/Withdrawals/LookupWithdrawals';
import { BaseRoute } from '../RouteInterfaces';

export const lookupRoutes: Array<BaseRoute> = [
  {
    path: 'lookup',
    name: 'Search',
    element: Outlet,
    icon: faAddressBook,
    collapse: true,
    permissions: [
      OnlineCasinoPermissions.advancedUserSearchV2,
      OnlineCasinoPermissions.getEventstreamStreamId,
      OnlineCasinoPermissions.getWithdrawalsGeneral,
      OnlineCasinoPermissions.getCasinos,
      OnlineCasinoPermissions.getDepositsGeneral,
      OnlineCasinoPermissions.getDepositDetails,
      OnlineCasinoPermissions.getTransactionsGeneral,
    ],
    children: [
      {
        path: 'users',
        name: 'Users',
        element: Outlet,
        children: [
          {
            path: '',
            name: 'Users',
            element: Lookup,
            mini: 'U',
            icon: faAddressBook,
            permissions: OnlineCasinoPermissions.advancedUserSearchV2,
          },
          {
            path: 'add',
            name: 'Add Test User',
            element: AddTestUser,
            mini: 'CTU',
          },
        ],
      },
      {
        path: 'withdrawals',
        name: 'Withdrawals',
        mini: 'W',
        element: LookupWithdrawals,
        permissions: [
          OnlineCasinoPermissions.advancedUserSearchV2,
          OnlineCasinoPermissions.getEventstreamStreamId,
          OnlineCasinoPermissions.getWithdrawalsGeneral,
          OnlineCasinoPermissions.getCasinos,
        ],
      },
      {
        path: 'deposits',
        name: 'Deposits',
        mini: 'D',
        element: LookupDeposits,
        permissions: [
          OnlineCasinoPermissions.advancedUserSearchV2,
          OnlineCasinoPermissions.getDepositsGeneral,
          OnlineCasinoPermissions.getDepositDetails,
        ],
      },
      {
        path: 'txs',
        name: 'Transactions',
        mini: 'T',
        element: Transactions,
        permissions: [OnlineCasinoPermissions.getTransactionsGeneral],
      },
      {
        path: 'gamesessionsoverview',
        name: 'Game Sessions',
        element: GameSessionsPage,
        hiddenLink: false,
        permissions: [OnlineCasinoPermissions.getGameSessionSummaries, OnlineCasinoPermissions.getGameSessions],
      },
      {
        path: 'eventstreams',
        name: 'Eventstreams',
        element: EventsStreamById,
        permissions: [OnlineCasinoPermissions.getPagedEventstreamByStreamId],
      },
    ],
  },
];

export default lookupRoutes;
