import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreateTestUserRequestSchema } from '@greenisland-api';
import { Box, capitalize, Typography, useTheme } from '@mui/material';

import Input from '@greenisland-common/components/molecules/Input';

const PASSWORD_VALIDATION_REGEX =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{}[\]:;,.<>?\\`~"/|_]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

const TestUserPasswordSettings = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { control, errors } = useFormContext<CreateTestUserRequestSchema>();

  return (
    <Box mb={6}>
      <Typography fontWeight={500} variant="body1" fontSize="medium" sx={{ mb: 1, mt: 2 }}>
        {capitalize(t('lookup.users.titles.choosePassword'))}
      </Typography>
      <Input
        fullWidth
        size="small"
        label={capitalize(t('password'))}
        control={control}
        type="text"
        name="password"
        required
        rules={{
          required: t('fieldIsRequired'),
          pattern: {
            value: PASSWORD_VALIDATION_REGEX,
            message: t('common.passwordSecurityRequirements'),
          },
        }}
        error={!!errors['password']}
        helperText={errors['password']?.message}
        sx={{
          [theme.breakpoints.up('xs')]: {
            width: '100%',
            mb: 2,
          },
          [theme.breakpoints.up('md')]: {
            width: '40%',
          },
        }}
      />
    </Box>
  );
};

export default TestUserPasswordSettings;
