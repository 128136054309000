import { Outlet } from 'react-router';
import { faGavel } from '@fortawesome/free-solid-svg-icons';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';

import FraudTransactions from '../../pages/OnlineCasino/Fraud/FraudTransactions/FraudTransactions';
import IPWhitelist from '../../pages/OnlineCasino/Fraud/IPWhitelist/IPWhitelist';
import RegistrationFraud from '../../pages/OnlineCasino/Fraud/RegistrationFraud/RegistrationFraud';
import { BaseRoute } from '../RouteInterfaces';

export const fraudRoutes: Array<BaseRoute> = [
  {
    path: 'fraud',
    name: 'Fraud',
    icon: faGavel,
    collapse: true,
    permissions: [
      OnlineCasinoPermissions.solveIntervention,
      OnlineCasinoPermissions.getIpWhitelist,
      OnlineCasinoPermissions.getWithdrawalsGeneral,
      OnlineCasinoPermissions.getDepositsGeneral,
      OnlineCasinoPermissions.advancedUserSearchV2,
      OnlineCasinoPermissions.getWithdrawalDetailsV2,
      OnlineCasinoPermissions.getDepositDetails,
    ],
    element: Outlet,
    children: [
      {
        path: 'fraud-transactions',
        name: 'Transactions',
        element: FraudTransactions,
        mini: 'FT',
        permissions: [
          OnlineCasinoPermissions.getWithdrawalsGeneral,
          OnlineCasinoPermissions.getDepositsGeneral,
          OnlineCasinoPermissions.advancedUserSearchV2,
          OnlineCasinoPermissions.getWithdrawalDetailsV2,
          OnlineCasinoPermissions.getDepositDetails,
        ],
      },
      {
        path: 'registration-fraud',
        name: 'Registration Fraud',
        element: RegistrationFraud,
        mini: 'RF',
        permissions: OnlineCasinoPermissions.solveIntervention,
      },
      {
        name: 'IP whitelist',
        path: 'ip',
        element: IPWhitelist,
        mini: 'IP',
        permissions: OnlineCasinoPermissions.getIpWhitelist,
      },
    ],
  },
];

export default fraudRoutes;
