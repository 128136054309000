import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from '@mui/material';

import { LimitSearchFilters } from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/Constants/Limits';

export interface Tab {
  title: string;
  content: JSX.Element;
  visible?: boolean;
}

interface Props {
  tabs: Tab[];
  activeTab?: number;
  setActiveTab?: (value: number) => void;
  updateActiveTabParamsHandler?: (key: string, value: string) => void;
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const TabMenu = ({ tabs, activeTab = 0, setActiveTab, updateActiveTabParamsHandler }: Props) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(activeTab);

  return (
    <>
      <Tabs
        variant="scrollable"
        sx={{ marginBottom: 2 }}
        value={selectedTab}
        onChange={(_, value) => {
          setSelectedTab(value);
          setActiveTab && setActiveTab(value);
          updateActiveTabParamsHandler && updateActiveTabParamsHandler(LimitSearchFilters.ACTIVE_TAB, value);
        }}
      >
        {tabs.map(
          (tab, index) =>
            tab.visible !== false && <Tab key={`tab-menu-${index}-${tab}`} label={t(tab.title)} {...a11yProps(index)} />
        )}
      </Tabs>
      {tabs[selectedTab].content}
    </>
  );
};

export default TabMenu;
