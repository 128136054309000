import { useTranslation } from 'react-i18next';
import { Button, Card, CardContent, Stack, Typography } from '@mui/material';

import config from '../../../../config';

const Info = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {t('information')}
        </Typography>
        <Stack spacing={2}>
          <Typography variant="body1">{t('BOversion', { version: config.version })}</Typography>
          <Button
            size="small"
            variant="contained"
            onClick={() => window.location.reload()}
            sx={{ width: 'fit-content' }}
          >
            {t('checkNewVersion')}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Info;
