import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMediaGalleryEntryTypeSchema } from '@greenisland-api';
import { Box, Typography } from '@mui/material';

import MediaField from '@greenisland-common/components/molecules/MediaField/MediaField';

const images = {
  logoColorLight: EMediaGalleryEntryTypeSchema.GameStudioLogoColorLight,
  logoColorDark: EMediaGalleryEntryTypeSchema.GameStudioLogoColorDark,
  logoWhite: EMediaGalleryEntryTypeSchema.GameStudioLogoWhite,
  logoGrey: EMediaGalleryEntryTypeSchema.GameStudioLogoGrey,
  logoBlack: EMediaGalleryEntryTypeSchema.GameStudioLogoBlack,
} as const;

const GameStudioImages = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="space-between" gap={2}>
      {Object.entries(images).map(([name, type]) => (
        <Box key={name} display="flex" flexDirection="column" alignItems="center">
          <Typography variant="caption">{t(`content.games.studios.${name}`)}</Typography>
          <MediaField control={control} name={`${name}Url`} mediaGalleryEntryType={type} />
        </Box>
      ))}
    </Box>
  );
};

export default GameStudioImages;
