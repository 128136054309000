import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

interface Props {
  name: string;
  label?: string;
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
  disabled?: boolean;
  size?: TextFieldProps['size'];
}

const DateField = ({ name, disabled = false, label, rules, size }: Props) => {
  const { control, errors } = useFormContext();

  return (
    <Controller
      rules={rules}
      name={name}
      defaultValue=""
      control={control}
      render={({ value, ...renderProps }) => (
        <DatePicker
          value={value ?? ''}
          label={label}
          {...renderProps}
          renderInput={params => (
            <TextField
              fullWidth
              {...params}
              error={Boolean(errors?.[name])}
              helperText={errors[name]?.message}
              size={size}
            />
          )}
          disabled={disabled}
          inputFormat="dd/MM/yyyy"
          mask="__/__/____"
          views={['year', 'month', 'day']}
        />
      )}
    />
  );
};

export default DateField;
