/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * images https://developer.mozilla.org/fr/docs/Web/HTTP/Basics_of_HTTP/MIME_types
 */
export type EMIMETypeSchema = (typeof EMIMETypeSchema)[keyof typeof EMIMETypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EMIMETypeSchema = {
  'image/png': 'image/png',
  'image/jpg': 'image/jpg',
  'image/jpeg': 'image/jpeg',
  'application/pdf': 'application/pdf',
  'video/mp4': 'video/mp4',
  'application/xml': 'application/xml',
  'application/vndopenxmlformats-officedocumentspreadsheetmlsheet':
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'image/bmp': 'image/bmp',
  'image/webp': 'image/webp',
  'image/gif': 'image/gif',
  'image/svg+xml': 'image/svg+xml',
} as const;
