import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useGetBasicAccountData } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';
import TabMenu, { Tab } from 'src/routes/components/TabMenu';

import { Link } from '@greenisland-common/components/atoms';

import LoyaltySpendablePointsTransactionsTable from './Tables/LoyaltySpendablePointsTransactionsTable';
import LoyaltyStatusPointsTransactionsTable from './Tables/LoyaltyStatusPointsTransactionsTable';
import { usePermission } from '../../../../../../app/hooks';
import { EventStreamParamsType } from '../Constants/Eventstreams';

type LoyaltyTransactionsProps = {
  userId: number;
};

const LoyaltyTransactionsCard = ({ userId }: LoyaltyTransactionsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const canReadLoyaltyStatusPointsTransactions = usePermission(OnlineCasinoPermissions.getLoyaltyStatusPointsTx);
  const canReadLoyaltySpendablePointsTransactions = usePermission(OnlineCasinoPermissions.getLoyaltySpendablePointsTx);
  const canReadLoyaltyTransactions =
    canReadLoyaltyStatusPointsTransactions || canReadLoyaltySpendablePointsTransactions;
  const canReadEventstreamById = usePermission(OnlineCasinoPermissions.getPagedEventstreamByStreamId);
  const canReadBasicAccountData = usePermission(OnlineCasinoPermissions.getBasicAccountData);
  const canReadLoyaltyEventStream = canReadEventstreamById && canReadBasicAccountData;
  const { data: accountData } = useGetBasicAccountData(userId.toString(), {
    query: { enabled: canReadBasicAccountData },
  });

  const tabs: Tab[] = [
    {
      title: 'loyaltyTransactions.tabTitle.statusPoints',
      content: <LoyaltyStatusPointsTransactionsTable userId={userId} compact />,
    },
    {
      title: 'loyaltyTransactions.tabTitle.spendablePoints',
      content: <LoyaltySpendablePointsTransactionsTable userId={userId} compact />,
    },
  ];

  return (
    <Card>
      <Box display="flex" justifyContent="space-between">
        <CardHeader title={t('loyaltyTransactions.title')} />
        <Box display="flex" gap={2}>
          {canReadLoyaltyEventStream && (
            <Button
              size="small"
              variant="outlined"
              onClick={() =>
                userId &&
                navigate(
                  `../../lookup/eventstreams?${EventStreamParamsType.STREAM_ID}=loyaltyv2/${accountData?.account?.accountId}`
                )
              }
            >
              {t('loyaltyevents')}
            </Button>
          )}
          {canReadLoyaltyTransactions && (
            <Button size="small" variant="outlined" component={Link} to="../loyaltyTransactions">
              {t('seeMore')}
            </Button>
          )}
        </Box>
      </Box>
      <CardContent>
        <TabMenu tabs={tabs} />
      </CardContent>
    </Card>
  );
};

export default memo(LoyaltyTransactionsCard);
