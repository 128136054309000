/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type ELanguagesSchema = (typeof ELanguagesSchema)[keyof typeof ELanguagesSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ELanguagesSchema = {
  BG: 'BG',
  DA: 'DA',
  DE: 'DE',
  EL: 'EL',
  EN: 'EN',
  ES: 'ES',
  FI: 'FI',
  FR: 'FR',
  ID: 'ID',
  IT: 'IT',
  NL: 'NL',
  NO: 'NO',
  PL: 'PL',
  PT: 'PT',
  RO: 'RO',
  RU: 'RU',
  SQ: 'SQ',
  SV: 'SV',
  TH: 'TH',
  TR: 'TR',
  UK: 'UK',
  VI: 'VI',
  ZH: 'ZH',
} as const;
