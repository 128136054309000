import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, capitalize, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FontAwesomeIcon, SelectInput } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

import { BoosterPackDialogSchema } from './helpers';

interface Props {
  ordinalIndex: number;
  cardOptions: { label: string; value: number }[];
  selectedCard?: BoosterPackDialogSchema;
}

const BoosterCardPackPoolExtraSettings = ({ ordinalIndex, cardOptions, selectedCard }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { control, clearErrors } = useFormContext<BoosterPackDialogSchema>();

  const { fields, remove, append } = useFieldArray({
    control,
    name: `ordinals[${ordinalIndex}].cards`,
  });

  useEffect(() => {
    if (fields?.length) {
      clearErrors('ordinals');
    }
  }, [clearErrors, fields?.length]);

  useEffect(() => {
    if (!selectedCard || !fields?.length) {
      append({
        weight: undefined,
        card: undefined,
      });
    }
  }, [selectedCard]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {fields?.map((card, cardIndex) => {
        return (
          <Box
            key={card.id}
            sx={{
              [theme.breakpoints.up('xs')]: {
                width: '100%',
              },
              [theme.breakpoints.up('md')]: {
                width: '70%',
              },
            }}
          >
            <Box
              px={2}
              sx={{
                border: '1px solid #eee',
                borderRadius: '4px',
              }}
            >
              <Box display="flex" alignItems="center">
                <Typography fontWeight={600} variant="body2" fontSize="large" my={2}>
                  {`${t('marketing.boosterPacks.titles.card')} ${cardIndex + 1}`}
                </Typography>
                {cardIndex !== 0 && (
                  <FontAwesomeIcon
                    icon={faTrash}
                    sx={{ marginLeft: 1, color: 'error.main', cursor: 'pointer' }}
                    onClick={() => remove(cardIndex)}
                  />
                )}
              </Box>
              <Box
                display="flex"
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    flexDirection: 'column',
                    gap: 2,
                  },
                  [theme.breakpoints.up('md')]: {
                    flexDirection: 'row',
                  },
                }}
              >
                <SelectInput
                  name={`ordinals[${ordinalIndex}].cards[${cardIndex}].card`}
                  label={t('marketing.boosterPacks.titles.card')}
                  options={cardOptions}
                  rules={{ required: t('fieldIsRequired') }}
                  defaultValue={card?.card}
                  sx={{
                    [theme.breakpoints.up('xs')]: {
                      mb: 2,
                    },
                  }}
                />
                <Input
                  size="medium"
                  label={capitalize(t('marketing.boosterPacks.titles.weight'))}
                  control={control}
                  type="number"
                  name={`ordinals[${ordinalIndex}].cards[${cardIndex}].weight`}
                  sx={{ mb: 2 }}
                  required
                  rules={{
                    min: 1,
                    required: t('fieldIsRequired'),
                    valueAsNumber: true,
                  }}
                  InputProps={{
                    inputProps: { min: 1, step: 'any' },
                  }}
                  defaultValue={card?.weight}
                />
              </Box>
            </Box>
            {fields && fields?.length > 1 && cardIndex !== fields?.length - 1 && (
              <Typography variant="body2" fontSize="large" fontWeight="bold" my={2} textAlign="center">
                {t('content.gameserver.tournamentconfiguration.form.other.and')}
              </Typography>
            )}
          </Box>
        );
      })}
      <Button
        onClick={() => {
          append({
            weight: undefined,
            card: undefined,
          });
        }}
        variant="contained"
        size="medium"
        sx={{
          mx: 'auto',
          my: 2,
        }}
        startIcon={<FontAwesomeIcon icon={faAdd} />}
      >
        {t('marketing.boosterPacks.titles.card')}
      </Button>
    </Box>
  );
};

export default BoosterCardPackPoolExtraSettings;
