import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faEnvelope, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetUserGeneral, useGetUserPreferences, useSendUserEmailVerification } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardContent, CardHeader, Skeleton, Tooltip, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import {
  DataGridContainer,
  DataGridPlain,
  DataGridPlainHeader,
  ErrorCard,
  NoData,
} from '@greenisland-common/components/atoms';

import { formatPhoneNumber } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';
import EditEmailDialog from './EditEmailDialog';
import EditPhoneNumberDialog from './EditPhoneNumberDialog';
import UserPreferences from './UserPreferences';

interface Props {
  userId: string;
}

const headers: GridColumns = [
  {
    headerName: '',
    field: 'title',
    renderCell: ({ value }) => <DataGridPlainHeader value={value} />,
    width: 140,
  },
  {
    headerName: '',
    field: 'value',
    renderCell: ({ value }) => (
      <Box component="span" sx={{ whiteSpace: 'normal' }}>
        {value}
      </Box>
    ),
    flex: 1,
  },
];

const Contact = ({ userId }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const canReadUserGeneral = usePermission(OnlineCasinoPermissions.getUserGeneral);
  const canEditUserEmail = usePermission(OnlineCasinoPermissions.setUserEmail);
  const canEditUserPhoneNumber = usePermission(OnlineCasinoPermissions.updatePhoneNumber);
  const canReadUserPreferences = usePermission(OnlineCasinoPermissions.getUserPreferences);
  const [isEditEmailDialogVisible, setEditEmailDialogVisible] = useState(false);
  const [isEditPhoneNumberDialogVisible, setEditPhoneNumberDialogVisible] = useState(false);

  const {
    data: user,
    isLoading: userIsLoading,
    isError: userIsError,
    error: userError,
  } = useGetUserGeneral(userId, { query: { enabled: canReadUserGeneral } });
  const {
    data: userPreferences,
    isLoading: userPreferencesIsLoading,
    isError: userPreferencesIsError,
    error: userPreferencesError,
  } = useGetUserPreferences(userId, { query: { enabled: canReadUserPreferences } });

  const sendUserEmailVerification = useSendUserEmailVerification({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const isLoading = userIsLoading || userPreferencesIsLoading;
  const isError = userIsError || userPreferencesIsError;
  const error = userError || userPreferencesError;

  if (!canReadUserGeneral) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={230} />;
  if (isError || !user || !userPreferences) return <ErrorCard sx={{ height: 230 }}>{error?.message}</ErrorCard>;

  const handleEmailUpdate = () => {
    sendUserEmailVerification.mutate({ userId });
  };

  const rows: GridRowsProp = [
    {
      id: 1,
      title: t('email'),
      value: (
        <>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
            flexDirection={['column', 'row']}
          >
            <Box component="span" sx={user.emailVerified ? {} : { color: 'error.main' }}>
              {user.email}
            </Box>
            <Box display="flex">
              {canEditUserEmail && (
                <Tooltip arrow title={t('edit') || ''}>
                  <div>
                    <Button
                      sx={{ px: 1, ml: 'auto', minWidth: 0 }}
                      variant="text"
                      color="secondary"
                      size="small"
                      onClick={() => setEditEmailDialogVisible(true)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                  </div>
                </Tooltip>
              )}
              <Tooltip arrow title={t('askVerification') || ''}>
                <div>
                  <Button
                    sx={{ px: 1, ml: 0.5, minWidth: 0 }}
                    onClick={handleEmailUpdate}
                    variant="text"
                    size="small"
                    color="secondary"
                  >
                    <FontAwesomeIcon icon={faEnvelope} />
                  </Button>
                </div>
              </Tooltip>
            </Box>
          </Box>
        </>
      ),
    },
    {
      id: 2,
      title: t('phonenumber'),
      value: (
        <>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
            flexDirection={['column', 'row']}
          >
            <Box component="span" sx={user.phoneNumberVerified ? {} : { color: 'error.main' }}>
              {formatPhoneNumber(user.phonenumber)}
            </Box>
            <Box display="flex">
              {canEditUserPhoneNumber && (
                <Tooltip arrow title={t('edit') || ''}>
                  <div>
                    <Button
                      sx={{ px: 1, ml: 'auto', minWidth: 0 }}
                      variant="text"
                      color="secondary"
                      size="small"
                      onClick={() => setEditPhoneNumberDialogVisible(true)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                  </div>
                </Tooltip>
              )}
            </Box>
          </Box>
        </>
      ),
    },
    { id: 3, title: t('address'), value: user.address },
  ];

  return (
    <>
      <Card sx={{ px: 0 }}>
        <CardHeader title={t('contact')} />
        <CardContent sx={{ px: 0 }}>
          {rows.length > 0 ? (
            <DataGridContainer>
              <DataGridPlain
                density={isMobile ? 'standard' : 'compact'}
                sx={{ '& .MuiDataGrid-row': { minHeight: '100px', maxHeight: '100px' } }}
                columns={headers}
                rows={rows}
              />
            </DataGridContainer>
          ) : (
            <NoData />
          )}
        </CardContent>
        <Box sx={{ pl: 2, pr: 2, pb: 1 }}>
          <UserPreferences userPreferences={userPreferences} />
        </Box>
      </Card>
      {isEditEmailDialogVisible ? <EditEmailDialog onClose={() => setEditEmailDialogVisible(false)} /> : null}
      {isEditPhoneNumberDialogVisible ? (
        <EditPhoneNumberDialog onClose={() => setEditPhoneNumberDialogVisible(false)} />
      ) : null}
    </>
  );
};

export default Contact;
