import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, capitalize } from '@mui/material';

interface Props extends BoxProps {
  value: string;
}

const DataGridPlainHeader = ({ value, ...rest }: Props) => {
  const { t } = useTranslation();
  return (
    <Box color="array.headers" component="span" {...rest}>
      {capitalize(t(value))}
    </Box>
  );
};

export default DataGridPlainHeader;
