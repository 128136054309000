import { CustomElement, CustomText, FormattedText } from './types';

const CUSTOM_ELEMENT_TYPES = ['paragraph', 'heading', 'block-quote', 'numbered-list', 'bulleted-list', 'list-item'];
const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES: Array<'left' | 'center' | 'right' | 'justify'> = ['left', 'center', 'right', 'justify'];
const TEXT_FORMAT_TYPES = ['bold', 'italic', 'underline', 'code'];

export function isCustomElementType(
  format: any
): format is 'paragraph' | 'heading' | 'block-quote' | 'numbered-list' | 'bulleted-list' | 'list-item' {
  return CUSTOM_ELEMENT_TYPES.includes(format);
}

export function isListType(format: any): format is 'numbered-list' | 'bulleted-list' {
  return LIST_TYPES.includes(format);
}

export function isTextAlignType(format: any): format is 'left' | 'center' | 'right' | 'justify' {
  return TEXT_ALIGN_TYPES.includes(format);
}

export function isTextFormatType(format: any): format is 'bold' | 'italic' | 'underline' | 'code' {
  return TEXT_FORMAT_TYPES.includes(format);
}

export function isCustomText(value: any): value is CustomText {
  return value.text !== undefined;
}

export const isFormattedText = (node: CustomElement | CustomText): node is FormattedText => {
  return (node as FormattedText).text !== undefined;
};

export type CustomElementKeys = 'type' | 'align' | 'children' | 'level';
