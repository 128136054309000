/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  BGCArticle6ClientResponseSchema,
  BGCArticle6ResponseSchema,
  BGCScheduledCanExceedRequestSchema,
  CDBCorrectionRequestSchema,
  CDBRecordSchema,
  CriticalFieldsMismatchSchema,
  DefaultResponse,
  DepseudonymizedContentSchema,
  EBGCArticle6ActionTypeSchema,
  GetCruksDisturbanceAffectedUsersParams,
  GetCruksDisturbanceOverviewParams,
  GetExceedDefaultWeeklyDepositLimitRequestsParams,
  GetReportToCruksUsersParams,
  GetScheduledCanExceedRequestsParams,
  GetUserRegistration200,
  GetUserRegistrationMessagesParams,
  KSACancellationRequestSchema,
  NotFoundResponse,
  PagedBGCExceedDefaultWeeklyDepositLimitRequestsSchema,
  PagedBGCScheduledCanExceedRequestsSchema,
  PagedCruksDisturbanceAffectedUsersSchema,
  PagedCruksDisturbanceOverviewSchema,
  PagedReportToCruksUsersSchema,
  RegulatorControllerBadRequestResponse,
  ResolveCriticalFieldsMismatchRequestBodySchema,
  ResolveReportToCruksUserBodySchema,
  SendPausedDeliveryRequestSchema,
  UnauthorizedResponse,
  UserRegistrationMessagesSchema,
  UserRegistrationSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get the detailed user registration.
 */
export const useGetUserRegistrationHook = () => {
  const getUserRegistration = useCustomInstance<GetUserRegistration200>();

  return (userId: string, signal?: AbortSignal) => {
    return getUserRegistration({ url: `/regulator/${userId}/registration`, method: 'get', signal });
  };
};

export const getGetUserRegistrationQueryKey = (userId: string) => [`/regulator/${userId}/registration`] as const;

export const useGetUserRegistrationQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserRegistrationHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRegistrationHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRegistrationHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserRegistrationQueryKey(userId);

  const getUserRegistration = useGetUserRegistrationHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserRegistrationHook>>>> = ({ signal }) =>
    getUserRegistration(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserRegistrationQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserRegistrationHook>>>
>;
export type GetUserRegistrationQueryError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the detailed user registration.
 */
export const useGetUserRegistration = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserRegistrationHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRegistrationHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserRegistrationQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Resolve the critical fields mismatch for the user with given user id.
 */
export const useResolveCriticalFieldsMismatchHook = () => {
  const resolveCriticalFieldsMismatch = useCustomInstance<void>();

  return (
    userId: number,
    resolveCriticalFieldsMismatchRequestBodySchema: ResolveCriticalFieldsMismatchRequestBodySchema
  ) => {
    return resolveCriticalFieldsMismatch({
      url: `/regulator/${userId}/critical-fields/mismatch/resolve`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: resolveCriticalFieldsMismatchRequestBodySchema,
    });
  };
};

export const useResolveCriticalFieldsMismatchMutationOptions = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResolveCriticalFieldsMismatchHook>>>,
    TError,
    { userId: number; data: ResolveCriticalFieldsMismatchRequestBodySchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useResolveCriticalFieldsMismatchHook>>>,
  TError,
  { userId: number; data: ResolveCriticalFieldsMismatchRequestBodySchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const resolveCriticalFieldsMismatch = useResolveCriticalFieldsMismatchHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useResolveCriticalFieldsMismatchHook>>>,
    { userId: number; data: ResolveCriticalFieldsMismatchRequestBodySchema }
  > = props => {
    const { userId, data } = props ?? {};

    return resolveCriticalFieldsMismatch(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResolveCriticalFieldsMismatchMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useResolveCriticalFieldsMismatchHook>>>
>;
export type ResolveCriticalFieldsMismatchMutationBody = ResolveCriticalFieldsMismatchRequestBodySchema;
export type ResolveCriticalFieldsMismatchMutationError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Resolve the critical fields mismatch for the user with given user id.
 */
export const useResolveCriticalFieldsMismatch = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResolveCriticalFieldsMismatchHook>>>,
    TError,
    { userId: number; data: ResolveCriticalFieldsMismatchRequestBodySchema },
    TContext
  >;
}) => {
  const mutationOptions = useResolveCriticalFieldsMismatchMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the the critical fields mismatch for the user.
 */
export const useGetCriticalFieldsMismatchHook = () => {
  const getCriticalFieldsMismatch = useCustomInstance<CriticalFieldsMismatchSchema>();

  return (userId: number, signal?: AbortSignal) => {
    return getCriticalFieldsMismatch({ url: `/regulator/${userId}/critical-fields/mismatch`, method: 'get', signal });
  };
};

export const getGetCriticalFieldsMismatchQueryKey = (userId: number) =>
  [`/regulator/${userId}/critical-fields/mismatch`] as const;

export const useGetCriticalFieldsMismatchQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCriticalFieldsMismatchHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCriticalFieldsMismatchHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCriticalFieldsMismatchHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCriticalFieldsMismatchQueryKey(userId);

  const getCriticalFieldsMismatch = useGetCriticalFieldsMismatchHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCriticalFieldsMismatchHook>>>> = ({
    signal,
  }) => getCriticalFieldsMismatch(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetCriticalFieldsMismatchQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCriticalFieldsMismatchHook>>>
>;
export type GetCriticalFieldsMismatchQueryError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the the critical fields mismatch for the user.
 */
export const useGetCriticalFieldsMismatch = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCriticalFieldsMismatchHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCriticalFieldsMismatchHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCriticalFieldsMismatchQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the user's registration messages.
 */
export const useGetUserRegistrationMessagesHook = () => {
  const getUserRegistrationMessages = useCustomInstance<UserRegistrationMessagesSchema>();

  return (userId: string, params?: GetUserRegistrationMessagesParams, signal?: AbortSignal) => {
    return getUserRegistrationMessages({
      url: `/regulator/${userId}/registrationmessages`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getGetUserRegistrationMessagesQueryKey = (userId: string, params?: GetUserRegistrationMessagesParams) =>
  [`/regulator/${userId}/registrationmessages`, ...(params ? [params] : [])] as const;

export const useGetUserRegistrationMessagesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserRegistrationMessagesHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserRegistrationMessagesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRegistrationMessagesHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRegistrationMessagesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserRegistrationMessagesQueryKey(userId, params);

  const getUserRegistrationMessages = useGetUserRegistrationMessagesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetUserRegistrationMessagesHook>>>> = ({
    signal,
  }) => getUserRegistrationMessages(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetUserRegistrationMessagesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetUserRegistrationMessagesHook>>>
>;
export type GetUserRegistrationMessagesQueryError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the user's registration messages.
 */
export const useGetUserRegistrationMessages = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetUserRegistrationMessagesHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  params?: GetUserRegistrationMessagesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetUserRegistrationMessagesHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetUserRegistrationMessagesQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Add action key to user registration
 */
export const useAddUserRegistrationActionKeyHook = () => {
  const addUserRegistrationActionKey = useCustomInstance<UserRegistrationSchema>();

  return (userId: string, actionKey: 'register' | 'remove' | 'update' | 'validate' | 'invalidate') => {
    return addUserRegistrationActionKey({ url: `/regulator/${userId}/registration/${actionKey}`, method: 'post' });
  };
};

export const useAddUserRegistrationActionKeyMutationOptions = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddUserRegistrationActionKeyHook>>>,
    TError,
    { userId: string; actionKey: 'register' | 'remove' | 'update' | 'validate' | 'invalidate' },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddUserRegistrationActionKeyHook>>>,
  TError,
  { userId: string; actionKey: 'register' | 'remove' | 'update' | 'validate' | 'invalidate' },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addUserRegistrationActionKey = useAddUserRegistrationActionKeyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddUserRegistrationActionKeyHook>>>,
    { userId: string; actionKey: 'register' | 'remove' | 'update' | 'validate' | 'invalidate' }
  > = props => {
    const { userId, actionKey } = props ?? {};

    return addUserRegistrationActionKey(userId, actionKey);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddUserRegistrationActionKeyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddUserRegistrationActionKeyHook>>>
>;

export type AddUserRegistrationActionKeyMutationError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Add action key to user registration
 */
export const useAddUserRegistrationActionKey = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddUserRegistrationActionKeyHook>>>,
    TError,
    { userId: string; actionKey: 'register' | 'remove' | 'update' | 'validate' | 'invalidate' },
    TContext
  >;
}) => {
  const mutationOptions = useAddUserRegistrationActionKeyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Schedule a can exceed request for the user with given user id.
 */
export const useScheduleCanExceedRequestHook = () => {
  const scheduleCanExceedRequest = useCustomInstance<BGCScheduledCanExceedRequestSchema>();

  return (userId: number) => {
    return scheduleCanExceedRequest({ url: `/regulator/${userId}/article6/schedulecanexceed`, method: 'post' });
  };
};

export const useScheduleCanExceedRequestMutationOptions = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useScheduleCanExceedRequestHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useScheduleCanExceedRequestHook>>>,
  TError,
  { userId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const scheduleCanExceedRequest = useScheduleCanExceedRequestHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useScheduleCanExceedRequestHook>>>,
    { userId: number }
  > = props => {
    const { userId } = props ?? {};

    return scheduleCanExceedRequest(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ScheduleCanExceedRequestMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useScheduleCanExceedRequestHook>>>
>;

export type ScheduleCanExceedRequestMutationError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Schedule a can exceed request for the user with given user id.
 */
export const useScheduleCanExceedRequest = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useScheduleCanExceedRequestHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}) => {
  const mutationOptions = useScheduleCanExceedRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Request a request can exceed limit for the user with given user id.
 */
export const useRequestCanExceedForUserHook = () => {
  const requestCanExceedForUser = useCustomInstance<BGCArticle6ResponseSchema>();

  return (userId: number) => {
    return requestCanExceedForUser({ url: `/regulator/${userId}/article6/requestcanexceed`, method: 'post' });
  };
};

export const useRequestCanExceedForUserMutationOptions = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRequestCanExceedForUserHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRequestCanExceedForUserHook>>>,
  TError,
  { userId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const requestCanExceedForUser = useRequestCanExceedForUserHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRequestCanExceedForUserHook>>>,
    { userId: number }
  > = props => {
    const { userId } = props ?? {};

    return requestCanExceedForUser(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type RequestCanExceedForUserMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRequestCanExceedForUserHook>>>
>;

export type RequestCanExceedForUserMutationError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Request a request can exceed limit for the user with given user id.
 */
export const useRequestCanExceedForUser = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRequestCanExceedForUserHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}) => {
  const mutationOptions = useRequestCanExceedForUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete the request can exceed for the given user. NOTE - THIS CAN NOT BE UNDONE.
 */
export const useDeleteRequestCanExceedForUserHook = () => {
  const deleteRequestCanExceedForUser = useCustomInstance<BGCArticle6ResponseSchema>();

  return (userId: number) => {
    return deleteRequestCanExceedForUser({ url: `/regulator/${userId}/article6/requestcanexceed`, method: 'delete' });
  };
};

export const useDeleteRequestCanExceedForUserMutationOptions = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteRequestCanExceedForUserHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteRequestCanExceedForUserHook>>>,
  TError,
  { userId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteRequestCanExceedForUser = useDeleteRequestCanExceedForUserHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteRequestCanExceedForUserHook>>>,
    { userId: number }
  > = props => {
    const { userId } = props ?? {};

    return deleteRequestCanExceedForUser(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteRequestCanExceedForUserMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteRequestCanExceedForUserHook>>>
>;

export type DeleteRequestCanExceedForUserMutationError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Delete the request can exceed for the given user. NOTE - THIS CAN NOT BE UNDONE.
 */
export const useDeleteRequestCanExceedForUser = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteRequestCanExceedForUserHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteRequestCanExceedForUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete the request can exceed for the given token. NOTE - THIS CAN NOT BE UNDONE.
 */
export const useDeleteRequestCanExceedForTokenHook = () => {
  const deleteRequestCanExceedForToken = useCustomInstance<void>();

  return (userId: number, token: string) => {
    return deleteRequestCanExceedForToken({
      url: `/regulator/${userId}/request-can-exceed/${token}`,
      method: 'delete',
    });
  };
};

export const useDeleteRequestCanExceedForTokenMutationOptions = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteRequestCanExceedForTokenHook>>>,
    TError,
    { userId: number; token: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteRequestCanExceedForTokenHook>>>,
  TError,
  { userId: number; token: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteRequestCanExceedForToken = useDeleteRequestCanExceedForTokenHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteRequestCanExceedForTokenHook>>>,
    { userId: number; token: string }
  > = props => {
    const { userId, token } = props ?? {};

    return deleteRequestCanExceedForToken(userId, token);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteRequestCanExceedForTokenMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteRequestCanExceedForTokenHook>>>
>;

export type DeleteRequestCanExceedForTokenMutationError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Delete the request can exceed for the given token. NOTE - THIS CAN NOT BE UNDONE.
 */
export const useDeleteRequestCanExceedForToken = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteRequestCanExceedForTokenHook>>>,
    TError,
    { userId: number; token: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteRequestCanExceedForTokenMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the exceed default weekly deposit limit requests.
 */
export const useGetExceedDefaultWeeklyDepositLimitRequestsHook = () => {
  const getExceedDefaultWeeklyDepositLimitRequests =
    useCustomInstance<PagedBGCExceedDefaultWeeklyDepositLimitRequestsSchema>();

  return (userId: number, params?: GetExceedDefaultWeeklyDepositLimitRequestsParams, signal?: AbortSignal) => {
    return getExceedDefaultWeeklyDepositLimitRequests({
      url: `/regulator/${userId}/article6/exceedRequests`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getGetExceedDefaultWeeklyDepositLimitRequestsQueryKey = (
  userId: number,
  params?: GetExceedDefaultWeeklyDepositLimitRequestsParams
) => [`/regulator/${userId}/article6/exceedRequests`, ...(params ? [params] : [])] as const;

export const useGetExceedDefaultWeeklyDepositLimitRequestsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetExceedDefaultWeeklyDepositLimitRequestsHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params?: GetExceedDefaultWeeklyDepositLimitRequestsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetExceedDefaultWeeklyDepositLimitRequestsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetExceedDefaultWeeklyDepositLimitRequestsHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetExceedDefaultWeeklyDepositLimitRequestsQueryKey(userId, params);

  const getExceedDefaultWeeklyDepositLimitRequests = useGetExceedDefaultWeeklyDepositLimitRequestsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetExceedDefaultWeeklyDepositLimitRequestsHook>>>
  > = ({ signal }) => getExceedDefaultWeeklyDepositLimitRequests(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetExceedDefaultWeeklyDepositLimitRequestsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetExceedDefaultWeeklyDepositLimitRequestsHook>>>
>;
export type GetExceedDefaultWeeklyDepositLimitRequestsQueryError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the exceed default weekly deposit limit requests.
 */
export const useGetExceedDefaultWeeklyDepositLimitRequests = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetExceedDefaultWeeklyDepositLimitRequestsHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params?: GetExceedDefaultWeeklyDepositLimitRequestsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetExceedDefaultWeeklyDepositLimitRequestsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetExceedDefaultWeeklyDepositLimitRequestsQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Reset the exceed requests for the user. This entails calling DELETE to the BGC and also expiring. NOTE - THE EXPIRE WILL BE CALLED EVEN IF DELETE IS NOT SUCCESSFUL. USING THIS ENDPOINT CAN LEAD TO INCONSISTENCIES.
 */
export const useResetExceedDefaultWeeklyDepositLimitRequestsHook = () => {
  const resetExceedDefaultWeeklyDepositLimitRequests = useCustomInstance<void>();

  return (userId: number) => {
    return resetExceedDefaultWeeklyDepositLimitRequests({
      url: `/regulator/${userId}/article6/exceedRequests/reset`,
      method: 'post',
    });
  };
};

export const useResetExceedDefaultWeeklyDepositLimitRequestsMutationOptions = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetExceedDefaultWeeklyDepositLimitRequestsHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useResetExceedDefaultWeeklyDepositLimitRequestsHook>>>,
  TError,
  { userId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const resetExceedDefaultWeeklyDepositLimitRequests = useResetExceedDefaultWeeklyDepositLimitRequestsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useResetExceedDefaultWeeklyDepositLimitRequestsHook>>>,
    { userId: number }
  > = props => {
    const { userId } = props ?? {};

    return resetExceedDefaultWeeklyDepositLimitRequests(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetExceedDefaultWeeklyDepositLimitRequestsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useResetExceedDefaultWeeklyDepositLimitRequestsHook>>>
>;

export type ResetExceedDefaultWeeklyDepositLimitRequestsMutationError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Reset the exceed requests for the user. This entails calling DELETE to the BGC and also expiring. NOTE - THE EXPIRE WILL BE CALLED EVEN IF DELETE IS NOT SUCCESSFUL. USING THIS ENDPOINT CAN LEAD TO INCONSISTENCIES.
 */
export const useResetExceedDefaultWeeklyDepositLimitRequests = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetExceedDefaultWeeklyDepositLimitRequestsHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}) => {
  const mutationOptions = useResetExceedDefaultWeeklyDepositLimitRequestsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the scheduled can exceed requests.
 */
export const useGetScheduledCanExceedRequestsHook = () => {
  const getScheduledCanExceedRequests = useCustomInstance<PagedBGCScheduledCanExceedRequestsSchema>();

  return (userId: number, params?: GetScheduledCanExceedRequestsParams, signal?: AbortSignal) => {
    return getScheduledCanExceedRequests({
      url: `/regulator/${userId}/article6/scheduledcanexceedrequests`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getGetScheduledCanExceedRequestsQueryKey = (
  userId: number,
  params?: GetScheduledCanExceedRequestsParams
) => [`/regulator/${userId}/article6/scheduledcanexceedrequests`, ...(params ? [params] : [])] as const;

export const useGetScheduledCanExceedRequestsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetScheduledCanExceedRequestsHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params?: GetScheduledCanExceedRequestsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetScheduledCanExceedRequestsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetScheduledCanExceedRequestsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetScheduledCanExceedRequestsQueryKey(userId, params);

  const getScheduledCanExceedRequests = useGetScheduledCanExceedRequestsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetScheduledCanExceedRequestsHook>>>> = ({
    signal,
  }) => getScheduledCanExceedRequests(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetScheduledCanExceedRequestsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetScheduledCanExceedRequestsHook>>>
>;
export type GetScheduledCanExceedRequestsQueryError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the scheduled can exceed requests.
 */
export const useGetScheduledCanExceedRequests = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetScheduledCanExceedRequestsHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params?: GetScheduledCanExceedRequestsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetScheduledCanExceedRequestsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetScheduledCanExceedRequestsQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the limit status for the given user id
 */
export const useGetLimitStatusForTokenForUserHook = () => {
  const getLimitStatusForTokenForUser = useCustomInstance<BGCArticle6ClientResponseSchema>();

  return (userId: number) => {
    return getLimitStatusForTokenForUser({ url: `/regulator/${userId}/article6/limitstatus`, method: 'post' });
  };
};

export const useGetLimitStatusForTokenForUserMutationOptions = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetLimitStatusForTokenForUserHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useGetLimitStatusForTokenForUserHook>>>,
  TError,
  { userId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const getLimitStatusForTokenForUser = useGetLimitStatusForTokenForUserHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useGetLimitStatusForTokenForUserHook>>>,
    { userId: number }
  > = props => {
    const { userId } = props ?? {};

    return getLimitStatusForTokenForUser(userId);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetLimitStatusForTokenForUserMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLimitStatusForTokenForUserHook>>>
>;

export type GetLimitStatusForTokenForUserMutationError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the limit status for the given user id
 */
export const useGetLimitStatusForTokenForUser = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetLimitStatusForTokenForUserHook>>>,
    TError,
    { userId: number },
    TContext
  >;
}) => {
  const mutationOptions = useGetLimitStatusForTokenForUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Resets the retry count for the given user id and actionType
 */
export const useResetRetryCountForUserHook = () => {
  const resetRetryCountForUser = useCustomInstance<void>();

  return (userId: number, actionType: EBGCArticle6ActionTypeSchema) => {
    return resetRetryCountForUser({
      url: `/regulator/${userId}/article6/${actionType}/resetretrycount`,
      method: 'post',
    });
  };
};

export const useResetRetryCountForUserMutationOptions = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetRetryCountForUserHook>>>,
    TError,
    { userId: number; actionType: EBGCArticle6ActionTypeSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useResetRetryCountForUserHook>>>,
  TError,
  { userId: number; actionType: EBGCArticle6ActionTypeSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const resetRetryCountForUser = useResetRetryCountForUserHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useResetRetryCountForUserHook>>>,
    { userId: number; actionType: EBGCArticle6ActionTypeSchema }
  > = props => {
    const { userId, actionType } = props ?? {};

    return resetRetryCountForUser(userId, actionType);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetRetryCountForUserMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useResetRetryCountForUserHook>>>
>;

export type ResetRetryCountForUserMutationError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Resets the retry count for the given user id and actionType
 */
export const useResetRetryCountForUser = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetRetryCountForUserHook>>>,
    TError,
    { userId: number; actionType: EBGCArticle6ActionTypeSchema },
    TContext
  >;
}) => {
  const mutationOptions = useResetRetryCountForUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the overview of cruks disturbances
 */
export const useGetCruksDisturbanceOverviewHook = () => {
  const getCruksDisturbanceOverview = useCustomInstance<PagedCruksDisturbanceOverviewSchema>();

  return (params?: GetCruksDisturbanceOverviewParams, signal?: AbortSignal) => {
    return getCruksDisturbanceOverview({ url: `/regulator/cruks/disturbance/overview`, method: 'get', params, signal });
  };
};

export const getGetCruksDisturbanceOverviewQueryKey = (params?: GetCruksDisturbanceOverviewParams) =>
  [`/regulator/cruks/disturbance/overview`, ...(params ? [params] : [])] as const;

export const useGetCruksDisturbanceOverviewQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCruksDisturbanceOverviewHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetCruksDisturbanceOverviewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCruksDisturbanceOverviewHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCruksDisturbanceOverviewHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCruksDisturbanceOverviewQueryKey(params);

  const getCruksDisturbanceOverview = useGetCruksDisturbanceOverviewHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCruksDisturbanceOverviewHook>>>> = ({
    signal,
  }) => getCruksDisturbanceOverview(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetCruksDisturbanceOverviewQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCruksDisturbanceOverviewHook>>>
>;
export type GetCruksDisturbanceOverviewQueryError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the overview of cruks disturbances
 */
export const useGetCruksDisturbanceOverview = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCruksDisturbanceOverviewHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetCruksDisturbanceOverviewParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCruksDisturbanceOverviewHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCruksDisturbanceOverviewQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the paged cruks disturbance affected users
 */
export const useGetCruksDisturbanceAffectedUsersHook = () => {
  const getCruksDisturbanceAffectedUsers = useCustomInstance<PagedCruksDisturbanceAffectedUsersSchema>();

  return (disturbanceId: number, params?: GetCruksDisturbanceAffectedUsersParams, signal?: AbortSignal) => {
    return getCruksDisturbanceAffectedUsers({
      url: `/regulator/cruks/disturbance/${disturbanceId}/users`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getGetCruksDisturbanceAffectedUsersQueryKey = (
  disturbanceId: number,
  params?: GetCruksDisturbanceAffectedUsersParams
) => [`/regulator/cruks/disturbance/${disturbanceId}/users`, ...(params ? [params] : [])] as const;

export const useGetCruksDisturbanceAffectedUsersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCruksDisturbanceAffectedUsersHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  disturbanceId: number,
  params?: GetCruksDisturbanceAffectedUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCruksDisturbanceAffectedUsersHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCruksDisturbanceAffectedUsersHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCruksDisturbanceAffectedUsersQueryKey(disturbanceId, params);

  const getCruksDisturbanceAffectedUsers = useGetCruksDisturbanceAffectedUsersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCruksDisturbanceAffectedUsersHook>>>> = ({
    signal,
  }) => getCruksDisturbanceAffectedUsers(disturbanceId, params, signal);

  return { queryKey, queryFn, enabled: !!disturbanceId, ...queryOptions };
};

export type GetCruksDisturbanceAffectedUsersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCruksDisturbanceAffectedUsersHook>>>
>;
export type GetCruksDisturbanceAffectedUsersQueryError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the paged cruks disturbance affected users
 */
export const useGetCruksDisturbanceAffectedUsers = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCruksDisturbanceAffectedUsersHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  disturbanceId: number,
  params?: GetCruksDisturbanceAffectedUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetCruksDisturbanceAffectedUsersHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCruksDisturbanceAffectedUsersQueryOptions(disturbanceId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Insert a command for a correction for a cdb record.
 */
export const useCreateCDBCorrectionCommandHook = () => {
  const createCDBCorrectionCommand = useCustomInstance<void>();

  return (cDBCorrectionRequestSchema: CDBCorrectionRequestSchema) => {
    return createCDBCorrectionCommand({
      url: `/regulator/cdb/correction`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: cDBCorrectionRequestSchema,
    });
  };
};

export const useCreateCDBCorrectionCommandMutationOptions = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateCDBCorrectionCommandHook>>>,
    TError,
    { data: CDBCorrectionRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateCDBCorrectionCommandHook>>>,
  TError,
  { data: CDBCorrectionRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createCDBCorrectionCommand = useCreateCDBCorrectionCommandHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateCDBCorrectionCommandHook>>>,
    { data: CDBCorrectionRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return createCDBCorrectionCommand(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCDBCorrectionCommandMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateCDBCorrectionCommandHook>>>
>;
export type CreateCDBCorrectionCommandMutationBody = CDBCorrectionRequestSchema;
export type CreateCDBCorrectionCommandMutationError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Insert a command for a correction for a cdb record.
 */
export const useCreateCDBCorrectionCommand = <
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateCDBCorrectionCommandHook>>>,
    TError,
    { data: CDBCorrectionRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useCreateCDBCorrectionCommandMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the cdb record for the given record id
 */
export const useGetCDBRecordHook = () => {
  const getCDBRecord = useCustomInstance<CDBRecordSchema>();

  return (recordId: string, signal?: AbortSignal) => {
    return getCDBRecord({ url: `/regulator/cdb/record/${recordId}`, method: 'get', signal });
  };
};

export const getGetCDBRecordQueryKey = (recordId: string) => [`/regulator/cdb/record/${recordId}`] as const;

export const useGetCDBRecordQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCDBRecordHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  recordId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCDBRecordHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCDBRecordHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCDBRecordQueryKey(recordId);

  const getCDBRecord = useGetCDBRecordHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCDBRecordHook>>>> = ({ signal }) =>
    getCDBRecord(recordId, signal);

  return { queryKey, queryFn, enabled: !!recordId, ...queryOptions };
};

export type GetCDBRecordQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCDBRecordHook>>>>;
export type GetCDBRecordQueryError =
  | RegulatorControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the cdb record for the given record id
 */
export const useGetCDBRecord = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCDBRecordHook>>>,
  TError = RegulatorControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  recordId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCDBRecordHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCDBRecordQueryOptions(recordId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Cancel a previously exported WOK record using a KSA_Cancellation
 */
export const useCancelWOKRecordHook = () => {
  const cancelWOKRecord = useCustomInstance<void>();

  return (kSACancellationRequestSchema: KSACancellationRequestSchema) => {
    return cancelWOKRecord({
      url: `/regulator/cdb/wok/cancel-record`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: kSACancellationRequestSchema,
    });
  };
};

export const useCancelWOKRecordMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelWOKRecordHook>>>,
    TError,
    { data: KSACancellationRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCancelWOKRecordHook>>>,
  TError,
  { data: KSACancellationRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const cancelWOKRecord = useCancelWOKRecordHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCancelWOKRecordHook>>>,
    { data: KSACancellationRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return cancelWOKRecord(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelWOKRecordMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelWOKRecordHook>>>>;
export type CancelWOKRecordMutationBody = KSACancellationRequestSchema;
export type CancelWOKRecordMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Cancel a previously exported WOK record using a KSA_Cancellation
 */
export const useCancelWOKRecord = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelWOKRecordHook>>>,
    TError,
    { data: KSACancellationRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useCancelWOKRecordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Send wok paused delivery queue entries to ksa with specified date time. Note that this endpoint only inserts the command.
 */
export const useSendWOKPausedDeliveryQueueEntriesHook = () => {
  const sendWOKPausedDeliveryQueueEntries = useCustomInstance<void>();

  return (sendPausedDeliveryRequestSchema: SendPausedDeliveryRequestSchema) => {
    return sendWOKPausedDeliveryQueueEntries({
      url: `/regulator/cdb/wok/send-paused-delivery`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: sendPausedDeliveryRequestSchema,
    });
  };
};

export const useSendWOKPausedDeliveryQueueEntriesMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSendWOKPausedDeliveryQueueEntriesHook>>>,
    TError,
    { data: SendPausedDeliveryRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSendWOKPausedDeliveryQueueEntriesHook>>>,
  TError,
  { data: SendPausedDeliveryRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const sendWOKPausedDeliveryQueueEntries = useSendWOKPausedDeliveryQueueEntriesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSendWOKPausedDeliveryQueueEntriesHook>>>,
    { data: SendPausedDeliveryRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return sendWOKPausedDeliveryQueueEntries(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendWOKPausedDeliveryQueueEntriesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSendWOKPausedDeliveryQueueEntriesHook>>>
>;
export type SendWOKPausedDeliveryQueueEntriesMutationBody = SendPausedDeliveryRequestSchema;
export type SendWOKPausedDeliveryQueueEntriesMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Send wok paused delivery queue entries to ksa with specified date time. Note that this endpoint only inserts the command.
 */
export const useSendWOKPausedDeliveryQueueEntries = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSendWOKPausedDeliveryQueueEntriesHook>>>,
    TError,
    { data: SendPausedDeliveryRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useSendWOKPausedDeliveryQueueEntriesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Send tax paused delivery queue entries to ksa with specified date time. Note that this endpoint only inserts the command.
 */
export const useSendTAXPausedDeliveryQueueEntriesHook = () => {
  const sendTAXPausedDeliveryQueueEntries = useCustomInstance<void>();

  return (sendPausedDeliveryRequestSchema: SendPausedDeliveryRequestSchema) => {
    return sendTAXPausedDeliveryQueueEntries({
      url: `/regulator/cdb/tax/send-paused-delivery`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: sendPausedDeliveryRequestSchema,
    });
  };
};

export const useSendTAXPausedDeliveryQueueEntriesMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSendTAXPausedDeliveryQueueEntriesHook>>>,
    TError,
    { data: SendPausedDeliveryRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSendTAXPausedDeliveryQueueEntriesHook>>>,
  TError,
  { data: SendPausedDeliveryRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const sendTAXPausedDeliveryQueueEntries = useSendTAXPausedDeliveryQueueEntriesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSendTAXPausedDeliveryQueueEntriesHook>>>,
    { data: SendPausedDeliveryRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return sendTAXPausedDeliveryQueueEntries(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendTAXPausedDeliveryQueueEntriesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSendTAXPausedDeliveryQueueEntriesHook>>>
>;
export type SendTAXPausedDeliveryQueueEntriesMutationBody = SendPausedDeliveryRequestSchema;
export type SendTAXPausedDeliveryQueueEntriesMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Send tax paused delivery queue entries to ksa with specified date time. Note that this endpoint only inserts the command.
 */
export const useSendTAXPausedDeliveryQueueEntries = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSendTAXPausedDeliveryQueueEntriesHook>>>,
    TError,
    { data: SendPausedDeliveryRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useSendTAXPausedDeliveryQueueEntriesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get (continued high risk) users that should (almost) be reported to CRUKS
 */
export const useGetReportToCruksUsersHook = () => {
  const getReportToCruksUsers = useCustomInstance<PagedReportToCruksUsersSchema>();

  return (params?: GetReportToCruksUsersParams, signal?: AbortSignal) => {
    return getReportToCruksUsers({ url: `/regulator/cruks/report-users`, method: 'get', params, signal });
  };
};

export const getGetReportToCruksUsersQueryKey = (params?: GetReportToCruksUsersParams) =>
  [`/regulator/cruks/report-users`, ...(params ? [params] : [])] as const;

export const useGetReportToCruksUsersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetReportToCruksUsersHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetReportToCruksUsersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReportToCruksUsersHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReportToCruksUsersHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportToCruksUsersQueryKey(params);

  const getReportToCruksUsers = useGetReportToCruksUsersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetReportToCruksUsersHook>>>> = ({ signal }) =>
    getReportToCruksUsers(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetReportToCruksUsersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetReportToCruksUsersHook>>>
>;
export type GetReportToCruksUsersQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get (continued high risk) users that should (almost) be reported to CRUKS
 */
export const useGetReportToCruksUsers = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetReportToCruksUsersHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetReportToCruksUsersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetReportToCruksUsersHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetReportToCruksUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Resolve a Report to CRUKS user
 */
export const useResolveReportToCRUKSHook = () => {
  const resolveReportToCRUKS = useCustomInstance<DepseudonymizedContentSchema>();

  return (userId: number, resolveReportToCruksUserBodySchema: ResolveReportToCruksUserBodySchema) => {
    return resolveReportToCRUKS({
      url: `/regulator/cruks/report-users/${userId}/resolve`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: resolveReportToCruksUserBodySchema,
    });
  };
};

export const useResolveReportToCRUKSMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResolveReportToCRUKSHook>>>,
    TError,
    { userId: number; data: ResolveReportToCruksUserBodySchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useResolveReportToCRUKSHook>>>,
  TError,
  { userId: number; data: ResolveReportToCruksUserBodySchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const resolveReportToCRUKS = useResolveReportToCRUKSHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useResolveReportToCRUKSHook>>>,
    { userId: number; data: ResolveReportToCruksUserBodySchema }
  > = props => {
    const { userId, data } = props ?? {};

    return resolveReportToCRUKS(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResolveReportToCRUKSMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useResolveReportToCRUKSHook>>>
>;
export type ResolveReportToCRUKSMutationBody = ResolveReportToCruksUserBodySchema;
export type ResolveReportToCRUKSMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Resolve a Report to CRUKS user
 */
export const useResolveReportToCRUKS = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResolveReportToCRUKSHook>>>,
    TError,
    { userId: number; data: ResolveReportToCruksUserBodySchema },
    TContext
  >;
}) => {
  const mutationOptions = useResolveReportToCRUKSMutationOptions(options);

  return useMutation(mutationOptions);
};
