import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Permission } from '@greenisland-types/authorization';

export const MARKETING_PERMISSIONS: Permission[] = [
  OnlineCasinoPermissions.getCampaignsOverview,
  OnlineCasinoPermissions.getDefaultTemplates,
  OnlineCasinoPermissions.getCampaign,
  OnlineCasinoPermissions.getCampaignTemplate,
  OnlineCasinoPermissions.getDefaultCampaignTemplate,
  OnlineCasinoPermissions.getTemplateTypes,
  OnlineCasinoPermissions.updateCampaignTemplate,
  OnlineCasinoPermissions.addCampaignTemplate,
  OnlineCasinoPermissions.addIndividualAction,
  OnlineCasinoPermissions.getFreeSpinsCampaignsV2,
  OnlineCasinoPermissions.getGameProviders,
  OnlineCasinoPermissions.updateIndividualAction,
  OnlineCasinoPermissions.getIndividualAction,
  OnlineCasinoPermissions.addCampaign,
  OnlineCasinoPermissions.updateCampaign,
];
