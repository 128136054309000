import { useTranslation } from 'react-i18next';
import { BechargeProductSchema } from '@greenisland-api';
import {
  Box,
  capitalize,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { CheckboxIcon } from '@greenisland-common/components/atoms';

interface Props {
  usageInfo?: string;
  products: BechargeProductSchema[];
}

const VouchersProductsDetails = ({ usageInfo, products }: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ m: 2 }}>
      <Card elevation={1} sx={{ p: 2, mt: 2, mb: 4 }}>
        <CardContent>
          {usageInfo && (
            <Box mb={4}>
              <Typography variant="h5" fontWeight={500} mb={2} fontSize="x-large">
                {capitalize(t('content.becharge.vouchersProducts.table.productUsageInfo'))}
              </Typography>
              <Box
                dangerouslySetInnerHTML={{ __html: usageInfo }}
                sx={{ border: '1px solid rgba(224, 224, 224, 1)', borderRadius: 5, padding: 2 }}
              />
            </Box>
          )}
          <Box mt={4}>
            <Typography variant="h5" fontWeight={500} mb={2} fontSize="x-large">
              {capitalize(t('content.becharge.vouchersProducts.table.productDetailsTitle'))}
            </Typography>
            <TableContainer sx={{ mb: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>{capitalize(t('content.becharge.vouchersProducts.table.productId'))}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{capitalize(t('content.becharge.vouchersProducts.table.value'))}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{capitalize(t('content.becharge.vouchersProducts.table.valueMax'))}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{capitalize(t('content.becharge.vouchersProducts.table.step'))}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{capitalize(t('content.becharge.vouchersProducts.table.margin'))}</strong>
                    </TableCell>
                    <TableCell>
                      <strong>{capitalize(t('content.becharge.vouchersProducts.table.requiresUserInfo'))}</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products?.map(product => (
                    <TableRow key={product?.productId}>
                      <TableCell>{product?.productId}</TableCell>
                      <TableCell>{product?.value}</TableCell>
                      <TableCell>{product?.valueMax}</TableCell>
                      <TableCell>{product?.step}</TableCell>
                      <TableCell>{product?.margin}</TableCell>
                      <TableCell>
                        <CheckboxIcon checked={product?.requiresUserInfo} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default VouchersProductsDetails;
