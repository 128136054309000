import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { MaxDepositLimitSchema, useGetActiveMaximumLoginSessionLimits } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { capitalize, Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowParams, GridRowsProp } from '@mui/x-data-grid-pro';
import { getDurationFromSeconds } from 'src/app/helpers/transformFunctions';
import { usePermission } from 'src/app/hooks';

import {
  DataGridContainer,
  DateTime,
  FontAwesomeIcon,
  PermissionWrapper,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { MaxLimitType } from '../../Constants/Limits';
import MaxLimitCancelDialog from '../../MaxLimitCancelDialog';

type DepositLimitsProps = {
  userId: string;
};

const ActiveMaxLimitsTable = ({ userId }: DepositLimitsProps) => {
  const { t } = useTranslation();

  const canReadSessionLimits = usePermission(OnlineCasinoPermissions.getActiveMaximumLoginSessionLimits);
  const canCancelMaximumSessionLimit = usePermission(OnlineCasinoPermissions.cancelMaximumLoginSessionLimit);

  const [selectedMaxLimit, setSelectedMaxLimit] = useState<MaxDepositLimitSchema | null>(null);

  const {
    data: activeSessionLimits,
    isLoading,
    isError,
    error,
  } = useGetActiveMaximumLoginSessionLimits(parseInt(userId, 10), { query: { enabled: canReadSessionLimits } });

  const headers: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      minWidth: 50,
      flex: 0.1,
      headerName: '',
      getActions: (params: GridRowParams) => [
        <>
          {canCancelMaximumSessionLimit && (
            <Tooltip title={t('cancel')} key="delete">
              <GridActionsCellItem
                label={t('cancel')}
                icon={<FontAwesomeIcon icon={faTrash} sx={{ color: 'error.main' }} />}
                onClick={() => {
                  if (params?.row) {
                    setSelectedMaxLimit(params.row);
                  }
                }}
                key="cancel"
              />
            </Tooltip>
          )}
        </>,
      ],
    },
    {
      headerName: capitalize(t('startDate')),
      field: 'startDate',
      renderCell: ({ row }) => (row.startDate ? <DateTime dateTime={row.startDate} /> : <></>),
      flex: 1,
      minWidth: 160,
    },
    { headerName: capitalize(t('period')), field: 'period', flex: 1, minWidth: 80 },
    {
      headerName: capitalize(t('value')),
      field: 'value',
      flex: 1,
      minWidth: 150,
      valueFormatter: ({ value }) => value && getDurationFromSeconds(value),
    },
  ];

  const rows: GridRowsProp = useMemo(() => {
    return [
      ...(activeSessionLimits?.daily ? [{ ...activeSessionLimits?.daily, id: 0 }] : []),
      ...(activeSessionLimits?.weekly ? [{ ...activeSessionLimits?.weekly, id: 1 }] : []),
      ...(activeSessionLimits?.monthly ? [{ ...activeSessionLimits?.monthly, id: 2 }] : []),
    ];
  }, [activeSessionLimits?.daily, activeSessionLimits?.monthly, activeSessionLimits?.weekly]);

  return (
    <>
      <PermissionWrapper
        errorMessage={error?.message}
        isError={isError}
        isLoading={isLoading}
        permission={OnlineCasinoPermissions.getActiveMaximumLoginSessionLimits}
      >
        <DataGridContainer>
          <StyledDataGrid
            initialState={{ columns: { columnVisibilityModel: { endDate: false } } }}
            columns={headers}
            rows={rows}
            hideFooter
            rowCount={3}
          />
        </DataGridContainer>
      </PermissionWrapper>
      {selectedMaxLimit ? (
        <MaxLimitCancelDialog
          open={true}
          onClose={() => setSelectedMaxLimit(null)}
          type={MaxLimitType.SESSION_LIMIT}
          selectedMaxLimit={selectedMaxLimit}
        />
      ) : null}
    </>
  );
};

export default memo(ActiveMaxLimitsTable);
