import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { useGetUserWithdrawalRequests, WithdrawalRequestSchema } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, IconButton, Stack } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { endOfDay, getUnixTime, sub } from 'date-fns';

import { FontAwesomeIcon, Link, StyledDataGrid } from '@greenisland-common/components/atoms';
import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import {
  transformCurrencyV2,
  transformStatusV2,
  transformUnixDateV2,
} from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import { transformToCSVWithdrawals } from '../AccountData/helpers/TransformToCSV';

const SearchWithdrawRequests = () => {
  const canReadWithdrawalRequests = usePermission(OnlineCasinoPermissions.getUserWithdrawalRequests);

  return canReadWithdrawalRequests ? <SearchWithdrawRequestsInnerChild /> : null;
};

const currentDate = new Date();

const SearchWithdrawRequestsInnerChild = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const canReadEventstream = usePermission(OnlineCasinoPermissions.getEventstreamStreamId);
  const canReadWithdrawalRequests = usePermission(OnlineCasinoPermissions.getUserWithdrawalRequests);
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startdate');
  const endDate = searchParams.get('enddate');
  const start = startDate ? Number(startDate) : getUnixTime(sub(currentDate, { days: 1 }));
  const end = endDate ? Number(endDate) : getUnixTime(endOfDay(currentDate));

  const { data: withdrawalRequests, isLoading: fetching } = useGetUserWithdrawalRequests(
    userId,
    {
      start: start,
      end: end,
      top: 100,
    },
    { query: { enabled: canReadWithdrawalRequests && !!userId } }
  );

  const columns: GridColDef[] = [
    { ...transformUnixDateV2('createdOn', t), flex: 1 },
    { ...transformUnixDateV2('modifiedOn', t), flex: 1 },
    { ...transformCurrencyV2('amount', t), flex: 1 },
    { ...transformCurrencyV2('balance', t), flex: 1 },
    { headerName: capitalize(t('destination')), field: 'destination', flex: 1 },
    { ...transformStatusV2('status', t), flex: 1 },
    {
      field: 'history',
      headerName: '',
      sortable: false,
      filterable: false,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams) => {
        const item = params.row as WithdrawalRequestSchema;
        return canReadEventstream && item?.id ? (
          <IconButton
            size="small"
            color="secondary"
            component={Link}
            {...{ to: `../../users/${userId}/withdrawals/${item.id.toLowerCase()}` }}
          >
            <FontAwesomeIcon icon={faEye} />
          </IconButton>
        ) : null;
      },
    },
  ];

  const download = (content: string, fileName: string, mimeType: string) => {
    const a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';

    if (URL && 'download' in a) {
      a.href = URL.createObjectURL(new Blob([content], { type: mimeType }));
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      window.location.href = 'data:application/octet-stream,' + encodeURIComponent(content);
    }
  };

  const handleDownload = () => {
    if (withdrawalRequests) {
      const csv = transformToCSVWithdrawals(withdrawalRequests);
      if (csv) {
        download(csv, `withdrawals_${userId}.csv`, 'text/csv;encoding:utf-8');
      }
    }
  };

  return (
    <Stack spacing={2}>
      <DateRangePicker
        unixStartDate={start * 1000}
        unixEndDate={end * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
        }}
      />
      <Box>
        <Button
          disabled={!withdrawalRequests || !withdrawalRequests?.length}
          variant="outlined"
          size="small"
          onClick={handleDownload}
        >
          {t('exportToCSV')}
        </Button>
      </Box>
      <StyledDataGrid
        loading={fetching}
        rows={withdrawalRequests || []}
        columns={columns}
        pagination
        rowCount={withdrawalRequests?.length || 0}
        disableSelectionOnClick
        autoHeight
        getRowId={row => row.streamId}
      />
    </Stack>
  );
};

export default SearchWithdrawRequests;
