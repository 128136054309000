import React, { FC } from 'react';
import { KeyboardKeys } from '@greenisland-types/keyboardKeys';
import { Box } from '@mui/material';
import { DataGridPro, DataGridProProps, GridCellParams, MuiEvent } from '@mui/x-data-grid-pro';

export const RowContainer: FC = ({ children }) => <Box sx={{ pt: 1, pb: 1, width: '100%' }}>{children}</Box>;

const DataGridPlain = (props: DataGridProProps) => {
  const { columns, rows, sx, headerHeight, density, autoHeight, hideFooter, onCellKeyDown, ...rest } = props;

  // Pressing space on a text input in a cell will not work
  // as pressing space by default will navigate to the next scrollable page
  const handleSpaceKeyPressed = (_cell: GridCellParams, event: MuiEvent<React.KeyboardEvent>) => {
    if (
      event.keyCode === KeyboardKeys.SPACE ||
      event.keyCode === KeyboardKeys.ARROW_LEFT ||
      event.keyCode === KeyboardKeys.ARROW_RIGHT
    )
      return event.stopPropagation();
  };

  return (
    <DataGridPro
      autoHeight={autoHeight || true}
      density={density || 'compact'}
      columns={columns}
      rows={rows}
      hideFooter={hideFooter || true}
      headerHeight={headerHeight || 0}
      sx={{
        border: 0,
        '.MuiDataGrid-columnHeaders': { display: 'none', border: 'none' },
        '.MuiDataGrid-cell:first-of-type': { pl: 3 },
        '& .MuiDataGrid-cellContent': { whiteSpace: 'normal' },
        ...sx,
      }}
      onCellKeyDown={onCellKeyDown || handleSpaceKeyPressed}
      {...rest}
    />
  );
};

export default DataGridPlain;
