import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Currency, TournamentConfiguration } from '@greenisland-store/gameServer';
import { Box, Card, CardContent, CardHeader, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CurrencyInput from '@greenisland-common/components/molecules/CurrentcyInput';
import Input from '@greenisland-common/components/molecules/Input';
import SelectDropdownButton from '@greenisland-common/components/organisms/SelectDropdownButton';

import { parseFromCurrencyToNumberHandler } from '../helpers/tournamentConfigFormHelpers';
import { useGetTournamentCurrencyValues } from '../helpers/tournamentConfigFormHooks';
import { TournamentConfigurationMode } from '../helpers/tournamentConfigFormInterfaces';

interface Props {
  mode: TournamentConfigurationMode;
  tournamentConfiguration?: TournamentConfiguration;
}

const TournamentConfigBuyInSettings = ({ mode = TournamentConfigurationMode.ADD }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { control } = useFormContext();
  const currencyOptions = useGetTournamentCurrencyValues();

  const [chosenCurrencyValue, setChosenCurrencyValue] = useState({
    value: Currency.EUR,
    label: 'EURO',
  });

  return (
    <Stack spacing={2} position="relative">
      <Card>
        <CardHeader title={t('content.gameserver.tournamentconfiguration.form.buyinsettings.title')} />
        <Box
          sx={{
            [theme.breakpoints.up('xs')]: {
              position: 'initial',
              ml: 2,
              my: 1,
            },
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              right: 16,
              top: 8,
            },
          }}
        >
          <SelectDropdownButton
            chosenValue={chosenCurrencyValue}
            options={currencyOptions}
            onChange={setChosenCurrencyValue}
            disabled={mode === TournamentConfigurationMode.EDIT}
          />
        </Box>
        <CardContent>
          <Box display="flex" flexDirection="column">
            <CurrencyInput
              label={t('content.gameserver.tournamentconfiguration.buyInPrice')}
              control={control}
              name="buyInPrice.amount"
              defaultValue=""
              required
              rules={{
                required: t('fieldIsRequired'),
                min: 0,
                setValueAs: (value: any) => parseFromCurrencyToNumberHandler(value.toString()),
              }}
              InputProps={{
                inputProps: { min: 0 },
              }}
              sx={{
                [theme.breakpoints.up('xs')]: {
                  width: '100%',
                  mb: 2,
                },
                [theme.breakpoints.up('md')]: {
                  width: '45%',
                },
              }}
              disabled={mode === TournamentConfigurationMode.EDIT}
            />
            <Box
              display="flex"
              sx={{
                [theme.breakpoints.up('xs')]: {
                  flexDirection: 'column',
                },
                [theme.breakpoints.up('md')]: {
                  flexDirection: 'row',
                  gap: 2,
                },
              }}
            >
              <CurrencyInput
                label={t('content.gameserver.tournamentconfiguration.rebuyPrice')}
                control={control}
                name="rebuyPrice.amount"
                defaultValue=""
                InputProps={{
                  inputProps: { min: 0 },
                }}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    width: '100%',
                    mb: 2,
                  },
                  [theme.breakpoints.up('md')]: {
                    width: '45%',
                  },
                }}
                required
                rules={{
                  required: t('fieldIsRequired'),
                  setValueAs: (value: any) => parseFromCurrencyToNumberHandler(value.toString()),
                }}
                disabled={mode === TournamentConfigurationMode.EDIT}
              />
              <Input
                size="small"
                label={t('content.gameserver.tournamentconfiguration.maxNumberOfRebuys')}
                control={control}
                type="number"
                name="maxNumberOfRebuys"
                defaultValue=""
                required
                rules={{
                  required: t('fieldIsRequired'),
                  valueAsNumber: true,
                }}
                InputProps={{ inputProps: { min: 0 } }}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    width: '100%',
                    mb: 2,
                  },
                  [theme.breakpoints.up('md')]: {
                    width: '45%',
                  },
                }}
                disabled={mode === TournamentConfigurationMode.EDIT}
              />
            </Box>
            <CurrencyInput
              label={t('content.gameserver.tournamentconfiguration.initialPlayCreditsBalance')}
              control={control}
              name="initialPlayCreditsBalance.amount"
              defaultValue=""
              InputProps={{
                inputProps: { min: 0 },
              }}
              sx={{
                [theme.breakpoints.up('xs')]: {
                  width: '100%',
                  mb: 2,
                },
                [theme.breakpoints.up('md')]: {
                  width: '45%',
                },
              }}
              required
              rules={{
                required: t('fieldIsRequired'),
                setValueAs: (value: any) => parseFromCurrencyToNumberHandler(value.toString()),
              }}
              disabled={mode === TournamentConfigurationMode.EDIT}
            />
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default TournamentConfigBuyInSettings;
