import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';

import { Link } from '@greenisland-common/components/atoms';

import VouchersTable from './Vouchers/VouchersTable';
import { usePermission } from '../../../../../app/hooks';

interface Props {
  userId: string;
}

const Vouchers = ({ userId }: Props) => {
  const { t } = useTranslation();
  const canReadVouchers = usePermission(OnlineCasinoPermissions.getUserVouchers);

  if (!canReadVouchers) return null;

  return (
    <>
      <Card>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CardHeader title={t('vouchers')} />
          <Button sx={{ mr: 1 }} variant="outlined" color="primary" size="small" component={Link} to="../vouchers">
            {t('seeMore')}
          </Button>
        </Box>
        <CardContent>
          <VouchersTable userId={userId} pageSize={5} />
        </CardContent>
      </Card>
    </>
  );
};
export default memo(Vouchers);
