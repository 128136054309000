import { TranslationsSchema } from '@greenisland-api';

import { TotalFieldArrayDataItem } from '../Forms/TranslationsDictionaryFields';

const filterEmptyTranslations = (translations?: TranslationsSchema) => {
  return translations?.filter(item => item.content && item.content.trim() !== '') ?? [];
};

export const processFieldArray = (totalFieldArray: TotalFieldArrayDataItem[]) => {
  const descriptionsArray: TranslationsSchema = [];
  const nameTranslationsArray: TranslationsSchema = [];
  const imageUrlTranslationsArray: TranslationsSchema = [];

  totalFieldArray.forEach(({ language, descriptionContent, codeNameContent, imageUrlContent }) => {
    descriptionsArray.push({ language, content: descriptionContent });
    nameTranslationsArray.push({ language, content: codeNameContent });
    imageUrlTranslationsArray.push({ language, content: imageUrlContent });
  });

  return {
    descriptions: filterEmptyTranslations(descriptionsArray),
    nameTranslations: filterEmptyTranslations(nameTranslationsArray),
    imageUrlTranslations: filterEmptyTranslations(imageUrlTranslationsArray),
  };
};

export default processFieldArray;
