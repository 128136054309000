import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Permission } from '@greenisland-types/authorization';

export const LOOKUP_PERMISSIONS: Permission[] = [
  OnlineCasinoPermissions.getUserGeneral,
  OnlineCasinoPermissions.advancedUserSearchV2,
  OnlineCasinoPermissions.getUserIdentity,
  OnlineCasinoPermissions.getUserVerification,
  OnlineCasinoPermissions.getBasicAccountData,
  OnlineCasinoPermissions.getAllAccountData,
  OnlineCasinoPermissions.getGamesActivity,
  OnlineCasinoPermissions.getWithdrawLimits,
  OnlineCasinoPermissions.getUserVouchers,
  OnlineCasinoPermissions.getUserVaultTokenBalance,
  OnlineCasinoPermissions.getUserPreferences,
];
