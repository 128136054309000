import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { IEntityState } from '@greenisland/stores';
import { Tab } from '@mui/material';
import type { BaseRoute } from 'src/routes/RouteInterfaces';

import { usePermission } from '../../../../app/hooks';

type Props = BaseRoute & IEntityState & { isBlocking: boolean };

const WithdrawalTab = ({ name, path, permissions, selectedEntity, selectedProduct, isBlocking }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const canAccess = usePermission(permissions ?? []);

  const navigateTo = (event: any, url: string) => {
    event.preventDefault();
    if (isBlocking) {
      const check = window.confirm(t('unsavedChanges'));
      if (check) navigate(url, { state: { name: 'Withdrawals', tab: path } });
    } else {
      navigate(url, { state: { name: 'Withdrawals', tab: path } });
    }
  };

  return canAccess ? (
    <Tab
      sx={theme => ({
        color: theme.palette.text.primary,
        '&:hover': { color: theme.palette.primary.main },
        '&:focus': { color: theme.palette.primary.main },
      })}
      label={name}
      onClick={event => navigateTo(event, `/${selectedEntity}/${selectedProduct}/tasks/withdrawals/${path}`)}
    />
  ) : null;
};

export default WithdrawalTab;
