/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * Bad request type:
 * `InvalidRegulator` - This call is not available for this regulator.
 * `InvalidUserId` - User id is invalid.
 * `InvalidToken` - Token is invalid.
 * `BGCArticle6NotEnabled` - BGC Article 6 feature flag is not enabled for this operator.
 * `BGCNoExceedRequest` - The specified user does not have an BGC article 6 exceed request which is required for this call.
 * `NoExistingRegistration` - There is no existing regulator registration for the specified user.
 * `InvalidRegistrationState` - The regulator registration state is invalid for the specified operation.

 */
export type ERegulatorControllerBadRequestTypeSchema =
  (typeof ERegulatorControllerBadRequestTypeSchema)[keyof typeof ERegulatorControllerBadRequestTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ERegulatorControllerBadRequestTypeSchema = {
  InvalidRegulator: 'InvalidRegulator',
  InvalidUserId: 'InvalidUserId',
  BGCArticle6NotEnabled: 'BGCArticle6NotEnabled',
  BGCNoExceedRequest: 'BGCNoExceedRequest',
  InvalidToken: 'InvalidToken',
  NoExistingRegistration: 'NoExistingRegistration',
  InvalidRegistrationState: 'InvalidRegistrationState',
} as const;
