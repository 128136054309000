import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { faGripLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import type { GridStateColDef } from '@mui/x-data-grid-pro';

interface ColumnOrderDialogProps {
  columns: GridStateColDef[];
  setColumnsOrder: (columnOrder: GridStateColDef[]) => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ColumnOrderDialog = (props: ColumnOrderDialogProps) => {
  const { t } = useTranslation();
  const { setColumnsOrder, isOpen, setIsOpen } = props;
  const [columns, setColumns] = useState(props.columns);

  const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(columns, result.source.index, result.destination.index);

    setColumns(items);
  };

  const saveOrder = () => {
    setColumnsOrder(columns);
    setIsOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogTitle>{t('setColumnOrder')}</DialogTitle>
      <DialogContent>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {columns.map(
                  (item, index) =>
                    item.field.toLowerCase() !== 'selection' && (
                      <Draggable key={item.field} draggableId={item.field} index={index}>
                        {provided => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <ListItem
                              key={item.field}
                              secondaryAction={
                                <IconButton edge="end" aria-label="draggable">
                                  <FontAwesomeIcon icon={faGripLines} />
                                </IconButton>
                              }
                              disablePadding
                            >
                              <ListItemButton role={undefined} dense>
                                <ListItemText id={item.field} primary={item.headerName} />
                              </ListItemButton>
                            </ListItem>
                          </div>
                        )}
                      </Draggable>
                    )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" size="medium" onClick={() => setIsOpen(false)}>
          {t('close')}
        </Button>
        <Button onClick={() => saveOrder()} size="medium" variant="contained">
          {t('saveColumnOrder')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ColumnOrderDialog;
