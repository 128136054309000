import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import {
  getGetIndividualActionQueryKey,
  UpdateIndividualActionSchema,
  useAddIndividualAction,
  useUpdateIndividualAction,
} from '@greenisland-api';
import { getUnixTime } from 'date-fns';
import { useSnackbar } from 'notistack';

import { calculateTicks } from '@greenisland-common/helpers/timeConverters';

import { getLoyaltyDependentMaxPayoutValues } from '../../../../Components/LoyaltyDependentValue/LoyaltyDependentValueDecimal';
import { IndividualActionForm } from '../context';
import { calculatePromotionDuration, getBonusLoyaltyDependentValues } from '../Forms/helpers/DepositBonusFormContext';

const transformData = (data: IndividualActionForm): UpdateIndividualActionSchema => {
  return {
    name: data.name,
    availableBonus: {
      enabled: false,
      amount: 0,
      expiresInDays: 0,
      playthrough: 0,
    },
    playthroughBonus: {
      enabled: data?.enabledPlaythrough || false,
      name: data?.playthroughBonusName || '',
      contentFilterId: data?.playthroughBonusContentFilterId || undefined,
      imageIds: data?.playthroughBonusImages?.map(image => image?.id),
      loyaltyDependentAmount: getBonusLoyaltyDependentValues(
        data.playthroughBonusAmountIsLoyaltyDependent,
        data.playthroughBonusAmountLoyaltyDependentValue,
        data.playthroughBonusLoyaltyDependentAmountField
      ),
      loyaltyDependentPlaythrough: getBonusLoyaltyDependentValues(
        data.playthroughBonusPlaythroughIsLoyaltyDependent,
        data.playthroughBonusPlaythroughLoyaltyDependentValue,
        data.playthroughBonusLoyaltyDependentPlaythroughField
      ),
      expiryDuration: calculatePromotionDuration(
        data?.playthroughBonusExpirationDuration,
        data?.playthroughBonusExpirationDurationSelectOption
      ),
    },
    depositBonus: {
      enabled: data?.enabledDeposit || false,
      name: data?.depositBonusName || '',
      contentFilterId: data?.depositBonusContentFilterId || undefined,
      imageIds: data?.depositBonusImages?.map(image => image?.id),
      loyaltyDependentPercentage: getBonusLoyaltyDependentValues(
        data.percentageIsLoyaltyDependent,
        data.percentageLoyaltyDependentValue,
        data.loyaltyDependentPercentageField
      ),
      loyaltyDependentLimit: getBonusLoyaltyDependentValues(
        data.limitIsLoyaltyDependent,
        data.limitLoyaltyDependentValue,
        data.loyaltyDependentLimitField
      ),
      loyaltyDependentPlaythrough: getBonusLoyaltyDependentValues(
        data.playthroughIsLoyaltyDependent,
        data.playthroughLoyaltyDependentValue,
        data.loyaltyDependentPlaythroughField
      ),
      expiryDuration: calculatePromotionDuration(
        data?.depositBonusExpirationDuration,
        data?.depositBonusExpirationDurationSelectOption
      ),
      expiryDurationDeposit: calculatePromotionDuration(
        data?.depositBonusPlaythroughExpirationDuration,
        data?.depositBonusPlaythroughExpirationDurationSelectOption
      ),
      absoluteExpiryDate: data?.depositBonusAbsoluteExpiryDate
        ? getUnixTime(data.depositBonusAbsoluteExpiryDate)
        : null,
    },
    trustpilotReviewEmail: {
      enabled: data.enabledTrustpilotEmail || false,
    },
    freeSpins: {
      enabled: data.enabledFreeSpins || false,
      freeSpinsCampaign: data.freeSpinsCampaign || {
        amountOfSpins: -1,
        campaignId: '',
        game: undefined,
        name: '',
        provider: {
          id: '',
          name: '',
        },
        stake: -1,
      },
      expiresInDays: Number(data.expiresInDaysFreeSpins) || 0,
      imageIds: data?.freeSpinsImages?.map(image => image?.id),
    },
    freeBet: {
      enabled: data.enabledFreeBet || false,
      amount: Number(data.amountFreeBet) || 0,
      currency: data.currencyFreeBet || '-',
      provider: data.providerFreeBet || '-',
      bonus: data.bonusFreeBet || '-',
    },
    freeCash: {
      enabled: data.enabledFreeCash || false,
      amount: Number(data.amountFreeCash) || 0,
      currency: data.currencyFreeCash || '-',
    },
    plainCampaignMail: {
      enabled: data.enabledPlain || false,
    },
    contestQuestion: {
      enabled: data.enabledContest || false,
      question: data.questionContest || '-',
    },
    loyaltyPoints: {
      enabled: data.enabledLoyaltyPoints || false,
      spendablePoints: Number(data.spendablePointsLoyaltyPoints) || 0,
      statusPoints: Number(data.statusPointsLoyaltyPoints) || 0,
    },
    voucher: {
      enabled: data.enabledVoucher || false,
      internalVoucher: data.internalVoucher || false,
      productGroupId: Number(data.productGroupIdVoucher) || 0,
      productId: Number(data.productIdVoucher) || 0,
      productValue: Number(data.productValueVoucher) || 0,
      durationTicks: data.durationVoucher ? calculateTicks(data.durationVoucher, 'days') : data.durationVoucher,
    },
    vault: {
      enabled: data.enabledTokens || false,
      amountOfTokens: Number(data.amountOfTokens) || 0,
    },
    boost: {
      name: data.boostName,
      enabled: data.boostEnabled,
      boostType: data.boostType,
      duration: calculatePromotionDuration(data?.boostDuration, data?.boostDurationSelectOption),
      aggregationFunction: data.boostAggregationFunction,
      factor: data.boostFactor / 100.0,
      payoutType: data.boostPayoutType,
      isLoyaltyDependent: data.boostIsLoyaltyDependent,
      contentFilterId: data.boostContentFilterId,
      startDate: data.boostStartDate && new Date(data.boostStartDate).getTime() / 1000,
      expiryDuration: data?.boostExpirationDuration
        ? calculatePromotionDuration(data.boostExpirationDuration, data?.boostExpirationDurationSelectOption)
        : null,
      imageIds: data?.boostImages?.map(image => image?.id),
      maxPayout: !data.boostIsLoyaltyDependent ? data.boostMaxPayout : null,
      loyaltyDependentMaxPayout: getLoyaltyDependentMaxPayoutValues(
        data.boostIsLoyaltyDependent,
        data.loyaltyDependentMaxPayout
      ),
      earlyMaxPayoutEnabled: data?.boostsEarlyMaxPayoutEnabled,
    },
    boosterPack: {
      enabled: data?.enabledBoosterPack || false,
      boosterPackId: data?.enabledBoosterPack ? data?.boosterPackId : undefined,
    },
  };
};

export default function useSubmitIndividualAction(campaignId: string, actionId: string) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = {
    onSuccess: () => {
      queryClient.invalidateQueries(getGetIndividualActionQueryKey(campaignId, actionId));
      enqueueSnackbar(t('success'), { variant: 'success' });
      navigate(`../campaigns/overview?campaignId=${campaignId}`);
    },
    onError: () => {
      enqueueSnackbar(t('error'), { variant: 'error' });
    },
  };

  const { mutate: addAction } = useAddIndividualAction({ mutation });
  const { mutate: updateAction } = useUpdateIndividualAction({ mutation });

  return useCallback(
    (data: IndividualActionForm) => {
      if (data) {
        const action = transformData(data);
        if (actionId.toLowerCase() === 'add') {
          addAction({ campaignId, data: action });
        } else {
          updateAction({ campaignId, data: action, actionId });
        }
      }
    },
    [actionId, addAction, campaignId, updateAction]
  );
}
