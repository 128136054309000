import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalize, Typography } from '@mui/material';

import type { IndividualActionForm } from '../context';
import IndividualActionFormListItem from './IndividualActionFormListItem';

const TrustpilotForm = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext<IndividualActionForm>();
  const enabledTrustpilotEmail = watch('enabledTrustpilotEmail');

  return (
    <IndividualActionFormListItem
      enabled={enabledTrustpilotEmail}
      name="enabledTrustpilotEmail"
      listItemText={capitalize(t('marketing.trustpilot.actions.reviewEmailTitle'))}
    >
      <Typography variant="body1">{t('noParametersToConfigure')}</Typography>
    </IndividualActionFormListItem>
  );
};

export default TrustpilotForm;
