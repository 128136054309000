import React, { useMemo } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  ELanguagesSchema,
  EMediaGalleryEntryTypeSchema,
  GameCategorySchema,
  GameProviderSchema,
  GameStudioSchema,
  GameThemeSchema,
  TranslationsSchemaItem,
} from '@greenisland-api';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, IconButton } from '@mui/material';

import { SelectInput } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';
import MediaField from '@greenisland-common/components/molecules/MediaField';

import { GameForm } from './helpers/GameFormContext';

export interface TotalFieldArrayDataItem {
  language: ELanguagesSchema;
  descriptionContent?: string;
  codeNameContent?: string;
  imageUrlContent?: string;
}

interface TranslationsDictionaryFieldsProps {
  control: Control<GameStudioSchema | GameForm | GameThemeSchema | GameCategorySchema | GameProviderSchema>;
  nameDescription?: string;
  nameCodeName?: string;
  nameImageUrl?: string;
  mediaGalleryEntryType?: EMediaGalleryEntryTypeSchema;
}

const sortLanguages = (a: ELanguagesSchema, b: ELanguagesSchema) => {
  if (a === ELanguagesSchema.EN) return -1;
  if (b === ELanguagesSchema.EN) return 1;
  if (a === ELanguagesSchema.NL) return -1;
  if (b === ELanguagesSchema.NL) return 1;
  if (a === ELanguagesSchema.FR) return -1;
  if (b === ELanguagesSchema.FR) return 1;
  if (a === ELanguagesSchema.DE) return -1;
  if (b === ELanguagesSchema.DE) return 1;
  return a.localeCompare(b);
};

const TranslationsDictionaryFields: React.FC<TranslationsDictionaryFieldsProps> = ({
  control,
  nameDescription,
  nameCodeName,
  nameImageUrl,
  mediaGalleryEntryType,
}) => {
  const { t } = useTranslation();

  const descriptionFieldArray = useFieldArray<TranslationsSchemaItem>({ control, name: nameDescription || '' });
  const codeNameFieldArray = useFieldArray<TranslationsSchemaItem>({ control, name: nameCodeName || '' });
  const imageUrlFieldArray = useFieldArray<TranslationsSchemaItem>({ control, name: nameImageUrl || '' });

  const totalFieldArray = useMemo(() => {
    const totalFieldArray: TotalFieldArrayDataItem[] = [];
    const languageSet = new Set();

    [...descriptionFieldArray.fields, ...codeNameFieldArray.fields, ...imageUrlFieldArray.fields].forEach(field => {
      if (!languageSet.has(field.language) && field.language) {
        languageSet.add(field.language);
        totalFieldArray.push({
          language: field.language,
          descriptionContent: descriptionFieldArray.fields.find(f => f.language === field.language)?.content || '',
          codeNameContent: codeNameFieldArray.fields.find(f => f.language === field.language)?.content || '',
          imageUrlContent: imageUrlFieldArray.fields.find(f => f.language === field.language)?.content || '',
        });
      }
    });

    totalFieldArray.sort((a, b) => sortLanguages(a.language, b.language));
    return totalFieldArray;
  }, [descriptionFieldArray.fields, codeNameFieldArray.fields, imageUrlFieldArray.fields]);

  function appendData() {
    const nextLanguage = Object.values(ELanguagesSchema)
      .sort(sortLanguages)
      .find(language => !totalFieldArray.find(field => field.language === language));
    if (!nextLanguage) return;

    descriptionFieldArray.append({ language: nextLanguage, content: '' });
    codeNameFieldArray.append({ language: nextLanguage, content: '' });
    imageUrlFieldArray.append({ language: nextLanguage, content: '' });
  }

  const removeDataByLanguage = (language: ELanguagesSchema | undefined) => {
    if (!language) return;

    descriptionFieldArray.remove(descriptionFieldArray.fields.findIndex(field => field.language === language));
    codeNameFieldArray.remove(codeNameFieldArray.fields.findIndex(field => field.language === language));
    imageUrlFieldArray.remove(imageUrlFieldArray.fields.findIndex(field => field.language === language));
  };

  return (
    <>
      {totalFieldArray.map(({ language, descriptionContent, codeNameContent, imageUrlContent }, index) => (
        <Box key={language} sx={{ mb: 2 }}>
          <Box display="flex" alignItems="center" gap={2}>
            <IconButton onClick={() => removeDataByLanguage(language)} size="small">
              <DeleteIcon />
            </IconButton>
            <SelectInput
              name={`totalFieldArray[${index}].language`}
              label={t('language')}
              rules={{ required: t('fieldIsRequired') }}
              options={Object.keys(ELanguagesSchema).map(key => ({ value: key, label: t(key) }))}
              defaultValue={language}
              sx={{ width: '20%' }}
            />
          </Box>
          <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
            <Box flex={1} sx={{ mr: 2 }}>
              {nameCodeName && (
                <Input
                  control={control}
                  label={t('codeName')}
                  name={`totalFieldArray[${index}].codeNameContent`}
                  defaultValue={codeNameContent}
                  fullWidth
                  sx={{ mb: 2 }}
                />
              )}
              {nameDescription && (
                <Input
                  control={control}
                  label={t('description')}
                  name={`totalFieldArray[${index}].descriptionContent`}
                  defaultValue={descriptionContent}
                  multiline
                  fullWidth
                />
              )}
            </Box>
            {nameImageUrl && mediaGalleryEntryType && (
              <MediaField
                control={control}
                name={`totalFieldArray[${index}].imageUrlContent`}
                defaultValue={imageUrlContent}
                mediaGalleryEntryType={mediaGalleryEntryType}
              />
            )}
          </Box>
        </Box>
      ))}
      <Box>
        <Button variant="outlined" onClick={appendData} sx={{ mb: 2 }}>
          {t('editGames.addTranslation')}
        </Button>
      </Box>
    </>
  );
};

export default TranslationsDictionaryFields;
