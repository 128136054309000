import { EServiceContractBadRequestTypeSchema } from '@greenisland-api';

type ServiceContractsErrorReasons = {
  [key in EServiceContractBadRequestTypeSchema]: string;
};

export const SERVICE_CONTRACTS_ERROR_REASONS: ServiceContractsErrorReasons = {
  [EServiceContractBadRequestTypeSchema.InvalidContractName]: 'serviceContracts.errors.InvalidContractName',
  [EServiceContractBadRequestTypeSchema.ContractNameAlreadyUsed]: 'serviceContracts.errors.ContractNameAlreadyUsed',
  [EServiceContractBadRequestTypeSchema.ContractNotFound]: 'serviceContracts.errors.ContractNotFound',
  [EServiceContractBadRequestTypeSchema.UserNotFound]: 'serviceContracts.errors.UserNotFound',
  [EServiceContractBadRequestTypeSchema.UserHasNeverSignedContract]:
    'serviceContracts.errors.UserHasNeverSignedContract',
  [EServiceContractBadRequestTypeSchema.FailedToUploadContract]: 'serviceContracts.errors.FailedToUploadContract',
  [EServiceContractBadRequestTypeSchema.InvalidContent]: 'serviceContracts.errors.InvalidContent',
};
