import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { InterventionSchema, SortOrderParamParameter, useGetInterventions } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import AddInterventionDialog from 'src/pages/OnlineCasino/Tasks/Interventions/AddInterventionDialog';
import InterventionStatusField from 'src/pages/OnlineCasino/Tasks/Interventions/InterventionStatusField';
import { WRITE_INTERVENTION_PERMISSION } from 'src/pages/OnlineCasino/Tasks/Interventions/permissions';

import {
  DataGridContainer,
  ErrorCard,
  FontAwesomeIcon,
  Link,
  NoData,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../app/hooks';

interface Props {
  userId: string;
}

const Interventions = ({ userId }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const canReadInterventions = usePermission(OnlineCasinoPermissions.getInterventions);
  const canAddIntervention = usePermission(WRITE_INTERVENTION_PERMISSION);
  const canReadIntervention = usePermission(OnlineCasinoPermissions.getIntervention);
  const navigate = useNavigate();

  const { data, isLoading, isError } = useGetInterventions(
    { userId: Number.parseInt(userId), numberOfItems: 5, pageNumber: 0, sortOrder: SortOrderParamParameter.descending },
    { query: { enabled: canReadInterventions } }
  );

  const headers = useMemo<GridColumns<InterventionSchema>>(
    () => [
      {
        field: 'status',
        headerName: t('tasks.interventions.status'),
        renderCell: params => <InterventionStatusField value={params.row.status} />,
        flex: 2,
        sortable: false,
        minWidth: 90,
      },
      {
        field: 'interventionType',
        headerName: t('tasks.interventions.interventionType'),
        flex: 2,
        sortable: false,
        minWidth: 130,
      },
      {
        field: 'interventionCause',
        headerName: t('tasks.interventions.interventionCause'),
        flex: 2,
        sortable: false,
        minWidth: 140,
      },
      ...(canReadIntervention
        ? [
            {
              field: 'actions',
              type: 'actions',
              getActions: (params: GridRowParams<any>) => [
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faEye} />}
                  label="View"
                  onClick={() => navigate(`../../responsible-gaming/interventions/${params.row.id}`)}
                  key="view"
                />,
              ],
              flex: 0.5,
            },
          ]
        : []),
    ],
    [canReadIntervention, navigate, t]
  );

  if (!canReadInterventions) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={95} />;
  if (isError) return <ErrorCard sx={{ height: 95 }}>{t('tasks.interventions.loadingError')}</ErrorCard>;

  return (
    <>
      <Card>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CardHeader title={t('tasks.interventions.interventions')} />
          <Box display="flex" flexWrap="wrap" justifyContent="flex-end" rowGap={1}>
            {canAddIntervention ? (
              <Button sx={{ mr: 1 }} variant="outlined" color="secondary" size="small" onClick={() => setIsOpen(true)}>
                {t('tasks.interventions.add')}
              </Button>
            ) : null}
            <Button
              sx={{ mr: 1 }}
              variant="outlined"
              color="primary"
              size="small"
              component={Link}
              to={`../../responsible-gaming/interventions?user=${userId}`}
            >
              {t('seeMore')}
            </Button>
          </Box>
        </Box>
        <CardContent>
          {data?.interventions && data.interventions.length > 0 ? (
            <DataGridContainer>
              <StyledDataGrid
                autoHeight
                density="compact"
                columns={headers}
                rows={data.interventions}
                hideFooter
                disableDensitySelector
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                disableChildrenSorting
                disableChildrenFiltering
                disableMultipleColumnsSorting
                disableColumnResize
                disableColumnReorder
                isRowSelectable={() => false}
              />
            </DataGridContainer>
          ) : (
            <NoData />
          )}
        </CardContent>
      </Card>
      {canAddIntervention ? <AddInterventionDialog setOpen={setIsOpen} open={isOpen} userId={userId} /> : null}
    </>
  );
};

export default memo(Interventions);
