import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select, TextField } from '@mui/material';

import type { IndividualActionForm } from '../context';
import IndividualActionFormListItem from './IndividualActionFormListItem';

type CommonProps = Pick<React.ComponentProps<typeof TextField>, 'size' | 'disabled' | 'required' | 'fullWidth'>;

const FreeBetForm = () => {
  const { t } = useTranslation();
  const { register, watch, setValue, errors } = useFormContext<IndividualActionForm>();
  const enabledFreeBet = watch('enabledFreeBet');
  const commonProps: CommonProps = {
    size: 'small',
    disabled: !enabledFreeBet,
    required: enabledFreeBet,
    fullWidth: true,
  };

  useEffect(() => {
    if (!enabledFreeBet) {
      setValue('amountFreeBet', '');
    }
  }, [setValue, enabledFreeBet]);

  return (
    <IndividualActionFormListItem enabled={enabledFreeBet} name="enabledFreeBet" listItemText={t('freeBet')}>
      <TextField
        {...commonProps}
        inputRef={register({ required: enabledFreeBet, min: 1 })}
        name="amountFreeBet"
        error={Boolean(errors.amountFreeBet)}
        label={t('amount')}
        type="number"
      />
      <Controller
        rules={{ required: enabledFreeBet }}
        name="currencyFreeBet"
        render={props => (
          <Select
            {...commonProps}
            error={Boolean(errors.currencyFreeBet)}
            label={t('currency')}
            onChange={props.onChange}
            value={props.value}
          >
            <MenuItem value="EUR">EUR €</MenuItem>
            <MenuItem value="USD">USD $</MenuItem>
            <MenuItem value="GBP">GBP £</MenuItem>
          </Select>
        )}
      />
      <TextField
        size="small"
        sx={{ display: 'flex', mb: 2 }}
        label={t('provider')}
        disabled
        name="providerFreeBet"
        inputRef={register()}
      />
      <Controller
        rules={{ required: enabledFreeBet }}
        name="bonusFreeBet"
        render={props => (
          <Select
            {...commonProps}
            error={Boolean(errors.bonusFreeBet)}
            label={t('bonus')}
            onChange={props.onChange}
            value={props.value}
          >
            <MenuItem value="bonus">{t('bonus')}</MenuItem>
          </Select>
        )}
      />
    </IndividualActionFormListItem>
  );
};

export default FreeBetForm;
