/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */

export type Currency = (typeof Currency)[keyof typeof Currency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Currency = {
  EUR: 'EUR',
} as const;
