import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useGetWithdrawalsGeneral } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Card } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid-pro';
import { formatCurrency, getDateTimeFromUnix, StatusSpan } from 'src/app/helpers/transformFunctions';
import { usePermission } from 'src/app/hooks';
import useSearch from 'src/pages/OnlineCasino/Lookup/hooks/useSearch';

import { Link, StyledDataGrid } from '@greenisland-common/components/atoms';

import { EventStreamParamsType } from '../../../../../../Lookup/Components/DetailsComponents/Constants/Eventstreams';

interface Props {
  iban: string;
  startDate?: Date;
  endDate?: Date;
}

const WithdrawalsTable = ({ iban, startDate, endDate }: Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const canSearchWithdrawals = usePermission(OnlineCasinoPermissions.getWithdrawalsGeneral);
  const navigate = useNavigate();
  const { data: withdrawals } = useGetWithdrawalsGeneral(
    {
      iban,
      ...(startDate ? { start: (startDate.getTime() ?? 0) / 1000 } : {}),
      ...(endDate ? { end: (endDate.getTime() ?? 0) / 1000 } : {}),
      pageNumber: page,
      numberOfItems: 20,
    },
    { query: { enabled: canSearchWithdrawals } }
  );

  const { setSearchValue, searchValue } = useSearch(data => {
    if (data && data.length > 0) {
      if (data.length === 1) {
        navigate(`../../users/${data[0].userId}/details`, {
          state: { name: 'User Details' },
        });
      }
      if (data.length > 1 && location.pathname) {
        navigate(`../../lookup?search=${searchValue}`, {
          state: { name: 'Lookup' },
        });
      }
    }
  });

  const headers: GridColumns = [
    {
      headerName: t('date'),
      field: 'timestamp',
      valueFormatter: ({ value }) => getDateTimeFromUnix(value),
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: t('value'),
      field: 'amount',
      valueFormatter: ({ value }) => formatCurrency(value),
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: t('destination'),
      field: 'destination',
      flex: 1,
      minWidth: 150,
    },
    {
      headerName: t('status'),
      field: 'status',
      flex: 1,
      renderCell: ({ row }) => <StatusSpan value={row.status} />,
      minWidth: 100,
    },
    {
      headerName: t('accountId'),
      field: 'accountId',
      flex: 1,
      renderCell: ({ row }) => (
        <Button variant="text" onClick={() => setSearchValue(`accountId:${row.accountId}`)}>
          {row.accountId}
        </Button>
      ),
      minWidth: 100,
    },
    {
      headerName: '',
      field: 'id',
      renderCell: ({ row }) => (
        <Link to={`../../lookup/eventstreams?${EventStreamParamsType.STREAM_ID}=withdrawRequest/${row.id}`}>
          {t('details')}
        </Link>
      ),
      flex: 1,
      minWidth: 100,
    },
  ];

  const rows = withdrawals?.items?.map(item => ({ ...item })) ?? [];

  return (
    <>
      <Card sx={{ padding: 0 }}>
        <StyledDataGrid
          autoHeight
          columns={headers}
          rows={rows}
          pagination
          paginationMode="server"
          pageSize={20}
          page={withdrawals?.pagingDetails?.currentPage}
          rowCount={withdrawals?.pagingDetails?.totalItems}
          onPageChange={setPage}
        />
      </Card>
    </>
  );
};

export default WithdrawalsTable;
