import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { URLSearchParamsInit } from 'react-router-dom';
import {
  EWithdrawalSortingTypeSchema,
  EWithdrawalStatusSchema,
  EWithdrawalTypeSchema,
  EWithdrawFlagSchema,
  GetWithdrawalsGeneralParams,
  useGetCasinos,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Checkbox, FormControlLabel, Stack } from '@mui/material';

import { AutoCompleteInput } from '@greenisland-common/components/atoms';
import CheckBox from '@greenisland-common/components/atoms/Checkbox';
import DateTimeSecondsField from '@greenisland-common/components/molecules/DateTimeSecondsInput/DateTimeSecondsInput';
import Input from '@greenisland-common/components/molecules/Input';
import NumberInput from '@greenisland-common/components/molecules/NumberInput';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import {
  pipe,
  removeEmptyStringProperties,
  removeNullProperties,
  removeUndefinedProperties,
} from '../../../../app/helpers/functions';
import { usePermission } from '../../../../app/hooks';

interface FormParams extends Omit<GetWithdrawalsGeneralParams, 'destination' | 'status' | 'flag' | 'sortBy'> {
  destination?: GetWithdrawalsGeneralParams['destination'] | null;
  status?: GetWithdrawalsGeneralParams['status'] | null;
  flag?: GetWithdrawalsGeneralParams['flag'] | null;
  sortBy?: GetWithdrawalsGeneralParams['sortBy'] | null;
}

interface Props {
  hasDetails: boolean;
  setHasDetails: (hasDetails: boolean) => void;
  setSearchQuery: (searchQuery: GetWithdrawalsGeneralParams) => void;
  searchParams: URLSearchParams;
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: any;
        }
      | undefined
  ) => void;
}

const SearchWithdrawal = ({ setSearchQuery, hasDetails, setHasDetails, searchParams, setSearchParams }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<FormParams>({
    defaultValues: {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      start: searchParams.get('start') !== null ? new Date(searchParams.get('start')!).getTime() : undefined,
      end: searchParams.get('end') !== null ? new Date(searchParams.get('end')!).getTime() : undefined,
      minAmount: searchParams.get('minamount') !== null ? parseFloat(searchParams.get('minamount')!) : undefined,
      maxAmount: searchParams.get('maxamount') !== null ? parseFloat(searchParams.get('maxamount')!) : undefined,
      userId: searchParams.get('userid') !== null ? searchParams.get('userid')! : undefined,
      accountId: searchParams.get('accountid') !== null ? searchParams.get('accountid')! : undefined,
      withdrawalId: searchParams.get('withdrawalid') !== null ? searchParams.get('withdrawalid')! : undefined,
      reference: searchParams.get('reference') !== null ? searchParams.get('reference')! : undefined,
      iban: searchParams.get('iban') !== null ? searchParams.get('iban')! : undefined,
      wallet: searchParams.get('wallet') !== null ? searchParams.get('wallet')! : undefined,
      casinoGuid: searchParams.get('casinoguid') !== null ? searchParams.get('casinoguid')! : undefined,
      destination:
        searchParams.get('destination') !== null ? (searchParams.get('destination')! as EWithdrawalTypeSchema) : null,
      status: searchParams.get('status') !== null ? (searchParams.get('status')! as EWithdrawalStatusSchema) : null,
      flag: searchParams.get('flag') !== null ? (searchParams.get('flag')! as EWithdrawFlagSchema) : null,
      sortBy:
        searchParams.get('sortby') !== null ? (searchParams.get('sortby')! as EWithdrawalSortingTypeSchema) : null,
      /* eslint-enable */
    },
  });
  const { control, handleSubmit } = methods;
  const canReadCasinos = usePermission(OnlineCasinoPermissions.getCasinos);
  const {
    data: casinos,
    isError,
    isLoading,
  } = useGetCasinos({
    query: {
      enabled: canReadCasinos,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  });

  const onSubmit = (data: FormParams) => {
    const start = data.start ? new Date(data.start).toISOString() : null;
    const end = data.end ? new Date(data.end).toISOString() : null;
    replaceUrlQueryParameters({ ...data, start: start, end: end }, setSearchParams);

    setSearchQuery(
      pipe(
        removeUndefinedProperties,
        removeNullProperties,
        removeEmptyStringProperties
      )({
        ...data,
        casinoGuid:
          !isLoading && !isError && data.casinoGuid != ''
            ? casinos?.filter(obj => obj.name == data.casinoGuid).at(0)?.casinoId
            : null,
        start: data.start ? data.start / 1000 : undefined,
        end: data.end ? data.end / 1000 : undefined,
      })
    );
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(data => onSubmit(data))}>
          <Box
            display="flex"
            flexDirection="column"
            sx={{ maxWidth: '100%', gridGap: '15px 15px', gridTemplateColumns: '1fr 1fr', marginBottom: '15px' }}
          >
            <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
              <DateTimeSecondsField name={'start'} label={t('startDate')} />
              <DateTimeSecondsField name={'end'} label={t('endDate')} />
            </Stack>

            <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
              <Input
                rules={{ min: 0 }}
                name={'minAmount'}
                control={control}
                fullWidth
                label={t('minimumAmount')}
                type="number"
                defaultValue={''}
              />
              <Input
                rules={{ min: 0 }}
                name={'maxAmount'}
                control={control}
                fullWidth
                label={t('maximumAmount')}
                type="number"
                defaultValue={''}
              />
            </Stack>

            <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
              <NumberInput minValue={1} name={'userId'} control={control} label={t('userId')} defaultValue={''} />
              <NumberInput minValue={1} name={'accountId'} control={control} label={t('accountId')} defaultValue={''} />
              <NumberInput
                minValue={1}
                name={'withdrawalId'}
                control={control}
                label={t('withdrawalId')}
                defaultValue={''}
              />
              <Input
                name={'reference'}
                control={control}
                fullWidth
                label={t('reference')}
                type="string"
                defaultValue={''}
              />
            </Stack>

            <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
              <Input name={'iban'} control={control} fullWidth label={t('iban')} type="string" defaultValue={''} />
              <Input name={'wallet'} control={control} fullWidth label={t('wallet')} type="string" defaultValue={''} />
              {canReadCasinos && (
                <AutoCompleteInput
                  name="casinoGuid"
                  disableCloseOnSelect={false}
                  label={t('casino')}
                  options={casinos?.map(casino => casino.name) ?? []}
                  getLabel={option => option ?? ''}
                />
              )}
            </Stack>

            <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
              <AutoCompleteInput
                name="destination"
                disableCloseOnSelect={false}
                label={t('destination')}
                options={Object.keys(EWithdrawalTypeSchema)}
                getLabel={option => option ?? ''}
              />
              <AutoCompleteInput
                name="status"
                disableCloseOnSelect={false}
                label={t('status')}
                options={Object.keys(EWithdrawalStatusSchema)}
                getLabel={option => option ?? ''}
              />
              <AutoCompleteInput
                name="flag"
                disableCloseOnSelect={false}
                label={t('flag')}
                options={Object.keys(EWithdrawFlagSchema)}
                getLabel={option => option ?? ''}
              />
            </Stack>

            <Stack
              direction="row"
              spacing={1}
              sx={{ width: 350 }}
              justifyContent="start"
              alignItems="center"
              display="flex"
            >
              <AutoCompleteInput
                name="sortBy"
                disableCloseOnSelect={false}
                label={t('sortBy')}
                options={Object.keys(EWithdrawalSortingTypeSchema)}
                getLabel={option => option ?? ''}
              />
              <CheckBox name="ascending" label={t('ascending')} />
            </Stack>

            <Stack direction="row" spacing={1} sx={{ width: 500 }} justifyContent="start" alignItems="start">
              <Button size="large" variant="contained" type="submit">
                {t('search')}
              </Button>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={e => setHasDetails(e.target.checked)}
                    checked={hasDetails}
                    size="medium"
                  />
                }
                label={t('details')}
              />
            </Stack>
          </Box>
        </form>
      </FormProvider>
    </>
  );
};

export default SearchWithdrawal;
