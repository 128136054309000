import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetLoyaltyRankDistributions } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { capitalize, Card, CardContent } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, Link, PermissionWrapper } from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { formatDecimal, formatPercentage, PercentageSpan } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';

const Ranks = () => {
  const t = useTranslation().t;
  const canReadLoyaltyRankDistributions = usePermission(OnlineCasinoPermissions.getLoyaltyRankDistributions);
  const canReadLoyaltyRankUsers = usePermission(OnlineCasinoPermissions.getLoyaltyRankUsers);

  const {
    data: loyaltyRanks,
    isLoading,
    isError,
  } = useGetLoyaltyRankDistributions({ query: { enabled: canReadLoyaltyRankDistributions } });

  const headers: GridColumns = [
    {
      headerName: capitalize(t('loyaltyRank')),
      field: 'loyaltyRank',
      renderCell: ({ value }) => (canReadLoyaltyRankUsers ? <Link to={`${value.toLowerCase()}`}>{value}</Link> : value),
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: capitalize(t('numberOfUsers')),
      field: 'numberOfUsers',

      renderCell: ({ value }) => formatDecimal(value, false),
      flex: 1,
      minWidth: 20,
    },
    {
      headerName: capitalize(t('percentageOfTotal')),
      field: 'percentageOfTotal',
      renderCell: ({ value }) => <PercentageSpan value={formatPercentage(value)} />,
      flex: 1,
      minWidth: 20,
    },
  ];

  const total = loyaltyRanks?.items.reduce((accumulator, currentValue) => accumulator + currentValue.numberOfUsers, 0);
  const rows: GridRowsProp =
    loyaltyRanks?.items && total
      ? loyaltyRanks?.items.map((item, i) => {
          return {
            ...item,
            id: i,
            percentageOfTotal: item.numberOfUsers / total,
          };
        })
      : [];

  return (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <PermissionWrapper
          errorMessage={t('loyaltyReporting.distribution.permissions.fetchError')}
          isError={isError}
          isLoading={isLoading}
          permission={OnlineCasinoPermissions.getLoyaltyRankDistributions}
        >
          <DataGridContainer>
            <LimitedDataGrid autoHeight columns={headers} rows={rows} hideFooter />
          </DataGridContainer>
        </PermissionWrapper>
      </CardContent>
    </Card>
  );
};

export default memo(Ranks);
