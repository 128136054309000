import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetMatches } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import {
  Box,
  Button,
  capitalize,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  MenuItem,
  Select,
} from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, Link, PermissionWrapper, StyledDataGrid } from '@greenisland-common/components/atoms';

const DEFAULT_PAGE_SIZE = 10;

interface Props {
  id: string;
}

const OpenKYCMatches = ({ id }: Props) => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [pageTokens, setPageTokens] = useState<(string | undefined)[]>([undefined]);

  const { data, isLoading, isError } = useGetMatches(
    parseInt(id, 10),
    { pageSize, ...(pageTokens[page] && { nextToken: pageTokens[page] }) },
    { query: { enabled: !!id } }
  );

  const headers: GridColumns = [
    {
      headerName: capitalize(t('status')),
      field: 'currentStatus',
      flex: 0.2,
      minWidth: 80,
    },
    {
      headerName: capitalize(t('name')),
      field: 'name',
      flex: 0.2,
      minWidth: 100,
    },
    {
      headerName: t('kyc.headers.countries'),
      field: 'countries',
      renderCell: ({ value }) => <span>{value.join(', ')}</span>,
      flex: 0.3,
      minWidth: 100,
    },
    {
      headerName: t('kyc.headers.score'),
      field: 'score',
      flex: 0.3,
      minWidth: 100,
    },
  ];

  const rows: GridRowsProp =
    data?.results?.map((item, index) => {
      return {
        name: item?.name,
        countries: item?.countries,
        score: item?.score,
        currentStatus: item?.currentStatus,
        id: index,
      };
    }) || [];

  const handlePageChange = useCallback(
    (newPage: number) => {
      if (newPage > page && newPage === pageTokens.length) {
        pageTokens.push(data?.nextToken);
        setPageTokens([...pageTokens]);
      }
      setPage(newPage);
    },
    [data?.nextToken, page, pageTokens]
  );

  const renderFooterPaginationHandler = useCallback(() => {
    if (data?.results && data?.results?.length < pageSize) return null;
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center" my={1}>
        <Select size="small" value={pageSize} onChange={e => setPageSize(e.target.value as number)}>
          {[10, 25, 50, 100].map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <Box>
          <Button onClick={() => handlePageChange(page - 1)} disabled={page === 0} size="small">
            {t('previous')}
          </Button>
          <Button onClick={() => handlePageChange(page + 1)} disabled={!data?.nextToken} size="small">
            {t('next')}
          </Button>
        </Box>
      </Box>
    );
  }, [data?.nextToken, data?.results, handlePageChange, page, pageSize, t]);

  return (
    <Card>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CardHeader title={t('kyc.monitoring.openKYCMatchesTitle')} />
        <Button
          sx={{ mr: 1 }}
          variant="outlined"
          color="primary"
          size="small"
          component={Link}
          {...{
            to: `../../tasks/kyc/profile/${id}`,
          }}
        >
          {t('seeMore')}
        </Button>
      </Box>
      <CardContent sx={{ pt: 0 }}>
        <Box mt={2}>
          <PermissionWrapper
            errorMessage={t('kyc.individual.errors.matches')}
            isError={isError}
            isLoading={isLoading}
            permission={OnlineCasinoPermissions.getMatches}
          >
            <DataGridContainer>
              <StyledDataGrid
                density="compact"
                autoHeight
                rowHeight={70}
                loading={isLoading}
                columns={headers}
                rows={rows}
                disableSelectionOnClick
                components={{
                  Footer: () => renderFooterPaginationHandler(),
                  LoadingOverlay: LinearProgress,
                }}
              />
            </DataGridContainer>
          </PermissionWrapper>
        </Box>
      </CardContent>
    </Card>
  );
};

export default OpenKYCMatches;
