import { useTranslation } from 'react-i18next';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, capitalize, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  code: string;
}

const CodeDialog = ({ isOpen, onClose, code }: Props) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>{capitalize(t('code'))}</DialogTitle>
      <DialogContent sx={{ display: 'flex', alignItems: 'center' }}>
        <strong>{code}</strong>
        <Tooltip title={t('copy') || ''}>
          <Box display="inline" ml={2}>
            <Button variant="contained" onClick={() => navigator.clipboard.writeText(code)}>
              <FontAwesomeIcon icon={faCopy} />
            </Button>
          </Box>
        </Tooltip>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={onClose}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CodeDialog;
