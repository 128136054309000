import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTenantContext } from '../../../../../../app/context/TenantContext';

export enum PlayingBreakRequestType {
  CANCEL = 'cancel',
  REMOVE = 'remove',
}

export function useGetCooldownValues() {
  const { t } = useTranslation();
  const { playingBreakConfigurations } = useTenantContext();

  return useMemo(
    () => [
      {
        value: 0,
        label: t('playingbreak.cancelImmediately'),
      },
      ...(playingBreakConfigurations.timeoutDays !== 0
        ? [
            {
              value: playingBreakConfigurations.timeoutDays,
              label: t('playingbreak.cancelInDays', { days: playingBreakConfigurations.timeoutDays }),
            },
          ]
        : []),
    ],
    [playingBreakConfigurations.timeoutDays, t]
  );
}
