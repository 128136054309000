/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  DefaultResponse,
  ForbiddenResponse,
  GetStoredEmailTemplatesParams,
  PagedTrustpilotEmailTemplatesSchema,
  TrustpilotEmailSyncBadRequestResponse,
  TrustpilotInternalServerErrorResponse,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Sync the email templates defined in the Trustpilot dashboard
 */
export const useSyncEmailTemplatesHook = () => {
  const syncEmailTemplates = useCustomInstance<void>();

  return () => {
    return syncEmailTemplates({ url: `/trustpilot/sync-email-templates`, method: 'post' });
  };
};

export const useSyncEmailTemplatesMutationOptions = <
  TError =
    | TrustpilotEmailSyncBadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | TrustpilotInternalServerErrorResponse,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSyncEmailTemplatesHook>>>,
    TError,
    TVariables,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSyncEmailTemplatesHook>>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const syncEmailTemplates = useSyncEmailTemplatesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSyncEmailTemplatesHook>>>,
    TVariables
  > = () => {
    return syncEmailTemplates();
  };

  return { mutationFn, ...mutationOptions };
};

export type SyncEmailTemplatesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSyncEmailTemplatesHook>>>
>;

export type SyncEmailTemplatesMutationError =
  | TrustpilotEmailSyncBadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | TrustpilotInternalServerErrorResponse;

/**
 * @summary Sync the email templates defined in the Trustpilot dashboard
 */
export const useSyncEmailTemplates = <
  TError =
    | TrustpilotEmailSyncBadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | TrustpilotInternalServerErrorResponse,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSyncEmailTemplatesHook>>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const mutationOptions = useSyncEmailTemplatesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the Trustpilot email templates that are stored.
 */
export const useGetStoredEmailTemplatesHook = () => {
  const getStoredEmailTemplates = useCustomInstance<PagedTrustpilotEmailTemplatesSchema>();

  return (params?: GetStoredEmailTemplatesParams, signal?: AbortSignal) => {
    return getStoredEmailTemplates({ url: `/trustpilot/email-templates`, method: 'get', params, signal });
  };
};

export const getGetStoredEmailTemplatesQueryKey = (params?: GetStoredEmailTemplatesParams) =>
  [`/trustpilot/email-templates`, ...(params ? [params] : [])] as const;

export const useGetStoredEmailTemplatesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetStoredEmailTemplatesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetStoredEmailTemplatesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStoredEmailTemplatesHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStoredEmailTemplatesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStoredEmailTemplatesQueryKey(params);

  const getStoredEmailTemplates = useGetStoredEmailTemplatesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetStoredEmailTemplatesHook>>>> = ({ signal }) =>
    getStoredEmailTemplates(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetStoredEmailTemplatesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetStoredEmailTemplatesHook>>>
>;
export type GetStoredEmailTemplatesQueryError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Get the Trustpilot email templates that are stored.
 */
export const useGetStoredEmailTemplates = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetStoredEmailTemplatesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetStoredEmailTemplatesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStoredEmailTemplatesHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetStoredEmailTemplatesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
