import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  ERegulatorControllerBadRequestTypeSchema,
  getGetCriticalFieldsMismatchQueryKey,
  useResolveCriticalFieldsMismatch,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import { Box, capitalize, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';

import { CRITICAL_FIELDS_MISMATCH_ERROR_REASONS } from './Constants/CriticalFieldsMismatchErrorReasons';
import { CriticalMismatchField, CriticalMismatchFieldMode } from './Constants';

interface Props {
  userId?: number;
  fieldInfo: CriticalMismatchField;
  setChosenOption?: Dispatch<SetStateAction<CriticalMismatchField | null>>;
  mode?: CriticalMismatchFieldMode;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}

const CriticalFieldsMismatchChoiceSection = ({ userId, fieldInfo, setChosenOption, mode, setOpen }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const { mutate: resolveResolveCriticalFieldsMismatch, isLoading } = useResolveCriticalFieldsMismatch({
    mutation: {
      onSuccess: () => {
        if (userId) {
          queryClient.invalidateQueries(getGetCriticalFieldsMismatchQueryKey(userId));
          enqueueSnackbar(t('success'), { variant: 'success' });
          setOpen?.(false);
        }
      },
      onError: error => {
        if (error) {
          if ('type' in error && error.type) {
            const errorReason: ERegulatorControllerBadRequestTypeSchema | undefined = error.type;
            const translationKey = errorReason
              ? CRITICAL_FIELDS_MISMATCH_ERROR_REASONS[errorReason]
              : 'somethingWentWrong';
            enqueueSnackbar(t(translationKey), { variant: 'error' });
          } else {
            enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
          }
        }
      },
    },
  });

  const submitHandler = async () => {
    if (mode === CriticalMismatchFieldMode.CONFIRM && userId) {
      resolveResolveCriticalFieldsMismatch({
        userId: Number(userId),
        data: { direction: fieldInfo.direction },
      });
    } else {
      if (setChosenOption) {
        setChosenOption(() => ({
          ...fieldInfo,
          mainTitle: fieldInfo.warningMessage,
        }));
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} minWidth={'220px'}>
      <Typography
        variant="h5"
        fontWeight={600}
        fontSize="medium"
        mb={1}
        color={mode === CriticalMismatchFieldMode.CONFIRM ? theme.palette.error.main : theme.palette.text.primary}
      >
        {capitalize(t(fieldInfo?.mainTitle || ''))}
      </Typography>
      <Typography variant="body1">
        {`${capitalize(t('firstName'))}: `}
        <Box component="span" fontWeight="500">
          {fieldInfo?.firstName}
        </Box>
      </Typography>
      <Typography variant="body1">
        {`${capitalize(t('lastName'))}: `}
        <Box component="span" fontWeight="500">
          {fieldInfo?.lastName}
        </Box>
      </Typography>
      <Typography variant="body1">
        {`${capitalize(t('dateOfBirth'))}: `}
        <Box component="span" fontWeight="500">
          {fieldInfo?.dateOfBirth}
        </Box>
      </Typography>
      <LoadingButton
        disabled={isLoading}
        loading={isLoading}
        variant="contained"
        size="medium"
        color="success"
        onClick={submitHandler}
        sx={{ maxWidth: '200px', mt: 2 }}
      >
        {t(mode === CriticalMismatchFieldMode.CONFIRM ? 'confirm' : 'common.thisIsCorrect')}
      </LoadingButton>
    </Box>
  );
};

export default CriticalFieldsMismatchChoiceSection;
