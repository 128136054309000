import { faGears } from '@fortawesome/free-solid-svg-icons';
import ProviderConfiguration from 'src/pages/ProviderService/ProviderConfiguration';

import Providers from '../../pages/ProviderService/Providers';
import { BaseRoute } from '../RouteInterfaces';

export const providerServiceRoutes: Array<BaseRoute> = [
  {
    path: '*',
    name: 'Providers',
    element: Providers,
    hiddenLink: true,
  },
  {
    path: 'providers',
    name: 'Providers',
    element: Providers,
    icon: faGears,
  },
  {
    path: 'providers/:providerIntegrationCode',
    name: 'Provider configuration',
    element: ProviderConfiguration,
    hiddenLink: true,
  },
];

export default providerServiceRoutes;
