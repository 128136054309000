/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * Agent task bad request type:
 * `agentTaskDoesNotExist` - Requested agent task ID is invalid or not linked to an existing agent task.
 * `invalidAgentTaskId` - The provided agent task ID has an invalid format.
 * `noResolutionReasonProvided` - When resolving an agent task, a non-empty resolution reason must be provided.
 * `resolutionReasonTooLong` - The provided resolution reason is too long. The maximum length is 250 characters.
 * `agentTaskNotManuallyResolvable` - Requested agent task ID is not manually resolvable.

 */
export type EAgentTaskBadRequestTypeSchema =
  (typeof EAgentTaskBadRequestTypeSchema)[keyof typeof EAgentTaskBadRequestTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EAgentTaskBadRequestTypeSchema = {
  agentTaskDoesNotExist: 'agentTaskDoesNotExist',
  invalidAgentTaskId: 'invalidAgentTaskId',
  noResolutionReasonProvided: 'noResolutionReasonProvided',
  resolutionReasonTooLong: 'resolutionReasonTooLong',
  agentTaskNotManuallyResolvable: 'agentTaskNotManuallyResolvable',
} as const;
