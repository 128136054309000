import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GameServerPermissions } from '@greenisland-core/permissions/GameServerPermissions';
import { GetJackpotInstancesParams, JackpotInstance, useGetJackpotInstances } from '@greenisland-store/gameServer';
import { Autocomplete, Box, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import { DataGridProProps, GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';

import {
  CheckboxIcon,
  DataGridContainer,
  DataGridPagination,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { formatCurrency, getDateTimeFromUnix } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';
import JackpotInstanceDetail from './JackpotInstanceDetail';

const PAGE_SIZE = 25;

type Row = JackpotInstance;

const JackpotsInstances = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [jackpotLevelFilter, setJackpotLevelFilter] = useState<number | null>(null);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const canReadJackpotInstance = usePermission(GameServerPermissions.getJackpotInstances);
  const levels = [1, 2, 3];

  const params: GetJackpotInstancesParams = { pageSize: pageSize, page: page + 1 };
  if (jackpotLevelFilter != null) params.jackpotLevel = jackpotLevelFilter;

  const {
    data: JackpotsInstances,
    isLoading,
    isError,
  } = useGetJackpotInstances(params, { query: { enabled: canReadJackpotInstance, keepPreviousData: true } });

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'active',
        headerName: t('content.gameserver.jackpotInstance.active'),
        type: 'boolean',
        flex: 0.1,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        sortable: false,
      },
      {
        field: 'jackpotLevel',
        headerName: t('content.gameserver.jackpotInstance.jackpotLevel'),
        sortable: false,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'seed',
        headerName: t('content.gameserver.jackpotInstance.seed'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return (
            <Typography variant="body2">{formatCurrency(params.row.seed / 100, params.row.baseCurrency)}</Typography>
          );
        },
      },
      {
        field: 'currentValue',
        headerName: t('content.gameserver.jackpotInstance.currentValue'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return (
            <Typography variant="body2">
              {formatCurrency(params.row.currentValue / 100, params.row.baseCurrency)}
            </Typography>
          );
        },
      },
      {
        field: 'targetValue',
        headerName: t('content.gameserver.jackpotInstance.targetValue'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return (
            <Typography variant="body2">
              {formatCurrency(params.row.targetValue / 100, params.row.baseCurrency)}
            </Typography>
          );
        },
      },
      {
        field: 'jackpotContributionsCheckpoint.startTime',
        headerName: t('content.gameserver.jackpotInstance.startTime'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          const formattedToDate = params.row.startTime;
          if (formattedToDate) {
            const formattedDaysDate = getDateTimeFromUnix(formattedToDate).split(' ')[0];
            const formattedHoursDate = getDateTimeFromUnix(formattedToDate).split(' ')[1];
            return (
              <Box>
                <Typography variant="body2">{formattedDaysDate}</Typography>
                <Typography variant="body2" color="GrayText">
                  {formattedHoursDate}
                </Typography>
              </Box>
            );
          }
          return <Typography variant="body2">-</Typography>;
        },
      },
      {
        field: 'jackpotContributionsCheckpoint.endTime',
        headerName: t('content.gameserver.jackpotInstance.endTime'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          const formattedToDate = params.row.endTime;
          if (formattedToDate) {
            const formattedDaysDate = getDateTimeFromUnix(formattedToDate).split(' ')[0];
            const formattedHoursDate = getDateTimeFromUnix(formattedToDate).split(' ')[1];
            return (
              <Box>
                <Typography variant="body2">{formattedDaysDate}</Typography>
                <Typography variant="body2" color="GrayText">
                  {formattedHoursDate}
                </Typography>
              </Box>
            );
          }
          return <Typography variant="body2">-</Typography>;
        },
      },
      {
        field: 'jackpotContributionsCheckpoint.startCheckpoint',
        headerName: t('content.gameserver.jackpotInstance.firstContributingBetTx'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.row.jackpotContributionsCheckpoint.startCheckpoint}</Typography>;
        },
      },
      {
        field: 'jackpotContributionsCheckpoint.endCheckpoint',
        headerName: t('content.gameserver.jackpotInstance.lastContributingBetTx'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.row.jackpotContributionsCheckpoint.endCheckpoint}</Typography>;
        },
      },
      {
        field: 'jackpotWin.winAmount',
        headerName: t('content.gameserver.jackpotInstance.jackpotWin.winAmount'),
        sortable: false,
        flex: 0.1,
        renderCell: params => {
          return (
            <Typography variant="body2">
              {params?.row?.jackpotWin
                ? formatCurrency((params.row.jackpotWin?.winAmount ?? 0) / 100, params.row.baseCurrency)
                : '-'}
            </Typography>
          );
        },
      },
    ],
    [t]
  );

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback(
    ({ row }: { row: JackpotInstance }) => <JackpotInstanceDetail jackpotInstance={row}></JackpotInstanceDetail>,
    []
  );

  const getDetailPanelHeight = useCallback((): 'auto' => 'auto', []);

  const rows = useMemo(
    () =>
      JackpotsInstances?.entries?.map<Row>(JackpotsInstance => ({
        ...JackpotsInstance,
      })) ?? [],
    [JackpotsInstances?.entries]
  );

  return (
    <Stack spacing={2}>
      {!isError ? (
        <>
          <Autocomplete
            id="jackpotLevelFilter"
            options={levels}
            getOptionLabel={option => option.toString()}
            renderInput={params => (
              <TextField {...params} size="small" label={t('content.gameserver.jackpotInstance.jackpotLevelFilter')} />
            )}
            value={jackpotLevelFilter}
            onChange={(e, value) => {
              setJackpotLevelFilter(value);
            }}
          />
          <DataGridContainer>
            <StyledDataGrid
              density="compact"
              autoHeight
              rowHeight={80}
              loading={isLoading}
              rows={rows}
              columns={columns}
              pagination
              page={page}
              rowCount={JackpotsInstances?.totalNumberOfEntries}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
              disableDensitySelector
              disableSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
              disableChildrenSorting
              disableChildrenFiltering
              disableMultipleColumnsSorting
              disableColumnResize
              disableColumnReorder
              isRowSelectable={() => false}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
        </>
      ) : (
        <ErrorState errorMessage="error" />
      )}
    </Stack>
  );
};

export default JackpotsInstances;
