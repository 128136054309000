import { Dispatch, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  getGetUserLoyaltyDetailsQueryKey,
  LoyaltyPointsArgumentsSchema,
  useAwardLoyaltyPointsToUser,
} from '@greenisland-api';
import { Button, capitalize, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

import { isValidNumber } from '@greenisland-common/helpers/utils';

import { useLoyaltyUserErrorHandling } from './Loyalty/Hooks/useLoyaltyUserErrorHandling';

interface Props {
  open: boolean;
  setOpen: Dispatch<boolean>;
  userId: number;
}

const AwardLoyaltyPointsDialog = ({ open, setOpen, userId }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const handleError = useLoyaltyUserErrorHandling();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (open) setLoading(false);
  }, [open]);
  const awardLoyaltyPointsMutation = useAwardLoyaltyPointsToUser({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetUserLoyaltyDetailsQueryKey(userId));
        enqueueSnackbar(capitalize(t('success')), { variant: 'success' });
        setOpen(false);
      },
      onError: error => {
        handleError(error);
        setLoading(false);
      },
    },
  });

  const { register, errors, handleSubmit, getValues, trigger } = useForm<LoyaltyPointsArgumentsSchema>();

  const onSubmit = (data: LoyaltyPointsArgumentsSchema) => {
    setLoading(true);
    const transformedData: LoyaltyPointsArgumentsSchema = {
      ...data,
      statusPoints: !isValidNumber(data.statusPoints) ? 0 : data.statusPoints,
      spendableStatusPoints: !isValidNumber(data.spendableStatusPoints) ? 0 : data.spendableStatusPoints,
    };
    awardLoyaltyPointsMutation.mutate({ userId, data: transformedData });
  };

  // To prevent recursion
  const triggerRef = useRef(false);

  const validateAtLeastOneValueGreaterThanZero = async () => {
    if (!triggerRef.current) {
      triggerRef.current = true;
      try {
        const fieldNames: Array<keyof LoyaltyPointsArgumentsSchema> = ['statusPoints', 'spendableStatusPoints'];
        await Promise.all(fieldNames.map(name => trigger(name)));
      } finally {
        triggerRef.current = false;
      }
    }

    const { statusPoints, spendableStatusPoints } = getValues();
    const validStatusPoints = isValidNumber(statusPoints) && statusPoints > 0;
    const validSpendableStatusPoints = isValidNumber(spendableStatusPoints) && spendableStatusPoints > 0;
    return validStatusPoints || validSpendableStatusPoints || t('atLeastOneFieldMustBeGreaterThanZero');
  };

  return (
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{capitalize(t('awardLoyaltyPoints'))}</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            fullWidth
            name="statusPoints"
            label={t('statusPoints')}
            type="number"
            inputRef={register({
              valueAsNumber: true,
              min: {
                value: 0,
                message: t('fieldMustBeNonNegative'),
              },
              validate: validateAtLeastOneValueGreaterThanZero,
            })}
            error={!!errors.statusPoints}
            helperText={errors.statusPoints ? errors.statusPoints.message : ''}
          />
          <TextField
            sx={{ mt: 2 }}
            size="small"
            fullWidth
            name="spendableStatusPoints"
            label={t('spendablePoints')}
            type="number"
            inputRef={register({
              valueAsNumber: true,
              min: {
                value: 0,
                message: t('fieldMustBeNonNegative'),
              },
              validate: validateAtLeastOneValueGreaterThanZero,
            })}
            error={!!errors.spendableStatusPoints}
            helperText={errors.spendableStatusPoints ? errors.spendableStatusPoints.message : ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="error" variant="outlined">
            {t('close')}
          </Button>
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            {t('award')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AwardLoyaltyPointsDialog;
