/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  GameRoundsPaginated,
  PlayersPaginated,
  QueryActivePlayersParams,
  QueryGameRoundsForPlayerParams,
  QueryPlayersParams,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Query players
 * @summary Query players
 */
export const useQueryPlayersHook = () => {
  const queryPlayers = useCustomInstance<PlayersPaginated>();

  return (params?: QueryPlayersParams, signal?: AbortSignal) => {
    return queryPlayers({ url: `/players`, method: 'get', params, signal });
  };
};

export const getQueryPlayersQueryKey = (params?: QueryPlayersParams) =>
  [`/players`, ...(params ? [params] : [])] as const;

export const useQueryPlayersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useQueryPlayersHook>>>,
  TError = unknown
>(
  params?: QueryPlayersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryPlayersHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryPlayersHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQueryPlayersQueryKey(params);

  const queryPlayers = useQueryPlayersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useQueryPlayersHook>>>> = ({ signal }) =>
    queryPlayers(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type QueryPlayersQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useQueryPlayersHook>>>>;
export type QueryPlayersQueryError = unknown;

/**
 * @summary Query players
 */
export const useQueryPlayers = <TData = Awaited<ReturnType<ReturnType<typeof useQueryPlayersHook>>>, TError = unknown>(
  params?: QueryPlayersParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryPlayersHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useQueryPlayersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get list of game rounds for player
 * @summary Get list of game rounds for player
 */
export const useQueryGameRoundsForPlayerHook = () => {
  const queryGameRoundsForPlayer = useCustomInstance<GameRoundsPaginated>();

  return (playerExternalId: string, params: QueryGameRoundsForPlayerParams, signal?: AbortSignal) => {
    return queryGameRoundsForPlayer({
      url: `/players/${playerExternalId}/cash-games/game-rounds`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getQueryGameRoundsForPlayerQueryKey = (playerExternalId: string, params: QueryGameRoundsForPlayerParams) =>
  [`/players/${playerExternalId}/cash-games/game-rounds`, ...(params ? [params] : [])] as const;

export const useQueryGameRoundsForPlayerQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useQueryGameRoundsForPlayerHook>>>,
  TError = unknown
>(
  playerExternalId: string,
  params: QueryGameRoundsForPlayerParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryGameRoundsForPlayerHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryGameRoundsForPlayerHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQueryGameRoundsForPlayerQueryKey(playerExternalId, params);

  const queryGameRoundsForPlayer = useQueryGameRoundsForPlayerHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useQueryGameRoundsForPlayerHook>>>> = ({
    signal,
  }) => queryGameRoundsForPlayer(playerExternalId, params, signal);

  return { queryKey, queryFn, enabled: !!playerExternalId, ...queryOptions };
};

export type QueryGameRoundsForPlayerQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useQueryGameRoundsForPlayerHook>>>
>;
export type QueryGameRoundsForPlayerQueryError = unknown;

/**
 * @summary Get list of game rounds for player
 */
export const useQueryGameRoundsForPlayer = <
  TData = Awaited<ReturnType<ReturnType<typeof useQueryGameRoundsForPlayerHook>>>,
  TError = unknown
>(
  playerExternalId: string,
  params: QueryGameRoundsForPlayerParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryGameRoundsForPlayerHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useQueryGameRoundsForPlayerQueryOptions(playerExternalId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Query active players
 * @summary Query active players during period
 */
export const useQueryActivePlayersHook = () => {
  const queryActivePlayers = useCustomInstance<PlayersPaginated>();

  return (params?: QueryActivePlayersParams, signal?: AbortSignal) => {
    return queryActivePlayers({ url: `/active-players`, method: 'get', params, signal });
  };
};

export const getQueryActivePlayersQueryKey = (params?: QueryActivePlayersParams) =>
  [`/active-players`, ...(params ? [params] : [])] as const;

export const useQueryActivePlayersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useQueryActivePlayersHook>>>,
  TError = unknown
>(
  params?: QueryActivePlayersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryActivePlayersHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryActivePlayersHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQueryActivePlayersQueryKey(params);

  const queryActivePlayers = useQueryActivePlayersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useQueryActivePlayersHook>>>> = ({ signal }) =>
    queryActivePlayers(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type QueryActivePlayersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useQueryActivePlayersHook>>>
>;
export type QueryActivePlayersQueryError = unknown;

/**
 * @summary Query active players during period
 */
export const useQueryActivePlayers = <
  TData = Awaited<ReturnType<ReturnType<typeof useQueryActivePlayersHook>>>,
  TError = unknown
>(
  params?: QueryActivePlayersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryActivePlayersHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useQueryActivePlayersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
