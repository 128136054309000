/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EAgentTaskTypeSchema = (typeof EAgentTaskTypeSchema)[keyof typeof EAgentTaskTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EAgentTaskTypeSchema = {
  Unknown: 'Unknown',
  BGCRegistrationActionInterventionNeeded: 'BGCRegistrationActionInterventionNeeded',
  BGCArticle6ActionInterventionNeeded: 'BGCArticle6ActionInterventionNeeded',
  BeChargeOrderFailed: 'BeChargeOrderFailed',
  DepositRefused: 'DepositRefused',
  DepositRefundFailed: 'DepositRefundFailed',
  DepositChargedBack: 'DepositChargedBack',
  KSACloseAccountInterventionNeeded: 'KSACloseAccountInterventionNeeded',
  CRUKSInterventionNeeded: 'CRUKSInterventionNeeded',
  InterventionFailed: 'InterventionFailed',
  PlayingBreakCancellationRequested: 'PlayingBreakCancellationRequested',
  SuspiciousDeposit: 'SuspiciousDeposit',
  KYCRiskDetected: 'KYCRiskDetected',
  WithdrawalFailed: 'WithdrawalFailed',
  BGCCriticalFieldsMismatch: 'BGCCriticalFieldsMismatch',
  KSAAddictionPreventionPlayingBreakCanBeCancelled: 'KSAAddictionPreventionPlayingBreakCanBeCancelled',
  DGOJCloseAccountInterventionNeeded: 'DGOJCloseAccountInterventionNeeded',
} as const;
