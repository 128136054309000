import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AcurisIndividualProfileSchema } from '@greenisland-api';
import {
  capitalize,
  Card,
  CardHeader,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

interface Props {
  data: AcurisIndividualProfileSchema | undefined;
}

const KYCContactDetailsCard = ({ data }: Props) => {
  const [addressInfoExpanded, setAddressInfoExpanded] = useState(true);
  const { t } = useTranslation();

  if (
    (!data?.addresses || data.addresses.length === 0) &&
    (!data?.contactEntries || data.contactEntries.length === 0)
  ) {
    return null;
  }

  return (
    <Card
      sx={{ backgroundColor: 'white' }}
      elevation={3}
      style={{ padding: '20px', marginBottom: '20px', marginTop: '20px' }}
    >
      <CardHeader
        title={t('kyc.resource.contactInfo')}
        action={
          <IconButton aria-expanded={addressInfoExpanded} onClick={() => setAddressInfoExpanded(!addressInfoExpanded)}>
            <FontAwesomeIcon icon={addressInfoExpanded ? faCaretUp : faCaretDown} />
          </IconButton>
        }
      />
      <Collapse in={addressInfoExpanded} timeout="auto" unmountOnExit>
        {data?.addresses && data.addresses.length > 0 && (
          <TableContainer sx={{ backgroundColor: 'white', marginBottom: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('kyc.headers.addressType')}</TableCell>
                  <TableCell>{capitalize(t('country'))}</TableCell>
                  <TableCell>{capitalize(t('address'))}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.addresses.map((address, index) => (
                  <TableRow key={index}>
                    <TableCell>{address.addressType}</TableCell>
                    <TableCell>{address.country}</TableCell>
                    <TableCell>
                      {[address.county || address.countyAbbreviation, address.line1, address.line2, address.postcode]
                        .filter(Boolean)
                        .join(', ')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {data?.contactEntries && data.contactEntries.length > 0 && (
          <TableContainer sx={{ backgroundColor: 'white' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('kyc.headers.contactInfoType')}</TableCell>
                  <TableCell>{t('kyc.headers.value')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.contactEntries.map((entry, index) => (
                  <TableRow key={index}>
                    <TableCell>{entry.category}</TableCell>
                    <TableCell>{entry.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Collapse>
    </Card>
  );
};

export default memo(KYCContactDetailsCard);
