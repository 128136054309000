import { EGameLobbyTypeSchema, GameLobbyArgumentsSchema, GameLobbySchema } from '@greenisland-api';

export interface GameLobbyForm
  extends Pick<
    GameLobbySchema,
    | 'name'
    | 'urlName'
    | 'imageUrl'
    | 'backgroundImageUrl'
    | 'thumbnailLandscapeUrl'
    | 'thumbnailPortraitUrl'
    | 'thumbnailSquareUrl'
    | 'enabled'
    | 'desktopExternalGameLobbyId'
    | 'mobileExternalGameLobbyId'
    | 'configuration'
  > {
  gameProviderId: number | null;
  gameProviderName: string | undefined;
  gameStudioId: number | null;
  gameStudioName: string | undefined;
  lobbyType: EGameLobbyTypeSchema | string;
}

export const defaultGameLobbyFormValue: GameLobbySchema = {
  id: 0,
  name: '',
  urlName: '',
  gameProviderId: 0,
  gameProviderName: '',
  gameStudioId: 0,
  gameStudioName: '',
  listOrder: 0,
  enabled: false,
  lobbyType: EGameLobbyTypeSchema.live,
  desktopExternalGameLobbyId: '',
  mobileExternalGameLobbyId: '',
  configuration: '',
  imageUrl: '',
  backgroundImageUrl: '',
  thumbnailLandscapeUrl: '',
  thumbnailPortraitUrl: '',
  thumbnailSquareUrl: '',
};

export const getDefaultGameLobbyFormValue = (
  gameLobby: GameLobbyArgumentsSchema,
  gameProviderName: string | undefined,
  gameStudioName: string | undefined
): GameLobbyForm => ({
  ...gameLobby,
  name: gameLobby.name,
  urlName: gameLobby.urlName,
  gameProviderId: gameLobby.gameProviderId,
  gameProviderName: gameProviderName ?? '',
  gameStudioId: gameLobby.gameStudioId,
  gameStudioName: gameStudioName ?? '',
  enabled: gameLobby.enabled,
  lobbyType: gameLobby.lobbyType ?? EGameLobbyTypeSchema.live,
  desktopExternalGameLobbyId: gameLobby.desktopExternalGameLobbyId,
  mobileExternalGameLobbyId: gameLobby.mobileExternalGameLobbyId,
  configuration: gameLobby.configuration ?? '',
  imageUrl: gameLobby.imageUrl ?? '',
  backgroundImageUrl: gameLobby.backgroundImageUrl ?? '',
  thumbnailLandscapeUrl: gameLobby.thumbnailLandscapeUrl ?? '',
  thumbnailPortraitUrl: gameLobby.thumbnailPortraitUrl ?? '',
  thumbnailSquareUrl: gameLobby.thumbnailSquareUrl ?? '',
});

export const gameLobbyFormToRequest = (formData: GameLobbyForm): GameLobbyArgumentsSchema => ({
  ...formData,
  name: formData.name,
  urlName: formData.urlName,
  gameStudioId: formData.gameStudioId ?? -1,
  gameProviderId: formData.gameProviderId ?? -1,
  enabled: formData.enabled,
  desktopExternalGameLobbyId: formData.desktopExternalGameLobbyId,
  mobileExternalGameLobbyId: formData.mobileExternalGameLobbyId,
  configuration: formData.configuration,
  lobbyType: formData.lobbyType as EGameLobbyTypeSchema,
  imageUrl: formData.imageUrl,
  backgroundImageUrl: formData.backgroundImageUrl,
  thumbnailLandscapeUrl: formData.thumbnailLandscapeUrl,
  thumbnailPortraitUrl: formData.thumbnailPortraitUrl,
  thumbnailSquareUrl: formData.thumbnailSquareUrl,
});
