import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize, TextField } from '@mui/material';

interface Props {
  label: string;
  value: string | null;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onRemove: () => void;
  type?: string;
}

const TransactionFilterTextField = ({ label, value, onChange, onRemove, type = 'text' }: Props) => {
  const { t } = useTranslation();

  return (
    <TextField
      fullWidth={true}
      size="small"
      type={type}
      required={false}
      label={capitalize(t(label))}
      value={value || ''}
      onChange={onChange}
      InputProps={{
        inputProps: type === 'number' ? { min: 0 } : {},
        endAdornment: value && (
          <FontAwesomeIcon
            onClick={onRemove}
            style={{
              visibility: value ? 'visible' : 'hidden',
              marginRight: '5px',
              marginLeft: type === 'number' ? '15px' : 'none',
              cursor: 'pointer',
            }}
            icon={faCircleXmark}
          />
        ),
      }}
    />
  );
};

export default TransactionFilterTextField;
