import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';

import { FormProps } from '../useIdentityDefaultValues';

interface Props {
  name: keyof FormProps;
  defaultValue: string;
  isRequired?: boolean;
}

const GenericTextField = ({ name, defaultValue, isRequired = true }: Props) => {
  const { t } = useTranslation();
  const { errors, register } = useFormContext();

  const validationRules = isRequired ? { required: t('fieldIsRequired') } : {};

  return (
    <TextField
      size="small"
      fullWidth
      error={Boolean(errors[name])}
      name={name}
      defaultValue={defaultValue}
      inputRef={register(validationRules)}
    />
  );
};

export default GenericTextField;
