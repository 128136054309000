import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardContent, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { usePermission } from 'src/app/hooks';
import TabMenu, { Tab } from 'src/routes/components/TabMenu';

import { useUpdateSearchParams } from '@greenisland-common/hooks/useUpdateSearchParams';

import LimitRequestsTable from './Tables/LimitRequestsTable';
import AllLimitsTable from './Tables/LimitsTable';
import MaximumLimitsTable from './Tables/MaximumLimitsTable';
import { LimitSearchFilters } from '../Constants/Limits';
import SessionLimitRequestDialog from './SessionLimitRequestDialog';

const UserSessionLimitsPage = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const updateLimitQueryParamsHandler = useUpdateSearchParams();
  const activeTabParams = searchParams.get(LimitSearchFilters.ACTIVE_TAB);
  const activeTab = activeTabParams ? Number(activeTabParams) : 0;

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();

  const canSetLimit = usePermission(OnlineCasinoPermissions.requestLoginSessionLimitUpdate);
  const canSetMax = usePermission(OnlineCasinoPermissions.setMaximumLoginSessionLimit);

  const tabs: Tab[] = [
    {
      title: 'sessionLimit.tabTitle.limits',
      content: <AllLimitsTable userId={userId} startDate={startDate} endDate={endDate} />,
      visible: usePermission(OnlineCasinoPermissions.getLoginSessionLimits),
    },
    {
      title: 'sessionLimit.tabTitle.requests',
      content: <LimitRequestsTable userId={userId} startDate={startDate} endDate={endDate} />,
      visible: usePermission(OnlineCasinoPermissions.getLoginSessionLimitUpdateRequests),
    },
    {
      title: 'sessionLimit.tabTitle.maxLimits',
      content: <MaximumLimitsTable userId={userId} startDate={startDate} endDate={endDate} />,
      visible: usePermission(OnlineCasinoPermissions.getMaximumLoginSessionLimits),
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between" gap={1} flexWrap="wrap">
        <Stack direction="row" spacing={1}>
          <DatePicker
            label={t('startDate')}
            disabled={activeTab === 2}
            onChange={date => {
              setStartDate(date ?? undefined);
            }}
            value={startDate ?? null}
            renderInput={params => <TextField size="small" {...params} />}
            inputFormat="dd/MM/yyyy"
            mask="__/__/____"
          />
          <DatePicker
            disabled={activeTab === 2}
            label={t('endDate')}
            onChange={date => {
              setEndDate(date ?? undefined);
            }}
            value={endDate ?? null}
            renderInput={params => <TextField size="small" {...params} />}
            inputFormat="dd/MM/yyyy"
            mask="__/__/____"
          />
        </Stack>
        <Box>
          {(canSetLimit || canSetMax) && (
            <Button size="small" variant="contained" onClick={() => setOpenDialog(true)}>
              {t('common.setLimit')}
            </Button>
          )}
        </Box>
      </Box>

      <Card sx={{ mt: 2 }}>
        <CardContent>
          <TabMenu tabs={tabs} activeTab={activeTab} updateActiveTabParamsHandler={updateLimitQueryParamsHandler} />
        </CardContent>
      </Card>
      {openDialog && (
        <SessionLimitRequestDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          userId={userId}
          activeTab={activeTab === 2 ? 1 : 0}
        />
      )}
    </>
  );
};

export default memo(UserSessionLimitsPage);
