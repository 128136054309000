import React from 'react';
import { Stack, Typography } from '@mui/material';
import { getUnixTime } from 'date-fns';
import { getDateTimeFromUnix } from 'src/app/helpers/transformFunctions';

interface Props {
  dateTime?: number;
}

const DateTime = ({ dateTime }: Props) => {
  const [date, time] = getDateTimeFromUnix(dateTime ?? getUnixTime(new Date())).split(' ');

  if (!dateTime) {
    return <Stack>-</Stack>;
  }

  return (
    <Stack>
      {date}
      <Typography variant="caption">{time}</Typography>
    </Stack>
  );
};

export default DateTime;
