/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  CasinoSchema,
  CasinosPagedSchema,
  CreateCasinoSchema,
  DefaultResponse,
  GetCasinosV2Params,
  NotFoundResponse,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Get list of casinos (only not archived casinos)
 * @summary Get list of casinos
 */
export const useGetCasinosV2Hook = () => {
  const getCasinosV2 = useCustomInstance<CasinosPagedSchema>();

  return (params?: GetCasinosV2Params, signal?: AbortSignal) => {
    return getCasinosV2({ url: `/casinos`, method: 'get', params, signal });
  };
};

export const getGetCasinosV2QueryKey = (params?: GetCasinosV2Params) =>
  [`/casinos`, ...(params ? [params] : [])] as const;

export const useGetCasinosV2QueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCasinosV2Hook>>>,
  TError = DefaultResponse
>(
  params?: GetCasinosV2Params,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCasinosV2Hook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCasinosV2Hook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCasinosV2QueryKey(params);

  const getCasinosV2 = useGetCasinosV2Hook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCasinosV2Hook>>>> = ({ signal }) =>
    getCasinosV2(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetCasinosV2QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCasinosV2Hook>>>>;
export type GetCasinosV2QueryError = DefaultResponse;

/**
 * @summary Get list of casinos
 */
export const useGetCasinosV2 = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCasinosV2Hook>>>,
  TError = DefaultResponse
>(
  params?: GetCasinosV2Params,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCasinosV2Hook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCasinosV2QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create a new casino
 */
export const useCreateCasinoHook = () => {
  const createCasino = useCustomInstance<CasinoSchema>();

  return (createCasinoSchema: CreateCasinoSchema) => {
    return createCasino({
      url: `/casinos`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createCasinoSchema,
    });
  };
};

export const useCreateCasinoMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateCasinoHook>>>,
    TError,
    { data: CreateCasinoSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateCasinoHook>>>,
  TError,
  { data: CreateCasinoSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createCasino = useCreateCasinoHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateCasinoHook>>>,
    { data: CreateCasinoSchema }
  > = props => {
    const { data } = props ?? {};

    return createCasino(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCasinoMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCreateCasinoHook>>>>;
export type CreateCasinoMutationBody = CreateCasinoSchema;
export type CreateCasinoMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Create a new casino
 */
export const useCreateCasino = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateCasinoHook>>>,
    TError,
    { data: CreateCasinoSchema },
    TContext
  >;
}) => {
  const mutationOptions = useCreateCasinoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Archive  a casino
 */
export const useArchiveCasinoHook = () => {
  const archiveCasino = useCustomInstance<void>();

  return (casinoID: string) => {
    return archiveCasino({ url: `/casinos/${casinoID}/archive`, method: 'patch' });
  };
};

export const useArchiveCasinoMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveCasinoHook>>>,
    TError,
    { casinoID: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useArchiveCasinoHook>>>,
  TError,
  { casinoID: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const archiveCasino = useArchiveCasinoHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useArchiveCasinoHook>>>,
    { casinoID: string }
  > = props => {
    const { casinoID } = props ?? {};

    return archiveCasino(casinoID);
  };

  return { mutationFn, ...mutationOptions };
};

export type ArchiveCasinoMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useArchiveCasinoHook>>>>;

export type ArchiveCasinoMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Archive  a casino
 */
export const useArchiveCasino = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveCasinoHook>>>,
    TError,
    { casinoID: string },
    TContext
  >;
}) => {
  const mutationOptions = useArchiveCasinoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update a casino
 */
export const useUpdateCasinoHook = () => {
  const updateCasino = useCustomInstance<CasinoSchema>();

  return (casinoID: string, createCasinoSchema: CreateCasinoSchema) => {
    return updateCasino({
      url: `/casinos/${casinoID}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: createCasinoSchema,
    });
  };
};

export const useUpdateCasinoMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateCasinoHook>>>,
    TError,
    { casinoID: string; data: CreateCasinoSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateCasinoHook>>>,
  TError,
  { casinoID: string; data: CreateCasinoSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateCasino = useUpdateCasinoHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateCasinoHook>>>,
    { casinoID: string; data: CreateCasinoSchema }
  > = props => {
    const { casinoID, data } = props ?? {};

    return updateCasino(casinoID, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateCasinoMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateCasinoHook>>>>;
export type UpdateCasinoMutationBody = CreateCasinoSchema;
export type UpdateCasinoMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary update a casino
 */
export const useUpdateCasino = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateCasinoHook>>>,
    TError,
    { casinoID: string; data: CreateCasinoSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateCasinoMutationOptions(options);

  return useMutation(mutationOptions);
};
