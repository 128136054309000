import React, { memo } from 'react';
import { Typography } from '@mui/material';

import { prettyPrintJson } from '@greenisland-common/helpers';

interface Props {
  title: string;
  data: any;
}

const KYCPrettyPrintedData = ({ title, data }: Props) => {
  if (!data || (Array.isArray(data) && data.length === 0)) {
    return null;
  }

  return (
    <>
      <Typography>{title}</Typography>
      <pre
        style={{
          whiteSpace: 'pre-wrap',
        }}
        dangerouslySetInnerHTML={{ __html: prettyPrintJson(data, { linkUrls: true }) }}
      />
    </>
  );
};

export default memo(KYCPrettyPrintedData);
