import { memo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import {
  EFinancialClassificationSchema,
  EVerificationStatusSchema,
  FinancialVerificationArgumentsSchema,
  FinancialVerificationSchema,
  getGetUserVerificationsQueryKey,
  useUpdateUserVerificationsFinancialVerificationStatus,
  VerificationStatusArgumentsSchema,
} from '@greenisland-api';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { getStatusColorClass } from 'src/app/helpers/transformFunctions';

import { DataGridPlain, DataGridPlainHeader } from '@greenisland-common/components/atoms';
import { RowContainer } from '@greenisland-common/components/atoms/DataGridPlain/DataGridPlain';

import DateField from '../../../../../../../common/components/molecules/DateInput/DateInput';
import DeclineVerificationDialog from '../DeclineVerificationDialog';
import UploadFinancialVerificationField from './UploadFinancialVerificationField';

interface Props {
  financialVerification: FinancialVerificationSchema;
}

interface FormProps {
  validUntil: Date;
  classification: string;
}

const FinancialVerification = ({ financialVerification }: Props) => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const methods = useForm<FormProps>({
    defaultValues: {
      validUntil: new Date(financialVerification.validUntil ?? ''),
      classification: financialVerification.classification,
    },
  });
  const { handleSubmit, errors, control } = methods;

  const [declineVerificationDialog, setDeclineVerificationDialog] = useState<boolean>(false);

  const isVerified =
    financialVerification.verificationStatus === EVerificationStatusSchema.Declined ||
    financialVerification.verificationStatus === EVerificationStatusSchema.Verified;
  const isUnVerified = financialVerification.verificationStatus === EVerificationStatusSchema.Unverified;
  const isWaitingApproval = financialVerification.verificationStatus === EVerificationStatusSchema.WaitingForApproval;

  const classificationOptions = Object.keys(EFinancialClassificationSchema);

  const { mutate: updateFinancialVerificationMutation, isLoading } =
    useUpdateUserVerificationsFinancialVerificationStatus({
      mutation: {
        onSuccess: async () => {
          queryClient.invalidateQueries(getGetUserVerificationsQueryKey(userId));
          enqueueSnackbar(t('success'), {
            variant: 'success',
          });
        },
      },
    });

  // TODO: These endpoints are deprecated for now (https://bitbucket.org/slotsgames/backofficeclientportal/pull-requests/1226)
  const submit = (data: FormProps) => {
    const formattedData: FinancialVerificationArgumentsSchema = {
      classification: data.classification as keyof typeof EFinancialClassificationSchema,
      // Deprecated
      type: 'Other',
      documentData: {
        type: 'Other',
      },
      validUntil: data.validUntil.getTime() / 1000,
      verificationStatus: EVerificationStatusSchema.Verified,
    };

    updateFinancialVerificationMutation({ userId, data: formattedData });
  };

  const updateVerificationStatus = (values: VerificationStatusArgumentsSchema) =>
    updateFinancialVerificationMutation({
      userId,
      data: {
        ...values,
        type: 'Other',
        documentData: {
          type: 'Other',
        },
      },
    });

  const verificaitonRows = [
    {
      id: 2,
      title: t('financialVerification'),
      value: (
        <UploadFinancialVerificationField
          userId={userId}
          id="financialDocument"
          metadata={financialVerification?.financialDocumentMetadata}
        />
      ),
    },
  ];

  const approvalRows = [
    {
      id: 3,
      title: t('validUntil'),
      value: <DateField name={'validUntil'} />,
    },
    {
      id: 4,
      title: t('Classification'),
      value: (
        <div>
          <Controller
            name="classification"
            render={({ onChange, value }) => (
              <Select
                size="small"
                label=""
                fullWidth
                disabled={isVerified}
                value={value}
                onChange={(e: any) => onChange(e)}
                error={!!errors.classification}
              >
                {classificationOptions.map(option => (
                  <MenuItem value={option} key={`classification_${option}`}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            )}
            rules={{ required: true }}
            control={control as any}
          />
        </div>
      ),
    },
  ];

  const verifiedRows = [
    {
      id: 3,
      title: t('validUntil'),
      value: <>{financialVerification.validUntil}</>,
    },
    {
      id: 4,
      title: t('Classification'),
      value: <>{financialVerification.classification}</>,
    },
  ];
  const headers: GridColumns = [
    { headerName: '', field: 'title', renderCell: ({ value }) => <DataGridPlainHeader value={value} />, width: 220 },
    {
      headerName: '',
      field: 'value',
      renderCell: ({ value }) => <RowContainer>{value}</RowContainer>,
      minWidth: 150,
      flex: 1,
    },
  ];

  const rows: GridRowsProp = [
    {
      id: 1,
      title: t('verificationStatus.title'),
      value: (
        <Typography variant="body2" sx={getStatusColorClass(financialVerification.verificationStatus)}>
          {t(financialVerification.verificationStatus)}
        </Typography>
      ),
    },
    ...(!isUnVerified ? verificaitonRows : []),
    ...(isWaitingApproval ? approvalRows : []),
    ...(isVerified ? verifiedRows : []),
  ];

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submit)}>
          <Card sx={{ mt: 2, pl: 0, pr: 0 }}>
            <CardHeader title={t('financialVerification')} />
            <CardContent>
              <DataGridPlain columns={headers} rows={rows} getRowHeight={() => 'auto'} />
            </CardContent>
            <CardActions>
              {isUnVerified && (
                <Button
                  color="primary"
                  size="medium"
                  variant="contained"
                  type="button"
                  onClick={() =>
                    updateVerificationStatus({ verificationStatus: EVerificationStatusSchema.NeedsVerification })
                  }
                >
                  {isLoading ? <CircularProgress color="info" size={20} /> : t('verify')}
                </Button>
              )}
              {isWaitingApproval && (
                <Button color="primary" size="medium" variant="contained" type="submit" disabled={isLoading}>
                  {isLoading ? <CircularProgress color="info" size={20} /> : t('verify')}
                </Button>
              )}
              {isWaitingApproval && (
                <Button
                  variant="outlined"
                  size="medium"
                  color="error"
                  onClick={() => setDeclineVerificationDialog(true)}
                >
                  {t('decline')}
                </Button>
              )}
              {financialVerification.verificationStatus === EVerificationStatusSchema.Verified && (
                <Button
                  variant="outlined"
                  size="medium"
                  color="error"
                  onClick={() =>
                    updateVerificationStatus({ verificationStatus: EVerificationStatusSchema.NeedsVerification })
                  }
                >
                  {t('revoke')}
                </Button>
              )}
            </CardActions>
          </Card>
        </form>
      </FormProvider>
      <DeclineVerificationDialog
        open={declineVerificationDialog}
        setOpen={() => setDeclineVerificationDialog(false)}
        action={values => updateVerificationStatus(values)}
      />
    </>
  );
};

export default memo(FinancialVerification);
