import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Box, Button, capitalize, Card, CardContent, CardHeader } from '@mui/material';

import PopularGames from '../../Reporting/Games/PopularGames/PopularGames';

const PopularGamesCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card elevation={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CardHeader title={capitalize(t('popularGames'))} />
        <Button
          sx={{ mr: 1 }}
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => navigate('../reporting/games/popular')}
        >
          {t('seeMore')}
        </Button>
      </Box>
      <CardContent sx={{ pt: 0 }}>
        <Box mt={2}>
          <PopularGames isCard={true} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default PopularGamesCard;
