import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { WithdrawalSchema } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button } from '@mui/material';
import {
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';

import { FontAwesomeIcon, Link } from '@greenisland-common/components/atoms';

import { formatCurrency, formatDateTime } from '@greenisland-common/helpers';

import { StatusSpan } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';

const useGetWithdrawalsHeaders = () => {
  const { t, i18n } = useTranslation();
  const canLookupUser = usePermission(OnlineCasinoPermissions.advancedUserSearchV2);

  const columns = useMemo<GridColumns<WithdrawalSchema>>(() => {
    const headers: GridColumns<WithdrawalSchema> = [
      {
        headerName: t('id'),
        field: 'id',
        type: 'string',
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('timestamp'),
        field: 'timestamp',
        type: 'date',
        valueGetter: ({ value }: GridValueGetterParams<number>) => value && new Date(value * 1000),
        valueFormatter: ({ value }: GridValueFormatterParams<Date>) => formatDateTime(value, false),
        flex: 2,
        minWidth: 150,
        description: t('theRequestTime'),
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('amount'),
        field: 'amount',
        type: 'number',
        valueFormatter: ({ value }: GridValueFormatterParams<number>) => formatCurrency(value, i18n.language),
        flex: 1,
        minWidth: 125,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('accountId'),
        field: 'accountId',
        type: 'string',
        flex: 1,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<string, WithdrawalSchema>) => {
          return canLookupUser ? (
            <Link underline="hover" to={`../../users/${params.row.userId}/details`}>
              {params.row.accountId}
            </Link>
          ) : (
            <span>{params.row.accountId}</span>
          );
        },
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('flag'),
        field: 'flag',
        type: 'string',
        flex: 1,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<string, WithdrawalSchema>) => (
          <StatusSpan value={params.row.flag?.toString() ?? ''} />
        ),
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('status'),
        field: 'status',
        type: 'string',
        flex: 2,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<string, WithdrawalSchema>) => (
          <StatusSpan value={params.row.status.toString() ?? ''} />
        ),
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('destination'),
        field: 'destination',
        type: 'string',
        flex: 2,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },

      {
        headerName: t('reference'),
        field: 'reference',
        type: 'string',
        flex: 4,
        minWidth: 250,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'actions',
        headerName: t('details'),
        flex: 2,
        minWidth: 150,
        disableColumnMenu: true,
        disableExport: true,
        disableReorder: true,
        filterable: false,
        hideable: false,
        sortable: false,
        resizable: false,
        renderCell: (params: GridRenderCellParams<string, WithdrawalSchema>) => {
          return (
            <Link to={`../../../users/${params.row.userId}/withdrawals/${params.row.id}`} underline="none">
              <Button variant="text" color="primary">
                <FontAwesomeIcon icon={faEye} />
              </Button>
            </Link>
          );
        },
        headerAlign: 'center',
        align: 'center',
      },
    ];
    return headers;
  }, [t, i18n.language, canLookupUser]);
  return { columns };
};

export default useGetWithdrawalsHeaders;
