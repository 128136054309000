import Lookup from '../../pages/GameServer/Lookup';
import { BaseRoute } from '../RouteInterfaces';

export const lookupRoutes: Array<BaseRoute> = [
  {
    path: 'lookup',
    name: 'Lookup',
    element: Lookup,
    hiddenLink: true,
  },
];

export default lookupRoutes;
