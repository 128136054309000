import { intervalToDuration } from 'date-fns';

export const getGameSessionDurationValue = (startDate: number, endDate: number): string => {
  const { hours, minutes, seconds } = intervalToDuration({
    start: new Date(startDate * 1000),
    end: new Date(endDate * 1000),
  });

  if (hours && hours > 1) {
    return `${hours}h ${minutes ? minutes + 'm' : ''}`;
  } else if (minutes && minutes > 1) {
    return `${minutes}m ${seconds ? seconds + 's' : ''}`;
  } else {
    return `${seconds ? seconds : 0}s`;
  }
};
