import { Fragment, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UseMutateFunction } from 'react-query';
import {
  ErrorSchema,
  FreeSpinArgumentsSchema,
  FreeSpinsGameConfigurationSchema,
  FreeSpinsGamesConfigurationsSchema,
  GameSchema,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, capitalize, CircularProgress, InputAdornment, TextField } from '@mui/material';

import { formatCurrency } from '@greenisland-common/helpers';

interface FreeSpinsCampaignPayload extends Omit<FreeSpinArgumentsSchema, 'game' | 'freeSpinsGameConfigurationFK'> {
  game: GameSchema;
  freeSpinsGameConfigurationFK: FreeSpinsGameConfigurationSchema;
}

interface Props {
  isGameProviderLoading: boolean;
  isUpdateFreeCampaignsLoading: boolean;
  isProviderConfigured: boolean;
  freeSpinsConfigurations?: FreeSpinsGamesConfigurationsSchema;
  areFreeSpinsConfigurationsLoading: boolean;
  areFreeSpinsConfigurationsRefetching: boolean;
  updateFreeSpinsCampaign: UseMutateFunction<void, ErrorSchema, { gameId: number }>;
}

const FreeSpinsConfigurationsFields = ({
  isGameProviderLoading,
  isUpdateFreeCampaignsLoading,
  areFreeSpinsConfigurationsLoading,
  areFreeSpinsConfigurationsRefetching,
  isProviderConfigured,
  freeSpinsConfigurations,
  updateFreeSpinsCampaign,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { control, register, errors, watch } = useFormContext<FreeSpinsCampaignPayload>();

  const isDataFetching =
    isGameProviderLoading || areFreeSpinsConfigurationsLoading || areFreeSpinsConfigurationsRefetching;

  const watchChosenGame: GameSchema = watch('game');

  const configurationsForSelectedGame = useMemo(() => {
    return (
      freeSpinsConfigurations?.configurations?.filter(
        configurationsForGame =>
          configurationsForGame.gameId === watchChosenGame?.activeGameConfiguration?.gameId ||
          configurationsForGame.gameId === watchChosenGame?.activeGameConfiguration?.mobileExternalGameId
      )[0]?.freeSpinsConfigurations || []
    );
  }, [
    freeSpinsConfigurations?.configurations,
    watchChosenGame?.activeGameConfiguration?.gameId,
    watchChosenGame?.activeGameConfiguration?.mobileExternalGameId,
  ]);

  if (!isProviderConfigured && !isGameProviderLoading && !areFreeSpinsConfigurationsLoading) {
    return (
      <TextField
        fullWidth
        sx={{ mt: 2 }}
        size="small"
        type="text"
        label={t('stakePerSpin')}
        name="stakePerSpin"
        inputRef={register({
          required: true,
          valueAsNumber: true,
          pattern: { value: /^[0-9]+((.|,)[0-9]{1,2})?$/, message: t('amountValidationMessageDecimals') },
          min: { value: 0.01, message: t('amountValidationMessageZero') },
        })}
        error={Boolean(errors.stakePerSpin)}
        disabled={isGameProviderLoading}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isGameProviderLoading || areFreeSpinsConfigurationsLoading ? <CircularProgress size={20} /> : null}
            </InputAdornment>
          ),
        }}
      />
    );
  }

  return (
    <>
      <Controller
        control={control}
        name="freeSpinsGameConfigurationFK"
        render={({ onChange, value, ...props }) => (
          <Autocomplete
            sx={{ mt: 2 }}
            disabled={!watchChosenGame || isDataFetching || isUpdateFreeCampaignsLoading}
            id="freeSpinsGameConfiguration"
            getOptionLabel={(option: FreeSpinsGameConfigurationSchema) =>
              formatCurrency(option.stakePerSpin, i18n.language)
            }
            isOptionEqualToValue={(option: FreeSpinsGameConfigurationSchema, value: FreeSpinsGameConfigurationSchema) =>
              option.configurationId === value.configurationId
            }
            options={configurationsForSelectedGame || []}
            loading={isDataFetching}
            renderInput={params => (
              <TextField
                {...params}
                {...props}
                label={t('stakePerSpin')}
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isDataFetching ? <CircularProgress size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
            onChange={(_, value) => onChange(value)}
            value={value || null}
          />
        )}
      />
      {isProviderConfigured && freeSpinsConfigurations && (
        <LoadingButton
          sx={{ mt: 2 }}
          size="small"
          color="secondary"
          variant="outlined"
          disabled={!watchChosenGame}
          loading={isUpdateFreeCampaignsLoading}
          onClick={() => watchChosenGame?.gamePKey && updateFreeSpinsCampaign({ gameId: watchChosenGame.gamePKey })}
        >
          {capitalize(t('marketing.freeSpinsCampaigns.titles.refreshConfigurations'))}
        </LoadingButton>
      )}
    </>
  );
};

export default FreeSpinsConfigurationsFields;
