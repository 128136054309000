/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EFraudReasonSchema = (typeof EFraudReasonSchema)[keyof typeof EFraudReasonSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EFraudReasonSchema = {
  OtherAccountsUseSameIban: 'OtherAccountsUseSameIban',
  OtherAccountsUseSameIp: 'OtherAccountsUseSameIp',
  OtherAccountsUseSameWallet: 'OtherAccountsUseSameWallet',
  NeverDepositedBefore: 'NeverDepositedBefore',
  OnlyUsedCreditCardsAfterLastWithdrawal: 'OnlyUsedCreditCardsAfterLastWithdrawal',
  OtherAccountsUseSameName: 'OtherAccountsUseSameName',
  OtherAccountsUseSameMobileNr: 'OtherAccountsUseSameMobileNr',
  AutomaticWithdrawalsNotAllowed: 'AutomaticWithdrawalsNotAllowed',
  SuspiciousDeposits: 'SuspiciousDeposits',
  MobileNumberNotVerified: 'MobileNumberNotVerified',
  NoMobileNumber: 'NoMobileNumber',
  IdentificationNumberPreviouslyUsed: 'IdentificationNumberPreviouslyUsed',
  BelowNettoDepositThreshold: 'BelowNettoDepositThreshold',
  UnverifiedIdentity: 'UnverifiedIdentity',
} as const;
