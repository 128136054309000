/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  Base64FileSchema,
  CancelWithdrawalInCasinoSchema,
  DefaultResponse,
  GetWithdrawalsGeneralParams,
  GetWithdrawalsGeneralResultSchema,
  GetWithdrawalsSummaryParams,
  NotFoundResponse,
  PagedTransactionSummarySchema,
  ProcessWithdrawalsExportSchema,
  TransactionsBadRequestResponse,
  UnauthorizedResponse,
  WithdrawalsExportsSchema,
  WithdrawalsSchema,
  WithdrawManuallySchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Gets all withdrawals for the given filters
 */
export const useGetWithdrawalsGeneralHook = () => {
  const getWithdrawalsGeneral = useCustomInstance<GetWithdrawalsGeneralResultSchema>();

  return (params?: GetWithdrawalsGeneralParams, signal?: AbortSignal) => {
    return getWithdrawalsGeneral({ url: `/withdrawals`, method: 'get', params, signal });
  };
};

export const getGetWithdrawalsGeneralQueryKey = (params?: GetWithdrawalsGeneralParams) =>
  [`/withdrawals`, ...(params ? [params] : [])] as const;

export const useGetWithdrawalsGeneralQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsGeneralHook>>>,
  TError = TransactionsBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetWithdrawalsGeneralParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsGeneralHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsGeneralHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalsGeneralQueryKey(params);

  const getWithdrawalsGeneral = useGetWithdrawalsGeneralHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsGeneralHook>>>> = ({ signal }) =>
    getWithdrawalsGeneral(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetWithdrawalsGeneralQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsGeneralHook>>>
>;
export type GetWithdrawalsGeneralQueryError =
  | TransactionsBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Gets all withdrawals for the given filters
 */
export const useGetWithdrawalsGeneral = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsGeneralHook>>>,
  TError = TransactionsBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetWithdrawalsGeneralParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsGeneralHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWithdrawalsGeneralQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the withdrawals per type - redundant with following calls, but kept separate to allow separate permissions
 */
export const useGetWithdrawalsByTypeHook = () => {
  const getWithdrawalsByType = useCustomInstance<WithdrawalsSchema>();

  return (
    type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer',
    signal?: AbortSignal
  ) => {
    return getWithdrawalsByType({ url: `/withdrawals/${type}`, method: 'get', signal });
  };
};

export const getGetWithdrawalsByTypeQueryKey = (
  type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer'
) => [`/withdrawals/${type}`] as const;

export const useGetWithdrawalsByTypeQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsByTypeHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer',
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsByTypeHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsByTypeHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalsByTypeQueryKey(type);

  const getWithdrawalsByType = useGetWithdrawalsByTypeHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsByTypeHook>>>> = ({ signal }) =>
    getWithdrawalsByType(type, signal);

  return { queryKey, queryFn, enabled: !!type, ...queryOptions };
};

export type GetWithdrawalsByTypeQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsByTypeHook>>>
>;
export type GetWithdrawalsByTypeQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the withdrawals per type - redundant with following calls, but kept separate to allow separate permissions
 */
export const useGetWithdrawalsByType = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsByTypeHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer',
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsByTypeHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWithdrawalsByTypeQueryOptions(type, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the withdrawals for type bankaccount - redundant with generic calls, but kept separate to allow separate permissions
 */
export const useGetWithdrawalsBankAccountHook = () => {
  const getWithdrawalsBankAccount = useCustomInstance<WithdrawalsSchema>();

  return (signal?: AbortSignal) => {
    return getWithdrawalsBankAccount({ url: `/withdrawals/bankaccount`, method: 'get', signal });
  };
};

export const getGetWithdrawalsBankAccountQueryKey = () => [`/withdrawals/bankaccount`] as const;

export const useGetWithdrawalsBankAccountQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsBankAccountHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsBankAccountHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsBankAccountHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalsBankAccountQueryKey();

  const getWithdrawalsBankAccount = useGetWithdrawalsBankAccountHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsBankAccountHook>>>> = ({
    signal,
  }) => getWithdrawalsBankAccount(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetWithdrawalsBankAccountQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsBankAccountHook>>>
>;
export type GetWithdrawalsBankAccountQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the withdrawals for type bankaccount - redundant with generic calls, but kept separate to allow separate permissions
 */
export const useGetWithdrawalsBankAccount = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsBankAccountHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsBankAccountHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWithdrawalsBankAccountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the withdrawals for type casino - redundant with generic calls, but kept separate to allow separate permissions
 */
export const useGetWithdrawalsCasinoHook = () => {
  const getWithdrawalsCasino = useCustomInstance<WithdrawalsSchema>();

  return (signal?: AbortSignal) => {
    return getWithdrawalsCasino({ url: `/withdrawals/casino`, method: 'get', signal });
  };
};

export const getGetWithdrawalsCasinoQueryKey = () => [`/withdrawals/casino`] as const;

export const useGetWithdrawalsCasinoQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsCasinoHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsCasinoHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsCasinoHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalsCasinoQueryKey();

  const getWithdrawalsCasino = useGetWithdrawalsCasinoHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsCasinoHook>>>> = ({ signal }) =>
    getWithdrawalsCasino(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetWithdrawalsCasinoQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsCasinoHook>>>
>;
export type GetWithdrawalsCasinoQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the withdrawals for type casino - redundant with generic calls, but kept separate to allow separate permissions
 */
export const useGetWithdrawalsCasino = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsCasinoHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsCasinoHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWithdrawalsCasinoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the withdrawals for type netteller - redundant with generic calls, but kept separate to allow separate permissions
 */
export const useGetWithdrawalsNettellerHook = () => {
  const getWithdrawalsNetteller = useCustomInstance<WithdrawalsSchema>();

  return (signal?: AbortSignal) => {
    return getWithdrawalsNetteller({ url: `/withdrawals/netteller`, method: 'get', signal });
  };
};

export const getGetWithdrawalsNettellerQueryKey = () => [`/withdrawals/netteller`] as const;

export const useGetWithdrawalsNettellerQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsNettellerHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsNettellerHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsNettellerHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalsNettellerQueryKey();

  const getWithdrawalsNetteller = useGetWithdrawalsNettellerHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsNettellerHook>>>> = ({ signal }) =>
    getWithdrawalsNetteller(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetWithdrawalsNettellerQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsNettellerHook>>>
>;
export type GetWithdrawalsNettellerQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the withdrawals for type netteller - redundant with generic calls, but kept separate to allow separate permissions
 */
export const useGetWithdrawalsNetteller = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsNettellerHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsNettellerHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWithdrawalsNettellerQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the withdrawals for type skrill - redundant with generic calls, but kept separate to allow separate permissions
 */
export const useGetWithdrawalsSkrillHook = () => {
  const getWithdrawalsSkrill = useCustomInstance<WithdrawalsSchema>();

  return (signal?: AbortSignal) => {
    return getWithdrawalsSkrill({ url: `/withdrawals/skrill`, method: 'get', signal });
  };
};

export const getGetWithdrawalsSkrillQueryKey = () => [`/withdrawals/skrill`] as const;

export const useGetWithdrawalsSkrillQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsSkrillHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsSkrillHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsSkrillHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalsSkrillQueryKey();

  const getWithdrawalsSkrill = useGetWithdrawalsSkrillHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsSkrillHook>>>> = ({ signal }) =>
    getWithdrawalsSkrill(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetWithdrawalsSkrillQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsSkrillHook>>>
>;
export type GetWithdrawalsSkrillQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the withdrawals for type skrill - redundant with generic calls, but kept separate to allow separate permissions
 */
export const useGetWithdrawalsSkrill = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsSkrillHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsSkrillHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWithdrawalsSkrillQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the withdrawals for type paypal - redundant with generic calls, but kept separate to allow separate permissions
 */
export const useGetWithdrawalsPaypalHook = () => {
  const getWithdrawalsPaypal = useCustomInstance<WithdrawalsSchema>();

  return (signal?: AbortSignal) => {
    return getWithdrawalsPaypal({ url: `/withdrawals/paypal`, method: 'get', signal });
  };
};

export const getGetWithdrawalsPaypalQueryKey = () => [`/withdrawals/paypal`] as const;

export const useGetWithdrawalsPaypalQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsPaypalHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsPaypalHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsPaypalHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalsPaypalQueryKey();

  const getWithdrawalsPaypal = useGetWithdrawalsPaypalHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsPaypalHook>>>> = ({ signal }) =>
    getWithdrawalsPaypal(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetWithdrawalsPaypalQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsPaypalHook>>>
>;
export type GetWithdrawalsPaypalQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the withdrawals for type paypal - redundant with generic calls, but kept separate to allow separate permissions
 */
export const useGetWithdrawalsPaypal = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsPaypalHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsPaypalHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWithdrawalsPaypalQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the withdrawals for type trustly - redundant with generic calls, but kept separate to allow separate permissions
 */
export const useGetWithdrawalsTrustlyHook = () => {
  const getWithdrawalsTrustly = useCustomInstance<WithdrawalsSchema>();

  return (signal?: AbortSignal) => {
    return getWithdrawalsTrustly({ url: `/withdrawal/trustly`, method: 'get', signal });
  };
};

export const getGetWithdrawalsTrustlyQueryKey = () => [`/withdrawal/trustly`] as const;

export const useGetWithdrawalsTrustlyQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsTrustlyHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsTrustlyHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsTrustlyHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalsTrustlyQueryKey();

  const getWithdrawalsTrustly = useGetWithdrawalsTrustlyHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsTrustlyHook>>>> = ({ signal }) =>
    getWithdrawalsTrustly(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetWithdrawalsTrustlyQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsTrustlyHook>>>
>;
export type GetWithdrawalsTrustlyQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the withdrawals for type trustly - redundant with generic calls, but kept separate to allow separate permissions
 */
export const useGetWithdrawalsTrustly = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsTrustlyHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsTrustlyHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWithdrawalsTrustlyQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the withdrawals per type
 */
export const useGetProcessingFailedWithdrawalsHook = () => {
  const getProcessingFailedWithdrawals = useCustomInstance<WithdrawalsSchema>();

  return (
    type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer',
    signal?: AbortSignal
  ) => {
    return getProcessingFailedWithdrawals({ url: `/withdrawals/${type}/processingfailed`, method: 'get', signal });
  };
};

export const getGetProcessingFailedWithdrawalsQueryKey = (
  type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer'
) => [`/withdrawals/${type}/processingfailed`] as const;

export const useGetProcessingFailedWithdrawalsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProcessingFailedWithdrawalsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer',
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetProcessingFailedWithdrawalsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcessingFailedWithdrawalsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProcessingFailedWithdrawalsQueryKey(type);

  const getProcessingFailedWithdrawals = useGetProcessingFailedWithdrawalsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProcessingFailedWithdrawalsHook>>>> = ({
    signal,
  }) => getProcessingFailedWithdrawals(type, signal);

  return { queryKey, queryFn, enabled: !!type, ...queryOptions };
};

export type GetProcessingFailedWithdrawalsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetProcessingFailedWithdrawalsHook>>>
>;
export type GetProcessingFailedWithdrawalsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the withdrawals per type
 */
export const useGetProcessingFailedWithdrawals = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProcessingFailedWithdrawalsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer',
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetProcessingFailedWithdrawalsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetProcessingFailedWithdrawalsQueryOptions(type, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the withdrawals for type bankaccount
 */
export const useGetProcFailedBankHook = () => {
  const getProcFailedBank = useCustomInstance<WithdrawalsSchema>();

  return (signal?: AbortSignal) => {
    return getProcFailedBank({ url: `/withdrawals/bankaccount/processingfailed`, method: 'get', signal });
  };
};

export const getGetProcFailedBankQueryKey = () => [`/withdrawals/bankaccount/processingfailed`] as const;

export const useGetProcFailedBankQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProcFailedBankHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedBankHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedBankHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProcFailedBankQueryKey();

  const getProcFailedBank = useGetProcFailedBankHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProcFailedBankHook>>>> = ({ signal }) =>
    getProcFailedBank(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetProcFailedBankQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetProcFailedBankHook>>>
>;
export type GetProcFailedBankQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the withdrawals for type bankaccount
 */
export const useGetProcFailedBank = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProcFailedBankHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedBankHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetProcFailedBankQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the withdrawals for type casino
 */
export const useGetProcFailedCasHook = () => {
  const getProcFailedCas = useCustomInstance<WithdrawalsSchema>();

  return (signal?: AbortSignal) => {
    return getProcFailedCas({ url: `/withdrawals/casino/processingfailed`, method: 'get', signal });
  };
};

export const getGetProcFailedCasQueryKey = () => [`/withdrawals/casino/processingfailed`] as const;

export const useGetProcFailedCasQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProcFailedCasHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedCasHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedCasHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProcFailedCasQueryKey();

  const getProcFailedCas = useGetProcFailedCasHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProcFailedCasHook>>>> = ({ signal }) =>
    getProcFailedCas(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetProcFailedCasQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProcFailedCasHook>>>>;
export type GetProcFailedCasQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get the withdrawals for type casino
 */
export const useGetProcFailedCas = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProcFailedCasHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedCasHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetProcFailedCasQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the withdrawals for type netteller
 */
export const useGetProcFailedNetHook = () => {
  const getProcFailedNet = useCustomInstance<WithdrawalsSchema>();

  return (signal?: AbortSignal) => {
    return getProcFailedNet({ url: `/withdrawals/netteller/processingfailed`, method: 'get', signal });
  };
};

export const getGetProcFailedNetQueryKey = () => [`/withdrawals/netteller/processingfailed`] as const;

export const useGetProcFailedNetQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProcFailedNetHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedNetHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedNetHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProcFailedNetQueryKey();

  const getProcFailedNet = useGetProcFailedNetHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProcFailedNetHook>>>> = ({ signal }) =>
    getProcFailedNet(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetProcFailedNetQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetProcFailedNetHook>>>>;
export type GetProcFailedNetQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get the withdrawals for type netteller
 */
export const useGetProcFailedNet = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProcFailedNetHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedNetHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetProcFailedNetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the withdrawals for type skrill
 */
export const useGetProcFailedSkrillHook = () => {
  const getProcFailedSkrill = useCustomInstance<WithdrawalsSchema>();

  return (signal?: AbortSignal) => {
    return getProcFailedSkrill({ url: `/withdrawals/skrill/processingfailed`, method: 'get', signal });
  };
};

export const getGetProcFailedSkrillQueryKey = () => [`/withdrawals/skrill/processingfailed`] as const;

export const useGetProcFailedSkrillQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProcFailedSkrillHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedSkrillHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedSkrillHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProcFailedSkrillQueryKey();

  const getProcFailedSkrill = useGetProcFailedSkrillHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProcFailedSkrillHook>>>> = ({ signal }) =>
    getProcFailedSkrill(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetProcFailedSkrillQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetProcFailedSkrillHook>>>
>;
export type GetProcFailedSkrillQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the withdrawals for type skrill
 */
export const useGetProcFailedSkrill = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProcFailedSkrillHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedSkrillHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetProcFailedSkrillQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the withdrawals for type paypal
 */
export const useGetProcFailedPaypalHook = () => {
  const getProcFailedPaypal = useCustomInstance<WithdrawalsSchema>();

  return (signal?: AbortSignal) => {
    return getProcFailedPaypal({ url: `/withdrawals/paypal/processingfailed`, method: 'get', signal });
  };
};

export const getGetProcFailedPaypalQueryKey = () => [`/withdrawals/paypal/processingfailed`] as const;

export const useGetProcFailedPaypalQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProcFailedPaypalHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedPaypalHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedPaypalHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProcFailedPaypalQueryKey();

  const getProcFailedPaypal = useGetProcFailedPaypalHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProcFailedPaypalHook>>>> = ({ signal }) =>
    getProcFailedPaypal(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetProcFailedPaypalQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetProcFailedPaypalHook>>>
>;
export type GetProcFailedPaypalQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the withdrawals for type paypal
 */
export const useGetProcFailedPaypal = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProcFailedPaypalHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedPaypalHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetProcFailedPaypalQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the withdrawals for type trustly
 */
export const useGetProcFailedTrustlyHook = () => {
  const getProcFailedTrustly = useCustomInstance<WithdrawalsSchema>();

  return (signal?: AbortSignal) => {
    return getProcFailedTrustly({ url: `/withdrawals/trustly/processingfailed`, method: 'get', signal });
  };
};

export const getGetProcFailedTrustlyQueryKey = () => [`/withdrawals/trustly/processingfailed`] as const;

export const useGetProcFailedTrustlyQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProcFailedTrustlyHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedTrustlyHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedTrustlyHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProcFailedTrustlyQueryKey();

  const getProcFailedTrustly = useGetProcFailedTrustlyHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProcFailedTrustlyHook>>>> = ({ signal }) =>
    getProcFailedTrustly(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetProcFailedTrustlyQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetProcFailedTrustlyHook>>>
>;
export type GetProcFailedTrustlyQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the withdrawals for type trustly
 */
export const useGetProcFailedTrustly = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProcFailedTrustlyHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProcFailedTrustlyHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetProcFailedTrustlyQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * This is used to export and process the withdrawals
 * @summary Exports withdrawals
 */
export const useExportWithdrawalsHook = () => {
  const exportWithdrawals = useCustomInstance<WithdrawalsSchema>();

  return (
    type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer',
    exportWithdrawalsBody: string[]
  ) => {
    return exportWithdrawals({
      url: `/withdrawals/${type}/export`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: exportWithdrawalsBody,
    });
  };
};

export const useExportWithdrawalsMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportWithdrawalsHook>>>,
    TError,
    {
      type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
      data: string[];
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useExportWithdrawalsHook>>>,
  TError,
  {
    type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
    data: string[];
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const exportWithdrawals = useExportWithdrawalsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useExportWithdrawalsHook>>>,
    {
      type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
      data: string[];
    }
  > = props => {
    const { type, data } = props ?? {};

    return exportWithdrawals(type, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportWithdrawalsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useExportWithdrawalsHook>>>
>;
export type ExportWithdrawalsMutationBody = string[];
export type ExportWithdrawalsMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Exports withdrawals
 */
export const useExportWithdrawals = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportWithdrawalsHook>>>,
    TError,
    {
      type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
      data: string[];
    },
    TContext
  >;
}) => {
  const mutationOptions = useExportWithdrawalsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * This is used to decline an individual withdrawal
 * @summary Decline individual withdrawal
 */
export const useDeclineWithdrawalHook = () => {
  const declineWithdrawal = useCustomInstance<WithdrawalsSchema>();

  return (
    type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer',
    withdrawalId: string,
    declineWithdrawalBody: string
  ) => {
    return declineWithdrawal({
      url: `/withdrawals/${type}/${withdrawalId}/decline`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: declineWithdrawalBody,
    });
  };
};

export const useDeclineWithdrawalMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeclineWithdrawalHook>>>,
    TError,
    {
      type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
      withdrawalId: string;
      data: string;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeclineWithdrawalHook>>>,
  TError,
  {
    type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
    withdrawalId: string;
    data: string;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const declineWithdrawal = useDeclineWithdrawalHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeclineWithdrawalHook>>>,
    {
      type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
      withdrawalId: string;
      data: string;
    }
  > = props => {
    const { type, withdrawalId, data } = props ?? {};

    return declineWithdrawal(type, withdrawalId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeclineWithdrawalMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeclineWithdrawalHook>>>
>;
export type DeclineWithdrawalMutationBody = string;
export type DeclineWithdrawalMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Decline individual withdrawal
 */
export const useDeclineWithdrawal = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeclineWithdrawalHook>>>,
    TError,
    {
      type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
      withdrawalId: string;
      data: string;
    },
    TContext
  >;
}) => {
  const mutationOptions = useDeclineWithdrawalMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * This is used to retry an individual withdrawal
 * @summary Retry individual withdrawal
 */
export const useRetryWithdrawalHook = () => {
  const retryWithdrawal = useCustomInstance<WithdrawalsSchema>();

  return (
    type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer',
    withdrawalId: string
  ) => {
    return retryWithdrawal({ url: `/withdrawals/${type}/${withdrawalId}/retry`, method: 'post' });
  };
};

export const useRetryWithdrawalMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRetryWithdrawalHook>>>,
    TError,
    {
      type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
      withdrawalId: string;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRetryWithdrawalHook>>>,
  TError,
  {
    type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
    withdrawalId: string;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const retryWithdrawal = useRetryWithdrawalHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRetryWithdrawalHook>>>,
    {
      type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
      withdrawalId: string;
    }
  > = props => {
    const { type, withdrawalId } = props ?? {};

    return retryWithdrawal(type, withdrawalId);
  };

  return { mutationFn, ...mutationOptions };
};

export type RetryWithdrawalMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useRetryWithdrawalHook>>>>;

export type RetryWithdrawalMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Retry individual withdrawal
 */
export const useRetryWithdrawal = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRetryWithdrawalHook>>>,
    TError,
    {
      type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
      withdrawalId: string;
    },
    TContext
  >;
}) => {
  const mutationOptions = useRetryWithdrawalMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * This is used to set the withdrawal to processed manually
 * @summary Manual individual withdrawal
 */
export const useSetManualWithdrawalHook = () => {
  const setManualWithdrawal = useCustomInstance<WithdrawalsSchema>();

  return (
    type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer',
    withdrawalId: string
  ) => {
    return setManualWithdrawal({ url: `/withdrawals/${type}/${withdrawalId}/manual`, method: 'post' });
  };
};

export const useSetManualWithdrawalMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetManualWithdrawalHook>>>,
    TError,
    {
      type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
      withdrawalId: string;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetManualWithdrawalHook>>>,
  TError,
  {
    type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
    withdrawalId: string;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setManualWithdrawal = useSetManualWithdrawalHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetManualWithdrawalHook>>>,
    {
      type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
      withdrawalId: string;
    }
  > = props => {
    const { type, withdrawalId } = props ?? {};

    return setManualWithdrawal(type, withdrawalId);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetManualWithdrawalMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetManualWithdrawalHook>>>
>;

export type SetManualWithdrawalMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Manual individual withdrawal
 */
export const useSetManualWithdrawal = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetManualWithdrawalHook>>>,
    TError,
    {
      type: 'bankaccount' | 'casino' | 'netteller' | 'skrill' | 'paypal' | 'trustly' | 'unknown' | 'truelayer';
      withdrawalId: string;
    },
    TContext
  >;
}) => {
  const mutationOptions = useSetManualWithdrawalMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the withdrawals exports
 */
export const useGetWithdrawalsExportsHook = () => {
  const getWithdrawalsExports = useCustomInstance<WithdrawalsExportsSchema>();

  return (signal?: AbortSignal) => {
    return getWithdrawalsExports({ url: `/withdrawals/exports`, method: 'get', signal });
  };
};

export const getGetWithdrawalsExportsQueryKey = () => [`/withdrawals/exports`] as const;

export const useGetWithdrawalsExportsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsExportsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsExportsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsExportsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalsExportsQueryKey();

  const getWithdrawalsExports = useGetWithdrawalsExportsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsExportsHook>>>> = ({ signal }) =>
    getWithdrawalsExports(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetWithdrawalsExportsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsExportsHook>>>
>;
export type GetWithdrawalsExportsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the withdrawals exports
 */
export const useGetWithdrawalsExports = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsExportsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsExportsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWithdrawalsExportsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the withdrawal export file
 */
export const useGetWithdrawalsExportFileHook = () => {
  const getWithdrawalsExportFile = useCustomInstance<Base64FileSchema>();

  return (withdrawalId: string, signal?: AbortSignal) => {
    return getWithdrawalsExportFile({ url: `/withdrawals/exports/${withdrawalId}`, method: 'get', signal });
  };
};

export const getGetWithdrawalsExportFileQueryKey = (withdrawalId: string) =>
  [`/withdrawals/exports/${withdrawalId}`] as const;

export const useGetWithdrawalsExportFileQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsExportFileHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  withdrawalId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsExportFileHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsExportFileHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalsExportFileQueryKey(withdrawalId);

  const getWithdrawalsExportFile = useGetWithdrawalsExportFileHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsExportFileHook>>>> = ({
    signal,
  }) => getWithdrawalsExportFile(withdrawalId, signal);

  return { queryKey, queryFn, enabled: !!withdrawalId, ...queryOptions };
};

export type GetWithdrawalsExportFileQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsExportFileHook>>>
>;
export type GetWithdrawalsExportFileQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the withdrawal export file
 */
export const useGetWithdrawalsExportFile = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsExportFileHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  withdrawalId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsExportFileHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWithdrawalsExportFileQueryOptions(withdrawalId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * This is used to process the exported withdrawals
 * @summary Process exported withdrawals
 */
export const useProcessWithdrawalsExportHook = () => {
  const processWithdrawalsExport = useCustomInstance<WithdrawalsExportsSchema>();

  return (processWithdrawalsExportSchema: ProcessWithdrawalsExportSchema) => {
    return processWithdrawalsExport({
      url: `/withdrawals/exports/process`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: processWithdrawalsExportSchema,
    });
  };
};

export const useProcessWithdrawalsExportMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProcessWithdrawalsExportHook>>>,
    TError,
    { data: ProcessWithdrawalsExportSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useProcessWithdrawalsExportHook>>>,
  TError,
  { data: ProcessWithdrawalsExportSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const processWithdrawalsExport = useProcessWithdrawalsExportHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useProcessWithdrawalsExportHook>>>,
    { data: ProcessWithdrawalsExportSchema }
  > = props => {
    const { data } = props ?? {};

    return processWithdrawalsExport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProcessWithdrawalsExportMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useProcessWithdrawalsExportHook>>>
>;
export type ProcessWithdrawalsExportMutationBody = ProcessWithdrawalsExportSchema;
export type ProcessWithdrawalsExportMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Process exported withdrawals
 */
export const useProcessWithdrawalsExport = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useProcessWithdrawalsExportHook>>>,
    TError,
    { data: ProcessWithdrawalsExportSchema },
    TContext
  >;
}) => {
  const mutationOptions = useProcessWithdrawalsExportMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the accepted withdrawals in casino
 */
export const useGetWithdrawalsInCasinoHook = () => {
  const getWithdrawalsInCasino = useCustomInstance<WithdrawalsSchema>();

  return (signal?: AbortSignal) => {
    return getWithdrawalsInCasino({ url: `/withdrawals/incasino`, method: 'get', signal });
  };
};

export const getGetWithdrawalsInCasinoQueryKey = () => [`/withdrawals/incasino`] as const;

export const useGetWithdrawalsInCasinoQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsInCasinoHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsInCasinoHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsInCasinoHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalsInCasinoQueryKey();

  const getWithdrawalsInCasino = useGetWithdrawalsInCasinoHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsInCasinoHook>>>> = ({ signal }) =>
    getWithdrawalsInCasino(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetWithdrawalsInCasinoQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsInCasinoHook>>>
>;
export type GetWithdrawalsInCasinoQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the accepted withdrawals in casino
 */
export const useGetWithdrawalsInCasino = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsInCasinoHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsInCasinoHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWithdrawalsInCasinoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Cancel the accepted withdrawal in casino
 */
export const useCancelWithdrawalInCasinoHook = () => {
  const cancelWithdrawalInCasino = useCustomInstance<WithdrawalsSchema>();

  return (withdrawalId: string, cancelWithdrawalInCasinoSchema: CancelWithdrawalInCasinoSchema) => {
    return cancelWithdrawalInCasino({
      url: `/withdrawals/incasino/${withdrawalId}/cancel`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: cancelWithdrawalInCasinoSchema,
    });
  };
};

export const useCancelWithdrawalInCasinoMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelWithdrawalInCasinoHook>>>,
    TError,
    { withdrawalId: string; data: CancelWithdrawalInCasinoSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCancelWithdrawalInCasinoHook>>>,
  TError,
  { withdrawalId: string; data: CancelWithdrawalInCasinoSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const cancelWithdrawalInCasino = useCancelWithdrawalInCasinoHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCancelWithdrawalInCasinoHook>>>,
    { withdrawalId: string; data: CancelWithdrawalInCasinoSchema }
  > = props => {
    const { withdrawalId, data } = props ?? {};

    return cancelWithdrawalInCasino(withdrawalId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelWithdrawalInCasinoMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCancelWithdrawalInCasinoHook>>>
>;
export type CancelWithdrawalInCasinoMutationBody = CancelWithdrawalInCasinoSchema;
export type CancelWithdrawalInCasinoMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Cancel the accepted withdrawal in casino
 */
export const useCancelWithdrawalInCasino = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelWithdrawalInCasinoHook>>>,
    TError,
    { withdrawalId: string; data: CancelWithdrawalInCasinoSchema },
    TContext
  >;
}) => {
  const mutationOptions = useCancelWithdrawalInCasinoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Create a manual withdrawal
 */
export const useWithdrawManuallyHook = () => {
  const withdrawManually = useCustomInstance<void>();

  return (withdrawManuallySchema: WithdrawManuallySchema) => {
    return withdrawManually({
      url: `/withdrawals/manually`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: withdrawManuallySchema,
    });
  };
};

export const useWithdrawManuallyMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useWithdrawManuallyHook>>>,
    TError,
    { data: WithdrawManuallySchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useWithdrawManuallyHook>>>,
  TError,
  { data: WithdrawManuallySchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const withdrawManually = useWithdrawManuallyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useWithdrawManuallyHook>>>,
    { data: WithdrawManuallySchema }
  > = props => {
    const { data } = props ?? {};

    return withdrawManually(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type WithdrawManuallyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useWithdrawManuallyHook>>>
>;
export type WithdrawManuallyMutationBody = WithdrawManuallySchema;
export type WithdrawManuallyMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Create a manual withdrawal
 */
export const useWithdrawManually = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useWithdrawManuallyHook>>>,
    TError,
    { data: WithdrawManuallySchema },
    TContext
  >;
}) => {
  const mutationOptions = useWithdrawManuallyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get sum of withdrawals with filters
 */
export const useGetWithdrawalsSummaryHook = () => {
  const getWithdrawalsSummary = useCustomInstance<PagedTransactionSummarySchema>();

  return (params?: GetWithdrawalsSummaryParams, signal?: AbortSignal) => {
    return getWithdrawalsSummary({ url: `/withdrawals/summary`, method: 'get', params, signal });
  };
};

export const getGetWithdrawalsSummaryQueryKey = (params?: GetWithdrawalsSummaryParams) =>
  [`/withdrawals/summary`, ...(params ? [params] : [])] as const;

export const useGetWithdrawalsSummaryQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsSummaryHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetWithdrawalsSummaryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsSummaryHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsSummaryHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWithdrawalsSummaryQueryKey(params);

  const getWithdrawalsSummary = useGetWithdrawalsSummaryHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsSummaryHook>>>> = ({ signal }) =>
    getWithdrawalsSummary(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetWithdrawalsSummaryQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsSummaryHook>>>
>;
export type GetWithdrawalsSummaryQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get sum of withdrawals with filters
 */
export const useGetWithdrawalsSummary = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsSummaryHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetWithdrawalsSummaryParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWithdrawalsSummaryHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWithdrawalsSummaryQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
