import { memo, useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import {
  EGameLobbyTypeSchema,
  GameLobbyArgumentsSchema,
  GameLobbySchema,
  getGetGameLobbiesQueryKey,
  getGetGameLobbyQueryKey,
  useAddGameLobby,
  useGetGameLobby,
  useGetGameProvider,
  useGetGameProviders,
  useGetGameStudios,
  useUpdateGameLobby,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Skeleton, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';

import { FormError, SelectInput } from '@greenisland-common/components/atoms';
import CheckboxField from '@greenisland-common/components/molecules/CheckboxField';
import Input from '@greenisland-common/components/molecules/Input';

import {
  defaultGameLobbyFormValue,
  GameLobbyForm,
  gameLobbyFormToRequest,
  getDefaultGameLobbyFormValue,
} from '../GameLobbyFormContext';
import GameLobbyImages from './GameLobbyImages';

const EditLobby = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { lobbyId }: any = useParams();

  const { data: lobby, isLoading: lobbyIsLoading } = useGetGameLobby(lobbyId, {
    query: { enabled: Boolean(lobbyId !== 'add') },
  });

  const methods = useForm<GameLobbyForm>({ defaultValues: defaultGameLobbyFormValue, shouldFocusError: true });
  const { control, handleSubmit, reset, setValue, formState } = methods;

  const gameProviderId = useWatch<string>({ control, name: 'gameProviderId', defaultValue: '' });
  const { data: gameProvider } = useGetGameProvider(gameProviderId);
  const { data: gameProviders, isLoading: gameProvidersAreLoading } = useGetGameProviders();

  const gameStudioName = useWatch<string>({ control, name: 'gameStudioName', defaultValue: '' });
  const { data: gameStudios, isLoading: gameStudiosAreLoading } = useGetGameStudios();

  useEffect(() => {
    if (lobby) {
      reset({
        ...getDefaultGameLobbyFormValue(lobby, lobby.gameProviderName, lobby.gameStudioName),
      });
    }
  }, [lobby, reset]);

  useEffect(() => {
    if (gameProvider) {
      setValue('gameProviderName', gameProvider.providerName);
    }
  }, [gameProvider, setValue]);

  const filteredGameStudios = useMemo(
    () => gameStudios?.filter(studio => gameProvider?.gameStudios.includes(studio.gameStudioId)) ?? [],
    [gameStudios, gameProvider]
  );

  useEffect(() => {
    if (
      Boolean(filteredGameStudios) === true &&
      filteredGameStudios.findIndex(gameStudio => gameStudio.gameStudioName === gameStudioName) === -1 &&
      Boolean(lobby) === false
    ) {
      setValue('gameStudioId', 0);
    }
  }, [filteredGameStudios, gameStudioName, lobby, setValue]);

  useEffect(() => {
    if (gameProvider?.gameStudios.length === 0) {
      enqueueSnackbar(t('content.games.gameLobbies.providerHasNoStudios'), { variant: 'warning' });
    }
  }, [enqueueSnackbar, gameProvider, t]);

  const {
    mutate: addGameLobby,
    isLoading: addIsLoading,
    isError: addProducedError,
  } = useAddGameLobby({
    mutation: {
      onSuccess: (data: GameLobbySchema) => {
        reset(getDefaultGameLobbyFormValue(data, gameProvider?.providerName, gameStudioName), {
          touched: false,
          isDirty: false,
        });
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetGameLobbiesQueryKey());
        const array = location.pathname.split('/');
        array.pop();
        navigate([...array, data.id].join('/'));
      },
    },
  });

  const {
    mutate: updateGameLobby,
    isLoading: updateIsLoading,
    isError: updateProducedError,
  } = useUpdateGameLobby({
    mutation: {
      onSuccess: (data: GameLobbySchema) => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetGameLobbyQueryKey(data.id));
      },
    },
  });

  const onSubmit = (formData: GameLobbyForm) => {
    const data: GameLobbyArgumentsSchema = gameLobbyFormToRequest(formData);

    if (!lobbyId || lobbyId === 'add') addGameLobby({ data });
    else updateGameLobby({ gameLobbyId: lobbyId, data });
  };

  if (lobbyIsLoading || gameProvidersAreLoading || gameStudiosAreLoading)
    return <Skeleton variant="rectangular" height={600} />;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" spacing={2}>
          <SelectInput
            name={'gameProviderId'}
            label={t('provider') + ' *'}
            rules={{ required: t('fieldIsRequired') }}
            defaultValue="0"
            disabled={Boolean(lobby?.id) !== false}
            options={
              gameProviders?.map(({ providerName, providerId }) => ({
                label: providerName,
                value: providerId,
              })) ?? []
            }
          />
          <SelectInput
            name={'gameStudioId'}
            label={t('content.games.gameLobbies.gameStudio') + ' *'}
            rules={{ required: t('fieldIsRequired') }}
            defaultValue="0"
            disabled={Boolean(lobby?.id) !== false || filteredGameStudios.length === 0}
            options={
              filteredGameStudios?.map(({ gameStudioName, gameStudioId }) => ({
                label: gameStudioName,
                value: gameStudioId,
              })) ?? []
            }
          />
          <SelectInput
            name={'lobbyType'}
            label={t('content.games.gameLobbies.lobbyType') + ' *'}
            rules={{ required: t('fieldIsRequired') }}
            options={Object.keys(EGameLobbyTypeSchema).map(key => ({ label: t(key), value: key })) ?? []}
          />
          <Input
            name={'name'}
            label={t('content.games.gameLobbies.lobbyName')}
            control={control}
            required
            rules={{ required: t('fieldIsRequired') }}
          />
          <Input
            name={'urlName'}
            label={t('content.games.gameLobbies.urlName')}
            control={control}
            required
            rules={{ required: t('fieldIsRequired') }}
          />
          <Input
            name={'desktopExternalGameLobbyId'}
            label={t('content.games.gameLobbies.desktopExternalGameLobbyId')}
            control={control}
            required
            rules={{ required: t('fieldIsRequired') }}
          />
          <Input
            name={'mobileExternalGameLobbyId'}
            label={t('content.games.gameLobbies.mobileExternalGameLobbyId')}
            control={control}
            required
            rules={{ required: t('fieldIsRequired') }}
          />
          <Input
            name={'configuration'}
            label={t('content.games.gameLobbies.configuration')}
            control={control}
            rules={{ required: t('fieldIsRequired') }}
          />
          <CheckboxField name="enabled" label={t('content.games.gameLobbies.enabled')} control={control} />
          <GameLobbyImages />
          {addProducedError ? <Alert severity="error">{t('content.games.gameLobbies.addError')}</Alert> : null}
          {updateProducedError ? <Alert severity="error">{t('content.games.gameLobbies.updateError')}</Alert> : null}
        </Stack>
        <Stack>
          <Box display="flex" gap={1}>
            <LoadingButton
              color="primary"
              type="submit"
              variant="contained"
              loading={addIsLoading || updateIsLoading}
              disabled={!formState.isDirty}
            >
              {t('save')}
            </LoadingButton>
          </Box>
          <FormError error={Object.keys(formState.errors).length > 0} message={t('common.formErrors')} />
        </Stack>
      </form>
    </FormProvider>
  );
};

export default memo(EditLobby);
