/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestAccountResponse,
  BadRequestResponse,
  DefaultResponse,
  NotFoundResponse,
  UnauthorizedResponse,
  WhitelistSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get whitelisted credentials that should pass fraud check for deposits and withdrawals
 */
export const useGetWhitelistedCredentialsHook = () => {
  const getWhitelistedCredentials = useCustomInstance<WhitelistSchema>();

  return (userId: number, whitelistType: 'cardHolderName' | 'email' | 'iban' | 'wallet', signal?: AbortSignal) => {
    return getWhitelistedCredentials({
      url: `/fraud/payments/users/${userId}/whitelist/${whitelistType}`,
      method: 'get',
      signal,
    });
  };
};

export const getGetWhitelistedCredentialsQueryKey = (
  userId: number,
  whitelistType: 'cardHolderName' | 'email' | 'iban' | 'wallet'
) => [`/fraud/payments/users/${userId}/whitelist/${whitelistType}`] as const;

export const useGetWhitelistedCredentialsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWhitelistedCredentialsHook>>>,
  TError = BadRequestAccountResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  whitelistType: 'cardHolderName' | 'email' | 'iban' | 'wallet',
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWhitelistedCredentialsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWhitelistedCredentialsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWhitelistedCredentialsQueryKey(userId, whitelistType);

  const getWhitelistedCredentials = useGetWhitelistedCredentialsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetWhitelistedCredentialsHook>>>> = ({
    signal,
  }) => getWhitelistedCredentials(userId, whitelistType, signal);

  return { queryKey, queryFn, enabled: !!(userId && whitelistType), ...queryOptions };
};

export type GetWhitelistedCredentialsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetWhitelistedCredentialsHook>>>
>;
export type GetWhitelistedCredentialsQueryError =
  | BadRequestAccountResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get whitelisted credentials that should pass fraud check for deposits and withdrawals
 */
export const useGetWhitelistedCredentials = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetWhitelistedCredentialsHook>>>,
  TError = BadRequestAccountResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  whitelistType: 'cardHolderName' | 'email' | 'iban' | 'wallet',
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetWhitelistedCredentialsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetWhitelistedCredentialsQueryOptions(userId, whitelistType, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Add credentials to the whitelist should pass fraud check for deposits and withdrawals
 */
export const useWhitelistCredentialsHook = () => {
  const whitelistCredentials = useCustomInstance<void>();

  return (
    userId: number,
    whitelistType: 'cardHolderName' | 'email' | 'iban' | 'wallet',
    whitelistSchema: WhitelistSchema
  ) => {
    return whitelistCredentials({
      url: `/fraud/payments/users/${userId}/whitelist/${whitelistType}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: whitelistSchema,
    });
  };
};

export const useWhitelistCredentialsMutationOptions = <
  TError = BadRequestAccountResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useWhitelistCredentialsHook>>>,
    TError,
    { userId: number; whitelistType: 'cardHolderName' | 'email' | 'iban' | 'wallet'; data: WhitelistSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useWhitelistCredentialsHook>>>,
  TError,
  { userId: number; whitelistType: 'cardHolderName' | 'email' | 'iban' | 'wallet'; data: WhitelistSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const whitelistCredentials = useWhitelistCredentialsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useWhitelistCredentialsHook>>>,
    { userId: number; whitelistType: 'cardHolderName' | 'email' | 'iban' | 'wallet'; data: WhitelistSchema }
  > = props => {
    const { userId, whitelistType, data } = props ?? {};

    return whitelistCredentials(userId, whitelistType, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type WhitelistCredentialsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useWhitelistCredentialsHook>>>
>;
export type WhitelistCredentialsMutationBody = WhitelistSchema;
export type WhitelistCredentialsMutationError =
  | BadRequestAccountResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Add credentials to the whitelist should pass fraud check for deposits and withdrawals
 */
export const useWhitelistCredentials = <
  TError = BadRequestAccountResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useWhitelistCredentialsHook>>>,
    TError,
    { userId: number; whitelistType: 'cardHolderName' | 'email' | 'iban' | 'wallet'; data: WhitelistSchema },
    TContext
  >;
}) => {
  const mutationOptions = useWhitelistCredentialsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Remove a credential from the whitelist
 */
export const useRemoveCredentialsFromWhitelistHook = () => {
  const removeCredentialsFromWhitelist = useCustomInstance<void>();

  return (
    userId: number,
    whitelistType: 'cardHolderName' | 'email' | 'iban' | 'wallet',
    whitelistSchema: WhitelistSchema
  ) => {
    return removeCredentialsFromWhitelist({
      url: `/fraud/payments/users/${userId}/whitelist/${whitelistType}`,
      method: 'delete',
      headers: { 'Content-Type': 'application/json' },
      data: whitelistSchema,
    });
  };
};

export const useRemoveCredentialsFromWhitelistMutationOptions = <
  TError = BadRequestAccountResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveCredentialsFromWhitelistHook>>>,
    TError,
    { userId: number; whitelistType: 'cardHolderName' | 'email' | 'iban' | 'wallet'; data: WhitelistSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRemoveCredentialsFromWhitelistHook>>>,
  TError,
  { userId: number; whitelistType: 'cardHolderName' | 'email' | 'iban' | 'wallet'; data: WhitelistSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const removeCredentialsFromWhitelist = useRemoveCredentialsFromWhitelistHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRemoveCredentialsFromWhitelistHook>>>,
    { userId: number; whitelistType: 'cardHolderName' | 'email' | 'iban' | 'wallet'; data: WhitelistSchema }
  > = props => {
    const { userId, whitelistType, data } = props ?? {};

    return removeCredentialsFromWhitelist(userId, whitelistType, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RemoveCredentialsFromWhitelistMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRemoveCredentialsFromWhitelistHook>>>
>;
export type RemoveCredentialsFromWhitelistMutationBody = WhitelistSchema;
export type RemoveCredentialsFromWhitelistMutationError =
  | BadRequestAccountResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Remove a credential from the whitelist
 */
export const useRemoveCredentialsFromWhitelist = <
  TError = BadRequestAccountResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRemoveCredentialsFromWhitelistHook>>>,
    TError,
    { userId: number; whitelistType: 'cardHolderName' | 'email' | 'iban' | 'wallet'; data: WhitelistSchema },
    TContext
  >;
}) => {
  const mutationOptions = useRemoveCredentialsFromWhitelistMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Mark the deposit as safe.
 */
export const useMarkDepositSafeHook = () => {
  const markDepositSafe = useCustomInstance<void>();

  return (depositId: string) => {
    return markDepositSafe({ url: `/fraud/payments/deposits/${depositId}/safe`, method: 'put' });
  };
};

export const useMarkDepositSafeMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useMarkDepositSafeHook>>>,
    TError,
    { depositId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useMarkDepositSafeHook>>>,
  TError,
  { depositId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const markDepositSafe = useMarkDepositSafeHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useMarkDepositSafeHook>>>,
    { depositId: string }
  > = props => {
    const { depositId } = props ?? {};

    return markDepositSafe(depositId);
  };

  return { mutationFn, ...mutationOptions };
};

export type MarkDepositSafeMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useMarkDepositSafeHook>>>>;

export type MarkDepositSafeMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Mark the deposit as safe.
 */
export const useMarkDepositSafe = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useMarkDepositSafeHook>>>,
    TError,
    { depositId: string },
    TContext
  >;
}) => {
  const mutationOptions = useMarkDepositSafeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Acknowledge the unsafe deposit.
 */
export const useAcknowledgeUnsafeDepositHook = () => {
  const acknowledgeUnsafeDeposit = useCustomInstance<void>();

  return (depositId: string) => {
    return acknowledgeUnsafeDeposit({ url: `/fraud/payments/deposits/${depositId}/acknowledge`, method: 'put' });
  };
};

export const useAcknowledgeUnsafeDepositMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAcknowledgeUnsafeDepositHook>>>,
    TError,
    { depositId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAcknowledgeUnsafeDepositHook>>>,
  TError,
  { depositId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const acknowledgeUnsafeDeposit = useAcknowledgeUnsafeDepositHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAcknowledgeUnsafeDepositHook>>>,
    { depositId: string }
  > = props => {
    const { depositId } = props ?? {};

    return acknowledgeUnsafeDeposit(depositId);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcknowledgeUnsafeDepositMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAcknowledgeUnsafeDepositHook>>>
>;

export type AcknowledgeUnsafeDepositMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Acknowledge the unsafe deposit.
 */
export const useAcknowledgeUnsafeDeposit = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAcknowledgeUnsafeDepositHook>>>,
    TError,
    { depositId: string },
    TContext
  >;
}) => {
  const mutationOptions = useAcknowledgeUnsafeDepositMutationOptions(options);

  return useMutation(mutationOptions);
};
