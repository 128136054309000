import { Box, useTheme } from '@mui/material';
import {
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';

import DataGridCustomPagination from '@greenisland-common/components/atoms/DataGridPagination/DataGridCustomPagination';

const DataGridPagination = ({ rowsPerPageOptions }: any) => {
  const theme = useTheme();
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rowsPerPage = useGridSelector(apiRef, gridPageSizeSelector);

  const onRowsPerPageChange = (value: number) => {
    apiRef.current.setPageSize(value);
    apiRef.current.setPage(0);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          justifyContent: 'flex-start',
        },
      }}
    >
      <DataGridCustomPagination
        page={page}
        count={pageCount}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageChange={apiRef.current.setPage}
      />
    </Box>
  );
};

export default DataGridPagination;
