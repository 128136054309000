import { useNavigate } from 'react-router';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Stack } from '@mui/material';
import { t } from 'i18next';
import { usePermission } from 'src/app/hooks';

import PaymentMethodList from './Components/PaymentMethodList';

const PaymentMethods = () => {
  const canAddPaymentMethod = usePermission(OnlineCasinoPermissions.createPaymentMethod);
  const navigate = useNavigate();

  return (
    <Stack spacing={2}>
      {canAddPaymentMethod ? (
        <div>
          <Button variant="text" startIcon={<FontAwesomeIcon icon={faAdd} />} onClick={() => navigate(`./add`)}>
            {t('paymentMethods.addSingle')}
          </Button>
        </div>
      ) : null}
      <PaymentMethodList />
    </Stack>
  );
};

export default PaymentMethods;
