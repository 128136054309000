import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';

import { isValidNumber } from '@greenisland-common/helpers/utils';

import type { IndividualActionForm } from '../context';
import IndividualActionFormListItem from './IndividualActionFormListItem';

const LoyaltyPointsForm = () => {
  const { t } = useTranslation();
  const { register, watch, setValue, errors, getValues, trigger } = useFormContext<IndividualActionForm>();
  const enabledLoyaltyPoints = watch('enabledLoyaltyPoints');
  const fieldNames: Array<keyof IndividualActionForm> = ['spendablePointsLoyaltyPoints', 'statusPointsLoyaltyPoints'];

  // To prevent recursion
  const triggerRef = useRef(false);

  useEffect(() => {
    if (!enabledLoyaltyPoints) {
      setValue('spendablePointsLoyaltyPoints', '');
      setValue('statusPointsLoyaltyPoints', '');
    }
  }, [setValue, enabledLoyaltyPoints]);

  const validateAtLeastOneValueGreaterThanZero = async () => {
    if (!triggerRef.current) {
      triggerRef.current = true;
      try {
        await Promise.all(fieldNames.map(name => trigger(name)));
      } finally {
        triggerRef.current = false;
      }
    }

    const { statusPointsLoyaltyPoints, spendablePointsLoyaltyPoints } = getValues();
    const validStatusPoints = isValidNumber(statusPointsLoyaltyPoints) && statusPointsLoyaltyPoints > 0;
    const validSpendablePoints = isValidNumber(spendablePointsLoyaltyPoints) && spendablePointsLoyaltyPoints > 0;

    return validStatusPoints || validSpendablePoints || t('atLeastOneFieldMustBeGreaterThanZero');
  };

  return (
    <IndividualActionFormListItem
      enabled={enabledLoyaltyPoints}
      name="enabledLoyaltyPoints"
      listItemText={t('loyaltyPoints')}
    >
      {fieldNames.map(fieldName => (
        <TextField
          size="small"
          key={fieldName}
          inputRef={register({
            valueAsNumber: true,
            min: {
              value: 0,
              message: t('fieldMustBeNonNegative'),
            },
            validate: enabledLoyaltyPoints ? validateAtLeastOneValueGreaterThanZero : undefined,
          })}
          name={fieldName}
          error={Boolean(errors[fieldName])}
          helperText={(errors[fieldName] as any)?.message}
          disabled={!enabledLoyaltyPoints}
          type="number"
          label={t(fieldName)}
        />
      ))}
    </IndividualActionFormListItem>
  );
};

export default LoyaltyPointsForm;
