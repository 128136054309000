/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type { MutationFunction, UseMutationOptions } from 'react-query';
import { useMutation } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  CheckinControllerBadRequestResponse,
  DefaultResponse,
  NotFoundResponse,
  ResetCheckinAttemptsRequestBodySchema,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Reset the checkin attempts for the past day
 */
export const useResetCheckinAttemptsHook = () => {
  const resetCheckinAttempts = useCustomInstance<void>();

  return (resetCheckinAttemptsRequestBodySchema: ResetCheckinAttemptsRequestBodySchema) => {
    return resetCheckinAttempts({
      url: `/checkin/attempts/reset`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: resetCheckinAttemptsRequestBodySchema,
    });
  };
};

export const useResetCheckinAttemptsMutationOptions = <
  TError = CheckinControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetCheckinAttemptsHook>>>,
    TError,
    { data: ResetCheckinAttemptsRequestBodySchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useResetCheckinAttemptsHook>>>,
  TError,
  { data: ResetCheckinAttemptsRequestBodySchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const resetCheckinAttempts = useResetCheckinAttemptsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useResetCheckinAttemptsHook>>>,
    { data: ResetCheckinAttemptsRequestBodySchema }
  > = props => {
    const { data } = props ?? {};

    return resetCheckinAttempts(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetCheckinAttemptsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useResetCheckinAttemptsHook>>>
>;
export type ResetCheckinAttemptsMutationBody = ResetCheckinAttemptsRequestBodySchema;
export type ResetCheckinAttemptsMutationError =
  | CheckinControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Reset the checkin attempts for the past day
 */
export const useResetCheckinAttempts = <
  TError = CheckinControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetCheckinAttemptsHook>>>,
    TError,
    { data: ResetCheckinAttemptsRequestBodySchema },
    TContext
  >;
}) => {
  const mutationOptions = useResetCheckinAttemptsMutationOptions(options);

  return useMutation(mutationOptions);
};
