import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  getGetUserGeneralQueryKey,
  OrderBechargeErrorSchema,
  useAddToMarketingBlacklist,
  useRemoveFromMarketingBlacklist,
  UserGeneralSchema,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, CircularProgress } from '@mui/material';
import { getUnixTime } from 'date-fns';
import { useSnackbar } from 'notistack';

import GenericField from './GenericField';

interface Props {
  user: UserGeneralSchema;
}

const MarketingBlacklistedField = ({ user }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { isMarketingBlacklisted } = user;

  const [expirationDate, setExpirationDate] = useState<Date | null>(null);

  const { mutate: addToMarketingBlacklist, isLoading: isAddToMarketingBlacklistLoading } = useAddToMarketingBlacklist({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetUserGeneralQueryKey(user.userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: (error: OrderBechargeErrorSchema) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  });

  const { mutate: removeFromMarketingBlacklist, isLoading: isRemoveFromMarketingBlacklistLoading } =
    useRemoveFromMarketingBlacklist({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries(getGetUserGeneralQueryKey(user.userId));
          enqueueSnackbar(t('success'), { variant: 'success' });
        },
        onError: (error: OrderBechargeErrorSchema) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        },
      },
    });

  const handleMarketingBlacklist = () => {
    if (isMarketingBlacklisted) {
      removeFromMarketingBlacklist({ userId: Number(user.userId) });
    } else {
      const unixExpirationDate = expirationDate ? getUnixTime(expirationDate) : null;
      addToMarketingBlacklist({ userId: Number(user.userId), data: { expirationDate: unixExpirationDate } });
    }
  };

  if (isAddToMarketingBlacklistLoading || isRemoveFromMarketingBlacklistLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <GenericField
      value={isMarketingBlacklisted}
      error={isMarketingBlacklisted}
      tooltip={
        isMarketingBlacklisted
          ? t('lookup.verification.removeFromBlacklistTitle')
          : t('lookup.verification.addToBlacklistTitle')
      }
      description={
        isMarketingBlacklisted
          ? t('lookup.verification.removeFromBlacklistDescription')
          : t('lookup.verification.addToBlacklistDescription')
      }
      togglePermissions={
        isMarketingBlacklisted
          ? OnlineCasinoPermissions.removeFromMarketingBlacklist
          : OnlineCasinoPermissions.addToMarketingBlacklist
      }
      onToggle={handleMarketingBlacklist}
      withDate={!isMarketingBlacklisted}
      dateLabel={`${t('expirationDate')} (${t('optional')})`}
      dateValue={expirationDate}
      setDateValue={setExpirationDate}
    />
  );
};

export default MarketingBlacklistedField;
