import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetPlayerTopTransactions } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Link, Stack, TextField } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { endOfDay, getUnixTime, sub } from 'date-fns';

import { StyledDataGrid } from '@greenisland-common/components/atoms';
import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import { transformCurrencyV2, transformUnixDateV2 } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

type FormData = {
  threshold: number;
};

const TopTransactions = () => {
  const canReadTopTransactions = usePermission(OnlineCasinoPermissions.getPlayerTopTransactions);
  return canReadTopTransactions ? <TopTransactionsInnerChild /> : null;
};

const currentDate = new Date();

const TopTransactionsInnerChild = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryThreshold = Number(searchParams.get('threshold'));
  const startDate = searchParams.get('startdate');
  const endDate = searchParams.get('enddate');
  const start = startDate ? Number(startDate) : getUnixTime(sub(currentDate, { days: 1 }));
  const end = endDate ? Number(endDate) : getUnixTime(endOfDay(currentDate));
  const { data: topTransactions, isLoading: fetching } = useGetPlayerTopTransactions({
    startdate: start,
    enddate: end,
    threshold: queryThreshold,
  });

  const methods = useForm<FormData>({
    defaultValues: {
      threshold: queryThreshold > 0 ? queryThreshold : 1000,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = methods;

  const threshold = watch('threshold');

  const onSubmit = (data: FormData) => {
    replaceUrlQueryParameters({ startDate: start, endDate: end, threshold: data.threshold }, setSearchParams);
  };

  const columns: GridColDef[] = useMemo(() => {
    if (!topTransactions?.length) return [];

    return Object.keys(topTransactions[0]).map(key => {
      switch (key) {
        case 'username':
          return {
            field: 'username',
            headerName: t('username'),
            flex: 1,
            renderCell: params => <Link href={`../../../users/${params.row.userId}/details`}>{params.value}</Link>,
          } as GridColDef;
        case 'timestamp':
          return {
            ...transformUnixDateV2('timestamp', t),
            flex: 1,
          };
        case 'cash':
        case 'bonus':
          return {
            ...transformCurrencyV2(key, t),
            flex: 1,
          };
        default:
          return { field: key, headerName: t(key), flex: 1 };
      }
    });
  }, [t, topTransactions]);

  return (
    <Stack spacing={2}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
            <DateRangePicker
              unixStartDate={start * 1000}
              unixEndDate={end * 1000}
              onChange={(startDate, endDate) => {
                replaceUrlQueryParameters({ startDate, endDate, threshold }, setSearchParams);
              }}
            />
            <TextField
              label={t('threshold')}
              name="threshold"
              fullWidth
              error={!!errors.threshold}
              inputRef={register({ required: true })}
              size="small"
            />
            <Button variant="contained" color="primary" type="submit">
              {t('search')}
            </Button>
          </Stack>
        </form>
      </FormProvider>
      <StyledDataGrid
        columns={columns}
        rows={topTransactions ?? []}
        loading={fetching}
        autoHeight
        disableSelectionOnClick
        pagination
        getRowId={row => row.userId}
        initialState={{
          columns: {
            columnVisibilityModel: {
              userId: false,
            },
          },
        }}
      />
    </Stack>
  );
};

export default TopTransactions;
