/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type { Operator } from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Get operator settings
 * @summary Get operator settings
 */
export const useGetOperatorHook = () => {
  const getOperator = useCustomInstance<Operator>();

  return (signal?: AbortSignal) => {
    return getOperator({ url: `/operator`, method: 'get', signal });
  };
};

export const getGetOperatorQueryKey = () => [`/operator`] as const;

export const useGetOperatorQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetOperatorHook>>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOperatorHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOperatorHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOperatorQueryKey();

  const getOperator = useGetOperatorHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetOperatorHook>>>> = ({ signal }) =>
    getOperator(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetOperatorQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetOperatorHook>>>>;
export type GetOperatorQueryError = unknown;

/**
 * @summary Get operator settings
 */
export const useGetOperator = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetOperatorHook>>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetOperatorHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetOperatorQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Put operator settings
 * @summary Put operator settings
 */
export const usePutOperatorHook = () => {
  const putOperator = useCustomInstance<Operator>();

  return (operator: Operator) => {
    return putOperator({
      url: `/operator`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: operator,
    });
  };
};

export const usePutOperatorMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutOperatorHook>>>,
    TError,
    { data: Operator },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutOperatorHook>>>,
  TError,
  { data: Operator },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putOperator = usePutOperatorHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutOperatorHook>>>,
    { data: Operator }
  > = props => {
    const { data } = props ?? {};

    return putOperator(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutOperatorMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePutOperatorHook>>>>;
export type PutOperatorMutationBody = Operator;
export type PutOperatorMutationError = unknown;

/**
 * @summary Put operator settings
 */
export const usePutOperator = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutOperatorHook>>>,
    TError,
    { data: Operator },
    TContext
  >;
}) => {
  const mutationOptions = usePutOperatorMutationOptions(options);

  return useMutation(mutationOptions);
};
