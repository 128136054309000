import './theme.css';
import { adaptV4Theme } from '@mui/material';
import { createTheme, darken, lighten, Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
declare module '@mui/material/styles/createPalette' {
  interface TypeText {
    hint: string;
  }
}

const fontFamily = [
  'Montserrat',
  'sans-serif',
  'Roboto',
  'Helvetica Neue',
  'Open Sans',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Arial',
  'sans-serif',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
].join(',');

const palette = {
  primary: {
    light: lighten('#2F855A', 0.1),
    main: '#2F855A',
    dark: darken('#2F855A', 0.1),
  },
  secondary: {
    light: lighten('#3B88CF', 0.1),
    main: '#3B88CF',
    dark: darken('#3B88CF', 0.1),
  },
  info: {
    light: '#51B9F6', //! GOTTA RE-THINK THIS ONE
    main: '#20A4F3',
    dark: '#0C98E9',
  },
  error: {
    light: lighten('#EF2917', 0.1),
    main: '#EF2917',
    dark: darken('#EF2917', 0.1),
  },
  warning: {
    light: lighten('#F6AE2D', 0.1),
    main: '#F6AE2D',
    dark: darken('#F6AE2D', 0.1),
  },
  text: {
    primary: '#070707',
    disabled: '#C1C1C1',
    hint: '#707070',
  },
  background: {
    default: '#FFFFFF',
    paper: '#F8F7F9',
  },
  array: {
    headers: '#6f6f6f',
  },
  // Can be used to set all shades of grey
  grey: {
    900: '#070707',
  },
};

const typography = {
  htmlFontSize: 16,
  fontWeightRegular: 400,
  fontFamily: fontFamily,
  h1: {
    fontWeight: 300, //light
    fontSize: '6rem', //96px
    letterSpacing: -1.5,
  },
  h2: {
    fontWeight: 300, //light
    fontSize: '3.75rem', //60px
    letterSpacing: -0.5,
  },
  h3: {
    fontWeight: 400, //regular
    fontSize: '3rem', //48px
    letterSpacing: 0,
  },
  h4: {
    fontWeight: 400, //regular
    fontSize: '2.125rem', //34px
    letterSpacing: 0.25,
  },
  h5: {
    fontWeight: 400, //regular
    fontSize: '1.5rem', //24px
    letterspacing: 0,
  },
  h6: {
    fontWeight: 500, //medium
    fontSize: '1.25rem', //20px
    letterSpacing: 0.15,
  },
  subtitle1: {
    fontWeight: 400, //regular
    fontSize: '1rem', //16px
    letterSpacing: 0.15,
  },
  subtitle2: {
    fontWeight: 400, //regular
    fontSize: '.875rem', //14px
    letterSpacing: 0.1,
  },
  body1: {
    fontWeight: 400, //regular
    fontSize: '1rem', //16px
    letterSpacing: 0.5,
  },
  body2: {
    fontWeight: 400, //regular
    fontSize: '.875rem', //14px
    letterSpacing: 0.25,
  },
  button: {
    fontWeight: 500, //medium
    fontSize: '.875rem', //14px
    letterSpacing: 1.25,
    textTransform: 'uppercase',
  },
  caption: {
    fontWeight: 400, //regular
    fontSize: '.75rem', //12px
    letterSpacing: 0.4,
  },
  overline: {
    fontWeight: 400, //regular
    fontSize: '.625rem', //10px
    letterSpacing: 1.5,
    textTransform: 'uppercase',
  },
};

const defaultTheme = createTheme();

export let theme = createTheme(
  defaultTheme,
  adaptV4Theme({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    typography,
    palette,
    shadows: [
      'none',
      '0 1px 3px rgba(0,0,0,.20), 0 1px 1px rgba(0,0,0,.14), 0 2px 1px rgba(0,0,0,.12)', //1
      '0 1px 5px rgba(0,0,0,.20), 0 2px 2px rgba(0,0,0,.14), 0 3px 1px rgba(0,0,0,.12)', //2
      '0 1px 8px rgba(0,0,0,.20), 0 3px 4px rgba(0,0,0,.14), 0 3px 3px rgba(0,0,0,.12)', //3
      '0 2px 4px rgba(0,0,0,.20), 0 4px 5px rgba(0,0,0,.14), 0 1px 10px rgba(0,0,0,.12)', //4
      'none',
      '0 3px 5px rgba(0,0,0,.20), 0 6px 10px rgba(0,0,0,.14), 0 1px 18px rgba(0,0,0,.12)', //6
      'none',
      '0 5px 5px rgba(0,0,0,.20), 0 8px 10px rgba(0,0,0,.14), 0 3px 14px rgba(0,0,0,.12)', //8
      'none',
      'none',
      'none',
      '0 7px 8px rgba(0,0,0,.20), 0 12px 17px rgba(0,0,0,.14), 0 5px 22px rgba(0,0,0,.12)', //12
      'none',
      'none',
      'none',
      '0 8px 10px rgba(0,0,0,.20), 0 16px 24px rgba(0,0,0,.14), 0 6px 30px rgba(0,0,0,.12)', //16
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      '0 11px 15px rgba(0,0,0,.20), 0 24px 38px rgba(0,0,0,.14), 0 9px 46px rgba(0,0,0,.12)', //24
    ],
    overrides: {
      MuiTypography: {
        root: {
          fontFamily: fontFamily,
        },
      },
      // Overrides for input fields (mui datepickers, ...) as in GIUI
      MuiFormLabel: {
        root: {
          color: 'rgba(0, 0, 0, 0.66)',
          position: 'relative',
        },
      },
      MuiInputBase: {
        input: {
          '&::placeholder': {
            transition: undefined,
            opacity: '0.42 !important',
          },
        },
      },
      MuiTextField: {
        root: {
          paddingTop: 0,
        },
      },
      MuiInputLabel: {
        formControl: {
          transform: undefined,
        },
      },
      MuiFormHelperText: {
        root: {
          color: '#AAAAAA',
        },
      },
      MuiLink: {
        root: {
          fontFamily: fontFamily,
        },
      },
      MuiCard: {
        root: {
          backgroundColor: palette.background.default,
          padding: defaultTheme.spacing(1),
        },
      },
      MuiCardHeader: {
        root: {
          paddingTop: defaultTheme.spacing(0.5),
          paddingBottom: defaultTheme.spacing(0.5),
        },
        title: {
          fontSize: typography.h6.fontSize,
          fontWeight: defaultTheme.typography.fontWeightMedium,
          textTransform: 'capitalize',
        },
      },
      MuiCardContent: {
        root: {
          '&:last-child': {
            paddingBottom: defaultTheme.spacing(0.5),
          },
        },
      },
      MuiChip: {
        root: {
          borderWidth: '1px',
          padding: '10px 5px',
          minHeight: 30,
        },
      },
      MuiDialogContent: {
        root: {
          // https://github.com/mui/material-ui/issues/31185
          paddingTop: `10px !important`,
        },
      },
      MuiDialogActions: {
        root: {
          padding: '12px',
        },
      },
    },
  })
);

let globalCSS = {
  body: {
    fontSize: '16px',
    fontFamily: fontFamily,
  },
  // You should target [class*="MuiButton-root"] instead if you nest themes.
  a: {
    textDecoration: 'none',
  },
  h1: {
    fontSize: '3em',
    lineHeight: '1.15em',
  },
  h2: {
    fontSize: '2.6em',
  },
  h3: {
    fontSize: '1.825em',
    lineHeight: '1.4em',
  },
  h4: {
    fontSize: '1.3em',
    lineHeight: '1.4em',
  },
  h5: {
    fontSize: '1.25em',
    lineHeight: '1.4em',
  },
  h6: {
    fontSize: '0.8em',
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  input: {
    fontSize: '100%',
  },
  select: {
    fontSize: '100%',
  },
  button: {
    fontFamily: fontFamily,
  },
};

const scrollbarCSS = {
  /* The emerging W3C standard
   that is currently Firefox-only */
  '*': {
    scrollbarHeight: 'thin',
    scrollbarWidth: 'thin',
    scrollbarColor: '#b8b8b8 transparent',
  },
  /* Works on Chrome/Edge/Safari */
  '*::-webkit-scrollbar': {
    width: '8px',
    height: '8px',
  },
  '*::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '*::-webkit-scrollbar-thumb': {
    backgroundColor: '#b8b8b8',
    borderRadius: '10px',
    cursor: 'pointer',
  },
};

function isWindows() {
  const platform = window.navigator.platform,
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  return windowsPlatforms.indexOf(platform) !== -1;
}

if (isWindows()) {
  globalCSS = { ...globalCSS, ...scrollbarCSS };
}

export const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': globalCSS,
})(() => null);

// theme = responsiveFontSizes(theme);

export const setTheme = (newTheme: Theme) => {
  theme = newTheme;
};
