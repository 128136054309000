/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */

export type RequirementValueType = (typeof RequirementValueType)[keyof typeof RequirementValueType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequirementValueType = {
  STRING: 'STRING',
  ARRAY_OF_STRINGS: 'ARRAY_OF_STRINGS',
  INTEGER: 'INTEGER',
} as const;
