import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useGetUserAvailableBonuses } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { endOfDay, getUnixTime, sub } from 'date-fns';

import { Link, StyledDataGrid } from '@greenisland-common/components/atoms';
import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import {
  formatCurrency,
  NoWrapSpan,
  transformBooleanV2,
  transformStatusV2,
  transformUnixDateV2,
} from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import { EventStreamParamsType } from '../Constants/Eventstreams';

const AvailableBonusesPage = () => {
  const canReadAvailableBonuses = usePermission(OnlineCasinoPermissions.getUserAvailableBonuses);
  return canReadAvailableBonuses ? <AvailableBonusesPageInnerChild /> : null;
};

const currentDate = new Date();

const AvailableBonusesPageInnerChild = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startdate');
  const endDate = searchParams.get('enddate');
  const start = startDate ? Number(startDate) : getUnixTime(sub(currentDate, { days: 30 }));
  const end = endDate ? Number(endDate) : getUnixTime(endOfDay(currentDate));
  const { data: availableBonuses, isLoading: fetching } = useGetUserAvailableBonuses(userId, { start, end });

  const columns: GridColDef[] = [
    transformUnixDateV2('expiresOn', t),
    transformUnixDateV2('createdOn', t),
    transformStatusV2('status', t),
    transformBooleanV2('sports', t),
    {
      field: 'conditions',
      headerName: t('conditions'),
      flex: 1,
      renderCell: params => (
        <NoWrapSpan value={`${formatCurrency(params.row.amount)} playthrough ${params.row.playthrough}`} />
      ),
    },
    {
      field: 'history',
      headerName: '',
      flex: 1,
      sortable: false,
      renderCell: params => (
        <Link
          to={`../../../lookup/eventstreams?${
            EventStreamParamsType.STREAM_ID
          }=availablepromotion/${params.row.streamId.toLowerCase()}`}
        >
          {t('history')}
        </Link>
      ),
    },
  ];

  const rows =
    availableBonuses?.map((item, index) => ({
      id: index,
      ...item,
    })) || [];

  return (
    <Stack spacing={2}>
      <DateRangePicker
        unixStartDate={start * 1000}
        unixEndDate={end * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
        }}
      />
      <StyledDataGrid
        columns={columns}
        rows={rows}
        loading={fetching}
        autoHeight
        pagination
        pageSize={20}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        disableSelectionOnClick
      />
    </Stack>
  );
};

export default AvailableBonusesPage;
