import { useSearchParams } from 'react-router-dom';
import { SortOrderParamParameter } from '@greenisland-api';

enum TestResponsesSearchFilters {
  ID = 'id',
  USER_ID = 'userid',
  SORT_BY_ORDER = 'sortbyorder',
}

const useGetTestResponsesFiltersQuery = () => {
  const [searchParams] = useSearchParams();

  const idQuery = searchParams.get(TestResponsesSearchFilters.ID);
  const userIdQuery = searchParams.get(TestResponsesSearchFilters.USER_ID);
  const sortByOrderQuery =
    searchParams.get(TestResponsesSearchFilters.SORT_BY_ORDER) || SortOrderParamParameter.descending;

  return {
    idQuery,
    userIdQuery,
    sortByOrderQuery,
  };
};

export { useGetTestResponsesFiltersQuery, TestResponsesSearchFilters };
