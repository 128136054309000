/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EPortalDepositStatusSchema = (typeof EPortalDepositStatusSchema)[keyof typeof EPortalDepositStatusSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EPortalDepositStatusSchema = {
  Undefined: 'Undefined',
  Unknown: 'Unknown',
  New: 'New',
  InProcess: 'InProcess',
  Done: 'Done',
  Failed: 'Failed',
  Cancelled: 'Cancelled',
  Refused: 'Refused',
  RefundRequested: 'RefundRequested',
  RefundFailed: 'RefundFailed',
  Refunded: 'Refunded',
  ChargedBack: 'ChargedBack',
  DisputeLost: 'DisputeLost',
  DisputeWon: 'DisputeWon',
  Expired: 'Expired',
} as const;
