import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControlLabel, Switch, SxProps, Theme } from '@mui/material';

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
  defaultValue?: boolean;
  sx?: SxProps<Theme> | undefined;
}

const SwitchInput = ({ name, label, disabled = false, defaultValue, sx }: Props) => {
  const { control } = useFormContext();
  return (
    <FormControlLabel
      label={label}
      sx={{
        ...sx,
        '.MuiTypography-root': {
          paddingLeft: '10px',
          fontWeight: 600,
          fontSize: '15px',
        },
      }}
      control={
        <Controller
          defaultValue={defaultValue ?? false}
          control={control}
          name={name}
          render={({ value, onChange }) => (
            <Switch edge="end" onChange={event => onChange(event.target.checked)} checked={value} disabled={disabled} />
          )}
        />
      }
    />
  );
};

export default SwitchInput;
