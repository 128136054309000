import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faAdd, faPencil } from '@fortawesome/free-solid-svg-icons';
import { BoosterPackSchema, SortOrderParamParameter, useGetBoosterPacks } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, LinearProgress, Link, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-pro';

import {
  DataGridContainer,
  DataGridPagination,
  FontAwesomeIcon,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';
import Select from '@greenisland-common/components/atoms/Select';

import BoosterPackDialog from './components/forms/BoosterPackDialog';
import { BoosterActionMode, BoosterPackDialogSchema } from './components/forms/helpers';
import { usePermission } from '../../../../app/hooks';

const PAGE_SIZE = 25;

type Row = BoosterPackDialogSchema;

const BoosterPacks = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const canReadBoosterPacks = usePermission(OnlineCasinoPermissions.getBoosterPacks);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [sortOrder, setSortOrder] = useState<SortOrderParamParameter>(SortOrderParamParameter.descending);

  const [mode, setMode] = useState<BoosterActionMode | null>(null);
  const [selectedBoosterPack, setSelectedBoosterPack] = useState<Row | undefined>(undefined);

  const {
    data: boosterPacks,
    isLoading,
    isError,
  } = useGetBoosterPacks(
    {
      page,
      pageSize,
      sortOrder,
    },
    {
      query: { enabled: canReadBoosterPacks },
    }
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'id',
        headerName: capitalize(t('id')),
        sortable: false,
        minWidth: 80,
        flex: 0.05,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value}</Typography>;
        },
      },
      {
        field: 'name',
        headerName: capitalize(t('name')),
        sortable: false,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value}</Typography>;
        },
      },
      {
        field: 'description',
        headerName: capitalize(t('description')),
        sortable: false,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ value }) => {
          return (
            <Tooltip title={value} arrow>
              <Box component="p" textOverflow="ellipsis" overflow="hidden" width="100%">
                {value || '-'}
              </Box>
            </Tooltip>
          );
        },
      },
      {
        field: 'skinType',
        headerName: capitalize(t('skinType')),
        sortable: false,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value || '-'}</Typography>;
        },
      },
      {
        headerName: capitalize(t('expirationDuration')),
        field: 'expirationDuration',
        renderCell: ({ value }) => {
          return <Typography variant="body2">{value ? `${value} ${t('days')}` : '-'}</Typography>;
        },
        minWidth: 150,
        flex: 0.1,
      },
      {
        field: 'redirectUrl',
        headerName: capitalize(t('redirectUrl')),
        sortable: false,
        minWidth: 130,
        flex: 0.1,
        renderCell: ({ value }) => {
          if (value) {
            return (
              <Link href={value} target="_blank">
                {value}
              </Link>
            );
          }
          return '-';
        },
      },

      {
        field: 'actions',
        type: 'actions',
        minWidth: 150,
        flex: 0.1,
        headerName: capitalize(t('actions')),
        getActions: (params: GridRowParams<Row>) => [
          <>
            <Tooltip title={t('edit')} key="details">
              <>
                <GridActionsCellItem
                  title={t('edit')}
                  label={t('edit')}
                  icon={<FontAwesomeIcon icon={faPencil} />}
                  onClick={() => {
                    if (params?.id) {
                      setMode(BoosterActionMode.EDIT);
                      setSelectedBoosterPack(params.row);
                    }
                  }}
                  key="edit"
                />
              </>
            </Tooltip>
          </>,
        ],
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      boosterPacks?.items?.map<BoosterPackSchema>(pack => ({
        ...pack,
      })) ?? [],
    [boosterPacks?.items]
  );

  return (
    <Stack spacing={2}>
      <PermissionWrapper
        errorMessage={t('marketing.boosterPacks.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getBoosterPacks}
      >
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              [theme.breakpoints.up('xs')]: {
                flexDirection: 'column',
                alignItems: 'initial',
                gap: 2,
              },
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                alignItems: 'center',
                gap: 0,
              },
            }}
          >
            <Button
              onClick={() => setMode(BoosterActionMode.CREATE)}
              variant="contained"
              size="medium"
              startIcon={<FontAwesomeIcon icon={faAdd} />}
              sx={{
                [theme.breakpoints.up('xs')]: {
                  mr: 0,
                },
                [theme.breakpoints.up('md')]: {
                  mr: 2,
                },
              }}
            >
              {t('marketing.boosterCards.titles.addBoosterPack')}
            </Button>
            <Select
              id={t('sortById')}
              label={capitalize(t('sortById'))}
              value={sortOrder}
              onChange={event => setSortOrder(event.target.value as SortOrderParamParameter)}
              options={Object.values(SortOrderParamParameter)}
            />
          </Box>
          <DataGridContainer>
            <LimitedDataGrid
              density="compact"
              autoHeight
              rowHeight={70}
              loading={isLoading}
              rows={rows}
              columns={columns}
              pagination
              page={page}
              rowCount={boosterPacks?.pagingDetails?.totalItems}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={(page: number) => {
                if (!isLoading) {
                  setPage(page);
                }
              }}
              onPageSizeChange={setPageSize}
              components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
        </>
      </PermissionWrapper>
      {mode === BoosterActionMode.CREATE ? <BoosterPackDialog open={true} onClose={() => setMode(null)} /> : null}
      {mode === BoosterActionMode.EDIT ? (
        <BoosterPackDialog open={true} onClose={() => setMode(null)} selectedCard={selectedBoosterPack} />
      ) : null}
    </Stack>
  );
};

export default BoosterPacks;
