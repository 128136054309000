import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { EComparisonSchema } from '@greenisland-api';
import { Box, capitalize, Switch } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { SelectInput } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

interface ContentFilterRTPFilterFieldProps {
  isRtpFilterOn: boolean;
  setIsRtpFilterOn: Dispatch<SetStateAction<boolean>>;
}

const ContentFilterRTPFilterField = ({ isRtpFilterOn, setIsRtpFilterOn }: ContentFilterRTPFilterFieldProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      sx={{
        [theme.breakpoints.up('xs')]: {
          flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
        },
      }}
    >
      <Box display="flex" alignItems="center">
        <p>{capitalize(t('content.contentFilter.tableHeaders.rtpFilter.title'))}</p>
        <Switch checked={isRtpFilterOn} onChange={() => setIsRtpFilterOn(!isRtpFilterOn)} />
      </Box>
      {isRtpFilterOn && (
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          sx={{
            [theme.breakpoints.up('xs')]: {
              flexDirection: 'column',
            },
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
            },
          }}
        >
          <Box
            style={{ color: 'grey' }}
            sx={{
              [theme.breakpoints.up('xs')]: {
                width: '100%',
              },
              [theme.breakpoints.up('md')]: {
                width: '20%',
              },
            }}
          >
            {capitalize(t('content.contentFilter.tableHeaders.rtpFilter.excludeGames'))}:
          </Box>
          <SelectInput
            size="small"
            name={`rtpFilter.comparison`}
            label={t('content.contentFilter.tableHeaders.rtpFilter.comparison')}
            rules={{ required: t('fieldIsRequired') }}
            options={Object.keys(EComparisonSchema).map(key => ({ value: key, label: t(key) }))}
            defaultValue={EComparisonSchema.None}
            sx={{
              [theme.breakpoints.up('xs')]: {
                width: '100%',
              },
              [theme.breakpoints.up('md')]: {
                width: '40%',
              },
            }}
          />
          <Input
            size="small"
            type="number"
            label={t('content.contentFilter.tableHeaders.rtpFilter.threshold')}
            required
            name={`rtpFilter.threshold`}
            rules={{ required: t('fieldIsRequired'), setValueAs: value => parseInt(value) }}
            defaultValue={0}
            sx={{
              [theme.breakpoints.up('xs')]: {
                width: '100%',
              },
              [theme.breakpoints.up('md')]: {
                width: '40%',
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ContentFilterRTPFilterField;
