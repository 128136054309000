import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, DataGridPlain, DataGridPlainHeader } from '@greenisland-common/components/atoms';

import { formatCurrency } from '../../../../../../app/helpers/transformFunctions';

interface Props {
  refunds?: number;
  refundsTestUser?: number;
}

const headers: GridColumns = [
  {
    headerName: '',
    field: 'title',
    renderCell: ({ value }) => <DataGridPlainHeader value={value} />,
    minWidth: 200,
    flex: 2,
  },
  { headerName: '', field: 'value', renderCell: ({ value }) => value, minWidth: 110, flex: 1 },
];

const Refunds = ({ refunds, refundsTestUser }: Props) => {
  const { t } = useTranslation();

  const rows: GridRowsProp = [
    { id: 1, title: t('online'), value: formatCurrency(refunds ?? 0) },
    { id: 2, title: t('testUser'), value: formatCurrency(refundsTestUser ?? 0) },
  ];

  return (
    <Card sx={{ pl: 0, pr: 0, mb: 2, maxWidth: 550 }}>
      <CardHeader title={t('refunds')} />
      <CardContent sx={{ pl: 0, pr: 0 }}>
        <DataGridContainer>
          <DataGridPlain columns={headers} rows={rows} />
        </DataGridContainer>
      </CardContent>
    </Card>
  );
};

export default Refunds;
