import { Dispatch, SetStateAction } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ApproveCancelPlayingBreakRequestSchema } from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import { Button, capitalize, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

import { SelectInput } from '@greenisland-common/components/atoms';

import { useTenantContext } from '../../../../../../app/context/TenantContext';
import { PlayingBreakRequestType, useGetCooldownValues } from './PlayinBreakFormContext';

interface Props {
  isLoading: boolean;
  title: string;
  requestDialogType: PlayingBreakRequestType | null;
  setRequestDialogType: Dispatch<SetStateAction<PlayingBreakRequestType | null>>;
  onSubmit: (data: ApproveCancelPlayingBreakRequestSchema) => void;
}

const PlayingBreakDialog = ({ isLoading, title, requestDialogType, setRequestDialogType, onSubmit }: Props) => {
  const { t } = useTranslation();
  const { playingBreakConfigurations } = useTenantContext();
  const cooldownValues = useGetCooldownValues();

  const methods = useForm<ApproveCancelPlayingBreakRequestSchema>({
    defaultValues: {
      cooldownDays: playingBreakConfigurations.timeoutDays,
      decisionReason: '',
    },
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  return (
    <Dialog fullWidth open={Boolean(requestDialogType)} onClose={() => setRequestDialogType(null)}>
      <DialogTitle>{capitalize(title)}</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            {requestDialogType === PlayingBreakRequestType.CANCEL && (
              <SelectInput
                name="cooldownDays"
                label={t('playingbreak.cooldownDays')}
                rules={{ required: t('fieldIsRequired') }}
                options={cooldownValues}
                defaultValue=""
              />
            )}
            <TextField
              fullWidth
              name="decisionReason"
              label={t('reason')}
              error={Boolean(errors['decisionReason'])}
              helperText={errors['decisionReason']?.message}
              inputRef={register({ required: t('fieldIsRequired') })}
              sx={{ my: 1 }}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={() => setRequestDialogType(null)}>
              {t('cancel')}
            </Button>
            <LoadingButton variant="contained" color="error" type="submit" disabled={isLoading}>
              {t('proceed')}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default PlayingBreakDialog;
