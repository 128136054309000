/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EDepositStatusSchema = (typeof EDepositStatusSchema)[keyof typeof EDepositStatusSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EDepositStatusSchema = {
  Unknown: 'Unknown',
  Started: 'Started',
  Pending: 'Pending',
  Completed: 'Completed',
  ManuallyCompleted: 'ManuallyCompleted',
  Declined: 'Declined',
  Failed: 'Failed',
  Cancelled: 'Cancelled',
  Refused: 'Refused',
  RefundRequested: 'RefundRequested',
  RefundFailed: 'RefundFailed',
  Refunded: 'Refunded',
  ChargedBack: 'ChargedBack',
  ChargedBackManually: 'ChargedBackManually',
  DisputeLost: 'DisputeLost',
  DisputeWon: 'DisputeWon',
  Expired: 'Expired',
} as const;
