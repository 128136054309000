import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SortOrderParamParameter, useGetBasicAccountData } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Card, CardContent } from '@mui/material';
import { usePermission } from 'src/app/hooks';
import TabMenu, { Tab } from 'src/routes/components/TabMenu';

import Select from '@greenisland-common/components/atoms/Select';

import LoyaltySpendablePointsTransactionsTable from './Tables/LoyaltySpendablePointsTransactionsTable';
import LoyaltyStatusPointsTransactionsTable from './Tables/LoyaltyStatusPointsTransactionsTable';
import { EventStreamParamsType } from '../Constants/Eventstreams';

const UserLoyaltyTransactionsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId = '' } = useParams();
  const [sortOrder, setSortDate] = useState<SortOrderParamParameter | undefined>(SortOrderParamParameter.descending);
  const canReadBasicAccountData = usePermission(OnlineCasinoPermissions.getBasicAccountData);
  const { data: accountData } = useGetBasicAccountData(userId.toString(), {
    query: { enabled: canReadBasicAccountData },
  });

  const tabs: Tab[] = [
    {
      title: 'loyaltyTransactions.tabTitle.statusPoints',
      content: <LoyaltyStatusPointsTransactionsTable userId={parseInt(userId)} sortOrder={sortOrder} />,
      visible: usePermission(OnlineCasinoPermissions.getLoyaltyStatusPointsTx),
    },
    {
      title: 'loyaltyTransactions.tabTitle.spendablePoints',
      content: <LoyaltySpendablePointsTransactionsTable userId={parseInt(userId)} sortOrder={sortOrder} />,
      visible: usePermission(OnlineCasinoPermissions.getLoyaltySpendablePointsTx),
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between" gap={1} flexWrap="wrap">
        <Select
          id="sortOrder"
          label={capitalize(t('sortOrder'))}
          value={sortOrder}
          onChange={event => setSortDate(event.target.value as SortOrderParamParameter)}
          options={Object.values(SortOrderParamParameter)}
        />
        {canReadBasicAccountData && (
          <Button
            size="small"
            variant="outlined"
            onClick={() =>
              userId &&
              navigate(
                `../../lookup/eventstreams?${EventStreamParamsType.STREAM_ID}=loyaltyv2/${accountData?.account?.accountId}`
              )
            }
          >
            {t('loyaltyevents')}
          </Button>
        )}
      </Box>
      <Card sx={{ mt: 2 }}>
        <CardContent>
          <TabMenu tabs={tabs} />
        </CardContent>
      </Card>
    </>
  );
};

export default memo(UserLoyaltyTransactionsPage);
