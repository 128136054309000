import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useGetBoosterPackCards } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Stack, Typography, useTheme } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../../app/hooks';
import BoosterCardPackPoolExtraSettings from './BoosterCardPackPoolExtraSettings';
import { BoosterPackDialogSchema } from './helpers';

interface Props {
  selectedCard?: BoosterPackDialogSchema;
}

const BoosterCardPackPoolSettings = ({ selectedCard }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const canReadBoosterPackCard = usePermission(OnlineCasinoPermissions.getBoosterPackCards);

  const { data: boosterPackCards } = useGetBoosterPackCards(
    {},
    {
      query: { enabled: canReadBoosterPackCard },
    }
  );

  const { control, errors } = useFormContext<BoosterPackDialogSchema>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ordinals',
  });

  const [isActiveCardSettings, setIsActiveCardSettings] = useState(false);

  useEffect(() => {
    if (fields?.length === 0) {
      setIsActiveCardSettings(false);
    } else if (selectedCard && selectedCard?.cardPool?.length) {
      setIsActiveCardSettings(true);
    }
  }, [fields?.length, selectedCard, t]);

  const addOrdinalHandler = useCallback(() => {
    if (!isActiveCardSettings) {
      setIsActiveCardSettings(true);
    }
    append({
      name: `${t('marketing.boosterPacks.titles.ordinal')}`,
    });
  }, [append, isActiveCardSettings, t]);

  const boosterOrdinalCardOptions = useMemo(() => {
    return (
      boosterPackCards?.items
        ?.sort((a, b) => a?.name?.localeCompare(b?.name))
        ?.map(entry => ({ label: `${entry.id} - ${entry.name}`, value: Number(entry.id) })) ?? []
    );
  }, [boosterPackCards?.items]);

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography fontWeight={500} variant="body1" fontSize="large">
          {capitalize(t('marketing.boosterPacks.titles.cardPoolSettings'))}
        </Typography>
        {errors?.ordinals && (
          <Typography ml={1} mt={0.5} variant="body2" fontSize="small" color={theme.palette.error.main}>
            ({capitalize(t('fieldIsRequired'))})
          </Typography>
        )}
      </Box>
      <Stack spacing={2} position="relative">
        {isActiveCardSettings ? (
          <Box display="flex" flexDirection="column">
            {fields?.map((entry, index) => {
              return (
                <Box
                  key={entry.id}
                  px={4}
                  py={1}
                  mb={2}
                  sx={{
                    border: '1px solid #eee',
                    borderRadius: '4px',
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Controller
                      name={`ordinals[${index}].name`}
                      control={control}
                      defaultValue={entry?.name}
                      render={() => (
                        <Typography id={entry?.id} fontWeight={600} variant="body2" fontSize="large" my={2}>
                          {`${entry?.name} ${index + 1}`}
                        </Typography>
                      )}
                    />
                    <FontAwesomeIcon
                      icon={faTrash}
                      sx={{ marginLeft: 1, color: 'error.main', cursor: 'pointer' }}
                      onClick={() => remove(index)}
                    />
                  </Box>
                  <BoosterCardPackPoolExtraSettings
                    ordinalIndex={index}
                    cardOptions={boosterOrdinalCardOptions}
                    selectedCard={selectedCard}
                  />
                </Box>
              );
            })}
          </Box>
        ) : null}
        <Button
          onClick={addOrdinalHandler}
          variant="contained"
          size="medium"
          startIcon={<FontAwesomeIcon icon={faAdd} />}
          sx={{ width: 'fit-content' }}
        >
          {t('marketing.boosterPacks.titles.ordinal')}
        </Button>
      </Stack>
    </>
  );
};

export default BoosterCardPackPoolSettings;
