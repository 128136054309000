import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';

import { GameFilterType } from '../constants';

export default function useUpdateGameFilter() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => new URLSearchParams(searchParams), [searchParams]);

  return useCallback(
    (key: string, value: string | string[]) => {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          params.set(GameFilterType.PAGE, '0');
          params.set(key, value.join(','));
        } else {
          params.delete(key);
        }
        const newQueryString = `?${params.toString().replace(/%2C/g, ',')}`;
        navigate(`${location.pathname}${newQueryString}`, { replace: true });
      } else {
        if (value === null || value === '') {
          searchParams.delete(GameFilterType.PAGE);
          searchParams.delete(key);
        } else {
          searchParams.set(GameFilterType.PAGE, '0');
          searchParams.set(key, value);
        }
        setSearchParams(searchParams, { replace: true });
      }
    },
    [location.pathname, navigate, params, searchParams, setSearchParams]
  );
}
