/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * Loyalty user bad request type:
  * `modelStateInvalid` - Invalid model has been provided.
  * `invalidUserId` - Requested user ID is invalid.
  * `userNotFound` - The requested user was not found.
  * `accountNotFound` - The account for the requested user was not found.
  * `pointsToAwardNegative` - The points to award have to be larger than zero.
  * `accountNotAllowedToEnlist` - Account is not allowed to enlist for loyalty.
  * `accountAlreadySameRank` - Account is already at the desired loyalty rank.

 */
export type ELoyaltyUserBadRequestTypeSchema =
  (typeof ELoyaltyUserBadRequestTypeSchema)[keyof typeof ELoyaltyUserBadRequestTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ELoyaltyUserBadRequestTypeSchema = {
  modelStateInvalid: 'modelStateInvalid',
  invalidUserId: 'invalidUserId',
  userNotFound: 'userNotFound',
  accountNotFound: 'accountNotFound',
  pointsToAwardNegative: 'pointsToAwardNegative',
  accountNotAllowedToEnlist: 'accountNotAllowedToEnlist',
  accountAlreadySameRank: 'accountAlreadySameRank',
} as const;
