import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EventModelSchema, PagedEventStreamSchema } from '@greenisland-api';
import { Permission } from '@greenisland-types/authorization';
import { LinearProgress } from '@mui/material';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getDateTimeFromUnix } from 'src/app/helpers/transformFunctions';
import usePermission from 'src/app/hooks/usePermission';

import { DataGridContainer, DataGridPagination, ErrorCard, StyledDataGrid } from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { prettyPrintJson } from '@greenisland-common/helpers';

type Row = EventModelSchema & { id: string | undefined };

interface Props {
  data?: PagedEventStreamSchema;
  isLoading: boolean;
  isError: boolean;
  permission: Permission;
  page: number;
  pageSize: number;
  onPageChange: Dispatch<SetStateAction<number>>;
  onPageSizeChange: Dispatch<SetStateAction<number>>;
}

const EventsStreamTable = ({ data, isLoading, isError, permission, ...pageConfig }: Props) => {
  const { t } = useTranslation();
  const hasPermission = usePermission(permission);

  const rows = useMemo(
    () => data?.events?.map(event => ({ ...event, id: JSON.parse(event.payload)['EventId'].toString() })) ?? [],
    [data]
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'timestamp',
        headerName: t('timestamp'),
        flex: 0.5,
        sortable: false,
        minWidth: 150,
        renderCell(params: GridRenderCellParams<Row>) {
          return params.row.timestamp > 0 ? (
            <span style={{ whiteSpace: 'nowrap' }}>{getDateTimeFromUnix(params.row.timestamp, true)}</span>
          ) : null;
        },
      },
      {
        field: 'eventName',
        headerName: t('eventName'),
        flex: 1,
        sortable: false,
        minWidth: 150,
      },
      {
        field: 'payload',
        headerName: t('payload'),
        flex: 2,
        renderCell: (params: GridRenderCellParams<Row>) => (
          <pre
            dangerouslySetInnerHTML={{ __html: prettyPrintJson(JSON.parse(params.row.payload), { linkUrls: true }) }}
          />
        ),
        sortable: false,
        minWidth: 150,
      },
    ],
    [t]
  );

  if (!hasPermission) return <ErrorCard severity="info">{t('common.noPermissionToSeeThePage', permission)}</ErrorCard>;

  if (isError) {
    return <ErrorState errorMessage={t('reporting.eventsStream.loadingError')} />;
  }

  return (
    <DataGridContainer>
      <StyledDataGrid
        density="compact"
        autoHeight
        loading={isLoading}
        rows={rows}
        columns={columns}
        pagination
        {...pageConfig}
        rowCount={data?.pagingDetails?.totalItems}
        paginationMode="server"
        disableDensitySelector
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        disableChildrenSorting
        disableChildrenFiltering
        disableMultipleColumnsSorting
        disableColumnResize
        disableColumnReorder
        isRowSelectable={() => false}
        components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
        componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
        getRowHeight={() => 'auto'}
        sx={{
          '& .MuiDataGrid-cell': {
            overflowWrap: 'anywhere',
          },
        }}
      />
    </DataGridContainer>
  );
};

export default EventsStreamTable;
