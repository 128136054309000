/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EPaymentCredentialWhitelistTypeSchema =
  (typeof EPaymentCredentialWhitelistTypeSchema)[keyof typeof EPaymentCredentialWhitelistTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EPaymentCredentialWhitelistTypeSchema = {
  cardHolderName: 'cardHolderName',
  email: 'email',
  iban: 'iban',
  wallet: 'wallet',
} as const;
