import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetBonusesBlacklist } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Link, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../app/hooks';

const BonusBlacklist = () => {
  const { t } = useTranslation();

  const canReadBonusesBlacklist = usePermission(OnlineCasinoPermissions.getBonusesBlacklist);
  const { data: bonusesBlacklist, isLoading: fetching } = useGetBonusesBlacklist({
    query: {
      enabled: canReadBonusesBlacklist,
    },
  });

  const columns: GridColDef[] = useMemo(() => {
    if (!bonusesBlacklist?.length) return [];

    return Object.keys(bonusesBlacklist[0]).map(key => {
      switch (key) {
        case 'username':
          return {
            field: 'username',
            headerName: t('username'),
            flex: 1,
            renderCell: params => <Link href={`../../../users/${params.row.userId}/details`}>{params.value}</Link>,
          } as GridColDef;
        default:
          return { field: key, headerName: t(key), flex: 1 };
      }
    });
  }, [bonusesBlacklist, t]);

  if (!canReadBonusesBlacklist) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <StyledDataGrid
        columns={columns}
        rows={bonusesBlacklist ?? []}
        loading={fetching}
        autoHeight
        disableSelectionOnClick
        getRowId={row => row.userId}
        initialState={{
          columns: {
            columnVisibilityModel: {
              userId: false,
            },
          },
        }}
      />
    </Stack>
  );
};

export default BonusBlacklist;
