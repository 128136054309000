import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { prettyPrintJson } from '@greenisland-common/helpers';

interface Props {
  poiEntries: string[] | undefined;
}

const KYCPOITable = ({ poiEntries }: Props) => {
  const { t } = useTranslation();

  if (!poiEntries || poiEntries.length === 0) {
    return null;
  }

  return (
    <>
      <Typography>{t('kyc.resource.poiEntries')}</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('kyc.headers.category')}</TableCell>
              <TableCell>{t('kyc.headers.positions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {poiEntries.map((entry, index) => {
              const { category, positions } = JSON.parse(entry);
              return (
                <TableRow key={index}>
                  <TableCell>{category}</TableCell>
                  <TableCell
                    dangerouslySetInnerHTML={{ __html: prettyPrintJson(positions, { linkUrls: true }) }}
                  ></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default memo(KYCPOITable);
