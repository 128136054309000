import GameRounds from '../../pages/GameServer/GameRounds';
import GameTransactions from '../../pages/GameServer/GameTransactions';
import { BaseRoute } from '../RouteInterfaces';

export const gameroundRoutes: Array<BaseRoute> = [
  {
    path: 'players/:externalId/gamerounds',
    name: 'Game Rounds',
    element: GameRounds,
    hiddenLink: true,
  },
  {
    path: 'players/:externalId/gamerounds/:gameroundId/transactions',
    name: 'Game Rounds',
    element: GameTransactions,
    hiddenLink: true,
  },
];

export default gameroundRoutes;
