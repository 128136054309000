import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useGetBechargeOrderDetails, VoucherSchema } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import {
  Box,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { GridColumns } from '@mui/x-data-grid-pro';

import {
  CheckboxIcon,
  DataGridContainer,
  FontAwesomeIcon,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';
import DataGridCustomPagination from '@greenisland-common/components/atoms/DataGridPagination/DataGridCustomPagination';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { getDateTimeFromUnix } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const PAGE_SIZE = 25;

interface Props {
  open: boolean;
  onClose: () => void;
  selectedBechargeOrderId: string;
}

const BechargeOrderDetailsDialog = ({ open, onClose, selectedBechargeOrderId }: Props) => {
  const { t } = useTranslation();

  const canReadBechargeOrderDetails = usePermission(OnlineCasinoPermissions.getBechargeOrderDetails);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const {
    data: orderDetails,
    isLoading,
    isError,
  } = useGetBechargeOrderDetails(selectedBechargeOrderId, {
    query: { enabled: canReadBechargeOrderDetails && !!selectedBechargeOrderId, keepPreviousData: true },
  });

  const columns = useMemo<GridColumns<VoucherSchema>>(
    () => [
      {
        field: 'id',
        headerName: capitalize(t('id')),
        sortable: false,
        minWidth: 90,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'product',
        headerName: capitalize(t('product')),
        sortable: false,
        minWidth: 90,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'value',
        headerName: capitalize(t('content.becharge.vouchersProducts.table.value')),
        sortable: false,
        minWidth: 90,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'serial',
        headerName: capitalize(t('content.becharge.vouchersProducts.table.serial')),
        sortable: false,
        minWidth: 90,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'expiry',
        headerName: capitalize(t('expiryDate')),
        sortable: true,
        minWidth: 100,
        flex: 0.1,
        renderCell: params => {
          const date = params.value;
          if (date) {
            const formattedDaysDate = getDateTimeFromUnix(date).split(' ')[0];
            const formattedHoursDate = getDateTimeFromUnix(date).split(' ')[1];
            return (
              <Box>
                <Typography variant="body2">{formattedDaysDate}</Typography>
                <Typography variant="body2" color="GrayText">
                  {formattedHoursDate}
                </Typography>
              </Box>
            );
          }
          return <Typography variant="body2">-</Typography>;
        },
      },
      {
        field: 'code',
        headerName: capitalize(t('code')),
        sortable: false,
        minWidth: 90,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'pin',
        headerName: capitalize(t('content.becharge.vouchersProducts.table.pin')),
        sortable: false,
        minWidth: 90,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      orderDetails?.vouchers?.map<VoucherSchema>(voucher => ({
        ...voucher,
      })) ?? [],
    [orderDetails?.vouchers]
  );

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setPageSize(value);
    setPage(0);
  };

  return (
    <Dialog fullWidth open={open} maxWidth="lg" onClose={onClose}>
      <PermissionWrapper
        errorMessage={t('content.becharge.orderDetails.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getBechargeOrderDetails}
      >
        <>
          <DialogTitle>{capitalize(t('content.becharge.vouchersProducts.table.orderDetailsTitle'))}</DialogTitle>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 16,
              top: 8,
            }}
          >
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
          <DialogContent>
            <Stack spacing={2}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" fontWeight={600} mr={1}>
                  {capitalize(t('content.becharge.orderDetails.table.completed'))}
                </Typography>
                <CheckboxIcon checked={orderDetails?.completed} />
              </Box>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" fontWeight={600} mr={1}>
                  {capitalize(t('content.becharge.orderDetails.table.orderId'))}
                </Typography>
                <span>{orderDetails?.orderId}</span>
              </Box>
              <DataGridContainer>
                <LimitedDataGrid
                  density="compact"
                  autoHeight
                  rowHeight={80}
                  loading={isLoading}
                  columns={columns}
                  rows={rows}
                  pagination
                  page={page}
                  pageSize={pageSize}
                  paginationMode="client"
                  components={{
                    LoadingOverlay: LinearProgress,
                    Footer: () => (
                      <>
                        <DataGridCustomPagination
                          page={page}
                          rowsPerPage={pageSize}
                          rowsPerPageOptions={[10, 25, 50, 100, 200]}
                          count={
                            orderDetails?.vouchers?.length ? Math.ceil(orderDetails?.vouchers?.length / pageSize) : 0
                          }
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          paginationMode="client"
                        />
                      </>
                    ),
                  }}
                />
              </DataGridContainer>
            </Stack>
            <DialogActions sx={{ px: 3, py: 2 }}>
              <Button variant="outlined" onClick={onClose}>
                {t('close')}
              </Button>
            </DialogActions>
          </DialogContent>
        </>
      </PermissionWrapper>
    </Dialog>
  );
};

export default BechargeOrderDetailsDialog;
