import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faAdd, faBoxArchive, faEye } from '@fortawesome/free-solid-svg-icons';
import { ContentFilterSchema, TranslationsSchemaItem, useGetContentFilters } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, LinearProgress, Stack, Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';

import {
  DataGridContainer,
  DataGridPagination,
  FontAwesomeIcon,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { ExtendedContentFilterSchema } from './components/Forms/helpers/ContentFilterFormContext';
import { usePermission } from '../../../../app/hooks';
import { ContentFilterArchiveDialog, ContentFilterCreationForm, ContentFilterDetailsDialogForm } from './components';

const DEFAULT_PAGE_SIZE = 10;

type Row = ContentFilterSchema;

enum ContentFilterAction {
  UPDATE = 'update',
  ARCHIVE = 'archive',
}

interface Props {
  customPageSize?: number;
  showCreateButton?: boolean;
  isSelectFilterEnabled?: boolean;
  onSelectedHandler?: (contentFilter: ExtendedContentFilterSchema) => void;
}

const ContentFilter = ({
  showCreateButton = true,
  customPageSize = DEFAULT_PAGE_SIZE,
  isSelectFilterEnabled = false,
  onSelectedHandler,
}: Props) => {
  const { t, i18n } = useTranslation();
  const canReadContentFilters = usePermission(OnlineCasinoPermissions.getContentFilters);
  const canGetContentFilterById = usePermission(OnlineCasinoPermissions.getContentFilterById);
  const canCreateContentFilters = usePermission(OnlineCasinoPermissions.createContentFilter);
  const [isCreateContentFilterDialogOpen, setIsCreateContentFilterDialog] = useState(false);
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [selectedContentFilterId, setSelectedContentFilterId] = useState<number | undefined>(undefined);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(customPageSize);

  const {
    data: contentFilters,
    isLoading,
    isError,
  } = useGetContentFilters(
    {
      pageSize: pageSize,
      page: page,
    },
    { query: { enabled: canReadContentFilters, keepPreviousData: true } }
  );

  const showContentFilterDetailsHandler = useCallback(
    (contentFilterId: number, action: ContentFilterAction) => {
      setSelectedContentFilterId(contentFilterId);

      if (action === ContentFilterAction.UPDATE) setIsDetailsDialogOpen(true);
      else setIsArchiveDialogOpen(true);
    },
    [setSelectedContentFilterId, setIsDetailsDialogOpen]
  );

  const selectedContentFilter = useMemo(
    () => contentFilters?.items?.find(({ id }) => id === selectedContentFilterId),
    [contentFilters?.items, selectedContentFilterId]
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'id',
        headerName: capitalize(t('content.contentFilter.tableHeaders.id')),
        sortable: false,
        minWidth: 120,
      },
      {
        field: 'name',
        headerName: capitalize(t('content.contentFilter.tableHeaders.name')),
        sortable: false,
        minWidth: 250,
      },
      {
        field: 'contentType',
        headerName: capitalize(t('type')),
        sortable: false,
        minWidth: 250,
      },
      {
        field: 'description',
        headerName: capitalize(t('content.contentFilter.tableHeaders.description')),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Row>) => {
          return (
            params?.row?.description
              ?.filter(
                (d: TranslationsSchemaItem) =>
                  d?.language && i18n.language.toUpperCase().startsWith(d.language.toUpperCase())
              )
              .map((d: TranslationsSchemaItem) => d.content)
              .join('') || ''
          );
        },
        sortable: false,
        minWidth: 300,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: isSelectFilterEnabled ? 200 : 150,
        getActions: (params: GridRowParams<Row>) => [
          <>
            {isSelectFilterEnabled && (
              <Button
                onClick={() => onSelectedHandler && onSelectedHandler(params.row as ExtendedContentFilterSchema)}
                sx={{ mr: 2 }}
                size="small"
                variant="contained"
              >
                {t('content.contentFilter.select')}
              </Button>
            )}
            <Tooltip title={t('content.contentFilter.tableHeaders.details')} key="details">
              <>
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faEye} />}
                  title={t('content.contentFilter.tableHeaders.details')}
                  label={t('content.contentFilter.tableHeaders.details')}
                  onClick={() => showContentFilterDetailsHandler(params.row.id, ContentFilterAction.UPDATE)}
                />
              </>
            </Tooltip>
            <Tooltip title={t('content.contentFilter.tableHeaders.archive')} key="archive">
              <>
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faBoxArchive} sx={{ color: 'warning.main' }} />}
                  title={t('content.contentFilter.tableHeaders.archive')}
                  label={t('content.contentFilter.tableHeaders.archive')}
                  onClick={() => showContentFilterDetailsHandler(params.row.id, ContentFilterAction.ARCHIVE)}
                />
              </>
            </Tooltip>
          </>,
        ],
      },
    ],
    [i18n.language, isSelectFilterEnabled, onSelectedHandler, showContentFilterDetailsHandler, t]
  );

  const rows = useMemo(
    () =>
      contentFilters?.items?.map<Row>(contentFilter => ({
        ...contentFilter,
      })) ?? [],
    [contentFilters]
  );

  return canReadContentFilters ? (
    <>
      <Stack spacing={2}>
        {!isError ? (
          <>
            {canCreateContentFilters && showCreateButton ? (
              <Box>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ mb: 4 }}
                  startIcon={<FontAwesomeIcon icon={faAdd} />}
                  onClick={() => setIsCreateContentFilterDialog(true)}
                >
                  {t('content.contentFilter.addContentFilter')}
                </Button>
              </Box>
            ) : null}
            <DataGridContainer>
              <StyledDataGrid
                density="compact"
                autoHeight
                rowHeight={70}
                loading={isLoading}
                rows={rows}
                columns={columns}
                pagination
                page={contentFilters?.pagingDetails?.currentPage}
                rowCount={contentFilters?.pagingDetails?.totalItems}
                pageSize={pageSize}
                paginationMode="server"
                onPageChange={setPage}
                onPageSizeChange={setPageSize}
                disableDensitySelector
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                disableChildrenSorting
                disableChildrenFiltering
                disableMultipleColumnsSorting
                disableColumnResize
                disableColumnReorder
                isRowSelectable={() => false}
                components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
                componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
              />
            </DataGridContainer>
            {canCreateContentFilters ? (
              <ContentFilterCreationForm
                onClose={() => {
                  setIsCreateContentFilterDialog(false);
                }}
                open={isCreateContentFilterDialogOpen}
              />
            ) : null}
            {canGetContentFilterById && selectedContentFilter ? (
              <ContentFilterDetailsDialogForm
                onClose={() => {
                  setIsDetailsDialogOpen(false);
                  setSelectedContentFilterId(undefined);
                }}
                open={isDetailsDialogOpen}
                contentFilter={selectedContentFilter as ExtendedContentFilterSchema}
              />
            ) : null}
            {selectedContentFilter && (
              <ContentFilterArchiveDialog
                onClose={() => {
                  setIsArchiveDialogOpen(false);
                }}
                open={isArchiveDialogOpen}
                contentFilter={selectedContentFilter as ExtendedContentFilterSchema}
              />
            )}
          </>
        ) : (
          <ErrorState errorMessage={t('content.contentFilter.loadingError')} />
        )}
      </Stack>
    </>
  ) : null;
};

export default ContentFilter;
