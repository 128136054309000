import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { ButtonGroup, useMediaQuery, useTheme } from '@mui/material';

import { usePermission } from '../../../../../../../app/hooks';
import EventstreamsMenu from './EventstreamsMenu';
import OtherMenu from './OtherMenu';
import TransactionsMenu from './TransactionsMenu';

const EVENTSTREAM_PERMISSIONS = [OnlineCasinoPermissions.getEventstreamStreamId];

const TRANSACTIONS_PERMISSIONS = [
  OnlineCasinoPermissions.getAccountTransactions,
  OnlineCasinoPermissions.exportWithdrawalsAndDepositsHistory,
  OnlineCasinoPermissions.withdrawManually,
  OnlineCasinoPermissions.depositManually,
];

const OTHER_PERMISSIONS = [
  OnlineCasinoPermissions.getUserLoginSession,
  OnlineCasinoPermissions.getUserNotes,
  OnlineCasinoPermissions.getUserLinkedAccounts,
  OnlineCasinoPermissions.getUserAccountStatistics,
];

interface Props {
  userId: string;
}

const UserDataMenu = ({ userId }: Props) => {
  const theme = useTheme();
  const isVertical = useMediaQuery(theme.breakpoints.down('lg'));
  const canAccessEventstreamMenu = usePermission(EVENTSTREAM_PERMISSIONS, { strict: false });
  const canAccessTransactionsMenu = usePermission(TRANSACTIONS_PERMISSIONS, { strict: false });
  const canAccessOtherMenu = usePermission(OTHER_PERMISSIONS, { strict: false });

  return canAccessEventstreamMenu || canAccessTransactionsMenu || canAccessOtherMenu ? (
    <ButtonGroup
      size="small"
      color="secondary"
      aria-label="outlined button group"
      orientation={isVertical ? 'vertical' : 'horizontal'}
    >
      {canAccessEventstreamMenu && <EventstreamsMenu userId={userId} />}
      {canAccessTransactionsMenu && <TransactionsMenu userId={userId} />}
      {canAccessOtherMenu && <OtherMenu />}
    </ButtonGroup>
  ) : null;
};

export default UserDataMenu;
