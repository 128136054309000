import { useTranslation } from 'react-i18next';
import { useGetPlayersBlocked } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

import {
  transformBooleanV2,
  transformDecimalV2,
  transformPercentageV2,
} from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const Blocked = () => {
  const { t } = useTranslation();
  const canReadBlocked = usePermission(OnlineCasinoPermissions.getPlayersBlocked);
  const { data: playerBlocked, isLoading } = useGetPlayersBlocked({ query: { enabled: canReadBlocked } });

  const columns: GridColDef[] = [
    {
      ...transformBooleanV2('blockedSports', t),
      flex: 1,
    },
    {
      ...transformBooleanV2('blockedGames', t),
      flex: 1,
    },
    {
      ...transformDecimalV2('amountOfUsers', t),
      flex: 1,
    },
    {
      ...transformPercentageV2('percentage', t, { colored: false, fixedTo: 2 }),
      flex: 1,
    },
  ];

  const rows =
    playerBlocked?.map((blocked, index) => ({
      id: index,
      ...blocked,
    })) || [];

  if (rows.length > 0) {
    const totalRow = {
      id: -1000,
      blockedSports: undefined,
      blockedGames: undefined,
      amountOfUsers: rows.reduce((sum, row) => sum + row.amountOfUsers, 0),
      percentage: rows.reduce((sum, row) => sum + row.percentage, 0),
    };
    rows.push(totalRow);
  }

  if (!canReadBlocked) {
    return null;
  }

  return (
    <Stack spacing={2}>
      <StyledDataGrid
        columns={columns}
        rows={rows}
        loading={isLoading}
        autoHeight
        disableSelectionOnClick
        pagination
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
      />
    </Stack>
  );
};

export default Blocked;
