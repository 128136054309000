import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BonusRequestArgumentsSchema,
  PromotionParametersSchema,
  useAwardPlayersRevenueContributorsBonusRequestHook,
  useGetPlayersRevenueContributorsBonusRequests,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Card, CardContent, Collapse, IconButton, Stack, styled, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

import { BooleanSpan, formatCurrency, ObjectSpan } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';

const Container = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: '15px',
  marginBottom: 15,
  gridTemplateAreas: `
    "awardClaimablePromotionTitle awardAvailablePromotionTitle"
    "awardClaimablePromotionContent awardAvailablePromotionContent"
  `,
  [theme.breakpoints.down('lg')]: {
    gridTemplateAreas: `
      "awardClaimablePromotionTitle"
      "awardClaimablePromotionContent"
      "awardAvailablePromotionTitle"
      "awardAvailablePromotionContent"
    `,
  },
}));

const PromotionArea = styled(Box)({
  display: 'flex',
  overflow: 'hidden',
});

const PromotionCard = styled(Card)({
  width: '100%',
});

const PromotionCardContent = styled(CardContent)({
  padding: 15,
  boxSizing: 'border-box',
});

const PromotionButtons = styled(Box)({
  display: 'flex',
  flexFlow: 'row wrap',
  marginBottom: 10,
});

const StyledButton = styled(Button)({
  marginRight: 10,
});

const DropdownIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  transition: theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.short,
  }),
  float: 'right',
  transform: 'rotate(0)',
}));

const ObjectContainer = styled(Box)({
  width: '0rem',
});

const BonusRequest = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const [claimableValue, setClaimableValue] = useState(0);
  const [availableValue, setAvailableValue] = useState(0);
  const canReadBonusRequests = usePermission(OnlineCasinoPermissions.getPlayersRevenueContributorsBonusRequests);
  const canAwardBonusRequest = usePermission(OnlineCasinoPermissions.awardPlayersRevenueContributorsBonusRequest);
  const { data: bonusRequests } = useGetPlayersRevenueContributorsBonusRequests(userId, {
    query: {
      enabled: canReadBonusRequests,
    },
  });

  const awardPlayerBonusRequest = useAwardPlayersRevenueContributorsBonusRequestHook();

  const awardBonusRequest = async (answeredQuestionId: number, values: BonusRequestArgumentsSchema) => {
    if (canAwardBonusRequest) {
      await awardPlayerBonusRequest(userId, answeredQuestionId.toString(), values);
    }
  };

  const columns: GridColDef[] = [
    { field: 'title', headerName: t('title'), flex: 1 },
    { field: 'value', headerName: t('value'), flex: 1 },
  ];

  const SettingsCollapse = ({ value }: { value: any }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <>
        <IconButton color="primary" aria-label="expand settings" onClick={() => setIsOpen(!isOpen)} size="small">
          <DropdownIcon icon={faAngleDown} sx={isOpen ? { transform: 'rotate(-180deg)' } : {}} />
        </IconButton>
        <Collapse in={isOpen} timeout="auto">
          <ObjectContainer>
            <ObjectSpan value={value} />
          </ObjectContainer>
        </Collapse>
      </>
    );
  };
  const mutatedParameters = (promotion: PromotionParametersSchema) => {
    const rows: any = [];
    Object.entries(promotion).forEach(([title, value]) => {
      switch (title) {
        case 'lastBetCanStillBePending':
        case 'openWithdrawals':
        case 'availablePromotions':
        case 'claimablePromotions':
        case 'openBets':
        case 'isBonusBlacklisted':
          rows.push({ title: t(title), value: <BooleanSpan boolean={value} t={t} /> });
          break;
        case 'settings':
          break;
        default:
          rows.push({ title: t(title), value: typeof value === 'string' ? value : formatCurrency(value) });
      }
    });
    rows.push({
      title: t('settings'),
      value: <SettingsCollapse value={promotion.settings} />,
    });
    return rows;
  };

  const handleClaimableValuesClick = (value: number) => {
    setClaimableValue(value);
  };

  const handleAvailableValuesClick = (value: number) => {
    setAvailableValue(value);
  };

  const handleClaimableExecute = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (bonusRequests)
      awardBonusRequest(bonusRequests.claimablePromotions.answeredQuestionId, { promotion: claimableValue });
  };

  const handleAvailableExecute = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (bonusRequests)
      awardBonusRequest(bonusRequests.availablePromotions.answeredQuestionId, { promotion: availableValue });
  };

  const methods = useForm();

  if (!canReadBonusRequests) return null;

  return (
    <FormProvider {...methods}>
      {bonusRequests ? (
        <Container>
          {canAwardBonusRequest && (
            <PromotionArea gridArea="awardClaimablePromotionTitle">
              <PromotionCard>
                <PromotionCardContent>
                  <Typography variant="h5">{t('awardClaimablePromotion')}</Typography>
                  {bonusRequests.claimablePromotions.reason ? (
                    <Typography variant="h6">{`${t('reason')}: ${
                      bonusRequests.claimablePromotions.reason
                    }`}</Typography>
                  ) : bonusRequests.claimablePromotions.promotions.length > 0 ? (
                    <Stack spacing={2}>
                      <Typography variant="h6">{t('claimablePromotionValue')}</Typography>
                      <PromotionButtons>
                        {bonusRequests.claimablePromotions.promotions.map((value, index) => (
                          <StyledButton
                            key={index}
                            onClick={() => handleClaimableValuesClick(value)}
                            color="secondary"
                            variant="contained"
                            sx={value === claimableValue ? { color: 'white', backgroundColor: `secondary.dark` } : {}}
                          >
                            {`${value}%`}
                          </StyledButton>
                        ))}
                      </PromotionButtons>
                      <Button variant="outlined" onClick={handleClaimableExecute} disabled={claimableValue === 0}>
                        {t('execute')}
                      </Button>
                    </Stack>
                  ) : (
                    <Typography variant="h6">{t('noReason')}</Typography>
                  )}
                </PromotionCardContent>
              </PromotionCard>
            </PromotionArea>
          )}
          <PromotionArea gridArea="awardClaimablePromotionContent">
            <PromotionCard>
              <CardContent>
                <Typography variant="h6">{t('claimablePromotions')}</Typography>
                <StyledDataGrid
                  columns={columns}
                  rows={mutatedParameters(bonusRequests.claimablePromotions.parameters)}
                  autoHeight
                  disableSelectionOnClick
                  getRowId={row => row.title}
                />
              </CardContent>
            </PromotionCard>
          </PromotionArea>
          {canAwardBonusRequest && (
            <PromotionArea gridArea="awardAvailablePromotionTitle">
              <PromotionCard>
                <PromotionCardContent>
                  <Typography variant="h5">{t('awardAvailablePromotion')}</Typography>
                  {bonusRequests.availablePromotions.reason ? (
                    <Typography variant="h6">{`${t('reason')}: ${
                      bonusRequests.availablePromotions.reason
                    }`}</Typography>
                  ) : bonusRequests.availablePromotions.promotions.length > 0 ? (
                    <Stack spacing={2}>
                      <Typography variant="h6">{t('availablePromotionValue')}</Typography>
                      <PromotionButtons>
                        {bonusRequests.availablePromotions.promotions.map((value, index) => (
                          <StyledButton
                            key={index}
                            onClick={() => handleAvailableValuesClick(value)}
                            color="secondary"
                            variant="contained"
                            sx={value === availableValue ? { color: 'white', backgroundColor: `secondary.dark` } : {}}
                          >
                            {`${value}€`}
                          </StyledButton>
                        ))}
                      </PromotionButtons>
                      <Button variant="outlined" onClick={handleAvailableExecute} disabled={availableValue === 0}>
                        {t('execute')}
                      </Button>
                    </Stack>
                  ) : (
                    <Typography variant="h6">{t('noReason')}</Typography>
                  )}
                </PromotionCardContent>
              </PromotionCard>
            </PromotionArea>
          )}
          <PromotionArea gridArea="awardAvailablePromotionContent">
            <PromotionCard>
              <CardContent>
                <Typography variant="h6">{t('availablePromotions')}</Typography>
                <StyledDataGrid
                  columns={columns}
                  rows={mutatedParameters(bonusRequests.availablePromotions.parameters)}
                  autoHeight
                  disableSelectionOnClick
                  getRowId={row => row.title}
                />
              </CardContent>
            </PromotionCard>
          </PromotionArea>
        </Container>
      ) : (
        <Typography>{t('noData')}</Typography>
      )}
    </FormProvider>
  );
};

export default BonusRequest;
