import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  AvailableBonusArgumentsSchema,
  getGetUserAvailableBonusesQueryKey,
  useAwardAvailableBonus,
  useGetCampaignsOverview,
} from '@greenisland-api';
import {
  Box,
  Button,
  capitalize,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { getUnixTime } from 'date-fns';
import { useSnackbar } from 'notistack';

type AwardDialogProps = {
  openDialog: boolean;
  setOpenDialog: (state: boolean) => void;
  userId: string;
};

const AwardAvailableBonusesDialog = ({ openDialog, setOpenDialog, userId }: AwardDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { data: campaigns, isLoading, isError } = useGetCampaignsOverview();
  const { register, handleSubmit, errors, control } = useForm<AvailableBonusArgumentsSchema>({
    mode: 'onChange',
  });
  const awardBonusMutation = useAwardAvailableBonus({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetUserAvailableBonusesQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
        setOpenDialog(false);
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  if (isLoading)
    return (
      <Dialog fullWidth open={true}>
        <Box
          sx={{
            height: 480,
            minWidth: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </Dialog>
    );

  if (isError || !campaigns) return null;

  const onSubmit = (data: AvailableBonusArgumentsSchema) => {
    awardBonusMutation.mutate({
      userId,
      data: {
        ...data,
        amount: Number(data.amount),
        playthrough: data.playthrough ? Number(data.playthrough) : undefined,
        expiryDate: getUnixTime(new Date(data.expiryDate)),
      },
    });
  };

  return (
    <Dialog fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{capitalize(t('awardAvailableBonus'))}</DialogTitle>
        <DialogContent>
          <TextField
            size="small"
            fullWidth
            name="description"
            label={t('description')}
            type={'text'}
            inputRef={register({
              required: t('fieldIsRequired') as string,
            })}
            error={!!errors.description}
          />
          <TextField
            size="small"
            sx={{ mt: 2 }}
            fullWidth
            name="amount"
            label={t('amount')}
            error={!!errors.amount}
            inputRef={register({ required: t('fieldIsRequired') as string, validate: value => value > 0 })}
            helperText={errors.amount && t('amountValidationMessageZero')}
            type="number"
          />
          <Controller
            name="playthrough"
            control={control}
            defaultValue={0}
            render={({ onChange, value }) => (
              <TextField
                size="small"
                fullWidth
                sx={{ mt: 2 }}
                error={!!errors.playthrough}
                label={t('playthrough')}
                helperText={t('emptyBasedOnRank')}
                type={'number'}
                value={value}
                onChange={onChange}
              />
            )}
            rules={{
              validate: value => value >= 0,
            }}
          />
          <Box sx={{ mt: 2 }}>
            <Controller
              control={control as any}
              defaultValue={new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString()}
              name="expiryDate"
              render={({ onChange, value }) => (
                <DateTimePicker
                  InputProps={{ size: 'small' }}
                  disablePast
                  label={t('expiryDate')}
                  value={value}
                  onChange={value => onChange(value.toISOString())}
                  renderInput={params => <TextField {...params} />}
                  inputFormat="dd/MM/yyyy HH:mm"
                  mask="__/__/____ __:__"
                />
              )}
            />
          </Box>
          <Controller
            control={control}
            name="campaignId"
            defaultValue=""
            render={({ onChange, value }) => (
              <TextField
                size="small"
                select
                fullWidth
                sx={{ mt: 2 }}
                label={t('campaign')}
                disabled={campaigns && campaigns.length <= 0}
                helperText={campaigns && campaigns.length <= 0 ? t('noCampaigns') : ''}
                error={!!errors.campaignId}
                value={value}
                onChange={onChange}
              >
                {campaigns
                  ? campaigns.map(campaign => (
                      <MenuItem key={campaign.campaignId} value={campaign.campaignId}>
                        {`${campaign.campaignName} ${campaign.theme && ` (${campaign.theme})`} ${
                          campaign.year && ` (${campaign.year})`
                        }`}
                      </MenuItem>
                    ))
                  : []}
              </TextField>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={() => setOpenDialog(false)}>
            {t('cancel')}
          </Button>
          <Button
            sx={{ minHeight: '36px', minWidth: '90px' }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={awardBonusMutation.isLoading}
          >
            {awardBonusMutation.isLoading ? <CircularProgress size={20} /> : t('award')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AwardAvailableBonusesDialog;
