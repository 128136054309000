import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GameServerPermissions } from '@greenisland-core/permissions/GameServerPermissions';
import { JackpotConfiguration, useGetJackpotConfigurations } from '@greenisland-store/gameServer';
import { LinearProgress, Stack, Typography } from '@mui/material';
import { DataGridProProps, GridColumns } from '@mui/x-data-grid-pro';

import { DataGridContainer, DataGridPagination, StyledDataGrid } from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { formatCurrency } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';
import JackpotConfigurationDetail from './JackpotConfigurationDetail';

const PAGE_SIZE = 25;

type Row = JackpotConfiguration;

const JackpotsConfigurations = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const canReadJackpotConfiguration = usePermission(GameServerPermissions.getJackpotConfigurations);

  const {
    data: JackpotsConfigurations,
    isLoading,
    isError,
  } = useGetJackpotConfigurations(
    { pageSize: pageSize, page: page + 1 },
    { query: { enabled: canReadJackpotConfiguration, keepPreviousData: true } }
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'id',
        headerName: t('content.gameserver.jackpotConfiguration.id'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'jackpotName',
        headerName: t('content.gameserver.jackpotConfiguration.jackpotName'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'jackpotLevel',
        headerName: t('content.gameserver.jackpotConfiguration.jackpotLevel'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'seed',
        headerName: t('content.gameserver.JackpotConfiguration.seed'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return (
            <Typography variant="body2">{formatCurrency(params.row.seed / 100, params.row.baseCurrency)}</Typography>
          );
        },
      },
      {
        field: 'targetValue',
        headerName: t('content.gameserver.JackpotConfiguration.targetValue'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return (
            <Typography variant="body2">
              {formatCurrency(params.row.targetValue / 100, params.row.baseCurrency)}
            </Typography>
          );
        },
      },
      {
        field: 'gameContribution',
        headerName: t('content.gameserver.JackpotConfiguration.gameContribution'),
        sortable: false,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.value * 100}%</Typography>;
        },
      },
    ],
    [t]
  );

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback(
    ({ row }: { row: JackpotConfiguration }) => <JackpotConfigurationDetail jackpotConfiguration={row} />,
    []
  );

  const getDetailPanelHeight = useCallback((): 'auto' => 'auto', []);

  const rows = useMemo(
    () =>
      JackpotsConfigurations?.entries?.map<Row>(JackpotsConfiguration => ({
        ...JackpotsConfiguration,
      })) ?? [],
    [JackpotsConfigurations?.entries]
  );

  return (
    <Stack spacing={2}>
      {!isError ? (
        <>
          <DataGridContainer>
            <StyledDataGrid
              density="compact"
              autoHeight
              rowHeight={80}
              loading={isLoading}
              rows={rows}
              columns={columns}
              pagination
              page={page}
              rowCount={JackpotsConfigurations?.totalNumberOfEntries}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
              disableDensitySelector
              disableSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
              disableChildrenSorting
              disableChildrenFiltering
              disableMultipleColumnsSorting
              disableColumnResize
              disableColumnReorder
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              isRowSelectable={() => false}
              components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
        </>
      ) : (
        <ErrorState errorMessage="error" />
      )}
    </Stack>
  );
};

export default JackpotsConfigurations;
