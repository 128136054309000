/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  ChangePaymentLimitErrorSchema,
  DefaultResponse,
  NotFoundResponse,
  PaymentMethodConfigurationsSchema,
  PaymentMethodDataSchema,
  PaymentMethodLimitsDataSchema,
  PaymentMethodSchema,
  PaymentMethodSortOrderSchema,
  PaymentMethodsSchema,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary get all payment methods
 */
export const useGetPaymentMethodsHook = () => {
  const getPaymentMethods = useCustomInstance<PaymentMethodsSchema>();

  return (signal?: AbortSignal) => {
    return getPaymentMethods({ url: `/paymentmethods`, method: 'get', signal });
  };
};

export const getGetPaymentMethodsQueryKey = () => [`/paymentmethods`] as const;

export const useGetPaymentMethodsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPaymentMethodsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | void | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPaymentMethodsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPaymentMethodsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentMethodsQueryKey();

  const getPaymentMethods = useGetPaymentMethodsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPaymentMethodsHook>>>> = ({ signal }) =>
    getPaymentMethods(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPaymentMethodsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPaymentMethodsHook>>>
>;
export type GetPaymentMethodsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | void
  | DefaultResponse;

/**
 * @summary get all payment methods
 */
export const useGetPaymentMethods = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPaymentMethodsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | void | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPaymentMethodsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPaymentMethodsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary create a new payment method
 */
export const useCreatePaymentMethodHook = () => {
  const createPaymentMethod = useCustomInstance<PaymentMethodSchema>();

  return (paymentMethodDataSchema: PaymentMethodDataSchema) => {
    return createPaymentMethod({
      url: `/paymentmethods`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: paymentMethodDataSchema,
    });
  };
};

export const useCreatePaymentMethodMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | void | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreatePaymentMethodHook>>>,
    TError,
    { data: PaymentMethodDataSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreatePaymentMethodHook>>>,
  TError,
  { data: PaymentMethodDataSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createPaymentMethod = useCreatePaymentMethodHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreatePaymentMethodHook>>>,
    { data: PaymentMethodDataSchema }
  > = props => {
    const { data } = props ?? {};

    return createPaymentMethod(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePaymentMethodMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreatePaymentMethodHook>>>
>;
export type CreatePaymentMethodMutationBody = PaymentMethodDataSchema;
export type CreatePaymentMethodMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | void
  | DefaultResponse;

/**
 * @summary create a new payment method
 */
export const useCreatePaymentMethod = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | void | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreatePaymentMethodHook>>>,
    TError,
    { data: PaymentMethodDataSchema },
    TContext
  >;
}) => {
  const mutationOptions = useCreatePaymentMethodMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary get possible payment method - provider configurations
 */
export const useGetPossibleConfigurationsHook = () => {
  const getPossibleConfigurations = useCustomInstance<PaymentMethodConfigurationsSchema>();

  return (signal?: AbortSignal) => {
    return getPossibleConfigurations({ url: `/paymentmethods/config`, method: 'get', signal });
  };
};

export const getGetPossibleConfigurationsQueryKey = () => [`/paymentmethods/config`] as const;

export const useGetPossibleConfigurationsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPossibleConfigurationsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | void | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPossibleConfigurationsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPossibleConfigurationsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPossibleConfigurationsQueryKey();

  const getPossibleConfigurations = useGetPossibleConfigurationsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPossibleConfigurationsHook>>>> = ({
    signal,
  }) => getPossibleConfigurations(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPossibleConfigurationsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPossibleConfigurationsHook>>>
>;
export type GetPossibleConfigurationsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | void
  | DefaultResponse;

/**
 * @summary get possible payment method - provider configurations
 */
export const useGetPossibleConfigurations = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPossibleConfigurationsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | void | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPossibleConfigurationsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPossibleConfigurationsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get payment method
 */
export const useGetPaymentMethodHook = () => {
  const getPaymentMethod = useCustomInstance<PaymentMethodSchema>();

  return (paymentMethodId: string, signal?: AbortSignal) => {
    return getPaymentMethod({ url: `/paymentmethods/${paymentMethodId}`, method: 'get', signal });
  };
};

export const getGetPaymentMethodQueryKey = (paymentMethodId: string) => [`/paymentmethods/${paymentMethodId}`] as const;

export const useGetPaymentMethodQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPaymentMethodHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | void | DefaultResponse
>(
  paymentMethodId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPaymentMethodHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPaymentMethodHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentMethodQueryKey(paymentMethodId);

  const getPaymentMethod = useGetPaymentMethodHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPaymentMethodHook>>>> = ({ signal }) =>
    getPaymentMethod(paymentMethodId, signal);

  return { queryKey, queryFn, enabled: !!paymentMethodId, ...queryOptions };
};

export type GetPaymentMethodQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPaymentMethodHook>>>>;
export type GetPaymentMethodQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | void
  | DefaultResponse;

/**
 * @summary get payment method
 */
export const useGetPaymentMethod = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPaymentMethodHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | void | DefaultResponse
>(
  paymentMethodId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPaymentMethodHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPaymentMethodQueryOptions(paymentMethodId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary update an existing payment method
 */
export const useUpdatePaymentMethodHook = () => {
  const updatePaymentMethod = useCustomInstance<PaymentMethodSchema>();

  return (paymentMethodId: string, paymentMethodDataSchema: PaymentMethodDataSchema) => {
    return updatePaymentMethod({
      url: `/paymentmethods/${paymentMethodId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: paymentMethodDataSchema,
    });
  };
};

export const useUpdatePaymentMethodMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | void | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdatePaymentMethodHook>>>,
    TError,
    { paymentMethodId: string; data: PaymentMethodDataSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdatePaymentMethodHook>>>,
  TError,
  { paymentMethodId: string; data: PaymentMethodDataSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updatePaymentMethod = useUpdatePaymentMethodHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdatePaymentMethodHook>>>,
    { paymentMethodId: string; data: PaymentMethodDataSchema }
  > = props => {
    const { paymentMethodId, data } = props ?? {};

    return updatePaymentMethod(paymentMethodId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePaymentMethodMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdatePaymentMethodHook>>>
>;
export type UpdatePaymentMethodMutationBody = PaymentMethodDataSchema;
export type UpdatePaymentMethodMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | void
  | DefaultResponse;

/**
 * @summary update an existing payment method
 */
export const useUpdatePaymentMethod = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | void | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdatePaymentMethodHook>>>,
    TError,
    { paymentMethodId: string; data: PaymentMethodDataSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdatePaymentMethodMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update the sort order of the payment method
 */
export const useUpdatePaymentMethodSortOrderHook = () => {
  const updatePaymentMethodSortOrder = useCustomInstance<PaymentMethodsSchema>();

  return (paymentMethodId: string, paymentMethodSortOrderSchema: PaymentMethodSortOrderSchema) => {
    return updatePaymentMethodSortOrder({
      url: `/paymentmethods/${paymentMethodId}/sortOrder`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: paymentMethodSortOrderSchema,
    });
  };
};

export const useUpdatePaymentMethodSortOrderMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | void | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdatePaymentMethodSortOrderHook>>>,
    TError,
    { paymentMethodId: string; data: PaymentMethodSortOrderSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdatePaymentMethodSortOrderHook>>>,
  TError,
  { paymentMethodId: string; data: PaymentMethodSortOrderSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updatePaymentMethodSortOrder = useUpdatePaymentMethodSortOrderHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdatePaymentMethodSortOrderHook>>>,
    { paymentMethodId: string; data: PaymentMethodSortOrderSchema }
  > = props => {
    const { paymentMethodId, data } = props ?? {};

    return updatePaymentMethodSortOrder(paymentMethodId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePaymentMethodSortOrderMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdatePaymentMethodSortOrderHook>>>
>;
export type UpdatePaymentMethodSortOrderMutationBody = PaymentMethodSortOrderSchema;
export type UpdatePaymentMethodSortOrderMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | void
  | DefaultResponse;

/**
 * @summary update the sort order of the payment method
 */
export const useUpdatePaymentMethodSortOrder = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | void | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdatePaymentMethodSortOrderHook>>>,
    TError,
    { paymentMethodId: string; data: PaymentMethodSortOrderSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdatePaymentMethodSortOrderMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary create limits for a payment method
 */
export const useCreatePaymentMethodLimitsHook = () => {
  const createPaymentMethodLimits = useCustomInstance<PaymentMethodSchema>();

  return (paymentMethodId: string, paymentMethodLimitsDataSchema: PaymentMethodLimitsDataSchema) => {
    return createPaymentMethodLimits({
      url: `/paymentmethods/${paymentMethodId}/limits`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: paymentMethodLimitsDataSchema,
    });
  };
};

export const useCreatePaymentMethodLimitsMutationOptions = <
  TError = ChangePaymentLimitErrorSchema | UnauthorizedResponse | void | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreatePaymentMethodLimitsHook>>>,
    TError,
    { paymentMethodId: string; data: PaymentMethodLimitsDataSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreatePaymentMethodLimitsHook>>>,
  TError,
  { paymentMethodId: string; data: PaymentMethodLimitsDataSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createPaymentMethodLimits = useCreatePaymentMethodLimitsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreatePaymentMethodLimitsHook>>>,
    { paymentMethodId: string; data: PaymentMethodLimitsDataSchema }
  > = props => {
    const { paymentMethodId, data } = props ?? {};

    return createPaymentMethodLimits(paymentMethodId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePaymentMethodLimitsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreatePaymentMethodLimitsHook>>>
>;
export type CreatePaymentMethodLimitsMutationBody = PaymentMethodLimitsDataSchema;
export type CreatePaymentMethodLimitsMutationError =
  | ChangePaymentLimitErrorSchema
  | UnauthorizedResponse
  | void
  | DefaultResponse;

/**
 * @summary create limits for a payment method
 */
export const useCreatePaymentMethodLimits = <
  TError = ChangePaymentLimitErrorSchema | UnauthorizedResponse | void | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreatePaymentMethodLimitsHook>>>,
    TError,
    { paymentMethodId: string; data: PaymentMethodLimitsDataSchema },
    TContext
  >;
}) => {
  const mutationOptions = useCreatePaymentMethodLimitsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary update the limits of an existing payment method
 */
export const useUpdatePaymentMethodLimitsHook = () => {
  const updatePaymentMethodLimits = useCustomInstance<PaymentMethodSchema>();

  return (paymentMethodId: string, limitId: number, paymentMethodLimitsDataSchema: PaymentMethodLimitsDataSchema) => {
    return updatePaymentMethodLimits({
      url: `/paymentmethods/${paymentMethodId}/limits/${limitId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: paymentMethodLimitsDataSchema,
    });
  };
};

export const useUpdatePaymentMethodLimitsMutationOptions = <
  TError = ChangePaymentLimitErrorSchema | UnauthorizedResponse | void | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdatePaymentMethodLimitsHook>>>,
    TError,
    { paymentMethodId: string; limitId: number; data: PaymentMethodLimitsDataSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdatePaymentMethodLimitsHook>>>,
  TError,
  { paymentMethodId: string; limitId: number; data: PaymentMethodLimitsDataSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updatePaymentMethodLimits = useUpdatePaymentMethodLimitsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdatePaymentMethodLimitsHook>>>,
    { paymentMethodId: string; limitId: number; data: PaymentMethodLimitsDataSchema }
  > = props => {
    const { paymentMethodId, limitId, data } = props ?? {};

    return updatePaymentMethodLimits(paymentMethodId, limitId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePaymentMethodLimitsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdatePaymentMethodLimitsHook>>>
>;
export type UpdatePaymentMethodLimitsMutationBody = PaymentMethodLimitsDataSchema;
export type UpdatePaymentMethodLimitsMutationError =
  | ChangePaymentLimitErrorSchema
  | UnauthorizedResponse
  | void
  | DefaultResponse;

/**
 * @summary update the limits of an existing payment method
 */
export const useUpdatePaymentMethodLimits = <
  TError = ChangePaymentLimitErrorSchema | UnauthorizedResponse | void | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdatePaymentMethodLimitsHook>>>,
    TError,
    { paymentMethodId: string; limitId: number; data: PaymentMethodLimitsDataSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdatePaymentMethodLimitsMutationOptions(options);

  return useMutation(mutationOptions);
};
