import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetWithdrawalsInCasino } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Card, CardContent, CircularProgress, Link } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

import {
  transformCurrencyV2,
  transformStatusV2,
  transformUnixDateV2,
} from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../../Lookup/LookupPermissions';
import InCasinoCancelWithdrawalDialog from './InCasinoCancelWithdrawalDialog';

const InCasino = () => {
  const { t } = useTranslation();

  const canReadInCasinoWithdrawals = usePermission(OnlineCasinoPermissions.getWithdrawalsInCasino);
  const canReadLoyaltyRank = usePermission(OnlineCasinoPermissions.getLoyaltyRankUsers);
  const canLookupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });
  const canCancelWithdrawal = usePermission(OnlineCasinoPermissions.cancelWithdrawalInCasino);

  const { data: withdrawals, isLoading } = useGetWithdrawalsInCasino({
    query: { enabled: canReadInCasinoWithdrawals },
  });

  const columns: GridColDef[] = useMemo(() => {
    if (!withdrawals?.length) return [];

    return Object.keys(withdrawals[0])
      .map(key => {
        switch (key) {
          case 'flagData':
            return { field: key, headerName: t(key), flex: 1 };
          case 'requestedOn':
            return transformUnixDateV2(key, t);
          case 'amountOfWithdrawal':
          case 'netDeposit':
          case 'balanceAfterWithdrawal':
            return transformCurrencyV2(key, t);
          case 'status':
          case 'identityVerification':
          case 'domicileVerification':
          case 'bankAccountVerification':
          case 'walletVerification':
            return transformStatusV2(key, t);
          case 'userLoyalty':
            return {
              field: key,
              headerName: t(key),
              flex: 1,
              renderCell: params =>
                canReadLoyaltyRank ? (
                  <Link href={`../../loyalty/ranks/${params.value}`}>{params.value}</Link>
                ) : (
                  params.value
                ),
            } as GridColDef;
          case 'username':
            return {
              field: key,
              headerName: t(key),
              flex: 1,
              renderCell: params =>
                canLookupUser ? (
                  <Link href={`../../users/${params.row.userId}/details`}>{params.value}</Link>
                ) : (
                  params.value
                ),
            } as GridColDef;
          default:
            return { field: key, headerName: t(key), flex: 1 };
        }
      })
      .concat({
        field: 'cancel',
        headerName: t('cancel'),
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: params => (canCancelWithdrawal ? <InCasinoCancelWithdrawalDialog withdrawal={params.row} /> : null),
      });
  }, [t, withdrawals, canCancelWithdrawal, canLookupUser, canReadLoyaltyRank]);

  if (isLoading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  if (!canReadInCasinoWithdrawals || !withdrawals?.length) return <Box>{t('noData')}</Box>;

  return (
    <Card>
      <CardContent>
        <StyledDataGrid
          columns={columns}
          rows={withdrawals}
          getRowId={row => row.withdrawalId}
          autoHeight
          disableSelectionOnClick
          disableColumnMenu
          density="compact"
          pagination
          initialState={{
            columns: {
              columnVisibilityModel: {
                userId: false,
                withdrawalId: false,
                destination: false,
                flagData: false,
                flagReason: false,
              },
            },
          }}
        />
      </CardContent>
    </Card>
  );
};

export default InCasino;
