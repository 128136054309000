import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { VerificationsSchemaIdentity } from '@greenisland-api';
import { TextField } from '@mui/material';

interface Props {
  identity: VerificationsSchemaIdentity;
}

const isBelgianNationalNumberValid = (nationalNumber: string, country: string) => {
  if (country !== 'BE') return true;

  const n = nationalNumber.replace(/\.|-/g, '');
  // National number needs to be 11 chars long
  if (n.length !== 11) return false;

  const checkDigit = n.substr(n.length - 2, 2);
  const modFunction = function (nr: number) {
    return 97 - (nr % 97);
  };

  let nrToCheck = parseInt(n.substr(0, 9));

  //first check without 2
  if (modFunction(nrToCheck) === parseInt(checkDigit)) return true;

  //then check with 2 appended for y2k+ births
  nrToCheck = parseInt('2' + n.substr(0, 9));

  return modFunction(nrToCheck) === parseInt(checkDigit);
};

const IdentificationNumberField = ({ identity }: Props) => {
  const { t } = useTranslation();
  const { errors, register, watch } = useFormContext();

  const identificationCountryOfOriginInputValue = watch('identificationCountryOfOrigin');

  return (
    <TextField
      size="small"
      fullWidth
      error={!!errors.identificationNumber}
      helperText={errors.identificationNumber?.message}
      name="identificationNumber"
      defaultValue={identity.identificationNumber}
      inputRef={register({
        required: identity.market !== 'KSA',
        validate: value =>
          isBelgianNationalNumberValid(value, identificationCountryOfOriginInputValue) ||
          `${t('useValidBelgianNumber')}`,
      })}
    />
  );
};

export default IdentificationNumberField;
