import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { faBars, faSignOutAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '@greenisland-core/store';
import { Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { usePermission } from '../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../pages/OnlineCasino/Lookup/LookupPermissions';
import Searchbar from './Searchbar';
import SearchBarGameServer from './SearchBarGameServer';

interface Props {
  drawerOpen: boolean;
  handleDrawerToggle: () => void;
  handleLogout: () => void;
}

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  zIndex: 999,
  backgroundColor: '#fff',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  gap: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.up('md')]: { width: `calc(100% - ${theme.spacing(32)})` },
}));

const Search = styled('div')({
  flexGrow: 1,
});

const MenuButton = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(5),
  [theme.breakpoints.up('md')]: { display: 'none' },
}));

const LogoutButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('md')]: { display: 'none' },
}));

const TopAppBar = ({ drawerOpen, handleDrawerToggle, handleLogout }: Props) => {
  const { t } = useTranslation();
  const hasLookupPermissions = usePermission(LOOKUP_PERMISSIONS, { strict: false });
  const entities = useAppSelector(state => state.authentication.data?.startupAgent?.entities);
  const { selectedEntity, selectedProduct } = useAppSelector(state => state.entities);

  const product = useMemo(
    () =>
      entities
        ?.find(({ entityName }) => entityName === selectedEntity)
        ?.products?.find(({ productName }) => productName === selectedProduct),
    [entities, selectedEntity, selectedProduct]
  );

  return (
    <Container>
      <Search>
        {hasLookupPermissions && product?.productType === 'OnlineCasino' && (
          <Searchbar closeDrawer={handleDrawerToggle} />
        )}
        {hasLookupPermissions && product?.productType === 'GameServer' && (
          <SearchBarGameServer closeDrawer={handleDrawerToggle} />
        )}
      </Search>
      <LogoutButton
        title={t('logout')}
        startIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
        id="logoutButton"
        aria-label={t('logout')}
        variant="text"
        color="secondary"
        onClick={handleLogout}
      />
      <MenuButton
        title={drawerOpen ? t('closeMenu') : t('openMenu')}
        aria-label={t('openDrawer')}
        onClick={handleDrawerToggle}
        color="secondary"
      >
        <FontAwesomeIcon icon={drawerOpen ? faTimes : faBars} />
      </MenuButton>
    </Container>
  );
};

export default TopAppBar;
