import { GridColumns } from '@mui/x-data-grid-pro';

import { formatCurrency } from '../../../../../app/helpers/transformFunctions';

export const headers: GridColumns = [
  {
    headerName: '',
    field: 'title',
    width: 200,
    renderCell: params => (params.row.id === 'total' ? <strong>{params.value}</strong> : params.value),
  },
  {
    headerName: '',
    field: 'value',
    renderCell: params =>
      params.row.id === 'total' ? <strong>{formatCurrency(params.value)}</strong> : formatCurrency(params.value),
    width: 200,
  },
];
