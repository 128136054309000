const TIME_UNITS_TICKS: Record<string, number> = {
  months: 30 * 24 * 60 * 60 * 10000000, // Approximating 1 month as 30 days
  days: 24 * 60 * 60 * 10000000,
  minutes: 60 * 10000000,
  seconds: 10000000,
};

export const calculateTicks = (value: number, durationUnit: string) => {
  return value * TIME_UNITS_TICKS[durationUnit];
};

export const calculateTime = (value: number, durationUnit: string) => {
  return value / TIME_UNITS_TICKS[durationUnit];
};

export const convertTime = (value: number, fromUnit: string, toUnit: string) => {
  if (TIME_UNITS_TICKS[fromUnit] && TIME_UNITS_TICKS[toUnit]) {
    return (value * TIME_UNITS_TICKS[fromUnit]) / TIME_UNITS_TICKS[toUnit];
  } else {
    throw new Error('Invalid time units');
  }
};
