import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  GameSessionsSortKeyParamParameter,
  GameSessionsSummaryGroupByKeyParamParameter,
  GameSessionsSummarySortKeyParamParameter,
  SortOrderParamParameter,
} from '@greenisland-api';
import { Box, Button, useTheme } from '@mui/material';
import { subWeeks } from 'date-fns';

import { GameSummarySearchFilters, SessionGameCardType } from '../Constants';
import GameSessionAdditionalFilters from './GameSessionAdditionalFilters';
import GameSessionDatesFilters from './GameSessionDatesFilters';
import GameSessionsTableData from './GameSessionsTableData';
import GameSummariesTableData from './GameSummariesTableData';

interface Props {
  isSummaryTab: boolean;
}

const GameSessionTab = ({ isSummaryTab }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const resetFilters = () => {
    return navigate(`${pathname}?${GameSummarySearchFilters.ACTIVE_TAB}=${isSummaryTab ? 0 : 1}`, { replace: true });
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          [theme.breakpoints.up('xs')]: {
            flexDirection: 'column',
            gap: 2,
          },
          [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
          },
        }}
      >
        <GameSessionDatesFilters />
        <GameSessionAdditionalFilters isSummaryTab={isSummaryTab} />
      </Box>
      <Box mb={4} mt={1}>
        <Button size="medium" variant="contained" color="warning" onClick={resetFilters}>
          {t('resetFilters')}
        </Button>
      </Box>
      {isSummaryTab ? (
        <GameSummariesTableData
          cardType={SessionGameCardType.ALL}
          startDate={subWeeks(new Date(), 1)}
          endDate={new Date()}
          groupByKey={GameSessionsSummaryGroupByKeyParamParameter.Game}
          sortOrder={SortOrderParamParameter.descending}
          sortKey={GameSessionsSummarySortKeyParamParameter.GamingRevenue}
        />
      ) : (
        <GameSessionsTableData
          cardType={SessionGameCardType.ALL}
          userId={null}
          gameId={null}
          startDate={subWeeks(new Date(), 1)}
          endDate={new Date()}
          sortOrder={SortOrderParamParameter.descending}
          sortKey={GameSessionsSortKeyParamParameter.GamingRevenue}
        />
      )}
    </>
  );
};

export default GameSessionTab;
