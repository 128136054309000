import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetPlayersActivity } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Link, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { endOfDay, getUnixTime, sub } from 'date-fns';

import { StyledDataGrid } from '@greenisland-common/components/atoms';
import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import { transformCurrencyV2 } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';
import { LOOKUP_PERMISSIONS } from '../../../Lookup/LookupPermissions';

const PlayerActivity = () => {
  const canReadPlayerActivity = usePermission(OnlineCasinoPermissions.getPlayersActivity);
  return canReadPlayerActivity ? <PlayerActivityInnerChild /> : null;
};

const currentDate = new Date();

const PlayerActivityInnerChild = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startdate');
  const endDate = searchParams.get('enddate');
  const start = startDate ? Number(startDate) : getUnixTime(sub(currentDate, { days: 1 }));
  const end = endDate ? Number(endDate) : getUnixTime(endOfDay(currentDate));
  const canLookupUser = usePermission(LOOKUP_PERMISSIONS, { strict: false });

  const { data: playerActivity, isLoading: fetching } = useGetPlayersActivity({
    startdate: start,
    enddate: end,
  });

  const columns: GridColDef[] = [
    {
      field: 'username',
      headerName: t('username'),
      flex: 1,
      renderCell: params => {
        const userId = params.row.userId;
        const username = params.row.username;
        return canLookupUser ? <Link href={`../../../users/${userId}/details`}>{username}</Link> : username;
      },
    },
    {
      flex: 1,
      ...transformCurrencyV2('gamingGross', t),
    },
    {
      flex: 1,
      ...transformCurrencyV2('bets', t),
    },
    {
      flex: 1,
      ...transformCurrencyV2('wins', t),
    },
  ];

  return (
    <Stack spacing={2}>
      <DateRangePicker
        unixStartDate={start * 1000}
        unixEndDate={end * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
        }}
      />
      <StyledDataGrid
        columns={columns}
        rows={playerActivity ?? []}
        loading={fetching}
        getRowId={row => row.userId}
        autoHeight
        pagination
        pageSize={50}
        rowsPerPageOptions={[50, 100, 200]}
        disableSelectionOnClick
      />
    </Stack>
  );
};

export default PlayerActivity;
