/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * The input value for the lambda for the kill switch
 */
export type KillSwitchLambdaParamParameter =
  (typeof KillSwitchLambdaParamParameter)[keyof typeof KillSwitchLambdaParamParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const KillSwitchLambdaParamParameter = {
  Kill: 'Kill',
  Revive: 'Revive',
} as const;
