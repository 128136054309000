import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  ComplaintClosureSchema,
  CreateComplaintTicketSchema,
  EAuthorTypeSchema,
  EComplaintClosureReasonSchema,
  getGetAllComplaintTicketsQueryKey,
  useCloseComplaintTicket,
} from '@greenisland-api';
import {
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

type CloseComplaintDialogDialogProps = {
  open: boolean;
  setOpen: (state: boolean) => void;
  complaintId: number;
  complaintTitle: string;
};

const CloseComplaintDialog = ({ open, setOpen, complaintId, complaintTitle }: CloseComplaintDialogDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const closeComplaintMutation = useCloseComplaintTicket({
    mutation: {
      onSuccess: async () => {
        queryClient.invalidateQueries(getGetAllComplaintTicketsQueryKey());
        enqueueSnackbar(t('success'), { variant: 'success' });
        setOpen(false);
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const { register, handleSubmit, errors, control } = useForm<
    Omit<CreateComplaintTicketSchema, 'userId'> & { userId?: string }
  >({
    mode: 'onChange',
  });

  const onSubmit = (data: ComplaintClosureSchema) => {
    closeComplaintMutation.mutate({
      complaintTicketId: complaintId,
      data: {
        authorType: EAuthorTypeSchema.Agent,
        content: data.content,
        closureReason: data.closureReason,
      },
    });
  };

  return (
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{capitalize(t('tasks.complaints.closeComplaint'))}</DialogTitle>
        <DialogContent>
          <Stack spacing={4}>
            <Typography sx={{ wordBreak: 'break-word' }}>
              {t('tasks.complaints.closeDescription', { title: complaintTitle })}
            </Typography>
            <Controller
              name="closureReason"
              rules={{ required: true }}
              control={control}
              render={({ onChange, value }) => (
                <TextField
                  size="small"
                  select
                  fullWidth
                  label={t('tasks.complaints.closureReason')}
                  value={value}
                  onChange={onChange}
                  error={!!errors.type}
                >
                  {Object.values(EComplaintClosureReasonSchema).map((key: string) => (
                    <MenuItem key={key} value={key}>
                      {t(key)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <TextField
              size="small"
              label={t('tasks.complaints.content')}
              type="text"
              multiline
              minRows={7}
              name="content"
              inputRef={register({
                required: true,
                validate: value => value.length <= 1024,
              })}
              error={!!errors.content}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={() => setOpen(false)}>
            {t('cancel')}
          </Button>
          <Button
            sx={{ minHeight: '36px', minWidth: '90px' }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={closeComplaintMutation.isLoading}
          >
            {t('tasks.complaints.closeComplaint')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CloseComplaintDialog;
