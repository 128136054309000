import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GameFilterType } from '../constants';
import useGetGameFiltersQuery from './useGetGameFiltersQuery';

export default function useGetGameFiltersCheckboxData() {
  const { t } = useTranslation();
  const { additionalFiltersQuery } = useGetGameFiltersQuery();

  const additionalFiltersParams = additionalFiltersQuery?.split(',');

  return useMemo(
    () => [
      {
        filterType: GameFilterType.FEATURED,
        label: t('content.games.games.featured'),
        isChecked: !!additionalFiltersParams?.includes(GameFilterType.FEATURED),
      },
      {
        filterType: GameFilterType.ENABLED,
        label: t('content.games.games.enabled'),
        isChecked: !!additionalFiltersParams?.includes(GameFilterType.ENABLED),
      },
      {
        filterType: GameFilterType.NEW,
        label: t('content.games.games.new'),
        isChecked: !!additionalFiltersParams?.includes(GameFilterType.NEW),
      },
      {
        filterType: GameFilterType.POPULAR,
        label: t('content.games.games.popular'),
        isChecked: !!additionalFiltersParams?.includes(GameFilterType.POPULAR),
      },
      {
        filterType: GameFilterType.LIVE,
        label: t('content.games.games.isLive'),
        isChecked: !!additionalFiltersParams?.includes(GameFilterType.LIVE),
      },
      {
        filterType: GameFilterType.HAS_JACKPOT,
        label: t('content.games.games.hasJackpot'),
        isChecked: !!additionalFiltersParams?.includes(GameFilterType.HAS_JACKPOT),
      },
      {
        filterType: GameFilterType.EXCLUSIVE,
        label: t('content.games.games.exclusive'),
        isChecked: !!additionalFiltersParams?.includes(GameFilterType.EXCLUSIVE),
      },
    ],
    [additionalFiltersParams, t]
  );
}
