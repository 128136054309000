/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EVoucherStatusSchema = (typeof EVoucherStatusSchema)[keyof typeof EVoucherStatusSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EVoucherStatusSchema = {
  Available: 'Available',
  Used: 'Used',
  NotOrdered: 'NotOrdered',
  Revoked: 'Revoked',
  RevokedAndReawarded: 'RevokedAndReawarded',
  Expired: 'Expired',
} as const;
