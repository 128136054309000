import { ReactElement, useState } from 'react';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { alpha, Box, capitalize, Card, CardContent, Collapse, Typography, useTheme } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

interface Props {
  title: string;
  children: ReactElement;
}

const CollapseSectionWrapper = ({ title, children }: Props) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      mt={5}
      mb={2}
      sx={{
        [theme.breakpoints.up('xs')]: {
          width: '100%',
        },
        [theme.breakpoints.up('md')]: {
          width: '45%',
        },
      }}
    >
      <Box sx={{ cursor: 'pointer' }} display="flex" alignItems="center" onClick={handleExpandClick}>
        <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} size="sm" />
        <Typography fontWeight={expanded ? 600 : 400} variant="body1" ml={1}>
          {capitalize(title)}
        </Typography>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit={false}>
        <Card elevation={0} sx={{ mt: 3, border: `1px solid ${alpha(theme.palette.text.disabled, 0.5)}}` }}>
          <CardContent>{children}</CardContent>
        </Card>
      </Collapse>
    </Box>
  );
};

export default CollapseSectionWrapper;
