import { Outlet } from 'react-router';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { AuthorizationPermissions, OnlineCasinoPermissions } from '@greenisland-core/permissions';
import AddPaymentMethod from 'src/pages/OnlineCasino/Settings/Configuration/Components/Forms/AddPaymentMethod';
import AddPaymentMethodLimits from 'src/pages/OnlineCasino/Settings/Configuration/Components/Forms/AddPaymentMethodLimits';
import EditPaymentMethod from 'src/pages/OnlineCasino/Settings/Configuration/Components/Forms/EditPaymentMethod';
import EditPaymentMethodLimits from 'src/pages/OnlineCasino/Settings/Configuration/Components/Forms/EditPaymentMethodLimits';
import PaymentMethods from 'src/pages/OnlineCasino/Settings/Configuration/PaymentMethods';
import Audit from 'src/pages/OnlineCasino/Settings/Management/Audit';

import BonusRequestSimulation from '../../pages/OnlineCasino/Settings/BonusRequestSimulation/BonusRequestSimulation';
import PromotionNameConfigurations from '../../pages/OnlineCasino/Settings/Configuration/PromotionNameConfigurations/PromotionNameConfigurations';
import Variables from '../../pages/OnlineCasino/Settings/Configuration/Variables';
import Info from '../../pages/OnlineCasino/Settings/Info/Info';
import KillSwitchSettings from '../../pages/OnlineCasino/Settings/Killswitch/KillSwitchSettings';
import Agents from '../../pages/OnlineCasino/Settings/Management/Agents';
import AgentDetails from '../../pages/OnlineCasino/Settings/Management/Components/AgentDetails';
import AgentForm from '../../pages/OnlineCasino/Settings/Management/Components/Forms/Agent/AgentForm';
import RoleDetails from '../../pages/OnlineCasino/Settings/Management/Components/RoleDetails';
import SetRole from '../../pages/OnlineCasino/Settings/Management/Components/SetRole';
import Roles from '../../pages/OnlineCasino/Settings/Management/Roles';
import { BaseRoute } from '../RouteInterfaces';

export const settingsRoutes: Array<BaseRoute> = [
  {
    name: 'Settings',
    path: 'settings',
    icon: faCog,
    collapse: true,
    element: Outlet,
    children: [
      {
        name: 'Management',
        path: 'management',
        collapse: true,
        mini: 'M',
        element: Outlet,
        permissions: [
          AuthorizationPermissions.getListAgentRoles,
          AuthorizationPermissions.getListAgents,
          AuthorizationPermissions.getAuditLogs,
        ],
        children: [
          {
            name: 'Agents',
            path: 'agents',
            element: Agents,
            mini: 'A',
            permissions: AuthorizationPermissions.getListAgents,
          },
          {
            name: 'Roles',
            path: 'roles',
            element: Roles,
            mini: 'R',
            permissions: AuthorizationPermissions.getListAgentRoles,
          },
          {
            name: 'Audit',
            path: 'audit',
            element: Audit,
            mini: 'AU',
            permissions: AuthorizationPermissions.getAuditLogs,
          },
          {
            name: 'Add Role',
            path: 'roles/add',
            element: SetRole,
            hiddenLink: true,
            permissions: AuthorizationPermissions.addAgentRole,
          },
          {
            name: 'Role Details',
            path: 'roles/:agentRoleId',
            element: RoleDetails,
            hiddenLink: true,
            permissions: AuthorizationPermissions.getAgentRoleDetails,
          },
          {
            name: 'Add Agent',
            path: 'agents/add',
            element: AgentForm,
            hiddenLink: true,
            permissions: [
              AuthorizationPermissions.addAgent,
              AuthorizationPermissions.getPermissions,
              AuthorizationPermissions.getListAgentRoles,
            ],
          },
          {
            name: 'Agent Details',
            path: 'agents/:agentId',
            element: AgentDetails,
            hiddenLink: true,
            permissions: AuthorizationPermissions.getAgentDetails,
          },
          {
            name: 'Edit Role',
            path: 'roles/edit/:agentRoleId',
            element: SetRole,
            hiddenLink: true,
            permissions: AuthorizationPermissions.updateAgentRole,
          },
          {
            name: 'Edit Agent',
            path: 'agents/edit/:agentId',
            element: AgentForm,
            hiddenLink: true,
            permissions: [
              AuthorizationPermissions.getAgentDetails,
              AuthorizationPermissions.getPermissions,
              AuthorizationPermissions.getListAgentRoles,
            ],
          },
        ],
      },
      {
        name: 'Configuration',
        path: 'configuration',
        collapse: true,
        mini: 'C',
        element: Outlet,
        permissions: [
          OnlineCasinoPermissions.getConfigurations,
          OnlineCasinoPermissions.getPromotionNameConfigurations,
        ],
        children: [
          {
            name: 'Variables',
            path: 'variables',
            element: Variables,
            mini: 'VAR',
            permissions: OnlineCasinoPermissions.getConfigurations,
          },
          {
            name: 'Payment methods',
            path: 'payment-methods',
            element: PaymentMethods,
            mini: 'PAY',
            permissions: OnlineCasinoPermissions.getConfigurations,
          },
          {
            name: 'Promotion Name Configurations',
            path: 'promotion-name-configurations',
            element: PromotionNameConfigurations,
            mini: 'PNC',
            permissions: OnlineCasinoPermissions.getPromotionNameConfigurations,
          },
          {
            name: 'Add payment method',
            path: 'payment-methods/add',
            hiddenLink: true,
            element: AddPaymentMethod,
            mini: 'ADDPAY',
            permissions: OnlineCasinoPermissions.getConfigurations,
          },
          {
            name: 'Edit payment method',
            path: 'payment-methods/:paymentMethodId',
            hiddenLink: true,
            element: EditPaymentMethod,
            mini: 'EPAY',
            permissions: OnlineCasinoPermissions.getConfigurations,
          },
          {
            name: 'Add payment method limits',
            path: 'payment-methods/:paymentMethodId/limits',
            hiddenLink: true,
            element: AddPaymentMethodLimits,
            mini: 'ADDPL',
            permissions: OnlineCasinoPermissions.getConfigurations,
          },
          {
            name: 'Edit payment method limits',
            path: 'payment-methods/:paymentMethodId/limits/edit',
            hiddenLink: true,
            element: EditPaymentMethodLimits,
            mini: 'EDITPL',
            permissions: OnlineCasinoPermissions.getConfigurations,
          },
        ],
      },
      {
        name: 'Bonus request simulation',
        path: 'bonus-request-simulation',
        element: BonusRequestSimulation,
        mini: 'BRS',
        permissions: OnlineCasinoPermissions.getBonusRequestSimulation,
      },
      {
        name: 'Killswitch',
        path: 'killswitch',
        element: KillSwitchSettings,
        permissions: [OnlineCasinoPermissions.getKillSwitchState, OnlineCasinoPermissions.setKillSwitchState],
      },
      {
        name: 'Info',
        path: 'info',
        element: Info,
        mini: 'I',
      },
    ],
  },
];

export const agentManagementRoutes: Array<BaseRoute> = [
  {
    name: 'Settings',
    path: 'settings',
    icon: faCog,
    collapse: true,
    element: Outlet,
    children: [
      {
        name: 'Management',
        path: 'management',
        collapse: true,
        mini: 'M',
        element: Outlet,
        permissions: [AuthorizationPermissions.getListAgentRoles, AuthorizationPermissions.getListAgents],
        children: [
          {
            name: 'Agents',
            path: 'agents',
            element: Agents,
            mini: 'A',
            permissions: AuthorizationPermissions.getListAgents,
          },
          {
            name: 'Roles',
            path: 'roles',
            element: Roles,
            mini: 'R',
            permissions: AuthorizationPermissions.getListAgentRoles,
          },
          {
            name: 'Add Role',
            path: 'roles/add',
            element: SetRole,
            hiddenLink: true,
            permissions: AuthorizationPermissions.addAgentRole,
          },
          {
            name: 'Role Details',
            path: 'roles/:agentRoleId',
            element: RoleDetails,
            hiddenLink: true,
            permissions: AuthorizationPermissions.getAgentRoleDetails,
          },
          {
            name: 'Add Agent',
            path: 'agents/add',
            element: AgentForm,
            hiddenLink: true,
            permissions: [
              AuthorizationPermissions.addAgent,
              AuthorizationPermissions.getPermissions,
              AuthorizationPermissions.getListAgentRoles,
            ],
          },
          {
            name: 'Agent Details',
            path: 'agents/:agentId',
            element: AgentDetails,
            hiddenLink: true,
            permissions: AuthorizationPermissions.getAgentDetails,
          },
          {
            name: 'Edit Role',
            path: 'roles/edit/:agentRoleId',
            element: SetRole,
            hiddenLink: true,
            permissions: AuthorizationPermissions.updateAgentRole,
          },
          {
            name: 'Edit Agent',
            path: 'agents/edit/:agentId',
            element: AgentForm,
            hiddenLink: true,
            permissions: [
              AuthorizationPermissions.getAgentDetails,
              AuthorizationPermissions.getPermissions,
              AuthorizationPermissions.getListAgentRoles,
            ],
          },
        ],
      },
    ],
  },
];

export default settingsRoutes;
