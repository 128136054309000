import React, { useCallback, useMemo, useState } from 'react';
import { Button } from '@mui/material';

// The useLoadMore hook
const useLoadMore = (total: number, initial: number, children: (start: number, end: number) => JSX.Element[]) => {
  const [limit, setLimit] = useState(initial);
  // Function to handle the "Load More" button click
  const handleLoadMore = useCallback(() => {
    const end = Math.min(limit + initial, total);
    setLimit(end);
  }, [initial, limit, total]);

  // Get the list of items to display initially
  const items = useMemo(() => {
    return children(0, limit);
  }, [limit, children]);

  const action = useMemo(
    () =>
      limit < total ? (
        <Button
          variant="outlined"
          color="secondary"
          sx={{ backgroundColor: 'white', width: 'fit-content', placeSelf: 'center' }}
          onClick={handleLoadMore}
        >
          Load more
        </Button>
      ) : null,
    [handleLoadMore, limit, total]
  );

  return { items, handleLoadMore, action };
};

export default useLoadMore;
