import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import {
  DepositSchema,
  ETransactionsBadRequestTypeSchema,
  GetDepositsGeneralParams,
  useAdvancedUserSearchV2Hook,
  useGetDepositsGeneral,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, LinearProgress, Stack } from '@mui/material';
import {
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import {
  DataGridContainer,
  DataGridPagination,
  FontAwesomeIcon,
  PermissionWrapper,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { useUpdateSearchParams } from '@greenisland-common/hooks/useUpdateSearchParams';

import { formatCurrency, formatDateTime } from '@greenisland-common/helpers';

import { StatusSpan } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';
import { DEPOSIT_ERROR_REASONS, DepositsParamsType } from '../Components/DetailsComponents/Constants/Deposits';
import useGetDepositFiltersQuery from '../hooks/useGetDepositFiltersQuery';
import DepositFilters from './DepositFilters';

type Row = DepositSchema;

const PAGE = 0;
const PAGE_SIZE = 25;

const LookupDeposits = () => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const {
    pageQuery,
    pageSizeQuery,
    startDateQuery,
    endDateQuery,
    hasDetailsQuery,
    isAscendingQuery,
    sortByQuery,
    statusQuery,
    methodQuery,
    providerQuery,
    ibanQuery,
    walletQuery,
    ipAddressQuery,
    minAmountQuery,
    maxAmountQuery,
    referenceQuery,
    depositIdQuery,
    accountIdQuery,
    userIdQuery,
  } = useGetDepositFiltersQuery();
  const updateSearchParamsHandler = useUpdateSearchParams();
  const advancedUserSearchV2 = useAdvancedUserSearchV2Hook();

  const canLookupUser = usePermission(OnlineCasinoPermissions.advancedUserSearchV2);

  const {
    data: depositsData,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetDepositsGeneral(
    {
      ...(startDateQuery && startDateQuery?.length > 0 ? { start: startDateQuery } : {}),
      ...(endDateQuery && endDateQuery?.length > 0 ? { end: endDateQuery } : {}),
      ...(minAmountQuery && minAmountQuery?.length > 0 ? { minAmount: minAmountQuery } : {}),
      ...(maxAmountQuery && maxAmountQuery?.length > 0 ? { maxAmount: maxAmountQuery } : {}),
      ...(userIdQuery && userIdQuery?.length > 0 ? { userId: userIdQuery } : {}),
      ...(accountIdQuery && accountIdQuery?.length > 0 ? { accountId: accountIdQuery } : {}),
      ...(referenceQuery && referenceQuery?.length > 0 ? { depositIdGuid: referenceQuery } : {}),
      ...(depositIdQuery && depositIdQuery?.length > 0 ? { pkeyInt: depositIdQuery } : {}),
      ...(ibanQuery && ibanQuery?.length > 0 ? { iban: ibanQuery } : {}),
      ...(walletQuery && walletQuery?.length > 0 ? { wallet: walletQuery } : {}),
      ...(ipAddressQuery && ipAddressQuery?.length > 0 ? { ipAddress: ipAddressQuery } : {}),
      ...(statusQuery && statusQuery?.length > 0 ? { status: statusQuery } : {}),
      ...(statusQuery && statusQuery?.length > 0 ? { status: statusQuery } : {}),
      ...(providerQuery && providerQuery?.length > 0 ? { provider: providerQuery } : {}),
      ...(methodQuery && methodQuery?.length > 0 ? { method: methodQuery } : {}),
      ...(sortByQuery && sortByQuery?.length > 0 ? { sortBy: sortByQuery } : {}),
      ...(isAscendingQuery && isAscendingQuery?.length > 0 ? { ascending: isAscendingQuery } : {}),
      pageNumber: pageQuery ? Number(pageQuery) : 0,
      numberOfItems: pageSizeQuery ? Number(pageSizeQuery) : PAGE_SIZE,
    } as GetDepositsGeneralParams,
    {
      query: {
        enabled: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onError: error => {
          if (error) {
            if ('type' in error && error.type) {
              const errorReason: ETransactionsBadRequestTypeSchema | undefined = error.type;
              const translationKey = errorReason ? DEPOSIT_ERROR_REASONS[errorReason] : 'somethingWentWrong';
              enqueueSnackbar(t(translationKey), { variant: 'error' });
            } else {
              enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
            }
          }
        },
      },
    }
  );

  useEffect(() => {
    if (searchParams?.toString()?.length) {
      refetch();
    }
  }, [pageQuery, pageSizeQuery, isAscendingQuery, sortByQuery, statusQuery]);

  const navigateOnClickHandler = useCallback(
    async (accountId: number, depositGuid?: string) => {
      if (accountId) {
        const response = await advancedUserSearchV2({ accountId: accountId });

        if (!depositGuid) {
          return navigate(`../../users/${response?.results?.at(0)?.userId}/details`);
        }
        return navigate(`../../users/${response?.results?.at(0)?.userId}/deposits/${depositGuid}`);
      }
    },
    [advancedUserSearchV2, navigate]
  );

  const columns = useMemo<GridColumns<Row>>(() => {
    const headers: GridColumns<DepositSchema> = [
      {
        headerName: t('id'),
        field: 'pkeyInt',
        type: 'string',
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('timestamp'),
        field: 'timestamp',
        type: 'date',
        valueGetter: ({ value }: GridValueGetterParams<number>) => value && new Date(value * 1000),
        valueFormatter: ({ value }: GridValueFormatterParams<Date>) => formatDateTime(value, false),
        flex: 2,
        minWidth: 100,
        description: 'The request time',
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('amount'),
        field: 'amount',
        type: 'number',
        valueFormatter: ({ value }: GridValueFormatterParams<number>) => formatCurrency(value, i18n.language),
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('accountId'),
        field: 'accountId',
        type: 'string',
        flex: 1,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<string, Row>) => {
          return canLookupUser ? (
            <span
              onClick={() => navigateOnClickHandler(parseInt(params.row.accountId ?? ''))}
              style={{ cursor: 'pointer' }}
            >
              {params.row.accountId}
            </span>
          ) : (
            <span>{params.row.accountId}</span>
          );
        },
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('provider'),
        field: 'provider',
        type: 'string',
        flex: 2,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('method'),
        field: 'paymentMethod',
        type: 'string',
        flex: 2,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('status'),
        field: 'status',
        type: 'string',
        flex: 2,
        minWidth: 100,
        renderCell: (params: GridRenderCellParams<string, DepositSchema>) => (
          <StatusSpan value={params.row.status?.toString() ?? ''} />
        ),
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('reference'),
        field: 'depositId',
        type: 'string',
        flex: 4,
        minWidth: 250,
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'actions',
        headerName: t('details'),
        flex: 1,
        minWidth: 70,
        disableColumnMenu: true,
        disableExport: true,
        disableReorder: true,
        filterable: false,
        hideable: false,
        sortable: false,
        resizable: false,
        renderCell: (params: GridRenderCellParams<string, DepositSchema>) => {
          return (
            <Button
              color="secondary"
              onClick={() => navigateOnClickHandler(parseInt(params.row.accountId ?? ''), params.row.depositId ?? '')}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          );
        },
        headerAlign: 'center',
        align: 'center',
      },
    ];
    const extraHeaders: GridColumns<DepositSchema> = [
      {
        headerName: t('failureReason'),
        field: 'reasonFailure',
        type: 'string',
        flex: 6,
        minWidth: 350,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('txId'),
        field: 'txId',
        type: 'string',
        flex: 1,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('ipAddress'),
        field: 'ipAddress',
        type: 'string',
        flex: 4,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },
      {
        headerName: t('walletId'),
        field: 'wallet',
        type: 'string',
        flex: 2,
        minWidth: 150,
        headerAlign: 'center',
        align: 'center',
      },
    ];

    return /true/.test(hasDetailsQuery || '') ? headers.concat(extraHeaders) : headers;
  }, [t, hasDetailsQuery, i18n.language, canLookupUser, navigateOnClickHandler]);

  const rows = useMemo(() => {
    return depositsData?.items?.map<Row>((item, index) => ({ ...item, id: index })) ?? [];
  }, [depositsData?.items]);

  return (
    <Stack spacing={2}>
      <DepositFilters fetchDepositsHandler={refetch} />
      <PermissionWrapper
        errorMessage={t('lookup.deposits.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getDepositsGeneral}
      >
        <DataGridContainer>
          <StyledDataGrid
            density="compact"
            autoHeight
            loading={isLoading || isFetching}
            rows={rows}
            columns={columns}
            pagination
            page={pageQuery ? Number(pageQuery) : PAGE}
            pageSize={pageSizeQuery ? Number(pageSizeQuery) : PAGE_SIZE}
            paginationMode="server"
            onPageChange={page => updateSearchParamsHandler(DepositsParamsType.PAGE, page.toString())}
            onPageSizeChange={pageSize => updateSearchParamsHandler(DepositsParamsType.PAGE_SIZE, pageSize.toString())}
            showColumnRightBorder={false}
            disableDensitySelector
            disableSelectionOnClick
            rowCount={depositsData?.pagingDetails?.totalItems ?? 0}
            componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            components={{
              Pagination: DataGridPagination,
              LoadingOverlay: LinearProgress,
            }}
          />
        </DataGridContainer>
      </PermissionWrapper>
    </Stack>
  );
};

export default LookupDeposits;
