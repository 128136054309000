import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import {
  DataGridContainer,
  ErrorCard,
  FontAwesomeIcon,
  Link,
  NoData,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { EventStreamParamsType } from './Constants/Eventstreams';
import { formatCurrency, getDateFromUnix, NoWrapSpan, StatusSpan } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';
import useAccountData from '../../hooks/useAccountData';
import AwardDepositBonusesDialog from './AwardDepositBonusesDialog';

type DepositBonusesProps = {
  userId: string;
};

const DepositBonuses = ({ userId }: DepositBonusesProps) => {
  const { t } = useTranslation();
  const canReadEventstream = usePermission(OnlineCasinoPermissions.getEventstreamStreamId);
  const canReadDepositBonuses = usePermission(OnlineCasinoPermissions.getUserDepositBonuses);
  const canAwardDepositBonuses = usePermission(OnlineCasinoPermissions.awardDepositBonus);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { data: accountData, isLoading, isError, error, permission } = useAccountData(userId);

  if (!permission) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={300} />;
  if (isError || !accountData) return <ErrorCard>{error?.message}</ErrorCard>;

  const headers: GridColumns = [
    {
      headerName: capitalize(t('createdOn')),
      field: 'createdOn',
      valueFormatter: ({ value }) => (value && value !== 0 ? getDateFromUnix(value) : '/'),
    },
    {
      headerName: capitalize(t('expiresOn')),
      field: 'expiresOn',
      valueFormatter: ({ value }) => (value && value !== 0 ? getDateFromUnix(value) : '/'),
    },
    { headerName: capitalize(t('source')), field: 'source', width: 300 },
    { headerName: capitalize(t('status')), field: 'status', renderCell: ({ value }) => <StatusSpan value={value} /> },
    { headerName: capitalize(t('conditions')), field: 'conditions', renderCell: ({ value }) => value, width: 150 },
    { headerName: '', field: 'actions', renderCell: ({ value }) => value, align: 'center', width: 50 },
  ];

  const rows: GridRowsProp = accountData.depositBonuses.map((item, i) => {
    return {
      ...item,
      id: i,
      conditions: <NoWrapSpan value={`${item.percentage}% tot ${formatCurrency(item.limit)}`} />,
      actions: canReadEventstream ? (
        <Button
          variant="text"
          component={Link}
          color="secondary"
          to={`../../../lookup/eventstreams?${
            EventStreamParamsType.STREAM_ID
          }=claimablepromotion/${item.streamId.toLowerCase()}`}
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
      ) : undefined,
    };
  });

  return (
    <>
      <Card>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CardHeader title={t('depositBonuses')} />
          <div>
            {canAwardDepositBonuses && (
              <Button variant="outlined" color="secondary" size="small" onClick={() => setOpenDialog(true)}>
                {t('award')}
              </Button>
            )}
            {canReadDepositBonuses && (
              <Button
                sx={{ ml: 2 }}
                variant="outlined"
                color="primary"
                size="small"
                component={Link}
                to="../depositbonuses"
              >
                {t('seeMore')}
              </Button>
            )}
          </div>
        </Box>
        <CardContent>
          {rows?.length > 0 ? (
            <DataGridContainer>
              <StyledDataGrid autoHeight density="compact" hideFooter columns={headers} rows={rows} />
            </DataGridContainer>
          ) : (
            <NoData />
          )}
        </CardContent>
      </Card>
      {openDialog && (
        <AwardDepositBonusesDialog openDialog={openDialog} setOpenDialog={setOpenDialog} userId={userId} />
      )}
    </>
  );
};

export default memo(DepositBonuses);
