import { SyntheticEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';

import { TabPanel } from '@greenisland-common/components/atoms';

import { useUpdateSearchParams } from '@greenisland-common/hooks/useUpdateSearchParams';

import GameSessionTab from './Components/GameSessionTab';
import { GameSummarySearchFilters } from './Constants';

const GameSessionsPage = () => {
  const { t } = useTranslation();
  const updateGameSessionQueryParamsHandler = useUpdateSearchParams();
  const [searchParams] = useSearchParams();

  const activeTab = searchParams.get(GameSummarySearchFilters.ACTIVE_TAB);

  useEffect(() => {
    if (!activeTab) {
      updateGameSessionQueryParamsHandler(GameSummarySearchFilters.ACTIVE_TAB, '0');
    }
  }, [activeTab, updateGameSessionQueryParamsHandler]);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    updateGameSessionQueryParamsHandler(GameSummarySearchFilters.ACTIVE_TAB, String(newValue));
    updateGameSessionQueryParamsHandler(GameSummarySearchFilters.PAGE, String(0));
  };

  return (
    <Card elevation={0}>
      <CardContent sx={{ pt: 0 }}>
        <Box mt={1}>
          <Tabs value={Number(activeTab)} onChange={handleTabChange} aria-label="tabs period">
            <Tab label={t('summaries')} id="tab-1" aria-controls="tab-panel-1" />
            <Tab label={t('sessions')} id="tab-2" aria-controls="tab-panel-2" />
          </Tabs>
        </Box>
        <TabPanel sx={{ mt: 2 }} value={Number(activeTab)} index={0}>
          <GameSessionTab isSummaryTab={true} />
        </TabPanel>
        <TabPanel sx={{ mt: 2 }} value={Number(activeTab)} index={1}>
          <GameSessionTab isSummaryTab={false} />
        </TabPanel>
      </CardContent>
    </Card>
  );
};

export default GameSessionsPage;
