import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetBonusesCashedPromos } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Link, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { endOfDay, getUnixTime, sub } from 'date-fns';

import { StyledDataGrid } from '@greenisland-common/components/atoms';
import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import { transformCurrencyV2 } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const CashedPromos = () => {
  const canReadCashedPromos = usePermission(OnlineCasinoPermissions.getBonusesCashedPromos);
  return canReadCashedPromos ? <CashedPromosInnerChild /> : null;
};

const currentDate = new Date();

const CashedPromosInnerChild = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startdate');
  const endDate = searchParams.get('enddate');
  const start = startDate ? Number(startDate) : getUnixTime(sub(currentDate, { days: 1 }));
  const end = endDate ? Number(endDate) : getUnixTime(endOfDay(currentDate));
  const { data: cashedPromos, isLoading: fetching } = useGetBonusesCashedPromos({
    startdate: start,
    enddate: end,
  });

  const columns: GridColDef[] = [
    {
      ...transformCurrencyV2('totalCashedPromoAmount', t),
      flex: 1,
    },
    {
      field: 'username',
      headerName: t('username'),
      flex: 1,
      renderCell: params => <Link href={`../../../users/${params.row.userId}/details`}>{params.value}</Link>,
    },
    {
      field: 'numberOfCashedPromos',
      headerName: t('numberOfCashedPromos'),
      flex: 1,
    },
  ];

  return (
    <Stack spacing={2}>
      <DateRangePicker
        unixStartDate={start * 1000}
        unixEndDate={end * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
        }}
      />
      <StyledDataGrid
        columns={columns}
        rows={cashedPromos ?? []}
        loading={fetching}
        autoHeight
        disableSelectionOnClick
        getRowId={row => row.id}
      />
    </Stack>
  );
};

export default CashedPromos;
