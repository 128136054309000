import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  BoosterPackCardPoolEntrySchema,
  EBoosterPackBadRequestTypeSchema,
  getGetBoosterPackCardsQueryKey,
  getGetBoosterPacksQueryKey,
} from '@greenisland-api';
import { useSnackbar } from 'notistack';

import { convertToMediaImageSchema } from '@greenisland-common/helpers';

import { BOOSTER_PACK_ERROR_REASONS } from '../../../constants/BoosterPackErrorReasons';
import {
  BoosterFormType,
  BoosterPackCardActions,
  BoosterPackCardDialogSchema,
  BoosterPackDialogSchema,
  OrdinalCard,
} from './index';

function useGetBoosterPackCardDefaultValues(
  selectedCard?: BoosterPackCardDialogSchema,
  actions?: BoosterPackCardActions[]
) {
  return useMemo(() => {
    if (!selectedCard) {
      return {
        actionId: undefined,
        boosterPackCardImages: null,
      };
    }

    const chosenActionId = actions?.find(action => action?.value === selectedCard.actionId);
    return {
      actionId: chosenActionId?.value || undefined,
      boosterPackCardImages: convertToMediaImageSchema(selectedCard?.images),
    };
  }, [actions, selectedCard]);
}

function useGetBoosterPackDefaultValues(selectedCard?: BoosterPackDialogSchema) {
  const { t } = useTranslation();

  const transformFromCardPoolData = (data: BoosterPackCardPoolEntrySchema[], name: string) => {
    const grouped = data?.reduce((acc, curr) => {
      if (!acc[curr?.ordinal]) {
        acc[curr?.ordinal] = [];
      }
      acc[curr?.ordinal].push({ card: curr?.cardId, weight: curr?.weight });
      return acc;
    }, {} as { [key: number]: OrdinalCard[] });

    return Object.keys(grouped).map(key => ({
      name,
      cards: grouped[parseInt(key, 10)],
    }));
  };

  return useMemo(() => {
    return {
      boosterPackImages: convertToMediaImageSchema(selectedCard?.images),
      ordinals: selectedCard
        ? transformFromCardPoolData(selectedCard.cardPool, t('marketing.boosterPacks.titles.ordinal'))
        : [],
    };
  }, [selectedCard, t]);
}

const useBoosterPackFormHandlers = (type: BoosterFormType, onClose: () => void, reset: any) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const queries = type === BoosterFormType.BOOSTER_PACKS ? getGetBoosterPacksQueryKey : getGetBoosterPackCardsQueryKey;

  const onSuccessHandler = useCallback(() => {
    queryClient.invalidateQueries(queries());
    enqueueSnackbar(t('success'), { variant: 'success' });
    onClose();
    reset();
  }, [queryClient, queries, enqueueSnackbar, t, onClose, reset]);

  const onErrorHandler = useCallback(
    error => {
      if (error) {
        const errorReason = error?.type;
        const translationKey =
          BOOSTER_PACK_ERROR_REASONS[errorReason as EBoosterPackBadRequestTypeSchema] ?? 'somethingWentWrong';
        enqueueSnackbar(t(translationKey), { variant: 'error' });
      }
    },
    [enqueueSnackbar, t]
  );

  return { onSuccessHandler, onErrorHandler };
};

export { useGetBoosterPackCardDefaultValues, useGetBoosterPackDefaultValues, useBoosterPackFormHandlers };
