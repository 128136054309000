import { useTranslation } from 'react-i18next';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { Box, BoxProps } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

const NoData = (props: BoxProps) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" {...props}>
      <FontAwesomeIcon sx={{ color: 'array.headers', mr: 2 }} icon={faDatabase} /> {t('noData')}
    </Box>
  );
};

export default NoData;
