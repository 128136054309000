import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { DataGridPro, GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, ErrorCard, NoData, StyledDataGrid } from '@greenisland-common/components/atoms';

import { formatCurrency, getDateFromUnix } from '../../../../../app/helpers/transformFunctions';
import useAccountData from '../../hooks/useAccountData';

type Props = {
  userId: string;
};

const DiscardedPromoCredits = ({ userId }: Props) => {
  const { t } = useTranslation();
  const { data: accountData, isLoading, isError, error, permission } = useAccountData(userId);

  if (!permission) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={300} />;
  if (isError || !accountData) return <ErrorCard>{error?.message}</ErrorCard>;

  const headers: GridColumns = [
    {
      headerName: capitalize(t('timestamp')),
      field: 'timestamp',
      valueFormatter: ({ value }) => (value && value !== 0 ? getDateFromUnix(value) : '/'),
    },
    {
      headerName: capitalize(t('discardedAmount')),
      field: 'discardedAmount',
      valueFormatter: ({ value }) => formatCurrency(value),
      width: 150,
    },
    {
      headerName: capitalize(t('totalWin')),
      field: 'totalWin',
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      headerName: capitalize(t('game')),
      field: 'game',
    },
    {
      headerName: capitalize(t('reason')),
      field: 'reason',
    },
  ];

  const rows: GridRowsProp = accountData?.discardedPromoCredits?.map((discardedPromo, i) => ({
    ...discardedPromo,
    id: i,
  }));

  return (
    <Card>
      <CardHeader title={t('discardedPromoCredits')} />
      <CardContent>
        {rows.length > 0 ? (
          <DataGridContainer>
            <StyledDataGrid autoHeight density="compact" columns={headers} rows={rows} />
          </DataGridContainer>
        ) : (
          <NoData />
        )}
      </CardContent>
    </Card>
  );
};

export default memo(DiscardedPromoCredits);
