interface LoyaltyBadge {
  imagePath: string;
  color: string;
}

const loyaltyBadges: Record<string, LoyaltyBadge> = {
  Standard: { imagePath: '/img/loyalty/standard.png', color: '#2A2A2A' },
  Bronze: { imagePath: '/img/loyalty/bronze.png', color: '#7B5F3C' },
  Silver: { imagePath: '/img/loyalty/silver.png', color: '#727272' },
  Gold: { imagePath: '/img/loyalty/gold.png', color: '#B6862D' },
  Diamond: { imagePath: '/img/loyalty/diamond.png', color: '#659D9E' },
  Platinum: { imagePath: '/img/loyalty/platinum.png', color: '#8FA4A7' },
  VIP: { imagePath: '/img/loyalty/vip.png', color: '#7449B8' },
};

export default loyaltyBadges;
