import { Fragment, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IndividualActionSchema } from '@greenisland-api';
import { Box, Button, Divider, List, TextField } from '@mui/material';

import DepositBonusForm from './Forms/DepositBonusForm/DepositBonusForm';
import PlaythroughBonusForm from './Forms/PlaythroughBonusForm/PlaythroughBonusForm';
import defaultIndividualActionFormValues from './hooks/useIndividualActionForm';
import useFeatureFlags from '../../../../../../common/hooks/useFeatureFlags';
import {
  BoosterPackForm,
  BoostForm,
  ContestForm,
  FreeBetForm,
  FreeCashForm,
  FreeSpinsForm,
  LoyaltyPointsForm,
  PlainMailForm,
  TrustpilotForm,
  VaultTokensForm,
  VoucherForm,
} from './components';
import type { IndividualActionForm as IndividualActionFormType } from './context';
import { useSubmitIndividualAction } from './hooks';

interface Props {
  individualAction?: IndividualActionSchema;
  actionId: string;
  campaignId: string;
}

const IndividualActionForm = ({ individualAction, actionId, campaignId }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<IndividualActionFormType>({
    defaultValues: defaultIndividualActionFormValues(individualAction),
  });
  const { handleSubmit, reset, register, errors, getValues, setValue } = methods;

  const submit = useSubmitIndividualAction(campaignId, actionId);
  const boostIsEnabled = useFeatureFlags('isBoostEnabled');
  const isTrustpilotEnabled = useFeatureFlags('isTrustpilotEnabled');
  const isBoosterPacksEnabled = useFeatureFlags('isBoosterPacksEnabled');

  const forms = [
    VoucherForm,
    LoyaltyPointsForm,
    PlaythroughBonusForm,
    DepositBonusForm,
    FreeSpinsForm,
    FreeBetForm,
    FreeCashForm,
    ContestForm,
    VaultTokensForm,
    PlainMailForm,
  ];

  if (boostIsEnabled) {
    forms.push(BoostForm);
  }

  if (isBoosterPacksEnabled) {
    forms.push(BoosterPackForm);
  }

  if (isTrustpilotEnabled) {
    forms.push(TrustpilotForm);
  }

  useEffect(() => {
    if (
      JSON.stringify(getValues().loyaltyDependentMaxPayout) !==
      JSON.stringify(individualAction?.boost?.loyaltyDependentMaxPayout)
    ) {
      setValue('loyaltyDependentMaxPayout', individualAction?.boost?.loyaltyDependentMaxPayout);
    }
  }, [getValues, individualAction, reset, setValue]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)}>
        <Box sx={{ m: 2, mt: 4 }}>
          <TextField
            required
            fullWidth
            size="small"
            label={t('name')}
            name="name"
            inputRef={register({ required: true })}
            error={Boolean(errors.name)}
          />
        </Box>
        <List>
          <Divider />
          {forms.map((FormComponent, index) => (
            <Fragment key={index}>
              <FormComponent />
              <Divider />
            </Fragment>
          ))}
        </List>
        <Box sx={{ m: 2 }}>
          <Button fullWidth color="primary" variant="contained" type="submit">
            {t('save')}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};

export default IndividualActionForm;
