import { useTranslation } from 'react-i18next';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Button, capitalize, Dialog, DialogActions, IconButton, Stack, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  description: string;
}

const KillSwitchWarningDialog = ({ open, onClose, onSubmit, description }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <Stack spacing={2} my={5}>
          <Typography
            variant="h5"
            fontWeight={500}
            fontSize="xx-large"
            color={theme => theme.palette.error.main}
            sx={{ py: 1 }}
            textAlign="center"
          >
            {t('settings.killswitch.warningTitle')}
          </Typography>
          <Typography fontSize="x-large" fontWeight={500} variant="body1" sx={{ pt: 1 }} textAlign="center">
            {capitalize(description)}
          </Typography>
        </Stack>
        <DialogActions sx={{ px: 3, py: 2, justifyContent: 'center' }}>
          <Button size="large" variant="outlined" onClick={onClose} sx={{ px: 5, mr: 2 }}>
            {t('no')}
          </Button>
          <Button size="large" variant="contained" color="error" onClick={onSubmit} sx={{ px: 5 }}>
            {t('yes')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default KillSwitchWarningDialog;
