import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { LinkedAccountDataSchema, LinkedAccountUsersSchema, useGetUserLinkedAccounts } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Collapse, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridColDef } from '@mui/x-data-grid-pro';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../../../../../app/hooks';

const LinkedAccountContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row wrap',
  width: '100%',
}));

const IndLinkedContainer = styled(Box)({
  flex: '1 1 33%',
});

const LinkedTitle = styled(Typography)(({ theme }) => ({
  margin: 0,
  marginBottom: theme.spacing(2.5),
  fontWeight: 400,
  textTransform: 'uppercase',
  fontSize: '1rem',
}));

const CollapseContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  '& h5': {
    fontWeight: 'normal',
    margin: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,
  },
}));

const LinkedAccounts = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const [expanded, setExpanded] = useState('');

  const canReadUserLinkedAccounts = usePermission(OnlineCasinoPermissions.getUserLinkedAccounts);

  const { data: userLinkedAccount, isLoading: fetching } = useGetUserLinkedAccounts(userId, {
    query: { enabled: canReadUserLinkedAccounts && !!userId },
  });

  const prepareData = (userLinkedAccount: LinkedAccountUsersSchema) => {
    const objectToReturn: { [key: string]: string[] } = {};

    userLinkedAccount.links.forEach((data: LinkedAccountDataSchema) => {
      if (objectToReturn[data.type]) {
        objectToReturn[data.type].push(data.data);
      } else {
        objectToReturn[data.type] = [data.data];
      }
    });

    return objectToReturn;
  };

  type ListProps = {
    data: {
      [key: string]: string[];
    };
  };

  const LinkedAccountsList = ({ data }: ListProps) => {
    if (data) {
      const testArray: any = [];
      const entries = Object.entries(data);
      entries.forEach((entry: any, index) => {
        const [key, data] = entry;

        testArray.push(
          <IndLinkedContainer key={`${key}-${index}`}>
            <LinkedTitle variant="h5">
              <strong>{t(key)}</strong>
            </LinkedTitle>
            {data &&
              data.map((content: string, index: number) => <Typography key={`${key}-${index}`}>{content}</Typography>)}
          </IndLinkedContainer>
        );
      });

      return <LinkedAccountContainer>{testArray}</LinkedAccountContainer>;
    }

    return null;
  };

  const handleExpandClick = (id: string) => {
    const idToUse: string = id === expanded ? '' : id;
    setExpanded(idToUse);
  };

  const columns: GridColDef[] = [
    {
      field: 'linkedUsername',
      headerName: '',
      flex: 1,
      renderCell: params => (
        <Link href={`../../users/${params.row.linkedUserId}/details`}>
          {`${params.value} (${Object.keys(prepareData(params.row)).join(',')})`}
        </Link>
      ),
    },
    {
      field: 'actions',
      headerName: t('actions'),
      flex: 1,
      renderCell: params => (
        <Button size="small" variant="outlined" onClick={() => handleExpandClick(params.row.linkedUserId)}>
          {t('details')}
        </Button>
      ),
    },
  ];

  return (
    <Stack spacing={2}>
      <Typography variant="h6">{userLinkedAccount?.username ?? ''}</Typography>
      <Stack spacing={2}>
        <StyledDataGrid
          loading={fetching}
          columns={columns}
          rows={userLinkedAccount?.linkedUsers || []}
          getRowId={row => row.linkedUserId}
          autoHeight
          disableSelectionOnClick
          pageSize={5}
          rowsPerPageOptions={[5, 10, 25]}
        />
        {userLinkedAccount?.linkedUsers.map((row, index) => {
          const isOpen: boolean = expanded === row.linkedUserId;
          const prepared = prepareData(row);
          return (
            <Collapse key={index} in={isOpen} timeout="auto" unmountOnExit>
              <CollapseContainer>
                <LinkedAccountsList data={prepared} />
              </CollapseContainer>
            </Collapse>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default LinkedAccounts;
