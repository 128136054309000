/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * The direction of the update. E.g., if UserDetails, then the UserDetails will be updated with the current critical fields value.
 */
export type EResolveCriticalFieldsMismatchUpdateDirectionSchema =
  (typeof EResolveCriticalFieldsMismatchUpdateDirectionSchema)[keyof typeof EResolveCriticalFieldsMismatchUpdateDirectionSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EResolveCriticalFieldsMismatchUpdateDirectionSchema = {
  userDetails: 'userDetails',
  criticalFields: 'criticalFields',
} as const;
