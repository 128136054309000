/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * Playing break bad request type:
 * `invalidUserId` - Requested user ID is invalid.
 * `endDateInPast` - The chosen end date is in the past.
 * `stricterPlayingBreakAlreadyPresent` - A stricter playing break was already present.
 * `noActivePlayingBreak` - Requested user is not on a playing break.
 * `regulatorNotAllowedToCancel` - Cancelling this playing break is not allowed by the regulator.
 * `alreadyShorterThanCancellationWaitPeriod` - Cancelling this playing break is not allowed because you are already in the cancellation wait period.
 * `cancellationAlreadyRequested` - A cancellation has already been requested for this playing break that is still in process or was approved
 * `cooldownLowerThanMinimumPlayingBreak` - Requested cooldown is lower than the minimum playing break allowed by the regulator.

 */
export type EPlayingBreakBadRequestTypeSchema =
  (typeof EPlayingBreakBadRequestTypeSchema)[keyof typeof EPlayingBreakBadRequestTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EPlayingBreakBadRequestTypeSchema = {
  invalidUserId: 'invalidUserId',
  endDateInPast: 'endDateInPast',
  stricterPlayingBreakAlreadyPresent: 'stricterPlayingBreakAlreadyPresent',
  noActivePlayingBreak: 'noActivePlayingBreak',
  regulatorNotAllowedToCancel: 'regulatorNotAllowedToCancel',
  alreadyShorterThanCancellationWaitPeriod: 'alreadyShorterThanCancellationWaitPeriod',
  cancellationAlreadyRequested: 'cancellationAlreadyRequested',
  cooldownLowerThanMinimumPlayingBreak: 'cooldownLowerThanMinimumPlayingBreak',
} as const;
