import { useMemo } from 'react';
import { EPromotionNameConfigurationTypeSchema, PromotionNameConfigurationSchema } from '@greenisland-api';

import {
  PROMOTION_NAME_CONFIGURATION_COLUMN_FIELDS,
  PROMOTION_NAME_CONFIGURATION_TYPE_OPTIONS,
} from './promotionNameConfigurationInterfaces';

function useGetPromotionNameConfigurationMissingTypes(
  configurations: PromotionNameConfigurationSchema[],
  type: EPromotionNameConfigurationTypeSchema
) {
  return useMemo(() => {
    const allTypes = PROMOTION_NAME_CONFIGURATION_TYPE_OPTIONS[type] as [];

    const usedTypesSet = new Set(
      configurations?.map(
        configuration => configuration[PROMOTION_NAME_CONFIGURATION_COLUMN_FIELDS[type] as keyof typeof configuration]
      ) || []
    );

    return allTypes?.filter(type => !usedTypesSet.has(type)) || [];
  }, [configurations, type]);
}

export { useGetPromotionNameConfigurationMissingTypes };
