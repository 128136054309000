import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Typography } from '@mui/material';
import { usePermission } from 'src/app/hooks';

import ResolveKYCRiskDialog from './ResolveKYCRiskDialog';

interface Props {
  agentTaskId: string;
}

const ResolveKYCRiskAction = ({ agentTaskId }: Props) => {
  const { t } = useTranslation();
  const canResolveDetectedRisk = usePermission(OnlineCasinoPermissions.resolveDetectedRisk);
  const [isResolveRiskDialogOpen, setResolveRiskDialogOpen] = useState<boolean>(false);

  return (
    <>
      <Box sx={{ display: 'flex', gap: 1 }}>
        {agentTaskId ? (
          <>
            <Button
              color="error"
              variant="contained"
              size="small"
              onClick={() => setResolveRiskDialogOpen(true)}
              disabled={!canResolveDetectedRisk || !agentTaskId}
            >
              {t('kyc.headers.resolveDetectedRisk')}
            </Button>
          </>
        ) : (
          <Typography color={'error'} variant="caption">
            {t('kyc.invalidAgentTaskId')}
          </Typography>
        )}
      </Box>
      {agentTaskId && (
        <ResolveKYCRiskDialog
          openDialog={isResolveRiskDialogOpen}
          setOpenDialog={setResolveRiskDialogOpen}
          agentTaskId={agentTaskId}
        />
      )}
    </>
  );
};

export default ResolveKYCRiskAction;
