import {
  EPromotionNameConfigurationTypeSchema,
  PromotionNameConfigurationSchema,
  PromotionNameConfigurationsSchema,
} from '@greenisland-api';

type PromotionNameConfigurationsByType = {
  [key in EPromotionNameConfigurationTypeSchema]?: PromotionNameConfigurationSchema[];
};

const groupPromotionNameConfigurations = (configurations: PromotionNameConfigurationsSchema) => {
  return configurations?.reduce((acc, item) => {
    const type = item.type as EPromotionNameConfigurationTypeSchema;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type]?.push(item);
    return acc;
  }, {} as PromotionNameConfigurationsByType);
};

export type { PromotionNameConfigurationsByType };
export { groupPromotionNameConfigurations };
