import { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IEntityState } from '@greenisland/stores';
import { canAccess } from '@greenisland-core/permissions';
import { useAppSelector } from '@greenisland-core/store';
import { Button, Collapse, Drawer, Hidden, Icon, ListItemText, Theme, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

import LanguageSelector from '@greenisland-common/components/molecules/LanguageSelector/LanguageSelector';

import { useTenantContext } from '../../app/context/TenantContext';
import { BaseRoute } from '../RouteInterfaces';
import BadgeCounter from './BadgeCounter';
import ProductSwitcher from './ProductSwitcher';

interface DrawerContentProps {
  entitySelected: IEntityState;
  handleDrawerToggle: any;
  routes: Array<BaseRoute>;
  handleLogout: any;
  identifier: string;
}

interface SideBarProps extends DrawerContentProps {
  drawerOpen: boolean;
}

const useSideBarStyles = makeStyles((theme: Theme) => ({
  toolbar: { ...theme.mixins.toolbar },
  drawerPaper: { backgroundColor: theme.palette.background.default },
}));

const SideBar = ({
  routes,
  drawerOpen,
  handleDrawerToggle,
  entitySelected,
  handleLogout,
  identifier,
}: SideBarProps) => {
  const theme = useTheme();
  const classes = useSideBarStyles();
  const medium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Drawer
      variant={medium ? 'permanent' : 'temporary'}
      open={drawerOpen}
      onClose={handleDrawerToggle}
      classes={{ paper: classes.drawerPaper }}
      sx={{ maxWidth: theme.spacing(32), width: { md: theme.spacing(32) } }}
      ModalProps={{ keepMounted: true }}
    >
      <DrawerContent
        routes={routes}
        handleLogout={handleLogout}
        entitySelected={entitySelected}
        handleDrawerToggle={handleDrawerToggle}
        identifier={identifier}
      />
    </Drawer>
  );
};

export default SideBar;

const useDrawerContentStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  innerCollapse: {
    boxSizing: 'border-box',
    '& li': {
      margin: 0,
    },
  },
  innerCollapse1: {
    padding: '0px 0px 5px 60px',
    '& $sidebarListItem': {
      color: '#8C8C8C',
      textTransform: 'uppercase',
      padding: '5px 20px 5px 0px',
      '&:hover': {
        backgroundColor: 'unset',
        color: theme.palette.primary.main,
      },
    },
    '& $sidebarListItem span': {
      fontSize: '0.9rem',
      letterSpacing: '0.5px',
    },
    '& $listItemInner': {
      '& span': {
        fontSize: '0.95rem',
      },
      '& .MuiBadge-badge': {
        display: 'flex',
        alignItems: 'center',
        marginRight: '15px',
        fontSize: '0.8rem',
      },
    },
  },
  innerCollapse2: {
    padding: '5px 10px',
    '& $navlinkTextInner': {
      padding: '2.5px 0px',
    },
    '& $navlinkTextInner span': {
      fontSize: '0.95rem',
    },
  },
  sidebarListItem: {
    color: '#595959',
    padding: '5px 20px',
    boxSizing: 'border-box',
    display: 'flex',
    cursor: 'pointer',
    margin: '5px 0',
    fontWeight: 400,
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#F5F6F9',
    },
  },
  activeSidebarListItemLevel0: {
    backgroundColor: '#F5F6F9',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.primary.main,
      width: 4,
      transform: 'scale(1)',
    },
  },
  activeSidebarListItemLevel1: {
    color: theme.palette.primary.main + ' !important',
  },
  itemIcon: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    marginRight: '20px',
    '& svg': {
      width: '0.75em',
      height: '0.75em',
    },
    '& .material-icons': {
      width: '0.75em',
      height: '0.75em',
    },
  },
  iconText: {
    fontFamily: theme.typography.fontFamily,
    height: '100% !important',
    width: '100% !important',
    fontSize: 14,
  },
  collapseListItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  routeNameCollapse: {
    fontWeight: 400,
    '& li div span': {
      fontSize: '0.9rem',
    },
  },
  caret: {
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
    color: 'rgba(0, 0, 0, 0.33)',
  },
  caretActive: {
    transform: 'rotate(180deg)',
  },
  listItemInner: {
    position: 'relative',
    padding: 0,
    marginBottom: 5,
    listStyle: 'none',
    '& $navlinkTextInner': {
      fontSize: '0.9rem',
    },
  },
  navlinkTextInner: {
    display: 'flex',
    alignItems: 'center',
    color: '#595959',
    width: '100%',
    padding: '5px 10px 5px 0px',
    boxSizing: 'border-box',
    '&:hover, &:active, &:focus': { color: theme.palette.primary.main },
    '& .MuiListItemText-primary': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
  },
  navlinkActiveInner: { color: theme.palette.primary.main },
  routeName: { fontWeight: 400 },
  innerDrawerList: {
    flex: 1,
    maxWidth: theme.spacing(32),
    overflowY: 'auto',
    padding: theme.spacing(1, 0),
    margin: 0,
  },
  listItem: {
    position: 'relative',
    padding: 0,
    margin: '5px 0',
    listStyle: 'none',
  },
  navlinkActive: {
    backgroundColor: '#F5F6F9',
    '&::before': {
      transform: 'scale(1)',
    },
  },
  navlinkText: {
    color: '#595959',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '5px 20px',
    boxSizing: 'border-box',
    '&:hover': { backgroundColor: '#F5F6F9' },
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.primary.main,
      width: 4,
      transform: 'scale(0)',
    },
    '& .MuiListItemText-primary': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
  },
  divider: {
    border: 'none',
    height: '1px',
    margin: 0,
    flexShrink: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  bottomDrawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 10px',
    boxSizing: 'border-box',
    width: '100%',
    '& > div': { padding: 0 },
    [theme.breakpoints.down('md')]: {
      '& > div:first-of-type': {
        paddingBottom: 15,
      },
    },
  },
}));

const DrawerContent = memo(({ handleDrawerToggle, routes, handleLogout }: DrawerContentProps) => {
  const { t } = useTranslation();
  const tenant = useTenantContext();
  const classes: any = useDrawerContentStyles();
  const location = useLocation();

  const { currentAgent } = useAppSelector(state => state.authentication.data);
  const [openCollapse, setOpenCollapse] = useState<{ [key: string]: boolean }>({});

  const handleOpenCollapse = (collapse: string | undefined) => {
    if (collapse) {
      const key = collapse;
      const open: boolean = openCollapse[key];
      setOpenCollapse({ ...openCollapse, [key]: !open });
    }
  };

  const mapCollapse: any = (collapseRoute: BaseRoute, level: number, prevRoute: string) => {
    const canAccessRoute =
      currentAgent &&
      canAccess(currentAgent, collapseRoute.permissions ?? [], Boolean(collapseRoute.strictPermissions));
    const collapseClasses = classnames({
      [classes.innerCollapse]: true,
      [classes[`innerCollapse${level}`]]: level,
    });

    const sidebarListItemClasses = classnames({
      [classes.sidebarListItem]: true,
      [classes[`activeSidebarListItemLevel${level - 1}`]]:
        `/${location.pathname.split('/')[2 + level]}` === collapseRoute.path ? level : false,
    });

    return canAccessRoute ? (
      <Fragment key={`collapseRouteLevel${collapseRoute.path}-${level}`}>
        <li
          className={`${sidebarListItemClasses} sidebarRoute-${collapseRoute.name.replace(/\s/g, '')}`}
          onClick={() => handleOpenCollapse(collapseRoute.path)}
        >
          {level < 2 &&
            (collapseRoute.icon ? (
              <div className={classes.itemIcon}>
                <FontAwesomeIcon icon={collapseRoute.icon} />
              </div>
            ) : (
              <div className={classes.itemIcon}>
                <Icon className={classes.iconText}>
                  {collapseRoute.mini ? collapseRoute.mini : collapseRoute.name[0].toUpperCase()}
                </Icon>
              </div>
            ))}
          <ListItemText
            className={classes.collapseListItem}
            classes={{ primary: classes.routeNameCollapse }}
            primary={collapseRoute.name}
            secondary={
              <b
                className={classnames({
                  [classes.caret]: true,
                  [classes.caretActive]:
                    openCollapse[Array.isArray(collapseRoute.path) ? collapseRoute.path[0] : collapseRoute.path],
                })}
              />
            }
          />
        </li>
        <Collapse
          className={`collapse-${collapseRoute.name.replace(/\s/g, '')}`}
          in={openCollapse[Array.isArray(collapseRoute.path) ? collapseRoute.path[0] : collapseRoute.path]}
          unmountOnExit
        >
          <div className={collapseClasses}>
            {collapseRoute.children &&
              collapseRoute.children.map((route: BaseRoute) => {
                const canAccessChild =
                  currentAgent && canAccess(currentAgent, route.permissions ?? [], Boolean(route.strictPermissions));

                if (route.collapse && route.children) {
                  return mapCollapse(route, level + 1, `${prevRoute}${collapseRoute.path}`);
                }

                const isAvailableOnlyForSpecificTenant =
                  !route.showOnlyForSpecificTenant || tenant.regulatorV2 === route.showOnlyForSpecificTenant;

                if (
                  !route.collapse &&
                  !route.hiddenLink &&
                  !route.redirect &&
                  isAvailableOnlyForSpecificTenant &&
                  canAccessChild
                ) {
                  const resolvedPath = `${prevRoute}/${collapseRoute.path}/${route.path}`.replace(/^\/+/g, '');

                  return (
                    <li key={route.path} className={classes.listItemInner}>
                      <NavLink
                        className={props => {
                          return classnames({
                            [classes.navlinkTextInner]: true,
                            [classes.navlinkActiveInner]: props.isActive,
                          });
                        }}
                        onClick={() => handleDrawerToggle()}
                        id={`sidebarRoute-${route.name.replace(/\s/g, '')}`}
                        to={resolvedPath}
                        state={{ name: route.name }}
                      >
                        <ListItemText classes={{ primary: classes.routeName }}>{route.name}</ListItemText>
                        {route?.badgeType && <BadgeCounter badgeType={route?.badgeType} />}
                      </NavLink>
                    </li>
                  );
                }
                return null;
              })}
          </div>
        </Collapse>
      </Fragment>
    ) : null;
  };

  return (
    <div className={classes.container}>
      <ProductSwitcher />
      <hr className={classes.divider} />
      <ul className={classes.innerDrawerList}>
        {routes.map((route: BaseRoute, index: number) => {
          if (route.collapse && route.children) {
            return mapCollapse(route, 1, '');
          }
          if (!route.collapse && !route.hiddenLink && !route.redirect) {
            const resolvedPath = route.path?.replace(/^\/+/g, '');
            return (
              <li key={route.name + '-' + index} className={classes.listItem}>
                <NavLink
                  className={props =>
                    classnames({ [classes.navlinkText]: true, [classes.navlinkActive]: props.isActive })
                  }
                  id={`sidebarRoute-${route.name.replace(/\s/g, '')}`}
                  onClick={() => handleDrawerToggle()}
                  to={resolvedPath ? resolvedPath : '*'}
                  state={{ name: route.name }}
                >
                  {route.icon ? (
                    <div className={classes.itemIcon}>
                      <FontAwesomeIcon icon={route.icon} />
                    </div>
                  ) : (
                    <div className={classes.itemIcon}>
                      <Icon className={classes.iconText}>{route.mini ? route.mini : route.name[0].toUpperCase()}</Icon>
                    </div>
                  )}
                  <ListItemText classes={{ primary: classes.routeName }}>{route.name}</ListItemText>
                </NavLink>
              </li>
            );
          }
          return null;
        })}
      </ul>
      <hr className={classes.divider} />
      <div className={classes.bottomDrawerContainer}>
        <LanguageSelector />
        <Hidden mdUp>
          <Button
            startIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
            aria-label={t('logout')}
            id="logoutButton"
            size="medium"
            onClick={handleLogout}
            fullWidth
            color="secondary"
            variant="outlined"
          >
            {t('logout')}
          </Button>
        </Hidden>
      </div>
    </div>
  );
});

DrawerContent.displayName = 'DrawerContent';
