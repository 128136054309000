import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CountryListSchema,
  CountryListSchemaItem,
  EGameLibraryModelSchema,
  GameProviderSchema,
  GameStudioSchema,
  GameStudiosSchema,
  getGetGameProviderQueryKey,
} from '@greenisland-api';
import { Box, Button, Stack, Typography } from '@mui/material';

import CheckboxField from '@greenisland-common/components/molecules/CheckboxField';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';
import Input from '@greenisland-common/components/molecules/Input';
import TransferList from '@greenisland-common/components/organisms/TransferList';

import SyncGameLibraryButton from './Forms/SyncGameLibraryButton';
import TranslationsDictionaryFields, { TotalFieldArrayDataItem } from './Forms/TranslationsDictionaryFields';
import processFieldArray from './utils/processFieldArray';
import GameStudiosComp from './GameStudios';

interface FormSchema extends Omit<GameProviderSchema, 'blacklistedCountries'> {
  blacklistedCountries: { name: string; id: string }[];
  totalFieldArray?: TotalFieldArrayDataItem[];
}
interface Props {
  gameProvider: GameProviderSchema;
  countries: CountryListSchema;
  submit: (values: GameProviderSchema) => void;
  gameStudios: GameStudiosSchema;
  error: string;
}

const ProviderFormEdit = ({ gameProvider, gameStudios, countries, submit, error }: Props) => {
  const { t } = useTranslation();
  const [selectedGameStudios, setSelectedGameStudios] = useState<GameStudiosSchema>(
    gameStudios.filter((studio: GameStudioSchema) =>
      gameProvider.gameStudios.find((id: string) => id === studio.gameStudioId)
    )
  );

  const DEFAULT_VALUES: FormSchema = {
    code: gameProvider.code,
    providerId: gameProvider.providerId,
    blacklistedCountries: countries
      .filter(country =>
        gameProvider.blacklistedCountries.some(blacklisted => blacklisted.country === country.countryCode)
      )
      .map(country => ({
        name: country.countryName,
        id: country.countryCode,
      })),
    demoEndPointUrl: gameProvider.demoEndPointUrl,
    enabled: gameProvider.enabled,
    endPointUrl: gameProvider.endPointUrl,
    gameStudios: gameProvider.gameStudios,
    mobileDemoEndPointUrl: gameProvider.mobileDemoEndPointUrl,
    mobileEndPointUrl: gameProvider.mobileEndPointUrl,
    order: gameProvider.order,
    providerName: gameProvider.providerName,
    integrationCode: gameProvider.integrationCode,
    conflictGamelib: gameProvider.conflictGamelib,
    gameLibId: gameProvider.gameLibId,
    description: gameProvider.description,
  };

  const methods = useForm<FormSchema>({ defaultValues: DEFAULT_VALUES });

  const { handleSubmit, register, control, errors, watch } = methods;

  const selectedCountries = watch('blacklistedCountries');

  const onSubmit = handleSubmit((data: FormSchema) => {
    if (data.totalFieldArray) {
      const { descriptions } = processFieldArray(data.totalFieldArray);
      data.description = descriptions;
      delete data.totalFieldArray;
    }

    if (gameProvider) {
      const newValues: GameProviderSchema = {
        ...gameProvider,
        ...data,
        blacklistedCountries: data.blacklistedCountries.map(country => ({ country: country.id })),
        gameStudios: selectedGameStudios.map(studio => studio.gameStudioId),
      };
      submit(newValues);
    }
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Stack direction="column" spacing={2}>
          <Typography variant="h4">{gameProvider.providerName}</Typography>
          {gameProvider?.conflictGamelib && (
            <Typography color="error">{t('conflict.gameLibId', { gameLibId: gameProvider.gameLibId })}</Typography>
          )}
          <Box>
            <SyncGameLibraryButton
              gameLibEntity={gameProvider}
              gameLibModel={EGameLibraryModelSchema.GameProvider}
              invalidatedQueryKey={[...getGetGameProviderQueryKey(gameProvider.providerId)]}
            />
          </Box>
          <CheckboxField name="enabled" label={t('enabled')} control={control} />
          <Input label={t('endPointUrl')} fullWidth name="endPointUrl" inputRef={register()} />
          <Input label={t('demoEndPointUrl')} fullWidth name="demoEndPointUrl" inputRef={register()} />
          <Input label={t('mobileEndPointUrl')} fullWidth name="mobileEndPointUrl" inputRef={register()} />
          <Input label={t('mobileDemoEndPointUrl')} fullWidth name="mobileDemoEndPointUrl" inputRef={register()} />
          <Input
            label={t('integrationCode')}
            fullWidth
            name="integrationCode"
            inputRef={register({ required: t('fieldIsRequired') as string })}
          />
          {errors.integrationCode && <Box color="error.main">{errors.integrationCode.message}</Box>}
          <TranslationsDictionaryFields nameDescription="description" control={control as any} />
          <Controller
            control={control}
            name="blacklistedCountries"
            render={({ name }) => {
              return (
                <TransferList
                  control={control}
                  name={name}
                  leftTitle={t('allowedCountries')}
                  rightTitle={t('blacklistedCountries')}
                  allItems={countries
                    ?.filter(
                      (item: CountryListSchemaItem) =>
                        !selectedCountries.some(selected => selected.id === item.countryCode)
                    )
                    .map((item: CountryListSchemaItem) => ({
                      name: item.countryName,
                      id: item.countryCode,
                    }))}
                  fallbackText={'no countries available'}
                />
              );
            }}
          />
          {gameProvider && (
            <GameStudiosComp
              gameStudios={gameStudios}
              selectedGameStudios={selectedGameStudios}
              setSelectedGameStudios={setSelectedGameStudios}
            />
          )}
          {error && <ErrorState errorMessage={error} />}
          <Button variant="contained" type="submit">
            {t('save')}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default ProviderFormEdit;
