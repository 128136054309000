/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GameSportProvider
 * This API describes the client-facing interface of Portal-GameSportProvider
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type { Error, ProviderCodes, ProviderConfigurationModel } from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary set provider configuration
 */
export const useSetProviderConfigurationHook = () => {
  const setProviderConfiguration = useCustomInstance<void>();

  return (providerIntegrationCode: string, providerConfigurationModel: ProviderConfigurationModel) => {
    return setProviderConfiguration({
      url: `/providerconfiguration/setproviderconfiguration/${providerIntegrationCode}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: providerConfigurationModel,
    });
  };
};

export const useSetProviderConfigurationMutationOptions = <TError = Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetProviderConfigurationHook>>>,
    TError,
    { providerIntegrationCode: string; data: ProviderConfigurationModel },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetProviderConfigurationHook>>>,
  TError,
  { providerIntegrationCode: string; data: ProviderConfigurationModel },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setProviderConfiguration = useSetProviderConfigurationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetProviderConfigurationHook>>>,
    { providerIntegrationCode: string; data: ProviderConfigurationModel }
  > = props => {
    const { providerIntegrationCode, data } = props ?? {};

    return setProviderConfiguration(providerIntegrationCode, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetProviderConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetProviderConfigurationHook>>>
>;
export type SetProviderConfigurationMutationBody = ProviderConfigurationModel;
export type SetProviderConfigurationMutationError = Error;

/**
 * @summary set provider configuration
 */
export const useSetProviderConfiguration = <TError = Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetProviderConfigurationHook>>>,
    TError,
    { providerIntegrationCode: string; data: ProviderConfigurationModel },
    TContext
  >;
}) => {
  const mutationOptions = useSetProviderConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary get provider configuration
 */
export const useGetProviderConfigurationHook = () => {
  const getProviderConfiguration = useCustomInstance<ProviderConfigurationModel>();

  return (providerIntegrationCode: string, signal?: AbortSignal) => {
    return getProviderConfiguration({
      url: `/providerconfiguration/getproviderconfiguration/${providerIntegrationCode}`,
      method: 'get',
      signal,
    });
  };
};

export const getGetProviderConfigurationQueryKey = (providerIntegrationCode: string) =>
  [`/providerconfiguration/getproviderconfiguration/${providerIntegrationCode}`] as const;

export const useGetProviderConfigurationQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProviderConfigurationHook>>>,
  TError = Error
>(
  providerIntegrationCode: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProviderConfigurationHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProviderConfigurationHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProviderConfigurationQueryKey(providerIntegrationCode);

  const getProviderConfiguration = useGetProviderConfigurationHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProviderConfigurationHook>>>> = ({
    signal,
  }) => getProviderConfiguration(providerIntegrationCode, signal);

  return { queryKey, queryFn, enabled: !!providerIntegrationCode, ...queryOptions };
};

export type GetProviderConfigurationQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetProviderConfigurationHook>>>
>;
export type GetProviderConfigurationQueryError = Error;

/**
 * @summary get provider configuration
 */
export const useGetProviderConfiguration = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProviderConfigurationHook>>>,
  TError = Error
>(
  providerIntegrationCode: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProviderConfigurationHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetProviderConfigurationQueryOptions(providerIntegrationCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get providers code
 */
export const useGetIntegrationsHook = () => {
  const getIntegrations = useCustomInstance<ProviderCodes>();

  return (signal?: AbortSignal) => {
    return getIntegrations({ url: `/providerconfiguration/getintegrations`, method: 'get', signal });
  };
};

export const getGetIntegrationsQueryKey = () => [`/providerconfiguration/getintegrations`] as const;

export const useGetIntegrationsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetIntegrationsHook>>>,
  TError = Error
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIntegrationsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIntegrationsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIntegrationsQueryKey();

  const getIntegrations = useGetIntegrationsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetIntegrationsHook>>>> = ({ signal }) =>
    getIntegrations(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetIntegrationsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetIntegrationsHook>>>>;
export type GetIntegrationsQueryError = Error;

/**
 * @summary get providers code
 */
export const useGetIntegrations = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetIntegrationsHook>>>,
  TError = Error
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIntegrationsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetIntegrationsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
