import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import {
  GameSessionsSortKeyParamParameter,
  GameSessionsSummaryGamingRevenueComparisonParameter,
  GameSessionsSummaryGamingRevenueComparisonValueParameter,
  GameSessionsSummaryGroupByKeyParamParameter,
  GameSessionsSummarySortKeyParamParameter,
  GameSessionSummaryForGroupSchema,
  GetGameSessionSummariesParams,
  SortOrderParamParameter,
  useGetGameProviders,
  useGetGameSessionSummaries,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useDebouncedValue } from '@lilib/hooks';
import { Box, LinearProgress, Link, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';

import {
  DataGridContainer,
  DataGridPagination,
  FontAwesomeIcon,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { useUpdateSearchParams } from '@greenisland-common/hooks/useUpdateSearchParams';

import { capitalize } from '@greenisland-common/helpers/formatters';

import { formatCurrency } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';
import { GameSummaryCardVariant, GameSummarySearchFilters, SessionGameCardType } from '../Constants';
import useGetGameSessionFiltersQuery from '../hooks/useGetGameSessionFiltersQuery';
import useGetGameSessionFormParams, { GameSessionFormType } from '../hooks/useGetGameSessionFormParams';

const CARD_PAGE_SIZE = 10;
const FULL_PAGE_SIZE = 25;

type Row = GameSessionSummaryForGroupSchema;

interface Props {
  cardType: SessionGameCardType;
  cardVariant?: GameSummaryCardVariant;
  startDate: Date;
  endDate: Date;
  groupByKey?: GameSessionsSummaryGroupByKeyParamParameter;
  sortOrder: SortOrderParamParameter;
  sortKey: GameSessionsSummarySortKeyParamParameter;
  gamingRevenueComparison?: GameSessionsSummaryGamingRevenueComparisonParameter;
  gamingRevenueComparisonValue?: GameSessionsSummaryGamingRevenueComparisonValueParameter;
}

const GameSummariesTableData = ({
  cardType,
  startDate,
  endDate,
  groupByKey,
  sortKey,
  sortOrder,
  gamingRevenueComparison = GameSessionsSummaryGamingRevenueComparisonParameter.None,
  gamingRevenueComparisonValue,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const canReadGameSessionSummaries = usePermission(OnlineCasinoPermissions.getGameSessionSummaries);
  const updateGameSessionQueryParamsHandler = useUpdateSearchParams();

  const { data: gameProviders } = useGetGameProviders();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(cardType === SessionGameCardType.ALL ? FULL_PAGE_SIZE : CARD_PAGE_SIZE);

  const { userIdQuery, gameIdQuery, pageQuery, groupByKeyQuery } = useGetGameSessionFiltersQuery();

  const [debouncedUserId] = useDebouncedValue(userIdQuery, { wait: 500 });
  const [debouncedGameId] = useDebouncedValue(gameIdQuery, { wait: 500 });

  const params = useGetGameSessionFormParams({
    formType: GameSessionFormType.SUMMARIES,
    params: {
      debouncedUserId,
      debouncedGameId,
      startDate,
      gamingRevenueComparison,
      gamingRevenueComparisonValue,
      page,
      pageSize,
      endDate,
      groupByKey,
      sortKey,
      sortOrder,
    },
  });

  const {
    data: gameSessionSummaries,
    isLoading,
    isError,
  } = useGetGameSessionSummaries(
    {
      ...(params as GetGameSessionSummariesParams),
    },
    {
      query: { enabled: canReadGameSessionSummaries },
    }
  );

  const navigateToSessionHandler = useCallback(
    (summaryInfo: GameSessionSummaryForGroupSchema) => {
      const searchParams = new URLSearchParams({
        [GameSummarySearchFilters.ACTIVE_TAB]: '1',
        [GameSummarySearchFilters.PAGE]: '0',
        [GameSummarySearchFilters.START_DATE]: params.startDate.toString(),
        [GameSummarySearchFilters.END_DATE]: params.endDate.toString(),
        [GameSummarySearchFilters.SORT_BY_ORDER]: SortOrderParamParameter.descending,
        [GameSummarySearchFilters.SORT_BY_KEY]: GameSessionsSortKeyParamParameter.GamingRevenue,
      });

      if (params.groupByKey === GameSessionsSummaryGroupByKeyParamParameter.User && summaryInfo?.userId) {
        searchParams.set(GameSummarySearchFilters.USER_ID, summaryInfo.userId.toString());
      }

      if (
        params.groupByKey === GameSessionsSummaryGroupByKeyParamParameter.Game ||
        params.groupByKey === GameSessionsSummaryGroupByKeyParamParameter.GameAndUser
      ) {
        const chosenUserId = summaryInfo?.userId || params?.userId;

        if (chosenUserId) {
          searchParams.set(GameSummarySearchFilters.USER_ID, chosenUserId.toString());
        }

        if (summaryInfo?.providerName && summaryInfo?.gameId) {
          const provider = gameProviders?.find(provider => provider.providerName === summaryInfo.providerName);
          if (provider) {
            searchParams.set(GameSummarySearchFilters.PROVIDER_ID, provider.providerId.toString());
            searchParams.set(GameSummarySearchFilters.GAME_ID, summaryInfo.gameId.toString());
          }
        }
      }

      if (params.gamingRevenueComparison && params.gamingRevenueComparisonValue) {
        searchParams.set(GameSummarySearchFilters.GAMING_REVENUE_COMPARISON, params.gamingRevenueComparison.toString());
        searchParams.set(
          GameSummarySearchFilters.GAMING_REVENUE_COMPARISON_VALUE,
          params.gamingRevenueComparisonValue.toString()
        );
      }

      const filtersQuery = '?' + searchParams.toString();

      navigate(`../gamesessionsoverview${filtersQuery}`);
    },
    [gameProviders, navigate, params]
  );

  const columns = useMemo<GridColumns<Row>>(() => {
    const dashboardColumns = [
      {
        field: 'id',
        headerName: '',
        filterable: false,
        renderCell: (params: GridValidRowModel) => {
          const indexOnPage = params.api.getAllRowIds().indexOf(params.id) % pageSize;
          return page * pageSize + indexOnPage + 1;
        },
        minWidth: 30,
        flex: 0.02,
      },
      ...(groupByKey === GameSessionsSummaryGroupByKeyParamParameter.Game
        ? [
            {
              field: '',
              headerName: capitalize(t('game')),
              renderCell: ({ row }: { row: Row }) => (
                <Stack display="flex" flexDirection="column" lineHeight="normal">
                  {row?.gameName}
                  <Typography variant="caption">{row?.providerName}</Typography>
                </Stack>
              ),
              sortable: false,
              minWidth: 150,
              flex: 0.4,
            },
          ]
        : [
            {
              field: 'nickname',
              headerName: capitalize(t('user')),
              renderCell: ({ row }: { row: Row }) => (
                <Stack display="flex" flexDirection="column">
                  <Typography variant="caption" lineHeight="normal" fontSize="small">
                    <Link href={`${cardType !== SessionGameCardType.ALL ? './' : '../'}users/${row?.userId}/details`}>
                      {row?.nickname}
                    </Link>
                  </Typography>
                  <Typography variant="caption" lineHeight="normal" fontSize="smaller">
                    {capitalize(t('userId'))} - {row?.userId}
                  </Typography>
                </Stack>
              ),
              sortable: false,
              minWidth: 150,
              flex: 0.4,
            },
          ]),
      {
        field: 'gamingRevenue',
        headerName: capitalize(t('gamesActivityGamingRevenue')),
        valueFormatter: ({ value }: { value: number }) => {
          return formatCurrency(value);
        },
        sortable: false,
        minWidth: 90,
        flex: 0.4,
      },
    ];
    const lookupDetailsColumns = [
      {
        field: '',
        headerName: capitalize(t('game')),
        renderCell: (params: GridRenderCellParams<Row>) => (
          <Stack display="flex" flexDirection="column">
            {params?.row?.gameName}
            <Typography variant="caption">{params?.row?.providerName}</Typography>
          </Stack>
        ),
        sortable: false,
        minWidth: 120,
        flex: 0.15,
      },
      {
        field: 'gamingRevenue',
        headerName: capitalize(t('gamesActivityGamingRevenue')),
        valueFormatter: ({ value }: { value: number }) => formatCurrency(value),
        sortable: false,
        minWidth: 120,
        flex: 0.1,
      },
      {
        field: 'rtp',
        headerName: capitalize(t('rtp')),
        valueFormatter: ({ value }: { value: number }) => `${(value * 100).toFixed(2)}%`,
        sortable: false,
        minWidth: 90,
        flex: 0.1,
      },
      {
        field: 'sessionCount',
        headerName: capitalize(t('gamesActivityNumberOfSessions')),
        sortable: false,
        minWidth: 80,
        flex: 0.1,
      },
    ];

    const allDataColumns = [
      {
        field: 'actions',
        type: 'actions',
        minWidth: 80,
        flex: 0.01,
        getActions: (params: GridRowParams<Row>) => [
          <Tooltip title={t('details')} key="details">
            <Box>
              <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faEye} />}
                title={t('details')}
                label={t('details')}
                onClick={() => navigateToSessionHandler(params.row)}
              />
            </Box>
          </Tooltip>,
        ],
      },
      ...(!groupByKeyQuery || groupByKeyQuery === GameSessionsSummaryGroupByKeyParamParameter.Game
        ? [
            {
              field: '',
              headerName: capitalize(t('game')),
              renderCell: (params: GridRenderCellParams<Row>) => (
                <Stack display="flex" flexDirection="column">
                  {params?.row?.gameName}
                  <Typography variant="caption">{params?.row?.providerName}</Typography>
                </Stack>
              ),
              sortable: false,
              minWidth: 120,
              flex: 0.15,
            },
          ]
        : [
            {
              field: 'nickname',
              headerName: capitalize(t('user')),
              renderCell: ({ row }: { row: Row }) => (
                <Stack display="flex" flexDirection="column">
                  <Typography variant="caption" fontSize="14px">
                    <Link href={`${cardType !== SessionGameCardType.ALL ? './' : '../'}users/${row?.userId}/details`}>
                      {row?.nickname}
                    </Link>
                  </Typography>
                  <Typography variant="caption" fontSize="smaller">
                    {capitalize(t('userId'))} - {row?.userId}
                  </Typography>
                </Stack>
              ),
              sortable: false,
              minWidth: 140,
              flex: 0.1,
            },
          ]),
      {
        field: 'gamingRevenue',
        headerName: capitalize(t('gamesActivityGamingRevenue')),
        valueFormatter: ({ value }: { value: number }) => formatCurrency(value),
        sortable: false,
        minWidth: 120,
        flex: 0.1,
      },
      {
        field: 'rtp',
        headerName: capitalize(t('rtp')),
        valueFormatter: ({ value }: { value: number }) => `${(value * 100).toFixed(2)}%`,
        sortable: false,
        minWidth: 90,
        flex: 0.1,
      },
      {
        field: 'totalBetAmount',
        headerName: capitalize(t('gamesActivityTotalStake')),
        valueFormatter: ({ value }: { value: number }) => formatCurrency(value),
        sortable: false,
        minWidth: 180,
        flex: 0.1,
      },
      {
        field: 'totalWinAmount',
        headerName: capitalize(t('gamesActivityTotalWinnings')),
        valueFormatter: ({ value }: { value: number }) => formatCurrency(value),
        sortable: true,
        minWidth: 180,
        flex: 0.1,
      },
      {
        field: 'betCount',
        headerName: capitalize(t('gamesActivityNumberOfBets')),
        valueFormatter: ({ value }: { value: number }) => value,
        sortable: false,
        minWidth: 150,
        flex: 0.1,
      },
      {
        field: 'winCount',
        headerName: capitalize(t('gamesActivityNumberOfWins')),
        valueFormatter: ({ value }: { value: number }) => value,
        sortable: false,
        minWidth: 150,
        flex: 0.1,
      },
      {
        field: 'sessionCount',
        headerName: capitalize(t('gamesActivityNumberOfSessions')),
        sortable: false,
        minWidth: 100,
        flex: 0.08,
      },
    ];

    if (cardType === SessionGameCardType.DASHBOARD) {
      return dashboardColumns;
    } else if (cardType === SessionGameCardType.LOOKUP) {
      return lookupDetailsColumns;
    } else {
      return allDataColumns;
    }
  }, [cardType, groupByKey, groupByKeyQuery, navigateToSessionHandler, page, pageSize, t]);

  const rows = useMemo(
    () =>
      gameSessionSummaries?.results?.map<Row>((sessionSummary, index) => ({
        id: index,
        ...sessionSummary,
      })) ?? [],
    [gameSessionSummaries?.results]
  );

  return (
    <Box mt={2}>
      <PermissionWrapper
        errorMessage={t('gamesession.permissions.error.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getGameSessionSummaries}
      >
        <DataGridContainer>
          <LimitedDataGrid
            density="compact"
            autoHeight
            rowHeight={cardType === SessionGameCardType.DASHBOARD ? 50 : 70}
            loading={isLoading}
            rows={rows}
            columns={columns}
            pagination
            page={Number(pageQuery) || page}
            rowCount={gameSessionSummaries?.pagingDetails?.totalItems}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(page: number) => {
              if (!isLoading) {
                if (cardType === SessionGameCardType.ALL) {
                  updateGameSessionQueryParamsHandler(GameSummarySearchFilters.PAGE, String(page));
                } else {
                  setPage(page);
                }
              }
            }}
            onPageSizeChange={setPageSize}
            components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
            sx={{
              '.MuiDataGrid-footerContainer .MuiBox-root': {
                [theme.breakpoints.up('md')]: {
                  flexDirection: cardType !== SessionGameCardType.ALL ? 'column' : 'row',
                  mx: cardType !== SessionGameCardType.ALL ? 0 : 1,
                },
              },
            }}
          />
        </DataGridContainer>
      </PermissionWrapper>
    </Box>
  );
};

export default GameSummariesTableData;
