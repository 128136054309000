import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreateTestUserRequestSchema } from '@greenisland-api';
import { Box, Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import CollapseSectionWrapper from './CollapseSectionWrapper';

const TestUserGenderSettings = () => {
  const { t } = useTranslation();
  const { setValue, register } = useFormContext<CreateTestUserRequestSchema>();

  register('isMale');

  const [isGenderChosen, setIsGenderChosen] = useState(false);
  const [isMaleChosen, setIsMaleChosen] = useState<boolean | null>(null);

  useEffect(() => {
    if (!isGenderChosen) {
      setIsMaleChosen(null);
      setValue('isMale', null);
    }
  }, [isGenderChosen, setValue]);

  const changeGenderOptionHandler = (isMale: boolean) => {
    setIsMaleChosen(isMale);
    setValue('isMale', isMale);
  };

  return (
    <CollapseSectionWrapper title={t('gender')}>
      <Box>
        <FormControlLabel
          control={<Checkbox color="primary" size="medium" />}
          onChange={(_, value) => setIsGenderChosen(value)}
          label={t('common.chooseGender')}
          checked={isGenderChosen}
          sx={{ '.MuiTypography-root': { fontWeight: isGenderChosen ? 600 : 400 }, mb: 2 }}
        />
        <Box ml={4}>
          <RadioGroup>
            <FormControlLabel
              onChange={() => changeGenderOptionHandler(true)}
              control={<Radio checked={isGenderChosen && isMaleChosen === true} size="small" />}
              label={t('male')}
              disabled={!isGenderChosen}
              sx={{ '.MuiTypography-root': { fontWeight: isMaleChosen === true ? 600 : 400 } }}
              required={isGenderChosen}
            />
            <FormControlLabel
              onChange={() => changeGenderOptionHandler(false)}
              control={<Radio checked={isGenderChosen && isMaleChosen === false} size="small" />}
              label={t('female')}
              disabled={!isGenderChosen}
              sx={{ '.MuiTypography-root': { fontWeight: isMaleChosen === false ? 600 : 400 } }}
              required={isGenderChosen}
            />
          </RadioGroup>
        </Box>
      </Box>
    </CollapseSectionWrapper>
  );
};

export default TestUserGenderSettings;
