import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { actionCreators, EIdentityProvider } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { Button, Dialog, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  content: { padding: '16px 24px' },
  button: { marginRight: 10 },
  divider: {
    margin: '16px 0',
    display: 'flex',
    alignItems: 'center',
    '&::before': {
      height: 1,
      margin: '0 1em',
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
      content: '""',
      flex: 1,
    },
    '&::after': {
      height: 1,
      margin: '0 1em',
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
      content: '""',
      flex: 1,
    },
  },
});

const TokenExpiredContainer = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { message: error } = useAppSelector(state => state.alert.alert);
  const [open, setOpen] = useState<boolean>(false);

  const handleLogout = () => {
    // We clear these items because of an annoying bug (not 100% sure but it works so yeah)
    // https://github.com/aws-amplify/amplify-flutter/issues/401#issuecomment-1224270594
    localStorage.removeItem('amplify-signin-with-hostedUI');
    localStorage.removeItem('amplify-redirected-from-hosted-ui');
    Auth.signOut()
      .catch(err => {
        enqueueSnackbar(err || t('somethingWentWrong'), { variant: 'error' });
      })
      .finally(() => {
        dispatch(actionCreators.logout());
        setOpen(false);
      });
  };

  useEffect(() => {
    if (error && error.toLowerCase() === 'unauthorized') {
      setOpen(true);
      Auth.currentSession()
        .then(data => {
          const url = location.search ? location.pathname + location.search : location.pathname;
          sessionStorage.setItem('redirectUrl', url);
          dispatch(actionCreators.handleLoginSuccess(data.getIdToken().getJwtToken(), EIdentityProvider.Cognito));
          setOpen(false);
        })
        .catch(() => {
          handleLogout();
          setOpen(false);
        });
    }
  }, [error]);

  return (
    <Dialog maxWidth="md" disableEscapeKeyDown={true} open={open}>
      <DialogContent className={classes.content}>
        <div>{t('refreshingSession')}</div>
        <Button fullWidth onClick={handleLogout}>
          {t('signOut')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default TokenExpiredContainer;
