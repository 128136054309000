import { useTranslation } from 'react-i18next';
import { useGetLastActivitySeconds } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box } from '@mui/material';

import { UpdatedAt } from '@greenisland-common/components/atoms';

import useFeatureFlags from '@greenisland-common/hooks/useFeatureFlags';

import DashboardGameCardsSection from './Components/DashboardGameCardsSection';
import { usePermission } from '../../../app/hooks';
import { useDashboardComponents } from './hooks';

const Dashboard = () => {
  const { t } = useTranslation();
  const canReadLastActivitySeconds = usePermission(OnlineCasinoPermissions.getLastActivitySeconds);
  const isGamesEnabled = useFeatureFlags('isGamesEnabled');
  const isSportsEnabled = useFeatureFlags('isSportsEnabled');
  const isPromoEnabled = useFeatureFlags('isPromoEnabled');
  const {
    data: lastActivity,
    isLoading,
    isError,
  } = useGetLastActivitySeconds({ query: { enabled: canReadLastActivitySeconds } });

  const cards = useDashboardComponents(isGamesEnabled, isSportsEnabled, isPromoEnabled);

  return (
    <Box
      sx={{
        marginTop: 2,
        spacing: 1,
      }}
    >
      <DashboardGameCardsSection cards={cards} />
      {!isLoading && !isError && lastActivity && (
        <Box mt={4}>
          {lastActivity &&
            lastActivity.timeElapsedSeconds &&
            (lastActivity.timeElapsedSeconds >= 3600 ? (
              <UpdatedAt
                value={t('updatedHoursAndMinutes', {
                  hours: Math.floor(lastActivity.timeElapsedSeconds / 3600),
                  minutes: Math.floor((lastActivity.timeElapsedSeconds / 60) % 60),
                })}
              />
            ) : (
              <UpdatedAt
                value={t('updatedMinutes', {
                  minutes: Math.floor(lastActivity.timeElapsedSeconds / 60),
                })}
              />
            ))}
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
