import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreateTestUserRequestSchema, ELanguagesSchema } from '@greenisland-api';
import { Box, Checkbox, FormControlLabel } from '@mui/material';

import { SelectInput } from '@greenisland-common/components/atoms';

import CollapseSectionWrapper from './CollapseSectionWrapper';

const TestUserPreferredLanguageSettings = () => {
  const { t } = useTranslation();

  const { setValue } = useFormContext<CreateTestUserRequestSchema>();

  const [isPreferredLanguageChosen, setIsPreferredLanguageChosen] = useState(false);

  useEffect(() => {
    if (!isPreferredLanguageChosen) {
      setValue('language', null);
    }
  }, [isPreferredLanguageChosen, setValue]);

  return (
    <CollapseSectionWrapper title={t('preferredLanguage')}>
      <Box display="flex" flexDirection="column">
        <FormControlLabel
          control={<Checkbox color="primary" size="medium" />}
          onChange={(_, value) => setIsPreferredLanguageChosen(value)}
          label={t('common.choosePrefferedLanguage')}
          checked={isPreferredLanguageChosen}
          sx={{
            '.MuiTypography-root': { fontWeight: isPreferredLanguageChosen ? 600 : 400 },
            mb: 2,
            width: 'fit-content',
          }}
        />
        <SelectInput
          size="small"
          name="language"
          label={t('language')}
          rules={isPreferredLanguageChosen ? { required: t('fieldIsRequired') } : {}}
          options={Object.keys(ELanguagesSchema).map(key => ({ value: key, label: t(key) }))}
          defaultValue={undefined}
          required={isPreferredLanguageChosen}
          clearSelect={true}
          sx={{ width: '50%' }}
          disabled={!isPreferredLanguageChosen}
        />
      </Box>
    </CollapseSectionWrapper>
  );
};

export default TestUserPreferredLanguageSettings;
