/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice Authorization
 * Description of API calls used for the actual authorization and agents management Changelog: - 20210315 MAE: Added linkedProductIds to agentrole - 20201124 MAE: Added crossEntityRole bij GetRoles - 20201119 MAE: Removed refreshtoken from loginresult, as it should never leave the server + loginTokens call - 20201027 MAE: invoice details for Product - 20201026 MAE: Permission level as enum - 20201019 MAE: propertyName permisions - 20200824 MAE: new LoginResultType - 20200729 MAE: discarded 6 permissions/roles calls on agent and agentrole, adjusted update models and list model for agent, separate update models for entity and product
 * OpenAPI spec version: 1.3.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../authHttpClient';
import type {
  AddPermissionNameSchema,
  AgentDetailsSchema,
  AgentPermissionPropertyNamesSchema,
  AgentPermissionsSchema,
  AgentRoleSchema,
  AgentRolesSchema,
  AgentsSchema,
  AuditLogsSchema,
  BadRequestResponse,
  ChangePasswordSchema,
  DefaultResponse,
  DeleteAgentParams,
  EntitiesSchema,
  EntitySchema,
  GenericResultSchema,
  GetAuditLogsFilterSchema,
  LoginChallengeSchema,
  LoginResultSchema,
  LoginSchema,
  NotFoundResponse,
  ProductSchema,
  ProductsSchema,
  ResetPasswordSchema,
  StartupAgentSchema,
  UnauthorizedResponse,
  UpdateAgentRoleSchema,
  UpdateAgentSchema,
  UpdateEntitySchema,
  UpdateProductSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Login call
 */
export const useLoginHook = () => {
  const login = useCustomInstance<LoginResultSchema>();

  return (loginSchema: LoginSchema) => {
    return login({
      url: `/authorization/login`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: loginSchema,
    });
  };
};

export const useLoginMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useLoginHook>>>,
    TError,
    { data: LoginSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useLoginHook>>>,
  TError,
  { data: LoginSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const login = useLoginHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useLoginHook>>>,
    { data: LoginSchema }
  > = props => {
    const { data } = props ?? {};

    return login(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useLoginHook>>>>;
export type LoginMutationBody = LoginSchema;
export type LoginMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Login call
 */
export const useLogin = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useLoginHook>>>,
    TError,
    { data: LoginSchema },
    TContext
  >;
}) => {
  const mutationOptions = useLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Tokens call
 */
export const useLoginTokensHook = () => {
  const loginTokens = useCustomInstance<LoginResultSchema>();

  return (loginCode: string, signal?: AbortSignal) => {
    return loginTokens({ url: `/authorization/login/${loginCode}`, method: 'get', signal });
  };
};

export const getLoginTokensQueryKey = (loginCode: string) => [`/authorization/login/${loginCode}`] as const;

export const useLoginTokensQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useLoginTokensHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  loginCode: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useLoginTokensHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useLoginTokensHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLoginTokensQueryKey(loginCode);

  const loginTokens = useLoginTokensHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useLoginTokensHook>>>> = ({ signal }) =>
    loginTokens(loginCode, signal);

  return { queryKey, queryFn, enabled: !!loginCode, ...queryOptions };
};

export type LoginTokensQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useLoginTokensHook>>>>;
export type LoginTokensQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Tokens call
 */
export const useLoginTokens = <
  TData = Awaited<ReturnType<ReturnType<typeof useLoginTokensHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  loginCode: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useLoginTokensHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useLoginTokensQueryOptions(loginCode, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Logoff call
 */
export const useLogoffHook = () => {
  const logoff = useCustomInstance<void>();

  return () => {
    return logoff({ url: `/authorization/logoff`, method: 'post' });
  };
};

export const useLogoffMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useLogoffHook>>>, TError, TVariables, TContext>;
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useLogoffHook>>>, TError, TVariables, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const logoff = useLogoffHook();

  const mutationFn: MutationFunction<Awaited<ReturnType<ReturnType<typeof useLogoffHook>>>, TVariables> = () => {
    return logoff();
  };

  return { mutationFn, ...mutationOptions };
};

export type LogoffMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useLogoffHook>>>>;

export type LogoffMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Logoff call
 */
export const useLogoff = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useLogoffHook>>>, TError, TVariables, TContext>;
}) => {
  const mutationOptions = useLogoffMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Login with challenge call
 */
export const useLoginWithChallengeHook = () => {
  const loginWithChallenge = useCustomInstance<LoginResultSchema>();

  return (loginChallengeSchema: LoginChallengeSchema) => {
    return loginWithChallenge({
      url: `/authorization/loginchallenge`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: loginChallengeSchema,
    });
  };
};

export const useLoginWithChallengeMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useLoginWithChallengeHook>>>,
    TError,
    { data: LoginChallengeSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useLoginWithChallengeHook>>>,
  TError,
  { data: LoginChallengeSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const loginWithChallenge = useLoginWithChallengeHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useLoginWithChallengeHook>>>,
    { data: LoginChallengeSchema }
  > = props => {
    const { data } = props ?? {};

    return loginWithChallenge(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type LoginWithChallengeMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useLoginWithChallengeHook>>>
>;
export type LoginWithChallengeMutationBody = LoginChallengeSchema;
export type LoginWithChallengeMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Login with challenge call
 */
export const useLoginWithChallenge = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useLoginWithChallengeHook>>>,
    TError,
    { data: LoginChallengeSchema },
    TContext
  >;
}) => {
  const mutationOptions = useLoginWithChallengeMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Request to start the password reset flow
 */
export const useResetPasswordHook = () => {
  const resetPassword = useCustomInstance<GenericResultSchema>();

  return (resetPasswordSchema: ResetPasswordSchema) => {
    return resetPassword({
      url: `/authorization/resetpassword`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: resetPasswordSchema,
    });
  };
};

export const useResetPasswordMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetPasswordHook>>>,
    TError,
    { data: ResetPasswordSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useResetPasswordHook>>>,
  TError,
  { data: ResetPasswordSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const resetPassword = useResetPasswordHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useResetPasswordHook>>>,
    { data: ResetPasswordSchema }
  > = props => {
    const { data } = props ?? {};

    return resetPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetPasswordMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useResetPasswordHook>>>>;
export type ResetPasswordMutationBody = ResetPasswordSchema;
export type ResetPasswordMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Request to start the password reset flow
 */
export const useResetPassword = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetPasswordHook>>>,
    TError,
    { data: ResetPasswordSchema },
    TContext
  >;
}) => {
  const mutationOptions = useResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Change the password
 */
export const useChangePasswordHook = () => {
  const changePassword = useCustomInstance<GenericResultSchema>();

  return (changePasswordSchema: ChangePasswordSchema) => {
    return changePassword({
      url: `/authorization/changepassword`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: changePasswordSchema,
    });
  };
};

export const useChangePasswordMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>,
    TError,
    { data: ChangePasswordSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>,
  TError,
  { data: ChangePasswordSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const changePassword = useChangePasswordHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>,
    { data: ChangePasswordSchema }
  > = props => {
    const { data } = props ?? {};

    return changePassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChangePasswordMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>>;
export type ChangePasswordMutationBody = ChangePasswordSchema;
export type ChangePasswordMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Change the password
 */
export const useChangePassword = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useChangePasswordHook>>>,
    TError,
    { data: ChangePasswordSchema },
    TContext
  >;
}) => {
  const mutationOptions = useChangePasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Startup call, returning the current agent and its access to entities, products
 */
export const useGetStartupHook = () => {
  const getStartup = useCustomInstance<StartupAgentSchema>();

  return (signal?: AbortSignal) => {
    return getStartup({ url: `/authorization/startup`, method: 'get', signal });
  };
};

export const getGetStartupQueryKey = () => [`/authorization/startup`] as const;

export const useGetStartupQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetStartupHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStartupHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStartupHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStartupQueryKey();

  const getStartup = useGetStartupHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetStartupHook>>>> = ({ signal }) =>
    getStartup(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetStartupQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetStartupHook>>>>;
export type GetStartupQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Startup call, returning the current agent and its access to entities, products
 */
export const useGetStartup = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetStartupHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStartupHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetStartupQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Lists all entities available
 */
export const useGetListEntitiesHook = () => {
  const getListEntities = useCustomInstance<EntitiesSchema>();

  return (signal?: AbortSignal) => {
    return getListEntities({ url: `/authorization/entities`, method: 'get', signal });
  };
};

export const getGetListEntitiesQueryKey = () => [`/authorization/entities`] as const;

export const useGetListEntitiesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetListEntitiesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetListEntitiesHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetListEntitiesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetListEntitiesQueryKey();

  const getListEntities = useGetListEntitiesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetListEntitiesHook>>>> = ({ signal }) =>
    getListEntities(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetListEntitiesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetListEntitiesHook>>>>;
export type GetListEntitiesQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Lists all entities available
 */
export const useGetListEntities = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetListEntitiesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetListEntitiesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetListEntitiesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Adds a new entity
 */
export const useAddEntityHook = () => {
  const addEntity = useCustomInstance<EntitySchema>();

  return (updateEntitySchema: UpdateEntitySchema) => {
    return addEntity({
      url: `/authorization/entities`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateEntitySchema,
    });
  };
};

export const useAddEntityMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddEntityHook>>>,
    TError,
    { data: UpdateEntitySchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddEntityHook>>>,
  TError,
  { data: UpdateEntitySchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addEntity = useAddEntityHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddEntityHook>>>,
    { data: UpdateEntitySchema }
  > = props => {
    const { data } = props ?? {};

    return addEntity(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddEntityMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddEntityHook>>>>;
export type AddEntityMutationBody = UpdateEntitySchema;
export type AddEntityMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Adds a new entity
 */
export const useAddEntity = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddEntityHook>>>,
    TError,
    { data: UpdateEntitySchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddEntityMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the details for an entity
 */
export const useGetEntityDetailsHook = () => {
  const getEntityDetails = useCustomInstance<EntitySchema>();

  return (entityId: string, signal?: AbortSignal) => {
    return getEntityDetails({ url: `/authorization/entities/${entityId}`, method: 'get', signal });
  };
};

export const getGetEntityDetailsQueryKey = (entityId: string) => [`/authorization/entities/${entityId}`] as const;

export const useGetEntityDetailsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetEntityDetailsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  entityId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEntityDetailsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEntityDetailsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEntityDetailsQueryKey(entityId);

  const getEntityDetails = useGetEntityDetailsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetEntityDetailsHook>>>> = ({ signal }) =>
    getEntityDetails(entityId, signal);

  return { queryKey, queryFn, enabled: !!entityId, ...queryOptions };
};

export type GetEntityDetailsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetEntityDetailsHook>>>>;
export type GetEntityDetailsQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get the details for an entity
 */
export const useGetEntityDetails = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetEntityDetailsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  entityId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetEntityDetailsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetEntityDetailsQueryOptions(entityId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Updates an Entity
 */
export const useUpdateEntityHook = () => {
  const updateEntity = useCustomInstance<EntitySchema>();

  return (entityId: string, updateEntitySchema: UpdateEntitySchema) => {
    return updateEntity({
      url: `/authorization/entities/${entityId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateEntitySchema,
    });
  };
};

export const useUpdateEntityMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateEntityHook>>>,
    TError,
    { entityId: string; data: UpdateEntitySchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateEntityHook>>>,
  TError,
  { entityId: string; data: UpdateEntitySchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateEntity = useUpdateEntityHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateEntityHook>>>,
    { entityId: string; data: UpdateEntitySchema }
  > = props => {
    const { entityId, data } = props ?? {};

    return updateEntity(entityId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateEntityMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateEntityHook>>>>;
export type UpdateEntityMutationBody = UpdateEntitySchema;
export type UpdateEntityMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Updates an Entity
 */
export const useUpdateEntity = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateEntityHook>>>,
    TError,
    { entityId: string; data: UpdateEntitySchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateEntityMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deletes an Entity
 */
export const useDeleteEntityHook = () => {
  const deleteEntity = useCustomInstance<void>();

  return (entityId: string) => {
    return deleteEntity({ url: `/authorization/entities/${entityId}`, method: 'delete' });
  };
};

export const useDeleteEntityMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteEntityHook>>>,
    TError,
    { entityId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteEntityHook>>>,
  TError,
  { entityId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteEntity = useDeleteEntityHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteEntityHook>>>,
    { entityId: string }
  > = props => {
    const { entityId } = props ?? {};

    return deleteEntity(entityId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteEntityMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteEntityHook>>>>;

export type DeleteEntityMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Deletes an Entity
 */
export const useDeleteEntity = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteEntityHook>>>,
    TError,
    { entityId: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteEntityMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Lists all Products available
 */
export const useGetListProductsHook = () => {
  const getListProducts = useCustomInstance<ProductsSchema>();

  return (signal?: AbortSignal) => {
    return getListProducts({ url: `/authorization/products`, method: 'get', signal });
  };
};

export const getGetListProductsQueryKey = () => [`/authorization/products`] as const;

export const useGetListProductsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetListProductsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetListProductsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetListProductsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetListProductsQueryKey();

  const getListProducts = useGetListProductsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetListProductsHook>>>> = ({ signal }) =>
    getListProducts(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetListProductsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetListProductsHook>>>>;
export type GetListProductsQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Lists all Products available
 */
export const useGetListProducts = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetListProductsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetListProductsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetListProductsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Adds a new Product
 */
export const useAddProductHook = () => {
  const addProduct = useCustomInstance<ProductSchema>();

  return (updateProductSchema: UpdateProductSchema) => {
    return addProduct({
      url: `/authorization/products`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateProductSchema,
    });
  };
};

export const useAddProductMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddProductHook>>>,
    TError,
    { data: UpdateProductSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddProductHook>>>,
  TError,
  { data: UpdateProductSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addProduct = useAddProductHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddProductHook>>>,
    { data: UpdateProductSchema }
  > = props => {
    const { data } = props ?? {};

    return addProduct(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddProductMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddProductHook>>>>;
export type AddProductMutationBody = UpdateProductSchema;
export type AddProductMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Adds a new Product
 */
export const useAddProduct = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddProductHook>>>,
    TError,
    { data: UpdateProductSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddProductMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the details for a product
 */
export const useGetProductDetailsHook = () => {
  const getProductDetails = useCustomInstance<ProductSchema>();

  return (productId: string, signal?: AbortSignal) => {
    return getProductDetails({ url: `/authorization/products/${productId}`, method: 'get', signal });
  };
};

export const getGetProductDetailsQueryKey = (productId: string) => [`/authorization/products/${productId}`] as const;

export const useGetProductDetailsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProductDetailsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  productId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProductDetailsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProductDetailsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProductDetailsQueryKey(productId);

  const getProductDetails = useGetProductDetailsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetProductDetailsHook>>>> = ({ signal }) =>
    getProductDetails(productId, signal);

  return { queryKey, queryFn, enabled: !!productId, ...queryOptions };
};

export type GetProductDetailsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetProductDetailsHook>>>
>;
export type GetProductDetailsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the details for a product
 */
export const useGetProductDetails = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetProductDetailsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  productId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetProductDetailsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetProductDetailsQueryOptions(productId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Updates a Product
 */
export const useUpdateProductHook = () => {
  const updateProduct = useCustomInstance<ProductSchema>();

  return (productId: string, updateProductSchema: UpdateProductSchema) => {
    return updateProduct({
      url: `/authorization/products/${productId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateProductSchema,
    });
  };
};

export const useUpdateProductMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateProductHook>>>,
    TError,
    { productId: string; data: UpdateProductSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateProductHook>>>,
  TError,
  { productId: string; data: UpdateProductSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateProduct = useUpdateProductHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateProductHook>>>,
    { productId: string; data: UpdateProductSchema }
  > = props => {
    const { productId, data } = props ?? {};

    return updateProduct(productId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateProductMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateProductHook>>>>;
export type UpdateProductMutationBody = UpdateProductSchema;
export type UpdateProductMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Updates a Product
 */
export const useUpdateProduct = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateProductHook>>>,
    TError,
    { productId: string; data: UpdateProductSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateProductMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deletes a Product
 */
export const useDeleteProductHook = () => {
  const deleteProduct = useCustomInstance<void>();

  return (productId: string) => {
    return deleteProduct({ url: `/authorization/products/${productId}`, method: 'delete' });
  };
};

export const useDeleteProductMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteProductHook>>>,
    TError,
    { productId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteProductHook>>>,
  TError,
  { productId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteProduct = useDeleteProductHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteProductHook>>>,
    { productId: string }
  > = props => {
    const { productId } = props ?? {};

    return deleteProduct(productId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteProductMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteProductHook>>>>;

export type DeleteProductMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Deletes a Product
 */
export const useDeleteProduct = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteProductHook>>>,
    TError,
    { productId: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteProductMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Performs the first initial authorization on the back-end. This happens after choosing the entity/product, so the permissions returned will depend on the chosen context.
 */
export const useGetCurrentPermissionsHook = () => {
  const getCurrentPermissions = useCustomInstance<AgentPermissionsSchema>();

  return (signal?: AbortSignal) => {
    return getCurrentPermissions({ url: `/authorization/permissions/current`, method: 'get', signal });
  };
};

export const getGetCurrentPermissionsQueryKey = () => [`/authorization/permissions/current`] as const;

export const useGetCurrentPermissionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCurrentPermissionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentPermissionsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentPermissionsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCurrentPermissionsQueryKey();

  const getCurrentPermissions = useGetCurrentPermissionsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCurrentPermissionsHook>>>> = ({ signal }) =>
    getCurrentPermissions(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetCurrentPermissionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetCurrentPermissionsHook>>>
>;
export type GetCurrentPermissionsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Performs the first initial authorization on the back-end. This happens after choosing the entity/product, so the permissions returned will depend on the chosen context.
 */
export const useGetCurrentPermissions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCurrentPermissionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentPermissionsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCurrentPermissionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Lists all permissions available
 */
export const useGetPermissionsHook = () => {
  const getPermissions = useCustomInstance<AgentPermissionsSchema>();

  return (signal?: AbortSignal) => {
    return getPermissions({ url: `/authorization/permissions`, method: 'get', signal });
  };
};

export const getGetPermissionsQueryKey = () => [`/authorization/permissions`] as const;

export const useGetPermissionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPermissionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPermissionsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPermissionsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPermissionsQueryKey();

  const getPermissions = useGetPermissionsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPermissionsHook>>>> = ({ signal }) =>
    getPermissions(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPermissionsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetPermissionsHook>>>>;
export type GetPermissionsQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Lists all permissions available
 */
export const useGetPermissions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPermissionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPermissionsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPermissionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Lists all permissions available
 */
export const useListPermissionPropertyNamesHook = () => {
  const listPermissionPropertyNames = useCustomInstance<AgentPermissionPropertyNamesSchema>();

  return (permissionId: string, signal?: AbortSignal) => {
    return listPermissionPropertyNames({
      url: `/authorization/permissions/name/${permissionId}`,
      method: 'get',
      signal,
    });
  };
};

export const getListPermissionPropertyNamesQueryKey = (permissionId: string) =>
  [`/authorization/permissions/name/${permissionId}`] as const;

export const useListPermissionPropertyNamesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useListPermissionPropertyNamesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  permissionId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListPermissionPropertyNamesHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListPermissionPropertyNamesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPermissionPropertyNamesQueryKey(permissionId);

  const listPermissionPropertyNames = useListPermissionPropertyNamesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListPermissionPropertyNamesHook>>>> = ({
    signal,
  }) => listPermissionPropertyNames(permissionId, signal);

  return { queryKey, queryFn, enabled: !!permissionId, ...queryOptions };
};

export type ListPermissionPropertyNamesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useListPermissionPropertyNamesHook>>>
>;
export type ListPermissionPropertyNamesQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Lists all permissions available
 */
export const useListPermissionPropertyNames = <
  TData = Awaited<ReturnType<ReturnType<typeof useListPermissionPropertyNamesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  permissionId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListPermissionPropertyNamesHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useListPermissionPropertyNamesQueryOptions(permissionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Adds a name permissions
 */
export const useAddNamePermissionsHook = () => {
  const addNamePermissions = useCustomInstance<AgentPermissionsSchema>();

  return (permissionId: string, addPermissionNameSchema: AddPermissionNameSchema) => {
    return addNamePermissions({
      url: `/authorization/permissions/name/${permissionId}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: addPermissionNameSchema,
    });
  };
};

export const useAddNamePermissionsMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddNamePermissionsHook>>>,
    TError,
    { permissionId: string; data: AddPermissionNameSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddNamePermissionsHook>>>,
  TError,
  { permissionId: string; data: AddPermissionNameSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addNamePermissions = useAddNamePermissionsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddNamePermissionsHook>>>,
    { permissionId: string; data: AddPermissionNameSchema }
  > = props => {
    const { permissionId, data } = props ?? {};

    return addNamePermissions(permissionId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddNamePermissionsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddNamePermissionsHook>>>
>;
export type AddNamePermissionsMutationBody = AddPermissionNameSchema;
export type AddNamePermissionsMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Adds a name permissions
 */
export const useAddNamePermissions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddNamePermissionsHook>>>,
    TError,
    { permissionId: string; data: AddPermissionNameSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddNamePermissionsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deletes a name permission, in case a wildcard (higher-level permission) is attempted to be be deleted, this will result in an error
 */
export const useDeleteNamePermissionsHook = () => {
  const deleteNamePermissions = useCustomInstance<AgentPermissionsSchema>();

  return (permissionId: string) => {
    return deleteNamePermissions({ url: `/authorization/permissions/name/${permissionId}`, method: 'delete' });
  };
};

export const useDeleteNamePermissionsMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteNamePermissionsHook>>>,
    TError,
    { permissionId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteNamePermissionsHook>>>,
  TError,
  { permissionId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteNamePermissions = useDeleteNamePermissionsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteNamePermissionsHook>>>,
    { permissionId: string }
  > = props => {
    const { permissionId } = props ?? {};

    return deleteNamePermissions(permissionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteNamePermissionsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteNamePermissionsHook>>>
>;

export type DeleteNamePermissionsMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Deletes a name permission, in case a wildcard (higher-level permission) is attempted to be be deleted, this will result in an error
 */
export const useDeleteNamePermissions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteNamePermissionsHook>>>,
    TError,
    { permissionId: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteNamePermissionsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Lists all authorized management users (agents)
 */
export const useGetListAgentsHook = () => {
  const getListAgents = useCustomInstance<AgentsSchema>();

  return (signal?: AbortSignal) => {
    return getListAgents({ url: `/authorization/agents`, method: 'get', signal });
  };
};

export const getGetListAgentsQueryKey = () => [`/authorization/agents`] as const;

export const useGetListAgentsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetListAgentsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetListAgentsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetListAgentsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetListAgentsQueryKey();

  const getListAgents = useGetListAgentsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetListAgentsHook>>>> = ({ signal }) =>
    getListAgents(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetListAgentsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetListAgentsHook>>>>;
export type GetListAgentsQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Lists all authorized management users (agents)
 */
export const useGetListAgents = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetListAgentsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetListAgentsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetListAgentsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Adds a new agents
 */
export const useAddAgentHook = () => {
  const addAgent = useCustomInstance<AgentDetailsSchema>();

  return (updateAgentSchema: UpdateAgentSchema) => {
    return addAgent({
      url: `/authorization/agents`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateAgentSchema,
    });
  };
};

export const useAddAgentMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddAgentHook>>>,
    TError,
    { data: UpdateAgentSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddAgentHook>>>,
  TError,
  { data: UpdateAgentSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addAgent = useAddAgentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddAgentHook>>>,
    { data: UpdateAgentSchema }
  > = props => {
    const { data } = props ?? {};

    return addAgent(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddAgentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddAgentHook>>>>;
export type AddAgentMutationBody = UpdateAgentSchema;
export type AddAgentMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Adds a new agents
 */
export const useAddAgent = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddAgentHook>>>,
    TError,
    { data: UpdateAgentSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddAgentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets the current agent details
 */
export const useGetCurrentAgentHook = () => {
  const getCurrentAgent = useCustomInstance<AgentDetailsSchema>();

  return (signal?: AbortSignal) => {
    return getCurrentAgent({ url: `/authorization/agents/current`, method: 'get', signal });
  };
};

export const getGetCurrentAgentQueryKey = () => [`/authorization/agents/current`] as const;

export const useGetCurrentAgentQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCurrentAgentHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentAgentHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentAgentHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCurrentAgentQueryKey();

  const getCurrentAgent = useGetCurrentAgentHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCurrentAgentHook>>>> = ({ signal }) =>
    getCurrentAgent(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetCurrentAgentQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCurrentAgentHook>>>>;
export type GetCurrentAgentQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Gets the current agent details
 */
export const useGetCurrentAgent = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCurrentAgentHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCurrentAgentHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCurrentAgentQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the details for an authorized agent
 */
export const useGetAgentDetailsHook = () => {
  const getAgentDetails = useCustomInstance<AgentDetailsSchema>();

  return (agentId: string, signal?: AbortSignal) => {
    return getAgentDetails({ url: `/authorization/agents/${agentId}`, method: 'get', signal });
  };
};

export const getGetAgentDetailsQueryKey = (agentId: string) => [`/authorization/agents/${agentId}`] as const;

export const useGetAgentDetailsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAgentDetailsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  agentId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAgentDetailsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAgentDetailsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAgentDetailsQueryKey(agentId);

  const getAgentDetails = useGetAgentDetailsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAgentDetailsHook>>>> = ({ signal }) =>
    getAgentDetails(agentId, signal);

  return { queryKey, queryFn, enabled: !!agentId, ...queryOptions };
};

export type GetAgentDetailsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAgentDetailsHook>>>>;
export type GetAgentDetailsQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get the details for an authorized agent
 */
export const useGetAgentDetails = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAgentDetailsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  agentId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAgentDetailsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAgentDetailsQueryOptions(agentId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Updates an authorized management user (agent)
 */
export const useUpdateAgentHook = () => {
  const updateAgent = useCustomInstance<AgentDetailsSchema>();

  return (agentId: string, updateAgentSchema: UpdateAgentSchema) => {
    return updateAgent({
      url: `/authorization/agents/${agentId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateAgentSchema,
    });
  };
};

export const useUpdateAgentMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateAgentHook>>>,
    TError,
    { agentId: string; data: UpdateAgentSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateAgentHook>>>,
  TError,
  { agentId: string; data: UpdateAgentSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateAgent = useUpdateAgentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateAgentHook>>>,
    { agentId: string; data: UpdateAgentSchema }
  > = props => {
    const { agentId, data } = props ?? {};

    return updateAgent(agentId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAgentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateAgentHook>>>>;
export type UpdateAgentMutationBody = UpdateAgentSchema;
export type UpdateAgentMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Updates an authorized management user (agent)
 */
export const useUpdateAgent = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateAgentHook>>>,
    TError,
    { agentId: string; data: UpdateAgentSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateAgentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deletes an authorized management user (agent)
 */
export const useDeleteAgentHook = () => {
  const deleteAgent = useCustomInstance<AgentsSchema>();

  return (agentId: string, params?: DeleteAgentParams) => {
    return deleteAgent({ url: `/authorization/agents/${agentId}`, method: 'delete', params });
  };
};

export const useDeleteAgentMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteAgentHook>>>,
    TError,
    { agentId: string; params?: DeleteAgentParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteAgentHook>>>,
  TError,
  { agentId: string; params?: DeleteAgentParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteAgent = useDeleteAgentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteAgentHook>>>,
    { agentId: string; params?: DeleteAgentParams }
  > = props => {
    const { agentId, params } = props ?? {};

    return deleteAgent(agentId, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAgentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteAgentHook>>>>;

export type DeleteAgentMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Deletes an authorized management user (agent)
 */
export const useDeleteAgent = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteAgentHook>>>,
    TError,
    { agentId: string; params?: DeleteAgentParams },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteAgentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deletes the link between agent and entity
 */
export const useDeleteAgentEntityReferenceHook = () => {
  const deleteAgentEntityReference = useCustomInstance<AgentsSchema>();

  return (agentId: string, entityId: string) => {
    return deleteAgentEntityReference({ url: `/authorization/agents/${agentId}/${entityId}`, method: 'delete' });
  };
};

export const useDeleteAgentEntityReferenceMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteAgentEntityReferenceHook>>>,
    TError,
    { agentId: string; entityId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteAgentEntityReferenceHook>>>,
  TError,
  { agentId: string; entityId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteAgentEntityReference = useDeleteAgentEntityReferenceHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteAgentEntityReferenceHook>>>,
    { agentId: string; entityId: string }
  > = props => {
    const { agentId, entityId } = props ?? {};

    return deleteAgentEntityReference(agentId, entityId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAgentEntityReferenceMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteAgentEntityReferenceHook>>>
>;

export type DeleteAgentEntityReferenceMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Deletes the link between agent and entity
 */
export const useDeleteAgentEntityReference = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteAgentEntityReferenceHook>>>,
    TError,
    { agentId: string; entityId: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteAgentEntityReferenceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Resets the password for the given agent
 */
export const useResetAgentPasswordHook = () => {
  const resetAgentPassword = useCustomInstance<AgentDetailsSchema>();

  return (agentId: string) => {
    return resetAgentPassword({ url: `/authorization/agents/${agentId}/resetpassword`, method: 'put' });
  };
};

export const useResetAgentPasswordMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetAgentPasswordHook>>>,
    TError,
    { agentId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useResetAgentPasswordHook>>>,
  TError,
  { agentId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const resetAgentPassword = useResetAgentPasswordHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useResetAgentPasswordHook>>>,
    { agentId: string }
  > = props => {
    const { agentId } = props ?? {};

    return resetAgentPassword(agentId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResetAgentPasswordMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useResetAgentPasswordHook>>>
>;

export type ResetAgentPasswordMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Resets the password for the given agent
 */
export const useResetAgentPassword = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResetAgentPasswordHook>>>,
    TError,
    { agentId: string },
    TContext
  >;
}) => {
  const mutationOptions = useResetAgentPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Locks the given agent
 */
export const useLockAgentHook = () => {
  const lockAgent = useCustomInstance<AgentsSchema>();

  return (agentId: string) => {
    return lockAgent({ url: `/authorization/agents/${agentId}/lock`, method: 'put' });
  };
};

export const useLockAgentMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useLockAgentHook>>>,
    TError,
    { agentId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useLockAgentHook>>>,
  TError,
  { agentId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const lockAgent = useLockAgentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useLockAgentHook>>>,
    { agentId: string }
  > = props => {
    const { agentId } = props ?? {};

    return lockAgent(agentId);
  };

  return { mutationFn, ...mutationOptions };
};

export type LockAgentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useLockAgentHook>>>>;

export type LockAgentMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Locks the given agent
 */
export const useLockAgent = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useLockAgentHook>>>,
    TError,
    { agentId: string },
    TContext
  >;
}) => {
  const mutationOptions = useLockAgentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Unlocks the given agent
 */
export const useUnlockAgentHook = () => {
  const unlockAgent = useCustomInstance<AgentsSchema>();

  return (agentId: string) => {
    return unlockAgent({ url: `/authorization/agents/${agentId}/lock`, method: 'delete' });
  };
};

export const useUnlockAgentMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUnlockAgentHook>>>,
    TError,
    { agentId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUnlockAgentHook>>>,
  TError,
  { agentId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const unlockAgent = useUnlockAgentHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUnlockAgentHook>>>,
    { agentId: string }
  > = props => {
    const { agentId } = props ?? {};

    return unlockAgent(agentId);
  };

  return { mutationFn, ...mutationOptions };
};

export type UnlockAgentMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUnlockAgentHook>>>>;

export type UnlockAgentMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Unlocks the given agent
 */
export const useUnlockAgent = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUnlockAgentHook>>>,
    TError,
    { agentId: string },
    TContext
  >;
}) => {
  const mutationOptions = useUnlockAgentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Lists all agent roles
 */
export const useGetListAgentRolesHook = () => {
  const getListAgentRoles = useCustomInstance<AgentRolesSchema>();

  return (signal?: AbortSignal) => {
    return getListAgentRoles({ url: `/authorization/agentroles`, method: 'get', signal });
  };
};

export const getGetListAgentRolesQueryKey = () => [`/authorization/agentroles`] as const;

export const useGetListAgentRolesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetListAgentRolesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetListAgentRolesHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetListAgentRolesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetListAgentRolesQueryKey();

  const getListAgentRoles = useGetListAgentRolesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetListAgentRolesHook>>>> = ({ signal }) =>
    getListAgentRoles(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetListAgentRolesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetListAgentRolesHook>>>
>;
export type GetListAgentRolesQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Lists all agent roles
 */
export const useGetListAgentRoles = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetListAgentRolesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetListAgentRolesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetListAgentRolesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Add a new agent role
 */
export const useAddAgentRoleHook = () => {
  const addAgentRole = useCustomInstance<UpdateAgentRoleSchema>();

  return (updateAgentRoleSchema: UpdateAgentRoleSchema) => {
    return addAgentRole({
      url: `/authorization/agentroles`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateAgentRoleSchema,
    });
  };
};

export const useAddAgentRoleMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddAgentRoleHook>>>,
    TError,
    { data: UpdateAgentRoleSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddAgentRoleHook>>>,
  TError,
  { data: UpdateAgentRoleSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addAgentRole = useAddAgentRoleHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddAgentRoleHook>>>,
    { data: UpdateAgentRoleSchema }
  > = props => {
    const { data } = props ?? {};

    return addAgentRole(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddAgentRoleMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddAgentRoleHook>>>>;
export type AddAgentRoleMutationBody = UpdateAgentRoleSchema;
export type AddAgentRoleMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Add a new agent role
 */
export const useAddAgentRole = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddAgentRoleHook>>>,
    TError,
    { data: UpdateAgentRoleSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddAgentRoleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the details for an authorized agent role
 */
export const useGetAgentRoleDetailsHook = () => {
  const getAgentRoleDetails = useCustomInstance<AgentRoleSchema>();

  return (agentRoleId: string, signal?: AbortSignal) => {
    return getAgentRoleDetails({ url: `/authorization/agentroles/${agentRoleId}`, method: 'get', signal });
  };
};

export const getGetAgentRoleDetailsQueryKey = (agentRoleId: string) =>
  [`/authorization/agentroles/${agentRoleId}`] as const;

export const useGetAgentRoleDetailsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAgentRoleDetailsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  agentRoleId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAgentRoleDetailsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAgentRoleDetailsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAgentRoleDetailsQueryKey(agentRoleId);

  const getAgentRoleDetails = useGetAgentRoleDetailsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAgentRoleDetailsHook>>>> = ({ signal }) =>
    getAgentRoleDetails(agentRoleId, signal);

  return { queryKey, queryFn, enabled: !!agentRoleId, ...queryOptions };
};

export type GetAgentRoleDetailsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAgentRoleDetailsHook>>>
>;
export type GetAgentRoleDetailsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the details for an authorized agent role
 */
export const useGetAgentRoleDetails = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAgentRoleDetailsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  agentRoleId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAgentRoleDetailsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAgentRoleDetailsQueryOptions(agentRoleId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Updates an authorized agent role
 */
export const useUpdateAgentRoleHook = () => {
  const updateAgentRole = useCustomInstance<AgentRoleSchema>();

  return (agentRoleId: string, updateAgentRoleSchema: UpdateAgentRoleSchema) => {
    return updateAgentRole({
      url: `/authorization/agentroles/${agentRoleId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateAgentRoleSchema,
    });
  };
};

export const useUpdateAgentRoleMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateAgentRoleHook>>>,
    TError,
    { agentRoleId: string; data: UpdateAgentRoleSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateAgentRoleHook>>>,
  TError,
  { agentRoleId: string; data: UpdateAgentRoleSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateAgentRole = useUpdateAgentRoleHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateAgentRoleHook>>>,
    { agentRoleId: string; data: UpdateAgentRoleSchema }
  > = props => {
    const { agentRoleId, data } = props ?? {};

    return updateAgentRole(agentRoleId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateAgentRoleMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useUpdateAgentRoleHook>>>>;
export type UpdateAgentRoleMutationBody = UpdateAgentRoleSchema;
export type UpdateAgentRoleMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Updates an authorized agent role
 */
export const useUpdateAgentRole = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateAgentRoleHook>>>,
    TError,
    { agentRoleId: string; data: UpdateAgentRoleSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateAgentRoleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deletes an authorized agent role
 */
export const useDeleteAgentRoleHook = () => {
  const deleteAgentRole = useCustomInstance<void>();

  return (agentRoleId: string) => {
    return deleteAgentRole({ url: `/authorization/agentroles/${agentRoleId}`, method: 'delete' });
  };
};

export const useDeleteAgentRoleMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteAgentRoleHook>>>,
    TError,
    { agentRoleId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteAgentRoleHook>>>,
  TError,
  { agentRoleId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteAgentRole = useDeleteAgentRoleHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteAgentRoleHook>>>,
    { agentRoleId: string }
  > = props => {
    const { agentRoleId } = props ?? {};

    return deleteAgentRole(agentRoleId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteAgentRoleMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteAgentRoleHook>>>>;

export type DeleteAgentRoleMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Deletes an authorized agent role
 */
export const useDeleteAgentRole = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteAgentRoleHook>>>,
    TError,
    { agentRoleId: string },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteAgentRoleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the audit logs.
 */
export const useGetAuditLogsHook = () => {
  const getAuditLogs = useCustomInstance<AuditLogsSchema>();

  return (getAuditLogsFilterSchema: GetAuditLogsFilterSchema) => {
    return getAuditLogs({
      url: `/authorization/audit`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: getAuditLogsFilterSchema,
    });
  };
};

export const useGetAuditLogsMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetAuditLogsHook>>>,
    TError,
    { data: GetAuditLogsFilterSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useGetAuditLogsHook>>>,
  TError,
  { data: GetAuditLogsFilterSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const getAuditLogs = useGetAuditLogsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useGetAuditLogsHook>>>,
    { data: GetAuditLogsFilterSchema }
  > = props => {
    const { data } = props ?? {};

    return getAuditLogs(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GetAuditLogsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAuditLogsHook>>>>;
export type GetAuditLogsMutationBody = GetAuditLogsFilterSchema;
export type GetAuditLogsMutationError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get the audit logs.
 */
export const useGetAuditLogs = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGetAuditLogsHook>>>,
    TError,
    { data: GetAuditLogsFilterSchema },
    TContext
  >;
}) => {
  const mutationOptions = useGetAuditLogsMutationOptions(options);

  return useMutation(mutationOptions);
};
