import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  EInterventionActionTypeSchema,
  EInterventionCauseSchema,
  EInterventionChannelTypeSchema,
  EInterventionControllerBadRequestTypeSchema,
  EInterventionTypeSchema,
  getGetInterventionsForUserQueryKey,
  getGetInterventionsQueryKey,
  UpdateInterventionSchema,
  useCreateInterventionForUser,
} from '@greenisland-api';
import {
  Button,
  capitalize,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { INTERVENTION_ERROR_REASONS } from './Constants/InterventionErrorReasons';

interface FormData extends UpdateInterventionSchema {
  userId: string;
}

type Props = {
  open: boolean;
  setOpen: (state: boolean) => void;
  userId?: string;
};

const AddInterventionDialog = ({ open, setOpen, userId }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: createIntervention, isLoading } = useCreateInterventionForUser({
    mutation: {
      onSuccess: async ({ userId }) => {
        queryClient.invalidateQueries(getGetInterventionsQueryKey());
        queryClient.invalidateQueries(getGetInterventionsForUserQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
        setOpen(false);
      },
      onError: error => {
        if (error) {
          if ('type' in error && error.type) {
            const errorReason: EInterventionControllerBadRequestTypeSchema | undefined = error.type;
            const translationKey = errorReason ? INTERVENTION_ERROR_REASONS[errorReason] : 'somethingWentWrong';
            enqueueSnackbar(t(translationKey), { variant: 'error' });
          } else {
            enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
          }
        }
      },
    },
  });

  const onSubmit = (data: FormData) => {
    createIntervention({
      userId: parseInt(userId || data.userId),
      data,
    });
  };

  const { register, handleSubmit, errors, control } = useForm<NonNullable<FormData>>({
    mode: 'onChange',
    defaultValues: { userId: userId ?? '' },
  });

  return (
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{capitalize(t('tasks.interventions.createIntervention'))}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              size="small"
              label={t('tasks.interventions.userId')}
              type="text"
              name="userId"
              inputRef={register({ required: true })}
              error={Boolean(errors.userId)}
              disabled={Boolean(userId)}
            />
            <Controller
              name="interventionType"
              rules={{ required: true }}
              control={control}
              render={({ onChange, value }) => (
                <TextField
                  size="small"
                  select
                  fullWidth
                  label={t('tasks.interventions.interventionType')}
                  value={value}
                  onChange={onChange}
                  error={Boolean(errors.interventionType)}
                >
                  {Object.values(EInterventionTypeSchema).map((key: string) => (
                    <MenuItem key={key} value={key}>
                      {t(key)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="interventionActionType"
              rules={{ required: true }}
              control={control}
              render={({ onChange, value }) => (
                <TextField
                  size="small"
                  select
                  fullWidth
                  label={t('tasks.interventions.interventionActionType')}
                  value={value}
                  onChange={onChange}
                  error={Boolean(errors.interventionActionType)}
                >
                  {Object.values(EInterventionActionTypeSchema).map((key: string) => (
                    <MenuItem key={key} value={key}>
                      {t(key)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="interventionChannelType"
              rules={{ required: true }}
              control={control}
              render={({ onChange, value }) => (
                <TextField
                  size="small"
                  select
                  fullWidth
                  label={t('tasks.interventions.interventionChannelType')}
                  value={value}
                  onChange={onChange}
                  error={Boolean(errors.interventionActionType)}
                >
                  {Object.values(EInterventionChannelTypeSchema).map((key: string) => (
                    <MenuItem key={key} value={key}>
                      {t(key)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="interventionCause"
              rules={{ required: true }}
              control={control}
              render={({ onChange, value }) => (
                <TextField
                  size="small"
                  select
                  fullWidth
                  label={t('tasks.interventions.interventionCause')}
                  value={value}
                  onChange={onChange}
                  error={Boolean(errors.interventionActionType)}
                >
                  {Object.values(EInterventionCauseSchema).map((key: string) => (
                    <MenuItem key={key} value={key}>
                      {t(key)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <TextField
              size="small"
              label={t('tasks.interventions.additionalInformation')}
              type="text"
              multiline
              minRows={3}
              name="additionalInformation"
              inputRef={register({ required: true })}
              error={Boolean(errors.additionalInformation)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={() => setOpen(false)}>
            {t('cancel')}
          </Button>
          <Button
            sx={{ minHeight: '36px', minWidth: '90px' }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={20} /> : t('tasks.interventions.create')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddInterventionDialog;
