/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * Playing break reason type `none` - The playing break has no associated reason. `other` - Other (The player has a custom reason that can be found in the `reason` field) `problemGambling` - The playing break was imposed because of problem gambling. `discontent` - The player is not satisfied with the offered products `unfair` - The player thinks he doesn't have a fair chance to win `feelingUnwell` - The player is not feeling great due to playing games `prevention` - The player wants a preventive break from playing because the player thinks its responsible to do so `inactive` - The player doesn't use the account anymore `competitor` - The player prefers a different gaming provider `investigation` - The player is under investigation, e.g., for breaching the Terms and Conditions. `suspension` - The player is suspended from playing on the platform, e.g., as a result of an investigation.
 */
export type EPlayingBreakReasonTypeV2Schema =
  (typeof EPlayingBreakReasonTypeV2Schema)[keyof typeof EPlayingBreakReasonTypeV2Schema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EPlayingBreakReasonTypeV2Schema = {
  none: 'none',
  other: 'other',
  problemGambling: 'problemGambling',
  discontent: 'discontent',
  unfair: 'unfair',
  feelingUnwell: 'feelingUnwell',
  prevention: 'prevention',
  inactive: 'inactive',
  competitor: 'competitor',
  investigation: 'investigation',
  suspension: 'suspension',
} as const;
