import { Dispatch, FC, ReactNode, SetStateAction, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, capitalize, Switch } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FormError } from '@greenisland-common/components/atoms';

import ContentFilterCategoryProviders from '../ContentFilterMediaForm/ContentFilterCategoryProviders';
import ContentFilterGameProviders from '../ContentFilterMediaForm/ContentFilterGameProviders';
import ContentFilterGames from '../ContentFilterMediaForm/ContentFilterGames';
import ContentFilterStudioProviders from '../ContentFilterMediaForm/ContentFilterStudioProviders';
import ContentFilterThemeProviders from '../ContentFilterMediaForm/ContentFilterThemeProviders';
import { ContentFiltersPrimaryKeysType, defaultContentFilterFormValues } from '../helpers/ContentFilterFormContext';

interface FilterInputFieldProps {
  type: 'included' | 'excluded';
  keyName: keyof FiltersProps;
}

const FilterInputField: FC<FilterInputFieldProps> = ({ type, keyName }) => {
  const { formState } = useFormContext();

  const filterComponents: Record<ContentFiltersPrimaryKeysType, ReactNode> = {
    [ContentFiltersPrimaryKeysType.gamePKeys]: <ContentFilterGames type={type} />,
    [ContentFiltersPrimaryKeysType.gameProviderPKeys]: <ContentFilterGameProviders type={type} />,
    [ContentFiltersPrimaryKeysType.gameStudioPKeys]: <ContentFilterStudioProviders type={type} />,
    [ContentFiltersPrimaryKeysType.gameCategoryPKeys]: <ContentFilterCategoryProviders type={type} />,
    [ContentFiltersPrimaryKeysType.gameThemePKeys]: <ContentFilterThemeProviders type={type} />,
  };

  return (
    <Box mt={2}>
      {filterComponents[keyName]}
      {formState.errors[`${type}Filter`] && formState.errors[`${type}Filter`][keyName] && (
        <FormError error={true} message={formState.errors[`${type}Filter`][keyName]?.message} />
      )}
    </Box>
  );
};

interface FiltersProps {
  gamePKeys: boolean;
  gameProviderPKeys: boolean;
  gameStudioPKeys: boolean;
  gameCategoryPKeys: boolean;
  gameThemePKeys: boolean;
}

interface Props {
  type: 'included' | 'excluded';
  filters: FiltersProps;
  setFilters: Dispatch<SetStateAction<FiltersProps>>;
}
const ContentIncludedExcludedFilterField = ({ type, filters, setFilters }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { control } = useFormContext();

  const isIncludedFilter = type === 'included';

  const defaultFilterValues = isIncludedFilter
    ? defaultContentFilterFormValues.includedFilter
    : defaultContentFilterFormValues.excludedFilter;

  const handleSwitchChange = (key: keyof FiltersProps) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [key]: !prevFilters[key],
    }));
  };

  const renderSwitchLabel = useCallback(
    (key: keyof FiltersProps) => (
      <Box
        sx={{
          [theme.breakpoints.up('xs')]: {
            display: 'none',
          },
          [theme.breakpoints.up('sm')]: {
            display: 'inline',
          },
        }}
      >
        (
        {filters[key]
          ? isIncludedFilter
            ? t('content.contentFilter.tableHeaders.includedFilters.includeAll')
            : t('content.contentFilter.tableHeaders.excludedFilters.excludeOnly')
          : isIncludedFilter
          ? t('content.contentFilter.tableHeaders.includedFilters.includeOnly')
          : t('content.contentFilter.tableHeaders.includedFilters.none')}
        )
      </Box>
    ),
    [filters, isIncludedFilter, t, theme.breakpoints]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={1}
      sx={{
        border: '1px solid silver',
        padding: '10px',
        [theme.breakpoints.up('xs')]: {
          width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
          width: '50%',
        },
      }}
    >
      <h5 style={{ margin: 0 }}>
        {capitalize(
          isIncludedFilter
            ? t('content.contentFilter.tableHeaders.includedFilters.title')
            : t('content.contentFilter.tableHeaders.excludedFilters.title')
        )}
      </h5>
      {Object.entries(defaultFilterValues).map(([key]) => {
        const filterKey = key as keyof FiltersProps;
        return (
          <Box key={key} display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <p style={{ color: 'grey' }}>{capitalize(t(`content.contentFilter.tableHeaders.filters.${key}`))}:</p>
              <Box>
                {renderSwitchLabel(filterKey)}
                <Controller
                  name={`${type}Filter.${key}`}
                  control={control}
                  render={() => <Switch checked={filters[filterKey]} onChange={() => handleSwitchChange(filterKey)} />}
                />
              </Box>
            </Box>
            {isIncludedFilter ? (
              !filters[filterKey] ? (
                <FilterInputField type={type} keyName={filterKey} />
              ) : null
            ) : filters[filterKey] ? (
              <FilterInputField type={type} keyName={filterKey} />
            ) : null}
          </Box>
        );
      })}
    </Box>
  );
};

export default ContentIncludedExcludedFilterField;
