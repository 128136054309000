import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  ESetMaxLimitReasonSchema,
  EUserLimitV2PeriodSchema,
  getGetActiveMaximumLoginSessionLimitsQueryKey,
  UpdateMaxDepositLimitSchema,
  useSetMaximumLoginSessionLimit,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, MenuItem, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

import { SelectInput } from '@greenisland-common/components/atoms';
import DateField from '@greenisland-common/components/molecules/DateInput/DateInput';

interface Props {
  userId: number;
  onClose: () => void;
}

const RequestMaxLimitForm = ({ userId, onClose }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const methods = useForm<UpdateMaxDepositLimitSchema>({ mode: 'onChange' });
  const { register, control, handleSubmit, errors } = methods;

  const onSubmit = (data: UpdateMaxDepositLimitSchema) => {
    mutate({
      userId,
      data: {
        ...data,
        expiryDate: data?.expiryDate ? new Date(data.expiryDate).getTime() / 1000 : null,
        value: data.value * 60 * 60,
      },
    });
  };

  const periodOptions = Object.keys(EUserLimitV2PeriodSchema);

  const { mutate, isLoading } = useSetMaximumLoginSessionLimit({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetActiveMaximumLoginSessionLimitsQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
        onClose();
      },
      onError: error => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  return (
    <Box pt={1}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={2}>
            <DateField
              label={t('expiryDate')}
              name="expiryDate"
              rules={{ required: t('fieldIsRequired') }}
              size="small"
            />
            <Controller
              name="period"
              control={control}
              rules={{ required: t('fieldIsRequired') as string }}
              defaultValue=""
              render={({ onChange, value }) => (
                <TextField
                  size="small"
                  sx={{ mr: 2, minWidth: 200 }}
                  label={t('period')}
                  select
                  fullWidth
                  onChange={onChange}
                  value={value ?? ''}
                  error={!!errors.period}
                  helperText={errors.period?.message}
                >
                  {periodOptions?.map(period => (
                    <MenuItem key={period} value={period}>
                      {period}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <TextField
              size="small"
              fullWidth
              label={t('sessionLimit.form.valueInHours')}
              type="number"
              name="value"
              inputRef={register({
                required: t('fieldIsRequired') as string,
                valueAsNumber: true,
                min: 0,
              })}
              error={!!errors.value}
            />
            <SelectInput
              size="small"
              name="reason"
              label={t('reason')}
              options={Object.keys(ESetMaxLimitReasonSchema).map(key => ({ value: key, label: t(key) }))}
              defaultValue={ESetMaxLimitReasonSchema.AddictionCategoryIncreased}
            />
          </Stack>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={onClose}>
              {t('cancel')}
            </Button>
            <LoadingButton
              sx={{ minHeight: '36px', minWidth: '90px' }}
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              loading={isLoading}
            >
              {t('save')}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Box>
  );
};

export default RequestMaxLimitForm;
