import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GraphDataPointSchema, useGetUserAccountStatistics } from '@greenisland-api';
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

import {
  formatCurrency,
  formatDecimal,
  getDateTimeFromUnix,
  transformCurrencyV2,
  transformDecimalV2,
} from '../../../../../../../app/helpers/transformFunctions';

const AccountStatistics = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const { data: userAccountStatistics, isLoading: fetching } = useGetUserAccountStatistics(userId);

  const columns: GridColDef[] = [
    { field: 'title', headerName: '', flex: 1 },
    { field: 'value', headerName: '', flex: 1 },
  ];

  const mutatedBankWithdrawalSummary = () => {
    if (!userAccountStatistics) return [];
    return Object.entries(userAccountStatistics.bankWithdrawalSummary).map(([title, value]) => {
      switch (title) {
        case 'nbOfProcessedWithdrawals':
        case 'nbOfCancelledWithdrawals':
          return { title: t(title), value: formatDecimal(value) };
        case 'withdrawalCancelPercentage':
          return {
            title: t(title),
            value: `${formatDecimal(Math.round((value + Number.EPSILON) * 100) / 100)} %`,
          };
        default:
          return { title: t(title), value: formatCurrency(value as number) };
      }
    });
  };

  const mutatedDepositsSummary = () => {
    if (!userAccountStatistics) return [];
    const rows: any = [];
    Object.entries(userAccountStatistics.depositsSummary).forEach(([title, value]) => {
      switch (title) {
        case 'payoutOverTime':
        case 'cashOutRatio':
          rows.push({
            title: t(title),
            value: `${formatDecimal(Math.round(((value as number) + Number.EPSILON) * 100) / 100)} %`,
          });
          break;
        case 'cashedBonus':
        case 'averageDeposits':
          rows.push({ title: t(title), value: formatCurrency(value as number) });
          break;
        case 'nbOfDeposits':
        case 'maxDeposits':
        case 'minDeposits':
          rows.push({ title: t(title), value: formatDecimal(value as number) });
          break;
      }
    });
    return rows;
  };

  const mutatedPromoSummary = () => {
    if (!userAccountStatistics) return [];
    return Object.entries(userAccountStatistics.promoSummary).map(([title, value]) => {
      switch (title) {
        case 'totalAmountActivatedPromos':
        case 'totalAmountPromoToCash':
          return { title: t(title), value: formatCurrency(value) };
        default:
          return { title: t(title), value: formatDecimal(value) };
      }
    });
  };

  const mutatedTransactionsLifetime = () => {
    if (!userAccountStatistics || userAccountStatistics.transactionsLifetime.length === 0)
      return { columns: [], rows: [] };
    const columns: GridColDef[] = Object.keys(userAccountStatistics.transactionsLifetime[0]).map(key => {
      switch (key) {
        case 'cash':
        case 'promo':
          return transformCurrencyV2(key, t);
        case 'count':
          return transformDecimalV2(key, t);
        default:
          return { field: key, headerName: t(key), flex: 1 };
      }
    });
    return { columns, rows: userAccountStatistics.transactionsLifetime };
  };

  const constructTransactionsData = (data: Array<GraphDataPointSchema>) => {
    if (!data || data.length === 0) return null;

    const dataToRender = data.map(entry => ({
      date: getDateTimeFromUnix(entry.date),
      netDeposit: entry.value,
    }));

    return (
      <Card>
        <CardContent>
          <Typography variant="h6">{t('netDeposits')}</Typography>
          <Box sx={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
              <LineChart data={dataToRender}>
                <CartesianGrid strokeDasharray="5 5" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="linear" dataKey="netDeposit" stroke="#2bcf6d" dot={false} />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <Stack spacing={2}>
      {userAccountStatistics &&
        userAccountStatistics.depositsSummary.netDepositOverTime &&
        constructTransactionsData(userAccountStatistics.depositsSummary.netDepositOverTime)}

      <Typography variant="h6">{t('depositsSummary')}</Typography>
      <StyledDataGrid
        columns={columns}
        loading={fetching}
        rows={mutatedDepositsSummary()}
        getRowId={row => row.title}
        autoHeight
        hideFooter
      />
      <Typography variant="h6">{t('bankWithdrawalSummary')}</Typography>
      <StyledDataGrid
        columns={columns}
        loading={fetching}
        rows={mutatedBankWithdrawalSummary()}
        getRowId={row => row.title}
        autoHeight
        hideFooter
      />

      <Typography variant="h6">{t('promoSummary')}</Typography>
      <StyledDataGrid
        columns={columns}
        rows={mutatedPromoSummary()}
        loading={fetching}
        getRowId={row => row.title}
        autoHeight
        hideFooter
      />

      <Typography variant="h6">{t('transactionsLifetime')}</Typography>
      {mutatedTransactionsLifetime().rows && (
        <StyledDataGrid
          loading={fetching}
          columns={mutatedTransactionsLifetime().columns}
          rows={mutatedTransactionsLifetime().rows}
          getRowId={row => row.id}
          autoHeight
        />
      )}
    </Stack>
  );
};

export default AccountStatistics;
