import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ELanguagesSchema, TranslationsSchema } from '@greenisland-api';
import { Box, Card, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';

import Select from '@greenisland-common/components/atoms/Select';

interface Props {
  content: TranslationsSchema;
  open: boolean;
  onClose: () => void;
}

const ServiceContractHistoryContentDialog = ({ content, open, onClose }: Props) => {
  const { t } = useTranslation();

  const contentLanguages: ELanguagesSchema[] = content
    .map(content => content.language)
    .filter((language): language is ELanguagesSchema => language !== undefined);
  const [selectedContentLanguage, setSelectedContentLanguage] = useState(contentLanguages[0]);

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose} fullWidth>
      <Box>
        <Card>
          <DialogTitle>{t('serviceContracts.history.content.title')}</DialogTitle>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <FontAwesomeIcon icon={faClose} />
          </IconButton>
          <DialogContent>
            {contentLanguages.length > 0 ? (
              <Box display="flex" gap={1} flexDirection="column">
                <Select
                  id="contentLanguage"
                  label={t('serviceContracts.content.contentLanguage')}
                  value={selectedContentLanguage}
                  onChange={event => setSelectedContentLanguage(event.target.value as ELanguagesSchema)}
                  options={contentLanguages.map(language => language.toString())}
                />
                <iframe
                  src={content.find(content => content?.language === selectedContentLanguage)?.content}
                  title={t('serviceContracts.history.content.title')}
                  height="500px"
                ></iframe>
              </Box>
            ) : null}
          </DialogContent>
        </Card>
      </Box>
    </Dialog>
  );
};

export default ServiceContractHistoryContentDialog;
