import { EFraudReasonSchema } from '@greenisland-api';

const classes = {
  red: {
    color: '#FF0000',
  },
  green: {
    color: '#00FF00',
  },
  orange: {
    color: '#FFA500',
  },
  blue: {
    color: '#0000FF',
  },
  greyItalic: {
    color: 'grey',
    fontStyle: 'ITALIC',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
};

export const getFraudReasonColor = (fraudReason?: EFraudReasonSchema) => {
  if (!fraudReason) return;

  if (
    fraudReason === EFraudReasonSchema.AutomaticWithdrawalsNotAllowed ||
    fraudReason === EFraudReasonSchema.OtherAccountsUseSameIban ||
    fraudReason === EFraudReasonSchema.OtherAccountsUseSameName ||
    fraudReason === EFraudReasonSchema.NeverDepositedBefore
  )
    return classes.red;
  if (
    fraudReason === EFraudReasonSchema.OtherAccountsUseSameIp ||
    fraudReason === EFraudReasonSchema.OtherAccountsUseSameWallet ||
    fraudReason === EFraudReasonSchema.OnlyUsedCreditCardsAfterLastWithdrawal ||
    fraudReason === EFraudReasonSchema.OtherAccountsUseSameMobileNr
  )
    return classes.orange;

  return classes.red;
};
