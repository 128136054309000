import { useEffect, useState } from 'react';
import { AdvancedSearchSchema, useAdvancedUserSearch, UserSearchResultsSchema } from '@greenisland-api';

const useSearch = (onSuccess: (data: UserSearchResultsSchema) => void) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const parseToJson = (data: string): AdvancedSearchSchema => {
    const [key, value] = data.split(':');
    if (!value) return JSON.parse(`{"username": "${key}"}`) as AdvancedSearchSchema;

    const parseValue = isNaN(value as any) ? `"${value}"` : value;

    return JSON.parse(`{"${key}": ${parseValue}}`) as AdvancedSearchSchema;
  };

  const { isLoading, mutate } = useAdvancedUserSearch({
    mutation: {
      onSuccess(data) {
        onSuccess(data);
        setSearchValue('');
      },
    },
  });

  useEffect(() => {
    if (searchValue) {
      mutate({ data: parseToJson(searchValue) });
    }
  }, [searchValue, mutate]);

  return { setSearchValue, searchValue, isLoading };
};

export default useSearch;
