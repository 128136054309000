import { ERankSchema } from '@greenisland-api';

enum LoyaltyRankOptionsStatus {
  SHOW_ALL = 'SHOW_ALL',
  DISABLE_INACTIVE = 'DISABLE_INACTIVE',
  HIDE_INACTIVE = 'HIDE_INACTIVE',
}

const FORM_FIELD_PRIZES_KEY = 'prizes';

const defaultPromotionalCalendarSettings = {
  active: false,
  name: '',
  calendarType: '',
  minimalLoyaltyRankRequirement: undefined,
};

const defaultPromotionalCalendarPrizeSettingValues = {
  dayOrdinal: 1,
  activeTimestamp: null,
  endTimestamp: null,
  activeDate: null,
  endDate: null,
};

const defaultPromotionalCalendarRankSettingValues = {
  id: undefined,
  name: '',
  description: '',
  redirectUrl: '',
  images: null,
  actionId: '',
  loyaltyRank: '' as ERankSchema,
};

const promotionalCalendarLoyaltyRankOptions = Object.keys(ERankSchema).map(key => ({
  value: key,
  label: key,
  disabled: false,
}));

export {
  promotionalCalendarLoyaltyRankOptions,
  defaultPromotionalCalendarSettings,
  defaultPromotionalCalendarRankSettingValues,
  defaultPromotionalCalendarPrizeSettingValues,
  FORM_FIELD_PRIZES_KEY,
  LoyaltyRankOptionsStatus,
};
