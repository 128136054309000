/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * Bad request type:
 * `UserNotFound` - The relevant user was not found.
 * `InterventionNotFound` - The intervention with given id was not found.
 * `InvalidStateChange` - The state change that is attempted is invalid.
 * `InvalidInterventionId` - The provided intervention id is either null or smaller than 1.
 * `InvalidUserId` - The provided user id is either null or smaller than 1.

 */
export type EInterventionControllerBadRequestTypeSchema =
  (typeof EInterventionControllerBadRequestTypeSchema)[keyof typeof EInterventionControllerBadRequestTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EInterventionControllerBadRequestTypeSchema = {
  UserNotFound: 'UserNotFound',
  InterventionNotFound: 'InterventionNotFound',
  InvalidStateChange: 'InvalidStateChange',
  InvalidInterventionId: 'InvalidInterventionId',
  InvalidUserId: 'InvalidUserId',
} as const;
