import { Dispatch, SetStateAction, useCallback } from 'react';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, capitalize, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Input from '@greenisland-common/components/molecules/Input';

import { AgentItem } from '../../../../pages/OnlineCasino/Settings/Management/Components/Forms/helpers/AgentFormContext';

interface PermissionListProps<T> {
  items: readonly T[];
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  checked: T[];
  setChecked: Dispatch<SetStateAction<T[]>>;
  fallbackText: string;
}

const PermissionList = <T extends AgentItem>({
  items,
  search,
  setSearch,
  checked,
  setChecked,
  fallbackText,
}: PermissionListProps<T>) => {
  const filteredItems = useCallback(
    (items: readonly T[], search: string) =>
      items?.filter(item => item?.name?.toLowerCase()?.includes(search?.toLowerCase())),
    []
  );

  const handleToggle = (value: T) => () => {
    const currentIndex = checked?.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <>
      <Input
        size="small"
        value={search}
        onChange={e => setSearch(e.target.value)}
        fullWidth
        type="text"
        name="search"
        placeholder="Search"
        sx={{ mb: 1 }}
        InputProps={{
          endAdornment: search && (
            <FontAwesomeIcon
              onClick={() => setSearch('')}
              style={{ visibility: search ? 'visible' : 'hidden', marginRight: '10px', cursor: 'pointer' }}
              icon={faCircleXmark}
            />
          ),
        }}
      />
      <Box
        sx={{
          height: 300,
          overflow: 'auto',
          border: `1px solid rgba(0, 0, 0, 0.23)`,
          borderRadius: '4px',
        }}
      >
        <List dense component="div" role="list">
          {items?.length ? (
            filteredItems(items, search).map((value: T) => {
              const labelId = `transfer-list-item-${value.id}-label`;
              return (
                <ListItemButton key={value.id} role="listitem" onClick={handleToggle(value)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value.name} />
                </ListItemButton>
              );
            })
          ) : (
            <Typography variant="body2" fontSize="medium" color="gray" sx={{ py: 1, pl: 2 }}>
              {capitalize(fallbackText)}
            </Typography>
          )}
        </List>
      </Box>
    </>
  );
};

export default PermissionList;
