import React from 'react';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid-pro';

import WithdrawTableToolbarOrderButton from './WithdrawTableToolbarOrderButton';

const WithdrawTableToolbar = ({
  setFilterButtonEl,
  destination,
}: {
  setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  destination: string;
}) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton ref={setFilterButtonEl} />
      <WithdrawTableToolbarOrderButton destination={destination} />
    </GridToolbarContainer>
  );
};

export default WithdrawTableToolbar;
