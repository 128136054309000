/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  DefaultResponse,
  GameSessionSummarySchema,
  GetGameSessionsActivityParams,
  GetGameSessionsParams,
  GetGameSessionSummariesParams,
  NotFoundResponse,
  PagedGameSessionsSchema,
  PagedGameSessionSummariesSchema,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Retrieves a list of gamesessions, based on the query filters.
 */
export const useGetGameSessionsHook = () => {
  const getGameSessions = useCustomInstance<PagedGameSessionsSchema>();

  return (params: GetGameSessionsParams, signal?: AbortSignal) => {
    return getGameSessions({ url: `/gamesessions`, method: 'get', params, signal });
  };
};

export const getGetGameSessionsQueryKey = (params: GetGameSessionsParams) =>
  [`/gamesessions`, ...(params ? [params] : [])] as const;

export const useGetGameSessionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameSessionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetGameSessionsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameSessionsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameSessionsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameSessionsQueryKey(params);

  const getGameSessions = useGetGameSessionsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameSessionsHook>>>> = ({ signal }) =>
    getGameSessions(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetGameSessionsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetGameSessionsHook>>>>;
export type GetGameSessionsQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Retrieves a list of gamesessions, based on the query filters.
 */
export const useGetGameSessions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameSessionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetGameSessionsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameSessionsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameSessionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Retrieves a list of gamesessions, based on the query filters.
 */
export const useGetGameSessionsActivityHook = () => {
  const getGameSessionsActivity = useCustomInstance<GameSessionSummarySchema>();

  return (userId: number, params: GetGameSessionsActivityParams, signal?: AbortSignal) => {
    return getGameSessionsActivity({ url: `/gamesessions/activity/${userId}`, method: 'get', params, signal });
  };
};

export const getGetGameSessionsActivityQueryKey = (userId: number, params: GetGameSessionsActivityParams) =>
  [`/gamesessions/activity/${userId}`, ...(params ? [params] : [])] as const;

export const useGetGameSessionsActivityQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameSessionsActivityHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params: GetGameSessionsActivityParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameSessionsActivityHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameSessionsActivityHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameSessionsActivityQueryKey(userId, params);

  const getGameSessionsActivity = useGetGameSessionsActivityHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameSessionsActivityHook>>>> = ({ signal }) =>
    getGameSessionsActivity(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetGameSessionsActivityQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGameSessionsActivityHook>>>
>;
export type GetGameSessionsActivityQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Retrieves a list of gamesessions, based on the query filters.
 */
export const useGetGameSessionsActivity = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameSessionsActivityHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params: GetGameSessionsActivityParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameSessionsActivityHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameSessionsActivityQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Retrieves a list of gamesession summaries, based on the query filters.
 */
export const useGetGameSessionSummariesHook = () => {
  const getGameSessionSummaries = useCustomInstance<PagedGameSessionSummariesSchema>();

  return (params: GetGameSessionSummariesParams, signal?: AbortSignal) => {
    return getGameSessionSummaries({ url: `/gamesessions/summary`, method: 'get', params, signal });
  };
};

export const getGetGameSessionSummariesQueryKey = (params: GetGameSessionSummariesParams) =>
  [`/gamesessions/summary`, ...(params ? [params] : [])] as const;

export const useGetGameSessionSummariesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameSessionSummariesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetGameSessionSummariesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameSessionSummariesHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameSessionSummariesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameSessionSummariesQueryKey(params);

  const getGameSessionSummaries = useGetGameSessionSummariesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameSessionSummariesHook>>>> = ({ signal }) =>
    getGameSessionSummaries(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetGameSessionSummariesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGameSessionSummariesHook>>>
>;
export type GetGameSessionSummariesQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Retrieves a list of gamesession summaries, based on the query filters.
 */
export const useGetGameSessionSummaries = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameSessionSummariesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetGameSessionSummariesParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameSessionSummariesHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameSessionSummariesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
