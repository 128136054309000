import { Game, TournamentConfiguration } from '@greenisland-store/gameServer';

const POPOVER_MODIFIED_LABELS = [
  {
    label: 'content.gameserver.tournamentconfiguration.form.options.activate',
  },
  {
    label: 'content.gameserver.tournamentconfiguration.form.options.description',
  },
  {
    label: 'content.gameserver.tournamentconfiguration.form.options.validTo',
  },
  {
    label: 'content.gameserver.tournamentconfiguration.form.options.thumbnail',
  },
  {
    label: 'content.gameserver.tournamentconfiguration.form.options.banner',
  },
];

enum TournamentDurationsEnum {
  YEARS = 'years',
  MONTHS = 'months',
  WEEKS = 'weeks',
  DAYS = 'days',
  HOURS = 'hours',
}

enum TournamentPrizePoolEnum {
  Monetary,
  NonMonetary,
}

enum TournamentConfigurationMode {
  ADD = 'add',
  EDIT = 'edit',
  COPY = 'copy',
}

interface CreateTournamentConfigurationFormData extends TournamentConfiguration {
  tournamentDurationWithUnit?: string;
  games?: Game[];
}

export type { CreateTournamentConfigurationFormData };

export { TournamentDurationsEnum, TournamentPrizePoolEnum, TournamentConfigurationMode, POPOVER_MODIFIED_LABELS };
