import React from 'react';
import { Controller } from 'react-hook-form';
import { Box, Collapse, ListItem, ListItemText, Stack, Switch } from '@mui/material';

interface Props {
  enabled: boolean;
  name: string;
  listItemText: string;
}

const IndividualActionFormListItem: React.FC<Props> = ({ enabled, name, listItemText, children }) => {
  return (
    <Box sx={enabled ? { borderLeft: 2, borderColor: 'primary.main' } : undefined}>
      <ListItem>
        <ListItemText primary={listItemText} primaryTypographyProps={{ fontWeight: enabled ? 'bolder' : 'normal' }} />
        <Controller
          name={name}
          render={({ onChange, value }) => (
            <Switch edge="end" onChange={event => onChange(event.target.checked)} checked={value} />
          )}
        />
      </ListItem>
      <Collapse in={enabled}>
        <Stack spacing={2} sx={{ m: 2 }}>
          {children}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default IndividualActionFormListItem;
