import { useTranslation } from 'react-i18next';
import { capitalize } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows';
import { GridRowsProp } from '@mui/x-data-grid-pro';

const useGetRows = (
  data?: Record<string, any>,
  sort?: ((a: GridValidRowModel, b: GridValidRowModel) => number) | undefined
): GridRowsProp => {
  const { t } = useTranslation();

  return Object.entries(data || {})
    .map(([key, value]) => ({
      title: capitalize(t(key)),
      value: value,
      key: key,
      id: key,
    }))
    .sort(sort);
};

export default useGetRows;
