/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * Enum descriptions:
 * `Unknown`
 * `New` - The intervention has been created but nobody is working on it yet.
 * `InProgress` - The intervention is in progress. The start date for the intervention is set. Only new interventions can be go to in progress.
 * `Completed` - The intervention that was in progress has been completed. The end date for the intervention is set. Only in progress interventions can go to completed.
 * `Closed` - The intervention is closed (for example, no longer relevant). Any status can go to the closed status.

 */
export type EInterventionStatusSchema = (typeof EInterventionStatusSchema)[keyof typeof EInterventionStatusSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EInterventionStatusSchema = {
  Unknown: 'Unknown',
  New: 'New',
  InProgress: 'InProgress',
  Completed: 'Completed',
  Closed: 'Closed',
} as const;
