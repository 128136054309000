import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthorizationPermissions } from '@greenisland-core/permissions';
import {
  AuditLogSchema,
  EPermissionLevelFilterSchema,
  useGetAuditLogs,
  useGetPermissions,
} from '@greenisland-store/authorization';
import { useDebouncedValue } from '@lilib/hooks';
import { Autocomplete, Box, FormControl, Input, MenuItem, Stack, TextField, Typography } from '@mui/material';
import {
  DataGridProProps,
  GridColumns,
  GridRenderCellParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { DateTimePicker } from '@mui/x-date-pickers';
import { prettyPrintJson } from 'pretty-print-json';
import { usePermission } from 'src/app/hooks';

import {
  CheckboxIcon,
  DataGridContainer,
  DataGridPagination,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { formatDateTime } from '@greenisland-common/helpers';

const Audit = () => {
  const { t } = useTranslation();

  // Form variables
  const [search, setSearch] = useState('');
  const [method, setMethod] = useState('');
  const [level, setLevel] = useState<EPermissionLevelFilterSchema | undefined>();
  const [code, setCode] = useState('');
  const [permissionId, setPermissionId] = useState('');
  const [start, setStart] = useState<number | undefined>();
  const [end, setEnd] = useState<number | undefined>();
  const [inputValuePermission, setInputValuePermission] = useState('');

  // Debounce code and search
  const [debouncedSearch] = useDebouncedValue(search, { wait: 500 });
  const [debouncedCode] = useDebouncedValue(code, { wait: 500 });

  // Data fetching
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const { data, mutate, isLoading } = useGetAuditLogs();

  useEffect(() => {
    mutate({
      data: {
        start: start != undefined ? start / 1000 : undefined,
        end: end != undefined ? end / 1000 : undefined,
        currentPage: page,
        pageSize: pageSize,
        agentEmailAddress: debouncedSearch != '' ? debouncedSearch : undefined,
        httpStatusCode: debouncedCode != '' && !isNaN(parseInt(debouncedCode)) ? parseInt(debouncedCode) : undefined,
        httpVerb: method != '' ? method : undefined,
        permissionId: permissionId != '' ? permissionId : undefined,
        permissionLevel: level != undefined ? level : undefined,
      },
    });
  }, [page, start, end, debouncedSearch, debouncedCode, method, permissionId, level, mutate, pageSize]);

  // Autocomplete field for permissions
  const canReadPermissions = usePermission(AuthorizationPermissions.getPermissions);
  const permissionData = useGetPermissions();
  const permissionOptions = useMemo(
    () =>
      permissionData?.data?.map(permission => ({
        id: permission.permissionId ?? '',
        label: permission.level + ' ' + permission.path,
      })) ?? [],
    [permissionData.data]
  );

  // Datagrid
  const rows = useMemo(() => {
    return data?.logs?.map((message, index) => ({ ...message, id: index })) ?? [];
  }, [data?.logs]);
  const columns = useMemo<GridColumns<AuditLogSchema>>(() => {
    const headers = [
      {
        headerName: t('timestamp'),
        field: 'timestamp',
        type: 'date',
        valueGetter: ({ value }: GridValueGetterParams<number>) => value && new Date(value * 1000),
        valueFormatter: ({ value }: GridValueFormatterParams<Date>) => formatDateTime(value, false),
        flex: 1,
        minWidth: 150,
      },
      {
        headerName: t('email'),
        field: 'agentEmailAddress',
        filter: 'select',
        type: 'singleSelect',
        valueOptions: () => [...new Set(data?.logs?.map(w => w.agentEmailAddress ?? ''))],
        flex: 1,
        minWidth: 150,
      },
      {
        headerName: t('product'),
        field: 'productName',
        filter: 'select',
        type: 'singleSelect',
        valueOptions: () => [...new Set(data?.logs?.map(w => w.productName ?? ''))],
        flex: 1,
        minWidth: 100,
      },
      {
        headerName: t('path'),
        field: 'requestPath',
        filter: 'select',
        type: 'singleSelect',
        valueOptions: () => [...new Set(data?.logs?.map(w => w.requestPath ?? ''))],
        flex: 1,
        minWidth: 150,
      },
      {
        headerName: t('method'),
        field: 'httpVerb',
        filter: 'select',
        type: 'singleSelect',
        valueOptions: () => [...new Set(data?.logs?.map(w => w.httpVerb ?? ''))],
        flex: 1,
        maxWidth: 75,
      },
      {
        headerName: t('code'),
        field: 'httpStatusCode',
        filter: 'select',
        type: 'singleSelect',
        renderCell: (params: GridRenderCellParams<AuditLogSchema>) => {
          return (
            <>
              <CheckboxIcon checked={params.row.httpStatusCode < 300} />
              <div>{params.row.httpStatusCode}</div>
            </>
          );
        },
        flex: 1,
        maxWidth: 75,
      },
    ];
    return headers;
  }, [data?.logs, t]);
  const getDetailPanelHeight = useCallback(() => 120, []);
  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback(
    ({ row }: { row: AuditLogSchema }) => (
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={16}>
          <Typography>{t('queryString')}</Typography>
          <Typography>{row.queryString != '' ? row.queryString : ''}</Typography>
        </Stack>
        <Stack direction="row" spacing={16}>
          <Typography>{t('requestBody')}</Typography>

          {row.requestBody != '' && (
            <Box
              component="pre"
              sx={{
                overflow: 'auto',
                '& .json-string': {
                  whiteSpace: 'normal',
                },
              }}
              dangerouslySetInnerHTML={{
                __html: prettyPrintJson.toHtml(JSON.parse(row.requestBody ?? '{}'), { linkUrls: true }),
              }}
            />
          )}
        </Stack>
      </Stack>
    ),
    [t]
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        flexWrap="wrap"
        paddingX={2}
        paddingY={1}
        gap={1}
        flexDirection="column"
        sx={{ maxWidth: 500 }}
      >
        <Stack direction="row" spacing={4} sx={{ width: '100%' }}>
          <DateTimePicker
            label={t('startDate')}
            value={start ?? null}
            onChange={newDate => {
              setStart(newDate?.getTime());
              setPage(0);
            }}
            renderInput={params => <TextField {...params} variant="standard" />}
            minDate={new Date(0)}
            inputFormat="dd/MM/yyyy HH:mm"
            mask="__/__/____ __:__"
            ampm={false}
          />
          <DateTimePicker
            label={t('endDate')}
            value={end ?? null}
            onChange={newDate => {
              setEnd(newDate?.getTime());
              setPage(0);
            }}
            renderInput={params => <TextField {...params} variant="standard" />}
            minDate={new Date()}
            inputFormat="dd/MM/yyyy HH:mm"
            mask="__/__/____ __:__"
            ampm={false}
          />
        </Stack>
        <Input
          fullWidth
          placeholder={t('email')}
          aria-label=""
          value={search}
          onChange={e => {
            setSearch(e.target.value);
            setPage(0);
          }}
        />
        <Input
          fullWidth
          placeholder={t('code')}
          aria-label=""
          value={code}
          onChange={e => {
            if (e.target.value == '' || !isNaN(parseInt(e.target.value))) {
              setCode(e.target.value);
              setPage(0);
            }
          }}
        />
        <FormControl fullWidth>
          <TextField
            variant="standard"
            label={t('permissionLevel')}
            select
            onChange={e => {
              setLevel(e.target.value != '' ? (e.target.value as EPermissionLevelFilterSchema | undefined) : undefined);
              setPage(0);
            }}
            value={level ?? ''}
          >
            <MenuItem key={'noFilter'} value={''}>
              {t('common.noFilter')}
            </MenuItem>
            {[EPermissionLevelFilterSchema.Read, EPermissionLevelFilterSchema.Write].map(level => (
              <MenuItem key={level} value={level}>
                {level.toString()}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            variant="standard"
            label={t('method')}
            select
            onChange={e => {
              setMethod(e.target.value);
              setPage(0);
            }}
            value={method}
          >
            <MenuItem key={'noFilter'} value={''}>
              {t('common.noFilter')}
            </MenuItem>
            {['GET', 'POST', 'PUT', 'DELETE', 'PATCH'].map(verb => (
              <MenuItem key={verb} value={verb}>
                {verb}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
        {canReadPermissions && (
          <Autocomplete
            fullWidth
            value={undefined}
            onChange={(event: any, newValue: any) => {
              setPermissionId(newValue?.id);
            }}
            inputValue={inputValuePermission}
            onInputChange={(event, newInputValue) => {
              setInputValuePermission(newInputValue);
            }}
            options={permissionOptions}
            renderInput={params => <TextField {...params} variant="standard" label={t('endpoint')} />}
          />
        )}
      </Box>
      <DataGridContainer>
        <StyledDataGrid
          density="compact"
          loading={isLoading}
          rows={rows}
          columns={columns}
          rowsPerPageOptions={[10, 25, 50, 100, 200]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          showColumnRightBorder={false}
          disableDensitySelector
          disableSelectionOnClick
          rowCount={data?.pagingDetails?.totalItems ?? 0}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          components={{
            Pagination: DataGridPagination,
          }}
        />
      </DataGridContainer>
    </>
  );
};

export default Audit;
