import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GameThemesSchema, useGetGameThemes } from '@greenisland-api';
import { Skeleton } from '@mui/material';

import { AutoCompleteInput } from '@greenisland-common/components/atoms';

import { capitalize } from '@greenisland-common/helpers/formatters';

import { ContentFiltersPrimaryKeysType } from '../helpers/ContentFilterFormContext';

interface Props {
  type: 'included' | 'excluded';
}

const ContentFilterThemeProviders = ({ type }: Props) => {
  const { t } = useTranslation();
  const { data: themes, isLoading } = useGetGameThemes();
  const themesOptions = useMemo(() => themes?.map(theme => ({ ...theme })) ?? [], [themes]);

  if (isLoading) return <Skeleton variant="rounded" height={56} animation="wave" />;

  return (
    <AutoCompleteInput
      size="small"
      multiple={true}
      name={`${type}Filter.${ContentFiltersPrimaryKeysType.gameThemePKeys}`}
      label={capitalize(t('content.games.themes.add'))}
      options={themesOptions}
      getLabel={option => option?.name ?? ''}
      rules={{
        required: true,
        setValueAs: (themes: GameThemesSchema) => {
          return themes?.map(theme => Number(theme?.gameThemeId));
        },
      }}
    />
  );
};

export default ContentFilterThemeProviders;
