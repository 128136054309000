import { AgentDetailsSchema, EPermissionLevelSchema, EProductTypeSchema } from '@greenisland-store/authorization';
import { Permission } from '@greenisland-types/authorization';

export class ReadPermission {
  readonly level = EPermissionLevelSchema.Read;
  constructor(
    readonly path: string,
    readonly productType: keyof typeof EProductTypeSchema = EProductTypeSchema.OnlineCasino
  ) {}

  toString() {
    return this.path;
  }
}

export class WritePermission {
  readonly level = EPermissionLevelSchema.Write;
  constructor(
    readonly path: string,
    readonly productType: keyof typeof EProductTypeSchema = EProductTypeSchema.OnlineCasino
  ) {}

  toString() {
    return this.path;
  }
}

export const hasPermission = (currentAgent: AgentDetailsSchema, permission: Permission) =>
  currentAgent.permissions.some(
    agentPermission =>
      agentPermission.path === permission.path &&
      agentPermission.level === permission.level &&
      agentPermission.productType === permission.productType
  );

export const canAccess = (
  currentAgent: AgentDetailsSchema,
  permissions: Permission | Permission[],
  strict: boolean
) => {
  if (currentAgent.isAdmin) return true;
  if (Array.isArray(permissions)) {
    return !permissions.length || strict
      ? permissions.every(permission => hasPermission(currentAgent, permission))
      : permissions.some(permission => hasPermission(currentAgent, permission));
  } else {
    return hasPermission(currentAgent, permissions);
  }
};
