import { useTranslation } from 'react-i18next';
import { capitalize, TextFieldProps, Typography } from '@mui/material';

interface UserInfoFieldProps {
  label: string;
  value?: string;
  size?: TextFieldProps['size'];
}

const UserInfoField = ({ label, value, size = 'small' }: UserInfoFieldProps) => {
  const { t } = useTranslation();

  const sx = {
    mb: size === 'small' ? 1 : 2,
    fontSize: size,
  };

  return (
    <Typography fontWeight={500} variant="body2" sx={sx}>
      {`${capitalize(t(label))}: `}
      <span style={{ fontWeight: 400 }}>{value || '-'}</span>
    </Typography>
  );
};

export default UserInfoField;
