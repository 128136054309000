import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, capitalize, Chip } from '@mui/material';

import {
  ContentFiltersExtendedPrimaryKeysType,
  ContentFiltersPrimaryKeysType,
  ExtendedContentFilterItemsSchema,
} from '../helpers/ContentFilterFormContext';

const MAX_UNEXPANDED_CHIPS = 5;

interface Props {
  filterType: 'included' | 'excluded';
  indexKey: string;
  data: ExtendedContentFilterItemsSchema;
}

const GetContentFilterStatus = ({ filterType, indexKey, data }: Props) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);

  const renderChips = useCallback(
    (
      items: {
        pkey: number;
        name: string;
      }[],
      showAll: boolean,
      maxItems: number
    ) => {
      const itemsToDisplay = showAll ? items : items.slice(0, maxItems);

      return (
        <>
          {itemsToDisplay.map(item => (
            <Chip key={item?.pkey} label={item?.name} />
          ))}
          {items.length > maxItems && (
            <Button
              variant="contained"
              size="small"
              endIcon={<FontAwesomeIcon icon={showAll ? faCaretUp : faCaretDown} />}
              onClick={() => setShowAll(toggle => !toggle)}
            >
              {showAll
                ? t('content.contentFilter.tableHeaders.filters.showLess')
                : t('content.contentFilter.tableHeaders.filters.showMore')}
            </Button>
          )}
        </>
      );
    },
    [t]
  );

  const renderContentFilterKey = (key: string) => {
    switch (key) {
      case ContentFiltersExtendedPrimaryKeysType.games:
        return ContentFiltersPrimaryKeysType.gamePKeys;
      case ContentFiltersExtendedPrimaryKeysType.gameProviders:
        return ContentFiltersPrimaryKeysType.gameProviderPKeys;
      case ContentFiltersExtendedPrimaryKeysType.gameStudios:
        return ContentFiltersPrimaryKeysType.gameStudioPKeys;
      case ContentFiltersExtendedPrimaryKeysType.gameCategories:
        return ContentFiltersPrimaryKeysType.gameCategoryPKeys;
      case ContentFiltersExtendedPrimaryKeysType.gameThemes:
        return ContentFiltersPrimaryKeysType.gameThemePKeys;
    }
  };

  return (
    <Box key={indexKey} display="flex" flexDirection="column">
      <p style={{ color: 'grey' }}>
        {capitalize(t(`content.contentFilter.tableHeaders.filters.${renderContentFilterKey(indexKey)}`))}:
      </p>
      <Box display="flex" flexWrap="wrap" gap={2}>
        {data?.items?.length === 0 ? (
          <Chip
            key={indexKey}
            label={
              filterType === 'included'
                ? t('content.contentFilter.tableHeaders.includedFilters.all')
                : t('content.contentFilter.tableHeaders.includedFilters.none')
            }
          />
        ) : (
          renderChips(data?.items, showAll, MAX_UNEXPANDED_CHIPS)
        )}
      </Box>
    </Box>
  );
};

export default GetContentFilterStatus;
