import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FontAwesomeIcon, SelectInput } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

import { useGetTournamentExtraCompoundRequirementsConditionValues } from '../helpers/tournamentConfigFormHooks';
import { TournamentConfigurationMode } from '../helpers/tournamentConfigFormInterfaces';

interface Props {
  entryIndex: number;
  mode: TournamentConfigurationMode;
}

const TournamentConfigExtraSubRequirements = ({ entryIndex, mode }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const extraCompoundRequirementsCondition = useGetTournamentExtraCompoundRequirementsConditionValues();

  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `extraRequirements.compoundRequirements[${entryIndex}].requirements`,
  });

  const canModify = mode === TournamentConfigurationMode.ADD || mode === TournamentConfigurationMode.COPY;

  useEffect(() => {
    if (canModify && !fields?.length) {
      append({
        attribute: '',
        condition: undefined,
        value: '',
      });
    }
  }, [append, canModify, fields?.length]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {fields?.map((requirement, requirementIndex) => {
        return (
          <Box
            key={requirementIndex}
            sx={{
              [theme.breakpoints.up('xs')]: {
                width: '100%',
              },
              [theme.breakpoints.up('md')]: {
                width: '70%',
              },
            }}
          >
            <Box
              px={2}
              sx={{
                border: '1px solid #eee',
                borderRadius: '4px',
              }}
            >
              <Box display="flex" alignItems="center">
                <Typography fontWeight={600} variant="body2" fontSize="large" my={2}>
                  {`${t('content.gameserver.tournamentconfiguration.form.other.subrequirement')} ${
                    requirementIndex + 1
                  }`}
                </Typography>
                {canModify && requirementIndex !== 0 && (
                  <FontAwesomeIcon
                    icon={faTrash}
                    sx={{ marginLeft: 1, color: 'error.main', cursor: 'pointer' }}
                    onClick={() => remove(requirementIndex)}
                  />
                )}
              </Box>
              <Box display="flex" flexDirection="column">
                <Input
                  size="small"
                  label={t('content.gameserver.tournamentconfiguration.extraRequirements.attribute')}
                  control={control}
                  type="text"
                  required
                  rules={{ required: t('fieldIsRequired') }}
                  name={`extraRequirements.compoundRequirements[${entryIndex}].requirements[${requirementIndex}].attribute`}
                  sx={{
                    [theme.breakpoints.up('xs')]: {
                      mb: 2,
                    },
                  }}
                  defaultValue={requirement?.attribute}
                  disabled={mode === TournamentConfigurationMode.EDIT}
                />
                <SelectInput
                  size="small"
                  name={`extraRequirements.compoundRequirements[${entryIndex}].requirements[${requirementIndex}].condition`}
                  label={t('content.gameserver.tournamentconfiguration.extraRequirements.condition')}
                  options={extraCompoundRequirementsCondition}
                  rules={{ required: t('fieldIsRequired') }}
                  defaultValue={requirement?.condition}
                  sx={{
                    [theme.breakpoints.up('xs')]: {
                      mb: 2,
                    },
                  }}
                  disabled={mode === TournamentConfigurationMode.EDIT}
                />
                <Input
                  control={control}
                  size="small"
                  label={t('content.gameserver.tournamentconfiguration.extraRequirements.value')}
                  type="text"
                  required
                  rules={{ required: t('fieldIsRequired') }}
                  name={`extraRequirements.compoundRequirements[${entryIndex}].requirements[${requirementIndex}].value`}
                  defaultValue={requirement?.value}
                  sx={{
                    [theme.breakpoints.up('xs')]: {
                      mb: 2,
                    },
                  }}
                  disabled={mode === TournamentConfigurationMode.EDIT}
                />
              </Box>
            </Box>
            {fields && fields?.length > 1 && requirementIndex !== fields?.length - 1 && (
              <Typography variant="body2" fontSize="large" fontWeight="bold" my={2} textAlign="center">
                {t('content.gameserver.tournamentconfiguration.form.other.and')}
              </Typography>
            )}
          </Box>
        );
      })}
      {canModify && (
        <Button
          onClick={() => {
            append({
              attribute: '',
              condition: undefined,
              value: '',
            });
          }}
          variant="contained"
          size="small"
          sx={{
            mx: 'auto',
            my: 2,
          }}
          startIcon={<FontAwesomeIcon icon={faAdd} />}
        >
          {t('content.gameserver.tournamentconfiguration.form.extrarequirementssettings.addsubrequirement')}
        </Button>
      )}
    </Box>
  );
};

export default TournamentConfigExtraSubRequirements;
