import { Component } from 'react';
import ReactDOM from 'react-dom';
import { Box, CircularProgress, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';

import { ErrorBoundary } from '@greenisland-common/components/atoms';

import { GIComponentTheme } from './app/theme';
import App from './App';
import ConfigLoader from './ConfigLoader';

declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

class Config extends Component {
  constructor(props: any) {
    super(props);

    // For testing unauthorized stuff
    // const agent = sessionStorage.getItem('developmentloggedInAgent');
    // if (agent) {
    // 	const parsed = JSON.parse(agent);
    // 	parsed.token =
    // 		'eyJraWQiOiJLcjgrdXR1TFdyd2FZVGxZY3J6R09HenNiN1JZbm5ZSEd1K0xFWERuSzJFPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoicUJBb3Zrdl9YZ19xSFdfeU9rMGctQSIsInN1YiI6IjFhZjFiNjk1LWU1MTAtNDg2Mi1iNThmLWFjMzc0MGVlODgwMyIsImNvZ25pdG86Z3JvdXBzIjpbImV1LWNlbnRyYWwtMV9nZFNaQ3Z5WlJfSnVtcENsb3VkIiwiQk9BdXRob3JpemF0aW9uRGV2IiwiQk9BdXRob3JpemF0aW9uIiwiQk9BdXRob3JpemF0aW9uUHJvZCJdLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xX2dkU1pDdnlaUiIsInBob25lX251bWJlcl92ZXJpZmllZCI6ZmFsc2UsImNvZ25pdG86dXNlcm5hbWUiOiJKdW1wQ2xvdWRfbGFuZGVyQGdyZWVuaXNsYW5kLmJlIiwibm9uY2UiOiItNGtYV3kwMHBUTWxyNi05OEVXb0xXM2NIODV6UV9xZHI0YklETzd4SWhmVnlDeUJzQUZDNnlhMjM3ZEI3TVlnZVlTWEdWNTc5Y2x0ZzdLNnhvOHE0UmhwOTRRMEVyeXJLblBYdHQyLUZ1N3VnbUI3dmg2ZGVkQmFBZTg1c3ZtczZvNDlCbDV0M3IwRktSVjJKMktDakVOS0hZV2l5WlE4YzdPRnVVVEJHRGciLCJhdWQiOiIxNHNnYjMwNmJiOWdrOHIxZWpqajdsdTd2MyIsImlkZW50aXRpZXMiOlt7InVzZXJJZCI6ImxhbmRlckBncmVlbmlzbGFuZC5iZSIsInByb3ZpZGVyTmFtZSI6Ikp1bXBDbG91ZCIsInByb3ZpZGVyVHlwZSI6IlNBTUwiLCJpc3N1ZXIiOiJKdW1wQ2xvdWQiLCJwcmltYXJ5IjoidHJ1ZSIsImRhdGVDcmVhdGVkIjoiMTU4OTk5MTA5MjY3MyJ9XSwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2MDIyMzQ4NjQsIm5hbWUiOiJMYW5kZXIiLCJwaG9uZV9udW1iZXIiOiIrMzI0OTQzNzg1ODkiLCJleHAiOjE2MDIyMzg0NjQsImlhdCI6MTYwMjIzNDg2NCwiZW1haWwiOiJsYW5kZXJAZ3JlZW5pc2xhbmQuYmUifQ.hK8ecKLTNqxBprQBMI-SY4_GFjzXjyFNMWWQ0Lr1nNypFYh7LQHsabpWun6UmRKMRyH5kUfS3_iJxFLfudw2ZDYrTcxJXfn11Hi8MDatFnO8D0F8BAGZ1MT_rBEt4cSEorSPupGrPqgRwLjYu9FjRj4M9Wh0BVxesZZqmvvqKW0Xa1xg-Azdt0Dbk2tb_z3gj-LfSZyoa_HC-yKK1ZXF8tFhFKrf8VfvI9I1_D98hQhvDW5ynlViX7is4GfAI8PSLLclKgu4SHieJhlvyWBSd3ifwp0b6VR8gr7Idz5nUZ5MFxjbxokBcXxTn5IjqPSNLzfR2y7PKeA-MyHbxyKSmQ';
    // 	sessionStorage.setItem('developmentloggedInAgent', JSON.stringify(parsed));
    // }
  }

  render() {
    // When the configuration is loaded, render the Home module
    return (
      <ThemeProvider theme={GIComponentTheme}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
              <ConfirmProvider
                defaultOptions={{
                  cancellationButtonProps: { color: 'secondary' },
                  confirmationButtonProps: { color: 'success', variant: 'contained' },
                }}
              >
                <ErrorBoundary>
                  <ConfigLoader
                    loading={() => (
                      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                        <CircularProgress />
                      </Box>
                    )}
                    ready={<App />}
                  />
                </ErrorBoundary>
              </ConfirmProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    );
  }
}

const rootElement = document.getElementById('root');

if (rootElement && rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<Config />, rootElement);
} else {
  ReactDOM.render(<Config />, rootElement);
}
