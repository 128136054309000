/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EWithdrawProviderSchema = (typeof EWithdrawProviderSchema)[keyof typeof EWithdrawProviderSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EWithdrawProviderSchema = {
  bankAccount: 'bankAccount',
  casino: 'casino',
  skrill: 'skrill',
  neteller: 'neteller',
  paypal: 'paypal',
  trustly: 'trustly',
  truelayer: 'truelayer',
} as const;
