import { useTranslation } from 'react-i18next';
import { GameSessionSummarySchema } from '@greenisland-api';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import { DataGridContainer, DataGridPlain, DataGridPlainHeader, NoData } from '@greenisland-common/components/atoms';

import { formatCurrency } from '../../../../../../app/helpers/transformFunctions';

type Row = GameSessionSummarySchema;

const columns: GridColumns<Row> = [
  {
    field: 'title',
    headerName: '',
    renderCell: ({ value }) => (
      <DataGridPlainHeader sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} value={value} />
    ),
    flex: 0.7,
  },
  { field: 'value', headerName: '', renderCell: ({ value }) => value, flex: 0.3 },
];

interface Props {
  data?: GameSessionSummarySchema;
}

const GamesActivityTableData = ({ data }: Props) => {
  const { t } = useTranslation();

  if (!data) return <NoData justifyContent="center" py={2} fontWeight="bold" />;

  const rows: GridRowsProp = [
    { id: 1, title: t('gamesActivityGamingRevenue'), value: formatCurrency(data.gamingRevenue) },
    { id: 2, title: t('rtp'), value: `${(data.rtp * 100).toFixed(2)}%` },
    { id: 3, title: t('gamesActivityTotalStake'), value: formatCurrency(data.totalBetAmount) },
    { id: 4, title: t('gamesActivityTotalWinnings'), value: formatCurrency(data.totalWinAmount) },
    { id: 5, title: t('gamesActivityNumberOfBets'), value: data.betCount },
    { id: 6, title: t('gamesActivityNumberOfWins'), value: data.winCount },
    { id: 7, title: t('gamesActivityNumberOfSessions'), value: data.sessionCount },
  ];

  return (
    <DataGridContainer>
      <DataGridPlain columns={columns} rows={rows} />
    </DataGridContainer>
  );
};

export default GamesActivityTableData;
