import React from 'react';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { Box, SxProps } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

interface Props {
  sx?: SxProps;
  value: string;
}

const UpdatedAt = ({ value }: Props) => {
  return (
    <Box display="flex" alignItems="center">
      <FontAwesomeIcon icon={faClock} sx={{ mr: 2, width: '20px', height: '20px' }} />
      {value}
    </Box>
  );
};

export default UpdatedAt;
