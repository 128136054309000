import { useTranslation } from 'react-i18next';
import { useGetBonusRequestSimulation } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, CircularProgress } from '@mui/material';

import { NoData, StyledDataGrid } from '@greenisland-common/components/atoms';

import {
  transformCurrencyV2,
  transformDecimalV2,
  transformDefaultV2,
  transformPercentageV2,
} from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';

const BonusRequestSimulation = () => {
  const { t } = useTranslation();
  const canReadBonusRequestSimulation = usePermission(OnlineCasinoPermissions.getBonusRequestSimulation);
  const { data: bonusRequestSimulation, isLoading: fetching } = useGetBonusRequestSimulation({
    query: { enabled: canReadBonusRequestSimulation },
  });

  const columns =
    bonusRequestSimulation && bonusRequestSimulation.length > 0
      ? Object.keys(bonusRequestSimulation[0]).map(key => {
          switch (key) {
            case 'promoBackClaimables':
            case 'promoBackAvailables':
            case 'maxClaimable':
              return transformPercentageV2(key, t);
            case 'netDepositLastWeek':
              return transformCurrencyV2(key, t);
            case 'availablesAwarded':
            case 'factor':
              return transformDecimalV2(key, t);
            default:
              return transformDefaultV2(key, t, true);
          }
        })
      : [];

  if (fetching)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );

  if (!canReadBonusRequestSimulation || !bonusRequestSimulation?.length) return <NoData />;

  return (
    <StyledDataGrid
      columns={columns}
      rows={bonusRequestSimulation}
      getRowId={row => row.simulationId}
      pagination
      pageSize={5}
      rowsPerPageOptions={[5, 10, 20]}
      disableSelectionOnClick
      initialState={{
        columns: {
          columnVisibilityModel: {
            simulationId: false,
          },
        },
      }}
    />
  );
};

export default BonusRequestSimulation;
