import React from 'react';
import { useTranslation } from 'react-i18next';
import { faRotateLeft, faWarning } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

interface Props {
  loading?: boolean;
  errorMessage?: string;
  retryAction?: () => void;
}

const ErrorState = ({ loading, errorMessage, retryAction }: Props) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mx: 2, my: 4 }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography sx={{ mb: 4 }} variant="h4">
            <FontAwesomeIcon sx={{ marginRight: 1, color: 'error.main' }} icon={faWarning} />
            {t('somethingWentWrong')}
          </Typography>
          {errorMessage ? (
            <Typography sx={{ mb: 4 }} variant="body1">
              {errorMessage}
            </Typography>
          ) : null}
          {retryAction ? (
            <Button variant="contained" startIcon={<FontAwesomeIcon icon={faRotateLeft} />} onClick={retryAction}>
              {t('common.retry')}
            </Button>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default ErrorState;
