import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export enum BoostDurationsEnum {
  SECONDS = 'seconds',
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
}

export function useBoostLoyaltyDependentOptions() {
  const { t } = useTranslation();
  return useMemo(() => {
    return [
      {
        label: t('marketing.loyaltyDependentMaxPayout.standard'),
        name: 'loyaltyDependentMaxPayout.standardValue',
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.bronze'),
        name: 'loyaltyDependentMaxPayout.bronzeValue',
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.silver'),
        name: 'loyaltyDependentMaxPayout.silverValue',
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.gold'),
        name: 'loyaltyDependentMaxPayout.goldValue',
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.platinum'),
        name: 'loyaltyDependentMaxPayout.platinumValue',
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.diamond'),
        name: 'loyaltyDependentMaxPayout.diamondValue',
      },
      {
        label: t('marketing.loyaltyDependentMaxPayout.vip'),
        name: 'loyaltyDependentMaxPayout.vipValue',
      },
    ];
  }, [t]);
}

export function useBoostDurationValues() {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { value: BoostDurationsEnum.SECONDS, label: t('seconds') },
      { value: BoostDurationsEnum.MINUTES, label: t('minutes') },
      { value: BoostDurationsEnum.HOURS, label: t('hours') },
      { value: BoostDurationsEnum.DAYS, label: t('days') },
    ],
    [t]
  );
}
