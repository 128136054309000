/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */

export type ExtraRequirementsCondition = (typeof ExtraRequirementsCondition)[keyof typeof ExtraRequirementsCondition];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExtraRequirementsCondition = {
  IS_ANY_OF: 'IS_ANY_OF',
  IS_ALL_OF: 'IS_ALL_OF',
  IS_ONE_OF: 'IS_ONE_OF',
  IS_NOT_ONE_OF: 'IS_NOT_ONE_OF',
} as const;
