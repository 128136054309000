import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalize, TextField } from '@mui/material';

import Input from '@greenisland-common/components/molecules/Input';
import LanguageTranslationSelect from '@greenisland-common/components/organisms/LanguageTranslationSelect';

import { ExtendedContentFilterSchema } from '../helpers/ContentFilterFormContext';

interface Props {
  contentFilter?: ExtendedContentFilterSchema;
}

const ContentFilterDetailsByIdGeneralSection = ({ contentFilter }: Props) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <>
      <p>{capitalize(t('content.contentFilter.details.general'))}</p>
      {contentFilter?.id && (
        <TextField
          size="small"
          label={t('content.contentFilter.tableHeaders.id')}
          type="text"
          name="id"
          disabled={true}
          value={contentFilter.id}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: 'rgba(0, 0, 0, 0.7)',
            },
          }}
        />
      )}
      {contentFilter?.name && (
        <Input
          size="small"
          label={t('content.contentFilter.tableHeaders.name')}
          control={control}
          type="text"
          name="name"
          defaultValue={contentFilter?.name || ''}
          required
          rules={{ required: t('fieldIsRequired') }}
        />
      )}
      <LanguageTranslationSelect name="description" mainTitle="description" />
    </>
  );
};
export default ContentFilterDetailsByIdGeneralSection;
