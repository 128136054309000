import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  EAddictionCategoryClassificationSchema,
  getGetAddictionCategoryQueryKey,
  SetAddictionCategoryManuallyArgumentsSchema,
  useSetAddictionCategoryManually,
} from '@greenisland-api';
import {
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { getUnixTime } from 'date-fns';
import { useSnackbar } from 'notistack';

type SetManuallyDialogDialogProps = {
  open: boolean;
  setOpen: (state: boolean) => void;
  userId: string;
};

const SetManuallyDialog = ({ open, setOpen, userId }: SetManuallyDialogDialogProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const setAddictionCategoryManuallyMutation = useSetAddictionCategoryManually({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetAddictionCategoryQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
        setOpen(false);
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const { handleSubmit, errors, control } = useForm<SetAddictionCategoryManuallyArgumentsSchema>({
    mode: 'onChange',
  });

  const onSubmit = (data: SetAddictionCategoryManuallyArgumentsSchema) => {
    setAddictionCategoryManuallyMutation.mutate({
      userId,
      data: {
        ...data,
        expiresAt: getUnixTime(new Date(data.expiresAt)),
      },
    });
  };

  return (
    <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{capitalize(t('addictionMonitor.changeUserClassification'))}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Controller
              name="classification"
              rules={{ required: t('fieldIsRequired') as string }}
              control={control}
              render={({ onChange, value }) => (
                <TextField
                  size="small"
                  select
                  fullWidth
                  label={t('addictionMonitor.classification')}
                  value={value}
                  onChange={onChange}
                  error={!!errors.classification}
                >
                  {Object.values(EAddictionCategoryClassificationSchema).map((key: string) => (
                    <MenuItem key={key} value={key}>
                      {t(key)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              control={control}
              defaultValue={new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toISOString()}
              rules={{ required: t('fieldIsRequired') as string }}
              name="expiresAt"
              render={({ onChange, value }) => (
                <DatePicker
                  InputProps={{ size: 'small' }}
                  disablePast
                  label={t('addictionMonitor.endDate')}
                  value={value}
                  onChange={value => {
                    if (!isNaN(value.getTime())) {
                      onChange(value);
                    }
                  }}
                  renderInput={params => <TextField {...params} />}
                  inputFormat="dd/MM/yyyy"
                  mask="__/__/____"
                  views={['year', 'month', 'day']}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={() => setOpen(false)}>
            {t('cancel')}
          </Button>
          <Button
            sx={{ minHeight: '36px', minWidth: '90px' }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={setAddictionCategoryManuallyMutation.isLoading}
          >
            {t('addictionMonitor.change')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SetManuallyDialog;
