import React, { useState } from 'react';
import { Pagination, styled, TablePagination } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  paddingBottom: 10,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}));

const MediaGalleryPagination = ({
  pageSize,
  page,
  pageCount,
  onPage,
  onPageSize,
}: {
  pageSize: number;
  page: number;
  pageCount: number;
  onPage: (page: number) => void;
  onPageSize: (page: number) => void;
}) => {
  return (
    <Root>
      <TablePagination
        component="div"
        rowsPerPage={pageSize}
        count={pageCount}
        onPageChange={(event, value) => onPage(value - 1)}
        onRowsPerPageChange={event => {
          onPageSize(parseInt(event.target.value, 10));
          onPage(0);
        }}
        page={page + 1}
        sx={{
          p: 0,
          width: ['100%', 'auto'],
          '& .MuiTablePagination-spacer': {
            display: 'none',
          },
          '& .MuiTablePagination-displayedRows': {
            display: 'none',
          },
          '& .MuiTablePagination-actions': {
            display: 'none',
          },
        }}
      />
      {pageCount !== 1 ? (
        <Pagination
          color="primary"
          count={pageCount}
          page={page + 1}
          onChange={(event, value) => onPage(value - 1)}
          showFirstButton
          showLastButton
          boundaryCount={1}
          siblingCount={0}
        />
      ) : null}
    </Root>
  );
};

export default MediaGalleryPagination;
