import { useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { MonetaryTournamentPrizePoolDistributionEntry } from '@greenisland-store/gameServer';
import { Box, Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

import { validatePrizeEntriesHandler } from '../helpers/tournamentConfigFormHelpers';
import { useGetTournamentPrizePoolValues } from '../helpers/tournamentConfigFormHooks';
import { TournamentConfigurationMode } from '../helpers/tournamentConfigFormInterfaces';

interface Props {
  mode: TournamentConfigurationMode;
}

const TournamentConfigNonMonetaryPrizePoolSettings = ({ mode = TournamentConfigurationMode.ADD }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const prizePoolValues = useGetTournamentPrizePoolValues();
  const { control, watch, errors, setError, clearErrors } = useFormContext();

  const canModify = mode === TournamentConfigurationMode.ADD || mode === TournamentConfigurationMode.COPY;

  const watchNonMonetaryPrizeEntries: MonetaryTournamentPrizePoolDistributionEntry[] = watch(
    'prizePoolDistribution.nonMonetaryPrizeEntries'
  );
  const validateNonMonetaryPrizeEntries = validatePrizeEntriesHandler(watchNonMonetaryPrizeEntries);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prizePoolDistribution.nonMonetaryPrizeEntries',
  });

  useEffect(() => {
    if (!validateNonMonetaryPrizeEntries) {
      setError('prizePoolDistribution.nonMonetaryPrizeEntries', {
        type: 'custom',
        message: 'content.gameserver.tournamentconfiguration.form.prizepoolsettings.ascendingordererror',
      });
    } else {
      clearErrors('prizePoolDistribution.nonMonetaryPrizeEntries');
    }
  }, [clearErrors, setError, validateNonMonetaryPrizeEntries]);

  const calculateAmountHandler = useMemo(() => {
    return watchNonMonetaryPrizeEntries?.reduce((acc, current) => {
      const difference = (current.to ?? 0) - (current.from ?? 0) + 1;
      return acc + difference;
    }, 0);
  }, [watchNonMonetaryPrizeEntries]);

  if (mode === TournamentConfigurationMode.EDIT && !watchNonMonetaryPrizeEntries?.length) {
    return null;
  }

  return (
    <Stack spacing={2} position="relative">
      <Card>
        <CardHeader
          title={`${prizePoolValues[1]?.label} ${t(
            'content.gameserver.tournamentconfiguration.form.prizepoolsettings.title'
          )}`}
        />
        {errors?.prizePoolDistribution?.nonMonetaryPrizeEntries && (
          <Typography ml={2} variant="body2" fontSize="small" color={theme.palette.error.main}>
            {t(errors?.prizePoolDistribution?.nonMonetaryPrizeEntries?.message)}
          </Typography>
        )}
        <CardContent>
          <>
            {fields?.map((entry, index) => {
              return (
                <Box
                  key={entry.id}
                  display="flex"
                  alignItems="center"
                  gap={2}
                  mb={2}
                  sx={{
                    [theme.breakpoints.up('xs')]: {
                      flexDirection: 'column',
                    },
                    [theme.breakpoints.up('md')]: {
                      flexDirection: 'row',
                    },
                  }}
                >
                  <Input
                    control={control}
                    size="small"
                    label={t('content.gameserver.tournamentconfiguration.form.prizepoolsettings.table.firstinrow')}
                    type="number"
                    name={`prizePoolDistribution.nonMonetaryPrizeEntries[${index}].from`}
                    required
                    defaultValue={entry?.from}
                    InputProps={{ inputProps: { min: 1 } }}
                    rules={{ required: t('fieldIsRequired'), valueAsNumber: true }}
                    sx={{
                      [theme.breakpoints.up('xs')]: {
                        width: '100%',
                      },
                      [theme.breakpoints.up('md')]: {
                        width: '20%',
                      },
                    }}
                    disabled={mode === TournamentConfigurationMode.EDIT}
                  />
                  <Input
                    control={control}
                    size="small"
                    label={t('content.gameserver.tournamentconfiguration.form.prizepoolsettings.table.lastinrow')}
                    type="number"
                    name={`prizePoolDistribution.nonMonetaryPrizeEntries[${index}].to`}
                    required
                    defaultValue={entry?.to}
                    InputProps={{ inputProps: { min: 1 } }}
                    rules={{ required: t('fieldIsRequired'), valueAsNumber: true }}
                    sx={{
                      [theme.breakpoints.up('xs')]: {
                        width: '100%',
                      },
                      [theme.breakpoints.up('md')]: {
                        width: '20%',
                      },
                    }}
                    disabled={mode === TournamentConfigurationMode.EDIT}
                  />
                  <Input
                    control={control}
                    size="small"
                    label={t('content.gameserver.tournamentconfiguration.prizepooldistribution.prize')}
                    type="text"
                    name={`prizePoolDistribution.nonMonetaryPrizeEntries[${index}].prize`}
                    required
                    defaultValue={entry?.prize}
                    rules={{ required: t('fieldIsRequired') }}
                    sx={{
                      [theme.breakpoints.up('xs')]: {
                        width: '100%',
                      },
                      [theme.breakpoints.up('md')]: {
                        width: '20%',
                      },
                    }}
                    disabled={mode === TournamentConfigurationMode.EDIT}
                  />
                  {canModify && (
                    <FontAwesomeIcon
                      icon={faTrash}
                      sx={{ marginLeft: 1, color: 'error.main', cursor: 'pointer' }}
                      onClick={() => remove(index)}
                    />
                  )}
                </Box>
              );
            })}
            {fields?.length && calculateAmountHandler ? (
              <Box my={2}>
                <Typography fontSize="medium" fontWeight="bold">
                  {`${t(
                    'content.gameserver.tournamentconfiguration.prizepooldistribution.totalPrizeAmount'
                  )} ${calculateAmountHandler} `}
                </Typography>
              </Box>
            ) : null}
            {canModify && (
              <Button
                onClick={() =>
                  append({
                    from: 1,
                    to: 1,
                    cash: '',
                  })
                }
                variant="contained"
                size="small"
                startIcon={<FontAwesomeIcon icon={faAdd} />}
              >
                {t('content.gameserver.tournamentconfiguration.form.prizepoolsettings.table.addrow')}
              </Button>
            )}
          </>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default TournamentConfigNonMonetaryPrizePoolSettings;
