import { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faAdd, faEuro, faEuroSign, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ERegulatorSchema, TournamentPrizeConfigurationSchema } from '@greenisland-api';
import { Box, Button, capitalize, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FontAwesomeIcon, FormError } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

import { useTenantContext } from '../../../../../../../app/context/TenantContext';
import {
  calculatePrizePoolTotalAmount,
  CreateMultiplierTournamentSchemaForm,
  PrizePoolType,
} from '../helpers/MultiplierTournamentFormContext';

interface Props {
  prizePoolEntriesValidation: boolean;
}

const MultiplierTournamentPrizePoolSettings = ({ prizePoolEntriesValidation }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const tenant = useTenantContext();
  const { control, watch } = useFormContext<CreateMultiplierTournamentSchemaForm>();
  const isKSATenant = tenant?.regulatorV2 === ERegulatorSchema.KSA;
  const prizePoolEntries: TournamentPrizeConfigurationSchema[] = watch('prizePool');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prizePool',
  });

  const calculateBonusPrizeHandler = useMemo(() => {
    if (isKSATenant) {
      return calculatePrizePoolTotalAmount(prizePoolEntries, PrizePoolType.BONUS_PRIZE);
    }
    return 0;
  }, [isKSATenant, prizePoolEntries]);

  const calculateWithdrawablePrizeHandler = useMemo(() => {
    return calculatePrizePoolTotalAmount(prizePoolEntries, PrizePoolType.WITHDRAWABLE_PRIZE);
  }, [prizePoolEntries]);

  return (
    <Stack spacing={2}>
      <Typography fontWeight={500} variant="h5" sx={{ pt: 1 }}>
        {capitalize(t('content.gameserver.tournamentconfiguration.form.prizepoolsettings.title'))}
      </Typography>
      {!prizePoolEntriesValidation && (
        <FormError
          sx={{
            mb: 1,
          }}
          error={true}
          message={t('content.gameserver.tournamentconfiguration.form.prizepoolsettings.ascendingordererror')}
        />
      )}
      <Box>
        {fields?.map((entry, index) => {
          return (
            <Box
              key={entry.id}
              display="flex"
              alignItems="center"
              gap={2}
              mb={2}
              sx={{
                [theme.breakpoints.up('xs')]: {
                  flexDirection: 'column',
                },
                [theme.breakpoints.up('md')]: {
                  flexDirection: 'row',
                },
              }}
            >
              <Input
                control={control}
                size="medium"
                label={capitalize(
                  t('content.gameserver.tournamentconfiguration.form.prizepoolsettings.table.firstinrow')
                )}
                type="number"
                name={`prizePool[${index}].firstPlace`}
                InputProps={{ inputProps: { min: 1 } }}
                required
                defaultValue={entry.firstPlace}
                rules={{ required: t('fieldIsRequired'), valueAsNumber: true }}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    width: '100%',
                  },
                  [theme.breakpoints.up('md')]: {
                    width: '20%',
                  },
                }}
              />
              <Input
                control={control}
                size="medium"
                label={capitalize(
                  t('content.gameserver.tournamentconfiguration.form.prizepoolsettings.table.lastinrow')
                )}
                type="number"
                name={`prizePool[${index}].lastPlace`}
                required
                defaultValue={entry.lastPlace}
                InputProps={{ inputProps: { min: 1 } }}
                rules={{ required: t('fieldIsRequired'), valueAsNumber: true }}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    width: '100%',
                  },
                  [theme.breakpoints.up('md')]: {
                    width: '20%',
                  },
                }}
              />
              {isKSATenant && (
                <Input
                  control={control}
                  size="medium"
                  label={capitalize(t('bonusPrize'))}
                  type="number"
                  name={`prizePool[${index}].bonusPrize`}
                  required={isKSATenant}
                  defaultValue={entry.bonusPrize}
                  InputProps={{
                    startAdornment: <FontAwesomeIcon sx={{ pr: 2 }} icon={faEuroSign} />,
                    inputProps: { min: 0, step: 'any' },
                  }}
                  rules={{
                    required: isKSATenant ? t('fieldIsRequired') : false,
                    valueAsNumber: true,
                    setValueAs: value => parseFloat(value),
                  }}
                  sx={{
                    [theme.breakpoints.up('xs')]: {
                      width: '100%',
                    },
                    [theme.breakpoints.up('md')]: {
                      width: '20%',
                    },
                  }}
                />
              )}
              <Input
                control={control}
                size="medium"
                label={capitalize(t('withdrawablePrize'))}
                type="number"
                name={`prizePool[${index}].withdrawablePrize`}
                required
                defaultValue={entry.withdrawablePrize}
                InputProps={{
                  startAdornment: <FontAwesomeIcon sx={{ pr: 2 }} icon={faEuroSign} />,
                  inputProps: { min: 0, step: 'any' },
                }}
                rules={{ required: t('fieldIsRequired'), valueAsNumber: true, setValueAs: value => parseFloat(value) }}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    width: '100%',
                  },
                  [theme.breakpoints.up('md')]: {
                    width: '20%',
                  },
                }}
              />
              <FontAwesomeIcon
                icon={faTrash}
                sx={{ marginLeft: 1, color: 'error.main', cursor: 'pointer' }}
                onClick={() => remove(index)}
              />
            </Box>
          );
        })}
        {fields?.length ? (
          <Box my={2}>
            {isKSATenant && calculateBonusPrizeHandler && (
              <Typography fontSize="medium" variant="body2" fontWeight="bold">
                {`${t(
                  'contests.multiplierTournaments.form.prizepoolsettings.totalBonusAmount'
                )} ${calculateBonusPrizeHandler.toFixed(2)} `}
                <FontAwesomeIcon sx={{ pr: 2 }} icon={faEuro} />
              </Typography>
            )}
            <Typography fontSize="medium" variant="body2" fontWeight="bold" my={1}>
              {`${t(
                'contests.multiplierTournaments.form.prizepoolsettings.totalWithdrawableAmount'
              )} ${calculateWithdrawablePrizeHandler.toFixed(2)} `}
              <FontAwesomeIcon sx={{ pr: 2 }} icon={faEuro} />
            </Typography>
          </Box>
        ) : null}
        <Button
          onClick={() =>
            append({
              firstPlace: 1,
              lastPlace: 1,
              bonusPrize: 0,
              withdrawablePrize: 0,
            })
          }
          variant="contained"
          size="medium"
          startIcon={<FontAwesomeIcon icon={faAdd} />}
        >
          {t('content.gameserver.tournamentconfiguration.form.prizepoolsettings.table.addrow')}
        </Button>
      </Box>
    </Stack>
  );
};

export default MultiplierTournamentPrizePoolSettings;
