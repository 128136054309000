/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  ComplaintClosureSchema,
  ComplaintsNotFoundResponseResponse,
  ComplaintTicketSchema,
  ComplaintTicketsSchema,
  CreateComplaintTicketSchema,
  DefaultResponse,
  GetAllComplaintTicketsParams,
  PreconditionFailedResponse,
  UnauthorizedResponse,
  UpdateComplaintEntrySchema,
  UpdateComplaintTicketSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Gets all complaints.
 */
export const useGetAllComplaintTicketsHook = () => {
  const getAllComplaintTickets = useCustomInstance<ComplaintTicketsSchema>();

  return (params?: GetAllComplaintTicketsParams, signal?: AbortSignal) => {
    return getAllComplaintTickets({ url: `/complaints`, method: 'get', params, signal });
  };
};

export const getGetAllComplaintTicketsQueryKey = (params?: GetAllComplaintTicketsParams) =>
  [`/complaints`, ...(params ? [params] : [])] as const;

export const useGetAllComplaintTicketsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAllComplaintTicketsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | ComplaintsNotFoundResponseResponse | DefaultResponse
>(
  params?: GetAllComplaintTicketsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllComplaintTicketsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllComplaintTicketsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllComplaintTicketsQueryKey(params);

  const getAllComplaintTickets = useGetAllComplaintTicketsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllComplaintTicketsHook>>>> = ({ signal }) =>
    getAllComplaintTickets(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAllComplaintTicketsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAllComplaintTicketsHook>>>
>;
export type GetAllComplaintTicketsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | ComplaintsNotFoundResponseResponse
  | DefaultResponse;

/**
 * @summary Gets all complaints.
 */
export const useGetAllComplaintTickets = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAllComplaintTicketsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | ComplaintsNotFoundResponseResponse | DefaultResponse
>(
  params?: GetAllComplaintTicketsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllComplaintTicketsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAllComplaintTicketsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Creates a new complaint ticket.
 */
export const useCreateComplaintTicketHook = () => {
  const createComplaintTicket = useCustomInstance<ComplaintTicketSchema>();

  return (createComplaintTicketSchema: CreateComplaintTicketSchema) => {
    return createComplaintTicket({
      url: `/complaints`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: createComplaintTicketSchema,
    });
  };
};

export const useCreateComplaintTicketMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | ComplaintsNotFoundResponseResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateComplaintTicketHook>>>,
    TError,
    { data: CreateComplaintTicketSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateComplaintTicketHook>>>,
  TError,
  { data: CreateComplaintTicketSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createComplaintTicket = useCreateComplaintTicketHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateComplaintTicketHook>>>,
    { data: CreateComplaintTicketSchema }
  > = props => {
    const { data } = props ?? {};

    return createComplaintTicket(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateComplaintTicketMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateComplaintTicketHook>>>
>;
export type CreateComplaintTicketMutationBody = CreateComplaintTicketSchema;
export type CreateComplaintTicketMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | ComplaintsNotFoundResponseResponse
  | DefaultResponse;

/**
 * @summary Creates a new complaint ticket.
 */
export const useCreateComplaintTicket = <
  TError = BadRequestResponse | UnauthorizedResponse | ComplaintsNotFoundResponseResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateComplaintTicketHook>>>,
    TError,
    { data: CreateComplaintTicketSchema },
    TContext
  >;
}) => {
  const mutationOptions = useCreateComplaintTicketMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets the requested complaint ticket.
 */
export const useGetComplaintTicketHook = () => {
  const getComplaintTicket = useCustomInstance<ComplaintTicketSchema>();

  return (complaintTicketId: number, signal?: AbortSignal) => {
    return getComplaintTicket({ url: `/complaints/${complaintTicketId}`, method: 'get', signal });
  };
};

export const getGetComplaintTicketQueryKey = (complaintTicketId: number) =>
  [`/complaints/${complaintTicketId}`] as const;

export const useGetComplaintTicketQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetComplaintTicketHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | ComplaintsNotFoundResponseResponse | DefaultResponse
>(
  complaintTicketId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetComplaintTicketHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetComplaintTicketHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetComplaintTicketQueryKey(complaintTicketId);

  const getComplaintTicket = useGetComplaintTicketHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetComplaintTicketHook>>>> = ({ signal }) =>
    getComplaintTicket(complaintTicketId, signal);

  return { queryKey, queryFn, enabled: !!complaintTicketId, ...queryOptions };
};

export type GetComplaintTicketQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetComplaintTicketHook>>>
>;
export type GetComplaintTicketQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | ComplaintsNotFoundResponseResponse
  | DefaultResponse;

/**
 * @summary Gets the requested complaint ticket.
 */
export const useGetComplaintTicket = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetComplaintTicketHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | ComplaintsNotFoundResponseResponse | DefaultResponse
>(
  complaintTicketId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetComplaintTicketHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetComplaintTicketQueryOptions(complaintTicketId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update the indicated complaint ticket.
 */
export const useUpdateComplaintTicketHook = () => {
  const updateComplaintTicket = useCustomInstance<ComplaintTicketSchema>();

  return (complaintTicketId: number, updateComplaintTicketSchema: UpdateComplaintTicketSchema) => {
    return updateComplaintTicket({
      url: `/complaints/${complaintTicketId}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: updateComplaintTicketSchema,
    });
  };
};

export const useUpdateComplaintTicketMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | ComplaintsNotFoundResponseResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateComplaintTicketHook>>>,
    TError,
    { complaintTicketId: number; data: UpdateComplaintTicketSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateComplaintTicketHook>>>,
  TError,
  { complaintTicketId: number; data: UpdateComplaintTicketSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateComplaintTicket = useUpdateComplaintTicketHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateComplaintTicketHook>>>,
    { complaintTicketId: number; data: UpdateComplaintTicketSchema }
  > = props => {
    const { complaintTicketId, data } = props ?? {};

    return updateComplaintTicket(complaintTicketId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateComplaintTicketMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateComplaintTicketHook>>>
>;
export type UpdateComplaintTicketMutationBody = UpdateComplaintTicketSchema;
export type UpdateComplaintTicketMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | ComplaintsNotFoundResponseResponse
  | DefaultResponse;

/**
 * @summary Update the indicated complaint ticket.
 */
export const useUpdateComplaintTicket = <
  TError = BadRequestResponse | UnauthorizedResponse | ComplaintsNotFoundResponseResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateComplaintTicketHook>>>,
    TError,
    { complaintTicketId: number; data: UpdateComplaintTicketSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateComplaintTicketMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Archive the given ticket.
 */
export const useArchiveComplaintTicketHook = () => {
  const archiveComplaintTicket = useCustomInstance<void>();

  return (complaintTicketId: number) => {
    return archiveComplaintTicket({ url: `/complaints/${complaintTicketId}`, method: 'delete' });
  };
};

export const useArchiveComplaintTicketMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | ComplaintsNotFoundResponseResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveComplaintTicketHook>>>,
    TError,
    { complaintTicketId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useArchiveComplaintTicketHook>>>,
  TError,
  { complaintTicketId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const archiveComplaintTicket = useArchiveComplaintTicketHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useArchiveComplaintTicketHook>>>,
    { complaintTicketId: number }
  > = props => {
    const { complaintTicketId } = props ?? {};

    return archiveComplaintTicket(complaintTicketId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ArchiveComplaintTicketMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useArchiveComplaintTicketHook>>>
>;

export type ArchiveComplaintTicketMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | ComplaintsNotFoundResponseResponse
  | DefaultResponse;

/**
 * @summary Archive the given ticket.
 */
export const useArchiveComplaintTicket = <
  TError = BadRequestResponse | UnauthorizedResponse | ComplaintsNotFoundResponseResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveComplaintTicketHook>>>,
    TError,
    { complaintTicketId: number },
    TContext
  >;
}) => {
  const mutationOptions = useArchiveComplaintTicketMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Adds a new complaint entry.
 */
export const useAddComplaintTicketEntryHook = () => {
  const addComplaintTicketEntry = useCustomInstance<ComplaintTicketSchema>();

  return (complaintTicketId: number, updateComplaintEntrySchema: UpdateComplaintEntrySchema) => {
    return addComplaintTicketEntry({
      url: `/complaints/${complaintTicketId}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateComplaintEntrySchema,
    });
  };
};

export const useAddComplaintTicketEntryMutationOptions = <
  TError =
    | BadRequestResponse
    | UnauthorizedResponse
    | ComplaintsNotFoundResponseResponse
    | PreconditionFailedResponse
    | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddComplaintTicketEntryHook>>>,
    TError,
    { complaintTicketId: number; data: UpdateComplaintEntrySchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddComplaintTicketEntryHook>>>,
  TError,
  { complaintTicketId: number; data: UpdateComplaintEntrySchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addComplaintTicketEntry = useAddComplaintTicketEntryHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddComplaintTicketEntryHook>>>,
    { complaintTicketId: number; data: UpdateComplaintEntrySchema }
  > = props => {
    const { complaintTicketId, data } = props ?? {};

    return addComplaintTicketEntry(complaintTicketId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddComplaintTicketEntryMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddComplaintTicketEntryHook>>>
>;
export type AddComplaintTicketEntryMutationBody = UpdateComplaintEntrySchema;
export type AddComplaintTicketEntryMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | ComplaintsNotFoundResponseResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @summary Adds a new complaint entry.
 */
export const useAddComplaintTicketEntry = <
  TError =
    | BadRequestResponse
    | UnauthorizedResponse
    | ComplaintsNotFoundResponseResponse
    | PreconditionFailedResponse
    | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddComplaintTicketEntryHook>>>,
    TError,
    { complaintTicketId: number; data: UpdateComplaintEntrySchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddComplaintTicketEntryMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Closes a complaint ticket.
 */
export const useCloseComplaintTicketHook = () => {
  const closeComplaintTicket = useCustomInstance<ComplaintTicketSchema>();

  return (complaintTicketId: number, complaintClosureSchema: ComplaintClosureSchema) => {
    return closeComplaintTicket({
      url: `/complaints/${complaintTicketId}/close`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: complaintClosureSchema,
    });
  };
};

export const useCloseComplaintTicketMutationOptions = <
  TError =
    | BadRequestResponse
    | UnauthorizedResponse
    | ComplaintsNotFoundResponseResponse
    | PreconditionFailedResponse
    | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCloseComplaintTicketHook>>>,
    TError,
    { complaintTicketId: number; data: ComplaintClosureSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCloseComplaintTicketHook>>>,
  TError,
  { complaintTicketId: number; data: ComplaintClosureSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const closeComplaintTicket = useCloseComplaintTicketHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCloseComplaintTicketHook>>>,
    { complaintTicketId: number; data: ComplaintClosureSchema }
  > = props => {
    const { complaintTicketId, data } = props ?? {};

    return closeComplaintTicket(complaintTicketId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CloseComplaintTicketMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCloseComplaintTicketHook>>>
>;
export type CloseComplaintTicketMutationBody = ComplaintClosureSchema;
export type CloseComplaintTicketMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | ComplaintsNotFoundResponseResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @summary Closes a complaint ticket.
 */
export const useCloseComplaintTicket = <
  TError =
    | BadRequestResponse
    | UnauthorizedResponse
    | ComplaintsNotFoundResponseResponse
    | PreconditionFailedResponse
    | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCloseComplaintTicketHook>>>,
    TError,
    { complaintTicketId: number; data: ComplaintClosureSchema },
    TContext
  >;
}) => {
  const mutationOptions = useCloseComplaintTicketMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Updates a complaint entry.
 */
export const useUpdateComplaintEntryHook = () => {
  const updateComplaintEntry = useCustomInstance<ComplaintTicketSchema>();

  return (
    complaintTicketId: number,
    complaintEntryId: number,
    updateComplaintEntrySchema: UpdateComplaintEntrySchema
  ) => {
    return updateComplaintEntry({
      url: `/complaints/${complaintTicketId}/${complaintEntryId}`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: updateComplaintEntrySchema,
    });
  };
};

export const useUpdateComplaintEntryMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | ComplaintsNotFoundResponseResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateComplaintEntryHook>>>,
    TError,
    { complaintTicketId: number; complaintEntryId: number; data: UpdateComplaintEntrySchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateComplaintEntryHook>>>,
  TError,
  { complaintTicketId: number; complaintEntryId: number; data: UpdateComplaintEntrySchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateComplaintEntry = useUpdateComplaintEntryHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateComplaintEntryHook>>>,
    { complaintTicketId: number; complaintEntryId: number; data: UpdateComplaintEntrySchema }
  > = props => {
    const { complaintTicketId, complaintEntryId, data } = props ?? {};

    return updateComplaintEntry(complaintTicketId, complaintEntryId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateComplaintEntryMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateComplaintEntryHook>>>
>;
export type UpdateComplaintEntryMutationBody = UpdateComplaintEntrySchema;
export type UpdateComplaintEntryMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | ComplaintsNotFoundResponseResponse
  | DefaultResponse;

/**
 * @summary Updates a complaint entry.
 */
export const useUpdateComplaintEntry = <
  TError = BadRequestResponse | UnauthorizedResponse | ComplaintsNotFoundResponseResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateComplaintEntryHook>>>,
    TError,
    { complaintTicketId: number; complaintEntryId: number; data: UpdateComplaintEntrySchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateComplaintEntryMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deletes a complaint entry.
 */
export const useArchiveComplaintEntryHook = () => {
  const archiveComplaintEntry = useCustomInstance<void>();

  return (complaintTicketId: number, complaintEntryId: number) => {
    return archiveComplaintEntry({ url: `/complaints/${complaintTicketId}/${complaintEntryId}`, method: 'delete' });
  };
};

export const useArchiveComplaintEntryMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | ComplaintsNotFoundResponseResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveComplaintEntryHook>>>,
    TError,
    { complaintTicketId: number; complaintEntryId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useArchiveComplaintEntryHook>>>,
  TError,
  { complaintTicketId: number; complaintEntryId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const archiveComplaintEntry = useArchiveComplaintEntryHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useArchiveComplaintEntryHook>>>,
    { complaintTicketId: number; complaintEntryId: number }
  > = props => {
    const { complaintTicketId, complaintEntryId } = props ?? {};

    return archiveComplaintEntry(complaintTicketId, complaintEntryId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ArchiveComplaintEntryMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useArchiveComplaintEntryHook>>>
>;

export type ArchiveComplaintEntryMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | ComplaintsNotFoundResponseResponse
  | DefaultResponse;

/**
 * @summary Deletes a complaint entry.
 */
export const useArchiveComplaintEntry = <
  TError = BadRequestResponse | UnauthorizedResponse | ComplaintsNotFoundResponseResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useArchiveComplaintEntryHook>>>,
    TError,
    { complaintTicketId: number; complaintEntryId: number },
    TContext
  >;
}) => {
  const mutationOptions = useArchiveComplaintEntryMutationOptions(options);

  return useMutation(mutationOptions);
};
