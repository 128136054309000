import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { faAdd, faEye } from '@fortawesome/free-solid-svg-icons';
import { ComplaintTicketSchema, useGetAllComplaintTickets } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, Stack } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { GridActionsCellItem, GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import { getDateFromUnix } from 'src/app/helpers/transformFunctions';
import { usePermission } from 'src/app/hooks';

import {
  DataGridContainer,
  DataGridPagination,
  FontAwesomeIcon,
  Link,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import AddComplaintDialog from './AddComplaintDialog';
import ComplaintsFilter from './ComplaintsFilter';
import ComplaintStatusField from './ComplaintsStatusField';

const Complaints = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [params, setParams] = useState({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [isOpen, setIsOpen] = useState(false);

  const canReadComplaints = usePermission(OnlineCasinoPermissions.getAllComplaintTickets);
  const canAddComplaint = usePermission(OnlineCasinoPermissions.createComplaintTicket);
  const canViewComplaint = usePermission(OnlineCasinoPermissions.getComplaintTicket);
  const navigate = useNavigate();

  useEffect(() => {
    const user = searchParams.get('user');
    if (user) {
      setParams(oldParams => ({ ...oldParams, complaintUserId: user }));
    }
  }, [searchParams]);

  const {
    data: complaints,
    isLoading,
    isError,
  } = useGetAllComplaintTickets(
    { ...params, numberOfItems: pageSize, pageNumber: page },
    { query: { enabled: canReadComplaints } }
  );
  const [rowCountState, setRowCountState] = useState(complaints?.pagination?.totalItems || 0);
  useEffect(() => {
    setRowCountState(prevRowCountState =>
      complaints?.pagination?.totalItems !== undefined ? complaints?.pagination?.totalItems : prevRowCountState
    );
  }, [complaints?.pagination?.totalItems, setRowCountState]);

  const rows = useMemo(() => complaints?.data?.map((c, index) => ({ ...c, pk: index })) ?? [], [complaints]);

  const columns = useMemo<GridColumns<ComplaintTicketSchema>>(
    () => [
      { field: 'pk', headerName: '#', flex: 0.1, sortable: false },
      {
        field: 'title',
        headerName: t('tasks.complaints.title'),
        flex: 2,
        sortable: false,
        minWidth: 200,
      },
      {
        field: 'userId',
        headerName: t('tasks.complaints.userId'),
        flex: 0.5,
        renderCell: params => <Link to={`../../users/${params.row.userId}/details`}>{params.row.userId}</Link>,
        sortable: false,
        minWidth: 80,
      },
      {
        field: 'complaintEntries',
        headerName: t('tasks.complaints.replies'),
        flex: 0.5,
        renderCell: params => params.row.complaintEntries.length,
        sortable: false,
        minWidth: 80,
      },
      {
        field: 'type',
        headerName: t('tasks.complaints.type'),
        flex: 0.5,
        sortable: false,
        minWidth: 80,
      },
      {
        field: 'status',
        headerName: t('tasks.complaints.status'),
        renderCell: params => <ComplaintStatusField value={params.row.status} />,
        flex: 0.5,
        sortable: false,
        minWidth: 150,
      },
      {
        field: 'startDate',
        headerName: t('tasks.complaints.startDate'),
        renderCell: params =>
          params.row.startDate && params.row.startDate > 0 ? getDateFromUnix(params.row.startDate) : '-',
        flex: 0.5,
        sortable: false,
        minWidth: 120,
      },
      {
        field: 'endDate',
        headerName: t('tasks.complaints.endDate'),
        renderCell: params =>
          params.row.endDate && params.row.endDate > 0 ? getDateFromUnix(params.row.endDate) : '-',
        flex: 0.5,
        sortable: false,
        minWidth: 120,
      },
      ...(canViewComplaint
        ? [
            {
              field: 'actions',
              type: 'actions',
              getActions: (params: GridRowParams<ComplaintTicketSchema>) => [
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faEye} />}
                  label="View"
                  onClick={() => navigate(`../complaints/${params.row.id}`)}
                  key="view"
                />,
              ],
              flex: 0.5,
            },
          ]
        : []),
    ],
    [canViewComplaint, navigate, t]
  );

  return canReadComplaints ? (
    <>
      <Stack spacing={2}>
        {canAddComplaint ? (
          <Box width="100%" display="flex" justifyContent="flex-end">
            <Button variant="contained" startIcon={<FontAwesomeIcon icon={faAdd} />} onClick={() => setIsOpen(true)}>
              {t('tasks.complaints.add')}
            </Button>
          </Box>
        ) : null}
        <ComplaintsFilter onChange={filters => setParams(filters)} />
        {!isError ? (
          <DataGridContainer>
            <StyledDataGrid
              density="compact"
              autoHeight
              loading={isLoading}
              rows={rows}
              rowCount={rowCountState}
              columns={columns}
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={newPage => setPage(newPage)}
              onPageSizeChange={newPageSize => setPageSize(newPageSize)}
              disableDensitySelector
              disableSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
              disableChildrenSorting
              disableChildrenFiltering
              disableMultipleColumnsSorting
              disableColumnResize
              disableColumnReorder
              isRowSelectable={() => false}
              components={{
                Pagination: DataGridPagination,
                LoadingOverlay: LinearProgress,
              }}
              componentsProps={{
                pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] },
              }}
            />
          </DataGridContainer>
        ) : (
          <ErrorState errorMessage={t('tasks.complaints.loadingError')} />
        )}
      </Stack>
      {canAddComplaint ? <AddComplaintDialog setOpen={setIsOpen} open={isOpen} /> : null}
    </>
  ) : null;
};

export default Complaints;
