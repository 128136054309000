import { Dispatch, SetStateAction } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';

import SelectDropdownButton from '@greenisland-common/components/organisms/SelectDropdownButton';

import { BoostDurationsEnum } from '../../../../pages/OnlineCasino/Marketing/Campaigns/Actions/Individual/components/helpers/BoostFormContext';
import { BonusDurationsEnum } from '../../../../pages/OnlineCasino/Marketing/Campaigns/Actions/Individual/Forms/helpers/DepositBonusFormContext';

interface ConfigurationOption {
  label: string;
  value: BonusDurationsEnum | BoostDurationsEnum;
}
interface Props<T> {
  chosenValue: T;
  options: T[];
  onChange: Dispatch<SetStateAction<T>>;
  InputProps?: TextFieldProps['InputProps'];
  name: TextFieldProps['name'];
  inputRef?: TextFieldProps['inputRef'];
  inputProps?: TextFieldProps['inputProps'];
  helperText?: TextFieldProps['helperText'];
  size?: TextFieldProps['size'];
  error?: TextFieldProps['error'];
  disabled?: TextFieldProps['disabled'];
  type?: TextFieldProps['type'];
  required?: TextFieldProps['required'];
  label?: TextFieldProps['label'];
  sx?: TextFieldProps['sx'];
}

const SelectInputDurationField = <T extends ConfigurationOption>({
  chosenValue,
  options,
  onChange,
  InputProps,
  disabled,
  ...rest
}: Props<T>) => {
  return (
    <TextField
      {...rest}
      disabled={disabled}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <SelectDropdownButton
              disabled={disabled}
              chosenValue={chosenValue}
              options={options}
              onChange={onChange}
              sx={{ ml: 0 }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SelectInputDurationField;
