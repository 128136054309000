import React, { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertProps } from '@mui/material';

const ErrorCard = ({ severity, sx, children, ...rest }: AlertProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { t } = useTranslation();

  return (
    <Alert
      ref={ref}
      sx={{ display: 'center', justifyContent: 'center', alignItems: 'center', height: 300, ...sx }}
      severity={severity || 'error'}
      {...rest}
    >
      {children || t('error')}
    </Alert>
  );
};

export default forwardRef(ErrorCard);
