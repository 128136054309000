import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  ESetMaxLimitReasonSchema,
  getGetActiveMaximumBalanceLimitQueryKey,
  UpdateMaxUserLimitSchema,
  useSetMaximumBalanceLimit,
} from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';

import { SelectInput } from '@greenisland-common/components/atoms';
import DateField from '@greenisland-common/components/molecules/DateInput/DateInput';

interface Props {
  userId: number;
  onClose: () => void;
}

const RequestMaxLimitForm = ({ userId, onClose }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const methods = useForm<UpdateMaxUserLimitSchema>({ mode: 'onChange' });
  const { register, handleSubmit, errors } = methods;

  const onSubmit = (data: UpdateMaxUserLimitSchema) => {
    mutate({
      userId,
      data: { ...data, expiryDate: data?.expiryDate ? new Date(data.expiryDate).getTime() / 1000 : null },
    });
  };

  const { mutate, isLoading } = useSetMaximumBalanceLimit({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetActiveMaximumBalanceLimitQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
        onClose();
      },
      onError: error => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  return (
    <Box pt={1}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={2}>
            <DateField
              label={t('expiryDate')}
              name="expiryDate"
              rules={{ required: t('fieldIsRequired') }}
              size="small"
            />
            <TextField
              size="small"
              fullWidth
              label={t('value')}
              type="number"
              name="value"
              inputRef={register({
                required: t('fieldIsRequired'),
                valueAsNumber: true,
                min: 0,
              })}
              error={!!errors.value}
            />
            <SelectInput
              size="small"
              name="reason"
              label={t('reason')}
              options={Object.keys(ESetMaxLimitReasonSchema).map(key => ({ value: key, label: t(key) }))}
              defaultValue={ESetMaxLimitReasonSchema.AddictionCategoryIncreased}
            />
          </Stack>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={onClose}>
              {t('cancel')}
            </Button>
            <LoadingButton type="submit" variant="contained" color="primary" loading={isLoading}>
              {t('save')}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Box>
  );
};

export default RequestMaxLimitForm;
