/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  BonusRequestSimulationsSchema,
  CasinosSchema,
  ConfigurationArgumentsSchema,
  ConfigurationsSchema,
  CountryListSchema,
  DefaultResponse,
  IPWhitelistInnerSchema,
  IPWhitelistSchema,
  NotFoundResponse,
  PreconditionFailedResponse,
  PromotionNameConfigurationBadRequestResponse,
  PromotionNameConfigurationsSchema,
  StartupSettingsSchema,
  UnauthorizedResponse,
  UpdatePromotionNameConfigurationSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get the startup parameters for the current website
 */
export const useGetStartupSettingsHook = () => {
  const getStartupSettings = useCustomInstance<StartupSettingsSchema>();

  return (signal?: AbortSignal) => {
    return getStartupSettings({ url: `/settings/startup`, method: 'get', signal });
  };
};

export const getGetStartupSettingsQueryKey = () => [`/settings/startup`] as const;

export const useGetStartupSettingsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetStartupSettingsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStartupSettingsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStartupSettingsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStartupSettingsQueryKey();

  const getStartupSettings = useGetStartupSettingsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetStartupSettingsHook>>>> = ({ signal }) =>
    getStartupSettings(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetStartupSettingsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetStartupSettingsHook>>>
>;
export type GetStartupSettingsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the startup parameters for the current website
 */
export const useGetStartupSettings = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetStartupSettingsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetStartupSettingsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetStartupSettingsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get the IP whitelist
 */
export const useGetIpWhitelistHook = () => {
  const getIpWhitelist = useCustomInstance<IPWhitelistSchema>();

  return (signal?: AbortSignal) => {
    return getIpWhitelist({ url: `/settings/fraud/ipwhitelist`, method: 'get', signal });
  };
};

export const getGetIpWhitelistQueryKey = () => [`/settings/fraud/ipwhitelist`] as const;

export const useGetIpWhitelistQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetIpWhitelistHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIpWhitelistHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIpWhitelistHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIpWhitelistQueryKey();

  const getIpWhitelist = useGetIpWhitelistHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetIpWhitelistHook>>>> = ({ signal }) =>
    getIpWhitelist(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetIpWhitelistQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetIpWhitelistHook>>>>;
export type GetIpWhitelistQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary get the IP whitelist
 */
export const useGetIpWhitelist = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetIpWhitelistHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIpWhitelistHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetIpWhitelistQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary add to the IP whitelist
 */
export const useAddIpWhitelistHook = () => {
  const addIpWhitelist = useCustomInstance<IPWhitelistSchema>();

  return (iPWhitelistInnerSchema: IPWhitelistInnerSchema) => {
    return addIpWhitelist({
      url: `/settings/fraud/ipwhitelist`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: iPWhitelistInnerSchema,
    });
  };
};

export const useAddIpWhitelistMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddIpWhitelistHook>>>,
    TError,
    { data: IPWhitelistInnerSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddIpWhitelistHook>>>,
  TError,
  { data: IPWhitelistInnerSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addIpWhitelist = useAddIpWhitelistHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddIpWhitelistHook>>>,
    { data: IPWhitelistInnerSchema }
  > = props => {
    const { data } = props ?? {};

    return addIpWhitelist(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddIpWhitelistMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddIpWhitelistHook>>>>;
export type AddIpWhitelistMutationBody = IPWhitelistInnerSchema;
export type AddIpWhitelistMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary add to the IP whitelist
 */
export const useAddIpWhitelist = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddIpWhitelistHook>>>,
    TError,
    { data: IPWhitelistInnerSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddIpWhitelistMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary get the list of casinos
 */
export const useGetCasinosHook = () => {
  const getCasinos = useCustomInstance<CasinosSchema>();

  return (signal?: AbortSignal) => {
    return getCasinos({ url: `/settings/casinos`, method: 'get', signal });
  };
};

export const getGetCasinosQueryKey = () => [`/settings/casinos`] as const;

export const useGetCasinosQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCasinosHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCasinosHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCasinosHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCasinosQueryKey();

  const getCasinos = useGetCasinosHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetCasinosHook>>>> = ({ signal }) =>
    getCasinos(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetCasinosQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetCasinosHook>>>>;
export type GetCasinosQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary get the list of casinos
 */
export const useGetCasinos = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetCasinosHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetCasinosHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetCasinosQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get the settings configurations
 */
export const useGetConfigurationsHook = () => {
  const getConfigurations = useCustomInstance<ConfigurationsSchema>();

  return (signal?: AbortSignal) => {
    return getConfigurations({ url: `/settings/configurations`, method: 'get', signal });
  };
};

export const getGetConfigurationsQueryKey = () => [`/settings/configurations`] as const;

export const useGetConfigurationsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetConfigurationsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConfigurationsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConfigurationsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConfigurationsQueryKey();

  const getConfigurations = useGetConfigurationsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetConfigurationsHook>>>> = ({ signal }) =>
    getConfigurations(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetConfigurationsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetConfigurationsHook>>>
>;
export type GetConfigurationsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary get the settings configurations
 */
export const useGetConfigurations = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetConfigurationsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConfigurationsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetConfigurationsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary update the configuration for given id
 */
export const useUpdateConfigurationHook = () => {
  const updateConfiguration = useCustomInstance<ConfigurationsSchema>();

  return (configurationId: string, configurationArgumentsSchema: ConfigurationArgumentsSchema) => {
    return updateConfiguration({
      url: `/settings/configurations/${configurationId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: configurationArgumentsSchema,
    });
  };
};

export const useUpdateConfigurationMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateConfigurationHook>>>,
    TError,
    { configurationId: string; data: ConfigurationArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateConfigurationHook>>>,
  TError,
  { configurationId: string; data: ConfigurationArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateConfiguration = useUpdateConfigurationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateConfigurationHook>>>,
    { configurationId: string; data: ConfigurationArgumentsSchema }
  > = props => {
    const { configurationId, data } = props ?? {};

    return updateConfiguration(configurationId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateConfigurationHook>>>
>;
export type UpdateConfigurationMutationBody = ConfigurationArgumentsSchema;
export type UpdateConfigurationMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary update the configuration for given id
 */
export const useUpdateConfiguration = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateConfigurationHook>>>,
    TError,
    { configurationId: string; data: ConfigurationArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 * @summary get the bonus request simulation
 */
export const useGetBonusRequestSimulationHook = () => {
  const getBonusRequestSimulation = useCustomInstance<BonusRequestSimulationsSchema>();

  return (signal?: AbortSignal) => {
    return getBonusRequestSimulation({ url: `/settings/bonusrequestsimulation`, method: 'get', signal });
  };
};

export const getGetBonusRequestSimulationQueryKey = () => [`/settings/bonusrequestsimulation`] as const;

export const useGetBonusRequestSimulationQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBonusRequestSimulationHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBonusRequestSimulationHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBonusRequestSimulationHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBonusRequestSimulationQueryKey();

  const getBonusRequestSimulation = useGetBonusRequestSimulationHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetBonusRequestSimulationHook>>>> = ({
    signal,
  }) => getBonusRequestSimulation(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetBonusRequestSimulationQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetBonusRequestSimulationHook>>>
>;
export type GetBonusRequestSimulationQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | PreconditionFailedResponse
  | DefaultResponse;

/**
 * @deprecated
 * @summary get the bonus request simulation
 */
export const useGetBonusRequestSimulation = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetBonusRequestSimulationHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | PreconditionFailedResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetBonusRequestSimulationHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetBonusRequestSimulationQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Reveals a next digit to the players. Caution, possible large impact, use with care! (wich is why it's under 'Settings', rather than 'Contest')
 */
export const useRevealVaultDigitHook = () => {
  const revealVaultDigit = useCustomInstance<void>();

  return (vaultConfigId: number) => {
    return revealVaultDigit({ url: `/settings/revealVaultDigit/${vaultConfigId}`, method: 'put' });
  };
};

export const useRevealVaultDigitMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRevealVaultDigitHook>>>,
    TError,
    { vaultConfigId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRevealVaultDigitHook>>>,
  TError,
  { vaultConfigId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const revealVaultDigit = useRevealVaultDigitHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRevealVaultDigitHook>>>,
    { vaultConfigId: number }
  > = props => {
    const { vaultConfigId } = props ?? {};

    return revealVaultDigit(vaultConfigId);
  };

  return { mutationFn, ...mutationOptions };
};

export type RevealVaultDigitMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRevealVaultDigitHook>>>
>;

export type RevealVaultDigitMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Reveals a next digit to the players. Caution, possible large impact, use with care! (wich is why it's under 'Settings', rather than 'Contest')
 */
export const useRevealVaultDigit = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRevealVaultDigitHook>>>,
    TError,
    { vaultConfigId: number },
    TContext
  >;
}) => {
  const mutationOptions = useRevealVaultDigitMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get a full list of countries in the world
 */
export const useGetAllCountriesHook = () => {
  const getAllCountries = useCustomInstance<CountryListSchema>();

  return (signal?: AbortSignal) => {
    return getAllCountries({ url: `/settings/countries`, method: 'get', signal });
  };
};

export const getGetAllCountriesQueryKey = () => [`/settings/countries`] as const;

export const useGetAllCountriesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAllCountriesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllCountriesHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllCountriesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllCountriesQueryKey();

  const getAllCountries = useGetAllCountriesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllCountriesHook>>>> = ({ signal }) =>
    getAllCountries(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAllCountriesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAllCountriesHook>>>>;
export type GetAllCountriesQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get a full list of countries in the world
 */
export const useGetAllCountries = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAllCountriesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllCountriesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAllCountriesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the list of promotion name configurations
 */
export const useGetPromotionNameConfigurationsHook = () => {
  const getPromotionNameConfigurations = useCustomInstance<PromotionNameConfigurationsSchema>();

  return (signal?: AbortSignal) => {
    return getPromotionNameConfigurations({ url: `/settings/promotions/name`, method: 'get', signal });
  };
};

export const getGetPromotionNameConfigurationsQueryKey = () => [`/settings/promotions/name`] as const;

export const useGetPromotionNameConfigurationsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPromotionNameConfigurationsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPromotionNameConfigurationsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPromotionNameConfigurationsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPromotionNameConfigurationsQueryKey();

  const getPromotionNameConfigurations = useGetPromotionNameConfigurationsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetPromotionNameConfigurationsHook>>>> = ({
    signal,
  }) => getPromotionNameConfigurations(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPromotionNameConfigurationsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPromotionNameConfigurationsHook>>>
>;
export type GetPromotionNameConfigurationsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the list of promotion name configurations
 */
export const useGetPromotionNameConfigurations = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPromotionNameConfigurationsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetPromotionNameConfigurationsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPromotionNameConfigurationsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create/update given promotion name configuration
 */
export const useUpdatePromotionNameConfigurationsHook = () => {
  const updatePromotionNameConfigurations = useCustomInstance<void>();

  return (updatePromotionNameConfigurationSchema: UpdatePromotionNameConfigurationSchema) => {
    return updatePromotionNameConfigurations({
      url: `/settings/promotions/name`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updatePromotionNameConfigurationSchema,
    });
  };
};

export const useUpdatePromotionNameConfigurationsMutationOptions = <
  TError = PromotionNameConfigurationBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdatePromotionNameConfigurationsHook>>>,
    TError,
    { data: UpdatePromotionNameConfigurationSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdatePromotionNameConfigurationsHook>>>,
  TError,
  { data: UpdatePromotionNameConfigurationSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updatePromotionNameConfigurations = useUpdatePromotionNameConfigurationsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdatePromotionNameConfigurationsHook>>>,
    { data: UpdatePromotionNameConfigurationSchema }
  > = props => {
    const { data } = props ?? {};

    return updatePromotionNameConfigurations(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePromotionNameConfigurationsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdatePromotionNameConfigurationsHook>>>
>;
export type UpdatePromotionNameConfigurationsMutationBody = UpdatePromotionNameConfigurationSchema;
export type UpdatePromotionNameConfigurationsMutationError =
  | PromotionNameConfigurationBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Create/update given promotion name configuration
 */
export const useUpdatePromotionNameConfigurations = <
  TError = PromotionNameConfigurationBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdatePromotionNameConfigurationsHook>>>,
    TError,
    { data: UpdatePromotionNameConfigurationSchema },
    TContext
  >;
}) => {
  const mutationOptions = useUpdatePromotionNameConfigurationsMutationOptions(options);

  return useMutation(mutationOptions);
};
