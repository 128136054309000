import OperatorDetails from 'src/pages/GameServer/OperatorDetails';

import { BaseRoute } from '../RouteInterfaces';

export const operatorRoutes: Array<BaseRoute> = [
  {
    path: 'operator',
    name: 'Operator',
    element: OperatorDetails,
    hiddenLink: false,
  },
];

export default operatorRoutes;
