const GAMING_QUESTIONS = [
  {
    header: 'Q1',
    question: 'responsibleGaming.testResponses.titles.question1',
    field: 'q1Response',
  },
  {
    header: 'Q2',
    question: 'responsibleGaming.testResponses.titles.question2',
    field: 'q2Response',
  },
  {
    header: 'Q3',
    question: 'responsibleGaming.testResponses.titles.question3',
    field: 'q3Response',
  },
  {
    header: 'Q4',
    question: 'responsibleGaming.testResponses.titles.question4',
    field: 'q4Response',
  },
  {
    header: 'Q5',
    question: 'responsibleGaming.testResponses.titles.question5',
    field: 'q5Response',
  },
  {
    header: 'Q6',
    question: 'responsibleGaming.testResponses.titles.question6',
    field: 'q6Response',
  },
  {
    header: 'Q7',
    question: 'responsibleGaming.testResponses.titles.question7',
    field: 'q7Response',
  },
  {
    header: 'Q8',
    question: 'responsibleGaming.testResponses.titles.question8',
    field: 'q8Response',
  },
  {
    header: 'Q9',
    question: 'responsibleGaming.testResponses.titles.question9',
    field: 'q9Response',
  },
  {
    header: 'Q10',
    question: 'responsibleGaming.testResponses.titles.question10',
    field: 'q10Response',
  },
];

export { GAMING_QUESTIONS };
