import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button } from '@mui/material';

import { usePermission } from '../../../../app/hooks';
import { GlobalBoostsCreateForm } from './components';
import GlobalBoostsOverviewTable from './globalBoostsOverviewTable';

const GlobalBoostsPage = () => {
  const { t } = useTranslation();
  const canCreateGlobalBoosts = usePermission(OnlineCasinoPermissions.createGlobalBoost);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {canCreateGlobalBoosts ? (
        <>
          <Box>
            <Button
              variant="contained"
              size="medium"
              sx={{ mb: 4 }}
              startIcon={<FontAwesomeIcon icon={faAdd} />}
              onClick={() => setIsOpen(true)}
            >
              {t('boost.global.create.button')}
            </Button>
          </Box>
          <GlobalBoostsCreateForm
            onClose={() => {
              setIsOpen(false);
            }}
            open={isOpen}
          />
        </>
      ) : null}
      <GlobalBoostsOverviewTable />
    </>
  );
};

export default GlobalBoostsPage;
