/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  DefaultResponse,
  ERankSchema,
  GetLoyaltyRankUsersParams,
  LoyaltyRankDistributionsSchema,
  LoyaltyRankUsersBadRequestResponse,
  LoyaltyTopSaversSchema,
  LoyaltyTotalBalancesSchema,
  NotFoundResponse,
  PagedLoyaltyUsersSchema,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get loyalty rank distributions
 */
export const useGetLoyaltyRankDistributionsHook = () => {
  const getLoyaltyRankDistributions = useCustomInstance<LoyaltyRankDistributionsSchema>();

  return (signal?: AbortSignal) => {
    return getLoyaltyRankDistributions({ url: `/loyalty/reporting/v2/ranks`, method: 'get', signal });
  };
};

export const getGetLoyaltyRankDistributionsQueryKey = () => [`/loyalty/reporting/v2/ranks`] as const;

export const useGetLoyaltyRankDistributionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoyaltyRankDistributionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyRankDistributionsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyRankDistributionsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLoyaltyRankDistributionsQueryKey();

  const getLoyaltyRankDistributions = useGetLoyaltyRankDistributionsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyRankDistributionsHook>>>> = ({
    signal,
  }) => getLoyaltyRankDistributions(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLoyaltyRankDistributionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLoyaltyRankDistributionsHook>>>
>;
export type GetLoyaltyRankDistributionsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get loyalty rank distributions
 */
export const useGetLoyaltyRankDistributions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoyaltyRankDistributionsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyRankDistributionsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetLoyaltyRankDistributionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get loyalty rank users
 */
export const useGetLoyaltyRankUsersHook = () => {
  const getLoyaltyRankUsers = useCustomInstance<PagedLoyaltyUsersSchema>();

  return (rankName: ERankSchema, params?: GetLoyaltyRankUsersParams, signal?: AbortSignal) => {
    return getLoyaltyRankUsers({ url: `/loyalty/reporting/v2/ranks/${rankName}`, method: 'get', params, signal });
  };
};

export const getGetLoyaltyRankUsersQueryKey = (rankName: ERankSchema, params?: GetLoyaltyRankUsersParams) =>
  [`/loyalty/reporting/v2/ranks/${rankName}`, ...(params ? [params] : [])] as const;

export const useGetLoyaltyRankUsersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoyaltyRankUsersHook>>>,
  TError = LoyaltyRankUsersBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  rankName: ERankSchema,
  params?: GetLoyaltyRankUsersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyRankUsersHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyRankUsersHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLoyaltyRankUsersQueryKey(rankName, params);

  const getLoyaltyRankUsers = useGetLoyaltyRankUsersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyRankUsersHook>>>> = ({ signal }) =>
    getLoyaltyRankUsers(rankName, params, signal);

  return { queryKey, queryFn, enabled: !!rankName, ...queryOptions };
};

export type GetLoyaltyRankUsersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLoyaltyRankUsersHook>>>
>;
export type GetLoyaltyRankUsersQueryError =
  | LoyaltyRankUsersBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get loyalty rank users
 */
export const useGetLoyaltyRankUsers = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoyaltyRankUsersHook>>>,
  TError = LoyaltyRankUsersBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  rankName: ERankSchema,
  params?: GetLoyaltyRankUsersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyRankUsersHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetLoyaltyRankUsersQueryOptions(rankName, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get loyalty top 50 savers
 */
export const useGetLoyaltyTopSaversHook = () => {
  const getLoyaltyTopSavers = useCustomInstance<LoyaltyTopSaversSchema>();

  return (signal?: AbortSignal) => {
    return getLoyaltyTopSavers({ url: `/loyalty/reporting/v2/top-savers`, method: 'get', signal });
  };
};

export const getGetLoyaltyTopSaversQueryKey = () => [`/loyalty/reporting/v2/top-savers`] as const;

export const useGetLoyaltyTopSaversQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoyaltyTopSaversHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyTopSaversHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyTopSaversHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLoyaltyTopSaversQueryKey();

  const getLoyaltyTopSavers = useGetLoyaltyTopSaversHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyTopSaversHook>>>> = ({ signal }) =>
    getLoyaltyTopSavers(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLoyaltyTopSaversQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLoyaltyTopSaversHook>>>
>;
export type GetLoyaltyTopSaversQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get loyalty top 50 savers
 */
export const useGetLoyaltyTopSavers = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoyaltyTopSaversHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyTopSaversHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetLoyaltyTopSaversQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the total balances of all users
 */
export const useGetLoyaltyTotalBalancesHook = () => {
  const getLoyaltyTotalBalances = useCustomInstance<LoyaltyTotalBalancesSchema>();

  return (signal?: AbortSignal) => {
    return getLoyaltyTotalBalances({ url: `/loyalty/reporting/v2/total-balances`, method: 'get', signal });
  };
};

export const getGetLoyaltyTotalBalancesQueryKey = () => [`/loyalty/reporting/v2/total-balances`] as const;

export const useGetLoyaltyTotalBalancesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoyaltyTotalBalancesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyTotalBalancesHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyTotalBalancesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetLoyaltyTotalBalancesQueryKey();

  const getLoyaltyTotalBalances = useGetLoyaltyTotalBalancesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyTotalBalancesHook>>>> = ({ signal }) =>
    getLoyaltyTotalBalances(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetLoyaltyTotalBalancesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetLoyaltyTotalBalancesHook>>>
>;
export type GetLoyaltyTotalBalancesQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the total balances of all users
 */
export const useGetLoyaltyTotalBalances = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetLoyaltyTotalBalancesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetLoyaltyTotalBalancesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetLoyaltyTotalBalancesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
