import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationMessageSchema, RegistrationMessagesSchema } from '@greenisland-api';
import {
  DataGridProProps,
  GridColumns,
  GridToolbar,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';

import { DataGridContainer, DataGridPagination, StyledDataGrid } from '@greenisland-common/components/atoms';

import { formatDateTime } from '@greenisland-common/helpers';

import RegistrationMessageDetailPanel from './RegistrationMessageDetailPanel';

interface Props {
  messages: RegistrationMessagesSchema;
  total: number;
  isLoading: boolean;
  pageNumber: number;
  pageSize: number;
  changePage: Dispatch<SetStateAction<number>>;
  changePageSize: Dispatch<SetStateAction<number>>;
}

const RegistrationMessagesTable = ({
  messages,
  total,
  isLoading,
  pageNumber,
  pageSize,
  changePage,
  changePageSize,
}: Props) => {
  const { t } = useTranslation();

  const messagesColumns = useMemo<GridColumns>(() => {
    const headers = [
      { field: 'messageId', headerName: t('messageId'), flex: 2, minWidth: 270 },
      {
        headerName: t('timestamp'),
        field: 'timestamp',
        type: 'date',
        valueGetter: ({ value }: GridValueGetterParams<number>) => value && new Date(value * 1000),
        valueFormatter: ({ value }: GridValueFormatterParams<Date>) => formatDateTime(value, false),
        flex: 1,
        minWidth: 150,
      },
      {
        headerName: t('type'),
        field: 'type',
        filter: 'select',
        type: 'singleSelect',
        valueOptions: [...new Set(messages.map(w => w.type))],
        flex: 0.5,
        minWidth: 150,
      },
      { field: 'request', headerName: t('tasks.verifications.registrationMessage.request') },
      { field: 'response', headerName: t('tasks.verifications.registrationMessage.response') },
    ];

    return headers;
  }, [messages, t]);

  const getDetailPanelContent: DataGridProProps['getDetailPanelContent'] = useCallback(
    ({ row }: { row: RegistrationMessageSchema }) => <RegistrationMessageDetailPanel registrationMessage={row} />,
    []
  );

  const getDetailPanelHeight = useCallback(() => 300, []);

  return (
    <DataGridContainer>
      <StyledDataGrid
        density="compact"
        loading={isLoading}
        rows={messages}
        rowCount={total}
        columns={messagesColumns}
        showColumnRightBorder={false}
        page={pageNumber}
        pageSize={pageSize}
        onPageChange={newPage => changePage(newPage)}
        onPageSizeChange={newPageSize => changePageSize(newPageSize)}
        rowsPerPageOptions={[10, 50, 100]}
        paginationMode="server"
        pagination
        hideFooterPagination={isLoading}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        initialState={{
          columns: {
            columnVisibilityModel: {
              request: false,
              response: false,
            },
          },
        }}
        components={{
          Toolbar: GridToolbar,
          Pagination: DataGridPagination,
        }}
        disableColumnPinning
        disableColumnResize
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
      />
    </DataGridContainer>
  );
};

export default RegistrationMessagesTable;
