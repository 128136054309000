import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  useGetAgentRoleDetails,
  useGetListAgentRoles,
  useGetPermissions,
  useUpdateAgentRole,
} from '@greenisland-store/authorization';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import CheckBox from '@greenisland-common/components/atoms/Checkbox/Checkbox';
import TransferList from '@greenisland-common/components/organisms/TransferList';

import {
  AgentItem,
  formatPermissionAgentData,
  formatRoleAgentData,
  generateAgentItems,
} from './Forms/helpers/AgentFormContext';

type FormData = {
  name: string;
  crossEntityRole: boolean;
  permissions: AgentItem[];
  agentRoles: AgentItem[];
};

const SetRole = () => {
  const { t } = useTranslation();
  const { agentRoleId = '' } = useParams();
  const { data: agentPermissions } = useGetPermissions();
  const { data: agentRoles } = useGetListAgentRoles();
  const { data: agentRole } = useGetAgentRoleDetails(agentRoleId);
  const { mutate: setRole } = useUpdateAgentRole();
  const methods = useForm<FormData>({
    defaultValues: {
      crossEntityRole: (agentRoleId && agentRole?.crossEntityRole) || false,
      name: agentRole?.roleName,
    },
  });
  const { register, handleSubmit, control, reset, setValue, watch } = methods;

  const watchPermissionsData = watch('permissions');
  const watchAgentRolesData = watch('agentRoles');

  useEffect(() => {
    if (agentRoleId && agentRole?.permissions)
      setValue('permissions', generateAgentItems(agentRole?.permissions, formatPermissionAgentData));
    if (agentRoleId && agentRole?.agentRoles)
      setValue('agentRoles', generateAgentItems(agentRole?.agentRoles, formatRoleAgentData));
  }, [agentRole, agentRoleId, setValue]);

  useEffect(() => {
    if (agentRoleId && agentRole?.roleName) setValue('name', agentRole?.roleName);
    if (agentRoleId && agentRole?.crossEntityRole) setValue('crossEntityRole', agentRole?.crossEntityRole);
  }, [agentRole, agentRoleId, setValue]);

  const onSubmit = (data: FormData) => {
    const permissionIds = watchPermissionsData.map((perm: AgentItem) => perm.id);
    const agentRoleIds = watchAgentRolesData.map((role: AgentItem) => role.id);
    setRole({
      agentRoleId: agentRoleId,
      data: { roleName: data.name, permissionIds, agentRoleIds, crossEntityRole: data.crossEntityRole },
    });
    if (!agentRoleId) reset();
  };

  if ((agentRoleId && agentRoleId !== agentRole?.roleId) || !agentPermissions || !agentRoles) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <TextField label={t('name')} fullWidth size="small" name="name" inputRef={register({ required: true })} />
              <CheckBox name="crossEntityRole" label={t('crossEntityRole')} />
              {agentRoleId ? (
                <>
                  <Controller
                    control={control}
                    name="permissions"
                    render={({ name }) => {
                      return (
                        <TransferList
                          control={control}
                          name={name}
                          leftTitle={t('availablePermissions')}
                          rightTitle={t('selectedPermissions')}
                          fallbackText={t('noPermissionsFound')}
                          allItems={generateAgentItems(agentPermissions, formatPermissionAgentData)}
                        />
                      );
                    }}
                  />
                  <Controller
                    control={control}
                    name="agentRoles"
                    render={({ name }) => {
                      return (
                        <TransferList
                          control={control}
                          name={name}
                          leftTitle={t('availableRoles')}
                          rightTitle={t('selectedRoles')}
                          fallbackText={t('noRolesFound')}
                          allItems={generateAgentItems(agentRoles, formatRoleAgentData)}
                        />
                      );
                    }}
                  />
                </>
              ) : (
                <Typography>{t('createNewRoleInfo')}</Typography>
              )}
            </Stack>
          </CardContent>
          <CardActions>
            <Button fullWidth color="primary" variant="contained" size="small" type="submit">
              {t('save')}
            </Button>
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};

export default SetRole;
