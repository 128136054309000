import { Outlet } from 'react-router';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { EWithdrawalTypeSchema } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import AgentTasks from 'src/pages/OnlineCasino/Tasks/AgentTasks/AgentTasks';

import Complaint from '../../pages/OnlineCasino/Tasks/Complaints/Complaint';
import Complaints from '../../pages/OnlineCasino/Tasks/Complaints/Complaints';
import DepositManually from '../../pages/OnlineCasino/Tasks/Deposits/DepositManually';
import KYCMonitor from '../../pages/OnlineCasino/Tasks/KYC/KYCMonitor';
import KYCUserMatches from '../../pages/OnlineCasino/Tasks/KYC/KYCUserMatches';
import UserLimits from '../../pages/OnlineCasino/Tasks/userLimits';
import Verifications from '../../pages/OnlineCasino/Tasks/Verifications/Verifications';
import WithdrawalsExports from '../../pages/OnlineCasino/Tasks/WithdrawalsV2/Exports/WithdrawalsExports';
import InCasino from '../../pages/OnlineCasino/Tasks/WithdrawalsV2/InCasino/InCasino';
import WithdrawalProvider from '../../pages/OnlineCasino/Tasks/WithdrawalsV2/WithdrawalProvider';
import Withdrawals from '../../pages/OnlineCasino/Tasks/WithdrawalsV2/Withdrawals';
import WithdrawManually from '../../pages/OnlineCasino/Tasks/WithdrawalsV2/WithdrawManually/WithdrawManually';
import { BadgeType } from '../components/BadgeCounter';
import { BaseRoute } from '../RouteInterfaces';

export const WithdrawalsViews: Array<BaseRoute> = Object.values(EWithdrawalTypeSchema)
  .filter(type => type !== EWithdrawalTypeSchema.unknown)
  .map(type => ({
    path: type.toLowerCase(),
    name: `To ${type}`,
    element: WithdrawalProvider,
    permissions: OnlineCasinoPermissions.getWithdrawalsBankAccount,
  }));

const verificationsViews: Array<BaseRoute> = [
  {
    path: 'verifications',
    name: 'Verifications',
    collapse: false,
    element: Verifications,
    mini: 'V',
    permissions: OnlineCasinoPermissions.getVerifications,
    badgeType: BadgeType.VERIFICATIONS,
  },
];

const manuallyWithdrawals: Array<BaseRoute> = [
  {
    path: 'manualwithdrawal/:userId',
    name: 'Withdraw Manually',
    element: WithdrawManually,
    mini: 'WM',
    hiddenLink: true,
    permissions: OnlineCasinoPermissions.withdrawManually,
    strictPermissions: true,
  },
];

const manuallyDeposits: Array<BaseRoute> = [
  {
    path: 'manualdeposit/:userId',
    name: 'Deposit Manually',
    element: DepositManually,
    mini: 'DM',
    hiddenLink: true,
    permissions: OnlineCasinoPermissions.depositManually,
    strictPermissions: true,
  },
];

const complaintsRoutes: Array<BaseRoute> = [
  {
    path: 'complaints',
    name: 'Complaints',
    element: Complaints,
    permissions: OnlineCasinoPermissions.getAllComplaintTickets,
    mini: 'CP',
  },
  {
    path: 'complaints/:complaintId',
    name: 'Complaint',
    element: Complaint,
    permissions: OnlineCasinoPermissions.getComplaintTicket,
    mini: 'CP',
    hiddenLink: true,
  },
];

const kycRoutes: Array<BaseRoute> = [
  {
    path: 'kyc/monitor',
    name: 'KYC Monitor',
    element: KYCMonitor,
    permissions: OnlineCasinoPermissions.getIndividuals,
    mini: 'KYC',
    featureFlag: 'isKycEnabled',
  },
  {
    path: 'kyc/profile/:individualId',
    name: 'KYC Profile',
    element: KYCUserMatches,
    permissions: OnlineCasinoPermissions.getMatches,
    mini: 'KYC',
    hiddenLink: true,
    featureFlag: 'isKycEnabled',
  },
];

const userLimitsRoutes: Array<BaseRoute> = [
  {
    path: 'user-limits',
    name: 'User Limits',
    element: UserLimits,
    permissions: OnlineCasinoPermissions.getDepositLimitsThatRequireManualApproval,
    mini: 'UL',
  },
];

export const taskRoutes: Array<BaseRoute> = [
  {
    path: 'tasks',
    name: 'Tasks',
    icon: faTasks,
    collapse: true,
    permissions: [
      OnlineCasinoPermissions.getInterventionNeeded,
      OnlineCasinoPermissions.getVerifications,
      OnlineCasinoPermissions.getWithdrawalsByType,
      OnlineCasinoPermissions.withdrawManually,
      OnlineCasinoPermissions.getWithdrawalsInCasino,
      OnlineCasinoPermissions.getAllComplaintTickets,
    ],
    element: Outlet,
    children: [
      {
        path: 'agent-tasks',
        name: 'Agent Tasks',
        element: AgentTasks,
        permissions: OnlineCasinoPermissions.getInterventionNeeded,
        mini: 'IN',
        badgeType: BadgeType.AGENT_TASKS,
      },
      ...verificationsViews,
      {
        path: 'withdrawals/exports',
        name: 'Withdrawals Exports',
        element: WithdrawalsExports,
        hiddenLink: true,
        permissions: OnlineCasinoPermissions.getWithdrawalsExports,
      },
      {
        path: 'withdrawals',
        name: 'Withdrawals',
        element: Withdrawals,
        mini: 'W',
        permissions: [
          OnlineCasinoPermissions.getWithdrawalsBankAccount,
          OnlineCasinoPermissions.getWithdrawalsCasino,
          OnlineCasinoPermissions.getWithdrawalsNetteller,
          OnlineCasinoPermissions.getWithdrawalsPaypal,
          OnlineCasinoPermissions.getWithdrawalsSkrill,
        ],
        children: WithdrawalsViews,
      },
      {
        path: 'in-casino',
        name: 'Withdrawals In Casino',
        element: InCasino,
        mini: 'WIC',
        permissions: OnlineCasinoPermissions.getWithdrawalsInCasino,
        featureFlag: 'isCasinoTransactionsEnabled',
      },
      ...manuallyWithdrawals,
      ...manuallyDeposits,
      ...complaintsRoutes,
      ...kycRoutes,
      ...userLimitsRoutes,
    ],
  },
];

export default taskRoutes;
