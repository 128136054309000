import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { actionCreators, IEntityState } from '@greenisland/stores';
import { useAppDispatch, useAppSelector } from '@greenisland-core/store';
import { List, ListItem, styled, Typography } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  '& h1': {
    color: theme.palette.primary.main,
    fontWeight: 900,
  },
  '& h4': {
    textAlign: 'center',
  },
}));

const StyledListItem = styled(ListItem)({
  display: 'flex',
  justifyContent: 'center',
});

const HelpTextContainer = styled('div')({
  padding: 20,
  boxSizing: 'border-box',
});

const Link = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
}));

const Oops = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const agentEntities = useAppSelector(state =>
    state.authentication.data ? state.authentication.data?.startupAgent?.entities : []
  );
  const setProduct: (ids: IEntityState) => void = async (ids: IEntityState) => dispatch(actionCreators.setProduct(ids));
  const visitLink = (entity: string, product: string) =>
    setProduct({ selectedEntity: entity, selectedProduct: product });

  return (
    <Container>
      <Typography variant="h1">OOPS!</Typography>
      <HelpTextContainer>
        <Typography variant="h5">{t('oopsError')}</Typography>
        <Typography variant="h5">{t('oopsEntityAccess')}</Typography>
      </HelpTextContainer>
      {agentEntities &&
        agentEntities.map(({ entityName, products }, entityKey) => (
          <Fragment key={entityKey}>
            <Typography variant="h5">{entityName}</Typography>
            <List>
              {products.map((product, key) => {
                const { productName, productType } = product;
                return (
                  <StyledListItem key={key}>
                    <div onClick={() => visitLink(entityName, productName)}>
                      <Link to={`/${entityName}/${productName}`}>
                        {productName} - {productType}
                      </Link>
                    </div>
                  </StyledListItem>
                );
              })}
            </List>
          </Fragment>
        ))}
    </Container>
  );
};

export default Oops;
