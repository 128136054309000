import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { GameStudioSchema, useGetGameStudios } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, Stack } from '@mui/material';

import { DataGridContainer, FontAwesomeIcon, StyledDataGrid } from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import useGameStudioColumns from './hooks/useGameStudioColumns';
import { usePermission } from '../../../../../app/hooks';
import { GameStudioDialog } from './components';

type Row = GameStudioSchema;

const Studios = () => {
  const { t } = useTranslation();

  const canCreateGameStudio = usePermission(OnlineCasinoPermissions.addGameStudio);
  const canEditGameStudio = usePermission(OnlineCasinoPermissions.updateGameStudio);
  const canReadGameStudios = usePermission(OnlineCasinoPermissions.getGameStudios);

  const { data: gameStudios, isLoading, isError, refetch } = useGetGameStudios();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedStudioId, setSelectedStudioId] = useState<string | undefined>(undefined);
  const selectedStudio = useMemo(
    () => gameStudios?.find(({ gameStudioId }) => gameStudioId === selectedStudioId),
    [gameStudios, selectedStudioId]
  );

  const handleClickEdit = useCallback(
    (categoryId?: string) => {
      setSelectedStudioId(categoryId);
      setIsOpen(true);
    },
    [setSelectedStudioId, setIsOpen]
  );

  const rows = useMemo(
    () => gameStudios?.map<Row>(studio => ({ ...studio, id: studio.gameStudioId })) ?? [],
    [gameStudios]
  );

  const columns = useGameStudioColumns(handleClickEdit);

  return canReadGameStudios ? (
    <>
      <Stack spacing={2}>
        {canCreateGameStudio ? (
          <div>
            <Button
              variant="text"
              startIcon={<FontAwesomeIcon icon={faAdd} />}
              onClick={() => handleClickEdit(undefined)}
            >
              {t('content.games.studios.add')}
            </Button>
          </div>
        ) : null}
        {!isError ? (
          <DataGridContainer>
            <StyledDataGrid
              density="compact"
              autoHeight
              loading={isLoading}
              rows={rows}
              columns={columns}
              pagination={false}
            />
          </DataGridContainer>
        ) : (
          <ErrorState errorMessage={t('content.games.studios.loadingError')} retryAction={() => refetch()} />
        )}
      </Stack>
      {canEditGameStudio ? (
        <GameStudioDialog
          onClose={() => {
            setIsOpen(false);
            setSelectedStudioId(undefined);
          }}
          open={isOpen}
          studio={selectedStudio}
        />
      ) : null}
    </>
  ) : null;
};

export default Studios;
