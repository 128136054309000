/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * The type of update request. Either an update (e.g.g, the value of the limit will change) or cancel (the limit will be cancelled).
 */
export type EUserLimitV2UpdateRequestTypeSchema =
  (typeof EUserLimitV2UpdateRequestTypeSchema)[keyof typeof EUserLimitV2UpdateRequestTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EUserLimitV2UpdateRequestTypeSchema = {
  update: 'update',
  cancel: 'cancel',
} as const;
