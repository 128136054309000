import { EPlayingBreakReasonTypeV2Schema, EPlayingBreakSourceSchema } from '@greenisland-api';

export const DEFAULT_FILTER_TYPE = 'All';
export const NO_REASON_FILTER_TYPE = 'No Reason';

export enum FILTER_TYPE {
  REASON = 'REASON',
  SOURCE = 'SOURCE',
}

const useGetPlayingBreakFilterOptions = (type: FILTER_TYPE) => {
  let options = [];

  if (type === FILTER_TYPE.REASON) {
    options = [
      ...Object.values(EPlayingBreakReasonTypeV2Schema).map(option =>
        option === EPlayingBreakReasonTypeV2Schema.none ? NO_REASON_FILTER_TYPE : option
      ),
    ];
  } else {
    options = [...Object.values(EPlayingBreakSourceSchema).map(option => option)];
  }

  const sortedOptions = options?.sort((a, b) => a.localeCompare(b));

  return [DEFAULT_FILTER_TYPE, ...sortedOptions];
};

export default useGetPlayingBreakFilterOptions;
