import { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faAdd, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, capitalize, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { FontAwesomeIcon, SelectInput } from '@greenisland-common/components/atoms';
import Input from '@greenisland-common/components/molecules/Input';

import { useGetTournamentExtraRequirementsConditionValues } from '../helpers/tournamentConfigFormHooks';
import { TournamentConfigurationMode } from '../helpers/tournamentConfigFormInterfaces';
import TournamentConfigExtraSubRequirements from './TournamentConfigExtraSubRequirements';

interface Props {
  mode: TournamentConfigurationMode;
}

const TournamentConfigExtraSettings = ({ mode }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { control, watch } = useFormContext();
  const extraRequirementsCondition = useGetTournamentExtraRequirementsConditionValues();

  const watchCompoundRequirements = watch('extraRequirements.compoundRequirements');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'extraRequirements.compoundRequirements',
  });

  const canModify = mode === TournamentConfigurationMode.ADD || mode === TournamentConfigurationMode.COPY;

  const [isActiveExtraSettings, setIsActiveExtraSettings] = useState(false);

  useEffect(() => {
    if (fields?.length === 0) {
      setIsActiveExtraSettings(false);
    } else if (watchCompoundRequirements?.length) {
      setIsActiveExtraSettings(true);
    }
  }, [fields?.length, watchCompoundRequirements?.length]);

  const addExtraRequirementHandler = useCallback(() => {
    if (!isActiveExtraSettings) {
      setIsActiveExtraSettings(true);
    }
    append({
      name: `${t('content.gameserver.tournamentconfiguration.form.other.requirement')}`,
    });
  }, [append, isActiveExtraSettings, t]);

  if (mode === TournamentConfigurationMode.EDIT && !watchCompoundRequirements?.length) {
    return null;
  }

  return (
    <Stack spacing={2} position="relative">
      <Card>
        <CardHeader title={t('content.gameserver.tournamentconfiguration.form.extrarequirementssettings.title')} />
        <Box
          sx={{
            [theme.breakpoints.up('xs')]: {
              position: 'initial',
              ml: 2,
              my: 1,
            },
            [theme.breakpoints.up('md')]: {
              position: 'absolute',
              right: 16,
              top: 8,
            },
          }}
        ></Box>
        <CardContent>
          {isActiveExtraSettings ? (
            <Box display="flex" flexDirection="column">
              <Input
                size="small"
                label={t('content.gameserver.tournamentconfiguration.extraRequirements.description')}
                control={control}
                type="text"
                name="extraRequirements.description"
                required
                rules={{ required: t('fieldIsRequired') }}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    width: '100%',
                    mb: 2,
                  },
                  [theme.breakpoints.up('md')]: {
                    width: '45%',
                  },
                }}
                defaultValue=""
                disabled={mode === TournamentConfigurationMode.EDIT}
              />
              <SelectInput
                size="small"
                name={'extraRequirements.condition'}
                label={t('content.gameserver.tournamentconfiguration.extraRequirements.condition')}
                options={extraRequirementsCondition}
                rules={{ required: t('fieldIsRequired') }}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    width: '100%',
                    mb: 2,
                  },
                  [theme.breakpoints.up('md')]: {
                    width: '45%',
                  },
                }}
                defaultValue=""
                disabled={mode === TournamentConfigurationMode.EDIT}
              />
              {fields?.map((entry, entryIndex) => {
                return (
                  <Box
                    key={entry.id}
                    px={4}
                    py={1}
                    mb={2}
                    sx={{
                      border: '1px solid #eee',
                      borderRadius: '4px',
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Controller
                        name={`extraRequirements.compoundRequirements[${entryIndex}].name`}
                        control={control}
                        defaultValue={entry?.name}
                        render={() => (
                          <Typography id={entry?.id} fontWeight={600} variant="body2" fontSize="large" my={2}>
                            {`${capitalize(t('content.gameserver.tournamentconfiguration.form.other.requirement'))} ${
                              entryIndex + 1
                            }`}
                          </Typography>
                        )}
                      />
                      {canModify && entryIndex !== 0 && (
                        <FontAwesomeIcon
                          icon={faTrash}
                          sx={{ marginLeft: 1, color: 'error.main', cursor: 'pointer' }}
                          onClick={() => remove(entryIndex)}
                        />
                      )}
                    </Box>
                    <TournamentConfigExtraSubRequirements entryIndex={entryIndex} mode={mode} />
                  </Box>
                );
              })}
            </Box>
          ) : null}
          {canModify && (
            <Button
              onClick={addExtraRequirementHandler}
              variant="contained"
              size="small"
              startIcon={<FontAwesomeIcon icon={faAdd} />}
            >
              {t('content.gameserver.tournamentconfiguration.form.extrarequirementssettings.addrequirement')}
            </Button>
          )}
        </CardContent>
      </Card>
    </Stack>
  );
};

export default TournamentConfigExtraSettings;
