/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  AddictionInfoSchema,
  BadRequestResponse,
  DefaultResponse,
  NotFoundResponse,
  SetAddictionCategoryManuallyArgumentsSchema,
  ThresholdExceededUsersSchema,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get the addiction category for the specified user
 */
export const useGetAddictionCategoryHook = () => {
  const getAddictionCategory = useCustomInstance<AddictionInfoSchema>();

  return (userId: string, signal?: AbortSignal) => {
    return getAddictionCategory({ url: `/addiction/${userId}/category`, method: 'get', signal });
  };
};

export const getGetAddictionCategoryQueryKey = (userId: string) => [`/addiction/${userId}/category`] as const;

export const useGetAddictionCategoryQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAddictionCategoryHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAddictionCategoryHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAddictionCategoryHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAddictionCategoryQueryKey(userId);

  const getAddictionCategory = useGetAddictionCategoryHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAddictionCategoryHook>>>> = ({ signal }) =>
    getAddictionCategory(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetAddictionCategoryQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAddictionCategoryHook>>>
>;
export type GetAddictionCategoryQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the addiction category for the specified user
 */
export const useGetAddictionCategory = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAddictionCategoryHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAddictionCategoryHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAddictionCategoryQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Set the addiction category for the specified user
 */
export const useSetAddictionCategoryManuallyHook = () => {
  const setAddictionCategoryManually = useCustomInstance<AddictionInfoSchema>();

  return (userId: string, setAddictionCategoryManuallyArgumentsSchema: SetAddictionCategoryManuallyArgumentsSchema) => {
    return setAddictionCategoryManually({
      url: `/addiction/${userId}/category`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: setAddictionCategoryManuallyArgumentsSchema,
    });
  };
};

export const useSetAddictionCategoryManuallyMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetAddictionCategoryManuallyHook>>>,
    TError,
    { userId: string; data: SetAddictionCategoryManuallyArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetAddictionCategoryManuallyHook>>>,
  TError,
  { userId: string; data: SetAddictionCategoryManuallyArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setAddictionCategoryManually = useSetAddictionCategoryManuallyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetAddictionCategoryManuallyHook>>>,
    { userId: string; data: SetAddictionCategoryManuallyArgumentsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return setAddictionCategoryManually(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetAddictionCategoryManuallyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetAddictionCategoryManuallyHook>>>
>;
export type SetAddictionCategoryManuallyMutationBody = SetAddictionCategoryManuallyArgumentsSchema;
export type SetAddictionCategoryManuallyMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Set the addiction category for the specified user
 */
export const useSetAddictionCategoryManually = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetAddictionCategoryManuallyHook>>>,
    TError,
    { userId: string; data: SetAddictionCategoryManuallyArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useSetAddictionCategoryManuallyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get the addiction threshold exceeded users for the given type.
 */
export const useGetAddictionThresholdExceededUsersHook = () => {
  const getAddictionThresholdExceededUsers = useCustomInstance<ThresholdExceededUsersSchema>();

  return (type: 'Deposit' | 'LoginSession', signal?: AbortSignal) => {
    return getAddictionThresholdExceededUsers({
      url: `/addiction/thresholdexceededusers/${type}`,
      method: 'get',
      signal,
    });
  };
};

export const getGetAddictionThresholdExceededUsersQueryKey = (type: 'Deposit' | 'LoginSession') =>
  [`/addiction/thresholdexceededusers/${type}`] as const;

export const useGetAddictionThresholdExceededUsersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAddictionThresholdExceededUsersHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  type: 'Deposit' | 'LoginSession',
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetAddictionThresholdExceededUsersHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAddictionThresholdExceededUsersHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAddictionThresholdExceededUsersQueryKey(type);

  const getAddictionThresholdExceededUsers = useGetAddictionThresholdExceededUsersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAddictionThresholdExceededUsersHook>>>> = ({
    signal,
  }) => getAddictionThresholdExceededUsers(type, signal);

  return { queryKey, queryFn, enabled: !!type, ...queryOptions };
};

export type GetAddictionThresholdExceededUsersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAddictionThresholdExceededUsersHook>>>
>;
export type GetAddictionThresholdExceededUsersQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the addiction threshold exceeded users for the given type.
 */
export const useGetAddictionThresholdExceededUsers = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAddictionThresholdExceededUsersHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  type: 'Deposit' | 'LoginSession',
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetAddictionThresholdExceededUsersHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAddictionThresholdExceededUsersQueryOptions(type, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
