import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Search } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, styled, TextField } from '@mui/material';

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    marginTop: '0px !important',
    '& label': { color: 'white' },
  },
  '& span': { color: 'white' },
}));

const Searchfield = (props: {
  handleSubmit: (displayname: string) => void;
  fetching: boolean;
  placeholder?: string;
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [inputvalue, setInputvalue] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputvalue(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setInputvalue('');
    props.handleSubmit(inputvalue);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        size="small"
        fullWidth
        value={inputvalue}
        onChange={handleChange}
        placeholder={props.placeholder || t('quickSearch')}
        InputProps={{
          endAdornment:
            props.fetching && pathname !== `${pathname}/lookup` ? (
              <Button variant="text" color="primary" sx={{ minWidth: 'auto', p: 0 }}>
                <CircularProgress color="primary" size={15} />
              </Button>
            ) : (
              <IconButton color="secondary" type="submit" size="small">
                <Search />
              </IconButton>
            ),
        }}
      />
    </Form>
  );
};

export default memo(Searchfield);
