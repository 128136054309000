import { URLSearchParamsInit } from 'react-router-dom';
import { State } from 'history';

export const createQueryString = <T extends Record<string, unknown>>(parameters: T) => {
  let queryString = '';
  Object.entries(parameters).forEach((entry: any) => {
    const [key, value] = entry;
    const formattedValue = typeof value === 'object' ? JSON.stringify(value) : value;
    if (value)
      queryString = queryString.concat(
        `${queryString ? '&' : ''}${encodeURIComponent(key.toLowerCase())}=${encodeURIComponent(formattedValue)}`
      );
  });
  return queryString;
};

export const replaceUrlQueryParameters = <T extends Record<string, unknown>>(
  parameters: T,
  setSearchParam: (nextInit: URLSearchParamsInit, navigateOptions?: { replace?: boolean; state?: State }) => void
) => {
  const queryString = createQueryString(parameters);
  setSearchParam(queryString, {
    replace: true,
  });
};

export default replaceUrlQueryParameters;
