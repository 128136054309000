/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  AwardContestQuestionSchema,
  BadRequestResponse,
  ContestSchema,
  DefaultResponse,
  GetVaultTokenBoostersParams,
  NotFoundResponse,
  UnauthorizedResponse,
  VaultConfigurationsSchema,
  VaultConfigurationUpdateSchema,
  VaultDetailsSchema,
  VaultTokenBoosterSchema,
  VaultTokenBoostersSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * This is used to award a user a contest question
 * @summary Award contest question
 */
export const useAwardContestQuestionHook = () => {
  const awardContestQuestion = useCustomInstance<ContestSchema>();

  return (awardContestQuestionSchema: AwardContestQuestionSchema) => {
    return awardContestQuestion({
      url: `/contests/awardcontestquestion`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: awardContestQuestionSchema,
    });
  };
};

export const useAwardContestQuestionMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAwardContestQuestionHook>>>,
    TError,
    { data: AwardContestQuestionSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAwardContestQuestionHook>>>,
  TError,
  { data: AwardContestQuestionSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const awardContestQuestion = useAwardContestQuestionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAwardContestQuestionHook>>>,
    { data: AwardContestQuestionSchema }
  > = props => {
    const { data } = props ?? {};

    return awardContestQuestion(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AwardContestQuestionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAwardContestQuestionHook>>>
>;
export type AwardContestQuestionMutationBody = AwardContestQuestionSchema;
export type AwardContestQuestionMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Award contest question
 */
export const useAwardContestQuestion = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAwardContestQuestionHook>>>,
    TError,
    { data: AwardContestQuestionSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAwardContestQuestionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets the vault details by id
 */
export const useGetVaultDetailsHook = () => {
  const getVaultDetails = useCustomInstance<VaultDetailsSchema>();

  return (vaultId: number, signal?: AbortSignal) => {
    return getVaultDetails({ url: `/contests/vault/${vaultId}`, method: 'get', signal });
  };
};

export const getGetVaultDetailsQueryKey = (vaultId: number) => [`/contests/vault/${vaultId}`] as const;

export const useGetVaultDetailsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetVaultDetailsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  vaultId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVaultDetailsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVaultDetailsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetVaultDetailsQueryKey(vaultId);

  const getVaultDetails = useGetVaultDetailsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetVaultDetailsHook>>>> = ({ signal }) =>
    getVaultDetails(vaultId, signal);

  return { queryKey, queryFn, enabled: !!vaultId, ...queryOptions };
};

export type GetVaultDetailsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetVaultDetailsHook>>>>;
export type GetVaultDetailsQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Gets the vault details by id
 */
export const useGetVaultDetails = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetVaultDetailsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  vaultId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVaultDetailsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetVaultDetailsQueryOptions(vaultId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Lists all vault configurations
 */
export const useGetVaultConfigurationsHook = () => {
  const getVaultConfigurations = useCustomInstance<VaultConfigurationsSchema>();

  return (signal?: AbortSignal) => {
    return getVaultConfigurations({ url: `/contests/vaultconfiguration`, method: 'get', signal });
  };
};

export const getGetVaultConfigurationsQueryKey = () => [`/contests/vaultconfiguration`] as const;

export const useGetVaultConfigurationsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetVaultConfigurationsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVaultConfigurationsHook>>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVaultConfigurationsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetVaultConfigurationsQueryKey();

  const getVaultConfigurations = useGetVaultConfigurationsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetVaultConfigurationsHook>>>> = ({ signal }) =>
    getVaultConfigurations(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetVaultConfigurationsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetVaultConfigurationsHook>>>
>;
export type GetVaultConfigurationsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Lists all vault configurations
 */
export const useGetVaultConfigurations = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetVaultConfigurationsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVaultConfigurationsHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetVaultConfigurationsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create a new vault configuration
 */
export const useAddVaultConfigurationHook = () => {
  const addVaultConfiguration = useCustomInstance<VaultConfigurationsSchema>();

  return (vaultConfigurationUpdateSchema: VaultConfigurationUpdateSchema) => {
    return addVaultConfiguration({
      url: `/contests/vaultconfiguration`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: vaultConfigurationUpdateSchema,
    });
  };
};

export const useAddVaultConfigurationMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddVaultConfigurationHook>>>,
    TError,
    { data: VaultConfigurationUpdateSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddVaultConfigurationHook>>>,
  TError,
  { data: VaultConfigurationUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addVaultConfiguration = useAddVaultConfigurationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddVaultConfigurationHook>>>,
    { data: VaultConfigurationUpdateSchema }
  > = props => {
    const { data } = props ?? {};

    return addVaultConfiguration(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddVaultConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddVaultConfigurationHook>>>
>;
export type AddVaultConfigurationMutationBody = VaultConfigurationUpdateSchema;
export type AddVaultConfigurationMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Create a new vault configuration
 */
export const useAddVaultConfiguration = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddVaultConfigurationHook>>>,
    TError,
    { data: VaultConfigurationUpdateSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddVaultConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Activates a vault configuration
 */
export const useActivateVaultConfigurationHook = () => {
  const activateVaultConfiguration = useCustomInstance<VaultConfigurationsSchema>();

  return (vaultConfigId: number) => {
    return activateVaultConfiguration({ url: `/contests/vaultconfiguration/${vaultConfigId}/activate`, method: 'put' });
  };
};

export const useActivateVaultConfigurationMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useActivateVaultConfigurationHook>>>,
    TError,
    { vaultConfigId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useActivateVaultConfigurationHook>>>,
  TError,
  { vaultConfigId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const activateVaultConfiguration = useActivateVaultConfigurationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useActivateVaultConfigurationHook>>>,
    { vaultConfigId: number }
  > = props => {
    const { vaultConfigId } = props ?? {};

    return activateVaultConfiguration(vaultConfigId);
  };

  return { mutationFn, ...mutationOptions };
};

export type ActivateVaultConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useActivateVaultConfigurationHook>>>
>;

export type ActivateVaultConfigurationMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Activates a vault configuration
 */
export const useActivateVaultConfiguration = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useActivateVaultConfigurationHook>>>,
    TError,
    { vaultConfigId: number },
    TContext
  >;
}) => {
  const mutationOptions = useActivateVaultConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deactivates a vault configuration
 */
export const useDeactivateVaultConfigurationHook = () => {
  const deactivateVaultConfiguration = useCustomInstance<VaultConfigurationsSchema>();

  return (vaultConfigId: number) => {
    return deactivateVaultConfiguration({
      url: `/contests/vaultconfiguration/${vaultConfigId}/deactivate`,
      method: 'put',
    });
  };
};

export const useDeactivateVaultConfigurationMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeactivateVaultConfigurationHook>>>,
    TError,
    { vaultConfigId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeactivateVaultConfigurationHook>>>,
  TError,
  { vaultConfigId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deactivateVaultConfiguration = useDeactivateVaultConfigurationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeactivateVaultConfigurationHook>>>,
    { vaultConfigId: number }
  > = props => {
    const { vaultConfigId } = props ?? {};

    return deactivateVaultConfiguration(vaultConfigId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeactivateVaultConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeactivateVaultConfigurationHook>>>
>;

export type DeactivateVaultConfigurationMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Deactivates a vault configuration
 */
export const useDeactivateVaultConfiguration = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeactivateVaultConfigurationHook>>>,
    TError,
    { vaultConfigId: number },
    TContext
  >;
}) => {
  const mutationOptions = useDeactivateVaultConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Lists all vault token boosters
 */
export const useGetVaultTokenBoostersHook = () => {
  const getVaultTokenBoosters = useCustomInstance<VaultTokenBoostersSchema>();

  return (params?: GetVaultTokenBoostersParams, signal?: AbortSignal) => {
    return getVaultTokenBoosters({ url: `/contests/vaulttokenbooster`, method: 'get', params, signal });
  };
};

export const getGetVaultTokenBoostersQueryKey = (params?: GetVaultTokenBoostersParams) =>
  [`/contests/vaulttokenbooster`, ...(params ? [params] : [])] as const;

export const useGetVaultTokenBoostersQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetVaultTokenBoostersHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetVaultTokenBoostersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVaultTokenBoostersHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVaultTokenBoostersHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetVaultTokenBoostersQueryKey(params);

  const getVaultTokenBoosters = useGetVaultTokenBoostersHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetVaultTokenBoostersHook>>>> = ({ signal }) =>
    getVaultTokenBoosters(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetVaultTokenBoostersQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetVaultTokenBoostersHook>>>
>;
export type GetVaultTokenBoostersQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Lists all vault token boosters
 */
export const useGetVaultTokenBoosters = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetVaultTokenBoostersHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetVaultTokenBoostersParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetVaultTokenBoostersHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetVaultTokenBoostersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Add a vault token booster
 */
export const useAddVaultTokenBoosterHook = () => {
  const addVaultTokenBooster = useCustomInstance<VaultTokenBoostersSchema>();

  return (vaultTokenBoosterSchema: VaultTokenBoosterSchema) => {
    return addVaultTokenBooster({
      url: `/contests/vaulttokenbooster`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: vaultTokenBoosterSchema,
    });
  };
};

export const useAddVaultTokenBoosterMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddVaultTokenBoosterHook>>>,
    TError,
    { data: VaultTokenBoosterSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddVaultTokenBoosterHook>>>,
  TError,
  { data: VaultTokenBoosterSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addVaultTokenBooster = useAddVaultTokenBoosterHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddVaultTokenBoosterHook>>>,
    { data: VaultTokenBoosterSchema }
  > = props => {
    const { data } = props ?? {};

    return addVaultTokenBooster(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddVaultTokenBoosterMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAddVaultTokenBoosterHook>>>
>;
export type AddVaultTokenBoosterMutationBody = VaultTokenBoosterSchema;
export type AddVaultTokenBoosterMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Add a vault token booster
 */
export const useAddVaultTokenBooster = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddVaultTokenBoosterHook>>>,
    TError,
    { data: VaultTokenBoosterSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddVaultTokenBoosterMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deactivates a vault token booster
 */
export const useDeactivateTokenBoosterHook = () => {
  const deactivateTokenBooster = useCustomInstance<void>();

  return (boosterId: number) => {
    return deactivateTokenBooster({ url: `/contests/vaulttokenbooster/${boosterId}`, method: 'delete' });
  };
};

export const useDeactivateTokenBoosterMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeactivateTokenBoosterHook>>>,
    TError,
    { boosterId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeactivateTokenBoosterHook>>>,
  TError,
  { boosterId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deactivateTokenBooster = useDeactivateTokenBoosterHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeactivateTokenBoosterHook>>>,
    { boosterId: number }
  > = props => {
    const { boosterId } = props ?? {};

    return deactivateTokenBooster(boosterId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeactivateTokenBoosterMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeactivateTokenBoosterHook>>>
>;

export type DeactivateTokenBoosterMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Deactivates a vault token booster
 */
export const useDeactivateTokenBooster = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeactivateTokenBoosterHook>>>,
    TError,
    { boosterId: number },
    TContext
  >;
}) => {
  const mutationOptions = useDeactivateTokenBoosterMutationOptions(options);

  return useMutation(mutationOptions);
};
