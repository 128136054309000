import escapeHtml from 'escape-html';

import { CustomElement, CustomText } from './types';
import { isCustomText, isFormattedText, isTextAlignType } from './typeUtils';

export const deserialize = (html: string): (CustomElement | CustomText)[] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const deserializeNode = (el: Node): (CustomElement | CustomText)[] => {
    // Handle text nodes directly
    if (el.nodeType === Node.TEXT_NODE) {
      return [{ text: el.textContent || '' }];
    }

    if (el.nodeType !== Node.ELEMENT_NODE) {
      return [];
    }

    const element = el as HTMLElement;
    const children = Array.from(element.childNodes).flatMap(deserializeNode).filter(isFormattedText);

    // Handle the different elements based on their tag names and styles
    switch (element.tagName) {
      case 'STRONG':
        return children.map(child => (isCustomText(child) ? { ...child, bold: true } : child));

      case 'BLOCKQUOTE':
        return [
          {
            type: 'block-quote',
            align: isTextAlignType(element.style.textAlign) ? element.style.textAlign : 'left',
            children,
          },
        ];

      case 'P':
        return [
          {
            type: 'paragraph',
            align: isTextAlignType(element.style.textAlign) ? element.style.textAlign : 'left',
            children,
          },
        ];

      case 'UL':
        return [
          {
            type: 'bulleted-list',
            children,
          },
        ];

      case 'OL':
        return [
          {
            type: 'numbered-list',
            children,
          },
        ];

      case 'LI':
        return [{ type: 'list-item', children }];

      case 'H1':
        return [
          {
            type: 'heading',
            level: 1,
            align: isTextAlignType(element.style.textAlign) ? element.style.textAlign : 'left',
            children,
          },
        ];

      case 'H2':
        return [
          {
            type: 'heading',
            level: 2,
            align: isTextAlignType(element.style.textAlign) ? element.style.textAlign : 'left',
            children,
          },
        ];

      case 'H3':
        return [
          {
            type: 'heading',
            level: 3,
            align: isTextAlignType(element.style.textAlign) ? element.style.textAlign : 'left',
            children,
          },
        ];

      case 'H4':
        return [
          {
            type: 'heading',
            level: 4,
            align: isTextAlignType(element.style.textAlign) ? element.style.textAlign : 'left',
            children,
          },
        ];

      case 'H5':
        return [
          {
            type: 'heading',
            level: 5,
            align: isTextAlignType(element.style.textAlign) ? element.style.textAlign : 'left',
            children,
          },
        ];

      case 'H6':
        return [
          {
            type: 'heading',
            level: 6,
            align: isTextAlignType(element.style.textAlign) ? element.style.textAlign : 'left',
            children,
          },
        ];

      case 'A':
        return [{ type: 'link', url: element.getAttribute('href') || '', children }];

      default:
        return children;
    }
  };

  return Array.from(doc.body.childNodes).flatMap(deserializeNode);
};

export const serialize = (node: CustomElement | CustomText): string => {
  if (isCustomText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }

  const children = node.children.map(n => serialize(n)).join('');
  const style = 'align' in node && node.align ? `text-align: ${node.align}` : '';

  switch (node.type) {
    case 'block-quote':
      return `<blockquote style="${style}">${children}</blockquote>`;
    case 'paragraph':
      return `<p style="${style}">${children}</p>`;
    case 'bulleted-list':
      return `<ul style="${style}">${children}</ul>`;
    case 'heading':
      switch (node.level) {
        case 1:
          return `<h1 style="${style}">${children}</h1>`;
        case 2:
          return `<h2 style="${style}">${children}</h2>`;
        case 3:
          return `<h3 style="${style}">${children}</h3>`;
        case 4:
          return `<h4 style="${style}">${children}</h4>`;
        case 5:
          return `<h5 style="${style}">${children}</h5>`;
        case 6:
          return `<h6 style="${style}">${children}</h6>`;
        default:
          return `<p style="${style}">${children}</p>`;
      }
    case 'list-item':
      return `<li style="${style}">${children}</li>`;
    case 'numbered-list':
      return `<ol style="${style}">${children}</ol>`;
    case 'link':
      return `<a style="${style}" href="${node.url}">${children}</a>`;
    default:
      return children;
  }
};
