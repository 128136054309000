/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EDepositMethodSchema = (typeof EDepositMethodSchema)[keyof typeof EDepositMethodSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EDepositMethodSchema = {
  Unknown: 'Unknown',
  Voucher: 'Voucher',
  Skrill: 'Skrill',
  Neteller: 'Neteller',
  Paypal: 'Paypal',
  BCMC: 'BCMC',
  'BCMC-Mobile': 'BCMC-Mobile',
  Trustly: 'Trustly',
  ING: 'ING',
  Belfius: 'Belfius',
  Visa: 'Visa',
  Mastercard: 'Mastercard',
  Maestro: 'Maestro',
  CB: 'CB',
  Sofort: 'Sofort',
  PaysafeCard: 'PaysafeCard',
  NeoSurf: 'NeoSurf',
  IDeal: 'IDeal',
  RapidTransfer: 'RapidTransfer',
  QRCode: 'QRCode',
  Cash: 'Cash',
  ManualWireTransfer: 'ManualWireTransfer',
  ManualCasinoTransfer: 'ManualCasinoTransfer',
  ManualWalletTransfer: 'ManualWalletTransfer',
  BizumRtp: 'BizumRtp',
  BizumRedirect: 'BizumRedirect',
} as const;
