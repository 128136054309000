import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';
import { getUnixTime, subWeeks } from 'date-fns';

import {
  CheckboxIcon,
  DataGridContainer,
  ErrorCard,
  FontAwesomeIcon,
  Link,
  NoData,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { formatCurrency, getDateTimeFromUnix, StatusSpan } from '../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../app/hooks';
import useAccountData from '../../../hooks/useAccountData';
import { DepositsParamsType } from '../Constants/Deposits';

interface Props {
  userId: string;
}

const LastDeposits = ({ userId }: Props) => {
  const { t } = useTranslation();
  const canReadDeposits = usePermission(OnlineCasinoPermissions.getOnlineDepositsForUser);
  const canReadDepositDetails = usePermission(OnlineCasinoPermissions.getDepositDetails);
  const { data: accountData, isLoading, isError, error, permission } = useAccountData(userId);

  if (!permission) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={300} />;
  if (isError || !accountData) return <ErrorCard>{error?.message}</ErrorCard>;

  const headers: GridColumns = [
    {
      headerName: capitalize(t('startedAt')),
      field: 'startedAt',
      valueFormatter: ({ value }) => getDateTimeFromUnix(value),
      width: 200,
    },
    {
      headerName: capitalize(t('amount')),
      field: 'amount',
      valueFormatter: ({ value }) => formatCurrency(value),
    },
    {
      headerName: capitalize(t('provider')),
      field: 'provider',
    },
    {
      headerName: capitalize(t('paymentMethod')),
      field: 'paymentMethod',
      width: 150,
    },
    {
      headerName: capitalize(t('status')),
      field: 'status',
      renderCell: ({ value }) => <StatusSpan value={value} />,
    },
    {
      headerName: capitalize(t('usedPromotion')),
      field: 'usedPromotion',
      renderCell: ({ value }) =>
        typeof value === 'string' && value !== '' ? (
          <Link to={`../promotions/${value}`}>{t('promotion')}</Link>
        ) : (
          <CheckboxIcon checked={false} />
        ),
      align: 'center',
      headerAlign: 'center',
      width: 150,
    },
    {
      headerName: capitalize(t('onAccount')),
      field: 'onAccount',
      renderCell: ({ value }) => <CheckboxIcon checked={value} />,
      align: 'center',
      headerAlign: 'center',
      width: 100,
    },
    {
      headerName: '',
      field: 'actions',
      renderCell: ({ value }) => value,
      align: 'center',
    },
  ];

  const rows: GridRowsProp = accountData.lastDeposits.map((item, i) => {
    return {
      ...item,
      id: i,
      actions: canReadDepositDetails ? (
        <Button variant="text" color="secondary" component={Link} to={`../deposits/${item.depositId}`}>
          <FontAwesomeIcon icon={faEye} />
        </Button>
      ) : null,
    };
  });

  return (
    <Card>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CardHeader title={t('lastDeposits')} />
        {canReadDeposits && (
          <Button
            color="primary"
            variant="outlined"
            size="small"
            component={Link}
            to={`../../lookup/deposits?${DepositsParamsType.USER_ID}=${userId}&${
              DepositsParamsType.START_DATE
            }=${getUnixTime(subWeeks(new Date(), 1))}&${DepositsParamsType.END_DATE}=${getUnixTime(new Date())}`}
          >
            {t('seeMore')}
          </Button>
        )}
      </Box>
      <CardContent>
        {rows.length > 0 ? (
          <DataGridContainer>
            <StyledDataGrid autoHeight density="compact" columns={headers} rows={rows} hideFooter />
          </DataGridContainer>
        ) : (
          <NoData />
        )}
      </CardContent>
    </Card>
  );
};

export default memo(LastDeposits);
