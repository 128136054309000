import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonProps } from '@mui/material';
import {
  gridColumnDefinitionsSelector,
  GridStateColDef,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { useLocalStorage } from 'usehooks-ts';

import ColumnOrderDialog from '@greenisland-common/components/organisms/ColumOrderDialog';

const WithdrawTableToolbarOrderButton = React.forwardRef<HTMLButtonElement, ButtonProps & { destination: string }>(
  function GridToolbarColumnsButton(props, ref) {
    const { t } = useTranslation();
    const {
      components: { BaseButton },
      componentsProps,
    } = useGridRootProps();
    const apiRef = useGridApiContext();
    const allColumns = useGridSelector(apiRef, gridColumnDefinitionsSelector);
    const [isOpen, setIsOpen] = React.useState(false);
    const [, setColumnOrderPreferences] = useLocalStorage<string[] | undefined>(
      `BO-Withdrawals-${props.destination}-columns-order-preferences`,
      undefined
    );

    const updateColumns = (columns: GridStateColDef<any, any, any>[]) => {
      const currentState = apiRef.current.exportState();
      apiRef.current.restoreState({
        columns: {
          ...currentState.columns,
          orderedFields: ['__check__', ...columns.map(col => col.field), 'actions'],
        },
      });
      setColumnOrderPreferences(columns.map(col => col.field));
    };

    return (
      <>
        <BaseButton
          ref={ref}
          size="small"
          color="primary"
          aria-label={t('setColumnOrder')}
          startIcon={<FontAwesomeIcon icon={faRotate} />}
          {...props}
          onClick={() => setIsOpen(true)}
          {...componentsProps?.baseButton}
        >
          {t('setColumnOrder')}
        </BaseButton>
        {isOpen ? (
          <ColumnOrderDialog
            columns={allColumns.slice(1, -1)}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setColumnsOrder={updateColumns}
          />
        ) : null}
      </>
    );
  }
);

export default WithdrawTableToolbarOrderButton;
