/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */

export type TournamentConfigurationSortOrderParameter =
  (typeof TournamentConfigurationSortOrderParameter)[keyof typeof TournamentConfigurationSortOrderParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TournamentConfigurationSortOrderParameter = {
  DESC: 'DESC',
  ASC: 'ASC',
} as const;
