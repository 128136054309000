import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Authenticator, Flex, Grid, Heading, Image, useTheme, View } from '@aws-amplify/ui-react';

const AmplifySignInHeader = (): JSX.Element => {
  const { tokens } = useTheme();
  const { t } = useTranslation();

  let text = t('login');
  text = text.charAt(0).toUpperCase() + text.slice(1);

  return (
    <Flex justifyContent="center" padding={tokens.space.medium}>
      <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
        {text}
      </Heading>
    </Flex>
  );
};

export default AmplifySignInHeader;
