import LookupDetails from '../../pages/OnlineCasino/Lookup/Components/LookupDetails';
import { BaseRoute } from '../RouteInterfaces';

export const detailsRoutes: Array<BaseRoute> = [
  {
    path: 'players/:userId/details',
    name: 'Details',
    element: LookupDetails,
    hiddenLink: true,
  },
];

export default detailsRoutes;
