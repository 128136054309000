import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GameProviderArgumentsSchema,
  getGetGameProviderQueryKey,
  useGetAllCountries,
  useGetGameProvider,
  useGetGameStudios,
  useUpdateGameProvider,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';

import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { usePermission } from '../../../../../app/hooks';
import ProviderFormEdit from './ProviderFormEdit';

const ProviderDetails = memo(() => {
  const { t } = useTranslation();
  const { providerId = '' } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const canReadGameProvider = usePermission(OnlineCasinoPermissions.getGameProvider);
  const canReadGameStudios = usePermission(OnlineCasinoPermissions.getGameStudios);

  const {
    data: gameStudios,
    isLoading: isLoadingGameStudios,
    error: gameStudiosError,
  } = useGetGameStudios({
    query: { enabled: canReadGameStudios },
  });
  const {
    data: gameProvider,
    isLoading: isLoadingGameProvider,
    error: gameProviderError,
  } = useGetGameProvider(providerId, {
    query: { enabled: canReadGameProvider },
  });

  const { data: countries, isLoading: isLoadingCountries, error: countriesError } = useGetAllCountries();

  const isLoading = isLoadingGameProvider || isLoadingGameStudios || isLoadingCountries;
  const isError = gameProviderError || gameStudiosError || countriesError;

  const updateMutation = useUpdateGameProvider({
    mutation: {
      onSuccess: async () => {
        enqueueSnackbar(t('success'), {
          variant: 'success',
        });
        await queryClient.invalidateQueries(getGetGameProviderQueryKey(providerId));
        navigate('./../');
      },
    },
  });

  const submit = async (values: GameProviderArgumentsSchema) => {
    updateMutation.mutate({ providerId, data: values });
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError || !gameStudios || !gameProvider || !countries) {
    return (
      <ErrorState errorMessage={gameProviderError?.message || gameStudiosError?.message || countriesError?.message} />
    );
  }

  return (
    <ProviderFormEdit
      gameStudios={gameStudios ? gameStudios : []}
      gameProvider={gameProvider}
      countries={countries}
      submit={submit}
      error={updateMutation.error?.message || ''}
    />
  );
});

ProviderDetails.displayName = 'ProviderDetails';

export default ProviderDetails;
