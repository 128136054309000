import { Component } from 'react';

import { load } from './config';

export default class ConfigLoader extends Component<any, any> {
  state: any = {
    isLoaded: false,
  };

  shouldComponentUpdate(nextProps: any, nextState: any) {
    return nextState.isLoaded !== this.state.isLoaded;
  }

  componentDidMount() {
    // Once the configuration is loaded set `isLoaded` to true so we know to render our component
    load().then((config: any) => {
      const versionLocalStorage = localStorage.getItem(config.environment);
      if (versionLocalStorage && config.version !== versionLocalStorage) {
        localStorage.setItem(config.environment, config.version);
        window.location.reload();
      } else {
        sessionStorage.setItem('loadedConfig', JSON.stringify(config));
        if (!versionLocalStorage) {
          localStorage.setItem(config.environment, config.version);
        }
        this.setState({ isLoaded: true, config });
      }
    });
  }

  render() {
    // If we haven't yet loaded the config, show either a "splash" component provided via a `loading` props or return nothing.
    if (!this.state.isLoaded) {
      return this.props.loading ? this.props.loading() : null;
    }

    // The config is loaded so show the component set on the `ready()` props
    return this.props.ready;
  }
}
