import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  faCalendar,
  faCaretDown,
  faDollar,
  faFileDownload,
  faHandHoldingDollar,
  faMoneyBillWaveAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ERegulatorSchema, useExportWithdrawalsAndDepositsHistory } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Button, CircularProgress, Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { getUnixTime, startOfDay, subHours } from 'date-fns';
import { useTenantContext } from 'src/app/context/TenantContext';

import { downloadBase64File } from '@greenisland-common/helpers';

import { usePermission } from '../../../../../../../app/hooks';
import { TransactionParamsType } from '../../Constants/Transactions';

interface Props {
  userId: string;
}

const TransactionsMenu = ({ userId }: Props) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'lookup.userDetailsMenu' });
  const tenant = useTenantContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();
  const isOpen = Boolean(anchorEl);
  const canReadAccountTransactions = usePermission(OnlineCasinoPermissions.getAccountTransactions);
  const canDownloadAccountArchive = usePermission(OnlineCasinoPermissions.exportWithdrawalsAndDepositsHistory);
  const canReadUserGeneral = usePermission(OnlineCasinoPermissions.getUserGeneral);
  const canGetAnnualUserReport = usePermission(OnlineCasinoPermissions.getAnnualUserReport);
  const canGetOrCreateAnnualUserReport = usePermission(OnlineCasinoPermissions.getOrCreateAnnualUserReport);
  const canSeeGamingReport =
    tenant?.regulatorV2 === ERegulatorSchema.DGOJ &&
    canReadUserGeneral &&
    canGetAnnualUserReport &&
    canGetOrCreateAnnualUserReport;
  const canWithdrawManually = usePermission(OnlineCasinoPermissions.withdrawManually);
  const canDepositManually = usePermission(OnlineCasinoPermissions.depositManually);
  const shouldShowTransactionsDivider =
    (canReadAccountTransactions || canDownloadAccountArchive) &&
    (canSeeGamingReport || canWithdrawManually || canDepositManually);
  const shouldShowManualActionsDivider = canWithdrawManually || canDepositManually;

  const { data: withdrawalsAndDepositsHistory, isLoading } = useExportWithdrawalsAndDepositsHistory(
    userId,
    {},
    {
      query: { enabled: canDownloadAccountArchive && !!userId },
    }
  );

  const downloadAccountArchive = async () => {
    if (withdrawalsAndDepositsHistory) {
      downloadBase64File(withdrawalsAndDepositsHistory);
    }
  };

  return (
    <>
      <Button
        sx={{ display: 'flex', alignItems: 'flex-start' }}
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        endIcon={<FontAwesomeIcon icon={faCaretDown} />}
        size="small"
        onClick={event => setAnchorEl(event.currentTarget)}
      >
        {t('transactions')}
      </Button>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {canReadAccountTransactions && (
          <MenuItem
            onClick={() => {
              const startDate = getUnixTime(subHours(startOfDay(new Date()), 24));
              const endDate = getUnixTime(new Date());

              navigate(
                `../../lookup/txs?${TransactionParamsType.USER_ID}=${userId}&${TransactionParamsType.START_DATE}=${startDate}&${TransactionParamsType.END_DATE}=${endDate}`
              );
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={faDollar} />
            </ListItemIcon>
            {t('transactionsOverview')}
          </MenuItem>
        )}
        {canDownloadAccountArchive && (
          <MenuItem onClick={downloadAccountArchive}>
            {isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faFileDownload} />
                </ListItemIcon>
                {t('downloadAllDepositsAndWithdrawals')}
              </>
            )}
          </MenuItem>
        )}
        {shouldShowTransactionsDivider && <Divider />}
        {canSeeGamingReport && (
          <>
            <MenuItem onClick={() => navigate(`../../reporting/player/annual-report?userId=${userId}`)}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faCalendar} />
              </ListItemIcon>
              {t('annualReport')}
            </MenuItem>
            {shouldShowManualActionsDivider && <Divider />}
          </>
        )}
        {canWithdrawManually && (
          <MenuItem onClick={() => navigate(`../../tasks/manualwithdrawal/${userId}`)}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faMoneyBillWaveAlt} />
            </ListItemIcon>
            {t('withdrawManually')}
          </MenuItem>
        )}
        {canDepositManually && (
          <MenuItem onClick={() => navigate(`../../tasks/manualdeposit/${userId}`)}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faHandHoldingDollar} />
            </ListItemIcon>
            {t('depositManually')}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default TransactionsMenu;
