import { Outlet } from 'react-router';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';

import { BoosterCards, BoosterPacks } from '../../pages/OnlineCasino/Marketing/BoosterPacks';
import GlobalBoostsPage from '../../pages/OnlineCasino/Marketing/Boosts/globalBoosts';
import IndividualAction from '../../pages/OnlineCasino/Marketing/Campaigns/Actions/Individual/IndividualAction';
import Campaigns from '../../pages/OnlineCasino/Marketing/Campaigns/Campaigns';
import LegacySpecificTemplateDetails from '../../pages/OnlineCasino/Marketing/Campaigns/Templates/LegacySpecificTemplateDetails';
import FreeSpinsProviderCampaigns from '../../pages/OnlineCasino/Marketing/FreeSpinsCampaigns/components/FreeSpinsProviderCampaigns';
import FreeSpinsCampaigns from '../../pages/OnlineCasino/Marketing/FreeSpinsCampaigns/FreeSpinsCampaigns';
import { MARKETING_PERMISSIONS } from '../../pages/OnlineCasino/Marketing/MarketingPermissions';
import CreatePromotionalCalendar from '../../pages/OnlineCasino/Marketing/PromotionalCalendar/components/forms/CreatePromotionalCalendar';
import EditPromotionalCalendar from '../../pages/OnlineCasino/Marketing/PromotionalCalendar/components/forms/EditPromotionalCalendar';
import PromotionalCalendar from '../../pages/OnlineCasino/Marketing/PromotionalCalendar/PromotionalCalendar';
import Trustpilot from '../../pages/OnlineCasino/Marketing/Trustpilot/Trustpilot';
import { BaseRoute } from '../RouteInterfaces';

export const marketingRoutes: Array<BaseRoute> = [
  {
    path: 'marketing',
    name: 'Marketing',
    icon: faBullhorn,
    collapse: true,
    element: Outlet,
    permissions: MARKETING_PERMISSIONS,
    children: [
      {
        name: 'Booster Packs',
        path: 'booster',
        collapse: true,
        element: Outlet,
        featureFlag: 'isBoosterPacksEnabled',
        children: [
          {
            path: 'packs',
            name: 'Packs',
            element: BoosterPacks,
            mini: 'BP',
          },
          {
            path: 'cards',
            name: 'Cards',
            element: BoosterCards,
            mini: 'BPC',
          },
        ],
      },
      {
        name: 'Campaigns Overview',
        path: 'campaigns/overview',
        element: Campaigns,
        mini: 'CO',
        permissions: [OnlineCasinoPermissions.getCampaignsOverview],
      },
      {
        path: 'campaigns/overview/:campaignId/actions/individual/:actionId',
        name: 'Individual Actions',
        element: IndividualAction,
        hiddenLink: true,
      },
      {
        name: 'Free Spins Campaigns',
        path: 'free-spins',
        element: FreeSpinsCampaigns,
        mini: 'FSC',
        featureFlag: 'isPromoEnabled',
      },
      {
        name: 'Free Spins Provider Campaigns',
        path: 'free-spins/:providerId',
        element: FreeSpinsProviderCampaigns,
        hiddenLink: true,
        featureFlag: 'isPromoEnabled',
      },
      {
        name: 'Specific Campaign Templates',
        path: 'campaigns/overview/:campaignId/template/:templateType',
        element: LegacySpecificTemplateDetails,
        exact: true,
        hiddenLink: true,
      },
      {
        name: 'Global Boosts',
        path: 'boosts',
        element: GlobalBoostsPage,
        permissions: OnlineCasinoPermissions.getBoosts,
        featureFlag: 'isBoostEnabled',
      },
      {
        name: 'Promotional Calendar',
        path: 'promotional-calendar',
        element: Outlet,
        children: [
          {
            path: '',
            name: 'Promotional Calendar',
            element: PromotionalCalendar,
            mini: 'PC',
            permissions: [OnlineCasinoPermissions.getPromotionalCalendars],
          },
          {
            path: 'add',
            name: 'Add Promotional Calendar',
            element: CreatePromotionalCalendar,
            mini: 'CPC',
            permissions: [OnlineCasinoPermissions.addPromotionalCalendar],
          },
          {
            path: 'edit/:promotionalCalendarId',
            name: 'Edit Promotional Calendar',
            element: EditPromotionalCalendar,
            mini: 'EPC',
            permissions: [OnlineCasinoPermissions.updatePromotionalCalendar],
          },
        ],
      },
      {
        name: 'Trustpilot',
        path: 'trustpilot',
        element: Trustpilot,
        featureFlag: 'isTrustpilotEnabled',
      },
    ],
  },
];

export default marketingRoutes;
