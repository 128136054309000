import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ELanguagesSchema, TranslationsSchema } from '@greenisland-api';

const useGetTranslationSchemaLocale = () => {
  const { i18n } = useTranslation();
  const targetLanguage = i18n.language.split('-')[0].toUpperCase();

  return useCallback(
    (codeNameTranslations?: TranslationsSchema) => {
      const translationItem =
        codeNameTranslations?.find(translation => translation.language === targetLanguage) ||
        codeNameTranslations?.find(translation => translation.language === ELanguagesSchema.EN) ||
        codeNameTranslations?.[0];
      return translationItem?.content;
    },
    [targetLanguage]
  );
};

export default useGetTranslationSchemaLocale;
