import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useQueryActivePlayers } from '@greenisland-store/gameServer';
import { Button, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { getUnixTime } from 'date-fns';

import { Link, StyledDataGrid } from '@greenisland-common/components/atoms';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers';

import { transformDefaultV2 } from '../../app/helpers/transformFunctions';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.text.primary,
  whiteSpace: 'nowrap',
  '&:focus': {
    outline: 'none',
  },
}));

const GameServer = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const period = searchParams.get('period') ?? 'PT12H';
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  const pageSize = searchParams.get('pageSize') ? Number(searchParams.get('pageSize')) : 20;
  const { data: players, isLoading: fetching } = useQueryActivePlayers({ start: period, page, pageSize });

  const handlePageChange = (page: number, pageSize: number) => {
    if (page + 1 !== page) {
      replaceUrlQueryParameters(
        { period, page: (page + 1).toString(), pageSize: pageSize.toString() },
        setSearchParams
      );
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: t('displayName'),
      flex: 1,
      renderCell: params => <Link to={`../players/${params.row.externalId}/details`}>{params.row.displayName}</Link>,
    },
    {
      ...transformDefaultV2('currency', t),
      flex: 1,
    },
    {
      field: 'gamerounds',
      headerName: '',
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: params => (
        <Link
          to={`../players/${params.row.externalId}/gamerounds?startdate=${getUnixTime(
            new Date(new Date().getTime() - 2 * 60 * 60 * 1000)
          )}&enddate=${getUnixTime(new Date())}`}
        >
          {t('gamerounds')}
        </Link>
      ),
    },
  ];

  const rows = players?.entries || [];

  return (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom>
        {t('activePlayers')}
      </Typography>
      <Stack direction="row" spacing={2}>
        {['PT12H', 'PT6H', 'PT1H', 'PT15M'].map(p => (
          <StyledButton
            key={p}
            onClick={() => replaceUrlQueryParameters({ period: p }, setSearchParams)}
            variant={period === p ? 'contained' : 'outlined'}
          >
            {t(`period${p.slice(2).toLowerCase()}`)}
          </StyledButton>
        ))}
      </Stack>
      <StyledDataGrid
        columns={columns}
        rows={rows}
        loading={fetching}
        pagination
        paginationMode="server"
        rowCount={players?.totalNumberOfEntries || 0}
        page={players?.currentPage ? players.currentPage - 1 : 0}
        pageSize={20}
        onPageChange={page => handlePageChange(page, 20)}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        disableColumnMenu
      />
    </Stack>
  );
};

export default GameServer;
