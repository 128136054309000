export const removeUndefinedProperties = (object: Record<string, unknown>): Record<string, any> =>
  Object.entries(object).reduce<Record<string, unknown>>(
    (result, [key, value]) => (value !== undefined ? { ...result, [key]: value } : result),
    {}
  );

export const removeNullProperties = (object: Record<string, unknown>): Record<string, any> =>
  Object.entries(object).reduce<Record<string, unknown>>(
    (result, [key, value]) => (value !== null ? { ...result, [key]: value } : result),
    {}
  );

export const removeEmptyStringProperties = (object: Record<string, unknown>): Record<string, any> =>
  Object.fromEntries(Object.entries(object).filter(([_, v]) => Boolean(v)));

export const allowOnlyIntegers = (value: string) => value.replace(/[^0-9]/g, '');

export const pipe =
  <T>(...fns: Array<(arg: T) => T>) =>
  (value: T) =>
    fns.reduce((acc, fn) => fn(acc), value);
