import { Navigate, Route, Routes, useLocation } from 'react-router';
import { IEntityState } from '@greenisland/stores';
import { EntitiesSchema } from '@greenisland-store/authorization';

import { BaseRoute, BaseRouting, CustomPartialRouteObject } from '../RouteInterfaces';
import VisitorPaths from '../VisitorPaths';

interface Props extends BaseRouting {
  entitySelected: IEntityState;
  agentEntities?: EntitiesSchema;
}

const EmptyLayout = (props: Props) => {
  const location = useLocation();
  const routesToUse = (): Array<CustomPartialRouteObject> =>
    props.routes.map((route: BaseRoute) => {
      return {
        path: route.path || '',
        element: route.element,
        children: route.children,
        name: route.name,
      };
    });

  const handleNoMatchRouteUrl = () => {
    // Following if-statement will check if the url contains a url the user wanted to visit
    // If so, this url will be stored in the sessionstorage as 'redirectUrl' so that it can be used when the user is authenticated
    if (!Object.values(VisitorPaths).find((entry: string) => entry === location.pathname)) {
      const url = location.search ? location.pathname + location.search : location.pathname;
      sessionStorage.setItem('redirectUrl', url);
    }

    return '/';
  };

  return (
    <Routes>
      {routesToUse().map((route: CustomPartialRouteObject) => {
        const Comp: any = route.element;
        return (
          <Route key={`${route.path}-random${Math.floor(Math.random() * 100)}`} path={route.path} element={<Comp />} />
        );
      })}
      <Route key="noMatchRoute" path="/*" element={<Navigate to={handleNoMatchRouteUrl()} />} />
    </Routes>
  );
};

export default EmptyLayout;
