import React from 'react';
import { Controller, UseControllerOptions } from 'react-hook-form';
import { StandardTextFieldProps, TextField } from '@mui/material';
import { allowOnlyIntegers } from 'src/app/helpers/functions';

interface Props extends UseControllerOptions {
  helpText?: string;
  minValue?: number;
  allowFloats?: boolean;
  label?: string;
}

const Input = (props: Props) => {
  const { name, control, defaultValue, label, minValue, allowFloats } = props;

  return (
    <Controller
      rules={{ min: minValue }}
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, onBlur, value, name, ref }) => (
        <TextField
          type="number"
          name={name}
          onBlur={onBlur}
          onChange={e => (allowFloats ? onChange(e.target.value) : onChange(allowOnlyIntegers(e.target.value)))}
          value={value}
          inputRef={ref}
          fullWidth
          label={label}
        />
      )}
    />
  );
};

export default Input;
