import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useGetCasinoTransactions } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Stack, Typography } from '@mui/material';
import { endOfDay, getUnixTime, sub } from 'date-fns';

import { StyledDataGrid } from '@greenisland-common/components/atoms';
import { DateRangePicker } from '@greenisland-common/components/molecules/CustomDateRangePicker/DateRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import { transformCurrencyV2 } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';

const CasinoTransactions = () => {
  const canReadCasinoTransactions = usePermission(OnlineCasinoPermissions.getCasinoTransactions);
  return canReadCasinoTransactions ? <CasinoTransactionsInner /> : null;
};
const currentDate = new Date();

const CasinoTransactionsInner = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startdate');
  const endDate = searchParams.get('enddate');
  const start = startDate ? Number(startDate) : getUnixTime(sub(currentDate, { days: 1 }));
  const end = endDate ? Number(endDate) : getUnixTime(endOfDay(currentDate));

  const { data: casinoTransactions, isLoading: fetching } = useGetCasinoTransactions({
    startdate: start,
    enddate: end,
  });

  const columns = [
    {
      field: 'casino',
      headerName: t('casino'),
      flex: 1,
      renderCell: (params: any) => (
        <Typography sx={params.row.location === 'Totals' ? { fontWeight: 'bold' } : {}}>{`${params.row.location} ${
          params.row.enterprisename ? `(${params.row.enterprisename})` : ''
        }`}</Typography>
      ),
    },
    { ...transformCurrencyV2('deposits', t), flex: 1 },
    { ...transformCurrencyV2('withdrawals', t), flex: 1 },
    { ...transformCurrencyV2('difference', t), flex: 1 },
  ];

  const rows =
    casinoTransactions?.map(item => ({
      ...item,
      id: item.location + item.enterprisename, // Assuming there's no unique id in the original data
    })) || [];

  const totals = rows.reduce(
    (acc, item) => ({
      deposits: acc.deposits + item.deposits,
      withdrawals: acc.withdrawals + item.withdrawals,
      difference: acc.difference + item.difference,
    }),
    { deposits: 0, withdrawals: 0, difference: 0 }
  );

  rows.push({
    id: 'totals',
    location: 'Totals',
    enterprisename: '',
    deposits: totals.deposits,
    withdrawals: totals.withdrawals,
    difference: totals.difference,
  });

  return (
    <Stack spacing={2}>
      <DateRangePicker
        unixStartDate={start * 1000}
        unixEndDate={end * 1000}
        onChange={(startDate, endDate) => {
          replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
        }}
      />
      <StyledDataGrid
        columns={columns}
        rows={rows}
        loading={fetching}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        hideFooterPagination
      />
    </Stack>
  );
};

export default CasinoTransactions;
