import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Game } from '@greenisland-store/gameServer';
import ReactJson from '@microlink/react-json-view';
import { Box, Card, CardContent, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Input from '@greenisland-common/components/molecules/Input';

interface Props {
  isCreateMode?: boolean;
  game?: Game;
}

const GameGeneralSettings = ({ isCreateMode = true, game }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { control, register, watch, setValue } = useFormContext();

  return (
    <Stack spacing={2}>
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="column">
            <Input
              size="medium"
              label={t('content.gameserver.game.gameName')}
              control={control}
              type="text"
              name="name"
              defaultValue={!isCreateMode ? game?.name : null}
              required
              rules={{ required: t('fieldIsRequired') }}
              sx={{
                [theme.breakpoints.up('xs')]: {
                  width: '100%',
                  mb: 2,
                },
                [theme.breakpoints.up('md')]: {
                  width: '45%',
                },
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Input
              size="medium"
              label={t('content.gameserver.game.gameReference')}
              control={control}
              type="number"
              name="gameReference"
              defaultValue={!isCreateMode && game?.gameReference}
              required
              rules={{
                required: t('fieldIsRequired'),
                setValueAs: value => parseFloat(value),
                min: 0,
              }}
              sx={{
                [theme.breakpoints.up('xs')]: {
                  width: '100%',
                  mb: 2,
                },
                [theme.breakpoints.up('md')]: {
                  width: '45%',
                },
              }}
              disabled={!isCreateMode}
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <FormControlLabel
              label={<Typography fontSize="medium">{t('content.gameserver.game.enabled')}</Typography>}
              control={
                <Controller
                  control={control}
                  name="enabled"
                  defaultValue={!isCreateMode && game?.enabled}
                  render={({ value, onChange }) => (
                    <Switch
                      sx={{ mr: 1 }}
                      edge="end"
                      onChange={event => onChange(event.target.checked)}
                      checked={value}
                    />
                  )}
                />
              }
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <FormControlLabel
              label={<Typography fontSize="medium">{t('content.gameserver.game.availableForTournament')}</Typography>}
              control={
                <Controller
                  control={control}
                  name="availableForTournament"
                  defaultValue={!isCreateMode && game?.availableForTournament}
                  render={({ value, onChange }) => (
                    <Switch
                      sx={{ mr: 1 }}
                      edge="end"
                      onChange={event => onChange(event.target.checked)}
                      checked={value}
                    />
                  )}
                />
              }
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography sx={{ textTransform: 'capitalize' }} variant="h6">
              {t('content.gameserver.game.gameSettings')}
            </Typography>
            <Typography sx={{ textTransform: 'capitalize' }} variant="body2">
              <Controller
                control={control}
                name="gameSettings"
                defaultValue={isCreateMode ? {} : game?.gameSettings}
                render={({ value, onChange }) => (
                  <ReactJson
                    name="gameSettings"
                    enableClipboard={true}
                    style={{ padding: '10px', borderRadius: '10px' }}
                    theme="railscasts"
                    onAdd={data => onChange(data.updated_src)}
                    onEdit={data => onChange(data.updated_src)}
                    onDelete={data => onChange(data.updated_src)}
                    src={value ? JSON.parse(JSON.stringify(value)) : {}}
                  />
                )}
              />
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default GameGeneralSettings;
