import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetGameRoundReplay, useQueryGameRoundsForPlayer } from '@greenisland-store/gameServer';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridColDef } from '@mui/x-data-grid-pro';
import { differenceInMilliseconds, endOfDay, getUnixTime, sub } from 'date-fns';

import { Link, StyledDataGrid } from '@greenisland-common/components/atoms';
import { DateTimeRangePicker } from '@greenisland-common/components/molecules/CustomDateTimeRangePicker/DateTimeRangePicker';

import { replaceUrlQueryParameters } from '@greenisland-common/helpers/queryFunctions';

import {
  transformBooleanYesOrNullV2,
  transformCurrencyV2,
  transformStatusV2,
  transformUnixDateV2,
} from '../../app/helpers/transformFunctions';

const UrlSpan = styled('span')({
  whiteSpace: 'nowrap',
  overflow: 'scroll',
});

const CopyButtonWrapper = styled(Box)({
  justifySelf: 'end',
});

const TimeError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  ...theme.typography.caption,
}));

const currentDate = new Date();

const GameRounds = () => {
  const { t } = useTranslation();
  const { externalId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get('startdate');
  const endDate = searchParams.get('enddate');
  const start = startDate ? Number(startDate) : getUnixTime(sub(currentDate, { days: 1 }));
  const end = endDate ? Number(endDate) : getUnixTime(endOfDay(currentDate));
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  const pageSize = searchParams.get('pageSize') ? Number(searchParams.get('pageSize')) : 20;

  const [timeError, setTimeError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [gameRoundId, setGameRoundId] = useState<number | null>(null);

  const { data: gameRounds, isLoading: fetching } = useQueryGameRoundsForPlayer(externalId, {
    start: start,
    end: end,
    page,
    pageSize,
  });

  const { data: replay, isLoading: fetchingReplay } = useGetGameRoundReplay(gameRoundId ?? 0, {
    query: {
      enabled: !!gameRoundId,
    },
  });

  const getReplay = async (gameroundId: number) => {
    setOpenDialog(true);
    setGameRoundId(gameroundId);
  };

  const handlePageChange = (page: number, pageSize: number) => {
    if (page + 1 !== page) {
      replaceUrlQueryParameters(
        {
          page: (page + 1).toString(),
          pageSize: pageSize.toString(),
        },
        setSearchParams
      );
    }
  };

  const columns: GridColDef[] = [
    { ...transformUnixDateV2('startTimestamp', t), flex: 1 },
    { ...transformUnixDateV2('endTimestamp', t), flex: 1 },
    { ...transformBooleanYesOrNullV2('ended', t), flex: 0.5 },
    { ...transformBooleanYesOrNullV2('settled', t), flex: 0.5 },
    { ...transformStatusV2('finalState', t), flex: 0.5 },
    {
      ...transformCurrencyV2('betAmount', t),
      renderCell: params => (
        <Typography>
          {new Intl.NumberFormat(undefined, { style: 'currency', currency: params.row.currency }).format(
            params.value.amount / 100
          )}
        </Typography>
      ),
    },
    {
      ...transformCurrencyV2('winAmount', t),
      renderCell: params => (
        <Typography>
          {new Intl.NumberFormat(undefined, { style: 'currency', currency: params.row.currency }).format(
            params.value.amount / 100
          )}
        </Typography>
      ),
    },
    {
      field: 'transactions',
      headerName: t('transactions'),
      sortable: false,
      filterable: false,
      renderCell: params => <Link to={`${params.row.id}/transactions`}>{t('transactions')}</Link>,
    },
    {
      field: 'fetchReplay',
      headerName: t('getReplay'),
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: params => (
        <Button variant="outlined" size="small" sx={{ width: 'fit-content' }} onClick={() => getReplay(params.row.id)}>
          {t('getReplay')}
        </Button>
      ),
    },
  ];

  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <DateTimeRangePicker
          unixStartDate={start * 1000}
          unixEndDate={end * 1000}
          onChange={(startDate, endDate) => {
            if (differenceInMilliseconds(endDate, startDate) > 2 * 60 * 60 * 1000) {
              setTimeError(true);
            } else {
              setTimeError(false);
              replaceUrlQueryParameters({ startDate, endDate }, setSearchParams);
            }
          }}
        />
        {timeError && <TimeError>{t('maximumTwoHours')}.</TimeError>}
      </Stack>
      <StyledDataGrid
        columns={columns}
        rows={gameRounds?.entries || []}
        loading={fetching}
        paginationMode="server"
        rowCount={gameRounds?.totalNumberOfEntries || 0}
        page={gameRounds?.currentPage ? gameRounds.currentPage - 1 : 0}
        pageSize={20}
        onPageChange={page => handlePageChange(page, 20)}
        disableSelectionOnClick
        autoHeight
      />
      <Dialog maxWidth="sm" fullWidth open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{t('getReplay')}</DialogTitle>
        {replay && !fetchingReplay ? (
          <>
            <DialogContent>
              <UrlSpan>{replay.url}</UrlSpan>
              <CopyButtonWrapper>
                <Tooltip title={t('copy')}>
                  <IconButton size="small" onClick={() => navigator.clipboard.writeText(replay.url || '')}>
                    <FontAwesomeIcon icon={faCopy} />
                  </IconButton>
                </Tooltip>
              </CopyButtonWrapper>
            </DialogContent>
            <DialogActions>
              <Button color="inherit" onClick={() => setOpenDialog(false)}>
                {t('close')}
              </Button>
              <Button onClick={() => window.open(replay.url, '_blank')}>{t('visitUrl')}</Button>
            </DialogActions>
          </>
        ) : (
          <DialogContent>
            <Typography>{t('noData')}</Typography>
          </DialogContent>
        )}
      </Dialog>
    </Stack>
  );
};

export default GameRounds;
