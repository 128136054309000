import { useTranslation } from 'react-i18next';
import { Box, TextField, useTheme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { getUnixTime, subHours, subMonths, subWeeks } from 'date-fns';

import { DATETIME_INPUT_FORMAT, DATETIME_INPUT_MASK } from '@greenisland-common/components/atoms/Constants';

import { useUpdateSearchParams } from '@greenisland-common/hooks/useUpdateSearchParams';

import { GameSummarySearchFilters } from '../Constants';
import useGetGameSessionFiltersQuery from '../hooks/useGetGameSessionFiltersQuery';

const GameSessionDatesFilters = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const updateGameSessionQueryParamsHandler = useUpdateSearchParams();

  const { startDateQuery, endDateQuery } = useGetGameSessionFiltersQuery();

  return (
    <Box
      mt={4}
      sx={{
        [theme.breakpoints.up('xs')]: {
          flexDirection: 'column',
          width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
          width: '20%',
        },
      }}
    >
      <Box
        display="flex"
        sx={{
          [theme.breakpoints.up('xs')]: {
            flexDirection: 'column',
            width: '100%',
          },
        }}
      >
        <DateTimePicker
          label={t('startDate')}
          onChange={date => {
            updateGameSessionQueryParamsHandler(
              GameSummarySearchFilters.START_DATE,
              String(getUnixTime(date ? Number(date) : subHours(new Date(), 24)))
            );
          }}
          value={startDateQuery ? new Date(Number(startDateQuery) * 1000) : subWeeks(new Date(), 1)}
          renderInput={params => <TextField size="small" sx={{ mb: 2 }} fullWidth {...params} />}
          inputFormat={DATETIME_INPUT_FORMAT}
          mask={DATETIME_INPUT_MASK}
          maxDate={endDateQuery ? new Date(Number(endDateQuery) * 1000) : new Date()}
          minDate={subMonths(new Date(), 3)}
        />
        <DateTimePicker
          label={t('endDate')}
          onChange={date => {
            updateGameSessionQueryParamsHandler(
              GameSummarySearchFilters.END_DATE,
              String(getUnixTime(date ? Number(date) : new Date()))
            );
          }}
          value={endDateQuery ? new Date(Number(endDateQuery) * 1000) : new Date()}
          renderInput={params => <TextField size="small" sx={{ mb: 2 }} fullWidth {...params} />}
          inputFormat={DATETIME_INPUT_FORMAT}
          mask={DATETIME_INPUT_MASK}
          minDate={startDateQuery ? new Date(Number(startDateQuery) * 1000) : subWeeks(new Date(), 1)}
        />
      </Box>
    </Box>
  );
};

export default GameSessionDatesFilters;
