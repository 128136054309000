import { Box, Pagination, TablePagination, useTheme } from '@mui/material';

import DataGridPaginationSearchPageField from '@greenisland-common/components/molecules/DataGridPaginationSearchPageField';

type PaginationMode = 'client' | 'server';

interface Props {
  page: number;
  count: number;
  onPageChange: (page: number) => void;
  onRowsPerPageChange: (value: number) => void;
  rowsPerPage: number;
  paginationMode?: PaginationMode;
  rowsPerPageOptions?: number[];
}

const DataGridCustomPagination = ({
  page,
  count,
  onPageChange,
  onRowsPerPageChange,
  paginationMode,
  rowsPerPage,
  rowsPerPageOptions,
}: Props) => {
  const theme = useTheme();
  const isBiggerThanOneCount = count > 1;

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          [theme.breakpoints.up('xs')]: {
            mt: 1,
            width: '100%',
            flexDirection: 'column',
          },
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: isBiggerThanOneCount ? 'space-between' : 'flex-end',
          },
        }}
      >
        {isBiggerThanOneCount ? (
          <Pagination
            size="small"
            color="primary"
            count={count}
            page={page + 1}
            onChange={(event, value) => onPageChange(value - 1)}
            showFirstButton
            showLastButton
            boundaryCount={1}
            siblingCount={0}
          />
        ) : null}
        <Box
          display="flex"
          sx={{
            [theme.breakpoints.up('xs')]: {
              flexDirection: 'column-reverse',
            },
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
              alignItems: 'center',
            },
          }}
        >
          {rowsPerPageOptions?.length ? (
            <TablePagination
              size="small"
              component="div"
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
              count={count}
              onPageChange={(event, value) => onPageChange(value - 1)}
              onRowsPerPageChange={event => {
                onRowsPerPageChange(parseInt(event.target.value, 10));
                onPageChange(0);
              }}
              page={page + 1}
              sx={{
                p: 0,
                width: ['100%', 'auto'],
                '& .MuiTablePagination-spacer': {
                  display: 'none',
                },
                '& .MuiTablePagination-displayedRows': {
                  display: 'none',
                },
                '& .MuiTablePagination-actions': {
                  display: 'none',
                },
                '& .MuiToolbar-root': {
                  padding: 0,
                },
              }}
            />
          ) : null}
          {isBiggerThanOneCount && (
            <DataGridPaginationSearchPageField paginationMode={paginationMode} onChange={onPageChange} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default DataGridCustomPagination;
