import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetAllMediaGalleryEntriesOfTypeParams, MediaGalleryEntryTypeParamParameter } from '@greenisland-api';
import { useDebouncedValue } from '@lilib/hooks';
import SearchIcon from '@mui/icons-material/Search';
import { Box, FormControl, InputBase, InputLabel, MenuItem, Paper, Select } from '@mui/material';

export interface Filters extends GetAllMediaGalleryEntriesOfTypeParams {
  searchString?: string;
}

interface Props {
  onChange: (filters: Filters) => void;
  mediaGalleryEntryType?: MediaGalleryEntryTypeParamParameter;
  disableFilter?: boolean;
}

export const MediaGalleryFilter = ({
  onChange,
  mediaGalleryEntryType = MediaGalleryEntryTypeParamParameter.Template,
  disableFilter = false,
}: Props) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<MediaGalleryEntryTypeParamParameter>(mediaGalleryEntryType);

  const [debouncedSearch] = useDebouncedValue(search, { wait: 500 });

  const params = useMemo(() => {
    return {
      ...(debouncedSearch.length > 0 ? { fileName: debouncedSearch } : {}),
      mediaGalleryEntryType: sortBy,
    };
  }, [debouncedSearch, sortBy]);

  useEffect(() => {
    onChange(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <Paper sx={{ backgroundColor: 'white' }} variant="outlined" elevation={0}>
      <Box display="flex" alignItems="center" flexWrap="wrap" paddingX={2} paddingY={1}>
        <SearchIcon />
        <Box ml={3} flexGrow={1}>
          <InputBase
            placeholder={t('common.search.contentPlaceholder')}
            fullWidth
            onChange={e => setSearch(e.target.value)}
          />
        </Box>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="entryType" color="info">
            {t('content.mediaGallery.entryType.title')}
          </InputLabel>
          <Select
            id="entryType"
            value={sortBy}
            label={t('content.mediaGallery.entryType.title')}
            onChange={e => setSortBy(e.target.value as MediaGalleryEntryTypeParamParameter)}
            color="info"
            disabled={disableFilter}
          >
            {Object.keys(MediaGalleryEntryTypeParamParameter).map(entryType => (
              <MenuItem key={entryType} value={entryType}>
                {entryType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Paper>
  );
};
