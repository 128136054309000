import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useProfileLookup } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';

import { PermissionWrapper } from '@greenisland-common/components/atoms';

import KYCContactDetailsCard from './Cards/KYCContactDetailsCard';
import KYCEvidenceDetailsCard from './Cards/KYCEvidenceDetailsCard/KYCEvidenceDetailsCard';
import KYCLinksDetailsCard from './Cards/KYCLinkDetailsCard';
import KYCOtherDetailsCard from './Cards/KYCOtherDetailsCard';
import KYCPersonalDetailsCard from './Cards/KYCPersonalDetailsCard';

interface Props {
  resourceId: string;
}

const KYCProfileDetails = ({ resourceId }: Props) => {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useProfileLookup(resourceId);

  return (
    <PermissionWrapper
      errorMessage={t('kyc.individual.errors.profilelookup')}
      isError={isError}
      isLoading={isLoading}
      permission={OnlineCasinoPermissions.profileLookup}
    >
      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
        <KYCPersonalDetailsCard data={data}></KYCPersonalDetailsCard>
        <KYCContactDetailsCard data={data}></KYCContactDetailsCard>
        <KYCEvidenceDetailsCard data={data}></KYCEvidenceDetailsCard>
        <KYCLinksDetailsCard data={data}></KYCLinksDetailsCard>
        <KYCOtherDetailsCard data={data}></KYCOtherDetailsCard>
      </div>
    </PermissionWrapper>
  );
};

export default memo(KYCProfileDetails);
