import { ReadPermission, WritePermission } from '@greenisland-core/permissions';
import { EProductTypeSchema } from '@greenisland-store/authorization';

export const GameServerPermissions = {
  ping: new ReadPermission('/ping', EProductTypeSchema.GameServer),
  endpoints: new ReadPermission('/endpoints', EProductTypeSchema.GameServer),
  getOperator: new ReadPermission('/operator', EProductTypeSchema.GameServer),
  putOperator: new WritePermission('/operator', EProductTypeSchema.GameServer),
  queryGames: new ReadPermission('/games', EProductTypeSchema.GameServer),
  addGame: new WritePermission('/games', EProductTypeSchema.GameServer),
  putGame: new WritePermission('/games/$gamereference', EProductTypeSchema.GameServer),
  deleteGame: new WritePermission('/games/$gamereference', EProductTypeSchema.GameServer),
  queryPlayers: new ReadPermission('/players', EProductTypeSchema.GameServer),
  queryGameRoundsForPlayer: new ReadPermission(
    '/players/$playerexternalid/cash-games/game-rounds',
    EProductTypeSchema.GameServer
  ),
  queryActivePlayers: new ReadPermission('/active-players', EProductTypeSchema.GameServer),
  getGameRoundTransactions: new ReadPermission(
    '/cash-games/game-rounds/$gameroundid/transactions',
    EProductTypeSchema.GameServer
  ),
  getGameRoundReplay: new ReadPermission('/cash-games/game-rounds/$gameroundid/replay', EProductTypeSchema.GameServer),
  queryTournamentConfigurations: new ReadPermission('/tournament-configurations', EProductTypeSchema.GameServer),
  addTournamentConfiguration: new WritePermission('/tournament-configurations', EProductTypeSchema.GameServer),
  deleteScheduledTournamentInstancesForTournamentConfiguration: new WritePermission(
    '/tournament-configurations/$tournamentconfigurationid/scheduled-instances',
    EProductTypeSchema.GameServer
  ),
  getTournamentConfiguration: new ReadPermission(
    '/tournament-configurations/$tournamentconfigurationid',
    EProductTypeSchema.GameServer
  ),
  putTournamentConfiguration: new WritePermission(
    '/tournament-configurations/$tournamentconfigurationid',
    EProductTypeSchema.GameServer
  ),
  putInstantiatedTournamentConfiguration: new WritePermission(
    '/tournament-configurations/update-instantiated/$tournamentconfigurationid',
    EProductTypeSchema.GameServer
  ),
  queryTournaments: new ReadPermission('/tournaments', EProductTypeSchema.GameServer),
  queryParticipation: new ReadPermission('/tournaments/participation', EProductTypeSchema.GameServer),
  getTournamentParticipationTransactions: new ReadPermission(
    '/tournaments/participation/$tournamentparticipationid/transactions',
    EProductTypeSchema.GameServer
  ),
  getTournamentParticipationGameRounds: new ReadPermission(
    '/tournaments/participation/$tournamentparticipationid/game-rounds',
    EProductTypeSchema.GameServer
  ),
  getTournamentParticipationGameRoundTransactions: new ReadPermission(
    '/tournaments/participation/$tournamentparticipationid/game-rounds/$tournamentparticipationgameroundid/transactions',
    EProductTypeSchema.GameServer
  ),
  getTournamentParticipationGameRoundReplay: new ReadPermission(
    '/tournaments/participation/$tournamentparticipationid/game-rounds/$tournamentparticipationgameroundid/replay',
    EProductTypeSchema.GameServer
  ),
  getJackpotConfigurations: new ReadPermission('/jackpot-configurations', EProductTypeSchema.GameServer),
  getJackpotInstances: new ReadPermission('/jackpot-instances', EProductTypeSchema.GameServer),
};

export type GameServerPermissionTypes = keyof typeof GameServerPermissions;
