import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

export interface ErrorProps {
  sx?: SxProps;
  iconPosition?: 'before' | 'after';
  icon?: IconProp;
}

/**
 * Simple Mui Box element with error styles
 *
 * @param children
 * @param sx - additional styles
 * @param icon - Add an icon
 * @param iconPosition - Position the icon either before or after text
 */
const ErrorWrapper: React.FC<ErrorProps> = ({ children, sx, icon, iconPosition }) => {
  return (
    <Box display="flex" alignItems="center">
      {iconPosition && iconPosition === 'before' && children && (
        <FontAwesomeIcon sx={{ mr: 2, color: 'error.main' }} icon={icon || faTimesCircle} />
      )}
      <Box color="error.main" fontSize={14} sx={sx}>
        {children}
      </Box>
      {iconPosition && iconPosition === 'after' && children && (
        <FontAwesomeIcon sx={{ ml: 2, color: 'error.main' }} icon={icon || faTimesCircle} />
      )}
    </Box>
  );
};

export default ErrorWrapper;
