import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faAdd, faEye, faPencil } from '@fortawesome/free-solid-svg-icons';
import { AuthorizationPermissions } from '@greenisland-core/permissions';
import { useGetListAgentRoles } from '@greenisland-store/authorization';
import { Button, LinearProgress, Stack } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowParams, GridRowsProp } from '@mui/x-data-grid-pro';

import {
  CheckboxIcon,
  DataGridContainer,
  DataGridPagination,
  FontAwesomeIcon,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { usePermission } from '../../../../app/hooks';

const Roles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const canReadAgentRoles = usePermission(AuthorizationPermissions.getListAgentRoles);
  const canAddAgentRoles = usePermission(AuthorizationPermissions.addAgentRole);
  const canEditAgentRoles = usePermission(AuthorizationPermissions.updateAgentRole);
  const canReadAgentRolesDetails = usePermission(AuthorizationPermissions.getAgentRoleDetails);
  const {
    data: agentRoles,
    isLoading,
    isError,
    refetch,
  } = useGetListAgentRoles({ query: { enabled: canReadAgentRoles } });

  const columns: GridColumns = useMemo(
    () => [
      {
        headerName: t('roleName'),
        field: 'roleName',
        minWidth: 150,
        flex: 1,
      },
      {
        headerName: t('crossEntityRole'),
        field: 'crossEntityRole',
        minWidth: 80,
        flex: 0.5,
        renderCell: params => <CheckboxIcon checked={params.value} />,
      },
      {
        headerName: t('linkedProductIds'),
        field: 'linkedProductIds',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 50,
        flex: 0.2,
        getActions: (params: GridRowParams) => {
          const actions = [];
          if (canEditAgentRoles) {
            actions.push(
              <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faPencil} />}
                label={t('edit')}
                onClick={() => navigate(`./edit/${params.row.roleId}`)}
                key="edit"
              />
            );
          }
          if (canReadAgentRolesDetails) {
            actions.push(
              <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faEye} />}
                label={t('details')}
                onClick={() => navigate(`./${params.row.roleId}`)}
                key="viewGames"
              />
            );
          }
          return actions;
        },
      },
    ],
    [canEditAgentRoles, canReadAgentRolesDetails, navigate, t]
  );

  const rows: GridRowsProp = useMemo(
    () =>
      agentRoles?.map(role => {
        return { ...role, id: role.roleId };
      }) || [],
    [agentRoles]
  );

  return canReadAgentRoles ? (
    <Stack spacing={2}>
      {canAddAgentRoles ? (
        <div>
          <Button variant="text" startIcon={<FontAwesomeIcon icon={faAdd} />} onClick={() => navigate(`./add`)}>
            {t('addRole')}
          </Button>
        </div>
      ) : null}
      {!isError ? (
        <DataGridContainer>
          <StyledDataGrid
            density="compact"
            autoHeight
            loading={isLoading}
            rows={rows}
            columns={columns}
            rowsPerPageOptions={[25, 50, 100, 200]}
            pagination
            disableDensitySelector
            disableSelectionOnClick
            disableChildrenSorting
            disableChildrenFiltering
            disableColumnResize
            disableColumnReorder
            isRowSelectable={() => false}
            components={{
              Pagination: DataGridPagination,
              LoadingOverlay: LinearProgress,
            }}
            componentsProps={{
              pagination: { rowsPerPageOptions: [25, 50, 100, 200] },
            }}
          />
        </DataGridContainer>
      ) : (
        <ErrorState errorMessage={t('settings.management.agentRoles.loadingError')} retryAction={() => refetch()} />
      )}
    </Stack>
  ) : null;
};

export default Roles;
