/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * The user's account status. `SelfExclusion` and `LockedTooManyLogins` are obsolete.
 */
export type EUserStatusSchema = (typeof EUserStatusSchema)[keyof typeof EUserStatusSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EUserStatusSchema = {
  BlockedSports: 'BlockedSports',
  Active: 'Active',
  Closed: 'Closed',
  Locked: 'Locked',
  LockedTooManyLogins: 'LockedTooManyLogins',
  SelfExclusion: 'SelfExclusion',
  OnPlayingBreak: 'OnPlayingBreak',
} as const;
