import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { faDice, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GameProvidersSchemaItem, useGetGameProviders } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { LinearProgress } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { usePermission } from 'src/app/hooks';

import {
  CheckboxIcon,
  DataGridContainer,
  DataGridPagination,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

type Row = GameProvidersSchemaItem & { id: string | undefined };

const Providers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const canReadGameProviders = usePermission(OnlineCasinoPermissions.getGameProviders);
  const canEditGameProvider = usePermission(OnlineCasinoPermissions.updateGameProvider);
  const canReadGameProviderGames = usePermission(OnlineCasinoPermissions.getGameProviderGames);
  const { data: gameProviders, isLoading, isError, refetch } = useGetGameProviders();
  const rows = useMemo(
    () => gameProviders?.map<Row>(provider => ({ ...provider, id: provider.providerId })) ?? [],
    [gameProviders]
  );
  const columns = useMemo<GridColumns<Row>>(
    () => [
      { field: 'id', headerName: t('content.games.providers.providerId'), flex: 0.3 },
      { field: 'providerName', headerName: t('content.games.providers.providerName'), flex: 1 },
      {
        field: 'enabled',
        headerName: t('content.games.providers.enabled'),
        type: 'boolean',
        flex: 0.3,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
      },
      { field: 'nrOfGames', headerName: t('content.games.providers.nrOfGames'), flex: 0.1 },
      { field: 'nrOfEnabledGames', headerName: t('content.games.providers.nrOfEnabledGames'), flex: 0.1 },
      {
        field: 'actions',
        type: 'actions',
        getActions: (params: GridRowParams) => {
          const actions = [];
          if (canEditGameProvider) {
            actions.push(
              <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faPencil} />}
                label="edit"
                onClick={() => navigate(`${params.row.providerId}`)}
                key="view"
              />
            );
          }
          if (canReadGameProviderGames) {
            actions.push(
              <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faDice} />}
                label={t('content.games.providers.viewGames')}
                onClick={() => navigate(`${params.row.providerId}/games`)}
                key="viewGames"
              />
            );
          }
          return actions;
        },
      },
    ],
    [t, canEditGameProvider, canReadGameProviderGames, navigate]
  );

  return canReadGameProviders ? (
    <>
      {!isError ? (
        <DataGridContainer>
          <StyledDataGrid
            density="compact"
            loading={isLoading}
            rows={rows}
            columns={columns}
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            pagination
            page={page}
            pageSize={pageSize}
            onPageChange={setPage}
            onPageSizeChange={setPageSize}
            components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
            componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            disableDensitySelector
            disableSelectionOnClick
            disableColumnSelector
            disableColumnResize
            disableColumnReorder
            disableColumnPinning
            isRowSelectable={() => false}
          />
        </DataGridContainer>
      ) : (
        <ErrorState errorMessage={t('content.games.providers.loadingError')} retryAction={() => refetch()} />
      )}
    </>
  ) : null;
};

export default memo(Providers);
