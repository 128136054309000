/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  AccountingInvoiceSchema,
  AccountingSchema,
  BadRequestResponse,
  DefaultResponse,
  GetAccountingInvoiceV2Params,
  GetAccountingV2Params,
  NotFoundResponse,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get cashflow, refunds (also test users) and games (also test users) activity for the entire month
 */
export const useGetAccountingV2Hook = () => {
  const getAccountingV2 = useCustomInstance<AccountingSchema>();

  return (params: GetAccountingV2Params, signal?: AbortSignal) => {
    return getAccountingV2({ url: `/v2/reporting/financial/accounting`, method: 'get', params, signal });
  };
};

export const getGetAccountingV2QueryKey = (params: GetAccountingV2Params) =>
  [`/v2/reporting/financial/accounting`, ...(params ? [params] : [])] as const;

export const useGetAccountingV2QueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAccountingV2Hook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetAccountingV2Params,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountingV2Hook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountingV2Hook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccountingV2QueryKey(params);

  const getAccountingV2 = useGetAccountingV2Hook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAccountingV2Hook>>>> = ({ signal }) =>
    getAccountingV2(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAccountingV2QueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetAccountingV2Hook>>>>;
export type GetAccountingV2QueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get cashflow, refunds (also test users) and games (also test users) activity for the entire month
 */
export const useGetAccountingV2 = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAccountingV2Hook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetAccountingV2Params,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountingV2Hook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAccountingV2QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get financial accounting invoice for the entire month
 */
export const useGetAccountingInvoiceV2Hook = () => {
  const getAccountingInvoiceV2 = useCustomInstance<AccountingInvoiceSchema>();

  return (params: GetAccountingInvoiceV2Params, signal?: AbortSignal) => {
    return getAccountingInvoiceV2({ url: `/v2/reporting/financial/accounting/invoice`, method: 'get', params, signal });
  };
};

export const getGetAccountingInvoiceV2QueryKey = (params: GetAccountingInvoiceV2Params) =>
  [`/v2/reporting/financial/accounting/invoice`, ...(params ? [params] : [])] as const;

export const useGetAccountingInvoiceV2QueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAccountingInvoiceV2Hook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetAccountingInvoiceV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountingInvoiceV2Hook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountingInvoiceV2Hook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccountingInvoiceV2QueryKey(params);

  const getAccountingInvoiceV2 = useGetAccountingInvoiceV2Hook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAccountingInvoiceV2Hook>>>> = ({ signal }) =>
    getAccountingInvoiceV2(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAccountingInvoiceV2QueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAccountingInvoiceV2Hook>>>
>;
export type GetAccountingInvoiceV2QueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get financial accounting invoice for the entire month
 */
export const useGetAccountingInvoiceV2 = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAccountingInvoiceV2Hook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params: GetAccountingInvoiceV2Params,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAccountingInvoiceV2Hook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAccountingInvoiceV2QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
