import { EContentFilterBadRequestTypeSchema } from '@greenisland-api';

type ContentFilterErrorReasons = {
  [key in EContentFilterBadRequestTypeSchema]: string;
};

export const CONTENT_FILTERS_ERROR_REASONS: ContentFilterErrorReasons = {
  [EContentFilterBadRequestTypeSchema.NonExistentIdForContentFilterLinkProvided]:
    'contentFilterError.nonExistentIdForContentFilterLinkProvided',
  [EContentFilterBadRequestTypeSchema.InvalidRequestBody]: 'contentFilterError.invalidRequestBody',
  [EContentFilterBadRequestTypeSchema.DuplicateName]: 'contentFilterError.duplicateName',
  [EContentFilterBadRequestTypeSchema.InvalidTranslations]: 'contentFilterError.invalidTranslations',
};
