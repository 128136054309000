import { Controller, UseControllerOptions } from 'react-hook-form';
import { StandardTextFieldProps, TextField } from '@mui/material';

type Props = UseControllerOptions &
  StandardTextFieldProps & {
    helpText?: string;
  };

const Input = ({ name, control, defaultValue, ...restProps }: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={restProps.rules}
      render={({ onChange, onBlur, value, name, ref }) => (
        <TextField
          name={name}
          onBlur={onBlur}
          onChange={e => onChange(e.target.value)}
          value={value}
          inputRef={ref}
          {...restProps}
        />
      )}
    />
  );
};

export default Input;
