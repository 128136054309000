import { useTranslation } from 'react-i18next';
import { ReadPermission, WritePermission } from '@greenisland-core/permissions';
import { Skeleton } from '@mui/lab';
import { Box, CircularProgress } from '@mui/material';
import { usePermission } from 'src/app/hooks';

import ErrorCard from '../ErrorCard';

interface Props {
  isLoading?: boolean;
  isCircularLoadingStyle?: boolean;
  errorMessage?: string;
  isError?: boolean;
  permission: ReadPermission | WritePermission;
  children?: JSX.Element;
}

const PermissionWrapper = ({
  isLoading = true,
  isCircularLoadingStyle = false,
  isError = true,
  permission,
  errorMessage = '',
  children,
}: Props) => {
  const { t } = useTranslation();
  const hasPermission = usePermission(permission);

  if (!hasPermission)
    return (
      <ErrorCard severity="info">{t('common.noPermissionToSeeThePage', { permission: permission.level })}</ErrorCard>
    );

  if (isLoading) {
    if (isCircularLoadingStyle) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" p={4}>
          <CircularProgress size={20} />
        </Box>
      );
    }
    return <Skeleton variant="rectangular" height={300} />;
  }

  if (isError) return <ErrorCard>{errorMessage}</ErrorCard>;

  return <>{children}</>;
};

export default PermissionWrapper;
