import { EKillSwitchErrorTypeSchema } from '@greenisland-api';

type KillSwitchErrorReasons = {
  [key in EKillSwitchErrorTypeSchema]: string;
};

export const KILL_SWITCH_ERROR_REASONS: KillSwitchErrorReasons = {
  [EKillSwitchErrorTypeSchema.LambdaFailure]: 'settings.killswitch.errors.LambdaFailure',
  [EKillSwitchErrorTypeSchema.SSMFailure]: 'settings.killswitch.errors.SSMFailure',
  [EKillSwitchErrorTypeSchema.CloudWatchFailure]: 'settings.killswitch.errors.CloudWatchFailure',
};
