import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridActionsCellItem, GridRowModel, useGridApiRef } from '@mui/x-data-grid-pro';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

import { IpWhitelistConfiguration } from '../../../store/provider';

interface Props {
  ipWhitelist?: IpWhitelistConfiguration;
  setIpWhiteList: (ipWhiteList: IpWhitelistConfiguration) => void;
}

interface Row {
  id: number;
  ip: string;
}

const IPWhitelistDataGrid = ({ ipWhitelist, setIpWhiteList }: Props) => {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const rows = useMemo(() => ipWhitelist?.map((ip, index) => ({ id: index, ip })) ?? [], [ipWhitelist]);

  const processRowUpdate = useCallback(
    ({ id, ip }: GridRowModel<Row>) => {
      setIpWhiteList(rows.map(row => (row.id === id ? ip : row.ip)));
      return { id, ip };
    },
    [setIpWhiteList, rows]
  );

  return (
    <StyledDataGrid
      apiRef={apiRef}
      experimentalFeatures={{ newEditingApi: true }}
      rows={rows}
      columns={[
        {
          field: 'ip',
          headerName: t('providerService.providerConfiguration.ip'),
          type: 'string',
          width: 200,
          editable: true,
        },
        {
          field: 'actions',
          type: 'actions',
          width: 80,
          getActions: ({ id }) => [
            <GridActionsCellItem
              key="edit"
              label={t('providerService.providerConfiguration.edit')}
              icon={
                <FontAwesomeIcon
                  icon={faPencil}
                  onClick={() => apiRef.current.startCellEditMode({ id, field: 'ip' })}
                />
              }
            />,
            <GridActionsCellItem
              key="delete"
              label={t('providerService.providerConfiguration.delete')}
              icon={
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => setIpWhiteList(rows.filter(row => row.id !== id).map(row => row.ip))}
                />
              }
            />,
          ],
        },
      ]}
      editMode="cell"
      processRowUpdate={processRowUpdate}
      hideFooter
      sx={{ width: 280, mb: 2 }}
    />
  );
};

export default memo(IPWhitelistDataGrid);
