import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ERegulatorControllerBadRequestTypeSchema,
  RegulatorControllerBadRequestContentSchema,
  useGetExceedDefaultWeeklyDepositLimitRequests,
} from '@greenisland-api';
import { Box, capitalize, Checkbox, FormControlLabel, Skeleton } from '@mui/material';
import { DataGridPro, GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getDateTimeFromUnix } from 'src/app/helpers/transformFunctions';

import {
  CheckboxIcon,
  DataGridContainer,
  DataGridPagination,
  ErrorCard,
  NoData,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { renderStatus } from './renderStatus';

interface Props {
  userId: number;
}

const RequestExceedTable = ({ userId }: Props) => {
  const { t } = useTranslation();
  const [isNotExpired, setIsNotExpired] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [error, setError] = useState<ERegulatorControllerBadRequestTypeSchema | undefined>(undefined);

  const queryOptions = useMemo(
    () => ({
      page,
      pageSize,
    }),
    [page, pageSize]
  );

  const requestExceedQuery = useGetExceedDefaultWeeklyDepositLimitRequests(
    userId,
    {
      ...(isNotExpired ? { isExpired: !isNotExpired } : {}),
      ...queryOptions,
    },
    {
      query: {
        onError: async (error: Response) => {
          const errorResp: RegulatorControllerBadRequestContentSchema = await error.json();
          setError(errorResp.type);
        },
      },
    }
  );

  const [rowCountState, setRowCountState] = useState(requestExceedQuery?.data?.pagingDetails.totalItems || 0);

  useEffect(() => {
    setRowCountState(prevRowCountState =>
      requestExceedQuery?.data?.pagingDetails.totalItems !== undefined
        ? requestExceedQuery?.data?.pagingDetails.totalItems
        : prevRowCountState
    );
  }, [requestExceedQuery?.data?.pagingDetails.totalItems, setRowCountState]);

  const requestExceedData = useMemo(
    () => requestExceedQuery.data?.items.map((v, index) => ({ ...v, id: index })) ?? [],
    [requestExceedQuery.data?.items]
  );

  if (requestExceedQuery.isLoading) return <Skeleton variant="rectangular" height={179} />;

  if (requestExceedQuery.isError || !requestExceedQuery.data)
    return <ErrorCard sx={{ height: 179 }}>{t(`regulator.${error}`)}</ErrorCard>;

  if (error === ERegulatorControllerBadRequestTypeSchema.BGCArticle6NotEnabled) {
    return null;
  }

  const requestExceedHeaders: GridColumns = [
    {
      headerName: capitalize(t('created')),
      field: 'created',
      flex: 1,
      minWidth: 180,
      valueFormatter: ({ value }) => (value && value !== 0 ? getDateTimeFromUnix(value) : '/'),
    },
    {
      headerName: capitalize(t('tokenId')),
      field: 'tokenId',
      flex: 2,
      minWidth: 320,
    },
    {
      headerName: capitalize(t('requestId')),
      field: 'requestId',
      flex: 1,
      minWidth: 180,
    },
    {
      headerName: capitalize(t('canExceedResponse')),
      field: 'canExceedResponse',
      flex: 1,
      minWidth: 180,
      renderCell: renderStatus,
    },
    {
      headerName: capitalize(t('limitStatus')),
      field: 'limitStatus',
      flex: 1,
      minWidth: 160,
      renderCell: renderStatus,
    },
    {
      headerName: capitalize(t('isActive')),
      field: 'isExpired',
      flex: 1,
      minWidth: 80,
      renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={!params.value} />,
    },
    { headerName: capitalize(t('additionalData')), field: 'additionalData', flex: 1, minWidth: 120 },
  ];

  return (
    <>
      <Box>
        {t('depositLimitsStatus.filter')}
        <FormControlLabel
          control={<Checkbox checked={isNotExpired} onChange={e => setIsNotExpired(e.target.checked)} />}
          label={capitalize(t('isActive'))}
          sx={{ ml: 0.5 }}
        />
      </Box>
      {requestExceedData.length > 0 ? (
        <DataGridContainer>
          <StyledDataGrid
            autoHeight
            density="compact"
            columns={requestExceedHeaders}
            rows={requestExceedData}
            rowCount={rowCountState}
            pagination
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={newPage => setPage(newPage)}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            components={{
              Pagination: DataGridPagination,
            }}
          />
        </DataGridContainer>
      ) : (
        <NoData />
      )}
    </>
  );
};

export default memo(RequestExceedTable);
