import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EContentTypeSchema } from '@greenisland-api';
import { Box, capitalize, InputLabel, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { SelectInput } from '@greenisland-common/components/atoms';

import { useTenantContext } from '../../../../../../../app/context/TenantContext';
import { ExtendedContentFilterSchema, useIsLiveFilterOptions } from '../helpers/ContentFilterFormContext';
import GetContentFilterBox from './GetContentFilterBox';

interface Props {
  contentFilter: ExtendedContentFilterSchema | undefined;
}

const ContentFilterDetailsByIdFiltersSection = ({ contentFilter }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const tenant = useTenantContext();
  const isLiveFilterOptions = useIsLiveFilterOptions();

  const isSportFilterTypeChosen = contentFilter?.contentType === EContentTypeSchema.Sports;

  const isLiveFilterValue = useMemo(() => {
    return isLiveFilterOptions.find(option => option.status === contentFilter?.isLiveFilter)?.value;
  }, [contentFilter?.isLiveFilter, isLiveFilterOptions]);

  return (
    <>
      {tenant?.isGamesEnabled && tenant?.isSportsEnabled && contentFilter?.contentType && (
        <>
          <InputLabel sx={{ mb: 2 }}>{t('content.contentFilter.tableHeaders.contentType.title')}</InputLabel>
          <SelectInput
            size="small"
            name="contentType"
            label=""
            options={Object.keys(EContentTypeSchema).map(key => ({ value: key, label: t(key) }))}
            defaultValue={contentFilter?.contentType}
            sx={{
              [theme.breakpoints.up('xs')]: {
                width: '100%',
              },
              [theme.breakpoints.up('lg')]: {
                width: '30%',
              },
            }}
            disabled={true}
          />
        </>
      )}
      {!isSportFilterTypeChosen ? (
        <>
          <InputLabel sx={{ mb: 2 }}>{t('content.contentFilter.tableHeaders.isLiveFilter.title')}</InputLabel>
          <SelectInput
            size="small"
            name="isLiveFilterLabel"
            label=""
            rules={{
              required: t('fieldIsRequired'),
            }}
            options={isLiveFilterOptions}
            defaultValue={isLiveFilterValue}
            sx={{
              [theme.breakpoints.up('xs')]: {
                width: '100%',
              },
              [theme.breakpoints.up('lg')]: {
                width: '30%',
              },
            }}
            disabled={true}
          />
          {contentFilter?.rtpFilter && (
            <>
              <p>{capitalize(t('content.contentFilter.tableHeaders.rtpFilter.title'))}</p>
              <TextField
                size="small"
                label={t('content.contentFilter.tableHeaders.rtpFilter.comparison')}
                type="text"
                name="comparison"
                disabled={true}
                value={contentFilter?.rtpFilter?.comparison}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: 'rgba(0, 0, 0, 0.7)',
                  },
                }}
              />
              <TextField
                size="small"
                label={t('content.contentFilter.tableHeaders.rtpFilter.threshold')}
                type="text"
                name="threshold"
                disabled={true}
                value={contentFilter?.rtpFilter?.threshold}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: 'rgba(0, 0, 0, 0.7)',
                  },
                }}
              />
            </>
          )}
          <Box
            display="flex"
            gap={4}
            sx={{
              [theme.breakpoints.up('xs')]: {
                flexDirection: 'column',
              },
              [theme.breakpoints.up('lg')]: {
                flexDirection: 'row',
              },
            }}
          >
            <GetContentFilterBox
              title={t('content.contentFilter.tableHeaders.includedFilters.title')}
              filterType="included"
              filterData={contentFilter?.includedFilterExtended}
            />
            <GetContentFilterBox
              title={t('content.contentFilter.tableHeaders.excludedFilters.title')}
              filterType="excluded"
              filterData={contentFilter?.excludedFilterExtended}
            />
          </Box>
        </>
      ) : null}
    </>
  );
};

export default ContentFilterDetailsByIdFiltersSection;
