/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  DefaultResponse,
  MediaGalleryBadRequestResponse,
  MediaGalleryEntriesV2Schema,
  MediaGalleryEntryV2Schema,
  MediaGalleryValidationParamSchema,
  NotFoundResponse,
  RetrieveMediaGalleryAssetsParams,
  UnauthorizedResponse,
  UpdateMediaGalleryEntryV2Schema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Deletes media gallery entry. (Currently only marks as isDeleted but does not delete from S3 bucket.)
 */
export const useDeleteMediaGalleryEntryHook = () => {
  const deleteMediaGalleryEntry = useCustomInstance<void>();

  return (mediaGalleryEntryId: number) => {
    return deleteMediaGalleryEntry({ url: `/mediagallery/${mediaGalleryEntryId}`, method: 'delete' });
  };
};

export const useDeleteMediaGalleryEntryMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMediaGalleryEntryHook>>>,
    TError,
    { mediaGalleryEntryId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteMediaGalleryEntryHook>>>,
  TError,
  { mediaGalleryEntryId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteMediaGalleryEntry = useDeleteMediaGalleryEntryHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteMediaGalleryEntryHook>>>,
    { mediaGalleryEntryId: number }
  > = props => {
    const { mediaGalleryEntryId } = props ?? {};

    return deleteMediaGalleryEntry(mediaGalleryEntryId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMediaGalleryEntryMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeleteMediaGalleryEntryHook>>>
>;

export type DeleteMediaGalleryEntryMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Deletes media gallery entry. (Currently only marks as isDeleted but does not delete from S3 bucket.)
 */
export const useDeleteMediaGalleryEntry = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteMediaGalleryEntryHook>>>,
    TError,
    { mediaGalleryEntryId: number },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteMediaGalleryEntryMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Upload a media gallery asset.
 */
export const useUploadMediaGalleryEntryV2Hook = () => {
  const uploadMediaGalleryEntryV2 = useCustomInstance<MediaGalleryEntryV2Schema>();

  return (updateMediaGalleryEntryV2Schema: UpdateMediaGalleryEntryV2Schema) => {
    return uploadMediaGalleryEntryV2({
      url: `/mediagallery`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateMediaGalleryEntryV2Schema,
    });
  };
};

export const useUploadMediaGalleryEntryV2MutationOptions = <
  TError = MediaGalleryBadRequestResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadMediaGalleryEntryV2Hook>>>,
    TError,
    { data: UpdateMediaGalleryEntryV2Schema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUploadMediaGalleryEntryV2Hook>>>,
  TError,
  { data: UpdateMediaGalleryEntryV2Schema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const uploadMediaGalleryEntryV2 = useUploadMediaGalleryEntryV2Hook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUploadMediaGalleryEntryV2Hook>>>,
    { data: UpdateMediaGalleryEntryV2Schema }
  > = props => {
    const { data } = props ?? {};

    return uploadMediaGalleryEntryV2(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadMediaGalleryEntryV2MutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUploadMediaGalleryEntryV2Hook>>>
>;
export type UploadMediaGalleryEntryV2MutationBody = UpdateMediaGalleryEntryV2Schema;
export type UploadMediaGalleryEntryV2MutationError = MediaGalleryBadRequestResponse | DefaultResponse;

/**
 * @summary Upload a media gallery asset.
 */
export const useUploadMediaGalleryEntryV2 = <
  TError = MediaGalleryBadRequestResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUploadMediaGalleryEntryV2Hook>>>,
    TError,
    { data: UpdateMediaGalleryEntryV2Schema },
    TContext
  >;
}) => {
  const mutationOptions = useUploadMediaGalleryEntryV2MutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Retrieve media gallery assets
 */
export const useRetrieveMediaGalleryAssetsHook = () => {
  const retrieveMediaGalleryAssets = useCustomInstance<MediaGalleryEntriesV2Schema>();

  return (params?: RetrieveMediaGalleryAssetsParams, signal?: AbortSignal) => {
    return retrieveMediaGalleryAssets({ url: `/mediagallery`, method: 'get', params, signal });
  };
};

export const getRetrieveMediaGalleryAssetsQueryKey = (params?: RetrieveMediaGalleryAssetsParams) =>
  [`/mediagallery`, ...(params ? [params] : [])] as const;

export const useRetrieveMediaGalleryAssetsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useRetrieveMediaGalleryAssetsHook>>>,
  TError = BadRequestResponse | DefaultResponse
>(
  params?: RetrieveMediaGalleryAssetsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useRetrieveMediaGalleryAssetsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useRetrieveMediaGalleryAssetsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveMediaGalleryAssetsQueryKey(params);

  const retrieveMediaGalleryAssets = useRetrieveMediaGalleryAssetsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useRetrieveMediaGalleryAssetsHook>>>> = ({
    signal,
  }) => retrieveMediaGalleryAssets(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type RetrieveMediaGalleryAssetsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRetrieveMediaGalleryAssetsHook>>>
>;
export type RetrieveMediaGalleryAssetsQueryError = BadRequestResponse | DefaultResponse;

/**
 * @summary Retrieve media gallery assets
 */
export const useRetrieveMediaGalleryAssets = <
  TData = Awaited<ReturnType<ReturnType<typeof useRetrieveMediaGalleryAssetsHook>>>,
  TError = BadRequestResponse | DefaultResponse
>(
  params?: RetrieveMediaGalleryAssetsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useRetrieveMediaGalleryAssetsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useRetrieveMediaGalleryAssetsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the validation settings for the given media gallery type
 */
export const useRetrieveValidationSettingsHook = () => {
  const retrieveValidationSettings = useCustomInstance<MediaGalleryValidationParamSchema>();

  return (
    mediaGalleryEntryType:
      | 'Template'
      | 'GameStudioImage'
      | 'GameCategoryImage'
      | 'GameThemeImage'
      | 'GameStudioLogo'
      | 'PaymentMethodImage'
      | 'PaymentMethodLogo'
      | 'GameBackground'
      | 'GameThumbnailSquare'
      | 'GameThumbnailPortrait'
      | 'GameThumbnailLandscape'
      | 'GameStudioLogoGrey'
      | 'GameStudioLogoWhite'
      | 'GameStudioLogoBlack'
      | 'GameStudioLogoColorLight'
      | 'GameStudioLogoColorDark'
      | 'BoostImage'
      | 'BonusImage'
      | 'MultiplierTournamentImage'
      | 'MultiplierTournamentBannerImage'
      | 'Unknown',
    signal?: AbortSignal
  ) => {
    return retrieveValidationSettings({
      url: `/mediagallery/validationsettings/${mediaGalleryEntryType}`,
      method: 'get',
      signal,
    });
  };
};

export const getRetrieveValidationSettingsQueryKey = (
  mediaGalleryEntryType:
    | 'Template'
    | 'GameStudioImage'
    | 'GameCategoryImage'
    | 'GameThemeImage'
    | 'GameStudioLogo'
    | 'PaymentMethodImage'
    | 'PaymentMethodLogo'
    | 'GameBackground'
    | 'GameThumbnailSquare'
    | 'GameThumbnailPortrait'
    | 'GameThumbnailLandscape'
    | 'GameStudioLogoGrey'
    | 'GameStudioLogoWhite'
    | 'GameStudioLogoBlack'
    | 'GameStudioLogoColorLight'
    | 'GameStudioLogoColorDark'
    | 'BoostImage'
    | 'BonusImage'
    | 'MultiplierTournamentImage'
    | 'MultiplierTournamentBannerImage'
    | 'Unknown'
) => [`/mediagallery/validationsettings/${mediaGalleryEntryType}`] as const;

export const useRetrieveValidationSettingsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useRetrieveValidationSettingsHook>>>,
  TError = BadRequestResponse | DefaultResponse
>(
  mediaGalleryEntryType:
    | 'Template'
    | 'GameStudioImage'
    | 'GameCategoryImage'
    | 'GameThemeImage'
    | 'GameStudioLogo'
    | 'PaymentMethodImage'
    | 'PaymentMethodLogo'
    | 'GameBackground'
    | 'GameThumbnailSquare'
    | 'GameThumbnailPortrait'
    | 'GameThumbnailLandscape'
    | 'GameStudioLogoGrey'
    | 'GameStudioLogoWhite'
    | 'GameStudioLogoBlack'
    | 'GameStudioLogoColorLight'
    | 'GameStudioLogoColorDark'
    | 'BoostImage'
    | 'BonusImage'
    | 'MultiplierTournamentImage'
    | 'MultiplierTournamentBannerImage'
    | 'Unknown',
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useRetrieveValidationSettingsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useRetrieveValidationSettingsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveValidationSettingsQueryKey(mediaGalleryEntryType);

  const retrieveValidationSettings = useRetrieveValidationSettingsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useRetrieveValidationSettingsHook>>>> = ({
    signal,
  }) => retrieveValidationSettings(mediaGalleryEntryType, signal);

  return { queryKey, queryFn, enabled: !!mediaGalleryEntryType, ...queryOptions };
};

export type RetrieveValidationSettingsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRetrieveValidationSettingsHook>>>
>;
export type RetrieveValidationSettingsQueryError = BadRequestResponse | DefaultResponse;

/**
 * @summary Get the validation settings for the given media gallery type
 */
export const useRetrieveValidationSettings = <
  TData = Awaited<ReturnType<ReturnType<typeof useRetrieveValidationSettingsHook>>>,
  TError = BadRequestResponse | DefaultResponse
>(
  mediaGalleryEntryType:
    | 'Template'
    | 'GameStudioImage'
    | 'GameCategoryImage'
    | 'GameThemeImage'
    | 'GameStudioLogo'
    | 'PaymentMethodImage'
    | 'PaymentMethodLogo'
    | 'GameBackground'
    | 'GameThumbnailSquare'
    | 'GameThumbnailPortrait'
    | 'GameThumbnailLandscape'
    | 'GameStudioLogoGrey'
    | 'GameStudioLogoWhite'
    | 'GameStudioLogoBlack'
    | 'GameStudioLogoColorLight'
    | 'GameStudioLogoColorDark'
    | 'BoostImage'
    | 'BonusImage'
    | 'MultiplierTournamentImage'
    | 'MultiplierTournamentBannerImage'
    | 'Unknown',
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useRetrieveValidationSettingsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useRetrieveValidationSettingsQueryOptions(mediaGalleryEntryType, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Retrieve a media gallery entry
 */
export const useRetrieveMediaGalleryEntryHook = () => {
  const retrieveMediaGalleryEntry = useCustomInstance<MediaGalleryEntryV2Schema>();

  return (mediaGalleryEntryId: number, signal?: AbortSignal) => {
    return retrieveMediaGalleryEntry({ url: `/mediagallery/${mediaGalleryEntryId}`, method: 'get', signal });
  };
};

export const getRetrieveMediaGalleryEntryQueryKey = (mediaGalleryEntryId: number) =>
  [`/mediagallery/${mediaGalleryEntryId}`] as const;

export const useRetrieveMediaGalleryEntryQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useRetrieveMediaGalleryEntryHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  mediaGalleryEntryId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useRetrieveMediaGalleryEntryHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useRetrieveMediaGalleryEntryHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveMediaGalleryEntryQueryKey(mediaGalleryEntryId);

  const retrieveMediaGalleryEntry = useRetrieveMediaGalleryEntryHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useRetrieveMediaGalleryEntryHook>>>> = ({
    signal,
  }) => retrieveMediaGalleryEntry(mediaGalleryEntryId, signal);

  return { queryKey, queryFn, enabled: !!mediaGalleryEntryId, ...queryOptions };
};

export type RetrieveMediaGalleryEntryQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRetrieveMediaGalleryEntryHook>>>
>;
export type RetrieveMediaGalleryEntryQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Retrieve a media gallery entry
 */
export const useRetrieveMediaGalleryEntry = <
  TData = Awaited<ReturnType<ReturnType<typeof useRetrieveMediaGalleryEntryHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  mediaGalleryEntryId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useRetrieveMediaGalleryEntryHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useRetrieveMediaGalleryEntryQueryOptions(mediaGalleryEntryId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create or update a blurred hash for all game image entries with a blur factor set in the configuration store
 */
export const useSyncBlurredVersionGameImagesHook = () => {
  const syncBlurredVersionGameImages = useCustomInstance<void>();

  return () => {
    return syncBlurredVersionGameImages({ url: `/mediagallery/blur/sync-gameimages`, method: 'post' });
  };
};

export const useSyncBlurredVersionGameImagesMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSyncBlurredVersionGameImagesHook>>>,
    TError,
    TVariables,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSyncBlurredVersionGameImagesHook>>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const syncBlurredVersionGameImages = useSyncBlurredVersionGameImagesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSyncBlurredVersionGameImagesHook>>>,
    TVariables
  > = () => {
    return syncBlurredVersionGameImages();
  };

  return { mutationFn, ...mutationOptions };
};

export type SyncBlurredVersionGameImagesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSyncBlurredVersionGameImagesHook>>>
>;

export type SyncBlurredVersionGameImagesMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Create or update a blurred hash for all game image entries with a blur factor set in the configuration store
 */
export const useSyncBlurredVersionGameImages = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSyncBlurredVersionGameImagesHook>>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const mutationOptions = useSyncBlurredVersionGameImagesMutationOptions(options);

  return useMutation(mutationOptions);
};
