import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { capitalize, FormControlLabel, InputAdornment, Switch, TextField } from '@mui/material';

import LoyaltyDependentValue, {
  LoyaltyDependentIconType,
  LoyaltyDependentType,
} from '../../../../../Components/LoyaltyDependentValue/LoyaltyDependentValue';
import { IndividualActionForm } from '../../context';
import {
  BonusLoyaltyDependentTypeEnum,
  useGetDepositBonusLoyaltyDependentIcon,
} from '../helpers/DepositBonusFormContext';

interface Props {
  isExtendedLoyaltyDependentMode: boolean;
  handleLoyaltyDependentChange: (event: ChangeEvent<HTMLInputElement>, type: BonusLoyaltyDependentTypeEnum) => void;
  mainLabel: string;
  inputLabel: string;
  name: string;
  enabledDeposit: boolean;
  loyaltyDependantType: BonusLoyaltyDependentTypeEnum;
  switchControlKey: string;
  helpTextKey: string;
  iconType: LoyaltyDependentIconType;
  loyaltyDependentOptions: {
    name: string;
    label: string;
  }[];
}

const BonusLoyaltyDependantSection = ({
  isExtendedLoyaltyDependentMode,
  handleLoyaltyDependentChange,
  mainLabel,
  inputLabel,
  name,
  enabledDeposit,
  loyaltyDependantType,
  switchControlKey,
  helpTextKey,
  iconType,
  loyaltyDependentOptions,
}: Props) => {
  const { t } = useTranslation();
  const { register, errors } = useFormContext<IndividualActionForm>();

  const icon = useGetDepositBonusLoyaltyDependentIcon(iconType);

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={isExtendedLoyaltyDependentMode}
            onChange={e => handleLoyaltyDependentChange(e, loyaltyDependantType)}
          />
        }
        label={capitalize(mainLabel)}
        disabled={!enabledDeposit}
        name={switchControlKey}
        inputRef={register({ required: false })}
        key={switchControlKey}
        sx={{
          '.MuiFormControlLabel-label': {
            fontWeight: isExtendedLoyaltyDependentMode ? 600 : 400,
          },
        }}
      />
      <TextField
        size="small"
        key={name}
        name={name}
        helperText={
          errors[name as keyof typeof errors]
            ? capitalize(t(`${helpTextKey}.helpText.${name}.error`))
            : capitalize(t(`${helpTextKey}.helpText.${name}.default`))
        }
        error={Boolean(errors[name as keyof typeof errors])}
        disabled={!enabledDeposit || isExtendedLoyaltyDependentMode}
        required={enabledDeposit && !isExtendedLoyaltyDependentMode}
        type="number"
        inputProps={{ min: 0 }}
        InputProps={{
          startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
        }}
        inputRef={register({
          required: enabledDeposit && !isExtendedLoyaltyDependentMode,
          min: 0,
          setValueAs: value => parseInt(value),
        })}
        label={capitalize(inputLabel)}
        sx={{
          display: isExtendedLoyaltyDependentMode ? 'none' : 'flex',
        }}
      />
      <LoyaltyDependentValue
        title={inputLabel}
        type={LoyaltyDependentType.DECIMAL}
        disabled={true}
        showSelectOptions={false}
        iconType={iconType}
        options={loyaltyDependentOptions}
        sx={{
          display: isExtendedLoyaltyDependentMode ? 'flex' : 'none',
        }}
        isRequired={enabledDeposit && isExtendedLoyaltyDependentMode}
      />
    </>
  );
};

export default BonusLoyaltyDependantSection;
