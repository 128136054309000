import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, capitalize, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid-pro';

import {
  CheckboxIcon,
  DataGridContainer,
  ErrorCard,
  FontAwesomeIcon,
  Link,
  NoData,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';

import { EventStreamParamsType } from './Constants/Eventstreams';
import { formatCurrency, getDateFromUnix, NoWrapSpan, StatusSpan } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';
import useAccountData from '../../hooks/useAccountData';
import AwardAvailableBonusesDialog from './AwardAvailableBonusesDialog';

interface Props {
  userId: string;
}

const AvailableBonuses = ({ userId }: Props) => {
  const { t } = useTranslation();
  const canReadEventstream = usePermission(OnlineCasinoPermissions.getEventstreamStreamId);
  const canReadAvailableBonuses = usePermission(OnlineCasinoPermissions.getUserAvailableBonuses);
  const canAwardAvailableBonuses = usePermission(OnlineCasinoPermissions.awardAvailableBonus);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { data: accountData, isLoading, isError, error, permission } = useAccountData(userId);

  if (!permission) return null;
  if (isLoading) return <Skeleton variant="rectangular" height={300} />;
  if (isError || !accountData) return <ErrorCard>{error?.message}</ErrorCard>;

  const headers: GridColumns = [
    {
      headerName: capitalize(t('createdOn')),
      field: 'createdOn',
      valueFormatter: ({ value }) => (value && value !== 0 ? getDateFromUnix(value) : '/'),
      width: 120,
    },
    {
      headerName: capitalize(t('description')),
      field: 'description',
    },
    {
      headerName: capitalize(t('sports')),
      field: 'sports',
      renderCell: ({ value }) => <CheckboxIcon checked={value} />,
      headerAlign: 'center',
      align: 'center',
    },
    {
      headerName: capitalize(t('expiresOn')),
      field: 'expiresOn',
      valueFormatter: ({ value }) => (value && value !== 0 ? getDateFromUnix(value) : '/'),
    },
    {
      headerName: capitalize(t('source')),
      field: 'source',
    },
    {
      headerName: capitalize(t('status')),
      field: 'status',
      renderCell: ({ value }) => <StatusSpan value={value} />,
    },
    {
      headerName: capitalize(t('conditions')),
      field: 'conditions',
      renderCell: ({ value }) => value,
      width: 200,
    },
    {
      headerName: '',
      field: 'actions',
      renderCell: ({ value }) => value,
      align: 'center',
    },
  ];

  const rows: GridRowsProp = accountData.availableBonuses.map((item, i) => {
    return {
      ...item,
      id: i,
      conditions: <NoWrapSpan value={`${formatCurrency(item.amount)} playthrough ${item.playthrough}`} />,
      actions: canReadEventstream ? (
        <Button
          size="small"
          variant="text"
          color="secondary"
          component={Link}
          to={`../../../lookup/eventstreams?${
            EventStreamParamsType.STREAM_ID
          }=availablepromotion/${item.streamId.toLowerCase()}`}
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
      ) : undefined,
    };
  });

  return (
    <>
      <Card>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CardHeader title={t('availableBonuses')} />
          <div>
            {(canAwardAvailableBonuses || canReadAvailableBonuses) && (
              <>
                {canAwardAvailableBonuses && (
                  <Button variant="outlined" color="secondary" size="small" onClick={() => setOpenDialog(true)}>
                    {t('award')}
                  </Button>
                )}
                {canReadAvailableBonuses && (
                  <Button
                    sx={{ ml: 2 }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    component={Link}
                    to="../availablebonuses"
                  >
                    {t('seeMore')}
                  </Button>
                )}
              </>
            )}
          </div>
        </Box>
        <CardContent>
          {rows.length > 0 ? (
            <DataGridContainer>
              <StyledDataGrid columns={headers} rows={rows} autoHeight density="compact" hideFooter />
            </DataGridContainer>
          ) : (
            <NoData />
          )}
        </CardContent>
      </Card>
      {openDialog && (
        <AwardAvailableBonusesDialog openDialog={openDialog} setOpenDialog={setOpenDialog} userId={userId} />
      )}
    </>
  );
};

export default memo(AvailableBonuses);
