import {
  capitalize,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MUISelect,
  SelectChangeEvent,
  SxProps,
  useTheme,
} from '@mui/material';

interface Props<T> {
  id: string;
  label: string;
  value: T;
  options: string[];
  onChange: (event: SelectChangeEvent<T>) => void;
  required?: boolean;
  error?: boolean;
  sx?: SxProps;
  disabled?: boolean;
}

function Select<T>({ id, label, value, options, onChange, required, error, sx, disabled }: Props<T>) {
  const theme = useTheme();

  return (
    <FormControl
      size="small"
      sx={{
        [theme.breakpoints.up('xs')]: {
          width: '100%',
          ...sx,
        },
        [theme.breakpoints.up('lg')]: {
          width: 'fit-content',
          minWidth: 180,
          ...sx,
        },
      }}
    >
      <InputLabel id={id} color="info">
        {capitalize(label)}
      </InputLabel>
      <MUISelect
        id={id}
        value={value}
        label={capitalize(label)}
        onChange={onChange}
        color="info"
        required={required}
        error={error}
        disabled={disabled}
      >
        {options.map(key => (
          <MenuItem key={key} value={key}>
            {capitalize(key)}
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
}

export default Select;
