import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { faCertificate, faEye } from '@fortawesome/free-solid-svg-icons';
import {
  Base64FileSchema,
  EVerificationStatusSchema,
  getGetUserVerificationsQueryKey,
  useArchiveUserBankAccountVerification,
  useGetDocumentById,
  UserDocumentMetadataSchema,
  useUnarchiveUserBankAccountVerification,
  useUpdateUserVerificationsBankAccountStatus,
  VerificationsBankAccountSchema,
  VerificationsBankAccountsSchema,
  VerificationStatusArgumentsSchema,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  capitalize,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridColumns, GridRowParams } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { CheckboxIcon, FontAwesomeIcon, Link, NoData, StyledDataGrid } from '@greenisland-common/components/atoms';
import ImageDialog from '@greenisland-common/components/molecules/ImageDialog';
import PdfDialog from '@greenisland-common/components/molecules/PdfDialog';

import { formatBase64String } from '@greenisland-common/helpers';

import { getStatusColorClass } from '../../../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../../../app/hooks';
import DeclineVerificationDialog from '../DeclineVerificationDialog';
import UploadBankAccountVerificationField from './UploadBankAccountVerificationField';

const { NeedsVerification, Verified, Unverified, WaitingForApproval } = EVerificationStatusSchema;

interface Props {
  bankAccounts: VerificationsBankAccountsSchema;
}

type VerifcationDialog = {
  open: boolean;
  bankAccount: VerificationsBankAccountSchema | undefined;
};

const BankAccounts = ({ bankAccounts }: Props) => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const canUpdateVerificationStatus = usePermission(OnlineCasinoPermissions.updateUserVerificationsBankAccountStatus);
  const canArchiveBankAccount = usePermission(OnlineCasinoPermissions.archiveUserBankAccountVerification);
  const canUnarchiveBankAccount = usePermission(OnlineCasinoPermissions.unarchiveUserBankAccountVerification);
  const [documentMetadata, setDocumentMetadata] = useState<UserDocumentMetadataSchema | undefined>(undefined);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [file, setFile] = useState<Base64FileSchema | string | null>();

  const {
    data: _,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useGetDocumentById(documentMetadata?.documentId as number, {
    query: { enabled: false },
  });

  const mutationObj = {
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetUserVerificationsQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  };

  const { mutate: archiveBankAccount, isLoading: isArchivingBankAccount } = useArchiveUserBankAccountVerification({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetUserVerificationsQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const { mutate: unarchiveBankAccount, isLoading: isUnarchivingBankAccount } = useUnarchiveUserBankAccountVerification(
    {
      mutation: {
        onSuccess: async () => {
          await queryClient.invalidateQueries(getGetUserVerificationsQueryKey(userId));
          enqueueSnackbar(t('success'), { variant: 'success' });
        },
        onError: () => {
          enqueueSnackbar(t('error'), { variant: 'error' });
        },
      },
    }
  );

  const updateBankVerificationStatusMutation = useUpdateUserVerificationsBankAccountStatus(mutationObj);
  const updateVerificationStatus = async (values: VerificationStatusArgumentsSchema, bankAccountId: string) =>
    updateBankVerificationStatusMutation.mutate({ userId, bankAccountId, data: values });

  const [declineVerificationDialog, setDeclineVerificationDialog] = useState<VerifcationDialog>({
    open: false,
    bankAccount: undefined,
  });

  const hasActions = bankAccounts.some(
    ({ verificationStatus }) => verificationStatus === Unverified || verificationStatus === WaitingForApproval
  );

  const previewMetadataImageHandler = useCallback(async () => {
    const response = await refetch();
    if (response?.data?.document) {
      const documentData = response.data.document;

      setFile({
        ...documentData,
        content: formatBase64String(documentData.content, documentData.mimeType),
      });
      setOpenImagePreview(true);
      setDocumentMetadata(undefined);
    }
  }, [refetch]);

  useEffect(() => {
    if (documentMetadata) {
      previewMetadataImageHandler();
    }
  }, [documentMetadata, previewMetadataImageHandler]);

  const headers: GridColumns<VerificationsBankAccountSchema> = [
    {
      headerName: '',
      field: 'isMainBankAccount',
      type: 'actions',
      renderCell: ({ value }) =>
        value ? (
          <Tooltip title={t('mainBankAccount')}>
            <FontAwesomeIcon sx={{ ml: 1 }} icon={faCertificate} />
          </Tooltip>
        ) : (
          ''
        ),
      minWidth: 50,
      flex: 0.1,
    },
    {
      headerName: capitalize(t('bankAccount')),
      field: 'bankAccountNumber',
      renderCell: ({ value, row }) => (
        <span>
          <Link to={`../userbankaccounts?iban=${value}`}>{value.match(/.{1,4}/g)?.join(' ')}</Link>
          {row?.bankAccountDocumentMetadata ? (
            <LoadingButton
              color="secondary"
              variant="text"
              onClick={() => setDocumentMetadata(row.bankAccountDocumentMetadata)}
              sx={{ ml: 0.5 }}
              loading={isLoading || isFetching}
              disableRipple
            >
              <FontAwesomeIcon sx={{ width: 15 }} icon={faEye} />
            </LoadingButton>
          ) : null}
        </span>
      ),
      minWidth: 280,
      flex: 0.2,
    },
    {
      headerName: capitalize(t('verificationStatus.title')),
      field: 'verificationStatus',
      renderCell: ({ value }) => (
        <Typography variant="body2" sx={getStatusColorClass(value)}>
          {t(value)}
        </Typography>
      ),
      minWidth: 160,
      flex: 0.1,
    },
    {
      headerName: capitalize(t('active')),
      field: 'archived',
      renderCell: ({ value }) => <CheckboxIcon checked={!value} />,
      align: 'center',
      headerAlign: 'center',
      minWidth: 140,
      flex: 0.1,
    },
    {
      align: 'left',
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams<VerificationsBankAccountSchema>) => [
        <Box key="update" sx={{ ml: 1, display: 'inline' }}>
          <Button
            disabled={!canUpdateVerificationStatus || params.row.verificationStatus !== Unverified}
            sx={{ mr: 1 }}
            size="small"
            variant="outlined"
            onClick={() =>
              updateVerificationStatus({ verificationStatus: NeedsVerification }, params.row.bankAccountId)
            }
          >
            {updateBankVerificationStatusMutation.isLoading ? <CircularProgress size={20} /> : t('needsVerification')}
          </Button>
          <>
            <Button
              disabled={!canUpdateVerificationStatus || params.row.verificationStatus !== WaitingForApproval}
              sx={{ mr: 1 }}
              size="small"
              color="primary"
              variant="contained"
              onClick={() => updateVerificationStatus({ verificationStatus: Verified }, params.row.bankAccountId)}
            >
              {updateBankVerificationStatusMutation.isLoading ? <CircularProgress size={20} /> : t('verify')}
            </Button>
            <Button
              disabled={!canUpdateVerificationStatus || params.row.verificationStatus !== WaitingForApproval}
              sx={{ mr: 1 }}
              size="small"
              variant="outlined"
              color="error"
              onClick={() => setDeclineVerificationDialog({ bankAccount: params.row, open: true })}
            >
              {t('decline')}
            </Button>
          </>
          {!params.row.archived && (
            <Button
              disabled={!canArchiveBankAccount}
              sx={{ mr: 1, width: 100 }}
              size="small"
              color="error"
              variant="outlined"
              onClick={() => archiveBankAccount({ userId, bankAccountNumber: params.row.bankAccountNumber })}
            >
              {isArchivingBankAccount ? <CircularProgress size={20} /> : t('archive')}
            </Button>
          )}
          {params.row.archived && (
            <Button
              disabled={!canUnarchiveBankAccount}
              sx={{ mr: 1, width: 100 }}
              size="small"
              color="primary"
              variant="outlined"
              onClick={() => unarchiveBankAccount({ userId, bankAccountId: params.row.bankAccountId })}
            >
              {isUnarchivingBankAccount ? <CircularProgress size={20} /> : t('activate')}
            </Button>
          )}
        </Box>,
        <UploadBankAccountVerificationField
          key="upload"
          userId={userId}
          bankAccountId={params.row.bankAccountId}
          metadata={params?.row?.bankAccountDocumentMetadata}
        />,
      ],
      minWidth: canUpdateVerificationStatus && hasActions ? 700 : 600,
      flex: 0.5,
    },
  ];

  const rows = bankAccounts.map(item => ({ ...item, id: item.bankAccountId }));

  if (isError) {
    enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
  }

  return (
    <>
      <Card sx={{ mt: 2, pl: 0, pr: 0 }}>
        <CardHeader title={t('bankAccounts')} />
        <CardContent sx={{ p: 2 }}>
          {rows.length ? (
            <StyledDataGrid hideFooter autoHeight density="compact" columns={headers} rows={rows} />
          ) : (
            <NoData />
          )}
          <DeclineVerificationDialog
            open={declineVerificationDialog.open}
            setOpen={() => setDeclineVerificationDialog({ ...declineVerificationDialog, open: false })}
            action={values =>
              updateVerificationStatus(values, declineVerificationDialog?.bankAccount?.bankAccountId || '')
            }
            bankaccount={declineVerificationDialog.bankAccount}
          />
        </CardContent>
      </Card>
      {openImagePreview &&
        file &&
        (typeof file === 'string' || (file as Base64FileSchema).mimeType !== 'application/pdf' ? (
          <ImageDialog
            openImageDialog={openImagePreview}
            setOpenImageDialog={setOpenImagePreview}
            image={typeof file === 'string' ? file : file.content}
          />
        ) : (
          <PdfDialog
            openPdfDialog={openImagePreview}
            setOpenPdfDialog={setOpenImagePreview}
            image={(file as Base64FileSchema).content}
          />
        ))}
    </>
  );
};

export default memo(BankAccounts);
