import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetGameRoundTransactions } from '@greenisland-store/gameServer';
import { GridColDef } from '@mui/x-data-grid-pro';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

import { transformCurrencyV2, transformDefaultV2, transformUnixDateV2 } from '../../app/helpers/transformFunctions';

const GameTransactions = () => {
  const { gameroundId = '' } = useParams();
  const { t } = useTranslation();
  const { isLoading: fetching, data: gameRoundTransactions } = useGetGameRoundTransactions(Number(gameroundId), {
    query: { enabled: !!gameroundId },
  });

  const columns: GridColDef[] = useMemo(() => {
    if (!gameRoundTransactions || gameRoundTransactions.length === 0) return [];
    return Object.keys(gameRoundTransactions[0]).map(key => {
      switch (key) {
        case 'timestamp':
          return {
            ...transformUnixDateV2(key, t),
            flex: 1,
          };
        case 'transactionAmount':
          return {
            ...transformCurrencyV2(key, t),
            flex: 1,
          };
        case 'balanceAfter':
          return {
            ...transformCurrencyV2(key, t),
            flex: 1,
          };
        default:
          return {
            ...transformDefaultV2(key, t),
            flex: 1,
          };
      }
    });
  }, [gameRoundTransactions, t]);

  const rows = useMemo(() => gameRoundTransactions || [], [gameRoundTransactions]);

  return (
    <StyledDataGrid
      columns={columns}
      rows={rows}
      loading={fetching}
      autoHeight
      pagination
      pageSize={20}
      rowsPerPageOptions={[5, 10, 25, 50, 100]}
      disableSelectionOnClick
      disableColumnMenu
      disableDensitySelector
    />
  );
};

export default GameTransactions;
