import { useMemo } from 'react';
import {
  GameSessionsSortKeyParamParameter,
  GameSessionsSummaryGamingRevenueComparisonParameter,
  GameSessionsSummaryGamingRevenueComparisonValueParameter,
  GameSessionsSummaryGroupByKeyParamParameter,
  GameSessionsSummarySortKeyParamParameter,
  SortOrderParamParameter,
} from '@greenisland-api';
import { useDebouncedValue } from '@lilib/hooks';
import { getUnixTime } from 'date-fns';

import useGetGameSessionFiltersQuery from './useGetGameSessionFiltersQuery';

export enum GameSessionFormType {
  'SUMMARIES' = 'SUMMARIES',
  'SESSIONS' = 'SESSIONS',
}

interface GameSessionFormParams {
  formType: GameSessionFormType;
  params: {
    debouncedUserId: string | null;
    debouncedGameId: string | null;
    startDate: Date;
    endDate: Date;
    groupByKey?: GameSessionsSummaryGroupByKeyParamParameter | GameSessionsSortKeyParamParameter;
    sortOrder: SortOrderParamParameter;
    sortKey: GameSessionsSortKeyParamParameter;
    gamingRevenueComparison?: GameSessionsSummaryGamingRevenueComparisonParameter;
    gamingRevenueComparisonValue?: GameSessionsSummaryGamingRevenueComparisonValueParameter;
    pageSize: number;
    page: number;
  };
}

interface GameSessionResponse {
  userId?: number;
  gameId?: number;
  groupByKey?: GameSessionsSummaryGroupByKeyParamParameter;
  gamingRevenueComparison?: GameSessionsSummaryGamingRevenueComparisonParameter;
  gamingRevenueComparisonValue?: number;
  startDate: number;
  endDate: number;
  sortKey: GameSessionsSortKeyParamParameter;
  sortOrder: SortOrderParamParameter;
  pageSize: number;
  page: number;
}

export default function useGetGameSessionFormParams({ formType, params }: GameSessionFormParams): GameSessionResponse {
  const {
    startDateQuery,
    endDateQuery,
    pageQuery,
    groupByKeyQuery,
    sortByKeyQuery,
    sortByOrderQuery,
    gamingRevenueComparisonQuery,
    gamingRevenueComparisonValueQuery,
  } = useGetGameSessionFiltersQuery();

  const {
    debouncedUserId,
    debouncedGameId,
    startDate,
    endDate,
    groupByKey,
    sortOrder,
    sortKey,
    gamingRevenueComparisonValue,
    pageSize,
    page,
    gamingRevenueComparison,
  } = params;
  const gamingRevenueComparisonParam = (gamingRevenueComparisonQuery ||
    gamingRevenueComparison) as GameSessionsSummaryGamingRevenueComparisonParameter;

  const gamingRevenueComparisonValueParam =
    gamingRevenueComparisonValueQuery || gamingRevenueComparisonValueQuery === '0'
      ? gamingRevenueComparisonValueQuery
      : gamingRevenueComparisonValue?.toString();

  const sortKeyForSummaries = (sortByKeyQuery ||
    sortKey ||
    GameSessionsSummarySortKeyParamParameter.GamingRevenue) as GameSessionsSummarySortKeyParamParameter;

  const sortKeyForSessions = (sortByKeyQuery ||
    sortKey ||
    GameSessionsSortKeyParamParameter.GamingRevenue) as GameSessionsSortKeyParamParameter;

  const [debouncedGamingRevenueComparisonValue] = useDebouncedValue(gamingRevenueComparisonValueParam, { wait: 500 });

  return useMemo(() => {
    return {
      ...(debouncedUserId && debouncedUserId.length > 0 ? { userId: Number(debouncedUserId) } : {}),
      ...(debouncedGameId && debouncedGameId.length > 0 ? { gameId: Number(debouncedGameId) } : {}),
      ...(formType === GameSessionFormType.SUMMARIES
        ? {
            groupByKey: (groupByKeyQuery ||
              groupByKey ||
              GameSessionsSummaryGroupByKeyParamParameter.Game) as GameSessionsSummaryGroupByKeyParamParameter,
          }
        : {}),
      ...(gamingRevenueComparisonParam &&
      gamingRevenueComparisonParam !== GameSessionsSummaryGamingRevenueComparisonParameter.None &&
      debouncedGamingRevenueComparisonValue
        ? {
            gamingRevenueComparison: gamingRevenueComparisonParam,
            gamingRevenueComparisonValue: Number(debouncedGamingRevenueComparisonValue),
          }
        : {
            gamingRevenueComparison: GameSessionsSummaryGamingRevenueComparisonParameter.None,
          }),
      startDate: Number(startDateQuery) || getUnixTime(startDate),
      endDate: Number(endDateQuery) || getUnixTime(endDate),
      sortKey: formType === GameSessionFormType.SUMMARIES ? sortKeyForSummaries : sortKeyForSessions,
      sortOrder: (sortByOrderQuery as SortOrderParamParameter) || sortOrder || SortOrderParamParameter.descending,
      pageSize,
      page: Number(pageQuery) || page,
    };
  }, [
    debouncedGameId,
    debouncedGamingRevenueComparisonValue,
    debouncedUserId,
    endDate,
    endDateQuery,
    formType,
    gamingRevenueComparisonParam,
    groupByKey,
    groupByKeyQuery,
    page,
    pageQuery,
    pageSize,
    sortByOrderQuery,
    sortKeyForSessions,
    sortKeyForSummaries,
    sortOrder,
    startDate,
    startDateQuery,
  ]);
}
