import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import {
  EContentFilterBadRequestTypeSchema,
  getGetContentFiltersQueryKey,
  useArchiveContentFilter,
} from '@greenisland-api';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Stack } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

import { ExtendedContentFilterSchema } from './Forms/helpers/ContentFilterFormContext';
import { CONTENT_FILTERS_ERROR_REASONS } from '../../Constants/ContentFilterErrorReasons';

interface Props {
  open: boolean;
  onClose: () => void;
  contentFilter: ExtendedContentFilterSchema;
}

const ContentFilterArchiveDialog = ({ open, onClose, contentFilter }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    shouldFocusError: true,
  });

  const { handleSubmit } = methods;

  const { mutate: archiveContentFilter, isLoading } = useArchiveContentFilter({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetContentFiltersQueryKey());
        onClose();
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: error => {
        if (error) {
          if ('type' in error && error.type) {
            const errorReason: EContentFilterBadRequestTypeSchema | undefined = error.type;
            const translationKey = errorReason ? CONTENT_FILTERS_ERROR_REASONS[errorReason] : 'somethingWentWrong';
            enqueueSnackbar(t(translationKey), { variant: 'error' });
          } else {
            enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
          }
        }
      },
    },
  });

  const onSubmit = () => {
    archiveContentFilter({
      contentFilterId: contentFilter.id,
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {t('content.contentFilter.archive.popup.title', { contentFilterName: contentFilter?.name })}
      </DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>{t('content.contentFilter.archive.popup.body')}</Stack>
            <DialogActions sx={{ px: 3, py: 2 }}>
              <Button variant="outlined" onClick={onClose}>
                {t('close')}
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                {t('archive')}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default ContentFilterArchiveDialog;
