/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EClosedLoopTypeSchema = (typeof EClosedLoopTypeSchema)[keyof typeof EClosedLoopTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EClosedLoopTypeSchema = {
  None: 'None',
  BankLoop: 'BankLoop',
  PaypalLoop: 'PaypalLoop',
  NetellerLoop: 'NetellerLoop',
  SkrillLoop: 'SkrillLoop',
  CashLoop: 'CashLoop',
} as const;
