import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { BeChargeVoucherTransactionSchema, useGetTransactionHistory } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, capitalize, LinearProgress, Stack, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { getUnixTime, subWeeks } from 'date-fns';

import {
  CheckboxIcon,
  DataGridContainer,
  FontAwesomeIcon,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';
import DataGridCustomPagination from '@greenisland-common/components/atoms/DataGridPagination/DataGridCustomPagination';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { getDateTimeFromUnix } from '../../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../../app/hooks';
import BechargeOrderDetailsDialog from './BechargeOrderDetailsDialog';
import VouchersDatesFilter from './VouchersDatesFilter';

const PAGE_SIZE = 25;

const VouchersTransactions = () => {
  const { t } = useTranslation();

  const canReadBechargeTransactions = usePermission(OnlineCasinoPermissions.getTransactionHistory);

  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [selectedBechargeOrderId, setSelectedBechargeOrderId] = useState<string | undefined>(undefined);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [voucherStartDate, setVoucherStartDate] = useState(subWeeks(new Date(), 1));
  const [voucherEndDate, setVoucherEndDate] = useState(new Date());

  const {
    data: bechargeTransactions,
    isLoading,
    isError,
  } = useGetTransactionHistory(
    {
      voucherStart: getUnixTime(voucherStartDate),
      voucherEnd: getUnixTime(voucherEndDate),
    },
    {
      query: { enabled: canReadBechargeTransactions, keepPreviousData: true },
    }
  );

  const columns = useMemo<GridColumns<BeChargeVoucherTransactionSchema>>(
    () => [
      {
        field: 'id',
        headerName: capitalize(t('id')),
        sortable: false,
        minWidth: 90,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'date',
        headerName: capitalize(t('date')),
        sortable: true,
        minWidth: 100,
        flex: 0.1,
        renderCell: params => {
          const date = params.value;
          if (date) {
            const formattedDaysDate = getDateTimeFromUnix(date).split(' ')[0];
            const formattedHoursDate = getDateTimeFromUnix(date).split(' ')[1];
            return (
              <Box>
                <Typography variant="body2">{formattedDaysDate}</Typography>
                <Typography variant="body2" color="GrayText">
                  {formattedHoursDate}
                </Typography>
              </Box>
            );
          }
          return <Typography variant="body2">-</Typography>;
        },
      },
      {
        field: 'productId',
        headerName: capitalize(t('content.becharge.vouchersProducts.table.productId')),
        sortable: false,
        minWidth: 100,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'value',
        headerName: capitalize(t('content.becharge.vouchersProducts.table.value')),
        sortable: false,
        minWidth: 90,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'margin',
        headerName: capitalize(t('content.becharge.vouchersProducts.table.margin')),
        sortable: false,
        minWidth: 90,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'type',
        headerName: capitalize(t('type')),
        sortable: false,
        minWidth: 90,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'reference',
        headerName: capitalize(t('reference')),
        sortable: false,
        minWidth: 100,
        flex: 0.1,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },

      {
        field: 'test',
        headerName: capitalize(t('content.becharge.vouchersProducts.table.test')),
        sortable: false,
        minWidth: 80,
        flex: 0.1,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 50,
        flex: 0.05,
        getActions: params => [
          <>
            <Tooltip title={t('content.contentFilter.tableHeaders.details')} key="details">
              <>
                <GridActionsCellItem
                  icon={<FontAwesomeIcon icon={faEye} />}
                  title={t('content.contentFilter.tableHeaders.details')}
                  label={t('content.contentFilter.tableHeaders.details')}
                  onClick={() => onChangeDialogStatusHandler(true, params.row.reference)}
                />
              </>
            </Tooltip>
          </>,
        ],
      },
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      bechargeTransactions?.transactions?.map<BeChargeVoucherTransactionSchema>(transaction => ({
        ...transaction,
      })) ?? [],
    [bechargeTransactions?.transactions]
  );

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setPageSize(value);
    setPage(0);
  };

  const onChangeDialogStatusHandler = (isOpen: boolean, orderId?: string) => {
    if (isOpen) {
      setIsDetailsDialogOpen(isOpen);
      setSelectedBechargeOrderId(orderId);
    } else {
      setIsDetailsDialogOpen(isOpen);
      setSelectedBechargeOrderId(undefined);
    }
  };

  return (
    <Stack spacing={2} mt={4}>
      <PermissionWrapper
        errorMessage={t('content.becharge.voucherTransactions.permissions.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getTransactionHistory}
      >
        <>
          <VouchersDatesFilter
            voucherStartDate={voucherStartDate}
            setVoucherStartDate={setVoucherStartDate}
            voucherEndDate={voucherEndDate}
            setVoucherEndDate={setVoucherEndDate}
          />
          <DataGridContainer>
            <LimitedDataGrid
              density="compact"
              autoHeight
              rowHeight={80}
              loading={isLoading}
              columns={columns}
              rows={rows}
              pagination
              page={page}
              pageSize={pageSize}
              paginationMode="client"
              components={{
                LoadingOverlay: LinearProgress,
                Footer: () => (
                  <>
                    <DataGridCustomPagination
                      page={page}
                      rowsPerPage={pageSize}
                      rowsPerPageOptions={[10, 25, 50, 100, 200]}
                      count={
                        bechargeTransactions?.transactions?.length
                          ? Math.ceil(bechargeTransactions?.transactions?.length / pageSize)
                          : 0
                      }
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      paginationMode="client"
                    />
                  </>
                ),
              }}
            />
          </DataGridContainer>
          {selectedBechargeOrderId ? (
            <BechargeOrderDetailsDialog
              onClose={() => onChangeDialogStatusHandler(false)}
              open={isDetailsDialogOpen}
              selectedBechargeOrderId={selectedBechargeOrderId}
            />
          ) : null}
        </>
      </PermissionWrapper>
    </Stack>
  );
};

export default VouchersTransactions;
