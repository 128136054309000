import { FontAwesomeIcon as RFontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';

/**
 * This component is a wrapper of FontAwesomeIcon base component
 * so we can apply MUI style on it with the styled property or sx.
 */
const FontAwesomeIcon = styled(RFontAwesomeIcon)({});

export default FontAwesomeIcon;
