import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Box, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { endOfMonth, getUnixTime, startOfMonth } from 'date-fns';

export enum GameProviderSearchFilters {
  PROVIDER = 'provider',
  START_DATE = 'startdate',
  END_DATE = 'enddate',
}

const GameProviderDateFilters = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate = searchParams.get(GameProviderSearchFilters.START_DATE);

  const updateQueryParamsHandler = useCallback(
    (date: Date | null) => {
      searchParams.set(
        GameProviderSearchFilters.START_DATE,
        String(getUnixTime(startOfMonth(date ? date : new Date())))
      );
      searchParams.set(GameProviderSearchFilters.END_DATE, String(getUnixTime(endOfMonth(date ? date : new Date()))));
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  return (
    <Box>
      <DatePicker
        label={t('yearAndMonth')}
        value={startDate ? new Date(Number(startDate) * 1000) : new Date()}
        maxDate={new Date()}
        views={['month', 'year']}
        onChange={date => updateQueryParamsHandler(date)}
        renderInput={params => <TextField {...params} size="small" helperText={null} />}
      />
    </Box>
  );
};

export default GameProviderDateFilters;
