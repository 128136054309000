/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Backoffice GameServer - Client api
 * API definition for the backoffice of a gameserver product type
 * OpenAPI spec version: 1.3.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type { Game, GamesPaginated, QueryGamesParams } from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Query games
 * @summary Query games
 */
export const useQueryGamesHook = () => {
  const queryGames = useCustomInstance<GamesPaginated>();

  return (params?: QueryGamesParams, signal?: AbortSignal) => {
    return queryGames({ url: `/games`, method: 'get', params, signal });
  };
};

export const getQueryGamesQueryKey = (params?: QueryGamesParams) => [`/games`, ...(params ? [params] : [])] as const;

export const useQueryGamesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useQueryGamesHook>>>,
  TError = unknown
>(
  params?: QueryGamesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryGamesHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryGamesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getQueryGamesQueryKey(params);

  const queryGames = useQueryGamesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useQueryGamesHook>>>> = ({ signal }) =>
    queryGames(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type QueryGamesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useQueryGamesHook>>>>;
export type QueryGamesQueryError = unknown;

/**
 * @summary Query games
 */
export const useQueryGames = <TData = Awaited<ReturnType<ReturnType<typeof useQueryGamesHook>>>, TError = unknown>(
  params?: QueryGamesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useQueryGamesHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useQueryGamesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Add game
 * @summary Add game
 */
export const useAddGameHook = () => {
  const addGame = useCustomInstance<Game>();

  return (game: Game) => {
    return addGame({ url: `/games`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: game });
  };
};

export const useAddGameMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameHook>>>,
    TError,
    { data: Game },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<ReturnType<typeof useAddGameHook>>>, TError, { data: Game }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const addGame = useAddGameHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddGameHook>>>,
    { data: Game }
  > = props => {
    const { data } = props ?? {};

    return addGame(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddGameMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddGameHook>>>>;
export type AddGameMutationBody = Game;
export type AddGameMutationError = unknown;

/**
 * @summary Add game
 */
export const useAddGame = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddGameHook>>>,
    TError,
    { data: Game },
    TContext
  >;
}) => {
  const mutationOptions = useAddGameMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Update game
 * @summary Update game
 */
export const usePutGameHook = () => {
  const putGame = useCustomInstance<Game>();

  return (gameReference: number, game: Game) => {
    return putGame({
      url: `/games/${gameReference}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: game,
    });
  };
};

export const usePutGameMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutGameHook>>>,
    TError,
    { gameReference: number; data: Game },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePutGameHook>>>,
  TError,
  { gameReference: number; data: Game },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const putGame = usePutGameHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePutGameHook>>>,
    { gameReference: number; data: Game }
  > = props => {
    const { gameReference, data } = props ?? {};

    return putGame(gameReference, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutGameMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof usePutGameHook>>>>;
export type PutGameMutationBody = Game;
export type PutGameMutationError = unknown;

/**
 * @summary Update game
 */
export const usePutGame = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutGameHook>>>,
    TError,
    { gameReference: number; data: Game },
    TContext
  >;
}) => {
  const mutationOptions = usePutGameMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Delete game
 * @summary Delete game
 */
export const useDeleteGameHook = () => {
  const deleteGame = useCustomInstance<void>();

  return (gameReference: number) => {
    return deleteGame({ url: `/games/${gameReference}`, method: 'delete' });
  };
};

export const useDeleteGameMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteGameHook>>>,
    TError,
    { gameReference: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeleteGameHook>>>,
  TError,
  { gameReference: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const deleteGame = useDeleteGameHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeleteGameHook>>>,
    { gameReference: number }
  > = props => {
    const { gameReference } = props ?? {};

    return deleteGame(gameReference);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteGameMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useDeleteGameHook>>>>;

export type DeleteGameMutationError = unknown;

/**
 * @summary Delete game
 */
export const useDeleteGame = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteGameHook>>>,
    TError,
    { gameReference: number },
    TContext
  >;
}) => {
  const mutationOptions = useDeleteGameMutationOptions(options);

  return useMutation(mutationOptions);
};
