import { useSearchParams } from 'react-router-dom';

import { GameFilterType } from '../constants';

export default function useGetGameFiltersQuery() {
  const [searchParams] = useSearchParams();

  const pageQuery = searchParams.get(GameFilterType.PAGE);
  const pageSizeQuery = searchParams.get(GameFilterType.PAGE_SIZE);
  const sortByQuery = searchParams.get(GameFilterType.SORT_BY);
  const searchQuery = searchParams.get(GameFilterType.SEARCH);
  const themesQuery = searchParams.get(GameFilterType.THEME);
  const categoriesQuery = searchParams.get(GameFilterType.CATEGORY);
  const studiosQuery = searchParams.get(GameFilterType.STUDIO);
  const additionalFiltersQuery = searchParams.get(GameFilterType.ADDITIONAL);

  return {
    pageQuery,
    pageSizeQuery,
    sortByQuery,
    searchQuery,
    themesQuery,
    studiosQuery,
    categoriesQuery,
    additionalFiltersQuery,
  };
}
