import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { faAdd, faGamepad, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ErrorSchema } from '@greenisland-api';
import { GameServerPermissions } from '@greenisland-core/permissions/GameServerPermissions';
import { Game, getQueryGamesQueryKey, useDeleteGame, useQueryGames } from '@greenisland-store/gameServer';
import { useDebouncedValue } from '@lilib/hooks';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, InputBase, LinearProgress, Paper, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import ConfirmDialog from 'src/app/components/ConfirmDialog';

import {
  CheckboxIcon,
  DataGridContainer,
  DataGridPagination,
  FontAwesomeIcon,
  StyledDataGrid,
} from '@greenisland-common/components/atoms';
import ErrorState from '@greenisland-common/components/molecules/ErrorState';

import { usePermission } from '../../../app/hooks';
import EditGameForm from './EditGameForm';

const PAGE_SIZE = 25;

type Row = Game;

const Games = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedGame, setSelectedGame] = useState<Game | null>(null);
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebouncedValue(search, { wait: 500 });
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<{ isOpen: boolean; gameReference?: number }>(
    {
      isOpen: false,
    }
  );
  const { enqueueSnackbar } = useSnackbar();
  const canReadGame = usePermission(GameServerPermissions.queryGames);
  const canEditGame = usePermission(GameServerPermissions.putGame);
  const canDeleteGame = usePermission(GameServerPermissions.deleteGame);
  const canAddGame = usePermission(GameServerPermissions.addGame);
  const queryClient = useQueryClient();

  const {
    data: games,
    isLoading,
    isError,
  } = useQueryGames(
    { gameName: debouncedSearch, pageSize: pageSize, page: page + 1 },
    { query: { enabled: canReadGame, keepPreviousData: true } }
  );

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'name',
        headerName: t('content.gameserver.game.gameName'),
        sortable: false,
        minWidth: 250,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'gameReference',
        headerName: t('content.gameserver.game.gameReference'),
        sortable: false,
        minWidth: 250,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: 'availableForTournament',
        headerName: t('content.gameserver.game.availableForTournament'),
        type: 'boolean',
        flex: 0.1,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'enabled',
        headerName: t('content.gameserver.game.enabled'),
        type: 'boolean',
        flex: 0.1,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        sortable: false,
        minWidth: 100,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 150,
        flex: 0.1,
        headerName: t('content.gameserver.game.actions'),
        getActions: (params: GridRowParams<Row>) => [
          <>
            <Tooltip title={t('content.gameserver.game.demo')} key="details">
              <>
                <GridActionsCellItem
                  title={t('content.gameserver.game.demo')}
                  label={t('content.gameserver.game.demo')}
                  icon={<FontAwesomeIcon icon={faGamepad} />}
                  onClick={() => {
                    if (params?.row?.gameReference) {
                      window.open(params?.row?.demoUrl, '_blank');
                    }
                  }}
                  key="edit"
                />
              </>
            </Tooltip>
            {canEditGame && (
              <Tooltip title={t('content.gameserver.game.edit')} key="details">
                <>
                  <GridActionsCellItem
                    title={t('content.gameserver.game.edit')}
                    label={t('content.gameserver.game.edit')}
                    icon={<FontAwesomeIcon icon={faPencil} />}
                    onClick={() => {
                      if (params?.row?.gameReference) {
                        setIsEdit(true);
                        setSelectedGame(params.row);
                      }
                    }}
                    key="edit"
                  />
                </>
              </Tooltip>
            )}
            {canDeleteGame && (
              <Tooltip title={t('content.gameserver.game.delete')} key="delete">
                <>
                  <GridActionsCellItem
                    title={t('content.gameserver.game.delete')}
                    label={t('content.gameserver.game.delete')}
                    icon={<FontAwesomeIcon icon={faTrash} sx={{ marginLeft: 1, color: 'error.main' }} />}
                    onClick={() => {
                      if (params?.row?.gameReference) {
                        setDeleteConfirmationDialog({ isOpen: true, gameReference: params?.row?.gameReference });
                      }
                    }}
                    key="delete"
                  />
                </>
              </Tooltip>
            )}
          </>,
        ],
      },
    ],
    [canDeleteGame, canEditGame, t]
  );

  const deleteGame = () => {
    if (deleteConfirmationDialog.gameReference) {
      deleteMutation.mutate({ gameReference: deleteConfirmationDialog.gameReference });
    }
  };

  const deleteMutation = useDeleteGame({
    mutation: {
      onSuccess: async () => {
        queryClient.invalidateQueries(getQueryGamesQueryKey());
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: (error: ErrorSchema) => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  const rows = useMemo(
    () =>
      games?.entries?.map<Row>(game => ({
        ...game,
      })) ?? [],
    [games?.entries]
  );

  return (
    <Stack spacing={2}>
      {!isError ? (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              [theme.breakpoints.up('xs')]: {
                flexDirection: 'column-reverse',
                gap: 2,
              },
              [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
              },
            }}
          >
            <Paper
              sx={{
                [theme.breakpoints.up('xs')]: {
                  width: '100%',
                  backgroundColor: 'white',
                  height: 'fit-content',
                },
                [theme.breakpoints.up('md')]: {
                  width: '30%',
                },
              }}
              variant="outlined"
              elevation={0}
            >
              <Box display="flex" alignItems="center" flexWrap="wrap" paddingX={2} paddingY={1}>
                <Box flexGrow={1}>
                  <InputBase
                    fullWidth
                    placeholder={t('content.gameserver.game.addGameSearchInput')}
                    onChange={e => setSearch(e.target.value)}
                  />
                </Box>
                <SearchIcon />
              </Box>
            </Paper>
            {canAddGame && (
              <Button
                onClick={() => navigate(`../addGame`)}
                variant="contained"
                size="medium"
                startIcon={<FontAwesomeIcon icon={faAdd} />}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    mr: 0,
                  },
                  [theme.breakpoints.up('md')]: {
                    mr: 2,
                  },
                }}
              >
                {t('content.gameserver.game.addGameButtonText')}
              </Button>
            )}
          </Box>
          <DataGridContainer>
            <StyledDataGrid
              density="compact"
              autoHeight
              rowHeight={80}
              getRowId={x => x.gameReference}
              loading={isLoading}
              rows={rows}
              columns={columns}
              pagination
              page={page}
              rowCount={games?.totalNumberOfEntries}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
              disableDensitySelector
              disableSelectionOnClick
              disableColumnFilter
              disableColumnSelector
              disableColumnMenu
              disableChildrenSorting
              disableChildrenFiltering
              disableMultipleColumnsSorting
              disableColumnResize
              disableColumnReorder
              isRowSelectable={() => false}
              components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
          <EditGameForm open={isEdit} setOpen={setIsEdit} selectedGame={selectedGame} />
          <ConfirmDialog
            title={t('content.gameserver.game.deleteform.title')}
            content={t('content.gameserver.game.deleteform.description')}
            isOpen={deleteConfirmationDialog.isOpen}
            setIsOpen={(open: boolean) => setDeleteConfirmationDialog({ isOpen: open })}
            onConfirm={deleteGame}
          />
        </>
      ) : (
        <ErrorState errorMessage="error" />
      )}
    </Stack>
  );
};

export default Games;
