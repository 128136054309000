import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Dialog, styled, Tooltip } from '@mui/material';

const CloseIcon = styled(Button)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  zIndex: 100,
}));

const DragIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  left: 0,
  zIndex: 100,
  cursor: 'move',
  background: theme.palette.primary.main,
  color: 'white',
  width: 48,
  minWidth: 48,
  maxWidth: 48,
  height: 48,
  minHeight: 48,
  maxHeight: 48,
  borderRadius: '50%',
  boxShadow: '0 1px 3px rgb(0 0 0 / 20%), 0 1px 1px rgb(0 0 0 / 14%), 0 2px 1px rgb(0 0 0 / 12%)',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    pointerEvents: 'auto',
    width: '100rem',
    height: '45rem',
    [theme.breakpoints.down('xl')]: {
      width: '90rem',
      height: '35rem',
    },
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: 'auto',
    },
    overflow: 'hidden',
  },
  '& .MuiDialog-root': {
    pointerEvents: 'none',
  },
  '& .MuiDialog-container': {
    alignItems: 'flex-end',
  },
}));

const PdfDialog = (props: { openPdfDialog: boolean; setOpenPdfDialog: (state: boolean) => void; image: string }) => {
  const { openPdfDialog, setOpenPdfDialog, image } = props;
  const { t } = useTranslation();

  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const [isPanning, setPanning] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dialogPosition, setDialogPosition] = useState({ oldClientX: 0, oldClientY: 0, x: 0, y: 0 });
  const [position, setPosition] = useState({ oldClientX: 0, oldClientY: 0, x: 0, y: 0 });

  const onMouseDownDialog = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDragging(true);
    setDialogPosition({
      ...dialogPosition,
      oldClientX: e.clientX,
      oldClientY: e.clientY,
    });
  };

  useEffect(() => {
    const mouseup = () => {
      setPanning(false);
      setIsDragging(false);
    };

    const mousemove = (e: MouseEvent) => {
      if (isPanning) {
        switch (rotation) {
          case 90:
          case -270:
            setPosition({
              ...position,
              x: position.x + e.clientY / scale - position.oldClientY,
              y: position.y - e.clientX / scale + position.oldClientX,
              oldClientX: e.clientX / scale,
              oldClientY: e.clientY / scale,
            });
            break;
          case 180:
          case -180:
            setPosition({
              ...position,
              x: position.x - e.clientX / scale + position.oldClientX,
              y: position.y - e.clientY / scale + position.oldClientY,
              oldClientX: e.clientX / scale,
              oldClientY: e.clientY / scale,
            });
            break;
          case 270:
          case -90:
            setPosition({
              ...position,
              x: position.x - e.clientY / scale + position.oldClientY,
              y: position.y + e.clientX / scale - position.oldClientX,
              oldClientX: e.clientX / scale,
              oldClientY: e.clientY / scale,
            });
            break;
          default:
            setPosition({
              ...position,
              x: position.x + e.clientX / scale - position.oldClientX,
              y: position.y + e.clientY / scale - position.oldClientY,
              oldClientX: e.clientX / scale,
              oldClientY: e.clientY / scale,
            });
        }
      } else if (isDragging) {
        setDialogPosition({
          ...dialogPosition,
          x: dialogPosition.x + e.clientX - dialogPosition.oldClientX,
          y: dialogPosition.y + e.clientY - dialogPosition.oldClientY,
          oldClientX: e.clientX,
          oldClientY: e.clientY,
        });
      }
    };

    window.addEventListener('mouseup', mouseup);
    window.addEventListener('mousemove', mousemove);

    return () => {
      window.removeEventListener('mouseup', mouseup);
      window.removeEventListener('mousemove', mousemove);
    };
  });

  const closeDialog = () => {
    setOpenPdfDialog(false);
    setRotation(0);
    setScale(1);
  };

  return (
    <StyledDialog
      open={openPdfDialog}
      onClose={closeDialog}
      maxWidth="lg"
      fullWidth
      disableEnforceFocus
      disableAutoFocus
      disableScrollLock
      BackdropComponent={() => null}
      style={{ transform: `translate(${dialogPosition.x}px, ${dialogPosition.y}px)` }}
    >
      <DragIcon onMouseDown={onMouseDownDialog}>
        <Tooltip title={t('moveScreen')}>
          <FontAwesomeIcon icon={faArrowsAlt} />
        </Tooltip>
      </DragIcon>
      <Box>
        <Tooltip title={t('close')}>
          <CloseIcon variant="contained" onClick={closeDialog}>
            ✕
          </CloseIcon>
        </Tooltip>
      </Box>
      <Box component="iframe" src={image} width="100%" height="100%">
        No Support
      </Box>
    </StyledDialog>
  );
};

export default PdfDialog;
