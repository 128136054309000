/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  DefaultResponse,
  GetInterventionsForUserParams,
  GetInterventionsParams,
  InterventionControllerBadRequestResponse,
  InterventionSchema,
  NotFoundResponse,
  PagedInterventionsSchema,
  UnauthorizedResponse,
  UpdateInterventionSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Retrieve the interventions that satisfy the query parameters
 * @summary Retrieve the interventions that satisfy the query parameters
 */
export const useGetInterventionsHook = () => {
  const getInterventions = useCustomInstance<PagedInterventionsSchema>();

  return (params?: GetInterventionsParams, signal?: AbortSignal) => {
    return getInterventions({ url: `/interventions`, method: 'get', params, signal });
  };
};

export const getGetInterventionsQueryKey = (params?: GetInterventionsParams) =>
  [`/interventions`, ...(params ? [params] : [])] as const;

export const useGetInterventionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInterventionsHook>>>,
  TError = InterventionControllerBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetInterventionsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInterventionsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInterventionsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInterventionsQueryKey(params);

  const getInterventions = useGetInterventionsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetInterventionsHook>>>> = ({ signal }) =>
    getInterventions(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetInterventionsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetInterventionsHook>>>>;
export type GetInterventionsQueryError =
  | InterventionControllerBadRequestResponse
  | UnauthorizedResponse
  | DefaultResponse;

/**
 * @summary Retrieve the interventions that satisfy the query parameters
 */
export const useGetInterventions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInterventionsHook>>>,
  TError = InterventionControllerBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetInterventionsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInterventionsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInterventionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Create an intervention for the user. All interventions created in the backoffice have manual action required set to true.
 * @summary Create an intervention for the user. All interventions created in the backoffice have manual action required set to true.
 */
export const useCreateInterventionForUserHook = () => {
  const createInterventionForUser = useCustomInstance<InterventionSchema>();

  return (userId: number, updateInterventionSchema: UpdateInterventionSchema) => {
    return createInterventionForUser({
      url: `/interventions/user/${userId}`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateInterventionSchema,
    });
  };
};

export const useCreateInterventionForUserMutationOptions = <
  TError = InterventionControllerBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateInterventionForUserHook>>>,
    TError,
    { userId: number; data: UpdateInterventionSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCreateInterventionForUserHook>>>,
  TError,
  { userId: number; data: UpdateInterventionSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const createInterventionForUser = useCreateInterventionForUserHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCreateInterventionForUserHook>>>,
    { userId: number; data: UpdateInterventionSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return createInterventionForUser(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateInterventionForUserMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCreateInterventionForUserHook>>>
>;
export type CreateInterventionForUserMutationBody = UpdateInterventionSchema;
export type CreateInterventionForUserMutationError =
  | InterventionControllerBadRequestResponse
  | UnauthorizedResponse
  | DefaultResponse;

/**
 * @summary Create an intervention for the user. All interventions created in the backoffice have manual action required set to true.
 */
export const useCreateInterventionForUser = <
  TError = InterventionControllerBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCreateInterventionForUserHook>>>,
    TError,
    { userId: number; data: UpdateInterventionSchema },
    TContext
  >;
}) => {
  const mutationOptions = useCreateInterventionForUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieve the interventions for the user
 * @summary Retrieve the interventions for the user
 */
export const useGetInterventionsForUserHook = () => {
  const getInterventionsForUser = useCustomInstance<PagedInterventionsSchema>();

  return (userId: number, params?: GetInterventionsForUserParams, signal?: AbortSignal) => {
    return getInterventionsForUser({ url: `/interventions/user/${userId}`, method: 'get', params, signal });
  };
};

export const getGetInterventionsForUserQueryKey = (userId: number, params?: GetInterventionsForUserParams) =>
  [`/interventions/user/${userId}`, ...(params ? [params] : [])] as const;

export const useGetInterventionsForUserQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInterventionsForUserHook>>>,
  TError = InterventionControllerBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  userId: number,
  params?: GetInterventionsForUserParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInterventionsForUserHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInterventionsForUserHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInterventionsForUserQueryKey(userId, params);

  const getInterventionsForUser = useGetInterventionsForUserHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetInterventionsForUserHook>>>> = ({ signal }) =>
    getInterventionsForUser(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetInterventionsForUserQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetInterventionsForUserHook>>>
>;
export type GetInterventionsForUserQueryError =
  | InterventionControllerBadRequestResponse
  | UnauthorizedResponse
  | DefaultResponse;

/**
 * @summary Retrieve the interventions for the user
 */
export const useGetInterventionsForUser = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInterventionsForUserHook>>>,
  TError = InterventionControllerBadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  userId: number,
  params?: GetInterventionsForUserParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInterventionsForUserHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInterventionsForUserQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Complete the intervention with given intervention id. Sets the status to Completed. Precondition - Status must new 'InProgress'.
 * @summary Complete the intervention with given intervention id.
 */
export const useCompleteInterventionHook = () => {
  const completeIntervention = useCustomInstance<InterventionSchema>();

  return (interventionId: number) => {
    return completeIntervention({ url: `/interventions/${interventionId}/complete`, method: 'put' });
  };
};

export const useCompleteInterventionMutationOptions = <
  TError = InterventionControllerBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompleteInterventionHook>>>,
    TError,
    { interventionId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCompleteInterventionHook>>>,
  TError,
  { interventionId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const completeIntervention = useCompleteInterventionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCompleteInterventionHook>>>,
    { interventionId: number }
  > = props => {
    const { interventionId } = props ?? {};

    return completeIntervention(interventionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompleteInterventionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCompleteInterventionHook>>>
>;

export type CompleteInterventionMutationError =
  | InterventionControllerBadRequestResponse
  | UnauthorizedResponse
  | DefaultResponse;

/**
 * @summary Complete the intervention with given intervention id.
 */
export const useCompleteIntervention = <
  TError = InterventionControllerBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompleteInterventionHook>>>,
    TError,
    { interventionId: number },
    TContext
  >;
}) => {
  const mutationOptions = useCompleteInterventionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Open the intervention with given intervention id. Sets the status to InProgress. Precondition - Status must new 'NEW'.
 * @summary Open the intervention with given intervention id. Sets the status to InProgress.
 */
export const useOpenInterventionHook = () => {
  const openIntervention = useCustomInstance<InterventionSchema>();

  return (interventionId: number) => {
    return openIntervention({ url: `/interventions/${interventionId}/open`, method: 'put' });
  };
};

export const useOpenInterventionMutationOptions = <
  TError = InterventionControllerBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useOpenInterventionHook>>>,
    TError,
    { interventionId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useOpenInterventionHook>>>,
  TError,
  { interventionId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const openIntervention = useOpenInterventionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useOpenInterventionHook>>>,
    { interventionId: number }
  > = props => {
    const { interventionId } = props ?? {};

    return openIntervention(interventionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type OpenInterventionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useOpenInterventionHook>>>
>;

export type OpenInterventionMutationError =
  | InterventionControllerBadRequestResponse
  | UnauthorizedResponse
  | DefaultResponse;

/**
 * @summary Open the intervention with given intervention id. Sets the status to InProgress.
 */
export const useOpenIntervention = <
  TError = InterventionControllerBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useOpenInterventionHook>>>,
    TError,
    { interventionId: number },
    TContext
  >;
}) => {
  const mutationOptions = useOpenInterventionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Close the intervention with given intervention id. Sets the status to Closed. Note - cannot be undone. Status Completed may not be closed.
 * @summary Close the intervention with given intervention id. Sets the status to Closed.
 */
export const useCloseInterventionHook = () => {
  const closeIntervention = useCustomInstance<InterventionSchema>();

  return (interventionId: number) => {
    return closeIntervention({ url: `/interventions/${interventionId}/close`, method: 'put' });
  };
};

export const useCloseInterventionMutationOptions = <
  TError = InterventionControllerBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCloseInterventionHook>>>,
    TError,
    { interventionId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCloseInterventionHook>>>,
  TError,
  { interventionId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const closeIntervention = useCloseInterventionHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCloseInterventionHook>>>,
    { interventionId: number }
  > = props => {
    const { interventionId } = props ?? {};

    return closeIntervention(interventionId);
  };

  return { mutationFn, ...mutationOptions };
};

export type CloseInterventionMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCloseInterventionHook>>>
>;

export type CloseInterventionMutationError =
  | InterventionControllerBadRequestResponse
  | UnauthorizedResponse
  | DefaultResponse;

/**
 * @summary Close the intervention with given intervention id. Sets the status to Closed.
 */
export const useCloseIntervention = <
  TError = InterventionControllerBadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCloseInterventionHook>>>,
    TError,
    { interventionId: number },
    TContext
  >;
}) => {
  const mutationOptions = useCloseInterventionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get the intervention with given intervention id.
 * @summary Get the intervention with given intervention id.
 */
export const useGetInterventionHook = () => {
  const getIntervention = useCustomInstance<InterventionSchema>();

  return (interventionId: number, signal?: AbortSignal) => {
    return getIntervention({ url: `/interventions/${interventionId}`, method: 'get', signal });
  };
};

export const getGetInterventionQueryKey = (interventionId: number) => [`/interventions/${interventionId}`] as const;

export const useGetInterventionQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInterventionHook>>>,
  TError = InterventionControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  interventionId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInterventionHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInterventionHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInterventionQueryKey(interventionId);

  const getIntervention = useGetInterventionHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetInterventionHook>>>> = ({ signal }) =>
    getIntervention(interventionId, signal);

  return { queryKey, queryFn, enabled: !!interventionId, ...queryOptions };
};

export type GetInterventionQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetInterventionHook>>>>;
export type GetInterventionQueryError =
  | InterventionControllerBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the intervention with given intervention id.
 */
export const useGetIntervention = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetInterventionHook>>>,
  TError = InterventionControllerBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  interventionId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetInterventionHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetInterventionQueryOptions(interventionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
