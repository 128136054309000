/**
 * Filters unique elements in a sorted array.
 * If a selector is provided, the elements will be compared based on the field that is the result of that function.
 *
 * @param sortedArray The sorted array that needs to be filtered
 * @param selector An optional function to select the field that needs to be compared
 * @returns A filtered array only containing unique elements
 */
export const uniq = <T>(sortedArray: T[], selector?: (item: T) => unknown) => {
  return sortedArray.filter(
    (element, position, array) =>
      element &&
      (!position || (selector?.(element) ?? element) !== (selector?.(array[position - 1]) ?? array[position - 1]))
  );
};

/**
 * Check if data is an array and if it is not empty
 *
 * @param data The element to check
 * @returns A boolean
 */
export const checkIsArray = (data: unknown): boolean => Array.isArray(data) && data?.length > 0;

/**
 * Return the keys from the array
 * or if param is not an array / is empty, return an empty array
 *
 * @param arr The element of which we want to retrieve the keys
 * @returns An array
 */
export const getArrayKeys = (arr: any) => (checkIsArray(arr) ? Object.keys(arr[0]) : []);
