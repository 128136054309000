import { memo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EMIMETypeSchema, GameStudioSchema, useAddGameStudio, useUpdateGameStudio } from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

import FileUpload from '@greenisland-common/components/molecules/FileUpload';
import Input from '@greenisland-common/components/molecules/Input';

const useStyles = makeStyles({
  dialogContent: { display: 'grid', gridGap: 16 },
  image: { width: '200px', height: 'fit-content' },
  actions: { padding: '16px 24px' },
});

const GameStudioDialog = (props: {
  open: boolean;
  setOpen: (state: boolean) => void;
  gameStudio: GameStudioSchema;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, errors, control, setValue } = useForm<{
    gameStudioName: string;
    urlName: string;
    code: string;
    logo: any;
  }>({
    defaultValues: {
      gameStudioName: props.gameStudio.gameStudioName,
      urlName: props.gameStudio.urlName,
      code: props.gameStudio.code,
      logo: props.gameStudio.logoUrl,
    },
  });

  const { mutate: addGameStudio, isLoading: isAddGameStudioLoading } = useAddGameStudio({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        handleClose();
      },
      onError: error => {
        enqueueSnackbar(error?.message || t('somethingWentWrong'), { variant: 'error' });
      },
    },
  });

  const { mutate: updateGameStudio, isLoading: isUpdateGameStudioLoading } = useUpdateGameStudio({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        handleClose();
      },
      onError: error => {
        enqueueSnackbar(error?.message || t('somethingWentWrong'), { variant: 'error' });
      },
    },
  });

  const handleClose = () => {
    props.setOpen(false);
  };

  const onSubmit = (data: any) => {
    props.gameStudio.gameStudioId
      ? updateGameStudio({ gameStudioId: props.gameStudio.gameStudioId, data })
      : addGameStudio(data);
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={props.open} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{props.gameStudio?.gameStudioId ? t('editGameStudio') : t('addGameStudio')}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Controller
            render={() => (
              <FileUpload
                error={!!errors.logo}
                id="gamestudioImage"
                type="image"
                onChange={file => setValue('logo', file)}
                defaultFile={props.gameStudio.logoUrl}
                buttonTitle="uploadImage"
                requirements={{
                  dimensions: { maxWidth: 180, height: 20 },
                  mimeTypes: [EMIMETypeSchema['image/png']],
                }}
              />
            )}
            control={control}
            rules={{ required: true }}
            defaultValue={props.gameStudio.logoUrl}
            name={'logo'}
          />
          <Input
            fullWidth
            inputRef={register({ required: true })}
            label={t('gameStudio')}
            name="gameStudioName"
            error={!!errors.gameStudioName}
            helperText={errors.gameStudioName && t('required')}
          />
          <Input
            label={t('urlName')}
            name="urlName"
            inputRef={register({ required: true })}
            error={!!errors.urlName}
            helperText={errors.urlName && t('required')}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button variant="text" onClick={handleClose} color="secondary">
            {t('cancel')}
          </Button>
          <LoadingButton
            loading={isAddGameStudioLoading || isUpdateGameStudioLoading}
            variant="contained"
            type="submit"
            color="primary"
          >
            {t('add')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(GameStudioDialog);
