import { useCallback, useMemo, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize, Grid, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';

import PermissionList from '@greenisland-common/components/organisms/TransferList/PermissionList';

import { AgentItem } from '../../../../pages/OnlineCasino/Settings/Management/Components/Forms/helpers/AgentFormContext';

interface Props<T> {
  control: Control;
  name: string;
  leftTitle: string;
  rightTitle: string;
  fallbackText: string;
  allItems: T[];
}

const TransferList = <T extends AgentItem>({
  leftTitle,
  rightTitle,
  fallbackText,
  allItems,
  control,
  name,
}: Props<T>) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { field } = useController({ name: name, control });
  const { value, onChange } = field;

  const [checked, setChecked] = useState<T[]>([]);
  const [left, setLeft] = useState<T[]>(allItems);

  const [leftSearch, setLeftSearch] = useState('');
  const [rightSearch, setRightSearch] = useState('');

  const selectedItems: T[] = useMemo(() => {
    const filteredItems = allItems?.filter(i1 => !value?.some((i2: T) => i1?.id === i2?.id));
    setLeft(filteredItems);
    return value;
  }, [allItems, value]);

  const not = useCallback((a: readonly T[], b: readonly T[]) => a?.filter(value => b?.indexOf(value) === -1), []);
  const intersection = useCallback(
    (a: readonly T[], b: readonly T[]) => a?.filter(value => b?.indexOf(value) !== -1),
    []
  );

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, selectedItems);

  const handleAllRight = () => {
    const filteredItems = left?.filter(item => item?.name?.toLowerCase()?.includes(leftSearch?.toLowerCase()));
    const nonFilteredItems = left?.filter(item => !item?.name?.toLowerCase()?.includes(leftSearch?.toLowerCase()));

    onChange(selectedItems?.concat(filteredItems));
    setLeft(nonFilteredItems);
  };

  const handleCheckedRight = () => {
    onChange(selectedItems?.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left?.concat(rightChecked));
    setChecked(not(checked, rightChecked));
    onChange(not(selectedItems, rightChecked));
  };

  const handleAllLeft = () => {
    const filteredItems = selectedItems?.filter(item =>
      item?.name?.toLowerCase()?.includes(rightSearch?.toLowerCase())
    );
    const nonFilteredItems = selectedItems?.filter(
      item => !item?.name?.toLowerCase()?.includes(rightSearch?.toLowerCase())
    );

    setLeft(left?.concat(filteredItems));
    onChange(nonFilteredItems);
  };

  return (
    <Grid
      container
      display="grid"
      gridTemplateRows="1fr"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      sx={{
        [theme.breakpoints.up('xs')]: {
          gridTemplateColumns: '1fr',
        },
        [theme.breakpoints.up('md')]: {
          gridTemplateColumns: 'repeat(3, minmax(200px, 1fr))',
        },
      }}
    >
      <Grid item>
        <Typography variant="body2" fontSize="medium" fontWeight={600} sx={{ py: 1, pl: 1 }}>
          {capitalize(leftTitle)}
        </Typography>
        <PermissionList
          items={left}
          search={leftSearch}
          setSearch={setLeftSearch}
          checked={leftChecked}
          setChecked={setChecked}
          fallbackText={fallbackText}
        />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="contained"
            size="medium"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
            fullWidth
          >
            {t('transferList.add')}
            <FontAwesomeIcon icon={faAngleRight} style={{ marginLeft: 10 }} />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="contained"
            size="medium"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
            fullWidth
          >
            <FontAwesomeIcon icon={faAngleLeft} style={{ marginRight: 10 }} />
            {t('transferList.remove')}
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="contained"
            size="medium"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
            fullWidth
          >
            {t('transferList.addAll')}
            <FontAwesomeIcon icon={faAnglesRight} style={{ marginLeft: 10 }} />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="contained"
            size="medium"
            onClick={handleAllLeft}
            disabled={selectedItems?.length === 0}
            aria-label="move all left"
            fullWidth
          >
            <FontAwesomeIcon icon={faAnglesLeft} style={{ marginRight: 10 }} />
            {t('transferList.removeAll')}
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body2" fontSize="medium" fontWeight={600} sx={{ py: 1, pl: 1 }}>
          {capitalize(rightTitle)}
        </Typography>
        <PermissionList
          items={selectedItems}
          search={rightSearch}
          setSearch={setRightSearch}
          checked={rightChecked}
          setChecked={setChecked}
          fallbackText={fallbackText}
        />
      </Grid>
    </Grid>
  );
};

export default TransferList;
