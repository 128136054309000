import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  EVerificationStatusSchema,
  getGetUserVerificationsQueryKey,
  useGetAllCountries,
  useGetUserVerifications,
  usePostCommunicateVerificationNeeded,
  VerificationsSchema,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button, CircularProgress, Skeleton, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import { ErrorCard, Link } from '@greenisland-common/components/atoms';

import BankAccounts from './DetailsComponents/BankAccounts/BankAccounts';
import Domicile from './DetailsComponents/Domicile';
import FinancialVerification from './DetailsComponents/financialVerification/FinancialVerification';
import Identity from './DetailsComponents/Identity/Identity';
import Wallets from './DetailsComponents/Wallets';
import { usePermission } from '../../../../../app/hooks';
import { EventStreamParamsType } from '../../../Lookup/Components/DetailsComponents/Constants/Eventstreams';

const VerificationDetailsSkeleton = () => (
  <>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Skeleton variant="text" height={50} width={400} />
      <Box display="flex">
        <Skeleton variant="rectangular" sx={{ mr: 2 }} height={30} width={90} />
        <Skeleton variant="rectangular" height={30} width={300} />
      </Box>
    </Box>
    <Skeleton variant="rectangular" height="70vh" />
  </>
);

const VerificationDetails = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const qc = useQueryClient();

  const canReadCountries = usePermission(OnlineCasinoPermissions.getAllCountries);
  const canReadVerificationDetails = usePermission([
    OnlineCasinoPermissions.getUserVerifications,
    OnlineCasinoPermissions.listCountries,
  ]);
  const canAccessEventStream = usePermission(OnlineCasinoPermissions.getEventstreamStreamId);
  const canCommunicateVerificationNeeded = usePermission(OnlineCasinoPermissions.postCommunicateVerificationNeeded);

  const { data: countries, isLoading: isCountriesLoading } = useGetAllCountries({
    query: { enabled: canReadCountries },
  });
  const { data: userVerifications, isLoading, isError, error } = useGetUserVerifications(userId);
  const communicateVerificationNeededMutation = usePostCommunicateVerificationNeeded({
    mutation: {
      onSuccess: async () => {
        await qc.invalidateQueries(getGetUserVerificationsQueryKey(userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  if (!canReadVerificationDetails) return null;
  if (isLoading || isCountriesLoading) return <VerificationDetailsSkeleton />;
  if (isError || !userVerifications || !countries) return <ErrorCard sx={{ height: 323 }}>{error?.message}</ErrorCard>;

  const communicateVerificationNeeded = () => communicateVerificationNeededMutation.mutate({ userId });

  const checkStatus = (status: string) =>
    status === EVerificationStatusSchema.NeedsVerification || status === EVerificationStatusSchema.Declined;

  const checkIfVerificationIsNeeded = (userVerifications: VerificationsSchema) => {
    for (const [key, value] of Object.entries(userVerifications)) {
      switch (key) {
        case 'identity':
        case 'domicile':
          if (value && checkStatus(value.verificationStatus)) return true;
          break;
        case 'wallets':
        case 'bankAccounts':
          for (const item of value) {
            if (item && checkStatus(item.verificationStatus)) return true;
          }
          break;
        default:
          break;
      }
    }
    return false;
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">{`${userVerifications.userName} - ${t('communicationLanguage', {
          language: userVerifications.language,
        })}`}</Typography>
        <Box>
          {canAccessEventStream && userVerifications?.streamId && (
            <Button
              sx={{ mr: 2 }}
              size="small"
              variant="outlined"
              color="secondary"
              component={Link}
              to={`../../lookup/eventstreams?${EventStreamParamsType.STREAM_ID}=${userVerifications.streamId}`}
            >
              {t('history')}
            </Button>
          )}
          {canCommunicateVerificationNeeded && checkIfVerificationIsNeeded(userVerifications) && (
            <Button variant="outlined" color="primary" size="small" onClick={communicateVerificationNeeded}>
              {communicateVerificationNeededMutation.isLoading ? (
                <CircularProgress size={20} />
              ) : (
                t('communicateVerificationNeeds')
              )}
            </Button>
          )}
        </Box>
      </Box>
      <Identity identity={userVerifications.identity} countries={countries} />
      <Domicile domicile={userVerifications.domicile} countries={countries} />
      <BankAccounts bankAccounts={userVerifications.bankAccounts} />
      <Wallets wallets={userVerifications.wallets} />
      {userVerifications.financialVerification && (
        <FinancialVerification financialVerification={userVerifications.financialVerification} />
      )}
    </>
  );
};

export default memo(VerificationDetails);
