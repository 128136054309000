import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  getGetVaultConfigurationsQueryKey,
  useAddVaultConfiguration,
  VaultConfigurationUpdateSchema,
} from '@greenisland-api';
import { useSnackbar } from 'notistack';

import Input from '@greenisland-common/components/molecules/Input';
import ConfirmDialog from '@greenisland-common/components/organisms/ConfirmDialog';

interface Props {
  open: boolean;
  onClose: () => void;
}

const VaultConfigurationsDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: addVaultConfiguration, isLoading: isAddVaultConfigurationLoading } = useAddVaultConfiguration({
    mutation: {
      onSuccess: () => {
        onClose();
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetVaultConfigurationsQueryKey());
      },
      onError: error => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  const onSubmit = (data: VaultConfigurationUpdateSchema) => {
    return addVaultConfiguration({ data });
  };

  const methods = useForm<VaultConfigurationUpdateSchema>({
    shouldFocusError: true,
    mode: 'onChange',
    defaultValues: {
      codeLength: 0,
      level: 0,
      amount: 0,
      numTokensRequired: 0,
    },
  });

  const { handleSubmit, errors, register } = methods;

  return (
    <ConfirmDialog
      title={t('addConfiguration')}
      isOpen={open}
      onCancel={onClose}
      onConfirm={handleSubmit(onSubmit)}
      loading={isAddVaultConfigurationLoading}
      confirmText={t('add')}
      cancelText={t('cancel')}
    >
      <FormProvider {...methods}>
        <Input
          name="codeLength"
          label={t('codeLength')}
          fullWidth
          error={!!errors.codeLength}
          inputRef={register({ required: true, valueAsNumber: true })}
          helperText={errors.codeLength && t('amountValidationMessageZero')}
          type={'number'}
          size="small"
          sx={{ mb: 2 }}
        />
        <Input
          name="level"
          label={t('level')}
          fullWidth
          error={!!errors.level}
          inputRef={register({ required: true, valueAsNumber: true })}
          helperText={errors.level && t('amountValidationMessageZero')}
          type={'number'}
          size="small"
          sx={{ mb: 2 }}
        />
        <Input
          name="amount"
          label={t('amount')}
          fullWidth
          error={!!errors.amount}
          inputRef={register({ required: true, valueAsNumber: true })}
          helperText={errors.amount && t('amountValidationMessageZero')}
          type={'number'}
          size="small"
          sx={{ mb: 2 }}
        />
        <Input
          name="numTokensRequired"
          label={t('numTokensRequired')}
          fullWidth
          error={!!errors.numTokensRequired}
          inputRef={register({ required: true, valueAsNumber: true })}
          helperText={errors.numTokensRequired && t('amountValidationMessageZero')}
          type={'number'}
          size="small"
          sx={{ mb: 2 }}
        />
      </FormProvider>
    </ConfirmDialog>
  );
};

export default VaultConfigurationsDialog;
