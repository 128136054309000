import { EPromotionalCalendarBadRequestTypeSchema } from '@greenisland-api';

type PromotionalCalendarErrorReasons = {
  [key in EPromotionalCalendarBadRequestTypeSchema]: string;
};

export const PROMOTIONAL_CALENDAR_ERROR_REASONS: PromotionalCalendarErrorReasons = {
  [EPromotionalCalendarBadRequestTypeSchema.promotionalCalendarNotFound]:
    'marketing.promotionalCalendar.errors.promotionalCalendarNotFound',
  [EPromotionalCalendarBadRequestTypeSchema.promotionalCalendarPrizeNotFound]:
    'marketing.promotionalCalendar.errors.promotionalCalendarPrizeNotFound',
  [EPromotionalCalendarBadRequestTypeSchema.noPrizeForRankForMinimumRank]:
    'marketing.promotionalCalendar.errors.noPrizeForRankForMinimumRank',
  [EPromotionalCalendarBadRequestTypeSchema.prizeForRankBelowMinimum]:
    'marketing.promotionalCalendar.errors.prizeForRankBelowMinimum',
  [EPromotionalCalendarBadRequestTypeSchema.prizesWithSameDayOrdinalHaveDifferentStartOrEnd]:
    'marketing.promotionalCalendar.errors.prizesWithSameDayOrdinalHaveDifferentStartOrEnd',
  [EPromotionalCalendarBadRequestTypeSchema.rankDefinedMultipleTimes]:
    'marketing.promotionalCalendar.errors.rankDefinedMultipleTimes',
};
