/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type ESetMaxLimitReasonSchema = (typeof ESetMaxLimitReasonSchema)[keyof typeof ESetMaxLimitReasonSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ESetMaxLimitReasonSchema = {
  RegistrationYoungAdult: 'RegistrationYoungAdult',
  YoungAdult: 'YoungAdult',
  PepOrSanctioned: 'PepOrSanctioned',
  Intervention: 'Intervention',
  DecisionFinancialVerification: 'DecisionFinancialVerification',
  AddictionCategoryDecreased: 'AddictionCategoryDecreased',
  AddictionCategoryIncreased: 'AddictionCategoryIncreased',
} as const;
