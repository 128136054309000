/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type { MutationFunction, UseMutationOptions } from 'react-query';
import { useMutation } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  DefaultResponse,
  FreeSpinsBadRequestContentSchema,
  FreeSpinsSchema,
  NotFoundResponse,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Cancel free spins
 */
export const useCancelFreeSpinsHook = () => {
  const cancelFreeSpins = useCustomInstance<FreeSpinsSchema>();

  return (freeSpinsId: number) => {
    return cancelFreeSpins({ url: `/freeSpins/cancel/${freeSpinsId}`, method: 'put' });
  };
};

export const useCancelFreeSpinsMutationOptions = <
  TError = FreeSpinsBadRequestContentSchema | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelFreeSpinsHook>>>,
    TError,
    { freeSpinsId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCancelFreeSpinsHook>>>,
  TError,
  { freeSpinsId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const cancelFreeSpins = useCancelFreeSpinsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCancelFreeSpinsHook>>>,
    { freeSpinsId: number }
  > = props => {
    const { freeSpinsId } = props ?? {};

    return cancelFreeSpins(freeSpinsId);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelFreeSpinsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useCancelFreeSpinsHook>>>>;

export type CancelFreeSpinsMutationError =
  | FreeSpinsBadRequestContentSchema
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Cancel free spins
 */
export const useCancelFreeSpins = <
  TError = FreeSpinsBadRequestContentSchema | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelFreeSpinsHook>>>,
    TError,
    { freeSpinsId: number },
    TContext
  >;
}) => {
  const mutationOptions = useCancelFreeSpinsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update the freespin game configuration for the given gameId
 */
export const useUpdateFreeSpinsGameConfigurationHook = () => {
  const updateFreeSpinsGameConfiguration = useCustomInstance<void>();

  return (gameId: number) => {
    return updateFreeSpinsGameConfiguration({ url: `/freeSpins/configurations/${gameId}/update`, method: 'put' });
  };
};

export const useUpdateFreeSpinsGameConfigurationMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateFreeSpinsGameConfigurationHook>>>,
    TError,
    { gameId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useUpdateFreeSpinsGameConfigurationHook>>>,
  TError,
  { gameId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const updateFreeSpinsGameConfiguration = useUpdateFreeSpinsGameConfigurationHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useUpdateFreeSpinsGameConfigurationHook>>>,
    { gameId: number }
  > = props => {
    const { gameId } = props ?? {};

    return updateFreeSpinsGameConfiguration(gameId);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateFreeSpinsGameConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useUpdateFreeSpinsGameConfigurationHook>>>
>;

export type UpdateFreeSpinsGameConfigurationMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Update the freespin game configuration for the given gameId
 */
export const useUpdateFreeSpinsGameConfiguration = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useUpdateFreeSpinsGameConfigurationHook>>>,
    TError,
    { gameId: number },
    TContext
  >;
}) => {
  const mutationOptions = useUpdateFreeSpinsGameConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
