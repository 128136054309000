import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useGetCriticalFieldsMismatch } from '@greenisland-api';
import { Box, Button, Stack, Typography } from '@mui/material';

import CriticalFieldsMismatchDialog from '../../../../../Lookup/Components/DetailsComponents/CriticalFieldsMismatch/CriticalFieldsMismatchDialog';
import RegistrationSummaryListItem from './RegistrationSummaryListItem';

const RegistrationCriticalFieldsMismatch = () => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();

  const { data: criticalFieldsMismatch } = useGetCriticalFieldsMismatch(Number(userId), {
    query: { enabled: !!userId },
  });

  const [isMismatchFieldsDialogOpen, setIsMismatchFieldsDialogOpen] = useState(false);

  return (
    <>
      <RegistrationSummaryListItem
        title={t('criticalFieldsMismatch.titles.criticalFieldsMismatch')}
        value={
          <Stack>
            {criticalFieldsMismatch?.mismatchPresent ? (
              <Box>
                <Typography variant="body1" color="error.main" mb={1}>
                  {t('yes')}
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  color="warning"
                  onClick={() => setIsMismatchFieldsDialogOpen(true)}
                >
                  {t('resolve')}
                </Button>
              </Box>
            ) : (
              <Typography variant="body1">{t('no')}</Typography>
            )}
          </Stack>
        }
      />
      {isMismatchFieldsDialogOpen && criticalFieldsMismatch && (
        <CriticalFieldsMismatchDialog
          userId={Number(userId)}
          criticalFieldsMismatchInfo={criticalFieldsMismatch}
          open={isMismatchFieldsDialogOpen}
          setOpen={setIsMismatchFieldsDialogOpen}
        />
      )}
    </>
  );
};

export default RegistrationCriticalFieldsMismatch;
