import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { Box, Button } from '@mui/material';
import { usePermission } from 'src/app/hooks';

import ServiceContractsCreationDialog from './components/ServiceContractsCreationDialog';
import ServiceContractsTable from './components/ServiceContractsTable';

const ServiceContracts = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const canCreateServiceContracts = usePermission(OnlineCasinoPermissions.createServiceContract);

  return (
    <>
      {canCreateServiceContracts && (
        <>
          <Box>
            <Button
              variant="contained"
              size="medium"
              sx={{ mb: 4 }}
              startIcon={<FontAwesomeIcon icon={faAdd} />}
              onClick={() => setIsOpen(true)}
            >
              {t('serviceContracts.create.button')}
            </Button>
          </Box>
          <ServiceContractsCreationDialog
            onClose={() => {
              setIsOpen(false);
            }}
            open={isOpen}
          />
        </>
      )}
      <ServiceContractsTable />
    </>
  );
};

export default ServiceContracts;
