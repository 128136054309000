import { useSearchParams } from 'react-router-dom';

import { TransactionParamsType } from '../Components/DetailsComponents/Constants/Transactions';

export default function useGetTransactionFiltersQuery() {
  const [searchParams] = useSearchParams();

  const startDateQuery = searchParams.get(TransactionParamsType.START_DATE);
  const endDateQuery = searchParams.get(TransactionParamsType.END_DATE);
  const minAmountQuery = searchParams.get(TransactionParamsType.MIN_AMOUNT);
  const maxAmountQuery = searchParams.get(TransactionParamsType.MAX_AMOUNT);
  const minPromoAmountQuery = searchParams.get(TransactionParamsType.MIN_PROMO_AMOUNT);
  const maxPromoAmountQuery = searchParams.get(TransactionParamsType.MAX_PROMO_AMOUNT);
  const userIdQuery = searchParams.get(TransactionParamsType.USER_ID);
  const accountIdQuery = searchParams.get(TransactionParamsType.ACCOUNT_ID);
  const transactionIdQuery = searchParams.get(TransactionParamsType.TRANSACTION_ID);
  const gameProviderNameQuery = searchParams.get(TransactionParamsType.GAME_PROVIDER_NAME);
  const gameIdQuery = searchParams.get(TransactionParamsType.GAME_ID);
  const gameNameQuery = searchParams.get(TransactionParamsType.GAME_NAME);
  const typeQuery = searchParams.get(TransactionParamsType.TYPE);
  const sortByQuery = searchParams.get(TransactionParamsType.SORT_BY);
  const isAscendingQuery = searchParams.get(TransactionParamsType.ASCENDING);
  const pageQuery = searchParams.get(TransactionParamsType.PAGE);
  const pageSizeQuery = searchParams.get(TransactionParamsType.PAGE_SIZE);

  return {
    startDateQuery,
    endDateQuery,
    minAmountQuery,
    maxAmountQuery,
    minPromoAmountQuery,
    maxPromoAmountQuery,
    userIdQuery,
    accountIdQuery,
    transactionIdQuery,
    gameProviderNameQuery,
    gameIdQuery,
    gameNameQuery,
    typeQuery,
    sortByQuery,
    isAscendingQuery,
    pageQuery,
    pageSizeQuery,
  };
}
