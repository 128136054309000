import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EVerificationStatusSchema,
  VerificationsBankAccountSchema,
  VerificationsSchemaDomicile,
  VerificationsSchemaIdentity,
  VerificationStatusArgumentsSchema,
  VerificationsWalletSchema,
} from '@greenisland-api';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
  action: (values: VerificationStatusArgumentsSchema) => void;
  identity?: VerificationsSchemaIdentity;
  domicile?: VerificationsSchemaDomicile;
  bankaccount?: VerificationsBankAccountSchema;
  wallet?: VerificationsWalletSchema;
}

const DeclineVerificationDialog = ({ open, setOpen, identity, domicile, bankaccount, wallet, action }: Props) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    setReason('');
  }, [bankaccount, wallet]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.target.value.length === 0) {
      setError(true);
    } else {
      setError(false);
    }
    setReason(event.target.value);
  };

  const confirmDeclineVerificationStatus = () => {
    if (!reason || reason.length === 0) {
      setError(true);
    }
    if (reason.length > 0 && !error) {
      action({ verificationStatus: EVerificationStatusSchema.Declined, reason });
      setOpen(false);
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        {t('declineVerification')}
        {identity && `: ${t('identity')}`}
        {domicile && `: ${t('domicile')}`}
        {bankaccount && `: ${t('bankaccount')} ${bankaccount.bankAccountNumber}`}
        {wallet && `: ${t('wallet')} ${wallet.paymentMethod}: ${wallet.walletId}`}
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          name="reason"
          label={t('reason')}
          value={reason}
          onChange={handleChange}
          error={error}
          helperText={error ? t('fieldIsRequired') : undefined}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" size="small" onClick={() => setOpen(false)}>
          {t('cancel')}
        </Button>
        <Button variant="outlined" size="small" onClick={confirmDeclineVerificationStatus} color="error">
          {t('decline')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(DeclineVerificationDialog);
