/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestUserResponse,
  DefaultResponse,
  GetSelfTestsParams,
  NotFoundResponse,
  PagedPgsiTestsRequestsSchema,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get PGSI selftests for a given user or device
 */
export const useGetSelfTestsHook = () => {
  const getSelfTests = useCustomInstance<PagedPgsiTestsRequestsSchema>();

  return (params?: GetSelfTestsParams, signal?: AbortSignal) => {
    return getSelfTests({ url: `/pgsi/selftest`, method: 'get', params, signal });
  };
};

export const getGetSelfTestsQueryKey = (params?: GetSelfTestsParams) =>
  [`/pgsi/selftest`, ...(params ? [params] : [])] as const;

export const useGetSelfTestsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSelfTestsHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetSelfTestsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSelfTestsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSelfTestsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSelfTestsQueryKey(params);

  const getSelfTests = useGetSelfTestsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetSelfTestsHook>>>> = ({ signal }) =>
    getSelfTests(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSelfTestsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetSelfTestsHook>>>>;
export type GetSelfTestsQueryError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get PGSI selftests for a given user or device
 */
export const useGetSelfTests = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetSelfTestsHook>>>,
  TError = BadRequestUserResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetSelfTestsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetSelfTestsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetSelfTestsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
