import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { faCheckCircle, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CampaignActionSchema,
  ErrorSchema,
  ETriggerIndividualActionBadRequestTypeSchema,
  useGetUserGeneral,
  useTriggerIndividualAction,
  useTriggerIndividualActionAllAccounts,
  useTriggerIndividualActionAllClosedAccounts,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useDebouncedValue } from '@lilib/hooks';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import UserInfoField from 'src/app/components/UserInfoField';

import TextFieldWithLoader from '@greenisland-common/components/molecules/TextFieldWithLoader';

import { usePermission } from '../../../../../../app/hooks';
import { PROCESS_INDIVIDUAL_ACTION_ERROR_REASONS } from '../../consts/ProcessIndividualActionErrorReasons';

enum ProcessingOption {
  FOR_ALL = 'FOR_ALL',
  FOR_CLOSED = 'FOR_CLOSED',
}

interface Props {
  isProcessMultiple?: boolean;
  selectedIndividualAction: CampaignActionSchema;
  campaignId: string;
  onClose: () => void;
}

const ProcessActionDialog = ({ isProcessMultiple = false, selectedIndividualAction, campaignId, onClose }: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const canReadUserGeneral = usePermission(OnlineCasinoPermissions.getUserGeneral);

  const [userId, setUserId] = useState('');
  const [isActionSuccessfulProcessed, setIsActionSuccessfulProcessed] = useState(false);
  const [processingOption, setProcessingOption] = useState<ProcessingOption | undefined>(undefined);

  const [debouncedSearch] = useDebouncedValue(userId, { wait: 500 });

  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useGetUserGeneral(debouncedSearch.length > 0 ? debouncedSearch : '', {
    query: { enabled: canReadUserGeneral && !!debouncedSearch },
  });

  const { mutate: triggerIndividualAction, isLoading: isTriggerIndividualActionLoading } = useTriggerIndividualAction({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        setIsActionSuccessfulProcessed(true);
      },
      onError: error => {
        if (error) {
          if ('type' in error && error.type) {
            const errorReason: ETriggerIndividualActionBadRequestTypeSchema | undefined = error.type;
            const translationKey = errorReason
              ? PROCESS_INDIVIDUAL_ACTION_ERROR_REASONS[errorReason]
              : 'somethingWentWrong';
            enqueueSnackbar(t(translationKey), { variant: 'error' });
          } else {
            enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
          }
        }
      },
    },
  });

  const { mutate: triggerIndividualActionAllAccounts, isLoading: isTriggerIndividualActionAllAccountsLoading } =
    useTriggerIndividualActionAllAccounts({
      mutation: {
        onSuccess: () => {
          enqueueSnackbar(t('success'), { variant: 'success' });
          setIsActionSuccessfulProcessed(true);
        },
        onError: (error: ErrorSchema) => {
          enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
        },
      },
    });

  const {
    mutate: triggerIndividualActionAllClosedAccounts,
    isLoading: isTriggerIndividualActionAllClosedAccountsLoading,
  } = useTriggerIndividualActionAllClosedAccounts({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        setIsActionSuccessfulProcessed(true);
      },
      onError: (error: ErrorSchema) => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  const submitHandler = () => {
    if (!isProcessMultiple) {
      return triggerIndividualAction({
        campaignId,
        actionId: selectedIndividualAction?.actionId,
        data: {
          userId,
        },
      });
    } else {
      if (processingOption === ProcessingOption.FOR_ALL) {
        return triggerIndividualActionAllAccounts({
          campaignId,
          actionId: selectedIndividualAction?.actionId,
        });
      } else {
        return triggerIndividualActionAllClosedAccounts({
          campaignId,
          actionId: selectedIndividualAction?.actionId,
        });
      }
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={Boolean(selectedIndividualAction)}>
      <DialogTitle>{`${capitalize(t('processAction'))} ${
        isProcessMultiple ? `(${capitalize(t('common.allUsers'))})` : ''
      }`}</DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={onClose}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <Stack spacing={2}>
          {!isActionSuccessfulProcessed ? (
            <>
              <Box>
                <UserInfoField label="actionId" value={selectedIndividualAction?.actionId} size="small" />
                <UserInfoField label="actionName" value={selectedIndividualAction?.name} size="small" />
              </Box>
              {!isProcessMultiple ? (
                <Box display="flex" flexDirection="column">
                  <TextFieldWithLoader
                    label="userId"
                    value={userId}
                    setValue={setUserId}
                    data={user}
                    isLoading={isUserLoading}
                    isError={isUserError}
                  />
                  {user && (
                    <Box mt={2}>
                      <UserInfoField label="displayname" value={user?.displayName} />
                      <UserInfoField
                        label="common.allowedToReceivePromotion"
                        value={String(!user?.isNotAllowedToReceivePromotion)}
                      />
                      <UserInfoField label="isClosed" value={String(user?.isClosed)} />
                      <UserInfoField label="loyaltyRank" value={user?.loyaltyRank} />
                    </Box>
                  )}
                  {isUserError && (
                    <Box mt={2}>
                      <Typography fontWeight={500} variant="body2" fontSize="small" color={theme.palette.error.main}>
                        {capitalize(t('common.userNotFound'))}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ) : (
                <>
                  <Typography variant="body1">{t('processAllExplanation')}</Typography>
                  <RadioGroup sx={{ pl: 1.5 }}>
                    <FormControlLabel
                      onChange={() => setProcessingOption(ProcessingOption.FOR_ALL)}
                      control={<Radio checked={processingOption === ProcessingOption.FOR_ALL} />}
                      label={
                        <Typography variant="body2">
                          <Trans i18nKey="processForAllActiveUsers" components={{ strong: <strong /> }} />
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      onChange={() => setProcessingOption(ProcessingOption.FOR_CLOSED)}
                      control={<Radio checked={processingOption === ProcessingOption.FOR_CLOSED} />}
                      label={
                        <Typography variant="body2">
                          <Trans i18nKey="processForAllClosedUsers" components={{ strong: <strong /> }} />
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </>
              )}
            </>
          ) : (
            <Box>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt={2} mb={6}>
                <FontAwesomeIcon icon={faCheckCircle} color={theme.palette.success.main} size="3x" />
                <Typography
                  variant="body1"
                  fontWeight={600}
                  fontSize="large"
                  sx={{ color: theme.palette.success.main }}
                  mt={2}
                >
                  {capitalize(t('marketing.individualActions.titles.successfullyAccepted'))}
                </Typography>
              </Box>
              <UserInfoField label="actionId" value={selectedIndividualAction?.actionId} size="medium" />
              <UserInfoField label="actionName" value={selectedIndividualAction?.name} size="medium" />
              {userId && <UserInfoField label="userId" value={userId} size="medium" />}
            </Box>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ m: 1 }}>
        {!isActionSuccessfulProcessed ? (
          <>
            <Button variant="text" onClick={onClose} color="secondary">
              {t('cancel')}
            </Button>
            <LoadingButton
              color="primary"
              variant="contained"
              onClick={submitHandler}
              loading={
                isTriggerIndividualActionLoading ||
                isTriggerIndividualActionAllAccountsLoading ||
                isTriggerIndividualActionAllClosedAccountsLoading
              }
              disabled={isProcessMultiple ? !processingOption : !userId.length || !user}
            >
              {t('process')}
            </LoadingButton>
          </>
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              onClose();
              setUserId('');
            }}
            color="primary"
          >
            {t('close')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ProcessActionDialog;
