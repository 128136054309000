import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AgentRoleSchema } from '@greenisland-store/authorization';
import { Box, Collapse, styled, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

const RoleName = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  padding: theme.spacing(1),
}));

const ExpandIcon = styled(FontAwesomeIcon)<{ $expanded: boolean }>(({ theme, $expanded }) => ({
  transform: $expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  marginRight: theme.spacing(1),
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

type NestedRolesProps = {
  agentRole: AgentRoleSchema;
  isSubRole?: boolean;
  level?: number;
};

const NestedRoles = ({ agentRole, isSubRole = false, level = 0 }: NestedRolesProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(!isSubRole);

  const mutatePermissions = (): { columns: GridColDef[]; rows: any[] } | undefined => {
    if (!agentRole.permissions || agentRole.permissions.length === 0) return undefined;
    const columns: GridColDef[] = Object.keys(agentRole.permissions[0]).map(key => {
      return {
        field: key,
        headerName: t(key),
        flex: 1,
      };
    });
    return {
      columns,
      rows: agentRole.permissions.map((p, index) => ({
        ...p,
        id: index,
      })),
    };
  };

  const toggleExpand = () => {
    if (isSubRole) setExpanded(prev => !prev);
  };

  return (
    <Box>
      {isSubRole && (
        <RoleName
          onClick={toggleExpand}
          sx={{
            bgcolor: level % 2 ? 'action.hover' : 'background.paper',
          }}
        >
          <Typography variant="h6" fontWeight="normal">
            {agentRole.roleName}
          </Typography>
          <ExpandIcon icon={faAngleDown} $expanded={expanded} />
        </RoleName>
      )}

      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={{
          bgcolor: level % 2 ? 'action.hover' : 'background.paper',
          borderTop: isSubRole ? 1 : 0,
          borderColor: 'divider',
        }}
      >
        <Box p={2}>
          <Typography variant="h6" fontWeight="normal" gutterBottom>
            {t('permissions')}
          </Typography>
          {mutatePermissions() ? (
            <StyledDataGrid
              columns={mutatePermissions()!.columns}
              rows={mutatePermissions()!.rows}
              autoHeight
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    permissionId: false,
                  },
                },
              }}
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              pagination
            />
          ) : (
            <Typography>{t('noPermissions')}</Typography>
          )}
        </Box>
        {agentRole.agentRoles && agentRole.agentRoles.length > 0 && (
          <Box p={2}>
            <Typography variant="h6" fontWeight="normal" gutterBottom>
              {t('roles')}
            </Typography>
            {agentRole.agentRoles.map(role => (
              <NestedRoles key={role.roleId} isSubRole level={level + 1} agentRole={role} />
            ))}
          </Box>
        )}
      </Collapse>
    </Box>
  );
};

export default NestedRoles;
