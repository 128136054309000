/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  BadRequestResponse,
  DefaultResponse,
  GameLibraryConflictsSchema,
  GameLibrarySyncAllParams,
  GameLibrarySyncJobResultsSchema,
  GameLibrarySyncRequestSchema,
  GetGameLibraryLastRunsParams,
  NotFoundResponse,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Get all gamelibrary merge conflicts
 */
export const useGetAllGameLibraryMergeConflictsHook = () => {
  const getAllGameLibraryMergeConflicts = useCustomInstance<GameLibraryConflictsSchema>();

  return (signal?: AbortSignal) => {
    return getAllGameLibraryMergeConflicts({ url: `/gamelibrary/conflicts`, method: 'get', signal });
  };
};

export const getGetAllGameLibraryMergeConflictsQueryKey = () => [`/gamelibrary/conflicts`] as const;

export const useGetAllGameLibraryMergeConflictsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAllGameLibraryMergeConflictsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetAllGameLibraryMergeConflictsHook>>>,
    TError,
    TData
  >;
}): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetAllGameLibraryMergeConflictsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllGameLibraryMergeConflictsQueryKey();

  const getAllGameLibraryMergeConflicts = useGetAllGameLibraryMergeConflictsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAllGameLibraryMergeConflictsHook>>>> = ({
    signal,
  }) => getAllGameLibraryMergeConflicts(signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAllGameLibraryMergeConflictsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetAllGameLibraryMergeConflictsHook>>>
>;
export type GetAllGameLibraryMergeConflictsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get all gamelibrary merge conflicts
 */
export const useGetAllGameLibraryMergeConflicts = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetAllGameLibraryMergeConflictsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetAllGameLibraryMergeConflictsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetAllGameLibraryMergeConflictsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get the gamelibrary last runs
 */
export const useGetGameLibraryLastRunsHook = () => {
  const getGameLibraryLastRuns = useCustomInstance<GameLibrarySyncJobResultsSchema>();

  return (params?: GetGameLibraryLastRunsParams, signal?: AbortSignal) => {
    return getGameLibraryLastRuns({ url: `/gamelibrary/lastruns`, method: 'get', params, signal });
  };
};

export const getGetGameLibraryLastRunsQueryKey = (params?: GetGameLibraryLastRunsParams) =>
  [`/gamelibrary/lastruns`, ...(params ? [params] : [])] as const;

export const useGetGameLibraryLastRunsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameLibraryLastRunsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetGameLibraryLastRunsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameLibraryLastRunsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameLibraryLastRunsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGameLibraryLastRunsQueryKey(params);

  const getGameLibraryLastRuns = useGetGameLibraryLastRunsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetGameLibraryLastRunsHook>>>> = ({ signal }) =>
    getGameLibraryLastRuns(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetGameLibraryLastRunsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetGameLibraryLastRunsHook>>>
>;
export type GetGameLibraryLastRunsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Get the gamelibrary last runs
 */
export const useGetGameLibraryLastRuns = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetGameLibraryLastRunsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetGameLibraryLastRunsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetGameLibraryLastRunsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetGameLibraryLastRunsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary perform gamelibrary sync on given object PKey
 */
export const useGameLibrarySyncHook = () => {
  const gameLibrarySync = useCustomInstance<void>();

  return (gameLibrarySyncRequestSchema: GameLibrarySyncRequestSchema) => {
    return gameLibrarySync({
      url: `/gamelibrary/sync`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: gameLibrarySyncRequestSchema,
    });
  };
};

export const useGameLibrarySyncMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGameLibrarySyncHook>>>,
    TError,
    { data: GameLibrarySyncRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useGameLibrarySyncHook>>>,
  TError,
  { data: GameLibrarySyncRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const gameLibrarySync = useGameLibrarySyncHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useGameLibrarySyncHook>>>,
    { data: GameLibrarySyncRequestSchema }
  > = props => {
    const { data } = props ?? {};

    return gameLibrarySync(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GameLibrarySyncMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGameLibrarySyncHook>>>>;
export type GameLibrarySyncMutationBody = GameLibrarySyncRequestSchema;
export type GameLibrarySyncMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary perform gamelibrary sync on given object PKey
 */
export const useGameLibrarySync = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGameLibrarySyncHook>>>,
    TError,
    { data: GameLibrarySyncRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useGameLibrarySyncMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary perform gamelibrary sync on all objects
 */
export const useGameLibrarySyncAllHook = () => {
  const gameLibrarySyncAll = useCustomInstance<void>();

  return (params?: GameLibrarySyncAllParams) => {
    return gameLibrarySyncAll({ url: `/gamelibrary/sync/all`, method: 'put', params });
  };
};

export const useGameLibrarySyncAllMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGameLibrarySyncAllHook>>>,
    TError,
    { params?: GameLibrarySyncAllParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useGameLibrarySyncAllHook>>>,
  TError,
  { params?: GameLibrarySyncAllParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const gameLibrarySyncAll = useGameLibrarySyncAllHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useGameLibrarySyncAllHook>>>,
    { params?: GameLibrarySyncAllParams }
  > = props => {
    const { params } = props ?? {};

    return gameLibrarySyncAll(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type GameLibrarySyncAllMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGameLibrarySyncAllHook>>>
>;

export type GameLibrarySyncAllMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary perform gamelibrary sync on all objects
 */
export const useGameLibrarySyncAll = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGameLibrarySyncAllHook>>>,
    TError,
    { params?: GameLibrarySyncAllParams },
    TContext
  >;
}) => {
  const mutationOptions = useGameLibrarySyncAllMutationOptions(options);

  return useMutation(mutationOptions);
};
