import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faAdd, faCircleXmark, faCopy, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ErrorSchema } from '@greenisland-api';
import { GameServerPermissions } from '@greenisland-core/permissions/GameServerPermissions';
import {
  QueryTournamentConfigurationsParams,
  TournamentConfiguration,
  TournamentConfigurationOrderByParameter,
  TournamentConfigurationSortOrderParameter,
  useDeleteScheduledTournamentInstancesForTournamentConfiguration,
  useQueryTournamentConfigurations,
} from '@greenisland-store/gameServer';
import { useDebouncedValue } from '@lilib/hooks';
import {
  Box,
  Button,
  capitalize,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import ConfirmDialog from 'src/app/components/ConfirmDialog';

import {
  CheckboxIcon,
  DataGridContainer,
  FontAwesomeIcon,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';
import Select from '@greenisland-common/components/atoms/Select';

import { TournamentConfigurationMode } from './components/forms/helpers/tournamentConfigFormInterfaces';
import { getDateTimeFromUnix } from '../../../app/helpers/transformFunctions';
import { usePermission } from '../../../app/hooks';

const PAGE_SIZE = 25;

type Row = TournamentConfiguration;

const TournamentsConfigs = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const navigate = useNavigate();

  const canReadTournamentConfiguration = usePermission(GameServerPermissions.queryTournamentConfigurations);
  const canAddTournamentConfiguration = usePermission(GameServerPermissions.addTournamentConfiguration);
  const canEditTournamentConfiguration = usePermission([
    GameServerPermissions.getTournamentConfiguration,
    GameServerPermissions.putTournamentConfiguration,
  ]);
  const canDeleteTournamentConfiguration = usePermission(GameServerPermissions.putTournamentConfiguration);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [isFilterByActive, setIsFilterByActive] = useState(false);
  const [sortBy, setSortBy] = useState<TournamentConfigurationOrderByParameter>(
    TournamentConfigurationOrderByParameter.id
  );
  const [sortOrder, setSortOrder] = useState<TournamentConfigurationSortOrderParameter>(
    TournamentConfigurationSortOrderParameter.DESC
  );
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState<{
    isOpen: boolean;
    tournamentConfigurationId?: number;
  }>({
    isOpen: false,
  });
  const [debouncedSearch] = useDebouncedValue(search, { wait: 500 });

  const {
    data: tournamentsConfigurations,
    isLoading,
    isError,
    isFetching,
  } = useQueryTournamentConfigurations(
    {
      filterByName: debouncedSearch,
      pageSize: pageSize,
      page: page + 1,
      sortBy: sortBy.toUpperCase(),
      sortOrder: sortOrder.toUpperCase(),
      ...(isFilterByActive ? { filterByActive: isFilterByActive } : {}),
    } as QueryTournamentConfigurationsParams,
    { query: { enabled: canReadTournamentConfiguration, keepPreviousData: true } }
  );

  const deleteMutation = useDeleteScheduledTournamentInstancesForTournamentConfiguration({
    mutation: {
      onSuccess: async () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: (error: ErrorSchema) => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  const columns = useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'active',
        headerName: t('content.gameserver.tournamentconfiguration.active'),
        type: 'boolean',
        flex: 0.05,
        renderCell: (params: GridRenderCellParams<boolean>) => <CheckboxIcon checked={params.value} />,
        sortable: false,
        minWidth: 80,
      },
      {
        field: 'tournamentName',
        headerName: t('content.gameserver.tournamentconfiguration.tournamentName'),
        sortable: false,
        minWidth: 250,
        flex: 0.2,
        renderCell: params => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
      },
      {
        field: '',
        headerName: t('content.gameserver.tournamentconfiguration.tournamentType'),
        sortable: false,
        minWidth: 150,
        flex: 0.2,
        renderCell: () => {
          return (
            <Typography variant="body2">
              {t('content.gameserver.tournamentconfiguration.tournamentRedPandaType')}
            </Typography>
          );
        },
      },
      {
        headerName: capitalize(t('content.gameserver.tournamentconfiguration.validFrom')),
        field: 'validity.from',
        renderCell: params => {
          const formattedFromDate = params.row.validity?.from;
          if (formattedFromDate) {
            const formattedDaysDate = getDateTimeFromUnix(formattedFromDate).split(' ')[0];
            const formattedHoursDate = getDateTimeFromUnix(formattedFromDate).split(' ')[1];
            return (
              <Box>
                <Typography variant="body2">{formattedDaysDate}</Typography>
                <Typography variant="body2" color="GrayText">
                  {formattedHoursDate}
                </Typography>
              </Box>
            );
          }
          return <Typography variant="body2">-</Typography>;
        },
        minWidth: 150,
        flex: 0.2,
      },
      {
        headerName: capitalize(t('content.gameserver.tournamentconfiguration.validTo')),
        field: 'validity.to',
        renderCell: params => {
          const formattedToDate = params.row.validity?.to;
          if (formattedToDate) {
            const formattedDaysDate = getDateTimeFromUnix(formattedToDate).split(' ')[0];
            const formattedHoursDate = getDateTimeFromUnix(formattedToDate).split(' ')[1];
            return (
              <Box>
                <Typography variant="body2">{formattedDaysDate}</Typography>
                <Typography variant="body2" color="GrayText">
                  {formattedHoursDate}
                </Typography>
              </Box>
            );
          }
          return <Typography variant="body2">-</Typography>;
        },
        minWidth: 150,
        flex: 0.2,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 150,
        flex: 0.1,
        headerName: t('content.gameserver.tournamentconfiguration.actions'),
        getActions: (params: GridRowParams<Row>) => [
          <>
            {canAddTournamentConfiguration && (
              <Tooltip title={t('copy')} key="details">
                <Box>
                  <GridActionsCellItem
                    label={capitalize(t('copy'))}
                    icon={<FontAwesomeIcon icon={faCopy} />}
                    onClick={() => {
                      if (params?.row?.id) {
                        navigate(`../${TournamentConfigurationMode.COPY}/${params?.row?.id}`);
                      }
                    }}
                    key="copy"
                  />
                </Box>
              </Tooltip>
            )}
            {canEditTournamentConfiguration && (
              <Tooltip title={t('content.gameserver.tournamentconfiguration.edit')} key="details">
                <Box ml={1}>
                  <GridActionsCellItem
                    label={t('content.gameserver.tournamentconfiguration.edit')}
                    icon={<FontAwesomeIcon icon={faPencil} color={theme.palette.warning.main} />}
                    onClick={() => {
                      if (params?.row?.id) {
                        navigate(`../${TournamentConfigurationMode.EDIT}/${params?.row?.id}`);
                      }
                    }}
                    key="edit"
                  />
                </Box>
              </Tooltip>
            )}
            {canDeleteTournamentConfiguration && (
              <Tooltip title={t('content.gameserver.tournamentconfiguration.delete')} key="delete">
                <Box ml={1}>
                  <GridActionsCellItem
                    label={t('content.gameserver.tournamentconfiguration.delete')}
                    icon={<FontAwesomeIcon icon={faTrash} sx={{ color: 'error.main' }} />}
                    onClick={() => {
                      if (params?.row?.id) {
                        setDeleteConfirmationDialog({ isOpen: true, tournamentConfigurationId: params?.row?.id });
                      }
                    }}
                    key="delete"
                  />
                </Box>
              </Tooltip>
            )}
          </>,
        ],
      },
    ],
    [
      canAddTournamentConfiguration,
      canDeleteTournamentConfiguration,
      canEditTournamentConfiguration,
      navigate,
      t,
      theme.palette.warning.main,
    ]
  );

  const deleteTournamentConfigurationHandler = () => {
    if (deleteConfirmationDialog?.tournamentConfigurationId) {
      deleteMutation.mutate({ tournamentConfigurationId: deleteConfirmationDialog.tournamentConfigurationId });
    }
  };

  const rows = useMemo(
    () =>
      tournamentsConfigurations?.entries?.map<Row>(tournamentsConfiguration => ({
        ...tournamentsConfiguration,
      })) ?? [],
    [tournamentsConfigurations?.entries]
  );

  return (
    <Stack spacing={2}>
      <PermissionWrapper
        errorMessage={t('content.gameserver.tournamentconfiguration.errors.fetchError')}
        isError={isError}
        isLoading={false}
        permission={GameServerPermissions.queryTournamentConfigurations}
      >
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              [theme.breakpoints.up('xs')]: {
                flexDirection: 'column-reverse',
                gap: 2,
              },
              [theme.breakpoints.up('lg')]: {
                flexDirection: 'row',
              },
            }}
          >
            {canAddTournamentConfiguration && (
              <Button
                onClick={() => navigate(`../${TournamentConfigurationMode.ADD}`)}
                variant="contained"
                size="small"
                startIcon={<FontAwesomeIcon icon={faAdd} />}
                sx={{
                  [theme.breakpoints.up('xs')]: {
                    mr: 0,
                  },
                  [theme.breakpoints.up('lg')]: {
                    mr: 2,
                  },
                }}
              >
                {t('content.gameserver.tournamentconfiguration.addTournamentButtonText')}
              </Button>
            )}
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap={2}
              sx={{
                [theme.breakpoints.up('xs')]: {
                  flexDirection: 'column',
                  width: '100%',
                },
                [theme.breakpoints.up('lg')]: {
                  flexDirection: 'row',
                  width: '65%',
                },
              }}
            >
              <FormControlLabel
                control={<Checkbox color="primary" size="small" />}
                onChange={(_, value) => setIsFilterByActive(value)}
                label={t('common.showActiveOnly')}
                checked={isFilterByActive}
                sx={{
                  '.MuiTypography-root': {
                    fontWeight: isFilterByActive ? 600 : 400,
                    fontSize: '15px',
                    minWidth: '140px',
                  },
                }}
              />
              <TextField
                fullWidth={true}
                size="small"
                type="text"
                required={false}
                label={capitalize(t('content.gameserver.tournamentconfiguration.addTournamentSearchInput'))}
                value={search || ''}
                onChange={e => setSearch(e.target.value)}
                InputProps={{
                  endAdornment: search && (
                    <FontAwesomeIcon
                      onClick={() => setSearch('')}
                      style={{
                        visibility: search ? 'visible' : 'hidden',
                        marginRight: '5px',
                        marginLeft: 'none',
                        cursor: 'pointer',
                      }}
                      icon={faCircleXmark}
                    />
                  ),
                }}
              />
              <Select
                id="sortBy"
                label={capitalize(t('sortBy'))}
                value={sortBy.toLowerCase()}
                onChange={event => setSortBy(event.target.value as TournamentConfigurationOrderByParameter)}
                options={Object.values(TournamentConfigurationOrderByParameter).map(value => value.toLowerCase())}
              />
              <Select
                id="sortOrder"
                label={capitalize(t('sortOrder'))}
                value={sortOrder.toLowerCase()}
                onChange={event => setSortOrder(event.target.value as TournamentConfigurationSortOrderParameter)}
                options={Object.values(TournamentConfigurationSortOrderParameter).map(value => value.toLowerCase())}
              />
            </Box>
          </Box>
          <DataGridContainer>
            <LimitedDataGrid
              density="compact"
              autoHeight
              rowHeight={80}
              loading={isLoading || isFetching}
              rows={rows}
              columns={columns}
              pagination
              page={page}
              rowCount={tournamentsConfigurations?.totalNumberOfEntries}
              pageSize={pageSize}
              paginationMode="server"
              onPageChange={setPage}
              onPageSizeChange={setPageSize}
              componentsProps={{ pagination: { rowsPerPageOptions: [10, 25, 50, 100, 200] } }}
            />
          </DataGridContainer>
          <ConfirmDialog
            title={t('content.gameserver.tournamentconfiguration.deletescheduledtournamentinstances.title')}
            content={t('content.gameserver.tournamentconfiguration.deletescheduledtournamentinstances.description')}
            isOpen={deleteConfirmationDialog.isOpen}
            setIsOpen={(open: boolean) => setDeleteConfirmationDialog({ isOpen: open })}
            onConfirm={deleteTournamentConfigurationHandler}
          />
        </>
      </PermissionWrapper>
    </Stack>
  );
};

export default TournamentsConfigs;
