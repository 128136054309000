import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { AgentTaskMetaDataSchema } from '@greenisland-api';
import { Box, Button, capitalize, Link, Typography } from '@mui/material';

import { getCriticalFieldBirthdayDateHandler } from '../../../Lookup/Components/DetailsComponents/CriticalFieldsMismatch/Constants';
import CriticalFieldsMismatchDialog from '../../../Lookup/Components/DetailsComponents/CriticalFieldsMismatch/CriticalFieldsMismatchDialog';

interface Props {
  metaData: AgentTaskMetaDataSchema;
}

const CriticalFieldMismatchDetail = ({ metaData }: Props) => {
  const { t } = useTranslation();
  const [isMismatchFieldsDialogOpen, setIsMismatchFieldsDialogOpen] = useState(false);

  return (
    <>
      <Box sx={{ paddingY: 1, paddingLeft: 2 }}>
        <Box display="flex" gap={1} my={2}>
          <Typography variant="body2" fontWeight={700}>{`${capitalize(t('userId'))}:`}</Typography>
          <Link component={RouterLink} to={`../../users/${metaData.userId}/details`} target="_blank">
            {metaData.userId}
          </Link>
        </Box>
        <Box display="flex" gap={10}>
          <Box>
            <Typography variant="h5" fontWeight={700} fontSize="medium" mb={2}>
              {`${capitalize(t('criticalFieldsMismatch.titles.currentCriticalFields'))}: `}
            </Typography>
            <Typography variant="body2" fontWeight={700} mb={1}>
              {`${capitalize(t('firstName'))}: `}
              <Box component="span" fontWeight="400">
                {metaData?.currentCriticalFields?.firstName}
              </Box>
            </Typography>
            <Typography variant="body2" fontWeight={700} mb={1}>
              {`${capitalize(t('lastName'))}: `}
              <Box component="span" fontWeight="400">
                {metaData?.currentCriticalFields?.lastName}
              </Box>
            </Typography>
            <Typography variant="body2" fontWeight={700} mb={1}>
              {`${capitalize(t('dateOfBirth'))}: `}
              <Box component="span" fontWeight="400">
                {getCriticalFieldBirthdayDateHandler(metaData?.currentCriticalFields?.dateOfBirth)}
              </Box>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5" fontWeight={700} fontSize="medium" mb={2}>
              {`${capitalize(t('criticalFieldsMismatch.titles.newCriticalFields'))}: `}
            </Typography>
            <Typography variant="body2" fontWeight={700} mb={1}>
              {`${capitalize(t('firstName'))}: `}
              <Box component="span" fontWeight="400">
                {metaData?.newCriticalFields?.firstName}
              </Box>
            </Typography>
            <Typography variant="body2" fontWeight={700} mb={1}>
              {`${capitalize(t('lastName'))}: `}
              <Box component="span" fontWeight="400">
                {metaData?.newCriticalFields?.lastName}
              </Box>
            </Typography>
            <Typography variant="body2" fontWeight={700} mb={1}>
              {`${capitalize(t('dateOfBirth'))}: `}
              <Box component="span" fontWeight="400">
                {getCriticalFieldBirthdayDateHandler(metaData?.newCriticalFields?.dateOfBirth)}
              </Box>
            </Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          size="small"
          color="warning"
          sx={{ mt: 2 }}
          onClick={() => setIsMismatchFieldsDialogOpen(true)}
        >
          {t('criticalFieldsMismatch.titles.fixCriticalFieldsWarning')}
        </Button>
      </Box>
      {isMismatchFieldsDialogOpen && (
        <CriticalFieldsMismatchDialog
          userId={metaData?.userId}
          open={isMismatchFieldsDialogOpen}
          setOpen={setIsMismatchFieldsDialogOpen}
          fetchCriticalFieldsMismatchData={true}
        />
      )}
    </>
  );
};

export default CriticalFieldMismatchDetail;
