import { LinearProgress, useTheme } from '@mui/material';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';

import DataGridPagination from '../DataGridPagination';

const StyledDataGrid = (props: DataGridProProps) => {
  const { sx, components, ...rest } = props;
  const theme = useTheme();
  const white = theme.palette.common.white;
  const grey = theme.palette.grey[100];

  return (
    <DataGridPro
      {...rest}
      density="compact"
      autoHeight
      sx={{
        ...sx,
        backgroundColor: white,
        border: 0,
        '& .MuiDataGrid-menuIconButton': {
          color: white,
        },
        '& .MuiDataGrid-columnHeaders': {
          border: 0,
          backgroundColor: theme.palette.primary.main,
          color: white,
        },
        '& .MuiDataGrid-footerContainer	': { border: 0 },
        '& .MuiDataGrid-row': { border: 0, backgroundColor: white },
        '& .MuiDataGrid-row:hover': { border: `1px solid ${theme.palette.grey[400]}`, backgroundColor: white },
        '& .MuiDataGrid-row:nth-child(even)': { backgroundColor: grey },
        '& .MuiDataGrid-cell': { border: 0 },
        '& .MuiDataGrid-sortIcon': { color: white },
        '& .MuiLinearProgress-root': { zIndex: 1 },
      }}
      components={{ ...components, Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
    />
  );
};

export default StyledDataGrid;
