/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EGameTypeSchema = (typeof EGameTypeSchema)[keyof typeof EGameTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EGameTypeSchema = {
  Slots: 'Slots',
  Poker: 'Poker',
  Blackjack: 'Blackjack',
  Roulette: 'Roulette',
  Bingo: 'Bingo',
  VirtualSports: 'VirtualSports',
  Casino: 'Casino',
  Other: 'Other',
} as const;
