/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  ActiveDepositLimitsV2Schema,
  BadRequestResponse,
  CancelMaxLimitRequestBodySchema,
  DefaultResponse,
  DepositLimitV2UpdateRequestResultSchema,
  ErrorSchema,
  GetDepositLimitsParams,
  GetDepositLimitsThatRequireManualApprovalParams,
  GetDepositLimitUpdateRequestsParams,
  MaxDepositLimitPerPeriodSchema,
  MaxDepositLimitSchema,
  MaxDepositLimitsSchema,
  MaxLimitBadRequestResponse,
  NotFoundResponse,
  PagedDepositLimitsV2Schema,
  PagedDepositLimitV2UpdateRequestsSchema,
  PagedUserLimitUpdateRequestsWithReferenceSchema,
  UnauthorizedResponse,
  UpdateDepositLimitV2RequestSchema,
  UpdateMaxDepositLimitSchema,
  UserLimitUpdateRequestManualDecisionArgumentsSchema,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Retrieves deposit limits.
 * @summary Retrieve deposit limits.
 */
export const useGetDepositLimitsHook = () => {
  const getDepositLimits = useCustomInstance<PagedDepositLimitsV2Schema>();

  return (params?: GetDepositLimitsParams, signal?: AbortSignal) => {
    return getDepositLimits({ url: `/depositlimits`, method: 'get', params, signal });
  };
};

export const getGetDepositLimitsQueryKey = (params?: GetDepositLimitsParams) =>
  [`/depositlimits`, ...(params ? [params] : [])] as const;

export const useGetDepositLimitsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDepositLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetDepositLimitsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositLimitsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositLimitsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDepositLimitsQueryKey(params);

  const getDepositLimits = useGetDepositLimitsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDepositLimitsHook>>>> = ({ signal }) =>
    getDepositLimits(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDepositLimitsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetDepositLimitsHook>>>>;
export type GetDepositLimitsQueryError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Retrieve deposit limits.
 */
export const useGetDepositLimits = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDepositLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetDepositLimitsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositLimitsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDepositLimitsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves deposit limit update requests.
 * @summary Retrieve deposit limit update requests.
 */
export const useGetDepositLimitUpdateRequestsHook = () => {
  const getDepositLimitUpdateRequests = useCustomInstance<PagedDepositLimitV2UpdateRequestsSchema>();

  return (params?: GetDepositLimitUpdateRequestsParams, signal?: AbortSignal) => {
    return getDepositLimitUpdateRequests({ url: `/depositlimits/requests`, method: 'get', params, signal });
  };
};

export const getGetDepositLimitUpdateRequestsQueryKey = (params?: GetDepositLimitUpdateRequestsParams) =>
  [`/depositlimits/requests`, ...(params ? [params] : [])] as const;

export const useGetDepositLimitUpdateRequestsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDepositLimitUpdateRequestsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetDepositLimitUpdateRequestsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetDepositLimitUpdateRequestsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetDepositLimitUpdateRequestsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDepositLimitUpdateRequestsQueryKey(params);

  const getDepositLimitUpdateRequests = useGetDepositLimitUpdateRequestsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetDepositLimitUpdateRequestsHook>>>> = ({
    signal,
  }) => getDepositLimitUpdateRequests(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDepositLimitUpdateRequestsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetDepositLimitUpdateRequestsHook>>>
>;
export type GetDepositLimitUpdateRequestsQueryError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Retrieve deposit limit update requests.
 */
export const useGetDepositLimitUpdateRequests = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDepositLimitUpdateRequestsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetDepositLimitUpdateRequestsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetDepositLimitUpdateRequestsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDepositLimitUpdateRequestsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Request to update deposit limit. A request does not mean that this deposit limit will be set in the future, as it may be declined. An update to the deposit limit can take place immediately (if a lower deposit limit value is requested) or in the future (after the approval process).
 * @summary Request an update for deposit limit.
 */
export const useRequestDepositLimitUpdateHook = () => {
  const requestDepositLimitUpdate = useCustomInstance<DepositLimitV2UpdateRequestResultSchema>();

  return (updateDepositLimitV2RequestSchema: UpdateDepositLimitV2RequestSchema) => {
    return requestDepositLimitUpdate({
      url: `/depositlimits/requests`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: updateDepositLimitV2RequestSchema,
    });
  };
};

export const useRequestDepositLimitUpdateMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRequestDepositLimitUpdateHook>>>,
    TError,
    { data: UpdateDepositLimitV2RequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useRequestDepositLimitUpdateHook>>>,
  TError,
  { data: UpdateDepositLimitV2RequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const requestDepositLimitUpdate = useRequestDepositLimitUpdateHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useRequestDepositLimitUpdateHook>>>,
    { data: UpdateDepositLimitV2RequestSchema }
  > = props => {
    const { data } = props ?? {};

    return requestDepositLimitUpdate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type RequestDepositLimitUpdateMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useRequestDepositLimitUpdateHook>>>
>;
export type RequestDepositLimitUpdateMutationBody = UpdateDepositLimitV2RequestSchema;
export type RequestDepositLimitUpdateMutationError = BadRequestResponse | UnauthorizedResponse | DefaultResponse;

/**
 * @summary Request an update for deposit limit.
 */
export const useRequestDepositLimitUpdate = <
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useRequestDepositLimitUpdateHook>>>,
    TError,
    { data: UpdateDepositLimitV2RequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useRequestDepositLimitUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves deposit limit update requests that require manual approval.
 * @summary Retrieve deposit limit update requests that require manual approval.
 */
export const useGetDepositLimitsThatRequireManualApprovalHook = () => {
  const getDepositLimitsThatRequireManualApproval =
    useCustomInstance<PagedUserLimitUpdateRequestsWithReferenceSchema>();

  return (params?: GetDepositLimitsThatRequireManualApprovalParams, signal?: AbortSignal) => {
    return getDepositLimitsThatRequireManualApproval({
      url: `/depositlimits/requests/requires-manual-approval`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getGetDepositLimitsThatRequireManualApprovalQueryKey = (
  params?: GetDepositLimitsThatRequireManualApprovalParams
) => [`/depositlimits/requests/requires-manual-approval`, ...(params ? [params] : [])] as const;

export const useGetDepositLimitsThatRequireManualApprovalQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDepositLimitsThatRequireManualApprovalHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetDepositLimitsThatRequireManualApprovalParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetDepositLimitsThatRequireManualApprovalHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<
  Awaited<ReturnType<ReturnType<typeof useGetDepositLimitsThatRequireManualApprovalHook>>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDepositLimitsThatRequireManualApprovalQueryKey(params);

  const getDepositLimitsThatRequireManualApproval = useGetDepositLimitsThatRequireManualApprovalHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetDepositLimitsThatRequireManualApprovalHook>>>
  > = ({ signal }) => getDepositLimitsThatRequireManualApproval(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDepositLimitsThatRequireManualApprovalQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetDepositLimitsThatRequireManualApprovalHook>>>
>;
export type GetDepositLimitsThatRequireManualApprovalQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | DefaultResponse;

/**
 * @summary Retrieve deposit limit update requests that require manual approval.
 */
export const useGetDepositLimitsThatRequireManualApproval = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetDepositLimitsThatRequireManualApprovalHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | DefaultResponse
>(
  params?: GetDepositLimitsThatRequireManualApprovalParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetDepositLimitsThatRequireManualApprovalHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetDepositLimitsThatRequireManualApprovalQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Retrieves deposit limits.
 * @summary Retrieve the active deposit limits from the user.
 */
export const useGetActiveDepositLimitsForUserHook = () => {
  const getActiveDepositLimitsForUser = useCustomInstance<ActiveDepositLimitsV2Schema>();

  return (userId: number, signal?: AbortSignal) => {
    return getActiveDepositLimitsForUser({ url: `/depositlimits/active/${userId}`, method: 'get', signal });
  };
};

export const getGetActiveDepositLimitsForUserQueryKey = (userId: number) =>
  [`/depositlimits/active/${userId}`] as const;

export const useGetActiveDepositLimitsForUserQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActiveDepositLimitsForUserHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | ErrorSchema | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActiveDepositLimitsForUserHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveDepositLimitsForUserHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveDepositLimitsForUserQueryKey(userId);

  const getActiveDepositLimitsForUser = useGetActiveDepositLimitsForUserHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetActiveDepositLimitsForUserHook>>>> = ({
    signal,
  }) => getActiveDepositLimitsForUser(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetActiveDepositLimitsForUserQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetActiveDepositLimitsForUserHook>>>
>;
export type GetActiveDepositLimitsForUserQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | ErrorSchema
  | DefaultResponse;

/**
 * @summary Retrieve the active deposit limits from the user.
 */
export const useGetActiveDepositLimitsForUser = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActiveDepositLimitsForUserHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | ErrorSchema | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActiveDepositLimitsForUserHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetActiveDepositLimitsForUserQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Manually approve a deposit limit update request.
 * @summary Manually approve a deposit limit update request.
 */
export const useApproveDepositLimitUpdateRequestManuallyHook = () => {
  const approveDepositLimitUpdateRequestManually = useCustomInstance<void>();

  return (
    requestId: number,
    userLimitUpdateRequestManualDecisionArgumentsSchema: UserLimitUpdateRequestManualDecisionArgumentsSchema
  ) => {
    return approveDepositLimitUpdateRequestManually({
      url: `/depositlimits/requests/${requestId}/approve`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: userLimitUpdateRequestManualDecisionArgumentsSchema,
    });
  };
};

export const useApproveDepositLimitUpdateRequestManuallyMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | ErrorSchema | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useApproveDepositLimitUpdateRequestManuallyHook>>>,
    TError,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useApproveDepositLimitUpdateRequestManuallyHook>>>,
  TError,
  { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const approveDepositLimitUpdateRequestManually = useApproveDepositLimitUpdateRequestManuallyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useApproveDepositLimitUpdateRequestManuallyHook>>>,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema }
  > = props => {
    const { requestId, data } = props ?? {};

    return approveDepositLimitUpdateRequestManually(requestId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ApproveDepositLimitUpdateRequestManuallyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useApproveDepositLimitUpdateRequestManuallyHook>>>
>;
export type ApproveDepositLimitUpdateRequestManuallyMutationBody = UserLimitUpdateRequestManualDecisionArgumentsSchema;
export type ApproveDepositLimitUpdateRequestManuallyMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | ErrorSchema
  | DefaultResponse;

/**
 * @summary Manually approve a deposit limit update request.
 */
export const useApproveDepositLimitUpdateRequestManually = <
  TError = BadRequestResponse | UnauthorizedResponse | ErrorSchema | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useApproveDepositLimitUpdateRequestManuallyHook>>>,
    TError,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useApproveDepositLimitUpdateRequestManuallyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Manually decline a deposit limit update request
 * @summary Manually decline a deposit limit update request
 */
export const useDeclineDepositLimitUpdateRequestManuallyHook = () => {
  const declineDepositLimitUpdateRequestManually = useCustomInstance<void>();

  return (
    requestId: number,
    userLimitUpdateRequestManualDecisionArgumentsSchema: UserLimitUpdateRequestManualDecisionArgumentsSchema
  ) => {
    return declineDepositLimitUpdateRequestManually({
      url: `/depositlimits/requests/${requestId}/decline`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: userLimitUpdateRequestManualDecisionArgumentsSchema,
    });
  };
};

export const useDeclineDepositLimitUpdateRequestManuallyMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | ErrorSchema | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeclineDepositLimitUpdateRequestManuallyHook>>>,
    TError,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useDeclineDepositLimitUpdateRequestManuallyHook>>>,
  TError,
  { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const declineDepositLimitUpdateRequestManually = useDeclineDepositLimitUpdateRequestManuallyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useDeclineDepositLimitUpdateRequestManuallyHook>>>,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema }
  > = props => {
    const { requestId, data } = props ?? {};

    return declineDepositLimitUpdateRequestManually(requestId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeclineDepositLimitUpdateRequestManuallyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDeclineDepositLimitUpdateRequestManuallyHook>>>
>;
export type DeclineDepositLimitUpdateRequestManuallyMutationBody = UserLimitUpdateRequestManualDecisionArgumentsSchema;
export type DeclineDepositLimitUpdateRequestManuallyMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | ErrorSchema
  | DefaultResponse;

/**
 * @summary Manually decline a deposit limit update request
 */
export const useDeclineDepositLimitUpdateRequestManually = <
  TError = BadRequestResponse | UnauthorizedResponse | ErrorSchema | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeclineDepositLimitUpdateRequestManuallyHook>>>,
    TError,
    { requestId: number; data: UserLimitUpdateRequestManualDecisionArgumentsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useDeclineDepositLimitUpdateRequestManuallyMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Sets requiresManualApproval flag to true for deposit limit update request
 * @summary Sets requiresManualApproval flag to true for deposit limit update request
 */
export const useSetDepositLimitUpdateRequestToRequireManualApprovalHook = () => {
  const setDepositLimitUpdateRequestToRequireManualApproval = useCustomInstance<void>();

  return (requestId: number) => {
    return setDepositLimitUpdateRequestToRequireManualApproval({
      url: `/depositlimits/requests/${requestId}/requiresmanualapproval`,
      method: 'patch',
    });
  };
};

export const useSetDepositLimitUpdateRequestToRequireManualApprovalMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | ErrorSchema | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetDepositLimitUpdateRequestToRequireManualApprovalHook>>>,
    TError,
    { requestId: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetDepositLimitUpdateRequestToRequireManualApprovalHook>>>,
  TError,
  { requestId: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setDepositLimitUpdateRequestToRequireManualApproval =
    useSetDepositLimitUpdateRequestToRequireManualApprovalHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetDepositLimitUpdateRequestToRequireManualApprovalHook>>>,
    { requestId: number }
  > = props => {
    const { requestId } = props ?? {};

    return setDepositLimitUpdateRequestToRequireManualApproval(requestId);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetDepositLimitUpdateRequestToRequireManualApprovalMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetDepositLimitUpdateRequestToRequireManualApprovalHook>>>
>;

export type SetDepositLimitUpdateRequestToRequireManualApprovalMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | ErrorSchema
  | DefaultResponse;

/**
 * @summary Sets requiresManualApproval flag to true for deposit limit update request
 */
export const useSetDepositLimitUpdateRequestToRequireManualApproval = <
  TError = BadRequestResponse | UnauthorizedResponse | ErrorSchema | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetDepositLimitUpdateRequestToRequireManualApprovalHook>>>,
    TError,
    { requestId: number },
    TContext
  >;
}) => {
  const mutationOptions = useSetDepositLimitUpdateRequestToRequireManualApprovalMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Sets the maximum deposit limit for the user. If the user's deposit limit is higher than the given value, automatically sets the user's limit to this max value.
 * @summary Sets the maximum deposit limit for the user. If the user's deposit limit is higher than the given value, automatically sets the user's limit to this max value.
 */
export const useSetMaximumDepositLimitHook = () => {
  const setMaximumDepositLimit = useCustomInstance<MaxDepositLimitSchema>();

  return (userId: number, updateMaxDepositLimitSchema: UpdateMaxDepositLimitSchema) => {
    return setMaximumDepositLimit({
      url: `/depositlimits/${userId}/max`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: updateMaxDepositLimitSchema,
    });
  };
};

export const useSetMaximumDepositLimitMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetMaximumDepositLimitHook>>>,
    TError,
    { userId: number; data: UpdateMaxDepositLimitSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSetMaximumDepositLimitHook>>>,
  TError,
  { userId: number; data: UpdateMaxDepositLimitSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const setMaximumDepositLimit = useSetMaximumDepositLimitHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSetMaximumDepositLimitHook>>>,
    { userId: number; data: UpdateMaxDepositLimitSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return setMaximumDepositLimit(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetMaximumDepositLimitMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetMaximumDepositLimitHook>>>
>;
export type SetMaximumDepositLimitMutationBody = UpdateMaxDepositLimitSchema;
export type SetMaximumDepositLimitMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Sets the maximum deposit limit for the user. If the user's deposit limit is higher than the given value, automatically sets the user's limit to this max value.
 */
export const useSetMaximumDepositLimit = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSetMaximumDepositLimitHook>>>,
    TError,
    { userId: number; data: UpdateMaxDepositLimitSchema },
    TContext
  >;
}) => {
  const mutationOptions = useSetMaximumDepositLimitMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Gets the maximum deposit limits for the user.
 * @summary Gets the maximum deposit limits for the user.
 */
export const useGetMaximumDepositLimitsHook = () => {
  const getMaximumDepositLimits = useCustomInstance<MaxDepositLimitsSchema>();

  return (userId: number, signal?: AbortSignal) => {
    return getMaximumDepositLimits({ url: `/depositlimits/${userId}/max`, method: 'get', signal });
  };
};

export const getGetMaximumDepositLimitsQueryKey = (userId: number) => [`/depositlimits/${userId}/max`] as const;

export const useGetMaximumDepositLimitsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMaximumDepositLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMaximumDepositLimitsHook>>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMaximumDepositLimitsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMaximumDepositLimitsQueryKey(userId);

  const getMaximumDepositLimits = useGetMaximumDepositLimitsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMaximumDepositLimitsHook>>>> = ({ signal }) =>
    getMaximumDepositLimits(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetMaximumDepositLimitsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetMaximumDepositLimitsHook>>>
>;
export type GetMaximumDepositLimitsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Gets the maximum deposit limits for the user.
 */
export const useGetMaximumDepositLimits = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMaximumDepositLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMaximumDepositLimitsHook>>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMaximumDepositLimitsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Cancels the maximum deposit limit for the user.
 * @summary Cancels the maximum deposit limit for the user.
 */
export const useCancelMaximumDepositLimitHook = () => {
  const cancelMaximumDepositLimit = useCustomInstance<void>();

  return (userId: number, cancelMaxLimitRequestBodySchema: CancelMaxLimitRequestBodySchema) => {
    return cancelMaximumDepositLimit({
      url: `/depositlimits/${userId}/max/cancel`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: cancelMaxLimitRequestBodySchema,
    });
  };
};

export const useCancelMaximumDepositLimitMutationOptions = <
  TError = MaxLimitBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelMaximumDepositLimitHook>>>,
    TError,
    { userId: number; data: CancelMaxLimitRequestBodySchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCancelMaximumDepositLimitHook>>>,
  TError,
  { userId: number; data: CancelMaxLimitRequestBodySchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const cancelMaximumDepositLimit = useCancelMaximumDepositLimitHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCancelMaximumDepositLimitHook>>>,
    { userId: number; data: CancelMaxLimitRequestBodySchema }
  > = props => {
    const { userId, data } = props ?? {};

    return cancelMaximumDepositLimit(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelMaximumDepositLimitMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCancelMaximumDepositLimitHook>>>
>;
export type CancelMaximumDepositLimitMutationBody = CancelMaxLimitRequestBodySchema;
export type CancelMaximumDepositLimitMutationError =
  | MaxLimitBadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Cancels the maximum deposit limit for the user.
 */
export const useCancelMaximumDepositLimit = <
  TError = MaxLimitBadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCancelMaximumDepositLimitHook>>>,
    TError,
    { userId: number; data: CancelMaxLimitRequestBodySchema },
    TContext
  >;
}) => {
  const mutationOptions = useCancelMaximumDepositLimitMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Gets the active maximum deposit limits for the user.
 * @summary Gets the active maximum deposit limits for the user.
 */
export const useGetActiveMaximumDepositLimitsHook = () => {
  const getActiveMaximumDepositLimits = useCustomInstance<MaxDepositLimitPerPeriodSchema>();

  return (userId: number, signal?: AbortSignal) => {
    return getActiveMaximumDepositLimits({ url: `/depositlimits/${userId}/max/active`, method: 'get', signal });
  };
};

export const getGetActiveMaximumDepositLimitsQueryKey = (userId: number) =>
  [`/depositlimits/${userId}/max/active`] as const;

export const useGetActiveMaximumDepositLimitsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumDepositLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumDepositLimitsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumDepositLimitsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveMaximumDepositLimitsQueryKey(userId);

  const getActiveMaximumDepositLimits = useGetActiveMaximumDepositLimitsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumDepositLimitsHook>>>> = ({
    signal,
  }) => getActiveMaximumDepositLimits(userId, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetActiveMaximumDepositLimitsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumDepositLimitsHook>>>
>;
export type GetActiveMaximumDepositLimitsQueryError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Gets the active maximum deposit limits for the user.
 */
export const useGetActiveMaximumDepositLimits = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumDepositLimitsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetActiveMaximumDepositLimitsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetActiveMaximumDepositLimitsQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
