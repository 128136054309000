import { Outlet } from 'react-router';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import BalanceLimitsPage from 'src/pages/OnlineCasino/Lookup/Components/DetailsComponents/BalanceLimits/BalanceLimitsPage';
import UserLoyaltyTransactionsPage from 'src/pages/OnlineCasino/Lookup/Components/DetailsComponents/LoyaltyTransactions/UserLoyaltyTransactionsPage';
import PlayingBreakPage from 'src/pages/OnlineCasino/Lookup/Components/DetailsComponents/PlayingBreak/PlayingBreakPage';
import UserSessionLimitsPage from 'src/pages/OnlineCasino/Lookup/Components/DetailsComponents/SessionLimits/UserSessionLimitsPage';
import BankaccountDetails from 'src/pages/OnlineCasino/Tasks/Verifications/Components/DetailsComponents/BankAccounts/BankaccountDetails';

import AccountStatistics from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/AccountData/Components/AccountStatistics';
import AvailableBonusesPage from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/AvailableBonuses/AvailableBonusesPage';
import DepositBonusesPage from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/DepositBonuses/DepositBonusesPage';
import UserDepositLimitsPage from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/DepositLimits/UserDepositLimitsPage';
import DepositDetails from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/Deposits/DepositDetails';
import Deposits from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/Deposits/Deposits';
import FreeSpinsPage from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/FreeSpins/FreeSpinsPage';
import GamesActivity from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/GamesActivity/GamesActivity';
import PgsiPage from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/Pgsi/Page';
import LinkedAccounts from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/UserData/Components/LinkedAccounts';
import Logins from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/UserData/Components/Logins';
import UserNotes from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/UserData/Components/UserNotes/UserNotes';
import VaultTransactions from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/VaultTransactions/VaultTransactions';
import VouchersPage from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/Vouchers/VouchersPage';
import WithdrawalDetails from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/Withdrawals/WithdrawalDetails';
import SearchWithdrawRequests from '../../pages/OnlineCasino/Lookup/Components/DetailsComponents/WithdrawRequests/SearchWithdrawRequests';
import LookupDetails from '../../pages/OnlineCasino/Lookup/Components/LookupDetails';
import Registration from '../../pages/OnlineCasino/Tasks/Verifications/Components/Registration/Registration';
import VerificationDetails from '../../pages/OnlineCasino/Tasks/Verifications/Components/VerificationDetails';
import { BaseRoute } from '../RouteInterfaces';

export const userRoutes: Array<BaseRoute> = [
  {
    path: 'users/:userId',
    name: 'Users',
    element: Outlet,
    hiddenLink: true,
    children: [
      {
        path: 'verifications',
        name: 'User Verification Details',
        element: VerificationDetails,
        hiddenLink: true,
      },
      {
        path: 'registration',
        name: 'User Registration',
        element: Registration,
        hiddenLink: true,
        permissions: OnlineCasinoPermissions.getUserRegistration,
      },
      {
        path: 'details',
        name: 'User Details',
        element: LookupDetails,
        hiddenLink: true,
      },
      {
        path: 'notes',
        name: 'User Notes',
        element: UserNotes,
        hiddenLink: true,
      },
      {
        path: 'linkedaccounts',
        name: 'User Linked Accounts',
        element: LinkedAccounts,
        hiddenLink: true,
      },
      {
        path: 'logins',
        name: 'User Logins',
        element: Logins,
        hiddenLink: true,
      },
      {
        path: 'account-statistics',
        name: 'User Account Statistics',
        element: AccountStatistics,
        hiddenLink: true,
      },
      {
        path: 'deposits',
        name: 'User Deposits',
        element: Deposits,
        hiddenLink: true,
      },
      {
        path: 'deposits/:depositId',
        name: 'User Deposit Details',
        element: DepositDetails,
        hiddenLink: true,
      },
      {
        path: 'withdrawal-requests',
        name: 'User Withdrawal Requests',
        element: SearchWithdrawRequests,
        hiddenLink: true,
      },
      {
        path: 'withdrawals/:withdrawalId',
        name: 'User Withdrawal Details',
        element: WithdrawalDetails,
        hiddenLink: true,
      },
      {
        path: 'availablebonuses',
        name: 'User Available Bonuses',
        element: AvailableBonusesPage,
        hiddenLink: true,
      },
      {
        path: 'depositbonuses',
        name: 'User Deposit Bonuses',
        element: DepositBonusesPage,
        hiddenLink: true,
      },
      {
        path: 'freespins',
        name: 'User Free Spins',
        element: FreeSpinsPage,
        hiddenLink: true,
      },
      {
        path: 'depositlimits',
        name: 'User Deposit Limits',
        element: UserDepositLimitsPage,
        hiddenLink: true,
      },
      {
        path: 'balancelimits',
        name: 'User Balance Limits',
        element: BalanceLimitsPage,
        hiddenLink: true,
      },
      {
        path: 'sessionlimits',
        name: 'User Session Limits',
        element: UserSessionLimitsPage,
        hiddenLink: true,
      },
      {
        path: 'vouchers',
        name: 'User Vouchers',
        element: VouchersPage,
        hiddenLink: true,
        permissions: OnlineCasinoPermissions.getUserVouchers,
      },
      {
        path: 'playingBreaks',
        name: 'Playing Breaks',
        element: PlayingBreakPage,
        hiddenLink: true,
        permissions: OnlineCasinoPermissions.getPlayingBreak,
      },
      {
        path: 'pgsi',
        name: 'User PGSI',
        element: PgsiPage,
        hiddenLink: true,
        permissions: OnlineCasinoPermissions.getSelfTests,
      },
      {
        path: 'vault/transactions',
        name: 'User Vault Transactions',
        element: VaultTransactions,
        hiddenLink: true,
      },
      {
        path: 'userbankaccounts',
        name: 'User Bank account Transactions',
        element: BankaccountDetails,
        hiddenLink: true,
      },
      {
        path: 'gameactivities',
        name: 'Game Activities',
        element: GamesActivity,
        hiddenLink: true,
      },
      {
        path: 'loyaltyTransactions',
        name: 'User Loyalty Transactions',
        element: UserLoyaltyTransactionsPage,
        hiddenLink: true,
      },
    ],
  },
];

export default userRoutes;
