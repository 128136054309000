import { Dispatch, SetStateAction, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { ErrorSchema } from '@greenisland-api';
import { GameServerPermissions } from '@greenisland-core/permissions/GameServerPermissions';
import { Game, getQueryGamesQueryKey, usePutGame } from '@greenisland-store/gameServer';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from '@greenisland-common/components/atoms';

import { usePermission } from '../../../app/hooks';
import GameGeneralSettings from './GameGeneralSettings';

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedGame: Game | null;
}

const EditGameForm = ({ open, setOpen, selectedGame }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const canEditGame = usePermission(GameServerPermissions.putGame);
  const [gameValidation, setGameValidation] = useState(false);
  const methods = useForm<Game>({
    shouldFocusError: true,
    mode: 'onChange',
  });
  const { handleSubmit } = methods;

  const { mutate: updateGame, isLoading } = usePutGame({
    mutation: {
      onSuccess: async () => {
        queryClient.invalidateQueries(getQueryGamesQueryKey());
        setGameValidation(false);
        enqueueSnackbar(t('success'), { variant: 'success' });
        setOpen(false);
      },
      onError: (error: ErrorSchema) => {
        enqueueSnackbar(`${t('errorOccurred')} ${error?.message || 'unknown'}`, { variant: 'error' });
      },
    },
  });

  function GameValidationHandler(entry: Game) {
    if (entry.name == null) return false;
    return true;
  }

  const onSubmit = (formData: Game) => {
    const gameValidation = GameValidationHandler(formData);
    setGameValidation(gameValidation);

    if (!gameValidation) return;

    if (selectedGame?.gameReference) {
      updateGame({ gameReference: selectedGame.gameReference, data: formData });
    }
  };

  if (isLoading) return <Skeleton variant="rectangular" height={600} />;

  return canEditGame && selectedGame ? (
    <Dialog fullWidth open={open} maxWidth="lg" onClose={() => setOpen(false)}>
      <DialogTitle></DialogTitle>
      <IconButton
        edge="start"
        color="inherit"
        onClick={() => setOpen(false)}
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 16,
          top: 8,
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </IconButton>
      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Typography sx={{ mb: 2 }} variant="body2" fontSize="large" fontWeight="bold">
                {t('content.gameserver.game.form.updategame')}
              </Typography>
              <GameGeneralSettings game={selectedGame} isCreateMode={false} />
            </Stack>
            <DialogActions sx={{ px: 3, py: 2 }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setOpen(false);
                }}
              >
                {t('close')}
              </Button>
              <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
                {t('save')}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  ) : null;
};

export default EditGameForm;
