/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EGameLibraryModelSchema = (typeof EGameLibraryModelSchema)[keyof typeof EGameLibraryModelSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EGameLibraryModelSchema = {
  Game: 'Game',
  GameCategory: 'GameCategory',
  GameConfiguration: 'GameConfiguration',
  GameProvider: 'GameProvider',
  GameStudio: 'GameStudio',
  GameTheme: 'GameTheme',
} as const;
