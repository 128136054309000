/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EWindowTypeSchema = (typeof EWindowTypeSchema)[keyof typeof EWindowTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EWindowTypeSchema = {
  SlidingWindow: 'SlidingWindow',
  FixedDayReset: 'FixedDayReset',
} as const;
