import { GridColumnVisibilityModel } from '@mui/x-data-grid-pro';
import { useLocalStorage } from 'usehooks-ts';

const orderDefault = [
  'balanceAfterWithdrawal',
  'identityVerification',
  'domicileVerification',
  'bankAccountVerification',
  'walletVerification',
  'amountOfWithdrawal',
  'userLoyalty',
  'username',
  'requestedOn',
];

const orderCasino = [
  'identityVerification',
  'domicileVerification',
  'bankAccountVerification',
  'walletVerification',
  'balanceAfterWithdrawal',
  'amountOfWithdrawal',
  'destination',
  'userLoyalty',
  'username',
];

const useColumnOrderPreference = (destinationName: string) => {
  const columnPreferenceKey = `BO-Withdrawals-${destinationName}-columns-preferences`;
  const columnOrderPrefernceKey = `BO-Withdrawals-${destinationName}-columns-order-preferences`;

  const [columnPreferences, setColumnPreferences] = useLocalStorage<GridColumnVisibilityModel>(columnPreferenceKey, {
    userId: false,
    withdrawalId: false,
    __detail_panel_toggle__: false,
  });
  const [columnOrderPreferences] = useLocalStorage<string[] | undefined>(columnOrderPrefernceKey, undefined);

  return { columnPreferences, setColumnPreferences, columnOrderPreferences, orderDefault, orderCasino };
};

export default useColumnOrderPreference;
