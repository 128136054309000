import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HelpOutline } from '@mui/icons-material';
import { FormControlLabel, Icon, Switch, TextField, Tooltip } from '@mui/material';

import type { IndividualActionForm } from '../context';
import IndividualActionFormListItem from './IndividualActionFormListItem';

const VoucherForm = () => {
  const { t } = useTranslation();
  const { register, watch, setValue, errors, control } = useFormContext<IndividualActionForm>();
  const enabledVoucher = watch('enabledVoucher');
  const externalVoucher = !watch('internalVoucher');
  const fieldNames: Array<keyof IndividualActionForm> = [
    ...(externalVoucher ? (['productGroupIdVoucher', 'productIdVoucher'] as Array<keyof IndividualActionForm>) : []),
    'productValueVoucher',
    'durationVoucher',
  ];

  useEffect(() => {
    if (!enabledVoucher) {
      setValue('productValueVoucher', '');
      if (enabledVoucher) {
        setValue('productIdVoucher', '');
        setValue('productGroupIdVoucher', '');
      }
      setValue('durationVoucher', '');
    }
  }, [setValue, enabledVoucher]);

  const getField = function (fieldName: keyof IndividualActionForm, index: number) {
    if (fieldName === 'durationVoucher') {
      return (
        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            size="small"
            key={fieldName}
            name={fieldName}
            inputRef={register({})}
            error={Boolean(errors[fieldName])}
            disabled={!enabledVoucher}
            type="number"
            required={false}
            label={t(fieldName)}
          />
          <Tooltip title={t('voucherDuration.Explanation')}>
            <Icon fontSize={'small'} sx={{ ml: 1 }}>
              <HelpOutline />
            </Icon>
          </Tooltip>
        </div>
      );
    } else {
      return (
        <TextField
          size="small"
          key={fieldName}
          name={fieldName}
          inputRef={register({ required: enabledVoucher, min: 1 })}
          error={Boolean(errors[fieldName])}
          disabled={!enabledVoucher}
          type="number"
          required={enabledVoucher}
          label={t(fieldName)}
        />
      );
    }
  };

  return (
    <IndividualActionFormListItem enabled={enabledVoucher} name="enabledVoucher" listItemText={t('voucher')}>
      <FormControlLabel
        label={t('action.internalVoucher')}
        control={
          <Controller
            name={'internalVoucher'}
            control={control}
            render={({ value, onChange }) => (
              <Switch edge="end" onChange={event => onChange(event.target.checked)} checked={value} />
            )}
          />
        }
      />
      {fieldNames.map((fieldName, index) => getField(fieldName, index))}
    </IndividualActionFormListItem>
  );
};

export default VoucherForm;
