import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import {
  EGameSessionCategorySchema,
  GameSessionSchema,
  GameSessionsSortKeyParamParameter,
  GameSessionsSummaryGamingRevenueComparisonParameter,
  GameSessionsSummaryGamingRevenueComparisonValueParameter,
  GetGameSessionsParams,
  SortOrderParamParameter,
  useGetGameSessions,
} from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useDebouncedValue } from '@lilib/hooks';
import { Box, LinearProgress, Link, Stack, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';

import {
  DataGridContainer,
  DataGridPagination,
  DateTime,
  FontAwesomeIcon,
  PermissionWrapper,
} from '@greenisland-common/components/atoms';
import LimitedDataGrid from '@greenisland-common/components/atoms/LimitedDataGrid';

import { useUpdateSearchParams } from '@greenisland-common/hooks/useUpdateSearchParams';

import { capitalize } from '@greenisland-common/helpers/formatters';

import { formatCurrency } from '../../../../app/helpers/transformFunctions';
import { usePermission } from '../../../../app/hooks';
import { TransactionParamsType } from '../../Lookup/Components/DetailsComponents/Constants/Transactions';
import { GameSummarySearchFilters, SessionGameCardType } from '../Constants';
import { getGameSessionDurationValue } from '../helpers/getGameSessionDurationValue';
import useGetGameSessionFiltersQuery from '../hooks/useGetGameSessionFiltersQuery';
import useGetGameSessionFormParams, { GameSessionFormType } from '../hooks/useGetGameSessionFormParams';

const CARD_PAGE_SIZE = 5;
const FULL_PAGE_SIZE = 10;

type Row = GameSessionSchema;

interface Props {
  cardType: SessionGameCardType;
  userId: string | null;
  gameId: string | null;
  startDate: Date;
  endDate: Date;
  sortOrder: SortOrderParamParameter;
  sortKey: GameSessionsSortKeyParamParameter;
  gamingRevenueComparison?: GameSessionsSummaryGamingRevenueComparisonParameter;
  gamingRevenueComparisonValue?: GameSessionsSummaryGamingRevenueComparisonValueParameter;
}

const GameSessionsTableData = ({
  cardType,
  userId,
  gameId,
  startDate,
  endDate,
  sortKey,
  sortOrder,
  gamingRevenueComparison = GameSessionsSummaryGamingRevenueComparisonParameter.None,
  gamingRevenueComparisonValue,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const canReadGameSessions = usePermission(OnlineCasinoPermissions.getGameSessions);
  const updateGameSessionQueryParamsHandler = useUpdateSearchParams();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(cardType === SessionGameCardType.ALL ? FULL_PAGE_SIZE : CARD_PAGE_SIZE);

  const { userIdQuery, gameIdQuery, pageQuery } = useGetGameSessionFiltersQuery();

  const [debouncedUserId] = useDebouncedValue(userId ? userId : userIdQuery, { wait: 500 });
  const [debouncedGameId] = useDebouncedValue(gameId ? gameId : gameIdQuery, { wait: 500 });

  const params = useGetGameSessionFormParams({
    formType: GameSessionFormType.SESSIONS,
    params: {
      debouncedUserId,
      debouncedGameId,
      startDate,
      endDate,
      sortOrder,
      sortKey,
      gamingRevenueComparisonValue,
      pageSize,
      page,
      gamingRevenueComparison,
    },
  });

  const {
    data: gameSessions,
    isLoading,
    isError,
  } = useGetGameSessions(
    {
      ...(params as GetGameSessionsParams),
    },
    {
      query: { enabled: canReadGameSessions },
    }
  );

  const renderGameNameFieldHandler = useCallback(
    (row: Row) => {
      if (row?.category === EGameSessionCategorySchema.tournament) {
        return (
          <Stack display="flex" flexDirection="column" lineHeight="normal">
            {row?.providerName
              ? t('content.gameserver.tournamentconfiguration.tournamentRedPandaTitle')
              : t('content.gameserver.tournamentconfiguration.tournamentMultiplierTitle')}
          </Stack>
        );
      } else {
        return (
          <Stack display="flex" flexDirection="column" lineHeight="normal">
            {row?.gameName}
            <Typography variant="caption">{row?.providerName}</Typography>
          </Stack>
        );
      }
    },
    [t]
  );

  const navigateToTransactionsHandler = useCallback(
    (session: GameSessionSchema) => {
      const searchParams = new URLSearchParams({
        [TransactionParamsType.PAGE]: '0',
        [TransactionParamsType.START_DATE]: session.startDateTime.toString(),
        [TransactionParamsType.END_DATE]: session.expiryDateTime.toString(),
        [TransactionParamsType.USER_ID]: session.userId.toString(),
        [TransactionParamsType.ACCOUNT_ID]: session.accountId.toString(),
      });

      if (session?.providerName) {
        searchParams.set(TransactionParamsType.GAME_PROVIDER_NAME, session.providerName.toString());
      }
      if (session?.gameName) {
        searchParams.set(TransactionParamsType.GAME_NAME, session.gameName.toString());
      }

      const filtersQuery = '?' + searchParams.toString();

      navigate(`../txs${filtersQuery}`);
    },
    [navigate]
  );

  const columns = useMemo<GridColumns<Row>>(() => {
    const dashboardColumns = [
      {
        field: '',
        headerName: capitalize(t('game')),
        renderCell: (params: GridRenderCellParams<Row>) => renderGameNameFieldHandler(params.row),
        sortable: false,
        minWidth: 200,
        flex: 0.15,
      },
      {
        field: 'nickname',
        headerName: capitalize(t('user')),
        renderCell: ({ row }: { row: Row }) => (
          <Stack display="flex" flexDirection="column">
            <Typography variant="caption" fontSize="14px" lineHeight="normal">
              <Link href={`${cardType !== SessionGameCardType.ALL ? './' : '../'}users/${row?.userId}/details`}>
                {row?.nickname}
              </Link>
            </Typography>
            <Typography variant="caption" fontSize="smaller" lineHeight="normal">
              {capitalize(t('userId'))} - {row?.userId}
            </Typography>
          </Stack>
        ),
        sortable: false,
        minWidth: 200,
        flex: 0.15,
      },
      {
        field: 'gamingRevenue',
        headerName: capitalize(t('gamesActivityGamingRevenue')),
        valueFormatter: ({ value }: { value: number }) => formatCurrency(value),
        sortable: false,
        minWidth: 150,
        flex: 0.1,
      },
      {
        field: 'rtp',
        headerName: capitalize(t('rtp')),
        valueFormatter: ({ value }: { value: number }) => `${(value * 100).toFixed(2)}%`,
        sortable: false,
        minWidth: 100,
        flex: 0.1,
      },
    ];
    const lookupDetailsColumns = [
      {
        field: '',
        headerName: capitalize(t('game')),
        renderCell: (params: GridRenderCellParams<Row>) => renderGameNameFieldHandler(params.row),
        sortable: false,
        minWidth: 150,
        flex: 0.2,
      },
      {
        field: 'gamingRevenue',
        headerName: capitalize(t('gamesActivityGamingRevenue')),
        valueFormatter: ({ value }: { value: number }) => formatCurrency(value),
        sortable: false,
        minWidth: 130,
        flex: 0.1,
      },
      {
        field: 'rtp',
        headerName: capitalize(t('rtp')),
        valueFormatter: ({ value }: { value: number }) => `${(value * 100).toFixed(2)}%`,
        sortable: false,
        minWidth: 100,
        flex: 0.1,
      },
    ];
    const allDataColumns = [
      {
        field: 'actions',
        type: 'actions',
        minWidth: 80,
        flex: 0.01,
        getActions: (params: GridRowParams<Row>) => [
          <Tooltip title={t('details')} key="details">
            <Box>
              <GridActionsCellItem
                icon={<FontAwesomeIcon icon={faEye} />}
                title={t('details')}
                label={t('details')}
                onClick={() => navigateToTransactionsHandler(params.row)}
              />
            </Box>
          </Tooltip>,
        ],
      },
      {
        field: '',
        headerName: capitalize(t('game')),
        renderCell: (params: GridRenderCellParams<Row>) => renderGameNameFieldHandler(params.row),
        sortable: false,
        minWidth: 200,
        flex: 0.15,
      },
      {
        field: 'nickname',
        headerName: capitalize(t('user')),
        renderCell: ({ row }: { row: Row }) => (
          <Stack display="flex" flexDirection="column">
            <Typography variant="caption" fontSize="14px">
              <Link href={`${cardType !== SessionGameCardType.ALL ? './' : '../'}users/${row?.userId}/details`}>
                {row?.nickname}
              </Link>
            </Typography>
            <Typography variant="caption" fontSize="smaller">
              {capitalize(t('userId'))} - {row?.userId}
            </Typography>
          </Stack>
        ),
        sortable: false,
        minWidth: 180,
        flex: 0.3,
      },
      {
        field: 'startDateTime',
        headerName: capitalize(t('startDate')),
        renderCell: ({ row }: { row: Row }) => <DateTime dateTime={row.startDateTime ?? 0} />,
        sortable: false,
        minWidth: 120,
        flex: 0.1,
      },
      {
        field: 'expiryDateTime',
        headerName: capitalize(t('endDate')),
        renderCell: ({ row }: { row: Row }) => <DateTime dateTime={row.expiryDateTime ?? 0} />,
        sortable: false,
        minWidth: 120,
        flex: 0.1,
      },
      {
        field: 'duration',
        headerName: capitalize(t('boost.duration')),
        renderCell: ({ row }: { row: Row }) => getGameSessionDurationValue(row.startDateTime, row.expiryDateTime),
        sortable: false,
        minWidth: 100,
        flex: 0.1,
      },
      {
        field: 'gamingRevenue',
        headerName: capitalize(t('gamesActivityGamingRevenue')),
        valueFormatter: ({ value }: { value: number }) => formatCurrency(value),
        sortable: false,
        minWidth: 150,
        flex: 0.1,
      },
      {
        field: 'rtp',
        headerName: capitalize(t('rtp')),
        valueFormatter: ({ value }: { value: number }) => `${(value * 100).toFixed(2)}%`,
        sortable: false,
        minWidth: 100,
        flex: 0.1,
      },
      {
        field: 'totalBetAmount',
        headerName: capitalize(t('gamesActivityTotalStake')),
        valueFormatter: ({ value }: { value: number }) => formatCurrency(value),
        sortable: false,
        minWidth: 150,
        flex: 0.1,
      },
      {
        field: 'totalWinAmount',
        headerName: capitalize(t('gamesActivityTotalWinnings')),
        valueFormatter: ({ value }: { value: number }) => formatCurrency(value),
        sortable: false,
        minWidth: 150,
        flex: 0.1,
      },
      {
        field: 'betCount',
        headerName: capitalize(t('gamesActivityNumberOfBets')),
        valueFormatter: ({ value }: { value: number }) => value,
        sortable: false,
        minWidth: 120,
        flex: 0.1,
      },
      {
        field: 'winCount',
        headerName: capitalize(t('gamesActivityNumberOfWins')),
        valueFormatter: ({ value }: { value: number }) => value,
        sortable: false,
        minWidth: 140,
        flex: 0.1,
      },
    ];

    if (cardType === SessionGameCardType.DASHBOARD) {
      return dashboardColumns;
    } else if (cardType === SessionGameCardType.LOOKUP) {
      return lookupDetailsColumns;
    } else {
      return allDataColumns;
    }
  }, [cardType, navigateToTransactionsHandler, renderGameNameFieldHandler, t]);

  const rows = useMemo(
    () =>
      gameSessions?.results?.map<Row>(gameSession => ({
        ...gameSession,
      })) ?? [],
    [gameSessions?.results]
  );

  return (
    <Box mt={2}>
      <PermissionWrapper
        errorMessage={t('gamesession.permissions.error.fetchError')}
        isError={isError}
        isLoading={false}
        permission={OnlineCasinoPermissions.getGameSessions}
      >
        <DataGridContainer>
          <LimitedDataGrid
            density="compact"
            autoHeight
            rowHeight={cardType === SessionGameCardType.ALL ? 70 : 50}
            loading={isLoading}
            rows={rows}
            columns={columns}
            pagination
            page={Number(pageQuery) || page}
            rowCount={gameSessions?.pagingDetails?.totalItems}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(page: number) => {
              if (!isLoading) {
                if (cardType === SessionGameCardType.ALL) {
                  updateGameSessionQueryParamsHandler(GameSummarySearchFilters.PAGE, String(page));
                } else {
                  setPage(page);
                }
              }
            }}
            onPageSizeChange={setPageSize}
            components={{ Pagination: DataGridPagination, LoadingOverlay: LinearProgress }}
          />
        </DataGridContainer>
      </PermissionWrapper>
    </Box>
  );
};

export default GameSessionsTableData;
