import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { getGetPaymentMethodsQueryKey, useCreatePaymentMethod } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useSnackbar } from 'notistack';

import { usePermission } from '../../../../../../app/hooks';
import PaymentMethodForm from './PaymentMethodForm';

const AddPaymentMethod = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const canEditPaymentMethod = usePermission(OnlineCasinoPermissions.updatePaymentMethod);

  const { mutate: addPaymentMethod, isLoading: addIsLoading } = useCreatePaymentMethod({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetPaymentMethodsQueryKey());
        navigate('../payment-methods');
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  return (
    <PaymentMethodForm
      canSubmitForm={canEditPaymentMethod}
      loading={addIsLoading}
      handleSubmitForm={data => addPaymentMethod({ data })}
    />
  );
};

export default memo(AddPaymentMethod);
