import { Badge } from '@mui/material';

import { useTenantContext } from '../../app/context/TenantContext';

export enum BadgeType {
  AGENT_TASKS = 'Agent Tasks',
  VERIFICATIONS = 'Verifications',
  REPORT_CRUKS = 'Report Cruks',
}

interface Props {
  badgeType: BadgeType;
}

const BadgeCounter = ({ badgeType }: Props) => {
  const { openAgentTaskCount, verificationsWaitingForApprovalCount, reportToCruksUsersCount } = useTenantContext();

  const badges = {
    [BadgeType.AGENT_TASKS]: openAgentTaskCount,
    [BadgeType.VERIFICATIONS]: verificationsWaitingForApprovalCount,
    [BadgeType.REPORT_CRUKS]: reportToCruksUsersCount,
  };

  return <Badge badgeContent={badges[badgeType]} color="error" max={999} />;
};

export default BadgeCounter;
