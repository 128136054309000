import { useSearchParams } from 'react-router-dom';

import { GameSummarySearchFilters } from '../Constants';

export default function useGetGameSessionFiltersQuery() {
  const [searchParams] = useSearchParams();

  const userIdQuery = searchParams.get(GameSummarySearchFilters.USER_ID);
  const gameIdQuery = searchParams.get(GameSummarySearchFilters.GAME_ID);
  const startDateQuery = searchParams.get(GameSummarySearchFilters.START_DATE);
  const endDateQuery = searchParams.get(GameSummarySearchFilters.END_DATE);
  const pageQuery = searchParams.get(GameSummarySearchFilters.PAGE);
  const groupByKeyQuery = searchParams.get(GameSummarySearchFilters.GROUP_BY_KEY);
  const sortByKeyQuery = searchParams.get(GameSummarySearchFilters.SORT_BY_KEY);
  const sortByOrderQuery = searchParams.get(GameSummarySearchFilters.SORT_BY_ORDER);
  const gamingRevenueComparisonQuery = searchParams.get(GameSummarySearchFilters.GAMING_REVENUE_COMPARISON);
  const gamingRevenueComparisonValueQuery = searchParams.get(GameSummarySearchFilters.GAMING_REVENUE_COMPARISON_VALUE);
  const providerIdQuery = searchParams.get(GameSummarySearchFilters.PROVIDER_ID);

  return {
    userIdQuery,
    gameIdQuery,
    startDateQuery,
    endDateQuery,
    pageQuery,
    groupByKeyQuery,
    sortByKeyQuery,
    sortByOrderQuery,
    gamingRevenueComparisonQuery,
    gamingRevenueComparisonValueQuery,
    providerIdQuery,
  };
}
