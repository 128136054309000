import { memo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EWindowTypeSchema, PaymentMethodLimitSchema, PaymentMethodLimitsDataSchema } from '@greenisland-api';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField, Typography } from '@mui/material';

import { Checkbox, FormError, SelectInput } from '@greenisland-common/components/atoms';

import {
  defaultPaymentMethodLimitFormValue,
  getDefaultPaymentMethodLimitFormValue,
  PaymentMethodLimitsFormSchema,
} from './helpers/PaymentMethodFormContext';

export interface PaymentMethodLimitsFormProps {
  canSubmitForm: boolean;
  loading: boolean;
  defaultLimits?: PaymentMethodLimitSchema;
  handleSubmitForm: (data: PaymentMethodLimitsDataSchema) => void;
}

const PaymentMethodLimitsForm = ({
  canSubmitForm,
  loading,
  defaultLimits,
  handleSubmitForm,
}: PaymentMethodLimitsFormProps) => {
  const { t } = useTranslation();

  const methods = useForm<PaymentMethodLimitsFormSchema>({
    defaultValues: defaultPaymentMethodLimitFormValue,
    shouldFocusError: true,
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = methods;

  const paymentMethodLimitFormToRequest = (formData: PaymentMethodLimitsFormSchema): PaymentMethodLimitsDataSchema => ({
    minimumDepositAmount: formData.minimumDepositAmount,
    maximumDailyDepositLimit: formData.maximumDailyDepositLimit,
    maximumWeeklyDepositLimit: formData.maximumWeeklyDepositLimit,
    maximumMonthlyDepositLimit: formData.maximumMonthlyDepositLimit,
    minimumWithdrawAmount: formData.minimumWithdrawAmount,
    maximumDailyWithdrawLimit: formData.maximumDailyWithdrawLimit,
    maximumWeeklyWithdrawLimit: formData.maximumWeeklyWithdrawLimit,
    maximumMonthlyWithdrawLimit: formData.maximumMonthlyWithdrawLimit,
    maximumDailyInstantWithdrawLimit: formData.maximumDailyInstantWithdrawLimit,
    windowType: formData.windowType,
    isMaxDailyWithdrawalsLoyaltyDependant: formData.isMaxDailyWithdrawalsLoyaltyDependant,
    maxDailyDeposits: formData.maxDailyDeposits,
    maxDailyWithdrawals: formData.maxDailyWithdrawals,
    instantWithdrawalDelay: formData.instantWithdrawalDelay,
  });

  const onSubmit = (formData: PaymentMethodLimitsFormSchema) => {
    const data: PaymentMethodLimitsDataSchema = paymentMethodLimitFormToRequest(formData);
    if (canSubmitForm) handleSubmitForm(data);
  };

  useEffect(() => {
    if (defaultLimits) {
      reset(getDefaultPaymentMethodLimitFormValue(defaultLimits as PaymentMethodLimitsDataSchema));
    }
  }, [defaultLimits, reset]);

  const windowTypes = Object.values(EWindowTypeSchema);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Typography variant="h4">{t('paymentMethods.depositLimits')}</Typography>
          <TextField
            size="small"
            fullWidth
            label={t('paymentMethods.limits.minimumDepositAmount')}
            type="number"
            name="minimumDepositAmount"
            inputProps={{ step: '1' }}
            inputRef={register({
              min: 0,
              valueAsNumber: true,
            })}
            error={!!errors.minimumDepositAmount}
          />
          <TextField
            size="small"
            fullWidth
            label={t('paymentMethods.limits.maximumDailyDepositLimit')}
            type="number"
            name="maximumDailyDepositLimit"
            inputProps={{ step: '1' }}
            inputRef={register({
              min: 0,
              valueAsNumber: true,
            })}
            error={!!errors.maximumDailyDepositLimit}
          />
          <TextField
            size="small"
            fullWidth
            label={t('paymentMethods.limits.maximumWeeklyDepositLimit')}
            type="number"
            name="maximumWeeklyDepositLimit"
            inputProps={{ step: '1' }}
            inputRef={register({
              min: 0,
              valueAsNumber: true,
            })}
            error={!!errors.maximumWeeklyDepositLimit}
          />
          <TextField
            size="small"
            fullWidth
            label={t('paymentMethods.limits.maximumMonthlyDepositLimit')}
            type="number"
            name="maximumMonthlyDepositLimit"
            inputProps={{ step: '1' }}
            inputRef={register({
              min: 0,
              valueAsNumber: true,
            })}
            error={!!errors.maximumMonthlyDepositLimit}
          />
          <TextField
            size="small"
            fullWidth
            label={t('paymentMethods.limits.maxDailyDeposits')}
            type="number"
            name="maxDailyDeposits"
            inputProps={{ step: '1' }}
            inputRef={register({
              min: 0,
              valueAsNumber: true,
            })}
            error={!!errors.maxDailyDeposits}
          />
          <Typography variant="h4">{t('paymentMethods.withdrawalLimits')}</Typography>
          <TextField
            size="small"
            fullWidth
            label={t('paymentMethods.limits.minimumWithdrawalAmount')}
            type="number"
            name="minimumWithdrawAmount"
            inputProps={{ step: '1' }}
            inputRef={register({
              min: 0,
              valueAsNumber: true,
            })}
            error={!!errors.minimumWithdrawAmount}
          />
          <TextField
            size="small"
            fullWidth
            label={t('paymentMethods.limits.maximumDailyWithdrawalLimit')}
            type="number"
            name="maximumDailyWithdrawLimit"
            inputProps={{ step: '1' }}
            inputRef={register({
              min: 0,
              valueAsNumber: true,
            })}
            error={!!errors.maximumDailyWithdrawLimit}
          />
          <TextField
            size="small"
            fullWidth
            label={t('paymentMethods.limits.maximumWeeklyWithdrawalLimit')}
            type="number"
            name="maximumWeeklyWithdrawLimit"
            inputProps={{ step: '1' }}
            inputRef={register({
              min: 0,
              valueAsNumber: true,
            })}
            error={!!errors.maximumWeeklyWithdrawLimit}
          />
          <TextField
            size="small"
            fullWidth
            label={t('paymentMethods.limits.maximumMonthlyWithdrawalLimit')}
            type="number"
            name="maximumMonthlyWithdrawLimit"
            inputProps={{ step: '1' }}
            inputRef={register({
              min: 0,
              valueAsNumber: true,
            })}
            error={!!errors.maximumMonthlyWithdrawLimit}
          />
          <TextField
            size="small"
            fullWidth
            label={t('paymentMethods.limits.maximumDailyInstantWithdrawalLimit')}
            type="number"
            name="maximumDailyInstantWithdrawLimit"
            inputProps={{ step: '1' }}
            inputRef={register({
              min: 0,
              valueAsNumber: true,
            })}
            error={!!errors.maximumDailyInstantWithdrawLimit}
          />
          <SelectInput
            name={'windowType'}
            label={t('paymentMethods.limits.windowType')}
            options={
              windowTypes.map(w => ({
                value: w,
                label: w,
              })) ?? []
            }
          />
          <Checkbox
            label={t('paymentMethods.isMaxDailyWithdrawalsLoyaltyDependant')}
            name="isMaxDailyWithdrawalsLoyaltyDependant"
          />
          <TextField
            size="small"
            fullWidth
            label={t('paymentMethods.limits.maxDailyWithdrawals')}
            type="number"
            name="maxDailyWithdrawals"
            inputProps={{ step: '1' }}
            inputRef={register({
              min: 0,
              valueAsNumber: true,
            })}
            error={!!errors.maxDailyWithdrawals}
          />
          <TextField
            size="small"
            fullWidth
            label={t('paymentMethods.limits.instantWithdrawalDelay')}
            type="number"
            name="instantWithdrawalDelay"
            inputProps={{ step: '1' }}
            inputRef={register({
              min: 0,
              valueAsNumber: true,
            })}
            error={!!errors.instantWithdrawalDelay}
          />
          <Stack>
            <Box display="flex" gap={1}>
              <LoadingButton color="primary" type="submit" variant="contained" loading={loading} disabled={!isDirty}>
                {t('save')}
              </LoadingButton>
            </Box>
            <FormError error={Object.keys(errors).length > 0} message={t('common.formErrors')} />
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default memo(PaymentMethodLimitsForm);
