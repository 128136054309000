/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

export type EMediaGalleryEntryTypeSchema =
  (typeof EMediaGalleryEntryTypeSchema)[keyof typeof EMediaGalleryEntryTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EMediaGalleryEntryTypeSchema = {
  Template: 'Template',
  GameStudioImage: 'GameStudioImage',
  GameCategoryImage: 'GameCategoryImage',
  GameThemeImage: 'GameThemeImage',
  GameStudioLogo: 'GameStudioLogo',
  PaymentMethodImage: 'PaymentMethodImage',
  PaymentMethodLogo: 'PaymentMethodLogo',
  GameBackground: 'GameBackground',
  GameThumbnailSquare: 'GameThumbnailSquare',
  GameThumbnailPortrait: 'GameThumbnailPortrait',
  GameThumbnailLandscape: 'GameThumbnailLandscape',
  GameStudioLogoGrey: 'GameStudioLogoGrey',
  GameStudioLogoWhite: 'GameStudioLogoWhite',
  GameStudioLogoBlack: 'GameStudioLogoBlack',
  GameStudioLogoColorLight: 'GameStudioLogoColorLight',
  GameStudioLogoColorDark: 'GameStudioLogoColorDark',
  BoostImage: 'BoostImage',
  BonusImage: 'BonusImage',
  MultiplierTournamentImage: 'MultiplierTournamentImage',
  MultiplierTournamentBannerImage: 'MultiplierTournamentBannerImage',
  Unknown: 'Unknown',
} as const;
