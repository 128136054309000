import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import enUS from './locales/New Backoffice/en-US/translation.json';
import frBE from './locales/New Backoffice/fr-BE/translation.json';
import nlBE from './locales/New Backoffice/nl-BE/translation.json';

const fallbackLng = 'en-US';
const storageLng = localStorage.getItem('language');

export function setupI18n() {
  if (!i18n.isInitialized) {
    return i18n.use(initReactI18next).init({
      lng: storageLng || fallbackLng,
      fallbackLng: fallbackLng,
      supportedLngs: ['en-US', 'nl-BE', 'fr-BE'],
      resources: {
        'en-US': { translation: enUS },
        'fr-BE': { translation: frBE },
        'nl-BE': { translation: nlBE },
      },
      debug: false,
      react: {
        useSuspense: false,
      },
      interpolation: {
        escapeValue: false,
      },
      nsSeparator: false,
      updateMissing: true,
    });
  }
}

export default i18n;
