/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */

/**
 * Loyalty shop management bad request type:
  * `modelStateInvalid` - Invalid model has been provided.
  * `invalidShopId` - Provided ShopId is invalid.
  * `shopNotFound` - The provided shop was not found.
  * `invalidShopTitle` - Provided ShopTitle is invalid.
  * `invalidImageUrl` - Provided ImageUrl is invalid.
  * `priceNegative` - Price needs to be larger than zero.
  * `noFulfillmentActionId` - FulfillmentActionId must be set.
  * `noFulfillmentItem` - FulfillmentItem must be set.

 */
export type ELoyaltyShopManagementBadRequestTypeSchema =
  (typeof ELoyaltyShopManagementBadRequestTypeSchema)[keyof typeof ELoyaltyShopManagementBadRequestTypeSchema];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ELoyaltyShopManagementBadRequestTypeSchema = {
  modelStateInvalid: 'modelStateInvalid',
  invalidShopId: 'invalidShopId',
  shopNotFound: 'shopNotFound',
  invalidShopTitle: 'invalidShopTitle',
  invalidImageUrl: 'invalidImageUrl',
  priceNegative: 'priceNegative',
  noFulfillmentActionId: 'noFulfillmentActionId',
  noFulfillmentItem: 'noFulfillmentItem',
} as const;
