import { Outlet } from 'react-router';

import JackpotConfigurations from '../../pages/GameServer/Jackpots/JackpotConfigurations/JackpotConfigurations';
import JackpotsInstances from '../../pages/GameServer/Jackpots/JackpotInstances/JackpotInstances';
import { BaseRoute } from '../RouteInterfaces';

const jackpotRoutes: Array<BaseRoute> = [
  {
    name: 'Jackpots',
    path: 'jackpots',
    collapse: true,
    element: Outlet,
    children: [
      {
        path: 'jackpot-configurations',
        name: 'Jackpots Configurations',
        element: JackpotConfigurations,
      },
      {
        path: 'jackpot-instances',
        name: 'Jackpots Instances',
        element: JackpotsInstances,
      },
    ],
  },
];

export default jackpotRoutes;
