import React from 'react';
import { RegistrationSchema } from '@greenisland-api';

import GenericField from './GenericField';

interface Props {
  registration: RegistrationSchema;
}

const EpisBlockedGamesField = ({ registration }: Props) => (
  <GenericField value={!registration.allowedGames} error={!registration.allowedGames} />
);

export default EpisBlockedGamesField;
