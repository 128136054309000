/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * GI Backoffice
 * This describes the available api calls for the backoffice interface of product type online casino.
 * OpenAPI spec version: 1.4.0-onlinecasino
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import { useCustomInstance } from '../../../httpClient';
import type {
  AcurisIndividualProfileSchema,
  AcurisResolveRiskRequestSchema,
  BadRequestResponse,
  BatchProcessingResultSchema,
  DefaultResponse,
  GetIndividualsParams,
  GetMatchesParams,
  GetMatchReviewsParams,
  MatchReviewsSchema,
  MonitorListSchema,
  NotFoundResponse,
  PagedIndividualMatchProfilesSchema,
  PagedMatchReviewResponsesSchema,
  UnauthorizedResponse,
} from '.././schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Retrieve all matches for a given individual monitor record
 */
export const useGetMatchesHook = () => {
  const getMatches = useCustomInstance<PagedIndividualMatchProfilesSchema>();

  return (userId: number, params?: GetMatchesParams, signal?: AbortSignal) => {
    return getMatches({ url: `/acuris/monitor/matches/${userId}`, method: 'get', params, signal });
  };
};

export const getGetMatchesQueryKey = (userId: number, params?: GetMatchesParams) =>
  [`/acuris/monitor/matches/${userId}`, ...(params ? [params] : [])] as const;

export const useGetMatchesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMatchesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params?: GetMatchesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMatchesHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMatchesHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMatchesQueryKey(userId, params);

  const getMatches = useGetMatchesHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMatchesHook>>>> = ({ signal }) =>
    getMatches(userId, params, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetMatchesQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetMatchesHook>>>>;
export type GetMatchesQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Retrieve all matches for a given individual monitor record
 */
export const useGetMatches = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMatchesHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  params?: GetMatchesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMatchesHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMatchesQueryOptions(userId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Insert multiple reviews and set the matches statuses
 */
export const useAddMatchReviewsHook = () => {
  const addMatchReviews = useCustomInstance<BatchProcessingResultSchema>();

  return (userId: number, matchReviewsSchema: MatchReviewsSchema) => {
    return addMatchReviews({
      url: `/acuris/monitor/matches/${userId}/reviews`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: matchReviewsSchema,
    });
  };
};

export const useAddMatchReviewsMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddMatchReviewsHook>>>,
    TError,
    { userId: number; data: MatchReviewsSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAddMatchReviewsHook>>>,
  TError,
  { userId: number; data: MatchReviewsSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const addMatchReviews = useAddMatchReviewsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAddMatchReviewsHook>>>,
    { userId: number; data: MatchReviewsSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return addMatchReviews(userId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddMatchReviewsMutationResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useAddMatchReviewsHook>>>>;
export type AddMatchReviewsMutationBody = MatchReviewsSchema;
export type AddMatchReviewsMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Insert multiple reviews and set the matches statuses
 */
export const useAddMatchReviews = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAddMatchReviewsHook>>>,
    TError,
    { userId: number; data: MatchReviewsSchema },
    TContext
  >;
}) => {
  const mutationOptions = useAddMatchReviewsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get match reviews for a given match
 */
export const useGetMatchReviewsHook = () => {
  const getMatchReviews = useCustomInstance<PagedMatchReviewResponsesSchema>();

  return (userId: number, qrCode: string, params?: GetMatchReviewsParams, signal?: AbortSignal) => {
    return getMatchReviews({
      url: `/acuris/monitor/matches/${userId}/reviews/${qrCode}`,
      method: 'get',
      params,
      signal,
    });
  };
};

export const getGetMatchReviewsQueryKey = (userId: number, qrCode: string, params?: GetMatchReviewsParams) =>
  [`/acuris/monitor/matches/${userId}/reviews/${qrCode}`, ...(params ? [params] : [])] as const;

export const useGetMatchReviewsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMatchReviewsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  qrCode: string,
  params?: GetMatchReviewsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMatchReviewsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMatchReviewsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMatchReviewsQueryKey(userId, qrCode, params);

  const getMatchReviews = useGetMatchReviewsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetMatchReviewsHook>>>> = ({ signal }) =>
    getMatchReviews(userId, qrCode, params, signal);

  return { queryKey, queryFn, enabled: !!(userId && qrCode), ...queryOptions };
};

export type GetMatchReviewsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetMatchReviewsHook>>>>;
export type GetMatchReviewsQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get match reviews for a given match
 */
export const useGetMatchReviews = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetMatchReviewsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  userId: number,
  qrCode: string,
  params?: GetMatchReviewsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetMatchReviewsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetMatchReviewsQueryOptions(userId, qrCode, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get individuals from worklist
 */
export const useGetIndividualsHook = () => {
  const getIndividuals = useCustomInstance<MonitorListSchema>();

  return (params?: GetIndividualsParams, signal?: AbortSignal) => {
    return getIndividuals({ url: `/acuris/monitor/individuals`, method: 'get', params, signal });
  };
};

export const getGetIndividualsQueryKey = (params?: GetIndividualsParams) =>
  [`/acuris/monitor/individuals`, ...(params ? [params] : [])] as const;

export const useGetIndividualsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetIndividualsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetIndividualsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIndividualsHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIndividualsHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIndividualsQueryKey(params);

  const getIndividuals = useGetIndividualsHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetIndividualsHook>>>> = ({ signal }) =>
    getIndividuals(params, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetIndividualsQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useGetIndividualsHook>>>>;
export type GetIndividualsQueryError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Get individuals from worklist
 */
export const useGetIndividuals = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetIndividualsHook>>>,
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  params?: GetIndividualsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetIndividualsHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetIndividualsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Lookup a profile present in the Acuris Search database
 */
export const useProfileLookupHook = () => {
  const profileLookup = useCustomInstance<AcurisIndividualProfileSchema>();

  return (resourceId: string, signal?: AbortSignal) => {
    return profileLookup({ url: `/acuris/profile/${resourceId}`, method: 'get', signal });
  };
};

export const getProfileLookupQueryKey = (resourceId: string) => [`/acuris/profile/${resourceId}`] as const;

export const useProfileLookupQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfileLookupHook>>>,
  TError = UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  resourceId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfileLookupHook>>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfileLookupHook>>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getProfileLookupQueryKey(resourceId);

  const profileLookup = useProfileLookupHook();

  const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useProfileLookupHook>>>> = ({ signal }) =>
    profileLookup(resourceId, signal);

  return { queryKey, queryFn, enabled: !!resourceId, ...queryOptions };
};

export type ProfileLookupQueryResult = NonNullable<Awaited<ReturnType<ReturnType<typeof useProfileLookupHook>>>>;
export type ProfileLookupQueryError = UnauthorizedResponse | NotFoundResponse | DefaultResponse;

/**
 * @summary Lookup a profile present in the Acuris Search database
 */
export const useProfileLookup = <
  TData = Awaited<ReturnType<ReturnType<typeof useProfileLookupHook>>>,
  TError = UnauthorizedResponse | NotFoundResponse | DefaultResponse
>(
  resourceId: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useProfileLookupHook>>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useProfileLookupQueryOptions(resourceId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Resolve a detected risk agent task with either a block or allow
 */
export const useResolveDetectedRiskHook = () => {
  const resolveDetectedRisk = useCustomInstance<void>();

  return (agentTaskId: string, acurisResolveRiskRequestSchema: AcurisResolveRiskRequestSchema) => {
    return resolveDetectedRisk({
      url: `/acuris/risk/${agentTaskId}/resolve`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: acurisResolveRiskRequestSchema,
    });
  };
};

export const useResolveDetectedRiskMutationOptions = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResolveDetectedRiskHook>>>,
    TError,
    { agentTaskId: string; data: AcurisResolveRiskRequestSchema },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useResolveDetectedRiskHook>>>,
  TError,
  { agentTaskId: string; data: AcurisResolveRiskRequestSchema },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const resolveDetectedRisk = useResolveDetectedRiskHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useResolveDetectedRiskHook>>>,
    { agentTaskId: string; data: AcurisResolveRiskRequestSchema }
  > = props => {
    const { agentTaskId, data } = props ?? {};

    return resolveDetectedRisk(agentTaskId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ResolveDetectedRiskMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useResolveDetectedRiskHook>>>
>;
export type ResolveDetectedRiskMutationBody = AcurisResolveRiskRequestSchema;
export type ResolveDetectedRiskMutationError =
  | BadRequestResponse
  | UnauthorizedResponse
  | NotFoundResponse
  | DefaultResponse;

/**
 * @summary Resolve a detected risk agent task with either a block or allow
 */
export const useResolveDetectedRisk = <
  TError = BadRequestResponse | UnauthorizedResponse | NotFoundResponse | DefaultResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useResolveDetectedRiskHook>>>,
    TError,
    { agentTaskId: string; data: AcurisResolveRiskRequestSchema },
    TContext
  >;
}) => {
  const mutationOptions = useResolveDetectedRiskMutationOptions(options);

  return useMutation(mutationOptions);
};
