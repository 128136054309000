import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { getGetUserGeneralQueryKey, useGetUserGeneral, useSetUserEmail } from '@greenisland-api';
import {
  Button,
  capitalize,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import ErrorState from '@greenisland-common/components/molecules/ErrorState';

interface Props {
  onClose: () => void;
}

const EditEmailDialog = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const { userId = '' } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data: user, isLoading, isError } = useGetUserGeneral(userId);
  const [email, setEmail] = useState(user?.email || '');
  const updateMutation = useSetUserEmail({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetUserGeneralQueryKey(userId));
      },
      onError: (error: any) => {
        enqueueSnackbar(error.Message || t('error'), { variant: 'error' });
      },
    },
  });

  const onSave = () => {
    if (email) {
      updateMutation.mutate({ userId, data: { email } });
      onClose();
    }
  };

  return (
    <Dialog maxWidth="xs" open onClose={onClose} fullWidth>
      <DialogTitle sx={{ textAlign: 'center' }}>{capitalize(t('changeEmail'))}</DialogTitle>
      {isLoading && <CircularProgress />}
      {isError && <ErrorState />}
      {!isLoading && !isError && user && (
        <>
          <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
            <Input
              sx={{ width: '100%', maxWidth: 300 }}
              defaultValue={user?.email}
              onChange={event => setEmail(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button
              sx={{ minHeight: '36px', minWidth: '90px' }}
              variant="contained"
              onClick={onSave}
              color="primary"
              disabled={!email || updateMutation.isLoading}
            >
              {updateMutation.isLoading ? <CircularProgress size={20} /> : t('save')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default EditEmailDialog;
