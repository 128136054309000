import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { getGetUserGeneralQueryKey, useAutomaticWithdrawalPermission, UserGeneralSchema } from '@greenisland-api';
import { OnlineCasinoPermissions } from '@greenisland-core/permissions';
import { useSnackbar } from 'notistack';

import GenericField from './GenericField';

interface Props {
  user: UserGeneralSchema;
}

const ManuallyBlockedFromWithdrawalsField = ({ user }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const updateMutation = useAutomaticWithdrawalPermission({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getGetUserGeneralQueryKey(user.userId));
        enqueueSnackbar(t('success'), { variant: 'success' });
      },
      onError: async error => {
        enqueueSnackbar(t('errorWithMessage', { message: error.message }), { variant: 'error' });
      },
    },
  });

  const { IsBlockedFromAutomaticWithdrawals } = user;
  const handleMutation = () =>
    updateMutation.mutate({ userId: user.userId, data: { permitted: !IsBlockedFromAutomaticWithdrawals } });

  return (
    <GenericField
      value={IsBlockedFromAutomaticWithdrawals}
      tooltip={
        IsBlockedFromAutomaticWithdrawals
          ? t('lookup.manualBlockedFromWithdrawalsTooltip.active')
          : t('lookup.manualBlockedFromWithdrawalsTooltip.unactive')
      }
      description={t('lookup.manualWithdrawalBlockedDescription')}
      togglePermissions={OnlineCasinoPermissions.automaticWithdrawalPermission}
      onToggle={handleMutation}
    />
  );
};

export default ManuallyBlockedFromWithdrawalsField;
