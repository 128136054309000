import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { faCheckCircle, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthorizationPermissions } from '@greenisland-core/permissions';
import {
  AgentPermissionsSchema,
  AgentRoleSchema,
  AgentSchema,
  useGetAgentDetails,
  useResetAgentPassword,
} from '@greenisland-store/authorization';
import { Box, Grid, Stack, Typography } from '@mui/material';

import { StyledDataGrid } from '@greenisland-common/components/atoms';

import ConfirmDialog from '../../../../../app/components/ConfirmDialog';
import { usePermission } from '../../../../../app/hooks';
import NestedRoles from './NestedRoles';

const AgentDetails = () => {
  const { t } = useTranslation();
  const { agentId = '' } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const canReadAgentDetails = usePermission(AuthorizationPermissions.getAgentDetails);

  const { data: agent, isLoading: fetching } = useGetAgentDetails(agentId, { query: { enabled: canReadAgentDetails } });
  const { mutate: resetPassword } = useResetAgentPassword();

  const mutatePermissions = (permissions: AgentPermissionsSchema) => {
    if (permissions.length === 0) return undefined;
    const columns = Object.keys(permissions[0]).map(key => {
      switch (key) {
        case 'permissionId':
          return { field: key, headerName: t(key), hide: true };
        default:
          return { field: key, headerName: t(key), flex: 1 };
      }
    });
    return {
      columns,
      rows: permissions.map((p, index) => ({
        ...p,
        id: index,
      })),
    };
  };

  const columns = [
    { field: 'title', headerName: t('title'), flex: 1 },
    { field: 'value', headerName: t('value'), flex: 1 },
  ];

  const mutatedData = (agent: AgentSchema) => {
    const rows = Object.entries(agent)
      .filter(([title]) => ['email', 'name', 'isAdmin', 'phone'].includes(title))
      .map(([title, value]) => ({
        id: title,
        title: t(title),
        value: value || '/',
      }));
    return { columns, rows };
  };

  return (
    <>
      <Stack spacing={2}>
        {agent?.isAdmin && (
          <Box color="success.main">
            <FontAwesomeIcon icon={faCheckCircle} />
            <Typography variant="body1" component="span" ml={1}>
              {t('isSuperAdmin')}
            </Typography>
          </Box>
        )}
        {agent?.isLocked && (
          <Box color="error.main">
            <FontAwesomeIcon icon={faCircleExclamation} />
            <Typography variant="body1" component="span" ml={1}>
              {t('locked').toUpperCase()}
            </Typography>
          </Box>
        )}
        {agent && (
          <StyledDataGrid
            columns={mutatedData(agent).columns}
            rows={mutatedData(agent).rows}
            autoHeight
            hideFooter
            disableColumnMenu
          />
        )}
        {agent?.permissions && mutatePermissions(agent.permissions) && (
          <Box>
            <Typography variant="h6" gutterBottom>
              {t('permissions')}
            </Typography>
            <StyledDataGrid
              columns={mutatePermissions(agent.permissions)!.columns}
              rows={mutatePermissions(agent.permissions)!.rows}
              autoHeight
              loading={fetching}
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              pagination
              pageSize={20}
            />
          </Box>
        )}
        {agent?.agentRoles && agent.agentRoles.length > 0 && (
          <Box>
            <Typography variant="h6" gutterBottom>
              {t('roles')}
            </Typography>
            <Grid container spacing={2}>
              {agent.agentRoles.map((role: AgentRoleSchema) => (
                <Grid item xs={12} key={role.roleId}>
                  <NestedRoles isSubRole level={1} agentRole={role} />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Stack>
      <ConfirmDialog
        title={t('resetPassword')}
        content={`${t('confirmResetPassword')}: ${agent?.email}`}
        isOpen={isOpen}
        setIsOpen={(open: boolean) => setIsOpen(open)}
        onConfirm={() => {
          if (agent) resetPassword({ agentId: agentId });
        }}
      />
    </>
  );
};

export default AgentDetails;
