import React, { memo, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  getGetExceedDefaultWeeklyDepositLimitRequestsQueryKey,
  getGetScheduledCanExceedRequestsQueryKey,
  getGetUserRegistrationQueryKey,
  useDeleteRequestCanExceedForToken,
  useDeleteRequestCanExceedForUser,
  useGetLimitStatusForTokenForUser,
  useRequestCanExceedForUser,
  useResetExceedDefaultWeeklyDepositLimitRequests,
  useScheduleCanExceedRequest,
} from '@greenisland-api';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';

import { TabPanel } from '@greenisland-common/components/atoms';

import RequestExceedTable from './DepositLimitStatus/RequestExceedTable';
import ScheduleRequestExceedTable from './DepositLimitStatus/ScheduleRequestExceedTable';
import ResetRetriesArticle6ActionTypeDialog from './ResetRetriesArticle6ActionTypeDialog';

interface Props {
  userId: string;
  userToken: string;
}

const DepositLimitsStatus = ({ userId, userToken }: Props) => {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isResetRetriesDialogOpen, setIsResetRetriesDialogOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();
  const mutationObject = {
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetScheduledCanExceedRequestsQueryKey(parseInt(userId)));
        queryClient.invalidateQueries(getGetUserRegistrationQueryKey(userId));
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  };

  const handleTabChange = (event: SyntheticEvent, newValue: number) => setSelectedTab(newValue);

  const requestExceed = useRequestCanExceedForUser({
    mutation: {
      onSuccess: data => {
        enqueueSnackbar(
          `status code: ${data.bgcClientResponse?.bgcResponseStatusCode} - response: ${data.bgcClientResponse?.bgcArticle6ErrorType}`,
          { variant: 'success' }
        );
        queryClient.invalidateQueries(getGetExceedDefaultWeeklyDepositLimitRequestsQueryKey(parseInt(userId)));
        queryClient.invalidateQueries(getGetUserRegistrationQueryKey(userId));
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });
  const scheduleCanRequestExceed = useScheduleCanExceedRequest(mutationObject);
  const deleteRequestExceed = useDeleteRequestCanExceedForUser({
    mutation: {
      onSuccess: data => {
        enqueueSnackbar(
          `status code: ${data.bgcClientResponse?.bgcResponseStatusCode} - response: ${data.bgcClientResponse?.bgcArticle6ErrorType}`,
          { variant: 'success' }
        );
        queryClient.invalidateQueries(getGetExceedDefaultWeeklyDepositLimitRequestsQueryKey(parseInt(userId)));
        queryClient.invalidateQueries(getGetUserRegistrationQueryKey(userId));
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });
  const resetRequestExceed = useResetExceedDefaultWeeklyDepositLimitRequests({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetExceedDefaultWeeklyDepositLimitRequestsQueryKey(parseInt(userId)));
        queryClient.invalidateQueries(getGetUserRegistrationQueryKey(userId));
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });
  const getLimistStatus = useGetLimitStatusForTokenForUser({
    mutation: {
      onSuccess: data => {
        enqueueSnackbar(`status code: ${data.bgcResponseStatusCode} - response: ${data.bgcArticle6ErrorType}`, {
          variant: 'success',
        });
        queryClient.invalidateQueries(getGetExceedDefaultWeeklyDepositLimitRequestsQueryKey(parseInt(userId)));
        queryClient.invalidateQueries(getGetUserRegistrationQueryKey(userId));
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const deleteRequestCanExceedForToken = useDeleteRequestCanExceedForToken({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(t('success'), { variant: 'success' });
        queryClient.invalidateQueries(getGetExceedDefaultWeeklyDepositLimitRequestsQueryKey(parseInt(userId)));
        queryClient.invalidateQueries(getGetUserRegistrationQueryKey(userId));
      },
      onError: () => {
        enqueueSnackbar(t('error'), { variant: 'error' });
      },
    },
  });

  const handleDeleteClick = () => {
    confirm({
      title: t('depositLimitsStatus.confirmDialogTitle'),
      description: t('depositLimitsStatus.confirmDeleteDialog'),
      confirmationButtonProps: { color: 'error', variant: 'contained' },
    }).then(() => deleteRequestExceed.mutate({ userId: parseInt(userId) }));
  };

  const handleResetClick = () => {
    confirm({
      title: t('depositLimitsStatus.confirmDialogTitleReset'),
      description: t('depositLimitsStatus.confirmResetDialog'),
      confirmationButtonProps: { color: 'error', variant: 'contained' },
      confirmationKeyword: 'Danger Ok',
      confirmationKeywordTextFieldProps: {
        label: t('depositLimitsStatus.confirmResetDialogConfirm', { message: 'Danger Ok' }),
        sx: { mt: 3 },
        color: 'info',
      },
    }).then(() => resetRequestExceed.mutate({ userId: parseInt(userId) }));
  };

  const handleConfirmationRequestExceed = () => {
    confirm({
      title: t('requestExceed'),
      description: t('depositLimitsStatus.confirmDialog', { action: t('requestExceed') }),
    }).then(() => requestExceed.mutate({ userId: parseInt(userId) }));
  };

  const handleConfirmationScheduleRequestExceed = () => {
    confirm({
      title: t('scheduleCanRequestExceed'),
      description: t('depositLimitsStatus.confirmDialog', { action: t('scheduleCanRequestExceed') }),
    }).then(() => scheduleCanRequestExceed.mutate({ userId: parseInt(userId) }));
  };

  const handleConfirmationGetLimitStatus = () => {
    confirm({
      title: t('getLimitsStatus'),
      description: t('depositLimitsStatus.confirmDialog', { action: t('getLimitsStatus') }),
    }).then(() => getLimistStatus.mutate({ userId: parseInt(userId) }));
  };

  const handleDeleteRequestCanExceedForToken = () => {
    confirm({
      title: t('deleteForToken'),
      description: t('depositLimitsStatus.deleteForToken', { action: t('deleteForToken') }),
    }).then(() => deleteRequestCanExceedForToken.mutate({ userId: parseInt(userId), token: userToken }));
  };

  const resetRetriesHandler = () => {
    setIsResetRetriesDialogOpen(true);
  };

  return (
    <>
      <Card>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <CardHeader title={t('depositLimitsStatus.title')} />
          {!isMobile && (
            <Stack direction="row" spacing={1}>
              <Button size="small" variant="contained" color="primary" onClick={handleConfirmationRequestExceed}>
                {t('requestExceed')}
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handleConfirmationScheduleRequestExceed}
              >
                {t('scheduleCanRequestExceed')}
              </Button>
              <Button size="small" variant="contained" color="primary" onClick={handleConfirmationGetLimitStatus}>
                {t('getLimitsStatus')}
              </Button>
              <Button size="small" variant="contained" color="error" onClick={handleDeleteClick}>
                {t('deleteRequestExceed')}
              </Button>
              <Button size="small" variant="contained" color="warning" onClick={resetRetriesHandler}>
                {t('common.resetRetries')}
              </Button>
            </Stack>
          )}
        </Box>
        <CardContent>
          {isMobile && (
            <Stack direction="row" mb={1} flexWrap="wrap" gap={1}>
              <Button size="small" variant="contained" color="primary" onClick={handleConfirmationRequestExceed}>
                {t('requestExceed')}
              </Button>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handleConfirmationScheduleRequestExceed}
              >
                {t('scheduleCanRequestExceed')}
              </Button>
              <Button size="small" variant="contained" color="primary" onClick={handleConfirmationGetLimitStatus}>
                {t('getLimitsStatus')}
              </Button>
              <Button size="small" variant="contained" color="error" onClick={handleDeleteClick}>
                {t('deleteRequestExceed')}
              </Button>
              <Button size="small" variant="contained" color="warning" onClick={resetRetriesHandler}>
                {t('common.resetRetries')}
              </Button>
            </Stack>
          )}
          <Tabs value={selectedTab} onChange={handleTabChange} aria-label="tabs period" variant="scrollable">
            <Tab label={t('requestExceed')} id="tab-1" aria-controls="tab-panel-1" />
            <Tab label={t('canRequestExceed')} id="tab-2" aria-controls="tab-panel-2" />
          </Tabs>
          <TabPanel sx={{ mt: 2 }} value={selectedTab} index={0}>
            <RequestExceedTable userId={parseInt(userId)} />
          </TabPanel>
          <TabPanel sx={{ mt: 2 }} value={selectedTab} index={1}>
            <ScheduleRequestExceedTable userId={parseInt(userId)} />
          </TabPanel>
        </CardContent>
        <CardActions>
          <Button size="small" variant="contained" color="error" onClick={handleResetClick}>
            {t('expireRequestExceed')}
          </Button>
          <Button size="small" variant="contained" color="error" onClick={handleDeleteRequestCanExceedForToken}>
            {t('deleteForToken')}
          </Button>
        </CardActions>
      </Card>
      <ResetRetriesArticle6ActionTypeDialog
        onClose={() => {
          setIsResetRetriesDialogOpen(false);
        }}
        open={isResetRetriesDialogOpen}
      />
    </>
  );
};

export default memo(DepositLimitsStatus);
